export default {
  SIGNUP_SUCCESS: '/signup-success',
  EMAIL_SIGNIN: '/slack/onboard/email-signin',
  EMAIL_SIGNUP: '/slack/onboard/email-signup',

  SIGN_IN: '/signin',
  HOT_FIX_SIGN_IN: '/hotfix-sign-in',
  SIGN_OUT: '/signout',
  FORGOT_PASSWORD: '/forgotPassword',
  FORGOT_PASSWORD_SUCCESS: '/forgotPassword/success',
  FORGOT_PASSWORD_VERIFY: '/forgotPassword/:verifyToken',
  RESET_PASSWORD: '/resetPassword',
  RESET_PASSWORD_SUCCESS: '/resetPassword/success',

  ENTER_EMAIL: '/create-account',
  ENTER_CODE: '/verify',
  ENTER_COMPANY_NAME: '/onboard/company',

  EMPLOYEE_ENTER_EMAIL: '/join',
  EMPLOYEE_SET_PROFILE: '/signup/set-profile',
  EMPLOYEE_SET_PROFILE_FROM_INVITE: '/signup/inviteToken',
  EMPLOYEE_SET_DETAILS: '/user/set-details',

  EMPLOYEE_VERIFY_MAGIC_LINK: '/verify/magicLink',

  SLACK_ONBOARD: '/slack/onboard',
  SLACK_SIGN_IN: '/slack/onboard/signin',
  SLACK_SIGN_IN_V2: '/slack/onboard/signin/v2',
  SLACK_ADD_TO: '/slack/onboard/add',
  SLACK_USER_AUTH: '/slack/onboard/userauth',
  SLACK_SELECT_CHANNELS: '/slack/onboard/channels',
  SLACK_SELECT_DEFAULT_CHANNEL: '/slack/onboard/default-channel',
  SLACK_SELECT_DEFAULT_CURRENCY: '/slack/onboard/currency',
  SLACK_SELECT_USERS_FOR_ACCESS: '/slack/onboard/select-users',
  SLACK_HANDLE_MEMBERS: '/slack/onboard/handle-members',
  SLACK_SPECIFIC_MEMBERS: '/slack/onboard/members',
  SLACK_AUTO_MANAGE_MEMBERS: '/slack/onboard/manage-members',
  SLACK_ASSEMBLY_ANNOUNCEMENT: '/slack/onboard/announcement',
  SLACK_SUCCESS: '/slack/onboard/success',

  ADP_SIGNIN: '/adp/onboard/authorize',
  IM_AUTHORIZE: '/:sso/onboard/authorize',

  CONNECTION_REDIRECT_URL: '/slack-redirect',
  BOT_AUTHORIZE: '/:botType/bot/authorize',

  SSO_SIGNIN_AUTHORIZE: '/:sso/signin/authorize',

  SSO_SIGNIN_AUTHORIZE_MODAL: '/:sso/signin/authorizeModal',
  SSO_SIGNUP_AUTHORIZE_MODAL: '/:sso/signup/authorizeModal',
  SAML_AUTHORIZE_MODAL: '/saml/authorizeModal',

  SSO_SIGNUP_AUTHORIZE: '/:sso/signup/authorize',

  SSO_ERRORS: '/:sso/:ssoType(signup|signin)/error/:errorCode',

  HOME: '/home',
  MSTEAM_CONFIG: '/msteams/config',

  //  ADMIN
  ADMIN: '/admin',

  //  WORKSPACE
  ADMIN_WORKSPACE_GENERAL: `/admin/settings`,
  ADMIN_WORKSPACE_ALLOWANCES: `/admin/settings/allowances`,
  ADMIN_WORKSPACE_ALLOWANCES_MANAGE: `/admin/settings/allowances/manage`,
  ADMIN_WORKSPACE_INTEGRATIONS_SLACK: `/admin/settings/integrations/slack`,
  ADMIN_WORKSPACE_INTEGRATIONS_TEAMS: `/admin/settings/integrations/teams`,
  ADMIN_WORKSPACE_INTEGRATIONS: `/admin/settings/integrations`,
  ADMIN_WORKSPACE_INSIGHTS: `/admin/settings/insights`,

  //  RECOGNITION
  ADMIN_RECOGNITION_SETTINGS: `/admin/recognition/settings`,
  ADMIN_RECOGNITION_ACTIVITY: `/admin/recognition/activity`,
  ADMIN_RECOGNITION_PARTICIPATION: `/admin/recognition/participation`,

  //  FLOWS
  ADMIN_FLOWS_PERMISSIONS: `/admin/flows/permissions`,

  //  USERS
  ADMIN_USER_MANAGE: `/admin/users/manage`,
  ADMIN_USER_MANAGE_IDENTITY: `/admin/users/manage/identity`,
  ADMIN_USER_INVITE: `/admin/users/invite`,
  ADMIN_USER_EARNINGS: `/admin/users/earnings`,
  ADMIN_USER_MANAGE_QUEUED: `/admin/users/manage?activeTab=queued`,

  //  CELEBRATION BOTS
  ADMIN_CELEBRATIONS_ANNIVERSARIES: `/admin/celebrations/anniversaries`,
  ADMIN_CELEBRATIONS_BIRTHDAYS: `/admin/celebrations/birthdays`,

  //  REWARDS
  ADMIN_REWARDS_GIFT_CARDS: `/admin/rewards/gifts`,
  ADMIN_REWARDS_CULTURE: `/admin/rewards/culture`,
  ADMIN_REWARDS_CHARITY: `/admin/rewards/charities`,
  ADMIN_REWARDS_REPORT: `/admin/rewards/report`,
  ADMIN_REWARDS_SWAGS: `/admin/rewards/swag`,

  //  BILLING
  ADMIN_BILLING_ACCOUNT: `/admin/billing/account`,
  ADMIN_BILLING_HISTORY: `/admin/billing/history`,
  ADMIN_BILLING_HISTORY_INVOICE: `/admin/billing/invoice`,
  ADMIN_BILLING_METHOD: `/admin/billing/method`,

  //  SAML
  ADMIN_SAML_AUTHENTICATION: `/admin/security/saml`,

  //  WORKSPACE LEGACY ROUTE
  ADMIN_WORKSPACE_GENERAL_LEGACY: '/admin/settings',
  ADMIN_WORKSPACE_ALLOWANCES_LEGACY: '/admin/settings/allowances',
  ADMIN_WORKSPACE_ALLOWANCES_MANAGE_LEGACY: '/admin/settings/allowances/manage',
  ADMIN_WORKSPACE_INTEGRATIONS_SLACK_LEGACY:
    '/admin/settings/integrations/slack',
  ADMIN_WORKSPACE_INTEGRATIONS_TEAMS_LEGACY:
    '/admin/settings/integrations/teams',
  ADMIN_WORKSPACE_INTEGRATIONS_LEGACY: '/admin/settings/integrations',
  ADMIN_WORKSPACE_INSIGHTS_LEGACY: '/admin/settings/insights',

  //  RECOGNITION LEGACY ROUTE
  ADMIN_RECOGNITION_SETTINGS_LEGACY: '/admin/recognition/settings',
  ADMIN_RECOGNITION_ACTIVITY_LEGACY: '/admin/recognition/activity',
  ADMIN_RECOGNITION_PARTICIPATION_LEGACY: '/admin/recognition/participation',

  //  FLOWS LEGACY ROUTE
  ADMIN_FLOWS_PERMISSIONS_LEGACY: '/admin/flows/permissions',

  //  USERS LEGACY ROUTE
  ADMIN_USER_MANAGE_LEGACY: '/admin/users/manage',
  ADMIN_USER_MANAGE_QUEUED_LEGACY: '/admin/users/manage?activeTab=queued',
  ADMIN_USER_MANAGE_IDENTITY_LEGACY: '/admin/users/manage/identity',
  ADMIN_USER_INVITE_LEGACY: '/admin/users/invite',
  ADMIN_USER_EARNINGS_LEGACY: '/admin/users/earnings',

  //  CELEBRATION BOTS LEGACY ROUTE
  ADMIN_CELEBRATIONS_ANNIVERSARIES_LEGACY: '/admin/celebrations/anniversaries',
  ADMIN_CELEBRATIONS_BIRTHDAYS_LEGACY: '/admin/celebrations/birthdays',

  //  REWARDS LEGACY ROUTE
  ADMIN_REWARDS_GIFT_CARDS_LEGACY: '/admin/rewards/gifts',
  ADMIN_REWARDS_CULTURE_LEGACY: '/admin/rewards/culture',
  ADMIN_REWARDS_CHARITY_LEGACY: '/admin/rewards/charities',
  ADMIN_REWARDS_REPORT_LEGACY: '/admin/rewards/report',
  ADMIN_REWARDS_SWAGS_LEGACY: '/admin/rewards/swag',

  //  BILLING LEGACY ROUTE
  ADMIN_BILLING_ACCOUNT_LEGACY: 'admin/billing/account',
  ADMIN_BILLING_HISTORY_LEGACY: '/admin/billing/history',
  ADMIN_BILLING_HISTORY_INVOICE_LEGACY: '/admin/billing/invoice',
  ADMIN_BILLING_METHOD_LEGACY: '/admin/billing/method',

  //  SAML LEGACY ROUTE
  ADMIN_SAML_AUTHENTICATION_LEGACY: '/admin/security/saml',

  // HELP
  HELP_CENTER: '/help-center',

  //  Client APP
  CLIENT_APP: '/client/app',
};
