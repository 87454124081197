import { Flex } from '../../Utils/styles/display';
import disassembled from '../../img/svgs/disassembled.svg';
import Body from '../atoms/Body';
import Button from '../atoms/Button';

export const UnsupportedBrowser = ({
  onBackHomeClick,
}: {
  onBackHomeClick: () => void;
}) => {
  return (
    <Flex
      height="100vh"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <img height="200px" src={disassembled} alt="Error icon" />
      <Body margin="30px 0 12px 0" variant="subHead2Medium">
        Your browser is outdated.
      </Body>
      <Body variant="body1">
        Please upgrade to enjoy the best Assembly experience.
      </Body>
      <Flex margin="20px 0" flexDirection="column" width="25%">
        <Button isFullWidth onClick={onBackHomeClick}>
          Take me home
        </Button>
        <Button variant="text" isFullWidth onClick={() => {}}>
          Learn more
        </Button>
      </Flex>
    </Flex>
  );
};

export const ErrorComponent = ({
  onBackHomeClick,
}: {
  onBackHomeClick: () => void;
}) => {
  return (
    <div className="somethingwentwrong-bg__div">
      <div className="vertical-center">
        <div className="container">
          <div className="row justify-content-center">
            <img className="nopage-image" src={disassembled} alt="" />
          </div>
          <div className="row justify-content-center top-buffer-new-row">
            <div className="nopage-subheader__div">
              Oops! Something was disassembled.
            </div>
          </div>
          <div className="row justify-content-center top-buffer-row-small-new bottom-buffer-row-new">
            <div className="nopage-body__div">
              Head back home while we re-assemble.
            </div>
          </div>
          <div className="row justify-content-center top-buffer-row">
            <Button
              variant="contained"
              color="primary"
              onClick={onBackHomeClick}
            >
              Take me home
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
