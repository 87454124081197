import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import getMonth from 'date-fns/getMonth';
import startOfMonth from 'date-fns/startOfMonth';
import isEqual from 'lodash/isEqual';

// this to get dayIds for custom occurrence
/* getDay() consider 0 as Sunday but in RRule Monday is our 1st day
ie., 0 is Monday. That' why when the day is 0 we return 6 & for the rest,
we reduce 1 */
export const getDayIdsForCustomOccurrence = (date: Date) => {
  const day = getDay(date);
  if (day === 0) {
    return 6;
  }
  return day - 1;
};

/*
  To get the selected date is the last day of the month and the week number
  1. get the startDate of the selectedMonth
  2. get the 1st day of the selectedDay (i.e, if the selectedDay is Monday then get the
  1st Monday from the selectedMonth)
  3. now get all the days of the selectedDay (i.e, get all the Mondays from the selectedMonth)
  4. now map the selectedDate with the arrayOfSelectedDays to get the weekNumber
*/
export const getWeekDetails = (date: Date) => {
  const selectedDay = getDay(date);
  const startDayOfMonth = startOfMonth(date);
  let tempDate = startDayOfMonth;
  for (let i = 0; i < 7; i += 1) {
    if (getDay(tempDate) === selectedDay) {
      break;
    } else {
      tempDate = addDays(tempDate, 1);
    }
  }

  const arrayOfSelectedDays: any[] = [];
  while (getMonth(tempDate) === getMonth(date)) {
    arrayOfSelectedDays.push(format(tempDate, 'd'));
    tempDate = addDays(tempDate, 7);
  }

  return {
    weekNumber: arrayOfSelectedDays.indexOf(format(date, 'd')) + 1,
    isLastWeek:
      arrayOfSelectedDays.indexOf(format(date, 'd')) + 1 ===
      arrayOfSelectedDays.length,
  };
};

// to check whether the RRule has all days[Sun-Sat]
export const hasAllWeekDays = (selectedDays: number[]) => {
  const allWeekDays = [0, 1, 2, 3, 4, 5, 6];
  return isEqual(allWeekDays, selectedDays.sort());
};

// to check whether the RRule has all the week days[Mon-Fri] - this is for builder
export const hasWeekDaysInParsedRRule = (selectedDays: number[]) => {
  const weekDays: number[] = [0, 1, 2, 3, 4];
  return isEqual(weekDays, selectedDays.sort());
};

export const isSameDay = (
  currentDate: Date | undefined,
  receivedDay: number,
) => {
  if (currentDate) {
    const currentDay = getDayIdsForCustomOccurrence(currentDate);
    const result = currentDay === receivedDay;
    return result;
  }
  return false;
};
