import React, { memo, useState, useCallback, useMemo } from 'react';

import {
  ADDED,
  GIVE_TROPHIES_BACK_TO_SENDER,
} from '../../../languages/en/home';
import { getAgoTime } from '../../../Utils/time';
import { getDisplayUserName, isDeactivatedUser } from '../../../Utils/user';

import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import FeedPostHeaderOption from '../FeedPostHeaderOption';

import { CommentHeaderProps } from './interfaces';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { FeedTypes } from '../../../interfaces/Home';

import {
  StyledFeedPostHeaderContent,
  FeedPostHeaderOptionsContainer,
} from '../../molecules/FeedPostHeader/styles';

import {
  StyledCommentHeader,
  StyledCommentHeaderSecondaryContent,
  StyledFeedPostHeaderContentInner,
  StyledSpinningLoader,
  StyledUserNameBody,
  StyledUserNameClickable,
  StyledCreatedAt,
} from './styles';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';
import { ActionsModal } from '../Comment/styles';
import { Flex } from '../../../Utils/styles/display';
import { ToggleButtonVariant } from '../../atoms/ToggleButton/interface';
import CommentActions from '../CommentActions';

const CommentHeader = (props: CommentHeaderProps) => {
  const {
    commentId,
    from,
    isDummy = false,
    trophies,
    assemblyCurrency,
    createdAt,
    currentUserId,
    avatarSize = '40px',
    onClickUserName,
    dropdownOptions,
    hasTrophies,
    onCopyLink,
    onEditLink,
    onDeleteCommentClick,
    className = '',
    isLoading,
    variant,
    reactions,
    onEmoticonSelect,
    onReactionToggleClick,
    showActionsModal,
    isEditing,
    showChatView,
  } = props;

  const [deleteCommentCarrotOption, setDeleteCommentCarrotOption] =
    useState<string>(GIVE_TROPHIES_BACK_TO_SENDER);

  const handleDeleteCommentOptionChange = useCallback(
    (value: string) => {
      setDeleteCommentCarrotOption(value);
    },
    [setDeleteCommentCarrotOption],
  );

  const onDeleteModalSubmitClick = useCallback(() => {
    if (onDeleteCommentClick) {
      onDeleteCommentClick(
        commentId,
        hasTrophies || false,
        deleteCommentCarrotOption,
      );
    }
  }, [onDeleteCommentClick, commentId, hasTrophies, deleteCommentCarrotOption]);

  const onCopyLinkClick = useCallback(() => {
    if (onCopyLink) {
      onCopyLink(commentId);
    }
  }, [onCopyLink, commentId]);

  const onEditReplyClick = useCallback(() => {
    if (onEditLink) {
      onEditLink(commentId);
    }
  }, [onEditLink, commentId]);

  const handleOnClickUserName = useCallback(
    (data: unknown) => {
      onClickUserName(data as FeedPostUserProps);
    },
    [onClickUserName],
  );

  const displayUserName = useMemo(() => {
    return getDisplayUserName({ user: from, currentUserId });
  }, [currentUserId, from]);

  const renderUserName = () => {
    if (isDummy) {
      return (
        <StyledUserNameBody inline variant="body2Medium" color="gray8">
          {displayUserName}
        </StyledUserNameBody>
      );
    }

    if (isDeactivatedUser(from)) {
      return (
        <StyledUserNameBody variant="body2Medium" color="gray6" inline>
          {DEACTIVATED_USER}
        </StyledUserNameBody>
      );
    }

    return (
      <StyledUserNameClickable
        data={from}
        onClickCallback={handleOnClickUserName}
      >
        {displayUserName}
      </StyledUserNameClickable>
    );
  };

  return (
    <div className={className}>
      <StyledCommentHeader variant={variant}>
        <Avatar
          img={from.image ? from.image : ''}
          size={avatarSize}
          fontSize="16px"
          name={from.firstName}
          userId={from.memberID}
          isDeleted={isDeactivatedUser(from)}
        />
        <StyledFeedPostHeaderContent>
          <StyledFeedPostHeaderContentInner>
            {renderUserName()}
            {trophies && (
              <>
                <Body inline variant="body2" color="gray8">
                  {' '}
                  {ADDED}{' '}
                </Body>
                {assemblyCurrency && (
                  <AssemblyCurrencyIcon
                    id="id"
                    currencyName="currencyName"
                    assemblyCurrency={assemblyCurrency}
                    size="14px"
                  />
                )}
                <Body
                  data-testid="trophies"
                  data-qa-id="trophies"
                  inline
                  variant="body2"
                  color="gray8"
                >
                  {trophies}
                </Body>
              </>
            )}
            {variant === 'sidebar' && isEditing && (
              <Body inline variant="body3" color="gray7" margin="1px 4px 0 4px">
                Editing...
              </Body>
            )}
            {variant === 'sidebar' && !isEditing && (
              <StyledCreatedAt
                inline
                variant="body3"
                color="gray7"
                margin="1px 4px 0 4px"
              >
                {getAgoTime(createdAt)}
              </StyledCreatedAt>
            )}
            {isLoading && <StyledSpinningLoader scale={0.12} />}
          </StyledFeedPostHeaderContentInner>
          {variant !== 'sidebar' && (
            <StyledCommentHeaderSecondaryContent>
              <Body inline variant="body3" color="gray7">
                {getAgoTime(createdAt)}
              </Body>
            </StyledCommentHeaderSecondaryContent>
          )}
        </StyledFeedPostHeaderContent>

        {showActionsModal && variant === 'sidebar' && (
          <ActionsModal>
            <Flex>
              <CommentActions
                icon="more"
                variant={variant}
                showReactions={false}
                reactions={reactions}
                contentId={commentId}
                title="comment options"
                hasTrophies={hasTrophies}
                showChatView={showChatView}
                feedType={FeedTypes.COMMENT}
                onCopyLink={onCopyLinkClick}
                currentUserId={currentUserId}
                dropdownOptions={dropdownOptions}
                onEmoticonSelect={onEmoticonSelect}
                canShowReactions={!reactions.length}
                reactionButtonSize={ButtonSize.Small}
                onReactionToggleClick={onReactionToggleClick}
                toggleButtonVariant={ToggleButtonVariant.Text}
                onEditReplyOptionClick={onEditReplyClick}
                dropdownToggleButtonSize={ButtonSize.Small}
                onDeleteModalSubmitClick={onDeleteModalSubmitClick}
                onDeletePostSelectedOption={handleDeleteCommentOptionChange}
                currency={assemblyCurrency}
              />
            </Flex>
          </ActionsModal>
        )}

        {variant !== 'sidebar' && (
          <FeedPostHeaderOptionsContainer>
            {!isDummy && (
              <FeedPostHeaderOption
                icon="more"
                title="comment options"
                hasTrophies={hasTrophies}
                currency={assemblyCurrency}
                onCopyLink={onCopyLinkClick}
                feedType={FeedTypes.COMMENT}
                onEditReplyOptionClick={onEditReplyClick}
                dropdownOptions={dropdownOptions}
                dropdownToggleButtonSize={ButtonSize.Small}
                onDeleteModalSubmitClick={onDeleteModalSubmitClick}
                onDeletePostSelectedOption={handleDeleteCommentOptionChange}
              />
            )}
          </FeedPostHeaderOptionsContainer>
        )}
      </StyledCommentHeader>
    </div>
  );
};

const MemoizedCommentHeader = memo(CommentHeader);
MemoizedCommentHeader.displayName = 'CommentHeader';

export default MemoizedCommentHeader;
