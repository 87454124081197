import { trackEvent } from '..';
import { AnalyticsEvents, CARD_EVENTS, DISCOVER_EVENT } from '../constants';
import { CardEventProps, DiscoverEventProps } from '../interfaces';

const trackDiscoverActionEvent = (eventProps: DiscoverEventProps) => {
  trackEvent(DISCOVER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

const trackCardActionEvent = (eventProps: CardEventProps) => {
  trackEvent(CARD_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export { trackDiscoverActionEvent, trackCardActionEvent };
