export const RECOGNITION_POST_LABELS = {
  CELEBRATE_LABEL: 'Who would you like to celebrate?',
  CORE_VALUE_LABEL: 'Choose a core value.',
  OPEN_ENDED_LABEL: 'What did your teammate(s) do?',
  TROPHIES_BLOCK_LABEL: 'Lastly, boost your celebration with',
};

export const FLOW_FEED_STATES = {
  NO_POSTS: {
    HEADING: 'There are no posts for this feed yet.',
    SUB_HEADING: 'Once someone participates, their answers will show up here.',
  },
  FLOW_NOT_TRIGGERED: {
    HEADING: 'You haven’t run this flow, yet!',
    SUB_HEADING:
      // eslint-disable-next-line max-len
      `Once you do, participants' answers will display in this feed. Run your flow for the first time below or click “Run now” above in the flow feed header.`,
    BUTTON_TEXT: 'Run now',
    BUTTON_ICON: 'play',
  },
  FLOW_NOT_VISIBLE: {
    HEADING: '👀 You don’t have visibility into this flow',
    SUB_HEADING:
      // eslint-disable-next-line max-len
      'You don’t have visibility permission into this flow, you aren’t able to see any of the posts. You can always edit the flow to give yourself visibility if needed.',
  },
  NO_MATCHING_POSTS: {
    HEADING: 'No posts match this filter configuration.',
    SUB_HEADING: 'Try changing it to try again.',
  },
  FLOW_NOT_FOUND: {
    HEADING: 'We couldn’t find this flow',
    SUB_HEADING: 'Head back to my feed.',
    BUTTON_TEXT: 'Go to My Feed',
    BUTTON_ICON: 'home',
  },
  ACCESS_DENIED: {
    HEADING: 'You don’t have access to this flow',
  },
  SERVER_ERROR: {
    HEADING: 'Oops! Something went wrong.',
    SUB_HEADING: 'Try refreshing the page or head back to my feed.',
  },
  SCHEDULED_FLOW_EMPTY_STATES: {
    HEADING: 'This flow hasn’t run yet!',
    GET_SUB_HEADING_PREFIX: (
      date: string,
      time: string,
      timeZone: string | undefined,
    ) => {
      return `The flow is scheduled to start on ${date} at ${time} ${timeZone}.`;
    },
    SUB_HEADING_SUFFIX_WITH_SHORTCUT: 'can still answer the flow anytime.',
    SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT:
      'won’t see this feed until it is triggered.',
  },
  SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES: {
    HEADING: 'This flow has no answers yet',
    GET_SUB_HEADING_PREFIX: (
      date: string,
      time: string,
      timeZone: string | undefined,
    ) => {
      return `The flow is scheduled to run again on ${date} at ${time} ${timeZone}.`;
    },
    SUB_HEADING_SUFFIX_WITH_SHORTCUT: 'can still answer this anytime.',
    SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_1: "We'll send an email to",
    SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_2: 'when this flow opens for answers.',
  },
  PREVIEW_FLOW: {
    Text: 'Preview flow',
    Icon: 'eye',
  },
};

export const EXIT_FULL_SCREEN = 'Exit full screen';
export const CHANGE_POST_DROPDOWN_LABEL = (time: number) => {
  return `Change post after ${time} seconds`;
};
export const NO_DESCRIPTION = 'No description';

export const FLOW_TRIGGER_RESPONSE_MESSAGE = {
  SUCCESS: 'Your flow is now running',
  ERROR: 'Whoops! Something went wrong. Please try running your flow again',
};

export const END_OCCURRENCE_RESPONSE_MESSAGE = {
  SUCCESS: 'Occurrence ended',
  ERROR: 'Whoops! Something went wrong. Please try end occurrence again',
};

export const REMIND_PARTICIPANTS_RESPONSE_MESSAGE = {
  SUCCESS: 'Sent reminder to participants',
  ERROR: 'Whoops! Something went wrong. Please try remind participants again',
};

export const FLOW_ARCHIVE_RESPONSE_MESSAGE = {
  SUCCESS: 'Flow archived successfully!',
  ERROR: 'Whoops! Something went wrong. Please try again',
};

export const FLOW_UNARCHIVE_RESPONSE_MESSAGE = {
  SUCCESS: 'Flow unarchived successfully!',
  ERROR: 'Whoops! Something went wrong. Please try again',
};

export const FLOW_TRIGGER_MODAL_VALIDATION_ERROR = {
  MINUTES: {
    LOWER: 'Cannot be less than 5 minutes',
    HIGHER: 'Cannot be higher than 44640 minutes',
  },
  HOURS: 'Cannot be greater than 744 hours',
  DAYS: 'Cannot be greater than 31 days',
  TWENTY_EIGHT_DAYS: 'Cannot be greater than 28 days',
  WEEKS: 'Cannot be greater than 4 weeks',
};

export const FLOW_REMIND_PARTICIPANTS = {
  BUTTON_TEXT: 'Remind',
  BUTTON_ICON: 'alarm',
};
