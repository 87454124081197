import React, { useEffect, useState } from 'react';
import { UnsupportedBrowser, ErrorComponent } from '../molecules/ErrorScreen';

import Bowser from 'bowser';
import data from '../../browserList.json';

export const ErrorFallback = () => {
  const [unsupportedBrowser, setIsUnsupportedBrowser] = useState(false);
  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    let isSupportedBrowser = true;
    if (browser) {
      // Get the list from the browserList.json file which is the latest browsers we support
      const currentBrowserDataFromList = data.find(
        (i) => i.name === browser.getBrowserName(),
      );
      if (currentBrowserDataFromList) {
        //  Check if the browser version is less than the minimum version we support
        isSupportedBrowser =
          !isNaN(parseInt(browser.getBrowserVersion())) &&
          parseInt(browser.getBrowserVersion()) >
            Math.min(
              ...Object.keys(currentBrowserDataFromList?.versions).map((i) =>
                parseInt(i),
              ),
            );
      }
    }
    setIsUnsupportedBrowser(!isSupportedBrowser);
  }, []);

  const onBackHomeClick = () => {
    setIsUnsupportedBrowser(false);
    window.location.href = `${window.location.origin}/home`;
  };

  if (unsupportedBrowser) {
    // If the browser version is outdated than the minimum version we support,
    // then show the UnsupportedBrowser component
    return <UnsupportedBrowser onBackHomeClick={onBackHomeClick} />;
  }
  return <ErrorComponent onBackHomeClick={onBackHomeClick} />;
};
