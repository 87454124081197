import React from 'react';

import useFilterControllerLogic from './useFilterController';

import KnowledgeCenterFilter from '../../../../atomic/organism/RightDrawerFilter/KnowledgeCenterFilter';
import KnowledgeCenterFilterErrorState from '../../../../atomic/organism/RightDrawerFilter/ErrorComponent';
import { KnowledgeCenterFilterSkeleton } from '../../../../atomic/organism/RightDrawerFilter/KnowledgeCenterFilter/KnowledgeCenterFilterSkeleton';
import { ComponentStatus } from '../../../../interfaces/component';

const FilterController = () => {
  const { models, operations } = useFilterControllerLogic();
  const {
    dateFilterProps,
    flowsFilterProps,
    postedByFilterProps,
    fileTypeFilterProps,
    isLoading,
    isError,
    isEmpty,
    isFilterButtonEnabled,
  } = models;
  const { handleOnClear, handleOnSubmit } = operations;

  if (isLoading) {
    return <KnowledgeCenterFilterSkeleton status={ComponentStatus.LOADING} />;
  }
  if (isError) {
    return <KnowledgeCenterFilterErrorState />;
  }
  if (isEmpty) {
    return <KnowledgeCenterFilterSkeleton status={ComponentStatus.EMPTY} />;
  }

  return (
    <KnowledgeCenterFilter
      dateFilterProps={dateFilterProps}
      flowFilterProps={flowsFilterProps}
      postedByFilterProps={postedByFilterProps}
      fileTypeFilterProps={fileTypeFilterProps}
      onSubmit={handleOnSubmit}
      onClear={handleOnClear}
      isFilterButtonEnabled={isFilterButtonEnabled}
    />
  );
};

export default FilterController;
