import styled from 'styled-components';
import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import DropdownIconButton from '../DropdownIconButton';
import CardWithIcon from '../../atoms/CardWithIcon';
import Body from '../../atoms/Body';

export const StyledCardWithIcon = styled(CardWithIcon)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 172px;
`;

export const StyledHeading = styled(Body)`
  overflow: hidden;
  margin: 4px 0 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  width: 100%;
  display: inline;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 40px;
`;

export const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-bottom: 2px;
`;

export const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 4px;
  svg path {
    fill: ${ThemeV2.palette.gray8};
  }
`;

export const StyledBody = styled(Body)`
  margin: 0;
  color: ${ThemeV2.palette.gray8};
`;

export const StyledButton = styled(Button)`
  margin-bottom: -3px;
`;
