/* eslint-disable max-len */
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import {
  canGiveCustomAmountOfPoints,
  getAssemblyCurrency,
  getCanUserPostPrivately,
} from '../../../queries/Profile/utils';
import Body from '../../../atomic/atoms/Body';
import FlowsPointsAdjustmentWarning from '../../../atomic/molecules/FlowsPointsAdjustmentWarning';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import FlowsTrophiesInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsTrophiesInputBlock';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../atomic/pages/ParticipationTemplate';
import FlowMultiselectInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowMultiselectInputBlock';
import FlowsDropdownInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsDropdownInputBlock';
import FlowsOpenEndedInputBlock from '../../../atomic/molecules/FlowInputBlocks/FlowsOpenEndedInputBlock';
import {
  ARE_GIVING_RECOGNITION,
  PERSON_SELECTION_NOT_EDITABLE_WARNING,
  RECOGNITION_FLOW_HEADER_DESCRIPTION,
  SAVE_CHANGES,
  TROPHY_BLOCK_NOT_EDITABLE_WARNING,
  VALIDATION_ERRORS,
} from '../../../languages/en/flows/participation';
import useRecognitionFlowController from './useRecognitionFlowController';
import { STEP_IDS } from './data';
import {
  FIRST_SLIDE_LABEL,
  FIRST_SLIDE_TITLE,
  generateTrophiesBlockHeading,
  SECOND_SLIDE_DESCRIPTION,
  SECOND_SLIDE_TITLE,
  THIRD_SLIDE_LABEL,
  THIRD_SLIDE_TITLE,
} from '../../../languages/en/recognitionFlow';
import FlowUserReceivingPointsInfo from '../../../atomic/molecules/FlowUsersReceivingPointsInfo';
import { FlowVariants, StaticBlockState } from '../../../interfaces/Flow';
import { isBlockContentValid } from '../../../hooks/useParticipationFlow/utils';
import { POST, YOU } from '../../../languages/en/singleWords';
import { ParticipationFlowHeaderContent } from '../../../atomic/molecules/FlowsParticipationHeader';
import { ComponentStatus } from '../../../interfaces/component';
import SplitButton from '../../../atomic/molecules/SplitButton';
import Button from '../../../atomic/atoms/Button';
import { FeedItemFromAPI } from '../../../interfaces/Feed';
import DropdownFooter from '../../../atomic/molecules/DropdownFooter';
import { useGetSinglePostQuery } from '../../../queries/Feed';

interface RecognitionFlowControllerProps {
  profileInfo: GetProfileInfoResponse;
  selectedTeammateDropdownItems?: AutocompleteDropdownItem<string>[];
  toggleIsRecognitionFlowModalOpen: () => void;
  onPostSuccess?: () => void;
  postData?: FeedItemFromAPI;
}

const recognitionFlowHeaderContent: ParticipationFlowHeaderContent = {
  TitleContent: (
    <>
      <Body color="geekblue6" inline variant="body2Medium">
        {`${YOU} `}
      </Body>
      {`${ARE_GIVING_RECOGNITION} `}
    </>
  ),
  DescriptionContent: RECOGNITION_FLOW_HEADER_DESCRIPTION,
};

const RecognitionFlowController = ({
  profileInfo,
  postData,
  selectedTeammateDropdownItems,
  toggleIsRecognitionFlowModalOpen,
  onPostSuccess,
}: RecognitionFlowControllerProps) => {
  const { postID } = postData || {};

  const { refetch } = useGetSinglePostQuery(postID as string);

  const postSucessHandler = () => {
    if (postID) {
      refetch();
    }

    onPostSuccess?.();
  };

  const {
    models: {
      isNavigatingToMainApp,
      blockErrors,
      currentStep,
      updatedSlideData,
      values,
      memberOptions,
      isMembersFetching,
      textboxValue,
      totalMembers,
      havePointsBeenAdjusted,
      assembly,
      fieldErrors,
      coreValueOptions,
      containerRef,
      errors,
      hasVisitedLastStep,
      isSubmitting,
      isPrivatePost,
      member,
      pointsLeftThisCycle,
      dynamicBlockData,
      isSingleMemberAssembly,
      bannerProps,
      isDraftMode,
      isEditMode,
      isEditModeSubmitButtonDisabled,
      inviteUserFromPersonSelectorProps,
      getDropdownFooterMessage,
    },
    operations: {
      onStepChange,
      setFieldValue,
      onTextboxValueChange,
      handleAdjustmentWarningCTAClick,
      handleModalClose,
      handleTrophiesValueChange,
      onFormCompleteClick,
      setIsPrivatePost,
      goToNextStep,
      goToPreviousStep,
      onPeopleOptionsScroll,
      handleDeleteDraft,
      handleMultiSelectInputBlockValueChange,
    },
  } = useRecognitionFlowController(
    profileInfo,
    postData,
    toggleIsRecognitionFlowModalOpen,
    selectedTeammateDropdownItems,
    postSucessHandler,
  );

  const renderSlide = () => {
    const { id: currentStepId, type } = updatedSlideData[currentStep];
    if (isSingleMemberAssembly && blockErrors[currentStepId]) {
      blockErrors[currentStepId] = VALIDATION_ERRORS.INVITE_AT_LEAST_ONE_MEMBER;
    }

    switch (type) {
      case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
        const getWarning = () => {
          if (isEditMode) {
            return PERSON_SELECTION_NOT_EDITABLE_WARNING;
          }

          if (havePointsBeenAdjusted) {
            <FlowsPointsAdjustmentWarning
              adjustedTo={values[STEP_IDS.TROPHIES]}
              assemblyCurrency={assembly.currency}
              onCallToActionClick={handleAdjustmentWarningCTAClick}
            />;
          }

          return null;
        };
        return (
          <FlowMultiselectInputBlock
            blockError={blockErrors[currentStepId]}
            goToNextStep={goToNextStep}
            title={FIRST_SLIDE_TITLE}
            inputLabel={FIRST_SLIDE_LABEL}
            isRequired
            value={values[currentStepId]}
            onChange={handleMultiSelectInputBlockValueChange}
            onBlur={() => {
              onTextboxValueChange('');
            }}
            options={memberOptions}
            loading={isMembersFetching}
            textboxValue={textboxValue}
            onTextboxValueChange={onTextboxValueChange}
            inputBaseRightPadding="48px"
            hasNoOptions={
              !inviteUserFromPersonSelectorProps && totalMembers === 1
            }
            onOptionsScroll={onPeopleOptionsScroll}
            warning={getWarning()}
            disabled={isEditMode}
            filterOptionsFunction={(opts) => opts}
          >
            {inviteUserFromPersonSelectorProps && (
              <DropdownFooter message={getDropdownFooterMessage()} />
            )}
          </FlowMultiselectInputBlock>
        );
      }
      case 'OPEN_ENDED': {
        return (
          <FlowsOpenEndedInputBlock
            blockError={blockErrors[currentStepId]}
            blockValue={values[STEP_IDS.WHAT_DID_THEY_DO]}
            description={SECOND_SLIDE_DESCRIPTION}
            fieldError={fieldErrors[currentStepId] as string}
            gifRatings={assembly.settings.gifAccessibility.value}
            goToNextStep={goToNextStep}
            hasError={
              !!blockErrors[currentStepId] || !!fieldErrors[currentStepId]
            }
            isRequired
            onBlockChange={(newBlockValue) =>
              setFieldValue(STEP_IDS.WHAT_DID_THEY_DO, newBlockValue)
            }
            title={SECOND_SLIDE_TITLE}
            hideAttachment
            hideTasks
            flowVariant={FlowVariants.RECOGNITION_FLOW}
          />
        );
      }
      case 'SINGLE_SELECT_DROPDOWN': {
        return (
          <FlowsDropdownInputBlock
            goToNextStep={goToNextStep}
            title={THIRD_SLIDE_TITLE}
            inputLabel={THIRD_SLIDE_LABEL}
            value={values[currentStepId]}
            onChange={(val) => setFieldValue(currentStepId, val)}
            options={coreValueOptions}
            isRequired={false}
          />
        );
      }
      case 'TROPHIES': {
        const assemblyCurrency = getAssemblyCurrency(profileInfo);
        const pluralCurrencyName = assemblyCurrency.pluralName;
        const canGiveUpToRemainingAllowance =
          canGiveCustomAmountOfPoints(profileInfo);
        const splitBy = values[STEP_IDS.SELECT_TEAMMATE].length || 1;
        const maximumGivingPercentage =
          profileInfo.assembly.settings.postImpactLevel.value.levels[4]
            .percentage;
        const maxPoints = canGiveUpToRemainingAllowance
          ? Math.floor(pointsLeftThisCycle / splitBy)
          : Math.min(
              Math.floor(pointsLeftThisCycle / splitBy),
              Math.floor(
                (member.allowance.points * maximumGivingPercentage) / 100,
              ),
            );
        return (
          <FlowsTrophiesInputBlock
            assemblyCurrency={assembly.currency}
            fieldError={errors[currentStepId] as string}
            monthlyAllowance={member.allowance.points}
            remainingAllowance={pointsLeftThisCycle}
            splitBy={splitBy}
            title={generateTrophiesBlockHeading(pluralCurrencyName)}
            onChange={handleTrophiesValueChange}
            maxPoints={maxPoints}
            value={values[currentStepId]}
            info={
              !isEditMode && values[STEP_IDS.SELECT_TEAMMATE]?.length ? (
                <FlowUserReceivingPointsInfo
                  onEditPeopleClick={() => onStepChange(0)}
                  users={values[STEP_IDS.SELECT_TEAMMATE]}
                />
              ) : null
            }
            warning={isEditMode && TROPHY_BLOCK_NOT_EDITABLE_WARNING}
            isEditMode={isEditMode}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const isPostDisabled =
    !(
      updatedSlideData.some(
        (step: StaticBlockState) =>
          values[step.id] && isBlockContentValid(step, values[step.id]),
      ) && hasVisitedLastStep
    ) || isNavigatingToMainApp;

  const isEditPostDisabled =
    !updatedSlideData.filter(
      (step: StaticBlockState) =>
        values[step.id] && isBlockContentValid(step, values[step.id]),
    ) ||
    isEditModeSubmitButtonDisabled ||
    isNavigatingToMainApp;

  const isSubmitButtonDisabled = isEditMode
    ? isEditPostDisabled
    : isPostDisabled;

  const SubmitButton = getCanUserPostPrivately(profileInfo) ? (
    <SplitButton
      disabled={isSubmitButtonDisabled}
      isLoading={isSubmitting}
      isPrivatePost={isPrivatePost}
      allowPrivateResponse={true}
      onPrivatePostToggle={() => setIsPrivatePost(!isPrivatePost)}
      onPostClick={onFormCompleteClick}
      isAnonymousPost={false}
      isDraftMode={isDraftMode}
      onCloseAndSaveDraftClick={handleModalClose}
      key="1"
    />
  ) : (
    <Button
      disabled={isSubmitButtonDisabled}
      isLoading={isSubmitting}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
    >
      {POST}
    </Button>
  );

  const EditPostSubmitButton = (
    <Button
      disabled={isSubmitButtonDisabled}
      isLoading={isSubmitting}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
      icon="tick"
      variant="contained"
      status="success"
      dataTestId="editPostSubmit"
    >
      {SAVE_CHANGES}
    </Button>
  );

  const footerButtons: JSX.Element[] = [
    isEditMode ? EditPostSubmitButton : SubmitButton,
  ];

  return (
    <ParticipationTemplate
      isFullScreen
      showCloseModalOption
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      isNextButtonDisabled={currentStep === updatedSlideData.length - 1}
      isPreviousButtonDisabled={currentStep === 0}
      member={member}
      onCloseModal={handleModalClose}
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={FlowVariants.RECOGNITION_FLOW}
      flowId=""
      flowHeaderContent={recognitionFlowHeaderContent}
      headerStatus={ComponentStatus.LOADED}
      footerButtons={footerButtons}
      bannerProps={bannerProps}
      isDraftMode={isDraftMode}
      isEditMode={isEditMode}
      handleDeleteDraft={handleDeleteDraft}
    >
      {renderSlide()}
    </ParticipationTemplate>
  );
};

export default RecognitionFlowController;
