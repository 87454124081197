import styled from 'styled-components';

export const DividerWrapper = styled.div`
  width: 100%;
  padding: 0px 24px;
`;

export const AboutMeSectionWrapper = styled.div`
  padding: 8px 24px;
`;
