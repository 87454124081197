import React, { useMemo, Fragment } from 'react';
import format from 'date-fns/format';
import Divider from '../../../atoms/Divider';
import {
  TaskHistoryWrapper,
  TaskHistoryLoader,
  LogGroupHeader,
} from './styles';
import ThemeV2 from '../../../../theme';
import { TaskHistoryActivity, TaskHistoryProps } from './interface';
import HistoryItemContent from './TaskHistoryItem';

const TaskHistory = ({
  taskHistory,
  handleUserClick,
  isLoading,
}: TaskHistoryProps) => {
  const categories = useMemo(() => {
    const map = new Map<string, TaskHistoryActivity[]>();
    taskHistory.forEach((item) => {
      const date = format(new Date(item.createdAt), 'LLLL do, yyyy');
      if (map.has(date)) {
        map.get(date)?.push(item);
      } else {
        map.set(date, [item]);
      }
    });
    return map;
  }, [taskHistory]);

  if (isLoading) {
    return (
      <TaskHistoryLoader
        speed={2}
        width={272}
        height={100}
        viewBox="0 0 272 100"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loader"
      >
        <rect x="0" y="0" rx="4" ry="4" width="135" height="12" />
        <circle cx="10" cy="30" r="10" />
        <rect x="28" y="22" rx="4" ry="4" width="230" height="16" />
        <rect x="28" y="46" rx="4" ry="4" width="230" height="12" />
        <rect x="28" y="66" rx="4" ry="4" width="72" height="10" />
      </TaskHistoryLoader>
    );
  }

  return (
    <TaskHistoryWrapper>
      {Array.from(categories).map(([date, itemArray]) => {
        return (
          <Fragment key={date}>
            <LogGroupHeader variant="body2" color="gray7" margin="12px 0 0 0">
              {date}
            </LogGroupHeader>
            <Divider
              color={ThemeV2.palette.white}
              isFullWidth
              marginBottom="8px"
              marginTop="8px"
            />
            {itemArray.map((item) => (
              <HistoryItemContent
                key={item.activityId}
                item={item}
                handleUserClick={handleUserClick}
              />
            ))}
          </Fragment>
        );
      })}
    </TaskHistoryWrapper>
  );
};

export default TaskHistory;
