export const COMPANY_LOGO_ALT_TEXT = 'Company logo';
export const MAIN_FEED = 'My Feed';

export const ERROR_FINDING_USER = 'Error finding user';
export const PROFILE_FEED = 'Profile Feed';

export const REWARDS_HEADING = 'Rewards';
export const REWARDS_HEADER_SUBTITLE = 'Redeem your points for rewards here!';

export const POST_BY = 'Post by';
export const POST_NOT_FOUND = 'Post not found';
export const POST_SUBHEADER = 'This post has been deleted or does not exist';
export const COULDNT_FIND_POST = 'We couldn’t find this post';
export const COULDNT_FIND_POST_COMMENT =
  'Head back to my feed to see more posts.';

export const getMainFeedName = (companyName: string) =>
  `${companyName}'s ${MAIN_FEED}`;

export const NOTEBOOK_HEADER_TITLE = 'Notebook';
export const NOTEBOOK_HEADER_SUBTITLE =
  'Manage all tasks assigned to you and created by you.';

export const KNOWLEDGE_CENTER_TITLE = 'Knowledge Center';
export const KNOWLEDGE_CENTER_SUBTITLE =
  'Find files, images, and links shared in all of your flows!';
export const PEOPLE_DIRECTORY_TITLE = 'People';
export const PEOPLE_DIRECTORY_SUBTITLE =
  'Find information about your teammates';
export const KNOWLEDGE_CENTER_LOADING = 'Loading your files...';
export const KNOWLEDGE_CENTER_LOADING_SUB =
  'Collecting files from high and low.';
export const KNOWLEDGE_CENTER_ERROR = 'Error loading Knowledge Center';
export const KNOWLEDGE_CENTER_ERROR_SUB =
  'Please refresh the page to try again.';
export const KNOWLEDGE_CENTER_EMPTY = 'There are no files here, yet';
export const KNOWLEDGE_CENTER_EMPTY_SUB =
  // eslint-disable-next-line max-len
  "The Knowledge Center is a collection of all the files, images, and links you have access to. Once you or someone else uploads a file to a flow you are a part of, it'll show up here.";
export const KNOWLEDGE_CENTER_EMPTY_SEARCH =
  'We couldn’t find any files for this search';
export const KNOWLEDGE_CENTER_EMPTY_SEARCH_SUB =
  'Try another search to find what you’re looking for.';
