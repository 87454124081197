import styled, { css } from 'styled-components';

import ReactionsBar from '../ReactionsBar';
import ThemeV2 from '../../../theme';
import SpinningLoader from '../../atoms/SpinnerLoader';
import FeedPostHeaderOption from '../FeedPostHeaderOption';
import Body from '../../atoms/Body';
import ClickableLink from '../../molecules/ClickableLink';

export const StyledCommentHeader = styled.div<{ variant?: 'feed' | 'sidebar' }>`
  display: flex;
  padding-top: 8px;
  padding-left: 4px;
  align-items: ${({ variant }) =>
    variant === 'sidebar' ? 'flex-start' : 'center'};

  > div:first-child {
    margin-top: 4px;
  }
`;

export const StyledCommentHeaderSecondaryContent = styled.div`
  margin-top: -4px;
`;

export const StyledFeedPostHeaderContentInner = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`;

export const StyledSpinningLoader = styled(SpinningLoader)`
  margin-left: 8px;
  margin-top: 2px;
`;

export const StyledReactionsBar = styled(ReactionsBar)`
  button {
    padding-left: 4px;
    padding-right: 4px;
  }

  &:hover {
    svg {
      border-radius: 4px;
      background: ${ThemeV2.palette.geekBlue1};
    }
  }
`;

export const StyledFeedPostHeaderOption = styled(FeedPostHeaderOption)`
  svg {
    height: 18px;
  }

  button {
    background: transparent;
    padding: 4px 0 0 0;
  }

  &:hover {
    button {
      background: transparent !important;

      svg {
        border-radius: 4px;
        background: ${ThemeV2.palette.geekBlue1};
      }
    }
  }
`;

const userNameEllipsisStyle = css`
  max-width: 200px;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledUserNameBody = styled(Body)`
  ${userNameEllipsisStyle}
`;

export const StyledUserNameClickable = styled(ClickableLink)`
  ${userNameEllipsisStyle}
`;

export const StyledCreatedAt = styled(Body)`
  min-width: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
