import { trackEvent } from '../index';
import {
  ParticipationAnalyticProps,
  RecognitionParticipationAnalyticProps,
} from './types';
import { mapFlowDetailsToParticipationEventProps } from './utils';
import { AnalyticsEvents, PARTICIPATION_EVENT } from '../constants';

export const trackFlowParticipationShowEvent = (
  props: ParticipationAnalyticProps,
) => {
  const mappedProperties = mapFlowDetailsToParticipationEventProps(props);
  trackEvent(PARTICIPATION_EVENT, undefined, {
    ...mappedProperties,
    event: AnalyticsEvents.SHOW,
  });
};

export const trackFlowParticipationActionEvent = (
  props: ParticipationAnalyticProps,
) => {
  const mappedProperties = mapFlowDetailsToParticipationEventProps(props);
  trackEvent(PARTICIPATION_EVENT, undefined, {
    ...mappedProperties,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackFlowParticipationErrorEvent = (
  props: ParticipationAnalyticProps,
) => {
  const mappedProperties = mapFlowDetailsToParticipationEventProps(props);
  trackEvent(PARTICIPATION_EVENT, undefined, {
    ...mappedProperties,
    event: AnalyticsEvents.ERROR,
  });
};

export const trackRecognitionParticipationActionEvent = (
  props: RecognitionParticipationAnalyticProps,
) => {
  trackEvent(PARTICIPATION_EVENT, undefined, {
    ...{ ...props, trigger: 'RECOGNITION' },
    event: AnalyticsEvents.ACTION,
  });
};

export const trackRecognitionParticipationErrorEvent = (
  props: RecognitionParticipationAnalyticProps,
) => {
  trackEvent(PARTICIPATION_EVENT, undefined, {
    ...{ ...props, trigger: 'RECOGNITION' },
    event: AnalyticsEvents.ERROR,
  });
};
