import React, { useState } from 'react';

import { FeedTypes } from '../../../interfaces/Home';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import { FlowsPostHeaderOptionProps } from './interface';
import DeleteModal from './DeleteModal';
import useLayoutStore from '../../../stores/layoutStore';

const FlowsPostHeaderOption = (props: FlowsPostHeaderOptionProps) => {
  const {
    dropdownOptions,
    icon,
    title,
    hasTrophies,
    dropdownToggleButtonSize = ButtonSize.Normal,
    onDeleteModalSubmitClick,
    onModalClose,
    onEditPost,
    onCopyLink,
    onDeletePostSelectedOption,
    onGotoPostClick,
    onNotificationPreferenceClick,
    onPostInteractionSettingsClick,
    onViewAnnouncementsInsightsClick,
    currency,
    feedType = FeedTypes.POST,
    onCreateAnnouncementClick,
    onEditAnnouncementClick,
    onEndAnnouncementClick,
    onDeletePostClick,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isEmbeddedInMainApp } = useLayoutStore();

  const onOptionsSelect = (value: string | number) => {
    if (value === 'share-announcement' && onCreateAnnouncementClick) {
      onCreateAnnouncementClick();
    }

    if (value === 'edit-announcement' && onEditAnnouncementClick) {
      onEditAnnouncementClick();
    }

    if (value === 'end-announcement' && onEndAnnouncementClick) {
      onEndAnnouncementClick();
    }

    if (value === 'edit-post' && onEditPost) {
      onEditPost();
    }

    if (value === 'delete') {
      if (isEmbeddedInMainApp) {
        onDeletePostClick?.();
      } else {
        setIsModalOpen(true);
      }
    }

    if (value === 'copy-link' && onCopyLink) {
      onCopyLink();
    }

    if (value === 'goto-post-link' && onGotoPostClick) {
      onGotoPostClick();
    }

    if (value === 'notifications-preference' && onNotificationPreferenceClick) {
      onNotificationPreferenceClick();
    }

    if (
      value === 'edit-post-interaction-settings' &&
      onPostInteractionSettingsClick
    ) {
      onPostInteractionSettingsClick();
    }

    if (value === 'view-insights' && onViewAnnouncementsInsightsClick) {
      onViewAnnouncementsInsightsClick();
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    if (onModalClose) {
      onModalClose();
    }
  };

  return (
    <>
      <DropdownIconButton
        menuItems={dropdownOptions}
        icon={icon}
        title={title}
        onItemClick={onOptionsSelect}
        size={dropdownToggleButtonSize}
        dataTestId="post-options-button"
        dataQaId="btn-flow-options"
      />
      {isModalOpen && (
        <DeleteModal
          hasTrophies={hasTrophies}
          onDeleteModalSubmitClick={onDeleteModalSubmitClick}
          onModalClose={handleModalClose}
          onDeletePostSelectedOption={onDeletePostSelectedOption}
          feedType={feedType}
          currency={currency}
        />
      )}
    </>
  );
};

const MemoizedFlowsPostHeaderOption = React.memo(FlowsPostHeaderOption);
MemoizedFlowsPostHeaderOption.displayName = 'FlowsPostHeaderOption';
export default FlowsPostHeaderOption;
