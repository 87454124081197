import { useEffect } from 'react';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import {
  PostInteractionSettingsUpdatedPayload,
  PostInteractionSettingsUpdatedSocketProps,
} from './types';
import { updateInteractionSettingsStatusUpdated } from './utils';
import { PageType } from '../useReactionUpdatedSocket/types';
import {
  GET_FLOW_FEED,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_PROFILE_FEED,
} from '../../constants/endpoints';
import { useQueryClient } from '@tanstack/react-query';

const usePostInteractionSettingUpdatedSocket = (
  props: PostInteractionSettingsUpdatedSocketProps,
) => {
  const { pageType, sortValue, filter, profileId } = props;
  const queryClient = useQueryClient();
  const { data: currentUserData } = useProfileInfoFetchQuery();
  useEffect(() => {
    let postInteractionSettingUpdatedHandler: (
      eventName: string,
      socketPayload: PostInteractionSettingsUpdatedPayload,
    ) => void | undefined;
    if (currentUserData) {
      const { assemblyId } = currentUserData.assembly;
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );
      postInteractionSettingUpdatedHandler = (
        eventName: string,
        socketPayload: PostInteractionSettingsUpdatedPayload,
      ) => {
        if (
          eventName === 'RESPONSE_REACTIONS_SETTING' ||
          eventName === 'RESPONSE_REPLIES_SETTING'
        ) {
          switch (pageType) {
            case PageType.SingleFlowFeed: {
              updateInteractionSettingsStatusUpdated({
                queryKey: [
                  GET_FLOW_RESPONSE,
                  socketPayload.flowId || '',
                  socketPayload.responseId || '',
                ],
                queryClient,
                socketPayload,
                eventName,
              });
              break;
            }
            case PageType.FlowFeed: {
              updateInteractionSettingsStatusUpdated({
                queryKey: [
                  GET_FLOW_FEED,
                  sortValue,
                  socketPayload.flowId || '',
                  filter,
                ],
                queryClient,
                socketPayload,
                eventName,
              });
              break;
            }
            case PageType.ProfileFeed: {
              updateInteractionSettingsStatusUpdated({
                queryKey: [GET_PROFILE_FEED, profileId || '', filter],
                queryClient,
                socketPayload,
                eventName,
              });
              break;
            }
            case PageType.MainFeed: {
              updateInteractionSettingsStatusUpdated({
                queryKey: [GET_MAIN_FEED, sortValue, filter],
                queryClient,
                socketPayload,
                eventName,
              });
              break;
            }
            default: {
              return;
            }
          }
        }
      };
      channelAssembly.bind_global(postInteractionSettingUpdatedHandler);
      return () => {
        channelAssembly.unbind_global(postInteractionSettingUpdatedHandler);
      };
    }
  }, [currentUserData, filter, pageType, profileId, queryClient, sortValue]);
};

export default usePostInteractionSettingUpdatedSocket;
