import styled from 'styled-components';

import ThemeV2 from '../../../../theme';
import FileUploadImg from '../../../atoms/SVGIcon/icons/file-upload.svg';
import FileUploadDisabledImg from '../../../atoms/SVGIcon/icons/file-upload-disabled.svg';

export const DisabledDragAndDrop = styled.div`
  max-height: 122px;
  padding: 24px;
  background-color: ${ThemeV2.palette.gray3};
  border-radius: 4px;
  cursor: not-allowed;
`;

export const DisabledDragDropInner = styled.div`
  padding: 0;
  text-align: center;
  &:before {
    display: block;
    content: ' ';
    background-image: url(${FileUploadDisabledImg});
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    margin: 0 auto;
  }
`;

export const StyleFileUploadContainer = styled.div`
  // To overwrite uppy styles
  .uppy-DragDrop-container {
    max-height: 122px;
    padding: 24px;
    border-radius: 4px;
    border: 0.5px dashed ${ThemeV2.palette.geekBlue6};
  }

  .uppy-DragDrop-inner {
    padding: 0;

    &:before {
      display: block;
      content: ' ';
      background-image: url(${FileUploadImg});
      background-size: 24px 24px;
      height: 24px;
      width: 24px;
      margin: 0 auto;
    }
  }

  .uppy-DragDrop-arrow {
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    display: none;
  }

  .uppy-DragDrop-label {
    font-size: 16px;
    line-height: 24px;
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    font-style: normal;
    color: ${ThemeV2.palette.gray8};
    margin-bottom: 0;
  }

  .uppy-DragDrop-note {
    font-size: 14px;
    line-height: 22px;
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    font-style: normal;
    color: ${ThemeV2.palette.gray6};
  }
`;

export const StyledFileCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  gap: 12px;

  // To overwrite uppy style
  > div {
    margin: 0;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 12px;
  background-color: ${ThemeV2.palette.gold2};

  span {
    margin-top: 2px;
  }

  div {
    margin-left: 8px;
  }
`;

export const HiddenInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
`;
