import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

const AvatarBox = styled.div`
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
`;

export const RepliesCountWrapper = styled(Body)`
  cursor: pointer;
`;

export const FlowRepliesBarWrapper = styled(Flex)`
  white-space: nowrap;
`;

export const TimeWrapper = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const AvatarList = styled(Flex)`
  span > div {
    cursor: pointer;
    margin-left: -10px;
    border-radius: 50%;
    border: 1px solid ${ThemeV2.palette.white};
  }
`;

export const EmptyAvatar = styled(AvatarBox)`
  opacity: 0.5;
  width: 30px;
  height: 30px;
  margin-left: -31px;
  border-radius: 50%;
  background-color: ${ThemeV2.palette.gray9};
`;

export const CountAvatar = styled(AvatarBox)`
  z-index: 1;
  transform: none;
  margin-left: -40px;
`;
