import React from 'react';
import styled from 'styled-components';

import Popover from '../Popover';

import ThemeV2 from '../../../theme';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';

import { Flex } from '../../../Utils/styles/display';
import { SAVE } from '../../../languages/en/singleWords';

const SaveButton = styled(Button)`
  height: 40px;
  margin-right: 1px;
  border-radius: 4px 0 0 4px;

  path {
    fill: ${ThemeV2.palette.white};
  }
  :disabled {
    path {
      fill: ${ThemeV2.palette.gray6};
    }
  }
`;

const DropdownButton = styled(Button)`
  height: 40px;
  padding: 16px 8px;
  border-radius: 0 4px 4px 0;

  & span {
    line-height: 16px;
  }
`;

const StyledToggleLabel = styled.label`
  padding: 4px;
  margin-right: 26px;
  margin-bottom: 0;
  cursor: pointer;
`;

const StyledPopoverContent = styled.div`
  display: flex;
  align-items: center;
  min-width: fit-content;
  padding: 4px;
`;

const StyledBody = styled(Body)`
  margin-left: 4px;
`;

export interface FlowBuilderSaveSplitButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  onSaveButtonClick: () => void;
  onRevertChangesButtonClick: () => void;
}

const FlowBuilderSaveSplitButton = ({
  disabled = false,
  onSaveButtonClick,
  isLoading,
  onRevertChangesButtonClick,
}: FlowBuilderSaveSplitButtonProps) => {
  return (
    <Flex>
      <SaveButton
        size="large"
        icon="tick"
        disabled={disabled}
        onClick={onSaveButtonClick}
        isLoading={isLoading}
      >
        {SAVE}
      </SaveButton>
      <Popover
        containerPadding="2px"
        popoverPosition="bottom-end"
        elementPadding="4px"
        referenceContent={
          <DropdownButton dataTestId="splitButtonDropdown" size="large">
            <SVGIcon icon="caret-rounded" size="16px" color="gray1" />
          </DropdownButton>
        }
        popoverContent={
          <StyledPopoverContent>
            <StyledToggleLabel
              htmlFor="isPrivatePost"
              onClick={onRevertChangesButtonClick}
            >
              <SVGIcon icon="undo" color={ThemeV2.palette.red6} rotate={90} />
              <StyledBody color="red6" inline variant="body2">
                Revert Changes
              </StyledBody>
            </StyledToggleLabel>
          </StyledPopoverContent>
        }
      />
    </Flex>
  );
};

export default FlowBuilderSaveSplitButton;
