import styled from 'styled-components';

import ThemeV2 from '../../../theme';

import NotificationDropdown from '../../../atomic/organism/NotificationDropdown';
import useNotificationsButtonController from '../../headers/NotificationsButtonController/useNotificationsButtonController';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';

const NotificationsWrapper = styled.div`
  background: ${ThemeV2.palette.white};
`;

const NotificationsController = () => {
  const {
    isError,
    isLoading,
    isMobileApp,
    notifications,
    isUnreadActive,
    onMarkReadClick,
    hasNextPage,
    handleFetchNextPage,
    handleUnreadClick,
    handleAllMessageClick,
    handleSettingsButtonClick,
    handleNotificationItemClick,
  } = useNotificationsButtonController();

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  return (
    <NotificationsWrapper>
      <NotificationDropdown
        hideCloseIcon
        isError={isError}
        isLoading={isLoading}
        showSettingsButton={isMobileApp}
        notificationData={notifications}
        onMarkReadClick={onMarkReadClick}
        hasNextPage={hasNextPage}
        isUnreadActive={isUnreadActive}
        handleFetchNextPage={handleFetchNextPage}
        onUnreadClick={handleUnreadClick}
        onShowAllClick={handleAllMessageClick}
        onSettingsButtonClick={handleSettingsButtonClick}
        handleNotificationItemClick={handleNotificationItemClick}
      />
    </NotificationsWrapper>
  );
};

export default NotificationsController;
