import React, { useCallback, useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import isEmpty from 'lodash/isEmpty';
import FlowBlockHeader from '../../../atomic/molecules/FlowBlockHeader';

import useToggle from '../../../hooks/useToggle';

import {
  FlowAccordionItemState,
  FlowAccordionItemValidations,
} from '../../../atomic/molecules/FlowAccordionItem/interface';

import FlowAccordionItem from '../../../atomic/molecules/FlowAccordionItem';
import FlowsEditor from '../../../atomic/organism/FlowsBuilder/FlowsEditor';

import { BLOCK, NEXT } from '../../../languages/en/singleWords';
import {
  BUILDER_CONTENT_BLOCK,
  LEARN_MORE_HELPER_LINK,
} from '../../../languages/en/flows/builder';

import {
  Border,
  FlowAccordionItemContainer,
  FlowBuilderBlockContainer,
  StyledNextButton,
} from '../FlowsBuilderController/styles';

import { FlowsBuilderContentControllerProps } from './types';
import useFlowBuilderContentController from './useFlowBuilderContentController';

const FlowsBuilderContentController = ({
  id,
  index,
  hasError,
  isTouched,
  isUnlocked,
  isExpanded,
  goToNextBlock,
  onEditButtonClick,
  blockData,
  setSpecificBlockData,
  setShowDataChangeConfirm,
}: FlowsBuilderContentControllerProps) => {
  const { models: contentToggleState, operations: contentToggleOperations } =
    useToggle(false);

  const { models, operations } = useFlowBuilderContentController({
    blockData,
    isExpanded,
    setSpecificBlockData,
    setShowDataChangeConfirm,
  });

  const [contentStatus, setContentStatus] = useState(
    FlowAccordionItemValidations.None,
  );

  const { blockStatus, blocks, canShowGivePointsStack } = models;
  const {
    setBlockStatus,
    handleOnMoveUpClick,
    handleOnDeleteClick,
    handleAddBlockClick,
    handleOnMoveDownClick,
    handleBlockValueChange,
  } = operations;

  const subtitleContent = useMemo(() => {
    return blocks.length > 0 ? `${pluralize(BLOCK, blocks.length, true)}` : '';
  }, [blocks.length]);

  useEffect(() => {
    if (isExpanded) {
      setBlockStatus(FlowAccordionItemState.Focus);
      contentToggleOperations.setToggleToTrue();
    } else if (isUnlocked) {
      setBlockStatus(FlowAccordionItemState.Blur);
      contentToggleOperations.setToggleToFalse();
    } else {
      setBlockStatus(FlowAccordionItemState.Disabled);
    }
    // Note: Adding "contentToggleOperations" in the dependency will cause block behavior issues
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUnlocked, isExpanded, setBlockStatus]);

  useEffect(() => {
    const optionBlocksWithError = blocks.filter((block) => {
      if (block.type === 'DROPDOWN' || block.type === 'MULTI_CHOICE')
        return block?.options.length < 1;
      return false;
    });
    if (blocks.length > 0 && !hasError) {
      setContentStatus(FlowAccordionItemValidations.Success);
    } else if ((hasError && isTouched) || !isEmpty(optionBlocksWithError)) {
      setContentStatus(FlowAccordionItemValidations.Error);
    } else {
      setContentStatus(FlowAccordionItemValidations.None);
    }
  }, [blocks, hasError, isTouched]);

  const handleOnNextButtonClick = useCallback(() => {
    contentToggleOperations.setToggleToFalse();
    goToNextBlock();
  }, [goToNextBlock, contentToggleOperations]);

  return (
    <FlowBuilderBlockContainer blockStatus={blockStatus} id={id}>
      <FlowBlockHeader
        title={BUILDER_CONTENT_BLOCK.TITLE_TEXT}
        icon="content"
        dataTestId="contentBlockHeader"
        onEditButtonClick={() => {
          onEditButtonClick(index);
          contentToggleOperations.setToggleValue();
        }}
        onLearnMoreButtonClick={() => {
          window.open(LEARN_MORE_HELPER_LINK, '_blank', 'noreferrer, noopener');
        }}
        state={blockStatus}
      />
      <FlowAccordionItem
        icon="content"
        title={BUILDER_CONTENT_BLOCK.ADD_CONTENT_ACCORDION_ITEM_TEXT}
        subtitle={contentToggleState.toggleValue ? '' : subtitleContent}
        dataTestId="addContentAccordionItem"
        validation={contentStatus}
        state={blockStatus}
        isOpen={contentToggleState.toggleValue}
        onAccordionItemClick={() => {
          contentToggleOperations.setToggleValue();
          onEditButtonClick(index);
        }}
      >
        <FlowAccordionItemContainer>
          <FlowsEditor
            blocks={blocks}
            onMoveUpClick={handleOnMoveUpClick}
            onDeleteClick={handleOnDeleteClick}
            onAddBlockClick={handleAddBlockClick}
            onMoveDownClick={handleOnMoveDownClick}
            onBlockValueChange={handleBlockValueChange}
            canShowGivePointsStack={canShowGivePointsStack}
          />
        </FlowAccordionItemContainer>
      </FlowAccordionItem>

      {contentToggleState.toggleValue &&
        blockStatus === FlowAccordionItemState.Focus && (
          <Border>
            <StyledNextButton
              variant="outlined"
              onClick={handleOnNextButtonClick}
              disabled={!blocks.length}
            >
              {NEXT}
            </StyledNextButton>
          </Border>
        )}
    </FlowBuilderBlockContainer>
  );
};

const MemoizedFlowsBuilderContentController = React.memo(
  FlowsBuilderContentController,
);

MemoizedFlowsBuilderContentController.displayName =
  'FlowsBuilderContentController';

export default MemoizedFlowsBuilderContentController;
