import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GET_NOTIFICATIONS } from '../../constants/endpoints';
import { pusher } from '../../pusher/pusher-base';
import { useProfileInfoFetchQuery } from '../../queries/Profile';

const useNotificationSocket = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();
  useEffect(() => {
    if (data) {
      const { memberId } = data.member;
      const channelMember = pusher.subscribe(`private-member-${memberId}`);
      // eslint-disable-next-line no-unused-vars
      channelMember.bind('NEW_NOTIFICATION', () => {
        queryClient.refetchQueries([GET_NOTIFICATIONS, '']);
        queryClient.refetchQueries([GET_NOTIFICATIONS, 'unread']);
      });
    }
  }, [data, queryClient]);
};

export default useNotificationSocket;
