import React, { useEffect, useRef } from 'react';

import '../styles/docx.scss';
import Loading from '../loading';

const DocxViewer = (props: { filePath: string | URL }) => {
  const docxRef = useRef(null);
  useEffect(() => {
    (async function () {
      const jsonFile = new XMLHttpRequest();
      const mammoth = await import('mammoth/mammoth.browser');
      jsonFile.open('GET', props.filePath, true);
      jsonFile.send();
      jsonFile.responseType = 'arraybuffer';
      jsonFile.onreadystatechange = () => {
        if (jsonFile.readyState === 4 && jsonFile.status === 200) {
          const options = {
            styleMap: ["br[type='page'] => div.page:fresh"],
          };
          mammoth
            .convertToHtml(
              { arrayBuffer: jsonFile.response },
              { includeDefaultStyleMap: true },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              options,
            )
            .then((result) => {
              const docEl = document.createElement('div');
              docEl.className = 'document-container';
              docEl.innerHTML = result.value;
              // document.getElementById('docx').innerHTML = docEl.outerHTML;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              if (docxRef && docxRef.current && docxRef.current.innerHTML) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                docxRef.current.innerHTML = docEl.outerHTML;
              }
            })
            .catch((e) => {
              console.log('DocX Failed to Load', e);
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            .done();
        }
      };
    })();
  });

  return (
    <div ref={docxRef} className="docx-wrapper">
      <Loading />
    </div>
  );
};

export default DocxViewer;
