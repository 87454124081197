import { useCallback, useContext, useMemo, useState } from 'react';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import { EmojiData } from 'emoji-mart';
import {
  DeletePostClickArgs,
  ResponseDetailsPayload,
  FeedItemMetaData,
  FeedProps,
  MemberInteractionType,
  Reaction,
} from '../../../../interfaces/Feed';
import { PostTypes } from '../../../../interfaces/Home';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import {
  useFetchFlowFileQuery,
  useFetchPreviewFileQuery,
} from '../../../../queries/Flows/Feed';
import {
  CARD_ACTION_EVENTS,
  POST_ANALYTICS_EVENTS,
} from '../../../../Utils/analytics/constants';
import useGetPageName from '../../../../hooks/useGetPageName';
import { trackPostEvent as trackPostEventMetric } from '../../../../Utils/analytics';
import { DownloadBlockType } from '../../../../atomic/organism/FlowPostBody/types';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';
import {
  checkIfFlowPostHasTrophies,
  getCurrencyFromProfileData,
  getFlowPostUrl,
} from '../../../../Utils/home/feeds';
import { mapResponseDataToFeedProps } from '../../../../Utils/home/feeds/response';
import { showErrorMessage, showSuccessMessage } from '../../../../Utils/toast';
import { FeedContext } from '../../../../Utils/flows/feeds';
import {
  Announcement,
  FlowFeedResponse,
} from '../../../../queries/Flows/Feed/interfaces';
import { useHistory } from 'react-router';
import useMultiAssemblySlug from '../../../../hooks/useMultiAssemblySlug';
import { trackPostActionEvent } from '../../../../Utils/analytics/post';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { useUpdateFlowResponseNotificationPreference } from '../../../../queries/Flows/Dashboard';
import { copyToClipboard } from '../../../../Utils/common';
import useLayoutStore from '../../../../stores/layoutStore';
import { trackCardActionEvent } from '../../../../Utils/analytics/discover';
import { supportedPdfPreviewFileTypes } from '../../../../constants/files';

type UseFlowResponseControllerProps = {
  profileData?: GetProfileInfoResponse;
  response: FlowFeedResponse;
  showEditOption: boolean;
  activeAnnouncement?: Announcement;
};
const useFlowResponseController = (props: UseFlowResponseControllerProps) => {
  const { slugUrl } = useMultiAssemblySlug();

  const history = useHistory();
  const { profileData, response, showEditOption } = props;
  const { page } = useGetPageName();
  const {
    onReactionSet,
    onReactionUnSet,
    onMemberClick,
    onDeletePost,
    onFlowClick,
    onDownloadFileClick,
  } = useContext(FeedContext);

  const newTrackPostEvent = useCallback(
    (action: string) => {
      const postEventProps = {
        feedViewed: page,
        numPostComments: response.commentsCount,
        responseId: response.responseId || null,
        numPostReactions: response.reactions.length || 0,
        postId: null,
        flowId: response.flow.flowId || PostTypes.RECOGNITION.toString(),
        flowName: response.flow.name,
      };

      trackPostEventMetric('post.events', {
        ...postEventProps,
        event: 'action',
        action,
      });
    },
    [page, response],
  );

  const [deletePostCarrotOption, setDeletePostCarrotOption] =
    useState<string>();

  const [filePreviewFile, setFilePreviewFile] = useState<
    DownloadBlockType | undefined
  >(undefined);

  const {
    responseId,
    flowId,
    instanceId,
    hasTrophies,
    showDeleteOption,
    isNotificationMuted,
    currency,
  } = useMemo(() => {
    return {
      instanceId: response?.instanceId,
      responseId: response.responseId,
      flowId: response.flow.flowId,
      postType: PostTypes.FLOW,
      hasTrophies: response && checkIfFlowPostHasTrophies(response),
      commentsCount: response?.commentsCount || 0,
      trophyReceivers: [],
      showDeleteOption: response?.canDelete,
      isNotificationMuted: response?.isMuted || false,
      currency: getCurrencyFromProfileData(
        profileData as GetProfileInfoResponse,
      ),
    };
  }, [response]);

  const fileRequestSubstitutions = {
    responseId: filePreviewFile ? responseId : '',
    blockId: filePreviewFile ? filePreviewFile.id : '',
    instanceId: instanceId ? instanceId : '',
    fileName: filePreviewFile ? filePreviewFile.fileName : '',
    flowId: flowId ? flowId : '',
  };

  const shouldFetchDownloadFileInfo = filePreviewFile !== undefined;

  const {
    data: fileToDownload,
    error: fileDownloadError,
    refetch: refetchFileDownloadLink,
  } = useFetchFlowFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo,
  );

  const { treatment: convertedPdfPreviewsTreatment } = useFeatureSplit(
    SplitNames.CONVERTED_PDF_PREVIEWS,
  );

  const isConvertedPdfPreviewsEnabled =
    convertedPdfPreviewsTreatment === TreatmentTypes.ON;

  const {
    data: fileToPreview,
    error: filePreviewError,
    isLoading: isFilePreviewLoading,
    refetch: refetchFilePreviewLink,
  } = useFetchPreviewFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo &&
      isConvertedPdfPreviewsEnabled &&
      !!filePreviewFile?.type &&
      supportedPdfPreviewFileTypes.includes(filePreviewFile.type),
  );

  const handleSetFilePreview = useCallback(
    (value: DownloadBlockType | undefined) => setFilePreviewFile(value),
    [setFilePreviewFile],
  );

  const handleDeletePostOptionChange = useCallback(
    (value: string) => {
      setDeletePostCarrotOption(value);
    },
    [setDeletePostCarrotOption],
  );

  const handleDeleteResponse = useCallback(
    (obj: DeletePostClickArgs) => onDeletePost(obj, deletePostCarrotOption),
    [deletePostCarrotOption, onDeletePost],
  );

  const handleMemberClick = useCallback(
    (member: MemberInteractionType) => {
      newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
      onMemberClick(member);
    },
    [newTrackPostEvent, onMemberClick],
  );

  const handleReactionSet = useCallback(
    (
      emoji: EmojiData,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
      reactions: Reaction[],
    ) => {
      onReactionSet(emoji, contentId, feedItemMetaData, reactions);

      trackPostActionEvent({
        flowId: flowId,
        reaction: emoji.name,
        responseId: responseId,
        type: feedItemMetaData.type,
        postId: feedItemMetaData.postId,
        action: POST_ANALYTICS_EVENTS.POST_REACTION_ADDED,
        flowName: response.flow.name || PostTypes.RECOGNITION,
      });
    },
    [onReactionSet, flowId, responseId, response.flow.name],
  );

  const handleReactionUnSet = useCallback(
    (
      reaction: Reaction,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      onReactionUnSet(reaction, contentId, feedItemMetaData);

      trackPostActionEvent({
        flowId: flowId,
        responseId: responseId,
        type: feedItemMetaData.type,
        postId: feedItemMetaData.postId,
        reaction: reaction?.reaction?.name,
        action: POST_ANALYTICS_EVENTS.POST_REACTION_REMOVED,
        flowName: response.flow.name || PostTypes.RECOGNITION,
      });
    },
    [onReactionUnSet, flowId, responseId, response.flow.name],
  );

  const handleFlowClick = useCallback(
    (flowID: string) => {
      onFlowClick(flowID);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.FLOW_CLICKED);
    },
    [newTrackPostEvent, onFlowClick],
  );

  const onSeeFullPostClick = useCallback(() => {
    newTrackPostEvent(POST_ANALYTICS_EVENTS.SEE_FULL_POST_CLICK);
  }, [newTrackPostEvent]);

  const handleDownloadFileClick = useCallback(
    async (fileDownload: DownloadBlockType) => {
      if (flowId) {
        onDownloadFileClick(fileDownload, instanceId || '', flowId);
        newTrackPostEvent(POST_ANALYTICS_EVENTS.FILE_DOWNLOADED);
      }
    },
    [flowId, onDownloadFileClick, newTrackPostEvent, instanceId],
  );

  const onCopyLinkClick = useCallback(() => {
    if (response) {
      if (response.flow.flowId && response.responseId) {
        const url = getFlowPostUrl(
          response.flow.flowId,
          response.responseId,
          slugUrl,
        );

        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    }

    trackPostActionEvent({
      type: PostTypes.FLOW,
      flowId: response?.flow.flowId,
      responseId: response?.responseId,
      action: POST_ANALYTICS_EVENTS.LINK_COPIED,
      flowName: response?.flow.name || PostTypes.RECOGNITION,
    });
  }, [response, slugUrl]);

  const onGotoPostLinkClick = useCallback(() => {
    if (response) {
      if (response.flow.flowId && response.responseId) {
        history.push(
          `/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`,
        );

        trackPostActionEvent({
          type: PostTypes.FLOW,
          flowName: response.flow.name,
          flowId: response.flow.flowId,
          responseId: response.responseId,
          action: POST_ANALYTICS_EVENTS.GO_TO_POST_CLICKED,
        });
      }
    }
  }, [flowId, history, response, responseId]);

  const { mutate: updateFlowResponseNotificationPreference } =
    useUpdateFlowResponseNotificationPreference(flowId, responseId);

  const onNotificationPreferenceClick = () => {
    trackPostActionEvent({
      action: response.isMuted
        ? POST_ANALYTICS_EVENTS.FOLLOW_POST_CLICKED
        : POST_ANALYTICS_EVENTS.UN_FOLLOW_POST_CLICKED,
    });
    if (response) {
      updateFlowResponseNotificationPreference(
        {
          type: response.isMuted ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
        },
        {
          onSuccess: () => {
            showSuccessMessage(
              `You successfully ${
                response.isMuted ? 'followed' : 'unfollowed'
              } ${response.respondent?.firstName || 'Someone'}'s post.`,
            );
          },
          onError: () => {
            showErrorMessage(
              `Unable to ${
                response.isMuted ? 'follow' : 'unfollow'
              } this post, please try again.`,
            );
          },
        },
      );
    }
  };

  const onDeleteModalSubmitClick = useCallback(() => {
    handleDeleteResponse({
      currentPostId: '',
      currentPostType: PostTypes.FLOW,
      hasTrophies: checkIfFlowPostHasTrophies(response),
      currentFlowId: response.flow.flowId,
      currentResponseId: response.responseId,
    });
  }, [handleDeleteResponse, response]);

  const feedProps: FeedProps | undefined = useMemo(() => {
    if (!response || !profileData) {
      return undefined;
    }

    return mapResponseDataToFeedProps({
      response,
      profileData,
      onMemberClick: handleMemberClick,
      isEditEnabled: showEditOption,
    });
  }, [response, profileData, handleMemberClick, showEditOption]);

  // Edit Post Interaction Settings
  const { isEmbeddedInMainApp } = useLayoutStore();
  const handlePostInteractionSettingsClick = () => {
    if (isEmbeddedInMainApp) {
      const responseDetailsPayload: ResponseDetailsPayload = {
        author: response.respondent?.name || '',
        flowName: response.flow.name,
        emoticon: response.flow.icon.value,
        allowReactions: !response.hideReactions,
        allowReplies: !response.hideReplies,
        responseId: response.responseId,
        flowId: response.flow.flowId,
      };
      trackCardActionEvent({
        action: CARD_ACTION_EVENTS.EDIT_POST_INTERACTIONS_CLICKED,
        isReactionsDisabled: response.hideReactions ?? false,
        isRepliesDisabled: response.hideReplies ?? false,
      });
      window.parent.postMessage(
        {
          type: 'OPEN_CONFIGURE_POST_INTERACTION_SETTINGS_MODAL',
          payload: responseDetailsPayload,
        },
        '*',
      );
    }
  };

  const handleViewAnnouncementInsightsClick = () => {
    if (isEmbeddedInMainApp) {
      const viewAnnouncementsInsightsPayload = {
        activeAnnouncement: props.activeAnnouncement,
      };
      window.parent.postMessage(
        {
          type: 'OPEN_ANNOUNCEMENT_INSIGHTS_MODAL',
          payload: viewAnnouncementsInsightsPayload,
        },
        '*',
      );
    }
  };

  const handleDeletePostClick = () => {
    window.parent.postMessage(
      {
        type: 'OPEN_DELETE_POST_MODAL',
        payload: {
          flowId,
          responseId,
          replyId: null,
          hasTrophies,
        },
      },
      '*',
    );
  };

  return {
    hasTrophies,
    showDeleteOption,
    showEditOption,
    notificationPreference: { isNotificationMuted },
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    isConvertedPdfPreviewsEnabled,
    handleSetFilePreview,
    onFlowClick: handleFlowClick,
    onCopyLinkClick,
    handleDeletePostOptionChange,
    handleMemberClick,
    onDownloadFileClick: handleDownloadFileClick,
    onSeeFullPostClick,
    handleReactionSet,
    handleReactionUnSet,
    onDeleteModalSubmitClick,
    feedProps,
    onGotoPostLinkClick,
    onNotificationPreferenceClick,
    currency: currency?.assemblyCurrency,
    handlePostInteractionSettingsClick,
    handleViewAnnouncementInsightsClick,
    handleDeletePostClick,
  };
};

export default useFlowResponseController;
