import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import { FeedItemFromAPI, PostSortOptions } from '../../../../interfaces/Feed';
import useToggle from '../../../../hooks/useToggle';
import { FEED_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';
import useFlowFeed from '../../../../hooks/useFlowFeed';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import { FLOWS, RECOGNITION_PARTICIPATE } from '../../../../constants/routes';
import { useGetFeedsForRecognition } from '../useSearchOrGetFeedData';
import {
  getSearchPayload,
  isFeedSearchEmpty,
} from '../../../../queries/Feed/utils';
import {
  defaultValues,
  UseSearchDropDownValues,
} from '../../../../atomic/organism/SearchDropdown';
import { getCanCurrentUserGiveRecognition } from '../../../../queries/Profile/utils';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../../../interfaces/PostMessage';
import useReactionUpdatedSocket from '../../../../hooks/useReactionUpdatedSocket';
import { PageType } from '../../../../hooks/useReactionUpdatedSocket/types';
import { useReactionsSocket } from '../../../../hooks/useReactionsSocket';
import { useRepliesUpdatedSocket } from '../../../../hooks/useRepliesUpdatedSocket';

export const useRecognitionController = () => {
  const location = useLocation();
  const history = useHistoryWrapper();
  const parsedParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const filter = location?.pathname?.includes('/fullscreen')
    ? { tvMode: true }
    : undefined;

  const [searchFeedValue, setSearchFeedValue] =
    useState<UseSearchDropDownValues>({ ...defaultValues });

  const [searchPayload, showSearchResults] = useMemo(() => {
    if (isFeedSearchEmpty(searchFeedValue)) {
      return [{}, false];
    }
    return [getSearchPayload(searchFeedValue), true];
  }, [searchFeedValue]);

  /**
   * TODO: Refactor the useGetFeedsForRecognition function to remove use of PostSortOptions.DESC completely. From UI
   * we already removed the sort order dropdown.
   */
  const {
    data,
    feedDataError,
    isRecognitionFeedLoading,
    fetchNextPost,
    hasMorePosts,
    isFetchingMoreFeeds,
    mutatePostReaction,
    refetch,
  } = useGetFeedsForRecognition(
    searchPayload,
    PostSortOptions.DESC,
    showSearchResults,
    filter,
  );

  useReactionsSocket();
  useReactionUpdatedSocket({
    pageType: PageType.Recognition,
    sortValue: PostSortOptions.DESC,
  });
  useRepliesUpdatedSocket({});

  const {
    feedContextProps,
    onFlowClick,
    isProfileInfoLoading,
    profileData,
    setShowLoadMoreButtonToFalse,
    setShowLoadMoreButtonToTrue,
    showLoadMoreButton,
  } = useFlowFeed({ mutatePostReaction });

  const trackFeedEvent = useCallback((action: FeedEventNameType) => {
    trackFeedEventMetric(action, { feedSort: PostSortOptions.DESC });
  }, []);

  const openRecognitionFlowModalOnLoad =
    parsedParams && parsedParams?.openRecognitionFlowModalOnLoad === 'true';

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const onSearchChange = useCallback(
    (values: UseSearchDropDownValues) => {
      setSearchFeedValue(values);
    },
    [setSearchFeedValue],
  );

  const handleCelebrateTeammateClick = useCallback(() => {
    history.push(`${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`);
  }, [history, location.pathname]);

  const handleEditPostClick = useCallback(
    (flow: { postId: string }) => {
      const editPostUrl = `${FLOWS}/recognition/edit/${flow.postId}?redirectUrl=${location.pathname}`;
      history.push(editPostUrl);
    },
    [history, location.pathname],
  );

  const handleFetchNextPage = useCallback(() => {
    fetchNextPost();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextPost, trackFeedEvent]);

  const isLoading = isRecognitionFeedLoading || isProfileInfoLoading;

  const feedProps = useMemo(() => {
    if (!isLoading) {
      postMessageToMobileApp({
        action: AppAction.FeedLoaded,
        payload: {
          type: 'recognition',
        },
      });
    }
    if (!data) {
      return [];
    }
    return data.pages.reduce<FeedItemFromAPI[]>(
      (arr, page) => [...arr, ...page.data],
      [],
    );
  }, [data, isLoading]);
  const isRecognitionFeedSearchResultEmpty =
    showSearchResults && !feedProps.length;

  // Current User Permissions
  const canCurrentUserGiveRecognition = useMemo(
    () => getCanCurrentUserGiveRecognition(profileData),
    [profileData],
  );

  useCommonFeedSocket({
    type: WebSocketFeedTypes.CELEBRATE_TEAMMATE,
    setCelebrateTeammateFeedShowLoadMoreButtonToTrue:
      setShowLoadMoreButtonToTrue,
    onCelebrateATeammatePostDeleted: refetch,
    feedProps,
    refetchRecognitionFeed: refetch,
  });

  useEffect(() => {
    if (feedDataError) {
      postMessageToMobileApp({
        action: AppAction.FeedErrored,
        payload: {
          type: 'recognition',
        },
      });
    }
  }, [feedDataError]);

  useEffect(() => {
    if (openRecognitionFlowModalOnLoad) {
      history.push(
        `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`,
      );
    }
  }, [
    history,
    location.pathname,
    openRecognitionFlowModalOnLoad,
    toggleIsRecognitionFlowModalOpen,
  ]);

  return {
    models: {
      isLoading,
      hasNextPage: hasMorePosts,
      profileData,
      searchFeedValue,
      data: feedProps,
      isFetchingNextPage: isFetchingMoreFeeds,
      showSearchResults,
      showLoadMoreButton,
      isRecognitionFlowModalOpen,
      canCurrentUserGiveRecognition,
      isRecognitionFeedSearchResultEmpty,
    },
    operations: {
      refetch,
      handleFetchNextPage,
      onFlowClick,
      handleCelebrateTeammateClick,
      setShowLoadMoreButtonToFalse,
      toggleIsRecognitionFlowModalOpen,
      onSearchChange,
      feedContextProps,
      handleEditPostClick,
    },
  };
};
