import styled from 'styled-components';

import Image from '../../atoms/Image';
import ClickableLink from '../../molecules/ClickableLink';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';
import { device } from '../../../constants/layout';
import { Flex } from '../../../Utils/styles/display';
import NewTextfield from '../../molecules/NewTextfield';
import { FullWidthStyle } from '../../../Utils/styles/css';
import Body from '../../atoms/Body';
import Link from '../../atoms/Link_V2';

export const VerticalDivider = styled.div`
  width: 1px;
  min-height: 24px;
  margin-right: 8px;
  background: ${ThemeV2.palette.gray4};
`;

export const StyledTextField = styled(NewTextfield)`
  caret-color: ${ThemeV2.palette.geekBlue6};
  .MuiInputBase-root {
    fieldset {
      border-width: 0;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-width: 0;
    }
  }

  .MuiInputAdornment-positionEnd {
    cursor: pointer;
  }
`;

export const FullWidth = styled.div`
  overflow: auto;
  ${FullWidthStyle}
`;

export const StyledFlex = styled(Flex)`
  padding: 16px;
  max-height: unset;
  height: 100%;

  @media ${device.desktop} {
    height: auto;
    max-height: 600px;
  }
`;

export const StyledTextFieldWrapper = styled(Flex)`
  ${FullWidthStyle}
  padding-right: 8px;
  .MuiOutlinedInput-adornedStart {
    padding-left: 10px;
  }
`;

export const StyledFlowMenuItemsWrapper = styled(Flex)`
  margin: 8px 0;
  overflow: hidden;

  flex-direction: column;
  align-items: flex-start;

  ${FullWidthStyle}
`;

export const StyledIconButton = styled(IconButton)`
  & span svg path {
    fill: ${ThemeV2.palette.gray6};
  }
`;

export const FlowMenuItemElementWrapper = styled(Flex)`
  margin-top: 12px;

  flex-direction: column;
  align-items: flex-start;

  ${FullWidthStyle}
`;

export const DividerWrapper = styled(Flex)`
  width: 100%;
  padding-top: 8px;
`;

export const Wrapper = styled(Flex)`
  width: 100%;
  padding: 8px;
`;

export const RightWrapper = styled.div`
  margin-left: 8px;
  max-width: 171px;
`;

export const NoResultsFoundWrapper = styled(Flex)`
  margin-top: 24px;
  width: 100%;
  justify-content: flex-start;
`;

export const StyledSearchImage = styled(Image)`
  width: 103px;
  margin-right: 32px;
  margin-left: 16px;
`;

export const StyledSectionTitle = styled(Body)`
  padding-left: 8px;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 8px;
  font-weight: ${ThemeV2.typography.fontWeightMedium};
`;

export const StyledNoItemsFoundText = styled(Body)`
  margin-top: 8px;
`;

export const StyledAddNewFlow = styled(ClickableLink)`
  font-weight: ${ThemeV2.typography.fontWeightRegular};
`;

export const StyledHelpLink = styled(Body)`
  margin: 16px 0 0 16px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;
