import { useCallback, useMemo, useState } from 'react';
import { EditorProps } from '../../atomic/organism/RichTextEditor';
import useGiveRecognitionFormLogic from '../../atomic/organism/GiveRecognitionForm/useGiveRecognitionFormLogic';
import useAtMentions from '../useAtMentions';
import useOnClickOutside from '../useOnOutsideClick';
import {
  GiveRecognitionFormBottomButtonsBaseProps,
  PostType,
} from '../../atomic/molecules/GiveRecognitionFormBottomButtons/interface';
import { checkForProfanity } from '../../Utils/giveCarrots/validation';
import {
  getBadWordsList,
  getCanCheckForBadWords,
  getCanShowAddTrophies,
  getCurrentUserAvatar,
  getGifRatings,
  getTrophies,
} from '../../queries/Profile/utils';
import {
  COMMENT_CREATION_ERROR_MESSAGE,
  PROFANE_ERROR_MESSAGE_COMMENT,
} from '../../languages/en/giveRecognitionForm';
import { PostInputBaseProps } from '../../atomic/organism/PostInput/interface';
import { PostInputProps } from '../../atomic/organism/PostInput';
import { GiveRecognitionTipsProps } from '../../atomic/molecules/GiveRecognitionTips';
import { AddTrophiesInputProps } from '../../atomic/molecules/AddTrophiesInput';
import { DropdownColor } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import { dismissAllToasts, showErrorMessage } from '../../Utils/toast';
import useCreatePostComment from '../../queries/Feed/useCreatePostComment';
import { PostTypes } from '../../interfaces/Home';
import { FeedCommentFromAPI, FeedPostUserProps } from '../../interfaces/Feed';
import useEditPostComment from '../../queries/Feed/useEditPostComment';
import { trackPostActionEvent } from '../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../Utils/analytics/constants';
import { useCreateChat } from '../../queries/Chats';
import { MENTION_REGEX_GLOBAL } from '../../Utils/text';

const emptyFunction = () => {};

export type UseCommentInputProps = {
  editedComment?: FeedCommentFromAPI | null;
  profileData?: GetProfileInfoResponse;
  postId: string;
  responseId?: string;
  flowId?: string;
  postType: PostTypes;
  trophyReceivers: FeedPostUserProps[];
  onCommentMutated?: () => void;
  chatToMemberId?: string;
};

export const useCommentInput = (props: UseCommentInputProps) => {
  const {
    profileData,
    postId,
    postType,
    flowId,
    responseId,
    editedComment,
    chatToMemberId,
    trophyReceivers,
    onCommentMutated,
  } = props;
  const giveRecognitionTipsProps: GiveRecognitionTipsProps = useMemo(
    () => ({
      isOpen: false,
      onToggleClick: emptyFunction,
      onToggleKeyPress: emptyFunction,
    }),
    [],
  );

  const { models, operations } = useGiveRecognitionFormLogic();

  const {
    message: commentMessage,
    editorState,
    gifUrl,
    hasText,
    trophiesInputValue,
    refElement,
    containerRef,
    isPrivate: isPrivateComment,
    inputErrorMessage,
    selectedMentions,
  } = models;
  const {
    setEditorState,
    onTrophiesInputChange,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onGifRemoveClick,
    onAddMention,
    onEditorFocus,
    onEditorBlur,
    onPrivateMessageClick,
    onAttachmentClick,
    resetInput,
    setInputErrorMessage,
    setEditorFocus,
    setGifUrl,
    setSelectedMentions,
  } = operations;
  const {
    suggestions,
    onMentionsChange,
    isLoading: isMentionsLoading,
  } = useAtMentions();
  const [isCommentEditorFocused, setCommentEditorFocused] = useState(false);
  const [isOutsideClicked, setIsOutsideClicked] = useState(false);

  const onCommentButtonClick = useCallback(() => {
    setIsOutsideClicked(false);
    if (!isOutsideClicked) {
      setCommentEditorFocused(true);
      setEditorFocus();
    }
  }, [isOutsideClicked, setEditorFocus]);

  const onOutsideClickHandler = useCallback(() => {
    if (isCommentEditorFocused) {
      setIsOutsideClicked(true);
      setCommentEditorFocused(false);
    }
  }, [isCommentEditorFocused]);
  useOnClickOutside(containerRef, onOutsideClickHandler);

  const addTrophies: AddTrophiesInputProps = useMemo(
    () =>
      getTrophies(
        trophiesInputValue,
        trophyReceivers.filter(
          ({ memberID }) => profileData?.member.memberId !== memberID,
        ).length,
        onTrophiesInputChange,
        profileData,
        DropdownColor.Primary,
      ),
    [onTrophiesInputChange, profileData, trophiesInputValue, trophyReceivers],
  );

  const showAddTrophy = useMemo(() => {
    if (postType === PostTypes.FLOW) {
      return false;
    }
    const canShowAddTrophies = getCanShowAddTrophies(profileData);
    const isCurrentUserOnlyReceiver =
      trophyReceivers.length === 1
        ? trophyReceivers[0].memberID === profileData?.member.memberId
        : false;
    return canShowAddTrophies && !isCurrentUserOnlyReceiver;
  }, [profileData, trophyReceivers, postType]);

  const handleEditorFocus = useCallback(() => {
    onEditorFocus();
    setCommentEditorFocused(true);
  }, [onEditorFocus]);

  const handleCreatePostCommentSuccess = useCallback(() => {
    const element = document.getElementById(`side-drawer`);
    if (element) {
      element.scroll({ top: element.scrollHeight, behavior: 'smooth' });
    }

    resetInput();
    trackPostActionEvent({
      type: postType,
      flowId: flowId,
      responseId: responseId,
      action: POST_ANALYTICS_EVENTS.REPLY_EXITED,
      messageType: postType === PostTypes.CHAT ? 'directMessages' : 'replies',
    });
  }, [flowId, postType, resetInput, responseId]);

  const handleCreatePostCommentError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(COMMENT_CREATION_ERROR_MESSAGE);
  }, []);

  const { mutate: createPostComment, isLoading: isCreatingComment } =
    useCreatePostComment(
      handleCreatePostCommentSuccess,
      handleCreatePostCommentError,
    );

  const { mutate: editReplyComment } = useEditPostComment();
  const { mutate: createChat } = useCreateChat();

  const handlePostClick = useCallback(() => {
    if (
      getCanCheckForBadWords(profileData) &&
      checkForProfanity(commentMessage, getBadWordsList(profileData))
    ) {
      showErrorMessage(PROFANE_ERROR_MESSAGE_COMMENT);
    } else {
      setInputErrorMessage(undefined);
      if (editedComment?.isEditing) {
        if (commentMessage.trim() === editedComment?.message.trim()) {
          editedComment.isEditing = false;
          resetInput();
          return;
        }

        editReplyComment({
          gifUrl: gifUrl,
          postId: postId,
          flowId: flowId || '',
          text: commentMessage,
          responseId: responseId || '',
          replyId: editedComment.commentID,
          mentions: selectedMentions.map(({ id }) => id),
          taggedUsers: selectedMentions,
        });

        trackPostActionEvent({
          type: postType,
          flowId: flowId,
          responseId: responseId,
          messageType: 'replies',
          replyId: editedComment.commentID,
          action: POST_ANALYTICS_EVENTS.REPLY_EDITED,
        });
      } else {
        if (postType === PostTypes.FLOW) {
          createPostComment({
            postId,
            text: commentMessage,
            points: trophiesInputValue > 0 ? trophiesInputValue : undefined,
            gifUrl,
            selectedMentions,
            postType,
            flowId,
            responseId,
          });
        } else if (postType === PostTypes.CHAT && profileData) {
          const getMentionedUsers = (message: string) => {
            const response = message.match(MENTION_REGEX_GLOBAL);
            return !response
              ? []
              : response.map((mention) => mention.slice(3, -3));
          };

          if (gifUrl || commentMessage.trim().length > 0) {
            createChat({
              gifUrl,
              fromMember: {
                ...profileData.member.profile,
                memberID: profileData.member.memberId,
              },
              memberId: chatToMemberId || '',
              toMemberId: chatToMemberId || '',
              selectedMentions:
                selectedMentions.length > 0
                  ? selectedMentions.filter(
                      (mention) =>
                        commentMessage &&
                        getMentionedUsers(commentMessage).includes(mention.id),
                    )
                  : [],
              message: commentMessage,
              taggedUsers: selectedMentions,
            });
          }
        } else {
          createPostComment({
            postId,
            text: commentMessage,
            points: trophiesInputValue > 0 ? trophiesInputValue : undefined,
            gifUrl,
            selectedMentions,
          });
        }

        trackPostActionEvent({
          type: postType,
          flowId: flowId,
          responseId: responseId,
          action: POST_ANALYTICS_EVENTS.REPLY_POSTED,
          messageType:
            postType === PostTypes.CHAT ? 'directMessages' : 'replies',
        });

        if (onCommentMutated) {
          setTimeout(() => {
            onCommentMutated();
          });
        }
      }

      resetInput();
    }
  }, [
    profileData,
    commentMessage,
    setInputErrorMessage,
    editedComment,
    resetInput,
    editReplyComment,
    gifUrl,
    postId,
    flowId,
    responseId,
    selectedMentions,
    postType,
    onCommentMutated,
    createPostComment,
    trophiesInputValue,
    createChat,
    chatToMemberId,
  ]);

  const postInputBaseProps: PostInputBaseProps = useMemo(
    () => ({
      containerRef,
      currentUser: getCurrentUserAvatar(profileData),
      hasText, // && trophiesInputValue !== AddTrophiesValue.Input,
      isEditorFocused: isCommentEditorFocused,
      showRecognitionTips: false,
      selectedGif: gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    }),
    [
      containerRef,
      profileData,
      hasText,
      isCommentEditorFocused,
      gifUrl,
      inputErrorMessage,
      onGifRemoveClick,
      setEditorFocus,
    ],
  );

  const giveRecognitionFormBottomButtonsProps: GiveRecognitionFormBottomButtonsBaseProps =
    useMemo(
      () => ({
        postType: PostType.Comment,
        onAtMentionClick,
        onEmoticonClick,
        onGifSelectorClick,
        onAttachmentClick,
        isGifSelected: gifUrl !== '',
        isPrivateMessageSelected: isPrivateComment,
        onPrivateMessageClick,
        onPostClick: handlePostClick,
        showAddTrophy,
        isCreatingPostOrComment: isCreatingComment,
        isAttachmentButtonHidden: true,
        isPrivatePostButtonHidden: true,
        gifRatings: getGifRatings(profileData),
      }),
      [
        onAtMentionClick,
        onEmoticonClick,
        onGifSelectorClick,
        onAttachmentClick,
        gifUrl,
        isPrivateComment,
        onPrivateMessageClick,
        handlePostClick,
        profileData,
        isCreatingComment,
        showAddTrophy,
      ],
    );

  const editorProps: EditorProps = useMemo(
    () => ({
      editorState,
      editorRefElement: refElement,
      onAddMention,
      onEditorBlur,
      onEditorChange: setEditorState,
      onEditorFocus: handleEditorFocus,
      suggestions,
      onSearchChange: onMentionsChange,
      isMentionsLoading,
    }),
    [
      editorState,
      refElement,
      onAddMention,
      onEditorBlur,
      setEditorState,
      handleEditorFocus,
      suggestions,
      onMentionsChange,
      isMentionsLoading,
    ],
  );
  const commentInputProps: PostInputProps = useMemo(
    () => ({
      ...giveRecognitionTipsProps,
      ...addTrophies,
      ...editorProps,
      ...giveRecognitionFormBottomButtonsProps,
      ...postInputBaseProps,
      onCommentButtonClick,
      setGifUrl,
      setSelectedMentions,
      setEditorState,
    }),
    [
      setGifUrl,
      setSelectedMentions,
      giveRecognitionTipsProps,
      addTrophies,
      editorProps,
      giveRecognitionFormBottomButtonsProps,
      postInputBaseProps,
      onCommentButtonClick,
      setEditorState,
    ],
  );
  return {
    commentInputProps,
  };
};
// Following are not needed as they are for give recognition tips and are not applicable for comments
