import { YOU } from '../languages/en/home';

import {
  Profile,
  AllowanceType,
  UserStatus,
  MemberRole,
  QuickSetupData,
  MemberState,
} from '../interfaces/user';

import { capitalizeFirstLetter } from './text';
import { FeedPostUserProps } from '../interfaces/Feed';
import { CurrentUser } from '../interfaces/currentUser';
import { IMemberDTO } from '../interfaces/member';
import isEmpty from 'lodash/isEmpty';

interface DisplayUserNameParamType {
  user: FeedPostUserProps;
  currentUserId: string;
  showOnlyFirstName?: boolean | undefined;
}

interface SerializeUserProps {
  profile?: {
    firstName: string;
    lastName: string;
  };
  firstName?: string;
  lastName?: string;
  id?: string | number;
}

export const serializeUser = (user: SerializeUserProps) => {
  const { profile } = user;
  if (profile) {
    return {
      ...user,
      profile: {
        ...profile,
        firstName: capitalizeFirstLetter(profile.firstName),
        lastName: capitalizeFirstLetter(profile.lastName),
      },
    };
  }
  return {
    ...user,
    firstName: capitalizeFirstLetter(user.firstName),
    lastName: capitalizeFirstLetter(user.lastName),
  };
};

export const getFullName = (
  profile: Profile | FeedPostUserProps | null,
): string => {
  if (!profile) {
    return '';
  }
  return isEmpty(profile?.firstName) && isEmpty(profile?.lastName)
    ? ''
    : `${profile?.firstName} ${profile?.lastName}`;
};

export const getProfileFullName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => {
  return `${firstName} ${lastName}`;
};

const getIsCurrentUser = (userId: string, currentUserId: string) => {
  return currentUserId === userId;
};

export const isUserAdmin = ({ role }: { role: MemberRole[] }) =>
  role.some((_role) => {
    return _role === MemberRole.Admin || _role === MemberRole.Owner;
  });

export const isUserOwner = ({ role }: { role: MemberRole[] }) =>
  role.some((_role) => {
    return _role === MemberRole.Owner;
  });

export const isUserManager = (user: CurrentUser) => user.profile.isManager;

export const getDisplayUserName = (param: DisplayUserNameParamType) => {
  const { user, currentUserId, showOnlyFirstName = false } = param;
  const isCurrentUser = getIsCurrentUser(user.memberID, currentUserId);
  if (isCurrentUser) {
    return YOU;
  }

  if (showOnlyFirstName) {
    return user.firstName;
  }

  return `${user.firstName} ${user.lastName}`;
};

export const canCurrentUserGiveAllowance = (currentUser: CurrentUser) => {
  const { allowance } = currentUser;
  const hasCustomAllowanceOf0 =
    allowance.type === AllowanceType.CUSTOM && allowance.points === 0;
  const hasDefaultAllowanceOf0 =
    AllowanceType.REGULAR && allowance.points === 0;
  const hasNoAllowance =
    currentUser.allowance.type === AllowanceType.NO_ALLOWANCE;
  const isObserver = currentUser.status === UserStatus.OBSERVER;
  const isReceiver = currentUser.status === UserStatus.RECEIVER;

  if (
    hasCustomAllowanceOf0 ||
    hasNoAllowance ||
    isObserver ||
    isReceiver ||
    hasDefaultAllowanceOf0
  ) {
    return false;
  }
  return true;
};

export const canCurrentUserGiveRecognition = (currentUser: CurrentUser) => {
  if (
    currentUser.status === UserStatus.OBSERVER ||
    currentUser.status === UserStatus.RECEIVER
  ) {
    return false;
  }
  return true;
};

export const canMemberReceiveAllowance = (member: IMemberDTO) => {
  if (
    member.status === UserStatus.OBSERVER ||
    member.status === UserStatus.GIVER
  ) {
    return false;
  }
  return true;
};

export const getQuickSetupProgress = (
  quickSetupData: QuickSetupData,
  isPremiumUser: boolean,
) => {
  let value = 1;
  if (quickSetupData?.givenFirstRecognition) {
    value += 1;
  }
  if (quickSetupData?.isRewardsSet) {
    value += 1;
  }
  if (quickSetupData?.isIntegrationConnected) {
    value += 1;
  }
  if (quickSetupData?.isUserInvited) {
    value += 1;
  }
  if (isPremiumUser) {
    if (quickSetupData?.isBirthdaySet) {
      value += 1;
    }
    if (quickSetupData?.isAnniversarySet) {
      value += 1;
    }
    if (quickSetupData?.isCoreValuesSet) {
      value += 1;
    }
    return Math.round(value * 12.5);
  }
  return value * 20;
};

export const isDeactivatedUser = (user: FeedPostUserProps) =>
  user?.memberState === MemberState.DEACTIVATED;

export const setCurrentUserFirstLogin = (isFirstLogin: boolean) => {
  localStorage.setItem('userFirstLogin', JSON.stringify(isFirstLogin));
};

export const getCurrentUserFirstLogin = () => {
  const isFirstLoginVal = localStorage.getItem('userFirstLogin');
  if (isFirstLoginVal) {
    return JSON.parse(isFirstLoginVal) as boolean;
  }
  return false;
};
