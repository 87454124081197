import WorkspaceSetupContainer from '../../atomic/molecules/WorkspaceSetupContainer';
import { ACCOUNT_SETUP_JOB } from '../../constants/endpoints';
import useOnBoardingWithoutOptionsController from './useOnBoardingWithoutOptionsController';
import useOnboardingJobSetup from '../../hooks/useOnboardingJobSetup';
import styled from 'styled-components';
import AssemblyLanderLoadingScreen from '../../atomic/molecules/AssemblyLanderLoadingScreen';
import { useEffect } from 'react';
import {
  fireConversionForEmployerOnboard,
  fireConversionForNonGenericEmailEmployerOnboard,
} from '../../Utils/analytics/gtag';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { EventType, trackEvent } from '../../Utils/analytics/Metrics';

const StyledWrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;

const TriggerJobAndRenderSetupScreen = (props: any) => {
  const { preJobResponse, canSetManagerFilter } = props;
  useOnboardingJobSetup({
    apiName: ACCOUNT_SETUP_JOB,
    onboardingPath: '',
    preJobResponse: preJobResponse,
    canSetManagerFilter,
    pathName: '?showTemplate=true',
  });
  return (
    <StyledWrapper>
      <WorkspaceSetupContainer
        title="We’re setting up your workspace!"
        marginTop="48px"
      />
    </StyledWrapper>
  );
};

const OnBoardingWithoutOptionsController = () => {
  const { isSuccess, canSetManagerFilter, preJobResponse } =
    useOnBoardingWithoutOptionsController();

  const { data } = useProfileInfoFetchQuery();

  useEffect(() => {
    if (data?.member.memberId) {
      trackEvent(EventType.PROFILE_COMPLETE, {
        userId: data.member.memberId,
        employerId: data.assembly.assemblyId,
      });
      if (data.member.isFreeEmail) {
        fireConversionForEmployerOnboard();
      } else {
        fireConversionForNonGenericEmailEmployerOnboard();
      }
    }
  }, [
    data?.member.memberId,
    data?.member.isFreeEmail,
    data?.assembly.assemblyId,
  ]);

  return isSuccess && preJobResponse ? (
    <TriggerJobAndRenderSetupScreen
      preJobResponse={preJobResponse}
      canSetManagerFilter={canSetManagerFilter}
    />
  ) : (
    <AssemblyLanderLoadingScreen />
  );
};

export default OnBoardingWithoutOptionsController;
