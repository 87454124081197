import { useMutation } from '@tanstack/react-query';
import { PUBLISH_TEMPLATE } from '../../../../constants/endpoints';
import { makeAPICallWithDataReturn } from '../../../utils';
import { TemplateDetails } from '../../Template/types';

export const useShareFlowAsTemplateQuery = () => {
  return useMutation((param: { flowId: string; template: TemplateDetails }) =>
    makeAPICallWithDataReturn(
      PUBLISH_TEMPLATE,
      { template: param.template },
      undefined,
      {
        flowId: param.flowId,
      },
    ),
  );
};
