import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import AssemblyLogo from '../atoms/SVGIcon/icons/assemblyLogo.svg';
import NotFoundImage from '../../img/404.svg';
import {
  StyledLogo,
  CustomHeader,
  AssemblyImage,
} from '../../controllers/flows/ExternalFlowsCompletionController/styles';
import { Flex } from '../../Utils/styles/display';
import { zIndexPopup } from '../../Utils/styles/z-index';
import ThemeV2 from '../../theme';
import Button from '../atoms/Button';
import Body from '../atoms/Body';
import Link_V2 from '../atoms/Link_V2';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { HELP_CENTER } from '../../constants/routes';
import Heading from '../atoms/Heading';
import useMobileApp from '../../hooks/useMobileApp';
import useLayoutStore from '../../stores/layoutStore';

const Header = styled.div`
  top: 0;
  position: fixed;
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${ThemeV2.palette.gray1};
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  z-index: ${zIndexPopup};
`;

const Content = styled.main`
  grid-area: content;
  max-height: 100%;
  height: 100vh;
  overflow-y: hidden;
  margin-top: -40px;
  background-color: ${ThemeV2.palette.gray1};
`;

const StyledHeading = styled(Heading)`
  display: flex;
  font-weight: 500;
  margin-bottom: 8px;

  @media (max-width: ${ThemeV2.breakPoints.sm}) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoContainer = styled(Flex)`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray1};
`;

const CustomLink = styled(Link_V2)`
  text-decoration: none;
  margin-top: 32px;
`;

const InlineLogo = styled.img`
  height: 32px;
`;

const StyledButtonWrapper = styled.div`
  display: inline-block;

  @media (max-width: ${ThemeV2.breakPoints.xs}) {
    display: flex;
    flex-direction: column;
    align-items: center;

    Button {
      margin: 4px 0;
    }
  }
`;

const StyledLogInButton = styled(Button)`
  display: inline-block;
  margin-right: 16px;
`;

const StyledGoBackButton = styled(Button)`
  display: inline-block;
`;

const PageNotFound = () => {
  const { push } = useHistory();
  const { data } = useProfileInfoFetchQuery();
  const handleLoginToWorkspace = () => {
    push('/signout');
  };
  const { isEmbeddedInMainApp } = useLayoutStore();

  const handleGoBackToHome = () => {
    if (isEmbeddedInMainApp) {
      window.parent.postMessage({ type: 'GO_TO_HOME_FEED' }, '*');
      return;
    }
    push('/');
  };

  const { isMobileApp } = useMobileApp();

  return (
    <>
      {!isMobileApp && (
        <Header>
          <Flex>
            <LogoContainer
              alignItems="center"
              justifyContent="center"
              height="40px"
              width="40px"
            >
              <StyledLogo
                src={data?.assembly.logo || AssemblyLogo}
                alt="Express"
              />
            </LogoContainer>
            <CustomHeader>{data?.assembly.name || 'Assembly'}</CustomHeader>
          </Flex>
        </Header>
      )}
      <Content>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <AssemblyImage alt="assemblyLogo" src={NotFoundImage} />
          <Flex flexDirection="column">
            <StyledHeading variant="h5" textAlign="center">
              You’re currently logged into{' '}
              <Flex padding="0 8px">
                <InlineLogo
                  src={data?.assembly.logo || AssemblyLogo}
                  alt="Express"
                />{' '}
                {data?.assembly.name}
              </Flex>
            </StyledHeading>
            <Body variant="body1" textAlign="center" padding="8px 0 24px">
              If you’ve got somewhere to be, these options will help:
            </Body>
          </Flex>
          <StyledButtonWrapper>
            <StyledLogInButton
              variant="outlined"
              color="primary"
              dataTestId="templatesButton"
              onClick={handleLoginToWorkspace}
            >
              Log into a different workspace
            </StyledLogInButton>
            <StyledGoBackButton
              variant="outlined"
              color="primary"
              dataTestId="templatesButton"
              onClick={handleGoBackToHome}
            >
              Go back to current workspace
            </StyledGoBackButton>
          </StyledButtonWrapper>
          <CustomLink to={HELP_CENTER} isAnchor openInNewTab>
            Go to Help Center
          </CustomLink>
        </Flex>
      </Content>
    </>
  );
};

export default PageNotFound;
