import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../theme';
import { OthersLabel } from '../FeedPostHeader/styles';
import Link from '../../atoms/Link_V2';

export const Wrapper = styled.div`
  padding: 16px 24px;
`;

export const MembersWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 0 6px 20px;
  cursor: pointer;
  margin-left: -20px;
  border-radius: 4px;

  &:hover,
  &:focus {
    background: ${ThemeV2.palette.geekBlue1};
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 4px;
`;

export const OthersLabelWrapper = styled(OthersLabel)`
  margin-top: 4px;
`;

export const SubsectionsWrapper = styled.div`
  div:last-child {
    border-bottom: none;
  }
`;

export const SocialProfileWrapper = styled(Link)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  gap: 10px;
  max-width: 102px;
  width: 100%;
  height: 32px;
  background: ${ThemeV2.palette.gray1};
  border: 0.5px solid ${ThemeV2.palette.gray5};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`;
