import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider,
  ThemeProvider,
  adaptV4Theme,
  createTheme,
} from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ToastContainer from './ToastContainer';
import { APP_URL } from './config';
import { SIGN_IN_PATH, TEMPLATE } from './constants/routes';
import { useMicrosoftTeamsContext } from './hooks/useMicrosoftTeamsContext';
import { HttpsStatus } from './interfaces/ResponseError';
import Routers from './routers/routers';
import './static/css/App.css';
import './static/css/vendor-styles.css';
import useLayoutStore from './stores/layoutStore';

const BOLD = 600;
const LIGHT = 300;
const REGULAR = 400;

const theme = createTheme(
  adaptV4Theme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiCssBaseline: {
        button: {
          '&:focus': {
            outline: 'none !important',
          },
        },
        body: {
          backgroundColor: '#ffffff',
        },
      },
      MuiIconButton: {
        root: {
          '&:focus': {
            outline: 'none !important',
          },
        },
      },
      MuiFormLabel: {
        root: {
          color: 'rgb(0,0,0,0.38)', // Some CSS
        },
      },
      MuiInput: {
        underline: {
          '&:after': {
            borderBottom: '2px solid #FF4400',
            transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
          },
          '&:before': {
            borderBottom: '1px solid rgb(224, 224, 224)',
          },
          '&:hover:before': {
            borderBottom: '1px solid rgb(224, 224, 224) !important',
          },
        },
      },
      MuiButton: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
        containedPrimary: {
          color: '#FFF',
          minWidth: 80,
          backgroundColor: '#FF4400',
          '&:hover': {
            color: '#FFF',
          },
        },
        containedSecondary: {
          color: '#FFF',
          '&:hover': {
            color: '#FFF',
          },
        },
      },
      MuiDialogTitle: {
        root: {
          color: '#000',
          fontSize: 20,
          fontWeight: 500,
        },
      },
    },
    palette: {
      primary: {
        main: '#FF4400',
      },
      secondary: {
        main: '#0AD71C',
      },
    },
    typography: {
      fontWeightLight: LIGHT,
      fontWeightRegular: REGULAR,
      fontWeightBold: BOLD,
      fontSize: 14,
      htmlFontSize: 16,
      fontFamily: ['"Roboto"', 'sans-serif'].join(','),
    },
  }),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      refetchInterval: Infinity,
      staleTime: Infinity,
      retry: (failureCount, e: unknown) => {
        const error = e as AxiosError;
        const statusCode = Number(error.response?.status);
        return statusCode >= 500 && failureCount <= 3;
      },
      onError: (e: unknown) => {
        const error = e as AxiosError;
        const externalPaths = [TEMPLATE];
        const currentPath = window.location.pathname;
        if (
          !currentPath.includes('rewards') &&
          !externalPaths.some((path) => currentPath.includes(path)) &&
          error.response?.status === HttpsStatus.UNAUTHORIZED
        ) {
          window.location.href = SIGN_IN_PATH;
        }
      },
    },
  },
});

const App = () => {
  useMicrosoftTeamsContext();

  const { enableEmbeddedModeInMainApp } = useLayoutStore();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('source') === 'embedded') {
      enableEmbeddedModeInMainApp();
    }
  }, [enableEmbeddedModeInMainApp]);

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <HelmetProvider>
          <Helmet
            titleTemplate={'%s | Assembly'}
            defaultTitle="Assembly - Empower your team"
          >
            <meta
              name="version"
              content={import.meta.env.VITE_APP_VERSION}
            ></meta>
            <meta
              property="assembly:favicon"
              content={`${APP_URL}favicon/favicon-196x196.png`}
            />
          </Helmet>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Routers />
            </ThemeProvider>
          </StyledEngineProvider>
          <ToastContainer newestOnTop />
        </HelmetProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

declare global {
  interface Window {
    Sisense?: {
      connect: (url: string, saveChanges: boolean) => Promise<void>;
    };
  }
}

export default App;
