import React from 'react';

import PrimaryHeader from '../../../atomic/organism/PrimaryHeader';
import useLayoutStore from '../../../stores/layoutStore';
import placeholderLogo from '../../../img/assembly-placeholder-logo.svg';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';

import RewardsButtonController from '../../headers/RewardsButtonController';
import NotificationsButtonController from '../../headers/NotificationsButtonController';
import ProfileButtonController from '../../headers/ProfileButtonController';
import usePrimaryHeaderController from './usePrimaryHeaderController';
import { QUICK_SETUP, V2_HOME } from '../../../constants/routes';
import { LayoutStoreState } from '../../../interfaces/Layout';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';

const layoutSelector = (state: LayoutStoreState) => state.toggleLeftAsideOpen;

const PrimaryHeaderController: React.FC = () => {
  const toggleLeftAsideOpen = useLayoutStore(layoutSelector);
  const { data: profileInfoQueryData } = useProfileInfoFetchQuery();
  const { slugUrl } = useMultiAssemblySlug();
  const linkToHome = `/${slugUrl}${V2_HOME}`;

  const companyLogo = profileInfoQueryData?.assembly.logo || placeholderLogo;
  const companyName = profileInfoQueryData?.assembly.name || '';

  const { currentUserData, assemblyData, isInQuickSetup } =
    usePrimaryHeaderController();

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  if (!currentUserData || !assemblyData) {
    return <div />;
  }

  return (
    <PrimaryHeader
      path={isInQuickSetup ? QUICK_SETUP : linkToHome}
      companyLogo={companyLogo}
      companyName={companyName}
      toggleLeftAsideOpen={toggleLeftAsideOpen}
    >
      {!isInQuickSetup && (
        <>
          <RewardsButtonController />
          <NotificationsButtonController />
          <ProfileButtonController />
        </>
      )}
    </PrimaryHeader>
  );
};

export default PrimaryHeaderController;
