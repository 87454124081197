import addHours from 'date-fns/addHours';
import { RRule } from 'rrule';

import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import {
  ScheduleRule,
  TriggerBuilderBlockData,
} from '../../../../interfaces/Flow/Builder';

// js converts the RRule time local time, so to convert that to UTC to get the actual time
/* .padStart(2,0) is added in order to get 1:00 when the time is 1pm/am this
  applies for all round time like 8,9,6, etc,.
*/
export const getUTCTimeFromDate = (date: Date) => {
  let hours = date.getUTCHours();
  let finalHours = hours.toString();
  const minutes = date.getUTCMinutes();
  if (hours === 12) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    finalHours = `${hours.toString()}:${minutes.toString().padStart(2, 0)} pm`;
  } else if (hours > 12) {
    hours -= 12;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    finalHours = `${hours.toString()}:${minutes.toString().padStart(2, 0)} pm`;
  } else if (hours === 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    finalHours = `12:${minutes.toString().padStart(2, 0)} am`;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    finalHours = `${hours.toString()}:${minutes.toString().padStart(2, 0)} am`;
  }
  return finalHours;
};

// to get the actual date from RRule - for builder & edit
export const getUTCDateFromDate = (receivedDate: Date) => {
  const date = receivedDate.getUTCDate();
  const month = receivedDate.getUTCMonth();
  const year = receivedDate.getUTCFullYear();
  const utcDate = new Date(year, month, date);
  return utcDate;
};

// to get the default value for flowStartDate
export const getDefaultFlowStartDate = (schedule: ScheduleRule | undefined) => {
  if (schedule) {
    const parsedString = RRule.parseString(schedule.rule);
    if (parsedString.dtstart) {
      return getUTCDateFromDate(parsedString.dtstart);
    }
  }
  return addHours(new Date(), 2);
};

// get next full hour time - this code is moved to a separate function in order to cover the test case
export const getNextFullHour = (nextFullHour: number) => {
  let nextFullHourTimeString = '';
  if (nextFullHour === 12) {
    nextFullHourTimeString = `${nextFullHour.toString()}:00 pm`;
  } else if (nextFullHour === 24) {
    nextFullHourTimeString = `12:00 am`;
  } else if (nextFullHour === 25) {
    nextFullHourTimeString = `1:00 am`;
  } else if (nextFullHour > 12) {
    const updatedNextFullHour = nextFullHour - 12;
    nextFullHourTimeString = `${updatedNextFullHour.toString()}:00 pm`;
  } else if (nextFullHour === 0) {
    nextFullHourTimeString = `12:00 am`;
  } else {
    nextFullHourTimeString = `${nextFullHour.toString()}:00 am`;
  }
  return nextFullHourTimeString;
};
export const getNextFullHourTimeInString = () => {
  const currentDateTime = new Date();
  const currentTime = currentDateTime.getHours();
  const nextFullHour = currentTime + 2;
  return getNextFullHour(nextFullHour);
};

export const getFrequencyTimeForOneClickScheduler = (
  flowFrequencyTimeOptions: AutocompleteDropdownItem<number, undefined>[],
) => {
  const nextFullHourTimeInString = getNextFullHourTimeInString();
  const selectedTimeOption:
    | AutocompleteDropdownItem<number, undefined>[]
    | null = flowFrequencyTimeOptions.filter(
    (flowFrequencyTimeOption) =>
      flowFrequencyTimeOption.title === nextFullHourTimeInString,
  );
  if (!selectedTimeOption?.length) {
    return flowFrequencyTimeOptions[0];
  }
  return selectedTimeOption[0];
};

// to get the default frequencyTime
export const getDefaultFlowFrequencyTime = (
  isFromTemplate: boolean,
  triggerBlockData: TriggerBuilderBlockData,
  flowFrequencyTimeOptions: AutocompleteDropdownItem<number, undefined>[],
) => {
  if (
    triggerBlockData?.triggerType === 'SCHEDULED' &&
    triggerBlockData?.schedule &&
    !isFromTemplate
  ) {
    const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
    if (parsedString.dtstart) {
      const finalHours = getUTCTimeFromDate(parsedString.dtstart);
      const selectedTimeOption:
        | AutocompleteDropdownItem<number, undefined>[]
        | null = flowFrequencyTimeOptions.filter(
        (flowFrequencyTimeOption) =>
          flowFrequencyTimeOption.title === finalHours,
      );
      if (selectedTimeOption.length === 0 || selectedTimeOption === undefined) {
        return flowFrequencyTimeOptions[0];
      }
      return selectedTimeOption[0];
    }
    return flowFrequencyTimeOptions[0];
  }

  const nextFullHourTimeInString = getNextFullHourTimeInString();
  const selectedTimeOption:
    | AutocompleteDropdownItem<number, undefined>[]
    | null = flowFrequencyTimeOptions.filter(
    (flowFrequencyTimeOption) =>
      flowFrequencyTimeOption.title === nextFullHourTimeInString,
  );
  if (selectedTimeOption.length === 0 || selectedTimeOption === undefined) {
    return flowFrequencyTimeOptions[0];
  }
  return selectedTimeOption[0];
};

// to get the default flowEndDate
export const getDefaultFlowEndDate = (schedule: ScheduleRule | undefined) => {
  if (schedule) {
    const parsedString = RRule.parseString(schedule.rule);
    if (parsedString.until) {
      return getUTCDateFromDate(parsedString.until);
    }
  }
  return undefined;
};

// to get the default flowEndDateTime
export const getDefaultFlowEndDateTime = (
  triggerBlockData: TriggerBuilderBlockData,
  flowFrequencyTimeOptions: AutocompleteDropdownItem<number, undefined>[],
) => {
  if (
    triggerBlockData?.triggerType === 'SCHEDULED' &&
    triggerBlockData?.schedule
  ) {
    const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
    if (parsedString.until) {
      const finalHours = getUTCTimeFromDate(parsedString.until);
      const selectedTimeOption:
        | AutocompleteDropdownItem<number, undefined>[]
        | null = flowFrequencyTimeOptions.filter(
        (flowFrequencyTimeOption) =>
          flowFrequencyTimeOption.title === finalHours,
      );
      if (selectedTimeOption.length === 0 || selectedTimeOption === undefined) {
        return null;
      }
      return selectedTimeOption[0];
    }
    return null;
  }
  return null;
};
