import { useMemo } from 'react';
import pluralize from 'pluralize';
import Tooltip from '@mui/material/Tooltip';

import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import SVGIcon from '../../atoms/SVGIcon';
import { getAgoTime } from '../../../Utils/time';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

import useHover from '../../../hooks/useHover';
import { isDesktopPlatform } from '../../../Utils/window';
import { ComponentStatus } from '../../../interfaces/component';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { FlowRepliesBarProps, FlowsRepliesLoadedProps } from './types';

import {
  AvatarList,
  EmptyAvatar,
  TimeWrapper,
  CountAvatar,
  RepliesCountWrapper,
  FlowRepliesBarWrapper,
} from './styles';
import { getFullName } from '../../../Utils/user';

const LoadedComponent = (props: FlowsRepliesLoadedProps) => {
  const COUNT_LIMIT = 9;
  const MAX_AVATARS_COUNT = 5;

  const { initialRespondents, respondentsCount, lastRepliedAt, count } = props;

  const { data: profileData } = useProfileInfoFetchQuery();

  const {
    model: { isHovering },
    operations: {
      handlePopoverOpen: onMouseEnter,
      handlePopoverClose: onMouseLeave,
    },
  } = useHover();

  const renderAvatarList = useMemo(() => {
    return (
      <AvatarList>
        {initialRespondents.map((user) => (
          <Tooltip key={user.memberID} title={user.name} placement="top">
            <span>
              <Avatar
                size="32px"
                fontSize="16px"
                iconSize="32px"
                img={user.image}
                name={user.name}
                userId={user.memberID}
              />
            </span>
          </Tooltip>
        ))}
        {respondentsCount > 5 && (
          <>
            <EmptyAvatar></EmptyAvatar>
            <CountAvatar>
              <Body color="white" variant="body2Medium" margin="10px 0 0 16px">
                {respondentsCount <= COUNT_LIMIT
                  ? `+${respondentsCount - MAX_AVATARS_COUNT}`
                  : `9+`}
              </Body>
            </CountAvatar>
          </>
        )}
      </AvatarList>
    );
  }, [initialRespondents, respondentsCount]);

  return (
    <FlowRepliesBarWrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {
        <Flex justifyContent="space-between" width="100%">
          <Flex>
            {count === 0
              ? profileData && (
                  <AvatarList>
                    <Tooltip
                      placement="top"
                      title={getFullName(profileData.member.profile)}
                    >
                      <span>
                        <Avatar
                          size="32px"
                          fontSize="16px"
                          iconSize="32px"
                          userId={profileData?.member.memberId}
                          img={profileData?.member.profile.image}
                          name={profileData?.member.profile.firstName}
                        />
                      </span>
                    </Tooltip>
                  </AvatarList>
                )
              : renderAvatarList}
            <RepliesCountWrapper
              color="geekBlue6"
              variant="body2"
              margin="0 8px"
            >
              {count === 0
                ? 'Reply to this post'
                : pluralize('reply', count, true)}
            </RepliesCountWrapper>
            {isDesktopPlatform && count > 0 && (
              <div>
                {isHovering ? (
                  <RepliesCountWrapper
                    color="geekBlue6"
                    variant="body2"
                    margin="0"
                  >
                    View {pluralize('reply', count, false)}
                  </RepliesCountWrapper>
                ) : (
                  <TimeWrapper color="gray8" variant="body2">
                    Last reply {getAgoTime(lastRepliedAt)}
                  </TimeWrapper>
                )}
              </div>
            )}
          </Flex>
          {isHovering && (
            <SVGIcon
              size="18px"
              icon="carat-right"
              color={ThemeV2.palette.geekBlue6}
            />
          )}
        </Flex>
      }
    </FlowRepliesBarWrapper>
  );
};

const LoadingComponent = () => {
  return null;
};

const FlowRepliesBar = (props: FlowRepliesBarProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent />;
    default:
      return null;
  }
};

export default FlowRepliesBar;
