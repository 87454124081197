import { trackEvent } from '../index';
import { KnowledgeCenterEventProps } from '../interfaces';
import { AnalyticsEvents, KNOWLEDGE_CENTER_EVENT } from '../constants';

export const trackKnowledgeCenterShowEvent = (
  eventProps: KnowledgeCenterEventProps,
) => {
  trackEvent(KNOWLEDGE_CENTER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

export const trackKnowledgeCenterActionEvent = (
  eventProps: KnowledgeCenterEventProps,
) => {
  trackEvent(KNOWLEDGE_CENTER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackKnowledgeCenterErrorEvent = (
  eventProps: KnowledgeCenterEventProps,
) => {
  trackEvent(KNOWLEDGE_CENTER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};
