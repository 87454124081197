import { useRef, useState, useCallback, useMemo } from 'react';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import { Flex } from '../../../Utils/styles/display';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';

import DropdownWithChildren from '../DropdownWithChildren';
import {
  MenuItemIndividualItem,
  DropdownPlacement,
} from '../Dropdown_V2/interfaces';
import SVGIcon from '../../atoms/SVGIcon';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../theme';
import {
  getSimplifiedShareSheetFlowOwnerSettings,
  simplifiedShareSheetBulkAddSettings,
  simplifiedShareSheetUserSettings,
  simplifiedShareSheetUserSettingsOptions,
} from '../../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import styled from 'styled-components';
import { SimplifiedShareSheetCardData } from '../../../controllers/flows/FlowsShareSheetController/types';
import ToolTip from '../../molecules/ToolTipV2';
import { device } from '../../../constants/layout';
import { useMediaQuery } from 'react-responsive';

export const AccessSettingsIcon = styled(SVGIcon)`
  background: ${ThemeV2.palette.geekBlue6};
  border-radius: 50%;
`;

const TooltipContent = styled.div`
  width: 212px;
`;

const ResponsiveButton = styled(Button)`
  @media ${device.mobile} {
    height: auto;
    padding: 4px 0;
  }
`;

export const DropdownWrapper = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
`;

const TitleWithOverflowEllipsis = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;

  @media ${device.mobile} {
    max-width: 150px;
  }
`;

const SubtitleWithOverflowEllipsis = styled(Body)<{
  isFullWidth: boolean;
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ isFullWidth }) => !isFullWidth && 'max-width: 300px'};

  @media ${device.mobile} {
    max-width: 150px;
  }
`;

export const SimplifiedShareSheetUserCard = ({
  cardData,
  shouldDisplayDropdown = false,
  changeUserSettings,
  currentUserId,
  tooltipContent,
  isLocked = false,
  hasNonOwnerParticipants = false,
}: {
  cardData: SimplifiedShareSheetCardData;
  shouldDisplayDropdown?: boolean;
  changeUserSettings?: (setting?: MenuItemIndividualItem) => void;
  currentUserId: string;
  tooltipContent?: (type: string, setting: string) => string | undefined;
  isLocked?: boolean;
  hasNonOwnerParticipants?: boolean;
}) => {
  const {
    avatar,
    email,
    title,
    setting,
    id,
    emailToInvite,
    isOwner = false,
    icon,
    type,
    isCollaborator,
  } = cardData;

  const containerRef = useRef<HTMLDivElement>(null);

  const [caretOpen, toggleCaretOpen] = useState(false);

  const onOutsideClickHandler = useCallback(() => {
    if (caretOpen) toggleCaretOpen(!caretOpen);
  }, [caretOpen]);

  const handleItemClick = useCallback(
    (value: string | number) => {
      const currentSelectedOption =
        simplifiedShareSheetUserSettingsOptions.find(
          (option) => option.id === value,
        );
      if (currentSelectedOption && changeUserSettings) {
        changeUserSettings(currentSelectedOption);
      }
      if (caretOpen) toggleCaretOpen(!caretOpen);
    },
    [caretOpen, changeUserSettings],
  );

  useOnClickOutside(containerRef, onOutsideClickHandler);

  const menuItems = useMemo(() => {
    if (type === 'department' || type === 'everyone') {
      return simplifiedShareSheetBulkAddSettings;
    }

    return isOwner || isCollaborator
      ? getSimplifiedShareSheetFlowOwnerSettings(hasNonOwnerParticipants)
      : simplifiedShareSheetUserSettings;
  }, [hasNonOwnerParticipants, isCollaborator, isOwner, type]);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const tooltip = tooltipContent
    ? tooltipContent(type, setting?.value || '')
    : undefined;

  const renderResponsiveCard = useCallback(() => {
    return (
      <Flex width="100%" flexDirection="row">
        <Flex>
          {icon ? (
            <AccessSettingsIcon
              size="44px"
              icon={icon || 'circular-pending-user'}
            />
          ) : (
            <Avatar
              img={avatar?.img || ''}
              name={avatar?.name || title}
              userId={avatar?.userId || id}
              size="44px"
              icon={avatar?.icon}
              iconColor={avatar?.iconColor}
              fillAllIconPaths
            />
          )}
          <Flex
            justifyContent="flex-start"
            alignItems="baseline"
            flexDirection="column"
            margin="0 0 0 8px"
          >
            <TitleWithOverflowEllipsis variant="body2Medium">
              {emailToInvite ? emailToInvite : title}
              {currentUserId === id && ` (you)`}
            </TitleWithOverflowEllipsis>
            <TitleWithOverflowEllipsis variant="body3" color="gray8">
              {emailToInvite ? 'Invite upon sharing' : email}
              {!isOwner &&
                setting &&
                !shouldDisplayDropdown &&
                ` • ${setting.value}`}
              {isOwner && ` (Owner)`}
              {isCollaborator && ` (Collaborator)`}
            </TitleWithOverflowEllipsis>
            {setting && <Body variant="body3Medium">{setting.value}</Body>}
          </Flex>
        </Flex>
      </Flex>
    );
  }, [
    avatar?.icon,
    avatar?.iconColor,
    avatar?.img,
    avatar?.name,
    avatar?.userId,
    currentUserId,
    email,
    emailToInvite,
    icon,
    id,
    isCollaborator,
    isOwner,
    setting,
    shouldDisplayDropdown,
    title,
  ]);

  if (isMobileView) {
    return (
      <Flex
        id={`${shouldDisplayDropdown ? 'displayCard-' : 'dropdownCard-'}${
          cardData.id
        }`}
        flexDirection="row"
        width="100%"
      >
        {shouldDisplayDropdown ? (
          <DropdownWrapper ref={containerRef}>
            <DropdownWithChildren
              dropdownPlacement={DropdownPlacement.BottomEnd}
              menuItems={menuItems}
              onItemClick={handleItemClick}
              isDropdownPositionFixed
              isFullWidth
            >
              <ResponsiveButton
                color="grayTertiary"
                onClick={() => {
                  toggleCaretOpen(!caretOpen);
                }}
                isFullWidth
                enableCaret={shouldDisplayDropdown}
                caretOpen={caretOpen}
              >
                {renderResponsiveCard()}
              </ResponsiveButton>
            </DropdownWithChildren>
          </DropdownWrapper>
        ) : (
          renderResponsiveCard()
        )}
      </Flex>
    );
  }

  return (
    <Flex
      id={`${shouldDisplayDropdown ? 'displayCard-' : 'dropdownCard-'}${
        cardData.id
      }`}
      width="100%"
      padding="4px"
      margin="0 0 8px 0"
    >
      {icon ? (
        <AccessSettingsIcon
          size="44px"
          icon={icon || 'circular-pending-user'}
          fillAllPaths
        />
      ) : (
        <Avatar
          img={avatar?.img || ''}
          name={avatar?.name || title}
          userId={avatar?.userId || id}
          size="44px"
          icon={avatar?.icon}
          iconColor={avatar?.iconColor}
          fillAllIconPaths
        />
      )}
      <Flex width="100%" margin="0 0 0 8px" justifyContent="space-between">
        <Flex width="65%" flexDirection="column" alignItems="flex-start">
          <TitleWithOverflowEllipsis variant="body2Medium">
            {emailToInvite ? emailToInvite : title}
            {currentUserId === id && ` (you)`}
          </TitleWithOverflowEllipsis>
          <SubtitleWithOverflowEllipsis
            variant="body3"
            color="gray8"
            isFullWidth={!shouldDisplayDropdown}
          >
            {emailToInvite ? 'Invite upon sharing' : email}
            {isOwner && ` (Owner)`}
            {isCollaborator && ` (Collaborator)`}
            {setting && !shouldDisplayDropdown && ` • ${setting.value}`}
          </SubtitleWithOverflowEllipsis>
        </Flex>
        <>
          {tooltip && (
            <ToolTip
              position="top"
              toolTipComponent={<SVGIcon icon="info-outlined" size="16px" />}
            >
              <TooltipContent>{tooltip}</TooltipContent>
            </ToolTip>
          )}
          {setting &&
            shouldDisplayDropdown &&
            (isLocked ? (
              <Flex justifyContent="flex-end" margin="0 20px 0 0" width="35%">
                <Body color="gray8" margin="0 14px 0 0" variant="body2">
                  {setting.value}
                </Body>
                <SVGIcon icon="lock" size="14px" />
              </Flex>
            ) : (
              <DropdownWrapper ref={containerRef}>
                <DropdownWithChildren
                  dropdownPlacement={DropdownPlacement.BottomEnd}
                  menuItems={menuItems}
                  onItemClick={handleItemClick}
                  isDropdownPositionFixed
                >
                  <Button
                    color="grayTertiary"
                    onClick={() => {
                      toggleCaretOpen(!caretOpen);
                    }}
                    isFullWidth
                    enableCaret
                    caretOpen={caretOpen}
                    caretIconSize="16px"
                  >
                    {setting.value}
                  </Button>
                </DropdownWithChildren>
              </DropdownWrapper>
            ))}
        </>
      </Flex>
    </Flex>
  );
};

export const SimplifiedShareSheetUserCardLoader = () => {
  return (
    <Flex width="100%" margin="8px 0px">
      <ContentLoader
        speed={2}
        width={'100%'}
        height={44}
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="autocomplete-loader"
      >
        <circle cx="22" cy="22" r="22" />
        <rect x="54" y="4" rx="4" width="182" height="16" />
        <rect x="54" y="28" rx="4" width="182" height="12" />
      </ContentLoader>
    </Flex>
  );
};
