import { SwitchTextTypes } from './types';

export const SwitchText: Record<
  SwitchTextTypes,
  { true: string; false: string }
> = {
  [SwitchTextTypes.ON_OFF]: {
    true: 'On',
    false: 'Off',
  },
  [SwitchTextTypes.YES_NO]: {
    true: 'Yes',
    false: 'No',
  },
  [SwitchTextTypes.EMPTY]: {
    true: '',
    false: '',
  },
};
