import Body from '../../../../../atomic/atoms/Body';
import { SHARE_SHEET_MODAL } from '../../../../../languages/en/flows/builder';
import { Flex } from '../../../../../Utils/styles/display';
import {
  StyledShareSheetButton,
  StyledShareSheetSVGIcon,
  StyledShareSheetBody,
  StyledShareSheetSuffixSVGIcon,
} from '../styles';
import ThemeV2 from '../../../../../theme';
import styled from 'styled-components';
import UpgradeButton from '../../../../../atomic/atoms/Button';
import { UPGRADE_BUTTON_TEXT } from '../../../../../languages/en/rewards';
import { ACCOUNT_ROUTES_LIST } from '../../../../../routers/routes';
import { useHistory } from 'react-router-dom';
import { trackShareSheetActionEvent } from '../../../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../../../Utils/analytics/constants';
import { ShareSheetRuleAccessTypes } from '../../types';
import RequestAccessController from '../../RequestAccessController';
import useRequestedUsersForAccessStore, {
  ShareSheetCurrentModalScreen,
} from '../../../../../stores/sharesheetStore';
import { useEffect } from 'react';
import advancedRulesPreviewImage from '../../../../../img/advancedRulesPreview.png';

export const AdvancedPaidShareSheetController = ({
  visibilityTitleContent,
  subtitleContent,
  handleChooseParticipants,
  handleChooseViewers,
  isSimplifiedShareSheetTreatmentOn,
}: {
  handleChooseParticipants: () => void;
  handleChooseViewers: () => void;
  visibilityTitleContent?: string;
  subtitleContent?: string;
  isSimplifiedShareSheetTreatmentOn: boolean;
}) => {
  const { setCurrentShareSheetModalScreen } = useRequestedUsersForAccessStore();

  useEffect(() => {
    setCurrentShareSheetModalScreen(ShareSheetCurrentModalScreen.Advanced_Rule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isSimplifiedShareSheetTreatmentOn && (
        <>
          <Body variant="body1Bold" className="hello">
            {SHARE_SHEET_MODAL.FLOW_MEMBERS}
          </Body>
          <Body margin="0 0 16px 0" variant="body3">
            {SHARE_SHEET_MODAL.DESCRIPTION}
          </Body>
        </>
      )}
      <RequestAccessController />
      <StyledShareSheetButton
        onClick={handleChooseParticipants}
        className="introjs-sharesheet-modal"
      >
        <Flex alignItems="flex-start">
          <StyledShareSheetSVGIcon
            icon="people"
            size="16px"
            color={ThemeV2.palette.geekBlue6}
          />
          <div>
            <StyledShareSheetBody variant="body1Medium">
              {SHARE_SHEET_MODAL.CHOOSE_PARTICIPANTS}
            </StyledShareSheetBody>
            <StyledShareSheetBody variant="body3">
              {subtitleContent}
            </StyledShareSheetBody>
          </div>
        </Flex>
        <StyledShareSheetSuffixSVGIcon icon="carat-right" size="20px" />
      </StyledShareSheetButton>
      <StyledShareSheetButton
        onClick={handleChooseViewers}
        className="introjs-sharesheet-participants"
      >
        <Flex alignItems="flex-start">
          <StyledShareSheetSVGIcon
            icon="eye"
            size="16px"
            color={ThemeV2.palette.geekBlue6}
          />
          <div>
            <StyledShareSheetBody variant="body1Medium">
              {SHARE_SHEET_MODAL.CHOOSE_VIEWERS}
            </StyledShareSheetBody>
            <StyledShareSheetBody variant="body3">
              {visibilityTitleContent}
            </StyledShareSheetBody>
          </div>
        </Flex>
        <StyledShareSheetSuffixSVGIcon icon="carat-right" size="20px" />
      </StyledShareSheetButton>
    </>
  );
};

const AdvancedRulesPreviewImage = styled.img`
  margin-bottom: 16px;
`;

export const AdvancedFreeShareSheetController = () => {
  const { push } = useHistory();

  return (
    <div>
      <Body margin="8px 0 0 0" variant="subHead2Medium">
        Upgrade for advanced rules
      </Body>
      <Body margin="0 0 16px 0" variant="body2">
        Want to make your life and workflows easier? Create more nuanced,
        dynamically updating lists of people who can post and people who can
        view posts.
      </Body>
      <AdvancedRulesPreviewImage src={advancedRulesPreviewImage} />
      <UpgradeButton
        status="info"
        icon="crown-solid"
        isFullWidth={true}
        onClick={() => {
          trackShareSheetActionEvent({
            action: SHARE_SHEET_EVENTS.UPGRADE_CLICKED,
            tab: 'invite and share',
            ruleType: ShareSheetRuleAccessTypes.Advance,
          });
          push(ACCOUNT_ROUTES_LIST.INDEX);
        }}
        size="small"
      >
        {UPGRADE_BUTTON_TEXT}
      </UpgradeButton>
    </div>
  );
};
