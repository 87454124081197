import React from 'react';
import { Route, Switch } from 'react-router-dom';

import useLayoutStore from '../../../stores/layoutStore';
import { LayoutStoreState } from '../../../interfaces/Layout';

import {
  V2_HOME,
  NOTEBOOK,
  FLOW_FEED,
  FLOWS_FEEDS,
  FLOW_INSIGHTS,
  FLOWS_BUILDER,
  V2_USER_PROFILE,
  KNOWLEDGE_CENTER,
  RECOGNITION_FEEDS,
  NEW_FLOWS_BUILDER,
  V3_FLOWS_POST_PAGE,
  UNARCHIVE_FLOW,
  PEOPLE_DIRECTORY,
} from '../../../constants/routes';

import ProfileViewController from '../ProfileViewController';
import ChatController from '../../rightDrawer/ChatController';
import MainFeedDrawerController from '../MainFeedDrawerController';
import { FlowsPostDrawerController } from '../FlowsPostDrawerController';
import NotebookRightDrawerController from '../../notebook/NotebookRightDrawerController';
import RepliesRightDrawerController from '../../rightDrawer/RepliesRightDrawerController';
import FlowFeedRightDrawerController from '../../rightDrawer/FlowFeedRightDrawerController';
import FlowBuilderRightDrawerController from '../../rightDrawer/FlowBuilderRightDrawerController';
import ProfileFeedRightDrawerController from '../../rightDrawer/ProfileFeedRightDrawerController';
import FlowsInsightsRightDrawerController from '../../rightDrawer/FlowsInsightsRightDrawerController';
import KnowledgeCenterRightDrawerController from '../../knowledgeCenter/KnowledgeCenterRightDrawerController';
import useMobileApp from '../../../hooks/useMobileApp';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import TemplateGalleryController from '../../flows/TemplateGalleryController';
import PeopleDirectoryRightDrawerController from '../PeopleDirectoryRightDrawerController';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import { SplitNames } from '../../../hooks/useSplitSdkConfig/constants';

const selectedMemberSelector = (state: LayoutStoreState) =>
  state.selectedMember;
const showRepliesSelector = (state: LayoutStoreState) => state.showReplies;
const showChatsSelector = (state: LayoutStoreState) => state.showChats;
const isEmbeddedInMainAppSelector = (state: LayoutStoreState) =>
  state.isEmbeddedInMainApp;

const SecondaryDrawerController = () => {
  const showChats = useLayoutStore(showChatsSelector);
  const showReplies = useLayoutStore(showRepliesSelector);
  const selectedMember = useLayoutStore(selectedMemberSelector);
  const isEmbeddedInMainApp = useLayoutStore(isEmbeddedInMainAppSelector);
  const { treatment: hideV2DirectMessaging } = useFeatureSplit(
    SplitNames.HideV2DirectMessaging,
  );
  const { showTemplateGallery } = useMobileApp();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  if (isMobileView && !isEmbeddedInMainApp) {
    if (showTemplateGallery) return <TemplateGalleryController />;
  }

  if (showReplies) {
    return <RepliesRightDrawerController />;
  }

  if (selectedMember) {
    return (
      <Switch>
        {showChats && hideV2DirectMessaging !== 'on' ? (
          <Route path={V2_USER_PROFILE} component={ChatController} />
        ) : (
          <Route
            path={V2_USER_PROFILE}
            component={ProfileFeedRightDrawerController}
          />
        )}
        {showChats && hideV2DirectMessaging !== 'on' ? (
          <Route
            path={`/:slugUrl${V2_USER_PROFILE}`}
            component={ChatController}
          />
        ) : (
          <Route
            path={`/:slugUrl${V2_USER_PROFILE}`}
            component={ProfileFeedRightDrawerController}
          />
        )}
        <Route>
          {showChats && hideV2DirectMessaging !== 'on' ? (
            <ChatController />
          ) : (
            <ProfileViewController
              firstName={selectedMember.firstName}
              memberId={selectedMember.memberId}
            />
          )}
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={RECOGNITION_FEEDS} component={MainFeedDrawerController} />
      <Route
        path={`/:slugUrl${RECOGNITION_FEEDS}`}
        component={MainFeedDrawerController}
      />
      <Route
        path={`/:slugUrl${V2_HOME}`}
        component={MainFeedDrawerController}
      />
      <Route path={V2_HOME} component={MainFeedDrawerController} />
      <Route
        exact
        path={FLOW_FEED}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOW_FEED}`}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={FLOW_INSIGHTS}
        component={FlowsInsightsRightDrawerController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOW_INSIGHTS}`}
        component={FlowsInsightsRightDrawerController}
      />
      <Route
        exact
        path={NEW_FLOWS_BUILDER}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={`/:slugUrl${NEW_FLOWS_BUILDER}`}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={FLOWS_BUILDER}
        component={FlowBuilderRightDrawerController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOWS_BUILDER}`}
        component={FlowBuilderRightDrawerController}
      />
      <Route exact path={`/:slugUrl${UNARCHIVE_FLOW}`} />
      <Route path={FLOWS_FEEDS} component={FlowFeedRightDrawerController} />
      <Route
        path={`/:slugUrl${FLOWS_FEEDS}`}
        component={FlowFeedRightDrawerController}
      />
      {/* Below route is needed - when we navigate through left nav */}
      <Route
        path={V2_USER_PROFILE}
        component={ProfileFeedRightDrawerController}
      />
      <Route
        path={`/:slugUrl${V2_USER_PROFILE}`}
        component={ProfileFeedRightDrawerController}
      />
      <Route
        path={KNOWLEDGE_CENTER}
        component={KnowledgeCenterRightDrawerController}
      />
      <Route
        path={`/:slugUrl${KNOWLEDGE_CENTER}`}
        component={KnowledgeCenterRightDrawerController}
      />
      <Route path={V3_FLOWS_POST_PAGE} component={FlowsPostDrawerController} />
      <Route
        path={`/:slugUrl${V3_FLOWS_POST_PAGE}`}
        component={FlowsPostDrawerController}
      />
      <Route
        path={`/:slugUrl${NOTEBOOK}`}
        component={NotebookRightDrawerController}
      />
      <Route path={NOTEBOOK} component={NotebookRightDrawerController} />
      <Route
        path={`/:slugUrl${PEOPLE_DIRECTORY}`}
        component={PeopleDirectoryRightDrawerController}
      />
      <Route
        path={PEOPLE_DIRECTORY}
        component={PeopleDirectoryRightDrawerController}
      />
    </Switch>
  );
};

export default SecondaryDrawerController;
