import React, { useMemo } from 'react';
import { AxomoSwagReward } from '../../../queries/Rewards/interfaces';
import RewardCard from '../../../../src/atomic/molecules/RewardCard';
import { RewardTypes } from '../../../atomic/molecules/RewardCard/interface';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useRewardCardControllerModel from './useRewardCardControllerModel';
import MemoizedTwoColumnModal from '../../../atomic/molecules/TwoColumnModal';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../theme';
import {
  ContentLeftFooterButtonWrapper,
  ContentLeftWrapper,
  RedeemPolicyContent,
  RedeemPolicyHeading,
  SwagDetailsWrapper,
} from '../../../atomic/organism/RedeemSelectModal/styles';
import {
  CONTINUE_TO_SHIPPING,
  REDEEM_SWAG_POLICY_LOADING,
  REDEEM_SWAG_POLICY_TITLE,
} from '../../../languages/en/rewards';
import Button from '../../../atomic/atoms/Button';
import { SwagCardStatuses } from '../../../queries/Admin/rewards/Swags/interface';
import SwagsRedeemModalController from '../SwagsRedeemModalController';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

interface RewardCardControllerProps {
  rewardCardData: AxomoSwagReward;
  profileData: GetProfileInfoResponse;
  rewardsData: AxomoSwagReward[];
  isSwagsTabTurnedOff?: boolean;
}

const RewardCardController = (props: RewardCardControllerProps) => {
  const { rewardCardData, profileData, rewardsData, isSwagsTabTurnedOff } =
    props;

  const {
    isModalOpen,
    selectedReward,
    rewardItemData,
    handleOnRewardSelect,
    onCloseRedeemModal,
    isRewardItemError,
    isRewardItemLoading,
    quantityDropdownValues,
    shippingCost,
    axomoShippingCountries,
  } = useRewardCardControllerModel({
    rewardsData,
    rewardCardData,
    totalPoints: profileData.member.pointsEarned,
    rewardPoints: rewardCardData.points[0],
  });

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const redeemModalLeftLoadingContent = useMemo(() => {
    return (
      <ContentLeftWrapper>
        <SwagDetailsWrapper>
          <ContentLoader
            speed={2}
            width={156}
            height={156}
            viewBox="0 0 156 156"
            className="contentLoader"
            data-testid="content-loader"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
          >
            <rect x="0" y="0" rx="4" ry="4" width="156" height="156" />
          </ContentLoader>
          <ContentLoader
            speed={2}
            width={213}
            height={24}
            viewBox="0 0 213 24"
            className="contentLoader"
            data-testid="content-loader"
            backgroundColor={ThemeV2.palette.gray4}
            foregroundColor={ThemeV2.palette.gray3}
          >
            <rect x="0" y="0" rx="4" ry="4" width="213" height="24" />
          </ContentLoader>
        </SwagDetailsWrapper>
        <ContentLeftFooterButtonWrapper>
          <Button
            isFullWidth
            disabled
            color="primary"
            onClick={() => {}}
            dataTestId="loading-button"
          >
            {CONTINUE_TO_SHIPPING}
          </Button>
        </ContentLeftFooterButtonWrapper>
      </ContentLeftWrapper>
    );
  }, []);

  const redeemModalRightLoadingContent = useMemo(() => {
    return (
      <>
        <RedeemPolicyHeading variant="h6">
          {REDEEM_SWAG_POLICY_TITLE}
        </RedeemPolicyHeading>
        <RedeemPolicyContent variant="body2">
          {REDEEM_SWAG_POLICY_LOADING}
        </RedeemPolicyContent>
      </>
    );
  }, []);

  if (isRewardItemLoading) {
    return (
      <MemoizedTwoColumnModal
        isOpen={isRewardItemLoading}
        onClose={() => {}}
        contentLeft={redeemModalLeftLoadingContent}
        contentRight={redeemModalRightLoadingContent}
        isMobileView={isMobileView}
        height="600px"
      />
    );
  }

  if (!rewardsData) return <></>;

  return (
    <>
      <RewardCard
        disabled={
          isRewardItemError ||
          quantityDropdownValues.length <= 0 ||
          isSwagsTabTurnedOff ||
          rewardCardData.status !== SwagCardStatuses.REDEEMABLE
        }
        key={rewardCardData.uid}
        id={rewardCardData.uid}
        rewardType={RewardTypes.Swags}
        image={rewardCardData.image}
        title={rewardCardData.name}
        description={rewardCardData.desc}
        assemblyCurrency={profileData.assembly.currency}
        cost={rewardCardData.points[0]}
        onRewardSelect={handleOnRewardSelect}
        isSwagTabTurnedOff={isSwagsTabTurnedOff}
        shippingCost={shippingCost}
        status={rewardCardData.status}
      />

      {isModalOpen &&
        selectedReward &&
        !isRewardItemError &&
        quantityDropdownValues.length &&
        rewardItemData && (
          <SwagsRedeemModalController
            isModalOpen={isModalOpen}
            rewardId={selectedReward}
            profileEmail={profileData.member.email}
            assemblyCurrency={profileData.assembly.currency}
            onCloseRedeemModal={onCloseRedeemModal}
            rewardItemData={rewardItemData}
            quantityDropdownValues={quantityDropdownValues}
            axomoShippingCountries={axomoShippingCountries}
          />
        )}
    </>
  );
};

export default RewardCardController;
