import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { MenuItemIndividualItem } from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { mapParticipationAndVisibilityDataToPreviewRules } from '../../../../Utils/flows';
import { allPermissionAccessPermission, ShareSheetPermissions } from './data';
import { useFetchMembersBasedOnFlowCriteria } from '../ShareSheetModalController/useFetchMembersBasedOnFlowCriteria';

export const useShareSheetAccessPreviewController = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const [dropdownValue, setDropdownValue] = useState<MenuItemIndividualItem>(
    allPermissionAccessPermission,
  );

  const { data: profileData } = useProfileInfoFetchQuery();

  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId || '',
    'builder',
  );

  const {
    isParticipantsCriteriaLoading,
    isViewerCriteriaLoading,
    participantsSelected,
    membersWithVisibility,
    hasMoreMembers,
    fetchNextPageForParticipants,
    fetchNextPageForViewers,
  } = useFetchMembersBasedOnFlowCriteria({
    enableFetch: true,
    isMembersOnly: false,
  });

  const fetchMoreMembers = () => {
    if (
      dropdownValue.id === ShareSheetPermissions.allPermissions ||
      dropdownValue.id === ShareSheetPermissions.canPost
    ) {
      fetchNextPageForParticipants();
    }
    if (
      dropdownValue.id === ShareSheetPermissions.allPermissions ||
      dropdownValue.id === ShareSheetPermissions.canView
    ) {
      fetchNextPageForViewers();
    }
  };

  const previewMembers = useMemo(() => {
    if (isParticipantsCriteriaLoading || isViewerCriteriaLoading) {
      return [];
    }
    return mapParticipationAndVisibilityDataToPreviewRules({
      participants: participantsSelected || [],
      membersWithVisibility: membersWithVisibility || [],
      flowOwner: flowDetails?.data?.owner,
      displayType: `${dropdownValue.id}`,
      flowCollaborators: flowDetails?.data.collaborators,
    });
  }, [
    dropdownValue.id,
    flowDetails?.data.collaborators,
    flowDetails?.data?.owner,
    isParticipantsCriteriaLoading,
    isViewerCriteriaLoading,
    membersWithVisibility,
    participantsSelected,
  ]);

  const currentUserId = useMemo(
    () => profileData?.member?.memberId,
    [profileData],
  );

  return {
    previewMembers,
    dropdownValue,
    setDropdownValue,
    fetchMoreMembers,
    hasMoreUsersToFetch: hasMoreMembers,
    currentUserId,
    isLoading: isParticipantsCriteriaLoading || isViewerCriteriaLoading,
  };
};
