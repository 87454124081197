import React from 'react';

import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../theme';

import { CriteriaItemProps } from './types';
import {
  CriteriaLabel,
  CriteriaValue,
  RightWrapper,
  StyledClickableClose,
  StyledIcon,
  StyledInviteIcon,
  Wrapper,
  CriteriaValueText,
  StyledFlex,
  ReadOnlyIconWrapper,
} from './styles';
import { MemberState } from '../../../interfaces/user';
import ToolTip from '../ToolTipV2';
import {
  NOT_A_MEMBER_TOOLTIP_TEXT,
  PENDING_MEMBER_TOOLTIP_TEXT,
} from '../../../languages/en/flows/builder';

const Loader = styled(ContentLoader)`
  margin-left: 10px;
`;

const CriteriaItem = (props: CriteriaItemProps) => {
  const {
    id,
    icon,
    criteria,
    isLoading,
    memberState,
    criteriaValue,
    onDismissClick,
    criteriaCondition,
    criteriaMessage = '',
    isSlackCriteria = false,
  } = props;

  return (
    <Wrapper>
      <Flex alignItems="center">
        <StyledIcon icon={icon} size="12px" />
        <StyledFlex width="220px">
          <CriteriaLabel variant="body2" color="gray9">
            {criteria}
          </CriteriaLabel>
          <CriteriaLabel variant="body2Medium" color="gray9">
            &nbsp;{criteriaCondition}
          </CriteriaLabel>
          {isLoading ? (
            <Loader
              speed={2}
              width={130}
              height={25}
              viewBox="0 0 125 25"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="4" ry="4" width="130" height="25" />
            </Loader>
          ) : (
            <CriteriaValue variant="body2" color={ThemeV2.palette.geekBlue6}>
              &nbsp;{' '}
              {memberState === MemberState.PENDING && (
                <ToolTip
                  position="top"
                  toolTipComponent={
                    <StyledInviteIcon
                      icon="pending-person"
                      size="16px"
                      color={ThemeV2.palette.geekBlue6}
                    />
                  }
                  positionStrategy="fixed"
                >
                  {PENDING_MEMBER_TOOLTIP_TEXT}
                </ToolTip>
              )}{' '}
              {memberState === MemberState.NEW && (
                <ToolTip
                  position="top"
                  toolTipComponent={
                    <StyledInviteIcon
                      icon="add-person"
                      size="16px"
                      color={ThemeV2.palette.geekBlue6}
                    />
                  }
                  positionStrategy="fixed"
                >
                  {NOT_A_MEMBER_TOOLTIP_TEXT}
                </ToolTip>
              )}{' '}
              <CriteriaValueText
                variant="body2"
                color={ThemeV2.palette.geekBlue6}
              >
                {criteriaValue}
              </CriteriaValueText>
            </CriteriaValue>
          )}
        </StyledFlex>
      </Flex>

      {isSlackCriteria && (
        <ReadOnlyIconWrapper>
          <ToolTip
            position="left"
            toolTipComponent={<SVGIcon icon="lock" size="16px" />}
          >
            {criteriaMessage}
          </ToolTip>
        </ReadOnlyIconWrapper>
      )}
      <RightWrapper alignItems="center" justifyContent="center">
        {onDismissClick && (
          <StyledClickableClose onClick={() => onDismissClick(id)}>
            <SVGIcon size="16px" icon="round-close" />
          </StyledClickableClose>
        )}
      </RightWrapper>
    </Wrapper>
  );
};

export default CriteriaItem;
