import { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { shallow } from 'zustand/shallow';

import {
  categoryMenuItems,
  timeFrameMenuItems,
} from '../../../atomic/molecules/Leaderboard/data';
import { GENERATE_ACTIVITY_OLD_DATA } from '../../../constants/endpoints';
import useLayoutStore from '../../../stores/layoutStore';
import useJobPollingForFlowDownload, {
  DownloadDataJobProps,
} from '../../../hooks/useJobPollingForDownload';
import {
  DATA_DOWNLOADED_SUCCESSFULLY,
  DATA_DOWNLOAD_ERROR,
  PREPARING_DATA_FOR_DOWNLOAD,
} from '../../../languages/en/flows';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../Utils/toast';
import { FeedItemFromAPI, PostSortOptions } from '../../../interfaces/Feed';
import {
  defaultValues,
  UseSearchDropDownValues,
} from '../../../atomic/organism/SearchDropdown';
import {
  getSearchPayload,
  isFeedSearchEmpty,
} from '../../../queries/Feed/utils';
import { useGetFeedsForRecognition } from '../RecognitionController/useSearchOrGetFeedData';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { isUserAdmin } from '../../../Utils/user';

const useLeaderboardControllerLogic = () => {
  const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(
    categoryMenuItems[0].value,
  );

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    timeFrameMenuItems[1].value,
  );

  const { setSelectedMemberProfile, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      setSelectedMemberProfile: state.setSelectedMember,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { data: profileData } = useProfileInfoFetchQuery();
  const filter = location?.pathname?.includes('/fullscreen')
    ? { tvMode: true }
    : undefined;
  const [feedsSortValue] = useState<PostSortOptions>(PostSortOptions.DESC);
  const [searchFeedValue] = useState<UseSearchDropDownValues>({
    ...defaultValues,
  });

  const [searchPayload, showSearchResults] = useMemo(() => {
    if (isFeedSearchEmpty(searchFeedValue)) {
      return [{}, false];
    }
    return [getSearchPayload(searchFeedValue), true];
  }, [searchFeedValue]);

  const { data } = useGetFeedsForRecognition(
    searchPayload,
    feedsSortValue,
    showSearchResults,
    filter,
  );

  const recognitionData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.pages.reduce<FeedItemFromAPI[]>(
      (arr, page) => [...arr, ...page.data],
      [],
    );
  }, [data]);

  const disableDownloadOption = recognitionData.length === 0;
  const showDownloadOption = profileData
    ? isUserAdmin(profileData.member)
    : false;

  const { downloadFlowData: downloadRecognitionData } =
    useJobPollingForFlowDownload({
      preJobAPI: {
        apiName: GENERATE_ACTIVITY_OLD_DATA,
      },
      postJobAPI: {
        apiName: GENERATE_ACTIVITY_OLD_DATA,
      },
    });

  const downloadClickHandler = useCallback(async () => {
    showInfoMessage(PREPARING_DATA_FOR_DOWNLOAD);
    const getDownloadFlowDataURL: DownloadDataJobProps =
      await downloadRecognitionData();
    if (
      getDownloadFlowDataURL.data.url !== undefined &&
      getDownloadFlowDataURL.status === 'SUCCESS'
    ) {
      window.open(
        getDownloadFlowDataURL.data.url,
        '_self',
        'noreferrer, noopener',
      );
      dismissAllToasts();
      setTimeout(() => showSuccessMessage(DATA_DOWNLOADED_SUCCESSFULLY), 500);
    } else {
      showErrorMessage(DATA_DOWNLOAD_ERROR);
    }
  }, [downloadRecognitionData]);

  return {
    location,
    selectedCategory,
    selectedTimeFrame,
    showDownloadOption,
    setSelectedCategory,
    setSelectedTimeFrame,
    downloadClickHandler,
    disableDownloadOption,
    setSelectedMemberProfile,
    toggleRightAsideOpen,
  };
};

export default useLeaderboardControllerLogic;
