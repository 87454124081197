import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Body from '../../../../../atomic/atoms/Body';
import { RadioOptionProps } from '../../../../../atomic/molecules/RadioGroup';

import {
  CriteriaGroups,
  VisibilityBuilderBlockData,
} from '../../../../../interfaces/Flow/Builder';
import { BUILDER_VISIBILITY_BLOCK } from '../../../../../languages/en/flows/builder';
import { visibilityRadioOptions } from '../../ShareSheetModalController/data';
import { VisibilityTypes } from '../../types';
import { ShareSheetParticipantsControllerProps } from '../index';
import { useParams } from 'react-router-dom';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { useGetFinalizedMembersFromCriteriaForPreview } from '../../../../../hooks/useGetMembersFromCriteriaForPreview';
import { GetMembersForPreviewRequest } from '../../../../../interfaces/Sharesheet';
import { mapRulesFromCriteriaGroups } from '../../../../../Utils/flows/criteria';
import {
  getCriteriaPayloadForVisibility,
  serializeCollaboratorToMemberDTO,
  getVisibilityCriteriaForCurrentFlow,
} from '../../../../../Utils/flows/sharesheet';
import { Participant } from '../../../../../atomic/molecules/ParticipantsList/types';
import { IMemberDTO } from '../../../../../interfaces/member';
import { getParticipantBlockForFlowParticipant } from '../../../../../Utils/flows';
import { MemberState } from '../../../../../interfaces/user';

const useShareSheetVisibilityController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const {
    variant,
    visibilityBlockData,
    visibilityParticipantsCriteria,
    updateVisibilityParticipantsBlockData,
  } = props;

  const getRadioOptionCustomContent = useCallback((type: string) => {
    switch (type) {
      case VisibilityTypes.CUSTOM:
        return (
          <Body variant="body3" color="gray8">
            {BUILDER_VISIBILITY_BLOCK.CUSTOM_OPTION_TEXT}
          </Body>
        );
      case VisibilityTypes.PARTICIPANTS_ONLY:
        return (
          <Body variant="body3" color="gray8">
            {BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY_OPTION_TEXT}
          </Body>
        );
      default:
        return null;
    }
  }, []);

  const radioOptions = useMemo(() => {
    return visibilityRadioOptions.map((option: RadioOptionProps) => {
      const children = getRadioOptionCustomContent(option.value);
      if (children) {
        return {
          ...option,
          children,
        };
      }
      return option;
    });
  }, [getRadioOptionCustomContent]);

  const [value, setValue] = useState(VisibilityTypes.ENTIRE_ORGANIZATION);

  useEffect(() => {
    if (visibilityBlockData?.onlyParticipants) {
      setValue(VisibilityTypes.PARTICIPANTS_ONLY);
    }

    if (visibilityBlockData?.onlyOwnersAndCollaborators) {
      setValue(VisibilityTypes.OWNER_AND_COLLABORATORS_ONLY);
    }

    if (visibilityBlockData?.everyone) {
      setValue(VisibilityTypes.ENTIRE_ORGANIZATION);
    }

    if (visibilityBlockData?.custom) {
      setValue(VisibilityTypes.CUSTOM);
    }
  }, [visibilityBlockData]);

  const rulesFromCriteria = useMemo(() => {
    const ownerAndCollaboratorsOnlyCriteria = {
      criteria: {
        onlyOwnersAndCollaborators: true,
      },
    };
    switch (value) {
      case VisibilityTypes.ENTIRE_ORGANIZATION:
        return {
          criteria: {
            everyone: true,
          },
        };

      case VisibilityTypes.PARTICIPANTS_ONLY:
        return mapRulesFromCriteriaGroups(visibilityParticipantsCriteria);

      case VisibilityTypes.CUSTOM:
        return visibilityBlockData?.criteriaGroups &&
          visibilityBlockData?.criteriaGroups.groups.length
          ? mapRulesFromCriteriaGroups(
              visibilityBlockData?.criteriaGroups,
              undefined,
              true,
              true,
            )
          : ownerAndCollaboratorsOnlyCriteria;

      case VisibilityTypes.OWNER_AND_COLLABORATORS_ONLY:
        return ownerAndCollaboratorsOnlyCriteria;

      default:
        return null;
    }
  }, [value, visibilityParticipantsCriteria, visibilityBlockData]);

  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowBuilderData } = useFetchFlowDetailsQuery(flowId, 'builder');

  const includesPayload = useMemo(() => {
    return getVisibilityCriteriaForCurrentFlow({
      ownerID: flowBuilderData?.data.owner.memberID || '',
      collaborators: flowBuilderData?.data.collaborators,
    });
  }, [
    flowBuilderData?.data.collaborators,
    flowBuilderData?.data.owner.memberID,
  ]);

  const {
    isLoading,
    data: searchedMembers,
    hasNextPage: hasMoreMembers,
    totalMembersCount: participantsCount,
    fetchNextPage: fetchMoreMembers,
    pendingMembersCount: pendingMembersParticipantCount,
  } = useGetFinalizedMembersFromCriteriaForPreview({
    ...getCriteriaPayloadForVisibility(
      rulesFromCriteria?.criteria,
      flowBuilderData,
      value,
    ),
    includes: includesPayload,
  } as GetMembersForPreviewRequest);

  const participants: Participant[] = useMemo(() => {
    let participantsList: Participant[] = searchedMembers
      ? searchedMembers.map((participant: IMemberDTO) => {
          return getParticipantBlockForFlowParticipant(participant, false);
        })
      : [];

    if (flowBuilderData) {
      const owner = getParticipantBlockForFlowParticipant(
        flowBuilderData?.data.owner,
        true,
      );

      const collaborators = serializeCollaboratorToMemberDTO(
        flowBuilderData.data.collaborators,
      )
        .filter(
          (collaborator) =>
            collaborator.memberState !== MemberState.DEACTIVATED,
        )
        .map((collaborator) =>
          getParticipantBlockForFlowParticipant(collaborator, true),
        );

      participantsList = [owner, ...collaborators, ...participantsList];
    }

    return participantsList.filter(
      (item, index) =>
        !item.memberId ||
        index ===
          participantsList.findIndex((i) => i.memberId === item.memberId),
    );
  }, [searchedMembers, flowBuilderData]);

  const updateVisibilityBlockData = useCallback(
    (updatedContentBlock: VisibilityBuilderBlockData) =>
      updateVisibilityParticipantsBlockData(updatedContentBlock),
    [updateVisibilityParticipantsBlockData],
  );

  const handleOnCriteriaGroupsChange = useCallback(
    (val: CriteriaGroups | undefined) => {
      const updatedVisibilityBlockDataData = {
        ...visibilityBlockData,
        criteriaGroups: val || undefined,
        everyone: visibilityBlockData?.everyone,
        onlyParticipants: visibilityBlockData?.onlyParticipants,
        onlyOwnersAndCollaborators:
          visibilityBlockData?.onlyOwnersAndCollaborators,
        custom: visibilityBlockData?.custom,
        type: value,
      };
      updateVisibilityBlockData(updatedVisibilityBlockDataData);
    },
    [value, updateVisibilityBlockData, visibilityBlockData],
  );

  const handleVisibilitySelection = useCallback(
    (selectionType: string) => {
      const visibilityToggle = {
        everyone: false,
        onlyParticipants: false,
        onlyOwnersAndCollaborators: false,
        custom: false,
      };
      switch (selectionType) {
        case VisibilityTypes.ENTIRE_ORGANIZATION:
          visibilityToggle.everyone = true;
          break;
        case VisibilityTypes.PARTICIPANTS_ONLY:
          visibilityToggle.onlyParticipants = true;
          break;
        case VisibilityTypes.OWNER_AND_COLLABORATORS_ONLY:
          visibilityToggle.onlyOwnersAndCollaborators = true;
          break;
        case VisibilityTypes.CUSTOM:
          visibilityToggle.custom = true;
          break;
        default:
          break;
      }
      const updatedVisibilityBlockDataData: VisibilityBuilderBlockData = {
        ...visibilityBlockData,
        ...visibilityToggle,
        type: selectionType,
      };

      updateVisibilityBlockData(updatedVisibilityBlockDataData);
    },
    [updateVisibilityBlockData, visibilityBlockData],
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedType: string = e.target.value;
      setValue(updatedType as VisibilityTypes);
      handleVisibilitySelection(updatedType);
    },
    [handleVisibilitySelection],
  );

  return {
    models: {
      value,
      variant,
      flowBuilderData,
      isLoading,
      radioOptions,
      hasNextPage: hasMoreMembers,
      participantsCount,
      participants,
      blockData: visibilityBlockData,
      pendingMembersParticipantCount,
    },
    operations: {
      onChange,
      fetchNextPage: fetchMoreMembers,
      handleOnCriteriaGroupsChange,
      handleVisibilitySelection,
    },
  };
};

export default useShareSheetVisibilityController;
