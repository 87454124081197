import { CREATE_FLOW, CREATE_POST } from './constants';

export const getMenuItems = (canUserCreateFlow: boolean) => {
  const items = [
    {
      id: CREATE_POST,
      isSelected: false,
      value: 'Post',
      prefixIcon: 'post-bw',
    },
  ];

  if (canUserCreateFlow) {
    items.push({
      id: CREATE_FLOW,
      isSelected: false,
      value: 'Flow',
      prefixIcon: 'routine',
    });
  }

  return items;
};
