import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import SVGIcon from '../../atoms/SVGIcon';
import DropdownIconButton from '../DropdownIconButton';

export const StyledFlex = styled(Flex)<{
  hasPaddingLeft?: boolean;
  tabRouteBase?: string;
  compactMode?: boolean;
  isEmbeddedInMainApp?: boolean;
}>`
  width: 100%;
  padding: ${({ compactMode, tabRouteBase, isEmbeddedInMainApp }) =>
    compactMode
      ? '4px 0 4px 0'
      : tabRouteBase
      ? `${isEmbeddedInMainApp ? '16px 32px 8px 0' : '16px 32px 8px 16px'}`
      : `${isEmbeddedInMainApp ? '16px 16px 16px 0' : '16px'}`};
  padding-left: ${({ hasPaddingLeft }) => hasPaddingLeft && '16px'};
  max-height: 80px;
`;

export const StyledPageHeaderMainContent = styled.div`
  margin-left: 8px;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 4px 0 0;
`;

export const RightDrawerButtonWrapper = styled.div`
  margin: 0 8px 0 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 4px;
  background-color: transparent;
`;

export const ErrorIconContainer = styled(LogoContainer)`
  background-color: ${ThemeV2.palette.gray4};
`;

export const StyledLogo = styled.img`
  max-width: 100%;
  background-color: transparent;
`;

export const StyledButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

export const StyledSvgIcon = styled(SVGIcon)`
  margin-top: 2px;
  margin-left: 8px;
`;

export const StyledSpan = styled.span`
  margin: 0 4px;
`;

export const StyledVisibiltyLabel = styled(Body)`
  margin-left: 2px;
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  margin-left: 6px;
`;

export const StyledInviteButton = styled(Button)`
  margin-left: 8px;
`;
