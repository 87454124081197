import { AxiosError } from 'axios';
import { useMemo, useEffect, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { TaskHistoryActivity } from '../../../../atomic/molecules/Notebook/TaskHistory/interface';
import {
  GET_FLOW_BLOCK_PARTICIPANTS,
  GET_MEMBERS,
} from '../../../../constants/endpoints';
import {
  LayoutStoreState,
  SelectedMember,
} from '../../../../interfaces/Layout';
import {
  Assignee,
  DropdownOptions,
  NotebookTask,
  NotebookViews,
  SectionFromAPI,
  TaskCategories,
} from '../../../../interfaces/notebook';
import { useInviteUserPostQuery } from '../../../../queries/Admin/invites';
import {
  useGetNotebookBoardSectionsQuery,
  useGetNotebookRightDrawerTaskQuery,
  useGetNotebookRightDrawerTaskSectionQuery,
  useGetNotebookTaskAssigneeList,
  useGetNotebookTaskHistoryQuery,
} from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useLayoutStore from '../../../../stores/layoutStore';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import { isEmpty } from '../../../../Utils/common';
import { convertAPITaskToTaskAndCategory } from '../../../../Utils/notebook';
import {
  dismissAllToasts,
  showErrorMessage,
  showSuccessMessage,
} from '../../../../Utils/toast';
import { getMappedSectionsFromAPIResponse } from '../../NotebookBoardController/useNotebookBoardController/utils';
import { getTaskCategoryBasedOnDueDate } from '../../NotebookViewController/utils';
import { getAssigneesList, getUpdatedNotebookTask } from '../../utils';
import {
  rightDrawerTaskDropdownItems,
  RightDrawerTaskDropdownOperations,
} from './data';

const selector = (store: NotebookStore) => store.addSingleNote;
const idMapSelector = (store: NotebookStore) => store.idMap;
const functionSelector = (store: NotebookStore) => ({
  editTaskContent: store.editTaskContent,
  updateTask: store.updateTask,
  currentView: store.currentView,
  archiveTask: store.archiveTask,
  completedSectionId: store.completedSectionId,
  editSingleNote: store.editSingleNote,
  setCompletedSectionId: store.setCompletedSectionId,
  updateBoardSections: store.updateBoardSections,
  sectionalDropdownData: store.sectionalDropdownData,
  setModalData: store.setModalData,
  deleteTask: store.deleteTask,
});

const layoutSelector = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  isRightAsideOpen: state.isRightAsideOpen,
  setSelectedMember: state.setSelectedMember,
  setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
});

const useNotebookRightDrawerController = () => {
  const { search } = useLocation();
  const history = useHistory();
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId');
  }, [search]);
  const idMap = useNotebookStore(idMapSelector);
  const {
    currentView,
    editTaskContent,
    updateTask,
    archiveTask,
    completedSectionId,
    editSingleNote,
    setCompletedSectionId,
    updateBoardSections,
    sectionalDropdownData,
    setModalData,
    deleteTask,
  } = useNotebookStore(functionSelector, shallow);
  const taskId = useMemo(
    () => (selectedTaskId ? idMap[selectedTaskId] : undefined),
    [idMap, selectedTaskId],
  );

  const {
    setRightAsideOpenToTrue,
    setRightAsideOpenToFalse,
    isRightAsideOpen,
    setSelectedMember,
  } = useLayoutStore(layoutSelector, shallow);

  const note: NotebookTask | undefined = useNotebookStore(
    useCallback((store) => store.allNotes[taskId || 0], [taskId]),
  );

  const addSingleNote = useNotebookStore(selector);
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const [historyLogSort, setLogSort] = useState('desc');
  const [taskIdFocused, setTaskIdOfFocusedTask] = useState<string>('');
  const [searchValueForAssignees, setSearchValueForAssignees] =
    useState<string>('');

  const shouldMakeAPICall = Boolean(selectedTaskId) && note === undefined;

  const [isAccessError, setAccessError] = useState(false);

  const handleAccessError = useCallback((error: unknown) => {
    const axiosError = error as AxiosError<{ message: string }>;
    if (
      axiosError?.response?.status === 409 &&
      axiosError?.response?.data?.message !== 'MISSING_REQUIRED_PARAMETERS'
    ) {
      setAccessError(true);
    }
  }, []);

  const {
    data,
    isInitialLoading: isLoading,
    isError,
  } = useGetNotebookRightDrawerTaskQuery(
    [selectedTaskId || ''],
    shouldMakeAPICall,
    handleAccessError,
  );

  const {
    data: sectionData,
    isInitialLoading: isTaskSectionDataLoading,
    isError: isTaskSectionDataError,
  } = useGetNotebookRightDrawerTaskSectionQuery(
    selectedTaskId || '',
    Boolean(selectedTaskId),
  );

  const { data: sectionsDropdownData, isInitialLoading: isSectionLoading } =
    useGetNotebookBoardSectionsQuery();

  useEffect(() => {
    if (!isSectionLoading && sectionsDropdownData) {
      const { pages } = sectionsDropdownData;
      const sectionsFromAPI = pages.reduce<SectionFromAPI[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
      const { sections, completedSection } =
        getMappedSectionsFromAPIResponse(sectionsFromAPI);
      if (isEmpty(completedSectionId)) {
        setCompletedSectionId(completedSection);
      }
      updateBoardSections(sections);
    }
  }, [
    completedSectionId,
    isSectionLoading,
    sectionsDropdownData,
    setCompletedSectionId,
    updateBoardSections,
  ]);

  const toggleHistoryLogSort = useCallback(
    () => setLogSort(historyLogSort === 'asc' ? 'desc' : 'asc'),
    [historyLogSort],
  );

  useEffect(() => {
    if (selectedTaskId && !isRightAsideOpen) {
      setRightAsideOpenToTrue();
    }
    if (!selectedTaskId && isRightAsideOpen) {
      setRightAsideOpenToFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: taskHistoryData,
    isInitialLoading: isTaskHistoryLoading,
    hasNextPage: hasMoreLogsToFetch,
    isFetchingNextPage: isFetchingMoreLogs,
    fetchNextPage: fetchMoreLogs,
  } = useGetNotebookTaskHistoryQuery(
    selectedTaskId || '',
    historyLogSort,
    !isEmpty(selectedTaskId),
  );

  const taskHistoryList = useMemo(() => {
    if (taskHistoryData) {
      return taskHistoryData.pages.reduce<TaskHistoryActivity[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return [];
  }, [taskHistoryData]);

  const {
    data: assigneesListData,
    hasNextPage: hasMoreAssigneesToFetch,
    isFetchingNextPage: isFetchingMoreAssignees,
    fetchNextPage: fetchMoreAssignees,
  } = useGetNotebookTaskAssigneeList(
    taskIdFocused,
    !isEmpty(taskIdFocused),
    searchValueForAssignees,
  );

  const assigneesList = useMemo(() => {
    return getAssigneesList({
      taskIdFocused,
      searchValueForAssignees,
      assigneesListData,
    });
  }, [assigneesListData, searchValueForAssignees, taskIdFocused]);

  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  useEffect(() => {
    if (data?.length > 0 && !note) {
      const currentSectionId =
        sectionData?.data?.length > 0 ? sectionData?.data[0]?.sectionId : null;
      const currentSectionTitle =
        sectionData?.data?.length > 0 ? sectionData?.data[0]?.title : null;
      addSingleNote(
        convertAPITaskToTaskAndCategory(
          {
            ...data[0],
            section: currentSectionId,
            sectionTitle: currentSectionTitle,
          },
          currentUserTimezone,
        )[0],
      );
    }
  }, [
    data,
    addSingleNote,
    note,
    currentUserTimezone,
    isLoading,
    sectionData?.data,
  ]);

  useEffect(() => {
    if (selectedTaskId && !isRightAsideOpen) {
      setRightAsideOpenToTrue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentSectionId =
      sectionData?.data?.length > 0 ? sectionData?.data[0]?.sectionId : null;
    if (
      note &&
      !isTaskSectionDataLoading &&
      !isTaskSectionDataError &&
      currentSectionId !== null &&
      !isLoading
    ) {
      editSingleNote({
        ...note,
        section: currentSectionId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editSingleNote,
    isTaskSectionDataError,
    isTaskSectionDataLoading,
    sectionData,
  ]);

  const getSectionAndCategoryForTask = useCallback(
    (task: NotebookTask) => {
      if (
        note?.section === completedSectionId &&
        task?.section !== completedSectionId
      ) {
        return {
          section: task?.section,
          type: getTaskCategoryBasedOnDueDate(
            task?.dueDate,
            currentUserTimezone,
          ),
        };
      }
      if (
        completedSectionId &&
        (task?.type === TaskCategories.COMPLETED ||
          task?.section === completedSectionId)
      ) {
        return {
          section: completedSectionId,
          type: TaskCategories.COMPLETED,
        };
      }
      return {
        section: task?.section,
        type: task?.type,
      };
    },
    [completedSectionId, currentUserTimezone, note?.section],
  );

  const onUpdateTask = useCallback(
    (
      updatedTask: NotebookTask,
      previousCategory: TaskCategories,
      isAssignment = false,
    ) => {
      if (profileInfo) {
        const { memberId } = profileInfo.member;
        const sectionAndCategoryMap = getSectionAndCategoryForTask(updatedTask);
        updateTask(
          {
            ...updatedTask,
            ...sectionAndCategoryMap,
          },
          previousCategory,
          memberId,
          isAssignment,
        );
      }
    },
    [profileInfo, getSectionAndCategoryForTask, updateTask],
  );

  const { mutate: uploadInviteUserAction } = useInviteUserPostQuery();
  const queryClient = useQueryClient();

  const handleAssigneeChange = useCallback(
    (task: NotebookTask, _, newAssignee: Assignee) => {
      if (newAssignee?.emailToInvite) {
        const inviteUsers = [{ email: newAssignee.emailToInvite }];
        const inviteUsersPayload = {
          data: inviteUsers,
        };
        uploadInviteUserAction(inviteUsersPayload, {
          onSuccess: (inviteUsersData) => {
            queryClient.invalidateQueries([GET_MEMBERS]);
            queryClient.invalidateQueries([GET_FLOW_BLOCK_PARTICIPANTS]);
            if (inviteUsersData.data.addedMembers.length) {
              const updatedTask = getUpdatedNotebookTask(inviteUsersData, task);
              onUpdateTask(updatedTask, updatedTask?.type, true);
              dismissAllToasts();
              showSuccessMessage(
                `You successfully invited ${inviteUsersData.data.addedMembers[0].profile.firstName} to the platform!`,
              );
            }
            if (inviteUsersData.data.failedMembers.length) {
              showErrorMessage('Failed to send invite. Please try again');
            }
          },
        });
      } else {
        onUpdateTask(task, task?.type, true);
      }
    },
    [onUpdateTask, queryClient, uploadInviteUserAction],
  );

  const handleUserClick = useCallback(
    (memberData: SelectedMember) => {
      setSelectedMember(memberData);
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
    },
    [isRightAsideOpen, setRightAsideOpenToTrue, setSelectedMember],
  );

  const closeRightDrawer = () => {
    if (currentView !== NotebookViews.Feed) {
      setRightAsideOpenToFalse();
    }

    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('taskId')) {
      queryParams.delete('taskId');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const currentUserId = profileInfo?.member.memberId || '';
  const isReadOnly =
    currentUserId !== note?.assignedTo?.memberID &&
    currentUserId !== note?.createdBy?.memberID;
  const dropdownOptions: DropdownOptions[] = useMemo(
    () => [
      {
        id: 'notebook-right-drawer-task-options',
        items: rightDrawerTaskDropdownItems.filter((item) => {
          if (isReadOnly) {
            return (
              item.id !== RightDrawerTaskDropdownOperations.DELETE &&
              item.id !== RightDrawerTaskDropdownOperations.ARCHIVE
            );
          }
          if (note?.type === TaskCategories.ARCHIVED) {
            return item.id !== RightDrawerTaskDropdownOperations.ARCHIVE;
          }
          return item.id !== RightDrawerTaskDropdownOperations.DELETE;
        }),
      },
    ],
    [isReadOnly, note],
  );

  const handleArchiveTask = useCallback(
    (task: NotebookTask) => {
      const callbackFunction = () => {
        archiveTask(task);
      };
      setModalData(
        true,
        task.type,
        {
          updatedTask: task,
          previousCategory: note.type,
          updatedCategory: task.type,
        },
        callbackFunction,
        currentUserId,
      );
    },
    [setModalData, note?.type, currentUserId, archiveTask],
  );

  return {
    toggleHistoryLogSort,
    historyLogSort,
    currentUserId,
    onUpdateTask,
    editTaskContent,
    fetchMoreAssignees,
    handleAssigneeChange,
    setTaskIdOfFocusedTask,
    setSearchValueForAssignees,
    selectedTaskId,
    isLoading: isLoading && isTaskSectionDataLoading,
    assigneesList,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees,
    searchValueForAssignees,
    note,
    hasMoreLogsToFetch,
    isFetchingMoreLogs,
    handleUserClick,
    fetchMoreLogs,
    currentUserTimezone,
    isTaskHistoryLoading,
    taskHistoryList,
    isError,
    isAccessError,
    closeRightDrawer,
    dropdownOptions,
    archiveTask: handleArchiveTask,
    rightDrawerSectionMenu: sectionalDropdownData,
    isTaskSectionDataLoading,
    isReadOnly,
    deleteTask,
  };
};

export default useNotebookRightDrawerController;
