import { useCallback } from 'react';
import { useV3EditorEnabled } from './useV3EditorEnabled';

export const useOpenEditFlowInV3 = () => {
  const { isFlowEditV3 } = useV3EditorEnabled();

  return useCallback(
    (
      flowId: string,
      options?: { enabledCallback?: () => void; disabledCallback?: () => void },
    ) => {
      if (isFlowEditV3) {
        options?.enabledCallback?.();
        window.parent.postMessage(
          { type: 'EDIT_FLOW', payload: { flowId } },
          '*',
        );
      } else {
        options?.disabledCallback?.();
      }
    },
    [isFlowEditV3],
  );
};
