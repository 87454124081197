import React from 'react';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import { SortIconButton, KnowledgeCenterSortWrapper } from './styles';

const KnowledgeCenterSort = (props: {
  isSearched: boolean;
  hasFiles: boolean;
  sortedValue: string;
  setSortedValue: any;
}) => {
  const { isSearched, hasFiles, sortedValue, setSortedValue } = props;
  const isSortedAsc = sortedValue === 'asc';

  return (
    <KnowledgeCenterSortWrapper>
      <Flex padding="4px" width="100%" justifyContent="space-between">
        <Body color={ThemeV2.palette.gray8} margin="4px 0">
          {isSearched ? 'Search results' : 'All uploads'}{' '}
        </Body>
        {hasFiles && (
          <SortIconButton
            onClick={() => setSortedValue(isSortedAsc ? 'desc' : 'asc')}
            icon={isSortedAsc ? 'arrow-up' : 'arrow-down'}
            color="secondary"
            variant="text"
            size="small"
          >
            <Body margin="0 0 0 8px">Recency</Body>
          </SortIconButton>
        )}
      </Flex>
    </KnowledgeCenterSortWrapper>
  );
};

export default KnowledgeCenterSort;
