import React, { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  ShowMoreButton,
  StyledActionBarWrapper,
  StyledPostLoading,
  StyledPostLoadingText,
  StyledSpinningLoader,
} from './styles';

import { feedSortItems } from './data';
import { FeedsContainer, Flex } from '../../../Utils/styles/display';
import ActionBarController from '../../ActionBarController';
import { useMainFeedController } from './useMainFeedController';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import { PostSortValues } from '../../../interfaces/Feed';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';

import {
  DropdownColor,
  DropdownSizes,
  DropdownPlacement,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';

import FlowPostController from '../../flows/FlowPostController';
import RecognitionFlowController from '../RecognitionFlowController';
import { POST_LOADING, SEE_NEW_POSTS } from '../../../languages/en/feed';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';

import searching from '../../../img/searching.svg';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import DummyFlowFeedPost from '../../../atomic/organism/DummyFlowFeedPost';
import {
  celebrateTeamMateDummyPostData,
  celebrateTeammateHeaderProps,
} from '../../../atomic/organism/DummyFlowFeedPost/data';
import { PostTypes } from '../../../interfaces/Home';
import { FeedContext } from '../../../Utils/flows/feeds';
import useNotebookPusher from '../../notebook/NotebookViewController/useNotebookPusher';
import useUploadStore from '../../../stores/uploadStore';
import { shallow } from 'zustand/shallow';

export const MainFeedController = () => {
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();

  const { models, operations } = useMainFeedController();

  const { postActiveUploads } = useUploadStore(
    (state) => ({
      postActiveUploads: state.postActiveUploads,
    }),
    shallow,
  );

  const {
    data,
    isLoading,
    hasNextPage,
    profileData,
    feedsSortValue,
    showLoadMoreButton,
    isRecognitionFlowModalOpen,
  } = models;

  const {
    refetch,
    handleFetchNextPage,
    handleFeedSortClick,
    handleEditPostClick,
    setShowLoadMoreButtonToFalse,
    toggleIsRecognitionFlowModalOpen,
    feedContextProps,
    onFlowClick,
  } = operations;

  useNotebookPusher();

  const onLoadMoreClick = useCallback(() => {
    document.getElementById('scrollableFeedContent')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetch();
    setShowLoadMoreButtonToFalse();
  }, [refetch, setShowLoadMoreButtonToFalse]);

  const renderEmptyState = () => {
    if (profileData) {
      celebrateTeamMateDummyPostData.bodyProps.blocks[0] = {
        id: '1',
        type: 'PERSON_SELECTOR',
        content: {
          title: 'Who would you like to celebrate?',
          persons: [
            {
              id: '6081484184d8987b291e40b3',
              label: `${profileData.member.profile.firstName} ${profileData.member.profile.lastName}`,
              avatar: {
                img: profileData.member.profile.image,
                userId: profileData.member.memberId,
                name: profileData.member.profile.username,
              },
            },
          ],
        },
      };
    }

    if (
      !isMainFeedFilterApplied &&
      data.length === 0 &&
      !isLoading &&
      profileData
    ) {
      const newCelebrateTeammateHeaderProps = {
        ...celebrateTeammateHeaderProps,
        handleOnFlowClick: ({ flowId }: { flowId: string }) =>
          onFlowClick(flowId),
      };

      return (
        <DummyFlowFeedPost
          assembly={profileData.assembly}
          currentUser={profileData.member}
          headerProps={newCelebrateTeammateHeaderProps}
          {...celebrateTeamMateDummyPostData}
        />
      );
    }
    if (isMainFeedFilterApplied) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  return (
    <FeedsContainer>
      <StyledActionBarWrapper>
        <ActionBarController />
      </StyledActionBarWrapper>
      <Flex justifyContent="space-between" margin="0 auto 4px">
        <div>
          <Dropdown
            menuItems={feedSortItems}
            size={DropdownSizes.Small}
            variant={DropdownVariants.Text}
            color={DropdownColor.Secondary}
            value={PostSortValues[feedsSortValue]}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            onItemClick={(val) => handleFeedSortClick(val)}
          />
        </div>
        {isRecognitionFlowModalOpen && profileData && (
          <RecognitionFlowController
            profileInfo={profileData}
            onPostSuccess={refetch}
            toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
          />
        )}
      </Flex>
      {Object.keys(postActiveUploads).length > 0 && (
        <StyledPostLoading>
          <StyledPostLoadingText variant="body1Medium" color="geekBlue6">
            {POST_LOADING}
          </StyledPostLoadingText>
          <StyledSpinningLoader scale={0.2} />
        </StyledPostLoading>
      )}
      {isLoading && <FlowsFeedPostLoader />}
      {data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          <FeedContext.Provider value={feedContextProps}>
            {data.map((post, index) => (
              <FlowPostController
                index={index}
                key={post.postID}
                post={post}
                profileData={profileData}
                showEditOption={
                  (post.type === PostTypes.RECOGNITION &&
                    post.from?.memberID === profileData?.member.memberId) ||
                  post.flowResponse?.canEdit
                }
                handleEditPostClick={handleEditPostClick}
                hideReactions={post.flowResponse?.hideReactions}
                hideRepliesBar={post.flowResponse?.hideReplies}
                showPostInteractionSettings={false}
              />
            ))}
          </FeedContext.Provider>
        </InfiniteScroll>
      )}
      {!isLoading && data.length === 0 && renderEmptyState()}
    </FeedsContainer>
  );
};
