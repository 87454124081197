import React from 'react';
import useDropDown from '../../../hooks/useDropDown';
import IconButton from '../../atoms/IconButton_V2';
import DropdownListHolder, { DropdownWrapper } from '../DropdownListHolder';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { DropdownIconButtonProps } from './interfaces';
import IconButtonWithToolTip from '../IconButtonWithToolTip';

const DropdownIconButton = (props: DropdownIconButtonProps) => {
  const {
    size,
    icon,
    rounded,
    isSelected,
    title,
    className,
    color,
    iconColor,
    dropdownPlacement = DropdownPlacement.BottomEnd,
    isDropdownPositionFixed,
    dataTestId,
    dataQaId,
    onClick,
    stopEventPropagation,
    iconBgColor,
    variant,
    customColorConfig,
    anchorEl: anchorElProp,
    toolTipContent,
  } = props;

  const { anchorProps } = useDropDown(props);
  const {
    referenceElement,
    isOpen,
    toggleDropdown,
    isFullWidth,
    disabled,
    anchorEl: anchorElDropdown,
  } = anchorProps;

  const anchorEl = anchorElProp || anchorElDropdown;

  const commonIconButtonProps = {
    buttonRef: anchorEl,
    disabled,
    icon,
    iconBgColor,
    iconColor,
    isSelected: (!disabled && isOpen) || isSelected,
    onClick: (e: MouseEvent) => {
      toggleDropdown();
      if (onClick) onClick();
      if (stopEventPropagation) {
        e.stopPropagation();
      }
    },
    rounded,
    size,
    title,
  };

  let buttonContent;

  if (toolTipContent) {
    buttonContent = (
      <IconButtonWithToolTip
        {...commonIconButtonProps}
        toolTipContent={toolTipContent}
      />
    );
  } else {
    buttonContent = (
      <IconButton
        {...commonIconButtonProps}
        color={color}
        variant={variant}
        dataTestId={dataTestId}
        dataQaId={dataQaId}
        customColorConfig={customColorConfig}
      />
    );
  }

  return (
    <DropdownWrapper
      ref={referenceElement}
      isFullWidth={isFullWidth}
      className={className}
    >
      {buttonContent}
      <DropdownListHolder
        {...anchorProps}
        anchorEl={anchorEl}
        dropdownPlacement={dropdownPlacement}
        isDropdownPositionFixed={isDropdownPositionFixed}
      />
    </DropdownWrapper>
  );
};

DropdownIconButton.defaultProps = {
  selectedMenuItemID: null,
  menuItems: [],
  onItemClick: () => {},
  disabled: false,
  size: 'normal',
  icon: '',
  rounded: false,
  isSelected: false,
  title: '',
  multiSelect: false,
  dataTestId: '',
  dataQaId: '',
};

const MemoizedDropdownIconButton = React.memo(DropdownIconButton);
MemoizedDropdownIconButton.displayName = 'DropdownIconButton';
export default MemoizedDropdownIconButton;
