import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';
import SVGIcon from '../../atoms/SVGIcon';

interface IconButtonProps {
  isActiveView: boolean;
}

export const KnowledgeCenterViewWrapper = styled.div`
  display: flex;
  width: 80px;
  height: 40px;
  border: 0.5px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin-left: 8px;
`;

export const StyledIcon = styled(SVGIcon)`
  cursor: pointer;
`;

export const KnowledgeCenterActionsWrapper = styled.div`
  padding: 16px;
`;

export const KnowledgeCenterStyledIconButton = styled(
  IconButton,
)<IconButtonProps>`
  margin: 4px;
  box-shadow: none;
  &:not(:disabled):hover {
    box-shadow: none;
  }
`;
