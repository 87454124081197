import { useState } from 'react';

import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import useEndOccurrenceOption from '../useFlowFeedOptions/useEndOccurrenceOption';

const useEndFlowOccurrenceModal = () => {
  const [flowDetails, setFlowDetails] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >();
  const flowId = flowDetails ? flowDetails.id : '';
  const {
    models: { isEndOccurrenceModalOpen, isEndOccurrenceModalLoading },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  } = useEndOccurrenceOption();
  const onEndOccurrenceClick = () => {
    if (flowId) {
      handleEndOccurrenceButtonClick(flowId);
    }
  };

  return {
    flowDetails,
    isEndOccurrenceModalLoading,
    isEndOccurrenceModalOpen,
    onEndOccurrenceClick,
    setEndOccurrenceModalClose,
    setIsEndOccurrenceModalOpen,
    setFlowDetails,
  };
};

export default useEndFlowOccurrenceModal;
