import styled from 'styled-components';

import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import assemblyQRCode from '../../../img/assemblyQRCode.svg';
import BasicModalTemplate from '../../templates/BasicModalTemplate';

type AssemblyQRCodeModalProps = {
  isOpen: boolean;
  onModalClose(): void;
};

const StyledModal = styled(BasicModalTemplate)`
  max-width: 570px;
  width: 100%;
  .makeStyles-childrenWrapper-102 {
    padding: 28px;
  }
`;

const StyledQRCode = styled(Image)`
  margin: 8px auto auto auto;
`;

const AssemblyQRCodeModal = (props: AssemblyQRCodeModalProps) => {
  const { isOpen, onModalClose } = props;
  return (
    <StyledModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      heading="Get our mobile app!"
    >
      <Body variant="body2">
        Scan this QR code using your phone’s camera to be redirected to the App
        Store. You can also search for “Assembly - Digital HQ” in the App Store
        to find it yourself.
      </Body>
      <StyledQRCode
        width="320"
        height="320"
        alt="assemblyQRCode"
        src={assemblyQRCode}
      />
    </StyledModal>
  );
};

export default AssemblyQRCodeModal;
