import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { zIndexKioskFooter } from '../../../Utils/styles/z-index';

export const KioskFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  bottom: 0;
  height: 42px;
  background: ${ThemeV2.palette.gold2};
  width: 100%;
  z-index: ${zIndexKioskFooter};
`;

export const LogOutButton = styled(Body)`
  margin-left: 4px;
  cursor: pointer;
  text-decoration: underline;
`;
