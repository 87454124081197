import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import { EmojiData } from 'emoji-mart';

import {
  addAtSymbolToEditorState,
  addEmojiToEditorState,
} from '../../../Utils/draftjs';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import { EntryProps } from '../../atoms/Giphy/interface';
import { CORE_VALUE_DEFAULT_TEXT } from '../../../languages/en/giveRecognitionForm';
import {
  getIsEditorHavingText,
  getTextFromEditor,
  getTrimmedMessage,
} from '../../../Utils/draftjs/mention';
import { Mention } from '../../../interfaces/user';

const useGiveRecognitionFormLogic = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const selectedMentions = useRef<Mention[]>([]);
  const [gifUrl, setGifUrl] = useState<string>('');
  const [hasText, setHasText] = useState(false);
  const [isEditorFocused, setEditorFocused] = useState(false);
  const [trophiesInputValue, onTrophiesInputChange] = useState<number>(0);
  const [canShowRecognitionTips, setCanShowRecognitionTips] = useState(false);
  const [selectedCoreValue, setSelectedCoreValue] = useState(
    CORE_VALUE_DEFAULT_TEXT,
  );
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const [inputErrorMessage, setInputErrorMessage] = useState<
    string | undefined
  >();

  const editorRef = useRef<Editor>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const setEditorFocus = useCallback(() => {
    editorRef.current?.focus();
  }, []);

  const focusEditorAfterDelay = useCallback((delay = 200) => {
    setTimeout(() => editorRef.current?.focus(), delay);
  }, []);

  const handleAtMentionClick = useCallback(() => {
    setEditorFocused(true);
    const newEditorState = addAtSymbolToEditorState(editorState);
    setEditorState(newEditorState);
    focusEditorAfterDelay();
  }, [editorState, focusEditorAfterDelay]);

  useEffect(() => {
    const isEditorStateContainText = getIsEditorHavingText(editorState);
    setHasText(isEditorStateContainText);
  }, [editorState]);

  const handleEmoticonClick = useCallback(
    (emojiData: EmojiData) => {
      setEditorFocused(true);
      const newEditorState = addEmojiToEditorState(emojiData, editorState);
      setEditorState(newEditorState);
      focusEditorAfterDelay();
    },
    [editorState, focusEditorAfterDelay],
  );

  const handleGifSelectorClick = useCallback((entry: EntryProps) => {
    let mediaAdded = entry.images.downsized_medium.url;
    if (mediaAdded.length === 0) {
      mediaAdded = entry.images.original.url;
    }
    setGifUrl(mediaAdded);
  }, []);
  const handleGifRemoveClick = useCallback(() => {
    setGifUrl('');
  }, []);

  const handleAddMention = useCallback((mention) => {
    const { id, name }: Mention = mention;
    selectedMentions.current.push({ id, name });
  }, []);

  const handleEditorFocus = useCallback(() => {
    setEditorFocused(true);
    setCanShowRecognitionTips(true);
  }, []);
  const handleEditorBlur = useCallback(() => {}, []);

  const onOutsideClickHandler = useCallback(() => {
    setEditorFocused(false);
  }, []);

  const handlePrivateMessageClick = useCallback(() => {
    setIsPrivate(!isPrivate);
  }, [isPrivate]);

  const handleAttachmentClick = useCallback(() => {
    // eslint-disable-next-line no-alert
    alert('onAttachmentClick');
  }, []);

  const message = useMemo(() => {
    const mentionsToReplace: Mention[] = [];
    const idDict: { [Key: string]: boolean } = {};
    selectedMentions.current.forEach((mention: Mention) => {
      if (!idDict[mention.id]) {
        mentionsToReplace.push(mention);
        idDict[mention.id] = true;
      }
    });
    const { text: editorText } = getTextFromEditor(
      editorState,
      mentionsToReplace,
    );
    return getTrimmedMessage(editorText);
  }, [selectedMentions, editorState]);

  const resetInput = useCallback(() => {
    setEditorState(EditorState.createEmpty());
    setHasText(false);
    onTrophiesInputChange(0);
    setGifUrl('');
    setIsPrivate(false);
    selectedMentions.current = [];
    // For Recognition flow
    setCanShowRecognitionTips(false);
    setSelectedCoreValue(CORE_VALUE_DEFAULT_TEXT);
    setTimeout(() => {
      editorRef.current?.focus();
    });
  }, []);

  useOnClickOutside(containerRef, onOutsideClickHandler);

  return {
    models: {
      message,
      editorState,
      gifUrl,
      hasText,
      isEditorFocused,
      trophiesInputValue,
      refElement: editorRef,
      containerRef,
      selectedMentions: selectedMentions.current,
      canShowRecognitionTips,
      selectedCoreValue,
      isPrivate,
      inputErrorMessage,
    },
    operations: {
      setEditorState,
      onTrophiesInputChange,
      setGifUrl,
      onAtMentionClick: handleAtMentionClick,
      onEmoticonClick: handleEmoticonClick,
      onGifSelectorClick: handleGifSelectorClick,
      onGifRemoveClick: handleGifRemoveClick,
      onAddMention: handleAddMention,
      onEditorFocus: handleEditorFocus,
      onEditorBlur: handleEditorBlur,
      onPrivateMessageClick: handlePrivateMessageClick,
      onAttachmentClick: handleAttachmentClick,
      setSelectedMentions: selectedMentions,
      setSelectedCoreValue,
      resetInput,
      setInputErrorMessage,
      setEditorFocus,
      focusEditorAfterDelay,
    },
  };
};

export default useGiveRecognitionFormLogic;
