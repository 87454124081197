import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  FeedItemFromAPI,
  FeedItemMetaData,
  ResponseDetailsPayload,
} from '../../../../interfaces/Feed';
import { useGetProfileFeedQuery } from '../../../../queries/ProfileFeed';
import useToggle from '../../../../hooks/useToggle';
import useFlowFeed from '../../../../hooks/useFlowFeed';
import { FLOWS, RECOGNITION_PARTICIPATE } from '../../../../constants/routes';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import {
  useProfileFeedUpdateFlowPostReactionMutation,
  useProfileFeedUpdatePostReactionMutation,
} from '../../../../queries/Feed';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';
import { transformMemberToAutoCompleteDropdownItem } from '../utils';
import { PostTypes } from '../../../../interfaces/Home';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import useReactionUpdatedSocket from '../../../../hooks/useReactionUpdatedSocket';
import { PageType } from '../../../../hooks/useReactionUpdatedSocket/types';
import usePostInteractionSettingUpdatedSocket from '../../../../hooks/usePostInteractionSettingUpdatedSocket';
import useLayoutStore from '../../../../stores/layoutStore';
import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';
import { CARD_ACTION_EVENTS } from '../../../../Utils/analytics/constants';
import { trackCardActionEvent } from '../../../../Utils/analytics/discover';
import { useEntityPermissionsQuery } from '../../../../queries/Flows/Feed';
import { useReactionsSocket } from '../../../../hooks/useReactionsSocket';
import { useRepliesUpdatedSocket } from '../../../../hooks/useRepliesUpdatedSocket';

export const useProfileFeedController = () => {
  const history = useHistoryWrapper();

  const { filter } = useGetParsedFilterOptions();
  const { userId } = useParams<{ userId: string }>();
  const { data: selectedProfileData } = useFetchMembersDetailsQuery([userId]);

  const { mutate: mutateFlowPostReaction } =
    useProfileFeedUpdateFlowPostReactionMutation({ userId, filter });

  const { mutate: mutatePostReaction } =
    useProfileFeedUpdatePostReactionMutation({ userId, filter });

  const { feedContextProps, isProfileInfoLoading, profileData } = useFlowFeed({
    mutateFlowPostReaction,
    mutatePostReaction,
  });

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  useReactionsSocket();
  useReactionUpdatedSocket({
    pageType: PageType.ProfileFeed,
    filter: filter,
    profileId: userId,
  });
  usePostInteractionSettingUpdatedSocket({
    pageType: PageType.ProfileFeed,
    filter: filter,
    profileId: userId,
  });
  useRepliesUpdatedSocket({
    filter,
    profileId: userId,
  });

  const handleCelebrateTeammateClick = useCallback(() => {
    history.push(`${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`);
  }, [history]);

  const handleEditPostClick = useCallback(
    (flow: FeedItemMetaData) => {
      if (flow.type === PostTypes.FLOW) {
        const editPostUrl = `${FLOWS}/${flow.flowId}/edit/${flow.responseId}?redirectUrl=${location.pathname}`;
        history.push(editPostUrl);
      } else {
        const editPostUrl = `${FLOWS}/recognition/edit/${flow.postId}?redirectUrl=${location.pathname}`;
        history.push(editPostUrl);
      }
    },
    [history],
  );

  const {
    data,
    refetch,
    isInitialLoading: isFeedLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetProfileFeedQuery({ userId, filter });

  const { data: entityPermissionResponse } = useEntityPermissionsQuery({
    entityIds:
      data?.pages.flatMap((x) =>
        x?.data?.map((y) => y?.flowResponse?.responseId ?? '').filter(Boolean),
      ) ?? [],
  });

  const isLoading = isFeedLoading || isProfileInfoLoading;

  const feedProps = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.reduce<FeedItemFromAPI[]>(
      (arr, page) => [
        ...arr,
        ...page.data.map((response) => {
          const selectedPermission = entityPermissionResponse?.data.data.find(
            (entityPermission) =>
              entityPermission.entityId === response?.flowResponse?.responseId,
          );
          return selectedPermission
            ? {
                ...response,
                flowResponse: response.flowResponse
                  ? { ...response.flowResponse, ...selectedPermission }
                  : undefined,
              }
            : response;
        }),
      ],
      [],
    );
  }, [data, entityPermissionResponse?.data.data]);

  const isOwnProfileFeed = useMemo(() => {
    return profileData && profileData.member.memberId === userId;
  }, [profileData, userId]);

  const selectedProfileUser = useMemo(() => {
    if (selectedProfileData && selectedProfileData.membersDetails?.length > 0) {
      return selectedProfileData.membersDetails[0];
    }
    return null;
  }, [selectedProfileData]);

  const profileDataForRecognitionModalDropdown = useMemo(() => {
    if (isOwnProfileFeed || !selectedProfileData) {
      return undefined;
    }

    const memberDetails = selectedProfileData.membersDetails[0];
    return [transformMemberToAutoCompleteDropdownItem(memberDetails)];
  }, [isOwnProfileFeed, selectedProfileData]);

  // Edit Post Interaction Settings
  const { isEmbeddedInMainApp } = useLayoutStore();
  const handlePostInteractionSettingsClick = (response?: FlowFeedResponse) => {
    if (isEmbeddedInMainApp && response) {
      const responseDetailsPayload: ResponseDetailsPayload = {
        author: response.respondent?.name || '',
        flowName: response.flow.name,
        emoticon: response.flow.icon.value,
        allowReactions: !response.hideReactions,
        allowReplies: !response.hideReplies,
        responseId: response.responseId,
        flowId: response.flow.flowId,
      };
      trackCardActionEvent({
        action: CARD_ACTION_EVENTS.EDIT_POST_INTERACTIONS_CLICKED,
        isReactionsDisabled: response.hideReactions ?? false,
        isRepliesDisabled: response.hideReplies ?? false,
      });
      window.parent.postMessage(
        {
          type: 'OPEN_CONFIGURE_POST_INTERACTION_SETTINGS_MODAL',
          payload: responseDetailsPayload,
        },
        '*',
      );
    }
  };

  return {
    models: {
      isLoading,
      hasNextPage,
      profileData,
      selectedProfileUser,
      isOwnProfileFeed,
      data: feedProps,
      isEmbeddedInMainApp,
      isRecognitionFlowModalOpen,
      profileDataForRecognitionModalDropdown,
    },
    operations: {
      refetch,
      handleFetchNextPage: fetchNextPage,
      toggleIsRecognitionFlowModalOpen,
      feedContextProps,
      handleEditPostClick,
      handleCelebrateTeammateClick,
      handlePostInteractionSettingsClick,
    },
  };
};
