import React from 'react';
import Divider from '../../atoms/Divider';
import Modal from '../../atoms/Modal';
import MemoizedTextFieldWithChips from '../../molecules/TextfieldWithChips';

import {
  INVITE_ASSEMBLY,
  ENTER_TEAMMATES_EMAIL,
  INVITE_HELPER_TEXT,
  INVITE_MODAL_PLACEHOLDER,
  REQUEST_INVITE_TEAMMATES,
} from '../../../languages/en/flows';

import {
  ModalBackground,
  SubmitButton,
  ModalHeading,
  ModalBody,
  ModalFooter,
  CloseIconButton,
  MessageBackgroundInner,
} from './styles';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
export interface InviteModalProps {
  isOpen: boolean;
  onClose: () => void;
  emails: string[];
  textFieldValue: string;
  handleEmailsChange: (
    event: React.ChangeEvent<unknown>,
    value: string[],
  ) => void;
  onInviteClick: (event: React.MouseEvent<HTMLElement>) => void;
  onCopyInvite: () => void;
  setTextFieldValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  validation?: (value: string) => boolean;
  isInviteRequiresApproval: boolean;
  canShowCopyInviteLink: boolean | undefined;
  isFullScreen?: boolean;
}

const InviteModal = (props: InviteModalProps) => {
  const {
    isOpen,
    onClose,
    emails,
    textFieldValue,
    handleEmailsChange,
    onInviteClick,
    onCopyInvite,
    setTextFieldValue,
    validation,
    isInviteRequiresApproval,
    canShowCopyInviteLink,
    isFullScreen,
  } = props;

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <ModalBackground isFullScreen={isFullScreen}>
        <MessageBackgroundInner>
          <CloseIconButton
            icon="close-rounded"
            onClick={onClose}
            size={ButtonSize.Normal}
          />
          <ModalHeading variant="h5">
            {isInviteRequiresApproval
              ? REQUEST_INVITE_TEAMMATES
              : INVITE_ASSEMBLY}
          </ModalHeading>
          <Divider isFullWidth />
          <ModalBody isFullScreen={isFullScreen}>
            <MemoizedTextFieldWithChips
              name={ENTER_TEAMMATES_EMAIL}
              placeholder={INVITE_MODAL_PLACEHOLDER}
              helperText={INVITE_HELPER_TEXT}
              value={emails}
              textFieldValue={textFieldValue}
              onChange={handleEmailsChange}
              onTextFieldChange={setTextFieldValue}
              options={[]}
              truncate={45}
              validation={validation}
            />
          </ModalBody>
          <Divider isFullWidth />
          <ModalFooter>
            {canShowCopyInviteLink && (
              <SubmitButton
                onClick={onCopyInvite}
                color="primary"
                variant="text"
                icon="Link"
              >
                Copy invite link
              </SubmitButton>
            )}
            <SubmitButton
              onClick={onInviteClick}
              disabled={!(emails.length > 0 || textFieldValue.length > 0)}
            >
              Send
            </SubmitButton>
          </ModalFooter>
        </MessageBackgroundInner>
      </ModalBackground>
    </Modal>
  );
};
export default InviteModal;
