import React from 'react';
import styled from 'styled-components';

import Body from '../../atomic/atoms/Body';
import Heading from '../../atomic/atoms/Heading';
import SVGIcon from '../../atomic/atoms/SVGIcon';
import MainFeedLinkButton from '../../atomic/molecules/MainFeedLinkButton';
import ThemeV2 from '../../theme';
import { Flex } from '../../Utils/styles/display';

const StyledWrapper = styled(Flex)`
  width: 100%;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0px;
`;

const WarningIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  width: 48px;
  height: 48px;
  background-color: ${ThemeV2.palette.gray4};
  border-radius: 50%;
`;
interface ProfileHeaderErrorProps {
  header: string;
  subheader: string;
}

const ProfileHeaderError = ({ header, subheader }: ProfileHeaderErrorProps) => {
  return (
    <StyledWrapper padding="0 32px 0 0">
      <WarningIconWrapper>
        <SVGIcon
          color={ThemeV2.palette.gray7}
          icon="warning-hollow"
          size="26px"
        />
      </WarningIconWrapper>
      <ErrorMessageWrapper>
        <StyledHeading color={ThemeV2.palette.gray6} variant="h6">
          {header}
        </StyledHeading>
        <Body color="gray7">{subheader}</Body>
      </ErrorMessageWrapper>
      <MainFeedLinkButton />
    </StyledWrapper>
  );
};

export default ProfileHeaderError;
