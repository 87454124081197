import { parse } from 'qs';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import ConnectionRedirectPath from '../atomic/organism/ConnectToSlack/ConnectionRedirectPath';
import PageNotFound from '../atomic/pages/404';
import KioskPage from '../atomic/pages/KioskPage';
import Landing from '../atomic/pages/Landing';
import NewAdminRoutesComponent from '../atomic/pages/NewAdminRoutes';
import Signout from '../atomic/pages/SignOut';
import { DEVICE, DEVICES } from '../constants/devices';
import {
  AUTH_SAML,
  EXTERNAL_PATH,
  FILE_PREVIEW,
  FLOWS,
  FLOWS_PARTICIPATE,
  KIOSK_PAGE_PATH,
  MOBILE_ROUTE_PATH,
  ONBOARDING_SETUP,
  TEMPLATE_PREVIEW,
  USER_ACCOUNT_SETUP,
  USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN,
} from '../constants/routes';
import ExternalRoutesController from '../controllers/ExternalRoutesController';
import FilePreviewController from '../controllers/FilePreviewController';
import FlowsParticipationController from '../controllers/flows/FlowsParticipationController';
import FlowsPreviewController from '../controllers/flows/FlowsPreviewController';
import OnBoardingWithoutOptionsController from '../controllers/OnBoardingWithoutOptionsController';
import SAMLAuthController from '../controllers/SAMLAuthController';
import UserOnBoardingController from '../controllers/UserOnBoardingController';
import UserSetupController from '../controllers/UserSetupController';
import V2RouteController from '../controllers/V2RouteController';
import { useMemberAuthenticated } from '../hooks/useAuth';
import { useEnforceV3Experience } from '../hooks/useEnforceV3Experience';
import RoutesList from '../routesList';
import { trackPageView } from '../Utils/analytics';
import { setSourceDeviceAsIPhone } from '../Utils/device';
import MobileRouters from './mobileRouters';
import SlugUrlController from './slugUrlController';

const MultiAssemblyRouters = ({ slugUrl }: { slugUrl: string }) => {
  let SLUG_URL = slugUrl;
  const location = useLocation();
  const { search, pathname } = location;
  useMemberAuthenticated();

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isDevicePresent = parsedParams && parsedParams[DEVICE];
    if (isDevicePresent) {
      const { device } = parsedParams;
      if (device === DEVICES.IPHONE) {
        setSourceDeviceAsIPhone();
      }
    }
  }, [search]);

  useEffect(() => {
    window.parent.postMessage(
      { type: 'ROUTE_CHANGE', payload: location.pathname },
      '*',
    );
  }, [location.pathname]);

  useEffect(() => {
    window.parent.postMessage(
      { type: 'PARAMS_CHANGE', payload: location.search },
      '*',
    );
  }, [location.search]);

  useEnforceV3Experience({ slugUrl });

  /* this check is added to handle links when the user is signed out */
  /*  !pathname.startsWith('/flows/') is added to let the public flow work as expected */
  if (
    !pathname.startsWith('/auth/') &&
    !pathname.startsWith('/template/') &&
    !pathname.startsWith('/flows/') &&
    !pathname.startsWith('/e/') &&
    slugUrl.includes('undefined')
  ) {
    const [, ...parts] = pathname.split('/');
    SLUG_URL = parts.join('/');
  }

  return (
    <Switch>
      <Route
        path={RoutesList.CONNECTION_REDIRECT_URL}
        component={ConnectionRedirectPath}
      />
      <Route path="/admin" component={NewAdminRoutesComponent} />
      <Route path="/signout" component={Signout} />
      <Route path={ONBOARDING_SETUP} component={UserOnBoardingController} />
      <Route
        path={USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN}
        component={OnBoardingWithoutOptionsController}
      />
      <Route path="/security/saml" component={NewAdminRoutesComponent} />
      <Route
        path={RoutesList.BOT_AUTHORIZE}
        component={NewAdminRoutesComponent}
      />
      <Route
        path={`/${SLUG_URL}${FILE_PREVIEW}/:flowId/:responseId/:blockId/:fileName`}
        component={FilePreviewController}
      />
      <Route
        path={RoutesList.IM_AUTHORIZE}
        component={NewAdminRoutesComponent}
      />
      <Route path={`/${SLUG_URL}`} component={SlugUrlController} />
      <Route path={`${EXTERNAL_PATH}`} component={ExternalRoutesController} />
      <Route path={`${KIOSK_PAGE_PATH}`} component={KioskPage} />
      <Route exact path={TEMPLATE_PREVIEW} component={FlowsPreviewController} />
      <Route
        exact
        path={FLOWS_PARTICIPATE}
        component={FlowsParticipationController}
      />
      <Route path={FLOWS} component={V2RouteController} />
      <Route path={MOBILE_ROUTE_PATH} component={MobileRouters} />
      <Route path={USER_ACCOUNT_SETUP} component={UserSetupController} />
      <Route path={AUTH_SAML} component={SAMLAuthController} />
      <Route path="/404" component={PageNotFound} />
      <Route exact path="/" component={Landing} />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default MultiAssemblyRouters;
