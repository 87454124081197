import styled from 'styled-components';
import { device } from '../../../constants/layout';
import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';

export const StyledFooter = styled.div`
  position: absolute;
  bottom: 4px;
  background: ${ThemeV2.palette.white};
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  height: 32px;
  border-top: 1px solid ${ThemeV2.palette.gray4};
  padding: 4px 8px;
  @media ${device.mobile} {
    height: 48px;
  }
`;

const DropdownFooter = ({ message }: { message: string }) => {
  return (
    <StyledFooter>
      <Body color="gray8" variant="body2">
        {message}
      </Body>
    </StyledFooter>
  );
};

export default DropdownFooter;
