import styled from 'styled-components';

import Theme from '../../../theme';
import { requestPermissions } from './data';
import Body from '../../../atomic/atoms/Body';
import Button from '../../../atomic/atoms/Button';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import IconButton from '../../../atomic/atoms/IconButton_V2';
import NewTextfield from '../../../atomic/molecules/NewTextfield';
import DropdownFooter from '../../../atomic/molecules/DropdownFooter';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import {
  MultiSelectAutoComplete,
  SingleSelectAutoComplete,
} from '../../../atomic/organism/Autocomplete';
import { useRequestAccessModalController } from './useRequestAccessModalController';

export type RequestAccessModalControllerProps = {
  flowName: string;
  handleModalClose: () => void;
  isRequestAccessModalOpen: boolean;
};

const RequestAccessModalContainer = styled(BasicModalTemplate)`
  max-width: 512px;
`;

const RequestAccessModalController = (
  props: RequestAccessModalControllerProps,
) => {
  const { flowName, handleModalClose, isRequestAccessModalOpen } = props;
  const {
    message,
    textboxValue,
    memberOptions,
    selectedPeople,
    isMembersFetching,
    requestPermission,
    handleOptionsScroll,
    handleMessageChange,
    handleCopyLinkClick,
    hasSelectPeopleError,
    hasMessageInputError,
    onTextboxValueChange,
    getDropdownFooterMessage,
    handlePeopleValuesChange,
    handleSendRequestsClick,
    creatingFlowShareRequest,
    handleRequestPermissionChange,
    handleRequestAccessModalClose,
    inviteUserFromPersonSelectorProps,
  } = useRequestAccessModalController(handleModalClose);
  return (
    <RequestAccessModalContainer
      hideCloseIcon
      childrenPadding="24px"
      hasTitleSection={false}
      disableContentScroll={true}
      onModalClose={handleRequestAccessModalClose}
      isOpen={isRequestAccessModalOpen}
    >
      <>
        <Flex justifyContent="space-between">
          <Body variant="body1Medium" color="gray9">
            Request to add people to ‘{flowName}’
          </Body>
          <IconButton onClick={handleRequestAccessModalClose}>
            <SVGIcon
              icon="round-close"
              color={Theme.palette.gray8}
              size="16px"
            />
          </IconButton>
        </Flex>
        <Body variant="body2">
          Your request will go to the flow’s owner and collaborators. If you
          don’t see someone, invite them to the workspace and flow by typing
          their email.
        </Body>
        <Flex
          gap="8px"
          margin="16px auto"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Body
            variant="body2Medium"
            color={hasSelectPeopleError ? 'dustRed6' : 'gray9'}
          >
            {hasSelectPeopleError
              ? 'Choose people to send a request'
              : 'Add people'}
          </Body>
          <MultiSelectAutoComplete
            textboxSize="medium"
            value={selectedPeople}
            options={memberOptions}
            loading={isMembersFetching}
            textboxValue={textboxValue}
            inputBaseRightPadding="32px"
            onChange={handlePeopleValuesChange}
            onTextboxValueChange={onTextboxValueChange}
            label="Search by name or invite with an email"
            ListboxProps={{
              onScroll: handleOptionsScroll,
            }}
            filterOptionsFunction={(opts) => opts}
            hasError={hasSelectPeopleError}
          >
            {inviteUserFromPersonSelectorProps && (
              <DropdownFooter message={getDropdownFooterMessage()} />
            )}
          </MultiSelectAutoComplete>
        </Flex>
        <Flex
          gap="8px"
          margin="16px auto"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Body variant="body2Medium">I’m requesting access for people to</Body>
          <SingleSelectAutoComplete
            showClearIcon={false}
            value={requestPermission}
            options={requestPermissions}
            onChange={handleRequestPermissionChange}
          />
        </Flex>
        <Flex
          gap="8px"
          margin="16px auto"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Body
            variant="body2Medium"
            color={hasMessageInputError ? 'dustRed6' : 'gray9'}
          >
            {hasMessageInputError
              ? 'Message must be less than 500 characters'
              : 'Send a message (optional)'}
          </Body>
          <NewTextfield
            multiline
            value={message}
            onChange={handleMessageChange}
            hasError={hasMessageInputError}
            placeholder="Write a message to the flow owner"
          />
        </Flex>
        <Flex margin="16px auto" justifyContent="space-between">
          <Button
            variant="text"
            color="secondary"
            onClick={handleRequestAccessModalClose}
          >
            Cancel
          </Button>
          <Flex gap="8px">
            <Button icon="Link" color="secondary" onClick={handleCopyLinkClick}>
              Copy link
            </Button>
            <Button
              onClick={handleSendRequestsClick}
              isLoading={creatingFlowShareRequest}
            >
              Send requests
            </Button>
          </Flex>
        </Flex>
      </>
    </RequestAccessModalContainer>
  );
};

export default RequestAccessModalController;
