import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';

export const requestPermissions: AutocompleteDropdownItem<string, unknown>[] = [
  { id: 'VIEWER', title: 'View posts from this flow' },
  { id: 'PARTICIPANT', title: 'Post in this flow' },
  {
    id: 'PARTICIPANT_AND_VIEWER',
    title: 'Post and view posts in this flow',
  },
];
