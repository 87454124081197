import React, { useCallback } from 'react';

import styled from 'styled-components';
import { EmojiData } from 'emoji-mart';
import { EmoticonPickerPopoverProps } from './interface';
import EmoticonPicker from '../../atoms/EmoticonPicker';

import usePopover from '../../../hooks/usePopover';
import { device } from '../../../constants/layout';

const Wrapper = styled.div`
  width: fit-content;
  & div:nth-child(2) {
    z-index: 9999;
  }
`;

const PopoverWrapper = styled.div<{ isMobileView?: boolean }>`
  padding: 8px;
  @media ${device.mobile} {
    height: '200px';
  }
`;

const EmoticonPickerPopover = (props: EmoticonPickerPopoverProps) => {
  const {
    onEmoticonSelect,
    xAxisOffset = 0,
    yAxisOffset = 10,
    popoverPosition,
    children,
  } = props;

  const { models, operations } = usePopover({
    xAxisOffset,
    yAxisOffset,
    popoverPosition,
    children,
  });
  const {
    childrenWithPopoverProps,
    isPopoverOpen,
    styles,
    containerRef,
    referenceElement,
    popperElement,
  } = models;
  const { togglePopover } = operations;

  const handleOnClick = useCallback(
    (emoji: EmojiData) => {
      onEmoticonSelect(emoji);
      togglePopover();
    },
    [onEmoticonSelect, togglePopover],
  );

  return (
    <Wrapper ref={containerRef}>
      <div ref={referenceElement}>{childrenWithPopoverProps}</div>
      <PopoverWrapper ref={popperElement} style={styles.popper}>
        {isPopoverOpen && <EmoticonPicker onEmoticonSelect={handleOnClick} />}
      </PopoverWrapper>
    </Wrapper>
  );
};

const MemoizedEmoticonPickerPopover = React.memo(EmoticonPickerPopover);
MemoizedEmoticonPickerPopover.displayName = 'EmoticonPickerPopover';
export default MemoizedEmoticonPickerPopover;
