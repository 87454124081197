import React from 'react';
import { makeStyles } from '@mui/styles';

import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import ToolTip from '../../atoms/ToolTip';
import SVGIcon from '../../atoms/SVGIcon';
import Clickable from '../../atoms/Clickable';

import useHover from '../../../hooks/useHover';

const useStyles = makeStyles({
  root: {
    display: 'inline',
    margin: '0px 10px',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface UpgradeInfoComponentProps {
  upgradeText: string;
  onUpgradeClick?: () => void;
  toolTipText: string;
}

const UpgradeInfoComponent = ({
  upgradeText,
  onUpgradeClick,
  toolTipText,
}: UpgradeInfoComponentProps) => {
  const { model, operations } = useHover();
  const classes = useStyles();
  return (
    <div
      aria-owns={model.isHovering ? 'mouse-over-popover' : undefined}
      aria-haspopup="true"
      onMouseEnter={operations.handlePopoverOpen}
      onMouseLeave={operations.handlePopoverClose}
      className={classes.root}
      data-testid="upgradeInfoDiv"
    >
      <Clickable
        onClick={onUpgradeClick}
        data-testid="upgradeInfoButton"
        data-qa-id="btn-upgrade-info"
      >
        <span className={classes.buttonContent}>
          <ToolTip
            title={toolTipText}
            placement="top"
            data-testid="upgradeInfoToolTip"
            data-qa-id="btn-upgrade-tooltip"
          >
            <div>
              <SVGIcon
                icon="premium-crown-icon"
                color={
                  model.isHovering
                    ? ThemeV2.palette.gold6
                    : ThemeV2.palette.gold5
                }
              />
            </div>
          </ToolTip>
          {model.isHovering && (
            <Body
              variant="body2"
              color="gold6"
              inline
              data-testid="upgradeInfoText"
              data-qa-id="btn-upgrade-info"
            >
              {upgradeText}
            </Body>
          )}
        </span>
      </Clickable>
    </div>
  );
};

export default UpgradeInfoComponent;
