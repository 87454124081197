import { create } from 'zustand';

type ActionBarStoreState = {
  isActionBarOpen: boolean;
  setIsActionBarOpen: (isOpen: boolean) => void;
};

const useActionBarStore = create<ActionBarStoreState>()((set) => ({
  isActionBarOpen: false,
  setIsActionBarOpen: (isOpen: boolean) =>
    set(() => ({ isActionBarOpen: isOpen })),
}));

export default useActionBarStore;
