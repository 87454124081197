/* eslint-disable max-len */
export const SamplePosts = [
  {
    responseId: '63eb2ee814145ff8e61febc3',
    instanceId: '63eb2ede14145ff8e61febaa',
    respondent: {
      name: 'The Assembly Team',
      firstName: 'The Assembly Team',
      lastName: '',
      memberID: '000000000000000000000001',
      image: '',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: 'DUMMY',
    },
    responses: [
      {
        type: 'OPEN_ENDED',
        blockId: '63eb2eb314145ff8e61fe62d',
        state: 'RESPONDED',
        content: {
          title: 'What is a flow?',
          type: 'OPEN_ENDED',
          rules: {
            required: false,
            allowedMedia: ['FILES', 'EMOJI', 'GIF', 'MENTION', 'TASKS'],
            fileType: [
              'jpg',
              'jpeg',
              'png',
              'svg',
              'gif',
              'pdf',
              'doc',
              'docx',
              'ppt',
              'pptx',
              'pps',
              'ppsx',
              'xls',
              'xlsx',
              'csv',
              'txt',
              'odt',
              'zip',
              'mp3',
              'm4a',
              'ogg',
              'wav',
              'mp4',
              'm4v',
              'mov',
              'wmv',
              'avi',
              'mpg',
              'ogv',
              '3gp',
              '3g2',
            ],
          },
        },
        response: {
          value:
            'Flows are the main way of communicating in Assembly.\n\nThey facilitate consistent communication over time within your organization.\n\nThey allow managers to structure communications with their reports, teams to structure their communications to each other, and leadership to structure communications with the whole organization.\n\nhttps://www.youtube.com/watch?v=R-nEX_wY0hc',
          files: [],
          gifUrl: '',
          mentions: [],
          tags: [],
        },
        edited: false,
      },
    ],
    flow: {
      flowId: '63eb2eb314145ff8e61fe62a',
      version: 1,
      name: 'Dummy post',
      kind: 'NO_TRIGGER',
      icon: {
        kind: 'HEX_CODE',
        value: '2699-fe0f',
      },
    },
    commentsCount: 0,
    reactions: [],
    visibility: 'onlyOwners',
    isPrivate: false,
    createdAt: '2023-02-14T06:49:12.326Z',
    canDelete: true,
    canEdit: true,
    kind: 'INTERNAL',
  },
  {
    responseId: '63eb2ff514145ff8e6201400',
    instanceId: '63eb2fa914145ff8e6200a27',
    respondent: {
      name: 'The Assembly Team',
      firstName: 'The Assembly Team',
      lastName: '',
      memberID: '000000000000000000000001',
      image: '',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: 'DUMMY',
    },
    responses: [
      {
        type: 'OPEN_ENDED',
        blockId: '63eb2fc914145ff8e6200f06',
        state: 'RESPONDED',
        content: {
          title: 'How to share a flow?',
          type: 'OPEN_ENDED',
          rules: {
            required: false,
            allowedMedia: ['FILES', 'EMOJI', 'GIF', 'MENTION', 'TASKS'],
            fileType: [
              'jpg',
              'jpeg',
              'png',
              'svg',
              'gif',
              'pdf',
              'doc',
              'docx',
              'ppt',
              'pptx',
              'pps',
              'ppsx',
              'xls',
              'xlsx',
              'csv',
              'txt',
              'odt',
              'zip',
              'mp3',
              'm4a',
              'ogg',
              'wav',
              'mp4',
              'm4v',
              'mov',
              'wmv',
              'avi',
              'mpg',
              'ogv',
              '3gp',
              '3g2',
            ],
          },
        },
        response: {
          value:
            'Click the share button above to start experimenting with sharing a flow.\n\nChoose participants who will be able to respond to the flow.\n\nThen choose viewers who will be able to see all the responses.\n\nhttps://youtu.be/xPFkO6vMXV4?t=188',
          files: [],
          gifUrl: '',
          mentions: [],
          tags: [],
        },
        edited: false,
      },
    ],
    flow: {
      flowId: '63eb2eb314145ff8e61fe62a',
      version: 2,
      name: 'Dummy post',
      kind: 'NO_TRIGGER',
      icon: {
        kind: 'HEX_CODE',
        value: '2699-fe0f',
      },
    },
    commentsCount: 0,
    reactions: [],
    visibility: 'onlyOwners',
    isPrivate: false,
    createdAt: '2023-02-14T06:53:41.960Z',
    canDelete: true,
    canEdit: true,
    kind: 'INTERNAL',
  },
  {
    responseId: '63eb303b14145ff8e6202364',
    instanceId: '63eb303414145ff8e620234b',
    respondent: {
      name: 'The Assembly Team',
      firstName: 'The Assembly Team',
      lastName: '',
      memberID: '000000000000000000000001',
      image: '',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: 'DUMMY',
    },
    responses: [
      {
        type: 'OPEN_ENDED',
        blockId: '63eb301414145ff8e6201e67',
        state: 'RESPONDED',
        content: {
          title: 'How do I see insights and analytics for my workflows?',
          type: 'OPEN_ENDED',
          rules: {
            required: false,
            allowedMedia: ['FILES', 'EMOJI', 'GIF', 'MENTION', 'TASKS'],
            fileType: [
              'jpg',
              'jpeg',
              'png',
              'svg',
              'gif',
              'pdf',
              'doc',
              'docx',
              'ppt',
              'pptx',
              'pps',
              'ppsx',
              'xls',
              'xlsx',
              'csv',
              'txt',
              'odt',
              'zip',
              'mp3',
              'm4a',
              'ogg',
              'wav',
              'mp4',
              'm4v',
              'mov',
              'wmv',
              'avi',
              'mpg',
              'ogv',
              '3gp',
              '3g2',
            ],
          },
        },
        response: {
          value:
            "Go to the Insights tab at the top of any flow feed to see analytics for that flow.\n\nThe charts and tables will help you understand how people have been using the flow over time, patterns in how they've answered, and more.\n\nhttps://www.youtube.com/watch?v=7ELxrrMNCRU",
          files: [],
          gifUrl: '',
          mentions: [],
          tags: [],
        },
        edited: false,
      },
    ],
    flow: {
      flowId: '63eb2eb314145ff8e61fe62a',
      version: 3,
      name: 'Dummy post',
      kind: 'NO_TRIGGER',
      icon: {
        kind: 'HEX_CODE',
        value: '2699-fe0f',
      },
    },
    commentsCount: 0,
    reactions: [],
    visibility: 'onlyOwners',
    isPrivate: false,
    createdAt: '2023-02-14T06:54:51.532Z',
    canDelete: true,
    canEdit: true,
    kind: 'INTERNAL',
  },
];
