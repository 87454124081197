import styled from 'styled-components';
import ThemeV2 from '../../theme';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  grid-column: 1 / 13;
  background-color: ${ThemeV2.palette.gray3};

  @media (max-width: 580px) {
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }

  .pg-viewer-wrapper {
    overflow: auto;
  }
`;

export const PreviewContent = styled.div<{
  fullHeight: boolean;
}>`
  display: flex;
  text-align: center;
  overflow: auto;
  height: ${({ fullHeight }) =>
    fullHeight ? 'calc(100vh - 144px)' : 'calc(100vh - 72px)'};
  @media (max-width: 580px) {
    max-height: 100%;
  }
`;
