import { Formik } from 'formik';
import React from 'react';

import Field from '../Field';
import TextField from '../TextField_V2';
import { SettingsDropdownWithCustomFieldProps } from './interfaces';
import { SAVE_CHANGES } from '../../../languages/en/settings';
import { StyledButton, StyledWrapper } from './styles';

const SettingsDropdownWithCustomField = (
  props: SettingsDropdownWithCustomFieldProps,
) => {
  const {
    customDropdownValue,
    customTextFieldLabel,
    dropdownItems,
    dropdownLabel,
    idPrefix,
    initialValues,
    onSaveChanges,
    validationSchema,
  } = props;
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSaveChanges}
      validationSchema={validationSchema}
    >
      {({
        dirty,
        handleSubmit,
        isValid,
        setFieldTouched,
        setFieldValue,
        values,
      }) => (
        <>
          <TextField
            disableClearButton
            dropdownItems={dropdownItems}
            enableChip={false}
            isEditable={false}
            label={dropdownLabel}
            name={`${idPrefix}-dropdown-input`}
            onChange={(newVal) => {
              setFieldValue('dropdownOption', newVal);
              setFieldTouched('dropdownOption', true);
            }}
            value={values.dropdownOption}
          />
          {values.dropdownOption === customDropdownValue && (
            <StyledWrapper>
              <Field
                label={customTextFieldLabel}
                name="custom"
                type="textV2_text"
                // Below props must be declared because the component does not define the types.
                value={undefined}
                helperText={undefined}
                isMultiSelectEnable={undefined}
              />
            </StyledWrapper>
          )}
          <StyledButton
            color="primary"
            dataTestId={`${idPrefix}-${SAVE_CHANGES}`}
            disabled={!(dirty && isValid)}
            onClick={handleSubmit}
            size="small"
            status="default"
            variant="contained"
          >
            {SAVE_CHANGES}
          </StyledButton>
        </>
      )}
    </Formik>
  );
};

export default SettingsDropdownWithCustomField;
