import { TaskHistoryActivity } from '../../atomic/molecules/Notebook/TaskHistory/interface';
import { MemberRole, MemberState } from '../user';
import { TaskContentFromAPI } from './editor';

export type AssignmentOperations = 'reassign' | 'unassign' | null;

export enum TaskCategories {
  TODAY = 'TODAY',
  OVERDUE = 'OVERDUE',
  UPCOMING = 'UPCOMING',
  UNSCHEDULED = 'UNSCHEDULED',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum MiscTaskCategories {
  UNARCHIVE = 'UNARCHIVE',
  DELETE = 'DELETE',
  INCOMPLETE = 'INCOMPLETE',
}

export enum NotebookOperations {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ASSIGN = 'ASSIGN',
  DELETE = 'DELETE',
}

export type NotebookSectionDetails = {
  title: string;
  isSectionOpenOnLoad: boolean;
  isCreateTaskSectionShown: boolean;
  isLastSection?: boolean;
};

export type PersonTypeFromAPI = {
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  memberID: string;
  image: string;
  pointsGiven: string;
  totalPointsGiven: string;
  memberState: string;
  role: MemberRole[];
};

export interface NotebookTask {
  taskId: string;
  noteId: string;
  createdAt: string;
  note: TaskContentFromAPI[];
  description: TaskContentFromAPI[];
  dueDate?: string | undefined;
  hasError?: boolean;
  stateEffectiveAt?: string;
  type: TaskCategories;
  isDeleted: boolean;
  assignedTo: Assignee | null;
  createdBy: PersonTypeFromAPI | null;
  isUnread: boolean;
  section?: string | null;
  sectionTitle?: string;
}

export type NotebookCategoryData = {
  tasks: string[];
  total: number;
  initialRequest: boolean;
  totalUnreadCount: number;
};

export type TaskStateInAPI = 'ACTIVE' | 'COMPLETED' | 'ARCHIVED' | 'DELETED';
export enum AssigneeTaskIndicators {
  UNREAD = 'unread',
}

export type TaskPayload = {
  transactionId: string;
  state: TaskStateInAPI;
  title: TaskContentFromAPI[];
  description: TaskContentFromAPI[];
  dueDate?: string;
  noteId?: string;
  afterTransactionId?: string;
  beforeTransactionId?: string;
  assignedTo: string | null;
  sectionId: string | null;
  previousSectionId?: string;
};

export type StatusPayload = {
  sectionId?: string;
  title?: string;
  beforeId?: string;
  afterId?: string;
};

export type TaskFromAPI = {
  id: string;
  transactionId: string;
  orderIndex: string;
  state: TaskStateInAPI;
  dueDate?: string;
  title: TaskContentFromAPI[];
  description: TaskContentFromAPI[];
  createdAt: string;
  stateEffectiveAt: string;
  createdBy: PersonTypeFromAPI;
  assignedTo: PersonTypeFromAPI;
  assigneeTaskIndicator: AssigneeTaskIndicators[];
  assignedBy: PersonTypeFromAPI;
  section?: string | null;
  sectionTitle?: string;
};

export type NotebookTransaction = {
  taskId: string;
  taskData: TaskPayload;
  previousTaskCategory?: TaskCategories;
  noteId?: string;
  operation: NotebookOperations;
};

// Figure out logic of how to handle transactions in general and how to keep things in sync with the server.

// MAYBE WE CAN REMOVE TRANSACTIONS FROM QUEUE IF ITS SUCCESSFUL

// CAP ON NUMBER OF TRANSACTIONS SENT

// FIND OUT WHAT HAPPENS WHEN API CALLS FAIL AND USER CLOSES THE BROWSERS
// LOCAL STORAGE FOR TRANSACTIONS

export type DeadlineNotesData = Record<TaskCategories, NotebookCategoryData>;
export type BoardNotesData = Record<string, NotebookCategoryData>;
export type TransactionData = {
  transactionQueue: NotebookTransaction[];
};

export type GetNotebookSectionResponse = {
  total: number;
  data: TaskFromAPI[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
};

// TO DO Sumedha - move it to interface folder
export type SectionFromAPI = {
  sectionId: string | null;
  count: number;
  type: 'NO_STATUS' | 'COMPLETED' | 'CUSTOM';
  title: string;
};

export type GetNotebookBoardSectionDataResponse = {
  data: SectionFromAPI[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
};

export type GetNotebookTaskAssigneeResponse = {
  total: number;
  data: Assignee[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
  canEmailInvite?: boolean;
};

export type GetTaskHistoryLogResponse = {
  total: number;
  data: TaskHistoryActivity[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
};

export type NotebookAnalyticsParameters = {
  action: string;
  section: TaskCategories;
};

export type NotebookTaskAnalyticsParameters = {
  action: string;
  originalTask?: NotebookTask;
  originalCategory?: TaskCategories | MiscTaskCategories;
  updatedTask?: NotebookTask | undefined;
  updatedCategory?: TaskCategories | MiscTaskCategories;
};

export type TaskPositionDataType = {
  tab: NotebookTabs;
  taskCategory: TaskCategories;
};

export type Assignee = {
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  memberID: string;
  image: string;
  pointsGiven: string;
  totalPointsGiven: string;
  memberState: MemberState;
  role: MemberRole[];
  timezone?: string;
  emailToInvite?: string;
} | null;

// NOTEBOOK V2
export enum NotebookViews {
  Deadline = 'deadline',
  Board = 'board',
  Feed = 'feed',
  Flow = 'flow',
}

export type DropdownItemOption = {
  id: string;
  value: string;
  deleteItem: boolean;
  isSelected: boolean;
  prefixIcon: string;
};

export type DropdownOptions = {
  id: string;
  items: DropdownItemOption[];
};

type NotebookResponse = {
  noteId: string;
};

export type NotebookAPIResponse = NotebookResponse[];

export enum NotebookTabs {
  myWork = 'myWork',
  ato = 'ato',
}

export type AssignedToAPIResponse = {
  name: string;
  firstName: string;
  lastName: string;
  username: string;
  memberID: string;
  image: string;
  pointsGiven: string;
  totalPointsGiven: string;
  memberState: MemberState;
  role: MemberRole[];
};
