import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Input from '@mui/material/Input';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SVGIcon from '../SVGIcon';
import Body from '../Body';
import ThemeV2 from '../../../theme';
import { DropdownProps, Item } from './interfaces';

const useStyles = makeStyles({
  menuItemStyle: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    '& ul': {
      backgroundColor: ThemeV2.palette.gray1,
    },
    '& li': {
      fontSize: '14px',
      padding: '.5rem .75rem',
      '&:hover, &:focus, &:visited': {
        backgroundColor: ThemeV2.palette.geekBlue1,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: ThemeV2.palette.geekBlue1,
      },
    },
  },
  root: ({
    selectedMenuItemId,
    width,
    isFilter,
    icon,
    hasCalendar,
  }: DropdownProps) => ({
    zIndex: 0,
    position: 'relative',
    width,
    left: '.5rem',
    padding: '0px',
    borderRadius: '2px',
    overflow: 'hidden',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: hasCalendar
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.gray5,
      borderStyle: isFilter ? 'none' : 'solid',
      transition: 'all .3s',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: hasCalendar
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.geekBlue5,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: isFilter ? '' : ThemeV2.palette.geekBlue5,
      boxShadow: isFilter ? '' : '0px 0px 4px rgba(24, 144, 255, 0.5)',
      backgroundColor: isFilter ? '' : 'transparent',
    },
    '& .MuiInputAdornment-positionStart': {
      position: 'absolute',
      left: '.5rem',
      zIndex: hasCalendar ? 0 : -1,
      cursor: 'pointer',
    },
    '& .MuiSelect-select': {
      padding: isFilter ? '0px' : '.5rem 1.5rem .5rem 0rem',
      paddingLeft: icon ? '2rem' : '1rem',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      color: ThemeV2.palette.gray9,
      backgroundColor: hasCalendar ? ThemeV2.palette.geekBlue6 : 'transparent',
      '&:focus': {
        backgroundColor: hasCalendar
          ? ThemeV2.palette.geekBlue6
          : 'transparent',
      },
    },
    '& .MuiSelect-icon': {
      right: '.5rem',
      color: ThemeV2.palette.gray6,
    },
    '& .placeholder': {
      opacity: isFilter || !selectedMenuItemId ? 0.6 : 1,
      fontSize: isFilter ? '12px' : '14px',
      textAlign: isFilter ? 'right' : 'left',
      paddingRight: isFilter ? '2rem' : 0,
      fontWeight: ThemeV2.typography.fontWeightMedium,
      color: hasCalendar ? ThemeV2.palette.white : ThemeV2.palette.gray9,
    },
    '& .caret': {
      position: 'absolute',
      top: isFilter ? '1.5px' : '10px',
      right: '8px',
      zIndex: hasCalendar ? 0 : -1,
      cursor: 'pointer',
      '& svg': {
        width: '24px',
        height: '20px',
        '& path': {
          fill: hasCalendar ? ThemeV2.palette.white : ThemeV2.palette.gray6,
        },
      },
    },
    '&.MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '&.MuiInput-underline:after': {
      borderBottom: 'none',
    },
  }),
  rippleVisible: {
    opacity: 0.5,
  },
  filterMenuItem: {
    fontSize: '.75rem !important',
    color: ThemeV2.palette.gray7,
  },
});

const Dropdown = (props: DropdownProps) => {
  const {
    menuItems,
    onChange,
    openPopup,
    selectedMenuItemId,
    isFilter,
    placeholder,
    icon,
    filterPlaceholder,
    removeBorder,
    className: parentClassName,
    isDropdownOpen,
    toggleDropdown,
    hasCalendar,
  } = props;
  const classes = useStyles(props);
  const root = `${classes.root} ${parentClassName}`;

  const InputElement = useMemo(
    () => (removeBorder ? Input : OutlinedInput),
    [removeBorder],
  );

  const handlePopup = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, item: Item) => {
      const hasPopUp = item.showPopup || false;
      if (hasPopUp) {
        event.stopPropagation();
        openPopup(event, item.id);
      }
    },
    [openPopup],
  );

  const handleOnChange = useCallback(
    (event: SelectChangeEvent<string | number>, child: React.ReactNode) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */
      const { id: hasPopUp } = child.props;
      if (hasPopUp === '0') {
        const selectedId = event.target.value as number | string;
        onChange(selectedId);
      }
    },
    [onChange],
  );

  const renderValue = useCallback(() => {
    const menuItem = menuItems.find((item) => {
      return item.id === selectedMenuItemId;
    });
    return (
      <Body variant="body2" className="placeholder">
        {menuItem?.value || placeholder}
      </Body>
    );
  }, [menuItems, selectedMenuItemId, placeholder]);

  const Caret = useCallback(() => {
    const caretStyles = isDropdownOpen
      ? {
          transform: 'scaleX(-1) scaleY(-1)',
          bottom: isFilter ? '3px' : '11px',
          pointerEvents: 'none',
        }
      : { pointerEvents: 'none' };
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */
      <SVGIcon icon="caret-rounded" className="caret" style={caretStyles} />
    );
  }, [isDropdownOpen, isFilter]);

  const svgColor = useMemo(
    () => (hasCalendar ? ThemeV2.palette.white : ThemeV2.palette.gray6),
    [hasCalendar],
  );

  const svgIcon = <SVGIcon icon={icon} size="18px" color={svgColor} />;

  const handleOnClick = useCallback(() => {
    toggleDropdown();
  }, [toggleDropdown]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  const menuProps: MenuProps = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
      getContentAnchorEl: null,
      MenuListProps: {
        disablePadding: true,
      },
      classes: {
        paper: classes.menuItemStyle,
      },
    }),
    [classes.menuItemStyle],
  );

  const onMenuItemClick = useCallback(
    (item: Item) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
      handlePopup(event, item),
    [handlePopup],
  );

  return (
    <Select
      onClick={handleOnClick}
      value={selectedMenuItemId}
      onChange={handleOnChange}
      IconComponent={Caret}
      input={
        <InputElement
          classes={{ root }}
          startAdornment={
            icon && <InputAdornment position="start">{svgIcon}</InputAdornment>
          }
        />
      }
      displayEmpty
      renderValue={renderValue}
      MenuProps={menuProps}
      open={isDropdownOpen}
      data-testid="dropdownCalendar"
      data-qa-id="ddown-calendar"
    >
      {isFilter && (
        <MenuItem className={classes.filterMenuItem} disabled>
          {filterPlaceholder || 'Filter by'}
        </MenuItem>
      )}
      {menuItems.map((item) => (
        <MenuItem
          value={item.id}
          key={item.id}
          data-haspopup={item.showPopup || false}
          id={item.showPopup ? '1' : '0'}
          onClick={onMenuItemClick(item)}
          disabled={!!item.disabled}
        >
          {item.value}
        </MenuItem>
      ))}
    </Select>
  );
};

Dropdown.defaultProps = {
  icon: '',
  selectedMenuItemId: '',
  isFilter: false,
  placeholder: '',
  filterPlaceholder: '',
  className: '',
  showDropdown: true,
  openPopup: () => {},
  closePopup: () => {},
  onPopoverClick: () => {},
  toggleDropdown: () => {},
  hasCalendar: false,
};

export default Dropdown;
