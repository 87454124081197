/* eslint-disable max-len */
import { memo } from 'react';

import {
  ABOUT_ME,
  AVATAR,
  BIRTHDAY,
  DATE,
  DEPARTMENT,
  DIRECT,
  EMAIL,
  HANDLE,
  HOME_LOCATION,
  INFORMATION,
  MANAGER,
  NAME,
  PROFILE,
  PRONOUNS,
  REPORTS,
  SOCIAL_PROFILES,
  START,
  TIME_ZONE,
  WORK_LOCATION,
} from '../../../languages/en/singleWords';
import Body from '../../atoms/Body';
import AccordionItem from '../../molecules/AccordionItem';
import {
  AccordionHeader,
  AccordionHeaderInner,
  AccordionRoot,
} from '../../molecules/AccordionItem/styles';
import SettingsDropdown from '../../molecules/SettingsDropdown';
import { SettingsDropdownProps } from '../../molecules/SettingsDropdown/interface';
import SettingsStringField, {
  SettingsStringFieldProps,
} from '../../molecules/SettingsEditStringField';
import SettingsMultiSelectDropdown from '../../molecules/SettingsMultiSelectDropdown';
import { SettingsMultiSelectDropdownProps } from '../../molecules/SettingsMultiSelectDropdown/interface';
import SettingsAvatar from '../SettingsAvatar';
import { SettingsAvatarProps } from '../SettingsAvatar/interface';

import React from 'react';
import { JOB_TITLE, LOCATION } from '../../../languages/en/home/profileDrawer';
import SettingsAutocomplete, {
  SettingsAutocompleteProps,
} from '../../molecules/SettingsAutocomplete';
import { SettingsDropdownWithCustomFieldProps } from '../../molecules/SettingsDropdownWithCustomField/interfaces';
import SettingsDropdownWithCustomField from '../../molecules/SettingsDropdownWithCustomField';
import Button from '../../atoms/Button';
import NewTextfield from '../../molecules/NewTextfield';
import styled from 'styled-components';
import {
  SocialProfile,
  SocialProfileNameEnum,
} from '../../../interfaces/Settings';
import {
  getSocialProfileIcon,
  getSocialProfileName,
} from '../../../controllers/SettingsController/utils';
import Theme from '../../../theme';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

const StyledSaveButton = styled(Button)`
  margin-top: 16px;
`;

const SocialProfileInput = styled(NewTextfield)`
  max-width: 453px;
  width: 100%;
  margin: 8px auto;
  .MuiOutlinedInput-root.Mui-focused {
    .MuiInputAdornment-root {
      svg * {
        fill: ${Theme.palette.geekBlue6};
      }
    }
  }
`;

export interface SettingsHandleProps {
  handle?: string;
}

export interface SettingsEmailProps {
  email?: string;
}

export interface SettingsPronounsProps
  extends SettingsDropdownWithCustomFieldProps {
  accordianPlaceholderValue: string;
}

export interface SettingsAboutMeProps {
  value: string;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isSaveChangesDisabled: boolean;
  onSaveChangesClick: () => void;
}

export interface SettingsSocialProfilesProps {
  socialHandles: SocialProfile[];
  handleSocialProfileValueChange: (
    socialProfile: SocialProfileNameEnum,
    handle: string,
  ) => void;
  isSaveChangesDisabled: boolean;
  onSaveChangesClick: () => void;
}

export interface GeneralSettingsAccordionProps {
  settingsAvatarProps: SettingsAvatarProps;
  settingsBirthdayProps: SettingsMultiSelectDropdownProps;
  settingsEditNameProps: SettingsStringFieldProps;
  settingsPronounsProps: SettingsPronounsProps;
  settingsEditJobTitleProps: SettingsStringFieldProps;
  settingsV2EditJobTitleProps: SettingsDropdownProps;
  settingsEditLocationProps: SettingsStringFieldProps;
  settingsV2EditLocationProps: SettingsDropdownProps;
  settingsEditWorkLocationProps: SettingsDropdownProps;
  settingsHandleProps?: SettingsHandleProps;
  settingsEmailProps?: SettingsEmailProps;
  settingsDepartmentProps: SettingsDropdownProps;
  settingsTimezoneProps: SettingsDropdownProps;
  settingsManagerProps: SettingsAutocompleteProps;
  settingsReportsProps: SettingsAutocompleteProps;
  settingsStartDateProps: SettingsMultiSelectDropdownProps;
  settingsAboutMeProps?: SettingsAboutMeProps;
  settingsSocialProfilesProps?: SettingsSocialProfilesProps;
  managerPlaceholder?: string;
  accordionItems?: string[];
  handleCollapse?: (name: string) => void;
  reportsPlaceholder?: string;
  birthDayPlaceholder?: string;
  startDatePlaceholder?: string;
  aboutMePlaceholder?: string;
  socialProfilesPlaceholder?: string;
  canShowSaveChangesButton?: boolean;
}

const GeneralSettingsAccordion = (props: GeneralSettingsAccordionProps) => {
  const {
    settingsAvatarProps,
    settingsEditNameProps,
    settingsPronounsProps,
    settingsEditJobTitleProps,
    settingsV2EditJobTitleProps,
    settingsEditLocationProps,
    settingsV2EditLocationProps,
    settingsEditWorkLocationProps,
    settingsHandleProps,
    settingsEmailProps,
    settingsDepartmentProps,
    settingsTimezoneProps,
    settingsManagerProps,
    settingsReportsProps,
    settingsBirthdayProps,
    settingsStartDateProps,
    settingsAboutMeProps,
    settingsSocialProfilesProps,
    managerPlaceholder,
    reportsPlaceholder,
    birthDayPlaceholder,
    startDatePlaceholder,
    aboutMePlaceholder,
    socialProfilesPlaceholder,
    accordionItems,
    handleCollapse,
    canShowSaveChangesButton,
  } = props;

  const { treatment: locationCriteria } = useFeatureSplit(
    SplitNames.LocationCriteria,
  );

  const isLocationCriteriaEnabled = locationCriteria === TreatmentTypes.ON;

  const { avatarImage } = settingsAvatarProps;
  const {
    initialValues: { userName },
  } = settingsEditNameProps;

  const { accordianPlaceholderValue, ...pronounsDropdownProps } =
    settingsPronounsProps;

  const {
    initialValues: { jobTitle },
  } = settingsEditJobTitleProps;

  const { initialValue: v2JobTitle } = settingsV2EditJobTitleProps;

  const {
    initialValues: { location },
  } = settingsEditLocationProps;

  const { initialValue: v2Location } = settingsV2EditLocationProps;

  const { initialValue: workLocation } = settingsEditWorkLocationProps;

  const { initialValue: departmentValue } = settingsDepartmentProps;

  return (
    <AccordionRoot>
      <AccordionHeader>
        <AccordionHeaderInner>
          <Body variant="body1Bold">{`${PROFILE} ${INFORMATION}`}</Body>
        </AccordionHeaderInner>
      </AccordionHeader>
      <AccordionItem
        itemTitle={AVATAR}
        avatarImage={avatarImage}
        itemHasNoValue={!avatarImage}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(AVATAR) === -1}
      >
        <SettingsAvatar {...settingsAvatarProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={NAME}
        placeholderValue={userName}
        itemHasNoValue={!userName}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(NAME) === -1}
      >
        <SettingsStringField {...settingsEditNameProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={PRONOUNS}
        placeholderValue={accordianPlaceholderValue}
        itemHasNoValue={!accordianPlaceholderValue}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(PRONOUNS) === -1
        }
      >
        <SettingsDropdownWithCustomField {...pronounsDropdownProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={HANDLE}
        placeholderValue={settingsHandleProps?.handle}
        itemHasNoValue={!settingsHandleProps?.handle}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(HANDLE) === -1}
        readOnly
      >
        Sample content
      </AccordionItem>
      <AccordionItem
        itemTitle={EMAIL}
        placeholderValue={settingsEmailProps?.email}
        handleCollapse={handleCollapse}
        isCollapseOpen={accordionItems && accordionItems.indexOf(EMAIL) === -1}
        readOnly
      >
        Sample content
      </AccordionItem>

      {!isLocationCriteriaEnabled && (
        <AccordionItem
          itemTitle={JOB_TITLE}
          placeholderValue={jobTitle}
          itemHasNoValue={!jobTitle}
          handleCollapse={handleCollapse}
          isCollapseOpen={accordionItems && !accordionItems.includes(JOB_TITLE)}
        >
          <SettingsStringField {...settingsEditJobTitleProps} />
        </AccordionItem>
      )}

      {isLocationCriteriaEnabled && (
        <AccordionItem
          itemTitle={JOB_TITLE}
          placeholderValue={v2JobTitle}
          itemHasNoValue={!v2JobTitle}
          handleCollapse={handleCollapse}
          isCollapseOpen={accordionItems && !accordionItems.includes(JOB_TITLE)}
        >
          {
            <SettingsDropdown
              {...settingsV2EditJobTitleProps}
              allowCustomOptionCreation
            />
          }
        </AccordionItem>
      )}

      <AccordionItem
        itemTitle={DEPARTMENT}
        placeholderValue={departmentValue}
        itemHasNoValue={!departmentValue}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(DEPARTMENT) === -1
        }
      >
        <SettingsDropdown {...settingsDepartmentProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={MANAGER}
        placeholderValue={managerPlaceholder || ''}
        itemHasNoValue={!managerPlaceholder}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(MANAGER) === -1
        }
        isItemDisabled={settingsManagerProps.isSettingsDisabled}
        toolTipText={
          settingsManagerProps.isSettingsDisabled
            ? 'Only admins can modify this field'
            : ''
        }
      >
        <SettingsAutocomplete {...settingsManagerProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={`${DIRECT} ${REPORTS}`}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems &&
          accordionItems.indexOf(`${DIRECT} ${REPORTS}`) === -1
        }
        placeholderValue={reportsPlaceholder || ''}
        itemHasNoValue={!reportsPlaceholder}
        isItemDisabled={settingsManagerProps.isSettingsDisabled}
        toolTipText={
          settingsManagerProps.isSettingsDisabled
            ? 'Only admins can modify this field'
            : ''
        }
      >
        <SettingsAutocomplete {...settingsReportsProps} />
      </AccordionItem>

      <AccordionItem
        itemTitle={TIME_ZONE}
        placeholderValue={settingsTimezoneProps.initialValue}
        itemHasNoValue={!settingsTimezoneProps.initialValue}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(TIME_ZONE) === -1
        }
      >
        <SettingsDropdown {...settingsTimezoneProps} />
      </AccordionItem>

      {!isLocationCriteriaEnabled && (
        <AccordionItem
          itemTitle={LOCATION}
          placeholderValue={location}
          itemHasNoValue={!location}
          handleCollapse={handleCollapse}
          isCollapseOpen={accordionItems && !accordionItems.includes(LOCATION)}
        >
          <SettingsStringField {...settingsEditLocationProps} />
        </AccordionItem>
      )}

      {isLocationCriteriaEnabled && (
        <AccordionItem
          itemTitle={isLocationCriteriaEnabled ? HOME_LOCATION : LOCATION}
          placeholderValue={v2Location}
          itemHasNoValue={!v2Location}
          handleCollapse={handleCollapse}
          isCollapseOpen={
            accordionItems &&
            !accordionItems.includes(
              isLocationCriteriaEnabled ? HOME_LOCATION : LOCATION,
            )
          }
        >
          <SettingsDropdown
            {...settingsV2EditLocationProps}
            allowCustomOptionCreation
          />
        </AccordionItem>
      )}

      {isLocationCriteriaEnabled && (
        <AccordionItem
          itemTitle={WORK_LOCATION}
          placeholderValue={workLocation}
          itemHasNoValue={!workLocation}
          handleCollapse={handleCollapse}
          isCollapseOpen={
            accordionItems && !accordionItems.includes(WORK_LOCATION)
          }
        >
          <SettingsDropdown
            {...settingsEditWorkLocationProps}
            allowCustomOptionCreation
          />
        </AccordionItem>
      )}

      <AccordionItem
        itemTitle={BIRTHDAY}
        toolTipText="Only admins can modify this field"
        placeholderValue={birthDayPlaceholder || ''}
        itemHasNoValue={!birthDayPlaceholder}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(BIRTHDAY) === -1
        }
      >
        <SettingsMultiSelectDropdown {...settingsBirthdayProps} />
      </AccordionItem>
      <AccordionItem
        itemTitle={`${START} ${DATE}`}
        toolTipText="Only admins can modify this field"
        placeholderValue={startDatePlaceholder}
        itemHasNoValue={!startDatePlaceholder}
        handleCollapse={handleCollapse}
        isCollapseOpen={
          accordionItems && accordionItems.indexOf(`${START} ${DATE}`) === -1
        }
        readOnly={!canShowSaveChangesButton}
      >
        <SettingsMultiSelectDropdown
          {...settingsStartDateProps}
          isDisabled={!canShowSaveChangesButton}
          canShowSaveChangesButton={canShowSaveChangesButton}
        />
      </AccordionItem>
      {settingsAboutMeProps && (
        <AccordionItem
          itemTitle={ABOUT_ME}
          placeholderValue={aboutMePlaceholder}
          itemHasNoValue={!aboutMePlaceholder}
          handleCollapse={handleCollapse}
          isCollapseOpen={
            accordionItems && accordionItems.indexOf(ABOUT_ME) === -1
          }
        >
          <div>
            <NewTextfield
              value={settingsAboutMeProps.value}
              onChange={settingsAboutMeProps.onValueChange}
              placeholder="Tell everyone about yourself. Here’s an example:
            I love hiking, going on holidays, reading books. I am wine aficionado and hope to create a wine of my own some day."
              multiline
              label="About me"
            />
            <StyledSaveButton
              disabled={settingsAboutMeProps.isSaveChangesDisabled}
              onClick={settingsAboutMeProps.onSaveChangesClick}
            >
              Save changes
            </StyledSaveButton>
          </div>
        </AccordionItem>
      )}
      {settingsSocialProfilesProps && (
        <AccordionItem
          itemTitle={SOCIAL_PROFILES}
          placeholderValue={socialProfilesPlaceholder}
          itemHasNoValue={!socialProfilesPlaceholder}
          handleCollapse={handleCollapse}
          isCollapseOpen={
            accordionItems && accordionItems.indexOf(SOCIAL_PROFILES) === -1
          }
        >
          <>
            {settingsSocialProfilesProps.socialHandles.map((profile) => (
              <SocialProfileInput
                value={profile.handle}
                onChange={(e) => {
                  settingsSocialProfilesProps.handleSocialProfileValueChange(
                    profile.name as SocialProfileNameEnum,
                    e.target.value,
                  );
                }}
                key={profile.name}
                icon={getSocialProfileIcon(profile.name)}
                placeholder={getSocialProfileName(profile.name)}
                label={getSocialProfileName(profile.name)}
              />
            ))}
            <StyledSaveButton
              disabled={settingsSocialProfilesProps.isSaveChangesDisabled}
              onClick={settingsSocialProfilesProps.onSaveChangesClick}
            >
              Save changes
            </StyledSaveButton>
          </>
        </AccordionItem>
      )}
    </AccordionRoot>
  );
};

const MemoizedGeneralSettingsAccordion = memo(GeneralSettingsAccordion);
MemoizedGeneralSettingsAccordion.displayName = 'GeneralSettingsAccordion';
export default MemoizedGeneralSettingsAccordion;
