import React, { ReactNode, useCallback, useEffect } from 'react';

import {
  Main,
  MainHeader,
  MainContent,
  HeaderRouterContainer,
  StyledBackButton,
  NotebookSunsetBannerWrapper,
} from './styles';

import ThemeV2 from '../../../theme';
import { shallow } from 'zustand/shallow';
import SVGIcon from '../../atoms/SVGIcon';
import { useHistory, useLocation } from 'react-router-dom';
import { Flex } from '../../../Utils/styles/display';
import { FILE_PREVIEW } from '../../../constants/routes';
import useLayoutStore from '../../../stores/layoutStore';
import PageHeaderRouter from '../../../controllers/headers/HeaderRouter';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import createStack from '../../../Utils/createStack';
import mobileStore from '../../../stores/mobileAppStore';
import { NotebookSunsetBanner } from '../../atoms/NotebookSunsetBanner';

interface PrimaryLayoutProps {
  children: ReactNode;
  isFlowOwner: boolean;
  isMobileApp: boolean;
  isMobileAppV3?: boolean;
  isNotebookView?: boolean;
  hasTabComponent?: boolean;
}

interface HistoryItem {
  pathname: string;
  search: string;
}

type WindowMessageEvent = {
  type: 'back';
};

const MainBody = ({
  children,
  isMobileApp,
  isFlowOwner,
  isNotebookView,
  hasTabComponent,
  isMobileAppV3,
}: PrimaryLayoutProps) => {
  const historyStack = createStack<HistoryItem>();
  const { isEmbeddedInMainApp, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isEmbeddedInMainApp: state.isEmbeddedInMainApp,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );
  const location = useLocation();
  const isFilePreviewRoute = location.pathname.includes(FILE_PREVIEW);
  const history = useHistory();
  const currentPath = location.pathname;
  const currentSearch = location.search;
  const latestPathOnStack = historyStack.peek()?.pathname || '';
  const isNavigatingWithinTab =
    latestPathOnStack?.split('/')[2] === currentPath.split('/')[2];

  useEffect(() => {
    const shouldPushToStack =
      isEmbeddedInMainApp &&
      latestPathOnStack !== currentPath &&
      !isNavigatingWithinTab;

    if (shouldPushToStack) {
      historyStack.push({ pathname: currentPath, search: currentSearch });
    }
  }, [
    currentPath,
    currentSearch,
    historyStack,
    isEmbeddedInMainApp,
    isNavigatingWithinTab,
    latestPathOnStack,
  ]);

  const handleOnBackButtonClick = useCallback(() => {
    // Top of the stack will always be current path
    // Hence we are poping the element, and navigating to the next top item in the stack.
    if (currentPath === latestPathOnStack) {
      historyStack.pop();
    }
    const path = historyStack.pop();
    if (path) {
      history.push(path);
    } else {
      window.parent.postMessage(
        { type: 'NAVIGATE_BACK', payload: currentPath },
        '*',
      );
    }
  }, [currentPath, history, historyStack, latestPathOnStack]);

  useEffect(() => {
    const messageHandler = (message: MessageEvent<WindowMessageEvent>) => {
      if (message.data.type === 'back') {
        handleOnBackButtonClick();
      }
    };
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [handleOnBackButtonClick]);

  const backButtonMarginTopPathnames = ['/settings', '/rewards'];
  const backButtonMarginTop = backButtonMarginTopPathnames.some((path) =>
    location.pathname.includes(path),
  )
    ? '-24px'
    : '0px';

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const leftMarginPathNames = ['/notebook'];
  const leftMargin =
    leftMarginPathNames.some((path) => location.pathname.includes(path)) &&
    isEmbeddedInMainApp &&
    !mobileStore.getState().isMobileAppV3
      ? '84px'
      : 'undefined';

  return (
    <Main
      background={
        isNotebookView ? `${ThemeV2.palette.white}` : `${ThemeV2.palette.gray1}`
      }
      isFullViewportHeight={isMobileApp || isEmbeddedInMainApp}
    >
      {location.pathname.includes(`/notebook`) && (
        <NotebookSunsetBannerWrapper>
          <NotebookSunsetBanner />
        </NotebookSunsetBannerWrapper>
      )}

      <MainHeader
        isMobileApp={isMobileApp}
        removeMargin={isNotebookView}
        hasTabComponent={hasTabComponent}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
        background={
          isNotebookView
            ? `${ThemeV2.palette.white}`
            : `${ThemeV2.palette.gray1}`
        }
        hasBorderBottom={
          isEmbeddedInMainApp &&
          !location.pathname.includes('/notebook') &&
          !isFlowOwner &&
          !isMobileView
        }
      >
        {isEmbeddedInMainApp ? (
          !isFilePreviewRoute &&
          !(isRightAsideOpen && isMobileView) && (
            <Flex
              alignItems="unset"
              borderBottom={isEmbeddedInMainApp && isMobileView && isFlowOwner}
            >
              <Flex
                onClick={handleOnBackButtonClick}
                padding={isMobileView ? '4px 0 4px 0' : '4px 20px 4px 28px'}
              >
                <StyledBackButton marginTop={backButtonMarginTop}>
                  <SVGIcon icon="carat-left" size="24px" />
                </StyledBackButton>
              </Flex>

              <HeaderRouterContainer isEmbeddedInMainApp={isEmbeddedInMainApp}>
                <PageHeaderRouter />
              </HeaderRouterContainer>
            </Flex>
          )
        ) : (
          <PageHeaderRouter />
        )}
      </MainHeader>

      <MainContent
        id="scrollableFeedContent"
        data-qa-id="scrollableFeedContent"
        isMobileApp={isMobileApp}
        isFullScreen={isFilePreviewRoute}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
        hasTabComponent={hasTabComponent}
        hasFlowBuilderTabs={isFlowOwner || isNotebookView}
        hasRightMargin={isNotebookView}
        leftMargin={leftMargin}
        isMobileAppV3={isMobileAppV3}
      >
        {children}
      </MainContent>
    </Main>
  );
};

export default MainBody;
