import { useLocation } from 'react-router-dom';

import { PostTypes } from '../../interfaces/Home';
import useLayoutStore from '../../stores/layoutStore';
import { LayoutStoreState } from '../../interfaces/Layout';
import { showChatsSelector } from '../../stores/layoutStore/selectors';
import { useCallback } from 'react';
import useGetPageName from '../useGetPageName';

const useSetRepliesToSidebar = () => {
  const { search } = useLocation();

  const repliesSelector = (state: LayoutStoreState) => state.setShowReplies;
  const setShowReplies = useLayoutStore(repliesSelector);
  const setSelectedPostSelector = (state: LayoutStoreState) =>
    state.setSelectedPost;
  const setShowChats = useLayoutStore(showChatsSelector);

  const type = new URLSearchParams(search).get('type') || '';
  const flowId = new URLSearchParams(search).get('flowId') || '';
  const postId = new URLSearchParams(search).get('postId') || '';
  const commentId = new URLSearchParams(search).get('commentId') || '';
  const responseId = new URLSearchParams(search).get('responseId') || '';
  const setSelectedPost = useLayoutStore(setSelectedPostSelector);

  const handleSetSelectedPost = useCallback(() => {
    setSelectedPost({
      type: type as PostTypes,
      flowId,
      postId,
      commentId,
      responseId,
    });
  }, [commentId, flowId, postId, responseId, setSelectedPost, type]);

  const handleSetReplies = useCallback(() => {
    setShowReplies(true);
    setShowChats(true);
    handleSetSelectedPost();
  }, [handleSetSelectedPost, setShowChats, setShowReplies]);

  // Compute Should Reset Replies
  const { page } = useGetPageName();
  const noResetRepliesPageNames = [
    'single post flows',
    'single post recognition',
  ];
  const shouldResetReplies = !noResetRepliesPageNames.some(
    (name) => page === name,
  );

  return {
    handleSetReplies,
    shouldResetReplies,
    handleSetSelectedPost,
  };
};

export default useSetRepliesToSidebar;
