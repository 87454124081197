import produce from 'immer';
import { MenuItemIndividualItem } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { NotebookStore } from './types';

export const filterDropdownDataByCurrentSection = ({
  sectionalDropdownData,
  currentSection,
}: {
  sectionalDropdownData: MenuItemIndividualItem[];
  currentSection: {
    sectionId: string;
    sectionTitle: string;
  };
}) => {
  const { sectionId, sectionTitle } = currentSection;
  return produce(sectionalDropdownData, (draft) => {
    const index = draft?.findIndex((section) => section.id === sectionId);
    if (index > -1) {
      draft[index] = {
        id: sectionId,
        value: sectionTitle,
      };
    } else {
      draft.push({
        id: sectionId,
        value: sectionTitle,
      });
    }
  });
};

export const getUpdatedNotebookStoreStateAfterSectionDeletion = ({
  currentNotebookStoreState,
  sectionIdToBeDeleted,
}: {
  currentNotebookStoreState: NotebookStore;
  sectionIdToBeDeleted: string;
}) => {
  const { boardSectionData, sectionOrder, sectionalDropdownData, boardNotes } =
    currentNotebookStoreState;
  const updatedBoardViewNotes = { ...boardNotes };
  const updatedBoardSectionData = { ...boardSectionData };
  delete updatedBoardSectionData[sectionIdToBeDeleted];
  delete updatedBoardViewNotes[sectionIdToBeDeleted];

  const updatedSectionOrder = sectionOrder.filter(
    (section) => section !== sectionIdToBeDeleted,
  );
  const updatedSectionDropdownData = sectionalDropdownData.filter(
    (section) => section?.id !== sectionIdToBeDeleted,
  );
  return {
    boardSectionData: updatedBoardSectionData,
    sectionOrder: updatedSectionOrder,
    sectionalDropdownData: updatedSectionDropdownData,
    boardNotes: updatedBoardViewNotes,
  };
};
