import { LocationDescriptor, LocationState } from 'history';
import { useHistory } from 'react-router-dom';
import useMultiAssemblySlug from '../useMultiAssemblySlug';

const useHistoryWrapper = () => {
  const history = useHistory();
  const { slugUrl } = useMultiAssemblySlug();

  const push = (location: LocationDescriptor<LocationState>) => {
    if (typeof location === 'string') {
      history.push(`/${slugUrl}${location}`);
    } else {
      const { pathname, ...rest } = location;
      const url = `/${slugUrl}${pathname}`;
      history.push({
        pathname: url,
        ...rest,
      });
    }
  };

  const replace = (url: string) => {
    history.replace(`/${slugUrl}${url}`);
  };

  return {
    push,
    replace,
    replaceParams: history.replace,
  };
};

export default useHistoryWrapper;
