import { RadioOptionProps } from '../../../atomic/molecules/RadioGroup';
import { BUILDER_VISIBILITY_BLOCK } from '../../../languages/en/flows/builder';

export const visibilityTypes = {
  ENTIRE_ORGANIZATION: 'EVERYONE',
  // eslint-disable-next-line max-len
  EVERYONE: 'EVERYONE', // Just a bit of redundancy here in case any instances of .EVERYONE still exist, pretty sure I caught them all but not 100% and don't want to break the flow builder accidentally over a text change. Once we're sure we should delete this option.
  PARTICIPANTS_ONLY: 'PARTICIPANTS_ONLY',
  OWNER_AND_COLLABORATORS_ONLY: 'OWNER_AND_COLLABORATORS_ONLY',
  CUSTOM: 'CUSTOM',
};

export const visibilityRadioOptions: RadioOptionProps[] = [
  {
    value: visibilityTypes.ENTIRE_ORGANIZATION,
    label: BUILDER_VISIBILITY_BLOCK.ENTIRE_ORGANIZATION,
  },
  {
    value: visibilityTypes.PARTICIPANTS_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY,
  },
  {
    value: visibilityTypes.OWNER_AND_COLLABORATORS_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.OWNER_ONLY,
  },
  {
    value: visibilityTypes.CUSTOM,
    label: BUILDER_VISIBILITY_BLOCK.CUSTOM,
  },
];
