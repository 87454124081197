import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  KnowledgeCenterViewWrapper,
  KnowledgeCenterStyledIconButton,
  KnowledgeCenterActionsWrapper,
  StyledIcon,
} from './styles';
import { Flex } from '../../../Utils/styles/display';
import SVGIcon from '../../atoms/SVGIcon';
import NewTextfield from '../NewTextfield';
import {
  KNOWLEDGE_CENTER_CARD_VIEW,
  KNOWLEDGE_CENTER_LIST_VIEW,
} from '../../../constants/routes';
import { trackKnowledgeCenterActionEvent } from '../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../Utils/analytics/constants';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';

const KnowledgeCenterActions = (props: {
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { setSearchValue } = props;
  const history = useHistoryWrapper();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');

  const isCardView = location.pathname.includes('card');
  const isListView = location.pathname.includes('list');

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      trackKnowledgeCenterActionEvent({
        action: KnowledgeCenterEvents.SearchApplied,
        searchQuery: searchText,
        view: isCardView ? 'gallery' : 'list',
      });
      setSearchValue(searchText);
    }
    if (searchText === '') {
      setSearchValue('');
    }
  };

  return (
    <KnowledgeCenterActionsWrapper>
      <Flex height="40px" width="100%" flexGrow={1}>
        <NewTextfield
          type={'textV3_text'}
          icon={'search'}
          placeholder={'Search Files'}
          size={'medium'}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            if (e.target.value === '') {
              setSearchValue('');
            }
          }}
          onKeyPress={handleKeypress}
          suffix={
            searchText && <StyledIcon icon={'close-rounded'} size="20px" />
          }
          onSuffixAdornmentClick={() => {
            setSearchText('');
            setSearchValue('');
          }}
        />
        <KnowledgeCenterViewWrapper>
          <KnowledgeCenterStyledIconButton
            onClick={() => {
              history.push(
                KNOWLEDGE_CENTER_CARD_VIEW +
                  (location.search ? location.search : ''),
              );
            }}
            isActiveView={isCardView}
            color={isCardView ? 'selectedTertiary' : 'tertiary'}
          >
            <SVGIcon icon="grid-view" size="24px" />
          </KnowledgeCenterStyledIconButton>
          <KnowledgeCenterStyledIconButton
            onClick={() => {
              history.push(
                KNOWLEDGE_CENTER_LIST_VIEW +
                  (location.search ? location.search : ''),
              );
            }}
            isActiveView={isListView}
            color={isListView ? 'selectedTertiary' : 'tertiary'}
          >
            <SVGIcon icon="list-view" size="24px" />
          </KnowledgeCenterStyledIconButton>
        </KnowledgeCenterViewWrapper>
      </Flex>
    </KnowledgeCenterActionsWrapper>
  );
};

export default KnowledgeCenterActions;
