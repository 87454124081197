import React from 'react';

import { SlackButton } from './styles';
import { ConnectToSlackProps } from './types';
import useOpenSlackModal from './useOpenSlackModal';
import useSlackUserStore from '../../../stores/userStore';

const ConnectToSlackButton = (props: ConnectToSlackProps) => {
  const { className, disabled } = props;

  const { isCreatingConnectionPool } = useSlackUserStore();
  const { open, isLoading, isError } = useOpenSlackModal();

  return (
    <SlackButton
      size="medium"
      onClick={open}
      className={className}
      icon="slack-mini-logo-white"
      data-test-id="connect-to-slack"
      disabled={isLoading || isError || disabled}
      isLoading={isLoading || isCreatingConnectionPool}
    >
      Connect to Slack
    </SlackButton>
  );
};

export default ConnectToSlackButton;
