import React, { SyntheticEvent } from 'react';

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  INVITE_MEMBERS,
  NO_MEMBERS_CONTENT,
  NO_MEMBERS_HEADER,
} from '../../../../languages/en/giveRecognitionForm/selectMembers';
import InviteUsersImage from '../../../../img/invite-users.svg';
import Image from '../../../atoms/Image';
import Button from '../../../atoms/Button';
import Body from '../../../atoms/Body';
import { MultiSelectAutoComplete } from '../../../organism/Autocomplete';
import { AutocompleteDropdownItem } from '../../../organism/Autocomplete/interfaces';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import routesList from '../../../../routesList';
import { device } from '../../../../constants/layout';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import { FilterOptionsState } from '@mui/material/useAutocomplete';

export interface FlowMultiselectInputBlockProps<
  T extends string | number,
  U = undefined,
> {
  blockError?: string;
  fieldError?: string;
  title: string;
  description?: string;
  goToNextStep: () => void;
  inputLabel: string;
  options: AutocompleteDropdownItem<T, U>[];
  value: AutocompleteDropdownItem<T, U>[];
  onChange: (val: AutocompleteDropdownItem<T, U>[]) => void;
  isRequired: boolean;
  instructions?: string;
  textboxValue?: string;
  loading?: boolean;
  onTextboxValueChange?: (value: string) => void;
  onBlur?: () => void;
  inputBaseRightPadding?: string;
  warning?: React.ReactNode;
  hasNoOptions?: boolean;
  onOptionsScroll?: () => void;
  optionsMaxLimit?: number;
  isLastBlock?: boolean;
  isPreviewFlow?: boolean;
  disabled?: boolean;
  children?: React.ReactElement;
  filterOptionsFunction?: (
    options: AutocompleteDropdownItem<T, U>[],
    state: FilterOptionsState<AutocompleteDropdownItem<T, U>>,
  ) => AutocompleteDropdownItem<T, U>[];
}

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const NoInvitedMemberImage = styled(Image)`
  max-height: 180px;
  margin: 0 32px 32px 0;
`;

const HasNoMemberContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tabletOrDesktop} {
    flex-direction: row;
  }
`;

function FlowMultiselectInputBlock<T extends string | number, U>({
  blockError,
  fieldError,
  goToNextStep,
  title,
  inputLabel,
  options,
  value,
  onChange,
  isRequired,
  description,
  instructions,
  textboxValue,
  onTextboxValueChange,
  loading,
  onBlur,
  inputBaseRightPadding,
  warning,
  hasNoOptions,
  onOptionsScroll,
  optionsMaxLimit,
  isPreviewFlow,
  isLastBlock = false,
  disabled,
  children,
  filterOptionsFunction,
}: FlowMultiselectInputBlockProps<T, U>) {
  const handleOptionsScroll = (event: SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight * 0.7 <=
      listboxNode.scrollTop + listboxNode.clientHeight
    ) {
      if (onOptionsScroll) {
        onOptionsScroll();
      }
    }
  };

  return (
    <FlowsBaseInputBlock
      blockError={blockError}
      fieldError={fieldError}
      description={description}
      isRequired={isRequired}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
      subDescription={instructions}
      title={title}
      warning={warning}
    >
      {hasNoOptions ? (
        <HasNoMemberContainer>
          <NoInvitedMemberImage
            src={InviteUsersImage}
            alt="Invite Users Image"
          />
          <div>
            <Body variant="body1Medium">{NO_MEMBERS_HEADER}</Body>
            <Body variant="body2">{NO_MEMBERS_CONTENT}</Body>
            <Link to={routesList.ADMIN_USER_MANAGE}>
              <StyledButton isFullWidth>{INVITE_MEMBERS}</StyledButton>
            </Link>
          </div>
        </HasNoMemberContainer>
      ) : (
        <MultiSelectAutoComplete
          autoFocus
          label={inputLabel}
          options={options}
          value={value}
          onChange={onChange}
          textboxSize="large"
          textboxValue={textboxValue}
          onTextboxValueChange={onTextboxValueChange}
          loading={loading}
          onBlur={onBlur}
          inputBaseRightPadding={inputBaseRightPadding}
          optionsMaxLimit={optionsMaxLimit}
          ListboxProps={{
            onScroll: handleOptionsScroll,
          }}
          disabled={disabled}
          filterOptionsFunction={filterOptionsFunction}
        >
          {children}
        </MultiSelectAutoComplete>
      )}
    </FlowsBaseInputBlock>
  );
}

export default FlowMultiselectInputBlock;
