import styled from 'styled-components';
import Clickable from '../../atomic/atoms/Clickable_V2';
import ThemeV2 from '../../theme';

export const StyledBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  height: 48px;
`;

export const StyledRightDiv = styled.div`
  margin-left: auto;
`;

export const StyledClickable = styled(Clickable)`
  color: ${ThemeV2.palette.gray9};
  font-size: 14px;
  text-decoration: underline;
  margin-left: 8px;
`;

export const DisableAPIKeyButton = styled(Clickable)`
  color: ${ThemeV2.palette.red6};
  font-size: 14px;
  text-decoration: underline;
  margin-left: 8px;
`;
