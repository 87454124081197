import React from 'react';
import styled, { css } from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';

import noop from 'lodash/noop';
import {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
  RefObject,
  memo,
} from 'react';
import ThemeV2 from '../../../theme';
import Body from '../Body';
import Clickable from '../Clickable';
import IconButton from '../IconButton';
import SVGIcon from '../SVGIcon';

const StyledOutlinedInput = styled(OutlinedInput)<{
  $width: string;
  $icon?: string;
  $domain?: any;
  $iconBackgroundColor?: string;
  $isValidInput?: boolean;
  $applyCustomAdornmentStyles: boolean;
}>`
  height: 40px;
  display: flex;
  width: ${(props) => props.$width};
  padding: 0px;
  color: ${ThemeV2.palette.gray8};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  border-color: ${(props) =>
    props?.$isValidInput ? ThemeV2.palette.green7 : ThemeV2.palette.gray5};
  pointer-events: ${(props) => (props?.$isValidInput ? 'none' : 'auto')};
  box-sizing: border-box;
  transition: all 0.3s;
  && .MuiInputAdornment-root {
    height: 40px;
    border-radius: 3px 0px 0px 3px;
    padding: 0px 12px;
    background-color: ${(props: any) =>
      props?.disabled ? ThemeV2.palette.gray3 : 'inherit'};
  }
  &.MuiOutlinedInput-adornedStart,
  &.MuiOutlinedInput-adornedEnd {
    padding-left: 0px;
    padding-right: 0px;
  }
  &.MuiInputBase-adornedEnd {
    & .MuiInputAdornment-root {
      border-radius: 0px 3px 3px 0px;
    }
  }

  &:hover {
    &:not(.Mui-disabled) {
      border-color: ${ThemeV2.palette.geekBlue5};
    }
    & .MuiInputAdornment-root {
      background-color: ${(props) =>
        props.$iconBackgroundColor
          ? ThemeV2.palette.geekBlue6
          : ThemeV2.palette.gray1};
    }
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.$iconBackgroundColor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
    }
  }
  &.Mui-focused {
    &:not(.Mui-disabled) {
      box-shadow: 0px 0px 4px rgba(24, 144, 255, 0.5);
      border-color: ${ThemeV2.palette.geekBlue5};
    }
    & .MuiInputAdornment-root {
      background-color: ${(props) =>
        props.$iconBackgroundColor || ThemeV2.palette.gray1};
    }
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.$iconBackgroundColor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
      transition: all 0.3s;
    }
  }
  &.Mui-error {
    border-color: ${ThemeV2.palette.red6};
    & .MuiInputAdornment-root svg path {
      fill: ${(props) =>
        props.$iconBackgroundColor
          ? ThemeV2.palette.gray1
          : ThemeV2.palette.gray6};
    }
    ${(props) =>
      props.$applyCustomAdornmentStyles &&
      css`
        &:hover {
          & .MuiInputAdornment-root {
            background-color: ${ThemeV2.palette.red6};
          }
        }
      `}
    ${(props) =>
      !props.$applyCustomAdornmentStyles &&
      css`
        & .MuiInputAdornment-root {
          color: ${ThemeV2.palette.red6};
        }
      `}
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  & .MuiOutlinedInput-input {
    padding: 8px 12px;
    padding-left: ${(props) =>
      (props.$icon && !props.$iconBackgroundColor) || props.$domain
        ? '0px'
        : '12px'};
    font-size: 16px;
    line-height: 24px;
    height: unset;
    border-radius: 3px;
    &.Mui-disabled {
      border-color: ${(props) => props.disabled && ThemeV2.palette.gray5};
      background: ${(props) => props.disabled && ThemeV2.palette.gray3};
    }
    &::placeholder {
      color: ${ThemeV2.palette.gray6};
      opacity: 1;
    }
  }
  & .MuiInputAdornment-root {
    margin: 0;
    padding: 12px;
    padding-right: ${(props) =>
      props.$icon && !props.$iconBackgroundColor ? '8px' : '12px'};
    max-height: 40px;
    background-color: ${(props) =>
      props.$iconBackgroundColor
        ? ThemeV2.palette[props.$iconBackgroundColor]
        : ThemeV2.palette.gray1};
    transition: all 0.3s;
    &.domainInput {
      width: 159px;
      padding-right: 4px;
    }
  }
`;

const StyledIconButton = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    top: 50%;
    right: ${({ $endIcon }) => ($endIcon ? '40px' : '8px')};
    padding: 4px;
    transform: translateY(-50%);
  }
`;

const InputWrapper = styled.div<{
  $disabled?: boolean;
}>`
  position: relative;
  & .MuiInputBase-root {
    height: auto;
    pointer-events: ${({ $disabled }: any) => ($disabled ? 'none' : 'auto')};
    cursor: ${({ $disabled }: any) => ($disabled ? 'not-allowed' : 'auto')};
    border: ${({ $disabled }: any) => $disabled && 'none'};
  }
`;

const StyledClickable = styled(Clickable)<{
  $disabled?: boolean;
}>`
  display: flex;
  background-color: ${({ $disabled }: any) =>
    $disabled ? `${ThemeV2.palette.gray3}` : 'inherit'};
`;

type TextFieldProps = {
  type?: 'text' | 'password' | 'number';
  name?: string;
  value?: string | number;
  placeholder: string;
  error?: boolean;
  autoFocus?: boolean;
  isValid?: boolean;
  domain?: string;
  className?: string;
  fullWidth?: boolean;
  iconBackgroundColor?: keyof typeof ThemeV2.palette;
  width?: string;
  disabled?: boolean;
  innerRef?: RefObject<unknown>;
  clearTextEnabled?: boolean;

  icon?: string;
  iconSize?: string;
  endIcon?: string;
  onIconClick?: MouseEventHandler<HTMLButtonElement>;
  onEndIconClick?: MouseEventHandler<HTMLButtonElement>;

  endAdornment?: ReactNode;

  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onClearClick?: MouseEventHandler<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export default memo(function TextField({
  type = 'text',
  value = '',
  placeholder,
  className: parentClassName = '',
  icon,
  iconSize = '18px',
  domain,
  error = false,
  autoFocus = false,
  isValid,
  name = '',
  fullWidth = true,
  width = 'auto',
  iconBackgroundColor,
  disabled = false,
  innerRef,
  endIcon,
  onIconClick = noop,
  onEndIconClick = noop,
  clearTextEnabled = false,
  onChange = noop,
  onBlur = noop,
  onFocus = noop,
  onClearClick,
  onClick = noop,
  onKeyDown = noop,
  endAdornment,
}: TextFieldProps) {
  let startAdornmentComponent;
  let endAdornmentComponent;

  if (icon) {
    startAdornmentComponent = (
      <InputAdornment position="start">
        <StyledClickable onClick={onIconClick} $disabled={disabled}>
          <SVGIcon icon={icon} size={iconSize} color={ThemeV2.palette.gray6} />
        </StyledClickable>
      </InputAdornment>
    );
  }

  if (endIcon) {
    endAdornmentComponent = (
      <InputAdornment position="start">
        <StyledClickable onClick={onEndIconClick} $disabled={disabled}>
          <SVGIcon
            icon={endIcon}
            size={iconSize}
            color={ThemeV2.palette.gray6}
          />
        </StyledClickable>
      </InputAdornment>
    );
  }

  if (domain) {
    startAdornmentComponent = (
      <InputAdornment className="domainInput" position="start">
        <Body variant="body1Italic">{domain}</Body>
      </InputAdornment>
    );
  }

  return (
    <InputWrapper $disabled={disabled}>
      <StyledOutlinedInput
        name={name}
        placeholder={placeholder}
        className={parentClassName}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        startAdornment={startAdornmentComponent}
        endAdornment={endAdornment || endAdornmentComponent}
        $applyCustomAdornmentStyles={!endAdornment}
        type={type}
        aria-describedby="outlined-weight-helper-text"
        notched
        error={error}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        disabled={disabled}
        ref={innerRef}
        onClick={onClick}
        $iconBackgroundColor={iconBackgroundColor}
        $width={width}
        $icon={icon}
        $isValidInput={isValid}
      />
      {clearTextEnabled && (
        <StyledIconButton
          color="primary"
          aria-label="caret icon"
          component="span"
          onClick={onClearClick}
          $endIcon={endIcon}
        >
          <SVGIcon
            icon="round-close"
            size={iconSize}
            color={ThemeV2.palette.gray8}
          />
        </StyledIconButton>
      )}
    </InputWrapper>
  );
});
