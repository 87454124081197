export enum TemplateAppAction {
  StartFromScratchClicked = 'startFromScratchClicked',
  CloseTemplateGalleryClicked = 'closeTemplateGalleryClicked',
  TemplateGalleryLoaded = 'templateGalleryLoaded',
  TemplateSelected = 'templateSelected',
}

export type FlowTemplateProps = {
  color: string;
  emoticon: string;
  templateId: string;
  title: string;
  filterApplied?: string;
  searchQuery?: string;
};

export type TemplateEventData = {
  action: TemplateAppAction;
  targetEvent: 'templates';
  payload?: FlowTemplateProps;
};
