import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PrimaryNavLinkProps, StyledPrimaryNavLinkProps } from './interfaces';
import NavItem from '../../atoms/PrimaryNavItem';
import ThemeV2 from '../../../theme';

const StyledPrimaryNavLink = styled(Link)<StyledPrimaryNavLinkProps>`
  display: flex;
  text-decoration: none;
  margin: 4px;
`;

const StyledPrimaryNavDiv = styled.div`
  display: flex;
  text-decoration: none;
  margin: 4px;
`;

export const StyledExternalPrimaryNavLink = styled.a`
  display: flex;
  text-decoration: none;
  margin: 4px;
  padding: 4px 10px;
  color: ${ThemeV2.palette.gray8};

  &:hover > * {
    color: ${ThemeV2.palette.geekBlue6};
  }
`;

const PrimaryNavLink = (props: PrimaryNavLinkProps) => {
  const { children, icon, to, size, isActive, onClick } = props;
  if (to) {
    if (to.includes('http')) {
      return (
        <StyledPrimaryNavLink
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          /* @ts-ignore */
          to={{
            pathname: to,
          }}
          target="_blank"
          onClick={onClick}
        >
          <NavItem icon={icon} size={size} isActive={isActive}>
            {children}
          </NavItem>
        </StyledPrimaryNavLink>
      );
    } else {
      return (
        <StyledPrimaryNavLink to={to} onClick={onClick}>
          <NavItem icon={icon} size={size} isActive={isActive}>
            {children}
          </NavItem>
        </StyledPrimaryNavLink>
      );
    }
  } else {
    return (
      <StyledPrimaryNavDiv onClick={onClick}>
        <NavItem icon={icon} size={size} isActive={isActive}>
          {children}
        </NavItem>
      </StyledPrimaryNavDiv>
    );
  }
};

export default PrimaryNavLink;
