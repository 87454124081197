export const Icon = {
  FORMAT: 'format-icon',
  AT_MENTION: 'atmention',
  EMOTICON: 'emoticon',
  GIF_SELECTOR: 'gif-selector-icon',
  ATTACHMENT: 'attachment-icon',
  LOCK: 'lock',
  UN_LOCK: 'lock-icon',
  TASKS: 'note',
};
