import styled from 'styled-components';
import Button from '../../atoms/Button';

export const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 20px 100px auto 100px;
  position: fixed;
  bottom: 10px;
  left: 20px;
  right: 20px;
  width: auto;
  height: 64px;
  background: #ffffff;
  box-shadow: 5px 5px 15px 0px rgba(138, 138, 138, 0.6);
  padding: 8px;
  border-radius: 8px;
  z-index: 10;
  align-items: center;
  grid-template-columns: auto 160px auto auto;
`;

export const OpenAppButton = styled(Button)`
  border-radius: 8px;
`;

export const AppImage = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 8px;
`;
