import styled from 'styled-components';

import Body from '../../../atoms/Body';
import Button from '../../../atoms/Button';
import TextWithIcon from '../../../atoms/TextWithIcon';
import UserListModal from '../../../molecules/UserListModal';
import { Card } from '../../../molecules/UserNameCard/styles';

import ThemeV2 from '../../../../theme';
import Clickable from '../../../atoms/Clickable_V2';
import Avatar from '../../../atoms/Avatar';

export const StyledWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  padding: 16px 24px;
`;

export const StyledTextWithIcon = styled(TextWithIcon)`
  align-items: center;
`;

export const StyledSpacing = styled.div<{ margin: string }>`
  margin: ${({ margin }) => margin};
`;

export const StyledUserListModal = styled(UserListModal)`
  max-width: 320px;
  ${Card} {
    height: 40px;
  }
`;

export const StyledDownloadButton = styled(Button)`
  margin: 12px 24px 0px;
  width: calc(100% - 48px);
`;

export const StyledDescription = styled(Body)`
  overflow-wrap: break-word;
  display: flow-root;
`;

export const StyledClickable = styled(Clickable)`
  color: ${ThemeV2.palette.geekBlue6};
  font-size: medium;
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;

export const StyledBody = styled(Body)`
  margin-left: 32px;
`;

export const LinkBody = styled(Body)`
  cursor: pointer;
`;
