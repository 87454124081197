import styled from 'styled-components';
import ThemeV2 from '../../../theme';

import { Flex } from '../../../Utils/styles/display';

import { StyledOnlineStatusProps, StyledAvatarTextProps } from './interfaces';

const onlineStatusColors = {
  active: ThemeV2.palette.polarGreen6,
  passive: ThemeV2.palette.sunriseYellow6,
  inactive: ThemeV2.palette.gray6,
};

export const AvatarContainer = styled.div<{
  size: string;
  isDeleted?: boolean;
  iconBgColor?: string;
  restrictHeight?: boolean;
}>`
  height: ${({ size }) => size};
  min-width: ${({ size }) => size};
  width: ${({ size }) => size};
  position: relative;
  & span {
    height: 100%;
    width: 100%;
    background-color: ${({ isDeleted, iconBgColor }) =>
      isDeleted ? ThemeV2.palette.gray4 : iconBgColor};
    display: flex;
    justify-content: center;
    border-radius: 100%;
    svg {
      ${({ restrictHeight }) => restrictHeight && 'height: 50%;'}
      ${({ restrictHeight }) => restrictHeight && 'min-width: 50%;'}
    }
  }
`;

export const AvatarBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  span {
    background: ${ThemeV2.palette.gray10};
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    opacity: 0;
    svg {
      height: 33.3%;
      min-width: 33.3%;
    }
    & :hover {
      opacity: 1;
    }
  }
`;

export const Img = styled.img<{
  hide: boolean;
  verticalAlign?: string;
  backgroundColor?: string;
}>`
  display: ${({ hide }) => (hide ? 'none' : 'initial')};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  height: 100%;
  width: 100%;
  border-radius: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const StyledOnlineStatus = styled.div<StyledOnlineStatusProps>`
  border-radius: 10px;
  width: 10px;
  height: 10px;
  background-color: ${(props) =>
    props.onlineStatus && onlineStatusColors[props.onlineStatus]};
  border: 2px solid ${(props) => props.onlineStatusBorderColor};
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const StyledAvatarText = styled(Flex)<StyledAvatarTextProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.fontWeightSemibold};
  width: ${(props) => (props.size ? props.size : '40px')};
  height: ${(props) => (props.size ? props.size : '40px')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

Img.displayName = 'Img';
