import Button from '../../atoms/Button';
import { SHARE } from '../../../languages/en/singleWords';
import { RequestedUsersCountContainer } from './styles';
import Body from '../../atoms/Body';

export const renderEditorShareButton = ({
  requestedUsersCount,
  isShareRequestsLoading,
  handleOpenShareSheetModal,
}: {
  requestedUsersCount?: number;
  isShareRequestsLoading?: boolean;
  handleOpenShareSheetModal: () => void;
}) => {
  return (
    <Button
      icon="share"
      onClick={handleOpenShareSheetModal}
      size="medium"
      data-test-id="share-button"
      className="introjs-sharesheet-share-button"
      isLoading={isShareRequestsLoading}
    >
      {SHARE}
      {!!requestedUsersCount && (
        <RequestedUsersCountContainer>
          <Body variant="body3" color="gray1">
            {requestedUsersCount}
          </Body>
        </RequestedUsersCountContainer>
      )}
    </Button>
  );
};
