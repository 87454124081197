import { FieldInputProps, FieldMetaProps, FormikErrors } from 'formik';
import { InferProps } from 'prop-types';
import React, { useCallback, useMemo, ValidationMap } from 'react';

import Input from '../Input';
import { RadioOptionProps } from '../RadioGroup';

// From Input/index.jsx
type FormikFieldType =
  | 'text'
  | 'textV2_text'
  | 'textV2_number'
  | 'textV2_password'
  | 'textV3_text'
  | 'textV3_number'
  | 'textV3_password'
  | 'textArea'
  | 'number'
  | 'password'
  | 'button'
  | 'submit'
  | 'dropdown'
  | 'dropdownWithMultiSelect'
  | 'textWithChips'
  | 'date'
  | 'radio'
  | 'checkbox'
  | 'textV3_text_emoticon_picker'
  | 'flowCheckbox'
  | 'singleSelectAutoComplete';

// From node_modules/formik/dist/Formik.d.ts. Taken only required fields
export interface FormikType<Values> {
  values: Values;
  isValid: boolean;
  dirty: boolean;
  initialValues: Values;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  getFieldProps: (nameOrOptions: string) => FieldInputProps<Values>;
  getFieldMeta: (name: string) => FieldMetaProps<Values>;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
}

export interface FormikFieldProps<Values> {
  formik: FormikType<Values>;
  name: string;
  type: FormikFieldType;
  value?: string | number | boolean | string[];
  helperText?: string;
  hasDropdown?: boolean;
  isMultiSelectEnable?: boolean;
  disableClearButton?: boolean;
  label?: string | React.ReactNode;
  placeholder?: string;
  disabled?: boolean;
  size?: string;
  radioOptions?: RadioOptionProps[];
  strength?: string;
  validationTexts?: {
    text: string;
    checked: boolean;
  }[];
  img?: string;
  autoFocus?: boolean;
  dropdownItems?: any;
  autoComplete?: string;
  expandableDropdown?: boolean;
}

function FormikField<
  Values extends
    | NonNullable<
        | string
        | number
        | boolean
        | InferProps<ValidationMap<any>>
        | null
        | undefined
      >
    | null
    | undefined,
>({
  formik,
  name,
  type,
  value: fieldValue,
  helperText,
  isMultiSelectEnable,
  disableClearButton,
  dropdownItems,
  autoComplete,
  expandableDropdown,
  ...props
}: FormikFieldProps<Values>) {
  const { getFieldProps, getFieldMeta, setFieldTouched, setFieldValue } =
    formik;
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  const { onBlur, onChange, value: formValues } = field;
  const { error, touched } = meta;
  const value =
    type === 'button' || type === 'submit' ? fieldValue : formValues;
  const handleChange = useCallback(
    (valueOrEvent, secondArg) => {
      if (
        [
          'dropdownWithMultiSelect',
          'date',
          'textV2_text',
          'textV2_number',
          'textV2_password',
          'textV3_text_emoticon_picker',
          'singleSelectAutoComplete',
        ].indexOf(type) !== -1
      ) {
        setFieldValue(name, valueOrEvent);
        setFieldTouched(name, true);
      } else if (['textWithChips'].indexOf(type) !== -1) {
        setFieldValue(name, secondArg);
        setFieldTouched(name, true);
      } else {
        onChange(valueOrEvent);
      }
    },
    [type, setFieldTouched, name, setFieldValue, onChange],
  );
  const errorMessage = useMemo(() => {
    if (typeof error === 'string') {
      return error;
    } else if (typeof error === 'object') {
      return (Object.values(error)[0] as string) || 'Required';
    }
    return 'Required';
  }, [error]);
  return (
    <Input
      type={type}
      {...props}
      name={name}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      error={touched && !!error}
      helperText={touched && !!error ? errorMessage : helperText}
      isMultiSelectEnable={isMultiSelectEnable}
      disableClearButton={disableClearButton}
      options={dropdownItems}
      autoComplete={autoComplete}
      expandableDropdown={expandableDropdown}
    />
  );
}

export default FormikField;
