import React, { useCallback, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { MenuList } from '@mui/material';

import { useMediaQuery } from 'react-responsive';

import IconButton from '../../../atoms/IconButton_V2';
import NewTextfield from '../../../molecules/NewTextfield';
import SVGIcon from '../../../atoms/SVGIcon';
import { TaskOption } from './TaskOption';
import { TasksAutoCompleteProps } from './interfaces';
import { device } from '../../../../constants/layout';
import {
  CLOSE_BUTTON_LABEL,
  CREATE_ONE_HERE,
  NO_NOTEBOOK_TASKS,
  NO_TASKS_FROM_SEARCH,
  PLEASE_TRY_AGAIN,
  SEARCH_ERROR_MESSAGE,
  SEARCH_INPUT_LABEL,
  SEARCH_PLACEHOLDER,
} from '../../../../languages/en/notebook';
import {
  CreateTaskButton,
  IconButtonWrapper,
  NoResultsContainer,
  SearchBoxContainer,
  TryAgainButton,
} from './styles';
import ThemeV2 from '../../../../theme';

export const TasksAutocomplete = ({
  currentUserTimezone,
  isError,
  isLoading,
  onChange,
  onClose,
  onCreateTaskClick,
  onOptionSelect,
  onTryAgain,
  options,
  value,
}: TasksAutoCompleteProps) => {
  const [prefixColor, setPrefixColor] = useState<string>();
  const handleBlur = useCallback(() => setPrefixColor(undefined), []);
  const handleInputFocus = useCallback(
    () => setPrefixColor(ThemeV2.palette.geekBlue6),
    [],
  );

  const handleKeyDown = useCallback((e) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        // Disable the default behavior of `<MenuList />` which
        // auto-focuses a menu option if the first character typed matches that of the menu option.
        e.stopPropagation();
    }
  }, []);

  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });

  let optionsContainer;

  if (isError) {
    optionsContainer = (
      <NoResultsContainer>
        {SEARCH_ERROR_MESSAGE}{' '}
        <TryAgainButton onClick={onTryAgain}>{PLEASE_TRY_AGAIN}</TryAgainButton>
      </NoResultsContainer>
    );
  } else if (isLoading) {
    optionsContainer = (
      <ContentLoader
        speed={2}
        width={438}
        height={60}
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loading-tasks-indicator"
      >
        <circle cx="20" cy="20" r="6.66667" />
        <rect x="32" y="14" rx="4" width="308" height="12" />
        <rect x="32" y="34" rx="4" width="106" height="12" />
      </ContentLoader>
    );
  } else if (options.length === 0) {
    const noResultsCopy =
      value.length > 0 ? (
        NO_TASKS_FROM_SEARCH
      ) : (
        <>
          {NO_NOTEBOOK_TASKS}{' '}
          <CreateTaskButton onClick={onCreateTaskClick}>
            {CREATE_ONE_HERE}
          </CreateTaskButton>
        </>
      );

    optionsContainer = <NoResultsContainer>{noResultsCopy}</NoResultsContainer>;
  } else {
    optionsContainer = options.map((option) => (
      <TaskOption
        {...option}
        currentUserTimezone={currentUserTimezone}
        key={option.noteId}
        onClick={onOptionSelect}
      />
    ));
  }

  return (
    <MenuList disablePadding variant="menu">
      <SearchBoxContainer>
        <NewTextfield
          autoComplete="off"
          autoFocus
          name={SEARCH_INPUT_LABEL}
          onBlur={handleBlur}
          onChange={onChange}
          onInputFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          placeholder={SEARCH_PLACEHOLDER}
          prefix={
            <SVGIcon
              color={prefixColor}
              icon="magnify-thick-handle"
              size="16px"
            />
          }
          size="small"
          spellCheck={false}
          type="text"
          value={value}
        />
        {!isDesktopView && (
          <IconButtonWrapper>
            <IconButton
              dataTestId="close-tasks-button"
              onClick={onClose}
              title={CLOSE_BUTTON_LABEL}
            >
              <SVGIcon
                color={ThemeV2.palette.gray6}
                icon="round-close"
                size="24px"
              />
            </IconButton>
          </IconButtonWrapper>
        )}
      </SearchBoxContainer>
      {optionsContainer}
    </MenuList>
  );
};
