import styled from 'styled-components';
import { FormControlLabel, RadioGroup } from '@mui/material';
import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';

export const Wrapper = styled.div<{ isMobileView?: boolean }>`
  max-width: ${({ isMobileView }) => (isMobileView ? '161px' : '313px')};
`;

export const StyledRadioGroup = styled(RadioGroup)`
  max-width: 100%;
  margin-bottom: 16px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-family: ${ThemeV2.typography.adminFontFamily};
    font-size: 14px;
    line-height: 22px;
    color: ${ThemeV2.palette.gray8};
  }
  .MuiButtonBase-root {
    padding: 0px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

export const MultiSelectAutoCompleteWrapper = styled.div`
  margin-top: 0px;
  max-width: 310px;
  .MuiAutocomplete-root
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot {
    padding: 4px 0;
  }
  div[role='button'] {
    margin-top: 0px;
    margin-bottom: 8px;
    > span:first-child {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .input-new-line {
    .MuiInputBase-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .MuiInputBase-input {
        padding: 8px 4px 8px 14px;
        width: 80%;
      }

      .autocomplete-loader {
        position: absolute;
        right: 12px;
        bottom: 12px;
        margin: 0;
      }

      .MuiAutocomplete-endAdornment {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

export const InviteMemberWrapper = styled(MultiSelectAutoCompleteWrapper)`
  .MuiInputBase-input {
    overflow-x: auto;
    white-space: nowrap;
    width: auto;
  }
  .pending-user-icon,
  .add-person-icon {
    transform: scale(1.4);
    position: relative;
    right: -4px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 28px;
  margin-left: auto;
`;

export const OptionItemWrapper = styled.div`
  max-width: 100%;
  &:not(:first-child) {
    margin-top: 12px;
  }
`;
