import {
  AnnouncementText,
  AnnouncementToolTip,
  AnnouncementWrapper,
} from './styles';
import SVGIcon from '../../../atoms/SVGIcon';

export type Gradient = {
  name:
    | 'gray'
    | 'blue'
    | 'red'
    | 'yellow'
    | 'green'
    | 'pink'
    | 'purple'
    | 'cyan'
    | 'orange';
  value: string;
};

export const getAnnouncementGradients = (): Gradient[] => [
  {
    name: 'gray',
    value: 'linear-gradient(44deg, #D9D9D9 0%, #F5F5F5 100%)',
  },
  {
    name: 'blue',
    value: 'linear-gradient(44deg, #BAE7FF 0%, #ADC6FF 100%)',
  },
  {
    name: 'red',
    value: 'linear-gradient(243deg, #FF8585 0%, #FF9C6E 100%)',
  },
  {
    name: 'yellow',
    value: 'linear-gradient(240deg, #FFE58F 0%, #FFC069 100%)',
  },
  {
    name: 'green',
    value: 'linear-gradient(240deg, #B7EB8F 0%, #B5F5EC 100%)',
  },
  {
    name: 'pink',
    value: 'linear-gradient(242deg, #FFCCC7 0%, #FFADD2 100%)',
  },
  {
    name: 'purple',
    value: 'linear-gradient(240deg, #D3ADF7 0%, #D6E4FF 100%)',
  },
  {
    name: 'cyan',
    value: 'linear-gradient(240deg, #87E8DE 0%, #BAE7FF 100%)',
  },
  {
    name: 'orange',
    value: 'linear-gradient(240deg, #FFC069 0%, #FFBB96 100%)',
  },
];

interface AnnouncementBannerProps {
  title: string;
  className?: string;
  description?: string;
  selectedColor: string;
  disableToolTip?: boolean;
  onClick?: (args: { description: string }) => void;
}

export const AnnouncementBanner = (props: AnnouncementBannerProps) => {
  const {
    title,
    onClick,
    className,
    description,
    selectedColor,
    disableToolTip = false,
  } = props;

  return (
    <AnnouncementWrapper
      className={className}
      selectedColor={selectedColor}
      onClick={() => {
        if (description) {
          onClick?.({ description });
        }
      }}
    >
      <SVGIcon icon="mega-phone" size="16px" />
      <AnnouncementText variant="body1Medium">{title}</AnnouncementText>
      {description && (
        <AnnouncementToolTip
          position="bottom"
          positionStrategy="fixed"
          disabled={disableToolTip}
          toolTipComponent={<SVGIcon icon="info-outlined" size="16px" />}
        >
          <div>{description}</div>
        </AnnouncementToolTip>
      )}
    </AnnouncementWrapper>
  );
};
