import React from 'react';
import { NEW_APP_URL } from '../../config';
import { V2_HOME } from '../../constants/routes';
import useHistoryWrapper from '../../hooks/useHistoryWrapper';
import { authStore } from '../../stores/authStore';

export const LandingPage = () => {
  const history = useHistoryWrapper();
  if (authStore.getState().authenticated) {
    history.push(V2_HOME);
  } else {
    window.location.href = NEW_APP_URL;
  }
  return <></>;
};

export default LandingPage;
