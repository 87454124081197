/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { SetStateAction, SyntheticEvent, useMemo, useState } from 'react';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import {
  generateInviteNewMemberOption,
  generateMemberAutocompleteOptions,
  getInviteUserCapabilityFromPersonSelector,
  getPersonSelectorValue,
  getRequestRoleFromPermission,
} from '../../../Utils/flows';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { IMemberDTO } from '../../../interfaces/member';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import useOptions from '../../../hooks/useFlowFeedOptions/useOptions';
import envelopeImage from '../../../atomic/atoms/SVGIcon/icons/envelope.svg';
import { useCreateFlowShareRequestMutation } from '../../../queries/Flows/Feed';
import {
  FlowRequestPermission,
  FlowShareRequestPayload,
} from '../../../interfaces/Flow';
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../Utils/toast';
import { requestPermissions } from './data';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';

export const useRequestAccessModalController = (
  handleModalClose: () => void,
) => {
  const { flowId } = useParams<{ flowId: string }>();
  const [selectedPeople, setSelectedPeople] = useState<
    AutocompleteDropdownItem<string, unknown>[]
  >([]);

  const [requestPermission, setRequestPermission] =
    useState<AutocompleteDropdownItem<string, unknown> | null>(
      requestPermissions[0],
    );

  const [message, setMessage] = useState('');
  const [hasSelectPeopleError, setHasPeopleSelectError] = useState(false);
  const [hasMessageInputError, setHasMessageInputError] = useState(false);
  const {
    models: {
      value: textboxValue,
      searchedMembers,
      isFetching: isMembersFetching,
      hasMoreMembers,
      canEmailInvite,
    },
    operations: { onChange: onTextboxValueChange, fetchMoreMembers },
  } = useMembersSearch(true);

  const { data: profileInfo } = useProfileInfoFetchQuery();

  const inviteUserFromPersonSelectorProps = profileInfo
    ? getInviteUserCapabilityFromPersonSelector({
        profileInfo,
        isUserManagementTreatmentOn: true,
      })
    : undefined;

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const getDropdownFooterMessage = () => {
    return isMobileView
      ? 'Type emails to invite new teammates'
      : 'Don’t see who you want to invite? Type an email to add someone else.';
  };

  const memberOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (searchedMembers) {
        if (canEmailInvite && inviteUserFromPersonSelectorProps) {
          return generateInviteNewMemberOption(textboxValue);
        }
        return generateMemberAutocompleteOptions(
          searchedMembers,
          profileInfo?.member.memberId || '',
          false,
        );
      }
      return [];
    }, [
      canEmailInvite,
      inviteUserFromPersonSelectorProps,
      profileInfo?.member.memberId,
      searchedMembers,
      textboxValue,
    ]);

  const handleOptionsScroll = (event: SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight * 0.7 <=
      listboxNode.scrollTop + listboxNode.clientHeight
    ) {
      if (hasMoreMembers && !isMembersFetching) {
        fetchMoreMembers();
      }
    }
  };

  const handlePeopleValuesChange = (
    selectedValues: AutocompleteDropdownItem<string, unknown>[],
  ) => {
    setHasPeopleSelectError(false);
    if (selectedValues && inviteUserFromPersonSelectorProps) {
      setSelectedPeople(
        selectedValues.map((possibleValue) =>
          getPersonSelectorValue(
            possibleValue,
            inviteUserFromPersonSelectorProps.isAdminApprovalRequired,
            envelopeImage,
          ),
        ),
      );
    } else {
      setSelectedPeople(selectedValues);
    }
    onTextboxValueChange('');
  };

  const handleRequestPermissionChange = (
    selectedRequestPermission: AutocompleteDropdownItem<string, unknown> | null,
  ) => {
    setRequestPermission(selectedRequestPermission);
  };

  const handleMessageChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setHasMessageInputError(false);
    setMessage(e.target.value);
  };

  const {
    operations: { handleCopyFlowFeedLink: handleCopyLinkClick },
  } = useOptions();

  const handleRequestAccessModalClose = () => {
    setSelectedPeople([]);
    setMessage('');
    setHasPeopleSelectError(false);
    setHasMessageInputError(false);
    handleModalClose();
  };

  const {
    mutate: createFlowShareRequest,
    isLoading: creatingFlowShareRequest,
  } = useCreateFlowShareRequestMutation(flowId);
  const handleSendRequestsClick = () => {
    setHasPeopleSelectError(!selectedPeople.length);
    setHasMessageInputError(message.length > 500);
    if (selectedPeople.length && message.length <= 500) {
      const payload: FlowShareRequestPayload = {
        requestedFor: selectedPeople.map((people) =>
          people.emailToInvite
            ? people.emailToInvite
            : (people.item as Record<string, string>).email,
        ),
        requestPermission:
          (requestPermission?.id as FlowRequestPermission) || 'VIEWER',
        message,
        source: 'SHARE_BUTTON',
      };
      createFlowShareRequest(payload, {
        onSuccess: () => {
          trackShareSheetActionEvent({
            event: SHARE_SHEET_EVENTS.SHARE_REQUEST_SENT,
            tab: 'invite and share',
            numShareRequests: selectedPeople.length,
            shareRequestRole: getRequestRoleFromPermission(
              (requestPermission?.id as FlowRequestPermission) || 'VIEWER',
            ),
          });
          showSuccessMessage(
            `You requested for ${selectedPeople.length} people to be added to this flow`,
          );
          handleRequestAccessModalClose();
        },
        onError: (err) => {
          const errorResponse = (err as AxiosError<{ message: string }>)
            .response?.data.message;
          const errorMessages = {
            FLOW_NOT_FOUND: 'The flow is not found',
            FLOW_DELETED_OR_ARCHIVED: 'This flow has been archived.',
            REQUESTED_BY_MEMBER_NOT_PART_OF_FLOW:
              'You do not have access to request users',
            MEMBER_ALREADY_A_PART_OF_CRITERIA:
              'This flow request has already been handled.',
            ALL_MEMBERS_ALREADY_A_PART_OF_CRITERIA:
              'This flow request has already been handled.',
          };
          if (
            errorResponse &&
            (errorMessages[errorResponse] ===
              errorMessages.MEMBER_ALREADY_A_PART_OF_CRITERIA ||
              errorMessages[errorResponse] ===
                errorMessages.ALL_MEMBERS_ALREADY_A_PART_OF_CRITERIA)
          ) {
            showInfoMessage(errorMessages[errorResponse]);
          } else {
            showErrorMessage(
              (errorResponse && errorMessages[errorResponse]) ||
                'Error requesting access for users! Try again',
            );
          }
          handleRequestAccessModalClose();
        },
      });
    }
  };

  return {
    message,
    textboxValue,
    memberOptions,
    selectedPeople,
    requestPermission,
    isMembersFetching,
    handleOptionsScroll,
    handleMessageChange,
    handleCopyLinkClick,
    onTextboxValueChange,
    hasSelectPeopleError,
    hasMessageInputError,
    handleSendRequestsClick,
    handlePeopleValuesChange,
    getDropdownFooterMessage,
    creatingFlowShareRequest,
    handleRequestPermissionChange,
    handleRequestAccessModalClose,
    inviteUserFromPersonSelectorProps,
  };
};
