import React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';

import ThemeV2 from '../../../theme';
import { OptionsProps } from '../../molecules/DropdownWithMultiSelect';
import Button from '../../atoms/Button/index';
import Field from '../../molecules/Field';

const useStyles = makeStyles({
  root: {
    backgroundColor: ThemeV2.palette.gray1,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    padding: '24px 16px',
    margin: '0',
  },
  formGroup: {
    marginBottom: '16px',
  },
  label: {
    margin: '1rem 0 .25rem',
  },
  caption: {
    margin: '.25rem 0',
    color: ThemeV2.palette.gray7,
  },
  cancelButton: {
    marginRight: '.5rem',
  },
  clearFilter: {
    color: `${ThemeV2.palette.gray8} !important`,
    marginRight: 'auto',
  },
  dropdownFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
  displayFlex: {
    display: 'flex',
  },
});

export interface UseSearchDropDownValues {
  from: OptionsProps[];
  to: OptionsProps[];
  keywords: string[];
  currencyMin: number;
  currencyMax: number;
  startDate?: Date;
  endDate?: Date;
}

export const defaultValues: UseSearchDropDownValues = {
  from: [],
  to: [],
  keywords: [],
  currencyMin: 0,
  currencyMax: 0,
};

export interface SearchDropdownProps {
  formValues: UseSearchDropDownValues;
  onAutoCompleteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  onSearchClick: (values: UseSearchDropDownValues) => void;
  onCancelClick: () => void;
  autoCompleteOptions: OptionsProps[];
  keywordsOptions: string[];
  isAutoCompleteLoading?: boolean;
  onAutoCompleteFocus?: () => void;
  autoCompleteEmptyText?: string;
  currencyNamePlural: string;
  hideUserFieldSearch?: boolean;
}

const SearchDropdown = (props: SearchDropdownProps) => {
  const {
    formValues,
    onAutoCompleteInputChange,
    onSearchClick,
    onCancelClick,
    autoCompleteOptions,
    keywordsOptions,
    isAutoCompleteLoading = false,
    onAutoCompleteFocus,
    autoCompleteEmptyText,
    currencyNamePlural,
    hideUserFieldSearch = false,
  } = props;
  const classes = useStyles();

  // Reference for cyclic dependency
  // https://builddeploy.co.uk/conditional-validation-in-yup-avoiding-cyclic-errors/
  const validationSchema = Yup.object().shape(
    {
      from: Yup.array().of(
        Yup.object().shape({
          displayText: Yup.string(),
          id: Yup.string(),
          secondaryText: Yup.string(),
          img: Yup.string().notRequired(),
        }),
      ),
      to: Yup.array().of(
        Yup.object().shape({
          displayText: Yup.string(),
          id: Yup.string(),
          secondaryText: Yup.string(),
          img: Yup.string().notRequired(),
        }),
      ),
      keywords: Yup.array(),
      currencyMin: Yup.number()
        // to not show 0 in text box
        .transform((cv) => (cv === 0 ? undefined : cv))
        .positive(`${currencyNamePlural} Minimum must be a Positive value`)
        .when(
          ['currencyMax'],
          (currencyMax: number, schema: Yup.NumberSchema) =>
            currencyMax !== undefined
              ? schema.lessThan(
                  currencyMax,
                  `${currencyNamePlural} Maximum should be greater than ${currencyNamePlural} Minimum`,
                )
              : schema,
        ),
      currencyMax: Yup.number()
        .transform((cv) => (cv === 0 ? undefined : cv))
        .positive(`${currencyNamePlural} Maximum must be a Positive value`)
        .when(
          'currencyMin',
          (currencyMin: number | undefined, schema: Yup.NumberSchema) =>
            currencyMin !== undefined
              ? schema.moreThan(
                  currencyMin,
                  `${currencyNamePlural} Maximum should be greater than ${currencyNamePlural} Minimum`,
                )
              : schema,
        ),
      startDate: Yup.date().when(
        'endDate',
        (endDate: Date | undefined, schema: Yup.DateSchema) =>
          endDate !== undefined
            ? schema.max(endDate, 'End Date should be greater than Start Date')
            : schema,
      ),
      endDate: Yup.date().when(
        'startDate',
        (startDate: Date | undefined, schema: Yup.DateSchema) =>
          startDate !== undefined
            ? schema.min(
                startDate,
                'End Date should be greater than Start Date',
              )
            : schema,
      ),
    },
    [
      ['currencyMin', 'currencyMax'],
      ['startDate', 'endDate'],
    ],
  );

  const nextDay = moment.utc().add(1, 'day').toDate();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema}
        onSubmit={onSearchClick}
      >
        {({ dirty, isValid, handleSubmit, values, resetForm }) => (
          <>
            {!hideUserFieldSearch && (
              <div className={classes.formGroup}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  type="dropdownWithMultiSelect"
                  label="From"
                  name="from"
                  options={autoCompleteOptions}
                  placeholder={
                    values?.from?.length === 0
                      ? 'Who created the post?'
                      : '+ Add another'
                  }
                  onInputChange={onAutoCompleteInputChange}
                  onInputFocus={onAutoCompleteFocus}
                  isLoading={isAutoCompleteLoading}
                  noOptionsText={autoCompleteEmptyText}
                />
              </div>
            )}
            {!hideUserFieldSearch && (
              <div className={classes.formGroup}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  type="dropdownWithMultiSelect"
                  label="To"
                  name="to"
                  options={autoCompleteOptions}
                  placeholder={
                    values?.to?.length === 0
                      ? 'Who received the post?'
                      : '+ Add another'
                  }
                  onInputChange={onAutoCompleteInputChange}
                  onInputFocus={onAutoCompleteFocus}
                  isLoading={isAutoCompleteLoading}
                  noOptionsText={autoCompleteEmptyText}
                />
              </div>
            )}

            <div className={classes.formGroup}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-ignore */}
              <Field
                type="textWithChips"
                label="Keyword"
                name="keywords"
                placeholder="Select keywords"
                options={keywordsOptions}
                helperText="Separate by comma or by hitting enter"
                helperTextVariant="body2"
              />
            </div>
            {/* TODO: Add start and "date" functionality. This is just a place holder */}
            <div className={`${classes.formGroup} ${classes.displayFlex}`}>
              <Box flex="1" mr=".5rem">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  name="startDate"
                  type="date"
                  label="Start Date"
                  placeholder="Pick a start date"
                  width="100%"
                  disabledDays={(day: Date) =>
                    values?.endDate
                      ? day > values.endDate || day > nextDay
                      : day > nextDay
                  }
                />
              </Box>
              <Box flex="1">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  name="endDate"
                  type="date"
                  label="End Date"
                  placeholder="Pick an end date"
                  width="100%"
                  disabledDays={(day: Date) =>
                    values?.startDate
                      ? day < values.startDate || day > nextDay
                      : day > nextDay
                  }
                />
              </Box>
            </div>
            <div className={`${classes.formGroup} ${classes.displayFlex}`}>
              <Box flex="1" mr=".5rem">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  name="currencyMin"
                  type="number"
                  label={`${currencyNamePlural} Minimum`}
                  placeholder="Enter a number"
                  width="100%"
                />
              </Box>
              <Box flex="1">
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <Field
                  name="currencyMax"
                  type="number"
                  label={`${currencyNamePlural} Maximum`}
                  placeholder="Enter a number"
                  width="100%"
                />
              </Box>
            </div>
            <div className={classes.dropdownFooter}>
              <Button
                // TODO: find a way to useCallback
                onClick={() => {
                  resetForm({ values: defaultValues });
                }}
                size="small"
                variant="text"
                className={classes.clearFilter}
              >
                Clear Filters
              </Button>
              <Button
                onClick={onCancelClick}
                color="secondary"
                size="small"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                size="small"
                disabled={!(dirty && isValid)}
              >
                Search
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default SearchDropdown;
