import { LeftNavOrganismProps } from './interface';
import { DrawerBottomContent, HomeDrawerWrapper } from './styles';

import useMobileApp from '../../../hooks/useMobileApp';

const HomeDrawer = (props: LeftNavOrganismProps) => {
  const { isMobileApp } = useMobileApp();
  const { children, isHoveredWhenCollapsed, isKioskTurnedOn, handleScroll } =
    props;

  return (
    <HomeDrawerWrapper
      isMobileApp={isMobileApp}
      isKioskTurnedOn={isKioskTurnedOn}
    >
      <DrawerBottomContent
        id="left-nav-drawer"
        isHoveredWhenCollapsed={isHoveredWhenCollapsed}
        onScroll={handleScroll}
      >
        {children}
      </DrawerBottomContent>
    </HomeDrawerWrapper>
  );
};

export default HomeDrawer;
