import { create } from 'zustand';

export const microsoftTeamsStore = create<{
  msTeamsLoginHint: string;
  setMSTeamsLoginHint: (loginHint?: string) => void;
  isLoggedInThroughNewTeamsApp: boolean;
  isLoggedInThroughMobileTeamsApp: boolean;
  setIsLoggedInThroughMobileTeamsApp: (
    isLoggedInThroughMobileTeamsApp: boolean,
  ) => void;
  setIsLoggedInThroughNewTeamsApp: (
    isLoggedInThroughNewTeamsApp: boolean,
  ) => void;
}>()((set) => ({
  msTeamsLoginHint: '',
  isLoggedInThroughMobileTeamsApp: false,
  isLoggedInThroughNewTeamsApp: false,
  setMSTeamsLoginHint: (loginHint?: string) =>
    set(() => ({
      msTeamsLoginHint: loginHint ?? '',
    })),
  setIsLoggedInThroughMobileTeamsApp: (
    isLoggedInThroughMobileTeamsApp: boolean,
  ) =>
    set(() => ({
      isLoggedInThroughMobileTeamsApp,
    })),
  setIsLoggedInThroughNewTeamsApp: (isLoggedInThroughNewTeamsApp: boolean) =>
    set(() => ({
      isLoggedInThroughNewTeamsApp,
    })),
}));
