import React from 'react';
import styled from 'styled-components';

import { ChildrenWrapper } from './styles';

export interface ProfileDrawerProps {
  children: React.ReactNode;
  name: string;
  removeSelectedMember: () => void;
  shouldShowCloseIcon: boolean;
}

const StyledWrapper = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;

const ProfileDrawer = ({ children }: ProfileDrawerProps) => (
  <StyledWrapper>
    <ChildrenWrapper>{children}</ChildrenWrapper>
  </StyledWrapper>
);
const MemoizedProfileDrawer = React.memo(ProfileDrawer);
MemoizedProfileDrawer.displayName = 'ProfileDrawer';
export default MemoizedProfileDrawer;
