import { getFlowPerms } from '../../flows/feeds';
import { AnalyticsEvents, LEFT_DRAWER_EVENT_NAME } from '../constants';
import { trackEvent } from '../index';
import { LeftDrawerEventProps, TrackLeftDrawerEventProps } from '../interfaces';

export const trackLeftDrawerShowEvent = () => {
  trackEvent(LEFT_DRAWER_EVENT_NAME, undefined, {
    event: AnalyticsEvents.SHOW,
  });
};

export const trackLeftDrawerActionEvent = (args: TrackLeftDrawerEventProps) => {
  const eventProps: LeftDrawerEventProps = {
    action: args.action,
    notificationTypeClicked: args.notificationTypeClicked,
    flowId: args.flowId || args.id,
    flowName: args.label,
    flowPerms: getFlowPerms({
      isFlowOwner: args.isFlowOwner || false,
      isFlowViewer: args.isFlowViewer || false,
      isFlowParticipant: args.isFlowParticipant || false,
    }),
    flowIsDue: !!args.occurrence?.activeOccurrence,
    flowOccurrenceRunning: !!args.occurrence?.activeOccurrence,
    flowState: args.flowState,
    isArchived: args.isArchived || false,
    postId: args.postId,
    responseId: args.responseId,
    folder: args.folder,
  };

  trackEvent(LEFT_DRAWER_EVENT_NAME, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};
