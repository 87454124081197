import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import FlowBlockHeader from '../../../atomic/molecules/FlowBlockHeader';
import {
  FlowAccordionItemState,
  FlowAccordionItemValidations,
} from '../../../atomic/molecules/FlowAccordionItem/interface';

import {
  Border,
  FlowAccordionItemContainer,
  FlowBuilderBlockContainer,
  StyledNextButton,
} from '../FlowsBuilderController/styles';

import { CREATE, FLOW, SAVE } from '../../../languages/en/singleWords';
import FlowAccordionItem from '../../../atomic/molecules/FlowAccordionItem';
import {
  BUILDER_COMPLETION_BLOCK,
  BUILDER_PREVIEW_BLOCK,
  FLOW_COMPLETION_EMOTICON_ERROR_TEXT,
  FLOW_COMPLETION_GENERIC_ERROR_TEXT,
  FLOW_COMPLETION_TITLE_ERROR_TEXT,
} from '../../../languages/en/flows/builder';
import Image from '../../../atomic/atoms/Image';
import BuilderConformationImage from '../../../img/svgs/flowsBuilderConfirmation.svg';
import FlowsNameAndEmoticonForm from '../../../atomic/molecules/FlowsNameAndEmoticonForm';
import {
  ColumnWrapper,
  StyledBody,
  StyledHeading,
  StyledInfoAlert,
} from './styles';
import { FlowsBuilderCompletionControllerProps } from './types';
import ThemeV2 from '../../../theme';
import { FLOW_BUILDER_EVENTS } from '../../../Utils/analytics/constants';
import {
  trackFlowBuilderErrorEvent,
  trackFlowBuilderActionEvent,
} from '../../../Utils/analytics/flowsBuilder';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import {
  templateIdSelector,
  templateNameSelector,
} from '../../../stores/flowBuilderStore/selectors';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const errorComponentCustomStyle = {
  background: ThemeV2.palette.dustRed2,
  color: ThemeV2.palette.gray9,
  focusColor: ThemeV2.palette.dustRed2,
  border: 'none',
  iconColor: ThemeV2.palette.red6,
  padding: '6px 8px',
};

const emptyFunction = () => {};

const FlowsBuilderCompletionController = ({
  id,
  flowId,
  index,
  isTouched,
  isUnlocked,
  isExpanded,
  hasError,
  onEditButtonClick,
  isCreateFlowButtonDisabled,
  isCreateFlowButtonLoading,
  onCreateFlowButtonClick,
  flowName,
  onFlowNameChange,
  flowDescription,
  onFlowDescriptionChange,
  flowEmoticon,
  onFlowEmoticonSelect,
  onPreviewButtonClick,
}: FlowsBuilderCompletionControllerProps) => {
  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const isTabletView = useMediaQuery({
    query: device.tablet,
  });

  const [blockStatus, setBlockStatus] = useState(FlowAccordionItemState.Blur);
  const [previewBlockOpenState, setPreviewBlockOpenState] = useState(false);
  const [blockValidation, setBlockValidation] = useState(
    FlowAccordionItemValidations.None,
  );

  useEffect(() => {
    if (isExpanded) {
      setBlockStatus(FlowAccordionItemState.Focus);
      setPreviewBlockOpenState(true);
    } else if (isUnlocked) {
      setBlockStatus(FlowAccordionItemState.Blur);
      setPreviewBlockOpenState(false);
    } else {
      setBlockStatus(FlowAccordionItemState.Disabled);
      setPreviewBlockOpenState(false);
    }

    if (!isCreateFlowButtonDisabled && isTouched) {
      setBlockValidation(FlowAccordionItemValidations.Success);
    } else {
      setBlockValidation(FlowAccordionItemValidations.None);
    }
  }, [
    isTouched,
    isUnlocked,
    isExpanded,
    setBlockStatus,
    flowName,
    flowEmoticon,
    isCreateFlowButtonDisabled,
  ]);

  const handleOnCompletedClick = () => {
    setPreviewBlockOpenState(!previewBlockOpenState);
    onEditButtonClick(index);
  };

  const flowNameAndEmoticonErrorText = useMemo(() => {
    if (isEmpty(flowEmoticon) && isEmpty(flowName))
      return FLOW_COMPLETION_GENERIC_ERROR_TEXT;
    if (isEmpty(flowEmoticon)) {
      trackFlowBuilderErrorEvent({
        error: FLOW_BUILDER_EVENTS.EMOJI_ERROR,
      });
      return FLOW_COMPLETION_EMOTICON_ERROR_TEXT;
    }
    if (isEmpty(flowName)) {
      trackFlowBuilderErrorEvent({
        error: FLOW_BUILDER_EVENTS.FLOW_TITLE_ERROR,
      });
      return FLOW_COMPLETION_TITLE_ERROR_TEXT;
    }
    return '';
  }, [flowEmoticon, flowName]);

  const handleOnTitleBlur = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_TITLE_ADDED,
      flowTitle: flowName,
      templateId,
      templateName,
    });
  }, [flowName, templateId, templateName]);

  const handleOnDescriptionBlur = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_DESCRIPTION_ADDED,
      flowDescription,
      flowTitle: flowName,
      templateId,
      templateName,
    });
  }, [flowDescription, flowName, templateId, templateName]);

  return (
    <FlowBuilderBlockContainer blockStatus={blockStatus} id={id}>
      <FlowBlockHeader
        title={flowId ? `${SAVE} ${FLOW}` : `${CREATE} ${FLOW}`}
        icon={
          blockStatus === FlowAccordionItemState.Disabled
            ? 'celebration-bubble-disabled'
            : 'celebration-bubble'
        }
        dataTestId="flowCompletionBlockHeader"
        onEditButtonClick={() => {
          onEditButtonClick(index);
          setPreviewBlockOpenState(!previewBlockOpenState);
        }}
        onPreviewButtonClick={onPreviewButtonClick || emptyFunction}
        state={blockStatus}
      />
      <FlowAccordionItem
        icon="edit"
        validation={blockValidation}
        dataTestId="previewAccordionItem"
        state={blockStatus}
        isOpen={previewBlockOpenState}
        onAccordionItemClick={handleOnCompletedClick}
        title={BUILDER_PREVIEW_BLOCK.ACCORDION_ITEM_TEXT}
      >
        <FlowAccordionItemContainer>
          <StyledHeading variant="body1Medium">
            {BUILDER_PREVIEW_BLOCK.TITLE}
          </StyledHeading>
          <StyledBody variant="body3">
            {BUILDER_PREVIEW_BLOCK.DESCRIPTION}
          </StyledBody>
          {(isCreateFlowButtonDisabled ||
            !isEmpty(flowNameAndEmoticonErrorText) ||
            hasError) && (
            <StyledInfoAlert
              id="confirmationBannerError"
              text={BUILDER_COMPLETION_BLOCK.ERROR}
              icon="error-warning"
              alertType="custom"
              bodyVariant="body3"
              customVariant={errorComponentCustomStyle}
            />
          )}
          <ColumnWrapper>
            {!isMobileView && !isTabletView && (
              <Image
                src={BuilderConformationImage}
                alt="builder confirmation step"
              />
            )}
            <div>
              <FlowsNameAndEmoticonForm
                disabled={false}
                flowId={flowId}
                flowName={flowName}
                hasEmojiError={isExpanded && isEmpty(flowEmoticon)}
                hasFlowNameError={isExpanded && isEmpty(flowName)}
                onNameChange={onFlowNameChange}
                description={flowDescription}
                onDescriptionChange={onFlowDescriptionChange}
                emoticon={flowEmoticon}
                onEmoticonSelect={onFlowEmoticonSelect}
                errorText={flowNameAndEmoticonErrorText}
                onTitleBlur={handleOnTitleBlur}
                onDescriptionBlur={handleOnDescriptionBlur}
              />
            </div>
          </ColumnWrapper>
        </FlowAccordionItemContainer>
      </FlowAccordionItem>
      {previewBlockOpenState && (
        <Border>
          <StyledNextButton
            variant="contained"
            status="success"
            icon={flowId ? '' : 'add-new'}
            isLoading={isCreateFlowButtonLoading}
            onClick={onCreateFlowButtonClick}
            disabled={isCreateFlowButtonDisabled}
          >
            {flowId ? `${SAVE} ${FLOW}` : `${CREATE} ${FLOW}`}
          </StyledNextButton>
        </Border>
      )}
    </FlowBuilderBlockContainer>
  );
};

const MemoizedFlowsBuilderCompletionController = React.memo(
  FlowsBuilderCompletionController,
);

MemoizedFlowsBuilderCompletionController.displayName =
  'FlowsBuilderCompletionController';

export default MemoizedFlowsBuilderCompletionController;
