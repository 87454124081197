import { render } from 'react-dom';

import { datadogLogs } from '@datadog/browser-logs';

import App from './App';
import { NEW_APP_URL } from './config';
import { GET_USER_INFO } from './constants/endpoints';
import { RECOGNITION_PARTICIPATE, SIGN_OUT, V2_HOME } from './constants/routes';
import { makeAPICall } from './queries/utils';
import {
  NO_SLUG_NO_REDIRECT_ROUTES,
  NO_SLUG_REDIRECT_ROUTES,
} from './routers/utils';
import { authStore } from './stores/authStore';
import mobileStore from './stores/mobileAppStore';
import { initAnalytics } from './Utils/analytics';
import { authenticateUser, clearLocalStorage } from './Utils/session';
import { isMobilePlatform } from './Utils/window';
import { setupDataDog } from './Utils/setupDataDog';

export async function handleV3Redirect({ slugPath }: { slugPath: string }) {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);
  const isEmbeddedInMainApp = searchParams.get('source') === 'embedded';
  const flowId = searchParams.get('participationFlowId');

  if (
    isEmbeddedInMainApp ||
    pathname.includes('kiosk') ||
    pathname.includes('/e/flows') ||
    pathname.includes('/bot/authorize') ||
    pathname.includes('slack-redirect') ||
    pathname.includes('onboard/authorize')
  ) {
    return;
  }

  if (flowId) {
    window.location.href = `${NEW_APP_URL}/${slugPath}/flows/${flowId}/answer?redirectUrl=${NEW_APP_URL}/discover`;
  } else if (pathname.includes(V2_HOME)) {
    const openRecognitionFlowModalOnLoad = searchParams.get(
      'openRecognitionFlowModalOnLoad',
    );
    if (
      openRecognitionFlowModalOnLoad &&
      openRecognitionFlowModalOnLoad === 'true'
    ) {
      window.location.href = `${NEW_APP_URL}/${slugPath}${RECOGNITION_PARTICIPATE}?redirectUrl=${NEW_APP_URL}/discover`;
    } else {
      window.location.href = `${NEW_APP_URL}/discover${search}`;
    }
  } else {
    if (
      NO_SLUG_REDIRECT_ROUTES.some((x) => window.location.href.includes(x)) &&
      !NO_SLUG_NO_REDIRECT_ROUTES.some((route) =>
        window.location.pathname.startsWith(route),
      )
    ) {
      // Single Recognition Post
      if (window.location.href.includes(`${window.location.origin}/post`)) {
        const postId = pathname.split('/')[2];
        window.location.href = `${NEW_APP_URL}/a/${slugPath}/discover?postId=${postId}`;
        return;
      }

      const url = window.location.href.replace(
        window.location.origin,
        `${NEW_APP_URL}/${slugPath}`,
      );

      window.location.href = url;
    } else {
      if (
        !pathname.includes(SIGN_OUT) &&
        !NO_SLUG_NO_REDIRECT_ROUTES.some((route) =>
          window.location.pathname.startsWith(route),
        )
      ) {
        window.location.href = window.location.href.replace(
          window.location.origin,
          NEW_APP_URL,
        );
      }
    }
  }
}

(async function renderApp() {
  if (import.meta.env.VITE_IS_MOBILE_DEV_MODE) {
    const searchParams = new URLSearchParams(window.location.search);
    const jwtToken = decodeURIComponent(
      searchParams.get('mobileAuthToken') || '',
    );
    if (jwtToken) {
      mobileStore.setState({ jwtToken });
    }
  }

  setupDataDog();
  initAnalytics();

  try {
    /**
     * Note: these are paths where the frontend would request the backend
     * with tokens from the SSO/SAML providers that'd set the cookie,
     * so we can't check if a user's logged in yet.
     *
     * We also shouldn't be checking in case of the template preview URLs.
     **/
    if (
      !(
        window.location.pathname.startsWith('/auth/saml') ||
        window.location.pathname.match(/\/signin\/authorize/g) ||
        window.location.pathname.startsWith('/template/')
      )
    ) {
      try {
        const res = await makeAPICall(GET_USER_INFO);
        authenticateUser(res);

        const data = res.data.data;

        const isMobileApp =
          isMobilePlatform &&
          (mobileStore.getState().isMobileApp ||
            new URLSearchParams(location.search).get('isMobileApp') === 'true');

        if (!isMobileApp) {
          const { shortCode, name } = data.employer.workspaceSlug;

          await handleV3Redirect({
            slugPath: `${name}-${shortCode}`,
          });
        }
      } catch (err: unknown) {
        datadogLogs.logger.error('Error while logging in', err as object);
        authStore.getState().setAuthStoreStateForAuthenticationError(err);
      }
    } else {
      const isOnboardingViaSlack =
        window.location.pathname.match(/\/signin\/authorize/g) &&
        window.location.pathname.includes('slack');
      if (isOnboardingViaSlack) {
        window.location.href = `${NEW_APP_URL}/login?provider=slack`;
      }
    }
  } catch {
    clearLocalStorage();
  } finally {
    render(<App />, document.getElementById('root'));
  }
})();
