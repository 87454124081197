import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import useLayoutStore from '../../stores/layoutStore';
import { LayoutStoreState } from '../../interfaces/Layout';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../constants/layout';
import useSetRepliesToSidebar from '../useSetRepliesToSideBar';

const repliesSelector = (state: LayoutStoreState) => state.setShowReplies;
const selectedPostSelector = (state: LayoutStoreState) => state.selectedPost;
const showRightAsideToFalseSelector = (state: LayoutStoreState) =>
  state.setRightAsideOpenToFalse;
const setSelectedPostSelector = (state: LayoutStoreState) =>
  state.setSelectedPost;
const removeSelectedMemberSelector = (state: LayoutStoreState) =>
  state.removeSelectedMember;

const useRepliesSidebar = () => {
  const history = useHistory();
  const { search } = useLocation();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { handleSetSelectedPost } = useSetRepliesToSidebar();
  const showReplies = new URLSearchParams(search).get('showReplies') === 'true';

  const setShowReplies = useLayoutStore(repliesSelector);
  const selectedPost = useLayoutStore(selectedPostSelector);
  const setSelectedPost = useLayoutStore(setSelectedPostSelector);
  const removeSelectedMember = useLayoutStore(removeSelectedMemberSelector);
  const showRightAsideToFalse = useLayoutStore(showRightAsideToFalseSelector);

  useEffect(() => {
    if (showReplies) {
      setShowReplies(true);
      handleSetSelectedPost();
    }
  }, [showReplies, setShowReplies, setSelectedPost, handleSetSelectedPost]);

  const reset = (shouldNotResetSelectedMember?: boolean) => {
    setSelectedPost({});
    setShowReplies(false);
    if (!shouldNotResetSelectedMember) {
      removeSelectedMember();
    }

    const params = new URLSearchParams(search);
    params.set('showReplies', 'false');
    params.set('showDirectMessage', 'false');

    history.replace({
      search: params.toString(),
    });

    if (isMobileView) {
      showRightAsideToFalse();
    }
  };

  return {
    reset,
    showReplies,
    type: selectedPost.type,
    flowId: selectedPost.flowId,
    postId: selectedPost.postId,
    commentId: selectedPost.commentId,
    responseId: selectedPost.responseId,
  };
};

export default useRepliesSidebar;
