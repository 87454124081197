import qs from 'qs';
import React from 'react';
import mainFeedLogo from '../../headers/MainFeed/main-feed-logo.svg';
import MainFeedDrawer from '../../../atomic/organism/MainFeedDrawer';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../../constants/common';
import useLeaderboardControllerLogic from '../LeaderboardController/useLeaderboardControllerLogic';
import useLeaderboardControllerModel from '../LeaderboardController/useLeaderboardControllerModel';
import { V2_REWARDS } from '../../../constants/routes';
import { NotebookTaskDetailsController } from '../../rightDrawer/NotebookTaskDetailsController';
import { RightDrawerHeader } from '../../../atomic/molecules/RightDrawerHeader';
import { COMPANY_LOGO_ALT_TEXT, MAIN_FEED } from '../../../languages/en/header';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const MainFeedDrawerController = () => {
  const {
    location,
    selectedCategory,
    selectedTimeFrame,
    showDownloadOption,
    setSelectedCategory,
    setSelectedTimeFrame,
    downloadClickHandler,
    disableDownloadOption,
    setSelectedMemberProfile,
    toggleRightAsideOpen,
  } = useLeaderboardControllerLogic();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const isCelebrateTeammateFeed = location.pathname.includes(
    CELEBRATE_TEAMMATE_FLOW_ID,
  );

  const isRewardsPage = location.pathname.includes(V2_REWARDS);

  const {
    metadata: { isError, isLoading },
    data: { currentUser, leaderboard },
  } = useLeaderboardControllerModel(
    selectedCategory,
    selectedTimeFrame,
    isCelebrateTeammateFeed,
  );

  const shouldShowTask =
    'taskId' in qs.parse(location.search, { ignoreQueryPrefix: true });

  if (shouldShowTask) {
    return <NotebookTaskDetailsController />;
  }

  return (
    <>
      {isMobileView && (
        <RightDrawerHeader
          title={MAIN_FEED}
          imageUrl={mainFeedLogo}
          imageName={COMPANY_LOGO_ALT_TEXT}
          onCloseClick={toggleRightAsideOpen}
        />
      )}
      <MainFeedDrawer
        canShowRightDrawer={isRewardsPage}
        currentMember={currentUser}
        hasFullError={isError}
        hasLeaderboardError={isError}
        isLeaderboardLoading={isLoading}
        leaderboardMembers={leaderboard?.leaderBoard}
        toggleRightAsideOpen={toggleRightAsideOpen}
        onCategoryChange={setSelectedCategory}
        onTimeFrameChange={setSelectedTimeFrame}
        selectedCategory={selectedCategory}
        selectedTimeFrame={selectedTimeFrame}
        setSelectedMemberProfile={setSelectedMemberProfile}
        showLegacySidebar={isCelebrateTeammateFeed}
        showDownloadOption={showDownloadOption}
        disableDownloadOption={disableDownloadOption}
        onDownloadClick={downloadClickHandler}
      />
    </>
  );
};

export default MainFeedDrawerController;
