import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import { FileFormat } from '../../../../atomic/organism/FileViewer/types';

export const fileTypeOptions = [
  {
    value: false,
    label: 'Links',
    icon: SVGIcon({ icon: 'linkAttachment', size: '24px' }),
    id: 'links',
  },
  {
    value: false,
    label: 'Documents',
    icon: SVGIcon({ icon: 'docFile', size: '24px' }),
    id: FileFormat.document,
  },
  {
    value: false,
    label: 'Images',
    icon: SVGIcon({ icon: 'imageFile', size: '24px' }),
    id: FileFormat.image,
  },
  {
    value: false,
    label: 'PDFs',
    icon: SVGIcon({ icon: 'pdfFile', size: '24px' }),
    id: FileFormat.pdf,
  },
  {
    value: false,
    label: 'Presentations',
    icon: SVGIcon({ icon: 'presentationFile', size: '24px' }),
    id: FileFormat.presentation,
  },
  {
    value: false,
    label: 'Spreadsheets',
    icon: SVGIcon({ icon: 'csvFile', size: '24px' }),
    id: FileFormat.spreadsheet,
  },
  {
    value: false,
    label: 'Audio',
    icon: SVGIcon({ icon: 'audioFile', size: '24px' }),
    id: FileFormat.audio,
  },
  {
    value: false,
    label: 'Video',
    icon: SVGIcon({ icon: 'videoFile', size: '24px' }),
    id: FileFormat.video,
  },
  {
    value: false,
    label: 'Zip files',
    icon: SVGIcon({ icon: 'zipFile', size: '24px' }),
    id: FileFormat.zip,
  },
];
