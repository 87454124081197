import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { LayoutStoreState } from '../../../interfaces/Layout';
import { TaskToken, TaskTokenProps } from '../TaskToken';
import useLayoutStore from '../../../stores/layoutStore';

const layoutStoreSelector = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  isRightAsideOpen: state.isRightAsideOpen,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
  removeSelectedMember: state.removeSelectedMember,
});

export const TaskFeedToken = (props: TaskTokenProps) => {
  const { ariaLabel, children, disabled, onClick, isDeleted, ...taskDetails } =
    props;
  const { push } = useHistory();
  const {
    setRightAsideOpenToTrue,
    isRightAsideOpen,
    setTaskIdForNotebook,
    removeSelectedMember,
  } = useLayoutStore(layoutStoreSelector, shallow);

  const handleClick = useCallback(() => {
    if (taskDetails.noteId) {
      removeSelectedMember();
      setTaskIdForNotebook(taskDetails.noteId);
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }

      const queryParams = new URLSearchParams(location.search);

      queryParams.delete('showReplies');
      queryParams.set('taskId', taskDetails.noteId);

      push({ search: queryParams.toString() });
    }
  }, [
    isRightAsideOpen,
    push,
    removeSelectedMember,
    setRightAsideOpenToTrue,
    setTaskIdForNotebook,
    taskDetails,
  ]);

  return (
    <TaskToken
      {...taskDetails}
      ariaLabel={ariaLabel}
      disabled
      onClick={handleClick}
      textVariant="body1"
      isDeleted={isDeleted}
    >
      {props.children}
    </TaskToken>
  );
};
