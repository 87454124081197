import styled from 'styled-components';
import Dropdown from '../../../../atomic/molecules/Dropdown_V2';
import {
  SimplifiedShareSheetUserCard,
  SimplifiedShareSheetUserCardLoader,
} from '../../../../atomic/molecules/ShareSheet/SimplifiedShareSheetUserCard';
import { Flex } from '../../../../Utils/styles/display';
import { useShareSheetAccessPreviewController } from './useShareSheetAccessPreviewController';
import {
  DropdownColor,
  DropdownPlacement,
  DropdownVariants,
} from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import {
  AccessPreviewDropdownOptions,
  AccessPreviewDropdownMenu,
} from './data';
import Theme from '../../../../theme';
import InfiniteScroll from 'react-infinite-scroll-component';

const Container = styled(Flex)`
  max-height: 530px;
  overflow-y: auto;
`;

const InfiniteScrollWrapper = styled.div<{ $hasPadding?: boolean }>`
  max-height: 440px;
  min-height: 440px;
  overflow-y: auto;
  width: 100%;
  padding: 0 32px;
`;

const PermissionDropdown = styled(Dropdown)`
  margin-left: 8px;
  border: 0.5px solid ${Theme.palette.gray5};
  border-radius: 4px;
  width: 100%;
`;

const ActionContainer = styled(Flex)`
  border-top: 1px solid ${Theme.palette.white4};
  border-bottom: 1px solid ${Theme.palette.white4};
  padding: 12px 32px;
`;

export const ShareSheetAccessPreviewController = () => {
  const {
    previewMembers,
    dropdownValue,
    setDropdownValue,
    fetchMoreMembers,
    hasMoreUsersToFetch,
    currentUserId,
    isLoading,
  } = useShareSheetAccessPreviewController();

  return (
    <Container alignItems="flex-start" flexDirection="column">
      <ActionContainer
        margin="0 0 16px 0"
        justifyContent="flex-end"
        width="100%"
      >
        <PermissionDropdown
          onItemClick={(v) => {
            const currentOption = AccessPreviewDropdownOptions.find(
              (option) => option.id === v,
            );
            if (currentOption) setDropdownValue(currentOption);
          }}
          menuItems={AccessPreviewDropdownMenu}
          variant={DropdownVariants.Text}
          color={DropdownColor.BlueTertiary}
          value={dropdownValue.value}
          dropdownPlacement={DropdownPlacement.BottomEnd}
          isFullWidth
          caretIconSize="16px"
        />
      </ActionContainer>
      <InfiniteScrollWrapper id="simpleShareSheetPreviewPeople">
        <InfiniteScroll
          dataLength={previewMembers.length}
          hasMore={Boolean(hasMoreUsersToFetch)}
          loader={<SimplifiedShareSheetUserCardLoader />}
          next={fetchMoreMembers}
          scrollableTarget="simpleShareSheetPreviewPeople"
        >
          {isLoading && <SimplifiedShareSheetUserCardLoader />}
          {previewMembers.map((member) => {
            return (
              <SimplifiedShareSheetUserCard
                key={member.id}
                cardData={member}
                currentUserId={currentUserId || ''}
              />
            );
          })}
        </InfiniteScroll>
      </InfiniteScrollWrapper>
    </Container>
  );
};
