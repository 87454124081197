import produce from 'immer';
import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';

import { ReactionUpdatedPayload } from './types';
import {
  GET_CHATS,
  GET_FLOW_FEED,
  GET_FLOW_POST_COMMENTS,
  GET_POST_COMMENTS,
} from '../../constants/endpoints';
import {
  GetCommentByPostResponse,
  GetFeedResponse,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import {
  findCommentInPostData,
  findInPaginatedFeedData,
  updateCommentReactionsInDraft,
  updateFlowPostReactionsInDraft,
  updatePostReactionsInDraft,
} from '../../queries/Feed/utils';
import { getFullName } from '../../Utils/text';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import { FlowFeedResponse } from '../../queries/Flows/Feed/interfaces';
import {
  findInPaginatedFlowFeedData,
  updatePostReactionsForFlowFeedInDraft,
} from '../../queries/Flows/Feed/utils';

export const updateFeedQueryReactionData = ({
  queryKey,
  queryClient,
  socketPayload,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
  socketPayload: ReactionUpdatedPayload;
}) => {
  const feedData = queryClient.getQueryData(queryKey);
  const previousFeedData = feedData as
    | InfiniteData<GetFeedResponse>
    | undefined;

  if (previousFeedData) {
    const updatedData = produce(previousFeedData, (draft) => {
      const updatedPost = findInPaginatedFeedData(
        draft.pages,
        socketPayload.postId
          ? socketPayload.postId || ''
          : socketPayload.responseId || '',
      );
      if (updatedPost) {
        if (socketPayload.postId) {
          updatePostReactionsInDraft(
            updatedPost,
            socketPayload.reaction,
            socketPayload.action === 'SET'
              ? UpdateReactionAction.SET
              : UpdateReactionAction.UNSET,
            {
              name: getFullName(socketPayload.fromMember),
              memberID: socketPayload.fromMember.memberId,
            },
          );
        } else {
          updateFlowPostReactionsInDraft(
            updatedPost,
            socketPayload.reaction,
            socketPayload.action === 'SET'
              ? UpdateReactionAction.SET
              : UpdateReactionAction.UNSET,
            {
              name: getFullName(socketPayload.fromMember),
              memberID: socketPayload.fromMember.memberId,
            },
          );
        }
      }
    });

    queryClient.setQueryData(queryKey, updatedData);
  }
};

export const updateFlowFeedQueryReactionData = ({
  queryKey,
  queryClient,
  socketPayload,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
  socketPayload: ReactionUpdatedPayload;
}) => {
  let flowFeedPreviousData;
  let singleFlowFeedPreviousData;
  if (queryKey[0] === GET_FLOW_FEED) {
    const flowFeed = queryClient.getQueryData(queryKey);
    flowFeedPreviousData = flowFeed as InfiniteData<
      PaginationResponse<FlowFeedResponse>
    >;
  } else {
    const singleFlowFeed = queryClient.getQueryData(queryKey);
    singleFlowFeedPreviousData = singleFlowFeed as FlowFeedResponse;
  }
  if (flowFeedPreviousData) {
    const updatedData = produce(flowFeedPreviousData, (draft) => {
      const updatedPost = findInPaginatedFlowFeedData(
        draft.pages,
        socketPayload.responseId || '',
      );
      if (updatedPost) {
        updatePostReactionsForFlowFeedInDraft(
          updatedPost,
          socketPayload.reaction,
          socketPayload.action === 'SET'
            ? UpdateReactionAction.SET
            : UpdateReactionAction.UNSET,
          {
            name: getFullName(socketPayload.fromMember),
            memberID: socketPayload.fromMember.memberId,
          },
        );
      }
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
  if (singleFlowFeedPreviousData) {
    const updatedData = produce(singleFlowFeedPreviousData, (flowPost) => {
      updatePostReactionsForFlowFeedInDraft(
        flowPost,
        socketPayload.reaction,
        socketPayload.action === 'SET'
          ? UpdateReactionAction.SET
          : UpdateReactionAction.UNSET,
        {
          name: getFullName(socketPayload.fromMember),
          memberID: socketPayload.fromMember.memberId,
        },
      );
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
};

export const updateCommentQueryReactionData = ({
  queryKey,
  queryClient,
  socketPayload,
}: {
  queryKey: QueryKey;
  queryClient: QueryClient;
  socketPayload: ReactionUpdatedPayload;
}) => {
  let previousData;
  if (queryKey[0] === GET_FLOW_POST_COMMENTS) {
    const flowPostCommentFeed = queryClient.getQueryData(queryKey);
    previousData = flowPostCommentFeed as
      | InfiniteData<GetCommentByPostResponse>
      | undefined;
  }
  if (queryKey[0] === GET_POST_COMMENTS) {
    const postCommentFeed = queryClient.getQueryData(queryKey);
    previousData = postCommentFeed as
      | InfiniteData<GetCommentByPostResponse>
      | undefined;
  }
  if (queryKey[0] === GET_CHATS) {
    const chatCommentFeed = queryClient.getQueryData(queryKey);
    previousData = chatCommentFeed as
      | InfiniteData<GetCommentByPostResponse>
      | undefined;
  }

  if (previousData) {
    const updatedData = produce(previousData, (draft) => {
      const updatedComment = findCommentInPostData(
        draft.pages,
        socketPayload.commentId || '',
      );
      if (updatedComment) {
        updateCommentReactionsInDraft(
          updatedComment,
          socketPayload.reaction,
          socketPayload.action === 'SET'
            ? UpdateReactionAction.SET
            : UpdateReactionAction.UNSET,
          {
            name: getFullName(socketPayload.fromMember),
            memberID: socketPayload.fromMember.memberId,
          },
        );
      }
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
};
