import { useState } from 'react';
import { useLocation } from 'react-router';

import { GeneralCollapsibleNavigationItemProps } from '../../../../atomic/organism/GeneralCollapsibleNavigation/types';
import {
  PEOPLE_DIRECTORY,
  V2_HOME,
  V2_REWARDS,
} from '../../../../constants/routes';
import useWindowSize from '../../../../hooks/useGetWindowScreenSize';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import useLayoutStore from '../../../../stores/layoutStore';
import usePrimaryNavLogic from '../../../layout/PrimaryNavController/usePrimaryNavLogic';
import usePrimaryNavModel from '../../../layout/PrimaryNavController/usePrimaryNavModel';
import { getGeneralListItems } from './data';

import useGetUserFlowPermissions from '../../../../hooks/useGetUserFlowPermissions';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import { TEMPLATES } from '../../../../languages/en/singleWords';
import { useAddNewFlowCallback } from '../../../flows/FlowsActiveListController/useAddNewFlowCallback';

const layoutSelector = (state: LayoutStoreState) => state.toggleLeftAsideOpen;

const useLeftDrawerGeneralListController = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { canUserCreateFlow = false } = useGetUserFlowPermissions();

  let listItems = getGeneralListItems(canUserCreateFlow);

  const handleAddNewFlowClick = useAddNewFlowCallback();

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const history = useHistoryWrapper();
  const { pathname } = useLocation();
  const toggleLeftAsideOpen = useLayoutStore(layoutSelector);

  const { isMobileWidth, isTabletWidth } = useWindowSize();

  const {
    models: {
      isCurrentUserAdmin,
      isQuickSetupDataLoading,
      showQuickSetupOption,
      hideRewardsLink,
    },
  } = usePrimaryNavModel();

  const {
    operations: {
      trackLeftDrawerRewardsClick,
      trackLeftDrawerMainFeedClick,
      trackLeftDrawerPeopleDirectoryClick,
    },
  } = usePrimaryNavLogic(
    isQuickSetupDataLoading,
    isCurrentUserAdmin,
    showQuickSetupOption,
  );

  const handleOnItemClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    const route = e.currentTarget.id;
    if (route === TEMPLATES) {
      handleAddNewFlowClick();
    } else {
      history.push(route);
    }

    if (isMobileWidth || isTabletWidth) {
      toggleLeftAsideOpen();
    }
    if (e.currentTarget.id === V2_HOME) {
      trackLeftDrawerMainFeedClick();
    }

    if (e.currentTarget.id === V2_REWARDS) {
      trackLeftDrawerRewardsClick();
    }

    if (e.currentTarget.id === PEOPLE_DIRECTORY) {
      trackLeftDrawerPeopleDirectoryClick();
    }
  };

  const getDisabledItems = (item: GeneralCollapsibleNavigationItemProps) => {
    switch (item.id) {
      case V2_REWARDS:
        return !hideRewardsLink;
      default:
        return true;
    }
  };

  listItems = listItems.map((item) => {
    return {
      ...item,
      isActive: pathname.includes(item.id),
      isEnabled: getDisabledItems(item),
    };
  });

  const heading = 'General';
  return {
    model: {
      heading,
      isExpanded,
      generalListItems: listItems,
    },
    operations: {
      handleExpand,
      handleOnItemClick,
    },
  };
};

export default useLeftDrawerGeneralListController;
