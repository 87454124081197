import copy from 'copy-to-clipboard';

import { APP_URL, NEW_APP_URL } from '../../config';

const getCopyLink = (url: string, isExternalParticipationLink?: boolean) => {
  let urlToRedirect;

  if (isExternalParticipationLink) {
    urlToRedirect = url.replace(`${APP_URL}`, `${NEW_APP_URL}/e/`);
  } else {
    urlToRedirect = url.replace(`${APP_URL}`, `${NEW_APP_URL}/`);
  }

  return urlToRedirect;
};

export const copyToClipboard = (
  str: string,
  isExternalParticipationLink?: boolean,
) => {
  const urlToRedirect = getCopyLink(str, isExternalParticipationLink);
  return copy(urlToRedirect);
};

export const isEmpty = (obj: any) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const groupBy = (xs: any, key: any) => {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const pick = (object: Record<string, any>, keys: string[]) => {
  return (keys || []).reduce((acc: Record<string, any>, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (object && object.hasOwnProperty(key)) {
      acc[key] = object[key];
    }
    return acc;
  }, {});
};

export const sortBy = (key: any) => {
  return (a: any, b: any) => (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0);
};

export const isArrayEquals = (
  a: unknown[] | undefined,
  b: unknown[] | undefined,
): boolean => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
};

export type Nullable<T> = T | undefined | null;

export const stripHTMLTagsFromString = (originalString: string) =>
  originalString.replace(/(<([^>]+)>)/gi, '');

export function buildURL(url: string, queryParamMap: Record<string, string>) {
  const urlToReturn = new URL(url);
  urlToReturn.search = new URLSearchParams(queryParamMap).toString();
  return urlToReturn.toString();
}

export function getIsSlugCodeFromURLValid(
  slugUrlFromAPI: string,
  slugUrlFromPathname: string,
) {
  const slugCodeFromAPI =
    slugUrlFromAPI.split('-')[slugUrlFromAPI.split('-').length - 1];
  const slugCodeFromURL =
    slugUrlFromPathname.split('-')[slugUrlFromPathname.split('-').length - 1];
  return slugCodeFromURL === slugCodeFromAPI;
}

// Utility function to create a message string from a format string and arguments
export function createMessage(
  format: (...strArgs: any[]) => string,
  ...args: any[]
) {
  return format(...args);
}
