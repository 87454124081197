import { NOTEBOOK_EVENTS, NOTEBOOK_TASK_EVENTS } from '../constants';

const eventsAttributesMap = new Map();

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SHOW, [
  'tab',
  'show',
  'section',
  'todayTasks',
  'overdueTasks',
  'upcomingTasks',
  'unscheduledTasks',
  'source',
  'subSource',
]);

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_ERROR, [
  'error',
  'source',
  'subSource',
  'errorIds',
  'tab',
  'source',
  'subSource',
]);

// Notebook sectional events
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_TASK_CREATION, [
  'action',
  'section',
  'type',
  'tab',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_CHANGED, [
  'section',
  'action',
  'lastSection',
  'type',
  'tab',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_EXPANDED, [
  'section',
  'action',
  'tab',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_COLLAPSED, [
  'section',
  'action',
  'tab',
  'source',
  'subSource',
]);

// Notebook task-based events
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_SHOW, [
  'show',
  'tab',
  'source',
  'subSource',
]);

eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_CREATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'type',
  'title',
  'status',
  'titleLinkURL',
  'tab',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ARCHIVE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DELETE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_TITLE_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastTitle',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_DUE_DATE_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastDueDate',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_STATUS_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastStatus',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ASSIGNMENT_UPDATE, [
  'action',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastAssigneeId',
  'titleLinkURL',
  'tab',
]);
eventsAttributesMap.set(NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_ERROR, ['error']);

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_BOARD_SECTION_CREATED, [
  'action',
  'view',
  'sectionName',
  'sectionId',
  'source',
  'subSource',
]);

eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_BOARD_SECTION_MOVED, [
  'action',
  'view',
  'sectionId',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_BOARD_SECTION_DELETED, [
  'action',
  'view',
  'sectionName',
  'sectionId',
  'source',
  'subSource',
]);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_BOARD_SECTION_RENAMED, [
  'action',
  'view',
  'sectionName',
  'sectionId',
  'source',
  'subSource',
]);
eventsAttributesMap.set(
  NOTEBOOK_EVENTS.NOTEBOOK_BOARD_SECTION_COMPLETION_FILTER,
  ['action', 'view', 'sectionId', 'completedFilter', 'source', 'subSource'],
);
eventsAttributesMap.set(NOTEBOOK_EVENTS.NOTEBOOK_SECTION_CHANGED, [
  'action',
  'view',
  'sectionName',
  'taskId',
  'creatorId',
  'assigneeId',
  'dueDate',
  'creationDate',
  'source',
  'subSource',
  'agent',
  'type',
  'title',
  'status',
  'lastSectionName',
  'titleLinkURL',
  'tab',
  'sectionId',
]);

export default eventsAttributesMap;
