import React from 'react';

const useHover = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.TouchEvent<HTMLElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isHovering = Boolean(anchorEl);
  return {
    model: {
      isHovering,
      anchorEl,
    },
    operations: {
      handlePopoverOpen,
      handlePopoverClose,
    },
  };
};

export default useHover;
