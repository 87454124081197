/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { FlowsFeedController } from '../FlowsFeedController';
import FlowsFeedPostLoader from '../FlowsFeedController/Loader';
import {
  useFetchFlowDetailsQuery,
  useVerifyFlowAuthorization,
} from '../../../queries/Flows/Feed';
import FlowsBuilderTabsController from '../../flowsBuilder/FlowsBuilderTabsController';
import { getErrorStatusCode } from '../../../Utils/message';
import { FeedsContainer } from '../../../Utils/styles/display';
import { isMobilePlatform } from '../../../Utils/window';
import useLayoutStore from '../../../stores/layoutStore';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { AxiosError } from 'axios';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import searching from '../../../img/searching.svg';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import { EXTERNAL_FLOW_PATH } from '../../../constants/routes';

import { FeedLoaderContainer } from './styles';

import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { setFlowIdSelector } from '../../../stores/flowBuilderStore/selectors';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { FlowAccessRoles } from '../../../queries/Flows/interfaces';

const FlowsController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();

  const { flowId } = useParams<{ flowId: string }>();
  const setFlowId = useFlowBuilderStore(setFlowIdSelector);

  setFlowId(flowId);

  const {
    isInitialLoading: isLoading,
    data,
    isError,
    error,
  } = useFetchFlowDetailsQuery(flowId);
  const {
    data: flowAuthorizationData,
    isInitialLoading: isFlowAuthorizationLoading,
  } = useVerifyFlowAuthorization(flowId, isError);
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { push } = useHistory();

  const button = useMemo(() => {
    return {
      text: 'Log into a different workspace',
      onClick: () => push('/signout'),
    };
  }, [push]);

  if (isLoading || isFlowAuthorizationLoading) {
    return (
      <FeedLoaderContainer>
        <FlowsFeedPostLoader />
      </FeedLoaderContainer>
    );
  }

  if (
    isError &&
    flowAuthorizationData &&
    !isFlowAuthorizationLoading &&
    flowAuthorizationData?.data.isLoggedIn &&
    flowAuthorizationData.data.participation === 'EXTERNAL'
  ) {
    return <Redirect to={EXTERNAL_FLOW_PATH.replace(':flowId', flowId)} />;
  }

  if (isError) {
    const flowDetailErrorCode = getErrorStatusCode(error as AxiosError);
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }

    let heading = FLOW_FEED_STATES.FLOW_NOT_FOUND.HEADING;

    if (flowDetailErrorCode === 403) {
      heading = FLOW_FEED_STATES.ACCESS_DENIED.HEADING;
    }

    if (flowDetailErrorCode === 500) {
      heading = FLOW_FEED_STATES.SERVER_ERROR.HEADING;
    }

    const subHeading = `You may not have access to this flow, or it may have been deleted.
      You’re currently logged into ${profileData?.assembly.name}. Make sure you’re logged into the correct Assembly workspace. `;

    return (
      <FeedsContainer>
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={heading}
          subHeading={subHeading}
          button={button}
        />
      </FeedsContainer>
    );
  }

  const isFlowEditor =
    data?.data.isFlowOwner ||
    Boolean(data?.data.flowAccessRoles?.includes(FlowAccessRoles.Collaborator));

  return isFlowEditor && !isMobilePlatform ? (
    <FlowsBuilderTabsController />
  ) : (
    <FlowsFeedController />
  );
};

export default FlowsController;
