export const timerOptions = [
  {
    id: 5,
    isSelected: false,
    value: '5 seconds',
  },
  {
    id: 10,
    isSelected: false,
    value: '10 seconds',
  },
  {
    id: 15,
    isSelected: false,
    value: '15 seconds',
  },
  {
    id: 20,
    isSelected: false,
    value: '20 seconds',
  },
  {
    id: 25,
    isSelected: false,
    value: '25 seconds',
  },
  {
    id: 30,
    isSelected: false,
    value: '30 seconds',
  },
];
