import { useState } from 'react';
import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import SVGIcon from '../../atoms/SVGIcon';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { flowItemsWithoutDeletePost } from '../KnowledgeCenterCard/data';
import {
  CardBody,
  CardImage,
  NewStyledBody,
  StyledCard,
  StyledDropdownIconButton,
} from './newStyles';
import { NameWrapper, NewStyledImage } from './styles';
import type { ResponseThumbnailProps } from './types';

export function ResponseDocumentThumbnail(props: ResponseThumbnailProps) {
  const [isHovered, setIsHovered] = useState(false);

  const {
    openPreviewModal,
    file,
    dataTestId,
    cardColor,
    cardIcon,
    onOptionsSelect,
    cardImageUrl,
  } = props;

  const fileName = file.originalName ?? file.name;

  return (
    <StyledCard
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => openPreviewModal(file)}
      data-testid={dataTestId}
    >
      <CardImage backgroundColor={cardColor}>
        {cardIcon && <SVGIcon icon={cardIcon} size="32px" />}

        {cardIcon === 'imageFile' && (
          <NewStyledImage alt={fileName} src={cardImageUrl} />
        )}
      </CardImage>

      <CardBody>
        <NameWrapper>
          <NewStyledBody variant="body2Medium">
            {fileName.substring(0, fileName.lastIndexOf('.'))}
          </NewStyledBody>
          <Body variant="body2Medium">.{fileName.split('.').pop()}</Body>
        </NameWrapper>
        <NewStyledBody variant="body3" color="gray8">
          Click to view file
        </NewStyledBody>
        {isHovered && (
          <StyledDropdownIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            menuItems={flowItemsWithoutDeletePost}
            icon={'more'}
            onItemClick={onOptionsSelect}
            size={ButtonSize.Normal}
            stopEventPropagation={true}
            dropdownPlacement={DropdownPlacement.Top}
          />
        )}
      </CardBody>
    </StyledCard>
  );
}
