import * as Yup from 'yup';

import { MenuItemProps } from '../atomic/molecules/Dropdown_V2/interfaces';
import { SETTINGS_EDIT_PRONOUNS_LANGUAGE } from '../languages/en/settings';

export const GENDER_PRONOUNS_CUSTOM_OPTION_VALUE = 'Custom';

export const GENDER_PRONOUNS_OPTIONS = [
  'She/Her/Hers',
  'He/Him/His',
  'They/Them/Theirs',
];

const dropdownItems = GENDER_PRONOUNS_OPTIONS.map((option, index) => ({
  id: index,
  value: option,
}));

export const GENDER_PRONOUNS_DROPDOWN_ITEMS: MenuItemProps[] = [
  {
    id: 'gender-pronouns',
    items: [
      ...dropdownItems,
      {
        id: dropdownItems.length,
        value: GENDER_PRONOUNS_CUSTOM_OPTION_VALUE,
      },
    ],
  },
];

export const GENDER_PRONOUNS_VALIDATION_SCHEMA = Yup.object().shape({
  custom: Yup.string()
    .max(25, SETTINGS_EDIT_PRONOUNS_LANGUAGE.MAX_LENGTH)
    .matches(/^(([ ]*[a-zA-Z]+[ ]*\/?[ ]*)|([ ]))+$/, {
      message: SETTINGS_EDIT_PRONOUNS_LANGUAGE.INVALID_CHARS,
      excludeEmptyString: true,
    }),
});

export const formatPronouns = (raw = '') => {
  return raw
    .replace(/\s+/g, ' ') // Remove extraneous spaces
    .replace(/\/\s*$/, '') // Remove trailing slash
    .trim();
};
