import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import ToolTip from '../ToolTipV2';
import ThemeV2 from '../../../theme';

export const ButtonWrapper = styled.div`
  padding-top: 8px;
`;

export const DividerWrapper = styled.div`
  padding: 16px 0px;
`;

export const RewardsPopoverWrapper = styled.div`
  padding: 16px;
`;

export const ProgressBody = styled(Body)`
  padding-top: 8px;
  color: ${ThemeV2.palette.gray7};
`;

export const ProgressWrapper = styled.div<{
  highlighted?: boolean;
  highlightColor?: string;
}>`
  width: 328px;
  height: 16px;
  background-color: ${({ highlighted, highlightColor }) =>
    highlighted ? highlightColor : ThemeV2.palette.geekBlue2};
  border-radius: 20px;
`;

export const RewardsHeading = styled(Heading)`
  margin: 0px;
`;

export const TooltipWrapper = styled(ToolTip)`
  display: inline-flex;
  margin-left: 4px;
`;

export const TooltipContent = styled.div`
  width: 196px;
`;

export const TooltipPrefix = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeV2.palette.gray9};
`;

export const TooltipSuffix = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeV2.palette.gray7};
`;

export const Spacing = styled.div<{
  margin?: string;
  padding?: string;
  line?: boolean;
}>`
  width: 100%;
  border-bottom: ${({ line }) =>
    line ? `1px solid ${ThemeV2.palette.gray4}` : `unset`};
  padding: ${({ padding }) => (padding ? `${padding}` : `unset`)};
  margin: ${({ margin }) => (margin ? `${margin}` : `unset`)};
`;
