import React from 'react';
import { GeneralMenu } from '../../../atomic/organism/LeftNav/GeneralMenu';
import useLeftDrawerGeneralListController from './useLeftDrawerGeneralListController';

const LeftDrawerGeneralListController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    model: { generalListItems },
    operations: { handleOnItemClick },
  } = useLeftDrawerGeneralListController();

  return (
    <GeneralMenu
      lists={generalListItems}
      isHovered={isLeftNavHovered || isLeftAsideOpen}
      onItemClick={(e) => {
        handleOnItemClick(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
      }}
    />
  );
};

export default LeftDrawerGeneralListController;
