import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { RefObject, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { AnyObjectSchema } from 'yup';
import { PARTICIPATION_ANALYTICS_EVENTS } from '../../Utils/analytics/constants';
import { trackFlowParticipationActionEvent } from '../../Utils/analytics/flowsParticipation';
import {
  dismissAllToasts,
  postErrorToastMessage,
  postInfoToastMessage,
  postSuccessToastMessage,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import {
  GET_FLOW_BLOCK_PARTICIPANTS,
  GET_MEMBERS,
} from '../../constants/endpoints';
import {
  getTouchedBlockIds,
  mapPostResponseDetailsToEditablePostData,
} from '../../controllers/flows/FlowsParticipationController/useFlowsParticipationController/utils';
import { formatBlockResponses } from '../../controllers/flows/ParticipationFlowController/utils';
import {
  DynamicBlockState,
  FlowSubmissionDetails,
  PointStackStaticBlockState,
  StaticBlockState,
  StepperErrorType,
} from '../../interfaces/Flow';
import { VALIDATION_ERRORS } from '../../languages/en/flows/participation';
import {
  FIX_THIS_ERROR,
  THIS_IS_REQUIRED,
} from '../../languages/en/recognitionFlow';
import { useInviteUserPostQuery } from '../../queries/Admin/invites';
import { FlowFeedResponse } from '../../queries/Flows/Feed/interfaces';
import { FlowInstanceResponse } from '../../queries/Flows/interfaces';
import useHardMouseWheel from '../useHardMouseWheel';
import {
  STEP_BUTTON_STATUS,
  generateBlockErrors,
  generateFieldErrors,
  getPersonSelectorBlockEmailsToInvite,
  isBlockContentValid,
  isBlockEmpty,
  isRequiredError,
} from './utils';
import useLayoutStore from '../../stores/layoutStore';

type UseParticipationFlowParameter = {
  staticBlockData: StaticBlockState[];
  schema: AnyObjectSchema;
  initialValues: Record<string, any>;
  onSubmitFlowError?: (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
  ) => void;
  containerRef: RefObject<HTMLDivElement | null>;
  postStepChange?: (
    blockData: StaticBlockState,
    values: Record<string, any>,
  ) => void;

  onFlowSubmit?: (submissionDetails: FlowSubmissionDetails) => void;
  defaultCurrentStep?: number;
  flowInstanceDetails?: FlowInstanceResponse;
  isPrivatePost?: boolean;
  isAnonymousPost?: boolean;
  postData?: FlowFeedResponse;
};

const checkDynamicBlockError = (
  item: StaticBlockState,
  errors: FormikErrors<Record<string, any>>,
  touched: FormikTouched<Record<string, any>>,
  values: Record<string, any>,
) => {
  const currentBlockError = errors[item.id];
  if (
    (item.type === 'SINGLE_PERSON_SELECTOR_DROPDOWN' ||
      item.type === 'MULTI_PERSON_SELECTOR_DROPDOWN') &&
    item.referenceStackId
  ) {
    return Boolean(
      currentBlockError && (values[item.referenceStackId] || touched[item.id]),
    );
  }
  return Boolean(currentBlockError && touched[item.id]);
};

function useParticipationFlow({
  initialValues,
  onSubmitFlowError,
  schema,
  staticBlockData,
  containerRef,
  onFlowSubmit,
  postStepChange = () => {},
  defaultCurrentStep,
  flowInstanceDetails,
  isPrivatePost,
  isAnonymousPost,
  postData,
}: UseParticipationFlowParameter) {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const [currentStep, setCurrentStep] = useState(
    defaultCurrentStep ? defaultCurrentStep : 0,
  );
  const [isFormValid, setFormValid] = useState(false);
  const [hasVisitedLastStep, setHasVisitedLastStep] = useState(
    staticBlockData.length === 1,
  );
  const [dynamicBlockData, setDynamicBlockData] = useState<DynamicBlockState[]>(
    [],
  );
  const [flowHasGivePointsStack] = useState(
    staticBlockData.some((block) => block.type === 'GIVE_POINTS_STACK'),
  );
  const [flowHasPersonSelectorBlock] = useState(
    staticBlockData.some(
      (block) =>
        block.type === 'SINGLE_PERSON_SELECTOR_DROPDOWN' ||
        block.type === 'MULTI_PERSON_SELECTOR_DROPDOWN',
    ),
  );

  const { responseId } = useParams<{ responseId: string }>();

  const isEditMode = !!responseId;

  const { mutate: uploadInviteUserAction, isLoading: isInvitingUsers } =
    useInviteUserPostQuery();
  const queryClient = useQueryClient();

  const handleFlowSubmit = (values: Record<string, any>) => {
    if (isFormValid && onFlowSubmit) {
      const { personSelectorBlockEmailsToInvite, personSelectorBlockId } =
        getPersonSelectorBlockEmailsToInvite(staticBlockData, values);
      if (
        flowHasPersonSelectorBlock &&
        personSelectorBlockEmailsToInvite.length
      ) {
        const inviteUsers = personSelectorBlockEmailsToInvite.map(
          (value: { emailToInvite: string }) => {
            return { email: value.emailToInvite };
          },
        );
        const inviteUsersPayload = {
          data: inviteUsers,
        };
        if (isEmbeddedInMainApp) {
          postInfoToastMessage("Inviting new user's");
        } else {
          showInfoMessage("Inviting new user's");
        }
        uploadInviteUserAction(inviteUsersPayload, {
          onSuccess: (inviteUsersData) => {
            queryClient.invalidateQueries([GET_MEMBERS]);
            queryClient.invalidateQueries([GET_FLOW_BLOCK_PARTICIPANTS]);
            if (inviteUsersData.data.addedMembers.length) {
              dismissAllToasts();
              if (isEmbeddedInMainApp) {
                postSuccessToastMessage('Teammate(s) successfully invited.');
              } else {
                showSuccessMessage('Teammate(s) successfully invited.');
              }
              const personSelectorBlockValues = values[personSelectorBlockId];
              if (Array.isArray(personSelectorBlockValues)) {
                personSelectorBlockValues
                  .filter((person) => person.emailToInvite)
                  .forEach((person) => {
                    person.id = inviteUsersData.data.addedMembers.find(
                      (member: { email: string }) =>
                        member.email === person.emailToInvite,
                    ).memberId;
                  });
              } else {
                personSelectorBlockValues.id =
                  inviteUsersData.data.addedMembers.find(
                    (member: { email: string }) =>
                      member.email === personSelectorBlockValues.emailToInvite,
                  ).memberId;
              }
              onFlowSubmit({
                values,
                dynamicBlockData,
                currentStep,
              });
            }
            if (inviteUsersData.data.failedMembers.length) {
              if (inviteUsersData.data.failedMembers.length === 1) {
                if (isEmbeddedInMainApp) {
                  postErrorToastMessage(
                    `Failed to invite ${inviteUsersData.data.failedMembers[0].email}. Try again.`,
                  );
                } else {
                  showErrorMessage(
                    `Failed to invite ${inviteUsersData.data.failedMembers[0].email}. Try again.`,
                  );
                }
              } else {
                if (isEmbeddedInMainApp) {
                  postErrorToastMessage(
                    'Failed to invite teammates. Try again.',
                  );
                } else {
                  showErrorMessage('Failed to invite teammates. Try again.');
                }
              }
            }
          },
        });
      } else {
        onFlowSubmit({
          values,
          dynamicBlockData,
          currentStep,
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    submitForm,
    submitCount,
    setFieldValue,
    setFieldTouched,
    setTouched,
    resetForm,
    setFieldError,
    isValid,
  } = useFormik({
    onSubmit: handleFlowSubmit,
    initialValues,
    validationSchema: schema,
    validateOnMount: true,
  });

  const editedBlockIds = useMemo(() => {
    if (isEditMode && flowInstanceDetails && postData?.responses) {
      const formattedInputs = formatBlockResponses(
        values,
        mapPostResponseDetailsToEditablePostData(postData),
        isPrivatePost,
        isAnonymousPost,
      );

      const inputResponses = formattedInputs.responses;
      return getTouchedBlockIds(inputResponses, postData.responses);
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const blockErrors = useMemo(
    () => generateBlockErrors(errors, submitCount > 0),
    [errors, submitCount],
  );

  const fieldErrors = useMemo(
    () => generateFieldErrors(errors, touched),
    [errors, touched],
  );

  useEffect(() => {
    setFormValid(isValid);
  }, [isValid]);

  // This useEffect updates the local state for each block based on Formik state
  useEffect(() => {
    setDynamicBlockData(
      staticBlockData.map((item) => {
        let errorMessage: string | undefined;
        let errorType: StepperErrorType | undefined;
        const isBlockValid = isBlockContentValid(item, values[item.id]);
        const isError = checkDynamicBlockError(item, errors, touched, values);
        const isEmpty = isBlockEmpty(item, values[item.id]);
        if (isError) {
          if (errors[item.id] === VALIDATION_ERRORS.REQUIRED) {
            errorMessage = THIS_IS_REQUIRED;
            errorType = StepperErrorType.REQUIRED;
          } else {
            errorMessage = FIX_THIS_ERROR;
            errorType = StepperErrorType.FIX_ERROR;
          }
        }
        return {
          id: item.id,
          title: item.title,
          isError,
          isValid: !errors[item.id] && isBlockValid,
          errorMessage,
          errorType,
          isEmpty: isEmpty,
        };
      }),
    );
  }, [errors, staticBlockData, touched, values]);

  // This useEffect is used to add custom errors for scenarios with Points Stack
  useEffect(() => {
    if (flowHasGivePointsStack) {
      const otherBlocksInFlow = staticBlockData.length > 2;
      const pointsStackBlocks = staticBlockData.filter(
        (block) => block.type === 'GIVE_POINTS_STACK',
      ) as PointStackStaticBlockState[];
      pointsStackBlocks.forEach((block) => {
        const dependentBlock = staticBlockData.find(
          ({ id }) => id === block.dependentBlockId,
        ) as StaticBlockState;
        const isPointsStackEmpty = isBlockEmpty(block, values[block.id]);
        const isDependentBlockEmpty = isBlockEmpty(
          dependentBlock,
          values[block.dependentBlockId],
        );
        if (otherBlocksInFlow) {
          if (!isPointsStackEmpty) {
            if (isDependentBlockEmpty) {
              setFieldError(block.dependentBlockId, VALIDATION_ERRORS.REQUIRED);
            } else if (
              errors[block.dependentBlockId] === VALIDATION_ERRORS.REQUIRED
            ) {
              setFieldError(block.dependentBlockId, undefined);
            }
          } else if (
            isDependentBlockEmpty &&
            errors[block.dependentBlockId] === VALIDATION_ERRORS.REQUIRED
          ) {
            setFieldError(block.dependentBlockId, undefined);
          }
        } else if (isDependentBlockEmpty) {
          setFieldError(block.dependentBlockId, VALIDATION_ERRORS.REQUIRED);
        }
      });
    }
  }, [flowHasGivePointsStack, setFieldError, staticBlockData, values, errors]);

  const invokeParticipationTrackerFunction = (from, step: number) => {
    switch (from) {
      case STEP_BUTTON_STATUS.NEXT:
        trackFlowParticipationActionEvent({
          currentStep: step,
          action: PARTICIPATION_ANALYTICS_EVENTS.NAVIGATION_NEXT_CLICKED,
        });
        break;

      case STEP_BUTTON_STATUS.PREV:
        trackFlowParticipationActionEvent({
          currentStep: step,
          action: PARTICIPATION_ANALYTICS_EVENTS.NAVIGATION_PREV_CLICKED,
        });
        break;

      default:
        trackFlowParticipationActionEvent({
          currentStep: step,
          action: PARTICIPATION_ANALYTICS_EVENTS.PROGRESS_BAR_CLICKED,
        });
        break;
    }
  };

  const onStepChange = (destinationStepIndex: number, fromNav = '') => {
    const newTouched = { ...touched };
    invokeParticipationTrackerFunction(fromNav, currentStep);

    const isValidDestinationIndex =
      destinationStepIndex >= 0 &&
      destinationStepIndex <= staticBlockData.length;
    if (!isValidDestinationIndex) return;

    if (destinationStepIndex < staticBlockData.length) {
      const { id: currentStepId } = staticBlockData[currentStep];
      const nextBlockData = staticBlockData[destinationStepIndex];
      const { id: nextStepId } = nextBlockData;
      // TO SPECIFICALLY CHECK IF NEXT BLOCK IS AN OPEN ENDED BLOCK OR MULTISELECT
      // AND IS IT EMPTY
      if (isBlockEmpty(nextBlockData, values[nextStepId])) {
        newTouched[nextStepId] = false;
      }

      if (postStepChange) {
        postStepChange(staticBlockData[currentStep], values[currentStepId]);
      }

      const currentStepError = (errors[currentStepId] || '') as string;
      if (
        currentStepError.length > 0 &&
        !isRequiredError(currentStepError) &&
        staticBlockData[currentStep].type === 'MULTI_CHOICE_MULTI_SELECT'
      ) {
        newTouched[currentStepId] = true;
        setTouched(newTouched);
        return;
      }

      if (
        currentStepError &&
        !isRequiredError(currentStepError) &&
        !touched[currentStepId]
      ) {
        newTouched[currentStepId] = true;
        setTouched(newTouched);
        return;
      }
      newTouched[currentStepId] = true;
      const isChangingToLastStep =
        destinationStepIndex === staticBlockData.length - 1;
      if (isChangingToLastStep) {
        setHasVisitedLastStep(true);
      }
      setTouched(newTouched);
      setCurrentStep(destinationStepIndex);
    }
  };

  const goToNextStep = () =>
    onStepChange(currentStep + 1, STEP_BUTTON_STATUS.NEXT);

  const goToPreviousStep = () =>
    onStepChange(currentStep - 1, STEP_BUTTON_STATUS.PREV);

  const onNeedHelpButtonClick = () => {
    trackFlowParticipationActionEvent({
      currentStep,
      action: PARTICIPATION_ANALYTICS_EVENTS.NEED_HELP_CLICKED,
    });
  };

  const onPromptOpen = () => {
    trackFlowParticipationActionEvent({
      currentStep,
      action: PARTICIPATION_ANALYTICS_EVENTS.EXIT_TRIGGERED,
    });
  };

  const onPromptClose = () => {
    trackFlowParticipationActionEvent({
      currentStep,
      action: PARTICIPATION_ANALYTICS_EVENTS.EXIT_CANCELED,
    });
  };

  // This function is called on submit to start checking whether all the validation
  // checks are passing for each block. handleSubmit is called which is a formik function
  // which does not call formikOnSubmit until there are no errors in formik state.
  const onFormCompleteClick = () => {
    const firstError = dynamicBlockData.findIndex((item) => errors[item.id]);
    if (firstError > -1) {
      setCurrentStep(firstError);
      const updatedTouched: Record<string, boolean> = {};
      dynamicBlockData.forEach((item) => {
        updatedTouched[item.id] = true;
      });
      setTouched(updatedTouched);
      if (onSubmitFlowError) {
        onSubmitFlowError(staticBlockData, dynamicBlockData);
      }
    }
    submitForm();
  };

  useHardMouseWheel(containerRef, {
    deltaThreshold: 75,
    onScrollDown: () => onStepChange(currentStep + 1),
    onScrollUp: () => onStepChange(currentStep - 1),
    customCheck: (event) => {
      const openEndedElement = document.getElementById('open-ended-block');
      if (event.target) {
        if (openEndedElement?.contains(event.target as Node)) {
          return false;
        }
      }
      return true;
    },
  });

  return {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      values,
      errors,
      touched,
      dynamicBlockData,
      editedBlockIds,
      isInvitingUsers,
    },
    operations: {
      onStepChange,
      onFormCompleteClick,
      setFieldValue,
      setFieldTouched,
      resetForm,
      goToNextStep,
      goToPreviousStep,
      onNeedHelpButtonClick,
      onPromptOpen,
      onPromptClose,
    },
  };
}

export default useParticipationFlow;
