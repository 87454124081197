import styled from 'styled-components';
import ThemeV2 from '../../../../theme';
import Heading from '../../../atoms/Heading';
import Body from '../../../atoms/Body';

export const TabChildren = styled.section`
  padding: 24px;
`;

export const LoaderWrapper = styled.section`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const Widget = styled.div`
  margin-bottom: 24px;
  height: 500px;
`;

export const WidgetContainer = styled.section`
  padding: 24px;
  margin-top: 24px;
  border-radius: 4px;
  pointer-events: none;
  border: 1px solid ${ThemeV2.palette.gray5};
`;

export const StyledHeading = styled(Heading)`
  margin-left: 8px;
`;

export const StyledBody = styled(Body)`
  margin-left: 8px;
`;

export const ContentWrapper = styled.section<{ isLoading: boolean }>`
  pointer-events: none;
  max-height: ${({ isLoading }) => (isLoading ? 0 : 'initial')};
  visibility: ${({ isLoading }) => (isLoading ? 'hidden' : 'initial')};
`;
