import styled from 'styled-components';
import { ProgressStatuses } from '.';
import ThemeV2 from '../../../theme';

export const colorMap = {
  none: {
    color: ThemeV2.palette.gray3,
  },
  success: {
    color: ThemeV2.palette.polarGreen6,
  },
  warning: {
    color: ThemeV2.palette.sunriseYellow6,
  },
  danger: {
    color: ThemeV2.palette.dustRed6,
  },
  flowProgress: {
    color: ThemeV2.palette.geekBlue6,
  },
};

export type ColorTypes = keyof typeof colorMap;

export const progressBarSizes = {
  small: '4px',
  medium: '6px',
  large: '8px',
};

export const StyledProgressBarWrapper = styled.div<{
  height: string;
  background: string;
}>`
  position: relative;
  height: ${(props) =>
    props.height ? `${props.height}` : progressBarSizes.medium};
  background: ${(props) =>
    props.background ? `${props.background}` : `${ThemeV2.palette.gray3}`};
  border-radius: 8px;
  overflow: hidden;
  flex-basis: calc(33.333% - 4px);
`;

export const StyledProgressBar = styled.div<{
  value: number;
  color: ProgressStatuses;
  transition?: string;
}>`
  height: inherit;
  width: ${(props) => `${props.value}%`};
  background: ${(props) =>
    props.color ? colorMap[props.color].color : ThemeV2.palette.gray3};
  border-radius: 8px;
  transition: ${(props) =>
    props.transition ? props.transition : '0.2s ease-in width'};
`;
