import styled from 'styled-components';

import ThemeV2 from '../../../../theme';
import Heading from '../../../../atomic/atoms/Heading';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import { Flex } from '../../../../Utils/styles/display';
import IconButton from '../../../../atomic/atoms/IconButton_V2';

type TemplateGalleryHeaderProps = {
  onClose(): void;
};

const StyledHeader = styled(Flex)`
  max-height: 72px;
  height: 100%;
  width: 100%;
  top: 0;
  padding: 18px 30px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
`;

const TemplateGalleryHeader = (props: TemplateGalleryHeaderProps) => {
  const { onClose } = props;

  return (
    <StyledHeader justifyContent="space-between">
      <Flex justifyContent="space-between">
        <SVGIcon icon="templateIcon" size="40px" />
        <Heading variant="h6" marginBottom="0">
          Choose your use case
        </Heading>
      </Flex>
      <IconButton onClick={onClose} dataTestId="closeTemplateGallery">
        <SVGIcon
          icon="round-close"
          color={ThemeV2.palette.gray6}
          size="24px"
          data-qa-id="btn-template-gallery-close"
        />
      </IconButton>
    </StyledHeader>
  );
};

export default TemplateGalleryHeader;
