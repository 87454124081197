import React, { useState } from 'react';
import {
  LeftColumn,
  RightColumn,
  StyledDropdownItem,
  StyledPopover,
  Wrapper,
} from './styles';
import { PopoverWithSubMenuProps, SubMenuContentProps } from './types';

const SubMenuContent = (props: SubMenuContentProps) => {
  const { isAutoWidth, popoverItems, slideContent } = props;
  const [activeItem, setActiveItem] = useState('');

  const handleActiveItem = (id: string) => {
    if (activeItem === id) {
      setActiveItem('');
    } else {
      setActiveItem(id);
    }
  };

  return (
    <Wrapper>
      <LeftColumn>
        {popoverItems?.map((item) => (
          <StyledDropdownItem
            id={item.id}
            key={item.id}
            onItemClick={() => {
              handleActiveItem(item.id);
            }}
            prefixIcon={item.icon}
            value={item.title}
            isSelected={activeItem === item.id}
            disabled={item.disabled}
          />
        ))}
      </LeftColumn>
      <RightColumn
        id="right-column"
        isAutoWidth={isAutoWidth}
        show={Boolean(slideContent(activeItem))}
      >
        {slideContent && slideContent(activeItem)}
      </RightColumn>
    </Wrapper>
  );
};

const PopoverWithSubMenu = (props: PopoverWithSubMenuProps) => {
  const {
    id,
    className,
    popoverItems,
    referenceContent,
    slideContent,
    isOpen,
    anchorEl,
    handleClose,
    isAutoWidth,
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin = {
      vertical: 'top',
      horizontal: 'center',
    },
  } = props;

  return (
    <div className={className}>
      {referenceContent}
      <StyledPopover
        id={id}
        open={isOpen || false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <SubMenuContent
          isAutoWidth={isAutoWidth}
          popoverItems={popoverItems}
          slideContent={slideContent}
        />
      </StyledPopover>
    </div>
  );
};

export default PopoverWithSubMenu;
