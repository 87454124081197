import React, { useRef, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ThemeV2 from '../../../../../theme';
import { variantMap } from '../../../../atoms/Body';

const StyledTextareaAutosize = styled(TextareaAutosize)<{
  variant: string;
  hovereffect: string;
  applyHoverStyles?: boolean;
}>`
  resize: none;
  width: 100%;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: Roboto;
  padding: 0;
  vertical-align: middle;
  padding: ${({ variant }) => (variant !== 'custom' ? 'none' : '4px 8px')};

  &:hover {
    ${({ hovereffect }) =>
      hovereffect === 'custom' && `background: ${ThemeV2.palette.gray3}`};
    border-radius: 4px;
    margin: ${({ applyHoverStyles }) => applyHoverStyles && '0 0 0 -8px'};
    padding: ${({ applyHoverStyles }) => applyHoverStyles && '0 0 0 8px'};
  }

  &:focus {
    border: ${({ variant }) =>
      variant !== 'custom'
        ? `0.5px solid ${ThemeV2.palette.white}`
        : `0.5px solid ${ThemeV2.palette.gray7}`};
    border-radius: 4px;
  }
`;

type CustomType = 'custom' | '';

interface TaskItemTextAreaProps {
  autofocus?: boolean;
  serializedTitle?: string;
  onBlur: (value: string) => void;
  handleEnterClick: (value: string) => void;
  isCreateItem?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean;
  variant?: CustomType;
  enableShiftNewLine?: boolean;
  hasHoverEffect: boolean;
  placeholder?: string;
  textVariant?: string;
  applyHoverStyles?: boolean;
}

const TaskItemTextArea = ({
  autofocus = true,
  serializedTitle,
  onBlur,
  handleEnterClick,
  isCreateItem,
  disabled,
  stopPropagation = true,
  variant = '',
  enableShiftNewLine = false,
  hasHoverEffect = false,
  placeholder = '',
  textVariant = 'body1',
  applyHoverStyles,
}: TaskItemTextAreaProps) => {
  const ref = useRef<null | HTMLTextAreaElement>(null);
  const [noteTitle, setNoteTitle] = useState(serializedTitle || '');

  const variantFromMap = variantMap[textVariant];
  const { fontSize, fontWeight, fontStyle, lineHeight } = variantFromMap;
  const fontColor = disabled ? 'gray6' : ThemeV2.palette.gray9;
  const palette = ThemeV2.palette as unknown as {
    [Key: string]: string;
  };
  const paletteColor = palette[fontColor];

  const bodyStyle = useMemo(
    () => ({
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight,
      color: paletteColor,
    }),
    [fontSize, fontStyle, fontWeight, lineHeight, paletteColor],
  );

  useEffect(() => {
    if (ref.current && autofocus) {
      ref.current.focus();
      ref.current.setSelectionRange(
        ref.current.value.length,
        ref.current.value.length,
      );
    }
  }, [autofocus, serializedTitle]);

  useEffect(() => {
    if (serializedTitle) {
      setNoteTitle(serializedTitle || '');
    }
  }, [serializedTitle]);

  const onTextAreaBlur = () => {
    onBlur(noteTitle);
  };

  const onTextAreaKeyDown = (e: React.KeyboardEvent) => {
    if (
      (e.key === 'Enter' || e.code === '13') &&
      (enableShiftNewLine ? !e.shiftKey : true)
    ) {
      e.preventDefault();
      handleEnterClick(noteTitle);
      if (isCreateItem) {
        setNoteTitle('');
      }
    }
  };

  return (
    <StyledTextareaAutosize
      ref={ref}
      spellCheck
      onKeyDown={onTextAreaKeyDown}
      value={noteTitle}
      onChange={(e) => setNoteTitle(e.target.value)}
      onBlur={onTextAreaBlur}
      data-testid="task-text-area"
      data-qa-id="textarea-notebook-task"
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
      disabled={disabled}
      variant={variant}
      // Material UI does not accept boolean non-standard props
      hovereffect={hasHoverEffect ? 'custom' : ''}
      placeholder={placeholder}
      style={{ ...bodyStyle }}
      applyHoverStyles={applyHoverStyles}
    />
  );
};

export default TaskItemTextArea;
