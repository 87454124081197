//import pdfjs from 'pdfjs-dist/build/pdf';
import React, { useEffect, useRef } from 'react';
import mobileStore from '../../../../../stores/mobileAppStore';

// eslint-disable-next-line @typescript-eslint/ban-types
function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

type HookProps = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  pdfDocument: pdfjs.PDFDocumentProxy | undefined;
  numberOfPages: number;
  canvassesRef: React.MutableRefObject<HTMLCanvasElement[]>;
  height: number;
  width: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  onPageLoadSuccess?: (page: pdfjs.PDFPageProxy) => void;
  onPageLoadFail?: (err: Error) => void;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  onPageRenderSuccess?: (page: pdfjs.PDFPageProxy) => void;
  onPageRenderFail?: (err: Error) => void;
  scale?: number;
  rotate?: number;
};

export const usePdf = ({
  pdfDocument,
  numberOfPages,
  canvassesRef,
  onPageLoadSuccess,
  onPageLoadFail,
  onPageRenderSuccess,
  onPageRenderFail,
  //The scale being set to 2 allows for a sharper image by default, and doesn't effect fidelity on most computers.
  scale = 2,
  rotate = 0,
  height,
  width,
}: HookProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore-next-line */
  const renderTask = useRef<(pdfjs.PDFRenderTask | null)[]>([]);
  const onPageLoadSuccessRef = useRef(onPageLoadSuccess);
  const onPageLoadFailRef = useRef(onPageLoadFail);
  const onPageRenderSuccessRef = useRef(onPageRenderSuccess);
  const onPageRenderFailRef = useRef(onPageRenderFail);

  // assign callbacks to refs to avoid redrawing

  useEffect(() => {
    onPageLoadSuccessRef.current = onPageLoadSuccess;
  }, [onPageLoadSuccess]);

  useEffect(() => {
    onPageLoadFailRef.current = onPageLoadFail;
  }, [onPageLoadFail]);

  useEffect(() => {
    onPageRenderSuccessRef.current = onPageRenderSuccess;
  }, [onPageRenderSuccess]);

  useEffect(() => {
    onPageRenderFailRef.current = onPageRenderFail;
  }, [onPageRenderFail]);

  useEffect(() => {
    const isMobileAppV3 = mobileStore.getState().isMobileAppV3;
    // draw a page of the pdf
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore-next-line */
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const drawPDF = (page: pdfjs.PDFPageProxy, pageNumber: number) => {
      // Because this page's rotation option overwrites pdf default rotation value,
      // calculating page rotation option value from pdf default and this component prop rotate.
      const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
      let viewport: any;
      let dpRatio: number;
      let transform: number[] | undefined = undefined;

      if (isMobileAppV3) {
        dpRatio = Math.min(window.devicePixelRatio, 2) || 1;
        viewport = page.getViewport({ scale, rotation });

        transform = dpRatio !== 1 ? [dpRatio, 0, 0, dpRatio, 0, 0] : undefined;
      } else {
        dpRatio = window.devicePixelRatio;
        const adjustedScale = scale * (dpRatio || 1);
        viewport = page.getViewport({ scale: adjustedScale, rotation });
      }

      const canvasEl = canvassesRef.current[pageNumber];
      if (!canvasEl) {
        return;
      }

      const canvasContext = canvasEl.getContext('2d', {
        willReadFrequently: true,
      });
      if (!canvasContext) {
        return;
      }

      canvasEl.style.width = `calc(100% - 64px)`;
      canvasEl.style.height = `calc(100% - 64px)`;
      if (isMobileAppV3) {
        canvasEl.width = Math.floor(viewport.width * dpRatio);
        canvasEl.height = Math.floor(viewport.height * dpRatio);
      } else {
        canvasEl.height = viewport.height;
        canvasEl.width = viewport.width;
      }

      // if previous render isn't done yet, we cancel it
      if (renderTask.current[pageNumber]) {
        renderTask.current[pageNumber].cancel();
      }

      //This double-render was set up to address an issue described here https://github.com/mozilla/pdf.js/issues/14641
      //TODO: Remove the double render logic once we are able to upgrade the version of pdfjs-dist successfully
      renderTask.current[pageNumber] = page.render({
        canvasContext,
        viewport,
        transform,
      });

      return renderTask.current[pageNumber].promise.then(
        () => {
          renderTask.current[pageNumber] = page.render({
            canvasContext,
            viewport,
            transform,
          });
          return renderTask.current[pageNumber].promise.then(
            () => {
              renderTask.current[pageNumber] = null;

              if (isFunction(onPageRenderSuccessRef.current)) {
                onPageRenderSuccessRef.current(page);
              }
            },
            (err) => {
              renderTask.current[pageNumber] = null;

              /*
              // @ts-ignore typings are outdated
              if (err && err.name === 'RenderingCancelledException') {
                drawPDF(page, pageNumber);
              } else
              */
              if (isFunction(onPageRenderFailRef.current)) {
                onPageRenderFailRef.current(err);
              }
            },
          );
        },
        (err) => {
          renderTask.current[pageNumber] = null;

          /*
          // @ts-ignore typings are outdated
          if (err && err.name === 'RenderingCancelledException') {
            drawPDF(page, pageNumber);
          } else
          */
          if (isFunction(onPageRenderFailRef.current)) {
            onPageRenderFailRef.current(err);
          }
        },
      );
    };

    if (pdfDocument) {
      for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
        pdfDocument.getPage(pageNum).then(
          (loadedPdfPage: any) => {
            if (isFunction(onPageLoadSuccessRef.current)) {
              onPageLoadSuccessRef.current(loadedPdfPage);
            }

            drawPDF(loadedPdfPage, pageNum);
          },
          (err) => {
            if (isFunction(onPageLoadFailRef.current)) {
              onPageLoadFailRef.current(err);
            }
          },
        );
      }
    }
  }, [canvassesRef, height, numberOfPages, pdfDocument, rotate, scale, width]);
  return;
};
