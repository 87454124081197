import styled from 'styled-components';
import Button from '../../../atomic/atoms/Button';
import Body from '../../../atomic/atoms/Body';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import SpinningLoader from '../../../atomic/atoms/SpinnerLoader';

export const StyledActionBarWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledPostLoading = styled(Flex)`
  background-color: ${ThemeV2.palette.geekBlue2};
  width: 100%;
  margin: 0 auto 4px;
  border: 1px solid ${ThemeV2.palette.geekBlue6};
  border-radius: 4px;
  justify-content: space-between;
`;

export const StyledPostLoadingText = styled(Body)`
  margin: 12px 16px;
`;

export const StyledSpinningLoader = styled(SpinningLoader)`
  margin-right: 20px;
`;

export const ShowMoreButton = styled(Button)`
  position: sticky;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 2;
  margin-bottom: 8px;
`;
