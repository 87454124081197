import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { trackFeedEvent as trackFeedEventMetric } from '../../../Utils/analytics';
import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { defaultFlow } from '../../../Utils/flows';
import { FeedEventNameType } from '../../../Utils/analytics/interfaces';
import { RECOGNITION_FEEDS } from '../../../constants/routes';
import RecognitionFeedHeaderController from '../../flows/RecognitionFeedHeaderController';
import FullScreenController from '../../flows/FullScreenController';
import { useRecognitionController } from '../RecognitionController/useRecognitionController';

const RecognitionFullScreenController = () => {
  const { push } = useHistory();
  const { models, operations } = useRecognitionController();
  const { data, profileData, isLoading } = models;
  const { feedContextProps, refetch } = operations;
  const timestampOnLoad = useRef(Date.now());

  const trackFeedEvent = (action: FeedEventNameType, eventData?: any) => {
    trackFeedEventMetric(action, {
      flowId: defaultFlow?.flowId,
      flowName: defaultFlow?.name,
      ...eventData,
    });
  };

  const exitFullScreen = () => {
    const durationInMin = Math.floor(
      (Date.now() - timestampOnLoad.current) / 1000 / 60,
    );
    push(RECOGNITION_FEEDS);
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_EXITED, {
      fullscreenDuration: String(durationInMin),
    });
  };

  return (
    <FullScreenController
      header={
        <RecognitionFeedHeaderController
          isFullScreen
          onClick={exitFullScreen}
        />
      }
      isLoading={isLoading}
      profileData={profileData}
      posts={data}
      exitFullScreen={exitFullScreen}
      feedContextProps={feedContextProps}
      refetchFeed={refetch}
      trackFeedEvent={trackFeedEvent}
    />
  );
};

export default RecognitionFullScreenController;
