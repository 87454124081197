import React, { useMemo } from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../theme';

interface BodyVariant {
  fontSize: string;
  lineHeight: string;
  fontWeight: number;
  fontStyle: string;
}

export type TextAlignType =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';
interface BodyTextType extends BodyVariant {
  fontFamily: string;
  color: string;
  margin?: string;
  padding?: string;
  textAlign?: TextAlignType;
  overflowEllipsis?: boolean;
  overflow?: string;
  whiteSpace?: string;
  textOverflow?: string;
}

export interface BodyProps {
  children: React.ReactNode;
  className?: string;
  variant?: string;
  fontFamily?: string;
  color?: string;
  inline?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  'data-testid'?: string;
  'data-qa-id'?: string;
  margin?: string;
  padding?: string;
  overflowEllipsis?: boolean;
  textAlign?: TextAlignType;
}

export const variantMap: { [Key: string]: BodyVariant } = {
  body1: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  body1Bold: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontStyle: 'normal',
  },
  body1Medium: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  body1Italic: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'italic',
  },
  body2: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  body2Bold: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontStyle: 'normal',
  },
  body2Medium: {
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  body3: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  body3Bold: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontStyle: 'normal',
  },
  body3Medium: {
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  body4: {
    fontSize: '10px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  body4Bold: {
    fontSize: '10px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontStyle: 'normal',
  },
  body4Medium: {
    fontSize: '10px',
    lineHeight: '20px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  subHead1: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  subHead2: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  subHead2Bold: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: ThemeV2.typography.fontWeightBold,
    fontStyle: 'normal',
  },
  subHead2Medium: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
  subHead3: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontStyle: 'normal',
  },
  subHead4: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: ThemeV2.typography.fontWeightMedium,
    fontStyle: 'normal',
  },
};

const BodyText = styled.div`
  overflow: ${(props: BodyTextType) =>
    props.overflow ? props.overflow : props.overflowEllipsis && 'hidden'};
  white-space: ${(props: BodyTextType) =>
    props.whiteSpace ? props.whiteSpace : props.overflowEllipsis && 'nowrap'};
  text-overflow: ${(props: BodyTextType) =>
    props.textOverflow
      ? props.textOverflow
      : props.overflowEllipsis && 'ellipsis'};
  font-family: ${(props: BodyTextType) => props.fontFamily};
  font-size: ${(props: BodyTextType) => props.fontSize};
  font-weight: ${(props: BodyTextType) => props.fontWeight};
  line-height: ${(props: BodyTextType) => props.lineHeight};
  text-align: ${(props: BodyTextType) => props.textAlign};
  color: ${(props: BodyTextType) => props.color};
  ${(props) => props.padding && `padding: ${props.padding}`};
  ${(props) => props.margin && `margin: ${props.margin}`};

  & > code {
    padding: ${(props: BodyTextType) =>
      props.fontWeight === ThemeV2.typography.fontWeightMedium
        ? '4px 8px'
        : '2px 4px'};
    color: ${ThemeV2.palette.volcano6};
    background: ${ThemeV2.palette.gray2};
    border: 0.5px solid ${ThemeV2.palette.gray6};
    border-radius: 4px;
  }
`;

const Body = ({
  children,
  className: parentClassName,
  variant = 'body1',
  fontFamily = ThemeV2.typography.adminFontFamily,
  color = 'darkGray9',
  inline = false,
  disabled = false,
  style = undefined,
  'data-testid': dataTestId,
  'data-qa-id': dataQaId,
  padding,
  margin,
  overflowEllipsis,
  textAlign,
}: BodyProps) => {
  const variantFromMap = variantMap[variant];
  const { fontSize, fontWeight, fontStyle, lineHeight } = variantFromMap;
  const fontColor = disabled ? 'gray6' : color;
  const palette = ThemeV2.palette as unknown as {
    [Key: string]: string;
  };
  const paletteColor = palette[fontColor];

  const bodyStyle = useMemo(
    () => ({
      fontFamily,
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight,
      color: paletteColor,
      textAlign,
    }),
    [
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      lineHeight,
      paletteColor,
      textAlign,
    ],
  );

  return (
    <BodyText
      as={inline ? 'span' : 'div'}
      className={parentClassName}
      data-testid={dataTestId}
      data-qa-id={dataQaId}
      style={style}
      padding={padding}
      margin={margin}
      overflowEllipsis={overflowEllipsis}
      {...bodyStyle}
    >
      {children}
    </BodyText>
  );
};

const MemoizedBody = React.memo(Body);
MemoizedBody.displayName = 'Body';

export default MemoizedBody;
