import React, { useState, useCallback, useEffect, useMemo } from 'react';

import {
  REDEEM,
  REDEEM_SELECT_MODAL,
  SELECT_GIFT_CARD_VALUE,
} from '../../../languages/en/rewards';
import {
  getCarrotsForCurrencyExchange,
  getDropdownItems,
} from '../../../Utils/rewards';
import {
  GiftCardReward,
  RedeemPostPayloadType,
} from '../../../queries/Rewards/interfaces';
import { RATING_EXTERNAL_LINKS } from '../../../Utils/rewards/urls';
import { openInNewTab } from '../../../Utils/window';
import { MenuItemProps } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { getDisplayAmount } from '../../../Utils/rewards';
import AssemblyCurrencyIcon from '../../../atomic/atoms/AssemblyCurrencyIcon';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { USD } from '../../../constants/rewards';
import { FOR } from '../../../languages/en/singleWords';

const DEFAULT_CURRENCY_CODE = 'USD';

export interface RedeemModalControllerLogicProps {
  reward: GiftCardReward;
  exchangeRates: Record<string, number>;
  exchangeRate: number;
  onCloseRedeemModal: () => void;
  assemblyCurrency: AssemblyCurrency;
  redeemRewardMutate: (options: RedeemPostPayloadType) => void;
  redeemRewardReset: () => void;
  profileEmail: string;
  pointsEarned: number;
}

const useRedeemModalControllerLogic = (
  props: RedeemModalControllerLogicProps,
) => {
  const {
    reward,
    exchangeRates,
    exchangeRate,
    onCloseRedeemModal,
    assemblyCurrency,
    redeemRewardMutate,
    redeemRewardReset,
    profileEmail,
    pointsEarned,
  } = props;

  const initialDropdownItems: MenuItemProps[] = [];
  const [dropdownItems, setDropdownItems] = useState(initialDropdownItems);
  const [dropdownValue, setDropdownValue] = useState<string>(
    SELECT_GIFT_CARD_VALUE,
  );
  const [isRewardRated, setIsRewardRated] = useState(false);
  const [isRedeemButtonDisabled, setIsRedeemButtonDisabled] = useState(true);

  useEffect(() => {
    const formattedDropdownItems = getDropdownItems(
      reward.denominations,
      exchangeRates,
      exchangeRate,
      pointsEarned,
    );
    setDropdownItems(formattedDropdownItems);
  }, [exchangeRate, exchangeRates, pointsEarned, reward]);

  const handleRewardRatingChange = (newRating: number) => {
    if (newRating) {
      if (newRating > 3) {
        const randomIndex = Math.floor(
          Math.random() * RATING_EXTERNAL_LINKS.length,
        );
        openInNewTab(RATING_EXTERNAL_LINKS[randomIndex]);
      }
    }
    setIsRewardRated(true);
  };

  const assemblyTrophyIcon = useMemo(() => {
    return (
      <AssemblyCurrencyIcon
        assemblyCurrency={assemblyCurrency}
        currencyName={assemblyCurrency.name}
        id={assemblyCurrency.name}
        size="16px"
      />
    );
  }, [assemblyCurrency]);

  const getItemLabel = useCallback(
    (value) => {
      // Display format: $5 for 🏆 250
      const denomination = reward.denominations.find(
        (item) => item._id === value,
      );
      if (denomination) {
        const { faceValue } = denomination;
        const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;
        const amount = getDisplayAmount(faceValue, currencyCode);
        let currencyExchangeRate;

        if (currencyCode === USD) {
          currencyExchangeRate = 1;
        } else {
          currencyExchangeRate = exchangeRates[currencyCode];
        }

        const trophies = getCarrotsForCurrencyExchange(
          faceValue,
          exchangeRate,
          currencyExchangeRate,
        );

        return (
          <div>
            {amount} {FOR} {assemblyTrophyIcon}
            {trophies}
          </div>
        );
      }
      return value;
    },
    [assemblyTrophyIcon, exchangeRate, exchangeRates, reward],
  );

  const onDropdownSelect = useCallback(
    (dropdownItemId: number | string) => {
      const selectedDropdownItem = dropdownItems[0].items.find(
        (item) => item.id === dropdownItemId,
      );

      if (selectedDropdownItem && selectedDropdownItem.value) {
        setDropdownValue(selectedDropdownItem.value);
        setIsRedeemButtonDisabled(false);
      }
    },

    [dropdownItems],
  );

  const onRedeemClick = useCallback(() => {
    const denomination = reward.denominations.find(
      (item) => item._id === dropdownValue,
    );

    if (denomination) {
      const { faceValue, utid } = denomination;
      const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;

      let currencyExchangeRate;
      if (currencyCode === USD) {
        currencyExchangeRate = 1;
      } else {
        currencyExchangeRate = exchangeRates[currencyCode];
      }

      const trophies = getCarrotsForCurrencyExchange(
        faceValue,
        exchangeRate,
        currencyExchangeRate,
      );

      const options = {
        brandKey: reward.brandKey,
        rewardType: reward.rewardType,
        brandName: reward.brandName,
        currencyCode,
        carrotsRedeemed: trophies,
        utid,
        faceValue,
        temporaryEmail: profileEmail,
      };

      redeemRewardMutate(options);
    }
  }, [
    dropdownValue,
    exchangeRate,
    exchangeRates,
    profileEmail,
    redeemRewardMutate,
    reward,
  ]);

  const onClose = useCallback(() => {
    onCloseRedeemModal();
    redeemRewardReset();
  }, [onCloseRedeemModal, redeemRewardReset]);

  const modelMethods = {
    handleRewardRatingChange,
    onActionButtonClick: onRedeemClick,
    onDropdownSelect,
    onClose,
  };

  return {
    modelMethods,
    getItemLabel,
    id: reward.brandKey,
    rewardTitle: `${REDEEM} ${reward.brandName}?`,
    label: REDEEM_SELECT_MODAL,
    isRedeemButtonDisabled,
    rewardImage: reward.imageUrls['278w-326ppi'],
    isRewardRated,
    dropdownItems,
    dropdownValue,
  };
};

export default useRedeemModalControllerLogic;
