import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../theme';
import { StyledCard } from './styles';

const KnowledgeCenterCardLoader = () => {
  return (
    <StyledCard>
      <div style={{ width: '100%', height: '136px', overflow: 'hidden' }}>
        <ContentLoader
          speed={2}
          viewBox="0 0 244 136"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect width="244" height="136" />
        </ContentLoader>
      </div>
      <ContentLoader
        speed={2}
        height="68px"
        viewBox="0 0 240 68"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
      >
        <rect x="16" y="16" width="174" height="12" rx="4" />
        <rect x="36" y="40" width="97" height="12" rx="4" />
        <rect x="16" y="38" width="16" height="16" rx="8" />
      </ContentLoader>
    </StyledCard>
  );
};

const MemoizedKnowledgeCenterCardLoader = memo(KnowledgeCenterCardLoader);
MemoizedKnowledgeCenterCardLoader.displayName = 'KnowledgeCenterCardLoader';
export default MemoizedKnowledgeCenterCardLoader;
