import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../interfaces/PostMessage';
import { FLOW_UNARCHIVE_RESPONSE_MESSAGE } from '../../../languages/en/flows/feed';
import {
  useFetchArchivedFlows,
  useTriggerUnArchiveFlow,
} from '../../../queries/Flows/FlowFeedOptions/RunFlow';

import { getErrorMessage } from '../../../Utils/message';
import { Flex } from '../../../Utils/styles/display';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import { UnarchiveFlowsModal } from '../FlowsArchivedListController/UnarchiveFlowsModal';
import { mapFlowsArchivedResponseToNavItems } from '../FlowsArchivedListController/utils';

export const FlowsUnArchiveController = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const { data } = useFetchArchivedFlows();
  const { mutate: triggerUnArchiveFlow, isLoading: isUnArchiveModalLoading } =
    useTriggerUnArchiveFlow();
  const archiveFlowsNavItems: LeftDrawerCollapsibleNavItemProps[] = useMemo(
    () =>
      data
        ? data.data
            .filter((flow) => flow.isFlowOwner)
            .map(mapFlowsArchivedResponseToNavItems)
        : [],
    [data],
  );

  const currentArchivedFlow = useMemo(() => {
    return archiveFlowsNavItems.find((i) => i.id === flowId);
  }, [archiveFlowsNavItems, flowId]);

  return (
    <Flex>
      <UnarchiveFlowsModal
        isArchiveModalOpen
        isUnArchiveModalLoading={isUnArchiveModalLoading}
        onModalClose={() => {
          postMessageToMobileApp({
            action: AppAction.CloseModalStatus,
            targetEvent: AppTargetEvent.Mobile,
            payload: {
              type: 'closeModal',
            },
          });
        }}
        hideCloseButton
        onUnArchiveClick={() => {
          triggerUnArchiveFlow(
            { flowId: flowId || '' },
            {
              onError: (error: unknown) => {
                const errorMessage = getErrorMessage(
                  error as AxiosError<{ message: string; body: string }>,
                  FLOW_UNARCHIVE_RESPONSE_MESSAGE.ERROR,
                );
                showErrorMessage(
                  errorMessage || FLOW_UNARCHIVE_RESPONSE_MESSAGE.ERROR,
                );
              },
              onSuccess: () => {
                showSuccessMessage(FLOW_UNARCHIVE_RESPONSE_MESSAGE.SUCCESS);
                postMessageToMobileApp({
                  action: AppAction.CloseModalStatus,
                  targetEvent: AppTargetEvent.Mobile,
                  payload: {
                    type: 'closeModal',
                  },
                });
              },
            },
          );
        }}
        unArchivingFlowDetails={currentArchivedFlow}
      />
    </Flex>
  );
};
