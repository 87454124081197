import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import Heading from '../../atoms/Heading';

export const StyledFlex = styled(Flex)`
  border-radius: 4px;
  box-shadow: ${ThemeV2.styles.boxShadow};
  background: ${ThemeV2.palette.gray1};
  width: 100%;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 8px 0 0;
`;
