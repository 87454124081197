import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from 'react-responsive';

import startConversation from '../../../img/svgs/start_conversation.svg';

import Body from '../../../atomic/atoms/Body';
import PostInput from '../../../atomic/organism/PostInput';
import Comment from '../../../atomic/organism/Comment/newIndex';
import ProfileChatHeader from '../../../atomic/molecules/ProfileChatHeader';
import MembersListError from '../../../atomic/organism/AssemblyMembers/Error';
import { DropdownPlacement } from '../../../atomic/molecules/Dropdown_V2/interfaces';

import { isMobilePlatform } from '../../../Utils/window';
import useChatController from './useChatController';
import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';

import {
  EmptyChatWrapper,
  ChatPostInputWrapper,
  InfiniteScrollWrapper,
} from './styles';

import { CommentsLoader } from '../RepliesRightDrawerController/Post/Loader';
import useMobileApp from '../../../hooks/useMobileApp';
import useLayoutStore from '../../../stores/layoutStore';

const ChatController = () => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { isMobileApp } = useMobileApp();
  const {
    chats,
    hasNextPage,
    footerHeight,
    currentUserId,
    fetchNextPage,
    chatsContainer,
    selectedProfile,
    isFetchingChats,
    assemblyCurrency,
    commentInputProps,
    isCurrentUserAdmin,
    handleOnCloseClick,
    chatInputContainer,
    handleOnMemberClick,
    handleOnCopyChatLink,
    handleOnEmotionSelect,
    handleOnReactionToggle,
    isMembersDetailsLoading,
    handleOnCopyProfileLink,
    handleOnViewProfileClick,
    isMembersDetailsLoadError,
    handleOnChatMessageDelete,
  } = useChatController();

  const { isEmbeddedInMainApp } = useLayoutStore();

  if (isMembersDetailsLoadError) {
    return <MembersListError />;
  }

  return (
    <>
      <ProfileChatHeader
        isMobileView={isMobileView}
        profile={selectedProfile}
        onCloseClick={handleOnCloseClick}
        isLoading={isMembersDetailsLoading}
        onViewProfileClick={handleOnViewProfileClick}
        onCopyProfileLinkClick={handleOnCopyProfileLink}
      />

      {isFetchingChats && <CommentsLoader />}

      <InfiniteScrollWrapper
        ref={chatsContainer}
        id="chat-scrollable-div"
        flexDirection="column-reverse"
        footerHeight={footerHeight}
        isMobileApp={isMobileApp}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
      >
        {(!chats || chats.length === 0) && !isFetchingChats && (
          <Flex flexDirection="column">
            <img src={startConversation} />
            <EmptyChatWrapper flexDirection="column">
              <Body variant="body1Bold" color="gray">
                Start the conversation
              </Body>
              <Body variant="body2" color="gray">
                Write {selectedProfile?.firstName} a message below 👇
              </Body>
            </EmptyChatWrapper>
          </Flex>
        )}
        {currentUserId && assemblyCurrency && chats && chats.length > 0 && (
          <InfiniteScroll
            inverse
            style={{
              width: '100%',
              display: 'flex',
              paddingTop: '16px',
              flexDirection: 'column-reverse',
            }}
            next={fetchNextPage}
            hasMore={!!hasNextPage}
            loader={<CommentsLoader />}
            dataLength={chats.length}
            scrollableTarget="chat-scrollable-div"
          >
            {chats.map((chat) => (
              <Comment
                comment={chat}
                showChatView
                isDummy={false}
                canDelete={false}
                variant="sidebar"
                key={chat.commentID}
                currentUserId={currentUserId}
                onCopyLink={handleOnCopyChatLink}
                onMemberClick={handleOnMemberClick}
                assemblyCurrency={assemblyCurrency}
                isCurrentUserAdmin={isCurrentUserAdmin}
                onEmoticonSelect={handleOnEmotionSelect}
                onDeleteComment={handleOnChatMessageDelete}
                onReactionToggleClick={handleOnReactionToggle}
                optionsDropDownPlacement={DropdownPlacement.BottomEnd}
              />
            ))}
          </InfiniteScroll>
        )}
      </InfiniteScrollWrapper>

      <ChatPostInputWrapper ref={chatInputContainer}>
        <PostInput
          {...commentInputProps}
          showChatView
          variant="sidebar"
          isTasksButtonHidden
          showAddTrophy={false}
          hasText={isMobilePlatform ? true : commentInputProps.hasText}
        />
      </ChatPostInputWrapper>
    </>
  );
};

export default ChatController;
