import React from 'react';
import { useHistory } from 'react-router-dom';
import LoadingPulse from '../../atomic/atoms/LoadingPulse';
import Body from '../../atomic/atoms/Body';
import SSOInfoCard from '../../atomic/pages/SSOInfoCard';

import useSAMLAuthLogic from './useSAMLAuthLogic';
import {
  ButtonVariants,
  ButtonStatuses,
  ButtonColors,
} from '../../atomic/atoms/Button/interfaces';
import { getSAMLErrorData, SAMLAuthErrorCodes } from './data';
import RoutesList from '../../routesList';
import { Flex } from '../../Utils/styles/display';
import { Navbar } from '../../atomic/molecules/Navbar';
import { Grid } from '@mui/material';

const SAMLAuthController = () => {
  const { loadingText, errorCode } = useSAMLAuthLogic();
  const { push } = useHistory();
  if (errorCode) {
    const { headerText, bodyText, buttonText, buttonIcon, urlToPush } =
      getSAMLErrorData(errorCode);
    let buttonProps: {
      variant?: ButtonVariants;
      status?: ButtonStatuses;
      colour?: ButtonColors;
    } = {};
    let handleButtonClick = () => {
      push(urlToPush);
    };
    if (errorCode === SAMLAuthErrorCodes.ADMIN_APPROVAL_REQUESTED) {
      buttonProps = {
        variant: 'text',
        status: 'success',
        colour: 'secondary',
      };
      handleButtonClick = () => {};
    }
    const subText = {
      text: '',
      linkText: 'Return to sign in',
      linkPath: RoutesList.SIGN_IN,
    };
    return (
      <Flex height="100vh" flexDirection="column">
        <Navbar />
        <SSOInfoCard
          headingText={headerText}
          bodyText={bodyText}
          buttonOnClick={handleButtonClick}
          buttonText={buttonText}
          buttonIcon={buttonIcon || undefined}
          subText={subText}
          buttonProps={buttonProps || undefined}
        />
      </Flex>
    );
  }
  return (
    <Grid container justifyContent="center" style={{ marginTop: 90 }}>
      <Body variant="body2Bold">{loadingText}</Body>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default SAMLAuthController;
