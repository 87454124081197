import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { useLocation } from 'react-router-dom';

import useLayoutStore from '../../stores/layoutStore';
import { useHistory } from 'react-router';

const useChats = () => {
  const history = useHistory();
  const { search } = useLocation();

  const profileId = new URLSearchParams(search).get('profileId') || '';
  const showChats =
    new URLSearchParams(search).get('showDirectMessage') === 'true';

  const { setSelectedMember, setShowChats } = useLayoutStore(
    (state) => ({
      setSelectedMember: state.setSelectedMember,
      setShowChats: state.setShowChats,
    }),
    shallow,
  );

  useEffect(() => {
    if (showChats && profileId) {
      setSelectedMember({
        memberId: profileId,
      });
      setShowChats(true);
    }
  }, [search, history, showChats, profileId, setShowChats, setSelectedMember]);
};

export default useChats;
