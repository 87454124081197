import styled from 'styled-components';
import { device } from '../../../constants/layout';

export const StyledRewardsHeader = styled.div`
  display: flex;
  flex-flow: wrap;
`;

export const StyledRewardsHeaderSearchContainer = styled.div<{
  isLeftAsideOpen: boolean | undefined;
  isRightAsideOpen: boolean | undefined;
}>`
  flex-grow: 1;
  @media ${device.mobile} {
    width: 100%;
    margin-bottom: 8px;
  }
  @media (max-width: 1556px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 100%;
        margin-bottom: 8px;`
      );
    }}
  }
  @media (max-width: 1248px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 100%;
        margin-bottom: 8px;`
      );
    }}
  }
  @media (max-width: 1220px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 100%;
        margin-bottom: 8px;`
      );
    }}
  }
  @media (max-width: 912px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 100%;
        margin-bottom: 8px;`
      );
    }}
  }
`;

export const StyledRewardFilterContainer = styled.div<{
  isLeftAsideOpen: boolean | undefined;
  isRightAsideOpen: boolean | undefined;
}>`
  margin-left: 8px;
  width: 264px;

  #spring-popper {
    width: 100%;
  }

  @media ${device.mobile} {
    width: 48%;
    margin-right: 4%;
    margin-left: 0;
  }
  @media (max-width: 1556px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 48%;
        margin-right: 4%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 1248px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 48%;
        margin-right: 4%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 1220px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 48%;
        margin-right: 4%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 912px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 48%;
        margin-right: 4%;
        margin-left: 0;`
      );
    }}
  }

  [role='textbox'] {
    @media ${device.mobile} {
      max-width: 50%;
    }
  }
`;

export const StyledRewardSortContainer = styled.div<{
  isLeftAsideOpen: boolean | undefined;
  isRightAsideOpen: boolean | undefined;
}>`
  width: 264px;
  margin-left: 8px;

  @media ${device.mobile} {
    width: 48%;
    margin-left: 0;
  }
  @media (max-width: 1556px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 48%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 1248px) {
    ${(props) => {
      return (
        props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 48%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 1220px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        props.isRightAsideOpen &&
        `width: 48%;
        margin-left: 0;`
      );
    }}
  }
  @media (max-width: 912px) {
    ${(props) => {
      return (
        !props.isLeftAsideOpen &&
        !props.isRightAsideOpen &&
        `width: 48%;
        margin-left: 0;`
      );
    }}
  }

  [role='textbox'] {
    max-width: 150px;
    @media ${device.mobile} {
      max-width: 50%;
    }
  }
`;
