import styled from 'styled-components';

import ThemeV2 from '../../../../theme';

export const SlackMessageWrapper = styled.section`
  padding: 12px;
  margin-top: 16px;
  border-radius: 8px;
  border: 1px solid ${ThemeV2.palette.lightGray5};
`;
