import styled from 'styled-components';
import { FlexCSS } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../theme';
import Body from '../../../../atomic/atoms/Body';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import Button from '../../../../atomic/atoms/Button';

export const StyledShareSheetBody = styled(Body)`
  visibility: visible;
  min-height: 20px;
  transition: all 0.4s;
`;

export const StyledShareSheetSuffixSVGIcon = styled(SVGIcon)`
  transition: all 0.4s;
`;

export const StyledShareSheetButton = styled.button`
  ${FlexCSS};
  justify-content: space-between;
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  border: 1px solid ${ThemeV2.palette.gray4};
  box-sizing: border-box;
  border-radius: 4px;
  text-align: unset;
  padding: 8px;
  cursor: pointer;
  margin-bottom: 12px;
  transition: all 0.4s;
  &:hover {
    background: ${ThemeV2.palette.geekBlue1};
    border-color: ${ThemeV2.palette.geekBlue6};
    ${StyledShareSheetBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }
    ${StyledShareSheetSuffixSVGIcon} {
      svg {
        path {
          fill: ${ThemeV2.palette.geekBlue6};
        }
      }
    }
  }
`;

export const StyledShareSheetSVGIcon = styled(SVGIcon)`
  margin-right: 8px;
  margin-top: 4px;
`;

export const ShareSheetSettings = styled.div`
  cursor: pointer;
`;

export const AccessSettingsIcon = styled(SVGIcon)`
  margin-top: 5px;
  svg > path {
    fill: currentColor;
  }
`;

export const ChangeLinkSettingsButton = styled(Button)`
  margin-top: 26px;
`;

export const ChangeResponseSettingsButton = styled(Button)`
  margin-top: 21px;
`;

export const LinkAccessLabel = styled.span`
  font-size: 14px;
  margin-left: 4px;
  line-height: 22px;
  font-style: normal;
  color: ${ThemeV2.palette.gray9};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
`;

export const ChangeLinkSettingsAppButton = styled(Button)`
  padding: 0;
`;

export const ResponseSettingsAppButton = styled(Button)`
  padding: 0;
  margin-top: -16px;
`;
