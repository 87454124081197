import styled from 'styled-components';
import Popover from '@mui/material/Popover';

import { StyledButton } from '../../../Utils/styles/button';

import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import DropdownIconButton from '../DropdownIconButton';
import ThemeV2 from '../../../theme';
import { device } from '../../../constants/layout';
import { windowSizeAdapter } from '../../../Utils/window';
import Theme from '../../../theme';

export const StyledFlowsFeedHeaderMainContent = styled.div`
  margin-left: 8px;
  @media ${device.mobile} {
    display: flex;
    align-items: center;
  }
`;

export const FeedHeaderMainContentInnerWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledHeading = styled(Heading)<{
  isMobileApp: boolean;
  isRightAsideOpen: boolean;
}>`
  margin: 0 4px 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ isMobileApp, isRightAsideOpen }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: 'calc(100vw - 240px);',
      mobileWeb: 'calc(100vw - 228px);',
      tablet: 'calc(100vw - 230px);',
      desktop: isRightAsideOpen ? 'calc(100vw - 700px);' : '100%;',
    })};
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 8px;
`;

export const Root = styled.div<{
  borderBottom?: boolean;
  isRightAsideOpen: boolean;
  isFullScreen?: boolean;
  isMobileView?: boolean;
  paddingLeft?: string;
}>`
  display: grid;
  position: ${({ isFullScreen }) => (isFullScreen ? 'fixed' : 'auto')};
  grid-template-columns: 48px 1fr auto;
  width: 100%;
  min-width: 100%;
  max-height: 80px;
  padding: ${({ isRightAsideOpen, isMobileView }) =>
    isRightAsideOpen || isMobileView ? '16px 16px' : '16px 40px 16px 16px'};
  border-bottom: ${({ borderBottom }) =>
    borderBottom && `1px solid ${ThemeV2.palette.gray4}`};
  transition: all 0.5s;
  z-index: 1;
  background-color: ${ThemeV2.palette.white};
  padding-left: ${({ paddingLeft }) => paddingLeft && paddingLeft};
`;

export const StyledButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LinkButton = styled(StyledButton)<{ $fontSize?: string }>`
  text-decoration: underline;
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '12px')};
`;

export const FlowsDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledBody = styled(Body)`
  max-width: 30vw;
  display: block;
  white-space: nowrap; /* forces text to single line */
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PopoverBody = styled(Body)`
  max-width: 100%;
  display: flex;
`;

export const CopyLinkButton = styled(Button)`
  svg {
    path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledPopover = styled(Popover)`
  pointer-events: none;
  & .MuiPopover-paper {
    pointer-events: auto;
    box-shadow: none;
    border: 1px solid ${ThemeV2.palette.gray4};
    padding: 8px 12px;
  }
`;

export const RequestedUsersCountContainer = styled.span`
  position: absolute;
  width: 24px;
  height: 20px;
  top: -8px;
  right: -8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Theme.palette.volcano6};
  border-radius: 10px;
  border: 1px solid ${Theme.palette.gray1};
`;
