import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import { Icon } from '../../queries/Flows/Feed/interfaces';
import { ShareRequestsProps } from '../../queries/Flows/Sharesheet';
import { FeedItemKind } from '../Feed';
import { PostTypes } from '../Home';
import { Profile, User } from '../user';

export enum NotificationTypes {
  POST = 'post',
  TAGGED_IN_POST = 'tagged_in_post',
  TAGGED_IN_COMMENT = 'tagged_in_comment',
  COMMENT = 'comment',
  LIKE = 'like',
  PLATFORM = 'platform',
  FLOW_TRIGGERED = 'flow_triggered',
  FLOW_REMINDER = 'flow_reminder',
  MENTIONS = 'mentions',
  FLOW_RESPONSE = 'flow_response',
  TASK_ACTIVITY = 'task_activity',
  SHARE_REQUEST_CREATED = 'SHARE_REQUEST_CREATED',
  SHARE_REQUEST_APPROVED = 'SHARE_REQUEST_APPROVED',
}

export enum CommentTypes {
  THREAD_COMMENT = 'THREAD_COMMENT',
}
interface ProfileWithImage extends Profile {
  image: {
    original: {
      relativeUrl: string;
    };
    resized?: {
      relativeUrl: string;
    };
  };
}

export interface Comment {
  carrots: number;
  message: string;
  taggedUsers: User[];
  _id: string;
}

interface Post {
  message: string;
  type: PostTypes;
  taggedUsers: User[];
  _id: string;
}

export enum FromUserStates {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
  PENDING = 'PENDING',
}

interface Flow {
  icon: Icon;
  _id: string;
  name: string;
  description: string;
}

interface FlowDetails {
  flow: Flow;
  occurrence: string;
}

interface TaskActivityDetails {
  taskActivity: {
    activity: string;
    content: {
      after: {
        other: string;
      };
    };
    notebookId: {
      _id: string;
      title: [
        {
          type: string;
          value: string;
        },
      ];
    };
  };
}

export interface ResponseId {
  _id: string;
  kind: FeedItemKind;
}

export interface Notification {
  isRead: boolean;
  carrots: number;
  isDeleted: boolean;
  type: NotificationTypes;
  updatedAt: string;
  post?: Post;
  user?: User;
  _id: string;
  from?: {
    // isDeleted is deprecated: https://joinassembly.slack.com/archives/CDMQWLTNX/p1623251877063900
    isDeleted: boolean;
    profile: ProfileWithImage;
    state: FromUserStates;
    _id: string;
  };
  createdAt: string;
  comment?: Comment;
  flowDetails?: FlowDetails;
  responseId?: string | ResponseId;
  commentId?: string;
  isAnonymous?: boolean;
  metaData?: {
    unreadCount: number;
    startId: string;
    commentType: CommentTypes;
  };
  taskActivityDetails?: TaskActivityDetails;
  flowShareRequestDetails?: {
    requestedBy: User;
    requestedForMembers: User[];
  };
  shareRequestDetails?: {
    shareRequestId: {
      _id: string;
      stateInfo: {
        accepted: {
          actionAt: string;
          actionBy: string;
          source: 'SHARE_BUTTON';
          requestPermission: ShareRequestsProps['requestPermission'];
        };
      };
    };
  };
}

export interface FormattedNotifications {
  read: boolean;
  timeAgo: string;
  headerText: string;
  bodyText: string;
  id: string;
  avatar: AvatarProps;
  icon?: string;
  postID: string;
  type: NotificationTypes;
  emoticon?: string;
  flowId?: string;
  occurrenceId?: string;
  responseId?: string | ResponseId;
  commentId?: string;
  taskId?: string;
  metaData?: {
    commentType: CommentTypes;
  };
}
