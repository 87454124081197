import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'qs';

import {
  FeedItemFromAPI,
  FeedItemMetaData,
  PostSortOptions,
  PostSortValues,
} from '../../../../interfaces/Feed';
import { useGetMainFeedQuery } from '../../../../queries/MainFeed';
import useToggle from '../../../../hooks/useToggle';
import { FEED_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';

import { useFetchFlowActive } from '../../../../queries/Flows/Dashboard';
import useFlowFeed from '../../../../hooks/useFlowFeed';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import { FLOWS, RECOGNITION_PARTICIPATE } from '../../../../constants/routes';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import {
  useMainFeedUpdateFlowPostReactionMutation,
  useMainFeedUpdatePostReactionMutation,
} from '../../../../queries/Feed';
import { PostTypes } from '../../../../interfaces/Home';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../../../interfaces/PostMessage';
import useReactionUpdatedSocket from '../../../../hooks/useReactionUpdatedSocket';
import { PageType } from '../../../../hooks/useReactionUpdatedSocket/types';
import usePostInteractionSettingUpdatedSocket from '../../../../hooks/usePostInteractionSettingUpdatedSocket';
import { useReactionsSocket } from '../../../../hooks/useReactionsSocket';

export const useMainFeedController = () => {
  const history = useHistoryWrapper();
  const { search } = useLocation();
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const { filter } = useGetParsedFilterOptions();
  const [feedsSortValue, setFeedsSortValue] = useState<PostSortOptions>(
    PostSortOptions.DESC,
  );

  const { mutate: mutateFlowPostReaction } =
    useMainFeedUpdateFlowPostReactionMutation({
      feedsSort: feedsSortValue,
      filter,
    });
  const { mutate: mutatePostReaction } = useMainFeedUpdatePostReactionMutation({
    feedsSort: feedsSortValue,
    filter,
  });

  const {
    feedContextProps,
    onFlowClick,
    isProfileInfoLoading,
    profileData,
    setShowLoadMoreButtonToFalse,
    setShowLoadMoreButtonToTrue,
    showLoadMoreButton,
  } = useFlowFeed({ mutateFlowPostReaction, mutatePostReaction });

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        feedSort: PostSortValues[feedsSortValue],
      });
    },
    [feedsSortValue],
  );

  const openRecognitionFlowModalOnLoad =
    parsedParams && parsedParams?.openRecognitionFlowModalOnLoad === 'true';

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const handleFeedSortClick = useCallback(
    (val: string | number) => {
      setFeedsSortValue(val as PostSortOptions);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SORTED);
    },
    [setFeedsSortValue, trackFeedEvent],
  );

  const handleEditPostClick = useCallback(
    (flow: FeedItemMetaData) => {
      if (flow.type === PostTypes.FLOW) {
        const editPostUrl = `${FLOWS}/${flow.flowId}/edit/${flow.responseId}?redirectUrl=${location.pathname}`;
        history.push(editPostUrl);
      } else {
        const editPostUrl = `${FLOWS}/recognition/edit/${flow.postId}?redirectUrl=${location.pathname}`;
        history.push(editPostUrl);
      }
    },
    [history],
  );

  const { data: activeFlowsData } = useFetchFlowActive();
  const activeFlows = activeFlowsData?.data;

  const {
    data,
    error: mainFeedDataFetchError,
    refetch,
    isInitialLoading: isMainFeedLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetMainFeedQuery({
    feedsSort: feedsSortValue,
    filter,
  });

  const handleFetchNextPage = useCallback(() => {
    fetchNextPage();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextPage, trackFeedEvent]);

  const isLoading = isMainFeedLoading || isProfileInfoLoading;

  const feedProps = useMemo(() => {
    if (!isLoading) {
      postMessageToMobileApp({
        action: AppAction.FeedLoaded,
        payload: { type: 'MainFeed' },
      });
    }

    if (!data) {
      return [];
    }
    return data.pages.reduce<FeedItemFromAPI[]>(
      (arr, page) => [...arr, ...page.data],
      [],
    );
  }, [data, isLoading]);

  useCommonFeedSocket({
    type: WebSocketFeedTypes.MAIN,
    setMainFeedShowLoadMoreButtonToTrue: setShowLoadMoreButtonToTrue,
    onMainFeedFlowResponseDelete: refetch,
    onMainFeedPostDeleted: refetch,
    feedProps,
    refetchMainFeed: refetch,
  });

  useReactionsSocket();
  useReactionUpdatedSocket({
    pageType: PageType.MainFeed,
    sortValue: feedsSortValue,
    filter: filter,
  });
  usePostInteractionSettingUpdatedSocket({
    pageType: PageType.MainFeed,
    sortValue: feedsSortValue,
    filter: filter,
  });

  useEffect(() => {
    if (mainFeedDataFetchError) {
      postMessageToMobileApp({
        action: AppAction.FeedErrored,
        payload: {
          type: 'mainfeed',
        },
      });
    }
  }, [mainFeedDataFetchError]);

  useEffect(() => {
    if (openRecognitionFlowModalOnLoad) {
      history.push(
        `${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`,
      );
    }
  }, [
    history,
    openRecognitionFlowModalOnLoad,
    toggleIsRecognitionFlowModalOpen,
  ]);

  return {
    models: {
      isLoading,
      activeFlows,
      hasNextPage,
      profileData,
      feedsSortValue,
      data: feedProps,
      isFetchingNextPage,
      showLoadMoreButton,
      isRecognitionFlowModalOpen,
    },
    operations: {
      refetch,
      handleFetchNextPage,
      onFlowClick,
      handleFeedSortClick,
      setShowLoadMoreButtonToFalse,
      toggleIsRecognitionFlowModalOpen,
      feedContextProps,
      handleEditPostClick,
    },
  };
};
