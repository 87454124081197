import React from 'react';
import { shallow } from 'zustand/shallow';
import Button from '../../atoms/Button';
import DateFilter from '../DateFilter';
import DrawerHeaderWithIcon from '../../molecules/DrawerHeaderWithIcon';
import CheckboxFilter from '../CheckboxFilter';
import {
  StyledFilterWrapper,
  StyledSpaceSeparator,
  StyledButton,
  StyledFlex,
  StyledScrollWrapper,
} from './styles';

import { DateFilterLoadingProps } from '../DateFilter/types';
import { CheckboxFilterLoadingProps } from '../CheckboxFilter/types';

import {
  FILTER_BY,
  APPLY_FILTERS,
  POSTED_BY,
} from '../../../languages/en/flows';
import {
  DATES,
  BLOCK,
  FLOW,
  MENTIONED,
  CLEAR,
} from '../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../Utils/text';

import { ComponentStatus } from '../../../interfaces/component';
import useLayoutStore from '../../../stores/layoutStore';
import useKioskStore from '../../../stores/kioskStore';
import useMobileApp from '../../../hooks/useMobileApp';

type LoadingComponentTypes = {
  isTab?: boolean;
  isMainFeedFilterLoading?: boolean;
  isFlowFeedFilterLoading?: boolean;
  isProfileFeedFilterLoading?: boolean;
  isKnowledgeCenterFilterLoading?: boolean;
};

const LoadingComponent = (props: LoadingComponentTypes) => {
  const {
    isTab = true,
    isMainFeedFilterLoading = false,
    isFlowFeedFilterLoading = false,
    isProfileFeedFilterLoading = false,
  } = props;
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const { isMobileApp } = useMobileApp();
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const dateFilterProps: DateFilterLoadingProps = {
    status: ComponentStatus.LOADING,
    headingText: DATES,
  };

  const flowFilterProps: CheckboxFilterLoadingProps = {
    status: ComponentStatus.LOADING,
    headingText: capitalizeFirstLetter(FLOW),
  };

  const blockFilterProps: CheckboxFilterLoadingProps = {
    status: ComponentStatus.LOADING,
    headingText: BLOCK,
  };

  const postedByFilterProps: CheckboxFilterLoadingProps = {
    status: ComponentStatus.LOADING,
    headingText: POSTED_BY,
  };

  const mentionedByFilterProps: CheckboxFilterLoadingProps = {
    status: ComponentStatus.LOADING,
    headingText: MENTIONED,
  };

  return (
    <StyledScrollWrapper isTab={isTab} isMobileApp={isMobileApp}>
      <div>
        <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
          {FILTER_BY}{' '}
        </DrawerHeaderWithIcon>
        <StyledFilterWrapper>
          <DateFilter {...dateFilterProps} />
          <StyledSpaceSeparator />
          {(isMainFeedFilterLoading || isProfileFeedFilterLoading) && (
            <CheckboxFilter {...flowFilterProps} />
          )}
          <StyledSpaceSeparator />
          <StyledSpaceSeparator />
          {isFlowFeedFilterLoading && <CheckboxFilter {...blockFilterProps} />}
          <StyledSpaceSeparator />
          {(isMainFeedFilterLoading || isFlowFeedFilterLoading) && (
            <CheckboxFilter {...postedByFilterProps} />
          )}
          <StyledSpaceSeparator />
          <CheckboxFilter {...mentionedByFilterProps} />
          <StyledSpaceSeparator />
        </StyledFilterWrapper>
      </div>
      <StyledFlex
        isKioskTurnedOn={isKioskTurnedOn}
        isRightAsideOpen={isRightAsideOpen}
        justifyContent="center"
      >
        <StyledButton
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          isLoading
        >
          {APPLY_FILTERS}
        </StyledButton>
        <Button
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          variant="text"
        >
          {CLEAR}
        </Button>
      </StyledFlex>
    </StyledScrollWrapper>
  );
};

export default LoadingComponent;
