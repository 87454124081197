import { useEffect } from 'react';

import {
  useMyRewardsFetchQuery,
  useMyRewardsHistoryFetchQuery,
  useResendMailMutation,
} from '../../../queries/Rewards';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';

import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
  dismissAllToasts,
} from '../../../Utils/toast';

const useMyRewardsControllerModel = () => {
  const {
    data: myRewardsData,
    isInitialLoading: isMyRewardsDataLoading,
    isError: isMyRewardsDataError,
  } = useMyRewardsFetchQuery();

  const {
    data: profileData,
    isInitialLoading: isProfileDataLoading,
    isError: isProfileDataError,
  } = useProfileInfoFetchQuery();

  const {
    data: rewardsHistory,
    isInitialLoading: isRewardsHistoryLoading,
    isError: isRewardsHistoryError,
  } = useMyRewardsHistoryFetchQuery();

  const {
    mutate: resendEmailForMyRewards,
    isLoading: isResendMailLoading,
    isSuccess: isResendMailSuccess,
    isError: isResendMailError,
  } = useResendMailMutation();

  const isLoading =
    isMyRewardsDataLoading || isProfileDataLoading || isRewardsHistoryLoading;

  const isError =
    isMyRewardsDataError || isProfileDataError || isRewardsHistoryError;

  useEffect(() => {
    if (isResendMailLoading) {
      showInfoMessage('Processing..');
    }
    if (isResendMailSuccess) {
      dismissAllToasts();
      showSuccessMessage('Mail/Reminder Sent!');
    }
    if (isResendMailError) {
      dismissAllToasts();
      showErrorMessage('Error occurred. Please try again!');
    }
  }, [isResendMailError, isResendMailLoading, isResendMailSuccess]);

  return {
    models: {
      rewardsHistory,
      assemblyCurrency: profileData?.assembly.currency,
      myRewardsData,
    },
    metadata: {
      isLoading,
      isError,
    },
    actions: {
      resendEmailForMyRewards,
    },
  };
};

export default useMyRewardsControllerModel;
