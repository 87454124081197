import { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Drawer, Popover } from '@mui/material';

import { CreateTaskInput } from '../../../atomic/organism/Notebook/CreateTaskInput';
import { device } from '../../../constants/layout';
import { useStyles } from './styles';
import { TaskOptionsContext } from '../taskOptionsContext';

export const CreateTaskController = () => {
  const {
    anchorElForTasksDropdown,
    handleCloseCreateTaskInput,
    handleNewTaskSubmit,
    handleNewTaskValueChange,
    handleNewTaskValueClear,
    isCreateTaskInputOpen,
    newTaskValue,
  } = useContext(TaskOptionsContext);

  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });

  const commonContainerProps = {
    onClose: handleCloseCreateTaskInput,
    open: isCreateTaskInputOpen,
  };

  const classes = useStyles();

  const inputComponent = (
    <CreateTaskInput
      onChange={handleNewTaskValueChange}
      onClear={handleNewTaskValueClear}
      onSubmit={handleNewTaskSubmit}
      value={newTaskValue}
    />
  );

  if (isDesktopView) {
    return (
      <Popover
        {...commonContainerProps}
        PaperProps={{
          style: {
            padding: '8px',
          },
        }}
        anchorEl={anchorElForTasksDropdown.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        className={classes.root}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      >
        {inputComponent}
      </Popover>
    );
  }

  const screenHeight = window.visualViewport
    ? window.visualViewport.height
    : window.screen.height;
  const adjustedHeight = screenHeight - 56; // Leave room at the top of the screen to close

  return (
    <Drawer
      {...commonContainerProps}
      PaperProps={{
        style: { maxHeight: `${adjustedHeight}px`, padding: '8px' },
      }}
      anchor="bottom"
    >
      {inputComponent}
    </Drawer>
  );
};
