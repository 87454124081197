import styled from 'styled-components';
import { device } from '../../../constants/layout';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';

export const ToggleButtonWrapper = styled.div`
  display: flex;
  & button {
    margin-right: 8px;
  }
`;
ToggleButtonWrapper.displayName = 'ToggleButtonWrapper';

export const DrawerHeading = styled(Heading)`
  margin: 0;
`;
DrawerHeading.displayName = 'DrawerHeading';

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;
NotificationHeader.displayName = 'NotificationHeader';

export const NotificationContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 600px;
  padding: 0 12px 0 12px;
  @media ${device.tabletOrDesktop} {
    padding: 0px 16px 0 16px;
  }
  @media ${device.mobile} {
    height: calc(100vh - 100px);
  }
`;

export const NotificationWrapper = styled.div`
  height: 100%;
  overflow: auto;
`;

export const NotificationDropdownContainer = styled.div`
  height: 100%;
  @media ${device.mobile} {
    top: 0;
  }
`;

export const NotificationErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
`;

NotificationErrorContainer.displayName = 'NotificationErrorContainer';

export const NotificationErrorImage = styled(Image)`
  margin: 56px 0 16px 0;
`;

export const ContentWrapper = styled.div`
  padding: 16px;
  @media ${device.tablet} {
    padding: 16px;
  }
`;
