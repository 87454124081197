import React from 'react';
import reactStringReplace from 'react-string-replace';
import { Emoji } from 'emoji-mart';
import _ from 'lodash';
import titleize from 'titleize';
import { CurrentUserProfile } from '../interfaces/currentUser';

export const MENTION_REGEX = /@__(.*?)__@/;
export const MENTION_REGEX_GLOBAL = /@__(.*?)__@/g;
export const URL_REGEX = /(https?:\/\/?\S+|www\.\S+)/gi;
export const DOMAIN_REGEX =
  /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g;
export const ALPHABETS_WITH_SPACES_REGEX = /^[a-zA-Z\s]*$/;

export const getProfileFullName = (
  user:
    | {
        profile: {
          firstName: string;
          lastName: string;
        };
      }
    | null
    | undefined,
) => {
  if (user && user.profile) {
    return `${user.profile.firstName} ${user.profile.lastName}`;
  }
  return '';
};

export const isValidDomain = (value: string) => {
  return !!value.match(DOMAIN_REGEX);
};

export const capitalizeFirstLetter = (value: string | Date | undefined) => {
  if (!value) {
    return '';
  }

  return titleize(value.toString());
};

export const httpRegExp = new RegExp(`((http(s?))\\://)`, 'gm');

export const capitalizeFirstLetterInText = (text: string) =>
  text.replace(/./, (c) => c.toUpperCase());

export const singularizeWord = (value: string) => {
  const word = value.split('');
  return word.slice(0, -1).join('');
};

export const getTaggedUsersFromMessage = (
  message: any,
  taggedUsers: any,
  isNotification = false,
) => {
  let replacedText = message;
  if (taggedUsers?.length > 0) {
    replacedText = reactStringReplace(replacedText, MENTION_REGEX, (match) => {
      const filteredEmployeeArr = taggedUsers
        .filter((employee: any) => employee._id === match)
        .map((employee: any) => {
          if (isNotification) {
            return employee.isDeleted
              ? 'Deactivated User'
              : getProfileFullName(employee);
          }
          return getProfileFullName(employee);
        });
      return filteredEmployeeArr;
    });
  }
  if (typeof replacedText === 'object') {
    return replacedText.join('');
  }
  return replacedText;
};

export const getPostFormattedMessage = (
  text: any,
  taggedUsers: any,
  onUserClicked: any,
) => {
  let replacedText = text;
  replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
    <span key={match + i} className="feed-card-hashtag">
      #{match}
    </span>
  ));
  replacedText = reactStringReplace(
    replacedText,
    /:([^\s]*?):/g,
    (match, i) => <Emoji key={match + i} emoji={`:${match}:`} size={16} />,
  );

  if (taggedUsers.length > 0) {
    replacedText = reactStringReplace(
      replacedText,
      MENTION_REGEX,
      (match, i) => {
        const filteredEmployeeArr = taggedUsers
          .filter((employee: any) => employee._id === match)
          .map((employee: any) => {
            if (onUserClicked) {
              return (
                <span
                  key={match + i}
                  className={
                    employee.isDeleted
                      ? 'noEvent deactivated_user'
                      : 'feed-card-mention'
                  }
                  onClick={() => {
                    if (!employee.isDeleted) {
                      onUserClicked(employee);
                    }
                  }}
                >
                  {employee.isDeleted
                    ? 'Deactivated User'
                    : getProfileFullName(employee)}
                </span>
              );
            }
            return (
              <span key={match + i}>
                <b>
                  {employee.isDeleted
                    ? 'Deactivated User'
                    : getProfileFullName(employee)}
                </b>
              </span>
            );
          });
        return filteredEmployeeArr;
      },
    );
  }
  return replacedText;
};

export const getFirstLetterOfName = (user: any) => {
  if (user && user.profile && user.profile.firstName) {
    return `${user.profile.firstName.slice(0, 1).toUpperCase()}`;
  }
  return '';
};

export const getFirstLastName = (fullNameStr: string) => {
  const fullName = fullNameStr.trim().split(' ');
  let firstName = '';
  let lastName = '';
  for (const index in fullName) {
    if (Number(index) === 0) {
      firstName = fullName[index];
    } else {
      lastName = `${lastName} ${fullName[index]}`;
    }
  }
  lastName = lastName.trim();

  return [firstName, lastName];
};

export const getFullName = (
  user: Pick<CurrentUserProfile, 'firstName' | 'lastName'>,
) => {
  if (user) {
    return `${user.firstName} ${user.lastName}`;
  }
  return '';
};

export const replaceAll = function (text: any, search: any, replacement: any) {
  return text.replace(new RegExp(search, 'g'), replacement);
};

export const sortUsingName = (users: any[]) =>
  users.sort((a, b) => {
    let aName = '';
    let bName = '';
    if (a.profile) {
      aName = a.profile.firstName.toLowerCase();
    } else if (a.firstName) {
      aName = a.firstName;
    }
    if (b.profile) {
      bName = b.profile.firstName.toLowerCase();
    } else if (b.firstName) {
      bName = b.firstName;
    }
    return aName < bName ? -1 : aName > bName ? 1 : 0;
  });

// @ts-ignore
export function truncate(n, useWordBoundary) {
  // @ts-ignore
  if (this.length <= n) {
    // @ts-ignore
    return this;
  }
  // @ts-ignore
  const subString = this.substr(0, n - 1);
  return `${
    useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString
  }...`;
}

export const convertUsersForSearch = (arr: any[]) => {
  const keyMap = {
    name: 'displayText',
    username: 'secondaryText',
  };
  return arr.map((obj) => {
    return _.mapKeys(obj, (value, key) => {
      // @ts-ignore
      return keyMap[key];
    });
  });
};

export const getCoworkersOptions = (coworkers: any[]) =>
  coworkers.map((coworker) => {
    const { _id: id, profile, email } = coworker;
    const { firstName, lastName } = profile;
    return {
      id,
      label: `${firstName} ${lastName} (${email})`,
      tagLabel: `${firstName} ${lastName}`,
    };
  });

export const getIds = (array: any) => array.map(({ id }: any) => id);

export const getCoworkersFromIds = (ids: any[], coworkers: any[]) => {
  if (ids.length === 0 || coworkers.length === 0) {
    return [];
  }
  return coworkers.reduce((acc, coworker) => {
    const { _id: id } = coworker;
    if (ids.indexOf(id) !== -1) {
      acc.push(coworker);
    }
    return acc;
  }, []);
};

export const filterExceptCurrentUser = (array: any[], currentUserId: string) =>
  array.filter(({ id }) => id !== currentUserId);

export const trimSpaces = (value: string) => {
  return value
    .replace(/&nbsp;/g, ' ')
    .replace(/&amp;/g, '&')
    .replace(/&gt;/g, '>')
    .replace(/&lt;/g, '<');
};
