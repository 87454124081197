import moment from 'moment';
import type { MomentInput } from 'moment';

export const getAgoTime = (serTime: MomentInput) => {
  const time = moment(serTime);
  if (Math.abs(moment().diff(time)) < 1000) {
    // 1000 milliseconds
    return 'just now';
  }
  return time.fromNow();
};

export const getReportXLSTime = (serTime: MomentInput) =>
  moment(serTime).format('MMMM Do YYYY, h:mm:ss a');

export const getFormattedDate = (time: MomentInput) => {
  return moment(time).format('MMMM Do, YYYY');
};

export const getDueTime = (expiryTime: MomentInput) => {
  const now = moment();
  const expiration = moment(expiryTime);
  const diff = expiration.diff(now);

  const diffDuration = moment.duration(diff);

  const diffAsMinutes = diffDuration.asMinutes();
  const diffAsHours = diffDuration.asHours();
  const diffAsDays = diffDuration.asDays();

  const diffMinutes = diffDuration.minutes();
  const diffHours = diffDuration.hours();
  const diffDays = diffDuration.days();
  const diffWeeks = diffDuration.weeks();

  const remainingDiffDays = diffDays % 7;

  if (diffAsMinutes < 1) {
    return `<1min`;
  }

  if (diffAsMinutes > 1 && diffAsHours < 1) {
    return `${diffMinutes}min`;
  }

  if (diffHours >= 1 && diffAsDays < 1) {
    if (diffMinutes === 0) {
      return `${diffHours}h`;
    }

    return `${diffHours}h ${diffMinutes}min`;
  }

  if (diffDays >= 1 && diffDays < 7) {
    if (diffHours === 0) {
      return `${diffDays}d`;
    }

    return `${diffDays}d ${diffHours}h`;
  }

  // Weeks
  if (remainingDiffDays === 0) {
    return `${diffWeeks}w`;
  }
  return `${diffWeeks}w ${remainingDiffDays}d`;
};
