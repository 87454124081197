import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo } from 'react';
import { useGetMembersFromCriteria } from '../../../../hooks/useMembersSearch';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { blockDataSelector } from '../../../../stores/flowBuilderStore/selectors';
import {
  mapRulesFromCriteriaGroups,
  mapRulesFromCriteriaGroupsAndReturnMembersOnly,
} from '../../../../Utils/flows/criteria';
import { useGetFinalizedMembersFromCriteriaForPreview } from '../../../../hooks/useGetMembersFromCriteriaForPreview';
import {
  getCriteriaPayloadForParticipation,
  getCriteriaPayloadForVisibility,
  hasAnExclusionCriteria,
  hasSomeMemberCriteria,
  getVisibilityCriteriaForCurrentFlow,
} from '../../../../Utils/flows/sharesheet';
import { GetMembersForPreviewRequest } from '../../../../interfaces/Sharesheet';
import { useParams } from 'react-router-dom';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { VisibilityTypes } from '../types';
import { CriteriaGroups } from '../../../../interfaces/Flow/Builder';
import uniqBy from 'lodash/uniqBy';

export const useFetchMembersBasedOnFlowCriteria = ({
  enableFetch,
  isMembersOnly,
}: {
  enableFetch: boolean;
  isMembersOnly?: boolean;
}) => {
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowBuilderData } = useFetchFlowDetailsQuery(flowId, 'builder');

  const blockData = useFlowBuilderStore(blockDataSelector);

  const visibilityBlockData = blockData.VISIBILITY;
  const participantsBlockData = blockData.PARTICIPANTS;

  const { participantsCriteria } = participantsBlockData;
  // @ts-ignore
  const { criteriaGroups: visibilityCriteria } = visibilityBlockData;

  const participantRulesFromCriteria = useMemo(() => {
    const inputCriteria = isMembersOnly
      ? mapRulesFromCriteriaGroupsAndReturnMembersOnly(
          participantsCriteria,
          undefined,
          true,
        )
      : mapRulesFromCriteriaGroups(
          participantsCriteria,
          undefined,
          true,
          true,
          true,
        );
    return enableFetch && !isEmpty(participantsCriteria)
      ? inputCriteria
      : undefined;
  }, [enableFetch, isMembersOnly, participantsCriteria]);

  const visibilityRulesFromCriteria = useMemo(() => {
    const inputCriteria = isMembersOnly
      ? mapRulesFromCriteriaGroupsAndReturnMembersOnly(
          visibilityCriteria,
          undefined,
          true,
        )
      : mapRulesFromCriteriaGroups(
          visibilityCriteria,
          undefined,
          true,
          true,
          true,
        );
    return enableFetch ? inputCriteria : undefined;
  }, [isMembersOnly, visibilityCriteria, enableFetch]);

  const {
    data: participantsSelected,
    isLoading: isParticipantsCriteriaLoading,
    totalMembersCount: totalParticipantsCount,
    fetchNextPage: fetchNextPageForParticipants,
    hasNextPage: hasNextPageForParticipants,
  } = useGetFinalizedMembersFromCriteriaForPreview(
    {
      ...getCriteriaPayloadForParticipation(
        participantRulesFromCriteria?.criteria,
        flowBuilderData,
        isMembersOnly,
      ),
    } as GetMembersForPreviewRequest,
    100,
  );

  const {
    models: {
      isLoading: isEveryoneCriteriaBeingFetched,
      totalMembers: membersCountForEveryoneCriteria,
    },
  } = useGetMembersFromCriteria(
    {
      limit: 20,
      criteria: { everyone: true },
      isNewMembersAdded: true,
      skippedMembers: [],
    },
    undefined,
    {
      isNewMembersAdded: true,
    },
  );

  const includesPayload = useMemo(() => {
    return getVisibilityCriteriaForCurrentFlow({
      ownerID: flowBuilderData?.data.owner.memberID || '',
      collaborators: flowBuilderData?.data.collaborators,
    });
  }, [
    flowBuilderData?.data.collaborators,
    flowBuilderData?.data.owner.memberID,
  ]);

  const {
    data: membersWithVisibility,
    isLoading: isViewerCriteriaLoading,
    totalMembersCount: totalViewersCount,
    fetchNextPage: fetchNextPageForViewers,
    hasNextPage: hasNextPageForViewers,
  } = useGetFinalizedMembersFromCriteriaForPreview(
    {
      ...getCriteriaPayloadForVisibility(
        visibilityBlockData?.type === VisibilityTypes.PARTICIPANTS_ONLY
          ? participantRulesFromCriteria?.criteria
          : visibilityRulesFromCriteria?.criteria,
        flowBuilderData,
        visibilityBlockData?.type as VisibilityTypes,
        isMembersOnly,
      ),
      includes: includesPayload,
    } as GetMembersForPreviewRequest,
    100,
  );

  const fetchMoreMembers = () => {
    if (hasNextPageForParticipants) {
      fetchNextPageForParticipants();
    }
    if (hasNextPageForViewers) {
      fetchNextPageForViewers();
    }
  };

  const shouldValidateMembersLength = useCallback(
    (criteria: CriteriaGroups | undefined, memberLength: number) => {
      return isMembersOnly && criteria && hasSomeMemberCriteria(criteria)
        ? memberLength > 0
        : true;
    },
    [isMembersOnly],
  );

  const exclusionCriteria = useMemo(() => {
    const hasExcludedParticipantsCriteria =
      isMembersOnly && hasAnExclusionCriteria(participantsCriteria);

    const hasExcludedVisibilityCriteria =
      isMembersOnly && hasAnExclusionCriteria(visibilityCriteria);

    const participationExclusionRules = (
      (participantsCriteria?.groups?.length === 2 &&
        participantsCriteria?.groups[1]?.groupRules) ||
      []
    ).flatMap((rule) => {
      return rule.value
        .filter((currentValue) => currentValue.id && currentValue.value)
        .map((currentValue) => ({
          field: rule.field,
          value: currentValue.value,
          operator: 'is',
        }));
    });

    const viewingExclusionRules = (
      (visibilityCriteria?.groups?.length === 2 &&
        visibilityCriteria?.groups[1]?.groupRules) ||
      []
    ).flatMap((rule) => {
      return rule.value
        .filter((currentValue) => currentValue.id && currentValue.value)
        .map((currentValue) => ({
          field: rule.field,
          value: currentValue.value,
          operator: 'is',
        }));
    });

    const rules = uniqBy(
      [
        ...(hasExcludedParticipantsCriteria ? participationExclusionRules : []),
        ...(hasExcludedVisibilityCriteria ? viewingExclusionRules : []),
      ],
      'value',
    ).map((rule) => ({
      ...rule,
      value: [rule.value],
    }));

    return rules?.length > 0
      ? {
          criteria: {
            custom: {
              rules: [
                {
                  rules,
                  condition: 'or',
                },
              ],
              condition: 'or',
            },
          },
        }
      : undefined;
  }, [isMembersOnly, participantsCriteria, visibilityCriteria]);

  const { data: excludedMembers, isLoading: isExclusionCriteriaLoading } =
    useGetFinalizedMembersFromCriteriaForPreview(
      {
        ...exclusionCriteria,
      } as GetMembersForPreviewRequest,
      100,
      !isEmpty(exclusionCriteria),
    );

  return {
    participantsSelected:
      shouldValidateMembersLength(
        participantsCriteria,
        participantsSelected.length,
      ) && !isParticipantsCriteriaLoading
        ? participantsSelected
        : undefined,
    isParticipantsCriteriaLoading,
    isEveryoneCriteriaBeingFetched,
    totalParticipantsCount,
    isViewerCriteriaLoading,
    membersWithVisibility:
      shouldValidateMembersLength(
        visibilityCriteria,
        membersWithVisibility.length,
      ) && !isViewerCriteriaLoading
        ? membersWithVisibility
        : undefined,
    participantsCriteria,
    visibilityCriteria,
    totalViewersCount,
    visibilityBlockData,
    participantsBlockData,
    membersCountForEveryoneCriteria,
    fetchMoreMembers,
    hasMoreMembers: hasNextPageForParticipants || hasNextPageForViewers,
    fetchNextPageForParticipants,
    fetchNextPageForViewers,
    isExclusionCriteriaLoading:
      isMembersOnly && exclusionCriteria && isExclusionCriteriaLoading,
    excludedMembers,
  };
};
