import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../theme';

const ProfileDetailsEntryLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={293}
      height={60}
      viewBox="0 0 293 60"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="profile-details-entry-loader"
    >
      <rect x="24" y="14" rx="4" width="16" height="16" />
      <rect x="44" y="14" rx="4" width="47" height="16" />
      <rect x="32" y="34" rx="4" width="109" height="16" />
    </ContentLoader>
  );
};

export default ProfileDetailsEntryLoader;
