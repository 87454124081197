import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Flex } from '../../../../../Utils/styles/display';
import { MenuItemIndividualItem } from '../../../../../atomic/molecules/Dropdown_V2/interfaces';
import {
  SimplifiedShareSheetUserCard,
  SimplifiedShareSheetUserCardLoader,
} from '../../../../../atomic/molecules/ShareSheet/SimplifiedShareSheetUserCard';

import { useFlowsSimpleShareSheet } from './useFlowsSimpleShareSheet';

import { BulkRuleSelector } from '../../../../../atomic/molecules/ShareSheet/BulkRuleSelector';
import { SimpleShareSheetPeopleSelector } from '../../../../../atomic/organism/ShareSheet/SimpleShareSheetPeopleSelector';
import {
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
} from '../../types';
import isEmpty from 'lodash/isEmpty';
import InfiniteScroll from 'react-infinite-scroll-component';
import { device } from '../../../../../constants/layout';
import RequestAccessController from '../../RequestAccessController';
import useRequestedUsersForAccessStore, {
  ShareSheetCurrentModalScreen,
} from '../../../../../stores/sharesheetStore';

const CardsContainer = styled(Flex)`
  max-height: calc(100vh - 528px);

  > :first-child {
    margin-top: 8px;
  }

  @media ${device.mobile} {
    max-height: calc(100vh - 420px);
  }
`;

const InfiniteScrollWrapper = styled.div<{ $hasPadding?: boolean }>`
  max-height: 160px;
  overflow-y: auto;
  width: 100%;
`;

export const SimplifiedShareSheetController = ({
  handleOnModifiedChanges,
  handleMembersToBeInvited,
  handleSelectedTabIndexChange,
}: {
  handleOnModifiedChanges: (hasChanged: boolean) => void;
  handleMembersToBeInvited: (members: string[]) => void;
  handleSelectedTabIndexChange: (index: number) => void;
}) => {
  const { setCurrentShareSheetModalScreen } = useRequestedUsersForAccessStore();
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setCurrentShareSheetModalScreen(ShareSheetCurrentModalScreen.Simple_Rule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    selectedItems,
    onOptionsScroll,
    displayOptions,
    handleAutoCompleteOptionSelection,
    value,
    textBoxValue,
    setTextBoxValue,
    isMembersLoading,
    changeUserSettings,
    isParticipantsCriteriaLoading,
    isViewerCriteriaLoading,
    resetAutoCompleteDropdown,
    currentUserId,
    resetSelectedItems,
    canEmailInvite,
    handleBulkMemberActionSelection,
    isEveryoneSelected,
    bulkRuleDropdownOptions,
    isBulkRuleSelectorDataLoading,
    onBulkRuleOptionSelect,
    onBulkRuleOptionDelete,
    departmentCountMap,
    membersCountForEveryoneCriteria,
    fetchMoreMembersForDisplay,
    hasMoreMembersToDisplay,
    hasNonOwnerParticipants,
    canInviteMembersFromShareSheet,
    isExclusionCriteriaLoading,
    tooltipContent,
    nonMemberItemSelectionType,
    canShowEveryoneCriteria,
  } = useFlowsSimpleShareSheet({
    handleOnModifiedChanges,
    handleMembersToBeInvited,
  });

  useEffect(() => {
    resetAutoCompleteDropdown(inputRef);
  }, [selectedItems, resetAutoCompleteDropdown]);

  useEffect(() => {
    return () => {
      handleMembersToBeInvited([]);
      resetSelectedItems();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubtitleText = (item: SimplifiedShareSheetCardData) => {
    if (item.type === 'everyone') {
      return `${
        membersCountForEveryoneCriteria
          ? membersCountForEveryoneCriteria
          : 'Loading'
      } members`;
    } else if (item.type === 'department') {
      return `${
        departmentCountMap[item.id] ? departmentCountMap[item.id] : 'Loading'
      } members`;
    }
    return item.email;
  };

  return (
    <Flex width="100%" flexDirection="column">
      {nonMemberItemSelectionType !== null ? (
        <BulkRuleSelector
          nonMemberItemSelectionType={nonMemberItemSelectionType}
          isBulkRuleSelectorDataLoading={isBulkRuleSelectorDataLoading}
          bulkRuleOptions={bulkRuleDropdownOptions}
          onBulkRuleOptionSelect={onBulkRuleOptionSelect}
          onBulkRuleOptionDelete={onBulkRuleOptionDelete}
        />
      ) : (
        <SimpleShareSheetPeopleSelector
          selectedItems={selectedItems}
          textBoxValue={textBoxValue}
          setTextBoxValue={setTextBoxValue}
          canEmailInvite={canEmailInvite}
          isEveryoneSelected={isEveryoneSelected}
          isMembersLoading={isMembersLoading}
          handleBulkMemberActionSelection={handleBulkMemberActionSelection}
          handleAutoCompleteOptionSelection={handleAutoCompleteOptionSelection}
          displayOptions={displayOptions}
          onOptionsScroll={onOptionsScroll}
          inputRef={inputRef}
          currentUserId={currentUserId || ''}
          value={value}
          resetAutoCompleteDropdown={resetAutoCompleteDropdown}
          handleSelectedTabIndexChange={handleSelectedTabIndexChange}
          canInviteMembersFromShareSheet={canInviteMembersFromShareSheet}
          canShowEveryoneCriteria={canShowEveryoneCriteria}
        />
      )}
      <RequestAccessController />
      <CardsContainer width="100%" flexDirection="column">
        {(isParticipantsCriteriaLoading ||
          isViewerCriteriaLoading ||
          isExclusionCriteriaLoading) &&
        isEmpty(selectedItems) ? (
          <SimplifiedShareSheetUserCardLoader />
        ) : (
          <InfiniteScrollWrapper id="simpleShareSheetPreviewPeople">
            <InfiniteScroll
              dataLength={selectedItems.length}
              hasMore={Boolean(hasMoreMembersToDisplay)}
              loader={<SimplifiedShareSheetUserCardLoader />}
              next={fetchMoreMembersForDisplay}
              scrollableTarget="simpleShareSheetPreviewPeople"
            >
              {selectedItems.map((i) => {
                const subtitleText = getSubtitleText(i);
                return (
                  <SimplifiedShareSheetUserCard
                    key={i.id}
                    cardData={{
                      ...i,
                      email: subtitleText,
                    }}
                    shouldDisplayDropdown
                    changeUserSettings={(setting?: MenuItemIndividualItem) => {
                      if (setting) {
                        changeUserSettings(setting, i.id);
                      }
                    }}
                    currentUserId={currentUserId || ''}
                    tooltipContent={tooltipContent}
                    isLocked={i.type === SimplifiedShareSheetDataTypes.Channel}
                    hasNonOwnerParticipants={hasNonOwnerParticipants}
                  />
                );
              })}
            </InfiniteScroll>
          </InfiniteScrollWrapper>
        )}
      </CardsContainer>
    </Flex>
  );
};
