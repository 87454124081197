import React from 'react';

import { generateV2UserProfileRoute } from '../../../constants/routes';
import Link from '../../atoms/Link_V2';
import { OnlineStatus } from '../../atoms/Avatar/interfaces';
import { StyledButton, Wrapper, StyledChatHeaderWrapper } from './styles';
import {
  viewMembersActivity,
  VIEW_MY_ACTIVITY,
} from '../../../languages/en/home/profileDrawer';
import { MemberState } from '../../../interfaces/user';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import ProfileChatHeader from '../ProfileChatHeader';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { HandleOnCloseClick } from '../../../controllers/home/ProfileViewController/interfaces';

export interface ProfileHeaderSectionProps {
  firstName: string;
  isCurrentUser: boolean;
  lastName: string;
  memberId: string;
  onlineStatus?: OnlineStatus;
  profilePicture: string;
  shouldDisplayViewActivityLink: boolean;
  username: string;
  state?: MemberState;
  pronouns?: string;
  onCloseClick: HandleOnCloseClick;
  onViewProfileClick: () => void;
  onCopyProfileLinkClick: () => void;
  hideCloseButton?: boolean;
}

const ProfileHeaderSection = ({
  firstName,
  isCurrentUser,
  lastName,
  memberId,
  profilePicture,
  shouldDisplayViewActivityLink,
  onCloseClick,
  onViewProfileClick,
  onCopyProfileLinkClick,
  hideCloseButton,
  state,
}: ProfileHeaderSectionProps) => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { slugUrl } = useMultiAssemblySlug();

  return (
    <Wrapper>
      <StyledChatHeaderWrapper>
        <ProfileChatHeader
          profile={{
            id: memberId,
            lastName: lastName,
            firstName: firstName,
            image: profilePicture,
            state: state,
          }}
          isMobileView={isMobileView}
          isLoading={false}
          onCloseClick={onCloseClick}
          hideCloseButton={hideCloseButton}
          onViewProfileClick={onViewProfileClick}
          onCopyProfileLinkClick={onCopyProfileLinkClick}
        />
      </StyledChatHeaderWrapper>

      {shouldDisplayViewActivityLink && (
        <Link to={`/${slugUrl}${generateV2UserProfileRoute(memberId)}`}>
          <StyledButton
            isFullWidth
            size="small"
            variant="text"
            onClick={
              isMobileView
                ? () => onCloseClick({ viewProfileClick: true })
                : () => undefined
            }
          >
            {isCurrentUser ? VIEW_MY_ACTIVITY : viewMembersActivity(firstName)}
          </StyledButton>
        </Link>
      )}
    </Wrapper>
  );
};

export default ProfileHeaderSection;
