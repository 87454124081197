import styled from 'styled-components';
import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import Card from '../../atoms/Card';
import Tooltip from '../ToolTipV2';
import ThemeV2 from '../../../theme';
import { RewardTypes, StyledComponentProps } from './interface';

export const StyledCard = styled(Card)`
  width: 100%;
  /* max-width: 215px; */
  border-radius: 4px;
`;
export const CardHeader = styled.div`
  position: relative;
`;
CardHeader.displayName = 'CardHeader';

export const CardHeaderPopoverWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
CardHeaderPopoverWrapper.displayName = 'CardHeaderPopoverWrapper';

interface CardBodyProps {
  rewardType: RewardTypes;
}

const cultureRewardCardBodyHeight = '206px';
const cardBodyHeight = '186px';

export const CardBody = styled.div<CardBodyProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) =>
    props.rewardType === RewardTypes.Culture
      ? cultureRewardCardBodyHeight
      : cardBodyHeight};
  padding: 24px 16px;
`;
CardBody.displayName = 'CardBody';

export const RewardImageWrapper = styled.div<CardBodyProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  border-radius: 4px 4px 0px 0px;
  background-color: ${({ rewardType }) =>
    rewardType !== RewardTypes.Swags && ThemeV2.palette.gray2};
`;
RewardImageWrapper.displayName = 'RewardImageWrapper';

export const RewardImage = styled.div<StyledComponentProps>`
  height: ${(props) => (props.backgroundSize === 'square' ? '93px' : '125px')};
  width: ${(props) => (props.backgroundSize === 'square' ? '93px' : '100%')};
  border-radius: ${(props) => props.backgroundSize === 'square' && '4px'};
  margin: ${(props) => (props.backgroundSize === 'square' ? '16px 0' : 0)};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: ${({ disabled }) => disabled && `opacity(0.4)`};
  border-radius: ${(props) =>
    props.backgroundSize === 'square' ? '4px' : '4px 4px 0 0'};
  @media (max-width: 1313px) {
    height: ${(props) =>
      props.backgroundSize === 'square' ? '94px' : '146px'};
  }
  @media (max-width: 1105px) {
    height: ${(props) =>
      props.backgroundSize === 'square' ? '94px' : '167px'};
  }
  @media (max-width: 896px) {
    height: ${(props) =>
      props.backgroundSize === 'square' ? '94px' : '166px'};
  }
`;
RewardImage.displayName = 'RewardImage';

export const RewardAvailability = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 4px;
  background: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
`;
RewardAvailability.displayName = 'RewardAvailability';

export const RewardTitle = styled(Body)<{ disabled?: boolean }>`
  display: -webkit-box;
  color: ${ThemeV2.palette.gray10};
  margin-bottom: 4px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  overflow: hidden;
  filter: ${({ disabled }) => disabled && `opacity(0.4)`};
`;
RewardTitle.displayName = 'RewardTitle';

export const RewardCost = styled(Body)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${ThemeV2.palette.gray8};
  filter: ${({ disabled }) => disabled && `opacity(0.4)`};
`;
RewardCost.displayName = 'RewardCost';

export const RewardDescriptionLink = styled(Body)`
  cursor: pointer;
  color: ${ThemeV2.palette.gray8};
  text-decoration: underline;
  margin-bottom: 20px;
`;
RewardDescriptionLink.displayName = 'RewardDescriptionLink';

export const RewardPopoverDescriptionText = styled(Body)`
  color: ${ThemeV2.palette.gray7};
  max-width: 215px;
`;
RewardPopoverDescriptionText.displayName = 'RewardPopoverDescriptionText';

export const RewardCardButton = styled(Button)`
  margin-top: auto;
`;
RewardCardButton.displayName = 'RewardCardButton';

export const TrophyRangeTooltip = styled(Tooltip)`
  margin-left: 4px;
`;
TrophyRangeTooltip.displayName = 'TrophyRangeTooltip';

export const RewardPopoverTooltipIcon = styled.div`
  display: flex;
  background: ${ThemeV2.palette.gray1};
  padding: 4px;
  border-radius: 25px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;
RewardPopoverTooltipIcon.displayName = 'RewardPopoverTooltipIcon';

export const StyledSwagToolTipText = styled(Body)`
  text-decoration: underline;
`;

export const ShippingCostContent = styled(Body)`
  max-width: 188px;
`;
