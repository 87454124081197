import styled from 'styled-components';
import { zIndexProgressBar } from '../../../Utils/styles/z-index';
import ProgressBar from '../../../atomic/atoms/ProgressBar';
import ThemeV2 from '../../../theme';

export const StyledWrapper = styled.div`
  background: ${ThemeV2.palette.white};
  min-height: 100vh;
`;

export const StyledFeedWrapper = styled.div<{
  scaleValue: number;
}>`
  max-width: ${({ scaleValue }) => `${676 * scaleValue}px`};
  margin: 0 auto;
`;

export const StyledInnerFeedWrapper = styled.div<{
  scaleValue: number;
}>`
  transform: ${({ scaleValue }) => `scale(${scaleValue})`};
  transform-origin: 0 112px;
  width: ${({ scaleValue }) => `${100 / scaleValue}%`};
`;

export const StyledProgressBar = styled(ProgressBar)`
  position: fixed;
  top: 79px;
  width: 100%;
  z-index: ${zIndexProgressBar};
`;

export const StyledMenuBar = styled.div`
  position: fixed;
  bottom: 32px;
  padding: 0 32px;
`;
