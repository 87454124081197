import { useEffect } from 'react';
import { pusher } from '../../pusher/pusher-base';

import { SortByQueryParams } from '../../Utils/data/rewards';
import { useGiftCardFetchQuery } from '../../queries/Rewards';
import { useProfileInfoFetchQuery } from '../../queries/Profile';

const useRewardDenominationSocket = () => {
  const { data } = useProfileInfoFetchQuery();
  const rewardsFetchQueryParams = {
    sort: SortByQueryParams.POPULAR,
  };
  const { refetch: refetchGiftCards } = useGiftCardFetchQuery(
    rewardsFetchQueryParams,
  );
  useEffect(() => {
    if (data) {
      const { assemblyId } = data.assembly;
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );
      channelAssembly.bind('REWARD_DENOMINATION_RANGE_UPDATED', () => {
        refetchGiftCards();
      });
      return () => {
        channelAssembly.unbind('REWARD_DENOMINATION_RANGE_UPDATED');
      };
    }
  }, [data, refetchGiftCards]);
};

export default useRewardDenominationSocket;
