import styled from 'styled-components';

import { device } from '../../../constants/layout';
import { shadowElevation2Style } from '../../../Utils/styles/css';
import { zIndexActionBarModal } from '../../../Utils/styles/z-index';
import { windowSizeAdapter } from '../../../Utils/window';

export const StyledActionModal = styled.div<{ isMobileApp: boolean }>`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: ${zIndexActionBarModal};
  top: 0;
  left: 0;
  background-color: #fff;
  ${shadowElevation2Style}
  border-radius: 4px;

  height: ${({ isMobileApp }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: 'calc(var(--mobileVh, 1vh) * 100 - 72px)',
      mobileWeb: 'calc(var(--mobileVh, 1vh) * 100 - 72px)',
      tablet: 'calc(100vh - 72px)',
      desktop: 'auto',
    })};

  @media ${device.desktop} {
    position: absolute;
    max-height: 600px;
  }

  @media ${device.mobile} {
    margin-top: 72px;
  }
`;
