import React from 'react';

import NotificationSettingsAccordion from '../../atomic/organism/NotificationSettingsAccordion';
import useNotificationsSettings from './useNotificationSettings';
import useMobileApp from '../../hooks/useMobileApp';

const NotificationSettingsController = () => {
  const {
    isError,
    isLoading,
    handleToggle,
    emailPreferences,
    mobileAppPreferences,
    handleMobileAppToggle,
    onFlowItemOptionsToggle,
    handleFlowItemOptionsMobileAppToggle,
  } = useNotificationsSettings();

  const { isMobileApp } = useMobileApp();

  return (
    <NotificationSettingsAccordion
      isError={isError}
      isLoading={isLoading}
      handleToggle={handleToggle}
      emailPreferences={emailPreferences}
      hideMobileAppSettings={!isMobileApp}
      onMobileAppToggle={handleMobileAppToggle}
      mobileAppPreferences={mobileAppPreferences}
      onFlowItemOptionsToggle={onFlowItemOptionsToggle}
      onFlowItemOptionsMobileAppToggle={handleFlowItemOptionsMobileAppToggle}
    />
  );
};

export default NotificationSettingsController;
