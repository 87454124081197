import ThemeV2 from '../../theme';
import { datadogLogs } from '@datadog/browser-logs';

export const mapHexCodeToEmoticon = (value: string) => {
  try {
    if (value.indexOf('-') > 0) {
      return String.fromCodePoint(
        ...value.split('-').map((item) => +`0x${item}`),
      );
    }

    return String.fromCodePoint(+`0x${value}`);
  } catch (e) {
    datadogLogs.logger.error(
      'Error in mapHexCodeToEmoticon function',
      e as object,
    );
    return value;
  }
};

export const mapColorToPalette = (value: string) => {
  if (!value) {
    throw new Error('invalid arguments');
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore  Theme object structure is not consistent
  const color = ThemeV2.palette[value];
  return color || ThemeV2.palette.blue2;
};
