import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../theme';
import ContentLoader from 'react-content-loader';
import Body from '../../../atoms/Body';
import { INITIAL_LOG_LIMIT } from './TaskHistoryItem';

export const TaskHistoryWrapper = styled.div``;

export const TaskHistoryItem = styled(Flex)`
  align-items: flex-start;
`;

export const Icon = styled(Flex)`
  background: ${ThemeV2.palette.gray3};
  width: 20px;
  padding: 0 4px;
  height: 20px;
  border-radius: 50%;
`;

export const Details = styled.div`
  margin-left: 8px;
  word-break: break-word;
`;

export const TaskHistoryLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`;

export const LogGroupHeader = styled(Body)`
  margin-top: 12px;
`;

export const PreviewContent = styled.div<{
  isExpanded: boolean;
}>`
  max-height: ${({ isExpanded }) =>
    isExpanded ? 'fit-content' : `${INITIAL_LOG_LIMIT}px`};
  overflow-y: hidden;
`;

export const ShowFullLogButton = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;
