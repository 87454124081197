import styled from 'styled-components';
import ThemeV2 from '../../../../theme';
import { zIndexFooter } from '../../../../Utils/styles/z-index';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';

export const FilterWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  padding: 24px;
  min-height: calc(100vh - 196px);
  overflow-y: auto;
`;

export const StyledDrawerHeaderWithIcon = styled(DrawerHeaderWithIcon)`
  margin-top: 0;
`;

export const SpaceSeparator = styled.div`
  margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
  bottom: 0;
  position: fixed;
  padding: 16px 24px;
  z-index: ${zIndexFooter};
  background: ${ThemeV2.palette.gray1};
  border-top: 1px solid ${ThemeV2.palette.gray4};
`;
