import { shallow } from 'zustand/shallow';
import React, { memo } from 'react';

import Button from '../../../atoms/Button';
import DateFilter from '../../DateFilter';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';
import CheckboxFilter from '../../CheckboxFilter';
import {
  StyledScrollWrapper,
  StyledFilterWrapper,
  StyledSpaceSeparator,
  StyledButton,
  StyledFlex,
  FilterWrapper,
} from '../styles';

import { FlowFeedFilterLoadedProps } from './types';
import { FILTER_BY, APPLY_FILTERS } from '../../../../languages/en/flows';
import { CLEAR } from '../../../../languages/en/singleWords';
import { CheckboxFilterLoadedProps } from '../../CheckboxFilter/types';
import { ComponentStatus } from '../../../../interfaces/component';
import useLayoutStore from '../../../../stores/layoutStore';
import useKioskStore from '../../../../stores/kioskStore';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../../constants/layout';
import useMobileApp from '../../../../hooks/useMobileApp';

const RenderCheckboxFilter = (props: CheckboxFilterLoadedProps) => {
  const { options, headingText } = props;
  if (options.length > 0) {
    return <CheckboxFilter {...props} />;
  }
  return (
    <CheckboxFilter status={ComponentStatus.EMPTY} headingText={headingText} />
  );
};

const MemoizedRenderCheckboxFilter = memo(RenderCheckboxFilter);

const LoadedComponent = (props: FlowFeedFilterLoadedProps) => {
  const {
    dateFilterProps,
    versionFilterProps,
    blockFilterProps,
    postedByFilterProps,
    mentionedByFilterProps,
    onSubmit,
    onClear,
    isTab,
    isFilterButtonEnabled = false,
  } = props;
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();

  const { isMobileApp } = useMobileApp();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { isEmbeddedInMainApp } = useLayoutStore();

  return (
    <StyledScrollWrapper
      isTab={isTab}
      isMobileApp={isMobileApp}
      isEmbeddedInMainApp={isEmbeddedInMainApp}
    >
      <FilterWrapper>
        {!isMobileView && (
          <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
            {FILTER_BY}
          </DrawerHeaderWithIcon>
        )}
        <StyledFilterWrapper>
          <DateFilter {...dateFilterProps} />
          <StyledSpaceSeparator />
          {versionFilterProps && (
            <MemoizedRenderCheckboxFilter {...versionFilterProps} />
          )}
          <StyledSpaceSeparator />
          <MemoizedRenderCheckboxFilter {...blockFilterProps} />
          <StyledSpaceSeparator />
          <MemoizedRenderCheckboxFilter {...postedByFilterProps} />
          <StyledSpaceSeparator />
          <MemoizedRenderCheckboxFilter {...mentionedByFilterProps} />
          <StyledSpaceSeparator />
        </StyledFilterWrapper>
      </FilterWrapper>
      <StyledFlex
        justifyContent="center"
        isKioskTurnedOn={isKioskTurnedOn}
        isRightAsideOpen={isRightAsideOpen}
      >
        <StyledButton
          color="primary"
          onClick={onSubmit}
          size="medium"
          status="default"
          disabled={isFilterButtonEnabled}
        >
          {APPLY_FILTERS}
        </StyledButton>
        <Button
          color="primary"
          onClick={onClear}
          size="medium"
          status="default"
          variant="text"
        >
          {CLEAR}
        </Button>
      </StyledFlex>
    </StyledScrollWrapper>
  );
};

export default LoadedComponent;
