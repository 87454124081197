import ThemeV2 from '../../../theme';
import { ShareSheetModalScreen } from '../../../controllers/flows/FlowsShareSheetController/types';
import Divider from '../../atoms/Divider';
import {
  ANYONE_WITH_LINK,
  CAN_RESPOND_AND_VIEW_FLOW_LABEL,
  CAN_RESPOND_FLOW_LABEL,
  CAN_VIEW_FLOW_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  PARTICIPANTS_AND_VIEWERS_LABEL,
  PARTICIPANTS_LABEL,
  VIEWERS_LABEL,
} from '../../../languages/en/flows/sharesheet';
import { getIconSettingsForLinkAccess } from '../../../controllers/flows/FlowsShareSheetController/LinkAccessSettingsController/utils';
import { SHARE_SHEET_MODAL } from '../../../languages/en/flows/builder';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import {
  ShareSheetSettings,
  AccessSettingsIcon,
  LinkAccessLabel,
  ChangeLinkSettingsAppButton,
  ChangeLinkSettingsButton,
} from '../../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/styles';

export const LinkAccessSettings = ({
  isMobileView,
  linkAccessType,
  selectedLinkPermissionRole,
  updateModalCurrentState,
  isSimplifiedShareSheetTreatmentOn,
}: {
  selectedLinkPermissionRole: string;
  linkAccessType: string;
  isMobileView: boolean;
  updateModalCurrentState: (currentState: ShareSheetModalScreen) => void;
  isSimplifiedShareSheetTreatmentOn: boolean;
}) => {
  return (
    <>
      <Divider color={ThemeV2.palette.gray4} isFullWidth />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isMobileView ? 'column' : 'row'}
        margin="16px 0"
      >
        <div>
          <Body variant="body1Medium" color="gray9">
            {isSimplifiedShareSheetTreatmentOn
              ? SHARE_SHEET_MODAL.ACCESS_SETTINGS
              : SHARE_SHEET_MODAL.LINK_ACCESS_SETTINGS}
          </Body>
          {isMobileView ? (
            <ShareSheetSettings
              onClick={() =>
                updateModalCurrentState(
                  ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                )
              }
            >
              <Flex alignItems="flex-start">
                <AccessSettingsIcon
                  size="16px"
                  color={ThemeV2.palette.geekBlue6}
                  icon={getIconSettingsForLinkAccess(linkAccessType).icon}
                />
                <div>
                  <Body
                    variant="body2Medium"
                    color="geekBlue6"
                    margin="4px 0 0 4px"
                  >
                    <span>
                      {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                        SHARE_SHEET_MODAL.EVERYONE_CAN_ACCESS_LABEL}
                      {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                        SHARE_SHEET_MODAL.ONLY_GROUP_I_SELECT_LABEL}
                      {linkAccessType === ANYONE_WITH_LINK &&
                        SHARE_SHEET_MODAL.ANYONE_WITH_LINK_CAN_ACCESS_LABEL}
                    </span>
                    <LinkAccessLabel>
                      {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                        CAN_RESPOND_AND_VIEW_FLOW_LABEL}
                      {linkAccessType === ANYONE_WITH_LINK &&
                        CAN_RESPOND_FLOW_LABEL}
                      {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                        selectedLinkPermissionRole === PARTICIPANTS_LABEL &&
                        CAN_RESPOND_FLOW_LABEL}
                      {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                        selectedLinkPermissionRole === VIEWERS_LABEL &&
                        CAN_VIEW_FLOW_LABEL}
                      {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                        selectedLinkPermissionRole ===
                          PARTICIPANTS_AND_VIEWERS_LABEL &&
                        CAN_RESPOND_AND_VIEW_FLOW_LABEL}
                    </LinkAccessLabel>
                  </Body>
                </div>
              </Flex>
            </ShareSheetSettings>
          ) : (
            <Flex alignItems="flex-start">
              <ShareSheetSettings
                onClick={() =>
                  updateModalCurrentState(
                    ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                  )
                }
              >
                <Flex alignItems="flex-start">
                  <AccessSettingsIcon
                    size="16px"
                    color={ThemeV2.palette.geekBlue6}
                    icon={getIconSettingsForLinkAccess(linkAccessType).icon}
                  />
                  <Body
                    variant="body2Medium"
                    color="geekBlue6"
                    margin="4px 0 0 4px"
                  >
                    {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                      SHARE_SHEET_MODAL.EVERYONE_CAN_ACCESS_LABEL}
                    {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                      SHARE_SHEET_MODAL.ONLY_GROUP_I_SELECT_LABEL}
                    {linkAccessType === ANYONE_WITH_LINK &&
                      SHARE_SHEET_MODAL.ANYONE_WITH_LINK_CAN_ACCESS_LABEL}
                  </Body>
                </Flex>
              </ShareSheetSettings>
              <Body variant="body2" color="gray9" margin="4px 0 0 4px">
                {linkAccessType === ONLY_PEOPLE_OR_RULES_LABEL &&
                  CAN_RESPOND_AND_VIEW_FLOW_LABEL}
                {linkAccessType === ANYONE_WITH_LINK && CAN_RESPOND_FLOW_LABEL}
                {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                  selectedLinkPermissionRole === PARTICIPANTS_LABEL &&
                  CAN_RESPOND_FLOW_LABEL}
                {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                  selectedLinkPermissionRole === VIEWERS_LABEL &&
                  CAN_VIEW_FLOW_LABEL}
                {linkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL &&
                  selectedLinkPermissionRole ===
                    PARTICIPANTS_AND_VIEWERS_LABEL &&
                  CAN_RESPOND_AND_VIEW_FLOW_LABEL}
              </Body>
            </Flex>
          )}
        </div>
        {isMobileView ? (
          !isSimplifiedShareSheetTreatmentOn && (
            <Flex width="100%">
              <ChangeLinkSettingsAppButton
                color="secondary"
                size="medium"
                variant="text"
                onClick={() =>
                  updateModalCurrentState(
                    ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                  )
                }
                dataTestId="change link access settings"
              >
                {SHARE_SHEET_MODAL.CHANGE_SETTINGS}
              </ChangeLinkSettingsAppButton>
            </Flex>
          )
        ) : (
          <ShareSheetSettings>
            <ChangeLinkSettingsButton
              color="secondary"
              size="small"
              variant="text"
              onClick={() =>
                updateModalCurrentState(
                  ShareSheetModalScreen.LINK_ACCESS_SETTINGS,
                )
              }
              dataTestId="change link access settings"
            >
              {SHARE_SHEET_MODAL.CHANGE_SETTINGS}
            </ChangeLinkSettingsButton>
          </ShareSheetSettings>
        )}
      </Flex>
    </>
  );
};
