import { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  ShowMoreButton,
  StyledActionBarWrapper,
} from '../MainFeedController/styles';

import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';
import { useDraftStore } from '../../../stores/draftStore';
import { FeedsContainer, Flex } from '../../../Utils/styles/display';
import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import { useRecognitionController } from './useRecognitionController';

import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import {
  CELEBRATE_TEAM_MEMBER,
  SEE_NEW_POSTS,
  START_POST,
} from '../../../languages/en/feed';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import FlowPostController from '../../flows/FlowPostController';
import RecognitionFlowController from '../RecognitionFlowController';

import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import DummyFlowFeedPost from '../../../atomic/organism/DummyFlowFeedPost';
import {
  celebrateTeamMateDummyPostData,
  celebrateTeammateHeaderProps,
} from '../../../atomic/organism/DummyFlowFeedPost/data';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import useMobileApp from '../../../hooks/useMobileApp';
import searching from '../../../img/searching.svg';
import { PostTypes } from '../../../interfaces/Home';
import { FeedContext } from '../../../Utils/flows/feeds';
import FeedSearchBarController from '../FeedSearchBarController';

export const RecognitionController = () => {
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();

  const { models, operations } = useRecognitionController();

  const { drafts } = useDraftStore();

  const {
    data,
    isLoading,
    hasNextPage,
    profileData,
    searchFeedValue,
    showSearchResults,
    showLoadMoreButton,
    isRecognitionFlowModalOpen,
    canCurrentUserGiveRecognition,
    isRecognitionFeedSearchResultEmpty,
  } = models;

  const {
    refetch,
    onFlowClick,
    onSearchChange,
    feedContextProps,
    handleFetchNextPage,
    handleEditPostClick,
    handleCelebrateTeammateClick,
    setShowLoadMoreButtonToFalse,
    toggleIsRecognitionFlowModalOpen,
  } = operations;

  const { isMobileApp } = useMobileApp();
  const actionBarText = `${START_POST} 🎉 ${CELEBRATE_TEAM_MEMBER}`;

  const onLoadMoreClick = useCallback(() => {
    document.getElementById('scrollableFeedContent')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetch();
    setShowLoadMoreButtonToFalse();
  }, [refetch, setShowLoadMoreButtonToFalse]);

  const renderEmptyState = () => {
    if (profileData) {
      celebrateTeamMateDummyPostData.bodyProps.blocks[0] = {
        id: '1',
        type: 'PERSON_SELECTOR',
        content: {
          title: 'Who would you like to celebrate?',
          persons: [
            {
              id: '6081484184d8987b291e40b3',
              label: `${profileData.member.profile.firstName} ${profileData.member.profile.lastName}`,
              avatar: {
                img: profileData.member.profile.image,
                userId: profileData.member.memberId,
                name: profileData.member.profile.username,
              },
            },
          ],
        },
      };
    }
    if (isRecognitionFeedSearchResultEmpty) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    if (
      !isMainFeedFilterApplied &&
      data.length === 0 &&
      !isLoading &&
      profileData
    ) {
      const newCelebrateTeammateHeaderProps = {
        ...celebrateTeammateHeaderProps,
        handleOnFlowClick: ({ flowId }: { flowId: string }) =>
          onFlowClick(flowId),
      };

      return (
        <DummyFlowFeedPost
          assembly={profileData.assembly}
          currentUser={profileData.member}
          headerProps={newCelebrateTeammateHeaderProps}
          {...celebrateTeamMateDummyPostData}
        />
      );
    }
    if (isMainFeedFilterApplied) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  return (
    <FeedsContainer>
      {!isMobileApp && (
        <FeedSearchBarController
          searchValue={searchFeedValue}
          onSearchChange={onSearchChange}
        />
      )}
      {canCurrentUserGiveRecognition && profileData && (
        <StyledActionBarWrapper>
          <ActionBarContainer
            avatar={profileData.member.profile.image}
            userName={profileData.member.profile.username}
            userId={profileData.member.memberId}
            feedType={FeedTypes.Flow}
            text={actionBarText}
            onClick={handleCelebrateTeammateClick}
            hasDraft={drafts['recognition_' + profileData.assembly.assemblyId]}
          />
        </StyledActionBarWrapper>
      )}

      <Flex justifyContent="space-between" margin="0 auto 4px">
        {isRecognitionFlowModalOpen && profileData && (
          <RecognitionFlowController
            profileInfo={profileData}
            onPostSuccess={refetch}
            toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
          />
        )}
      </Flex>
      {isLoading && <FlowsFeedPostLoader />}
      {data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {!showSearchResults && showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          {
            <FeedContext.Provider value={feedContextProps}>
              {data.map((post, index) => (
                <FlowPostController
                  index={index}
                  key={post.postID}
                  post={post}
                  profileData={profileData}
                  showEditOption={
                    post.type === PostTypes.RECOGNITION &&
                    post.from?.memberID === profileData?.member.memberId
                  }
                  handleEditPostClick={handleEditPostClick}
                  showPostInteractionSettings={false}
                />
              ))}
            </FeedContext.Provider>
          }
        </InfiniteScroll>
      )}
      {!isLoading && data.length === 0 && renderEmptyState()}
    </FeedsContainer>
  );
};
