import React from 'react';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { REDEEM_CONTENT_MODAL_TEXTS } from '../../../languages/en/rewards';
import { Flex } from '../../../Utils/styles/display';
import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import {
  RewardCost,
  RewardDescription,
  RewardImageBeforeRedeem,
  RewardTitle,
} from '../../organism/RedeemSelectModal/styles';
import ToolTip from '../ToolTipV2';
import ThemeV2 from '../../../theme';
import styled from 'styled-components';

interface RedeemModalHeaderMoleculeProps {
  direction?: 'row' | 'column';
  image?: string;
  title: string;
  cost?: string | number;
  description?: string;
  assemblyCurrency: AssemblyCurrency;
  shippingCostDetails?: React.ReactNode;
}

const StyledTooltip = styled(ToolTip)`
  margin-left: 4px;
`;

const RedeemModalHeaderMolecule = (props: RedeemModalHeaderMoleculeProps) => {
  const {
    direction = 'column',
    image,
    title,
    cost,
    description,
    assemblyCurrency,
    shippingCostDetails,
  } = props;

  if (direction === 'column')
    return (
      <>
        {image && <RewardImageBeforeRedeem src={image} alt={title} />}
        <RewardTitle variant="h5" weight="bold">
          {title}
        </RewardTitle>
        <Flex>
          {cost && (
            <RewardCost
              variant="subHead1"
              data-testid="reward-cost"
              data-qa-id="reward-cost"
            >
              {REDEEM_CONTENT_MODAL_TEXTS.TOTAL_COST}{' '}
              <AssemblyCurrencyIcon
                assemblyCurrency={assemblyCurrency}
                currencyName={assemblyCurrency.name}
                id={assemblyCurrency.name}
                size="18px"
              />
              {cost}
            </RewardCost>
          )}
          {shippingCostDetails && (
            <StyledTooltip position="top" iconColor={ThemeV2.palette.gray8}>
              {shippingCostDetails}
            </StyledTooltip>
          )}
        </Flex>
        {description && (
          <RewardDescription variant="body3">{description}</RewardDescription>
        )}
      </>
    );
  else return <></>;
};

export default RedeemModalHeaderMolecule;
