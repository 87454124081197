/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { shallow } from 'zustand/shallow';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useCallback, useEffect, useMemo, useState } from 'react';

import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import useFlowBuilderEdit from '../../flowsBuilder/FlowsBuilderController/useFlowBuilderEdit';

import {
  emojiSelector,
  flowNameSelector,
  shareSheetModalOpenSelector,
  setShareSheetModalOpenSelector,
  newParticipantsToInviteSelector,
  shareSheetAnonymousToggleSelector,
  setNewParticipantsToInviteSelector,
  newVisibilityMembersToInviteSelector,
  setNewVisibilityMembersToInviteSelector,
} from '../../../stores/flowBuilderStore/selectors';

import {
  serializeVisibilityBlock,
  getCriteriaForAnonymityBasedOnDropdown,
} from '../../../Utils/flows/builder/utils';

import { mapRulesFromCriteriaGroups } from '../../../Utils/flows/criteria';
import { LOADING_LABEL } from '../../../languages/en/singleWords';

import { ShareSheetModalScreen, ShareSheetRuleAccessTypes } from './types';

import useToggle from '../../../hooks/useToggle';
import useOptions from '../../../hooks/useFlowFeedOptions/useOptions';

import { getErrorMessage } from '../../../Utils/message';
import { showErrorMessage, showInfoMessage } from '../../../Utils/toast';

import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import { useInviteUserPostQuery } from '../../../queries/Admin/invites';
import { useUpdateFlowAccessPolicy } from '../../../queries/Flows/Builder';

import {
  PARTICIPANTS_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  VIEWERS_LABEL,
  ANYONE_WITH_LINK,
} from '../../../languages/en/flows/sharesheet';

import {
  AccessRole,
  LinkAccessType,
  GetFlowDetailsResponse,
} from '../../../queries/Flows/interfaces';

import {
  AnonymousSettings,
  anonymousSettingsOptions,
  LinkAccessTypeMap,
  ShareSheetMemberActions,
} from './ShareSheetModalController/data';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import {
  trackShareSheetActionEvent,
  trackShareSheetErrorEvent,
} from '../../../Utils/analytics/shareSheet';
import { device } from '../../../constants/layout';
import useLayoutStore from '../../../stores/layoutStore';
import { toggleRightAsideOpenSelector } from '../../../stores/layoutStore/selectors';
import { useFlowsShareSheetRules } from './ShareSheetModalController/useFlowsShareSheetRules';
import {
  getLinkRole,
  hasAnAndCondition,
} from '../../../Utils/flows/sharesheet';
import useSlackUserStore from '../../../stores/userStore';
import { useSaveConnectionIntegration } from '../../../queries/Connections';
import {
  Channel,
  SaveConnectionIntegrationRequest,
} from '../../../interfaces/Connections';
import {
  ShareRequestsProps,
  useUpdateShareSheetRequests,
} from '../../../queries/Flows/Sharesheet';
import useRequestedUsersForAccessStore from '../../../stores/sharesheetStore';
import { getRequestRoleFromPermission } from '../../../Utils/flows';

const useFlowsShareSheetController = () => {
  // Mobile App
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { replace } = useHistory();
  const { search } = useLocation();
  const setToggleRightAsideOpen = useLayoutStore(toggleRightAsideOpenSelector);

  const closeShareSheet = useCallback(() => {
    const params = new URLSearchParams(search);
    // TODO: Sumedha - Use either and remove the other one showShareSheet/show-share-sheet
    params.delete('showShareSheet');
    params.delete('show-share-sheet');
    params.delete('tab');
    params.delete('open-request-tab');
    replace({
      search: params.toString(),
    });
    setToggleRightAsideOpen();
  }, [replace, search, setToggleRightAsideOpen]);

  // Link Access Settings
  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');

  const { isLoading: isMemberInviteInProgress, mutateAsync: inviteUser } =
    useInviteUserPostQuery();

  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const { requestedUsers } = useRequestedUsersForAccessStore();

  const {
    applicationCacheId,
    selectedConnectionId,
    setApplicationCacheId,
    setSelectedConnectionId,
    setSelectedConnectionName,
    selectedPeopleToPostChannels,
    setSelectedPeopleToPostChannels,
    selectedPostFlowResponseChannels,
    setSelectedPostFlowResponseChannels,
  } = useSlackUserStore();

  const { isConnectionsTabTouched, setIsConnectionsTabTouched } =
    useFlowBuilderStore();

  const resetConnectionsTab = useCallback(() => {
    setApplicationCacheId('');
    setSelectedConnectionId('');
    setSelectedConnectionName('');
    setSelectedPeopleToPostChannels([]);
    setSelectedPostFlowResponseChannels([]);
    setIsConnectionsTabTouched(false);
  }, [
    setApplicationCacheId,
    setSelectedConnectionId,
    setSelectedPeopleToPostChannels,
    setSelectedPostFlowResponseChannels,
    setIsConnectionsTabTouched,
    setSelectedConnectionName,
  ]);

  const {
    mutate: mutateFlowAccessPolicy,
    isLoading: isUpdatingInviteAccessPolicy,
  } = useUpdateFlowAccessPolicy(flowId);

  const { mutate: mutateShareRequests } = useUpdateShareSheetRequests(flowId);

  const { mutate: saveConnection, isLoading: isUpdatedConnectionsIntegration } =
    useSaveConnectionIntegration(flowId);

  const [hasModifiedChanges, setHasModifiedChanges] = useState(false);

  const [defaultSelectedLinkAccessType, setDefaultSelectedLinkAccessType] =
    useState(ONLY_PEOPLE_OR_RULES_LABEL);

  const [
    defaultSelectedLinkPermissionRole,
    setDefaultSelectedLinkPermissionRole,
  ] = useState(PARTICIPANTS_LABEL);

  const [defaultShowInDrawerValue, setDefaultShowInDrawerValue] =
    useState(true);

  const [selectedLinkAccessType, setSelectedLinkAccessType] = useState(
    ONLY_PEOPLE_OR_RULES_LABEL,
  );

  const [selectedLinkPermissionRole, setSelectedLinkPermissionRole] =
    useState(PARTICIPANTS_LABEL);

  const [showInDrawerValue, setShowInDrawerValue] = useState(true);

  const [currentModalScreen, setCurrentModalScreen] = useState(
    ShareSheetModalScreen.HOME,
  );

  const [visibilityTitleContent, setVisibilityTitleContent] = useState('');

  const [membersToBeInvited, setMembersToBeInvited] = useState<string[]>([]);

  const handleMembersToBeInvited = useCallback((members: string[]) => {
    setMembersToBeInvited(members);
  }, []);

  const [shareSheetRuleAccessType, setShareSheetRuleAccessType] =
    useState<ShareSheetRuleAccessTypes>(ShareSheetRuleAccessTypes.Simple);

  const updateModalCurrentState = useCallback(
    (currentState: ShareSheetModalScreen) => {
      setCurrentModalScreen(currentState);
    },
    [],
  );

  const {
    models: { toggleValue: isConfirmationModalOpen },
    operations: {
      setToggleToFalse: setIsConfirmationModalClose,
      setToggleToTrue: setIsConfirmationFlowModalOpen,
    },
  } = useToggle();

  const {
    models: { toggleValue: isSwitchingModalOpen },
    operations: {
      setToggleToTrue: setIsSwitchingModalOpen,
      setToggleToFalse: setIsSwitchingModalClose,
    },
  } = useToggle();

  const {
    handleChooseParticipants,
    handleChooseViewers,
    participantsCriteria,
    isViewerCriteriaLoading,
    visibilityCount,
    subtitleContent,
    updateCurrentVisibilityBlockData,
    updateParticipantsBlockData,
    currentParticipantsBlockData,
    handleVisibilityConfirmButtonClick,
    handleBackButtonClick,
    handleVisibilityBackButtonClick,
    visibilityBlockData,
    participantsCount,
    currentVisibilityBlockData,
    handleConfirmButtonClick,
    participantsBlockData,
    resetParticipationCriteria,
    resetVisibilityCriteria,
    totalViewersCount,
    visibilityCriteria,
    flattenParticipationCriteria,
    flattenVisibilityCriteria,
  } = useFlowsShareSheetRules({
    updateModalCurrentState,
    setHasModifiedChanges: (hasDataChanged: boolean) => {
      setHasModifiedChanges(hasDataChanged);
    },
  });

  const changeShareSheetAccessTypeText = useMemo(
    () =>
      shareSheetRuleAccessType === ShareSheetRuleAccessTypes.Advance
        ? 'You’re switching from sharing using advanced rules to sharing using simple rules.'
        : 'You’re switching from sharing using simple rules to sharing using advanced rules. We’ll translate your current rules into the advanced format for you.',
    [shareSheetRuleAccessType],
  );

  const changeShareSheetAccessTypeBoldText = useMemo(() => {
    if (shareSheetRuleAccessType === ShareSheetRuleAccessTypes.Advance) {
      return (participantsCriteria &&
        hasAnAndCondition(participantsCriteria) &&
        participantsCount > 100) ||
        (visibilityCriteria &&
          hasAnAndCondition(visibilityCriteria) &&
          totalViewersCount > 100)
        ? 'This will reset your rules to owner only permissions. This list will not auto-update.'
        : 'This will flatten your rules into a list of people. This list will not auto-update.';
    }
    return '';
  }, [
    participantsCount,
    participantsCriteria,
    shareSheetRuleAccessType,
    totalViewersCount,
    visibilityCriteria,
  ]);

  const handleShareSheetAccessTypeChangeOnConfirm = useCallback(
    (accessType: ShareSheetRuleAccessTypes) => {
      setShareSheetRuleAccessType(accessType);
      if (accessType === ShareSheetRuleAccessTypes.Simple) {
        if (
          participantsCriteria &&
          hasAnAndCondition(participantsCriteria) &&
          participantsCount > 100
        ) {
          resetParticipationCriteria();
        } else if (
          participantsCriteria &&
          hasAnAndCondition(participantsCriteria) &&
          participantsCount <= 100
        ) {
          flattenParticipationCriteria();
        }
        if (
          visibilityCriteria &&
          hasAnAndCondition(visibilityCriteria) &&
          totalViewersCount > 100
        ) {
          resetVisibilityCriteria();
        } else if (
          visibilityCriteria &&
          hasAnAndCondition(visibilityCriteria) &&
          totalViewersCount <= 100
        ) {
          flattenVisibilityCriteria();
        }
      }
      setHasModifiedChanges(true);
      setIsSwitchingModalClose();
    },
    [
      setIsSwitchingModalClose,
      participantsCriteria,
      participantsCount,
      visibilityCriteria,
      totalViewersCount,
      resetParticipationCriteria,
      flattenParticipationCriteria,
      resetVisibilityCriteria,
      flattenVisibilityCriteria,
    ],
  );

  const handleShareSheetAccessTypeChange = useCallback(
    (accessType: ShareSheetRuleAccessTypes) => {
      if (accessType !== shareSheetRuleAccessType) {
        setIsSwitchingModalOpen();
      } else {
        setShareSheetRuleAccessType(accessType);
      }
    },
    [setIsSwitchingModalOpen, shareSheetRuleAccessType],
  );

  const isShareSheetModalOpen = useFlowBuilderStore(
    shareSheetModalOpenSelector,
  );

  const { anonymousSettings, setAnonymousSettings } = useFlowBuilderStore(
    shareSheetAnonymousToggleSelector,
    shallow,
  );

  const setNewParticipantsToInvite = useFlowBuilderStore(
    setNewParticipantsToInviteSelector,
  );

  const setNewVisibilityMembersToInvite = useFlowBuilderStore(
    setNewVisibilityMembersToInviteSelector,
  );

  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );

  // Modal Handlers
  const flowName = useFlowBuilderStore(flowNameSelector);
  const emoji = useFlowBuilderStore(emojiSelector);

  useFlowBuilderEdit();

  const goToHomeScreen = useCallback(() => {
    setCurrentModalScreen(ShareSheetModalScreen.HOME);
  }, []);

  const {
    operations: { handleCopyFlowFeedLink },
  } = useOptions();

  const copyFlowFeedLinkHandler = () => {
    trackShareSheetActionEvent({
      flowName,
      tab: 'invite and share',
      action: SHARE_SHEET_EVENTS.COPY_LINK,
      ruleType: shareSheetRuleAccessType,
    });
    handleCopyFlowFeedLink(selectedLinkAccessType);
  };

  const handleOnShareSheetModalClose = useCallback(() => {
    if (!hasModifiedChanges) {
      resetConnectionsTab();
      setShareSheetModalOpen(false);
      closeShareSheet();
    } else {
      setIsConfirmationFlowModalOpen();
    }
  }, [
    closeShareSheet,
    hasModifiedChanges,
    resetConnectionsTab,
    setShareSheetModalOpen,
    setIsConfirmationFlowModalOpen,
  ]);

  const anonymousActionEventName = useMemo(() => {
    switch (anonymousSettings.id) {
      case 'anonymousOn':
        return SHARE_SHEET_EVENTS.ANONYMOUS_TOGGLE_ON;
      case 'anonymousOff':
        return SHARE_SHEET_EVENTS.ANONYMOUS_TOGGLE_OFF;
      case 'anonymousOptional':
        return SHARE_SHEET_EVENTS.ANONYMOUS_OPTIONAL_ON;
      default:
        return '';
    }
  }, [anonymousSettings.id]);

  const handleAnonymitySettingsConfirmButtonClick = useCallback(() => {
    trackShareSheetActionEvent({
      flowName,
      action: anonymousActionEventName,
      tab: 'invite and share',
      ruleType: shareSheetRuleAccessType,
    });
    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [
    flowName,
    goToHomeScreen,
    anonymousActionEventName,
    shareSheetRuleAccessType,
  ]);

  useEffect(() => {
    const titleContent = isViewerCriteriaLoading
      ? LOADING_LABEL
      : `${visibilityCount} viewers`;

    setVisibilityTitleContent(titleContent);
  }, [isViewerCriteriaLoading, setVisibilityTitleContent, visibilityCount]);

  const mapLinkAccessFromFlowDetails = useCallback(
    (flowDetailsResponse: GetFlowDetailsResponse) => {
      const linkAccessType =
        flowDetailsResponse.data.accessPolicy?.linkAccess.type;
      const linkAccessRoles =
        flowDetailsResponse.data.accessPolicy?.linkAccess.roles;

      const currentFlowRuleAccess = flowDetailsResponse.data?.metaData;

      const showInDrawer =
        flowDetailsResponse.data.accessPolicy?.linkAccess.showInLeftDrawer;

      setDefaultShowInDrawerValue(Boolean(showInDrawer));

      if (linkAccessType) {
        setDefaultSelectedLinkAccessType(LinkAccessTypeMap[linkAccessType]);
        setSelectedLinkAccessType(LinkAccessTypeMap[linkAccessType]);
      }

      if (linkAccessRoles && linkAccessRoles.length) {
        const linkAccessRole = getLinkRole(linkAccessRoles);
        setDefaultSelectedLinkPermissionRole(linkAccessRole);
      }

      const ruleAccessType = currentFlowRuleAccess?.ruleSelected
        ? currentFlowRuleAccess?.ruleSelected
        : ShareSheetRuleAccessTypes.Advance;

      setShareSheetRuleAccessType(ruleAccessType);
    },
    [],
  );

  useEffect(() => {
    if (flowDetails) {
      mapLinkAccessFromFlowDetails(flowDetails);
    }
  }, [flowDetails, mapLinkAccessFromFlowDetails]);

  useEffect(() => {
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);
  }, [defaultSelectedLinkPermissionRole]);

  useEffect(() => {
    setShowInDrawerValue(defaultShowInDrawerValue);
  }, [defaultShowInDrawerValue]);

  const handleOnUpdateSelectionClick = useCallback(() => {
    trackShareSheetActionEvent({
      flowName,
      tab: 'invite and share',
      ruleType: shareSheetRuleAccessType,
      action: SHARE_SHEET_EVENTS.UPDATE_LINK,
    });

    setShowInDrawerValue(defaultShowInDrawerValue);
    setSelectedLinkAccessType(defaultSelectedLinkAccessType);
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);

    goToHomeScreen();
  }, [
    flowName,
    goToHomeScreen,
    defaultShowInDrawerValue,
    shareSheetRuleAccessType,
    defaultSelectedLinkAccessType,
    defaultSelectedLinkPermissionRole,
  ]);

  const handleOnLinkSaveButtonClick = useCallback(() => {
    trackShareSheetActionEvent({
      flowName,
      tab: 'invite and share',
      action: SHARE_SHEET_EVENTS.NEW_LINK_SETTINGS,
      newLinkSettings: {
        accessType: selectedLinkAccessType,
        permissions: selectedLinkPermissionRole,
      },
      ruleType: shareSheetRuleAccessType,
    });
    setDefaultShowInDrawerValue(showInDrawerValue);
    setDefaultSelectedLinkAccessType(selectedLinkAccessType);
    setDefaultSelectedLinkPermissionRole(selectedLinkPermissionRole);

    goToHomeScreen();
    setHasModifiedChanges(true);
  }, [
    flowName,
    goToHomeScreen,
    selectedLinkAccessType,
    selectedLinkPermissionRole,
    shareSheetRuleAccessType,
    showInDrawerValue,
  ]);

  const handleOnLinkBackButtonClick = useCallback(() => {
    setShowInDrawerValue(defaultShowInDrawerValue);
    setSelectedLinkAccessType(defaultSelectedLinkAccessType);
    setSelectedLinkPermissionRole(defaultSelectedLinkPermissionRole);

    goToHomeScreen();
  }, [
    goToHomeScreen,
    defaultShowInDrawerValue,
    defaultSelectedLinkAccessType,
    defaultSelectedLinkPermissionRole,
  ]);

  const handleOnFlowVisibleInFlowShareSheetChanged = useCallback(() => {
    setShowInDrawerValue(!showInDrawerValue);
  }, [showInDrawerValue]);

  // TODO: Sumedha - refactor this
  const handleOnDoneButtonClicked = useCallback(async () => {
    trackShareSheetActionEvent({
      flowName,
      tab: 'invite and share',
      action: SHARE_SHEET_EVENTS.SHARE_SHEET_COMPLETED,
      modalVariant: 'ShareSheet home',
      participantCriteriaSelected: mapRulesFromCriteriaGroups(
        participantsBlockData.participantsCriteria,
        undefined,
        false,
      ),
      viewerCriteriaSelected: serializeVisibilityBlock(visibilityBlockData),
      ruleType: shareSheetRuleAccessType,
    });
    const membersToInvite = [
      ...newVisibilityMembersToInvite,
      ...newParticipantsToInvite,
      ...membersToBeInvited,
    ];

    if (!hasModifiedChanges) {
      resetConnectionsTab();
      setShareSheetModalOpen(false);
      return;
    }

    setIsConfirmationModalClose();
    setHasModifiedChanges(false);

    if (membersToInvite.length) {
      await inviteUser({
        data: membersToInvite.map((email) => {
          return { email: email };
        }),
      });
    }

    const mapLinkAccessTypeToName = (value: string) => {
      if (value === EVERYONE_IN_THIS_WORKSPACE_LABEL) {
        return LinkAccessType.INTERNAL;
      }

      if (value === ANYONE_WITH_LINK) {
        return LinkAccessType.EXTERNAL;
      }

      return LinkAccessType.RESTRICTED;
    };

    const mapLinkPermissionRoleToName = (value: string) => {
      if (value === PARTICIPANTS_LABEL) {
        return [AccessRole.PARTICIPANT];
      }

      if (value === VIEWERS_LABEL) {
        return [AccessRole.VIEWER];
      }

      return [AccessRole.VIEWER, AccessRole.PARTICIPANT];
    };

    const saveAccessPolicy = () => {
      const payload = {
        viewing: serializeVisibilityBlock(visibilityBlockData),
        participation: mapRulesFromCriteriaGroups(
          participantsBlockData.participantsCriteria,
          undefined,
          false,
          true,
        ) || {
          criteria: {
            custom: {
              condition: 'or',
              rules: [],
            },
          },
        },
        responseSettings: {
          anonymity: {
            state: getCriteriaForAnonymityBasedOnDropdown(
              anonymousSettings.id as AnonymousSettings,
            ),
          },
        },
        linkAccess: {
          showInLeftDrawer:
            showInDrawerValue &&
            selectedLinkAccessType === EVERYONE_IN_THIS_WORKSPACE_LABEL,
          type: mapLinkAccessTypeToName(selectedLinkAccessType),
          roles: mapLinkPermissionRoleToName(selectedLinkPermissionRole),
        },
        ruleSelected: shareSheetRuleAccessType,
      };

      const getAction = (permission: 'APPROVED' | 'REJECTED' | 'PENDING') => {
        switch (permission) {
          case 'APPROVED': {
            return 'APPROVE';
          }
          default: {
            return 'DENY';
          }
        }
      };

      const getRequestPermission = (
        requestPermission: ShareSheetMemberActions,
      ) => {
        switch (requestPermission) {
          case ShareSheetMemberActions.canOnlyPost: {
            return 'PARTICIPANT';
          }
          case ShareSheetMemberActions.canOnlyViewPosts: {
            return 'VIEWER';
          }
          default: {
            return 'PARTICIPANT_AND_VIEWER';
          }
        }
      };

      const getShareRequestsPayload = () => {
        const payloadData: ShareRequestsProps[] = requestedUsers
          .filter((user) => user.requestStatus !== 'PENDING')
          .map((user) => {
            return {
              shareRequestId: user.requestId,
              action: getAction(user.requestStatus),
              requestPermission: getRequestPermission(user.permission),
              source: 'SHARE_SHEET',
            };
          });
        return payloadData;
      };
      const payloadForShareRequests = getShareRequestsPayload();

      if (payloadForShareRequests.length) {
        mutateShareRequests(
          { shareRequests: payloadForShareRequests },
          {
            onSuccess: () => {
              payloadForShareRequests.forEach((shareRequest) => {
                trackShareSheetActionEvent({
                  action:
                    shareRequest.action === 'APPROVE'
                      ? SHARE_SHEET_EVENTS.SHARE_REQUEST_APPROVED
                      : SHARE_SHEET_EVENTS.SHARE_REQUEST_DENIED,
                  tab: 'invite and share',
                  shareRequestRole: getRequestRoleFromPermission(
                    shareRequest.requestPermission,
                  ),
                });
              });
            },
          },
        );
      }

      mutateFlowAccessPolicy(payload, {
        onSettled: () => {
          closeShareSheet();
          setShareSheetModalOpen(false);
        },
        onSuccess: () => {
          closeShareSheet();
          setShareSheetModalOpen(false);
          showInfoMessage('Updated permissions successfully');
        },
        onError: (error: unknown) => {
          trackShareSheetErrorEvent({
            tab: 'invite and share',
            error: SHARE_SHEET_EVENTS.SHARE_SHEET_ERROR,
            ruleType: shareSheetRuleAccessType,
          });

          const axiosError = error as AxiosError<{
            message: string;
            body: string;
          }>;
          if (axiosError) {
            const errorMessage = getErrorMessage(axiosError);
            if (errorMessage === `data/participation must be object`) {
              showErrorMessage('At least one participant needs to be selected');
            } else if (errorMessage === 'INVALID_CRITERIA') {
              showErrorMessage('Duplicate criteria added. Please update.');
            } else if (errorMessage) {
              showErrorMessage(errorMessage);
            }
          }
        },
      });
    };

    if (isConnectionsTabTouched && applicationCacheId && selectedConnectionId) {
      const savedConnectionsPayload: SaveConnectionIntegrationRequest = {
        integrations: [],
        connectionId: selectedConnectionId,
        applicationCacheId: applicationCacheId,
      };

      if (selectedPeopleToPostChannels.length) {
        savedConnectionsPayload.integrations.push({
          type: 'participationNotification',
          source: {
            type: 'channels',
            id: selectedPeopleToPostChannels.map((item: Channel) => item.id),
          },
        });
      }

      if (selectedPostFlowResponseChannels.length) {
        savedConnectionsPayload.integrations.push({
          type: 'responseNotification',
          source: {
            type: 'channels',
            id: selectedPostFlowResponseChannels.map(
              (item: Channel) => item.id,
            ),
          },
        });
      }

      saveConnection(savedConnectionsPayload, {
        onSettled: () => {
          resetConnectionsTab();
          saveAccessPolicy();
        },
      });
    } else {
      saveAccessPolicy();
    }
  }, [
    flowName,
    participantsBlockData.participantsCriteria,
    visibilityBlockData,
    newVisibilityMembersToInvite,
    newParticipantsToInvite,
    membersToBeInvited,
    hasModifiedChanges,
    setIsConfirmationModalClose,
    anonymousSettings.id,
    showInDrawerValue,
    selectedLinkAccessType,
    selectedLinkPermissionRole,
    shareSheetRuleAccessType,
    isConnectionsTabTouched,
    applicationCacheId,
    selectedConnectionId,
    mutateFlowAccessPolicy,
    resetConnectionsTab,
    setShareSheetModalOpen,
    inviteUser,
    selectedPeopleToPostChannels,
    selectedPostFlowResponseChannels,
    saveConnection,
    closeShareSheet,
    requestedUsers,
    mutateShareRequests,
  ]);

  useEffect(() => {
    return () => {
      setNewParticipantsToInvite([]);
      setNewVisibilityMembersToInvite([]);
      setMembersToBeInvited([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAnonymousSettingsSelection = useCallback(
    (value: string | number) => {
      if (value) {
        const selectedOption = anonymousSettingsOptions.find(
          (option) => option.id === value,
        );
        if (selectedOption) {
          setAnonymousSettings(selectedOption);
        }
      }
    },
    [setAnonymousSettings],
  );

  const handleOnModifiedChanges = useCallback((hasDataChanged: boolean) => {
    setHasModifiedChanges(hasDataChanged);
  }, []);

  const handleConfirmationModalClose = useCallback(() => {
    resetConnectionsTab();
    setIsConfirmationModalClose();
  }, [resetConnectionsTab, setIsConfirmationModalClose]);

  return {
    isMobileView,
    showInDrawerValue,
    visibilityCount,
    participantsCount,
    subtitleContent,
    selectedLinkAccessType,
    currentModalScreen,
    selectedLinkPermissionRole,
    isShareSheetModalOpen,
    visibilityTitleContent,
    isConfirmationModalOpen,
    isUpdatingFlowAccessPolicy:
      isUpdatedConnectionsIntegration || isUpdatingInviteAccessPolicy,
    currentVisibilityBlockData,
    currentParticipantsBlockData,
    visibilityParticipantsCriteria: participantsCriteria,
    flowName: `${emoji?.native} ${flowName}`,
    anonymousSettings,
    shareSheetAccessType: shareSheetRuleAccessType,
    handleShareSheetAccessTypeChange,
    handleAnonymousSettingsSelection,
    handleOnLinkSaveButtonClick,
    setSelectedLinkAccessType,
    goToHomeScreen,
    handleChooseViewers,
    handleBackButtonClick,
    setShareSheetModalOpen,
    handleCopyFlowFeedLink: copyFlowFeedLinkHandler,
    handleConfirmButtonClick,
    handleChooseParticipants,
    handleOnDoneButtonClicked,
    handleConfirmationModalClose,
    updateParticipantsBlockData,
    handleOnShareSheetModalClose,
    handleVisibilityBackButtonClick,
    updateCurrentVisibilityBlockData,
    handleVisibilityConfirmButtonClick,
    updateModalCurrentState,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnUpdateSelectionClick,
    handleOnLinkBackButtonClick,
    setSelectedLinkPermission: setSelectedLinkPermissionRole,
    handleOnFlowVisibleInFlowShareSheetChanged,
    handleOnModifiedChanges,
    handleMembersToBeInvited,
    isMemberInviteInProgress,
    isSwitchingModalOpen,
    setIsSwitchingModalClose,
    changeShareSheetAccessTypeChange: handleShareSheetAccessTypeChangeOnConfirm,
    changeShareSheetAccessTypeText,
    changeShareSheetAccessTypeBoldText,
  };
};

export default useFlowsShareSheetController;
