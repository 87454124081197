import React, { useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import InfiniteScroll from 'react-infinite-scroll-component';

import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import ThemeV2 from '../../../theme';
import Searching from '../../../img/searching.svg';
import PublicGroup from '../../../img/public-group.svg';
import { getProfileFullName } from '../../../Utils/user';
import { ComponentStatus } from '../../../interfaces/component';
import { PARTICIPANT_LIST_TEXTS } from '../../../languages/en/flows';

import {
  BottomWrapper,
  NoParticipantsWrapper,
  StyledBody,
  StyledImage,
  StyledMembersListEntryLoader,
  TopWrapper,
  StickyTopWrapper,
  UserWrapper,
  Wrapper,
  StyledFlex,
  StyledContentWrapper,
  StyledParticipantsListTitle,
} from './styles';
import {
  LoadedProps,
  LoadingProps,
  NoParticipantsProps,
  Participant,
  ParticipantsListProps,
} from './types';
import SVGIcon from '../../atoms/SVGIcon';

import { Flex } from '../../../Utils/styles/display';
import { MemberState } from '../../../interfaces/user';
import { PENDING } from '../../../languages/en/singleWords';
import {
  INVITE_PENDING,
  INVITE_UPON_FLOW_CREATION,
  INVITE_UPON_SAVE,
} from '../../../languages/en/flows/builder';

const NoParticipants = (props: NoParticipantsProps) => {
  const { image, text } = props;
  return (
    <NoParticipantsWrapper>
      <StyledImage src={image} alt="public-group" />
      <Body variant="body2" textAlign="center">
        {text}
      </Body>
    </NoParticipantsWrapper>
  );
};

const LoadedComponent = (props: LoadedProps) => {
  const {
    title,
    titleIcon,
    participants,
    fetchNextPage,
    hasMoreMembers = false,
    participantsCount,
    pendingMembersParticipantCount,
    hasNoMatchingParticipants,
    noMatchingParticipantsImage,
    noMatchingParticipantsLabel,
    restrictHeight = false,
    stickyHeader = false,
    isInEditMode,
  } = props;

  const renderHeader = useCallback(() => {
    return (
      <>
        <Flex justifyContent="center" alignItems="center">
          <StyledParticipantsListTitle variant="body2Bold">
            {title} ({participantsCount || 0})
          </StyledParticipantsListTitle>
          {titleIcon && (
            <SVGIcon
              icon={titleIcon}
              size="12px"
              color={ThemeV2.palette.white}
            />
          )}
        </Flex>
        <Body variant="body2">
          {pendingMembersParticipantCount || 0}&nbsp;
          {PENDING}
        </Body>
      </>
    );
  }, [participantsCount, pendingMembersParticipantCount, title, titleIcon]);

  const renderAvatar = (participant: Participant, fullName: string) => {
    let icon = '';

    if (participant.memberState === MemberState.PENDING) {
      icon = 'pending-person';
    }

    if (participant.memberState === MemberState.NEW) {
      icon = 'add-person';
    }

    return (
      <Avatar
        img={participant.image}
        name={fullName}
        size="32px"
        userId={participant.memberId}
        icon={icon}
      />
    );
  };

  const renderDescription = (memberState: MemberState | undefined) => {
    let description = '';
    if (memberState === MemberState.PENDING) {
      description = INVITE_PENDING;
    }
    if (memberState === MemberState.NEW) {
      if (isInEditMode) {
        description = INVITE_UPON_SAVE;
      } else {
        description = INVITE_UPON_FLOW_CREATION;
      }
    }

    if (description) {
      return (
        <StyledBody color="gray8" variant="body4">
          {description}
        </StyledBody>
      );
    }

    return null;
  };

  // For accurate scrolls, it is important to have unique IDs of each usage of this component
  const id = `${title.toLowerCase().replace(/ /g, '')}-scrollable-div`;

  return (
    <Wrapper
      id={id}
      stickyHeader={stickyHeader}
      restrictHeight={restrictHeight}
    >
      {stickyHeader && (
        <StickyTopWrapper justifyContent="space-between">
          {renderHeader()}
        </StickyTopWrapper>
      )}
      <InfiniteScroll
        hasMore={hasMoreMembers}
        loader={<StyledMembersListEntryLoader />}
        dataLength={participants?.length || 0}
        next={fetchNextPage}
        scrollableTarget={id}
      >
        {!stickyHeader && (
          <TopWrapper justifyContent="space-between">
            {renderHeader()}
          </TopWrapper>
        )}
        <BottomWrapper>
          {!participants && (
            <NoParticipants
              image={PublicGroup}
              text={PARTICIPANT_LIST_TEXTS.NO_PARTICIPANTS}
            />
          )}
          {participants && hasNoMatchingParticipants && (
            <NoParticipants
              image={noMatchingParticipantsImage || Searching}
              text={
                noMatchingParticipantsLabel ||
                PARTICIPANT_LIST_TEXTS.NO_MATCHING_PARTICIPANTS
              }
            />
          )}
          {participants &&
            participants.length > 0 &&
            !hasNoMatchingParticipants &&
            participants.map((participant) => {
              const fullName = getProfileFullName({
                firstName: participant.firstName,
                lastName: participant.lastName,
              });

              return (
                <UserWrapper
                  key={participant.memberId}
                  justifyContent="space-between"
                >
                  <StyledFlex>
                    {renderAvatar(participant, fullName)}
                    <StyledContentWrapper>
                      <StyledBody color="gray9" variant="body2">
                        {fullName}
                      </StyledBody>
                      {renderDescription(participant.memberState)}
                    </StyledContentWrapper>
                  </StyledFlex>
                  {participant.showLockIcon && (
                    <SVGIcon
                      icon="lock"
                      size="14px"
                      color={ThemeV2.palette.gray7}
                    />
                  )}
                </UserWrapper>
              );
            })}
        </BottomWrapper>
      </InfiniteScroll>
    </Wrapper>
  );
};

const LoadingComponent = ({
  title,
  titleIcon,
  restrictHeight = false,
}: LoadingProps) => {
  return (
    <Wrapper id="participants-list" restrictHeight={restrictHeight}>
      <TopWrapper justifyContent="space-between">
        <Flex justifyContent="center" alignItems="center">
          <StyledParticipantsListTitle variant="body2Bold">
            {title}
          </StyledParticipantsListTitle>
          {titleIcon && (
            <SVGIcon
              icon={titleIcon}
              size="12px"
              color={ThemeV2.palette.white}
            />
          )}
        </Flex>
        <ContentLoader
          speed={2}
          width={64}
          height={16}
          viewBox="0 0 64 16"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="participants-list-top-loader"
        >
          <rect x="0" y="0" rx="4" ry="4" width="64" height="16" />
        </ContentLoader>
      </TopWrapper>
      <BottomWrapper>
        <StyledMembersListEntryLoader />
      </BottomWrapper>
    </Wrapper>
  );
};

const ParticipantsList = (props: ParticipantsListProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED:
      return <LoadedComponent {...props} />;
    case ComponentStatus.LOADING:
      return <LoadingComponent {...props} />;
    default:
      throw new Error('Invalid Component Status');
  }
};

export default ParticipantsList;
