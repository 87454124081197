import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import ThemeV2 from '../../../theme';

const SharedStyle = css`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-style: normal;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeV2.palette.geekBlue6};
  transition: 0.2s ease color;

  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:focus {
    color: ${ThemeV2.palette.geekBlue7};
  }
  &:active {
    color: ${ThemeV2.palette.geekBlue7};
  }
`;

export const StyledRouterLink = styled(RouterLink)<{
  color?: string;
  underline?: boolean;
}>`
  ${SharedStyle}
  color: ${({ color }) => color};
  ${({ underline }) => underline && 'text-decoration: underline;'}
  &:hover,
  &:focus,
  &:active {
    color: ${({ color }) => color};
  }
`;

export const StyledAnchor = styled.a<{ color?: string; underline?: boolean }>`
  ${SharedStyle}
  color: ${({ color }) => color};
  ${({ underline }) => underline && 'text-decoration: underline;'}
  &:hover,
  &:focus,
  &:active {
    color: ${({ color }) => color};
  }
`;
