import React from 'react';

import { StyledWrapper, StyledTextWithIcon, StyledSpacing } from './styles';
import { FlowDetailsProps } from './types';
import Body from '../../../atoms/Body';

import ThemeV2 from '../../../../theme';

import {
  FLOW_ID,
  RESPONSE_VISIBILITY,
  ERROR_PULLING_DATA,
} from '../../../../languages/en/flows';
import {
  OWNER,
  DESCRIPTION,
  ABOUT,
  COLLABORATORS,
} from '../../../../languages/en/singleWords';

import { capitalizeFirstLetter } from '../../../../Utils/text';
import DrawerHeaderWithIcon from '../../../molecules/DrawerHeaderWithIcon';

const ErrorComponent = (props: FlowDetailsProps) => {
  const { toggleRightAsideOpen } = props;
  return (
    <>
      <DrawerHeaderWithIcon icon="info" onClick={toggleRightAsideOpen}>
        {ABOUT}{' '}
      </DrawerHeaderWithIcon>
      <StyledWrapper>
        <Body variant="body2" color="gray7">
          {DESCRIPTION}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <StyledTextWithIcon
          icon="error-warning"
          iconColor={ThemeV2.palette.gray6}
          iconSize="16px"
          variant="body1"
          color="gray6"
        >
          {ERROR_PULLING_DATA}
        </StyledTextWithIcon>
        <StyledSpacing margin="0 0 24px" />
        <Body variant="body2" color="gray7">
          {capitalizeFirstLetter(OWNER)}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <StyledTextWithIcon
          icon="error-warning"
          iconColor={ThemeV2.palette.gray6}
          iconSize="16px"
          variant="body1"
          color="gray6"
        >
          {ERROR_PULLING_DATA}
        </StyledTextWithIcon>
        <StyledSpacing margin="0 0 24px" />
        <>
          <Body variant="body2" color="gray7">
            {capitalizeFirstLetter(COLLABORATORS)}
          </Body>
          <StyledSpacing margin="0 0 8px" />
          <StyledTextWithIcon
            icon="error-warning"
            iconColor={ThemeV2.palette.gray6}
            iconSize="16px"
            variant="body1"
            color="gray6"
          >
            {ERROR_PULLING_DATA}
          </StyledTextWithIcon>
          <StyledSpacing margin="0 0 24px" />
        </>
        <Body variant="body2" color="gray7">
          {FLOW_ID}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <StyledTextWithIcon
          icon="error-warning"
          iconColor={ThemeV2.palette.gray6}
          iconSize="16px"
          variant="body1"
          color="gray6"
        >
          {ERROR_PULLING_DATA}
        </StyledTextWithIcon>
        <StyledSpacing margin="0 0 24px" />
        <Body variant="body2" color="gray7">
          {RESPONSE_VISIBILITY}
        </Body>
        <StyledSpacing margin="0 0 8px" />
        <StyledTextWithIcon
          icon="error-warning"
          iconColor={ThemeV2.palette.gray6}
          iconSize="16px"
          variant="body1"
          color="gray6"
        >
          {ERROR_PULLING_DATA}
        </StyledTextWithIcon>
      </StyledWrapper>
    </>
  );
};

export default ErrorComponent;
