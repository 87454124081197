import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { parse } from 'qs';

import { AUTH_SAML, SIGN_IN } from '../../constants/routes';
import { LOADING } from '../../languages/en/singleWords';
import { CONNECTING_TO, VERIFYING_SAML } from '../../languages/en/signIn';
import {
  useAuthenticateSAMLQuery,
  useSAMLConfigFetchQuery,
} from '../../queries/Auth/Sisense/saml';
import { SAMLAuthErrorCodes } from './data';
import { authenticateUser } from '../../Utils/session';
import useMobileApp from '../../hooks/useMobileApp';
import { APP_URL, NEW_APP_URL } from '../../config';
import { datadogLogs } from '@datadog/browser-logs';

const useSAMLAuthLogic = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [assemblyId, setAssemblyId] = useState<string | undefined>(undefined);
  const [samlName, setSamlName] = useState<string>('');
  const [errorCode, setErrorCode] = useState<SAMLAuthErrorCodes | undefined>(
    undefined,
  );

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const tokenFromURL = parsedParams && parsedParams.token;
    const assemblyIdFromURL = parsedParams && parsedParams.assemblyId;
    const errorCodeFromURL = parsedParams && parsedParams.error;

    if (tokenFromURL) {
      setToken(tokenFromURL as string);
    }
    if (assemblyIdFromURL) {
      setAssemblyId(assemblyIdFromURL as string);
    }
    if (errorCodeFromURL === SAMLAuthErrorCodes.SAML_NOT_ENABLED) {
      push(`${SIGN_IN}?error=${errorCodeFromURL}`);
    }
    if (errorCodeFromURL && !assemblyIdFromURL) {
      setErrorCode(errorCodeFromURL as SAMLAuthErrorCodes);
    }
  }, [push, search]);

  let loadingText = '';

  const hasToken = !!token;
  const { data, error } = useAuthenticateSAMLQuery(hasToken, token);

  const hasAssemblyId = !!assemblyId;
  const { isMobileApp } = useMobileApp();
  useEffect(() => {
    datadogLogs.logger.log('SAML Details', {
      details: {
        hasToken,
        tokenLength: token?.length,
        isMobileApp,
        data,
      },
    });
    if (data) {
      const { jwtToken, refreshToken, user } = data;
      if (isMobileApp && user && jwtToken) {
        window.location.href = `${NEW_APP_URL}/s/auth/saml?${new URLSearchParams(
          [
            ['jwtToken', jwtToken],
            ...(refreshToken ? [['refreshToken', refreshToken]] : []),
          ],
        )}`;
        return;
      }
      if (user && jwtToken) {
        authenticateUser(user);
        window.location.href = `${APP_URL}home`;
      }
    }
    if (error && !hasAssemblyId) {
      const errorCodeValue =
        error.response?.data.message || SAMLAuthErrorCodes.INVALID_PARAMETER;
      push(`${AUTH_SAML}?error=${errorCodeValue}`);
    }
  }, [data, error, push, hasAssemblyId, isMobileApp]);

  const { data: samlConfigData } = useSAMLConfigFetchQuery(
    hasAssemblyId,
    assemblyId,
  );
  useEffect(() => {
    if (samlConfigData) {
      const { name, uri } = samlConfigData;
      if (uri) {
        window.location.href = uri;
        setSamlName(name);
      }
    }
  }, [samlConfigData]);

  if (hasToken) {
    loadingText = VERIFYING_SAML;
  }
  if (hasAssemblyId) {
    if (samlName) {
      loadingText = `${CONNECTING_TO} ${samlName}`;
    } else {
      loadingText = LOADING;
    }
  }
  return {
    loadingText,
    errorCode,
  };
};

export default useSAMLAuthLogic;
