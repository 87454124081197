import {
  InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { UpdateReplyRequest } from '../../interfaces/Feed/Replies';
import { makeAPICall } from '../utils';
import {
  EDIT_POST_REPLY,
  GET_FLOW_POST_COMMENTS,
  GET_POST_COMMENTS,
} from '../../constants/endpoints';
import { GetCommentByPostResponse } from '../../interfaces/Feed';

const useEditPostComment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateReplyRequest) => {
      return makeAPICall(EDIT_POST_REPLY, payload, undefined, {
        replyId: payload.replyId,
      });
    },
    {
      onMutate: async (payload: UpdateReplyRequest) => {
        if (payload.flowId) {
          const queryKey = [
            GET_FLOW_POST_COMMENTS,
            payload.flowId,
            payload.responseId,
          ];

          const response =
            queryClient.getQueryData<InfiniteData<GetCommentByPostResponse>>(
              queryKey,
            );
          response?.pages.forEach((page) => {
            page.data.forEach((comment) => {
              if (comment.commentID === payload.replyId) {
                comment.isEditing = false;
                comment.editedAt = new Date();
                comment.isSaving = true;
                comment.message = payload.text || '';
                comment.gifURL = payload.gifUrl || '';
                comment.taggedUsers =
                  payload.taggedUsers?.map((x) => ({
                    memberID: x.id,
                    firstName: x.name,
                    lastName: '',
                  })) || [];
              }
            });

            queryClient.setQueryData(queryKey, response);
          });
        }

        if (payload.postId) {
          const queryKey = [GET_POST_COMMENTS, payload.postId];
          const response =
            queryClient.getQueryData<InfiniteData<GetCommentByPostResponse>>(
              queryKey,
            );

          response?.pages
            .filter((page) => page.data.length > 0)
            .forEach((page) => {
              page.data.forEach((comment) => {
                if (comment.commentID === payload.replyId) {
                  comment.isEditing = false;
                  comment.editedAt = new Date();
                  comment.isSaving = true;
                  comment.message = payload.text || '';
                  comment.gifURL = payload.gifUrl || '';
                  comment.taggedUsers =
                    payload.taggedUsers?.map((x) => ({
                      memberID: x.id,
                      firstName: x.name,
                      lastName: '',
                    })) || [];
                }
              });

              queryClient.setQueryData(queryKey, response);
            });
        }
      },
      onSuccess: async (data, payload: UpdateReplyRequest) => {
        const queryKey = [
          GET_FLOW_POST_COMMENTS,
          payload.flowId,
          payload.responseId,
        ];

        await queryClient.invalidateQueries(queryKey);
      },
    },
  );
};

export default useEditPostComment;
