import React from 'react';
import { withStyles } from '@mui/styles';
import MuiTabs from '@mui/material/Tabs';
import MuiTab, { TabProps } from '@mui/material/Tab';

import ThemeV2 from '../../../theme';

export const Tabs = withStyles({
  root: {
    background: ThemeV2.palette.gray1,
    minHeight: 'unset',
    marginBottom: '16px',
  },
  indicator: {
    backgroundColor: ThemeV2.palette.geekBlue6,
  },
})(MuiTabs);

export const Tab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: ThemeV2.palette.lightGray3,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    letterSpacing: '0.5px',
    padding: '0 0 4px 0',
    minHeight: 'unset',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: ThemeV2.palette.lightGray1,
    flex: '1',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    [theme.breakpoints.down('md')]: {
      minWidth: 196,
    },
  },
  selected: {
    color: ThemeV2.palette.darkText,
  },
}))((props: TabProps) => <MuiTab disableRipple {...props} />);

export interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};
