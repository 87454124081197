import styled, { css, keyframes } from 'styled-components';

import ThemeV2 from '../../../theme';
import { shadowElevation2Style } from '../../../Utils/styles/css';
import { device } from '../../../constants/layout';

const fadeInAnimation = keyframes`
  0% { background: ${ThemeV2.palette.geekBlue2} }
  100% { background: ${ThemeV2.palette.white} }
`;

export const CommentWrapper = styled.div<{
  isLoading: boolean;
  showAnimation?: boolean;
}>`
  margin: 0 16px 0 16px;
  padding-bottom: 4px;
  position: relative;
  border-radius: 4px;

  ${({ showAnimation }) =>
    showAnimation &&
    css`
      animation-delay: 200ms;
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-name: ${fadeInAnimation};
    `};

  &:before {
    content: ${({ isLoading }) => (isLoading ? `''` : `unset`)};
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${ThemeV2.palette.gray1};
    filter: opacity(0.5);
    z-index: 3;
  }

  @media ${device.tabletOrDesktop} {
    &:hover {
      background: ${ThemeV2.palette.gray2};
    }
  }
`;

export const StyledCommentBody = styled.div<{ variant?: 'feed' | 'sidebar' }>`
  padding-left: 48px;
  margin-top: ${({ variant }) => (variant === 'sidebar' ? `-24px` : `unset`)};

  > div:first-child {
    padding-top: 0;
  }
`;

export const StyledReactionBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 16px;
  margin-bottom: 4px;
`;

export const ActionsModal = styled.div`
  top: -12px;
  right: 8px;
  position: absolute;
  border-radius: 4px;
  ${shadowElevation2Style};
  z-index: zIndexCommentsActionBar;
  background-color: ${ThemeV2.palette.white};
  @media ${device.mobile} {
    z-index: unset;
  }
`;
