export const dismissCardMenuItems = [
  {
    id: 'dismissItems',
    items: [
      {
        id: 'dismissScheduleFlow',
        value: 'Dismiss',
        prefixIcon: 'close',
      },
    ],
  },
];
