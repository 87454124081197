import format from 'date-fns/format';
import React, { useEffect, useRef, useState } from 'react';
import ThemeV2 from '../../../theme';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { File } from '../FileCard/types';
import FileViewer from '../../organism/FileViewer';

import {
  DATE_NOT_FOUND,
  FILE_NOT_FOUND,
  SHARED_IN,
  VISIBILITY,
} from '../../../languages/en/feed';

import { Slider } from '@mui/material';

import {
  imagePreviewFileTypes,
  supportedPdfPreviewFileTypes,
  supportedPreviewFileTypes,
} from '../../../constants/files';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { ENTIRE_ORGANIZATION } from '../../../languages/en';
import {
  ONLY_FLOW_PARTICIPANTS,
  ONLY_OWNER,
} from '../../../languages/en/flows';
import {
  ANONYMOUS,
  CUSTOM,
  UPLOADED,
  YOU,
} from '../../../languages/en/singleWords';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';
import { GetFlowFileResponse } from '../../../queries/Flows/interfaces';
import { FileViewerEvents } from '../../../Utils/analytics/constants';
import {
  trackFileViewerActionEvent,
  trackFileViewerShowEvent,
} from '../../../Utils/analytics/fileViewer';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { FlowType } from '../FlowsPostHeader/interfaces';
import ToolTip from '../ToolTipV2';
import {
  ButtonsWrapper,
  DownloadButton,
  FileDescription,
  FooterContainer,
  HeaderText,
  ModalBackground,
  ModalBody,
  ModalHeading,
  ModalWrapper,
  NavigationButton,
  PageBreak,
  PageSelector,
  SideWrapper,
  SliderBox,
  SliderWrapper,
  StickyFooter,
  StyledAvatar,
  StyledBodyHeaderTitle,
  StyledIconButton,
  StyledSubheading,
  VerticalBar,
} from './styles';
import { AxiosError } from 'axios';
import Body from '../../atoms/Body';
import { DOWNLOAD_FILE } from '../../../languages/en/home';
import { PREVIEW_PAGE_LIMIT } from '../../organism/FileViewer/languages/en';

export interface FilePreviewModalProps {
  fileInformation?: File;
  isOpen: boolean;
  fileDownloadError?: AxiosError | null;
  fileToDownload?: GetFlowFileResponse;
  refetchDownloadLink?: () => void;
  fileToPreview?: GetFlowFileResponse;
  filePreviewError?: AxiosError | null;
  refetchPreviewLink?: () => void;
  isFilePreviewLoading?: boolean;
  isConvertedPdfPreviewsEnabled?: boolean;
  person?: FeedPostUserProps | null;
  flow?: FlowType;
  createdAt?: string;
  visibility?: VisibilityType;
  source: string;
  goToPost?: (flowId: string, responseId: string) => void;
  onClickDownload: (data: File) => void;
  onCloseModal: () => void;
  flowId?: string;
  responseId?: string;
  isAnonymous?: boolean;
  hasPrevious?: boolean;
  hasNext?: boolean;
  onFilePreviewNavigation?: (direction: 'previous' | 'next') => void;
  blockId?: string;
}

const idPrefix = 'pdf-viewer-page-';

export const renderVisibilityText = (visibility: string | undefined) => {
  if (visibility === 'custom') {
    return `${capitalizeFirstLetter(CUSTOM)}`;
  }
  if (visibility === 'everyone') {
    return ENTIRE_ORGANIZATION;
  }
  if (visibility === 'onlyParticipants') {
    return ONLY_FLOW_PARTICIPANTS;
  }
  return ONLY_OWNER;
};

export const PageBreakComponent = (props: { index: number }) => {
  const { index } = props;
  return <PageBreak>{index}</PageBreak>;
};

const FilePreviewModal = (props: FilePreviewModalProps) => {
  const {
    fileInformation,
    isOpen,
    fileDownloadError,
    fileToDownload,
    refetchDownloadLink,
    fileToPreview,
    filePreviewError,
    refetchPreviewLink,
    isFilePreviewLoading,
    isConvertedPdfPreviewsEnabled,
    onClickDownload,
    onCloseModal,
    goToPost,
    flowId,
    responseId,
    source,
    person,
    flow,
    createdAt,
    visibility,
    isAnonymous = false,
    hasPrevious,
    hasNext,
    onFilePreviewNavigation,
    blockId,
  } = props;

  useEffect(() => {
    trackFileViewerShowEvent({
      show: FileViewerEvents.FileViewerShow,
      source,
    });
  }, [source]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft' && hasPrevious) {
        onFilePreviewNavigation?.('previous');
      }
      if (event.key === 'ArrowRight' && hasNext) {
        onFilePreviewNavigation?.('next');
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [hasNext, hasPrevious, onFilePreviewNavigation]);

  const [defaultZoom, setDefaultZoom] = useState(100);
  const [zoomPercent, setZoomPercent] = useState(0);

  const bodyElement = useRef(null);

  useEffect(() => {
    setZoomPercent(defaultZoom);
  }, [defaultZoom]);

  const scrollToPage = (index: number) => {
    const myElement = document.getElementById(idPrefix + index);
    const topPos = myElement && myElement.offsetTop;
    if (bodyElement && bodyElement.current) {
      //@ts-ignore
      bodyElement.current.scrollTop = topPos;
    }
  };

  const PdfFooter = () => {
    return (
      <StickyFooter>
        <FooterContainer>
          <Body color="gray8" variant="body3">
            {PREVIEW_PAGE_LIMIT}
          </Body>
          <DownloadButton
            color="secondary"
            icon="download"
            onClick={() => fileInformation && onClickDownload(fileInformation)}
            size="small"
            variant="text"
          >
            <Body color="gray8" variant="body2">
              {DOWNLOAD_FILE}
            </Body>
          </DownloadButton>
        </FooterContainer>
      </StickyFooter>
    );
  };

  const PdfSideWrapper = (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => {
    const { index, children } = wrapperProps;
    return (
      <SideWrapper onClick={() => scrollToPage(index)}>
        <div style={{ height: '16px' }} />
        {children}
      </SideWrapper>
    );
  };

  const onSliderMove = (event: Event, value: number) => {
    setZoomPercent(value);
  };

  const sliderText = (value: number) => {
    return '' + value;
  };

  const fileType = fileInformation
    ? fileInformation.name.substring(
        fileInformation.name.lastIndexOf('.') + 1,
        fileInformation.name.length,
      )
    : '';

  const isImage = imagePreviewFileTypes.includes(fileType);

  const shouldUseConvertedFileFormat =
    isConvertedPdfPreviewsEnabled &&
    supportedPdfPreviewFileTypes.includes(fileType);

  const isSupported =
    supportedPreviewFileTypes.includes(fileType) ||
    shouldUseConvertedFileFormat;

  const isPreviewUnsupportedError = filePreviewError?.response?.status === 415;

  const previewFileType =
    shouldUseConvertedFileFormat && !isPreviewUnsupportedError
      ? 'pdf'
      : fileType;

  const filePath = shouldUseConvertedFileFormat //@ts-ignore
    ? fileToPreview?.data.url //@ts-ignore
    : fileToDownload?.data.url;

  const isPreviewTooLargeError = filePreviewError?.response?.status === 413;

  const hasPreviewError = shouldUseConvertedFileFormat
    ? !!filePreviewError
    : !!fileDownloadError;

  const hasProblemLoadingPreview = shouldUseConvertedFileFormat
    ? !isPreviewTooLargeError &&
      !isPreviewUnsupportedError &&
      !!filePreviewError
    : !!fileDownloadError;

  const reloadPreview = shouldUseConvertedFileFormat
    ? refetchPreviewLink
    : refetchDownloadLink;

  const zoomPercentOnClickChangeValue = 15;

  const maxZoom = 200;

  return (
    <ModalWrapper isOpen={isOpen} handleClose={onCloseModal}>
      <ModalBackground
        data-testid="filePreviewModal"
        data-qa-id="filePreviewModal"
      >
        <ModalHeading>
          <StyledAvatar
            img={person?.image}
            name={person?.firstName}
            userId={person?.memberID}
            size="40px"
            isAnonymous={isAnonymous}
            {...(isAnonymous && { iconSize: '40px' })}
          />
          <HeaderText>
            <StyledBodyHeaderTitle color="gray9" variant="body2">
              <span style={{ fontWeight: '500' }}>
                {person
                  ? person.memberID === '0'
                    ? `${YOU} `
                    : person?.firstName
                  : ANONYMOUS}
              </span>
              {` ${UPLOADED} `}
              <span style={{ fontWeight: '500' }}>
                {fileInformation
                  ? fileInformation.originalName ?? fileInformation.name
                  : `${FILE_NOT_FOUND}`}
              </span>
            </StyledBodyHeaderTitle>
            <StyledSubheading color="gray7" variant="body3">
              {createdAt
                ? format(new Date(createdAt), 'LLL do, yyyy')
                : `${DATE_NOT_FOUND}`}
              {flow && visibility && (
                <>
                  {' • '}
                  {SHARED_IN}{' '}
                  {flow ? mapHexCodeToEmoticon(flow.icon.value) : ''}{' '}
                  {flow?.name}
                  {' • '}
                  {`${VISIBILITY}: ` + renderVisibilityText(visibility)}
                </>
              )}
            </StyledSubheading>
          </HeaderText>
          <ButtonsWrapper>
            {goToPost && flowId && responseId && (
              <ToolTip
                toolTipComponent={
                  <StyledIconButton
                    height={32}
                    icon="go-to-post"
                    onClick={() => goToPost(flowId, responseId)}
                    size={ButtonSize.Normal}
                    iconColor={ThemeV2.palette.gray6}
                    dataTestId="goToPostModal"
                    dataQaId="goToPostModal"
                    margin="0 16px 0"
                  />
                }
                position="top"
                positionStrategy="fixed"
              >
                {'Go to post'}
              </ToolTip>
            )}
            {fileInformation && (
              <ToolTip
                toolTipComponent={
                  <StyledIconButton
                    height={32}
                    icon="download"
                    size={ButtonSize.Normal}
                    iconColor={ThemeV2.palette.gray6}
                    onClick={() => {
                      trackFileViewerActionEvent({
                        action: FileViewerEvents.DownloadFile,
                      });
                      onClickDownload(fileInformation);
                    }}
                  />
                }
                position="top"
                positionStrategy="fixed"
              >
                {'Download file'}
              </ToolTip>
            )}
            {((goToPost && flowId && responseId) || fileInformation) && (
              <VerticalBar height={32} sideMargin={16} />
            )}
            <StyledIconButton
              height={32}
              icon="close-rounded"
              onClick={onCloseModal}
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
              dataTestId="closeFilePreviewModal"
              dataQaId="closeFilePreviewModal"
            />
          </ButtonsWrapper>
        </ModalHeading>
        {isSupported && <FileDescription hasMessage={false} />}
        <ModalBody fullHeight={!isSupported}>
          {previewFileType === 'pdf' && !hasPreviewError && (
            <PageSelector>
              <FileViewer
                fileType={previewFileType}
                filePath={filePath}
                zoomPercent={zoomPercent}
                setDefaultZoom={setDefaultZoom}
                SubComponent={PageBreakComponent}
                WrapperComponent={PdfSideWrapper}
                hideLoader={true}
                FooterComponent={PdfFooter}
                flowId={flow?.flowId}
                responseId={responseId}
                blockId={blockId}
              />
            </PageSelector>
          )}
          <FileViewer
            fileType={previewFileType}
            filePath={filePath}
            zoomPercent={zoomPercent}
            setDefaultZoom={setDefaultZoom}
            viewerRef={bodyElement}
            idPrefix={idPrefix}
            hasNoPreview={isPreviewTooLargeError}
            onDownloadClick={() =>
              fileInformation && onClickDownload(fileInformation)
            }
            isLoading={isFilePreviewLoading}
            hasError={hasProblemLoadingPreview}
            onRetry={reloadPreview}
            isUnsupportedFileType={isPreviewUnsupportedError}
            flowId={flow?.flowId}
            responseId={responseId}
            blockId={blockId}
          />
        </ModalBody>

        {hasPrevious && (
          <NavigationButton
            icon="arrow-left"
            dataTestId="carousel-left-button"
            dataQaId="btn-left-carousal"
            direction="left"
            rounded
            onClick={() => onFilePreviewNavigation?.('previous')}
          />
        )}

        {hasNext && (
          <NavigationButton
            icon="arrow-right"
            dataTestId="carousel-right-button"
            dataQaId="btn-right-carousal"
            direction="right"
            rounded
            onClick={() => onFilePreviewNavigation?.('next')}
          />
        )}

        {isImage && (
          <SliderBox>
            <StyledIconButton
              height={24}
              icon="zoom-out"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent - zoomPercentOnClickChangeValue > defaultZoom) {
                  setZoomPercent(zoomPercent - zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(defaultZoom);
                }
              }}
            />
            <SliderWrapper>
              <Slider
                getAriaLabel={() => 'Temperature range'}
                value={zoomPercent}
                //@ts-ignore
                onChange={onSliderMove}
                valueLabelDisplay="auto"
                getAriaValueText={sliderText}
                track={false}
                min={defaultZoom}
                max={maxZoom}
              />
            </SliderWrapper>
            <StyledIconButton
              height={24}
              icon="zoom-in"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent + zoomPercentOnClickChangeValue < maxZoom) {
                  setZoomPercent(zoomPercent + zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(maxZoom);
                }
              }}
            />
            <VerticalBar height={24} sideMargin={8} />
            <StyledIconButton
              height={24}
              icon="reset-zoom"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
              onClick={() => setZoomPercent(defaultZoom)}
            />
          </SliderBox>
        )}
      </ModalBackground>
    </ModalWrapper>
  );
};
export default FilePreviewModal;
