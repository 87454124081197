import React, { useCallback, MouseEvent, useMemo } from 'react';
import Avatar from '../../atoms/Avatar';
import {
  NotificationWrapper,
  NotificationTextWrapper,
  TextContentWrapper,
  TimeAgoText,
  UnreadDot,
  NotificationLink,
  NotificationImage,
  StyledBody,
} from './styles';
import { NotificationItemProps } from './interface';
import SVGIcon from '../../atoms/SVGIcon';
import { truncateText } from '../../../Utils/formatting';
import {
  generateV3PostRoute,
  generateFlowResponseRoute,
  V2_HOME,
  FLOWS_FEEDS,
  NOTEBOOK_BOARD_VIEW,
} from '../../../constants/routes';
import ThemeV2 from '../../../theme';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { ANSWER } from '../../../languages/en/singleWords';
import { REMINDER_LABEL } from '../../../languages/en/notifications';
import IconContainer from '../../atoms/IconContainer';
import {
  NotificationTypes,
  CommentTypes,
} from '../../../interfaces/Notifications';
import {
  COMMENTED_ON_YOUR_POST,
  MENTIONED_YOU_IN_FLOW,
  MENTIONED_YOU_IN_FLOW_COMMENT,
  REPLIED_IN_A_THREAD_IN,
} from '../../../Utils/languages/notifications';
import { PostTypes } from '../../../interfaces/Home';
import { useLocation } from 'react-router';
import useMobileApp from '../../../hooks/useMobileApp';

const NotificationItem = (props: NotificationItemProps) => {
  const {
    timeAgo,
    avatar,
    onClick,
    closePopover,
    read,
    bodyText = '',
    headerText,
    icon,
    id,
    postID,
    notificationType = '',
    emoticon = '',
    emoticonBg = ThemeV2.palette.cyan2,
    flowId = '',
    occurrenceId = '',
    responseId = '',
    commentId = '',
    taskId = '',
    commentType,
  } = props;

  const location = useLocation();
  const { isMobileApp } = useMobileApp();

  const onReadDotClick = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const truncateTextLimit = useMemo(() => {
    return 60 - headerText.length;
  }, [headerText.length]);

  const isThreadComment =
    notificationType === NotificationTypes.COMMENT &&
    commentType === CommentTypes.THREAD_COMMENT;

  const isFlowComment = useMemo(() => {
    return (
      notificationType === NotificationTypes.COMMENT &&
      responseId &&
      commentType !== CommentTypes.THREAD_COMMENT
    );
  }, [notificationType, responseId, commentType]);

  const notificationItemLink = useMemo(() => {
    const notificationResponseId =
      typeof responseId === 'string' ? responseId : responseId?._id || '';
    if (
      notificationType === NotificationTypes.LIKE ||
      notificationType === NotificationTypes.POST ||
      notificationType === NotificationTypes.COMMENT ||
      notificationType === NotificationTypes.MENTIONS ||
      notificationType === NotificationTypes.TAGGED_IN_POST ||
      notificationType === NotificationTypes.TAGGED_IN_COMMENT
    ) {
      const params = new URLSearchParams({
        showReplies: 'true',
        showOriginalPost: 'true',
        postId: postID || '',
        flowId: flowId || '',
        commentId: commentId || '',
        responseId: notificationResponseId,
        type: flowId ? PostTypes.FLOW : PostTypes.RECOGNITION,
      }).toString();

      if (location.pathname.includes('notifications')) {
        if (flowId) {
          return generateFlowResponseRoute(flowId, notificationResponseId);
        } else {
          return generateV3PostRoute(postID);
        }
      }
      return `${location.pathname}?${params}`;
    }

    if (
      flowId &&
      (notificationType === NotificationTypes.FLOW_RESPONSE ||
        notificationType === NotificationTypes.SHARE_REQUEST_APPROVED)
    ) {
      return FLOWS_FEEDS.replace(':flowId', flowId);
    }

    if (
      flowId &&
      notificationType === NotificationTypes.SHARE_REQUEST_CREATED
    ) {
      const url = FLOWS_FEEDS.replace(':flowId', flowId);
      return `${url}?show-share-sheet=true&open-request-tab=true`;
    }

    if (taskId && notificationType === NotificationTypes.TASK_ACTIVITY) {
      return `${NOTEBOOK_BOARD_VIEW}?taskId=${taskId}`;
    }

    if (isFlowComment || notificationType === NotificationTypes.MENTIONS) {
      if (flowId && notificationResponseId) {
        return generateFlowResponseRoute(flowId, notificationResponseId);
      }

      if (postID) {
        return generateV3PostRoute(postID);
      }

      return V2_HOME;
    }

    return postID ? generateV3PostRoute(postID) : V2_HOME;
  }, [
    commentId,
    flowId,
    isFlowComment,
    location.pathname,
    notificationType,
    postID,
    responseId,
    taskId,
  ]);

  const onNotificationClick = useCallback(() => {
    if (onClick) {
      onClick({
        notificationType,
        read,
        id,
        flowId,
        occurrenceId,
        linkURL: notificationItemLink,
      });
    }
    if (closePopover) {
      closePopover();
    }
  }, [
    closePopover,
    flowId,
    id,
    notificationItemLink,
    notificationType,
    occurrenceId,
    onClick,
    read,
  ]);

  const renderImage = useMemo(() => {
    if (
      notificationType === NotificationTypes.FLOW_TRIGGERED ||
      notificationType === NotificationTypes.SHARE_REQUEST_APPROVED ||
      notificationType === NotificationTypes.SHARE_REQUEST_CREATED
    ) {
      return (
        <EmoticonHolder
          emoticon={emoticon}
          size={EmoticonHolderSizes.Medium3}
          color={emoticonBg}
          isRounded
        />
      );
    }

    if (notificationType === NotificationTypes.FLOW_REMINDER) {
      return (
        <IconContainer bgColor={ThemeV2.palette.orange2} borderRadius="50%">
          <SVGIcon color={ThemeV2.palette.orange9} icon="active-notification" />
        </IconContainer>
      );
    }

    if (icon) {
      return <SVGIcon icon={icon} size="40px" />;
    }

    if (avatar) {
      return <Avatar {...avatar} size="40px" />;
    }

    return null;
  }, [avatar, emoticon, emoticonBg, icon, notificationType]);

  const renderContent = useMemo(() => {
    let headerTextElement = (
      <StyledBody variant={isMobileApp ? 'body2Medium' : 'body3Medium'} inline>
        {headerText.length >= 80 ? truncateText(67, headerText) : headerText}
      </StyledBody>
    );

    if (notificationType === NotificationTypes.FLOW_TRIGGERED) {
      headerTextElement = (
        <StyledBody
          variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
          inline
        >
          {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
        </StyledBody>
      );
      const answerTextElement = (
        <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
          {ANSWER}
        </StyledBody>
      );
      return (
        <TextContentWrapper read={read || false}>
          {answerTextElement} {headerTextElement}
        </TextContentWrapper>
      );
    }

    if (notificationType === NotificationTypes.FLOW_REMINDER) {
      headerTextElement = (
        <StyledBody
          variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
          inline
        >
          {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
        </StyledBody>
      );
      const reminderTextElement = (
        <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
          {REMINDER_LABEL}
        </StyledBody>
      );
      return (
        <TextContentWrapper read={read || false}>
          {reminderTextElement} {headerTextElement}
        </TextContentWrapper>
      );
    }

    if (isThreadComment) {
      headerTextElement = (
        <StyledBody
          variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
          inline
        >
          {headerText.length >= 80 ? truncateText(67, headerText) : headerText}
        </StyledBody>
      );
      const commentedOnFlow = (
        <>
          <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
            {REPLIED_IN_A_THREAD_IN}
          </StyledBody>{' '}
          <StyledBody
            variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
            inline
          >
            {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
          </StyledBody>
        </>
      );
      return (
        <TextContentWrapper read={read || false}>
          {headerTextElement} {commentedOnFlow}
        </TextContentWrapper>
      );
    }

    if (isFlowComment) {
      headerTextElement = (
        <StyledBody
          variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
          inline
        >
          {headerText.length >= 80 ? truncateText(67, headerText) : headerText}
        </StyledBody>
      );
      const commentedOnFlow = (
        <>
          <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
            {COMMENTED_ON_YOUR_POST}
          </StyledBody>{' '}
          <StyledBody
            variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
            inline
          >
            {bodyText.length >= 80 ? truncateText(67, bodyText) : bodyText}
          </StyledBody>
        </>
      );
      return (
        <TextContentWrapper read={read || false}>
          {headerTextElement} {commentedOnFlow}
        </TextContentWrapper>
      );
    }

    const bodyTextElement = (
      <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
        {headerText.length < 80 && truncateText(truncateTextLimit, bodyText)}
      </StyledBody>
    );

    if (
      notificationType === NotificationTypes.MENTIONS ||
      notificationType === NotificationTypes.TAGGED_IN_POST
    ) {
      const mentionedText = commentId
        ? MENTIONED_YOU_IN_FLOW_COMMENT
        : MENTIONED_YOU_IN_FLOW;
      return (
        <TextContentWrapper read={read || false}>
          {headerTextElement}{' '}
          <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
            {mentionedText}{' '}
          </StyledBody>
          <StyledBody
            variant={isMobileApp ? 'body2Medium' : 'body3Medium'}
            inline
          >
            {headerText.length < 80 &&
              truncateText(truncateTextLimit, bodyText)}
          </StyledBody>
        </TextContentWrapper>
      );
    }

    if (notificationType === NotificationTypes.SHARE_REQUEST_CREATED) {
      return (
        <TextContentWrapper read={read || false}>
          <StyledBody variant={isMobileApp ? 'body2' : 'body3'} inline>
            {headerText} {bodyText}
          </StyledBody>
        </TextContentWrapper>
      );
    }

    return (
      <TextContentWrapper read={read || false}>
        {headerTextElement} {bodyTextElement}
      </TextContentWrapper>
    );
  }, [
    bodyText,
    commentId,
    headerText,
    isFlowComment,
    isThreadComment,
    isMobileApp,
    notificationType,
    read,
    truncateTextLimit,
  ]);

  if (
    Object.values(NotificationTypes).includes(
      notificationType as NotificationTypes,
    )
  ) {
    return (
      <NotificationLink to={!isMobileApp ? notificationItemLink : '#'}>
        <NotificationWrapper onClick={onNotificationClick} read={read}>
          <NotificationImage isMobileApp={isMobileApp}>
            {renderImage}
          </NotificationImage>
          <NotificationTextWrapper>
            {renderContent}
            {timeAgo && (
              <TimeAgoText
                variant={isMobileApp ? 'body2' : 'body3'}
                inline
                read={read || false}
              >
                {timeAgo}
              </TimeAgoText>
            )}
          </NotificationTextWrapper>
          {!read && (
            <UnreadDot
              onClick={onReadDotClick}
              data-testid="unread-indicator"
              data-qa-id="unread-indicator"
              isMobileApp={isMobileApp}
            />
          )}
        </NotificationWrapper>
      </NotificationLink>
    );
  }
  return null;
};

export default NotificationItem;
