import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { PageType } from '../../interfaces/Feed';

const useGetPageName = () => {
  const { pathname } = useLocation();

  const page: PageType = useMemo(() => {
    if (pathname.includes('/home')) {
      return 'main';
    }

    if (pathname.includes('/flows/recognition')) {
      return 'recognition';
    }

    if (pathname.includes('post/flow/recognition')) {
      return 'single post recognition';
    }

    if (pathname.includes('post/flow/flows')) {
      return 'single post flows';
    }

    if (pathname.includes('/flows')) {
      return 'flow';
    }

    if (pathname.includes('/notebook')) {
      return 'notebook';
    }

    if (pathname.includes('/rewards')) {
      return 'rewards';
    }

    if (pathname.includes('/people')) {
      return 'people';
    }

    return 'profile';
  }, [pathname]);

  return { page };
};

export default useGetPageName;
