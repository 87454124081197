import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import { RadioOptionProps } from '../../../../atomic/molecules/RadioGroup';
import {
  BUILDER_VISIBILITY_BLOCK,
  SHARE_SHEET_MODAL,
} from '../../../../languages/en/flows/builder';
import {
  ANYONE_WITH_LINK,
  EVERYONE_IN_THIS_WORKSPACE_LABEL,
  ONLY_PEOPLE_OR_RULES_LABEL,
} from '../../../../languages/en/flows/sharesheet';
import { LinkAccessType } from '../../../../queries/Flows/interfaces';
import Theme from '../../../../theme';
import ThemeV2 from '../../../../theme';
import { ShareSheetRuleAccessTypes, VisibilityTypes } from '../types';

export const visibilityRadioOptions: RadioOptionProps[] = [
  {
    value: VisibilityTypes.ENTIRE_ORGANIZATION,
    label: BUILDER_VISIBILITY_BLOCK.ENTIRE_ORGANIZATION,
  },
  {
    value: VisibilityTypes.PARTICIPANTS_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.PARTICIPANTS_ONLY,
  },
  {
    value: VisibilityTypes.OWNER_AND_COLLABORATORS_ONLY,
    label: BUILDER_VISIBILITY_BLOCK.OWNER_AND_COLLABORATORS_ONLY,
  },
  {
    value: VisibilityTypes.CUSTOM,
    label: BUILDER_VISIBILITY_BLOCK.CUSTOM,
  },
];

export enum AnonymousSettings {
  ANONYMOUS_OFF = 'anonymousOff',
  ANONYMOUS_ON = 'anonymousOn',
  ANONYMOUS_OPTIONAL = 'anonymousOptional',
}

export const anonymousSettingsOptions: MenuItemIndividualItem[] = [
  {
    id: AnonymousSettings.ANONYMOUS_OFF,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_NOT_ANONYMOUS,
  },
  {
    id: AnonymousSettings.ANONYMOUS_OPTIONAL,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_OPTIONALLY_ANONYMOUS,
  },
  {
    id: AnonymousSettings.ANONYMOUS_ON,
    value: SHARE_SHEET_MODAL.ALL_RESPONSES_ARE_ANONYMOUS,
  },
];

export const anonymousSettingsMenuItems: MenuItemProps[] = [
  {
    id: 'anonymousSettingsMenuItems',
    items: anonymousSettingsOptions,
  },
];

export const LinkAccessTypeMap = {
  [LinkAccessType.INTERNAL]: EVERYONE_IN_THIS_WORKSPACE_LABEL,
  [LinkAccessType.RESTRICTED]: ONLY_PEOPLE_OR_RULES_LABEL,
  [LinkAccessType.EXTERNAL]: ANYONE_WITH_LINK,
};

export enum ShareSheetMemberActions {
  canOnlyPost = 'canOnlyPost',
  canOnlyViewPosts = 'canOnlyViewPosts',
  canPostAndViewPosts = 'canPostAndViewPosts',
  excludeFromFlow = 'excludeFromFlow',
}

export const canOnlyPostSetting = {
  id: ShareSheetMemberActions.canOnlyPost,
  value: 'Can only post',
};
export const canOnlyViewPostsSetting = {
  id: ShareSheetMemberActions.canOnlyViewPosts,
  value: 'Can only view posts',
};
export const canPostAndViewPostsSetting = {
  id: ShareSheetMemberActions.canPostAndViewPosts,
  value: 'Can post & view posts',
};
export const excludedFromFlowSetting = {
  id: ShareSheetMemberActions.excludeFromFlow,
  value: 'Exclude from flow',
  helperText: 'Can’t post or view posts',
};

export const simplifiedShareSheetUserSettingsOptions: MenuItemIndividualItem[] =
  [
    canOnlyPostSetting,
    canOnlyViewPostsSetting,
    canPostAndViewPostsSetting,
    {
      id: 'remove',
      value: 'Remove',
      deleteItem: true,
    },
  ];

export const simplifiedShareSheetFlowOwnerSettingsOptions: MenuItemIndividualItem[] =
  [canPostAndViewPostsSetting];

export const getSimplifiedShareSheetFlowOwnerSettings = (
  hasNonOwnerParticipants: boolean,
): MenuItemProps[] => {
  const dropdownOptions = [...simplifiedShareSheetFlowOwnerSettingsOptions];
  if (hasNonOwnerParticipants) dropdownOptions.push(canOnlyViewPostsSetting);
  return [
    {
      id: 'accessSettingsMenuForOwner',
      items: dropdownOptions,
    },
  ];
};

export const simplifiedShareSheetUserSettings: MenuItemProps[] = [
  {
    id: 'accessSettingsMenuForNonOwner',
    items: simplifiedShareSheetUserSettingsOptions,
  },
];

export const simplifiedShareSheetBulkAddSettingsOptions: MenuItemIndividualItem[] =
  [
    canOnlyPostSetting,
    canOnlyViewPostsSetting,
    canPostAndViewPostsSetting,
    {
      id: 'remove',
      value: 'Delete rule',
      deleteItem: true,
    },
  ];

export const simplifiedShareSheetBulkAddSettings: MenuItemProps[] = [
  {
    id: 'accessSettingsMenuForDepartment',
    items: simplifiedShareSheetBulkAddSettingsOptions,
  },
];

export const shareSheetDropdownItems: MenuItemIndividualItem[] = [
  {
    value: `simple rules`,
    id: ShareSheetRuleAccessTypes.Simple,
  },
  {
    value: `advanced rules`,
    id: ShareSheetRuleAccessTypes.Advance,
  },
];

export const dropdownOptions: MenuItemProps[] = [
  {
    id: 'share-sheet-access-types',
    items: shareSheetDropdownItems,
  },
];

export const animationOptions = {
  duration: 1000,
  delay: 0.002,
  iterations: 1,
};

export const animationEffect = [
  {
    background: ThemeV2.palette.geekBlue2,
  },
  {
    background: ThemeV2.palette.white,
  },
];

export const bulkMemberActions = {
  title: '',
  id: 'bulk-member-actions',
  icon: '',
  key: 'bulk-member-actions',
  name: 'bulk-member-actions',
  email: 'bulk-member-actions',
};

export const getInviteUserCard = (inputValue: string) => ({
  id: 'invite-user-card',
  title: `Invite "${inputValue}" to your workspace`,
  emailToInvite: inputValue,
});

export const noResultsFoundCard = {
  id: 'no-results-found-card',
  title: 'No results found',
};

export const DepartmentAvatar = {
  icon: 'department',
  iconSize: '24px',
  iconColor: Theme.palette.white,
};

export const WorkLocationAvatar = {
  icon: 'building',
  iconSize: '24px',
  iconColor: Theme.palette.white,
};

export const HomeLocationAvatar = {
  icon: 'home',
  iconSize: '24px',
  iconColor: Theme.palette.white,
};

export const JobTitleAvatar = {
  icon: 'badge',
  iconSize: '24px',
  iconColor: Theme.palette.white,
};

export const SlackChannelAvatar = {
  icon: 'signin-slack',
  iconSize: '24px',
  iconColor: Theme.palette.white,
};

export const ConditionTextMap = {
  is: 'is',
  isNot: 'is not',
};

export const BulkRuleHelperTextMap = {
  workLocation: 'work location',
  homeLocation: 'home location',
  jobTitle: 'job title',
  department: 'department',
};

export const BulkMemberRuleAvatarMap = {
  homeLocation: HomeLocationAvatar,
  workLocation: WorkLocationAvatar,
  department: DepartmentAvatar,
  jobTitle: JobTitleAvatar,
};
