import { convertFromRaw, EditorState } from 'draft-js';
import * as Yup from 'yup';
import uuid from 'uuid';

import { OptionalArraySchema } from 'yup/lib/array';
import { AnyObject } from 'yup/lib/types';
import isNaN from 'lodash/isNaN';
import {
  MultiChoiceStaticBlockState,
  StaticBlockState,
  DropdownFlowBlock,
  FlowBlockFromAPI,
  FlowInternalBlockType,
  OpenEndedFlowBlock,
  ScaleFlowBlock,
  GifUploadFlowBlock,
  DropdownStaticBlockState,
  GenericStaticBlockState,
  ScaleStaticBlockState,
  BlockResponses,
  SubmitInstancePayload,
  OpenEndedBlockValue,
  AllowedFlowFileTypes,
  OpenEndedStaticBlockState,
  FileUploadFlowBlock,
  FlowFileForAPI,
  MultiChoiceFlowBlock,
  PersonSelectorFlowBlock,
  PersonSelectorStaticBlockState,
  GiveTrophiesStackFlowBlock,
  PointStackStaticBlockState,
  SelectablePeopleSelectorOptions,
  PreviewSummaryStaticBlockState,
  FlowBlockOption,
} from '../../../interfaces/Flow';

import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { MemberState, Mention } from '../../../interfaces/user';
import {
  CHOOSE_AN_OPTION,
  CHOOSE_OPTIONS,
  getDropdownExactErrorText,
  getDropdownMaxErrorText,
  getDropdownMinErrorText,
  getMultipleOptionsHelperText,
  getRangeHelperText,
  SELECT_PERSON,
  SELECT_PEOPLE,
  getMultiplePeopleHelperText,
  getPeopleExactErrorText,
  getPeopleMaxErrorText,
  getPeopleMinErrorText,
  getPeopleRangeHelperText,
} from '../../../languages/en/flows';
import { VALIDATION_ERRORS } from '../../../languages/en/flows/participation';
import { FlowInstanceResponse } from '../../../queries/Flows/interfaces';
// eslint-disable-next-line max-len
import { MultipleChoiceOption } from '../../../atomic/molecules/FlowInputBlocks/FlowsMultipleChoiceMultiselectInputBlock/types';
import { transformMessageForApi } from '../../home/RecognitionFlowController/utils';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  arrayRequiredTest,
  checkOpenEndedBlockMaximumCharacters,
  checkOpenEndedBlockMinimumCharacters,
  exactLengthTest,
  maximumCountTest,
  minimumCountTest,
  openEndedProfanityTest,
  openEndedRequiredTest,
} from '../../../Utils/flows/blockValidations';
import { GetFlowTemplateResponse } from '../../../queries/Flows/Template/types';
import { canCurrentUserGiveAllowance } from '../../../Utils/user';
import { ExternalFlowDetails } from './useLoadedParticipationFlowController';
import {
  FlowBlockResponse,
  FlowBlockResponsePerson,
  FlowFeedResponse,
  TagResponse,
} from '../../../queries/Flows/Feed/interfaces';
import { getRawDraftContentState } from '../../../Utils/message';
import ThemeV2 from '../../../theme';
import { File } from '../../../atomic/molecules/FileCard/types';
import { NotebookTask } from '../../../interfaces/notebook';
import { CurrentUser } from '../../../interfaces/currentUser';
import { getTaskCategoryFromAPI } from '../../../Utils/notebook';
import {
  getBadWordsList,
  getCanCheckForBadWords,
} from '../../../queries/Profile/utils';

type InitialValue = any;
type InitialValueObject = Record<string, InitialValue>;
type DefaultArrayBlockSchema = OptionalArraySchema<
  Yup.AnySchema<any, any, any>,
  AnyObject,
  InitialValue[] | undefined
>;

const arrayRequiredCheck = (blockSchema: DefaultArrayBlockSchema) =>
  blockSchema.test({
    name: 'required',
    test: arrayRequiredTest,
    message: VALIDATION_ERRORS.REQUIRED,
  });

const exactLengthCheck = (
  blockSchema: DefaultArrayBlockSchema,
  errorMessage: string,
  exactLength: number,
) =>
  blockSchema.test({
    name: 'exact',
    test: exactLengthTest(exactLength),
    message: errorMessage,
  });

const minimumItemsCheck = (
  blockSchema: DefaultArrayBlockSchema,
  errorMessage: string,
  minLength: number,
) =>
  blockSchema.test({
    name: 'minimumItems',
    test: minimumCountTest(minLength),
    message: errorMessage,
  });

const maximumItemsCheck = (
  blockSchema: DefaultArrayBlockSchema,
  errorMessage: string,
  maxLength: number,
) =>
  blockSchema.test({
    name: 'maximumItems',
    test: maximumCountTest(maxLength),
    message: errorMessage,
  });

const getReferenceStackId = (blocks: FlowBlockFromAPI[], key: string) =>
  blocks.find(
    ({ content }) =>
      content.type === 'GIVE_POINTS_STACK' &&
      content.dependentKeys.indexOf(key) > -1,
  )?.blockId;

type ProcessPersonSelectorBlockType = (
  block: PersonSelectorFlowBlock,
  blocks: FlowBlockFromAPI[],
  blockResponse?: FlowBlockResponse,
) => {
  type: 'SINGLE_PERSON_SELECTOR_DROPDOWN' | 'MULTI_PERSON_SELECTOR_DROPDOWN';
  initialValue: InitialValue;
  blockSchema: Yup.AnySchema;
  helperText: string;
  maxOptions?: number;
  minOptions?: number;
  referenceStackId?: string;
  selectableOptions?: SelectablePeopleSelectorOptions;
};

const mapPersonsToPersonSelectorOptions = (
  persons: FlowBlockResponsePerson[] = [],
) => {
  return persons.map((person: FlowBlockResponsePerson) => ({
    id: person.memberId,
    title: `${person.name.firstName} ${person.name.lastName}`,
    avatar: {
      img: person.image,
      userId: person.memberId,
      name: person.name.firstName,
      icon: person.memberState === MemberState.PENDING ? 'pending-person' : '',
      iconColor: ThemeV2.palette.white,
    },
  }));
};

const processPersonSelectorBlock: ProcessPersonSelectorBlockType = (
  { select_type, rules, key },
  blocks,
  blockResponse,
) => {
  let selectableOptions: SelectablePeopleSelectorOptions | undefined;
  const selectedPersonOptionValues = mapPersonsToPersonSelectorOptions(
    blockResponse?.persons,
  );

  if (rules) {
    selectableOptions = {
      type: rules.select,
      criteria: rules.criteria,
    };
  }
  if (select_type === 'SINGLE_PERSON') {
    let blockSchema = Yup.object()
      .shape({
        title: Yup.string(),
        id: Yup.string(),
      })
      .nullable();
    if (rules?.required) {
      blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
    }
    return {
      type: 'SINGLE_PERSON_SELECTOR_DROPDOWN',
      initialValue:
        selectedPersonOptionValues.length > 0
          ? selectedPersonOptionValues[0]
          : null,
      blockSchema,
      helperText: SELECT_PERSON,
      referenceStackId: key ? getReferenceStackId(blocks, key) : undefined,
      selectableOptions,
    };
  }
  let maxOptions: number | undefined;
  let minOptions: number | undefined;
  let helperText = SELECT_PEOPLE;
  let blockSchema = Yup.array();
  if (rules) {
    const { required, limit } = rules;
    if (required) {
      blockSchema = blockSchema.test({
        name: 'required',
        test: arrayRequiredTest,
        message: VALIDATION_ERRORS.REQUIRED,
      });
    }
    if (limit) {
      const { exact, range, noLimit } = limit;
      if (!noLimit) {
        if (exact) {
          const errorMessage = getPeopleExactErrorText(exact);
          blockSchema = exactLengthCheck(blockSchema, errorMessage, exact);
          helperText = getMultiplePeopleHelperText(exact);
          maxOptions = exact;
        } else if (range) {
          const { max, min } = range;
          blockSchema = minimumItemsCheck(
            blockSchema,
            getPeopleMinErrorText(min),
            min,
          );
          blockSchema = maximumItemsCheck(
            blockSchema,
            getPeopleMaxErrorText(max),
            max,
          );
          helperText = getPeopleRangeHelperText(min, max);
          maxOptions = max;
          minOptions = min;
        }
      }
    }
  }

  return {
    type: 'MULTI_PERSON_SELECTOR_DROPDOWN',
    initialValue:
      selectedPersonOptionValues.length > 0 ? selectedPersonOptionValues : [],
    blockSchema,
    helperText,
    minOptions,
    maxOptions,
    referenceStackId: key ? getReferenceStackId(blocks, key) : undefined,
    selectableOptions,
  };
};

type ProcessDropdownBlockType = (
  block: DropdownFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  type: 'SINGLE_SELECT_DROPDOWN' | 'MULTI_SELECT_DROPDOWN';
  options: AutocompleteDropdownItem<string>[];
  initialValue: InitialValue;
  blockSchema: Yup.AnySchema;
  helperText: string;
  maxOptions?: number;
};

const getSerializedOptions = (options: FlowBlockOption[] = []) => {
  const serializedOptions: AutocompleteDropdownItem<string>[] = options.map(
    ({ id, value }) => ({
      id,
      title: value,
    }),
  );
  return serializedOptions;
};

const processDropdownBlock: ProcessDropdownBlockType = (
  { rules, options }: DropdownFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  // HANDLE SAVED VALUES?
  let helperText = CHOOSE_AN_OPTION;
  const blockResponseValue = blockResponse?.value as FlowBlockOption[];
  const serializedOptions = getSerializedOptions(options);

  const serializedSelectedValues = blockResponseValue
    ? getSerializedOptions(blockResponseValue)
    : null;

  // CONDITIONS FOR A DROPDOWN TO BE A SINGLE SELECT DROPDOWN
  if (!rules || rules?.limit?.exact === 1) {
    const type = 'SINGLE_SELECT_DROPDOWN';
    const initialValue = serializedSelectedValues
      ? serializedSelectedValues[0]
      : null;
    let blockSchema = Yup.object()
      .shape({
        title: Yup.string(),
        id: Yup.string(),
      })
      .nullable();
    if (rules) {
      if (rules.required) {
        blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
      }
    }
    return {
      blockSchema,
      type,
      initialValue,
      options: serializedOptions,
      helperText,
    };
  }
  const { limit, required } = rules;
  const type = 'MULTI_SELECT_DROPDOWN';
  helperText = CHOOSE_OPTIONS;
  let maxOptions: number | undefined;
  let minOptions: number | undefined;
  let blockSchema = Yup.array();
  const initialValue: InitialValue = serializedSelectedValues
    ? serializedSelectedValues
    : [];
  if (required) {
    /* blockSchema = blockSchema.test(
      'required',
      VALIDATION_ERRORS.REQUIRED,
      (arr: InitialValue[], testContext) =>
        arr.length ||
        testContext.createError({ message: VALIDATION_ERRORS.REQUIRED }),
    ); */
    blockSchema = arrayRequiredCheck(blockSchema);
  }
  if (limit) {
    const { exact, range, noLimit } = limit;
    if (!noLimit) {
      if (exact) {
        const errorMessage = getDropdownExactErrorText(exact);
        blockSchema = exactLengthCheck(blockSchema, errorMessage, exact);
        helperText = getMultipleOptionsHelperText(exact);
        maxOptions = exact;
      } else if (range) {
        const { max, min } = range;
        blockSchema = minimumItemsCheck(
          blockSchema,
          getDropdownMinErrorText(min),
          min,
        );
        blockSchema = maximumItemsCheck(
          blockSchema,
          getDropdownMaxErrorText(max),
          max,
        );
        helperText = getRangeHelperText(min, max);
        maxOptions = max;
        minOptions = min;
      }
    }
  }
  return {
    blockSchema,
    type,
    initialValue,
    options: serializedOptions,
    helperText,
    maxOptions,
    minOptions,
  };
};

const processScaleBlock = (
  { rules, max, min }: ScaleFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  let blockSchema = Yup.number().nullable(true).min(min).max(max);
  const blockResponseValue = blockResponse?.value;
  const initialValue = blockResponseValue ? blockResponseValue : null;
  const type: FlowInternalBlockType = 'SCALE';
  if (rules?.required) {
    blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
  }
  return { blockSchema, initialValue, type };
};

const processFileUploadBlock = (
  { rules }: FileUploadFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  // TODO: Handle situation where a previous file has to be restored?
  let blockSchema = Yup.array();
  const initialValue = blockResponse?.files ? blockResponse?.files : [];
  const type: FlowInternalBlockType = 'FILE_UPLOAD';
  if (rules?.required) {
    blockSchema = blockSchema.min(1, VALIDATION_ERRORS.REQUIRED);
  }
  return { blockSchema, initialValue, type };
};

const processGifSelectionBlock = (
  { rules }: GifUploadFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  // TODO: Handle situation where a previous file has to be restored?
  let blockSchema = Yup.string();
  const blockResponseGifUrl = blockResponse?.value;
  const initialValue = blockResponseGifUrl ? blockResponseGifUrl : '';
  const type: FlowInternalBlockType = 'GIF_SELECTOR';
  if (rules?.required) {
    blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
  }
  return { blockSchema, initialValue, type };
};

export const getOpenEndedEditedBlockInitialValue = (
  blockResponse: FlowBlockResponse,
  member?: CurrentUser,
) => {
  const blockValue = blockResponse.value as string;
  const formattedSelectedMentions =
    blockResponse.mentions?.map((mention) => ({
      id: mention.memberId,
      name: `${mention.firstName} ${mention.lastName}`,
    })) || [];
  const formattedTags =
    blockResponse.tags?.map((tag: TagResponse) => ({
      id: tag.tag,
      name: tag.displayText,
    })) || [];
  const blockTasks = blockResponse.tasks?.map((task) => ({
    note: task.title,
    noteId: task.id,
    type: getTaskCategoryFromAPI(
      task.state,
      member?.timeZone || '',
      task.dueDate,
    ),
  }));
  const rawState = getRawDraftContentState(
    blockValue,
    formattedSelectedMentions,
    blockResponse.tags,
    blockTasks,
  );

  return {
    editorState: EditorState.createWithContent(convertFromRaw(rawState)),
    gifUrl: blockResponse.gifUrl || '',
    // Todo: pass actual files
    files: blockResponse.files,
    selectedMentions: formattedSelectedMentions || [],
    tags: formattedTags || [],
    tasks: blockTasks || [],
  };
};

const getOpenEndedBlockInitialValue = (
  blockResponse?: FlowBlockResponse,
  member?: CurrentUser,
): OpenEndedBlockValue => {
  if (blockResponse) {
    return getOpenEndedEditedBlockInitialValue(blockResponse, member);
  }

  return {
    editorState: EditorState.createEmpty(),
    gifUrl: undefined,
    files: [],
    selectedMentions: [],
    tags: [],
    tasks: [],
  };
};

const processOpenEndedBlock = (
  { rules }: OpenEndedFlowBlock,
  blockResponse?: FlowBlockResponse,
  member?: CurrentUser,
  profileData?: GetProfileInfoResponse,
) => {
  // TODO: Handle situation where a previous state has to be restored?
  const initialValue = getOpenEndedBlockInitialValue(blockResponse, member);
  let blockSchema = Yup.object();
  const type: FlowInternalBlockType = 'OPEN_ENDED';
  const shouldCheckForProfanity = getCanCheckForBadWords(profileData);

  if (shouldCheckForProfanity) {
    blockSchema = blockSchema.test(
      'profanityTest',
      VALIDATION_ERRORS.PROFANE_ERROR_MESSAGE,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      (
        {
          editorState,
        }: {
          editorState: EditorState;
        },
        testContext,
      ) =>
        openEndedProfanityTest(
          editorState,
          testContext,
          getBadWordsList(profileData),
        ),
    );
  }

  if (rules) {
    const {
      required,
      min: minimumCharacterLimit,
      max: maximumCharacterLimit = 10000,
    } = rules;

    if (required) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      blockSchema = blockSchema.test({
        name: 'required',
        test: openEndedRequiredTest,
        message: VALIDATION_ERRORS.REQUIRED,
      });
    }
    if (minimumCharacterLimit) {
      blockSchema = blockSchema.test(
        'minimumCharacterLimit',
        VALIDATION_ERRORS.UNDER_CHARACTER_LIMIT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        (
          {
            editorState,
            selectedMentions,
          }: {
            editorState: EditorState;
            selectedMentions: Mention[];
            tags: Mention[];
          },
          testContext,
        ) =>
          checkOpenEndedBlockMinimumCharacters(
            editorState,
            selectedMentions,
            testContext,
            minimumCharacterLimit,
          ),
      );
    }
    if (maximumCharacterLimit) {
      blockSchema = blockSchema.test(
        'maximumCharacterLimit',
        VALIDATION_ERRORS.OVER_CHARACTER_LIMIT,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore */
        (
          {
            editorState,
            selectedMentions,
          }: {
            editorState: EditorState;
            selectedMentions: Mention[];
            tags: Mention[];
          },
          testContext,
        ) =>
          checkOpenEndedBlockMaximumCharacters(
            editorState,
            selectedMentions,
            testContext,
            maximumCharacterLimit,
          ),
      );
    }
  }
  return { blockSchema, initialValue, type };
};

const processMultiChoiceBlock = (
  { optionType, options, rules }: MultiChoiceFlowBlock,
  blockResponse?: FlowBlockResponse,
) => {
  const isSingle = optionType === 'SINGLE';
  const blockResponseValue = blockResponse?.value as FlowBlockOption[];

  if (isSingle) {
    const type: FlowInternalBlockType = 'MULTI_CHOICE_SINGLE_SELECT';
    const initialValue =
      blockResponseValue && blockResponseValue.length > 0
        ? blockResponseValue[0]
        : null;
    let blockSchema = Yup.object()
      .shape({
        id: Yup.string(),
        value: Yup.string(),
      })
      .nullable();
    if (rules?.required) {
      blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
    }

    return {
      blockSchema,
      type,
      initialValue,
      options,
    };
  }

  const type: FlowInternalBlockType = 'MULTI_CHOICE_MULTI_SELECT';
  const initialValue: InitialValue =
    blockResponseValue && blockResponseValue.length > 0
      ? blockResponseValue
      : [];
  let blockSchema = Yup.array();
  if (rules?.required) {
    blockSchema = arrayRequiredCheck(blockSchema);
  }

  if (rules?.limit) {
    const { exact, range, noLimit } = rules.limit;
    if (!noLimit) {
      if (exact) {
        const errorMessage = getDropdownExactErrorText(exact);
        blockSchema = exactLengthCheck(blockSchema, errorMessage, exact);
      } else if (range) {
        const { max, min } = range;
        blockSchema = minimumItemsCheck(
          blockSchema,
          getDropdownMinErrorText(min),
          min,
        );
        blockSchema = maximumItemsCheck(
          blockSchema,
          getDropdownMaxErrorText(max),
          max,
        );
      }
    }
  }

  return {
    blockSchema,
    initialValue,
    type,
  };
};

const processPointsStack = (
  { rules, dependentKeys }: GiveTrophiesStackFlowBlock,
  blocks: FlowBlockFromAPI[],
  profileInfo: GetProfileInfoResponse,
  blockResponse?: FlowBlockResponse,
) => {
  const {
    member: { pointsLeftThisCycle, allowance },
  } = profileInfo;
  const responseBlockTrophyValue = blockResponse?.value;
  const initialValue = responseBlockTrophyValue ? responseBlockTrophyValue : '';
  let blockSchema = Yup.string();
  const dependentBlockKey =
    blocks.find(
      ({ content }) =>
        // CHECKS FOR ONLY THE MATCHING KEY IN THE FIRST POSITION;
        content.type === 'PERSON_SELECTOR' && content.key === dependentKeys[0],
    )?.blockId || '';
  let blockMaxPoints = pointsLeftThisCycle;
  if (rules) {
    const { required, points } = rules;
    if (required) {
      blockSchema = blockSchema.required(VALIDATION_ERRORS.REQUIRED);
    }
    if (points) {
      if (points.limitType === 'EXACT_VALUE') {
        blockMaxPoints = Math.min(blockMaxPoints, points.limit);
      } else if (points.limitType === 'PERCENTAGE') {
        blockMaxPoints = Math.min(
          blockMaxPoints,
          Math.floor((allowance.points * points.limit) / 100),
        );
      }
    }
  }
  return {
    blockSchema,
    initialValue,
    dependentBlockKey,
    blockMaxPoints,
  };
};

type ProcessTemplateFunctionType = (
  blocks: FlowBlockFromAPI[],
  profileInfo: GetProfileInfoResponse,
  previewTemplateData?: GetFlowTemplateResponse,
  externalFlowDetails?: ExternalFlowDetails,
  postData?: FlowFeedResponse,
  draft?: Record<string, any>,
) => [
  Yup.AnyObjectSchema,
  InitialValueObject,
  StaticBlockState[],
  InitialValueObject,
];

type ProcessedBlock = {
  schema: Yup.AnySchema;
  initialValue: InitialValue;
  blockId: string;
  blockData: StaticBlockState;
};

const reformatEntityValue = <T>(rawValue: T[], mappedKey: keyof T) => {
  return rawValue.length ? rawValue.map((elem) => elem[mappedKey]) : undefined;
};

export const processFlowBlocks: ProcessTemplateFunctionType = (
  blocks,
  profileInfo,
  previewTemplateData,
  externalFlowDetails,
  postData,
  draft = {},
) => {
  let processedBlockArr: ProcessedBlock[] = blocks.map((block, index) => {
    const { content } = block;
    const blockResponse = postData?.responses[index].response;
    let schema: Yup.AnySchema = Yup.mixed();
    const blockId: string = block.blockId || uuid.v4();
    let initialValue: InitialValue;
    let initialFiles: File[] | undefined;
    // Setting an initial value for type which will be overwritten later.
    let type: FlowInternalBlockType = 'FILE_UPLOAD';
    switch (content.type) {
      case 'DROPDOWN': {
        let options: AutocompleteDropdownItem<string>[];
        let helperText = '';
        let maxOptions: undefined | number;
        ({
          type,
          blockSchema: schema,
          initialValue,
          options,
          helperText,
          maxOptions,
        } = processDropdownBlock(content, blockResponse));
        return {
          schema,
          initialValue,
          blockId,
          blockData: {
            id: blockId,
            title: content.title,
            isRequired: Boolean(content?.rules?.required),
            type,
            description: content?.description?.text,
            options,
            helperText,
            maxOptions,
          } as DropdownStaticBlockState,
        };
      }
      case 'PERSON_SELECTOR': {
        let helperText = '';
        let maxOptions: undefined | number;
        let referenceStackId: undefined | string;
        let selectableOptions: SelectablePeopleSelectorOptions | undefined;
        ({
          type,
          blockSchema: schema,
          initialValue,
          helperText,
          maxOptions,
          referenceStackId,
          selectableOptions,
        } = processPersonSelectorBlock(content, blocks, blockResponse));
        return {
          schema,
          initialValue,
          blockId,
          blockData: {
            id: blockId,
            title: content.title,
            isRequired: Boolean(content?.rules?.required),
            type,
            description: content?.description?.text,
            helperText,
            maxOptions,
            referenceStackId,
            selectableOptions,
          } as PersonSelectorStaticBlockState,
        };
      }
      case 'SCALE': {
        ({
          blockSchema: schema,
          initialValue,
          type,
        } = processScaleBlock(content, blockResponse));
        return {
          schema,
          initialValue,
          blockId,
          blockData: {
            id: blockId,
            title: content.title,
            isRequired: Boolean(content?.rules?.required),
            type,
            min: content.min,
            max: content.max,
            labels: content.labels,
            description: content?.description?.text,
          } as ScaleStaticBlockState,
        };
      }
      case 'OPEN_ENDED': {
        ({
          blockSchema: schema,
          initialValue,
          type,
        } = processOpenEndedBlock(
          content,
          blockResponse,
          profileInfo.member,
          profileInfo,
        ));
        let allowEmojis = false;
        let allowFiles = false;
        let allowGifs = false;
        let allowMentions = false;
        let allowTasks = false;
        let allowedFileTypes: AllowedFlowFileTypes[] = [];
        if (content.rules?.allowedMedia?.length) {
          const { allowedMedia, fileType } = content.rules;
          allowEmojis = allowedMedia.some((media) => media === 'EMOJI');
          allowGifs = allowedMedia.some((media) => media === 'GIF');
          allowMentions =
            !externalFlowDetails &&
            allowedMedia.some((media) => media === 'MENTION');
          allowTasks =
            !externalFlowDetails &&
            allowedMedia.some((media) => media === 'TASKS');
          if (fileType?.length) {
            allowFiles = allowedMedia.some((media) => media === 'FILES');
            allowedFileTypes = fileType;
          }
        }
        return {
          schema,
          initialValue,
          blockId,
          blockData: {
            id: blockId,
            title: content.title,
            isRequired: Boolean(content?.rules?.required),
            type,
            allowEmojis,
            allowFiles,
            allowGifs,
            allowMentions,
            allowTasks,
            allowedFileTypes,
            minCharacters: content.rules?.min,
            maxCharacters: content.rules?.max,
            description: content.description?.text,
          } as OpenEndedStaticBlockState,
        };
      }
      case 'GIVE_POINTS_STACK': {
        let blockMaxPoints = 0;
        let dependentBlockKey = '';
        ({
          blockMaxPoints,
          blockSchema: schema,
          dependentBlockKey,
          initialValue,
        } = processPointsStack(content, blocks, profileInfo, blockResponse));
        return {
          schema,
          initialValue,
          blockId,
          blockData: {
            id: blockId,
            title: content.title,
            isRequired: Boolean(content?.rules?.required),
            type: 'GIVE_POINTS_STACK',
            description: content?.description?.text,
            maxPoints: blockMaxPoints,
            dependentBlockId: dependentBlockKey,
          } as PointStackStaticBlockState,
        };
      }
      case 'FILE_UPLOAD': {
        ({
          blockSchema: schema,
          initialValue,
          type,
        } = processFileUploadBlock(content, blockResponse));
        break;
      }
      case 'GIF': {
        ({
          blockSchema: schema,
          initialValue,
          type,
        } = processGifSelectionBlock(content, blockResponse));
        break;
      }
      case 'MULTI_CHOICE': {
        ({
          blockSchema: schema,
          initialValue,
          type,
        } = processMultiChoiceBlock(content, blockResponse));
        const blockData: MultiChoiceStaticBlockState = {
          allowOther: Boolean(content.rules?.allowOther),
          description: content.description?.text,
          id: blockId,
          limit: content.rules?.limit,
          options: content.options,
          title: content.title,
          isRequired: Boolean(content.rules?.required),
          type,
        };
        return {
          schema,
          initialValue,
          blockId,
          blockData,
        };
      }
      default: {
        break;
      }
    }
    return {
      schema,
      // TODO: WHAT IF RESTORE VALUES FROM BACKEND ISNT IN THE FORMAT WE EXPECT?
      initialValue,
      initialFiles,
      blockId,
      blockData: {
        id: blockId,
        title: content.title,
        isRequired: Boolean(content?.rules?.required),
        type,
        description: content?.description?.text,
      } as GenericStaticBlockState,
    };
  });
  if (!canCurrentUserGiveAllowance(profileInfo.member)) {
    processedBlockArr = processedBlockArr.filter(
      (block) => block.blockData.type !== 'GIVE_POINTS_STACK',
    );
  }
  if (previewTemplateData) {
    const { category, description, kind, title } =
      previewTemplateData.templateDetails;
    const blockId = uuid.v4();
    let triggerType = 'ONDEMAND';
    if (kind === 'SCHEDULED') {
      triggerType = 'SCHEDULED';
    } else {
      triggerType = kind;
    }
    processedBlockArr.unshift({
      blockId,
      initialValue: null,
      schema: Yup.number().nullable(true),
      blockData: {
        actionType: 'SEND_FORM',
        category,
        description: description.text,
        noOfBlocks: blocks.length,
        id: blockId,
        type: 'PREVIEW_SUMMARY',
        triggerType,
        title,
        creator: {
          name: previewTemplateData.creator,
        },
        icon: description.icon?.value || '',
      } as PreviewSummaryStaticBlockState,
    });
  }

  if (externalFlowDetails) {
    let length = blocks.length;
    const { name, category, description, kind, creator, icon } =
      externalFlowDetails;
    const blockId = uuid.v4();
    let triggerType = 'ONDEMAND';
    if (kind === 'SCHEDULED') {
      triggerType = 'SCHEDULED';
    } else {
      triggerType = kind;
    }
    processedBlockArr = processedBlockArr.filter((block) => {
      if (block.blockData.type === 'GIVE_POINTS_STACK') {
        length = length - 1;
      }
      return block.blockData.type !== 'GIVE_POINTS_STACK';
    });
    processedBlockArr.unshift({
      blockId,
      initialValue: null,
      schema: Yup.number().nullable(true),
      blockData: {
        actionType: 'SEND_FORM',
        category,
        description: description,
        noOfBlocks: length,
        id: blockId,
        type: 'EXTERNAL_FLOW',
        triggerType,
        title: name,
        creator: {
          name: creator.name,
        },
        icon: icon.value,
      } as PreviewSummaryStaticBlockState,
    });
  }
  const [schemaObj, initialValues, blockDataArr] = processedBlockArr.reduce<
    [Record<string, Yup.AnySchema>, InitialValueObject, StaticBlockState[]]
  >(
    (acc, obj) => {
      acc[0][obj.blockId] = obj.schema;
      acc[1][obj.blockId] = obj.initialValue;
      acc[2].push(obj.blockData);
      return acc;
    },
    [{}, {}, []],
  );

  const draftValues = { ...draft };
  const loadedDraftValues: InitialValueObject = {};

  Object.keys(initialValues).forEach((key) => {
    if (Object.keys(draft).includes(key)) {
      if (
        draft[key]?.editorState &&
        !draft[key].editorState.getCurrentContent
      ) {
        loadedDraftValues[key] = draftValues[key];
        loadedDraftValues[key].editorState = EditorState.createWithContent(
          convertFromRaw(draftValues[key].editorState),
        );
      } else {
        loadedDraftValues[key] = draftValues[key];
      }
    } else {
      loadedDraftValues[key] = initialValues[key];
    }
  });

  return [
    Yup.object().shape(schemaObj),
    initialValues,
    blockDataArr,
    loadedDraftValues,
  ];
};

type FormatBlockResponsesType = (
  submittedValues: Record<string, any>,
  instanceDetails: FlowInstanceResponse,
  isPrivatePost?: boolean,
  isAnonymousPost?: boolean,
  isExternalFlow?: boolean,
  isEditMode?: boolean,
  optionalInstanceDetails?: Record<string, any>,
) => SubmitInstancePayload;

export const formatBlockResponses: FormatBlockResponsesType = (
  submittedValues,
  instanceDetails,
  isPrivatePost,
  isAnonymousPost,
  isExternalFlow,
  isEditMode = false,
  optionalInstanceDetails = {},
) => {
  const blockResponses: BlockResponses[] = instanceDetails.blocks.map(
    ({ blockType, blockId }) => {
      switch (blockType) {
        case 'PERSON_SELECTOR': {
          const selectedOptions = submittedValues[blockId] as
            | AutocompleteDropdownItem<string>
            | AutocompleteDropdownItem<string>[]
            | null;
          const valueArr = Array.isArray(selectedOptions)
            ? selectedOptions
            : [...(selectedOptions ? [selectedOptions] : [])];
          return {
            blockId,
            response: {
              persons: valueArr.map(({ id }) => id),
            },
          };
        }
        case 'DROPDOWN': {
          const selectedOptions = submittedValues[blockId] as
            | AutocompleteDropdownItem<string>
            | AutocompleteDropdownItem<string>[]
            | null;
          const valueArr = Array.isArray(selectedOptions)
            ? selectedOptions
            : [...(selectedOptions ? [selectedOptions] : [])];
          return {
            blockId,
            response: {
              value: valueArr.map(({ id, title }) => ({
                id,
                value: title,
              })),
            },
          };
        }

        case 'FILE_UPLOAD': {
          const fileUploadBlockFiles = (
            submittedValues[blockId] as FlowFileForAPI[]
          ).map((fileBlockResponse) => {
            const fileUploadBlockErrorDetails =
              optionalInstanceDetails[instanceDetails.instanceId] || [];
            if (
              fileUploadBlockErrorDetails.length > 0 &&
              fileUploadBlockErrorDetails.includes(fileBlockResponse.name)
            ) {
              return {
                ...fileBlockResponse,
                size: 0,
              };
            }
            return fileBlockResponse;
          });
          return {
            blockId,
            response: {
              files: fileUploadBlockFiles,
            },
          };
        }
        case 'GIF': {
          return {
            blockId,
            response: {
              value: submittedValues[blockId] as string,
            },
          };
        }
        case 'GIVE_POINTS_STACK': {
          if (!isExternalFlow) {
            let value: number | null = parseInt(submittedValues[blockId], 10);
            if (isNaN(value)) {
              value = null;
            }
            return {
              blockId,
              response: {
                value,
              },
            };
          }
          return {
            blockId,
            response: {
              value: 0,
            },
          };
        }
        case 'SCALE': {
          return {
            blockId,
            response: {
              value: submittedValues[blockId] as number,
            },
          };
        }
        case 'OPEN_ENDED': {
          const { editorState, gifUrl, files, selectedMentions, tags, tasks } =
            submittedValues[blockId];
          const {
            text: transformedText,
            replacedMentions,
            replacedTags,
            replacedTasks,
          } = transformMessageForApi(
            editorState,
            selectedMentions || [],
            tags,
            tasks,
          );
          const formattedMentions = reformatEntityValue<Mention>(
            replacedMentions,
            'id',
          );
          const formattedTags = reformatEntityValue<Mention>(
            replacedTags,
            'id',
          );
          const formattedTasks = reformatEntityValue<NotebookTask>(
            replacedTasks,
            'noteId',
          );

          return {
            blockId,
            response: {
              value: transformedText,
              ...(files && { files }),
              ...(gifUrl && { gifUrl }),
              mentions: formattedMentions,
              tags: formattedTags,
              tasks: formattedTasks,
            },
          };
        }
        case 'MULTI_CHOICE': {
          const selectedOptions =
            submittedValues[blockId] ||
            ([] as MultipleChoiceOption | MultipleChoiceOption[]);

          const valueArr = Array.isArray(selectedOptions)
            ? selectedOptions
                .map((i: { id: string; value: string }) => {
                  if (i.id === 'other' && i.value === '')
                    return { ...i, value: 'other' };
                  return i;
                })
                .filter((x) => x.value)
            : [
                selectedOptions.id === 'other' && selectedOptions.value === ''
                  ? {
                      ...selectedOptions,
                      value: 'other',
                    }
                  : selectedOptions,
              ];
          return {
            blockId,
            response: {
              value: valueArr,
            },
          };
        }
        default: {
          return {
            blockId,
            response: {
              value: '',
            },
          };
        }
      }
    },
  );
  if (isExternalFlow) {
    return {
      instanceId: instanceDetails.instanceId,
      responses: blockResponses,
    };
  }
  return {
    responses: blockResponses,
    instanceId: instanceDetails.instanceId,
    ...(!isEditMode && { isPrivate: Boolean(isPrivatePost) }),
    ...(!isEditMode && { isAnonymous: Boolean(isAnonymousPost) }),
  };
};
