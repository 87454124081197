import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../theme';
import { MaximumRightContentWidth } from '../../../constants/layout';

type LoaderProps = {
  className?: string;
};

const Loader = (props: LoaderProps) => {
  const { className } = props;
  return (
    <ContentLoader
      speed={2}
      height={32}
      viewBox="0 0 380 32"
      className={className}
      width={MaximumRightContentWidth}
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4" ry="4" width="245" height="32" />
    </ContentLoader>
  );
};

export default Loader;
