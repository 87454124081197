import { Dispatch, SetStateAction, useState } from 'react';

import NewTextfield from '../../../atomic/molecules/NewTextfield';
import styled from 'styled-components';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import { trackPeopleDirectoryActionEvent } from '../../../Utils/analytics/peopleDirectory';
import { PEOPLE_DIRECTORY_EVENTS } from '../../../Utils/analytics/constants';

const SearchBar = styled(NewTextfield)`
  height: 40px;
`;

const Wrapper = styled.div`
  background: white;
  position: sticky;
  top: 0px;
  padding: 16px;
  z-index: 1;
`;

export const SearchPeopleDirectory = ({
  setSearchValue,
}: {
  setSearchValue: Dispatch<SetStateAction<string>>;
}) => {
  const [searchText, setSearchText] = useState('');

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      trackPeopleDirectoryActionEvent({
        action: PEOPLE_DIRECTORY_EVENTS.PEOPLE_SEARCH_APPLIED,
        searchQuery: searchText,
      });
      setSearchValue(searchText);
    }
    if (searchText === '') {
      setSearchValue('');
    }
  };

  return (
    <Wrapper>
      <SearchBar
        type="textV3_text"
        icon="search"
        placeholder="Search People"
        size="medium"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
          if (e.target.value === '') {
            setSearchValue('');
          }
        }}
        onKeyPress={handleKeypress}
        suffix={searchText && <SVGIcon icon={'close-rounded'} size="20px" />}
        onSuffixAdornmentClick={() => {
          setSearchText('');
          setSearchValue('');
        }}
      />
    </Wrapper>
  );
};
