import {
  FeedsContainer,
  FeedsGridContainerLayout,
  Flex,
} from '../../../Utils/styles/display';
import { isDesktopPlatform } from '../../../Utils/window';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { FLOWS, NEW_FLOWS_BUILDER } from '../../../constants/routes';
import hideBuilder from '../../../img/hide-builder.svg';
import { EDITOR, FEED, INSIGHTS } from '../../../languages/en/singleWords';

import {
  HIDE_BUILDER_BODY,
  HIDE_BUILDER_HEADER,
} from '../../../languages/en/flows/builder';

import { FlowsFeedController } from '../../flows/FlowsFeedController';
import FlowsInsightsController from '../../flowsInsights/FlowsInsightsController';
import FlowsBuilderController from '../FlowsBuilderController';
import { SyntheticEvent } from 'react';

const HideFlowsBuilder = () => {
  return (
    <Flex justifyContent="center">
      <EmptyErrorStateTemplate
        image={hideBuilder}
        heading={HIDE_BUILDER_HEADER}
        subHeading={HIDE_BUILDER_BODY}
      />
    </Flex>
  );
};

export const getTabComponents = ({
  canUserCreateFlow,
  flowId,
  slugUrl,
  folderId,
  onTabClick,
}: {
  canUserCreateFlow: boolean;
  flowId: string;
  slugUrl?: string;
  folderId: string | null;
  onTabClick?: (event: SyntheticEvent, label: string) => void;
}) => {
  if (flowId) {
    return {
      isNavigationEnabled: true,
      onTabClick,
      tabItems: [
        {
          tabHeader: {
            label: FEED,
            path: slugUrl
              ? `/${slugUrl}${FLOWS}/${flowId}`
              : `${FLOWS}/${flowId}`,
          },
          tabContent: (
            <FeedsGridContainerLayout>
              <FeedsContainer>
                <FlowsFeedController />
              </FeedsContainer>
            </FeedsGridContainerLayout>
          ),
        },
        {
          tabHeader: {
            label: INSIGHTS,
            path: slugUrl
              ? `/${slugUrl}${FLOWS}/${flowId}/insights`
              : `${FLOWS}/${flowId}/insights`,
          },
          tabContent: <FlowsInsightsController />,
        },
        ...(canUserCreateFlow
          ? [
              {
                tabHeader: {
                  label: EDITOR,
                  path: slugUrl
                    ? `/${slugUrl}${FLOWS}/${flowId}/editor`
                    : `${FLOWS}/${flowId}/editor`,
                  id: 'intro-flows-feed-step-3',
                },
                tabContent: isDesktopPlatform ? (
                  <FlowsBuilderController folderId={folderId} />
                ) : (
                  <HideFlowsBuilder />
                ),
              },
            ]
          : []),
      ],
    };
  }

  return {
    tabItems: [
      {
        tabHeader: {
          label: FEED,
          path: slugUrl ? `/${slugUrl}${FLOWS}` : `${FLOWS}`,
          disabled: true,
        },
        tabContent: null,
      },
      ...(canUserCreateFlow
        ? [
            {
              tabHeader: {
                label: EDITOR,
                path: slugUrl
                  ? `/${slugUrl}${NEW_FLOWS_BUILDER}`
                  : `${NEW_FLOWS_BUILDER}`,
                disabled: true,
              },
              tabContent: isDesktopPlatform ? (
                <FlowsBuilderController folderId={folderId} />
              ) : (
                <HideFlowsBuilder />
              ),
            },
          ]
        : []),
    ],
    isNavigationEnabled: true,
  };
};
