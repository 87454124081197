import format from 'date-fns/format';
import React, { useCallback, useMemo } from 'react';

import Avatar from '../../../atoms/Avatar';
import Body from '../../../atoms/Body';
import SVGIcon from '../../../atoms/SVGIcon';
import { TaskOptionProps } from './interfaces';
import { convertTokenizedObjectToString } from '../../../../Utils/notebook';
import { FORMAT_DATE } from '../../../../constants/notebook';
import {
  COMPLETED,
  NO_DUE_DATE,
  TODAY,
} from '../../../../languages/en/notebook';
import { TaskCategories } from '../../../../interfaces/notebook';
import { UNASSIGNED } from '../../../../languages/en/singleWords';
import {
  AvatarContainer,
  SecondaryInfoContainer,
  TaskContentContainer,
  TaskOptionContainer,
  TaskSecondaryInfo,
  TaskTitle,
} from './styles';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import ThemeV2 from '../../../../theme';

export const TaskOption = (props: TaskOptionProps) => {
  const { currentUserTimezone, onClick, ...notebookTaskData } = props;
  const { assignedTo, dueDate, note, type } = notebookTaskData;
  const serializedTitleCopy = convertTokenizedObjectToString(note);
  const dueDateCopy = useMemo(() => {
    if (!dueDate) {
      return NO_DUE_DATE;
    }

    if (type === TaskCategories.TODAY) {
      return capitalizeFirstLetter(TODAY);
    }

    return format(new Date(dueDate), FORMAT_DATE);
  }, [dueDate, type]);
  const dueDateStatusColor = useMemo(() => {
    switch (type) {
      case TaskCategories.UPCOMING:
        return 'geekBlue8';
      case TaskCategories.TODAY:
        return 'orange9';
      case TaskCategories.OVERDUE:
        return 'dustRed9';
      default:
        return 'gray8';
    }
  }, [type]);
  const avatar = !assignedTo ? (
    <SVGIcon icon="unassigned" size="24px" />
  ) : (
    <Avatar
      img={assignedTo.image}
      name={assignedTo.name}
      userId={assignedTo.memberID}
      size="24px"
    />
  );
  const assigneeName = !assignedTo
    ? capitalizeFirstLetter(UNASSIGNED)
    : assignedTo.name;
  const handleClick = useCallback(
    () => onClick(notebookTaskData),
    [onClick, notebookTaskData],
  );
  const handleKeydown = useCallback(
    (ev: React.KeyboardEvent<HTMLLIElement>) => {
      if (ev.key === 'Enter') {
        handleClick();
        ev.preventDefault();
      }
    },
    [handleClick],
  );

  return (
    <TaskOptionContainer
      onClick={handleClick}
      onKeyDown={handleKeydown}
      role="menuitem"
      tabIndex={0}
    >
      <SVGIcon
        circleStroke={
          type === TaskCategories.COMPLETED ? ThemeV2.palette.green7 : undefined
        }
        color={
          type === TaskCategories.COMPLETED
            ? ThemeV2.palette.green7
            : ThemeV2.palette.gray3
        }
        icon="checkbox-circle-unchecked"
        size="20px"
        stroke={
          type === TaskCategories.COMPLETED
            ? ThemeV2.palette.white
            : ThemeV2.palette.gray5
        }
      />
      <TaskContentContainer>
        <TaskTitle
          style={
            type === TaskCategories.COMPLETED
              ? { textDecoration: 'line-through' }
              : undefined
          }
          variant="body2"
        >
          {serializedTitleCopy}
        </TaskTitle>
        <SecondaryInfoContainer>
          {type !== TaskCategories.COMPLETED && (
            <Body inline variant="body2" color={dueDateStatusColor}>
              {dueDateCopy}
            </Body>
          )}
          {type === TaskCategories.COMPLETED && (
            <Body inline variant="body2">
              {capitalizeFirstLetter(COMPLETED)}
            </Body>
          )}
          <TaskSecondaryInfo $margin={8} inline variant="body2">
            •
          </TaskSecondaryInfo>
          <AvatarContainer>{avatar}</AvatarContainer>
          <TaskSecondaryInfo $margin={4} inline truncate variant="body2">
            {assigneeName}
          </TaskSecondaryInfo>
        </SecondaryInfoContainer>
      </TaskContentContainer>
    </TaskOptionContainer>
  );
};
