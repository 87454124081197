import { EditorState } from 'draft-js';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { Mention } from '../user';

import { File } from '../../atomic/molecules/FileCard/types';
import { NotebookTask } from '../notebook';

export type FlowBlockAPITypes =
  | 'DROPDOWN'
  | 'SCALE'
  | 'OPEN_ENDED'
  | 'TROPHIES'
  | 'PERSON_SELECTOR'
  | 'GIF'
  | 'FILE_UPLOAD'
  | 'MULTI_CHOICE'
  | 'GIVE_POINTS_STACK'
  | 'NPS';

export type FlowInternalBlockType =
  | 'MULTI_SELECT_DROPDOWN'
  | 'SCALE'
  | 'OPEN_ENDED'
  | 'TROPHIES'
  | 'PERSON_SELECTOR'
  | 'SINGLE_SELECT_DROPDOWN'
  | 'GIF_SELECTOR'
  | 'FILE_UPLOAD'
  | 'MULTI_CHOICE_SINGLE_SELECT'
  | 'MULTI_CHOICE_MULTI_SELECT'
  | 'MULTI_PERSON_SELECTOR_DROPDOWN'
  | 'SINGLE_PERSON_SELECTOR_DROPDOWN'
  | 'GIVE_POINTS_STACK'
  | 'PREVIEW_SUMMARY'
  | 'EXTERNAL_FLOW';
interface BaseStaticBlockState {
  id: string;
  title: string;
  description?: string;
  isRequired?: boolean;
  type: FlowInternalBlockType;
}

export interface ScaleLabels {
  low?: string;
  middle?: string;
  high?: string;
}

export type SelectablePeopleSelectorOptions = {
  type: SelectablePeopleTypes;
  criteria: any;
};
export interface GenericStaticBlockState extends BaseStaticBlockState {
  type: 'TROPHIES' | 'FILE_UPLOAD' | 'GIF_SELECTOR';
}

export interface ScaleStaticBlockState extends BaseStaticBlockState {
  type: 'SCALE';
  min: number;
  max: number;
  labels?: ScaleLabels;
}

export interface PreviewSummaryStaticBlockState extends BaseStaticBlockState {
  type: 'PREVIEW_SUMMARY' | 'EXTERNAL_FLOW';
  icon: string;
  triggerType: 'ONDEMAND' | 'SCHEDULED';
  actionType: 'SEND_FORM';
  noOfBlocks: number;
  creator: {
    name: string;
    icon?: string;
    description?: string;
  };
  category: string;
}

export interface PointStackStaticBlockState extends BaseStaticBlockState {
  type: 'GIVE_POINTS_STACK';
  maxPoints: number;
  dependentBlockId: string;
}

export interface OpenEndedStaticBlockState extends BaseStaticBlockState {
  type: 'OPEN_ENDED';
  allowEmojis?: boolean;
  allowFiles?: boolean;
  allowGifs?: boolean;
  allowMentions?: boolean;
  allowTasks?: boolean;
  allowedFileTypes?: AllowedFlowFileTypes[];
  minCharacters?: number;
  maxCharacters?: number;
}

export interface PersonSelectorStaticBlockState extends BaseStaticBlockState {
  type: 'MULTI_PERSON_SELECTOR_DROPDOWN' | 'SINGLE_PERSON_SELECTOR_DROPDOWN';
  helperText: string;
  maxOptions?: number;
  minOptions?: number;
  referenceStackId?: string;
  selectableOptions?: SelectablePeopleSelectorOptions;
}

export interface DropdownStaticBlockState extends BaseStaticBlockState {
  type: 'MULTI_SELECT_DROPDOWN' | 'SINGLE_SELECT_DROPDOWN';
  options: AutocompleteDropdownItem<string>[];
  helperText: string;
  maxOptions?: number;
  minOptions?: number;
}

export interface MultiChoiceStaticBlockState extends BaseStaticBlockState {
  type: 'MULTI_CHOICE_SINGLE_SELECT' | 'MULTI_CHOICE_MULTI_SELECT';
  allowOther: boolean;
  options: any[];
  limit?: {
    exact?: number;
    range?: {
      min: number;
      max: number;
    };
    noLimit?: boolean;
  };
}

export type StaticBlockState =
  | GenericStaticBlockState
  | ScaleStaticBlockState
  | DropdownStaticBlockState
  | OpenEndedStaticBlockState
  | MultiChoiceStaticBlockState
  | PersonSelectorStaticBlockState
  | PointStackStaticBlockState
  | PreviewSummaryStaticBlockState;

export enum StepperErrorType {
  REQUIRED = 'REQUIRED',
  FIX_ERROR = 'FIX_ERROR',
}

export type OpenEndedBlockValue = {
  gifUrl?: string;
  files?: File[];
  editorState: EditorState;
  selectedMentions: Mention[];
  tags: Mention[];
  isUploading?: boolean;
  tasks: Partial<NotebookTask>[];
};

export type AllowedFlowFileTypes =
  | 'jpg'
  | 'jpeg'
  | 'png'
  | 'svg'
  | 'gif'
  | 'pdf'
  | 'doc'
  | 'docx'
  | 'ppt'
  | 'pptx'
  | 'pps'
  | 'ppsx'
  | 'xls'
  | 'xlsx'
  | 'csv'
  | 'txt'
  | 'odt'
  | 'zip'
  | 'mp3'
  | 'm4a'
  | 'ogg'
  | 'wav'
  | 'mp4'
  | 'm4v'
  | 'mov'
  | 'wmv'
  | 'avi'
  | 'mpg'
  | 'ogv'
  | '3gp'
  | '3g2';

export type AllowedOpenEndedMediaTypes =
  | 'EMOJI'
  | 'GIF'
  | 'FILES'
  | 'FORMATTING'
  | 'MENTION'
  | 'TASKS';

export enum FlowVariants {
  PARTICIPATION_FLOW = 'PARTICIPATION_FLOW',
  RECOGNITION_FLOW = 'RECOGNITION_FLOW',
  PREVIEW_FLOW = 'PREVIEW_FLOW',
  EXTERNAL_FLOW = 'EXTERNAL_FLOW',
}

interface BaseDynamicBlockState {
  id: string;
  title: string;
  isError: boolean;
  isValid: boolean;
  errorMessage?: string;
  errorType?: StepperErrorType;
  isEmpty?: boolean;
}

export type DynamicBlockState = BaseDynamicBlockState;

export type Flow = {
  id: string;
  title: string;
  dueDate: Date;
  emoticon: string;
  lastRunDate: Date;
  emoticonBg: string;
  hasShortcut: boolean;
};

type FlowDescription = {
  text: string;
  icon?: {
    type: string;
    value: string;
  };
};

export type FlowBlockOption = {
  id: string;
  value: string;
};

export interface BasicFlowBlockAttributes {
  blockId?: string;
  type: FlowBlockAPITypes;
  title: string;
  description?: FlowDescription;
}

export type FlowRuleLimit = {
  exact?: number;
  noLimit?: boolean;
  range?: {
    min: number;
    max: number;
  };
};
export interface BasicFlowRuleAttributes {
  required?: boolean;
  editable?: boolean;
}

export interface DropdownBlockRules extends BasicFlowRuleAttributes {
  limit?: FlowRuleLimit;
}

export interface MultichoiceBlockRules extends BasicFlowRuleAttributes {
  limit?: FlowRuleLimit;
  allowOther?: boolean;
}

type ScaleBlockRules = BasicFlowRuleAttributes;
type GifUploadBlockRules = BasicFlowRuleAttributes;

export type SelectablePeopleTypes =
  | 'EVERYONE'
  | 'PARTICIPANTS'
  | 'VIEWERS'
  | 'CUSTOM';

export interface PersonSelectorBlockRules extends BasicFlowRuleAttributes {
  select: SelectablePeopleTypes;
  criteria?: Record<string, any>;
  limit?: FlowRuleLimit;
}

export interface OpenEndedBlockRules extends BasicFlowRuleAttributes {
  min?: number;
  max?: number;
  allowedMedia?: AllowedOpenEndedMediaTypes[];
  notifyMentionedIndividual?: boolean;
  fileType?: AllowedFlowFileTypes[];
}

export interface GivePointsStackRules extends BasicFlowRuleAttributes {
  points?: {
    limitType: 'EXACT_VALUE' | 'PERCENTAGE';
    noLimit?: boolean;
    limit: number;
  };
  hidePoints?: boolean;
}

export interface FileUploadBlockRules extends BasicFlowRuleAttributes {
  type?: AllowedFlowFileTypes[];
}

export interface DropdownFlowBlock extends BasicFlowBlockAttributes {
  type: 'DROPDOWN';
  options: FlowBlockOption[];
  rules?: DropdownBlockRules;
}

export interface PersonSelectorFlowBlock extends BasicFlowBlockAttributes {
  type: 'PERSON_SELECTOR';
  rules?: PersonSelectorBlockRules;
  select_type: 'SINGLE_PERSON' | 'MULTI_PERSON';
  key?: string;
}

export interface GiveTrophiesStackFlowBlock extends BasicFlowBlockAttributes {
  type: 'GIVE_POINTS_STACK';
  dependentKeys: string[];
  rules: GivePointsStackRules;
}

export interface ScaleFlowBlock extends BasicFlowBlockAttributes {
  type: 'SCALE';
  min: number;
  max: number;
  labels?: ScaleLabels;
  rules?: ScaleBlockRules;
  isNPSEnabled?: boolean;
}

export interface NPSFlowBlock extends BasicFlowBlockAttributes {
  type: 'NPS';
  min: number;
  max: number;
  labels?: ScaleLabels;
  rules?: ScaleBlockRules;
  isNPSEnabled?: boolean;
}

export interface OpenEndedFlowBlock extends BasicFlowBlockAttributes {
  type: 'OPEN_ENDED';
  rules?: OpenEndedBlockRules;
}

export interface FileUploadFlowBlock extends BasicFlowBlockAttributes {
  type: 'FILE_UPLOAD';
  rules?: FileUploadBlockRules;
}

export interface GifUploadFlowBlock extends BasicFlowBlockAttributes {
  type: 'GIF';
  rules?: GifUploadBlockRules;
}

export interface MultiChoiceFlowBlock extends BasicFlowBlockAttributes {
  type: 'MULTI_CHOICE';
  optionType: 'MULTI' | 'SINGLE';
  options: FlowBlockOption[];
  rules?: MultichoiceBlockRules;
}

export type FlowBlockContent =
  | DropdownFlowBlock
  | ScaleFlowBlock
  | OpenEndedFlowBlock
  | FileUploadFlowBlock
  | GifUploadFlowBlock
  | MultiChoiceFlowBlock
  | PersonSelectorFlowBlock
  | GiveTrophiesStackFlowBlock
  | NPSFlowBlock;

export interface FlowBlockFromAPI {
  blockId: string;
  blockType: FlowBlockAPITypes;
  content: FlowBlockContent;
}

export interface BlockResponses {
  blockId: string;
  response: {
    value: string | string[] | number | FlowBlockOption[];
    // TODO: Files key is required for files.
    // files?: [];
    persons?: string[];
    gifUrl?: string;
    mentions?: string[];
  };
}

export interface SubmitInstancePayload {
  instanceId: string;
  responses: BlockResponses[];
}

export interface UpdateNotificationsPreferencePayload {
  type: 'SUBSCRIBE' | 'UNSUBSCRIBE';
}

export type FlowRequestPermission =
  | 'VIEWER'
  | 'PARTICIPANT'
  | 'PARTICIPANT_AND_VIEWER';
export interface FlowShareRequestPayload {
  requestedFor: string[];
  requestPermission: FlowRequestPermission;
  source: 'SHARE_BUTTON';
  message?: string;
}

export type FlowFileForAPI = {
  type: string;
  createdAt: Date;
  location: string;
  name: string;
  size: number;
  originalName: string;
  thumbnailUrl?: string;
  thumbnails?: {
    360: string;
  };
  id?: string;
};

export type FlowPostErrorMessage = { blockId: string; message: string };

export type FlowSubmissionDetails = {
  values: Record<string, any>;
  dynamicBlockData: DynamicBlockState[];
  currentStep: number;
};

export type PossibleValues =
  | AutocompleteDropdownItem<string, unknown>[]
  | AutocompleteDropdownItem<string, unknown>
  | null;
