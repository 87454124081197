import styled from 'styled-components';

import Button from '../../atoms/Button';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';
import { zIndexFooter } from '../../../Utils/styles/z-index';
import { windowSizeAdapter } from '../../../Utils/window';

const desktopSize = (isTab?: boolean, isEmbeddedInMainApp?: boolean) => {
  if (isEmbeddedInMainApp) {
    return `calc(100vh - 65px)`;
  } else if (isTab) {
    return `calc(100vh - 136px)`;
  } else {
    return `calc(100vh - 70px)`;
  }
};

export const StyledScrollWrapper = styled.div<{
  isTab?: boolean;
  isMobileApp: boolean;
  isEmbeddedInMainApp?: boolean;
}>`
  height: ${({ isMobileApp, isTab, isEmbeddedInMainApp }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: isTab
        ? `calc(var(--mobileVh, 1vh) * 100 - 128px)`
        : `calc(var(--mobileVh, 1vh) * 100 - 64px)`,
      mobileWeb: isTab
        ? `calc(var(--mobileVh, 1vh) * 100 - 196px)`
        : `calc(var(--mobileVh, 1vh) * 100 - 132px)`,
      tablet: isTab ? `calc(100vh - 200px)` : `calc(100vh - 134px)`,
      desktop: desktopSize(isTab, isEmbeddedInMainApp),
    })};

  overflow: hidden;
  padding-bottom: ${({ isTab }) => (isTab ? '6px' : '20px')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FilterWrapper = styled.div`
  overflow: auto;
`;

export const LoadingWrapper = styled(Flex)`
  height: calc(100vh - 70px);
  overflow-y: auto;
  padding-bottom: 20px;
`;

export const StyledFilterWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  padding: 16px 24px 0;
`;

export const StyledSpaceSeparator = styled.div`
  margin-bottom: 24px;
`;

export const StyledButton = styled(Button)`
  width: 210px;
  margin-right: 8px;
`;

export const StyledFlex = styled(Flex)<{
  isRightAsideOpen?: boolean;
  isKioskTurnedOn: boolean;
}>`
  justify-content: center;
  bottom: ${({ isKioskTurnedOn }) => (isKioskTurnedOn ? '30px' : '0')};
  background: ${ThemeV2.palette.gray1};
  z-index: ${zIndexFooter};
  padding: 16px 24px 0 24px;
  right: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '0' : '-296px')};
  border-top: 1px solid ${ThemeV2.palette.gray4};
  transition: all 0.5s;

  @media ${device.mobile} {
    width: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '100vw' : '320px')};
    display: ${({ isRightAsideOpen }) => (isRightAsideOpen ? 'flex' : 'none')};
  }
`;

export const StyledWrapper = styled.div`
  padding: 16px 0;

  .MuiTabs-indicator {
    width: 50% !important;
  }
`;

export const Wrapper = styled.div`
  max-height: calc(100vh);
  overflow-y: auto;
`;
