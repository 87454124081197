import { useCallback, useState } from 'react';
import { JOB_TITLE, LOCATION } from '../../../languages/en/home/profileDrawer';
import {
  ABOUT_ME,
  AVATAR,
  BIRTHDAY,
  DATE,
  DEPARTMENT,
  DIRECT,
  EMAIL,
  HANDLE,
  MANAGER,
  NAME,
  PRONOUNS,
  REPORTS,
  SOCIAL_PROFILES,
  START,
  TIME_ZONE,
  HOME_LOCATION,
  WORK_LOCATION,
} from '../../../languages/en/singleWords';

export const useAccordionToggleLogic = () => {
  const [accordionItems, setAccordionItems] = useState([
    AVATAR,
    NAME,
    DEPARTMENT,
    JOB_TITLE,
    LOCATION,
    HANDLE,
    EMAIL,
    MANAGER,
    TIME_ZONE,
    `${DIRECT} ${REPORTS}`,
    BIRTHDAY,
    `${START} ${DATE}`,
    PRONOUNS,
    ABOUT_ME,
    SOCIAL_PROFILES,
    HOME_LOCATION,
    WORK_LOCATION,
  ]);

  const handleCollapse = useCallback(
    (itemName: string) => {
      if (!(accordionItems.indexOf(itemName) !== -1)) {
        setAccordionItems([...accordionItems, itemName]);
      } else {
        const filteredAccordionItems = accordionItems.filter((item) => {
          return item !== itemName;
        });
        setAccordionItems([...filteredAccordionItems]);
      }
    },
    [accordionItems],
  );

  const handleSaveClick = useCallback(
    (itemName: string) => {
      setAccordionItems([...accordionItems, itemName]);
    },
    [accordionItems],
  );

  return {
    models: {
      accordionItems,
    },
    operations: {
      handleCollapse,
      handleSaveClick,
    },
  };
};
