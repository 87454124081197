import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthStoreState = {
  slackCode: string;
  authenticated: boolean;
  error: unknown;
  userData?: any;
  isCreatingFlowForTheFirstTime: boolean;
  setUserData: (userData: any) => void;
  setAuthenticated: (authenticated: boolean) => void;
  setAuthenticationError: (error: unknown) => void;
  setAuthStoreStateForAuthenticatedUser: (userData?: any) => void;
  resetAuthStore: () => void;
  setAuthStoreStateForAuthenticationError: (error: unknown) => void;
  setSlackCode: (value: string) => void;
  setCreatingFlowForTheFirstTime: (val: boolean) => void;
};

export const authStore = create<AuthStoreState>()(
  persist(
    (set) => ({
      slackCode: '',
      authenticated: false,
      error: '',
      userData: undefined,
      isCreatingFlowForTheFirstTime: false,
      setUserData: (userData: any) => set(() => ({ userData })),
      setAuthenticated: (authenticated: boolean) =>
        set(() => ({
          authenticated,
        })),
      setAuthenticationError: (error: unknown) =>
        set(() => ({
          error,
        })),
      setAuthStoreStateForAuthenticatedUser: (userData?: any) =>
        set(() => ({
          authenticated: true,
          error: undefined,
          userData,
        })),
      resetAuthStore: () =>
        set(() => ({
          authenticated: false,
          error: undefined,
          userData: undefined,
        })),
      setAuthStoreStateForAuthenticationError: (error: unknown) =>
        set(() => ({
          authenticated: false,
          error,
        })),
      setSlackCode: (value: string) => set(() => ({ slackCode: value })),
      setCreatingFlowForTheFirstTime: (val: boolean) =>
        set(() => ({ isCreatingFlowForTheFirstTime: val })),
    }),
    {
      name: 'auth-store',
    },
  ),
);
