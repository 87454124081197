enum SplitNames {
  DisplayAppBanner = 'DISPLAY_APP_BANNER',
  EnableUserActivitiesTracking = 'ENABLE_USER_ACTIVITIES_TRACKING',
  LocationCriteria = 'LOCATION_CRITERIA',
  MultiPopoutDrawer = 'MULTI_POPOUT_DRAWER',
  FlowSummaries = 'FLOW_SUMMARIES_V0',
  CONVERTED_PDF_PREVIEWS = 'CONVERTED_PDF_PREVIEWS',
  DisableRewards = 'DISABLE_TANGO_REDEMPTION',

  HideV2DirectMessaging = 'HIDE_V2_DM',
  EnableAssemblyMagicLink = 'ASSEMBLY_MAGIC_LINK',

  FlowCreation = 'FLOW_CREATION',
  FlowEditV3 = 'FLOW_EDIT_V3',
}

enum TreatmentTypes {
  CONTROL = 'control',
  ON = 'on',
  OFF = 'off',
  NOT_YET_SET = 'NOT_YET_SET',
}

export { SplitNames, TreatmentTypes };
