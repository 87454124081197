import { useContext } from 'react';

import FlowEndOccurrenceModal from '../../atomic/molecules/FlowEndOccurrenceModal';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../Utils/analytics/constants';
import { EndFlowOccurrenceModalContext } from './endFlowOccurrenceModalContext';
import { FLOWS_END_OCCURRENCE_MODAL } from '../../languages/en/flows';
import { trackLeftDrawerActionEvent } from '../../Utils/analytics/leftDrawer';

const EndFlowOccurrenceModal = () => {
  const {
    flowDetails,
    isEndOccurrenceModalLoading,
    isEndOccurrenceModalOpen,
    onEndOccurrenceClick,
    setEndOccurrenceModalClose,
  } = useContext(EndFlowOccurrenceModalContext);

  return (
    <FlowEndOccurrenceModal
      heading={FLOWS_END_OCCURRENCE_MODAL.modalHeading}
      isOpen={isEndOccurrenceModalOpen}
      onCancelClick={setEndOccurrenceModalClose}
      onEndOccurrenceClick={() => {
        onEndOccurrenceClick();
        trackLeftDrawerActionEvent({
          action:
            LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_OCCURRENCE_ENDED,
          ...flowDetails,
        });
      }}
      onModalClose={setEndOccurrenceModalClose}
      isLoading={isEndOccurrenceModalLoading}
    />
  );
};

export default EndFlowOccurrenceModal;
