import styled, { keyframes, css } from 'styled-components';
import { hexToRGB } from '../../../Utils/color';

import { PulseProps } from './types';

const getPulseAnimation = (color: string, size: number) => {
  return keyframes`
  0% {
     transform: scale(0.95);
     box-shadow: 0 0 0 0 ${hexToRGB(color, 0.7)};
   }

   70% {
     transform: scale(1);
     box-shadow: 0 0 0 ${size / 2}px ${hexToRGB(color, 0)};
   }

   100% {
     transform: scale(0.95);
     box-shadow: 0 0 0 0 ${hexToRGB(color, 0)};
   }`;
};

export const StyledPulse = styled.div<PulseProps>`
  background: ${({ color }) => color};
  border-radius: 50%;
  margin: ${({ size }) => size && `${size / 2}px`};
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};

  box-shadow: ${({ color }) => color && `0 0 0 0 ${hexToRGB(color, 1)}`};

  transform: scale(1);
  animation: ${({ color, size }) =>
    color &&
    size &&
    css`
      ${getPulseAnimation(color, size)} 2s infinite
    `};
`;
