import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftDecorator,
  EditorState,
  Modifier,
} from 'draft-js';
import { EmojiData } from 'emoji-mart';
import { EditorPlugin } from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';

import { getLiteralTextFromEditor } from './mention';
import { TaskDraftDecoratorToken } from '../../atomic/molecules/TaskDraftDecoratorToken';

export const NOTEBOOK_TASK_DRAFT_ENTITY_KEY = 'notebookTask';

const taskTokenStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
): void => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    // This is adapted from the original strategy implementation, for mentions, in the following GitHub link.
    // https://tinyurl.com/2s3na6hx
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() ===
        NOTEBOOK_TASK_DRAFT_ENTITY_KEY
    );
  }, callback);
};

export const getTaskTokensDecorator = () => {
  return {
    strategy: taskTokenStrategy,
    component: TaskDraftDecoratorToken,
  };
};

const getDecoratorFromLinkifyPlugin = () => {
  // The Linkify plugin only provides a decorator,
  // so we can update the strategy so that it ignores those links in Tasks referenced.
  // See https://tinyurl.com/mt3b93dd (GitHub) for the relevant source code.
  const originalLinkifyPlugin: EditorPlugin = createLinkifyPlugin();
  const originalDecorators = originalLinkifyPlugin.decorators;

  if (
    originalDecorators &&
    originalDecorators[0] &&
    'component' in originalDecorators[0]
  ) {
    return originalDecorators[0];
  }

  return null; // This should never be reached, unless the @draft-js-plugins/linkify package is updated.
};

export const getAllDecorators = () => {
  const decoratorConfigs: Array<DraftDecorator> = [getTaskTokensDecorator()];
  const linkifyDecorator = getDecoratorFromLinkifyPlugin();

  if (linkifyDecorator) {
    decoratorConfigs.push(linkifyDecorator);
  }

  return [new CompositeDecorator(decoratorConfigs)];
};

export const addAtSymbolToEditorState = (editorState: EditorState) => {
  const contentState = editorState.getCurrentContent();
  const targetRange = editorState.getSelection();
  const newContentState = Modifier.insertText(contentState, targetRange, '@');

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-fragment',
  );
  return newEditorState;
};

export const addEmojiToEditorState = (
  emojiData: EmojiData,
  editorState: EditorState,
) => {
  const contentState = editorState.getCurrentContent();
  const targetRange = editorState.getSelection();
  const newContentState = Modifier.insertText(
    contentState,
    targetRange,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    `${emojiData.native} `,
  );

  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'insert-fragment',
  );
  return newEditorState;
};

export const getCharacterCount = (editorState: EditorState) => {
  const literalMessage = getLiteralTextFromEditor(editorState);
  return literalMessage.trim().length;
};
