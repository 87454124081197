import useConnectionsController from './useConnectionsController';

export enum ConnectionState {
  ConnectState = 'ConnectState',
  DisconnectedState = 'DisconnectedState',
  ConnectToSlackState = 'ConnectToSlackState',
  ConnectionEditState = 'ConnectionEditState',
  ConnectionDisplayState = 'ConnectionDisplayState',
}

export type ConnectionControllerProp = ReturnType<
  typeof useConnectionsController
>;
