import debounce from 'lodash/debounce';
import { useCallback, useMemo, useRef, useState } from 'react';

import {
  GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS,
  GET_FLOW_BLOCK_PARTICIPANTS,
  GET_FLOW_PARTICIPANTS,
  GET_FLOW_VIEWERS,
  GET_MEMBERS,
  GET_MEMBERS_FROM_CRITERIA,
} from '../../constants/endpoints';
import { useMutation } from '@tanstack/react-query';
import { IMemberDTO } from '../../interfaces/member';
import { CriteriaResponse } from '../../queries/Flows/interfaces';
import {
  GenericMemberQueryDefinition,
  GetFlowParticipantsResponse,
  GetMembersResponse,
  useGenericFetchMembersInfiniteScrollQuery,
} from '../../queries/Members';
import { makeAPICall } from '../../queries/utils';

const getMemberQueryDetails: GenericMemberQueryDefinition<
  GetMembersResponse,
  IMemberDTO
> = {
  cursorKey: 'delta',
  requestType: 'GET',
  queryKey: GET_MEMBERS,
  getNextPageParam: (lastPage: GetMembersResponse) =>
    lastPage.metadata.pagination?.next || undefined,
  getPreviousPageParam: (lastPage: GetMembersResponse) =>
    lastPage.metadata.pagination?.prev,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.members],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const getMembersFromCriteriaQueryDetails: GenericMemberQueryDefinition<
  GetFlowParticipantsResponse,
  IMemberDTO
> = {
  queryKey: GET_MEMBERS_FROM_CRITERIA,
  cursorKey: 'cursor',
  requestType: 'POST',
  getNextPageParam: (lastPage) =>
    lastPage.metadata.pagination.cursor?.next || undefined,
  getPreviousPageParam: (lastPage) =>
    lastPage.metadata.pagination.cursor?.previous || undefined,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const getFlowBlockMembersQueryDetails: GenericMemberQueryDefinition<
  GetFlowParticipantsResponse,
  IMemberDTO
> = {
  queryKey: GET_FLOW_BLOCK_PARTICIPANTS,
  cursorKey: 'cursor',
  requestType: 'GET',
  getNextPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.next || undefined,
  getPreviousPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.previous || undefined,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const getExternalFlowBlockMembersQueryDetails: GenericMemberQueryDefinition<
  GetFlowParticipantsResponse,
  IMemberDTO
> = {
  queryKey: GET_EXTERNAL_FLOW_BLOCK_PARTICIPANTS,
  cursorKey: 'cursor',
  requestType: 'GET',
  getNextPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.next || undefined,
  getPreviousPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.previous || undefined,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const getFlowParticipantsQueryDetails: GenericMemberQueryDefinition<
  GetFlowParticipantsResponse,
  IMemberDTO
> = {
  queryKey: GET_FLOW_PARTICIPANTS,
  cursorKey: 'cursor',
  requestType: 'GET',
  getNextPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.next || undefined,
  getPreviousPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.previous || undefined,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const getViewersOfFlowQueryDetails: GenericMemberQueryDefinition<
  GetFlowParticipantsResponse,
  IMemberDTO
> = {
  queryKey: GET_FLOW_VIEWERS,
  cursorKey: 'cursor',
  requestType: 'GET',
  getNextPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.next || undefined,
  getPreviousPageParam: (lastPage: GetFlowParticipantsResponse) =>
    lastPage.metadata.pagination.cursor?.previous || undefined,
  getTotalMembers: (pagesArr) => pagesArr?.pages[0].total || 0,
  getInfiniteMembers: (pagesArr) => {
    if (pagesArr) {
      return pagesArr.pages.reduce<IMemberDTO[]>(
        (arr, page) => [...arr, ...page.data],
        [],
      );
    }
    return undefined;
  },
  getNewMembers: (pagesArr) => pagesArr?.pages[0].newMembers || [],
  getTotalPendingMembers: (pagesArr) => pagesArr?.pages[0].totalPending || 0,
  getCanEmailInvite: (pagesArr) => pagesArr?.pages[0].canEmailInvite || false,
};

const useGenericMembersSearch = <ResponseType, MemberType>(
  queryDetails: GenericMemberQueryDefinition<ResponseType, MemberType>,
  canFetchWhenEmpty?: boolean,
  substitutions?: Record<string, string>,
  limit?: number,
  postMemberSearch?: () => void,
  dynamicSearchKey = 'keyword',
  additionalPayload?: Record<string, any>,
) => {
  const [value, setValue] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');

  const handleClearValue = useCallback(() => {
    setValue('');
    setKeyword('');
  }, [setValue]);

  const debounceSearchValue = useRef(
    debounce((newValue: string) => {
      setKeyword(newValue);
    }, 500),
  );

  const handleChange = useCallback(
    (newValue: string) => {
      if (postMemberSearch && newValue !== '') {
        postMemberSearch();
      }
      setValue(newValue);
      debounceSearchValue.current(newValue);
    },
    [postMemberSearch],
  );

  const isQueryEnabled = canFetchWhenEmpty || keyword.length > 2;

  const {
    data: searchedMembersData,
    isError,
    isFetching,
    isInitialLoading: isLoading,
    hasNextPage: hasMoreMembers,
    fetchNextPage: fetchMoreMembers,
    refetch: refetchMembers,
  } = useGenericFetchMembersInfiniteScrollQuery<ResponseType, MemberType>({
    queryDetails,
    enabled: isQueryEnabled,
    params: {
      [dynamicSearchKey]: keyword || undefined,
      limit,
      ...(additionalPayload || {}),
    },
    substitutions,
  });

  const infiniteMembers = useMemo(
    () => queryDetails.getInfiniteMembers(searchedMembersData),
    [queryDetails, searchedMembersData],
  );

  return {
    models: {
      isError,
      isFetching,
      isLoading,
      value,
      totalMembers: queryDetails.getTotalMembers(searchedMembersData),
      totalPendingMembers:
        queryDetails.getTotalPendingMembers(searchedMembersData),
      newMembers: queryDetails.getNewMembers(searchedMembersData),
      searchedMembers: isQueryEnabled ? infiniteMembers : undefined,
      hasMoreMembers,
      canEmailInvite:
        queryDetails.getCanEmailInvite(searchedMembersData) || false,
    },
    operations: {
      onChange: handleChange,
      onClearValue: handleClearValue,
      fetchMoreMembers,
      refetchMembers,
    },
  };
};

export const useMembersSearch = (
  canFetchWhenEmpty?: boolean,
  limit?: number,
  dynamicKeyword?: string,
  searchLimit?: number,
) => {
  return useGenericMembersSearch<GetMembersResponse, IMemberDTO>(
    getMemberQueryDetails,
    canFetchWhenEmpty,
    undefined,
    limit,
    undefined,
    dynamicKeyword,
    { searchLimit },
  );
};

export const useFlowBlockMembersSearch = (
  flowId: string,
  blockId: string,
  enabled: boolean,
  isExternalFlow: boolean,
) => {
  const substitutions = useMemo(() => ({ flowId, blockId }), [blockId, flowId]);
  return useGenericMembersSearch<GetFlowParticipantsResponse, IMemberDTO>(
    isExternalFlow
      ? getExternalFlowBlockMembersQueryDetails
      : getFlowBlockMembersQueryDetails,
    enabled,
    substitutions,
  );
};

export const useFlowParticipantsSearch = (
  flowId: string,
  canFetchParticipantList?: boolean,
) => {
  const substitutions = useMemo(() => ({ flowId }), [flowId]);
  return useGenericMembersSearch<GetFlowParticipantsResponse, IMemberDTO>(
    getFlowParticipantsQueryDetails,
    Boolean(canFetchParticipantList && flowId),
    substitutions,
    20,
  );
};

export const useGetFlowParticipants = (
  flowId: string,
  canFetchParticipantList?: boolean,
) => {
  const substitutions = useMemo(() => ({ flowId }), [flowId]);
  return useGenericMembersSearch<GetFlowParticipantsResponse, IMemberDTO>(
    getFlowParticipantsQueryDetails,
    Boolean(canFetchParticipantList && flowId),
    substitutions,
    99,
  );
};

export const useFlowViewersSearch = (
  flowId: string,
  postMemberSearch?: (...args: any) => void,
) => {
  const substitutions = useMemo(() => ({ flowId }), [flowId]);
  return useGenericMembersSearch<GetFlowParticipantsResponse, IMemberDTO>(
    getViewersOfFlowQueryDetails,
    Boolean(flowId),
    substitutions,
    20,
    postMemberSearch,
  );
};

export const useGetMembersFromCriteria = (
  previewParticipantRules: any,
  // TODO ARUN/SUMEDHA : ADD TYPES FOR previewParticipantRules
  limit?: number,
  miscAttributes?: Record<string, any>,
) => {
  let additionalPayload: Record<string, any> = {};
  if (previewParticipantRules) {
    additionalPayload = { ...additionalPayload, ...previewParticipantRules };
  }
  if (miscAttributes) {
    additionalPayload = { ...additionalPayload, ...miscAttributes };
  }
  return useGenericMembersSearch<GetFlowParticipantsResponse, IMemberDTO>(
    getMembersFromCriteriaQueryDetails,
    Boolean(previewParticipantRules),
    undefined,
    limit,
    undefined,
    'keyword',
    additionalPayload,
  );
};

export function useGetMembersFromCriteriaMutation() {
  return useMutation((body: CriteriaResponse) =>
    makeAPICall(GET_MEMBERS_FROM_CRITERIA, body),
  );
}
