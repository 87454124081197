import styled, { css } from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import IconButton from '../../atoms/IconButton_V2';

export const StyledIconButton = styled(IconButton)``;

const FilteredStyle = css`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -16px;
    margin: auto;
    width: 6px;
    height: 6px;
    background: ${ThemeV2.palette.geekBlue6};
    border-radius: 100%;
  }
`;

export const StyledBody = styled(Body)<{ isFiltered: boolean }>`
  position: relative;
  ${({ isFiltered }) => isFiltered && FilteredStyle};
`;

export const StyledFlex = styled(Flex)`
  cursor: pointer;
  user-select: none;
  :hover {
    ${StyledIconButton} {
      background-color: ${ThemeV2.palette.geekBlue1};
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  }
  :active {
    ${StyledIconButton} {
      background-color: ${ThemeV2.palette.geekBlue2};
    }
  }
`;
