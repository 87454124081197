import { APP_URL, NEW_APP_URL } from '../config';
import { USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN } from '../constants/routes';

export const NO_SLUG_REDIRECT_ROUTES = [
  `${window.location.origin}/home`,
  `${window.location.origin}/settings`,
  `${window.location.origin}/rewards`,
  `${window.location.origin}/notebook`,
  `${window.location.origin}/knowledge-center`,
  `${window.location.origin}/flows`,
  `${window.location.origin}/post`,
  `${window.location.origin}/user`,
  `${window.location.origin}/template/`,
  `${window.location.origin}/create-one-click-flow/`,
];

/*
There are few conflicts routes where slugUrl is not needed to be appended,
to handle that we're using this array
*/
export const NO_SLUG_NO_REDIRECT_ROUTES = [
  `/user/set-details`,
  '/user/account-setup',
  USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN,
];

/* MultiAssembly redirects - backward compatibility */
export const REDIRECT_ROUTES = {
  SIGN_IN: `${window.location.origin}/signin`,
  CREATE_ACCOUNT: `${window.location.origin}/create-account`,
  FORGOT_PASSWORD: `${window.location.origin}/forgotPassword`,
  HOME: `${window.location.origin}/home`,
  SOURCE_MS_TEAMS: `${window.location.origin}/?source=MS_TEAMS`,
  SIGN_IN_MS_TEAMS: `${window.location.origin}/signin?source=MS_TEAMS`,
  USER_DETAILS: `${window.location.origin}/user/set-details`,
};

export const REDIRECT_ROUTES_WITH_LOGIC = [
  `${window.location.origin}/join`,
  `${window.location.origin}/signup/inviteToken`,
];

export const getRedirectionUrl = ({
  pathName,
  authenticated,
  search,
}: {
  pathName: string;
  authenticated: boolean;
  search?: string;
}) => {
  let url: any = undefined;

  if (REDIRECT_ROUTES.USER_DETAILS.includes(pathName)) {
    const params = new URLSearchParams(location.search);
    const firstLogin = params.get('firstLogin');
    if (firstLogin) {
      url = `${NEW_APP_URL}/onboarding/user-details`;
    } else {
      url = `${APP_URL}home`;
    }
  } else if (search !== undefined && search !== '') {
    if (
      window.location.href.startsWith(REDIRECT_ROUTES.SOURCE_MS_TEAMS) ||
      window.location.href.startsWith(REDIRECT_ROUTES.SIGN_IN_MS_TEAMS)
    ) {
      if (authenticated) {
        url = `${window.location.origin}/home`;
      } else {
        url = `${NEW_APP_URL}/ms-teams/login`;
      }
    } else if (!authenticated) {
      url = `${NEW_APP_URL}/login`;
    }
  } else if (REDIRECT_ROUTES.SIGN_IN.includes(pathName)) {
    url = window.location.href.replace(
      `${window.location.origin}/signin`,
      `${NEW_APP_URL}/login`,
    );
  } else if (REDIRECT_ROUTES.CREATE_ACCOUNT.includes(pathName)) {
    url = window.location.href.replace(
      `${window.location.origin}/create-account`,
      `${NEW_APP_URL}/create-account`,
    );
  } else if (REDIRECT_ROUTES.FORGOT_PASSWORD.includes(pathName)) {
    url = window.location.href.replace(
      `${window.location.origin}/forgotPassword`,
      `${NEW_APP_URL}/login`,
    );
  } else if (
    window.location.href.startsWith(`${REDIRECT_ROUTES.FORGOT_PASSWORD}`) ||
    (!authenticated && REDIRECT_ROUTES.HOME.includes(pathName))
  ) {
    url = `${NEW_APP_URL}/login`;
  }
  return url;
};
