import {
  OptionItemProps,
  RadioOptionValueTypes,
} from '../../../../atomic/organism/FlowParticipationBuilderOptionsContent/types';
import { RadioOptionManagerValueTypes } from '../../../../atomic/organism/FlowsManagerBuilderOptionsContent/types';

export const optionItems: OptionItemProps[] = [
  {
    label: 'is',
    value: RadioOptionValueTypes.Is,
  },
  {
    label: 'is not',
    value: RadioOptionValueTypes.IsNot,
  },
];

export const optionManagerItems: OptionItemProps[] = [
  {
    label: 'Member reports to',
    value: RadioOptionManagerValueTypes.DirectReport,
  },
  {
    label: 'Member is a Manager',
    value: RadioOptionManagerValueTypes.MemberIsManager,
  },
  {
    label: 'Member is not a Manager',
    value: RadioOptionManagerValueTypes.MemberIsNotManager,
  },
];
