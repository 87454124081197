import React, { useCallback } from 'react';
import NotificationItem from '../../molecules/NotificationItem';
import ToggleButton from '../../atoms/ToggleButton';
import {
  DrawerHeading,
  NotificationHeader,
  ToggleButtonWrapper,
  NotificationContainer,
  NotificationDropdownContainer,
  NotificationErrorImage,
  NotificationErrorContainer,
  ContentWrapper,
  NotificationWrapper,
} from './styles';
import ErrorImageSrc from '../../../img/error.svg';
import { NotificationDropdownProps } from './interface';
import {
  notificationDropdownAllButton,
  notificationDropdownHeading,
  notificationDropdownUnreadButton,
  SOMETHING_WENT_WRONG,
  TRY_AGAIN,
} from '../../../languages/en/home';
import { Flex } from '../../../Utils/styles/display';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import Body from '../../atoms/Body';
import { MARK_ALL_AS_READ } from '../../../languages/en/notifications';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';
import EmptyNotificationsNotice from '../../molecules/EmptyNotificationsNotice';
import Loader from './Loader';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { CloseInfoPanel } from '../PrimaryNavbar/styles';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMobileApp from '../../../hooks/useMobileApp';

const MARK_ALL_AS_READ_ID = 'mark-all-read';

const NotificationDropdownOptions = [
  {
    id: 'notifications-header-options',
    items: [
      {
        value: MARK_ALL_AS_READ,
        id: MARK_ALL_AS_READ_ID,
        prefixIcon: 'check-rounded',
        isSelected: false,
      },
    ],
  },
];

const NotificationDropdown = (props: NotificationDropdownProps) => {
  const {
    notificationData,
    isLoading,
    showSettingsButton = false,
    onMarkReadClick,
    handleNotificationItemClick,
    onUnreadClick,
    onShowAllClick,
    isError,
    closePopover,
    hideCloseIcon,
    onSettingsButtonClick,
    handleFetchNextPage,
    hasNextPage,
    isUnreadActive,
  } = props;

  const onDropdownItemClick = useCallback(
    (optionId: string | number) => {
      if (optionId === MARK_ALL_AS_READ_ID) {
        onMarkReadClick();
      }
    },
    [onMarkReadClick],
  );

  const { isMobileApp } = useMobileApp();

  return (
    <NotificationDropdownContainer>
      <ContentWrapper>
        <NotificationHeader>
          <Flex justifyContent="space-between" width="100%">
            <DrawerHeading variant={isMobileApp ? 'h6' : 'h7'} weight="medium">
              {notificationDropdownHeading}
            </DrawerHeading>
            <Flex margin="0 4px" justifyContent="space-between" flex={1}>
              <DropdownIconButton
                icon="more"
                dropdownPlacement={DropdownPlacement.RightStart}
                menuItems={NotificationDropdownOptions}
                onItemClick={onDropdownItemClick}
                size={ButtonSize.Normal}
              />
              {showSettingsButton && (
                <IconButton
                  icon="setting-filled"
                  size={ButtonSize.Normal}
                  onClick={onSettingsButtonClick}
                />
              )}
            </Flex>
          </Flex>

          {!hideCloseIcon && (
            <CloseInfoPanel onClick={closePopover}>
              <SVGIcon icon="round-close" color={ThemeV2.palette.gray6} />
            </CloseInfoPanel>
          )}
        </NotificationHeader>

        <Flex justifyContent="space-between">
          <ToggleButtonWrapper>
            <ToggleButton
              active={!isUnreadActive}
              onClick={() => {
                if (onShowAllClick) {
                  onShowAllClick();
                }
              }}
              size={isMobileApp ? ButtonSize.Medium : ButtonSize.Small}
            >
              {notificationDropdownAllButton}
            </ToggleButton>
            <ToggleButton
              active={isUnreadActive}
              onClick={() => {
                if (onUnreadClick) {
                  onUnreadClick();
                }
              }}
              size={isMobileApp ? ButtonSize.Medium : ButtonSize.Small}
            >
              {notificationDropdownUnreadButton}
            </ToggleButton>
          </ToggleButtonWrapper>
        </Flex>
      </ContentWrapper>
      <NotificationContainer>
        {isError && (
          <NotificationErrorContainer>
            <NotificationErrorImage src={ErrorImageSrc} alt="Error" />
            <Body variant="body1Bold">{SOMETHING_WENT_WRONG}</Body>
            <Body variant="body2">{TRY_AGAIN}</Body>
          </NotificationErrorContainer>
        )}
        {isLoading && <Loader />}
        {!isLoading && !isError && (
          <NotificationWrapper id="notificationContent">
            {notificationData.length > 0 && (
              <InfiniteScroll
                next={() => {
                  if (handleFetchNextPage) {
                    handleFetchNextPage();
                  }
                }}
                dataLength={notificationData.length}
                loader={<Loader />}
                hasMore={Boolean(hasNextPage)}
                scrollableTarget="notificationContent"
              >
                {notificationData.map((notificationItem) => (
                  <NotificationItem
                    key={notificationItem.id}
                    id={notificationItem.id}
                    avatar={notificationItem.avatar}
                    timeAgo={notificationItem.timeAgo}
                    read={notificationItem.read}
                    bodyText={notificationItem.bodyText}
                    icon={notificationItem.icon}
                    headerText={notificationItem.headerText}
                    onClick={handleNotificationItemClick}
                    closePopover={closePopover}
                    postID={notificationItem.postID}
                    notificationType={notificationItem.type}
                    emoticon={notificationItem.emoticon}
                    flowId={notificationItem.flowId}
                    responseId={notificationItem.responseId}
                    occurrenceId={notificationItem.occurrenceId}
                    commentId={notificationItem.commentId}
                    taskId={notificationItem.taskId}
                    commentType={notificationItem?.metaData?.commentType}
                  />
                ))}
              </InfiniteScroll>
            )}
            {notificationData.length === 0 && !isUnreadActive && (
              <EmptyNotificationsNotice type="allNotifications" />
            )}
            {notificationData.length < 1 && isUnreadActive && (
              <EmptyNotificationsNotice type="unreadNotifications" />
            )}
          </NotificationWrapper>
        )}
      </NotificationContainer>
    </NotificationDropdownContainer>
  );
};

export default NotificationDropdown;
