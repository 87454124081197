import styled from 'styled-components';

export const UnderMaintenanceMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-top: 4px;
  color: #595959;

  a {
    color: #595959;
    text-decoration: underline;
  }
`;

export const UnderMaintenanceTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-top: 4px;
`;
