import React, { useCallback } from 'react';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import { PrimaryNavbarProps } from './interface';
import { CloseInfoPanel, InfoPanel, Nav, StyledIconButton } from './styles';

const PrimaryNavbar = (props: PrimaryNavbarProps) => {
  const {
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    renderInfoPanelContent,
    isLeftNavHovered,
    setLeftNavHoveredToTrue,
    setLeftNavHoveredToFalse,
    isKioskTurnedOn,
  } = props;

  const handleMouseOver = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (isLeftNavHovered) {
        return;
      }

      const target = e.target as HTMLButtonElement;

      if ('float-button' !== target.id) {
        setLeftNavHoveredToTrue();
      }
    },
    [isLeftNavHovered, setLeftNavHoveredToTrue],
  );

  return (
    <>
      <Nav
        isLeftAsideOpen={isLeftAsideOpen}
        isRightAsideOpen={isRightAsideOpen}
        aria-label="primary navigation menu"
        role="navigation"
        onMouseOver={handleMouseOver}
        onMouseLeave={() => {
          setLeftNavHoveredToFalse();
        }}
        isHovered={isLeftNavHovered}
      >
        <InfoPanel
          isLeftAsideOpen={isLeftAsideOpen}
          isHovered={isLeftNavHovered}
        >
          <CloseInfoPanel onClick={toggleLeftAsideOpen}>
            <SVGIcon icon="round-close" color={ThemeV2.palette.gray6} />
          </CloseInfoPanel>
          {renderInfoPanelContent({
            isHoveredWhenCollapsed: isLeftNavHovered && !isLeftAsideOpen,
          })}
        </InfoPanel>
        <StyledIconButton
          type="button"
          isActive
          onClick={toggleLeftAsideOpen}
          isLeftAsideOpen={isLeftAsideOpen}
          isRightAsideOpen={isRightAsideOpen}
          isHovered={isLeftNavHovered}
          id="float-button"
          isKioskTurnedOn={isKioskTurnedOn}
        >
          {isLeftAsideOpen ? (
            <SVGIcon icon="carat-left" size="20px" />
          ) : (
            <SVGIcon icon="carat-right" size="20px" />
          )}
        </StyledIconButton>
      </Nav>
    </>
  );
};

const MemoizedPrimaryNav = React.memo(PrimaryNavbar);
MemoizedPrimaryNav.displayName = 'PrimaryNavbar';

export default MemoizedPrimaryNav;
