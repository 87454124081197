import uuid from 'uuid';
import { Nullable } from '../common';
import { MemberState } from '../../interfaces/user';
import { MenuItemIndividualItem } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';

import {
  PARTICIPANTS_AND_VIEWERS_LABEL,
  PARTICIPANTS_LABEL,
  VIEWERS_LABEL,
} from '../../languages/en/flows/sharesheet';

import {
  AccessRole,
  CriteriaResponse,
  GetFlowDetailsResponse,
  GroupRule,
  Rule,
} from '../../queries/Flows/interfaces';

import {
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
  VisibilityTypes,
} from '../../controllers/flows/FlowsShareSheetController/types';
import {
  CriteriaField,
  CriteriaGroup,
  CriteriaGroups,
  CriteriaValue,
} from '../../interfaces/Flow/Builder';
import { ShareSheetMemberActions } from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import useSlackUserStore from '../../stores/userStore';
import useFlowBuilderStore from '../../stores/flowBuilderStore';
import { Channel } from '../../interfaces/Connections';
import { GetMembersForPreviewRequest } from '../../interfaces/Sharesheet';
import { FlowCollaborator } from '../../interfaces/member';

function getSlackChannelCriteria(
  channels: Channel[],
  selectedConnectionId: string,
) {
  return channels?.length > 0
    ? [
        {
          field: 'integration',
          operator: 'is',
          value: {
            from: 'slack',
            model: [
              {
                connectionId: selectedConnectionId,
                source: 'channel',
                choose: channels.map((channel) => ({
                  id: channel.id,
                  name: channel.displayName,
                })),
              },
            ],
          },
        },
      ]
    : undefined;
}

const getOthersCriteriaForParticipation = (
  flowBuilderData: Nullable<GetFlowDetailsResponse>,
) => {
  const { isConnectionsTabTouched } = useFlowBuilderStore.getState();
  const { selectedConnectionId, selectedPeopleToPostChannels } =
    useSlackUserStore.getState();

  if (isConnectionsTabTouched) {
    return getSlackChannelCriteria(
      selectedPeopleToPostChannels,
      selectedConnectionId,
    );
  }

  return flowBuilderData?.data.integrations?.participationNotification &&
    flowBuilderData.data.integrations.participationNotification.length > 0 &&
    flowBuilderData.data.integrations.participationNotification[0].metaData
    ? [
        {
          field: 'integration',
          operator: 'is',
          value: {
            from: 'slack',
            model: [
              {
                connectionId:
                  flowBuilderData.data.integrations.participationNotification[0]
                    .connectionId,
                source: 'channel',
                choose:
                  flowBuilderData.data.integrations.participationNotification[0].metaData.channelInfo.map(
                    (channel) => ({
                      id: channel.id,
                      name: channel.name,
                    }),
                  ),
              },
            ],
          },
        },
      ]
    : undefined;
};

const getOthersCriteriaForVisibility = (
  flowBuilderData: Nullable<GetFlowDetailsResponse>,
) => {
  const { isConnectionsTabTouched } = useFlowBuilderStore.getState();
  const { selectedConnectionId, selectedPostFlowResponseChannels } =
    useSlackUserStore.getState();

  if (isConnectionsTabTouched) {
    return getSlackChannelCriteria(
      selectedPostFlowResponseChannels,
      selectedConnectionId,
    );
  }

  return flowBuilderData &&
    flowBuilderData.data.integrations &&
    flowBuilderData.data.integrations.responseNotification &&
    flowBuilderData.data.integrations.responseNotification.length > 0 &&
    flowBuilderData.data.integrations.responseNotification[0].metaData
    ? [
        {
          field: 'integration',
          operator: 'is',
          value: {
            from: 'slack',
            model: [
              {
                connectionId:
                  flowBuilderData.data.integrations.responseNotification[0]
                    .connectionId,
                source: 'channel',
                choose:
                  flowBuilderData.data.integrations.responseNotification[0].metaData.channelInfo.map(
                    (channel) => ({
                      id: channel.id,
                      name: channel.name,
                    }),
                  ),
              },
            ],
          },
        },
      ]
    : undefined;
};

export const getCriteriaPayloadForParticipation = (
  criteria: Nullable<any>,
  flowBuilderData: Nullable<GetFlowDetailsResponse>,
  isMembersOnly = false,
) => {
  const others = isMembersOnly
    ? undefined
    : getOthersCriteriaForParticipation(flowBuilderData);

  if (criteria) {
    return {
      criteria: {
        ...criteria,
        others: others,
      },
    };
  } else {
    return {
      criteria: {
        custom: {
          condition: 'or',
          rules: [],
        },
        others: others,
      },
    };
  }
};

export const getCriteriaPayloadForVisibility = (
  criteria: Nullable<any>,
  flowBuilderData: Nullable<GetFlowDetailsResponse>,
  visibilityType: VisibilityTypes,
  isMembersOnly = false,
) => {
  let others = isMembersOnly
    ? undefined
    : getOthersCriteriaForVisibility(flowBuilderData);

  if (visibilityType === VisibilityTypes.PARTICIPANTS_ONLY) {
    if (
      flowBuilderData &&
      flowBuilderData.data.integrations &&
      flowBuilderData.data.integrations.participationNotification &&
      flowBuilderData.data.integrations.participationNotification.length > 0 &&
      flowBuilderData.data.integrations.participationNotification[0].metaData
    ) {
      if (others) {
        others[0].value.model[0].choose = [
          ...others[0].value.model[0].choose,
          ...flowBuilderData.data.integrations.participationNotification[0].metaData.channelInfo.map(
            (channel) => ({
              id: channel.id,
              name: channel.name,
            }),
          ),
        ];
      } else {
        others = getOthersCriteriaForParticipation(flowBuilderData);
      }
    }
  }

  if (visibilityType === VisibilityTypes.OWNER_AND_COLLABORATORS_ONLY) {
    return {
      criteria: {
        custom: {
          condition: 'or',
          rules: [
            {
              field: 'member',
              operator: 'is',
              value: [flowBuilderData?.data.owner.memberID],
            },
          ],
        },
        others: others,
      },
    };
  }

  if (visibilityType === VisibilityTypes.ENTIRE_ORGANIZATION) {
    return {
      criteria: {
        everyone: true,
      },
    };
  }

  if (criteria) {
    return {
      criteria: {
        ...criteria,
        others: others,
      },
    };
  } else {
    return {
      criteria: {
        custom: {
          condition: 'or',
          rules: [],
        },
        others: others,
      },
    };
  }
};

export const getVisibilityCriteriaForCurrentFlow = ({
  ownerID,
  collaborators,
}: {
  ownerID: string;
  collaborators?: FlowCollaborator[];
}): GetMembersForPreviewRequest['includes'] => {
  return [
    {
      type: 'MEMBER',
      value: [
        ownerID,
        ...(collaborators
          ?.filter(
            (collaborator) => collaborator.state !== MemberState.DEACTIVATED,
          )
          .map((collaborator) => collaborator.memberID) || []),
      ],
    },
  ];
};

export const serializeCollaboratorToMemberDTO = (
  flowCollaborators?: FlowCollaborator[],
) => {
  if (flowCollaborators) {
    return flowCollaborators
      .filter((collaborator) => collaborator.state !== MemberState.DEACTIVATED)
      .map((collaborator) => {
        return {
          memberID: collaborator.memberID,
          firstName: collaborator.name,
          lastName: '',
          image: collaborator.image,
          memberState: collaborator.state,
          role: [],
          username: '',
          email: collaborator.email,
        };
      });
  }
  return [];
};

export const getLinkRole = (linkAccessRoles: AccessRole[]) => {
  if (linkAccessRoles.includes(AccessRole.VIEWER)) {
    return VIEWERS_LABEL;
  }
  if (
    linkAccessRoles.includes(AccessRole.VIEWER) &&
    linkAccessRoles.includes(AccessRole.PARTICIPANT)
  ) {
    return PARTICIPANTS_AND_VIEWERS_LABEL;
  }
  return PARTICIPANTS_LABEL;
};

export const getUpdatedSelectedItemsBasedOnDropdownSelection = ({
  currentUserId,
  setting,
  selectedItems,
}: {
  currentUserId: string;
  setting: MenuItemIndividualItem;
  selectedItems: SimplifiedShareSheetCardData[];
}) => {
  let modifiedSelectedItems = [...selectedItems];
  if (setting?.id === 'remove') {
    modifiedSelectedItems = [...selectedItems].filter(
      (i) => i.id !== currentUserId,
    );
  } else {
    const currentUserIndex = selectedItems.findIndex(
      (i) => i.id === currentUserId,
    );
    if (currentUserIndex > -1 && setting) {
      modifiedSelectedItems.splice(currentUserIndex, 1, {
        ...selectedItems[currentUserIndex],
        setting,
        icon:
          selectedItems[currentUserIndex]?.memberState ===
            MemberState.PENDING ||
          selectedItems[currentUserIndex]?.emailToInvite
            ? 'circular-pending-user'
            : selectedItems[currentUserIndex]?.icon,
      });
    }
  }
  return modifiedSelectedItems;
};

export const getShareSheetCardDataFromAutoCompleteOption = ({
  option,
  setting,
  type,
}: {
  option: AutocompleteDropdownItem<string>;
  type: SimplifiedShareSheetDataTypes;
  setting?: MenuItemIndividualItem;
}): SimplifiedShareSheetCardData =>
  option.emailToInvite
    ? {
        ...option,
        id: option.emailToInvite,
        setting,
        type,
        memberState: MemberState.PENDING,
        icon: 'circular-pending-user',
      }
    : {
        ...option,
        setting,
        type,
      };

export const serializeSimpleShareSheetRulesForAPIPayload = (
  selectedConditions: SimplifiedShareSheetCardData[],
): CriteriaResponse | undefined => {
  const nonChannelRules = selectedConditions.filter(
    (item) =>
      item.type !== 'channel' &&
      item?.setting?.id !== ShareSheetMemberActions.excludeFromFlow,
  );

  const excludedMembers = selectedConditions.filter(
    (item) => item?.setting?.id === ShareSheetMemberActions.excludeFromFlow,
  );

  const criteriaRules = nonChannelRules?.map((rule) => {
    if (rule.type === 'everyone') {
      return {
        value: [],
        field: 'everyone',
        operator: 'is',
      } as Rule;
    }
    const ruleId = rule.id
      .replaceAll('-isNot', '')
      .replaceAll('-is', '')
      .replaceAll(`-${rule.type}`, '');
    return {
      value: [rule?.emailToInvite ? rule?.emailToInvite : ruleId],
      field: rule?.emailToInvite ? 'email' : rule.type,
      operator: rule?.condition || 'is',
    } as Rule;
  });

  const exclusionCriteriaRules = excludedMembers?.map((rule) => {
    return {
      value: [rule?.emailToInvite ? rule?.emailToInvite : rule.id],
      field: rule?.emailToInvite ? 'email' : rule.type,
      operator: 'isNot',
    } as Rule;
  });

  const allCustomRules: GroupRule[] = [
    {
      rules: criteriaRules,
      condition: 'or',
    },
    ...(exclusionCriteriaRules.length
      ? ([
          {
            rules: exclusionCriteriaRules,
            condition: 'and',
          },
        ] as GroupRule[])
      : []),
  ];

  return allCustomRules
    ? {
        criteria: {
          custom: {
            rules: allCustomRules,
            condition: excludedMembers.length ? 'and' : 'or',
          },
        },
        isNewMembersAdded: false,
      }
    : undefined;
};

export const hasEveryoneRuleWithinCurrentGroup = (
  criteriaGroups: CriteriaGroup,
) => {
  return (
    criteriaGroups &&
    criteriaGroups.groupRules
      ?.flatMap((rule) => rule)
      .some((rule) => rule.field === 'everyone')
  );
};

export const hasAnAndCondition = (criteria: CriteriaGroups | undefined) => {
  return (
    (criteria && criteria?.groupsCondition === 'and') ||
    criteria?.groups
      ?.flatMap((groups) => groups)
      .flatMap((group) => group?.groupCondition)
      .some((condition) => condition === 'and')
  );
};

export const hasAnExclusionCriteria = (
  criteria: CriteriaGroups | undefined,
) => {
  return (
    criteria?.groups?.length === 2 &&
    criteria?.groups[1]?.groupCondition === 'and' &&
    criteria?.groups[0]?.groupCondition === 'or' &&
    criteria?.groups[1]?.groupRules?.filter(
      (rule) =>
        (rule.field === 'member' || rule.field === 'email') &&
        rule.operator === 'isNot',
    ).length === criteria?.groups[1]?.groupRules?.length
  );
};

export const getOwnerOnlyCriteriaBasedOnUserId = (userId: string) =>
  ({
    criteria: {
      custom: {
        rules: [
          {
            rules: [
              {
                value: [userId],
                field: 'member',
                operator: 'is',
              },
            ],
            condition: 'or',
          },
        ],
        condition: 'or',
      },
    },
    isNewMembersAdded: false,
  } as CriteriaResponse);

export const getFlattenedCriteriaBasedOnUserIds = (userIds: string[]) =>
  ({
    criteria: {
      custom: {
        rules: [
          {
            rules: userIds.map((id) => ({
              value: [id],
              field: 'member',
              operator: 'is',
            })),
            condition: 'or',
          },
        ],
        condition: 'or',
      },
    },
    isNewMembersAdded: false,
  } as CriteriaResponse);

export const hasSomeMemberCriteria = (criteria: CriteriaGroups | undefined) => {
  const flattenedCriteria =
    criteria?.groups?.flatMap((rule) => rule).flatMap((r) => r?.groupRules) ||
    [];

  return flattenedCriteria.some(
    (rule) => rule.field === 'member' || rule.field === 'email',
  );
};

export const getCriteriaGroup = ({
  criteriaValue,
  criteriaField,
}: {
  criteriaValue: CriteriaValue;
  criteriaField: CriteriaField;
}) => {
  const groups: CriteriaGroup[] = [
    {
      groupId: uuid.v4(),
      groupCondition: 'or',
      groupRules: [
        {
          value: criteriaValue,
          operator: 'is',
          field: criteriaField,
          ruleId: uuid.v4(),
        },
      ],
    },
  ];
  return groups;
};

export const getUpdatedVisibilityCriteriaGroups = (
  criteriaGroupToBeUpdated: CriteriaGroups,
  existingGroups: CriteriaGroup[],
  updatedGroups: CriteriaGroup[],
) => {
  criteriaGroupToBeUpdated.groups = [...existingGroups, ...updatedGroups];
  criteriaGroupToBeUpdated.groupsCondition = 'or';
  return { ...criteriaGroupToBeUpdated } as CriteriaGroups;
};
