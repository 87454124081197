import React, { Component } from 'react';
import { EntryProps } from '../../../../atomic/atoms/Giphy/interface';
import GiphyList from '../GiphyList';
import styles from './styles.module.css';

interface GiphySelectProps {
  theme?: {
    select: string;
    selectInput: string;
    attribution: string;
  };
  placeholder?: string;
  requestDelay?: number;
  requestKey?: string;
  requestLang?: string;
  requestRating?: string;
  renderEntry?: (entry: any, onSelect: any, options: any) => React.ReactNode;
  autoFocus?: boolean;
  onEntrySelect?: (entry: EntryProps) => void;
}

interface GiphySelectState {
  items: unknown[];
}

export default class GiphySelect extends Component<
  GiphySelectProps,
  GiphySelectState
> {
  _requestTimer: ReturnType<typeof setTimeout> | null = null;

  private input: HTMLInputElement | null = null;

  state = {
    items: [],
  };

  UNSAFE_componentWillMount() {
    this._fetchItems();
  }

  shouldComponentUpdate = () => !this._activeFetch;

  componentDidMount() {
    setTimeout(() => {
      if (this.input && this.props.autoFocus) {
        this.input.focus();
      }
    }, 0);
  }

  loadNextPage = () => {
    if (this._offset < this._totalCount) {
      this._fetchItems();
    }
  };

  _onQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.trim();

    if (this._requestTimer) {
      clearTimeout(this._requestTimer);
      this._requestTimer = null;
    }

    this._requestTimer = setTimeout(() => {
      if (query !== this._query) {
        this._query = query;
        this._offset = 0;
        this._activeFetch = true;
        this.setState({
          items: [],
        });
        this._fetchItems();
      }
    }, this.props.requestDelay);
  };

  _onWheel = (e: React.WheelEvent) => e.preventDefault();

  _fetchItems = () => {
    const {
      requestKey = 'dc6zaTOxFJmzC',
      requestLang = '',
      requestRating = 'pg',
    } = this.props;
    let endpoint = '';
    if (this._query) {
      endpoint = `search?q=${encodeURIComponent(this._query)}&`;
    } else {
      endpoint = 'trending?';
    }
    const offset = this._offset;

    fetch(
      // eslint-disable-next-line max-len
      `${window.location.protocol}//api.giphy.com/v1/gifs/${endpoint}offset=${offset}&lang=${requestLang}&rating=${requestRating}&api_key=${requestKey}`,
    )
      .then((response) => response.json())
      .then(this._updateItems)
      .catch(console.error); // eslint-disable-line no-console
  };

  _updateItems = (response) => {
    this._activeFetch = false;
    this.setState((prevState) => ({
      items: [...prevState.items, ...response.data],
    }));
    this._offset = response.pagination.offset + response.pagination.count;
    this._totalCount = response.pagination.total_count;
  };

  _setInputRef = (input: HTMLInputElement) => {
    this.input = input;
  };

  _theme = {
    select: this.props.theme?.select || styles.select,
    selectInput: this.props.theme?.selectInput || styles.selectInput,
    attribution: this.props.theme?.attribution || styles.attribution,
  };

  _query = '';

  _offset = 0;

  _totalCount = 0;

  _activeFetch = false;

  render() {
    const {
      placeholder = 'Select GIFs',
      renderEntry,
      onEntrySelect,
    } = this.props;
    const theme = { ...this._theme, ...this.props.theme };

    return (
      <div className={theme.select} onWheel={this._onWheel}>
        <input
          className={theme.selectInput}
          placeholder={placeholder}
          ref={this._setInputRef}
          onChange={this._onQueryChange}
        />
        <GiphyList
          theme={theme}
          items={this.state.items}
          renderEntry={renderEntry}
          onEntrySelect={onEntrySelect}
          loadNextPage={this.loadNextPage}
        />
        <div className={theme.attribution}>Powered by Giphy</div>
      </div>
    );
  }
}
