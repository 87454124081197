import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Uppy, { IndexedObject, UploadedUppyFile, UppyFile } from '@uppy/core';
import { DragDrop } from '@uppy/react';

import { useUppyStore } from '../../../../stores/uppyStore';
import {
  AWSUploadedUppyFile,
  determineFileStatus,
  determineFileType,
  getExistingFiles,
  getFileCategoryByFileType,
  mapResponseFileToPayloadFile,
  transformUploadedUppyFileToFileForAPI,
} from './utils';
import FileCard from '../../FileCard';
import FlowsBaseInputBlock from '../FlowsBaseInputBlock';
import { showErrorMessage } from '../../../../Utils/toast';
import NavigationInstructions from '../../FlowsInputBlockNavigationInstructions';
import {
  HiddenInput,
  StyledFileCardList,
  StyleFileUploadContainer,
  DisabledDragAndDrop,
  DisabledDragDropInner,
} from './styles';
import Body from '../../../atoms/Body';
import {
  FLOW_FILE_UPLOAD_ERROR_TEXTS,
  ERROR_UPLOADING,
  UPLOAD_FILE,
  PREVIEW_DISABLE_UPLOAD_TEXT,
  getMaxLimitUploadText,
} from '../../../../languages/en/flows';
import { FileUploadStatus, File } from '../../FileCard/types';
import useUploadStore from '../../../../stores/uploadStore';

export type FlowsFileUploadInputBlockProps = {
  blockId: string;
  description?: string;
  goToNextStep: () => void;
  isRequired: boolean;
  isLastBlock?: boolean;
  subDescription?: string;
  onChange: (newValue: File[] | null) => void;
  onBlur?: () => void;
  title: string;
  uppy: Uppy;
  value: File[] | null | undefined;
  onDeleteFileClick: (fileName: string) => void;
  setIsFetchingUploadUrl?: (isFetching: boolean) => void;
  isPreviewFlow?: boolean;
};

function FlowsFileUploadInputBlock({
  blockId,
  description,
  isRequired,
  onChange,
  subDescription,
  title,
  uppy,
  goToNextStep,
  onDeleteFileClick,
  setIsFetchingUploadUrl,
  isPreviewFlow,
  isLastBlock = false,
  value,
}: FlowsFileUploadInputBlockProps) {
  const [fileUploadError, setFileUploadError] = useState('');
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const files = useUppyStore((state) => state.stores[blockId]?.files);
  const [initialFiles, setInitialFiles] = useState<File[]>([]);

  const uppyFiles = useMemo(
    () => (files ? Object.keys(files).map((fileId) => files[fileId]) : []),
    [files],
  );

  const { fileUploadLimit } = useUploadStore();

  useEffect(() => {
    const uploadingFiles = uppyFiles.filter((uppyFile: UppyFile) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      Boolean(uppyFile.xhrUpload),
    ) as UploadedUppyFile<IndexedObject<any>, IndexedObject<any>>[];

    if (setIsFetchingUploadUrl) {
      setIsFetchingUploadUrl(uppyFiles.length !== uploadingFiles.length);
    }

    const transformedFiles = uploadingFiles.map((uploadingFile) =>
      transformUploadedUppyFileToFileForAPI(
        uploadingFile as AWSUploadedUppyFile,
      ),
    );

    const existingFiles = getExistingFiles(initialFiles);

    const allFiles =
      existingFiles.length > 0
        ? [...existingFiles, ...transformedFiles]
        : transformedFiles;

    onChange(allFiles as File[]);
  }, [initialFiles, onChange, setIsFetchingUploadUrl, uppyFiles]);

  useEffect(() => {
    if (value) {
      const existingFiles = getExistingFiles(
        initialFiles.length > 0 ? initialFiles : value,
      );
      const transformedFiles = mapResponseFileToPayloadFile(existingFiles);
      setInitialFiles(transformedFiles);
      onChange(transformedFiles);
    }
  }, []);

  uppy.on('restriction-failed', (file, error) => {
    if (error) {
      setFileUploadError(error.message);
    }
  });

  uppy.on('file-removed', () => {
    setFileUploadError('');
  });

  useEffect(() => {
    if (fileUploadError !== '') {
      showErrorMessage(fileUploadError);
    }
  }, [fileUploadError]);

  uppy.on('file-added', () => {
    hiddenInputRef.current?.focus();
  });

  const removeExistingFileByLocation = useCallback(
    ({
      fileLocation,
      isDeadFile = false,
    }: {
      fileLocation: string;
      isDeadFile?: boolean;
    }) => {
      const updatedInitialFiles = initialFiles.filter((file) =>
        isDeadFile
          ? !(file.location === fileLocation && file.size === 0)
          : file.location !== fileLocation,
      );
      const allFiles =
        value?.filter((file) =>
          isDeadFile
            ? !(file.location === fileLocation && file.size === 0)
            : file.location !== fileLocation,
        ) || [];
      setInitialFiles(updatedInitialFiles);
      onChange(allFiles);
    },
    [initialFiles, onChange, value],
  );

  const placeHolderText = useMemo(() => {
    if (fileUploadError === FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_FILES)
      return FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_THAN_15_FILES;
    if (fileUploadError === FLOW_FILE_UPLOAD_ERROR_TEXTS.MAX_FILE_SIZE)
      return FLOW_FILE_UPLOAD_ERROR_TEXTS.MORE_THAN_25_MB;
    return 'Upload File';
  }, [fileUploadError]);

  return (
    <FlowsBaseInputBlock
      description={description}
      isRequired={isRequired}
      navigationInstructions={
        <NavigationInstructions
          type={isLastBlock ? 'last+enter' : 'enter'}
          goToNextStep={goToNextStep}
          isPreviewFlow={isPreviewFlow}
        />
      }
      subDescription={subDescription}
      title={title}
    >
      <HiddenInput type="button" ref={hiddenInputRef} />
      <StyleFileUploadContainer>
        <StyledFileCardList>
          {initialFiles &&
            initialFiles.map((file: File) => {
              return (
                <FileCard
                  key={`${file.name}${file.size}`}
                  file={{
                    type: getFileCategoryByFileType(file.type),
                    name: file.name,
                    thumbnails: file.thumbnails,
                    size: file.size,
                  }}
                  isParticipation
                  onClickClose={() => {
                    if (file.location) {
                      removeExistingFileByLocation({
                        fileLocation: file.location,
                        isDeadFile: file.size === 0,
                      });
                    }
                  }}
                  status={
                    file.size === 0
                      ? FileUploadStatus.Error
                      : FileUploadStatus.Success
                  }
                  dataTestId="file-block-file-card"
                />
              );
            })}
          {uppyFiles.map((file: UppyFile) => {
            return (
              <FileCard
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /* @ts-ignore */
                errorMessage={file.error && ERROR_UPLOADING}
                key={file.id}
                loadingProgress={file.progress?.percentage}
                onClickRetry={() => uppy.retryUpload(file.id)}
                file={{
                  type: determineFileType(file),
                  name: file.name,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /* @ts-ignore */
                  thumbnails: {
                    360: URL.createObjectURL(file.data),
                  },
                  size: file.size,
                }}
                isParticipation
                onClickClose={() => {
                  uppy.removeFile(file.id);
                  onDeleteFileClick(file.name);
                }}
                status={determineFileStatus(file)}
                dataTestId="file-block-file-card"
              />
            );
          })}
        </StyledFileCardList>
        {!isPreviewFlow ? (
          <DragDrop
            width="100%"
            height="100%"
            note={getMaxLimitUploadText(fileUploadLimit)}
            uppy={uppy}
            locale={{
              strings: {
                dropHereOr: UPLOAD_FILE,
              },
            }}
          />
        ) : (
          <DisabledDragAndDrop>
            <DisabledDragDropInner>
              <Body variant="body1" color="gray8">
                {placeHolderText}
              </Body>
              <Body variant="body2" color="gray6">
                {PREVIEW_DISABLE_UPLOAD_TEXT}
              </Body>
            </DisabledDragDropInner>
          </DisabledDragAndDrop>
        )}
      </StyleFileUploadContainer>
    </FlowsBaseInputBlock>
  );
}

export default FlowsFileUploadInputBlock;
