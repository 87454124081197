/* eslint-disable max-len */
import ThemeV2 from '../../theme';
import { FeatureIntroOptions } from '../../hooks/useFeatureIntro';

const flowsFeedsIntroStrings = () => ({
  firstStep: {
    intro: `<div style="width:450px;height:360px;"><h6 style="color:${ThemeV2.palette.gray9};size:20px;font-weight: bold;line-height:28px">Welcome to your Assembly workspace!</h6><p style="color:${ThemeV2.palette.gray9};size:16px;line-height:24px;">It’s time to level up your workday! Explore our templates, create your own workflows, and invite your teammates (because everything is better with friends).</p><img src="https://media.giphy.com/media/5L57f5fI3f2716NaJ3/giphy.gif" loading="eager" width="450px" height="255px" style="border-radius:4px;"/></div>`,
  },
  secondStep: {
    element: document.querySelector('#intro-flows-feed-step-2') || '',
    intro: `<div style="width:200px;height:124px;"><p style="font-weight:bold;size:16px;line-height:24px;margin-bottom:4px;">Write your first flow post</p><p style="font-weight:400;size:14px;line-height:22px;">Click the action bar to try participating in your new flow.</p></div>`,
  },
  thirdStep: {
    element: document.querySelector('#intro-flows-feed-step-3') || '',
    intro: `<div style="width:200px;height:124px;"><p style="font-weight:bold;size:16px;line-height:24px;margin-bottom:4px;">Customize your flow</p><p style="font-weight:400;size:14px;line-height:22px;">Go to the editor to tailor the flow to your liking with a range of different question types and prompts.</p></div>`,
  },
  fourStep: {
    element: document.querySelector('.introjs-sharesheet-share-button') || '',
    intro: `<div style="width:200px;height:124px;"><p style="font-weight:bold;size:16px;line-height:24px;margin-bottom:4px;">Get the team involved</p><p style="font-weight:400;size:14px;line-height:22px;">Once you’ve set up this flow to your liking, share it with your teammates so they can start posting too.</p></div>`,
  },
});

const getFlowsFeedsIntroSteps = () => {
  const steps = flowsFeedsIntroStrings();
  return [
    {
      ...steps.firstStep,
    },
    {
      ...steps.secondStep,
    },
    {
      ...steps.thirdStep,
    },
    {
      ...steps.fourStep,
    },
  ];
};

export const getFlowsFeedIntroOptions = (): FeatureIntroOptions => {
  return {
    getStepDetails() {
      return getFlowsFeedsIntroSteps();
    },
    toolTipPosition: 'right',
    doneLabel: 'Get started',
    hidePrev: true,
    showBullets: false,
    prevLabel: '',
    tooltipClass: 'flowsFeed',
  };
};
