import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';

import { useSearchNotebookTasksQuery } from '../queries/Notebook';
import { useProfileInfoFetchQuery } from '../queries/Profile';
import { convertAPITaskToTaskAndCategory } from '../Utils/notebook';
import { TaskFromAPI } from '../interfaces/notebook';

export const useNotebookSearch = () => {
  const [value, setValue] = useState<string>('');
  const [keyword, setKeyword] = useState<string>('');

  const debounceSearchValue = useRef(
    debounce((newValue: string) => {
      setKeyword(newValue);
    }, 500),
  );

  const handleChange = (newValue: string) => {
    setValue(newValue);
    debounceSearchValue.current(newValue);
  };

  const { data: profileInfo } = useProfileInfoFetchQuery();
  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  const {
    refetch,
    isError,
    data: tasksSuggestions,
    isInitialLoading: isLoading,
  } = useSearchNotebookTasksQuery(keyword);
  const taskOptions = useMemo(() => {
    if (tasksSuggestions) {
      return tasksSuggestions.tasks.map((task: TaskFromAPI) => {
        const [formattedTask] = convertAPITaskToTaskAndCategory(
          task,
          currentUserTimezone,
        );

        return formattedTask;
      });
    }
    return [];
  }, [currentUserTimezone, tasksSuggestions]);

  return {
    models: { currentUserTimezone, isError, isLoading, taskOptions, value },
    operations: { onChange: handleChange, refetch },
  };
};
