import {
  useQuery,
  useMutation,
  InfiniteData,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';

import produce from 'immer';

import {
  GetFlowDetailsResponse,
  PaginationResponse,
  GetFlowsActivityUpdatesResponse,
  GetFlowFileResponse,
  GetFlowAuthorizationResponse,
  EntityPermissionApiResponse,
} from '../interfaces';
import {
  EntityPermissionPayload,
  GetFeedResponse,
  PostFlowFeedPayload,
  UpdateReactionAction,
} from '../../../interfaces/Feed';
import {
  FileOperationsRequest,
  FlowFeedResponse,
  ReactionRequestPayload,
} from './interfaces';

import { makeAPICall, makeAPICallWithDataReturn } from '../../utils';

import {
  DELETE_FLOW_RESPONSE,
  GET_FLOWS_ACTIVITY,
  GET_FLOW_DETAILS,
  GET_FLOW_FEED,
  GET_FLOW_POST_DOWNLOAD_URL,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_PROFILE_FEED,
  GET_PROFILE_INFO,
  RECOGNITION_POST_NOTIFICATION_PREFERENCE,
  UPDATE_FLOW_POST_REACTION,
  UPDATE_SCHEDULE_CARD_STATUS,
  VERIFY_FLOW_AUTHORIZATION,
  GET_SINGLE_POST,
  V3_GET_FEED,
  CREATE_FLOW_SHARE_REQUEST,
  GET_ENTITY_PERMISSION_DETAILS,
  GET_FILE_PREVIEW_URL,
} from '../../../constants/endpoints';

import {
  findInPaginatedFlowFeedData,
  updatePostReactionsForFlowFeedInDraft,
} from './utils';
import axios, { AxiosError } from 'axios';
import { updateFlowPostReactionsInDraft } from '../../Feed/utils';
import {
  FlowShareRequestPayload,
  UpdateNotificationsPreferencePayload,
} from '../../../interfaces/Flow';
import { FlowCollaborator } from '../../../interfaces/member';
import { datadogLogs } from '@datadog/browser-logs';

export const useGetFlowFeedsQuery = ({
  flowId,
  flowFeedsSort,
  filter = {},
}: PostFlowFeedPayload) => {
  const queryClient = useQueryClient();
  const cachedActivityData =
    queryClient.getQueryData<GetFlowsActivityUpdatesResponse>([
      GET_FLOWS_ACTIVITY,
    ]);
  return useInfiniteQuery<PaginationResponse<FlowFeedResponse>>(
    [GET_FLOW_FEED, flowFeedsSort, flowId, filter],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_FLOW_FEED,
        {
          cursor: pageParam,
          sortBy: flowFeedsSort,
          filter,
        },
        undefined,
        { flowId },
      ),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
      enabled:
        Boolean(flowId) &&
        Boolean(flowId.toLowerCase() === 'editor' ? false : flowId),
      onSuccess: () => {
        if (cachedActivityData) {
          const updatedActivityUpdatesCache = cachedActivityData.data.map(
            (item) => {
              if (item.flowId === flowId) {
                return {
                  ...item,
                  unreadMentionsCount: 0,
                  hasUnreadPost: false,
                };
              }
              return item;
            },
          );

          queryClient.setQueryData([GET_FLOWS_ACTIVITY], {
            data: updatedActivityUpdatesCache,
          });
        }
      },
    },
  );
};

export const useEntityPermissionsQuery = (payload: EntityPermissionPayload) => {
  return useQuery<EntityPermissionApiResponse>(
    [GET_ENTITY_PERMISSION_DETAILS, payload.entityIds.sort().join(',')],
    () => makeAPICall(GET_ENTITY_PERMISSION_DETAILS, payload),
  );
};

// ToDo: Arun - Handle Error in mutation? We need to show error message to the user - as discussed in FE Call.
export const useUpdateFlowReactionMutation = ({
  flowId,
  flowFeedsSort,
  filter,
}: PostFlowFeedPayload) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload, responseId, action }: ReactionRequestPayload) => {
      return makeAPICall(
        UPDATE_FLOW_POST_REACTION,
        payload,
        {},
        { flowId, responseId, action },
      );
    },
    {
      onMutate: ({
        payload,
        responseId,
        action,
        user,
      }: ReactionRequestPayload) => {
        const queryKey = [GET_FLOW_FEED, flowFeedsSort, flowId, filter];
        const flowFeed = queryClient.getQueryData(queryKey);
        const previousData = flowFeed as InfiniteData<
          PaginationResponse<FlowFeedResponse>
        >;
        const updatedData = produce(previousData, (draft) => {
          const updatedPost = findInPaginatedFlowFeedData(
            draft.pages,
            responseId,
          );
          if (updatedPost) {
            updatePostReactionsForFlowFeedInDraft(
              updatedPost,
              payload,
              action === 'set'
                ? UpdateReactionAction.SET
                : UpdateReactionAction.UNSET,
              user,
            );
          }
        });
        queryClient.setQueryData(queryKey, updatedData);

        const flowResponseKey = [GET_FLOW_RESPONSE, flowId, responseId];
        const flow = queryClient.getQueryData(flowResponseKey);
        if (flow) {
          const data = produce(flow as FlowFeedResponse, (post) => {
            updatePostReactionsForFlowFeedInDraft(
              post,
              payload,
              action === 'set'
                ? UpdateReactionAction.SET
                : UpdateReactionAction.UNSET,
              user,
            );
          });

          queryClient.setQueryData(flowResponseKey, data);
        }

        return { previousData };
      },
    },
  );
};

export const useUpdateSingleFlowReactionMutation = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload, responseId, action }: ReactionRequestPayload) => {
      return makeAPICall(
        UPDATE_FLOW_POST_REACTION,
        payload,
        {},
        { flowId, responseId, action },
      );
    },
    {
      onMutate: ({
        payload,
        responseId,
        action,
        user,
      }: ReactionRequestPayload) => {
        const queryKey = [GET_FLOW_RESPONSE, flowId, responseId];
        const flowFeed = queryClient.getQueryData(queryKey);
        const previousData = flowFeed as FlowFeedResponse;
        const updatedData = produce(previousData, (post) => {
          updatePostReactionsForFlowFeedInDraft(
            post,
            payload,
            action === 'set'
              ? UpdateReactionAction.SET
              : UpdateReactionAction.UNSET,
            user,
          );
        });
        queryClient.setQueryData(queryKey, updatedData);

        // Update Feeds
        let keys = [GET_FLOW_FEED];
        keys.forEach((key) => {
          const queriesData = queryClient.getQueriesData([key]);
          if (queriesData && queriesData.length) {
            queriesData.forEach((queryData) => {
              const queryDataKey = queryData[0];
              const flowFeedData = queryClient.getQueryData(queryDataKey);
              const previousFlowFeedData = flowFeedData as InfiniteData<
                PaginationResponse<FlowFeedResponse>
              >;

              const data = produce(previousFlowFeedData, (draft) => {
                const updatedPost = findInPaginatedFlowFeedData(
                  draft.pages,
                  responseId,
                );
                if (updatedPost) {
                  updatePostReactionsForFlowFeedInDraft(
                    updatedPost,
                    payload,
                    action === 'set'
                      ? UpdateReactionAction.SET
                      : UpdateReactionAction.UNSET,
                    user,
                  );
                }
              });

              queryClient.setQueryData(queryDataKey, data);
            });
          }
        });

        keys = [GET_MAIN_FEED, GET_PROFILE_FEED];
        keys.forEach((key) => {
          const queriesData = queryClient.getQueriesData([key]);
          if (queriesData && queriesData.length) {
            queriesData.forEach((queryData) => {
              const queryDataKey = queryData[0];
              const flowFeedData = queryClient.getQueryData(queryDataKey);
              const previousFlowFeedData =
                flowFeedData as InfiniteData<GetFeedResponse>;

              const data = produce(previousFlowFeedData, (draft) => {
                let postIndex;
                const postFoundPage = draft.pages.find((page) => {
                  postIndex = page.data.findIndex(
                    (post) => post?.flowResponse?.responseId === responseId,
                  );
                  return postIndex !== -1;
                });

                if (postIndex !== undefined && postFoundPage) {
                  const currentItem = postFoundPage.data[postIndex];
                  if (currentItem) {
                    updateFlowPostReactionsInDraft(
                      currentItem,
                      payload,
                      action === 'set'
                        ? UpdateReactionAction.SET
                        : UpdateReactionAction.UNSET,
                      {
                        name: user?.name || '',
                        memberID: user?.memberID || '',
                      },
                    );
                  }
                }
              });

              queryClient.setQueryData(queryDataKey, data);
            });
          }
        });

        return { previousData };
      },
    },
  );
};

export const useDeleteFlowPost = (
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      flowId,
      responseId,
      returnPoints,
    }: {
      flowId: string;
      responseId: string;
      returnPoints: boolean;
    }) => {
      return makeAPICall(
        DELETE_FLOW_RESPONSE,
        undefined,
        { returnPoints },
        {
          flowId,
          responseId,
        },
      );
    },
    {
      onSuccess: (flowId, responseId, returnPoints) => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries([GET_PROFILE_FEED]);
        queryClient.invalidateQueries([GET_FLOW_FEED]);
        queryClient.invalidateQueries([GET_FLOW_RESPONSE, flowId, responseId]);
        if (returnPoints) {
          queryClient.refetchQueries([GET_PROFILE_INFO]);
        }
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onError: () => {
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    },
  );
};

export const useFetchFlowDetailsQuery = (
  flowId = '',
  request = '',
  enabled = true,
  onErrorCallback?: (error: AxiosError<{ message: string }>) => void,
) => {
  return useQuery<GetFlowDetailsResponse>(
    [GET_FLOW_DETAILS, flowId, request],
    () =>
      makeAPICall(
        GET_FLOW_DETAILS,
        undefined,
        { type: request },
        {
          flowId,
        },
      ),
    {
      onError: (error: unknown) => {
        if (onErrorCallback) {
          onErrorCallback(error as AxiosError<{ message: string }>);
        }
      },
      retry: false,
      refetchOnMount: !request,
      staleTime: Infinity,
      enabled:
        enabled && Boolean(flowId.toLowerCase() === 'editor' ? false : flowId),
      select: (response) => {
        const sortedCollaborators: FlowCollaborator[] | undefined =
          response.data.collaborators?.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        return {
          ...response,
          data: { ...response.data, collaborators: sortedCollaborators },
        };
      },
    },
  );
};

export const useFetchFlowFileQuery = (
  request: FileOperationsRequest,
  fileValue: boolean | undefined = undefined,
) => {
  return useQuery<GetFlowFileResponse, AxiosError>(
    [GET_FLOW_POST_DOWNLOAD_URL, request],
    async () => {
      try {
        return await makeAPICall(
          GET_FLOW_POST_DOWNLOAD_URL,
          undefined,
          undefined,
          {
            ...request,
          },
        );
      } catch (e) {
        const errorInfo = e instanceof Error ? e : undefined;

        datadogLogs.logger.error(
          'Error fetching file-download link',
          {
            ...request,
            errorInfo,
          },
          errorInfo,
        );

        throw e;
      }
    },
    {
      enabled: Boolean(fileValue),
      staleTime: 240000, // 4 minutes in milliseconds
    },
  );
};

export const useFetchPreviewFileQuery = (
  request: FileOperationsRequest,
  fileValue: boolean | undefined = undefined,
) => {
  return useQuery<GetFlowFileResponse, AxiosError>(
    [GET_FILE_PREVIEW_URL, request],
    async () => {
      try {
        return await makeAPICall(GET_FILE_PREVIEW_URL, undefined, undefined, {
          ...request,
        });
      } catch (e) {
        const errorInfo = e instanceof Error ? e : undefined;

        const isFileTooLargeError =
          axios.isAxiosError(e) && e.response?.status === 413;

        if (!isFileTooLargeError) {
          datadogLogs.logger.error(
            'Error fetching file-preview link',
            {
              ...request,
              errorInfo,
            },
            errorInfo,
          );
        }

        throw e;
      }
    },
    {
      enabled: Boolean(fileValue),
      staleTime: 240000, // 4 minutes in milliseconds
    },
  );
};

export const useVerifyFlowAuthorization = (
  flowId: string,
  enabled = true,
  onError?: (error: unknown) => void,
) => {
  return useQuery<GetFlowAuthorizationResponse>(
    [VERIFY_FLOW_AUTHORIZATION, flowId],
    () =>
      makeAPICall(VERIFY_FLOW_AUTHORIZATION, undefined, undefined, {
        flowId,
      }),
    {
      retry: false,
      enabled,
      onError,
    },
  );
};

export const useUpdateScheduleCardStatus = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      makeAPICall(UPDATE_SCHEDULE_CARD_STATUS, undefined, undefined, {
        flowId,
      }),
    {
      onSuccess: async () => {
        const cacheObject: GetFlowDetailsResponse | undefined =
          queryClient.getQueryData([GET_FLOW_DETAILS, flowId, '']);
        if (cacheObject?.data) {
          cacheObject.data.showScheduleCard = false;
        }
        queryClient.setQueryData([GET_FLOW_DETAILS], cacheObject);
      },
    },
  );
};

export const useUpdateFlowPostNotificationPreference = (postId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateNotificationsPreferencePayload) =>
      makeAPICall(
        RECOGNITION_POST_NOTIFICATION_PREFERENCE,
        payload,
        undefined,
        {
          postId,
        },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries([GET_PROFILE_FEED]);
        queryClient.invalidateQueries([GET_SINGLE_POST, postId]);
        queryClient.invalidateQueries([V3_GET_FEED]);
      },
    },
  );
};

export const useCreateFlowShareRequestMutation = (flowId: string) => {
  return useMutation((payload: FlowShareRequestPayload) =>
    makeAPICall(CREATE_FLOW_SHARE_REQUEST, payload, undefined, { flowId }),
  );
};
