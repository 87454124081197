import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Dropdown from '../../molecules/Dropdown_V2';

export const ModalBodyHeading = styled(Heading)`
  margin: 0 0 8px 0;
`;

export const ModalBodyDropdown = styled(Dropdown)`
  margin-top: 16px;
  z-index: 2;
`;
