import { FileViewerProps } from '..';
import { DOWNLOAD_TO_VIEW, FILE_TOO_BIG } from '../languages/en';
import { DOWNLOAD_FILE } from '../../../../languages/en/home';
import largePreviewIllustration from '../../../../img/large-preview.svg';
import EmptyFilePreview from '../shared/EmptyFilePreview';

const LargeFileViewer = (props: FileViewerProps) => {
  const { onDownloadClick } = props;

  return (
    <EmptyFilePreview
      cta={DOWNLOAD_FILE}
      imgSrc={largePreviewIllustration}
      onClick={onDownloadClick}
      subtitle={DOWNLOAD_TO_VIEW}
      title={FILE_TOO_BIG}
    />
  );
};

export default LargeFileViewer;
