import styled from 'styled-components';
import ThemeV2 from '../../../theme';

export const StyledWrapper = styled.div`
  padding: 4px 0;
  width: 100%;
`;

export const SettingsWrapper = styled.div`
  overflow-y: auto;
  padding: 24px 24px 16px;
  height: calc(100vh - 166px);
  background: ${ThemeV2.palette.gray1};
`;

export const FormWrapper = styled.div<{ margin?: string }>`
  margin: ${({ margin }) => margin || '0'};
  [role='button'] {
    max-width: 75%;
  }
`;
