import React from 'react';
import Avatar from '../../atoms/Avatar';
import { AvatarProps } from '../../atoms/Avatar/interfaces';
import { BodyProps } from '../../atoms/Body';
import CountIndicator, {
  CountIndicatorProps,
} from '../../atoms/CountIndicator';
import { SVGIconProps } from '../../atoms/SVGIcon';
import DropdownListHolder from '../DropdownListHolder';
import { EmoticonProps, MenuListItemProps, SlotProps } from './interfaces';
import {
  FolderIndicator,
  IconBackground,
  PrefixSVGIcon,
  PrefixSVGIconWrapper,
  SVGIconWithBackgroundWrapper,
  StyledBody,
  StyledEmoticon,
  StyledMenuItem,
  StyledSVGIcon,
} from './styles';

const MenuList = ({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) => {
  return <>{children}</>;
};

const PrefixElement = (props: SVGIconProps) => {
  const { size = '20px', ...rest } = props;
  return <>{props && <PrefixSVGIcon size={size} {...rest} />}</>;
};

const Emoticon = ({ emoticon }: EmoticonProps) => {
  return <StyledEmoticon>{emoticon}</StyledEmoticon>;
};

const Icon = (props: SVGIconProps) => {
  const { size = '24px', ...rest } = props;
  return <StyledSVGIcon size={size} {...rest} />;
};

const StyledSVGIconWithBackground = (props: SVGIconProps) => {
  const { size = '16px', ...rest } = props;
  return (
    <SVGIconWithBackgroundWrapper justifyContent="center">
      <StyledSVGIcon size={size} {...rest} />
    </SVGIconWithBackgroundWrapper>
  );
};

const MenuItemAvatar = (props: AvatarProps) => {
  return <Avatar {...props} />;
};

const Text = (props: BodyProps) => {
  return <StyledBody className={'menuItemText'} {...props} />;
};

const SuffixElement = (props: CountIndicatorProps) => {
  return <CountIndicator {...props} />;
};

const Item = ({
  children,
  isSelected,
  onClick,
  onRightClick,
  className,
  refElement,
  anchorEl,
  id,
  isExpanded,
  disabled,
  folderColor,
  isMuted = false,
  disableHoverState = false,
}: MenuListItemProps) => {
  const slots: SlotProps = {
    prefix: null,
    icon: null,
    emoticon: null,
    avatar: null,
    iconButton: null,
    text: null,
    suffix: null,
    dropdownListHolder: null,
  };

  React.Children.forEach(children, (child) => {
    switch (child?.type) {
      case MenuList.PrefixElement: {
        slots.prefix = child;
        break;
      }
      case MenuList.Emoticon: {
        slots.emoticon = child;
        break;
      }
      case MenuList.Icon: {
        slots.icon = child;
        break;
      }
      case MenuList.IconButton: {
        slots.iconButton = child;
        break;
      }
      case MenuList.Avatar: {
        slots.avatar = child;
        break;
      }
      case MenuList.Text: {
        slots.text = child;
        break;
      }
      case MenuList.SuffixElement: {
        slots.suffix = child;
        break;
      }
      case MenuList.DropdownListHolder: {
        slots.dropdownListHolder = child;
        break;
      }
    }
  });

  return (
    <>
      <div ref={refElement} className={className}>
        <StyledMenuItem
          onClick={onClick}
          onContextMenu={onRightClick}
          isSelected={isSelected}
          isExpanded={isExpanded}
          disableHoverState={disableHoverState || disabled}
          isMuted={isMuted}
          data-testid="menu-item"
          id={id}
          disabled={disabled}
        >
          {folderColor && <FolderIndicator folderColor={folderColor} />}
          <PrefixSVGIconWrapper isExpanded={isExpanded}>
            {slots.prefix && slots.prefix}
          </PrefixSVGIconWrapper>
          {slots.icon && (
            <div style={{ position: 'relative' }}>
              <IconBackground isSelected={isSelected} isExpanded={isExpanded} />
              {slots.icon}
            </div>
          )}
          {slots.emoticon && (
            <div style={{ position: 'relative' }}>
              <IconBackground isSelected={isSelected} isExpanded={isExpanded} />
              {slots.emoticon}
            </div>
          )}
          <div>{slots.avatar && slots.avatar}</div>
          <div>{slots.iconButton && slots.iconButton}</div>
          <div>{slots.text}</div>

          <div>
            <div>{slots.suffix && slots.suffix}</div>
          </div>
        </StyledMenuItem>
        {slots.dropdownListHolder && (
          <div ref={anchorEl}>{slots.dropdownListHolder}</div>
        )}
      </div>
    </>
  );
};

MenuList.Item = Item;
MenuList.PrefixElement = PrefixElement;
MenuList.Emoticon = Emoticon;
MenuList.Icon = Icon;
MenuList.Avatar = MenuItemAvatar;
MenuList.Text = Text;
MenuList.SuffixElement = SuffixElement;
MenuList.DropdownListHolder = DropdownListHolder;
MenuList.IconButton = StyledSVGIconWithBackground;

export default MenuList;
