import Body from '../../../atomic/atoms/Body';
import Heading from '../../../atomic/atoms/Heading';
import WorkspaceSetupContainer from '../../../atomic/molecules/WorkspaceSetupContainer';
import {
  CustomHeader,
  LogoContainer,
  StyledLogo,
} from '../../../atomic/organism/PrimaryHeader/styles';
import { ACCOUNT_SETUP_JOB } from '../../../constants/endpoints';
import useOnboardingJobSetup from '../../../hooks/useOnboardingJobSetup';
import { Flex } from '../../../Utils/styles/display';
import OnBoardingScreenWithBooking from './OnBoardingScreenWithBooking';

import {
  Wrapper,
  MainContainer,
  OnboardingHeader,
  SelectionWrapper,
  StyledClickableSelectionItem,
} from './styles';
import { useFlowsOnboardingWithQuickSetupController } from './useFlowsOnboardingWithQuickSetupController';

const FlowsOnboardingWithQuickSetupController = () => {
  const {
    isSuccess,
    companyLogo,
    companyName,
    optionItems,
    preJobResponse,
    selectedItemId,
    canSetManagerFilter,
    onContinueExploringButtonClick,
    isNewOnboardingScreen,
  } = useFlowsOnboardingWithQuickSetupController();
  const TriggerJobAndRenderSetupScreen = () => {
    useOnboardingJobSetup({
      apiName: ACCOUNT_SETUP_JOB,
      onboardingPath: selectedItemId,
      preJobResponse: preJobResponse,
      canSetManagerFilter,
      pathName: '/home?showTemplate=true',
    });
    return (
      <WorkspaceSetupContainer
        title="We’re setting up your workspace!"
        marginTop="48px"
      />
    );
  };
  if (isNewOnboardingScreen && !isSuccess) {
    return (
      <>
        <OnboardingHeader>
          <Flex margin="0 0 0 12px">
            <LogoContainer>
              <StyledLogo src={companyLogo} alt={companyName} />
            </LogoContainer>
            <CustomHeader>{companyName}</CustomHeader>
          </Flex>
        </OnboardingHeader>
        <div style={{ margin: '0 auto', maxWidth: '718px' }}>
          <OnBoardingScreenWithBooking
            onClick={onContinueExploringButtonClick}
          />
        </div>
      </>
    );
  }
  return (
    <Wrapper flexDirection="column">
      <OnboardingHeader>
        <Flex margin="0 0 0 12px">
          <LogoContainer>
            <StyledLogo src={companyLogo} alt={companyName} />
          </LogoContainer>
          <CustomHeader>{companyName}</CustomHeader>
        </Flex>
      </OnboardingHeader>
      {isSuccess ? (
        <TriggerJobAndRenderSetupScreen />
      ) : (
        <MainContainer>
          <SelectionWrapper>
            <Heading variant="h4" weight="medium">
              How are you planning on using Assembly?
            </Heading>
            <Body variant="body1" color="gray8">
              Choose a path, tailor your experience!
            </Body>
            {optionItems.map((item) => (
              <StyledClickableSelectionItem {...item} key={item.title} />
            ))}
          </SelectionWrapper>
        </MainContainer>
      )}
    </Wrapper>
  );
};

export default FlowsOnboardingWithQuickSetupController;
