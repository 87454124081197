import { AxiosError } from 'axios';
import { useLocation } from 'react-router';
import { useArchiveFlow } from '../../queries/Flows/FlowFeedOptions/Archive';
import {
  postErrorToastMessage,
  postSuccessToastMessage,
  showErrorMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import { FLOW_ARCHIVE_RESPONSE_MESSAGE } from '../../languages/en/flows/feed';
import { getErrorMessage } from '../../Utils/message';
import useToggle from '../useToggle';
import { V2_HOME } from '../../constants/routes';
import useHistoryWrapper from '../useHistoryWrapper';
import useLayoutStore from '../../stores/layoutStore';

const useArchiveFlowOption = (flowId: string) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const history = useHistoryWrapper();
  const location = useLocation();
  const {
    models: { toggleValue: isArchiveModalOpen },
    operations: {
      setToggleToFalse: setArchiveModalClose,
      setToggleToTrue: setArchiveModalOpen,
    },
  } = useToggle();

  const isUserOnTheCurrentFlow = location.pathname.includes(flowId);

  const { mutate: mutateArchiveFlows, isLoading: isArchiveFlowLoading } =
    useArchiveFlow();

  const handleOnArchiveClick = () => {
    mutateArchiveFlows(
      { flowId },
      {
        onError: (error) => {
          if (isEmbeddedInMainApp) {
            const errorMessage = getErrorMessage(
              error as AxiosError<{ message: string; body: string }>,
              FLOW_ARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
            postErrorToastMessage(
              errorMessage || FLOW_ARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
          } else {
            const errorMessage = getErrorMessage(
              error as AxiosError<{ message: string; body: string }>,
              FLOW_ARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
            showErrorMessage(
              errorMessage || FLOW_ARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
          }
        },
        onSuccess: () => {
          if (isEmbeddedInMainApp) {
            postSuccessToastMessage(FLOW_ARCHIVE_RESPONSE_MESSAGE.SUCCESS);
          } else {
            showSuccessMessage(FLOW_ARCHIVE_RESPONSE_MESSAGE.SUCCESS);
          }
          setArchiveModalClose();
          if (isUserOnTheCurrentFlow) {
            if (isEmbeddedInMainApp) {
              window.parent.postMessage(
                { type: 'NAVIGATE_BACK', payload: location.pathname },
                '*',
              );
            } else {
              history.push(V2_HOME);
            }
          }
        },
      },
    );
  };
  return {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      handleOnArchiveClick,
      setArchiveModalOpen,
      setArchiveModalClose,
    },
  };
};

export default useArchiveFlowOption;
