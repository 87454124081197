import isEmpty from 'lodash/isEmpty';
import { GeneralCollapsibleNavigationItemProps } from '../../../../atomic/organism/GeneralCollapsibleNavigation/types';
import {
  V2_HOME,
  V2_REWARDS,
  KNOWLEDGE_CENTER,
  PEOPLE_DIRECTORY,
} from '../../../../constants/routes';
import { TEMPLATES } from '../../../../languages/en/singleWords';
import { microsoftTeamsStore } from '../../../../stores/microsoftStore';

export const getGeneralListItems: (
  canUserCreateFlow: boolean,
) => GeneralCollapsibleNavigationItemProps[] = (canUserCreateFlow) => {
  const isLoggedInThroughTeamsMobile =
    !isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) &&
    microsoftTeamsStore.getState().isLoggedInThroughMobileTeamsApp;

  const generalListItems = [
    {
      id: V2_HOME,
      title: 'My Feed',
      icon: 'feed',
      isActive: false,
      isEnabled: true,
    },
    {
      id: V2_REWARDS,
      title: 'Rewards',
      icon: 'gift',
      isActive: false,
      isEnabled: true,
    },
    {
      id: KNOWLEDGE_CENTER,
      title: 'Knowledge Center',
      icon: 'knowledge',
      isActive: false,
      isEnabled: true,
    },
  ];
  if (canUserCreateFlow && !isLoggedInThroughTeamsMobile) {
    generalListItems.push({
      id: TEMPLATES,
      title: 'Templates',
      icon: 'cube',
      isActive: false,
      isEnabled: true,
    });
  }

  generalListItems.push({
    id: PEOPLE_DIRECTORY,
    title: 'People',
    icon: 'people-directory-nav',
    isActive: false,
    isEnabled: true,
  });

  return generalListItems;
};
