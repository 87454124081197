import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  GET_MAIN_NAV_CONTENTS,
  HIDE_FLOW_ACTIVITY,
  SHOW_FLOW_ACTIVITY,
} from '../../../../constants/endpoints';
import { makeAPICall } from '../../../utils';
import { ArchiveFlowPayload } from '../RunFlow/types';
import { FlowsActivityStates, GetFlowDetailsResponse } from '../../interfaces';

export const useHideFlowActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ArchiveFlowPayload) =>
      makeAPICall(HIDE_FLOW_ACTIVITY, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        const cacheObject: GetFlowDetailsResponse | undefined =
          queryClient.getQueryData([GET_FLOW_DETAILS, payload.flowId, '']);
        if (cacheObject?.data) {
          cacheObject.data.activityState = FlowsActivityStates.Muted;
        }
        queryClient.setQueryData(
          [GET_FLOW_DETAILS, payload.flowId, ''],
          cacheObject,
        );
      },
    },
  );
};

export const useShowFlowActivity = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ArchiveFlowPayload) =>
      makeAPICall(SHOW_FLOW_ACTIVITY, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: (data, payload) => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        const cacheObject: GetFlowDetailsResponse | undefined =
          queryClient.getQueryData([GET_FLOW_DETAILS, payload.flowId, '']);
        if (cacheObject?.data) {
          cacheObject.data.activityState = FlowsActivityStates.Active;
        }
        queryClient.setQueryData(
          [GET_FLOW_DETAILS, payload.flowId, ''],
          cacheObject,
        );
      },
    },
  );
};
