import { useCallback } from 'react';
import { useImmer } from 'use-immer';
import { File } from '../../molecules/FileCard/types';

function customSortFiles(a: File, b: File) {
  if (a.type === 'IMAGE' && b.type !== 'IMAGE') {
    return -1;
  }
  if (a.type !== 'IMAGE' && b.type === 'IMAGE') {
    return 1;
  }
  return 0;
}

export function useFilePreviewer(
  files?: File[],
  onFilePreviewCallback?: (file: File | undefined) => void,
) {
  const sortedFiles = files?.sort(customSortFiles);

  const [filePreviewerState, updateFilePreviewerState] = useImmer<{
    isOpen: boolean;
    file?: File;
    hasPrevious: boolean;
    hasNext: boolean;
  }>({
    isOpen: false,
    file: undefined,
    hasPrevious: false,
    hasNext: false,
  });

  const previewFile = useCallback(
    (file: File) => {
      if (!sortedFiles) {
        return;
      }

      const fileIndex = sortedFiles.findIndex(
        (currentFile) => currentFile.name === file?.name,
      );

      updateFilePreviewerState((draft) => {
        if (!sortedFiles) {
          return;
        }

        draft.isOpen = true;
        draft.file = file;

        draft.hasPrevious = fileIndex > 0;
        draft.hasNext = fileIndex < sortedFiles.length - 1;
      });

      onFilePreviewCallback?.(file);
    },
    [sortedFiles, onFilePreviewCallback, updateFilePreviewerState],
  );

  const onFilePreviewNavigation = useCallback(
    (direction: 'previous' | 'next') => {
      if (!sortedFiles) {
        return;
      }

      const fileIndex = sortedFiles.findIndex(
        (file) => file.name === filePreviewerState.file?.name,
      );

      if (direction === 'previous') {
        if (fileIndex > 0) {
          previewFile(sortedFiles[fileIndex - 1]);
        }
      } else {
        if (fileIndex < sortedFiles.length - 1) {
          previewFile(sortedFiles[fileIndex + 1]);
        }
      }
    },
    [filePreviewerState.file?.name, sortedFiles, previewFile],
  );

  const onCloseModal = useCallback(() => {
    updateFilePreviewerState((draft) => {
      draft.isOpen = false;
      draft.file = undefined;
    });
    onFilePreviewCallback?.(undefined);
  }, [onFilePreviewCallback, updateFilePreviewerState]);

  return {
    filePreviewerState,
    previewFile,
    onCloseModal,
    onFilePreviewNavigation,
  };
}
