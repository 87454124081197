import React, { useCallback, useEffect, useRef, useState } from 'react';

import { CHANGE_POST_DROPDOWN_LABEL } from '../../../languages/en/flows/feed';
import { FeedsContainer } from '../../../Utils/styles/display';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import {
  DropdownVariants,
  DropdownColor,
  DropdownPlacement,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { FeedContext } from '../../../Utils/flows/feeds';
import ZoomSlider from '../../../atomic/molecules/ZoomSlider';
import useZoomSlider from '../../../hooks/useZoomSlider';
import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import FlowsFeedPostLoader from '../FlowsFeedController/Loader';
import FlowPostController from '../FlowPostController';
import FlowResponseController from '../FlowResponseController';
import {
  StyledProgressBar,
  StyledWrapper,
  StyledFeedWrapper,
  StyledInnerFeedWrapper,
  StyledMenuBar,
} from './styles';
import { timerOptions } from './constants';
import { Document, FullScreenProps, HTMLElement } from './types';
import useLayoutStore from '../../../stores/layoutStore';

const FullScreenController = (props: FullScreenProps) => {
  const {
    exitFullScreen,
    header,
    isLoading,
    feedContextProps,
    posts,
    responses,
    profileData,
    refetchFeed,
    trackFeedEvent,
  } = props;
  const { models: zoomModel, operations: zoomOperations } = useZoomSlider(() =>
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_ZOOM_CLICKED),
  );
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { zoomPercent } = zoomModel;
  const { setZoomPercent, onSliderMove, onZoomIn, onZoomOut } = zoomOperations;
  const scaleValue = zoomPercent / 100;
  const [timerInS, setTimerInS] = useState(10);
  const [progressValue, setProgressValue] = useState(0);

  const resetProgress = () => {
    setProgressValue(0);
    window.setTimeout(() => {
      setProgressValue(100);
    }, 0);
  };

  const isHidden = useRef(false);
  const mouseTimer: { current: number | null } = useRef(null);
  const postIndex: { current: number } = useRef(0);

  const handleMouseMovement = useCallback(() => {
    if (mouseTimer.current) {
      window.clearTimeout(mouseTimer.current);
    }

    const body = document.querySelector('body') as HTMLBodyElement;
    mouseTimer.current = window.setTimeout(() => {
      if (!isHidden.current) {
        body.classList.add('idle--actionable-elements');
        isHidden.current = true;
      }
    }, 3000);
    if (isHidden.current) {
      body.classList.remove('idle--actionable-elements');
      isHidden.current = false;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMovement);
    if (!document.fullscreenElement) {
      if (isEmbeddedInMainApp) {
        window.parent.postMessage(
          { type: 'ENTER_FULL_SCREEN', payload: location.pathname },
          '*',
        );
      } else {
        const docEle = document.documentElement as HTMLElement;
        if (docEle.requestFullscreen) {
          docEle
            .requestFullscreen()
            .catch((error) => console.log('Error: ', error));
        } else if (docEle.webkitRequestFullscreen) {
          docEle.webkitRequestFullscreen();
        }
      }
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMovement);
      if (mouseTimer.current) {
        window.clearTimeout(mouseTimer.current);
      }
    };
  }, [handleMouseMovement, isEmbeddedInMainApp]);

  useEffect(() => {
    if (!isLoading) {
      const timerInMs = timerInS * 1000;
      resetProgress();
      const timer = setInterval(async () => {
        const res = posts || responses;
        if (res?.[postIndex.current + 1]) {
          postIndex.current += 1;
        } else {
          if (refetchFeed) {
            await refetchFeed();
          }
          postIndex.current = 0;
        }
        resetProgress();
      }, timerInMs + 100);

      return () => {
        postIndex.current = 0;
        clearInterval(timer);
      };
    }
  }, [isLoading, refetchFeed, timerInS, posts, responses]);

  useEffect(() => {
    return () => {
      const doc = document as Document;
      if (isEmbeddedInMainApp) {
        window.parent.postMessage(
          { type: 'EXIT_FULL_SCREEN', payload: location.pathname },
          '*',
        );
      } else {
        if (doc.fullscreenElement && doc.exitFullscreen) {
          doc.exitFullscreen().catch((error) => console.log('Error: ', error));
        } else if (doc.webkitFullscreenElement && doc.webkitExitFullscreen) {
          doc.webkitExitFullscreen();
        }
      }
    };
  }, [isEmbeddedInMainApp]);

  if (isLoading) {
    return (
      <FeedsContainer>
        <FlowsFeedPostLoader />
      </FeedsContainer>
    );
  }

  if (
    !profileData ||
    (posts && posts.length <= 1) ||
    (responses && responses.length <= 1)
  ) {
    exitFullScreen();
  }

  const displayPost = () => {
    if (posts?.[postIndex.current]) {
      return (
        <FlowPostController
          key={posts[postIndex.current].postID}
          post={posts[postIndex.current]}
          profileData={profileData}
          hideInteractions
          isExpandedOnLoad
          hideReactions={posts[postIndex.current].flowResponse?.hideReactions}
          hideRepliesBar={posts[postIndex.current].flowResponse?.hideReplies}
          showPostInteractionSettings={false}
        />
      );
    } else if (responses?.[postIndex.current]) {
      return (
        <FlowResponseController
          key={responses[postIndex.current].responseId}
          response={responses[postIndex.current]}
          profileData={profileData}
          showEditOption={false}
          hideInteractions
          isExpandedOnLoad
          hideRepliesBar={responses[postIndex.current].hideReplies}
          hideReactions={responses[postIndex.current].hideReactions}
          canEditAnnouncement={false}
          canShareAsAnnouncement={false}
          canEndAnnouncement={false}
          canViewAnnouncementInsights={false}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <StyledWrapper>
      {header}
      <StyledProgressBar
        value={progressValue}
        color="flowProgress"
        background="transparent"
        height="4px"
        transition={`${progressValue === 0 ? 0 : timerInS}s linear width`}
        dataTestId="post-progress-bar"
      />
      <StyledFeedWrapper scaleValue={scaleValue}>
        <StyledInnerFeedWrapper scaleValue={scaleValue}>
          <FeedContext.Provider value={feedContextProps}>
            <FeedsContainer isFullScreen>{displayPost()}</FeedsContainer>
          </FeedContext.Provider>
        </StyledInnerFeedWrapper>
      </StyledFeedWrapper>
      <StyledMenuBar id="menu-bar">
        <Dropdown
          color={DropdownColor.GrayTertiary}
          variant={DropdownVariants.Contained}
          value={CHANGE_POST_DROPDOWN_LABEL(timerInS)}
          menuItems={[
            {
              id: 'post-timer-menu',
              items: timerOptions,
            },
          ]}
          onItemClick={(id) => {
            setTimerInS(id as number);
            trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_TIME_CHANGED, {
              timeSelected: String(id),
            });
          }}
          dropdownPlacement={DropdownPlacement.TopEnd}
          caretIconSize="16px"
          hasBoxShadow
        />
        <ZoomSlider
          zoomPercent={zoomPercent}
          setZoomPercent={setZoomPercent}
          onSliderMove={onSliderMove}
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
        />
      </StyledMenuBar>
    </StyledWrapper>
  );
};

export default FullScreenController;
