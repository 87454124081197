import { useEffect, useState } from 'react';

import { FlowRepliesBarProps } from '../../atomic/organism/FlowRepliesBar/types';

import { ComponentStatus } from '../../interfaces/component';
import { useGetReplySummary } from '../../queries/Flows/Replies';
import { useLocation } from 'react-router-dom';

type RepliesBarProps = {
  postId: string;
  enabled: boolean;
  type: 'POST' | 'RESPONSE';
};

const useRepliesBar = (props: RepliesBarProps) => {
  const [repliesBarProps, setRepliesBarProps] = useState<FlowRepliesBarProps>({
    status: ComponentStatus.LOADING,
  });

  const { pathname } = useLocation();

  const { isInitialLoading: isLoading, data } = useGetReplySummary(
    props,
    props.enabled,
  );

  useEffect(() => {
    if (!isLoading && data) {
      setRepliesBarProps({
        ...data,
        status: ComponentStatus.LOADED,
      });
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!props.enabled) {
      setRepliesBarProps({
        status: ComponentStatus.LOADED,
        respondentsCount: 0,
        lastRepliedAt: new Date(),
        initialRespondents: [],
        count: 0,
      });
    }
  }, [props.enabled]);

  return {
    repliesBarProps,
    isInFlowDetailsPage: pathname.includes('post/flow'),
  };
};

export default useRepliesBar;
