import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

import ThemeV2 from '../../../theme';

const useStyles = makeStyles({
  arrow: {
    color: ThemeV2.palette.gray9,
  },
  tooltip: {
    backgroundColor: ThemeV2.palette.gray9,
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
    padding: '5px 8px',
  },
});

const CustomToolTip: React.FC<TooltipProps> = (
  props: TooltipProps,
): React.ReactElement => {
  const classes = useStyles();

  const { classes: overrideClasses, ...otherProps } = props;
  return (
    <Tooltip
      arrow
      classes={{ ...classes, ...overrideClasses }}
      {...otherProps}
    />
  );
};

export default CustomToolTip;
