import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  GET_NOTIFICATIONS,
  MARK_NOTIFICATION_AS_READ,
} from '../../constants/endpoints';
import { Notification } from '../../interfaces/Notifications';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

export interface GetNotificationsResponse {
  data: {
    notificationCount: number;
    notifications: Notification[];
    meta: {
      pagination: {
        cursor: {
          previous: string | null;
          next: string | null;
        };
      };
    };
  };
}

type MarkNotificationsAsReadContext =
  | {
      previousNotificationData:
        | InfiniteData<GetNotificationsResponse>
        | undefined;
    }
  | undefined;

export const useNotificationFetchQuery = (type?: string) => {
  return useInfiniteQuery<GetNotificationsResponse>(
    [GET_NOTIFICATIONS, type],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_NOTIFICATIONS, undefined, {
        cursor: pageParam,
        type: type || '',
      }),
    {
      staleTime: 60 * 5 * 1000,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.data.meta?.pagination.cursor.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.data.meta?.pagination.cursor.previous;
      },
    },
  );
};

export const useMarkNotificationsAsReadMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (notificationId?: string) =>
      makeAPICall(
        `${MARK_NOTIFICATION_AS_READ}`,
        undefined,
        notificationId
          ? {
              notificationIds: notificationId,
            }
          : undefined,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_NOTIFICATIONS, '']);
        queryClient.invalidateQueries([GET_NOTIFICATIONS, 'unread']);
      },
      onError: (err, variables, context: MarkNotificationsAsReadContext) => {
        if (context?.previousNotificationData) {
          queryClient.setQueryData(
            [GET_NOTIFICATIONS],
            context.previousNotificationData,
          );
        }
      },
    },
  );
};
