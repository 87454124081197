/* eslint-disable max-len */
export const CULTURE = 'Culture';
export const GIFT_CARDS = 'Gift Cards';
export const CHARITIES = 'Charities';
export const AXOMO_SWAGS = 'Swag';
export const MY_REWARDS = 'My Rewards';
export const REWARDS = 'Rewards';
export const MORE_ABOUT_THIS_REWARD = 'More about this reward';
export const REWARDS_COST = 'Cost';
export const REWARDS_BUTTON_TEXT_CHARITY = 'Select Charity';
export const REWARDS_BUTTON_TEXT_REWARD = 'Select Reward';
export const REWARDS_BUTTON_TEXT_SWAGS = 'Select Swag';
export const REWARDS_BUTTON_TEXT_COMING_SOON = 'Coming Soon';
export const REWARDS_BUTTON_TEXT_OUT_OF_STOCK = 'Out of Stock';
export const REWARDS_AVAILABILITY_TEXT = 'available';
export const REWARDS_AVAILABILITY_OF_TEXT = 'of';
export const UPGRADE_HERE = 'Upgrade here';
export const UPGRADE_THROUGH_DESKTOP =
  'Upgrade by heading to a desktop app near you.';

export const ACCOUNT_SETUP = 'Account setup';
export const THERES_NO_REWARDS = "There's no rewards here, yet";
export const CULTURE_REWARDS_PROVIDE_YOU = `Culture rewards provide you the flexibility
  to create rewards outside the scope of our gift card and charity catalog. Want to create some?`;
export const TAKE_ME_TO_ADMIN_CULTURE_REWARDS =
  'Take me to admin culture rewards';
export const ON_ANY_PAID_PLAN_YOU_ARE_ABLE = `On any paid plan, you're able to offer a
  wide range of gift cards and charities to suit all of your employees interests! Not on a paid plan yet?`;

export const GIFT_CARD_SETUP_TEXT = `All paid plans offer a wide range of gift cards to suit all of your employees' interests!`;
export const CHARITY_SETUP_TEXT = `All paid plans offer a wide range of charities to suit all of your employees' interests!`;

export const TAKE_ME_TO_TURN_ON_GIFT_CARDS = 'Take me to turn on gift cards';
export const TAKE_ME_TO_TURN_ON_CHARITIES = 'Take me to turn on charities';
export const REWARDS_REDEEM = 'Redeem';
export const CONTINUE_TO_SHIPPING = 'Continue to shipping';
export const REWARDS_CLOSE = 'Close';
export const PLACE_ORDER = 'Place order';
export const BUTTON_TEXT_NEXT = 'Next';

export const TAKE_ME_TO_TURN_ON_PAID_ALLOWANCE =
  'Take me to turn on paid allowance';

export const REWARDS_REDEEM_SUCCESS_RATING_TEXT = 'How is Assembly doing?';
export const REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_HEADING =
  'Thank you for celebrating Assembly, you’re a star!';
export const REWARDS_REDEEM_SUCCESS_COMPLETED_RATING_TEXT =
  'We appreciate your candid feedback so that we can make Assembly the best it can be. If you have more comments, never hesitate to reach out.';
export const CREATE_CULTURE_REWARD_LIMITED_REWARD_TEXT =
  'How it works: Have a specific amount of a reward you can give? Use a limited reward to make sure you keep track of when it’s running out of stock.';
export const CREATE_CULTURE_REWARD_OPTIONS_HEADING =
  'What type of reward is this? *';
export const REDEMPTION_CARD_TEXT = {
  REDEEMED_FOR: 'Redeemed for',
  REDEEMED_ON: 'Redeemed on',
  REDEMPTION_DETAILS: 'See redemption details',
  RESEND_EMAIL: 'Resend Email',
  EMAIL_RESENT: 'Email Resent',
  REMIND_ADMIN: 'Remind Admin',
  REMINDER_SENT: 'Reminder Sent',
  DELIVERED: 'Delivered',
  FULFILLED: 'Fulfilled',
  CANCELLED: 'Cancelled',
  CHECK_STATUS: 'Check Status',
  GET_QUANTITY_TEXT: (qty: string) => `Quantity: ${qty},`,
  GET_SIZE_TEXT: (size: string) => ` Size: ${size},`,
};
export const UPLOAD_REWARD_IMAGE = 'Upload Reward Image';
export const UPLOAD_REWARD_IMAGE_SIZE_RECOMMENDATION =
  'Size recommendation: 215px by 125px';
export const IMAGE_CROPPER_MODAL_HEADING = 'Edit Image';
export const IMAGE_CROPPER_MODAL_SUBMIT_TEXT = 'Done';
export const IMAGE_CROPPER_MODAL_CANCEL_TEXT = 'Cancel';

export const REWARD_NAME_INPUT_PLACEHOLDER = 'Enter a reward name';
export const REWARD_NAME_INPUT_LABEL = 'Reward name *';
export const REWARD_NAME_INPUT_HELPER_TEXT = 'Please limit to 100 characters.';

export const REWARD_DESCRIPTION_INPUT_PLACEHOLDER = 'Enter a description text';
export const REWARD_DESCRIPTION_INPUT_LABEL = 'Description *';
export const REWARD_DESCRIPTION_INPUT_HELPER_TEXT =
  'Please limit to 500 characters.';
export const GET_REWARD_TROPHIES_INPUT_PLACEHOLDER = (
  currencyNamePlural: string,
) => `${currencyNamePlural} needed to redeem *`;
export const REWARD_LIMIT_INPUT_OPTIONS_TEXT = 'How many are available? *';
export const CREATE_REWARD = 'Create Reward';
export const EDIT_REWARD = 'Edit Reward';
export const REWARD_TYPES_OPTIONS_LABEL = [
  'Unlimited reward',
  'Limited reward',
];

export const CULTURE_REWARDS_SEARCH_PLACEHOLDER = 'Search Culture Rewards';
export const CULTURE_REWARDS_FILTER_PLACEHOLDER = 'Reward type';
export const REWARDS_SORT_PLACEHOLDER = 'Sort by';

export const GIFT_CARD_REWARDS_SEARCH_PLACEHOLDER = 'Search Gift Cards';
export const GIFT_CARD_REWARDS_FILTER_PLACEHOLDER = 'Countries';

export const CHARITY_REWARDS_SEARCH_PLACEHOLDER = 'Search Charities';

export const MY_REWARDS_TEXT = {
  QUICK_STATS: 'Quick Stats',
  REDEEMED: 'Redeemed',
  DOLLARS_REDEEMED: 'Dollars Redeemed',
  REDEMPTION_HISTORY: 'Redemption History',
  EMPTY_REDEMPTION_HISTORY:
    'You haven’t redeemed any rewards yet. Once you do, you’ll be able to find them here.',
};

export const REDEEM = 'Redeem';
export const CULTURE_REWARD_REDEMPTION_POLICY =
  'Culture reward redemption policy';
export const YOUR_REWARD_WILL_BE_SENT_TO =
  'Once you click ‘Redeem,’ your reward will be sent to';
export const YOUR_ADMINISTRATOR_WILL_CONTACT_YOU =
  'Your administrator, will contact you to coordinate your reward.';
export const AFTER_CLICKING_REDEEM =
  "IMPORTANT: After clicking 'Redeem,' Assembly cannot issue a refund for this reward.";
export const PLEASE_CHECK_YOUR_EMAIL = 'Please check your email';
export const ALWAYS_CHECK_YOUR_SPAM =
  'Always check your spam if you do not see the reward. If you don’t receive an email to claim your reward, please notify your admin.';
export const CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED =
  'Congrats! You’ve successfully redeemed:';
export const SELECT_GIFT_CARD_VALUE = 'Select a gift card value';
export const REDEEM_SELECT_MODAL = 'Redeem select modal';
export const GIFT_CARD = 'gift card';

export const REDEEM_SWAG_POLICY_TITLE = 'Description';
export const REDEEM_SWAG_NO_DESCRIPTION_FOUND = 'No description found';
export const REDEEM_SWAG_NO_DETAILS_FOUND = 'No details found';
export const REDEEM_SWAG_POLICY_DETAILS = 'Details';
export const REDEEM_SWAG_POLICY_LOADING = 'Loading...';
export const REDEEM_SWAG_POLICY_ABOUT_SHIPPING = 'About shipping';
export const PLACING_AN_ORDER_CONFIRMATION_WILL_SENT_TO =
  'When you place this order, your confirmation will be sent to';
export const ORDERS_CANNOT_BE_CANCELLED =
  'Orders cannot be cancelled or modified once placed.';
export const CHOOSE_A_SIZE = 'Choose a size';
export const QUANTITY = 'Quantity';
export const CHOOSE_COLOR = 'Choose a color';
export const REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_PREFIX =
  'We automatically choose the best shipping method for you, so you don’t have to worry about that.';
export const REDEEM_SWAG_SHIPPING_ABOUT_CONTENT_SUFFIX =
  'Please confirm that your order and address are correct, and then click “Place order” to finalize your purchase.';

export const GIFT_CARD_POLICY_TITLE = 'Gift card redemption policy';
export const HOW_TO_REDEEM_YOUR_E_GIFT_CARD = 'How to redeem your e-gift card';
export const CHARITY_POLICY_TITLE = 'Charity donation policy';
export const CHARITY_POLICY_DESCRIPTION =
  'Once you click ‘Redeem,’ your donation confirmation will be sent to';

export const REWARDS_PER_PAGE = 'Rewards per page:';
export const GIFT_CARDS_PER_PAGE = 'Gift cards per page:';
export const CHARITIES_PER_PAGE = 'Charities per page:';

export const COULD_NOT_FIND_ANY_REWARDS =
  'We couldn’t find any rewards in this search';
export const TRY_DIFFERENT_SEARCH =
  'Try a different search to see if we have what you’re looking for.';
export const REDEMPTIONS_PER_PAGE = 'Redemptions per page';
export const REDEEM_SUCCESS_MESSAGE = 'Successfully redeemed';
export const REDEEM_ERROR_MESSAGE = 'Redeem failed!';

export const WE_ARE_LOOKING_FOR_THE_BEST_REWARDS =
  'We’re looking for the best rewards...';

export const HOLD_TIGHT = 'Hold tight, we’re searching high and low.';

export const NEXT_PAGE = 'Next Page';
export const PREVIOUS_PAGE = 'Previous Page';

export const AXOMO_SWAG_REWARDS_SEARCH_PLACEHOLDER = 'Search swag';
export const AXOMO_SWAG_REWARDS_TYPE_PLACEHOLDER = 'Swag type';
export const AXOMO_SWAG_REWARDS_SORT_PLACEHOLDER = 'Sort by';

export const CREATE_CULTURE_REWARD = 'Create a New Culture Reward';
export const EDIT_CULTURE_REWARD = 'Edit Culture Reward';

export const CULTURE_REWARD_CREATED = 'Culture reward created!';
export const CULTURE_REWARD_UPDATED = 'Culture reward updated!';
export const CREATE_CULTURE_REWARD_ERROR = 'Unable to create culture reward';
export const UPDATE_CULTURE_REWARD_ERROR = 'Unable to update culture reward';

export const CREATE_CULTURE_REWARD_ERROR_MESSAGE = {
  REQUIRED: 'Required field',
  NAME_MAX_CHAR: 'Please limit to 28 characters.',
  NAME_MIN_CHAR: 'Reward Name must be more than 2 characters long',
  DESCRIPTION_MAX_CHAR: 'Please limit to 180 characters.',
  VALID_NUMBER: 'Enter a valid number',
  TROPHIES_INPUT_MIN_CHAR: 'Enter a value greater than 0',
};

export const REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS =
  'Take me to admin swag settings';
export const REWARD_EMPTY_SWAG_TITLE = 'There’s no swag here, yet';
export const REWARD_EMPTY_SWAG_DESCRIPTION =
  'Assembly has partnered with Axomo to cover all of your company’s swag design and distribution needs. Get them set up and have your organization redeem swag with points!';
export const REWARD_UPGRAGE_SWAG_TITLE =
  'Swag cannot be seen by anyone until you upgrade';
export const REWARD_UPGRADE_SWAG_DESCRIPTION =
  'Want company swag? Assembly has partnered with Axomo to cover all of your company’s swag design and distribution needs. Get them set up and have your organization redeem swag with points!';
export const UPGRADE_BUTTON_TEXT = 'Upgrade to access';
export const LEAVE_CHECKOUT_TEXT =
  'Are you sure you want to leave checkout? If you leave now, your items will not be redeemed.';
export const GO_BACK = 'Go back';
export const LEAVE_CHECKOUT = 'Leave checkout';
export const LEAVE_CHECKOUT_HEADING = 'Leave checkout?';
export const REDEEM_CONTENT_MODAL_TEXTS = {
  SHIPPING_ADDRESS: 'Shipping Address',
  CONGRATS: 'Congrats! You’ve successfully redeemed:',
  CONFIRM: 'Confirm your details:',
  TOTAL_COST: 'Total cost:',
  GET_QUANTITY_SIZE_TEXT: (qty: string | undefined) => `Quantity: ${qty}`,
  GET_SIZE_TEXT: (size: string | undefined) => `, Size: ${size}`,
  EXPECTED_DATE: 'Expected delivery date:',
  SENT_TO: 'Sent to:',
  GET_SHIPPING_ADDRESS_TITLE: (
    qty: string | undefined,
    name: string | undefined,
  ) => `${qty}x, ${name}`,
  CHECK_STATUS: 'Check the status of your order from the',
  TRACK_STATUS:
    'page. Once it ships, you’ll get an email and be able to track your swag.',
  PRODUCT_INFORMATION: 'Product information:',
  TRACKING_INFORMATION: 'Tracking information:',
  ORDER_NOT_SHIPPED:
    'Your order hasn’t shipped yet. Once it does, we’ll update this section with your tracking link.',
  ORDER_CANCELLED: 'Order Cancelled',
  ORDER_DELIVERED: 'Your order was delivered',
};

export const PLACE_ORDER_FAILED = 'Place order failed';
export const RE_CONNECT_AXOMO =
  'Your Axomo account has been disconnected. Go back to Admin to reconnect.';
export const REWARD_SWAGS_TAB_ALERT_TEXTS = {
  PREFIX: 'The swag tab is turned off. Go to admin or ',
  CLICK_HERE: 'click here',
  SUFFIX:
    ' to turn it on. No one will be able to redeem Swag while this tab is turned off.',
};
export const GET_SHIPPING_COST_TOOLTIP_TEXT = (shippingCost?: number) =>
  `${shippingCost} flat shipping cost for your order (regardless of quantity)`;
export const SHIPPING = 'shipping';
export const TURNING_REWARDS_ON = 'Turning on Swag tab';
export const SWAGS_TURNED_ON = 'Swag tab now on for everyone!';
