import { FluidContainer } from '../../Utils/styles/display';
import { PeopleDirectoryView } from './Components/PeopleDirectoryView';
import { Loader } from './Components/Loader';
import { SearchPeopleDirectory } from './Components/SearchPeopleDirectory';
import { usePeopleDirectory } from './usePeopleDirectory';
import EmptyErrorStateTemplate from '../../atomic/molecules/EmptyErrorStateTemplate';
import ErrorImage from '../../img/error.svg';

export const PeopleDirectory = () => {
  const {
    data,
    setSearchValue,
    searchedMembers,
    membersSearchValue,
    hasMoreMembersToFetch,
    isMembersSearchLoading,
    isMembersFetchingError,
    fetchMoreInfiniteMembers,
    handleItemClick,
  } = usePeopleDirectory();

  if (isMembersFetchingError) {
    return (
      <FluidContainer contentPadding="16px">
        <EmptyErrorStateTemplate
          image={ErrorImage}
          heading="Error loading People"
          subHeading="Please refresh the page to try again."
        />
      </FluidContainer>
    );
  }

  if (!data.length && isMembersSearchLoading) {
    return <Loader />;
  }
  return (
    <>
      <FluidContainer>
        <>
          <SearchPeopleDirectory setSearchValue={setSearchValue} />
          <PeopleDirectoryView
            isMembersLoading={isMembersSearchLoading}
            hasMoreMembersToFetch={hasMoreMembersToFetch || false}
            fetchMoreInfiniteMembers={fetchMoreInfiniteMembers}
            data={
              membersSearchValue && searchedMembers ? searchedMembers : data
            }
            membersSearchValue={membersSearchValue}
            onItemClick={handleItemClick}
          />
        </>
      </FluidContainer>
    </>
  );
};
