import { trackEvent } from '../index';

import { ActionBarEventProps } from '../interfaces';
import { ACTION_BAR_EVENTS, AnalyticsEvents } from '../constants';

export const trackActionBarActionEvent = (eventProps: ActionBarEventProps) => {
  trackEvent(ACTION_BAR_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};
