import uuid from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { IMemberDTO } from '../../../../interfaces/member';
import { useMembersSearch } from '../../../../hooks/useMembersSearch';
import {
  MembersDetailsOptionalParams,
  useFetchMembersDetailsQuery,
} from '../../../../queries/MembersDetails';
import { useGetAllDepartmentsQuery } from '../../../../queries/FlowBuilder/Participants';
import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import { SelectedCriteriaProps } from '../../../../atomic/organism/FlowParticipationBuilderOptionsContent/types';
import { popoverItems, popoverLocationItems } from './data';

import ThemeV2 from '../../../../theme';

import {
  CriteriaField,
  CriteriaGroup,
  CriteriaGroups,
  CriteriaOperator,
  CriteriaCondition,
} from '../../../../interfaces/Flow/Builder';

import {
  filterByUniqueCriteria,
  getPreExistingMemberIDs,
} from '../../../../Utils/flows/builder/utils';
import { FLOW_BUILDER_EVENTS } from '../../../../Utils/analytics/constants';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  canInviteMembers,
  getCanShowParticipationCriteriaEveryone,
  getIsInviteRequiresApproval,
} from '../../../../Utils/permissions';
import { trackFlowBuilderActionEvent } from '../../../../Utils/analytics/flowsBuilder';
import { MemberState } from '../../../../interfaces/user';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  flowNameSelector,
  templateIdSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import { SelectedCriteriaManagerProps } from '../../../../atomic/organism/FlowsManagerBuilderOptionsContent/types';
import { isEveryoneCriteria } from '../../../../Utils/flows/criteria';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { useGetUserLocation } from '../../../../queries/Settings';

const attributeLabelForAnalytics = {
  [FLOW_BUILDER_EVENTS.PARTICIPANTS_SELECTED]: 'participantCriteriaSelected',
  [FLOW_BUILDER_EVENTS.VIEWERS_SELECTED]: 'viewerCriteriaSelected',
};

const memberDetailsDeactivatedUserCheck: MembersDetailsOptionalParams = {
  filter: {
    allowDeactivateCheck: 'no',
  },
};

const useFlowsBuilderFilterCriteriaController = (
  hideEveryone: boolean,
  criteriaGroups: CriteriaGroups | undefined,
  onCriteriaGroupsChange: (value: CriteriaGroups | undefined) => void,
  from: string,
) => {
  const flowName = useFlowBuilderStore(flowNameSelector);
  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);

  let memberIds: string[] = [];
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isEveryOneSelected, setIsEveryOneSelected] = useState(false);

  const isCriteriaPopoverOpen = Boolean(anchorEl);
  const popoverId = isCriteriaPopoverOpen ? 'material-popover' : undefined;

  const { treatment: locationCriteria } = useFeatureSplit(
    SplitNames.LocationCriteria,
  );

  const isLocationCriteriaEnabled = locationCriteria === TreatmentTypes.ON;

  const paramLabel = useMemo(() => {
    switch (from) {
      case 'visibility':
        return FLOW_BUILDER_EVENTS.VIEWERS_SELECTED;
      case 'participation':
      case 'personSelector':
      default:
        return FLOW_BUILDER_EVENTS.PARTICIPANTS_SELECTED;
    }
  }, [from]);

  const {
    models: {
      hasMoreMembers,
      searchedMembers,
      isLoading: isMembersSearchLoading,
      isFetching: isMembersSearchFetching,
    },
    operations: { onChange: onMembersSearchChange, fetchMoreMembers },
  } = useMembersSearch(true, 20);

  const { data: profileData } = useProfileInfoFetchQuery();

  const { data: userLocationData, isLoading: isUserLocationDataLoading } =
    useGetUserLocation(['JOB_TITLE', 'HOME_LOCATION', 'WORK_LOCATION']);

  const locationOptions = useMemo(() => {
    if (userLocationData) {
      return userLocationData.data
        .filter((location) => location.key === 'HOME_LOCATION')[0]
        .values.map((val) => {
          return {
            title: val,
            id: val,
          };
        });
    }
    return [];
  }, [userLocationData]);

  const workLocationOptions = useMemo(() => {
    if (userLocationData) {
      return userLocationData.data
        .filter((location) => location.key === 'WORK_LOCATION')[0]
        .values.map((val) => {
          return {
            title: val,
            id: val,
          };
        });
    }
    return [];
  }, [userLocationData]);

  const jobTitleOptions = useMemo(() => {
    if (userLocationData) {
      return userLocationData.data
        .filter((location) => location.key === 'JOB_TITLE')[0]
        .values.map((val) => {
          return {
            title: val,
            id: val,
          };
        });
    }
    return [];
  }, [userLocationData]);

  const canShowParticipationCriteriaEveryone =
    getCanShowParticipationCriteriaEveryone(profileData);

  const isInviteRequiresApproval = getIsInviteRequiresApproval(
    profileData,
    true,
  );

  const canInviteMembersOnFlowBuilder =
    canInviteMembers(
      profileData?.member,
      profileData?.assembly.settings,
      true,
    ) && !isInviteRequiresApproval;

  const {
    models: {
      hasMoreMembers: hasMoreEmails,
      searchedMembers: searchedMembersByEmail,
      isLoading: isMembersSearchByEmailLoading,
      isFetching: isMembersSearchByEmailFetching,
    },
    operations: {
      fetchMoreMembers: fetchMoreEmails,
      onChange: onMembersSearchByEmailChange,
    },
  } = useMembersSearch(true, 20, 'email');

  criteriaGroups?.groups.forEach((group) => {
    group.groupRules.forEach((rule) => {
      if (rule.field === 'member' || rule.field === 'directReport') {
        rule.value.forEach((val) => {
          if (val.id === val.value) {
            memberIds = [...memberIds, val.id];
          }
        });
      }
    });
  });

  const { data } = useFetchMembersDetailsQuery(
    memberIds,
    memberIds && memberIds.length > 0,
    memberDetailsDeactivatedUserCheck,
  );

  const popoverMenuItems = useMemo(() => {
    if (
      hideEveryone ||
      !canShowParticipationCriteriaEveryone ||
      isEveryoneCriteria(criteriaGroups)
    ) {
      if (isLocationCriteriaEnabled) {
        return popoverLocationItems.filter((item) => item.id !== 'everyone');
      } else {
        return popoverItems.filter((item) => item.id !== 'everyone');
      }
    }
    if (isLocationCriteriaEnabled) {
      return popoverLocationItems;
    }
    return popoverItems;
  }, [
    hideEveryone,
    canShowParticipationCriteriaEveryone,
    criteriaGroups,
    isLocationCriteriaEnabled,
  ]);

  useEffect(() => {
    setIsEveryOneSelected(isEveryoneCriteria(criteriaGroups));

    criteriaGroups?.groups.forEach((group) => {
      group.groupRules.forEach((rule) => {
        if (rule.field === 'member' || rule.field === 'directReport') {
          rule.value.forEach((val) => {
            if (val.id === val.value) {
              const memberDetails = data?.membersDetails.find(
                (x) => x.memberId === val.id,
              );
              if (memberDetails) {
                const { firstName, lastName } = memberDetails.profile;
                // eslint-disable-next-line no-param-reassign
                val.value = `${firstName} ${lastName}`;
              }
            }
          });
        }
      });
    });
  }, [data, criteriaGroups, setIsEveryOneSelected, onCriteriaGroupsChange]);

  useEffect(() => {
    if (criteriaGroups) {
      onCriteriaGroupsChange({ ...criteriaGroups });
    }
  }, [data]);

  const getParticipantMembers: () => AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      return () => {
        const preExistingIDs = getPreExistingMemberIDs(
          criteriaGroups?.groups || [],
          selectedGroupId || '',
        );
        if (searchedMembers) {
          return searchedMembers
            .filter(
              (member) => !(preExistingIDs || []).includes(member.memberID),
            )
            .map((member) => {
              return {
                title: `${member.firstName} ${member.lastName}`,
                id: member.memberID,
                avatar: {
                  img: member.image || '',
                  userId: member.memberID,
                  name: `${member.firstName} ${member.lastName}`,
                  icon:
                    member.memberState === MemberState.PENDING
                      ? 'pending-person'
                      : '',
                },
                memberState: member.memberState,
              };
            });
        }
        return [];
      };
    }, [searchedMembers, criteriaGroups, selectedGroupId]);

  const getParticipantsMembersByEmail: () => AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      return () => {
        const preExistingIDs = getPreExistingMemberIDs(
          criteriaGroups?.groups || [],
          selectedGroupId || '',
        );
        return searchedMembersByEmail
          ? searchedMembersByEmail
              ?.filter(
                (member) => !(preExistingIDs || []).includes(member.memberID),
              )
              ?.map((memberData: IMemberDTO) => {
                return {
                  title: memberData.email || '',
                  id: memberData.memberID,
                  avatar: {
                    img: memberData.image || '',
                    userId: memberData.memberID,
                    name: `${memberData.firstName} ${memberData.lastName}`,
                    icon:
                      memberData.memberState === MemberState.PENDING
                        ? 'pending-person'
                        : '',
                    iconBgColor: 'transparent',
                    iconColor: ThemeV2.palette.gray9,
                  },
                };
              })
          : [];
      };
    }, [searchedMembersByEmail, criteriaGroups, selectedGroupId]);

  const { data: departments, isInitialLoading: isDepartmentsLoading } =
    useGetAllDepartmentsQuery();

  const participationDepartments: AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      if (departments) {
        return departments.data.map((department: string) => {
          return {
            title: department,
            id: department,
          };
        });
      }
      return [];
    }, [departments]);

  const getParticipationCriteriaActiveSlideId = (itemId?: string) => {
    if (itemId === popoverItems[0].id) {
      setAnchorEl(null);
    }
  };

  // when the criteria changes from any/and
  const handleGroupConditionChange = useCallback(
    (groupId: string, value: CriteriaCondition) => {
      if (!criteriaGroups) {
        return;
      }

      const currentCriteriaGroups = criteriaGroups;
      const criteriaToBeUpdated = criteriaGroups.groups.find(
        (x) => x.groupId === groupId,
      );

      if (criteriaToBeUpdated) {
        criteriaToBeUpdated.groupCondition = value;
        const index = criteriaGroups.groups.findIndex(
          (x) => x.groupId === groupId,
        );

        currentCriteriaGroups.groups[index] = { ...criteriaToBeUpdated };
        onCriteriaGroupsChange({ ...currentCriteriaGroups });
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleCriteriaGroupConditionChange = useCallback(
    (value: CriteriaCondition) => {
      if (!criteriaGroups) {
        return;
      }
      onCriteriaGroupsChange({ ...criteriaGroups, groupsCondition: value });
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleOnEveryOneSelectedWithoutSimpleShareSheet = useCallback(() => {
    setAnchorEl(null);
    trackFlowBuilderActionEvent({
      action: paramLabel,
      [attributeLabelForAnalytics[paramLabel]]: ['everyone'],
      templateId,
      templateName,
      flowTitle: flowName,
    });
    const groups: CriteriaGroup[] = [
      {
        groupId: uuid.v4(),
        groupCondition: 'and',
        groupRules: [
          {
            value: [{ id: 'everyone', value: 'everyone' }],
            operator: 'is',
            field: 'everyone',
            ruleId: uuid.v4(),
          },
        ],
      },
    ];
    const newCriteriaGroups: CriteriaGroups = {
      groups,
      groupsCondition: 'and',
    };

    onCriteriaGroupsChange(newCriteriaGroups);
  }, [flowName, onCriteriaGroupsChange, paramLabel, templateId, templateName]);

  const handleOnEveryOneSelectedWithSimpleShareSheet = useCallback(() => {
    setAnchorEl(null);
    trackFlowBuilderActionEvent({
      action: paramLabel,
      [attributeLabelForAnalytics[paramLabel]]: ['everyone'],
      templateId,
      templateName,
      flowTitle: flowName,
    });
    if (!criteriaGroups) {
      return;
    }

    const currentCriteriaGroups = criteriaGroups;
    const criteriaToBeUpdated = criteriaGroups.groups.find(
      (x) => x.groupId === selectedGroupId,
    );
    const indexOfCriteriaToBeUpdated = criteriaGroups.groups.findIndex(
      (x) => x.groupId === selectedGroupId,
    );
    if (!isEveryoneCriteria(currentCriteriaGroups)) {
      if (criteriaToBeUpdated) {
        criteriaToBeUpdated.groupRules = [
          {
            value: [],
            operator: 'is',
            field: 'everyone',
            ruleId: uuid.v4(),
          },
          ...criteriaToBeUpdated.groupRules,
        ];
        currentCriteriaGroups.groups[indexOfCriteriaToBeUpdated] = {
          ...criteriaToBeUpdated,
        };
        onCriteriaGroupsChange({
          ...currentCriteriaGroups,
          groupsCondition: 'or',
        });
      } else {
        const groups: CriteriaGroup = {
          groupId: uuid.v4(),
          groupCondition: 'or',
          groupRules: [
            {
              value: [],
              operator: 'is',
              field: 'everyone',
              ruleId: uuid.v4(),
            },
          ],
        };
        const newCriteriaGroups: CriteriaGroups = {
          groups: [...criteriaGroups.groups, groups],
          groupsCondition: criteriaGroups.groupsCondition,
        };
        onCriteriaGroupsChange(newCriteriaGroups);
      }
    }
  }, [
    criteriaGroups,
    flowName,
    onCriteriaGroupsChange,
    paramLabel,
    selectedGroupId,
    templateId,
    templateName,
  ]);

  const addNewCriteriaGroupWithoutSimpleShareSheet = useCallback(
    (selectedCriteriaProps: SelectedCriteriaProps<string>) => {
      const groups: CriteriaGroup[] = [
        // Default for adding more than 1 criteria in the same group should be “Participant matches any”
        {
          groupId: uuid.v4(),
          groupCondition: 'or',
          groupRules: [
            {
              value: selectedCriteriaProps.value.map((x) => ({
                id: x.id,
                value: x.title,
                memberState: x.memberState,
              })),
              operator: selectedCriteriaProps.operator as CriteriaOperator,
              field: selectedCriteriaProps.field as CriteriaField,
              ruleId: uuid.v4(),
            },
          ],
        },
      ];
      const newCriteriaGroups: CriteriaGroups = {
        groups,
        groupsCondition: 'or',
      };

      if (!criteriaGroups) {
        onCriteriaGroupsChange(newCriteriaGroups);
      } else {
        const criteriaGroupToBeUpdated = criteriaGroups;
        criteriaGroupToBeUpdated.groups = [
          ...criteriaGroupToBeUpdated.groups,
          ...groups,
        ];
        onCriteriaGroupsChange({ ...criteriaGroupToBeUpdated });
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const addNewCriteriaGroupWithSimpleShareSheet = useCallback(
    (selectedCriteriaProps: SelectedCriteriaProps<string>) => {
      const groups: CriteriaGroup[] = [
        // Default for adding more than 1 criteria in the same group should be “Participant matches any”
        {
          groupId: uuid.v4(),
          groupCondition: 'or',
          groupRules: [
            {
              value: selectedCriteriaProps.value.map((x) => ({
                id: x.id,
                value: x.title,
                memberState: x.memberState,
              })),
              operator: selectedCriteriaProps.operator as CriteriaOperator,
              field: selectedCriteriaProps.field as CriteriaField,
              ruleId: uuid.v4(),
            },
          ],
        },
      ];
      const newCriteriaGroups: CriteriaGroups = {
        groups,
        groupsCondition: 'or',
      };

      if (!criteriaGroups) {
        onCriteriaGroupsChange(newCriteriaGroups);
      } else {
        const criteriaGroupToBeUpdated = criteriaGroups;
        criteriaGroupToBeUpdated.groups = [
          ...criteriaGroupToBeUpdated.groups,
          ...groups,
        ];
        onCriteriaGroupsChange({ ...criteriaGroupToBeUpdated });
      }
    },
    [criteriaGroups, onCriteriaGroupsChange],
  );

  const handleExistingCriteriaGroupChangeWithoutSimpleShareSheet = useCallback(
    (
      selectedCriteriaGroups: CriteriaGroups,
      selectedCriteriaProps: SelectedCriteriaProps<string>,
      groupId: string,
    ) => {
      const currentCriteriaGroups = selectedCriteriaGroups;
      const criteriaToBeUpdated = selectedCriteriaGroups.groups.find(
        (x) => x.groupId === groupId,
      );
      const uniqueValues = filterByUniqueCriteria(
        selectedCriteriaGroups.groups,
        selectedCriteriaProps.value,
      );

      if (criteriaToBeUpdated && uniqueValues.length > 0) {
        criteriaToBeUpdated.groupRules = [
          ...criteriaToBeUpdated.groupRules,
          {
            value: uniqueValues.map((x) => ({
              id: x.id,
              value: x.title,
              memberState: x.memberState,
            })),
            operator: selectedCriteriaProps.operator as CriteriaOperator,
            field: selectedCriteriaProps.field as CriteriaField,
            ruleId: uuid.v4(),
          },
        ];
        const index = selectedCriteriaGroups.groups.findIndex(
          (x) => x.groupId === groupId,
        );

        currentCriteriaGroups.groups[index] = { ...criteriaToBeUpdated };
        onCriteriaGroupsChange({ ...currentCriteriaGroups });
      }
    },
    [onCriteriaGroupsChange],
  );

  const handleExistingCriteriaGroupChangeWithSimpleShareSheet = useCallback(
    (
      selectedCriteriaGroups: CriteriaGroups,
      selectedCriteriaProps: SelectedCriteriaProps<string>,
      groupId: string,
    ) => {
      const currentCriteriaGroups = selectedCriteriaGroups;
      const criteriaToBeUpdated = selectedCriteriaGroups.groups.find(
        (x) => x.groupId === groupId,
      );
      const uniqueValues = filterByUniqueCriteria(
        selectedCriteriaGroups.groups,
        selectedCriteriaProps.value,
      );
      if (criteriaToBeUpdated && uniqueValues.length > 0) {
        criteriaToBeUpdated.groupRules = [
          ...criteriaToBeUpdated.groupRules,
          {
            value:
              selectedCriteriaProps.field === 'everyone'
                ? []
                : uniqueValues.map((x) => ({
                    id: x.id,
                    value: x.title,
                    memberState: x.memberState,
                  })),
            operator: selectedCriteriaProps.operator as CriteriaOperator,
            field: selectedCriteriaProps.field as CriteriaField,
            ruleId: uuid.v4(),
          },
        ].map((rule) => {
          if (rule.field === 'everyone') {
            return {
              ...rule,
              value: [],
            };
          }
          return rule;
        });
        const index = selectedCriteriaGroups.groups.findIndex(
          (x) => x.groupId === groupId,
        );

        currentCriteriaGroups.groups[index] = { ...criteriaToBeUpdated };

        onCriteriaGroupsChange({
          ...currentCriteriaGroups,
        });
      }
    },
    [onCriteriaGroupsChange],
  );

  const handleOnGroupChange = useCallback(
    (
      groupId: string | undefined,
      selectedCriteriaProps: SelectedCriteriaProps<string>,
    ) => {
      if (!groupId || !criteriaGroups) {
        addNewCriteriaGroupWithSimpleShareSheet(selectedCriteriaProps);
      } else {
        handleExistingCriteriaGroupChangeWithSimpleShareSheet(
          criteriaGroups,
          selectedCriteriaProps,
          groupId,
        );
      }
    },
    [
      addNewCriteriaGroupWithSimpleShareSheet,
      addNewCriteriaGroupWithoutSimpleShareSheet,
      criteriaGroups,
      handleExistingCriteriaGroupChangeWithSimpleShareSheet,
      handleExistingCriteriaGroupChangeWithoutSimpleShareSheet,
    ],
  );

  const removeRuleFromExistingCriteria = useCallback(
    (
      selectedCriteria: CriteriaGroups | undefined,
      ruleId: string,
      criteriaValue: string,
    ) => {
      const currentCriteriaGroups = selectedCriteria;

      if (currentCriteriaGroups) {
        const criteriaToBeUpdated = currentCriteriaGroups.groups.find((x) =>
          x.groupRules.some((y) => y.ruleId === ruleId),
        );

        if (criteriaToBeUpdated) {
          const index = currentCriteriaGroups.groups.findIndex(
            (x) => x.groupId === criteriaToBeUpdated.groupId,
          );

          const selectedGroupRule = currentCriteriaGroups.groups[
            index
          ].groupRules.find((x) => x.ruleId === ruleId);

          const selectedGroupRuleIndex = currentCriteriaGroups.groups[
            index
          ].groupRules.findIndex((x) => x.ruleId === ruleId);

          if (
            selectedGroupRule &&
            selectedGroupRule.value &&
            selectedGroupRule.field !== 'everyone' &&
            selectedGroupRule.value.length > 1 &&
            currentCriteriaGroups.groups[index].groupRules[
              selectedGroupRuleIndex
            ].field !== 'everyone'
          ) {
            currentCriteriaGroups.groups[index].groupRules[
              selectedGroupRuleIndex
            ].value = currentCriteriaGroups.groups[index].groupRules[
              selectedGroupRuleIndex
            ].value.filter((x) => x.id !== criteriaValue);

            onCriteriaGroupsChange({ ...currentCriteriaGroups });
          } else {
            currentCriteriaGroups.groups[index].groupRules =
              currentCriteriaGroups.groups[index].groupRules.filter(
                (x) => x.ruleId !== ruleId,
              );
          }
          onCriteriaGroupsChange({ ...currentCriteriaGroups });
        }

        const groupIdToBeRemoved = currentCriteriaGroups.groups.find(
          (x) => x.groupRules.length === 0,
        )?.groupId;

        if (groupIdToBeRemoved) {
          currentCriteriaGroups.groups = currentCriteriaGroups.groups.filter(
            (x) => x.groupId !== groupIdToBeRemoved,
          );
          onCriteriaGroupsChange({ ...currentCriteriaGroups });
        }
      }
    },
    [onCriteriaGroupsChange],
  );

  const handleRemoveRuleClick = useCallback(
    (ruleId: string, criteriaValue: string) => {
      if (criteriaValue === 'everyone') {
        setIsEveryOneSelected(false);
        removeRuleFromExistingCriteria(criteriaGroups, ruleId, criteriaValue);
        return;
      }
      removeRuleFromExistingCriteria(criteriaGroups, ruleId, criteriaValue);
    },
    [criteriaGroups, onCriteriaGroupsChange, removeRuleFromExistingCriteria],
  );

  const handleOnMemberAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaProps<string>) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      if (selectedCriteria) {
        trackFlowBuilderActionEvent({
          action: paramLabel,
          [attributeLabelForAnalytics[paramLabel]]: [
            `${selectedCriteria.field} ${selectedCriteria.operator}`,
          ],
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      setAnchorEl(null);
    },
    [
      handleOnGroupChange,
      paramLabel,
      selectedGroupId,
      templateId,
      templateName,
      flowName,
    ],
  );

  const handleOnManagerAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaManagerProps) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      setAnchorEl(null);
      trackFlowBuilderActionEvent({
        action: paramLabel,
        [attributeLabelForAnalytics[paramLabel]]: ['manager'],
        templateId,
        templateName,
        flowTitle: flowName,
      });
    },
    [
      handleOnGroupChange,
      paramLabel,
      selectedGroupId,
      templateId,
      templateName,
      flowName,
    ],
  );

  const handleOnSlackChannelAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaManagerProps) => {
      setAnchorEl(null);
      handleOnGroupChange('', selectedCriteria);
    },
    [],
  );

  const handleOnDepartmentAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaProps<string>) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      if (selectedCriteria) {
        trackFlowBuilderActionEvent({
          action: paramLabel,
          [attributeLabelForAnalytics[paramLabel]]: [
            `${selectedCriteria.field} ${selectedCriteria.operator}`,
          ],
          templateId,
          templateName,
          flowTitle: flowName,
        });
      }
      setAnchorEl(null);
    },
    [
      handleOnGroupChange,
      paramLabel,
      selectedGroupId,
      templateId,
      templateName,
      flowName,
    ],
  );

  const handleOnLocationAddClick = useCallback(
    (selectedCriteria: SelectedCriteriaProps<string>) => {
      handleOnGroupChange(selectedGroupId, selectedCriteria);
      setAnchorEl(null);
    },
    [handleOnGroupChange, selectedGroupId],
  );

  const handleOnCriteriaMemberOptionsScroll = useCallback(async () => {
    if (hasMoreMembers && !isMembersSearchFetching) {
      await fetchMoreMembers();
    }
  }, [fetchMoreMembers, hasMoreMembers, isMembersSearchFetching]);

  const handleOnCriteriaEmailOptionsScroll = useCallback(async () => {
    if (hasMoreEmails && !isMembersSearchByEmailFetching) {
      await fetchMoreEmails();
    }
  }, [fetchMoreEmails, hasMoreEmails, isMembersSearchByEmailFetching]);

  return {
    models: {
      anchorEl,
      popoverId,
      departments,
      hasMoreEmails,
      hasMoreMembers,
      selectedGroupId,
      locationOptions,
      jobTitleOptions,
      popoverMenuItems,
      workLocationOptions,
      isEveryOneSelected,
      isDepartmentsLoading,
      isCriteriaPopoverOpen,
      isMembersSearchLoading,
      isMembersSearchFetching,
      participationDepartments,
      isUserLocationDataLoading,
      canInviteMembersOnFlowBuilder,
      isMembersSearchByEmailLoading,
      isMembersSearchByEmailFetching,
    },
    operations: {
      setAnchorEl,
      fetchMoreEmails,
      fetchMoreMembers,
      setSelectedGroupId,
      handleOnGroupChange,
      setIsEveryOneSelected,
      handleRemoveRuleClick,
      onMembersSearchChange,
      handleOnEveryOneSelectedWithoutSimpleShareSheet,
      handleOnEveryOneSelectedWithSimpleShareSheet,
      handleGroupConditionChange,
      onMembersSearchByEmailChange,
      getParticipantMembers,
      getParticipantsMembersByEmail,
      handleCriteriaGroupConditionChange,
      getParticipationCriteriaActiveSlideId,
      onSlackChannelAdd: handleOnSlackChannelAddClick,
      onMemberAddClick: handleOnMemberAddClick,
      onDepartmentAddClick: handleOnDepartmentAddClick,
      handleOnLocationAddClick,
      onCriteriaEmailOptionsScroll: handleOnCriteriaEmailOptionsScroll,
      onCriteriaMemberOptionsScroll: handleOnCriteriaMemberOptionsScroll,
      onManagerAddClick: handleOnManagerAddClick,
    },
  };
};

export default useFlowsBuilderFilterCriteriaController;
