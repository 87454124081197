import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import SVGIcon from '../../atoms/SVGIcon';
import { FlowAccordionItemState } from '../FlowAccordionItem/interface';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background: ${ThemeV2.palette.gray1};
  border-radius: 4px;
  margin-right: 12px;
`;

export const StyledSVGIcon = styled(SVGIcon)<{
  state: FlowAccordionItemState;
  isFlowBuilderCompleteAccordion: boolean | undefined;
}>`
  svg path {
    fill: ${({ state, isFlowBuilderCompleteAccordion }) => {
      if (!isFlowBuilderCompleteAccordion) {
        if (state === FlowAccordionItemState.Blur) {
          return ThemeV2.palette.gray9;
        }
        if (state === FlowAccordionItemState.Disabled) {
          return ThemeV2.palette.gray6;
        }
        return ThemeV2.palette.geekBlue6;
      }
      return null;
    }};
    opacity: ${({ state }) => state === FlowAccordionItemState.Blur && 0.8};
    transition: 0.2s ease;
    transition-property: fill, opacity;
  }
`;

export const StyledHeading = styled(Heading)<{ state: FlowAccordionItemState }>`
  margin: auto 0;
  color: ${({ state }) => {
    if (state === FlowAccordionItemState.Disabled) {
      return ThemeV2.palette.gray6;
    }
    if (state === FlowAccordionItemState.Blur) {
      return ThemeV2.palette.gray9;
    }
    return ThemeV2.palette.gray1;
  }};
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && 0.8};
  transition: 0.2s ease;
  transition-property: color;
`;

export const StyledButton = styled(Button)<{ state: FlowAccordionItemState }>`
  margin: auto 0 auto auto;
  color: ${({ state }) =>
    state === FlowAccordionItemState.Focus
      ? ThemeV2.palette.gray1
      : ThemeV2.palette.geekBlue6};
  opacity: ${({ state }) => state === FlowAccordionItemState.Blur && 0.8};
  transition: 0.2s ease;
  transition-property: color, opacity;
`;

export const StyledLockIcon = styled(SVGIcon)`
  margin: auto 16px auto auto;
  svg path {
    fill: ${ThemeV2.palette.gray6};
  }
`;

export const HeaderRoot = styled.div<{ state: FlowAccordionItemState }>`
  display: flex;
  background: ${({ state }) =>
    state === FlowAccordionItemState.Focus
      ? ThemeV2.palette.geekBlue6
      : ThemeV2.palette.gray1};
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid
    ${({ state }) =>
      state === FlowAccordionItemState.Focus
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.gray4};
  padding: 10px 16px;
  transition: 0.2s ease;
  transition-property: background, border-color;
  :hover {
    ${StyledSVGIcon} {
      svg path {
        opacity: 1;
      }
    }
    ${StyledHeading},${StyledButton} {
      opacity: 1;
    }
  }
`;
