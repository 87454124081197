import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import FlowsPostHeader from '../../../atomic/molecules/FlowsPostHeader';
import FlowResponseController from '../../flows/FlowResponseController';
import Button from '../../../atomic/atoms/Button';
import { Flex } from '../../../Utils/styles/display';
import InfoAlert from '../../../atomic/molecules/InfoAlert';

export const StyledFlowResponseController = styled(FlowResponseController)`
  margin: 0;
  width: 100%;
`;

export const CommentsContainer = styled.div`
  width: 100%;
`;

export const RepliesTextWrapper = styled.div`
  width: 100%;
  padding: 10px 16px 0 16px;
  display: grid;
  grid-template-columns: auto 1fr;
`;

export const HorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
  margin: auto 0 auto 8px;
`;

export const DropdownContainer = styled.div`
  margin-left: auto;
`;

export const StyledFlowPostHeader = styled(FlowsPostHeader)`
  top: 0;
  width: 100%;
  z-index: 99;
  position: sticky;
  padding: 16px 16px 8px 16px;
  background: ${ThemeV2.palette.white};
  ${({ showBoxShadow }) =>
    showBoxShadow && `box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);`}
`;

export const StyledFeedCommentInputWrapper = styled.div<{
  footerHeight: number;
}>`
  bottom: 128px;
  width: auto;
  position: fixed;
  ${({ footerHeight }) => `bottom: ${footerHeight + 28}px;`}
`;

export const ViewMoreRepliesButton = styled(Button)`
  border-radius: 16px;
  background-color: ${ThemeV2.palette.gray3};
`;

export const PostAndCommentsContainer = styled(Flex)<{
  height: string;
}>`
  overflow-y: auto;
  height: ${({ height }) => height};
`;

export const CommentInputWrapper = styled.div<{ showBoxShadow: boolean }>`
  padding: 8px 16px 0 16px;
  width: 100%;
  background: ${ThemeV2.palette.white};
  ${({ showBoxShadow }) =>
    showBoxShadow && `box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);`}
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin: 8px 0;
  padding: 4px 16px;
`;

export const PostAndCommentWrapper = styled.div<{
  isEmbeddedInMainApp?: boolean;
}>`
  width: 100%;
  height: ${({ isEmbeddedInMainApp }) =>
    isEmbeddedInMainApp ? 'calc(100vh - 90px)' : '100%'};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
