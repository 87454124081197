/* eslint-disable max-len */
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import ThemeV2 from '../../../theme';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import useParticipationFlow from '../../../hooks/useParticipationFlow';
import {
  CreatePostPayload,
  FeedItemFromAPI,
  UpdatePostPayload,
} from '../../../interfaces/Feed';
import { IMemberDTO } from '../../../interfaces/member';
import { useCreatePost, useUpdatePost } from '../../../queries/Feed';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  canGiveCustomAmountOfPoints,
  getIsCoreValuesEnabled,
} from '../../../queries/Profile/utils';
import { trackEvent } from '../../../Utils/analytics';
import {
  ANALYTICS_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../../Utils/analytics/constants';
import {
  postErrorToastMessage,
  postSuccessToastMessage,
  showErrorMessage,
  showSuccessMessage,
} from '../../../Utils/toast';
import { recognitionFlowStepData, STEP_IDS } from './data';
import {
  generateRecognitionMemberAutocompleteOptions,
  generateValidationSchema,
  getEditPostTouchedFields,
  transformMessageForApi,
} from './utils';
import {
  POST_CREATION_SUCCESS_MESSAGE,
  POST_UPDATED_SUCCESS_MESSAGE,
} from '../../../languages/en/giveRecognitionForm';
import {
  StaticBlockState,
  DynamicBlockState,
  OpenEndedBlockValue,
  FlowSubmissionDetails,
  PossibleValues,
} from '../../../interfaces/Flow';
import {
  getErrorMessage,
  getRawDraftContentState,
} from '../../../Utils/message';
import {
  EDIT_ANSWER_BANNER_TEXT,
  EDIT_DRAFT_BANNER_TEXT,
  EDIT_DRAFT_CLICKABLE_TEXT,
  RECOGNITION_API_GENERIC_ERROR,
} from '../../../languages/en/flows/participation';
import { canCurrentUserGiveAllowance } from '../../../Utils/user';
import { useParams } from 'react-router-dom';
import { mapUsersToChipProps } from '../../flows/FlowsFeedController/utils';
import { mapTaggedUsersToSelectedMembers } from '../../../Utils/draftjs/mention';
import {
  trackFlowParticipationActionEvent,
  trackFlowParticipationErrorEvent,
  trackRecognitionParticipationActionEvent,
  trackRecognitionParticipationErrorEvent,
} from '../../../Utils/analytics/flowsParticipation';
import { useDraftStore } from '../../../stores/draftStore';
import {
  generateInviteNewMemberOption,
  getInviteUserCapabilityFromPersonSelector,
  getPersonSelectorValue,
} from '../../../Utils/flows';
import isArray from 'lodash/isArray';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import inviteMemberImage from '../../../atomic/atoms/SVGIcon/icons/invite-member.svg';
import useLayoutStore from '../../../stores/layoutStore';

const NO_USER_PROPS = undefined;

const generateInitialValues = (
  selectedTeammateDropdownItems?: AutocompleteDropdownItem<string>[],
) => {
  const selectedTeammates = selectedTeammateDropdownItems
    ? selectedTeammateDropdownItems
    : [];

  const initialValues = {
    [STEP_IDS.SELECT_TEAMMATE]: selectedTeammates,
    [STEP_IDS.WHAT_DID_THEY_DO]: {
      editorState: EditorState.createEmpty(),
      gifUrl: undefined,
      selectedMentions: [],
      tags: [],
      tasks: [],
    } as OpenEndedBlockValue,
    [STEP_IDS.SELECT_CORE_VALUE]: null,
    [STEP_IDS.TROPHIES]: '',
  };
  return initialValues;
};

const generateEditPostInitialValues = (postData: FeedItemFromAPI) => {
  const selectedMembers = mapUsersToChipProps(postData.to);
  const selectedMentions = mapTaggedUsersToSelectedMembers(
    postData.taggedUsers,
  );
  const rawState = getRawDraftContentState(postData.message, selectedMentions);

  const initialValues = {
    [STEP_IDS.SELECT_TEAMMATE]: selectedMembers,
    [STEP_IDS.WHAT_DID_THEY_DO]: {
      editorState: EditorState.createWithContent(convertFromRaw(rawState)),
      gifUrl: postData.gifURL,
      selectedMentions: selectedMentions,
      tags: [],
    },
    [STEP_IDS.SELECT_CORE_VALUE]: {
      id: postData.coreValue,
      title: postData.coreValue,
    },
    [STEP_IDS.TROPHIES]: postData.pointsEach,
  };
  return initialValues;
};

const generateEditDraftInitialValues = (draftData: Record<string, any>) => {
  return {
    [STEP_IDS.SELECT_TEAMMATE]: draftData[STEP_IDS.SELECT_TEAMMATE],
    [STEP_IDS.WHAT_DID_THEY_DO]: {
      editorState: EditorState.createWithContent(
        convertFromRaw(draftData[STEP_IDS.WHAT_DID_THEY_DO].editorState),
      ),
      gifUrl: draftData[STEP_IDS.WHAT_DID_THEY_DO].gifUrl,
      selectedMentions: draftData[STEP_IDS.WHAT_DID_THEY_DO].selectedMentions,
      tags: [],
    },
    [STEP_IDS.SELECT_CORE_VALUE]: draftData[STEP_IDS.SELECT_CORE_VALUE],
    [STEP_IDS.TROPHIES]: draftData[STEP_IDS.TROPHIES],
  };
};

const useRecognitionFlowController = (
  profileInfo: GetProfileInfoResponse,
  postData: FeedItemFromAPI | undefined,
  toggleIsRecognitionFlowModalOpen: () => void,
  selectedTeammateDropdownItems?: AutocompleteDropdownItem<string>[],
  onPostSuccess?: () => void,
) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const [havePointsBeenAdjusted, setHavePointsBeenAdjusted] = useState(false);
  const [isPrivatePost, setIsPrivatePost] = useState(false);
  const [isNavigatingToMainApp, setIsNavigatingToMainApp] = useState(false);
  const { assembly, member } = profileInfo;
  const { pointsLeftThisCycle } = member;

  const { drafts, saveDraft, deleteDraft } = useDraftStore();
  const { postId } = useParams<{ postId: string }>();
  const isEditMode = !!postId;
  const isDraftMode =
    !!drafts['recognition_' + assembly.assemblyId] && !isEditMode;

  const {
    models: {
      value: textboxValue,
      searchedMembers,
      totalMembers,
      isFetching: isMembersFetching,
      hasMoreMembers,
      canEmailInvite,
    },
    operations: { onChange: onTextboxValueChange, fetchMoreMembers },
  } = useMembersSearch(true);

  const onPeopleOptionsScroll = () => {
    if (hasMoreMembers && !isMembersFetching) {
      fetchMoreMembers();
    }
  };

  const coreValueOptions: AutocompleteDropdownItem<string>[] = useMemo(() => {
    if (profileInfo) {
      return profileInfo.assembly.settings.coreValues.value.map((value) => ({
        id: value,
        title: value,
      }));
    }
    return [];
  }, [profileInfo]);

  const updatedSlideData = useMemo(() => {
    let data = [...recognitionFlowStepData];
    if (!getIsCoreValuesEnabled(profileInfo)) {
      data = data.filter((slide) => slide.id !== STEP_IDS.SELECT_CORE_VALUE);
    }
    if (!canCurrentUserGiveAllowance(profileInfo.member)) {
      data = data.filter((slide) => slide.id !== STEP_IDS.TROPHIES);
    }
    return data;
  }, [profileInfo]);

  const handlePostingSuccess = (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
    successMessage: string,
    editedBlockIds: string[] = [],
  ) => {
    const numberOfBlocks = staticStates.length;
    const numberOfBlocksRequired = staticStates.filter(
      ({ isRequired }) => isRequired,
    ).length;
    if (isEditMode) {
      trackRecognitionParticipationActionEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_RECOGNITION_POST_SAVED,
        postId,
        numberOfBlocks,
        numberOfBlocksRequired,
        editedBlockIds,
      });
    } else {
      trackEvent(
        ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_POST,
        NO_USER_PROPS,
        {
          numberOfBlocks,
          numberOfBlocksRequired,
          numberOfBlocksAnswered: dynamicStates.filter(({ isValid }) => isValid)
            .length,
        },
      );
    }

    toggleIsRecognitionFlowModalOpen();
    if (isEmbeddedInMainApp) {
      postSuccessToastMessage(successMessage);
    } else {
      showSuccessMessage(successMessage);
    }

    if (onPostSuccess) {
      onPostSuccess();
    }
  };

  const onPostError = (
    staticStates: StaticBlockState[],
    dynamicStates: DynamicBlockState[],
    editedBlockIds: string[] = [],
  ) => {
    const numberOfBlocks = staticStates.length;
    const numberOfBlocksRequired = staticStates.filter(
      ({ isRequired }) => isRequired,
    ).length;
    if (isEditMode) {
      trackRecognitionParticipationErrorEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_RECOGNITION_POST_ERROR,
        postId,
        numberOfBlocks,
        numberOfBlocksRequired,
        editedBlockIds,
      });
    } else {
      trackFlowParticipationErrorEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.ERRORED,
      });

      trackEvent(
        ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_POST_ERROR,
        NO_USER_PROPS,
        {
          numberOfBlocks,
          numberOfBlocksRequired,
          numberOfBlocksAnswered: dynamicStates.filter(({ isValid }) => isValid)
            .length,
        },
      );
    }
  };

  const { isLoading: isPosting, mutate: mutateCreatePost } = useCreatePost();
  const { isLoading: isUpdating, mutate: mutateUpdatePost } =
    useUpdatePost(postId);

  const formatPayload = (
    finalValues: any,
    editPostTouchedFields: string[] = [],
  ) => {
    const { editorState, gifUrl, selectedMentions } = finalValues[
      STEP_IDS.WHAT_DID_THEY_DO
    ] as OpenEndedBlockValue;
    const { text: transformedMessage } = transformMessageForApi(
      editorState,
      selectedMentions,
    );

    const selectedTeammates = finalValues[
      STEP_IDS.SELECT_TEAMMATE
    ] as AutocompleteDropdownItem<string>[];

    const carrotsEach =
      finalValues[STEP_IDS.TROPHIES] > 0 ? finalValues[STEP_IDS.TROPHIES] : 0;
    const coreValue = finalValues[STEP_IDS.SELECT_CORE_VALUE]?.id || undefined;
    const payloadGifUrl = gifUrl || undefined;
    const to = selectedTeammates.map((teammate) => teammate.id);

    if (!isEditMode) {
      return {
        carrotsEach,
        coreValue,
        gifUrl: payloadGifUrl,
        isPrivate: isPrivatePost,
        message: transformedMessage,
        to,
      };
    }

    return {
      ...(editPostTouchedFields.includes('coreValue') && {
        coreValue: coreValue || '',
      }),
      ...(editPostTouchedFields.includes('gifUrl') && {
        gifUrl: payloadGifUrl || '',
      }),
      ...(editPostTouchedFields.includes('message') && {
        message: transformedMessage,
      }),
      ...(editPostTouchedFields.includes('selectedTeammate') && {
        to,
      }),
    };
  };

  const schema = useMemo(
    () => generateValidationSchema(profileInfo, updatedSlideData),
    [profileInfo, updatedSlideData],
  );

  const initialValues = useMemo(() => {
    if (postData) {
      return generateEditPostInitialValues(postData);
    } else if (drafts['recognition_' + assembly.assemblyId]) {
      return generateEditDraftInitialValues(
        drafts['recognition_' + assembly.assemblyId],
      );
    }
    return generateInitialValues(selectedTeammateDropdownItems);
  }, [assembly.assemblyId, drafts, postData, selectedTeammateDropdownItems]);

  const onFlowSubmit = ({
    values,
    dynamicBlockData,
  }: FlowSubmissionDetails) => {
    const editPostTouchedFields = getEditPostTouchedFields(values, postData);
    const formSubmitSuccessMessage = isEditMode
      ? POST_UPDATED_SUCCESS_MESSAGE
      : POST_CREATION_SUCCESS_MESSAGE;

    if (drafts['recognition_' + assembly.assemblyId]) {
      deleteDraft('recognition_' + assembly.assemblyId);
    }

    const onError = (error: unknown) => {
      const apiErrorMessages = getErrorMessage(
        error as AxiosError<{ message: string; body: string }>,
        RECOGNITION_API_GENERIC_ERROR,
      );

      onPostError(updatedSlideData, dynamicBlockData, editPostTouchedFields);

      if (apiErrorMessages) {
        if (isEmbeddedInMainApp) {
          postErrorToastMessage(RECOGNITION_API_GENERIC_ERROR);
        } else {
          showErrorMessage(RECOGNITION_API_GENERIC_ERROR);
        }
      }

      setIsNavigatingToMainApp(false);
    };

    const onSuccess = () => {
      if (handlePostingSuccess) {
        handlePostingSuccess(
          updatedSlideData,
          dynamicBlockData,
          formSubmitSuccessMessage,
          editPostTouchedFields,
        );
      }
      setIsNavigatingToMainApp(true);
    };

    // Manually overriding onError and onSuccess call for this mutate function.
    if (isEditMode) {
      const payload = formatPayload(
        values,
        editPostTouchedFields,
      ) as UpdatePostPayload;

      mutateUpdatePost(payload, {
        onError,
        onSuccess,
      });
    }

    if (!isEditMode) {
      const payload = formatPayload(
        values,
        editPostTouchedFields,
      ) as CreatePostPayload;
      mutateCreatePost(payload, {
        onError,
        onSuccess,
      });
    }
  };

  const {
    models: {
      blockErrors,
      currentStep,
      errors,
      fieldErrors,
      hasVisitedLastStep,
      values,
      dynamicBlockData,
      isInvitingUsers,
    },
    operations: {
      onStepChange,
      onFormCompleteClick,
      setFieldTouched,
      setFieldValue,
      resetForm,
      goToNextStep,
      goToPreviousStep,
    },
  } = useParticipationFlow({
    onFlowSubmit,
    staticBlockData: updatedSlideData,
    schema,
    initialValues,
    onSubmitFlowError: onPostError,
    containerRef,
    defaultCurrentStep: isEditMode ? 1 : 0,
  });

  const handleDeleteDraft = () => {
    if (isDraftMode) deleteDraft('recognition_' + assembly.assemblyId);
    resetForm({ values: generateInitialValues(selectedTeammateDropdownItems) });
  };

  const getBannerProps = () => {
    if (isEditMode) {
      return [
        {
          iconColor: ThemeV2.palette.gray8,
          bannerIcon: 'edit',
          bannerText: EDIT_ANSWER_BANNER_TEXT,
        },
      ];
    }
    if (isDraftMode) {
      return [
        {
          iconColor: ThemeV2.palette.gray8,
          bannerIcon: 'generic-file',
          bannerText: EDIT_DRAFT_BANNER_TEXT,
          clickableText: EDIT_DRAFT_CLICKABLE_TEXT,
        },
      ];
    }
  };

  const editPostTouchedFields = getEditPostTouchedFields(values, postData);
  const inviteUserFromPersonSelectorProps =
    getInviteUserCapabilityFromPersonSelector({
      profileInfo,
      isUserManagementTreatmentOn: true,
    });

  const { id: currentStepId } = updatedSlideData[currentStep];
  const handleMultiSelectInputBlockValueChange = (
    possibleValues: PossibleValues,
  ) => {
    if (possibleValues && inviteUserFromPersonSelectorProps) {
      if (isArray(possibleValues)) {
        setFieldValue(
          currentStepId,
          possibleValues.map((possibleValue) =>
            getPersonSelectorValue(
              possibleValue,
              inviteUserFromPersonSelectorProps.isAdminApprovalRequired,
              inviteMemberImage,
            ),
          ),
        );
      } else {
        setFieldValue(
          currentStepId,
          getPersonSelectorValue(
            possibleValues,
            inviteUserFromPersonSelectorProps.isAdminApprovalRequired,
            inviteMemberImage,
          ),
        );
      }
    } else {
      setFieldValue(currentStepId, possibleValues);
    }
    onTextboxValueChange('');
  };

  const memberOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (searchedMembers) {
        if (canEmailInvite && inviteUserFromPersonSelectorProps) {
          return generateInviteNewMemberOption(textboxValue);
        }
        return generateRecognitionMemberAutocompleteOptions(
          searchedMembers,
          profileInfo.member.memberId,
        );
      }
      return [];
    }, [
      canEmailInvite,
      inviteUserFromPersonSelectorProps,
      profileInfo.member.memberId,
      searchedMembers,
      textboxValue,
    ]);

  const handleAdjustmentWarningCTAClick = () => {
    const trophiesStepIndex = getIsCoreValuesEnabled(profileInfo) ? 3 : 2;
    onStepChange(trophiesStepIndex);
  };

  const handleTrophiesValueChange = useCallback(
    (value: string | number) => {
      const shouldValidate = false;
      setFieldValue(STEP_IDS.TROPHIES, value, shouldValidate);
    },
    [setFieldValue],
  );

  const handleModalClose = (draftSaveValues?: {
    saveRequested: boolean;
    isNewDraft?: boolean;
    onDraftSave?: () => void;
  }) => {
    const numberOfBlocks = updatedSlideData.length;
    const numberOfBlocksRequired = updatedSlideData.filter(
      ({ isRequired }) => isRequired,
    ).length;
    if (isEditMode) {
      trackRecognitionParticipationActionEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_RECOGNITION_POST_EXIT,
        postId,
        numberOfBlocks,
        numberOfBlocksRequired,
        editedBlockIds: editPostTouchedFields,
      });
    } else {
      if (draftSaveValues?.saveRequested) {
        const { editorState } = values[
          STEP_IDS.WHAT_DID_THEY_DO
        ] as OpenEndedBlockValue;
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const saveableValues = { ...values };
        saveableValues[STEP_IDS.WHAT_DID_THEY_DO].editorState = rawContentState;
        saveDraft('recognition_' + assembly.assemblyId, saveableValues);
        trackFlowParticipationActionEvent({
          action: draftSaveValues?.isNewDraft
            ? PARTICIPATION_ANALYTICS_EVENTS.DRAFT_SAVED
            : PARTICIPATION_ANALYTICS_EVENTS.DRAFT_UPDATED,
          flowId: '0',
          name: 'Give Recognition',
        });
      } else {
        trackFlowParticipationActionEvent({
          action: draftSaveValues?.isNewDraft
            ? PARTICIPATION_ANALYTICS_EVENTS.DRAFT_DISCARDED
            : PARTICIPATION_ANALYTICS_EVENTS.DRAFT_UPDATES_DISCARDED,
          flowId: '0',
          name: 'Give Recognition',
        });
      }
      trackEvent(
        ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_EXIT,
        NO_USER_PROPS,
        {
          numberOfBlocks,
          numberOfBlocksRequired,
          numberOfBlocksAnswered: dynamicBlockData.filter(
            ({ isValid }) => isValid,
          ).length,
        },
      );
    }

    resetForm();
    toggleIsRecognitionFlowModalOpen();
    draftSaveValues?.onDraftSave?.();
  };

  // Todo
  useEffect(() => {
    const numberOfBlocks = updatedSlideData.length;
    const numberOfBlocksRequired = updatedSlideData.filter(
      ({ isRequired }) => isRequired,
    ).length;

    if (isEditMode) {
      trackRecognitionParticipationActionEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_RECOGNITION_POST_STARTED,
        postId,
        numberOfBlocks,
        numberOfBlocksRequired,
      });
    } else {
      trackEvent(
        ANALYTICS_EVENTS.RECOGNITION_PARTICIPATION_START,
        NO_USER_PROPS,
        {
          numberOfBlocks,
          numberOfBlocksRequired,
        },
      );
    }
    // ONLY RUN ON RENDER
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const trophiesInputValue = values[STEP_IDS.TROPHIES];
    const selectedTeammates = values[STEP_IDS.SELECT_TEAMMATE];
    const selectedTeammatesLength = selectedTeammates.length || 1;
    const maximumGivingPercentage =
      profileInfo.assembly.settings.postImpactLevel.value.levels[4].percentage;

    const maxPointsEach = canGiveCustomAmountOfPoints(profileInfo)
      ? Math.floor(pointsLeftThisCycle / selectedTeammatesLength)
      : Math.min(
          Math.floor(pointsLeftThisCycle / selectedTeammatesLength),
          Math.floor((member.allowance.points * maximumGivingPercentage) / 100),
        );

    const hasExceededAvailablePoints = maxPointsEach < trophiesInputValue;
    if (hasExceededAvailablePoints) {
      const newTrophiesInputValue = Math.min(
        Math.floor(maxPointsEach * selectedTeammatesLength),
        maxPointsEach,
      );
      const newFieldValue =
        newTrophiesInputValue === 0 ? '' : newTrophiesInputValue;
      setFieldValue(STEP_IDS.TROPHIES, newFieldValue);
      setHavePointsBeenAdjusted(true);
    }
  }, [
    member.allowance.points,
    pointsLeftThisCycle,
    profileInfo,
    setFieldValue,
    values,
  ]);

  useEffect(() => {
    setHavePointsBeenAdjusted(false);
  }, [currentStep]);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const getDropdownFooterMessage = () => {
    if (isMobileView) {
      return 'Type emails to invite new teammates';
    } else {
      return inviteUserFromPersonSelectorProps?.isAdminApprovalRequired
        ? 'Don’t see who you’re looking for? Type an email to invite a new teammate, and an invite request will be sent to admin.'
        : 'Don’t see who you’re looking for? Type an email to invite a new teammate.';
    }
  };

  const isSubmitting = isEditMode ? isUpdating : isPosting;
  const isEditModeSubmitButtonDisabled = !editPostTouchedFields.length;

  return {
    models: {
      isNavigatingToMainApp,
      isSingleMemberAssembly: totalMembers === 1,
      updatedSlideData,
      currentStep,
      blockErrors,
      values,
      memberOptions,
      isMembersFetching,
      textboxValue,
      totalMembers,
      havePointsBeenAdjusted,
      assembly,
      fieldErrors,
      coreValueOptions,
      member,
      errors,
      pointsLeftThisCycle,
      hasVisitedLastStep,
      isSubmitting: isSubmitting || isInvitingUsers,
      isPrivatePost,
      dynamicBlockData,
      containerRef,
      bannerProps: getBannerProps(),
      isDraftMode,
      isEditMode,
      isEditModeSubmitButtonDisabled,
      inviteUserFromPersonSelectorProps,
      getDropdownFooterMessage,
    },
    operations: {
      onStepChange,
      setFieldValue,
      onTextboxValueChange,
      setFieldTouched,
      handleAdjustmentWarningCTAClick,
      handleTrophiesValueChange,
      onFormCompleteClick,
      setIsPrivatePost,
      handleModalClose,
      goToNextStep,
      goToPreviousStep,
      onPeopleOptionsScroll,
      handleDeleteDraft,
      handleMultiSelectInputBlockValueChange,
    },
  };
};

export default useRecognitionFlowController;
