import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { handleV3Redirect } from '../';
import useLayoutStore from '../stores/layoutStore';
import mobileStore from '../stores/mobileAppStore';
import { isMobilePlatform } from '../Utils/window';

export async function useEnforceV3Experience({ slugUrl }: { slugUrl: string }) {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { pathname } = useLocation();
  const isMobileApp =
    isMobilePlatform &&
    (mobileStore.getState().isMobileApp ||
      new URLSearchParams(location.search).get('isMobileApp') === 'true');

  const enforceV3Experience = useCallback(async () => {
    if (
      !(
        pathname.match(/\/(signin|bot)\/authorize/g) ||
        pathname.startsWith('/slack-redirect') ||
        pathname.startsWith('/security/') ||
        pathname.startsWith('/auth/saml') ||
        pathname.startsWith('/template/') ||
        pathname.startsWith('/e/completion')
      )
    ) {
      if (!isEmbeddedInMainApp) {
        await handleV3Redirect({
          slugPath: slugUrl,
        });
      }
    }
  }, [isEmbeddedInMainApp, slugUrl, pathname]);

  useEffect(() => {
    if (!isMobileApp) {
      enforceV3Experience();
    }
  }, [enforceV3Experience, isMobileApp]);
}
