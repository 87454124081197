import React from 'react';
import ParticipantsList from '../../../../atomic/molecules/ParticipantsList';
import {
  criteriaConditionItems,
  criteriaMenuItems,
} from '../../../../constants/flowsBuilder';
import { ComponentStatus } from '../../../../interfaces/component';
import { PARTICIPANT_LIST_TEXTS } from '../../../../languages/en/flows';
import {
  ADD_PARTICIPANT_CRITERIA_LABEL,
  SHARE_SHEET_MODAL,
} from '../../../../languages/en/flows/builder';
import { Flex } from '../../../../Utils/styles/display';
import FlowsBuilderFilterCriteriaController from '../../../flowsBuilder/FlowsBuilderFilterCriteriaController';
import {
  ParticipantDescription,
  ParticipantsCriteriaContainer,
  ParticipantsListWrapper,
  ParticipationCriteriaTitle,
} from '../styles';

import useShareSheetParticipantsController from './useShareSheetParticipantsController';
import ParticipantsNotFoundImage from '../../../../img/participants_not_found.svg';
import { ParticipantsBuilderBlockData } from '../../../../interfaces/Flow/Builder';
import CriteriaItem from '../../../../atomic/molecules/CriteriaItem';
import { ConditionWrapper, HorizontalRule } from './styles';
import Body from '../../../../atomic/atoms/Body';
import ThemeV2 from '../../../../theme';
import { LockIcon } from '../../../../atomic/molecules/CriteriaItem/styles';
import ToolTip from '../../../../atomic/molecules/ToolTipV2';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import useSlackUserStore from '../../../../stores/userStore';
import { Channel } from '../../../../interfaces/Connections';
import { isEveryoneCriteria } from '../../../../Utils/flows/criteria';

export type ShareSheetParticipantsControllerProps = {
  variant: 'mobileView' | 'default';
  participantsBlockData: ParticipantsBuilderBlockData;
  updateParticipantsBlockData: (
    updatedContentBlock: ParticipantsBuilderBlockData,
  ) => void;
};

const ShareSheetParticipantsController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const { models, operations } = useShareSheetParticipantsController(props);
  const {
    variant,
    isLoading,
    hasNextPage,
    participants,
    flowBuilderData,
    participantsCount,
    participantsCriteriaGroups,
    pendingMembersParticipantCount,
  } = models;

  const { isConnectionsTabTouched } = useFlowBuilderStore();
  const { selectedPeopleToPostChannels } = useSlackUserStore();

  const { fetchNextPage, handleOnCriteriaGroupsChange } = operations;

  const isEveryOneSelected = isEveryoneCriteria(participantsCriteriaGroups);

  const isCriteriaItemsEmpty = participantsCriteriaGroups?.groups.length === 0;

  const slackConnectionsList = isConnectionsTabTouched
    ? selectedPeopleToPostChannels.map((x: Channel) => ({
        id: x.id,
        name: x.displayName,
        isPrivate: x.isPrivate,
      }))
    : flowBuilderData?.data?.integrations?.participationNotification[0]?.metaData?.channelInfo.map(
        (channel) => ({
          id: channel.id,
          name: channel.name,
          isPrivate: channel.isPrivate,
        }),
      ) || [];

  return (
    <>
      {variant === 'mobileView' ? (
        SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.DESCRIPTION
      ) : (
        <ParticipantDescription variant="body3">
          {SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.DESCRIPTION}
        </ParticipantDescription>
      )}
      <Flex
        width="100%"
        alignItems="unset"
        flexDirection={variant === 'mobileView' ? 'column' : 'row'}
      >
        <ParticipantsCriteriaContainer
          variant={variant}
          flexDirection="column"
          alignItems="flex-start"
        >
          <ParticipationCriteriaTitle variant="body1Medium" color="gray9">
            {SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.TITLE}
          </ParticipationCriteriaTitle>
          <FlowsBuilderFilterCriteriaController
            variant={variant}
            hideEveryone={false}
            from="participation"
            criteriaGroups={participantsCriteriaGroups}
            criteriaMenuItems={criteriaMenuItems}
            criteriaLabel={ADD_PARTICIPANT_CRITERIA_LABEL}
            criteriaConditionItems={criteriaConditionItems}
            onCriteriaGroupsChange={handleOnCriteriaGroupsChange}
          />
          {slackConnectionsList.length > 0 && (
            <>
              {!isEveryOneSelected && !isCriteriaItemsEmpty && (
                <HorizontalRule />
              )}
              <ConditionWrapper>
                <Body variant="body2" color="gray9">
                  Or participant matches
                </Body>
                <ToolTip
                  position="bottom"
                  toolTipComponent={
                    <LockIcon
                      icon="lock"
                      size="14px"
                      color={ThemeV2.palette.gray8}
                    />
                  }
                >
                  Members of this channel who <br />
                  were added via Connections can post <br />
                  in this flow.
                </ToolTip>
              </ConditionWrapper>
              {slackConnectionsList.map((connection) => (
                <CriteriaItem
                  isSlackCriteria
                  isLoading={false}
                  key={connection.id}
                  id={connection.id}
                  criteriaValue={connection.name}
                  icon="slack-dark"
                  criteria="Slack Channel"
                  criteriaCondition="is"
                  memberState={undefined}
                  criteriaMessage={
                    <>
                      Members of this channel who <br />
                      were added via Connections can post <br />
                      in this flow.
                    </>
                  }
                />
              ))}
            </>
          )}
        </ParticipantsCriteriaContainer>
        <ParticipantsListWrapper
          variant={variant}
          alignItems="unset"
          flexDirection="column"
        >
          {isLoading ? (
            <ParticipantsList
              title={PARTICIPANT_LIST_TEXTS.PARTICIPANTS}
              status={ComponentStatus.LOADING}
              restrictHeight={false}
            />
          ) : (
            <ParticipantsList
              isInEditMode={true}
              title={PARTICIPANT_LIST_TEXTS.PARTICIPANTS}
              participants={participants}
              hasMoreMembers={hasNextPage}
              fetchNextPage={fetchNextPage}
              participantsCount={participantsCount}
              pendingMembersParticipantCount={pendingMembersParticipantCount}
              status={ComponentStatus.LOADED}
              hasNoMatchingParticipants={participantsCount === 0}
              noMatchingParticipantsImage={ParticipantsNotFoundImage}
              noMatchingParticipantsLabel={
                participantsCriteriaGroups
                  ? PARTICIPANT_LIST_TEXTS.NO_MATCHING_PARTICIPANTS
                  : PARTICIPANT_LIST_TEXTS.NO_PARTICIPANTS
              }
              restrictHeight={false}
              stickyHeader
            />
          )}
        </ParticipantsListWrapper>
      </Flex>
    </>
  );
};

export default ShareSheetParticipantsController;
