import { AxiosError } from 'axios';
import { END_OCCURRENCE_RESPONSE_MESSAGE } from '../../languages/en/flows/feed';
import { useEndOccurrenceQuery } from '../../queries/Flows/FlowFeedOptions/EndOccurrence';
import { showErrorMessage, showSuccessMessage } from '../../Utils/toast';

import { getErrorMessage } from '../../Utils/message';
import useToggle from '../useToggle';

const useEndOccurrenceOption = () => {
  const {
    models: { toggleValue: isEndOccurrenceModalOpen },
    operations: {
      setToggleToFalse: setIsEndOccurrenceModalClose,
      setToggleToTrue: setIsEndOccurrenceModalOpen,
    },
  } = useToggle();

  const { mutate: mutateEndOccurrence, isLoading } = useEndOccurrenceQuery();

  const setEndOccurrenceModalClose = () => {
    setIsEndOccurrenceModalClose();
  };

  const handleEndOccurrenceButtonClick = (flowId: string) => {
    mutateEndOccurrence(
      {
        flowId,
      },
      {
        onError: (error) => {
          const errorMessage = getErrorMessage(
            error as AxiosError<{ message: string; body: string }>,
            END_OCCURRENCE_RESPONSE_MESSAGE.ERROR,
          );
          showErrorMessage(
            errorMessage || END_OCCURRENCE_RESPONSE_MESSAGE.ERROR,
          );
        },
        onSuccess: () => {
          setIsEndOccurrenceModalClose();
          showSuccessMessage(END_OCCURRENCE_RESPONSE_MESSAGE.SUCCESS);
        },
      },
    );
  };

  return {
    models: {
      isEndOccurrenceModalOpen,
      isEndOccurrenceModalLoading: isLoading,
    },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  };
};

export default useEndOccurrenceOption;
