import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { canMemberReceiveAllowance, getFullName } from '../../../Utils/user';
import { getCharacterCount } from '../../../Utils/draftjs';
import {
  getBadWordsList,
  getCanCheckForBadWords,
  getMinimumMessageCharacters,
} from '../../../queries/Profile/utils';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  getLiteralTextFromEditor,
  getTextFromEditor,
  getTrimmedMessage,
} from '../../../Utils/draftjs/mention';
import { VALIDATION_ERRORS } from '../../../languages/en/flows/participation';
import { IMemberDTO } from '../../../interfaces/member';
import {
  OpenEndedBlockValue,
  StaticBlockState,
} from '../../../interfaces/Flow';
import { MemberState, Mention } from '../../../interfaces/user';
import { checkForProfanity } from '../../../Utils/giveCarrots/validation';
import ThemeV2 from '../../../theme';
import { FeedItemFromAPI } from '../../../interfaces/Feed';
import { STEP_IDS } from './data';
import isEqual from 'lodash/isEqual';
import { replaceAll } from '../../../Utils/text';
import { NotebookTask } from '../../../interfaces/notebook';

export const generateRecognitionMemberAutocompleteOptions = (
  members: IMemberDTO[],
  currentUserId: string,
) => {
  return members.reduce<AutocompleteDropdownItem<string, IMemberDTO>[]>(
    (arr, member) => {
      const isCurrentUser = member.memberID === currentUserId;
      const canReceiveAllowance = canMemberReceiveAllowance(member);
      const fullName = getFullName(member);
      if (!isCurrentUser && canReceiveAllowance) {
        const item = {
          id: member.memberID,
          title: fullName,
          avatar: {
            img: member.image || '',
            name: fullName,
            userId: member.memberID,
            icon:
              member.memberState === MemberState.PENDING
                ? 'pending-person'
                : '',
            iconColor: ThemeV2.palette.white,
          },
          item: member,
        };

        arr.push(item);
      }
      return arr;
    },
    [],
  );
};

export const transformMessageForApi = (
  editorState: EditorState,
  selectedMentions: Mention[],
  tags?: Mention[],
  tasks?: NotebookTask[],
) => {
  const mentionsToReplace: Mention[] = [];
  const idDict: { [Key: string]: boolean } = {};
  selectedMentions.forEach((mention: Mention) => {
    if (!idDict[mention.id]) {
      mentionsToReplace.push(mention);
      idDict[mention.id] = true;
    }
  });
  const { uniqueTasks } = (tasks || []).reduce<{
    uniqueTasks: NotebookTask[];
    tasksSeen: Set<string>;
  }>(
    (tasksAccum, task) => {
      if (!tasksAccum.tasksSeen.has(task.noteId)) {
        tasksAccum.tasksSeen.add(task.noteId);
        tasksAccum.uniqueTasks.push(task);
      }
      return tasksAccum;
    },
    { uniqueTasks: [], tasksSeen: new Set() },
  );
  const {
    text: editorText,
    replacedMentions,
    replacedTags,
    replacedTasks,
  } = getTextFromEditor(editorState, mentionsToReplace, tags, uniqueTasks);

  return {
    text: getTrimmedMessage(editorText) as string,
    replacedMentions,
    replacedTags,
    replacedTasks,
  };
};

export const generateValidationSchema = (
  profileInfo: GetProfileInfoResponse,
  slideData: StaticBlockState[],
) => {
  const minimumCharacterLimit = getMinimumMessageCharacters(profileInfo) || 1;
  const canCheckForProfanity = getCanCheckForBadWords(profileInfo);
  const badWordsList = getBadWordsList(profileInfo);
  const schemaObj: Record<string, Yup.AnySchema> = {};

  slideData.forEach(({ id, type, isRequired }) => {
    switch (type) {
      case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
        schemaObj[id] = Yup.array().ensure().min(1, VALIDATION_ERRORS.REQUIRED);
        break;
      }
      case 'OPEN_ENDED': {
        schemaObj[id] = Yup.object()
          .test(
            'required',
            VALIDATION_ERRORS.REQUIRED,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }) =>
              editorState.getCurrentContent().hasText(),
          )
          .test(
            'minimumCharacterLimit',
            VALIDATION_ERRORS.UNDER_CHARACTER_LIMIT,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }, testContext) => {
              const messageLength = getCharacterCount(editorState);

              if (messageLength < minimumCharacterLimit) {
                const amountUnderCharacterLimit =
                  minimumCharacterLimit - messageLength;
                const message =
                  VALIDATION_ERRORS.generateMinimumCharacterLimitError(
                    amountUnderCharacterLimit,
                  );
                return testContext.createError({ message });
              }

              return messageLength >= minimumCharacterLimit;
            },
          )
          .test(
            'profanityCheck',
            VALIDATION_ERRORS.PROFANE_ERROR_MESSAGE,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            /* @ts-ignore */
            ({ editorState }: { editorState: EditorState }) =>
              canCheckForProfanity
                ? !checkForProfanity(
                    getLiteralTextFromEditor(editorState).toLowerCase(),
                    badWordsList,
                  )
                : true,
          );
        break;
      }
      case 'SINGLE_SELECT_DROPDOWN': {
        schemaObj[id] = Yup.object().nullable();
        break;
      }
      case 'TROPHIES': {
        schemaObj[id] = Yup.number().test(
          'excessiveTrophies',
          VALIDATION_ERRORS.EXCESSIVE_TROPHIES,
          (value) => value !== -1,
        );
        break;
      }
      default: {
        break;
      }
    }
    if (isRequired && type !== 'OPEN_ENDED') {
      schemaObj[id] = schemaObj[id].required(VALIDATION_ERRORS.REQUIRED);
    }
  });
  return Yup.object().shape(schemaObj);
};

export const getEditPostTouchedFields = (
  finalValues: any,
  postData?: FeedItemFromAPI,
) => {
  const editPostTouchedPayload: any[] = [];

  if (!postData) {
    return [];
  }

  const { editorState, gifUrl, selectedMentions } = finalValues[
    STEP_IDS.WHAT_DID_THEY_DO
  ] as OpenEndedBlockValue;
  const selectedCoreValue =
    finalValues[STEP_IDS.SELECT_CORE_VALUE] &&
    finalValues[STEP_IDS.SELECT_CORE_VALUE].id;
  const selectedTeammateIds = finalValues[STEP_IDS.SELECT_TEAMMATE].map(
    (user: AutocompleteDropdownItem<string>) => user.id,
  );
  const postDataToIds = postData?.to.map((user) => user.memberID);
  const { text: currentMessage } = transformMessageForApi(
    editorState,
    selectedMentions,
  );

  if (!isEqual(selectedTeammateIds, postDataToIds)) {
    editPostTouchedPayload.push('selectedTeammate');
  }

  // Todo: Temp fix by Suren:
  // To remove extra new line appended by editor while populate mentions data in edit mode
  // Because of this new line, it considered as edited field, so.
  // Once we fixed new line append issue, we can safely remove this.
  const MENTION_REGEX_WITH_NEW_LINE = /\n(@__.*?__@)\n/;
  const formattedCurrentMessage = replaceAll(
    currentMessage,
    MENTION_REGEX_WITH_NEW_LINE,
    (_allMatch: string, mention: string) => {
      return ` ${mention} `;
    },
  );
  const formattedPostDataMessage = replaceAll(
    postData?.message,
    MENTION_REGEX_WITH_NEW_LINE,
    (_allMatch: string, mention: string) => {
      return ` ${mention} `;
    },
  );

  if (formattedCurrentMessage !== formattedPostDataMessage) {
    editPostTouchedPayload.push('message');
  }

  if (gifUrl !== postData?.gifURL) {
    editPostTouchedPayload.push('gifUrl');
  }

  if (selectedCoreValue !== postData?.coreValue) {
    editPostTouchedPayload.push('coreValue');
  }

  return editPostTouchedPayload;
};
