import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  V2_GIFTCARD_REWARDS,
  V2_CULTURE_REWARDS,
  V2_CHARITY_REWARDS,
  V2_MY_REWARDS,
  V2_HOME,
  V2_REWARDS,
  V2_AXOMO_SWAGS,
} from '../../../constants/routes';
import underMaintenance from '../../../img/under-maintenance.svg';

import CultureController from '../../../controllers/rewards/CultureController';
import GiftCardsController from '../../../controllers/rewards/GiftCardsController';
import CharityController from '../../../controllers/rewards/CharityController';
import MyRewardsController from '../../../controllers/rewards/MyRewardsController';
import SwagsController from '../../../controllers/rewards/SwagsController';

import useLayoutStore from '../../../stores/layoutStore';
import useRewardsRoutesControllerModel from './useRewardsRoutesControllerModel';
import {
  useDonationFetchQuery,
  useGiftCardFetchQuery,
  useMyRewardsFetchQuery,
  useRewardsFetchQuery,
} from '../../../queries/Rewards';
import { DEFAULT_REWARDS_QUERY_PARAMS } from '../../../Utils/data/rewards';
import GifLoader from '../../../atomic/molecules/GifLoader';
import rewardsLoaderImage from '../../../img/rewards-loader.gif';
import {
  WE_ARE_LOOKING_FOR_THE_BEST_REWARDS,
  HOLD_TIGHT,
} from '../../../languages/en/rewards';
import { Flex, FluidContainer } from '../../../Utils/styles/display';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import { Img } from '../../peopleDirectory/Components/PeopleDirectoryAvatar';
import { UnderMaintenanceMessage, UnderMaintenanceTitle } from './styles';

const RewardsController = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { slugUrl } = useMultiAssemblySlug();

  const {
    isAdmin,
    isCreditStateDeniedByAdmin,
    isCultureRewardsEnabled,
    isGiftCardsEnabled,
    isCharitiesEnabled,
    isChargeable,
    isAxomoSwagsEnabled,
  } = useRewardsRoutesControllerModel();

  const { treatment: disableRewardsTreatment } = useFeatureSplit(
    SplitNames.DisableRewards,
  );

  const isDisableRewardsTreatmentOn =
    disableRewardsTreatment === TreatmentTypes.ON;

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const history = useHistory();
  const location = useLocation();

  if (isCreditStateDeniedByAdmin) {
    history.push(V2_HOME);
  }

  const {
    isInitialLoading: isCultureRewardsLoading,
    data: cultureRewardsData,
  } = useRewardsFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.cultureRewards,
    !isDisableRewardsTreatmentOn,
  );

  const { isInitialLoading: isGiftCardsLoading, data: giftCardsData } =
    useGiftCardFetchQuery(
      DEFAULT_REWARDS_QUERY_PARAMS.giftCards,
      !isDisableRewardsTreatmentOn,
    );

  const {
    isInitialLoading: isCharityRewardsLoading,
    data: charityRewardsData,
  } = useDonationFetchQuery(
    DEFAULT_REWARDS_QUERY_PARAMS.charitySortParams,
    !isDisableRewardsTreatmentOn,
  );

  const { isInitialLoading: isMyRewardsLoading } = useMyRewardsFetchQuery(
    !isDisableRewardsTreatmentOn,
  );

  useEffect(() => {
    if (isRightAsideOpen) {
      toggleRightAsideOpen();
    }
    return () => {
      if (isRightAsideOpen === false && isMobileView === false) {
        toggleRightAsideOpen();
      }
    };
  }, [isMobileView, isRightAsideOpen, toggleRightAsideOpen]);

  const Culture = useCallback(() => {
    return (
      <>
        <Helmet>
          <title>Culture Rewards</title>
        </Helmet>
        <CultureController
          isAdmin={isAdmin}
          isCultureRewardsEnabled={isCultureRewardsEnabled}
        />
      </>
    );
  }, [isAdmin, isCultureRewardsEnabled]);

  const GiftCard = useCallback(() => {
    return (
      <>
        <Helmet>
          <title>Gift Card Rewards</title>
        </Helmet>
        <GiftCardsController
          isAdmin={isAdmin}
          isGiftCardsEnabled={isGiftCardsEnabled}
        />
      </>
    );
  }, [isAdmin, isGiftCardsEnabled]);

  const Charities = useCallback(() => {
    return (
      <>
        <Helmet>
          <title>Charity Rewards</title>
        </Helmet>
        <CharityController
          isAdmin={isAdmin}
          isCharitiesEnabled={isCharitiesEnabled}
        />
      </>
    );
  }, [isAdmin, isCharitiesEnabled]);

  const MyRewards = useCallback(() => {
    return (
      <>
        <Helmet>
          <title>My Redeemed Rewards</title>
        </Helmet>
        <MyRewardsController />
      </>
    );
  }, []);

  const AxomoSwags = useCallback(() => {
    return (
      <>
        <Helmet>
          <title>Swag Rewards</title>
        </Helmet>
        <SwagsController isAxomoSwagsEnabled={isAxomoSwagsEnabled} />
      </>
    );
  }, [isAxomoSwagsEnabled]);

  const hasCultureRewardsSetup = useMemo(() => {
    if (cultureRewardsData && cultureRewardsData.rewards) {
      const activeRewards = cultureRewardsData.rewards.filter(
        (reward) => reward.isActive,
      );
      return activeRewards.length > 0;
    }

    return false;
  }, [cultureRewardsData]);

  const hasGiftCardsSetup = useMemo(() => {
    if (giftCardsData && giftCardsData.rewards) {
      return giftCardsData.rewards.length > 0;
    }

    return false;
  }, [giftCardsData]);

  const hasCharitiesSetup = useMemo(() => {
    if (charityRewardsData && charityRewardsData.rewards) {
      return charityRewardsData.rewards.length > 0;
    }

    return false;
  }, [charityRewardsData]);

  const hideCultureRewards = useMemo(() => {
    return !isAdmin && (!isCultureRewardsEnabled || !hasCultureRewardsSetup);
  }, [hasCultureRewardsSetup, isAdmin, isCultureRewardsEnabled]);

  const hideGiftCards = useMemo(() => {
    return (
      !isAdmin && (!isGiftCardsEnabled || !hasGiftCardsSetup || !isChargeable)
    );
  }, [hasGiftCardsSetup, isAdmin, isChargeable, isGiftCardsEnabled]);

  const hideCharities = useMemo(() => {
    return (
      !isAdmin && (!isCharitiesEnabled || !hasCharitiesSetup || !isChargeable)
    );
  }, [hasCharitiesSetup, isAdmin, isChargeable, isCharitiesEnabled]);

  if (isDisableRewardsTreatmentOn) {
    return (
      <FluidContainer contentPadding="24px">
        <Flex
          width="100%"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Img hide={false} loading="eager" src={underMaintenance} />

          <UnderMaintenanceTitle>
            This page is under maintenance and is temporarily disabled.
          </UnderMaintenanceTitle>
          <UnderMaintenanceMessage>
            Check our{' '}
            <a href="https://www.joinassembly.com/status">status page</a> for
            the latest updates. Please come back again later to redeem your
            rewards!
          </UnderMaintenanceMessage>
        </Flex>
      </FluidContainer>
    );
  }

  if (
    isCultureRewardsLoading ||
    isGiftCardsLoading ||
    isCharityRewardsLoading ||
    isMyRewardsLoading
  ) {
    return (
      <FluidContainer contentPadding="16px">
        <GifLoader
          source={rewardsLoaderImage}
          primaryText={WE_ARE_LOOKING_FOR_THE_BEST_REWARDS}
          secondaryText={HOLD_TIGHT}
        />
      </FluidContainer>
    );
  }

  if (
    !hasCultureRewardsSetup &&
    !hasGiftCardsSetup &&
    !hasCharitiesSetup &&
    !isAdmin
  ) {
    return <Redirect to={V2_HOME} />;
  }

  if (location.pathname === `/${slugUrl}${V2_REWARDS}`) {
    if (isAdmin) {
      history.push(`/${slugUrl}${V2_GIFTCARD_REWARDS}`);
    } else {
      if (hasGiftCardsSetup) {
        history.push(`/${slugUrl}${V2_GIFTCARD_REWARDS}`);
      }

      if (!hasGiftCardsSetup && hasCharitiesSetup) {
        history.push(`/${slugUrl}${V2_CHARITY_REWARDS}`);
      }

      if (!hasGiftCardsSetup && !hasCharitiesSetup && hasCultureRewardsSetup) {
        history.push(`/${slugUrl}${V2_CULTURE_REWARDS}`);
      }
    }
  } else if (!isAdmin) {
    switch (location.pathname) {
      case V2_CULTURE_REWARDS:
        if (hideCultureRewards) {
          history.push(`/${slugUrl}${V2_REWARDS}`);
        }
        break;

      case V2_GIFTCARD_REWARDS:
        if (hideGiftCards) {
          history.push(`/${slugUrl}${V2_REWARDS}`);
        }
        break;

      case V2_CHARITY_REWARDS:
        if (hideCharities) {
          history.push(`/${slugUrl}${V2_REWARDS}`);
        }
        break;

      default:
        break;
    }
  }

  return (
    <FluidContainer
      contentPadding={isMobileView ? '40px 16px 70px' : '16px 16px 70px'}
      contentMargin={isEmbeddedInMainApp && !isMobileView ? '0 88px' : '0'}
    >
      <Switch>
        <Route
          path={`/${slugUrl}${V2_GIFTCARD_REWARDS}/:id?`}
          component={GiftCard}
        />
        <Route
          path={`/${slugUrl}${V2_CHARITY_REWARDS}/:id?`}
          component={Charities}
        />
        <Route
          path={`/${slugUrl}${V2_CULTURE_REWARDS}/:id?`}
          component={Culture}
        />
        <Route
          path={`/${slugUrl}${V2_MY_REWARDS}/:id?`}
          component={MyRewards}
        />
        <Route
          path={`/${slugUrl}${V2_AXOMO_SWAGS}/:id?`}
          component={AxomoSwags}
        />
        <Redirect to={`/${slugUrl}${V2_REWARDS}`} />
      </Switch>
    </FluidContainer>
  );
};

export default RewardsController;
