import { FlowBuilderState } from '../../interfaces/Flow/Builder';

export const emojiSelector = (state: FlowBuilderState) => state.emoji;

export const ownerSelector = (state: FlowBuilderState) => state.owner;

export const flowNameSelector = (state: FlowBuilderState) => state.flowName;

export const templateIdSelector = (state: FlowBuilderState) => state.templateId;

export const shareSheetModalOpenSelector = (state: FlowBuilderState) =>
  state.isShareSheetModalOpen;

export const shareSheetAnonymousToggleSelector = (state: FlowBuilderState) => ({
  anonymousSettings: state.anonymousSettings,
  setAnonymousSettings: state.setAnonymousSettings,
});

export const shareSheetSetAnonymousSettingsSelector = (
  state: FlowBuilderState,
) => state.setAnonymousSettings;

export const templateNameSelector = (state: FlowBuilderState) =>
  state.templateName;

export const pendingEmailsSelector = (state: FlowBuilderState) =>
  state.pendingEmails;

export const newParticipantsToInviteSelector = (state: FlowBuilderState) =>
  state.newParticipantsToInvite;
export const newVisibilityMembersToInviteSelector = (state: FlowBuilderState) =>
  state.newVisibilityMembersToInvite;
export const newContentMembersToInviteSelector = (state: FlowBuilderState) =>
  state.newContentMembersToInvite;

export const setEmojiSelector = (state: FlowBuilderState) => state.setEmoji;

export const blockDataSelector = (state: FlowBuilderState) => state.blockData;

export const descriptionSelector = (state: FlowBuilderState) =>
  state.description;

export const setFlowNameSelector = (state: FlowBuilderState) =>
  state.setFlowName;

export const setPendingEmailsSelector = (state: FlowBuilderState) =>
  state.setPendingEmails;

export const flowIdSelector = (state: FlowBuilderState) => state.flowId;

export const setFlowIdSelector = (state: FlowBuilderState) => state.setFlowId;

export const setNewParticipantsToInviteSelector = (state: FlowBuilderState) =>
  state.setNewParticipantsToInvite;

export const setNewVisibilityMembersToInviteSelector = (
  state: FlowBuilderState,
) => state.setNewVisibilityMembersToInvite;

export const setNewContentMembersToInviteSelector = (state: FlowBuilderState) =>
  state.setNewContentMembersToInvite;

export const isInEditModeSelector = (state: FlowBuilderState) =>
  state.isInEditMode;

export const setIsInEditModeSelector = (state: FlowBuilderState) =>
  state.setIsInEditMode;

export const setOwnerSelector = (state: FlowBuilderState) => state.setOwner;

export const setCollaboratorsSelector = (state: FlowBuilderState) =>
  state.setCollaborators;

export const setDescriptionSelector = (state: FlowBuilderState) =>
  state.setDescription;

export const showDataChangeConfirmSelector = (state: FlowBuilderState) =>
  state.showDataChangeConfirm;

export const templateTypeSelector = (state: FlowBuilderState) =>
  state.templateType;

export const disableNavigationModalSelector = (state: FlowBuilderState) =>
  state.disableNavigationModal;

export const setShowDataChangeConfirmSelector = (state: FlowBuilderState) =>
  state.setShowDataChangeConfirm;

export const setDisableNavigationModalSelector = (state: FlowBuilderState) =>
  state.setDisableNavigationModal;

export const setIsFlowDataChangeConfirmedSelector = (state: FlowBuilderState) =>
  state.setIsFlowDataChangeConfirmed;

export const isInviteNewTeammatesModalOpenSelector = (
  state: FlowBuilderState,
) => state.isInviteNewTeammatesModalOpen;

export const setIsInviteNewTeammatesModalOpenSelector = (
  state: FlowBuilderState,
) => state.setIsInviteNewTeammatesModalOpen;

export const setSpecificBlockDataSelector = (state: FlowBuilderState) =>
  state.setSpecificBlockData;

export const revertBlockDataToDefaultStateSelector = (
  state: FlowBuilderState,
) => state.revertBlockDataToDefaultState;

export const isFlowDataChangeConfirmedSelector = (state: FlowBuilderState) =>
  state.isFlowDataChangeConfirmed;

export const setTemplateIdSelector = (state: FlowBuilderState) =>
  state.setTemplateId;

export const setTemplateNameSelector = (state: FlowBuilderState) =>
  state.setTemplateName;

export const setTemplateTypeSelector = (state: FlowBuilderState) =>
  state.setTemplateType;

export const setCurrentScheduleSelector = (state: FlowBuilderState) =>
  state.setCurrentSchedule;

export const setShareSheetModalOpenSelector = (state: FlowBuilderState) =>
  state.setShareSheetModalOpen;
