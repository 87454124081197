import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import Error from '../Error';
import Loader from '../Loader';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { useGetSinglePostQuery } from '../../../queries/Feed';
import { getFullName } from '../../../Utils/user';
import { getAgoTime } from '../../../Utils/time';
import {
  POST_BY,
  POST_NOT_FOUND,
  POST_SUBHEADER,
} from '../../../languages/en/header';
import { PostTypes } from '../../../interfaces/Home';
import { BIRTHDAY_TEXT, ANNIVERSARY_TEXT } from '../../../languages/en/feed';

const SinglePostHeaderController = () => {
  const { postId } = useParams<{ postId: string }>();
  const query = useGetSinglePostQuery(postId, postId !== 'flow');

  const pageTitle = useMemo(() => {
    if (!query.data) {
      return '';
    }
    if (query.data.type === PostTypes.ANNIVERSARY) {
      return ANNIVERSARY_TEXT;
    }
    if (query.data.type === PostTypes.BIRTHDAY) {
      return BIRTHDAY_TEXT;
    }
    return `${POST_BY} ${query.data.from ? getFullName(query.data.from) : ''}`;
  }, [query]);

  if (query.isInitialLoading || query.isFetching) {
    return <Loader />;
  }

  if (query.isError) {
    return <Error header={POST_NOT_FOUND} subheader={POST_SUBHEADER} />;
  }

  return (
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="post"
      title={pageTitle}
      subTitle={getAgoTime(query.data?.createdAt)}
      showMainFeedLinkInOthers
    />
  );
};

export default SinglePostHeaderController;
