import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import { NameWrapper } from './styles';
import type { ResponseThumbnailProps } from './types';
import { CardBody, CardImage, NewStyledBody, StyledCard } from './newStyles';

export function ResponseErrorDocumentThumbnail(
  props: Omit<ResponseThumbnailProps, 'cardImageUrl'> & {
    cardIconColor?: string;
  },
) {
  const { file, dataTestId, cardColor, cardIcon, cardIconColor } = props;

  return (
    <StyledCard data-testid={dataTestId}>
      <CardImage backgroundColor={cardColor}>
        {cardIcon && (
          <SVGIcon color={cardIconColor} icon={cardIcon} size="32px" />
        )}
      </CardImage>

      <CardBody>
        <NameWrapper>
          <NewStyledBody variant="body2Medium">
            {file.name.substring(0, file.name.lastIndexOf('.'))}
          </NewStyledBody>
          <Body variant="body2Medium">.{file.name.split('.').pop()}</Body>
        </NameWrapper>
        <NewStyledBody variant="body3" color="dustRed6">
          Upload failed
        </NewStyledBody>
      </CardBody>
    </StyledCard>
  );
}
