import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  GIFT_CARD_REWARDS_SEARCH_PLACEHOLDER,
  GIFT_CARD_REWARDS_FILTER_PLACEHOLDER,
  REWARDS_SORT_PLACEHOLDER,
} from '../../../languages/en/rewards';
import {
  giftCardsSortByMenuItems,
  REWARDS_PER_PAGE_DEFAULT,
} from '../../../Utils/data/rewards';
import ThemeV2 from '../../../theme';
import {
  filterByCountry,
  getCountriesMenu,
  getRewardCardsUnderSpecifiedRange,
} from '../../../Utils/rewards';
import { getCountryCode } from '../../../Utils/rewards';
import {
  TextFieldType,
  TextFieldProps,
} from '../../../atomic/molecules/TextField_V2/interface';
import {
  DenominationRangeSelectedByEmployer,
  ExchangeRates,
  GiftCardReward,
} from '../../../queries/Rewards/interfaces';
import useStringSearch from '../../../hooks/useSearchString';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

interface GiftCardRewardsComponentProps {
  rewards: GiftCardReward[];
  onSortChange: (value: unknown) => void;
  defaultCountry: string;
  exchangeRates: ExchangeRates;
  denominationRangeSelectedByEmployer?: DenominationRangeSelectedByEmployer;
}

const GiftCardControllerLogic = (props: GiftCardRewardsComponentProps) => {
  const {
    rewards,
    onSortChange,
    defaultCountry,
    exchangeRates,
    denominationRangeSelectedByEmployer,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const { id: cardId } = useParams<{ id: string }>();
  const rewardCardsUnderSpecifiedRange = getRewardCardsUnderSpecifiedRange(
    rewards,
    exchangeRates,
    denominationRangeSelectedByEmployer,
  );
  const filteredByDefaultCountry = filterByCountry({
    arr: rewardCardsUnderSpecifiedRange,
    countryCode: getCountryCode(defaultCountry),
  });

  const defaultCountryFilter =
    filteredByDefaultCountry.length === 0 ? '' : defaultCountry;

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(REWARDS_PER_PAGE_DEFAULT);
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState(defaultCountryFilter);
  const [sortValue, setSortValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<
    GiftCardReward | undefined
  >();

  let rewardsToDisplay = [...rewardCardsUnderSpecifiedRange];

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsToDisplay, keys: ['brandName'] });

  const { searchResult } = searchModels;

  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  useEffect(() => {
    setFilterValue(defaultCountryFilter);
  }, [defaultCountryFilter]);

  const resetCurrentPage = useCallback(() => {
    setPageNumber(1);
  }, []);

  const onSearchChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
    },
    [resetCurrentPage],
  );

  const onFilterChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setFilterValue(value);
    },
    [resetCurrentPage],
  );

  const onSortChangeHandler = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const countriesMenu = useMemo(() => {
    return getCountriesMenu(rewardCardsUnderSpecifiedRange, defaultCountry);
  }, [rewardCardsUnderSpecifiedRange, defaultCountry]);

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: GIFT_CARD_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: onSearchChangeHandler,
      icon: 'search',
    };
  }, [onSearchChangeHandler, searchValue]);

  const filterProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: GIFT_CARD_REWARDS_FILTER_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'filter',
      value: filterValue,
      onChange: onFilterChangeHandler,
      dropdownItems: countriesMenu,
      isEditable: true,
      inputIconColor: ThemeV2.palette.gray9,
      hasDropdownFilter: true,
    };
  }, [countriesMenu, filterValue, onFilterChangeHandler]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: sortValue,
      onChange: onSortChangeHandler,
      dropdownItems: giftCardsSortByMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [sortValue, onSortChangeHandler]);

  // search logic
  rewardsToDisplay = searchResult;

  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewardCardsUnderSpecifiedRange.find(
        (reward) => reward.brandKey === id,
      );
      if (currentReward) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);
      }
    },
    [rewardCardsUnderSpecifiedRange],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
    history.replace(location.pathname.replace(`/${cardId}`, ''));
  }, [cardId, history, location.pathname]);

  if (filterValue) {
    rewardsToDisplay = filterByCountry({
      arr: rewardsToDisplay,
      countryCode: getCountryCode(filterValue),
    });
  }

  useEffect(() => {
    if (cardId && rewardCardsUnderSpecifiedRange.length > 0) {
      const currentReward = rewardCardsUnderSpecifiedRange.find(
        (reward) => reward.brandKey === cardId,
      );
      if (currentReward && !isModalOpen) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);

        const selectedRewardIndex = rewardCardsUnderSpecifiedRange.findIndex(
          (reward) => reward.brandKey === cardId,
        );

        const selectedPage = Math.floor(selectedRewardIndex / itemsPerPage) + 1;
        setPageNumber(selectedPage);
      } else if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }
  }, [cardId, isModalOpen, itemsPerPage, rewardCardsUnderSpecifiedRange]);

  return {
    pageNumber,
    setPageNumber,
    itemsPerPage,
    setItemsPerPage,
    searchProps,
    filterProps,
    sortProps,
    rewardsToDisplay,
    handleOnRewardSelect,
    isModalOpen,
    selectedReward,
    onCloseRedeemModal,
  };
};

export default GiftCardControllerLogic;
