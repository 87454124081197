import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';

import useTriggerFlowOption from '../../../hooks/useFlowFeedOptions/useTriggerFlowOption';
import useEndOccurrenceOption from '../../../hooks/useFlowFeedOptions/useEndOccurrenceOption';
import {
  useFetchFlowDetailsQuery,
  useGetFlowFeedsQuery,
  useVerifyFlowAuthorization,
} from '../../../queries/Flows/Feed';
import { PostSortOptions } from '../../../interfaces/Feed';
import { allFlowFeedHeaderOptions } from './data';
import useOptions from '../../../hooks/useFlowFeedOptions/useOptions';
import { useFlowViewersSearch } from '../../../hooks/useMembersSearch';
import useArchiveFlowOption from '../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import useRemindParticipantsOption from '../../../hooks/useFlowFeedOptions/useRemindParticipantsOption';
import useLayoutStore from '../../../stores/layoutStore';

import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { trackFeedEvent as trackFeedEventMetric } from '../../../Utils/analytics';
import { FeedEventNameType } from '../../../Utils/analytics/interfaces';
import { getFlowPerms } from '../../../Utils/flows/feeds';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { setShareSheetModalOpenSelector } from '../../../stores/flowBuilderStore/selectors';
import useRepliesSidebar from '../../../hooks/useRepliesSidebar';
import {
  showChatsSelector,
  toggleRightAsideOpenSelector,
} from '../../../stores/layoutStore/selectors';
import { device } from '../../../constants/layout';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { useGetShareRequestsQuery } from '../../../queries/Flows/Sharesheet';
import { FlowAccessRoles } from '../../../queries/Flows/interfaces';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useFlowFeedHeaderControllerLogic = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const {
    isInitialLoading: isFlowDetailsLoading,
    isError,
    data,
  } = useFetchFlowDetailsQuery(flowId);
  const { data: flowBuilderData } = useFetchFlowDetailsQuery(flowId, 'builder');

  const { isInitialLoading: isFlowAuthorizationLoading } =
    useVerifyFlowAuthorization(flowId, isError);

  const { isInitialLoading: isFlowFeedLoading, data: flowFeedsData } =
    useGetFlowFeedsQuery({
      flowId,
      flowFeedsSort: PostSortOptions.DESC,
    });

  const flowDetailsData = data?.data;

  const flowVisibilityCriteria = flowDetailsData?.visibility.criteria;

  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const {
    models: {
      searchedMembers,
      isError: isUsersError,
      value: memberSearchValue,
      isLoading: isUsersLoading,
      isFetching: isUsersFetching,
      hasMoreMembers: hasNextPage,
    },
    operations: {
      fetchMoreMembers: fetchMoreUsers,
      onChange: onMemberSearchChange,
      onClearValue: clearMemberSearchValue,
    },
  } = useFlowViewersSearch(flowId);

  const { canUserCreateFlow } = useGetUserFlowPermissions();

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        flowPerms: getFlowPerms(flowDetailsData),
        flowId: flowDetailsData?.flowId,
        flowName: flowDetailsData?.name,
        templateId: flowDetailsData?.templateId,
      });
    },
    [flowDetailsData],
  );

  useEffect(() => {
    clearMemberSearchValue();
  }, [flowId, clearMemberSearchValue]);

  const users = searchedMembers?.map((member) => {
    return {
      ...member,
      image: member.image,
    } as FeedPostUserProps;
  });

  const handleMemberSearchChange = useCallback(
    (value) => {
      onMemberSearchChange(value);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWERS_SEARCHED);
    },
    [onMemberSearchChange, trackFeedEvent],
  );

  const {
    models: {
      participants,
      dueDateValue,
      occurrenceValue,
      hasMoreMembers,
      participantsCount,
      isTriggerFlowLoading,
      isTriggerFlowModalOpen,
      totalPendingMembers: pendingMembersParticipantCount,
    },
    operations: {
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      setIsTriggerFlowModalOpen,
      fetchMoreMembers,
      handleTriggerFlowButtonClick,
    },
  } = useTriggerFlowOption(flowId);

  const openRunNowModal = () => {
    setIsTriggerFlowModalOpen();
  };

  const { isFlowsFeedFilterApplied } = useGetParsedFilterOptions();

  const {
    operations: {
      handleEditFlow,
      handleCopyFlowFeedLink,
      handleDuplicateFlowClick,
      handleEditNotificationSettings,
      handleEnterFullScreenClick,
      handleShareAsTemplateClick,
      handleShowActivityClick,
      handleHideActivityClick,
      handleAboutIconClick,
      handleFilterFlowFeedClick,
    },
  } = useOptions();

  const {
    models: { isEndOccurrenceModalOpen, isEndOccurrenceModalLoading },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  } = useEndOccurrenceOption();

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const {
    models: { isRemindParticipantsModalOpen, isRemindParticipantsLoading },
    operations: {
      setRemindParticipantsModalClose,
      setRemindParticipantsModalOpen,
      handleRemindParticipantsButtonClick,
    },
  } = useRemindParticipantsOption();

  const { reset } = useRepliesSidebar();

  const setShowChats = useLayoutStore(showChatsSelector);
  const setRightAsideOpen = useLayoutStore(toggleRightAsideOpenSelector);
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const handleFlowSummariesActionClick = () => {
    if (flowDetailsData) {
      const { icon, name } = flowDetailsData;

      window.parent.postMessage(
        {
          type: 'OPEN_POPOUT_DRAWER',
          payload: {
            icon,
            flowId,
            name,
          },
        },
        '*',
      );
    }
  };

  // Options
  const handleMenuOptionOnClick = (value: string | number) => {
    if (value === allFlowFeedHeaderOptions.filterFlowFeed.id) {
      handleFilterFlowFeedClick();
    }

    if (value === allFlowFeedHeaderOptions.aboutThisFlow.id) {
      handleAboutIconClick();
    }

    if (value === allFlowFeedHeaderOptions.endOccurrence.id) {
      setIsEndOccurrenceModalOpen();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.OCCURRENCE_ENDED);
    }

    if (value === allFlowFeedHeaderOptions.shareAsTemplate.id) {
      handleShareAsTemplateClick(flowDetailsData?.flowId, flowBuilderData);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.SHARED_AS_TEMPLATE);
    }

    if (value === allFlowFeedHeaderOptions.duplicateFlow.id) {
      handleDuplicateFlowClick(flowDetailsData?.flowId);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.DUPLICATE_FLOW);
    }

    if (value === allFlowFeedHeaderOptions.editNotificationSettings.id) {
      handleEditNotificationSettings();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.EDIT_NOTIFICATIONS_CLICKED);
    }

    if (value === allFlowFeedHeaderOptions.archiveFlow.id) {
      setArchiveModalOpen();
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_ARCHIVED);
    }

    if (value === allFlowFeedHeaderOptions.hideActivity.id) {
      handleHideActivityClick(flowDetailsData?.flowId);
    }

    if (value === allFlowFeedHeaderOptions.showActivity.id) {
      handleShowActivityClick(flowDetailsData?.flowId);
    }

    if (value === allFlowFeedHeaderOptions.enterFullScreen.id) {
      handleEnterFullScreenClick(flowDetailsData?.flowId);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_ENTERED);
    }

    if (value === allFlowFeedHeaderOptions.editFlow.id) {
      handleEditFlow(flowDetailsData?.flowId);
    }

    if (value === allFlowFeedHeaderOptions.summarizeFlow.id) {
      handleFlowSummariesActionClick();
    }
  };

  const onRunNowButtonClick = () => {
    handleTriggerFlowButtonClick(flowId);
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_RAN);
  };

  const hasActiveOccurrence = Boolean(
    flowDetailsData
      ? 'hasAnyOccurrences' in flowDetailsData
        ? flowDetailsData.hasAnyOccurrences
        : flowDetailsData?.occurrence.activeOccurrence?.occurrenceId
      : false,
  );

  const openRemindParticipantsModal = () => {
    setRemindParticipantsModalOpen();
  };

  const onEndOccurrenceClick = () => {
    handleEndOccurrenceButtonClick(flowId);
  };

  const onRemindParticipantsClick = () => {
    const occurrenceId =
      flowDetailsData?.occurrence.activeOccurrence?.occurrenceId;
    handleRemindParticipantsButtonClick(flowId, occurrenceId);
    trackFeedEvent(FEED_ANALYTICS_EVENTS.PARTICIPANTS_REMINDED);
  };

  const onMemberClick = useCallback(
    ({ memberID, name }) => {
      if (memberID && name) {
        reset();
        setShowChats(true);
        setMemberAndToggleRightAsideOpen({
          memberId: memberID,
          firstName: name,
        });
      }
      trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWER_PROFILE_CLICKED);
    },
    [reset, setMemberAndToggleRightAsideOpen, setShowChats, trackFeedEvent],
  );

  const trackFlowFeedMenuOpened = useCallback(() => {
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FLOW_FEED_MENU_OPENED);
  }, [trackFeedEvent]);

  const trackViewersHovered = useCallback(() => {
    trackFeedEvent(FEED_ANALYTICS_EVENTS.VIEWERS_HOVERED);
  }, [trackFeedEvent]);

  const history = useHistory();
  const { search } = useLocation();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const handleShareSheetModalOpen = useCallback(
    (value: boolean) => {
      if (isMobileView) {
        const params = new URLSearchParams(search);
        params.set('showShareSheet', 'true');

        history.replace({
          search: params.toString(),
        });

        setRightAsideOpen();
      }
      setShareSheetModalOpen(value);
    },
    [history, isMobileView, search, setShareSheetModalOpen],
  );

  const [isRequestAccessModalOpen, setRequestAccessModalOpen] = useState(false);

  const handleRequestAccessModalOpen = (value: boolean) => {
    setRequestAccessModalOpen(value);
  };

  const isFlowEditor =
    flowDetailsData?.isFlowOwner ||
    Boolean(
      flowDetailsData?.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
    ) ||
    false;

  const { data: requestedUsers, isInitialLoading: isShareRequestsLoading } =
    useGetShareRequestsQuery(flowId, 20, isFlowEditor);
  const requestedUsersCount = requestedUsers?.pages[0].total || 0;

  const hasOnlyGifBlocks = flowBuilderData?.data.action?.blocks?.every(
    (block) => block.type === 'GIF',
  );

  return {
    models: {
      isLoading:
        isFlowDetailsLoading || isFlowAuthorizationLoading || isFlowFeedLoading,
      isError,
      data: flowDetailsData,
      isTriggerFlowModalOpen,
      dueDateValue,
      occurrenceValue,
      participants,
      participantsCount,
      feedData: flowFeedsData,
      flowVisibilityCriteria,
      hasMoreMembers,
      isTriggerFlowLoading,
      isEndOccurrenceModalOpen,
      isEndOccurrenceModalLoading,
      isArchiveModalOpen,
      isArchiveFlowLoading,
      isUsersError,
      isUsersLoading: isUsersLoading || isUsersFetching,
      hasNextPage,
      memberSearchValue,
      users,
      isRemindParticipantsModalOpen,
      isRemindParticipantsLoading,
      hasActiveOccurrence,
      hasOnlyGifBlocks,
      pendingMembersParticipantCount,
      isFlowsFeedFilterApplied,
      isMobileView,
      isRequestAccessModalOpen,
      requestedUsersCount,
      isShareRequestsLoading,
      canUserCreateFlow,
    },
    operations: {
      handleMenuOptionOnClick,
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      handleTriggerFlowButtonClick,
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      fetchMoreMembers,
      onRunNowButtonClick,
      onEndOccurrenceClick,
      handleOnArchiveClick,
      setArchiveModalClose,
      fetchMoreUsers,
      handleMemberSearchChange,
      setRemindParticipantsModalClose,
      onRemindParticipantsClick,
      onMemberClick,
      openRunNowModal,
      openRemindParticipantsModal,
      trackFlowFeedMenuOpened,
      trackViewersHovered,
      handleShareSheetModalOpen,
      handleCopyFlowFeedLink,
      handleAboutIconClick,
      handleRequestAccessModalOpen,
      handleFlowSummariesActionClick,
    },
  };
};

export default useFlowFeedHeaderControllerLogic;
