import Body from '../../atoms/Body';
import Link from '../../atoms/Link_V2';
import SVGIcon from '../../atoms/SVGIcon';
import IconButton from '../../atoms/IconButton_V2';
import { AppImage, BannerContainer, OpenAppButton } from './styles';

type AssemblyBannerProps = {
  icon: string;
  imgSrc: string;
  linkUrl: string;
  heading: string;
  subHeading: string;
  buttonText: string;
  onCloseClick: () => void;
};

const AssemblyBanner = (props: AssemblyBannerProps) => {
  const {
    icon,
    linkUrl,
    imgSrc,
    heading,
    buttonText,
    subHeading,
    onCloseClick,
  } = props;
  return (
    <BannerContainer>
      <AppImage src={imgSrc} />
      <div>
        <Body variant="body2Medium">{heading}</Body>
        <Body variant="body3">{subHeading}</Body>
      </div>
      <Link to={linkUrl} openInNewTab isAnchor>
        <OpenAppButton color="primary">{buttonText}</OpenAppButton>
      </Link>
      <IconButton onClick={onCloseClick}>
        <SVGIcon icon={icon} size="24px" />
      </IconButton>
    </BannerContainer>
  );
};

export default AssemblyBanner;
