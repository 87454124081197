import React from 'react';
import { Badge, Collapse } from '@mui/material';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  NavigationExpandBar,
  StyledBetaIcon,
  StyledIconButton,
  StyledSVGIcon,
  StyledToolTip,
  TextWrapper,
} from './styles';
import { ExpandableListHolderProps } from './types';

const StyledBody = styled(Body)<{
  bgColor: string | undefined;
  textColor: string | undefined;
  centerContent: boolean;
}>`
  ${({ bgColor, centerContent, textColor }) => css`
    ${bgColor && `background-color: ${bgColor}`};
    ${textColor && `color: ${textColor}`};
    padding: 0 8px;
    border-radius: 4px;
    ${centerContent && `margin-left: 20px;`}

    max-width: 180px;
    text-align: left;
    padding-left: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;

const Header = styled(NavigationExpandBar)<{ $isExpanded: boolean }>`
  padding-right: 16px;
  padding-top: 0;
  padding-bottom: ${({ $isExpanded }) => ($isExpanded ? '4px' : '0')};
  justify-content: space-between;
`;

const Container = styled.div`
  padding: 16px 0;
`;

const ExpandableListHolder = (props: ExpandableListHolderProps) => {
  const {
    onClick,
    onMouseEnter,
    onMouseLeave,
    heading,
    isExpanded,
    isHovered = true,
    isBeta,
    addButton,
    arrowIconSize,
    children,
    className,
    totalNotificationsCount,
    titleStyle,
    headerSuffixComponent,
  } = props;

  const [isHoveringTitle, setIsHoveringTitle] = useState(false);

  return (
    <Container className={className}>
      <Header
        $isExpanded={isExpanded}
        onClick={onClick}
        onMouseEnter={() => {
          setIsHoveringTitle(true);
          onMouseEnter?.();
        }}
        onMouseLeave={() => {
          setIsHoveringTitle(false);
          onMouseLeave?.();
        }}
      >
        <TextWrapper>
          {isHovered && (
            <StyledSVGIcon
              icon={isExpanded ? 'carat-up' : 'carat-down'}
              color={ThemeV2.palette.gray8}
              size={arrowIconSize}
            />
          )}
          <StyledBody
            variant={
              !isExpanded && totalNotificationsCount ? 'body1Bold' : 'body1'
            }
            bgColor={titleStyle?.bgColor}
            textColor={titleStyle?.color}
            centerContent={!isHovered}
          >
            {isHovered ? heading : heading[0]}
          </StyledBody>
          {!isExpanded && totalNotificationsCount && (
            <Badge>
              <Body variant="body3" color="gray1">
                {totalNotificationsCount}
              </Body>
            </Badge>
          )}
          {isBeta && <StyledBetaIcon icon="beta-mono" data-testid="betaIcon" />}
        </TextWrapper>
        {isHoveringTitle && headerSuffixComponent}
        {!headerSuffixComponent && addButton && addButton.enabled && (
          <StyledToolTip
            toolTipComponent={
              <StyledIconButton
                icon={addButton.icon || 'union-rounded'}
                color="textWithBackground"
                size={ButtonSize.Small}
                onClick={addButton.onClick}
                dataTestId={addButton.dataTestId}
                dataQaId={addButton.dataQaId}
              />
            }
            position="top"
            positionStrategy="fixed"
          >
            {addButton.text}
          </StyledToolTip>
        )}
      </Header>
      <Collapse in={isExpanded}>{children}</Collapse>
    </Container>
  );
};

export default ExpandableListHolder;
