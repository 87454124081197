import { FLOW_FEED_HEADER_OPTIONS } from '../../../languages/en/flows';

export const allFlowFeedHeaderOptions = {
  copyFlowFeedLink: {
    value: FLOW_FEED_HEADER_OPTIONS.COPY_FLOW_FEED_LINK,
    id: 'copyFlowFeedLink',
    prefixIcon: 'Link',
    isSelected: false,
  },
  hideActivity: {
    value: FLOW_FEED_HEADER_OPTIONS.HIDE_ACTIVITY,
    id: 'hideActivity',
    prefixIcon: 'private',
    isSelected: false,
  },
  showActivity: {
    value: FLOW_FEED_HEADER_OPTIONS.SHOW_ACTIVITY,
    id: 'showActivity',
    prefixIcon: 'eye',
    isSelected: false,
  },
  editNotificationSettings: {
    value: FLOW_FEED_HEADER_OPTIONS.EDIT_NOTIFICATION_SETTINGS,
    id: 'editNotificationSettings',
    prefixIcon: 'settings-2',
    isSelected: false,
  },
  editFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.EDIT_FLOW,
    id: 'editFlow',
    prefixIcon: 'edit',
    isSelected: false,
  },
  runFlowNow: {
    value: FLOW_FEED_HEADER_OPTIONS.RUN_FLOW_NOW,
    id: 'runFlowNow',
    prefixIcon: 'play',
    isSelected: false,
  },
  archiveFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.ARCHIVE_FLOW,
    id: 'archiveFlow',
    prefixIcon: 'archive',
    isSelected: false,
    deleteItem: true,
    disabled: false,
  },
  remindParticipants: {
    value: FLOW_FEED_HEADER_OPTIONS.REMIND_PARTICIPANTS,
    id: 'remindParticipants',
    prefixIcon: 'active-notification',
    isSelected: false,
  },
  endOccurrence: {
    value: FLOW_FEED_HEADER_OPTIONS.END_OCCURRENCE,
    id: 'endOccurrence',
    prefixIcon: 'stop',
    isSelected: false,
  },
  enterFullScreen: {
    value: FLOW_FEED_HEADER_OPTIONS.ENTER_FULL_SCREEN,
    id: 'enterFullScreen',
    prefixIcon: 'enter-full-screen',
    isSelected: false,
  },
  duplicateFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.DUPLICATE_FLOW,
    id: 'duplicateFlow',
    prefixIcon: 'copy',
    isSelected: false,
  },
  shareAsTemplate: {
    value: FLOW_FEED_HEADER_OPTIONS.SHARE_AS_TEMPLATE,
    id: 'shareAsTemplate',
    prefixIcon: 'sharing-idea',
    isSelected: false,
  },
  aboutThisFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.ABOUT_THIS_FLOW,
    id: 'aboutThisFlow',
    prefixIcon: 'info-outlined',
    isSelected: false,
  },
  filterFlowFeed: {
    value: FLOW_FEED_HEADER_OPTIONS.FILTER_FLOW_FEED,
    id: 'filterFlowFeed',
    prefixIcon: 'filter',
    isSelected: false,
  },
  removeFromFolder: {
    value: FLOW_FEED_HEADER_OPTIONS.REMOVE_FROM_FOLDER,
    id: 'removeFromFolder',
    prefixIcon: 'close',
    isSelected: false,
  },
  summarizeFlow: {
    value: FLOW_FEED_HEADER_OPTIONS.SUMMARIZE_FLOW,
    id: 'summarizeFlow',
    prefixIcon: 'sparkles',
    isSelected: false,
  },
};
