import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FlowBuilderBlockTypes } from '../../../../interfaces/Flow/Builder';

import { getIsScheduledFlowEnded } from '../../../../Utils/flows';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { useGetFlowTemplate } from '../../../../queries/Flows/Template';
import {
  useFetchFlowDetailsQuery,
  useGetFlowFeedsQuery,
} from '../../../../queries/Flows/Feed';
import { visibilityTypes } from '../../FlowsBuilderVisibilityController/data';
import useGetFlowDetailsFromTemplate from '../../../../hooks/useGetFlowDetailsFromTemplate';

import { mapCriteriaResponseToBlockData } from '../../../../Utils/flows/builder/utils';

import {
  setSpecificBlockDataSelector,
  revertBlockDataToDefaultStateSelector,
  ownerSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import { CriteriaResponse } from '../../../../queries/Flows/interfaces';
import { usePopulateFlowDetailsToStore } from '../../../../hooks/usePopulateFlowDetailsToStore';
import { setFlowBannerStatus } from '../../../../Utils/flows/feeds';
import { PostSortOptions } from '../../../../interfaces/Feed';

const useFlowBuilderEdit = () => {
  const { search, state } = useLocation<{ flowIdToDuplicate: string }>();
  const flowIdToDuplicate = state?.flowIdToDuplicate;

  const isScheduledFlowCreatedUsingOneClick =
    search.includes('setSchedule=true');
  const { flowId } = useParams<{ flowId: string }>();
  const [template, setTemplate] = useState('');

  const { data: templateData } = useGetFlowTemplate(template);
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId || flowIdToDuplicate,
    'builder',
  );
  const { data: flowFeedsData } = useGetFlowFeedsQuery({
    flowId,
    flowFeedsSort: PostSortOptions.DESC,
  });
  const { populateFlowDetailsFromAPI } = usePopulateFlowDetailsToStore(
    flowId || flowIdToDuplicate,
    isScheduledFlowCreatedUsingOneClick,
    Boolean(flowIdToDuplicate),
  );

  const owner = useFlowBuilderStore(ownerSelector);

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const revertBlockDataToDefaultState = useFlowBuilderStore(
    revertBlockDataToDefaultStateSelector,
  );

  // eslint-disable-next-line
  useEffect(() => revertBlockDataToDefaultState(), [flowDetails, templateData]);

  useEffect(() => {
    setTemplate(new URLSearchParams(search).get('template') || '');
  }, [search]);

  useGetFlowDetailsFromTemplate();

  useEffect(() => {
    if (!flowId) {
      const defaultParticipantsCriteria: CriteriaResponse = {
        criteria: {
          custom: {
            rules: [
              {
                condition: 'or',
                rules: [
                  {
                    field: 'member',
                    value: [owner[0]?.id || ''],
                    operator: 'is',
                  },
                ],
              },
            ],
            condition: 'or',
          },
        },
      };

      const defaultVisibilityCriteria: CriteriaResponse = {
        criteria: {
          onlyOwnersAndCollaborators: true,
        },
      };

      setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
        errors: null,
        participantsCriteria: mapCriteriaResponseToBlockData(
          defaultParticipantsCriteria,
          true,
        ),
      });

      setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
        errors: null,
        custom: true,
        everyone: false,
        onlyOwnersAndCollaborators: false,
        onlyParticipants: false,
        type: visibilityTypes.CUSTOM,
        criteriaGroups: mapCriteriaResponseToBlockData(
          defaultVisibilityCriteria,
          true,
        ),
      });
    }
  }, [flowId, owner, setSpecificBlockData]);

  useEffect(() => {
    if (location.pathname.includes('editor')) {
      setFlowBannerStatus(flowId, { showBanner: false });
    }
  }, [flowId, search]);

  useEffect(() => {
    populateFlowDetailsFromAPI();
  }, [populateFlowDetailsFromAPI]);

  const isScheduledFlowEnded = getIsScheduledFlowEnded({
    isFlowOwner: flowDetails?.data.shortcut,
    kind: flowDetails?.data.kind,
    hasActiveOccurrence: flowDetails?.data.hasActiveOccurrence,
    schedule: flowDetails?.data.schedule,
  });

  return {
    flowId,
    flowIdToDuplicate,
    hasFeedData:
      flowFeedsData && flowFeedsData.pages
        ? flowFeedsData.pages[0].data.length > 0
        : false,
    hasActiveOccurrence: !flowIdToDuplicate
      ? flowDetails?.data
        ? 'hasAnyOccurrences' in flowDetails.data
          ? (flowDetails.data.hasAnyOccurrences as boolean)
          : flowDetails?.data.hasActiveOccurrence
        : false
      : false,
    isScheduledFlowEnded,
    isScheduledFlowCreatedUsingOneClick,
    resetFlowDetails: populateFlowDetailsFromAPI,
    flowOwner: flowDetails?.data.owner,
    collaborators: flowDetails?.data.collaborators,
  };
};

export default useFlowBuilderEdit;
