import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';

import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';

const useStyles = makeStyles({
  root: (props: FilterSearchInputProps) => ({
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    background: props.disabled ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1,
    borderRadius: '4px',
    border: props.isFocused
      ? `1px solid ${ThemeV2.palette.geekBlue6}`
      : `1px solid ${ThemeV2.palette.gray5}`,
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontStyle: 'normal',
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    padding: '12px 76px 12px 16px',
    boxSizing: 'border-box',
    pointerEvents: props.disabled ? 'none' : 'auto',
    transition: 'all .3s',
    '&:focus': {
      outline: '0',
    },
    '&:hover': {
      borderColor: props.isFocused
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.geekBlue5,
    },
    boxShadow: props.isFocused ? '0px 0px 4px rgba(24, 144, 255, 0.5)' : 'none',
  }),
  wrapper: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    marginLeft: '8px',
    '&::-webkit-scrollbar': {
      width: 'thin',
      height: '0',
    },
  },
  userName: {
    backgroundColor: ThemeV2.palette.geekBlue1,
    borderRadius: '2px',
    padding: '0 4px',
    marginRight: '4px',
  },
  keywords: {
    marginRight: '4px',
  },
  placeholder: {
    marginLeft: '8px',
    userSelect: 'none',
  },
});

export interface FilterSearchFormValues {
  from?: string[];
  to?: string[];
  keywords?: string[];
  startDate?: string;
  endDate?: string;
  minCurrency?: string;
  maxCurrency?: string;
}

interface FilterSearchInputProps {
  formValues: FilterSearchFormValues;
  placeholder?: string;
  disabled: boolean;
  icon: string;
  onClick?: () => void;
  isFocused: boolean;
  currencyNamePlural: string;
}

interface BodyProps {
  variant: string;
  inline?: boolean;
  color?: string;
  className?: string;
  children: string;
}

const RenderBody = (props: BodyProps) => {
  const { variant, inline, color, className, children } = props;
  return (
    <Body
      variant={variant}
      inline={!!inline}
      color={color}
      className={className}
    >
      {children}
    </Body>
  );
};

const FilterSearchInput = (props: FilterSearchInputProps) => {
  const classes = useStyles(props);
  const { formValues, placeholder, icon, onClick, currencyNamePlural } = props;
  const { from, to, keywords, startDate, endDate, minCurrency, maxCurrency } =
    formValues;

  const renderComponent = useCallback(() => {
    if (
      from ||
      to ||
      keywords ||
      startDate ||
      endDate ||
      minCurrency ||
      maxCurrency
    ) {
      return (
        <div className={classes.wrapper}>
          {from && from.length > 0 ? (
            <>
              <Body inline variant="body2Bold">
                from:{' '}
              </Body>
              {from.map((item) => (
                <RenderBody
                  key={item}
                  variant="body2"
                  inline
                  color="geekBlue6"
                  className={classes.userName}
                >
                  {item}
                </RenderBody>
              ))}
            </>
          ) : null}
          {to && to.length > 0 ? (
            <>
              <Body inline variant="body2Bold">
                to:{' '}
              </Body>
              {to.map((item) => (
                <RenderBody
                  key={item}
                  variant="body2"
                  inline
                  color="geekBlue6"
                  className={classes.userName}
                >
                  {item}
                </RenderBody>
              ))}
            </>
          ) : null}
          {keywords && keywords.length > 0 ? (
            <>
              {keywords.map((item) => (
                <RenderBody
                  key={item}
                  variant="body2"
                  inline
                  className={classes.keywords}
                >
                  {item}
                </RenderBody>
              ))}
            </>
          ) : null}
          {startDate && (
            <>
              <Body inline variant="body2Bold">
                startdate:{' '}
              </Body>
              <Body variant="body2" inline>
                {startDate}&nbsp;
              </Body>
            </>
          )}
          {endDate && (
            <>
              <Body inline variant="body2Bold">
                enddate:{' '}
              </Body>
              <Body variant="body2" inline>
                {endDate}&nbsp;
              </Body>
            </>
          )}
          {minCurrency && (
            <>
              <Body inline variant="body2Bold">
                {`min${currencyNamePlural}: `}
              </Body>
              <Body variant="body2" inline>
                {minCurrency}&nbsp;
              </Body>
            </>
          )}
          {maxCurrency && (
            <>
              <Body inline variant="body2Bold">
                {`max${currencyNamePlural}: `}
              </Body>
              <Body variant="body2" inline>
                {maxCurrency}&nbsp;
              </Body>
            </>
          )}
        </div>
      );
    }
    return (
      <>
        <Body variant="body2" color="gray6" className={classes.placeholder}>
          {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore  */}
          {placeholder}
        </Body>
      </>
    );
  }, [
    classes.keywords,
    classes.placeholder,
    classes.userName,
    classes.wrapper,
    currencyNamePlural,
    endDate,
    from,
    keywords,
    maxCurrency,
    minCurrency,
    placeholder,
    startDate,
    to,
  ]);

  return (
    <div
      className={classes.root}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <SVGIcon
        icon={icon}
        size="18px"
        color={ThemeV2.palette.gray6}
        data-testid="svgIcon"
      />
      {renderComponent()}
    </div>
  );
};

const formValuesDefault = {
  from: null,
  to: null,
  keywords: null,
  startDate: null,
  endDate: null,
  minCurrency: null,
  maxCurrency: null,
};

FilterSearchInput.defaultProps = {
  formValues: formValuesDefault,
  disabled: false,
  isFocused: false,
  icon: 'magnify-thick-handle',
};

export default FilterSearchInput;
