import React from 'react';
import { AvatarProps } from '../../../../atoms/Avatar/interfaces';
import Avatar from '../../../../atoms/Avatar';
import ToolTip from '../../../ToolTipV2';
import { isEmpty } from '../../../../../Utils/common';
import { UNASSIGNED } from '../../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../../../Utils/text';
import { TaskItemAssigneeWrapper } from '../styles';
import SVGIcon from '../../../../atoms/SVGIcon';
import { UNASSIGNED_MEMBER_ID } from '../../../../../constants/notebook';
import { Assignee } from '../../../../../interfaces/notebook';
import { ASSIGNEE_ME } from '../../../../../languages/en/notebook';
import { MemberState } from '../../../../../interfaces/user';

type TaskItemAssigneeProps = AvatarProps & {
  assignedTo: Assignee;
  currentUserId: string;
};

const TaskItemAssignee = ({
  assignedTo,
  currentUserId,
}: TaskItemAssigneeProps) => {
  return (
    <TaskItemAssigneeWrapper data-testid="avatar-wrapper">
      <ToolTip
        position="top"
        toolTipComponent={
          <>
            {assignedTo?.memberID === UNASSIGNED_MEMBER_ID ||
            assignedTo === null ? (
              <SVGIcon icon="unassigned" size="24px" />
            ) : (
              <Avatar
                img={assignedTo?.image}
                name={assignedTo?.name}
                userId={assignedTo?.memberID}
                size="24px"
                icon={
                  assignedTo?.memberState === MemberState.PENDING
                    ? 'pending-person'
                    : ''
                }
              />
            )}
          </>
        }
      >
        {`${
          isEmpty(assignedTo?.name)
            ? capitalizeFirstLetter(UNASSIGNED)
            : capitalizeFirstLetter(assignedTo?.name)
        } ${currentUserId === assignedTo?.memberID ? ASSIGNEE_ME : ''}`}
      </ToolTip>
    </TaskItemAssigneeWrapper>
  );
};

export default TaskItemAssignee;
