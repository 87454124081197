import React, { useState, useEffect } from 'react';

import FilterController from './FilterController';
import { Wrapper } from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { RightDrawerHeader } from '../../../atomic/molecules/RightDrawerHeader';
import useLayoutStore from '../../../stores/layoutStore';
import { KNOWLEDGE_CENTER_TITLE } from '../../../languages/en/header';
import { shallow } from 'zustand/shallow';

const KnowledgeCenterRightDrawerController = () => {
  const [, setIsFiltered] = useState(false);
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  useEffect(() => {
    setIsFiltered(isMainFeedFilterApplied);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      {isMobileView && (
        <RightDrawerHeader
          title={KNOWLEDGE_CENTER_TITLE}
          icon={'file-browsing'}
          onCloseClick={toggleRightAsideOpen}
        />
      )}
      <FilterController />
    </Wrapper>
  );
};

export default KnowledgeCenterRightDrawerController;
