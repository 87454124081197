import { ReactNode } from 'react';
import { Redirect } from 'react-router-dom';

import { getAnswerFlowRoute } from '../../../Utils/flows';
import PrimaryLayout from '../../../atomic/templates/PrimaryLayout';
import usePrimaryLayoutController from './usePrimaryLayoutController';
import ConfirmationModal from '../../../atomic/molecules/Notebook/ConfirmationModal';
import useUpdateTaskDeletedSocket from '../../../hooks/useUpdateTaskDeletedSocket';
import InviteModalController from '../../InviteModalController';
import TemplateGalleryController from '../../flows/TemplateGalleryController';
import FeedbackSurveyModal from '../../../atomic/organism/FeedbackSurveyModal';

const PrimaryLayoutController = ({ children }: { children: ReactNode }) => {
  const {
    models: {
      isOpen,
      hasCheckbox,
      hasDropdown,
      modalProps,
      isFlowOwner,
      isNotebookView,
      hasTabComponent,
      participationFlow,
      isInviteModalOpen,
      isCreateFlowModalOpen,
      onSecondaryDrawerToggle,
      isEmbeddedInMainApp,
      isFeedbackSurveyModalOpen,
    },
    operations: {
      setModalData,
      onPrimaryButtonClick,
      onSecondaryButtonClick,
      handleInviteModalCloseClick,
      handleFeedbackSurveyModalCloseClick,
    },
  } = usePrimaryLayoutController();

  useUpdateTaskDeletedSocket();

  if (participationFlow && participationFlow.participationFlowId) {
    const { occurrenceId, participationFlowId: flowId } = participationFlow;
    return (
      <Redirect
        to={getAnswerFlowRoute(flowId, occurrenceId, location.pathname)}
      />
    );
  }

  return (
    <>
      <PrimaryLayout
        isFlowOwner={isFlowOwner}
        onSecondaryDrawerToggle={onSecondaryDrawerToggle}
        isNotebookView={isNotebookView}
        hasTabComponent={hasTabComponent}
      >
        {children}
      </PrimaryLayout>
      {!isEmbeddedInMainApp && (
        <TemplateGalleryController
          isCreateFlowModalOpen={isCreateFlowModalOpen}
        />
      )}
      <InviteModalController
        isModalOpen={isInviteModalOpen}
        closeInviteModal={handleInviteModalCloseClick}
      />
      <ConfirmationModal
        isOpen={isOpen}
        modalProps={modalProps}
        toggleOpen={setModalData}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        hasDropdown={hasDropdown}
        hasCheckbox={hasCheckbox}
      />
      <FeedbackSurveyModal
        isOpen={isFeedbackSurveyModalOpen}
        onModalClose={handleFeedbackSurveyModalCloseClick}
      />
    </>
  );
};

export default PrimaryLayoutController;
