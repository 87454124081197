import { useEffect } from 'react';
import useUpdateTimezone from '../../hooks/useUpdateTimezone';
import { useRunAccountSetupJob } from '../../queries/Onboarding';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useRedirectStore from '../../stores/redirectStore';
import { trackOnboardingActionEvent } from '../../Utils/analytics/onboarding';

const useOnBoardingWithoutOptionsController = () => {
  const {
    mutate: runAccountJobMutate,
    isSuccess,
    data: preJobResponse,
  } = useRunAccountSetupJob();

  // This is to clear the redirect path from store when user
  // logs in to the application via onboard flow
  const clearPath = useRedirectStore((store) => store.clearPath);

  useEffect(() => {
    clearPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: profileInfoQueryData, isInitialLoading: isLoading } =
    useProfileInfoFetchQuery();

  useUpdateTimezone(profileInfoQueryData?.member.timeZone, isLoading, true);

  const params = new URLSearchParams(location.search);
  const canSetManagerFilter = params.get('isManager') === 'yes';

  useEffect(() => {
    trackOnboardingActionEvent({
      completeOnboarding: 'pathSelectionBypass',
    });
    runAccountJobMutate({ onboardingPath: '' });
  }, []);

  return {
    isSuccess,
    preJobResponse: preJobResponse?.data,
    canSetManagerFilter,
  };
};

export default useOnBoardingWithoutOptionsController;
