import { create } from 'zustand';
import { ShareSheetMemberActions } from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';

export type RequestedUsersForAccess = {
  name: string;
  requestId: string;
  memberID: string;
  email: string;
  permission: ShareSheetMemberActions;
  image: string;
  requestStatus: 'PENDING' | 'APPROVED' | 'REJECTED';
  memberState: 'ACTIVE' | 'PENDING';
};

export enum ShareSheetCurrentModalScreen {
  Simple_Rule = 'SIMPLE_RULE',
  Advanced_Rule = 'ADVANCED_RULE',
}

type RequestedUsersForAccessStore = {
  updatedRequestedUserID: string;
  setUpdatedRequestedUserID: (memberID: string) => void;
  requestedUsers: RequestedUsersForAccess[];
  setRequestedUsers: (
    updatedRequestedUsersForAccess: RequestedUsersForAccess[],
  ) => void;
  requestedUsersCount: number;
  setRequestedUsersCount: (count: number) => void;
  canUpdateFlowPermission: boolean;
  setCanUpdateFlowPermission: (canOverride: boolean) => void;
  isModifyAndConditionModalOpen: boolean;
  setModifyAndConditionModalOpen: (isOpen: boolean) => void;
  currentShareSheetModalScreen: ShareSheetCurrentModalScreen;
  setCurrentShareSheetModalScreen: (
    screen: ShareSheetCurrentModalScreen,
  ) => void;
};

const useRequestedUsersForAccessStore = create<RequestedUsersForAccessStore>()(
  (set) => ({
    updatedRequestedUserID: '',
    setUpdatedRequestedUserID: (memberID: string) =>
      set(() => ({ updatedRequestedUserID: memberID })),
    requestedUsers: [],
    setRequestedUsers: (
      updatedRequestedUsersForAccess: RequestedUsersForAccess[],
    ) => set(() => ({ requestedUsers: updatedRequestedUsersForAccess })),
    requestedUsersCount: 0,
    setRequestedUsersCount: (count: number) =>
      set(() => ({ requestedUsersCount: count })),
    canUpdateFlowPermission: false,
    setCanUpdateFlowPermission: (canOverride: boolean) =>
      set(() => ({ canUpdateFlowPermission: canOverride })),
    isModifyAndConditionModalOpen: false,
    setModifyAndConditionModalOpen: (isOpen: boolean) =>
      set(() => ({ isModifyAndConditionModalOpen: isOpen })),
    currentShareSheetModalScreen: ShareSheetCurrentModalScreen.Simple_Rule,
    setCurrentShareSheetModalScreen: (screen: ShareSheetCurrentModalScreen) =>
      set(() => ({ currentShareSheetModalScreen: screen })),
  }),
);

export default useRequestedUsersForAccessStore;
