import { useLayoutEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { RequireExactlyOne } from 'type-fest';
import { Flex } from '../../Utils/styles/display';
import Body from '../atoms/Body';
import SVGIcon from '../atoms/SVGIcon';
import { ErrorCardFileExtension, ErrorCardFileName } from './FileCard/styles';
import type { File } from './FileCard/types';
import ThemeV2 from '../../theme';

type ImageFilesGridProps = {
  files: File[];
  onClickPreview?: (file: File) => void;
};

const ImageThumbnail = styled.img<
  RequireExactlyOne<
    {
      fullSize: boolean;
      imageHeight: number;
    },
    'fullSize' | 'imageHeight'
  >
>`
  ${({ fullSize, imageHeight }) =>
    fullSize
      ? css`
          width: 100%;
        `
      : css`
          height: ${imageHeight}px;
        `}

  border-radius: 8px;

  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.015);
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

const ImageErrorThumbnail = styled(Flex)<
  RequireExactlyOne<
    {
      fullSize: boolean;
      imageHeight: number;
    },
    'fullSize' | 'imageHeight'
  >
>`
  ${({ fullSize, imageHeight }) =>
    fullSize
      ? css`
          width: 100%;
        `
      : css`
          height: ${imageHeight}px;
          width: ${imageHeight}px;
        `}
  border-radius: 8px;
  border: 1px solid ${ThemeV2.palette.gray5};
  transition: all 200ms ease-in-out;
`;

const Grid = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
`;

const ImageErrorThumbnailHeader = styled(Flex)`
  height: 36px;
  width: 100%;
  padding: 8px;
  border-bottom: 1px solid ${ThemeV2.palette.gray5};
`;

const getFileExtensionByFileName = (fileName: string) => {
  return fileName.split('.').slice(-1).pop();
};

const ImageThumbnailErrorCard = ({
  file,
  imageHeight,
}: {
  file: File;
  imageHeight: number;
}) => {
  return (
    <ImageErrorThumbnail flexDirection="column" imageHeight={imageHeight}>
      <ImageErrorThumbnailHeader>
        <ErrorCardFileName inline variant="body3Medium" color="gray9">
          {file.name}
        </ErrorCardFileName>
        <ErrorCardFileExtension inline variant="body3Medium" color="gray9">
          .{getFileExtensionByFileName(file.name)}
        </ErrorCardFileExtension>
      </ImageErrorThumbnailHeader>
      <Flex
        flexDirection="column"
        padding="20px"
        justifyContent="space-evenly"
        flexGrow={1}
      >
        <SVGIcon color={ThemeV2.palette.dustRed6} icon="warning" />
        <Body variant="body3" color="dustRed6">
          Upload failed
        </Body>
      </Flex>
    </ImageErrorThumbnail>
  );
};

function ImageFilesGrid({ files, onClickPreview }: ImageFilesGridProps) {
  const [rowHeight, setRowHeight] = useState(96);

  useLayoutEffect(() => {
    const post = document.querySelector('[data-js-id="flow-feed-post"]');
    const computeLineHeight = () => {
      if (post) {
        const { width } = post.getBoundingClientRect();

        if (width < 380) {
          setRowHeight(96);
        } else if (width >= 380 && width <= 450) {
          setRowHeight(120);
        } else if (width >= 451 && width <= 479) {
          setRowHeight(136);
        } else if (width > 480) {
          setRowHeight(160);
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => computeLineHeight());

    if (post) {
      resizeObserver.observe(post as Element);
    }

    computeLineHeight();

    return () => resizeObserver.disconnect();
  }, [rowHeight]);

  return (
    <Grid>
      {files.map((file) =>
        file.size === 0 ? (
          <ImageThumbnailErrorCard
            key={file.name}
            imageHeight={rowHeight}
            file={file}
          />
        ) : (
          <ImageThumbnail
            imageHeight={rowHeight}
            key={file.name}
            src={file.thumbnails?.[360]}
            onClick={() => onClickPreview?.(file)}
          />
        ),
      )}
    </Grid>
  );
}

export function ImageFiles({ files, onClickPreview }: ImageFilesGridProps) {
  if (files.length === 1) {
    return (
      <ImageThumbnail
        fullSize
        src={files[0].thumbnails?.[360]}
        onClick={() => onClickPreview?.(files[0])}
      />
    );
  }

  return <ImageFilesGrid files={files} onClickPreview={onClickPreview} />;
}
