import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

import Hidden from '@mui/material/Hidden';
import { getFullName } from '../../../Utils/user';

import { Flex } from '../../../Utils/styles/display';
import { isMobilePlatform } from '../../../Utils/window';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import MainFeedLinkButton from '../MainFeedLinkButton';
import { HeaderType, PageHeaderProps } from './interfaces';

import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { NOTEBOOK_HEADER_TITLE } from '../../../languages/en/header';
import useLayoutStore from '../../../stores/layoutStore';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import RouteTabs from '../../organism/RouteTabs';
import {
  LogoContainer,
  RightDrawerButtonWrapper,
  StyledButtonContainer,
  StyledFlex,
  StyledHeading,
  StyledInviteButton,
  StyledLogo,
  StyledPageHeaderMainContent,
} from './styles';
import mobileStore from '../../../stores/mobileAppStore';

const PageHeader = (props: PageHeaderProps) => {
  const {
    headerType,
    title = '',
    imageUrl,
    imageName,
    profile = null,
    subTitle = '',
    icon = '',
    userId,
    showMainFeedLinkInOthers,
    hasBottomBorder = true,
    buttonText,
    pageTabs,
    tabRouteBase,
    onButtonClick,
    isBeta,
    onClick,
    onInviteButtonClick,
    onTemplatesButtonClick,
    canShowTemplateButton,
  } = props;

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { isEmbeddedInMainApp } = useLayoutStore();

  const {
    toggleRightAsideOpen,
    setShowChats,
    setMemberAndToggleRightAsideOpen,
  } = useLayoutStore(
    (state) => ({
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      setShowChats: state.setShowChats,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
    }),
    shallow,
  );

  const Home = useMemo(
    () => (
      <>
        <LogoContainer>
          <StyledLogo src={imageUrl} alt={imageName} />
        </LogoContainer>

        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
        </StyledPageHeaderMainContent>
        <Flex margin="0 8px 0 auto">
          {isMobileView && (
            <RightDrawerButtonWrapper>
              <IconButton
                variant="outlined"
                color="tertiary"
                dataTestId="rightDrawerButton"
                dataQaId="btn-right-drawer"
                icon="filter"
                onClick={toggleRightAsideOpen}
                size={ButtonSize.Medium}
              />
            </RightDrawerButtonWrapper>
          )}

          {canShowTemplateButton && isMobileView && !isMobilePlatform && (
            <Button
              variant="outlined"
              color="primary"
              dataTestId="templatesButton"
              dataQaId="btn-flow-addTemplate"
              onClick={onTemplatesButtonClick}
              padding="8px"
            >
              <SVGIcon icon="cube" />
            </Button>
          )}
          {canShowTemplateButton && !isMobileView && (
            <Button
              variant="outlined"
              color="primary"
              icon="cube"
              dataTestId="templatesButton"
              onClick={onTemplatesButtonClick}
              dataQaId="btn-flow-addTemplate"
            >
              Templates
            </Button>
          )}

          <StyledInviteButton
            variant="outlined"
            color="primary"
            dataTestId="inviteButton"
            dataQaId="btn-invite"
            onClick={onInviteButtonClick}
          >
            Invite
          </StyledInviteButton>
        </Flex>
      </>
    ),
    [
      imageUrl,
      imageName,
      title,
      toggleRightAsideOpen,
      canShowTemplateButton,
      isMobileView,
      onTemplatesButtonClick,
      onInviteButtonClick,
    ],
  );

  const Flows = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <Flex>
            <StyledHeading variant="h6">{title}</StyledHeading>
            {isBeta && <SVGIcon icon="beta" size="32px" />}
          </Flex>
          <Hidden only={['xs']}>
            <Body variant="body3" color="gray7">
              {subTitle}
            </Body>
          </Hidden>
        </StyledPageHeaderMainContent>
        {onButtonClick && isMobileView && (
          <StyledButtonContainer>
            <Button
              icon="union"
              size="medium"
              variant="contained"
              dataTestId="request-flow"
              dataQaId="btn-flow-request"
              onClick={() => onButtonClick()}
            >
              {buttonText}
            </Button>
          </StyledButtonContainer>
        )}
      </>
    ),
    [icon, title, isBeta, subTitle, onButtonClick, isMobileView, buttonText],
  );

  const Profile = useMemo(() => {
    if (profile) {
      const fullName = getFullName(profile);
      return (
        <>
          <Avatar
            userId={userId}
            img={profile.imageUrl}
            name={fullName}
            size="48px"
            fontSize="20px"
          />
          <StyledPageHeaderMainContent>
            <StyledHeading variant="h6">{fullName}</StyledHeading>
            <Body variant="body3" color="gray7">
              @{profile.username}{' '}
              {profile.departments && `• ${profile.departments}`}
            </Body>
          </StyledPageHeaderMainContent>
          {isMobileView && userId ? (
            <Flex margin="0 8px 0 auto">
              <RightDrawerButtonWrapper>
                <IconButton
                  variant="outlined"
                  color="tertiary"
                  dataTestId="rightDrawerButton"
                  dataQaId="btn-right-drawer"
                  icon="info-outlined"
                  onClick={() =>
                    setMemberAndToggleRightAsideOpen({
                      firstName: profile.firstName,
                      memberId: userId,
                    })
                  }
                  size={ButtonSize.Medium}
                />
              </RightDrawerButtonWrapper>
              {mobileStore.getState().isMobileAppV3 ? null : (
                <IconButton
                  variant="outlined"
                  color="tertiary"
                  dataTestId="rightDrawerButton"
                  dataQaId="btn-right-drawer"
                  icon="message"
                  onClick={() => {
                    setShowChats(true);
                    setMemberAndToggleRightAsideOpen({
                      firstName: profile.firstName,
                      memberId: userId,
                    });
                  }}
                  size={ButtonSize.Medium}
                />
              )}
            </Flex>
          ) : (
            <MainFeedLinkButton onClick={onClick} />
          )}
        </>
      );
    }

    return null;
  }, [isMobileView, onClick, profile, toggleRightAsideOpen, userId]);

  const KnowledgeCenter = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
          <Body variant="body3" color="gray7">
            {subTitle}
          </Body>
        </StyledPageHeaderMainContent>
        {showMainFeedLinkInOthers && <MainFeedLinkButton />}
        <Flex justifyContent="right" flexGrow={1}>
          {isMobileView && (
            <RightDrawerButtonWrapper>
              <IconButton
                variant="outlined"
                color="tertiary"
                dataTestId="rightDrawerButton"
                icon="filter"
                onClick={toggleRightAsideOpen}
                size={ButtonSize.Medium}
              />
            </RightDrawerButtonWrapper>
          )}
        </Flex>
      </>
    ),
    [
      icon,
      isMobileView,
      showMainFeedLinkInOthers,
      subTitle,
      title,
      toggleRightAsideOpen,
    ],
  );

  const Others = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
          <Body variant="body3" color="gray7">
            {subTitle}
          </Body>
        </StyledPageHeaderMainContent>
        {showMainFeedLinkInOthers && <MainFeedLinkButton />}
      </>
    ),
    [icon, showMainFeedLinkInOthers, subTitle, title],
  );

  const PeopleDirectory = useMemo(
    () => (
      <>
        <SVGIcon icon={icon} size="48px" />
        <StyledPageHeaderMainContent>
          <StyledHeading variant="h6">{title}</StyledHeading>
          <Body variant="body3" color="gray7">
            {subTitle}
          </Body>
        </StyledPageHeaderMainContent>
        <Flex margin="0 8px 0 auto">
          {!isMobileView && (
            <StyledInviteButton
              icon="add-person-transparent"
              dataTestId="inviteButtonPeopleDirectory"
              dataQaId="btn-invite-people-directory"
              onClick={onInviteButtonClick}
            >
              Invite teammates
            </StyledInviteButton>
          )}
          {isMobileView && !isMobilePlatform && (
            <Button
              color="primary"
              dataTestId="inviteTeammatesButton"
              onClick={onInviteButtonClick}
              padding="8px"
            >
              <SVGIcon icon="add-person-transparent" />
            </Button>
          )}
        </Flex>
      </>
    ),
    [icon, isMobileView, onInviteButtonClick, subTitle, title],
  );

  const renderHeader = useMemo(() => {
    switch (headerType) {
      case HeaderType.MAIN_FEED:
        return Home;
      case HeaderType.PROFILE:
        return Profile;
      case HeaderType.FLOWS:
        return Flows;
      case HeaderType.KNOWLEDGE_CENTER:
        return KnowledgeCenter;
      case HeaderType.PEOPLE_DIRECTORY:
        return PeopleDirectory;
      default:
        return Others;
    }
  }, [
    headerType,
    Home,
    Profile,
    Flows,
    KnowledgeCenter,
    PeopleDirectory,
    Others,
  ]);

  return (
    <>
      <StyledFlex
        borderBottom={hasBottomBorder}
        hasPaddingLeft={!isEmbeddedInMainApp && title === NOTEBOOK_HEADER_TITLE}
        tabRouteBase={tabRouteBase}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
      >
        {renderHeader}
      </StyledFlex>
      {pageTabs && tabRouteBase && (
        <RouteTabs routeTabButtons={pageTabs} urlBase={tabRouteBase} />
      )}
    </>
  );
};

export default PageHeader;
