import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import ThemeV2 from '../../../theme';
import { V2_USER_PROFILE } from '../../../constants/routes';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import { SelectedMember } from '../../../interfaces/Layout';
import { MemberDetails } from '../../../interfaces/MemberDetails';
import Divider from '../../atoms/Divider';
import ProfileDetailsSection from '../../molecules/ProfileDetailsSection';
import ProfileHeaderSection from '../../molecules/ProfileHeaderSection';
import { AboutMeSectionWrapper, DividerWrapper } from './styles';
import Body from '../../atoms/Body';
import TaskItemTextArea from '../../molecules/Notebook/TaskItem/TaskItemTextArea';
import { HandleOnCloseClick } from '../../../controllers/home/ProfileViewController/interfaces';

interface ProfileViewProps {
  isCurrentUser: boolean;
  manager?: {
    firstName: string;
    lastName: string;
    memberId: string;
    profilePicture: string;
  };
  memberDetails: MemberDetails;
  directReportsDetails: MemberDetails[];
  toggleRightAsideOpen: () => void;
  setSelectedMemberProfile: (selectedMember: SelectedMember) => void;
  onCloseClick: HandleOnCloseClick;
  onViewProfileClick: () => void;
  onCopyProfileLinkClick: () => void;
  hideCloseButton?: boolean;
  handleAboutMeChange: (value: string) => void;
}

const ProfileView = ({
  isCurrentUser,
  manager,
  directReportsDetails,
  memberDetails,
  toggleRightAsideOpen,
  setSelectedMemberProfile,
  onCloseClick,
  onViewProfileClick,
  onCopyProfileLinkClick,
  hideCloseButton,
  handleAboutMeChange,
}: ProfileViewProps) => {
  const { slugUrl } = useMultiAssemblySlug();

  const { email, memberId, timeZone, state } = memberDetails;
  const {
    birthday,
    department,
    firstName,
    hiredDay,
    image,
    lastName,
    username,
    jobTitle,
    location,
    workLocation,
    pronouns,
    aboutMe,
    socialProfiles,
  } = memberDetails.profile;
  const currentLocation = useLocation();
  const matched = matchPath<{ userId: string }>(currentLocation.pathname, {
    path: `/${slugUrl}${V2_USER_PROFILE}`,
  });
  const activityFeedMemberId = matched?.params.userId;
  const shouldDisplayViewActivtyLink =
    activityFeedMemberId !== memberDetails.memberId;
  const canShowAboutMeSection = isCurrentUser || Boolean(aboutMe);

  return (
    <>
      <ProfileHeaderSection
        profilePicture={image}
        isCurrentUser={isCurrentUser}
        firstName={firstName}
        lastName={lastName}
        memberId={memberId}
        shouldDisplayViewActivityLink={shouldDisplayViewActivtyLink}
        username={username}
        state={state}
        pronouns={pronouns}
        hideCloseButton={hideCloseButton}
        onCloseClick={onCloseClick}
        onViewProfileClick={onViewProfileClick}
        onCopyProfileLinkClick={onCopyProfileLinkClick}
      />
      {shouldDisplayViewActivtyLink && (
        <DividerWrapper>
          <Divider color={ThemeV2.palette.gray5} isFullWidth />
        </DividerWrapper>
      )}

      {canShowAboutMeSection && (
        <>
          <AboutMeSectionWrapper>
            <Body variant="body2Bold" color="gray10">
              About Me
            </Body>
            {isCurrentUser ? (
              <TaskItemTextArea
                autofocus={false}
                onBlur={handleAboutMeChange}
                handleEnterClick={handleAboutMeChange}
                stopPropagation={false}
                enableShiftNewLine
                hasHoverEffect
                textVariant="body1"
                placeholder="Tell us about yourself…"
                serializedTitle={aboutMe}
                applyHoverStyles
              />
            ) : (
              <Body variant="body1" color="gray9">
                {aboutMe}
              </Body>
            )}
          </AboutMeSectionWrapper>

          <DividerWrapper>
            <Divider color={ThemeV2.palette.gray5} isFullWidth />
          </DividerWrapper>
        </>
      )}

      <ProfileDetailsSection
        username={username}
        birthday={birthday}
        department={department}
        isCurrentUser={isCurrentUser}
        manager={manager}
        timeZone={timeZone}
        jobTitle={jobTitle}
        location={location}
        workLocation={workLocation}
        setSelectedMemberProfile={setSelectedMemberProfile}
        directReportsDetails={directReportsDetails}
        workAnniversary={hiredDay}
        email={email}
        toggleRightAsideOpen={toggleRightAsideOpen}
        pronouns={pronouns}
        socialProfiles={socialProfiles}
      />
    </>
  );
};

export default ProfileView;
