import React, { useRef, useEffect, useMemo, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import ThemeV2 from '../../../theme';
import { trimSpaces } from '../../../Utils/text';
import { variantMap } from '../Body';

import { SentenceInput, InputWrapper, WidthControl } from './styles';
import isEmpty from 'lodash/isEmpty';

export type TextEditorProps = {
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus: boolean;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  dataTestId?: string;
  dataQaId?: string;
  className?: string;
  required?: boolean;
  autoComplete?: boolean;
  spellCheck?: boolean;
  textVariant?: string;
};

const sanitizeConfig = {
  allowedTags: [],
  allowedAttributes: {},
};

const TextEditor = (props: TextEditorProps) => {
  const {
    onFocus,
    onBlur,
    autoFocus = false,
    placeholder,
    value,
    onChange,
    dataTestId,
    dataQaId,
    className,
    required = false,
    spellCheck = true,
    textVariant = 'body1Medium',
  } = props;

  const [isFocused, setFocus] = useState(false);

  const { fontSize, fontWeight, fontStyle, lineHeight } =
    variantMap[textVariant];
  const fontColor = ThemeV2.palette.gray9;
  const palette = ThemeV2.palette as unknown as {
    [Key: string]: string;
  };
  const paletteColor = palette[fontColor];

  const bodyStyle = useMemo(
    () => ({
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight,
      color: paletteColor,
    }),
    [fontSize, fontStyle, fontWeight, lineHeight, paletteColor],
  );

  const inputRef = useRef<null | HTMLTextAreaElement>(null);

  useEffect(() => {
    if ((autoFocus || isFocused) && inputRef !== null) {
      inputRef?.current?.focus();
      if (inputRef?.current?.setSelectionRange) {
        inputRef?.current?.setSelectionRange(
          inputRef.current?.value.length,
          inputRef.current?.value.length,
        );
      }
    }
  }, [autoFocus, isFocused]);

  const disableEnterEvent = (event: any) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 13) {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = false;
      if (event.preventDefault) event.preventDefault();
    }
  };

  const renderEditor = () => {
    if (isFocused) {
      return (
        <SentenceInput
          onFocus={onFocus}
          ref={inputRef}
          className={className}
          placeholder={placeholder}
          value={sanitizeHtml(value, sanitizeConfig).toString()}
          isPlaceHolder={isEmpty(value)}
          onChange={(e: any) => {
            onChange(trimSpaces(e.target.value || ''));
          }}
          onBlur={() => {
            setFocus(false);
            if (onBlur) onBlur();
          }}
          autoFocus={isFocused}
          onKeyDown={disableEnterEvent}
          required={required}
          style={{ ...bodyStyle }}
          spellCheck={spellCheck}
          data-testid={dataTestId}
          data-qa-id={dataQaId}
        />
      );
    }
    return (
      <WidthControl
        style={{ ...bodyStyle }}
        isPlaceHolder={isEmpty(value)}
        required={required}
        data-testid={dataTestId}
        data-qa-id={dataQaId}
      >
        {isEmpty(value)
          ? placeholder
          : sanitizeHtml(value, sanitizeConfig).toString()}
      </WidthControl>
    );
  };

  return (
    <InputWrapper
      onClick={() => {
        setFocus(true);
      }}
      className="text-editor"
    >
      {renderEditor()}
    </InputWrapper>
  );
};

export default TextEditor;
