import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  GET_FLOW_FEED,
  GET_MAIN_FEED,
  GET_PROFILE_FEED,
} from '../../constants/endpoints';
import { pusher } from '../../pusher/pusher-base';
import { useProfileInfoFetchQuery } from '../../queries/Profile';

const useUpdateTaskDeletedSocket = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();
  useEffect(() => {
    if (data) {
      const { assemblyId } = data.assembly;
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );
      channelAssembly.bind('TASK_DELETED', () => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries([GET_PROFILE_FEED]);
        queryClient.invalidateQueries([GET_FLOW_FEED]);
      });
      return () => {
        channelAssembly.unbind('TASK_DELETED');
      };
    }
  }, [data, queryClient]);
};

export default useUpdateTaskDeletedSocket;
