import styled from 'styled-components';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import Clickable_V2 from '../../atoms/Clickable_V2';

export const IconHolder = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  max-width: 44px;
  height: 44px;
  min-width: 44px;
  min-height: 44px;
  border-radius: 4px;
  background: ${({ isSelected }) =>
    isSelected ? ThemeV2.palette.geekBlue1 : ThemeV2.palette.gray3};
  margin-right: 12px;
`;

export const StyledContentWrapper = styled.button<{
  isSelected: boolean | undefined;
}>`
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
  background: ${ThemeV2.palette.gray1};
  padding: 8px 12px;
  border-radius: 4px;
  div {
    color: ${({ isSelected }) => isSelected && `${ThemeV2.palette.geekBlue6}`};
  }
  :hover,
  &:focus-visible {
    background: ${ThemeV2.palette.geekBlue1};
    ${IconHolder} {
      background: ${ThemeV2.palette.geekBlue2};
    }
    div {
      color: ${ThemeV2.palette.geekBlue6};
    }
  }

  :active {
    background: ${ThemeV2.palette.geekBlue2};
    div {
      color: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledBody = styled(Body)`
  padding-left: 28px;
`;

export const StyledClickable = styled(Clickable_V2)`
  display: block;
  width: 100%;
  text-align: left;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 12px;
  margin-top: 4px;
`;
