import Body from '../../../atomic/atoms/Body';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import { FLOWS_UNARCHIVE_MODAL } from '../../../languages/en/flows';
import { UNARCHIVE } from '../../../languages/en/singleWords';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';

export const UnarchiveFlowsModal = ({
  isArchiveModalOpen,
  isUnArchiveModalLoading,
  onModalClose,
  onUnArchiveClick,
  unArchivingFlowDetails,
  flowItem,
  hideCloseButton = false,
}: {
  isArchiveModalOpen: boolean;
  isUnArchiveModalLoading: boolean;
  onModalClose: () => void;
  onUnArchiveClick: () => void;
  unArchivingFlowDetails: LeftDrawerCollapsibleNavItemProps | undefined;
  flowItem?: LeftDrawerCollapsibleNavItemProps | undefined;
  hideCloseButton?: boolean;
}) => {
  return (
    <BasicModalTemplate
      isOpen={isArchiveModalOpen}
      heading={FLOWS_UNARCHIVE_MODAL.MODAL_HEADING}
      alignActionButtons="right"
      primaryButton={{
        text: `${FLOWS_UNARCHIVE_MODAL.UNARCHIVE_LABEL}`,
        onClick: () => {
          onUnArchiveClick();
          if (flowItem)
            trackLeftDrawerActionEvent({
              action:
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVED,
              ...flowItem,
            });
        },
        isLoading: isUnArchiveModalLoading,
      }}
      secondaryButton={{
        text: `${FLOWS_UNARCHIVE_MODAL.CANCEL_LABEL}`,
        onClick: () => {
          onModalClose();
          if (flowItem)
            trackLeftDrawerActionEvent({
              action:
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVE_CANCELLED,
              ...flowItem,
            });
        },
      }}
      onModalClose={() => {
        onModalClose();
        if (flowItem)
          trackLeftDrawerActionEvent({
            action:
              LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_UNARCHIVE_CANCELLED,
            ...flowItem,
          });
      }}
      hideCloseIcon={hideCloseButton}
    >
      {unArchivingFlowDetails ? (
        <Body variant="body2">
          {UNARCHIVE + ' '}
          <EmoticonWithText
            text={unArchivingFlowDetails?.label}
            emoticon={unArchivingFlowDetails?.emoticon || ''}
            hasBoldText
          />
          {FLOWS_UNARCHIVE_MODAL.MODAL_CONTENT_TEXT}
        </Body>
      ) : null}
    </BasicModalTemplate>
  );
};
