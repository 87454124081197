import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { parse } from 'qs';
import { AxiosError } from 'axios';

import useTriggerFlowOption from '../../../../hooks/useFlowFeedOptions/useTriggerFlowOption';

import {
  MemberInteractionType,
  PostSortOptions,
  PostSortValues,
} from '../../../../interfaces/Feed';

import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';

import {
  useGetFlowFeedsQuery,
  useFetchFlowDetailsQuery,
  useUpdateFlowReactionMutation,
  useUpdateScheduleCardStatus,
  useEntityPermissionsQuery,
} from '../../../../queries/Flows/Feed';

import useModalsStore from '../../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';
import useLayoutStore from '../../../../stores/layoutStore';
import { getErrorStatusCode } from '../../../../Utils/message';
import useToggle from '../../../../hooks/useToggle';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import {
  FEED_ANALYTICS_EVENTS,
  SHARE_SHEET_EVENTS,
} from '../../../../Utils/analytics/constants';
import useCommonFeedSocket from '../../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../../hooks/useCommonFeedSocket/types';
import { getAnswerFlowRoute } from '../../../../Utils/flows';
import { BUILDER_FLOW_CREATED_MODAL } from '../../../../languages/en/flows/builder';
import { FLOWS, FLOWS_FEEDS, V2_HOME } from '../../../../constants/routes';
import useArchiveFlowOption from '../../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { trackFeedEvent as trackFeedEventMetric } from '../../../../Utils/analytics';
import { FeedEventNameType } from '../../../../Utils/analytics/interfaces';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  blockDataSelector,
  setShareSheetModalOpenSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import { trackShareSheetActionEvent } from '../../../../Utils/analytics/shareSheet';
import { useCanvasConfetti } from '../../../../hooks/useCanvasConfetti';
import useFlowFeed from '../../../../hooks/useFlowFeed';
import {
  getFlowBannerStatus,
  setFlowBannerStatus,
  updateFlowBannerViewCount,
} from '../../../../Utils/flows/feeds';
import { CreationSource } from '../../../../queries/Flows/interfaces';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import useRepliesSidebar from '../../../../hooks/useRepliesSidebar';
import useMultiAssemblySlug from '../../../../hooks/useMultiAssemblySlug';
import { showChatsSelector } from '../../../../stores/layoutStore/selectors';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../../../interfaces/PostMessage';
import useReactionUpdatedSocket from '../../../../hooks/useReactionUpdatedSocket';
import { PageType } from '../../../../hooks/useReactionUpdatedSocket/types';
import { SamplePosts } from '../samplePost';
import {
  FeatureIntro,
  useFeatureIntro,
} from '../../../../hooks/useFeatureIntro';
import { authStore } from '../../../../stores/authStore';
import { ShareSheetRuleAccessTypes } from '../../FlowsShareSheetController/types';
import usePostInteractionSettingUpdatedSocket from '../../../../hooks/usePostInteractionSettingUpdatedSocket';
import { useReactionsSocket } from '../../../../hooks/useReactionsSocket';
import { useRepliesUpdatedSocket } from '../../../../hooks/useRepliesUpdatedSocket';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

export const useFlowsFeedController = () => {
  const history = useHistoryWrapper();
  const { replace } = useHistory();
  const { isCreatingFlowForTheFirstTime, setCreatingFlowForTheFirstTime } =
    authStore();

  const params = new URLSearchParams(location.search);
  const isTemplateGalleryOpen = params.get('showTemplate') === 'true';

  const handleIntroJsClose = () => {
    setCreatingFlowForTheFirstTime(false);
    if (params.get('flowsOnboardingFirstTime')) {
      params.delete('flowsOnboardingFirstTime');
      replace({
        search: params.toString(),
      });
    }
  };
  useFeatureIntro({
    feature: FeatureIntro.FLOWS_FEED,
    enabled: isCreatingFlowForTheFirstTime && !isTemplateGalleryOpen,
    delay: 2000,
    onIntroJsClose: handleIntroJsClose,
  });
  const { slugUrl } = useMultiAssemblySlug();

  let { flowId } = useParams<{ flowId: string }>();
  const customHistory = useHistory();
  const { search, state } = useLocation<{ flowIdToDuplicate: string }>();
  const { filter } = useGetParsedFilterOptions();

  const [flowFeedsSortValue, setFlowFeedsSortValue] = useState<PostSortOptions>(
    PostSortOptions.DESC,
  );

  if (!flowId && state?.flowIdToDuplicate) {
    flowId = state.flowIdToDuplicate;
  }

  const { mutate: mutateFlowPostReaction } = useUpdateFlowReactionMutation({
    flowId,
    flowFeedsSort: flowFeedsSortValue,
    filter,
  });

  const {
    feedContextProps,
    onFlowClick,
    isProfileDataError,
    isProfileInfoLoading,
    profileData,
    setShowLoadMoreButtonToFalse,
    setShowLoadMoreButtonToTrue,
    showLoadMoreButton,
  } = useFlowFeed({ mutateResponseReaction: mutateFlowPostReaction });

  const [disableFlowDetailsRequest, setDisableFlowDetailsRequest] =
    useState(false);

  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId,
    'builder',
    !disableFlowDetailsRequest,
  );
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const openParticipationFlowModalOnLoad =
    parsedParams && parsedParams?.openParticipationFlowModalOnLoad === 'true';
  const occurrenceIdFromParam =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const isFromQuickSetup =
    (parsedParams && (parsedParams.quickSetup as string)) || undefined;
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);

  const {
    isError: isFlowDetailError,
    isInitialLoading: isFlowDetailLoading,
    data,
    error: flowDetailError,
  } = useFetchFlowDetailsQuery(flowId, '', !useFetchFlowDetailsQuery);

  const { mutate: updateScheduleCardStatusMutate } =
    useUpdateScheduleCardStatus(flowId);

  const trackFeedEvent = useCallback(
    (action: FeedEventNameType) => {
      trackFeedEventMetric(action, {
        feedSort: PostSortValues[flowFeedsSortValue],
        flowName: data?.data.name,
      });
    },
    [data, flowFeedsSortValue],
  );

  const flowVisibilityCriteria = data?.data.visibility.criteria;

  let flowDetailErrorCode: any = null;
  if (flowDetailError) {
    flowDetailErrorCode = getErrorStatusCode(flowDetailError as AxiosError);
  }

  const flowDetailsData = data?.data;

  const handleFeedSortClick = useCallback(
    (val: string | number) => {
      setFlowFeedsSortValue(val as PostSortOptions);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SORTED);
    },
    [trackFeedEvent],
  );

  const {
    isInitialLoading: isFlowFeedLoading,
    isError: isFlowFeedError,
    data: flowFeedsData,
    hasNextPage: hasMoreFeeds,
    fetchNextPage: fetchNextFeeds,
    isFetchingNextPage: isFetchingNextFeeds,
    refetch: refetchFlowFeed,
  } = useGetFlowFeedsQuery({
    flowId,
    flowFeedsSort: flowFeedsSortValue,
    filter,
  });

  const { data: entityPermissionResponse } = useEntityPermissionsQuery({
    entityIds:
      flowFeedsData?.pages.flatMap((x) => x.data.map((y) => y.responseId)) ??
      [],
  });

  const handleFetchNextPage = useCallback(() => {
    fetchNextFeeds();
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FEED_SCROLLED);
  }, [fetchNextFeeds, trackFeedEvent]);

  const { reset } = useRepliesSidebar();
  const setShowChats = useLayoutStore(showChatsSelector);
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);
  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      reset();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [reset, setMemberAndToggleRightAsideOpen, setShowChats],
  );

  const {
    models: {
      dueDateValue,
      participants,
      hasMoreMembers,
      occurrenceValue,
      participantsCount,
      isTriggerFlowModalOpen,
      isTriggerFlowLoading,
      totalPendingMembers: pendingMembersParticipantCount,
    },
    operations: {
      setTriggerFlowModalClose,
      handleDueDateValueChange,
      setOccurrenceValue,
      setIsTriggerFlowModalOpen,
      fetchMoreMembers,
      handleTriggerFlowButtonClick,
    },
  } = useTriggerFlowOption(flowId);

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const openArchiveModal = () => {
    setArchiveModalOpen();
  };

  const isLoading =
    isFlowDetailLoading || isFlowFeedLoading || isProfileInfoLoading;
  const isError = isFlowDetailError || isFlowFeedError || isProfileDataError;

  const flowFeeds: FlowFeedResponse[] = useMemo(() => {
    if (!isLoading) {
      postMessageToMobileApp({
        action: AppAction.FeedLoaded,
        payload: {
          type: 'flow',
        },
      });
    }

    if (!flowFeedsData) {
      return [];
    }

    if (
      flowFeedsData.pages[0].data.length === 0 &&
      flowDetailsData?.isFlowOwner
    ) {
      return SamplePosts.map((flowData) => {
        return {
          ...flowData,
          flow: {
            ...flowData.flow,
            flowID: flowId,
            name: data?.data.name,
            hideReplies: false,
          },
        };
      }) as unknown as FlowFeedResponse[];
    }

    return flowFeedsData.pages.reduce<FlowFeedResponse[]>(
      (acc, page) => [
        ...acc,
        ...page.data.map((feedResponse) => {
          const selectedPermission = entityPermissionResponse?.data.data.find(
            (entityPermission) =>
              entityPermission.entityId === feedResponse.responseId,
          );
          return selectedPermission
            ? {
                ...feedResponse,
                ...selectedPermission,
              }
            : feedResponse;
        }),
      ],
      [],
    );
  }, [
    isLoading,
    flowFeedsData,
    entityPermissionResponse,
    flowDetailsData?.isFlowOwner,
    flowId,
    data?.data.name,
  ]);

  const showSamplePosts =
    flowFeedsData?.pages[0].data.length === 0 &&
    Boolean(flowDetailsData?.isFlowOwner);

  useEffect(() => {
    if (isError) {
      postMessageToMobileApp({
        action: AppAction.FeedErrored,
        payload: {
          type: 'flow',
        },
      });
    }
  }, [isError]);

  useEffect(() => {
    if (flowDetailError) {
      setDisableFlowDetailsRequest(true);
    }
  }, [flowDetailError]);
  // Edit flow banner for flow creation using template
  const { canDisplayBanner, viewCount } = getFlowBannerStatus(flowId);
  // State to preserve banner status, since updating it in local storage does not
  // re-render the banner
  const [canDisplayFlowBanner, setCanDisplayFlowBanner] =
    useState(canDisplayBanner);
  useEffect(() => {
    if (canDisplayBanner) {
      updateFlowBannerViewCount(flowId);
      if (viewCount === 3) {
        setCanDisplayFlowBanner(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const canShowFlowBannerOnFeed = useMemo(() => {
    const isFlowOwner = flowDetailsData?.isFlowOwner;
    const isOneClickFlow =
      flowDetailsData?.creationSource === CreationSource.OneClickFlow;
    return canDisplayFlowBanner && isFlowOwner && isOneClickFlow;
  }, [
    flowDetailsData?.isFlowOwner,
    flowDetailsData?.creationSource,
    canDisplayFlowBanner,
  ]);
  const handleEditFlowBannerClose = () => {
    setCanDisplayFlowBanner(false);
    setFlowBannerStatus(flowId, { showBanner: false });
  };
  const handleEditorButtonClick = () => {
    history.push(`${FLOWS}/${flowId}/editor`);
  };

  const handleActionBarClick = useCallback(() => {
    const occurrenceId = data?.data.occurrence.activeOccurrence?.occurrenceId;
    history.push(getAnswerFlowRoute(flowId, occurrenceId, location.pathname));
  }, [flowId, history, data?.data.occurrence.activeOccurrence?.occurrenceId]);

  const handleOnMemberClick = useCallback(
    (member: MemberInteractionType) => {
      reset();
      setMemberAndToggleRightAsideOpen({
        memberId: member.memberID,
        firstName: member.firstName,
      });
    },
    [reset, setMemberAndToggleRightAsideOpen],
  );

  const openRunNowModal = useCallback(() => {
    setIsTriggerFlowModalOpen();
  }, [setIsTriggerFlowModalOpen]);

  const onRunNowButtonClick = () => {
    handleTriggerFlowButtonClick(flowId);
  };

  useEffect(() => {
    if (openParticipationFlowModalOnLoad) {
      setParticipationFlow({
        occurrenceId: occurrenceIdFromParam,
        participationFlowId: flowId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditPostClick = useCallback(
    (flow: { postId: string }) => {
      const editResponseUrl = `${FLOWS}/${flowId}/edit/${flow.postId}?redirectUrl=${location.pathname}`;
      history.push(editResponseUrl);
    },
    [flowId, history],
  );

  useCommonFeedSocket({
    type: WebSocketFeedTypes.FLOW,
    flowId,
    setFlowFeedShowLoadMoreButtonToTrue: setShowLoadMoreButtonToTrue,
    onFlowFeedFlowResponseDelete: refetchFlowFeed,
    feedProps: flowFeeds,
  });

  useReactionsSocket();
  useReactionUpdatedSocket({
    pageType: PageType.FlowFeed,
    sortValue: flowFeedsSortValue,
    filter: filter,
  });
  usePostInteractionSettingUpdatedSocket({
    pageType: PageType.FlowFeed,
    sortValue: flowFeedsSortValue,
    filter: filter,
  });
  useRepliesUpdatedSocket({
    flowFeedsSort: flowFeedsSortValue,
    filter,
  });

  const resetCreatedConfirmationOpen = useCallback(() => {
    customHistory.push({ search: '' });
  }, [customHistory]);

  const onFlowCreateModalClose = useCallback(() => {
    resetCreatedConfirmationOpen();
  }, [resetCreatedConfirmationOpen]);

  const onRunFlowModalClick = useCallback(() => {
    openRunNowModal();
    onFlowCreateModalClose();
  }, [onFlowCreateModalClose, openRunNowModal]);

  const {
    models: { toggleValue: isPreviewModalOpen },
    operations: { setToggleValue: togglePreviewModalOpen },
  } = useToggle();
  const blockData = useFlowBuilderStore(blockDataSelector);
  const handlePreviewFlowButtonClick = useCallback(() => {
    if (blockData.CONTENT.contentBlocks.length) {
      togglePreviewModalOpen();
    }
  }, [blockData.CONTENT.contentBlocks.length, togglePreviewModalOpen]);

  const onViewMainFeedClick = useCallback(() => {
    history.push(V2_HOME);
  }, [history]);

  const handleShareSheetModalOpen = useCallback(() => {
    trackShareSheetActionEvent({
      action: SHARE_SHEET_EVENTS.SHARE_SHEET_STARTED,
      shareFlowStartSource: 'flow completion modal',
      tab: 'invite and share',
      ruleType:
        flowDetails?.data?.metaData?.ruleSelected ||
        ShareSheetRuleAccessTypes.Advance,
    });
    onFlowCreateModalClose();
    setShareSheetModalOpen(true);
  }, [
    flowDetails?.data?.metaData?.ruleSelected,
    onFlowCreateModalClose,
    setShareSheetModalOpen,
  ]);

  useEffect(() => {
    if (search.includes('show-share-sheet=true')) {
      setShareSheetModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Canvas Confetti

  const canDisplayConfetti = search.includes('flow-created-from-template=true');

  const { startConfetti, stopConfetti } = useCanvasConfetti(
    'feeds-grid-container',
    200,
  );

  const handleSetScheduleClick = useCallback(() => {
    updateScheduleCardStatusMutate();
    history.push(`${FLOWS}/${flowId}/editor?setSchedule=true`);
  }, [flowId, history, updateScheduleCardStatusMutate]);

  const handleDismissButtonClick = useCallback(() => {
    updateScheduleCardStatusMutate();
  }, [updateScheduleCardStatusMutate]);

  useEffect(() => {
    if (canDisplayConfetti) {
      startConfetti();
      const flowFeedLink = `/${slugUrl}${FLOWS_FEEDS}`;

      const timer = setTimeout(() => {
        customHistory.replace(flowFeedLink.replace(':flowId', `${flowId}`));
        stopConfetti();
      }, 3000);
      return () => {
        stopConfetti();
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getConfirmationModalButtonProps = useMemo(() => {
    if (isFromQuickSetup) {
      return {
        primaryButton: {
          text: BUILDER_FLOW_CREATED_MODAL.QUICK_SETUP.PRIMARY_BUTTON_TEXT,
          onClick: onFlowCreateModalClose,
        },
        secondaryButton: {
          text: BUILDER_FLOW_CREATED_MODAL.QUICK_SETUP.SECONDARY_BUTTON_TEXT,
          onClick: onViewMainFeedClick,
        },
      };
    }

    if (flowDetailsData) {
      if (flowDetailsData.kind === 'SCHEDULED') {
        return {
          primaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
            onClick: handleShareSheetModalOpen,
            icon: 'add-person-transparent',
          },
          secondaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SCHEDULED_FLOW
              .SECONDARY_BUTTON_TEXT,
            onClick: onFlowCreateModalClose,
          },
        };
      } else if (flowDetailsData.kind === 'NO_TRIGGER') {
        return {
          primaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
            onClick: handleShareSheetModalOpen,
            icon: 'add-person-transparent',
          },
          secondaryButton: {
            text: BUILDER_FLOW_CREATED_MODAL.NO_TRIGGER_FLOW
              .SECONDARY_BUTTON_TEXT,
            onClick: onViewMainFeedClick,
          },
        };
      }
    }
    return {
      primaryButton: {
        text: BUILDER_FLOW_CREATED_MODAL.SHARE_THIS_FLOW,
        icon: 'add-person-transparent',
        onClick: handleShareSheetModalOpen,
      },
      secondaryButton: {
        text: BUILDER_FLOW_CREATED_MODAL.ON_DEMAND_FLOW.PRIMARY_BUTTON_TEXT,
        icon: 'play',
        onClick: onRunFlowModalClick,
      },
    };
  }, [
    flowDetailsData,
    isFromQuickSetup,
    onRunFlowModalClick,
    onViewMainFeedClick,
    onFlowCreateModalClose,
    handleShareSheetModalOpen,
  ]);

  const handleErrorDraftBarClick = useCallback(() => {
    if (flowDetailsData?.flowId)
      history.push(getAnswerFlowRoute(flowId, '', location.pathname, true));
  }, [flowDetailsData, history, flowId]);

  const getIsMemberFlowParticipant = () => {
    //This check is to make sure the action bar shows for first time when
    //the job takes some time to set isFlowParticipant as true from the BE.
    //This will be applicable only for flow created from template.
    if (flowDetails && profileData) {
      const isEveryoneParticipant =
        flowDetails.data.participation?.criteria.everyone;
      const participationCriteriaRules =
        flowDetails.data.participation?.criteria.custom?.rules;
      return participationCriteriaRules
        ? participationCriteriaRules
            .find((rule) => rule.rules[0].field === 'member')
            ?.rules[0].value.includes(profileData.member.memberId as never)
        : isEveryoneParticipant;
    }
    return false;
  };

  return {
    feedContextProps,
    isLoading,
    isError,
    profileData,
    isFromQuickSetup,
    flowFeedsSortValue,
    flowDetails,
    flowDetailError,
    handleOnMemberClick,
    handleFeedSortClick,
    handleActionBarClick,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    participantsCount,
    hasMoreMembers,
    isTriggerFlowLoading,
    onFlowClick,
    flowDetailsData,
    showLoadMoreButton,
    flowDetailErrorCode,
    flowFeeds,
    feedData: {
      data: flowFeeds,
      handleFetchNextPage,
      hasNextPage: hasMoreFeeds || false,
      isFetchingNextPage: isFetchingNextFeeds,
    },
    openRunNowModal,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    fetchMoreMembers,
    onRunNowButtonClick,
    onMemberClick,
    refetchFlowFeed,
    setShowLoadMoreButtonToFalse,
    onFlowCreateModalClose,
    getConfirmationModalButtonProps,
    openArchiveModal,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    setArchiveModalClose,
    handleOnArchiveClick,
    pendingMembersParticipantCount,
    isPreviewModalOpen,
    togglePreviewModalOpen,
    handlePreviewFlowButtonClick,
    handleEditPostClick,
    handleSetScheduleClick,
    handleDismissButtonClick,
    canShowFlowBannerOnFeed,
    handleEditFlowBannerClose,
    handleEditorButtonClick,
    handleErrorDraftBarClick,
    showSamplePosts,
    isMemberFlowParticipant: getIsMemberFlowParticipant(),
    handleShareSheetModalOpen,
  };
};
