import React, {
  ReactElement,
  useCallback,
  memo,
  ButtonHTMLAttributes,
} from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../theme';

export interface ClickableProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClickCallback: (data: unknown) => void;
  onHoverCallback?: (data: unknown) => void;
  data?: unknown;
  dataTestId?: string;
  dataQaId?: string;
  children: string | ReactElement;
}

export const StyledClickable = styled.button.attrs((props) => ({
  type: props.type || 'button',
}))`
  background: none;
  color: inherit;
  border: none;
  padding: 0px;
  font: inherit;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  outline: inherit;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  line-height: 22px;

  &:focus {
    outline: 0;
  }
`;

const Clickable = (props: ClickableProps): ReactElement => {
  const {
    dataTestId,
    dataQaId,
    children,
    onClickCallback,
    onHoverCallback,
    data,
    ...rest
  } = props;

  const onClickHandler = useCallback(() => {
    if (onClickCallback) {
      onClickCallback(data);
    }
  }, [data, onClickCallback]);

  const onHoverHandler = useCallback(() => {
    if (onHoverCallback) {
      onHoverCallback(data);
    }
  }, [data, onHoverCallback]);

  return (
    <StyledClickable
      data-testid={dataTestId}
      onClick={onClickHandler}
      onMouseEnter={onHoverHandler}
      {...rest}
    >
      {children}
    </StyledClickable>
  );
};

const MemoizedClickable = memo(Clickable);
MemoizedClickable.displayName = 'Clickable';

export default MemoizedClickable;
