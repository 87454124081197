import { createContext } from 'react';

import useEndFlowOccurrenceModal from '.';
import FlowEndOccurrenceModal from './EndFlowOccurrenceModal';
import {
  EndFlowOccurrenceModalContextState,
  EndFlowOccurrenceModalProviderProps,
} from './types';

export const EndFlowOccurrenceModalContext =
  createContext<EndFlowOccurrenceModalContextState>({
    flowDetails: undefined,
    isEndOccurrenceModalLoading: false,
    isEndOccurrenceModalOpen: false,
    onEndOccurrenceClick: () => {},
    setEndOccurrenceModalClose: () => {},
    setIsEndOccurrenceModalOpen: () => {},
    setFlowDetails: () => {},
  });

export const EndFlowOccurrenceModalProvider = (
  props: EndFlowOccurrenceModalProviderProps,
) => {
  const { children } = props;
  const modalValue = useEndFlowOccurrenceModal();

  return (
    <EndFlowOccurrenceModalContext.Provider value={modalValue}>
      <FlowEndOccurrenceModal />
      {children}
    </EndFlowOccurrenceModalContext.Provider>
  );
};
