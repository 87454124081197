import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { showErrorMessage } from '../../Utils/toast';

type DraftState = {
  drafts: Record<string, any>;
  saveDraft: (feed: string, draftValue: Record<string, any>) => void;
  deleteDraft: (feed: string) => void;
  errorDrafts: Record<string, any>;
  saveErrorDraft: (feed: string, draftValue: Record<string, any>) => void;
  deleteErrorDraft: (feed: string) => void;
};

export const useDraftStore = create<DraftState>()(
  persist(
    (set, get) => ({
      drafts: {},
      errorDrafts: {},
      saveDraft: (feed, draftValue) => {
        const newDrafts = get().drafts;
        newDrafts[feed] = draftValue;
        try {
          set({ drafts: newDrafts });
        } catch (error: any) {
          if (error?.message?.includes('Failed to execute')) {
            showErrorMessage(
              'Error saving draft. Please clear your browser cache before saving your draft.',
            );
          }
        }
      },
      deleteDraft: (feed) => {
        const drafts = get().drafts;
        delete drafts[feed];
        try {
          set({ drafts });
        } catch (error: any) {
          if (error?.message?.includes('Failed to execute')) {
            showErrorMessage(
              'Error saving draft. Please clear your browser cache before trying again.',
            );
          }
        }
      },
      saveErrorDraft: (feed, draftValue) => {
        const newErrorDrafts = get().errorDrafts;
        newErrorDrafts[feed] = draftValue;
        try {
          set({ errorDrafts: newErrorDrafts });
        } catch (error: any) {
          if (error?.message?.includes('Failed to execute')) {
            showErrorMessage(
              'Error saving draft. Please clear your browser cache before saving your draft.',
            );
          }
        }
      },
      deleteErrorDraft: (feed) => {
        const errorDrafts = get().errorDrafts;
        delete errorDrafts[feed];
        try {
          set({ errorDrafts });
        } catch (error: any) {
          if (error?.message?.includes('Failed to execute')) {
            showErrorMessage(
              'Error saving draft. Please clear your browser cache before trying again.',
            );
          }
        }
      },
    }),
    {
      name: 'draft-storage',
    },
  ),
);
