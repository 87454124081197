import styled from 'styled-components';
import Image from '../../atoms/Image';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import Checkbox from '../../atoms/Checkbox';
import Dropdown from '../../molecules/Dropdown_V2';
import { RewardTypes } from '../../molecules/RewardCard/interface';
import { Flex } from '../../../Utils/styles/display';
import { SingleSelectAutoComplete } from '../Autocomplete';
import FormikField from '../../molecules/FormikField';
import InfoAlert from '../../molecules/InfoAlert';
import { device } from '../../../constants/layout';

export const ContentLeftWrapper = styled.div`
  position: relative;
  min-height: 100%;

  ol,
  ul {
    padding-left: 15px;
  }
`;

export const ContentLeftWrapperInner = styled.div`
  padding: 32px 32px 16px 32px;
  overflow-y: auto;
  height: 475px;
  @media ${device.mobile} {
    height: auto;
    padding: 24px 32px 0 32px;
  }
`;

interface ContentLeftWrapperInnerBeforeRedeemProps {
  rewardType?: RewardTypes;
}

export const ContentLeftWrapperInnerBeforeRedeem = styled(
  ContentLeftWrapperInner,
)<ContentLeftWrapperInnerBeforeRedeemProps>`
  overflow-y: ${(props) =>
    props.rewardType === RewardTypes.Culture ? 'auto' : 'visible'};
`;

export const RewardImage = styled(Image)`
  max-height: 125px;
  width: auto;
  border-radius: 4px;
`;

export const RewardImageBeforeRedeem = styled(RewardImage)`
  margin-top: 26px;
  @media ${device.mobile} {
    margin-top: 0;
  }
`;

export const RewardTitle = styled(Heading)`
  margin: 16px 0 4px 0;
  color: ${ThemeV2.palette.gray10};
`;

export const RewardCost = styled(Body)`
  margin: 4px 0;
  color: ${ThemeV2.palette.gray8};
`;

export const RewardDescription = styled(Body)`
  margin: 0 0 16px 0;
  color: ${ThemeV2.palette.gray7};
  @media ${device.mobile} {
    margin: 0;
  }
`;

export const RewardDropdown = styled(Dropdown)`
  margin-top: 16px;
`;

export const ContentLeftFooterButtonWrapper = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${ThemeV2.palette.gray4};
  padding: 16px;
  z-index: 1;
  background: ${ThemeV2.palette.gray1};
  @media ${device.mobile} {
    position: relative;
    border-top-width: 0;
    margin-top: 8px;
    padding: 24px 32px;
    flex-direction: column;
  }
  gap: 10px;
`;

export const RewardSuccessHeading = styled(Heading)`
  margin: 0 0 12px 0px;
  color: ${ThemeV2.palette.gray9};
`;

export const RewardSuccessTitle = styled(Body)`
  margin: 12px 0 16px 0;
  color: ${ThemeV2.palette.gray9};
`;

export const RewardSuccessContent = styled.div`
  margin-top: 24px;
`;

export const RewardSuccessContentText = styled(Body)`
  color: ${ThemeV2.palette.gray8};
`;
export const RewardSuccessContentTextTitle = styled(Body)`
  margin-bottom: 4px;
  margin-top: 0;
`;

export const RewardSuccessContentTextEmail = styled(Body)`
  margin-bottom: 16px;
  color: ${ThemeV2.palette.gray8};
`;

export const RewardSuccessContentRedeemLink = styled(Body)`
  margin-bottom: 8px;
  & a {
    text-decoration: none;
  }
`;

export const RewardsMessageTitle = styled(Body)`
  margin-bottom: 8px;
  font-weight: bold;
  color: ${ThemeV2.palette.gray9};
`;

export const RewardsMessageContent = styled(Body)`
  margin-bottom: 8px;
  color: ${ThemeV2.palette.gray10};
`;

export const RewardSuccessContentContainer = styled.div`
  margin-bottom: 8px;
  border-radius: 8px;
  background: ${ThemeV2.palette.gray3};
  padding: 16px;
`;

export const RewardSuccessContentList = styled.div`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${ThemeV2.palette.gray8};

  ol,
  ul {
    padding: 0 16px;
    margin: 0;
  }
`;

export const RedeemPolicyHeading = styled(Heading)`
  color: ${ThemeV2.palette.gray9};
  margin: 4px 0 16px 0;
`;

export const RedeemPolicyContent = styled(Body)`
  color: ${ThemeV2.palette.gray9};
  margin: 8px 0 16px 0;
  & a {
    text-decoration: none;
  }
`;

export const StarRatingContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StarRatingContainerText = styled(Body)`
  margin-bottom: 8px;
`;

export const StarRatingWrapper = styled.div`
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 24px 24px 18px 24px;
  border-radius: 2px;
`;

export const RewardSuccessRatedHeading = styled(Heading)`
  margin: 24px 0 8px 0;
`;

export const RewardSuccessRatedText = styled(Body)``;

export const StyledBarcode = styled.img`
  display: block;
  max-width: 100px;
  max-height: 50px;
  width: auto;
  height: auto;
`;

export const StyledRewardsModalVerticalMiddleContent = styled.div`
  margin-top: -64px;
  @media ${device.mobile} {
    margin-top: -16px;
  }
`;

export const SwagDetailsWrapper = styled.div`
  padding: 32px;
`;

export const StyledAutoComplete = styled(SingleSelectAutoComplete)`
  margin: 24px auto;
`;

export const FormikWrapper = styled(Flex)``;

export const Wrapper = styled.div`
  padding: 56px 32px;
  overflow-y: auto;
  height: 650px;
  @media ${device.mobile} {
    height: auto;
    padding: 0px 32px;
  }
`;

export const SelectedRewardImage = styled(Image)`
  width: 40px;
  height: 40px;
  margin-right: 8px;
`;

export const StyledHeading = styled(Heading)`
  margin: 24px 0 8px 0;
`;

export const StyledFormikField = styled(FormikField)<{ marginLeft?: string }>`
  margin-left: ${({ marginLeft }) => marginLeft && marginLeft};
  margin-bottom: 16px;
`;

export const ConfirmationScreenWrapper = styled.div`
  padding: 32px;
`;

export const StyledBody = styled(Body)`
  margin-top: 8px;
`;

export const RedeemScreenWrapper = styled.div`
  overflow-y: auto;
  height: 480px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-top: 8px;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 100%;
`;
