import { useEffect } from 'react';
import { showErrorMessage } from '../../Utils/toast';
import { MOBILE_APP_URL } from '../../config';
import { SETUP_FLOWS_ERROR } from '../../languages/en/flows';
import { PreJobResponseInterface } from '../../queries/Settings/identityManager';
import useHistoryWrapper from '../useHistoryWrapper';
import useMobileApp from '../useMobileApp/index';
import useMultipleJobsPollingV2 from '../useMultipleJobsPolling';
import { useGetOnboardingRedirectUrl } from '../../queries/Onboarding';

const useOnboardingJobSetup = (props: {
  apiName: string;
  pathName?: string;
  onboardingPath?: string;
  canSetManagerFilter?: boolean;
  preJobResponse?: PreJobResponseInterface;
}) => {
  const history = useHistoryWrapper();
  const { isMobileApp, jwtToken } = useMobileApp();
  const { error, isJobStatusSuccessful } = useMultipleJobsPollingV2<undefined>({
    preJobsAPI: {
      apiName: props.apiName,
    },
    enabled: props.preJobResponse ? false : true,
    preJobResponse: props.preJobResponse,
  });
  const { data: redirect, isSuccess } = useGetOnboardingRedirectUrl(
    isJobStatusSuccessful,
  );

  useEffect(() => {
    if (error) {
      showErrorMessage(SETUP_FLOWS_ERROR);
    }
  }, [error]);
  useEffect(() => {
    if (isSuccess && redirect) {
      if (props.pathName) {
        if (isMobileApp) {
          window.location.href = `${MOBILE_APP_URL}home?jwtToken=${jwtToken}`;
          return;
        }
        // We construct the URL object here to handle scenarios where API returns query params
        const redirectUrl = new URL(
          `${redirect?.redirectTo}${props.pathName}`,
          window.location.origin,
        );
        redirectUrl.searchParams.append('flowsOnboardingFirstTime', 'true');
        if (props.onboardingPath) {
          if (props.canSetManagerFilter) {
            redirectUrl.searchParams.append(
              'canSetManagerFilter',
              String(props.canSetManagerFilter),
            );
          }
          redirectUrl.searchParams.append(
            'onboardingPath',
            props.onboardingPath,
          );
        }
        history.push(`${redirectUrl.pathname}${redirectUrl.search}`);
      }
    }
  }, [
    history,
    jwtToken,
    redirect,
    isSuccess,
    isMobileApp,
    props.pathName,
    props.onboardingPath,
    isJobStatusSuccessful,
    props.canSetManagerFilter,
  ]);
};

export default useOnboardingJobSetup;
