import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../theme';

const NotificationItemLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={288}
      height={48}
      viewBox="0 0 288 48"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="notification-items-loader"
    >
      <circle cx="24" cy="24" r="20" />
      <rect x="52" y="6" rx="4" width="183" height="16" />
      <rect x="52" y="26" rx="4" width="145" height="16" />
    </ContentLoader>
  );
};

const NotificationItemsLoader = () => (
  <>
    <NotificationItemLoader />
    <NotificationItemLoader />
  </>
);

export default NotificationItemsLoader;
