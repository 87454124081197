import React from 'react';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import ThemeV2 from '../../../theme';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      background: alpha(ThemeV2.palette.geekBlue5, 0.05),
    },
  },
});
export interface IconButtonProps {
  children: React.ReactNode;
  className?: string;
  [x: string]: unknown;
}

const SimpleIconButton = (props: IconButtonProps) => {
  const { className: parentClassName, children, ...rest } = props;
  const classes = useStyles(props);
  const styles = `${classes.root} ${parentClassName}`;

  return (
    <IconButton className={styles} {...rest}>
      {children}
    </IconButton>
  );
};

export default SimpleIconButton;
