import { useCallback, useMemo } from 'react';

import { shallow } from 'zustand/shallow';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { FLOWS_FEEDS } from '../../../constants/routes';
import useFlowsListAccordion from '../../../hooks/useFlowsListAccordion';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import useRepliesSidebar from '../../../hooks/useRepliesSidebar';
import { UnreadChatMember } from '../../../interfaces/Chats';
import { useGetUnreadChats } from '../../../queries/Chats';
import {
  useFetchFlowsActivity,
  useMainNavQuery,
} from '../../../queries/Flows/Dashboard';
import useLayoutStore from '../../../stores/layoutStore';
import {
  mapFlowsResponseToNavFlowItem,
  sortLeftDrawerNavFlowItemsByLabel,
} from '../utils';

const useUnreadFlowsController = () => {
  const history = useHistoryWrapper();

  const {
    flowsDropdownMenuItems,
    handleAccordionClick,
    handleMenuItemClick: onMenuItemClick,
    handleMenuItemOptionsSelect,
    isOpen,
    onDropdownMenuItemRightClick,
  } = useFlowsListAccordion();

  const { data: unReadChats, refetch: refetchUnreadChats } =
    useGetUnreadChats();
  const { data, isSuccess } = useMainNavQuery();
  const { data: flowsActivityUpdates } = useFetchFlowsActivity({
    isEnabled: true,
  });

  const { reset } = useRepliesSidebar();
  const { setShowChats, setMemberAndToggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      setSelectedMember: state.setSelectedMember,
      setShowChats: state.setShowChats,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
    }),
    shallow,
  );

  const flows = data?.flattenedData;

  const sortedFlowItems = useMemo(() => {
    if (!flows) {
      return [];
    }

    const flowsActivityData = flowsActivityUpdates?.data || [];

    return flows
      .reduce((unreadFlows, flow) => {
        const flowActivityData = flowsActivityData.find(
          (flowWithActivityData) => flowWithActivityData.flowId === flow.flowId,
        );

        if (flowActivityData?.hasUnreadPost) {
          unreadFlows.push({
            ...mapFlowsResponseToNavFlowItem(flow),
            badgeText:
              flowActivityData.unreadMentionsCount !== 0
                ? flowActivityData.unreadMentionsCount?.toString()
                : undefined,
            isUnread: true,
          });
        }

        return unreadFlows;
      }, [] as LeftDrawerCollapsibleNavItemProps[])
      .filter((item) => !item.isMuted)
      .sort(sortLeftDrawerNavFlowItemsByLabel);
  }, [flowsActivityUpdates?.data, flows]);

  const totalNotificationsCount = useMemo(() => {
    let notificationCount = 0;
    sortedFlowItems.forEach((item) => {
      if (Number(item.badgeText)) {
        notificationCount += Number(item.badgeText);
      }
    });
    return notificationCount;
  }, [sortedFlowItems]);

  const handleMenuItemClick = (id?: string) => {
    onMenuItemClick();
    history.push(FLOWS_FEEDS.replace(':flowId', `${id}`));
  };

  const handleOnUserMenuItemClick = useCallback(
    (user: UnreadChatMember) => {
      const { memberId, firstName } = user;

      reset();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({ memberId, firstName });
      refetchUnreadChats();
    },
    [refetchUnreadChats, reset, setMemberAndToggleRightAsideOpen, setShowChats],
  );

  return {
    models: {
      isOpen,
      unReadChats,
      menuItems: sortedFlowItems,
      totalNotificationsCount,
      dropdownMenuItems: flowsDropdownMenuItems,
      isSuccess,
    },
    operations: {
      handleMenuItemClick,
      handleAccordionClick,
      handleOnUserMenuItemClick,
      handleMenuItemOptionsSelect,
      onDropdownMenuItemRightClick,
    },
  };
};

export default useUnreadFlowsController;
