import { datadogLogs } from '@datadog/browser-logs';
import moment from 'moment-timezone';
import { DEFAULT_TIMEZONE } from '../constants';
import timezones from '../data/timezones/index.json';

const BEGIN_YEAR = 1972;
const START_YEAR = 1950;

export const getMonthFromDateObject = (dateObject: {
  month: string;
  monthNumber: number;
}) => {
  if (!dateObject.month && !dateObject.monthNumber) {
    return '';
  }
  if (dateObject.month) {
    return dateObject.month;
  }
  return moment.months(dateObject.monthNumber - 1);
};

export const getMonthsToShow = () => {
  return moment.months().map((mon) => {
    return {
      value: mon,
      label: mon,
    };
  });
};

const isFeb = (month: string | number) => moment().month(month).month() === 1;

export const getDaysForMonth = (month: string) => {
  const monthMoment = moment([1971, moment().month(month).month()]);
  const daysInMonth = isFeb(month)
    ? monthMoment.daysInMonth() + 1
    : monthMoment.daysInMonth();
  return Array.from({ length: daysInMonth }, (v, k) => ({
    value: k + 1,
  }));
};

export const getDateFromDateObject = (dateObject: {
  month: string;
  monthNumber: number;
  year?: number | undefined;
  date: string;
  day: string;
}) => {
  if (!dateObject) {
    return null;
  }
  const { month, monthNumber, year = BEGIN_YEAR, date, day } = dateObject;

  if (day && month && year) {
    // this is to support the latest data structure[V3 API]
    return moment(`${year} ${month} ${day}`, 'YYYY MM DD');
  }
  if (month) {
    return moment(`${year} ${month} ${date ? date : day}`, 'YYYY MMMM DD');
  }
  return moment(`${year} ${monthNumber} ${date ? date : day}`, 'YYYY MM DD');
};

export const getDateObjectFromMomentDate = (momentDate: moment.Moment) => {
  if (!momentDate) {
    return null;
  }
  const date = momentDate.date();
  const month = momentDate.format('MMMM');
  const monthNumber = Number(momentDate.format('M'));
  const year = Number(momentDate.format('YYYY'));
  return {
    date,
    month,
    monthNumber,
    year,
  };
};

export const validateMonthDayAndYear = (
  month: string | number,
  day: string | number,
  year = BEGIN_YEAR,
) => {
  const monthMoment = moment([year, moment().month(month).month(), day]);
  return monthMoment.isValid();
};

export const getHireYearOptions = () => {
  let END_YEAR = moment().year() + 1;
  const yearOptions: any[] = [];
  while (START_YEAR < END_YEAR) {
    yearOptions.push({ value: END_YEAR });
    END_YEAR -= 1;
  }
  return yearOptions;
};

export const getDatePhrase = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return `${moment.monthsShort()[month]} ${day}, ${year}`;
};

export const getStartDay = (date: moment.MomentInput) =>
  moment.utc(date).startOf('day').toDate();

export const getEndDay = (date: moment.MomentInput) =>
  moment.utc(date).endOf('day').toDate();

export const monthNames = Array.from({ length: 12 }, (item, i) => {
  return new Date(0, i).toLocaleString('en-US', { month: 'long' });
});

export const getMonthByNumber = (monthNumber: number) => {
  const monthsMap: { [key: number]: string } = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  return monthsMap[monthNumber];
};
export const getMonthNumbers = (months: string[]) => {
  const monthNumberMap: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  return months.map((month) => monthNumberMap[month]);
};

export const getAbbreviatedMonthByNumber = (monthNumber: number) => {
  const abbreviatedMonthsMap: { [key: number]: string } = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };

  return abbreviatedMonthsMap[monthNumber];
};

export const getLocalTimeAtTimeZone = (
  ianaTimeZone: string,
  format = 'h:mma (z)',
) => {
  const localTime = moment();
  const convertedTime = moment.tz(localTime, ianaTimeZone);
  return convertedTime.format(format);
};

export const changeTimezone = (date: Date, ianatz = DEFAULT_TIMEZONE) => {
  // suppose the date is 12:00 UTC
  const invdate = new Date(
    date.toLocaleString('en-US', {
      timeZone: ianatz,
    }),
  );

  // then invdate will be 07:00 in Toronto
  // and the diff is 5 hours
  const diff = date.getTime() - invdate.getTime();

  // so 12:00 in Toronto is 17:00 UTC
  return new Date(date.getTime() - diff); // needs to substract
};

export const mapTimezoneValue = (timezoneValue: string) => {
  let filteredTimezone = timezones.filter(
    (timezone) => timezone.key === timezoneValue,
  );
  if (filteredTimezone.length === 0) {
    filteredTimezone = timezones.filter((timezone) =>
      timezone.otherUtc.includes(timezoneValue),
    );
  }
  if (filteredTimezone.length > 0) {
    return filteredTimezone[0].value;
  }
  return undefined;
};

export const detectTimezone = () => {
  const detectedValue = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const hasValue = mapTimezoneValue(detectedValue);
  if (hasValue !== undefined && hasValue.length > 0) {
    return detectedValue;
  }
  return null;
};

export const getCurrentTimeInTimeZone = (timeZone: string | undefined) => {
  if (timeZone) {
    const hasValue = mapTimezoneValue(timeZone);
    if (hasValue !== '' || hasValue !== undefined) {
      const now = new Date();
      const res = new Date(now.toLocaleString('en-US', { timeZone }));
      const time = res.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
      const timezoneCode = moment.tz(timeZone).zoneAbbr();
      return `${time} (${timezoneCode})`;
    }
  }
  return null;
};

const isValidTimeZone = (tz: any) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
    throw new Error('Time zones are not available in this environment');
  }

  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    return false;
  }
};

export const convertTZ = (date: Date | string, tzString: string) => {
  try {
    const timezone = isValidTimeZone(tzString) ? tzString : '';
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        {
          timeZone: timezone,
        },
      ),
    );
  } catch {
    datadogLogs.logger.error('Error in convertTZ function', {
      date,
      tzString,
    });
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
      ),
    );
  }
};

export const getCurrentDateForTimeZone = (timeZone: string): Date => {
  const timeZoneCurrentDate = convertTZ(new Date().toISOString(), timeZone);
  return timeZoneCurrentDate;
};

export const getDateForTimeZone = (
  date: Date | string,
  timeZone: string,
): Date => {
  return convertTZ(date, timeZone);
};
