import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import useFlowFeed from '../../../../hooks/useFlowFeed';
import { useGetFlowResponseQuery } from '../../../../queries/Feed';
import routesList from '../../../../routesList';
import {
  useEntityPermissionsQuery,
  useUpdateSingleFlowReactionMutation,
} from '../../../../queries/Flows/Feed';
import { FLOWS } from '../../../../constants/routes';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import useReactionUpdatedSocket from '../../../../hooks/useReactionUpdatedSocket';
import { PageType } from '../../../../hooks/useReactionUpdatedSocket/types';
import useLayoutStore from '../../../../stores/layoutStore';
import { NEW_APP_URL } from '../../../../config';
import usePostInteractionSettingUpdatedSocket from '../../../../hooks/usePostInteractionSettingUpdatedSocket';
import { useReactionsSocket } from '../../../../hooks/useReactionsSocket';

export const useNewSingleFlowFeedPostController = () => {
  const { search } = useLocation();
  const flowId = new URLSearchParams(search).get('flowId') || '';
  const responseId = new URLSearchParams(search).get('responseId') || '';
  const history = useHistoryWrapper();
  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;

  const { data: entityPermissionResponse } = useEntityPermissionsQuery({
    entityIds: [responseId],
  });

  const { mutate: mutateFlowPostReaction } =
    useUpdateSingleFlowReactionMutation(flowId);

  useReactionsSocket();
  useReactionUpdatedSocket({ pageType: PageType.SingleFlowFeed });
  usePostInteractionSettingUpdatedSocket({
    pageType: PageType.SingleFlowFeed,
  });

  const onPostDelete = useCallback(() => {
    if (isEmbeddedInMainApp) {
      window.parent.postMessage({ type: 'GO_TO_HOME_FEED' }, '*');
    } else {
      history.push(routesList.HOME);
    }
  }, [history, isEmbeddedInMainApp]);

  const { feedContextProps, isProfileInfoLoading, profileData } = useFlowFeed({
    onPostDelete,
    mutateResponseReaction: mutateFlowPostReaction,
  });

  const {
    isInitialLoading: isSingleFlowFeedPostLoading,
    isError,
    data,
  } = useGetFlowResponseQuery(flowId, responseId);

  const flowFeedResponse = useMemo(() => {
    if (
      entityPermissionResponse &&
      entityPermissionResponse.data?.data.length > 0
    ) {
      const entityPermission = entityPermissionResponse.data.data[0];
      if (entityPermission && data) {
        return {
          ...data,
          ...entityPermission,
        };
      }
    } else {
      return data;
    }
  }, [data, entityPermissionResponse]);

  const isLoading = isSingleFlowFeedPostLoading || isProfileInfoLoading;

  const handleEditPostClick = useCallback(
    (flow: { postId: string }) => {
      if (isEmbeddedInMainApp) {
        // eslint-disable-next-line max-len
        const editResponseUrl = `${FLOWS}/${flowId}/edit/${flow.postId}?redirectUrl=${NEW_APP_URL}${location.pathname}?flowId=${flowId}&responseId=${responseId}`;
        history.push(editResponseUrl);
      } else {
        // eslint-disable-next-line max-len
        const editResponseUrl = `${FLOWS}/${flowId}/edit/${flow.postId}?redirectUrl=${location.pathname}?flowId=${flowId}&responseId=${responseId}`;
        history.push(editResponseUrl);
      }
    },
    [flowId, history, isEmbeddedInMainApp, responseId],
  );

  return {
    isError,
    isLoading,
    profileData,
    feedContextProps,
    handleEditPostClick,
    data: flowFeedResponse,
  };
};
