import { DropdownItemOption } from '../../../../interfaces/notebook';
import {
  ARCHIVE_TASK_LABEL,
  COPY_TASK_LINK_LABEL,
  DELETE_TASK_LABEL,
} from '../../../../languages/en/notebook';

export enum RightDrawerTaskDropdownOperations {
  ARCHIVE = 'archive',
  COPY_LINK = 'copyLink',
  DELETE = 'delete',
}

export const rightDrawerTaskDropdownItems: DropdownItemOption[] = [
  {
    id: RightDrawerTaskDropdownOperations.COPY_LINK,
    value: COPY_TASK_LINK_LABEL,
    deleteItem: false,
    isSelected: false,
    prefixIcon: 'Link',
  },
  {
    id: RightDrawerTaskDropdownOperations.ARCHIVE,
    value: ARCHIVE_TASK_LABEL,
    deleteItem: true,
    isSelected: false,
    prefixIcon: 'archive',
  },
  {
    id: RightDrawerTaskDropdownOperations.DELETE,
    value: DELETE_TASK_LABEL,
    deleteItem: true,
    isSelected: false,
    prefixIcon: 'delete',
  },
];
