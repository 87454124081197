import React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';
import { makeStyles } from '@mui/styles';
import ThemeV2 from '../../../theme';

const useStyles = makeStyles({
  root: {
    color: ThemeV2.palette.gray5,
    '&:hover': {
      color: ThemeV2.palette.geekBlue6,
      backgroundColor: 'transparent',
    },
    '&$disabled': {
      color: `${ThemeV2.palette.gray5} !important `,
    },
  },
  checked: {
    color: ThemeV2.palette.geekBlue6,
    '&:hover': {
      backgroundColor: 'transparent',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  disabled: {
    color: `${ThemeV2.palette.gray5} !important `,
  },
});

const CustomRadio = (props: RadioProps) => {
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: classes.root,
        checked: classes.checked,
        disabled: classes.disabled,
      }}
      disableRipple
      {...props}
      color="default"
    />
  );
};

export default CustomRadio;
