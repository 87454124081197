import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';
import SVGIcon from '../../atoms/SVGIcon';
import ToolTip from '../ToolTipV2';

export const NavigationExpandBar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0px;
  cursor: pointer;
  user-select: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin: 0 4px 0 12px;
`;

export const StyledBetaIcon = styled(SVGIcon)`
  margin-left: 8px;
`;

export const StyledToolTip = styled(ToolTip)`
  margin-left: auto;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: auto;
`;

export const Badge = styled.span`
  display: inline-block;
  background: ${ThemeV2.palette.geekBlue6};
  padding: 0 8px;
  margin-left: 4px;
  border-radius: 25px;
`;
