import { useState } from 'react';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import DeleteModal from '../../atomic/organism/FlowsPostHeaderOption/DeleteModal';

const DeleteModalController = () => {
  const { data: profileInfo } = useProfileInfoFetchQuery();

  const [isModalOpen, setModalOpen] = useState(true);
  const handleOnModalClose = () => {
    setModalOpen(false);
    window.parent.postMessage(
      {
        type: 'DELETE_POST_MODAL_CLOSE',
      },
      '*',
    );
  };

  const onDeleteModalSubmitClick = (returnPoints?: boolean) => {
    window.parent.postMessage(
      {
        type: 'DELETE_POST_MODAL_DELETE',
        payload: { returnPoints },
      },
      '*',
    );
  };

  if (!isModalOpen) {
    return <></>;
  }

  return (
    <div>
      <DeleteModal
        onModalClose={handleOnModalClose}
        onDeleteModalSubmitClick={onDeleteModalSubmitClick}
        currency={profileInfo?.assembly.currency}
        hasTrophies
      />
    </div>
  );
};

export default DeleteModalController;
