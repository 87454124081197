import styled from 'styled-components';
import { InputBase } from '@mui/material';
import DropdownCard from '../../../atoms/DropdownCard_V2';

import Button from '../../../atoms/Button';
import ThemeV2 from '../../../../theme';
import { DropdownCategoryLabel } from '../../../molecules/Dropdown_V2/styles';
import Body from '../../../atoms/Body';

export const AddBlockButton = styled(Button)`
  display: flex;
  justify-content: flex-start;

  svg path {
    fill: ${ThemeV2.palette.geekBlue6};
  }
`;

export const StyledDropdownCategoryLabel = styled(DropdownCategoryLabel)`
  margin-top: 8px;
`;

export const StyledTitleTextField = styled(InputBase)`
  width: 100%;

  .MuiInputBase-input {
    padding: 0;
    border: none;
    height: 22px;
    font-size: 14px;
    line-height: 22px;
    color: ${ThemeV2.palette.gray6};
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    font-family: ${ThemeV2.typography.adminFontFamily};

    &::placeholder {
      opacity: 1;
    }
  }
`;

export const StyledDropdownCard = styled(DropdownCard)`
  width: 280px;
`;

export const BlocksCountWrapper = styled(Body)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
