import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import { SocialProfileWrapper } from './styles';
import styled from 'styled-components';

export type SocialProfileProps = {
  to: string;
  icon: string;
  profileName: string;
};

const StyledSVGIcon = styled(SVGIcon)`
  path {
    fill: currentColor;
  }
`;

const SocialProfile = (props: SocialProfileProps) => {
  const { to, icon, profileName } = props;
  return (
    <SocialProfileWrapper to={to} isAnchor openInNewTab>
      <>
        <StyledSVGIcon icon={icon} color={ThemeV2.palette.gray8} size="16px" />
        <Body variant="body2" color="gray8">
          {profileName}
        </Body>
      </>
    </SocialProfileWrapper>
  );
};

export default SocialProfile;
