import { AnalyticsEvents } from './constants';
import { trackEvent } from './index';

type FolderOperations =
  | 'folderCreated'
  | 'flowAddedToFolder'
  | 'folderDeleted'
  | 'editFolderClicked'
  | 'folderMoved';

type BaseFolderEvent = {
  folder: 'customFolder' | 'flows';
  customFolderName?: string;
};

export type FolderActionEvent = {
  action: FolderOperations;
} & BaseFolderEvent;

export type FolderErrorEvent = {
  error: FolderOperations;
} & BaseFolderEvent;

export const trackFolderActionEvent = (event: FolderActionEvent) => {
  trackEvent('left.drawer.events', undefined, {
    ...event,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackFolderErrorEvent = (event: FolderErrorEvent) => {
  trackEvent('left.drawer.events', undefined, {
    ...event,
    event: AnalyticsEvents.ERROR,
  });
};
