/* eslint-disable max-len */
import React, { useCallback, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RRule } from 'rrule';
import ThemeV2 from '../../../theme';

import FlowsFeedPostLoader from './Loader';
import { FeedsContainer, Flex } from '../../../Utils/styles/display';
import { useFlowsFeedController } from './useFlowsFeedController';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import {
  StyledActionBarWrapper,
  StyledDropdown,
  StyledInfoAlert,
  StyledPostLoadingText,
  StyledPostLoading,
  StyledSpinningLoader,
  ErrorDraftBanner,
  EditIcon,
  DeleteIcon,
  StyledClickable,
} from './styles';
import { feedSortItems } from '../../home/MainFeedController/data';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { PostSortValues } from '../../../interfaces/Feed';
import {
  ARCHIVE_IT,
  CUSTOMIZE_FLOWS_TEXT,
  FLOWS_ARCHIVE_MODAL,
  FLOW_ACTION_BAR_NOTIFICATION_TEXT,
  FLOW_ENDED_INFO,
  FLOW_OWNER_ENDED_INFO,
  FLOW_OWNER_SHORTCUT_ENDED_INFO,
  FLOW_SHORTCUT_ENDED_INFO,
  IS_CLOSED,
  SCHEDULE_CARD_TEXT,
} from '../../../languages/en/flows';

import searching from '../../../img/searching.svg';
import setAccountInfo from '../../../img/set-account-info.svg';

import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import FeedPostLoader from '../../../atomic/organism/FeedPost/Loader';
import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';

import {
  DropdownColor,
  DropdownPlacement,
  DropdownSizes,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import {
  ANSWERED,
  ARCHIVE,
  WHEN,
  YOU,
  ANONYMOUSLY,
  THE,
  EDITOR,
  TO,
  DO,
  SO,
} from '../../../languages/en/singleWords';
import FlowRunNowModal from '../../../atomic/organism/FlowRunNowModal';
import { getFlowNameWithEmoticon } from '../../../Utils/flows';
import { capitalizeFirstLetter } from '../../../Utils/text';
import FlowResponseController from '../FlowResponseController';
import { getDueTime } from '../../../Utils/time';
import { ShowMoreButton } from '../../home/MainFeedController/styles';
import {
  POST_LOADING,
  SEE_NEW_POSTS,
  START_POST,
} from '../../../languages/en/feed';
import {
  FeedContext,
  getFormattedNextTriggerDateAndTime,
} from '../../../Utils/flows/feeds';

import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import {
  covertToLocalTime,
  getTimezoneAbbr,
} from '../../../Utils/flows/builder/utils';
import { LinkButton } from '../../../atomic/molecules/FlowsFeedHeader/styles';
import Body from '../../../atomic/atoms/Body';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import {
  getScheduleFlowEmptyStateSubHeading,
  getScheduleFlowEmptyStateTriggeredOnceSubHeading,
} from './utils';
import {
  AnonymityStates,
  CreationSource,
  FlowAccessRoles,
  LinkAccessType,
} from '../../../queries/Flows/interfaces';
import { V2_HOME } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import FlowsParticipationPreviewController from '../FlowsParticipationPreviewController';
import FlowPromptCard from '../../../atomic/organism/FlowPromptCard';
import { FlowPromptCardStatus } from '../../../atomic/organism/FlowPromptCard/types';
import { dismissCardMenuItems } from './data';
import { useDraftStore } from '../../../stores/draftStore';
import useNotebookPusher from '../../notebook/NotebookViewController/useNotebookPusher';
import useUploadStore from '../../../stores/uploadStore';
import { shallow } from 'zustand/shallow';
import { isMobilePlatform } from '../../../Utils/window';
import useShareSheetSocket from '../../../hooks/useShareSheetSocket';
import useLayoutStore from '../../../stores/layoutStore';

export const FlowsFeedController = () => {
  const { isFlowsFeedFilterApplied } = useGetParsedFilterOptions();
  const {
    feedContextProps,
    feedData,
    isLoading,
    flowDetails,
    flowDetailsData,
    profileData,
    showLoadMoreButton,
    flowFeedsSortValue,
    flowFeeds,
    handleActionBarClick,
    handleFeedSortClick,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    participantsCount,
    hasMoreMembers,
    isTriggerFlowLoading,
    openRunNowModal,
    flowDetailError,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    onRunNowButtonClick,
    fetchMoreMembers,
    refetchFlowFeed,
    setShowLoadMoreButtonToFalse,
    openArchiveModal,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    setArchiveModalClose,
    handleOnArchiveClick,
    pendingMembersParticipantCount,
    isPreviewModalOpen,
    togglePreviewModalOpen,
    handlePreviewFlowButtonClick,
    handleSetScheduleClick,
    handleEditPostClick,
    handleDismissButtonClick,
    canShowFlowBannerOnFeed,
    handleEditFlowBannerClose,
    handleEditorButtonClick,
    handleErrorDraftBarClick,
    showSamplePosts,
    isMemberFlowParticipant,
    handleShareSheetModalOpen,
  } = useFlowsFeedController();
  const { push } = useHistory();
  const { drafts } = useDraftStore();
  const { postActiveUploads } = useUploadStore(
    (state) => ({
      postActiveUploads: state.postActiveUploads,
    }),
    shallow,
  );

  const { isEmbeddedInMainApp } = useLayoutStore();

  useShareSheetSocket();
  useNotebookPusher();

  const { data, hasNextPage, handleFetchNextPage } = feedData;

  const flowTriggerScheduleDetails =
    flowDetailsData?.schedule?.rule &&
    RRule.parseString(flowDetailsData.schedule.rule);

  const goToMainFeed = () => {
    push(V2_HOME);
  };

  const onLoadMoreClick = useCallback(() => {
    document.getElementById('scrollableFeedContent')?.scroll({
      top: 0,
      behavior: 'smooth',
    });
    refetchFlowFeed();
    setShowLoadMoreButtonToFalse();
  }, [refetchFlowFeed, setShowLoadMoreButtonToFalse]);

  const hasActiveOccurrence = !!flowDetailsData?.hasAnyOccurrences;
  const hasLastOccurrence =
    flowDetailsData?.occurrence.lastOccurrence?.occurrenceId;

  const canShowScheduleEndedBanner = useMemo(() => {
    if (flowDetailsData?.kind === 'SCHEDULED' && !hasActiveOccurrence) {
      if (
        !flowDetailsData?.schedule ||
        (flowDetailsData?.schedule &&
          flowDetailsData?.schedule.status === 'COMPLETED')
      ) {
        return true;
      }
    }
    return false;
  }, [flowDetailsData, hasActiveOccurrence]);

  if (isLoading) {
    return (
      <FeedsContainer>
        <FlowsFeedPostLoader />
      </FeedsContainer>
    );
  }

  let emoticon = '';
  if (flowDetailsData?.icon.value) {
    emoticon = mapHexCodeToEmoticon(flowDetailsData.icon.value);
  }

  const renderEmptyState = () => {
    if (flowDetailsData && data.length === 0) {
      const {
        isFlowOwner,
        isFlowParticipant,
        isFlowViewer,
        isShortcut,
        kind,
        schedule,
        creationSource,
      } = flowDetailsData;
      const isFlowCollaborator = Boolean(
        flowDetailsData?.flowAccessRoles?.includes(
          FlowAccessRoles.Collaborator,
        ),
      );
      const { formattedDate, formattedTime, timeZone } = covertToLocalTime(
        flowDetailsData?.schedule?.nextTimeInUTC ?? '',
      );

      const { formattedNextTriggerDate, formattedNextTriggerTime } =
        getFormattedNextTriggerDateAndTime(schedule?.nextTimeInUTC);

      const renderScheduleFlowEmptyStates = () => {
        const isOneClickFlow = creationSource === CreationSource.OneClickFlow;

        if (!hasLastOccurrence) {
          return (
            <EmptyErrorStateTemplate
              image={setAccountInfo}
              variant={HeadingType.Bold}
              heading={FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.HEADING}
              subHeading={getScheduleFlowEmptyStateSubHeading(
                formattedDate,
                formattedTime,
                timeZone,
                isShortcut,
                isFlowOwner || isFlowCollaborator,
              )}
              {...(isOneClickFlow && {
                button: {
                  text: `${FLOW_FEED_STATES.PREVIEW_FLOW.Text}`,
                  icon: `${FLOW_FEED_STATES.PREVIEW_FLOW.Icon}`,
                  onClick: handlePreviewFlowButtonClick,
                  color: 'secondary',
                },
              })}
            />
          );
        }

        return (
          <EmptyErrorStateTemplate
            image={setAccountInfo}
            variant={HeadingType.Bold}
            heading={
              FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
                .HEADING
            }
            subHeading={getScheduleFlowEmptyStateTriggeredOnceSubHeading(
              formattedNextTriggerDate,
              formattedNextTriggerTime,
              getTimezoneAbbr(flowTriggerScheduleDetails),
              isShortcut,
              isFlowOwner || isFlowCollaborator,
            )}
          />
        );
      };

      if (isFlowsFeedFilterApplied) {
        return (
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
            subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
          />
        );
      }
      if (isFlowOwner || isFlowCollaborator) {
        if (!hasActiveOccurrence) {
          const isOneClickFlow = creationSource === CreationSource.OneClickFlow;
          return (
            <>
              {kind === 'SCHEDULED' && renderScheduleFlowEmptyStates()}
              {kind === 'ONDEMAND' && (
                <EmptyErrorStateTemplate
                  image={setAccountInfo}
                  variant={HeadingType.Bold}
                  heading={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.HEADING}
                  subHeading={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.SUB_HEADING}
                  button={{
                    text: `${FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_TEXT}`,
                    icon: `${FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_ICON}`,
                    onClick: openRunNowModal,
                  }}
                  {...(isOneClickFlow && {
                    secondaryButton: {
                      text: `${FLOW_FEED_STATES.PREVIEW_FLOW.Text}`,
                      icon: `${FLOW_FEED_STATES.PREVIEW_FLOW.Icon}`,
                      onClick: handlePreviewFlowButtonClick,
                      color: 'secondary',
                    },
                  })}
                />
              )}
              {kind === 'NO_TRIGGER' && (
                <EmptyErrorStateTemplate
                  image={searching}
                  variant={HeadingType.Bold}
                  heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
                  subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
                  {...(isOneClickFlow && {
                    button: {
                      text: `${FLOW_FEED_STATES.PREVIEW_FLOW.Text}`,
                      icon: `${FLOW_FEED_STATES.PREVIEW_FLOW.Icon}`,
                      onClick: handlePreviewFlowButtonClick,
                      color: 'secondary',
                    },
                  })}
                />
              )}
              {isPreviewModalOpen && profileData && (
                <FlowsParticipationPreviewController
                  togglePreviewModalOpen={togglePreviewModalOpen}
                  profileInfo={profileData}
                />
              )}
              <FlowRunNowModal
                isOpen={isTriggerFlowModalOpen}
                modalHeading={getFlowNameWithEmoticon(flowDetailsData)}
                onModalClose={setTriggerFlowModalClose}
                value={dueDateValue}
                onChange={handleDueDateValueChange}
                participants={participants}
                participantsCount={participantsCount}
                occurrenceValue={occurrenceValue}
                onOccurrenceChange={setOccurrenceValue}
                onRunNowButtonClick={onRunNowButtonClick}
                isLoading={isTriggerFlowLoading}
                hasMoreMembers={hasMoreMembers}
                fetchNextPage={fetchMoreMembers}
                visibilityCriteria={flowVisibilityCriteria}
                pendingMembersParticipantCount={pendingMembersParticipantCount}
              />
            </>
          );
        }
        return (
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
            subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
          />
        );
      }
      if (isFlowParticipant || isFlowViewer) {
        if (!hasActiveOccurrence) {
          if (kind === 'SCHEDULED') {
            return renderScheduleFlowEmptyStates();
          }
        }
        return (
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
            subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
          />
        );
      }
      return (
        <EmptyErrorStateTemplate
          image={setAccountInfo}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.FLOW_NOT_VISIBLE.HEADING}
          subHeading={FLOW_FEED_STATES.FLOW_NOT_VISIBLE.SUB_HEADING}
        />
      );
    }

    if (!flowDetailsData && flowDetailError) {
      return (
        <FeedsContainer>
          <EmptyErrorStateTemplate
            image={searching}
            variant={HeadingType.Bold}
            heading={FLOW_FEED_STATES.FLOW_NOT_FOUND.HEADING}
            subHeading={FLOW_FEED_STATES.FLOW_NOT_FOUND.SUB_HEADING}
            button={{
              text: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_TEXT}`,
              icon: `${FLOW_FEED_STATES.FLOW_NOT_FOUND.BUTTON_ICON}`,
              onClick: goToMainFeed,
            }}
          />
        </FeedsContainer>
      );
    }

    return null;
  };

  let actionBarText = '';
  let isOccurrenceClosed = false;
  const isAnonymityEnabled =
    flowDetailsData?.responseSettings.anonymity.state ===
    AnonymityStates.ENABLED;

  if (flowDetailsData) {
    const emoji = mapHexCodeToEmoticon(flowDetailsData.icon.value);
    const actionBarAnswerText = `${START_POST} ${emoji} ${flowDetailsData.name}`;
    const actionBarAnsweredText = `${YOU} ${capitalizeFirstLetter(
      ANSWERED,
    )} ${emoji} ${flowDetailsData.name}`;
    const actionBarClosedText = `${emoji} ${flowDetailsData.name} ${IS_CLOSED}`;
    const answerAnonymouslyActionBarText = `${ANONYMOUSLY} ${START_POST.toLowerCase()} ${emoji} ${
      flowDetailsData.name
    }`;

    actionBarText = isAnonymityEnabled
      ? answerAnonymouslyActionBarText
      : actionBarAnswerText;

    if (!flowDetailsData.isShortcut) {
      if (flowDetailsData.occurrence?.activeOccurrence) {
        if (flowDetailsData.occurrence.activeOccurrence.hasResponded) {
          isOccurrenceClosed = true;
          actionBarText = actionBarAnsweredText;
        }
      } else {
        isOccurrenceClosed = true;
        actionBarText = actionBarClosedText;
      }
    }
  }

  const canShowNotification =
    flowDetailsData?.occurrence?.activeOccurrence &&
    !flowDetailsData?.occurrence.activeOccurrence.hasResponded;

  const ScheduleEndedBannerElement = () => {
    if (flowDetailsData) {
      const emoji = mapHexCodeToEmoticon(flowDetailsData.icon.value);
      const { isFlowOwner, isShortcut } = flowDetailsData;
      const isFlowCollaborator = Boolean(
        flowDetailsData?.flowAccessRoles?.includes(
          FlowAccessRoles.Collaborator,
        ),
      );
      if (isFlowOwner || isFlowCollaborator) {
        if (isShortcut) {
          return (
            <Body variant="body3">
              {FLOW_OWNER_ENDED_INFO} {emoji} {flowDetailsData.name} or{' '}
              <LinkButton type="button" onClick={openArchiveModal}>
                {ARCHIVE_IT}
              </LinkButton>
              . {FLOW_OWNER_SHORTCUT_ENDED_INFO}
            </Body>
          );
        }
        return (
          <Body variant="body3">
            {FLOW_OWNER_ENDED_INFO} {emoji} {flowDetailsData.name} or &nbsp;
            <LinkButton type="button" onClick={openArchiveModal}>
              {ARCHIVE_IT}
            </LinkButton>
          </Body>
        );
      }
      if (isShortcut) {
        return <Body variant="body3">{FLOW_SHORTCUT_ENDED_INFO}</Body>;
      }
      return <Body variant="body3">{FLOW_ENDED_INFO}</Body>;
    }

    return null;
  };

  const CustomiseFlowBannerElement = () => {
    return (
      <>
        {isMobilePlatform ? (
          <Body variant="body2" color="gray9">
            {CUSTOMIZE_FLOWS_TEXT.MOBILE}
          </Body>
        ) : (
          <Body variant="body2" color="gray9">
            {CUSTOMIZE_FLOWS_TEXT.DESKTOP}&nbsp;
            <LinkButton
              type="button"
              onClick={handleEditorButtonClick}
              $fontSize="14px"
            >
              {THE} {EDITOR.toLowerCase()}
            </LinkButton>
            &nbsp;{TO} {DO} {SO}.
          </Body>
        )}
      </>
    );
  };

  const linkAccessType = flowDetails?.data.accessPolicy?.linkAccess.type;

  const isFlowCollaborator = Boolean(
    flowDetailsData?.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
  );

  const canShowActionBar =
    flowDetailsData &&
    profileData &&
    (flowDetailsData.isFlowParticipant ||
      flowDetailsData.isFlowOwner ||
      isFlowCollaborator ||
      isMemberFlowParticipant ||
      linkAccessType === LinkAccessType.EXTERNAL ||
      linkAccessType === LinkAccessType.INTERNAL);

  const renderTooltipContent = () => {
    if (
      (flowDetailsData?.isFlowOwner || isFlowCollaborator) &&
      !flowDetailsData?.isFlowParticipant
    ) {
      return (
        <div style={{ width: '413px', height: '40px' }}>
          <span>
            You own this flow but you do not have permission to post in it. You
            can give yourself permission to post any time.{' '}
            <StyledClickable onClickCallback={handleShareSheetModalOpen}>
              Click here
            </StyledClickable>{' '}
            to change permissions.
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <FeedsContainer style={{ paddingTop: '16px' }}>
      {canShowScheduleEndedBanner && (
        <StyledInfoAlert
          id="flow-state-info-message"
          text={ScheduleEndedBannerElement()}
          icon="info-circle"
          alertType="info"
        />
      )}
      {canShowFlowBannerOnFeed && (
        <StyledInfoAlert
          id="customise-flow-banner"
          text={CustomiseFlowBannerElement()}
          icon="info-outlined"
          alertType="info"
          isDismissible={{
            onCloseClick: handleEditFlowBannerClose,
            onCloseKeyPress: handleEditFlowBannerClose,
          }}
        />
      )}
      {canShowActionBar && (
        <StyledActionBarWrapper id="intro-flows-feed-step-2">
          <ActionBarContainer
            avatar={profileData.member.profile.image}
            userName={profileData.member.profile.username}
            userId={profileData.member.memberId}
            feedType={FeedTypes.Flow}
            notification={
              canShowNotification
                ? `${FLOW_ACTION_BAR_NOTIFICATION_TEXT} ● Due in ${getDueTime(
                    flowDetailsData.occurrence?.activeOccurrence?.endTime,
                  )}`
                : ''
            }
            text={actionBarText}
            onClick={handleActionBarClick}
            isDisabled={
              linkAccessType === LinkAccessType.EXTERNAL
                ? isOccurrenceClosed
                : isOccurrenceClosed ||
                  !(
                    flowDetailsData.isFlowParticipant || isMemberFlowParticipant
                  )
            }
            isAnonymous={isAnonymityEnabled}
            hasDraft={
              flowDetailsData.flowId && !isOccurrenceClosed
                ? Boolean(drafts[flowDetailsData.flowId])
                : false
            }
            tooltipContent={renderTooltipContent()}
          />
        </StyledActionBarWrapper>
      )}
      {flowDetailsData?.showScheduleCard && (
        <FlowPromptCard
          status={FlowPromptCardStatus.Schedule}
          title="Set a schedule for this flow"
          emoticon="⏰"
          dropdownMenuItems={dismissCardMenuItems}
          dropdownItemClick={handleDismissButtonClick}
          feedType={FeedTypes.Flow}
          dataTestId="setScheduleForFlowCard"
          description={
            isMobilePlatform
              ? SCHEDULE_CARD_TEXT.MOBILE
              : SCHEDULE_CARD_TEXT.DESKTOP
          }
          onAnswerClick={isMobilePlatform ? undefined : handleSetScheduleClick}
        />
      )}
      {data && data.length > 0 && (
        <Flex justifyContent="space-between" margin="0 auto 4px">
          <div />
          <div>
            <StyledDropdown
              menuItems={feedSortItems}
              size={DropdownSizes.Small}
              variant={DropdownVariants.Text}
              color={DropdownColor.Secondary}
              value={PostSortValues[flowFeedsSortValue]}
              dropdownPlacement={DropdownPlacement.BottomEnd}
              onItemClick={(val) => handleFeedSortClick(val)}
            />
          </div>
        </Flex>
      )}
      {Object.keys(postActiveUploads).length > 0 && (
        <StyledPostLoading>
          <StyledPostLoadingText variant="body1Medium" color="geekBlue6">
            {POST_LOADING}
          </StyledPostLoadingText>
          <StyledSpinningLoader scale={0.2} />
        </StyledPostLoading>
      )}
      {flowDetailsData?.flowId &&
        Boolean(
          useDraftStore.getState().errorDrafts[flowDetailsData?.flowId],
        ) && (
          <ErrorDraftBanner onClick={handleErrorDraftBarClick}>
            <Flex flexGrow={1} justifyContent="space-between">
              <Body color="dustRed6" variant="body1Medium">
                Failed to post in{' '}
                <EmoticonWithText
                  emoticon={emoticon}
                  text={flowDetailsData?.name}
                  hasBoldText
                />
              </Body>
              <Flex margin="0 12px 0 0">
                <EditIcon color="gray8" size="16px" icon="edit" />
                <Body color="gray8" variant="body2">
                  Edit to try again
                </Body>
              </Flex>
            </Flex>
            <Flex
              onClick={(e) => {
                e.stopPropagation();
                useDraftStore
                  .getState()
                  .deleteErrorDraft(flowDetailsData?.flowId);
              }}
            >
              <DeleteIcon
                color={ThemeV2.palette.dustRed6}
                size="16px"
                icon="delete"
              />
            </Flex>
          </ErrorDraftBanner>
        )}
      {renderEmptyState()}
      <BasicModalTemplate
        isOpen={isArchiveModalOpen}
        heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
          onClick: handleOnArchiveClick,
          status: 'warning',
          isLoading: isArchiveFlowLoading,
        }}
        secondaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
          onClick: setArchiveModalClose,
        }}
        onModalClose={setArchiveModalClose}
      >
        <Body variant="body2" inline>
          {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
        </Body>
        <Body variant="body2Medium" inline>
          <EmoticonWithText
            emoticon={emoticon}
            text={flowDetailsData?.name}
            hasBoldText
          />
        </Body>
        <Body variant="body2" inline>
          {', '}
          {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
        </Body>
      </BasicModalTemplate>
      {profileData && data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          hasMore={hasNextPage}
          dataLength={data.length}
          loader={<FeedPostLoader />}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {showLoadMoreButton && (
            <ShowMoreButton
              size="small"
              variant="showMore"
              onClick={onLoadMoreClick}
              icon="arrow-thick-up"
            >
              {SEE_NEW_POSTS}
            </ShowMoreButton>
          )}
          <FeedContext.Provider value={feedContextProps}>
            {flowFeeds.map((feed) => (
              <FlowResponseController
                key={feed.responseId}
                response={feed}
                profileData={profileData}
                activeAnnouncement={feed.activeAnnouncement}
                handleEditPostClick={handleEditPostClick}
                showEditOption={feed.canEdit}
                hideInteractions={showSamplePosts}
                hideRepliesBar={feed.hideReplies}
                hideReactions={feed.hideReactions}
                isSamplePost={showSamplePosts}
                showPostInteractionSettings={
                  isEmbeddedInMainApp && feed?.showTurnOffSettings
                }
                canEndAnnouncement={Boolean(feed.canEndAnnouncement)}
                canEditAnnouncement={Boolean(feed.canEditAnnouncement)}
                canShareAsAnnouncement={Boolean(feed.canShareAsAnnouncement)}
                canViewAnnouncementInsights={Boolean(
                  feed.activeAnnouncement?.showInsights,
                )}
              />
            ))}
          </FeedContext.Provider>
        </InfiniteScroll>
      )}
    </FeedsContainer>
  );
};
