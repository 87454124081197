import { TasksAutocomplete } from '../../../atomic/organism/Notebook/TasksAutocomplete';
import { AutocompleteViewProps } from './interfaces';
import { useNotebookSearch } from '../../useNotebookSearch';

export const AutocompleteView = ({
  onClose,
  onCreateTaskClick,
  onOptionSelect,
}: AutocompleteViewProps) => {
  const {
    models: {
      currentUserTimezone,
      isError,
      isLoading,
      taskOptions,
      value: tasksSearchValue,
    },
    operations: { onChange, refetch },
  } = useNotebookSearch();
  const handleTasksAutocompleteValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onChange(e.target.value);
  };
  return (
    <TasksAutocomplete
      currentUserTimezone={currentUserTimezone}
      isError={isError}
      isLoading={isLoading}
      onChange={handleTasksAutocompleteValueChange}
      onCreateTaskClick={onCreateTaskClick}
      onTryAgain={refetch}
      onClose={onClose}
      onOptionSelect={onOptionSelect}
      options={taskOptions}
      value={tasksSearchValue}
    />
  );
};
