import { getFlowPerms } from '../../flows/feeds';

import { StepProps, ParticipationAnalyticProps } from './types';

export const mapFlowDetailsToParticipationEventProps = (
  data: ParticipationAnalyticProps,
) => {
  const {
    name,
    action,
    templateId,
    currentStep,
    editedBlockIds,
    numberOfBlocksEdited,
    flowId = '',
    createdAt = '',
    updatedAt = '',
    owner = {
      memberId: '',
      role: [],
    },
    stepData = [],
    isShortcut = false,
    isFlowOwner = false,
    isFlowViewer = false,
    isExternalFlow = false,
    isFlowParticipant = false,
    taskType,
    internalParticipant,
    numFilesUploaded,
    fileType,
    fileSize,
    totalFileSize,
    kind: trigger,
  } = data;

  const { memberId = '', role = [] } = owner;

  let numBlocksRequired = 0;

  const blockTypes = stepData.map((step: StepProps) => {
    if (step.isRequired) {
      numBlocksRequired += 1;
    }

    return step.type ? step.type : '';
  });

  const flowPerms = getFlowPerms({
    isFlowOwner: isFlowOwner || false,
    isFlowViewer: isFlowViewer || false,
    isFlowParticipant: isFlowParticipant || false,
  });

  return {
    flowId,
    action,
    flowPerms,
    blockTypes,
    templateId,
    currentStep,
    isExternalFlow,
    flowName: name,
    numVersions: 0,
    numOccurrences: 0,
    numBlocksRequired,
    trigger,
    flowOwnerEmail: '',
    shortcut: isShortcut,
    flowOwnerId: memberId,
    publishedDate: createdAt,
    flowOwnerPermissions: role,
    lastOccurrenceDate: updatedAt,
    firstOccurrenceDate: createdAt,
    numBlocks: (stepData || []).length,
    taskType,
    ...(editedBlockIds && { editedBlockIds }),
    ...(numberOfBlocksEdited && { numberOfBlocksEdited }),
    internalParticipant,
    numFilesUploaded,
    fileType,
    fileSize,
    totalFileSize,
  };
};
