import React, { useCallback, useMemo } from 'react';
import CalendarInput from '../../../CalendarInput';
import ThemeV2 from '../../../../../theme';
import {
  CustomTriggerButton,
  DueDateLabel,
  TaskItemDatePickerWrapper,
  CustomTriggerIcon,
} from '../styles';
import { TaskCategories } from '../../../../../interfaces/notebook';
import { capitalizeFirstLetter } from '../../../../../Utils/text';
import {
  SET_DATE,
  TODAY,
  TOMORROW,
  DUE_DATE_TODAY,
  SET_A_DUE_DATE,
  REMOVE_DATE,
  NO_DATE_SET,
} from '../../../../../languages/en/notebook';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import { FORMAT_DATE } from '../../../../../constants/notebook';
import {
  convertTZ,
  getCurrentDateForTimeZone,
} from '../../../../../Utils/date';
import { getDueDateBasedButtonColor } from '../../../../../controllers/notebook/NotebookViewController/utils';

interface TaskItemDatePickerProps {
  dueDate: string | undefined;
  setDueDate: (updatedDueDate: string | undefined) => void;
  taskType: TaskCategories;
  stopEventPropagation?: boolean;
  isDeleted: boolean;
  currentUserTimezone: string;
  isReadOnly?: boolean;
}

const iconMap = {
  [TaskCategories.COMPLETED]: {
    icon: 'check',
    color: ThemeV2.palette.green7,
  },
  [TaskCategories.ARCHIVED]: {
    icon: 'close',
    color: ThemeV2.palette.red6,
  },
};

const TaskItemDatePicker = ({
  dueDate,
  taskType,
  setDueDate,
  stopEventPropagation,
  isDeleted,
  currentUserTimezone,
  isReadOnly,
}: TaskItemDatePickerProps) => {
  const date = useMemo(
    () => (dueDate ? convertTZ(dueDate, currentUserTimezone) : undefined),
    [dueDate, currentUserTimezone],
  );
  const customTriggerIcon = useMemo(() => {
    if (isDeleted)
      return (
        <CustomTriggerIcon
          icon="delete"
          size="16px"
          color={`${ThemeV2.palette.dustRed6}`}
        />
      );
    if (
      taskType === TaskCategories.ARCHIVED ||
      taskType === TaskCategories.COMPLETED
    ) {
      return (
        <CustomTriggerIcon
          icon={`${iconMap[taskType].icon}-rounded`}
          size="16px"
          color={`${iconMap[taskType].color}`}
        />
      );
    }
    return null;
  }, [isDeleted, taskType]);

  const customTriggerBackgroundColor = useMemo(() => {
    return (
      getDueDateBasedButtonColor(
        date?.toISOString(),
        taskType,
        currentUserTimezone,
      ) || {
        background: ThemeV2.palette.gray3,
        hover: ThemeV2.palette.gray4,
        color: ThemeV2.palette.gray9,
      }
    );
  }, [date, taskType, currentUserTimezone]);

  const handleOnChange = useCallback(
    (value?: Date | undefined) =>
      setDueDate(value ? value.toISOString() : undefined),
    [setDueDate],
  );

  const dueDateText = useMemo(() => {
    if (isDeleted)
      return {
        text: `${format(
          getCurrentDateForTimeZone(currentUserTimezone),
          FORMAT_DATE,
        )}`,
      };
    if (!date && taskType === TaskCategories.UNSCHEDULED)
      return {
        text: isReadOnly ? NO_DATE_SET : SET_DATE,
        color: 'gray6',
      };
    if (date) {
      const noOfDaysTillDueDate = differenceInCalendarDays(
        date,
        getCurrentDateForTimeZone(currentUserTimezone),
      );
      if (taskType === TaskCategories.TODAY)
        return {
          text: capitalizeFirstLetter(TODAY),
        };
      if (noOfDaysTillDueDate === 1)
        return {
          text: capitalizeFirstLetter(TOMORROW),
        };

      return {
        text: `${format(date, FORMAT_DATE)}`,
      };
    }

    return {
      text: `${format(
        getCurrentDateForTimeZone(currentUserTimezone),
        FORMAT_DATE,
      )}`,
    };
  }, [currentUserTimezone, date, isDeleted, taskType, isReadOnly]);
  const triggerComponent = useMemo(
    () => ({
      primaryTriggerComponent: (
        <CustomTriggerButton
          customTriggerBackgroundColor={customTriggerBackgroundColor.background}
          customTriggerHoverColor={customTriggerBackgroundColor.hover}
        >
          {customTriggerIcon}
          <DueDateLabel
            inline
            variant="body2"
            color={customTriggerBackgroundColor.color}
          >
            {dueDateText.text}
          </DueDateLabel>
        </CustomTriggerButton>
      ),
    }),
    [customTriggerBackgroundColor, customTriggerIcon, dueDateText],
  );

  return (
    <TaskItemDatePickerWrapper
      data-testid="task-date-picker"
      data-qa-id="task-date-picker"
    >
      <CalendarInput
        name="name2"
        value={date}
        onChange={handleOnChange}
        placeholder={DUE_DATE_TODAY}
        showDropdownIcon={false}
        hideCalendarIcon
        disabled={
          taskType === TaskCategories.COMPLETED ||
          taskType === TaskCategories.ARCHIVED ||
          isDeleted ||
          isReadOnly
        }
        triggerComponent={triggerComponent}
        heading={SET_A_DUE_DATE}
        footerProps={{
          disabled: taskType === TaskCategories.UNSCHEDULED,
          footerLabel: REMOVE_DATE,
          onFooterClick: () => {
            setDueDate(undefined);
          },
        }}
        stopEventPropagation={stopEventPropagation}
        timezone={currentUserTimezone}
      />
    </TaskItemDatePickerWrapper>
  );
};

export default TaskItemDatePicker;
