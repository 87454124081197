import {
  MenuItemProps,
  MenuItemIndividualItem,
} from '../atomic/molecules/Dropdown_V2/interfaces';

export const getDropDownOptions = (
  dropdownOptions: MenuItemProps[],
  isCurrentUserAdmin: boolean,
  canRemoveGif: boolean,
  canDelete: boolean,
): MenuItemProps[] => {
  const items = dropdownOptions[0].items.reduce<MenuItemIndividualItem[]>(
    (result, option) => {
      const { id } = option;
      if (isCurrentUserAdmin || canDelete) {
        if (id === 'remove-gif' && canRemoveGif) {
          result.push(option);
        } else if (id === 'delete' && canDelete) {
          result.push(option);
        }
      }
      if (id === 'copy-link') {
        result.push(option);
      }
      return result;
    },
    [],
  );
  return [{ ...dropdownOptions[0], items }];
};

export const getDropDownOptionsForRepliesSidebar = (
  dropdownOptions: MenuItemProps[],
  isCurrentUserAdmin: boolean,
  canRemoveGif: boolean,
  canDelete: boolean,
  canEdit: boolean,
): MenuItemProps[] => {
  const items = dropdownOptions[0].items.reduce<MenuItemIndividualItem[]>(
    (result, option) => {
      const { id } = option;
      if (isCurrentUserAdmin || canDelete) {
        if (id === 'remove-gif' && canRemoveGif) {
          result.push(option);
        } else if (id === 'delete' && canDelete) {
          result.push(option);
        } else if (id == 'edit-reply' && canEdit) {
          result.push(option);
        }
      }

      if (id === 'copy-link') {
        result.push(option);
      }

      return result;
    },
    [],
  );
  return [{ ...dropdownOptions[0], items }];
};

export const isGifURLPresent = (gifURL?: string) =>
  gifURL !== '' && gifURL !== null && gifURL !== undefined;
