import styled, { css } from 'styled-components';
import ThemeV2 from '../../../theme';

/*TODO: This needs work to get updated with the new windowSizeAdapter,
but leaving it for now since it doesn't effect the mobile app directly*/

export const HomeDrawerWrapper = styled.div<{
  isMobileApp: boolean;
  isKioskTurnedOn: boolean;
}>`
  width: 100%;
  height: ${({ isKioskTurnedOn, isMobileApp }) => {
    if (isKioskTurnedOn) {
      return 'calc(100vh - 114px)';
    }

    return isMobileApp ? '100vh' : 'calc(100vh - 72px)';
  }};
`;

export const DrawerBottomContent = styled.div<{
  isHoveredWhenCollapsed: boolean;
  onScroll: (...args: any[]) => any;
}>`
  overflow: hidden;

  padding: 16px 0 40px 0;
  overflow-y: auto;

  ${({ isHoveredWhenCollapsed }) => {
    if (isHoveredWhenCollapsed) {
      return css`
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04),
          0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12);
        padding: 16px 16px 40px 0;
      `;
    }
  }}

  @media (min-width: ${ThemeV2.breakPoints.sm}) {
    height: 100%;
    overflow-y: auto;
  }
`;
