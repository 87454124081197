import {
  ADDED,
  AND_COMMENTED_ON_POST,
  AND_SAID,
  COMMENTED_ON_POST,
  DEACTIVATED_USER,
  GAVE_YOU,
  HAPPY_ANNIVERSARY,
  HAPPY_BIRTHDAY,
  MENTIONED_IN_COMMENT,
  MENTIONED_IN_POST,
  RECOGNIZED_YOU,
} from '../../languages/en/notifications';
import { SOMEONE } from '../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../text';

export const getBirthdayMessage = (name: string) =>
  `${HAPPY_BIRTHDAY} ${name}: `;
export const getAnniversaryMessage = (name: string) =>
  `${HAPPY_ANNIVERSARY} ${name}: `;
export const getUserMentionInPost = (name: string) =>
  `${name} ${MENTIONED_IN_POST}`;
export const getUserRecognizedInPost = (name: string) =>
  `${name} ${RECOGNIZED_YOU} ${AND_SAID}`;
export const getDeletedUserGave = (howMuch: string, isAnonymous: boolean) =>
  `${
    isAnonymous ? capitalizeFirstLetter(SOMEONE) : DEACTIVATED_USER
  } ${GAVE_YOU} ${howMuch} ${AND_SAID}`;
export const getUserGave = (name: string, howMuch: string) =>
  `${name} ${GAVE_YOU} ${howMuch} ${AND_SAID}`;
export const getUserMentionInComment = (name: string) =>
  `${name} ${MENTIONED_IN_COMMENT}`;
export const getDeletedUserAddedAndCommented = (howMuch: string) =>
  `${DEACTIVATED_USER} ${ADDED} ${howMuch} ${AND_COMMENTED_ON_POST}`;
export const getUserAddedAndCommented = (name: string, howMuch: string) =>
  `${name} ${ADDED} ${howMuch} ${AND_COMMENTED_ON_POST}`;
export const getUserCommented = (name: string) =>
  `${name} ${COMMENTED_ON_POST}`;

export const COMMENTED_ON_YOUR_POST = 'commented on your post in';
export const REPLIED_IN_A_THREAD_IN = 'replied in a thread in';
export const MENTIONED_YOU_IN_FLOW = 'mentioned you in';
export const MENTIONED_YOU_IN_FLOW_COMMENT = 'mentioned you in a comment in';
