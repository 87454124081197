import styled from 'styled-components';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

export const HeaderWrapper = styled(Flex)`
  margin-top: 4px;
  padding: 16px 18px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
`;

export const HeadingTextWrapper = styled(Flex)`
  min-width: 0;
`;

export const BodyWrapper = styled(Flex)`
  flex-direction: column;
  padding: 16px 18px;
`;

export const HeadingText = styled(Body)`
  max-width: 80vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PrimaryButton = styled(Button)`
  margin: 0 16px;
`;

export const ShareSheetContainer = styled.div`
  display: grid;
  height: auto;
  grid-template-rows: auto 1fr auto;
`;
