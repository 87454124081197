/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getErrorMessage } from '../../../../Utils/message';
import { showErrorMessage, showSuccessMessage } from '../../../../Utils/toast';
import { LeftDrawerCollapsibleNavItemProps } from '../../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import useToggle from '../../../../hooks/useToggle';
import { FLOW_UNARCHIVE_RESPONSE_MESSAGE } from '../../../../languages/en/flows/feed';
import {
  useFetchArchivedFlows,
  useTriggerUnArchiveFlow,
} from '../../../../queries/Flows/FlowFeedOptions/RunFlow';
import { mapFlowsArchivedResponseToNavItems } from '../utils';

const useFlowsArchivedListController = () => {
  const [unArchivingFlowDetails, setUnArchivingFlowDetails] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);

  const {
    models: { toggleValue: isOpen },
    operations: { setToggleValue },
  } = useToggle();

  const {
    models: { toggleValue: isArchiveModalOpen },
    operations: {
      setToggleToTrue: setUnArchiveModalOpen,
      setToggleToFalse: setUnArchiveModalClose,
    },
  } = useToggle();

  const { data } = useFetchArchivedFlows();
  const { mutate: triggerUnArchiveFlow, isLoading: isUnArchiveModalLoading } =
    useTriggerUnArchiveFlow();

  const archiveFlowsNavItems: LeftDrawerCollapsibleNavItemProps[] = useMemo(
    () =>
      data
        ? data.data
            .filter((flow) => flow.isFlowOwner)
            .map(mapFlowsArchivedResponseToNavItems)
        : [],
    [data],
  );

  const onMenuItemClick = (flowId: string) => {
    const getUnArchiveFlowItem = archiveFlowsNavItems.find(
      (flow) => flow.id === flowId,
    );
    setUnArchivingFlowDetails(getUnArchiveFlowItem);
    setUnArchiveModalOpen();
  };

  const onModalClose = () => {
    setUnArchiveModalClose();
  };

  const { search } = useLocation();
  const showUnarchiveFlowModal = Boolean(
    new URLSearchParams(search).get('showUnarchiveFlowModal'),
  );
  const flowIdForMobileUnarchiveAction =
    new URLSearchParams(search).get('flowId') || '';

  useEffect(() => {
    if (showUnarchiveFlowModal) {
      const getUnArchiveFlowItem = archiveFlowsNavItems.find(
        (flow) => flow.id === flowIdForMobileUnarchiveAction,
      );
      setUnArchivingFlowDetails(getUnArchiveFlowItem);
      setUnArchiveModalOpen();
    }
  }, [
    archiveFlowsNavItems,
    flowIdForMobileUnarchiveAction,
    setUnArchiveModalOpen,
    showUnarchiveFlowModal,
  ]);

  const onUnArchiveClick = () =>
    new Promise((resolve, reject) => {
      triggerUnArchiveFlow(
        { flowId: unArchivingFlowDetails?.id || '' },
        {
          onError: (error) => {
            const errorMessage = getErrorMessage(
              error as AxiosError<{ message: string; body: string }>,
              FLOW_UNARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
            showErrorMessage(
              errorMessage || FLOW_UNARCHIVE_RESPONSE_MESSAGE.ERROR,
            );
            reject();
          },
          onSuccess: () => {
            showSuccessMessage(FLOW_UNARCHIVE_RESPONSE_MESSAGE.SUCCESS);
            setUnArchiveModalClose();
            resolve(true);
          },
        },
      );
    });

  return {
    models: {
      isOpen,
      archiveFlowsNavItems,
      isArchiveModalOpen,
      isUnArchiveModalLoading,
      unArchivingFlowDetails,
    },
    operations: {
      onExpandClick: setToggleValue,
      onMenuItemClick,
      onUnArchiveClick,
      onModalClose,
    },
  };
};

export default useFlowsArchivedListController;
