import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { ShareSheetRuleAccessTypes } from '../../controllers/flows/FlowsShareSheetController/types';
import { PageType } from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import { MemberRole } from '../../interfaces/member';
import { MiscTaskCategories, TaskCategories } from '../../interfaces/notebook';
import {
  ACTION_EVENTS,
  FEED_ANALYTICS_EVENTS,
  NOTEBOOK_TASK_EVENTS,
  POST_ANALYTICS_EVENTS,
  RIGHT_DRAWER_EVENTS,
} from './constants';

export enum RegistrationMethods {
  Google = 'Google',
  ADP = 'ADP',
  Slack = 'Slack',
  Email = 'Email',
}

export type EventPropsType = {
  [key: string]:
    | null
    | Date
    | string
    | number
    | boolean
    | undefined
    | Array<string>
    | Record<string, unknown>;
};

export type BaseEventProps = {
  show?: string;
  error?: string;
  action?: string;
  event?: (typeof ACTION_EVENTS)[keyof typeof ACTION_EVENTS];
};

export interface UserProps {
  $name: string;
  $email: string;
  $distinct_id: string;
  'Registration Date': string;
  'Registration Method': RegistrationMethods;
  'Company Plan Type': string;
  'Allowance Points Available': number;
  isAdmin: boolean;
  isManager: boolean;
  Department: string;
  'Allowance Type': string;
  'User Status': string;
  'Total Allowance': number;
  'Total Points Given': number;
  canSendPrivate: boolean;
  Platform: string;
  Birthday: string;
  Anniversary: string;
  isPaid: boolean;
  planType: string;
  managesPeople?: boolean;
  role: string[];
  memberId: string;
}

export interface SuperProps {
  Name: string;
  Email: string;
  'Company Plan Type': string;
  'Allowance Points Available': number;
  canSendPrivate: boolean;
  Platform: string;
  isAdmin: boolean;
  isManager: boolean;
  Department: string;
  'Allowance Type': string;
  'User Status': string;
  'Total Allowance': number;
  'Total Points Given': number;
  active: number;
  completed: number;
  archived: number;
  whitelistEnabled: boolean;
  whitelistedDomains: string[];
  hris: string;
  numWorkspacesActive: number;
  assemblyID: string;
}

export enum FlowState {
  Default = 'default',
  ResponsePrompted = 'responsePrompted',
  UnreadResponses = 'unreadResponses',
}

export type LeftDrawerEventProps = BaseEventProps & {
  notificationTypeClicked?: undefined | string;
  flowId?: string;
  flowName?: string;
  flowPerms?: FlowPermissions[];
  flowIsDue?: boolean;
  flowOccurrenceRunning?: boolean;
  flowState?: FlowState;
  isArchived?: boolean;
  postId?: string;
  responseId?: string;
  folder?: 'customFolder' | 'flows';
};

export type PostAnalyticsEventProps = BaseEventProps & {
  flowId: string;
  flowName?: string;
  isArchived?: boolean;
  feedViewed: PageType;
  postId: string | null;
  numPostComments: number;
  numPostReactions: number;
  responseId: string | null;
  flowPerms?: 'owner' | 'participant' | 'viewer';
  commentMediaAttached?: 'mention' | 'gif' | 'emoji';
};

export type FlowBuilderEventProps = BaseEventProps & {
  dueDate?: number;
  shortcut?: boolean;
  flowTitle?: string;
  numBlocks?: number;
  flowEmoji?: string;
  lastEditDate?: Date;
  templateId?: string;
  templateName?: string;
  flowOwnerId?: string;
  flowEdited?: boolean;
  actionSelected?: string;
  numOccurrences?: number;
  flowOwnerEmail?: string;
  flowDescription?: string;
  lastOccurrenceDate?: Date;
  triggerRecurrence?: string;
  firstOccurrenceDate?: Date;
  numBlocksRequired?: number;
  contentBlockType?: string[];
  triggerTypeSelected?: string;
  flowSectionsEdited?: string[];
  flowOwnerPermissions?: string[];
  viewerCriteriaSelected?: string[];
  triggerSettingsConfigured?: string;
  participantCriteriaSelected?: string[];
  scheduleStartDateSelected?: string;
  scheduleStartTimeSelected?: string;
  scheduleEndDateSelected?: string;
  scheduleEndTimeSelected?: string;
  recurrenceSelected?: string;
  dueDateSelected?: string;
  numCollaborators?: number;
};

export type OnboardingEventProps = BaseEventProps & {
  clickPath?: string;
  completeOnboarding?: string;
};

export type FlowInsightsEventProps = BaseEventProps & {
  tab?: string;
  blockType?: string;
  specificFilter?: string[];
  filter?: Record<string, string[] | Date | undefined>;
};

export type DrawerTabType = 'filter' | 'about' | 'people' | 'leaderboards';

export type RightDrawerEventProps = BaseEventProps & {
  feedViewed: PageType;
  drawerTabViewed: DrawerTabType;
  filterApplied?: Array<string> | string;
  flowId?: string;
  flowPerms?: Array<string> | string;
  flowName?: string;
};
export type FlowPermissions = 'owner' | 'participant' | 'viewer';

export type FeedAnalyticsEventProps = BaseEventProps & {
  feedSort?: string;
  flowPerms?: FlowPermissions[] | null;
  flowName?: string;
  flowId?: string;
  templateId?: string;
};

export type PostEventNameType =
  (typeof POST_ANALYTICS_EVENTS)[keyof typeof POST_ANALYTICS_EVENTS];
export type FeedEventNameType =
  (typeof FEED_ANALYTICS_EVENTS)[keyof typeof FEED_ANALYTICS_EVENTS];
export type RightDrawerEventNameType =
  (typeof RIGHT_DRAWER_EVENTS)[keyof typeof RIGHT_DRAWER_EVENTS];
export type NotebookTaskEventNameType =
  (typeof NOTEBOOK_TASK_EVENTS)[keyof typeof NOTEBOOK_TASK_EVENTS];

export type NotebookSectionalAnalyticsProps = {
  section?: TaskCategories | MiscTaskCategories | string;
  todayTasks?: string[];
  overdueTasks?: string[];
  upcomingTasks?: string[];
  unscheduledTasks?: string[];
  completedTasks?: string[];
  archivedTasks?: string[];
  lastSection?: TaskCategories | MiscTaskCategories | string;
  type?: string;
  action?: string;
  show?: string;
  error?: string;
  source?: string;
  subSource?: string;
  errorIds?: string[];
  view?: string;
  sectionName?: string;
  lastSectionName?: string;
  completedFilter?: string;
  sectionId?: string;
};

export type NotebookTaskAnalyticsBaseProps = {
  taskId?: string;
  creatorId?: string;
  assigneeId?: string;
  dueDate?: string;
  creationDate?: string;
  source?: string;
  subSource?: string;
  agent?: string;
  type?: string;
  title?: string;
  status?: string;
  lastTitle?: string;
  lastDueDate?: string;
  lastStatus?: string;
  lastAssigneeId?: string;
  titleLinkURL?: string[];
  tab?: string;
};

export type NotebookTaskAnalyticsProps = NotebookTaskAnalyticsBaseProps & {
  action?: NotebookTaskEventNameType;
  show?: NotebookTaskEventNameType;
  error?: NotebookTaskEventNameType;
};

export type InviteMembersEventProps = BaseEventProps & {
  inviterId?: string;
  inviteesEmails?: string[];
  inviterRole?: MemberRole[];
  source?: string;
  subSource?: string | string[];
};

export type TemplateGalleryEventsProps = BaseEventProps & {
  templateId?: string;
  templateName?: string;
  searchQuery?: string;
  filterApplied?: string;
  source?: string;
};

export type DiscoverEventProps = BaseEventProps & {
  profileMenuItem?: string;
  v3enabled: boolean;
};

export type CardEventProps = BaseEventProps & {
  isRepliesDisabled: boolean;
  isReactionsDisabled: boolean;
};

export type PeopleDirectoryEventProps = BaseEventProps & {
  searchQuery?: string;
};

export type ShareRequestRole = 'participant' | 'viewer' | 'participantViewer';

export type TrackLeftDrawerEventProps = LeftDrawerEventProps &
  Partial<LeftDrawerCollapsibleNavItemProps>;

export type ShareSheetEventProps = BaseEventProps & {
  email?: string;
  flowName?: string;
  channelType?: string;
  modalVariant?: string;
  newLinkSettings?: any;
  app?: 'slack' | 'teams';
  viewerCriteriaSelected?: any;
  shareFlowStartSource?: string;
  participantCriteriaSelected?: any;
  responseNotificationChannel?: string;
  tab: 'invite and share' | 'connections';
  participationNotificationChannel?: string;
  ownerId?: string;
  ruleType?: ShareSheetRuleAccessTypes;
  shareRequestRole?: ShareRequestRole;
  numShareRequests?: number;
};

export type PostEventProps = BaseEventProps & {
  postId?: string;
  flowId?: string;
  slugUrl?: string;
  type?: PostTypes;
  reaction?: string;
  replyId?: string;
  flowName?: string;
  memberId?: string;
  responseId?: string;
  templateId?: string;
  templateName?: string;
  messageType?: 'replies' | 'directMessages';
};

export type KnowledgeCenterEventProps = BaseEventProps & {
  searchQuery?: string;
  filterApplied?: string[];
  view?: string;
};

export type FileViewerEventProps = BaseEventProps & {
  source?: string;
};

export type AxomoEventProps = BaseEventProps & {
  swagItem?: string;
  swagTabOn?: boolean;
  swagItemOn?: boolean;
  shippingCost?: number;
  swagItemCost?: number;
  swagItemQuantity?: number;
  view?: string;
};

export type ActionBarEventProps = BaseEventProps & {
  flowId?: string;
  searchText?: string;
  numToDoFlows?: number;
  numShortcutFlows?: number;
};
