import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import FlowFeedPost from '../../../atomic/organism/FlowFeedPost';
import { getFeedPostHeaderMenuOptions } from '../../../atomic/organism/FlowFeedPost/data';
import FlowsPostHeaderOption from '../../../atomic/organism/FlowsPostHeaderOption';
import useRepliesBar from '../../../hooks/useRepliesBar';
import {
  FeedItemFromAPI,
  FeedItemKind,
  FeedItemMetaData,
} from '../../../interfaces/Feed';
import { PostTypes } from '../../../interfaces/Home';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useFlowPostController from './useFlowPostController';
import useLayoutStore from '../../../stores/layoutStore';

type FlowPostControllerProps = {
  index?: number;
  post: FeedItemFromAPI;
  profileData?: GetProfileInfoResponse;
  handleEditPostClick?: (flow: FeedItemMetaData) => void;
  hideInteractions?: boolean;
  isExpandedOnLoad?: boolean;
  showEditOption?: boolean;
  hideRepliesBar?: boolean;
  hideReactions?: boolean;
  hideBorder?: boolean;
  variant?: 'feed' | 'sidebar';
  showPostInteractionSettings: boolean;
  handlePostInteractionSettingsClick?: () => void;
  canShareAsAnnouncement?: boolean;
  canEditAnnouncement?: boolean;
  canEndAnnouncement?: boolean;
  canViewAnnouncementInsights?: boolean;
};

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const FlowPostController = (props: FlowPostControllerProps) => {
  const {
    index = 0,
    profileData,
    handleEditPostClick,
    hideInteractions,
    isExpandedOnLoad,
    hideBorder,
    showEditOption,
    hideRepliesBar,
    hideReactions,
    variant,
    showPostInteractionSettings,
    handlePostInteractionSettingsClick,
    canEndAnnouncement,
    canShareAsAnnouncement,
    canEditAnnouncement,
    canViewAnnouncementInsights,
  } = props;

  const {
    hasTrophies,
    showDeleteOption,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    fileDownloadError,
    fileToDownload,
    refetchFileDownloadLink,
    isConvertedPdfPreviewsEnabled,
    handleSetFilePreview,
    handleDeletePostOptionChange,
    onCopyLinkClick,
    onFlowClick,
    handleMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    handleReactionSet,
    handleReactionUnSet,
    onDeleteModalSubmitClick,
    feedProps,
    onGotoPostLinkClick,
    notificationPreference,
    onNotificationPreferenceClick,
    currency,
    handleDeletePostClick,
  } = useFlowPostController(props);

  const { repliesBarProps, isInFlowDetailsPage } = useRepliesBar({
    postId: props.post.flowResponse?.responseId || props.post.postID,
    type: props.post.type === PostTypes.FLOW ? 'RESPONSE' : 'POST',
    enabled:
      props.post.type === PostTypes.FLOW && props.post.flowResponse
        ? props.post.flowResponse.commentsCount > 0
        : props.post.commentsCount > 0,
  });

  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;

  const handleOnCreateAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'CREATE_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.post.flowResponse?.responseId,
          activeAnnouncement: props.post.flowResponse?.activeAnnouncement,
          hideReactions: props.post.flowResponse?.hideReactions,
          hideReplies: props.post.flowResponse?.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.post.flowResponse?.activeAnnouncement,
    props.post.flowResponse?.hideReactions,
    props.post.flowResponse?.hideReplies,
    props.post.flowResponse?.responseId,
  ]);

  const handleOnEndAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'END_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.post.flowResponse?.responseId,
          activeAnnouncement: props.post.flowResponse?.activeAnnouncement,
          hideReactions: props.post.flowResponse?.hideReactions,
          hideReplies: props.post.flowResponse?.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.post.flowResponse?.activeAnnouncement,
    props.post.flowResponse?.hideReactions,
    props.post.flowResponse?.hideReplies,
    props.post.flowResponse?.responseId,
  ]);

  const handleOnEditAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'EDIT_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.post.flowResponse?.responseId,
          activeAnnouncement: props.post.flowResponse?.activeAnnouncement,
          hideReactions: props.post.flowResponse?.hideReactions,
          hideReplies: props.post.flowResponse?.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.post.flowResponse?.activeAnnouncement,
    props.post.flowResponse?.hideReactions,
    props.post.flowResponse?.hideReplies,
    props.post.flowResponse?.responseId,
  ]);

  const handleViewAnnouncementInsightsClick = useCallback(() => {
    const viewAnnouncementsInsightsPayload = {
      activeAnnouncement: props.post.flowResponse?.activeAnnouncement,
    };
    window.parent.postMessage(
      {
        type: 'OPEN_ANNOUNCEMENT_INSIGHTS_MODAL',
        payload: viewAnnouncementsInsightsPayload,
      },
      '*',
    );
  }, [props.post.flowResponse?.activeAnnouncement]);

  const dropdownComponent = useMemo(
    () =>
      hideInteractions ? null : (
        <DropdownContainer>
          <FlowsPostHeaderOption
            dropdownOptions={getFeedPostHeaderMenuOptions(
              Boolean(showDeleteOption),
              Boolean(showEditOption),
              variant,
              notificationPreference,
              showPostInteractionSettings,
              canEndAnnouncement && isEmbeddedInMainApp,
              canEditAnnouncement && isEmbeddedInMainApp,
              canShareAsAnnouncement && isEmbeddedInMainApp,
              canViewAnnouncementInsights && isEmbeddedInMainApp,
            )}
            icon="more"
            title="flows post options"
            onCopyLink={onCopyLinkClick}
            hasTrophies={hasTrophies}
            onDeletePostSelectedOption={handleDeletePostOptionChange}
            onDeleteModalSubmitClick={onDeleteModalSubmitClick}
            onGotoPostClick={onGotoPostLinkClick}
            onEditPost={() => {
              if (handleEditPostClick) {
                if (feedProps) {
                  const { postId, responseId, type, flowId } = feedProps;
                  if (postId) {
                    handleEditPostClick({
                      postId,
                      responseId,
                      type,
                      flowId,
                    });
                  }
                }
              }
            }}
            onNotificationPreferenceClick={onNotificationPreferenceClick}
            currency={currency}
            onPostInteractionSettingsClick={handlePostInteractionSettingsClick}
            onEndAnnouncementClick={handleOnEndAnnouncementClick}
            onEditAnnouncementClick={handleOnEditAnnouncementClick}
            onCreateAnnouncementClick={handleOnCreateAnnouncementClick}
            onViewAnnouncementsInsightsClick={
              handleViewAnnouncementInsightsClick
            }
            onDeletePostClick={handleDeletePostClick}
          />
        </DropdownContainer>
      ),
    [
      hideInteractions,
      showDeleteOption,
      showEditOption,
      variant,
      notificationPreference,
      showPostInteractionSettings,
      canEndAnnouncement,
      isEmbeddedInMainApp,
      canEditAnnouncement,
      canShareAsAnnouncement,
      canViewAnnouncementInsights,
      onCopyLinkClick,
      hasTrophies,
      handleDeletePostOptionChange,
      onDeleteModalSubmitClick,
      onGotoPostLinkClick,
      onNotificationPreferenceClick,
      currency,
      handlePostInteractionSettingsClick,
      handleOnEndAnnouncementClick,
      handleOnEditAnnouncementClick,
      handleOnCreateAnnouncementClick,
      handleViewAnnouncementInsightsClick,
      handleEditPostClick,
      feedProps,
      handleDeletePostClick,
    ],
  );
  if (profileData && feedProps) {
    return (
      <FlowFeedPost
        index={index}
        variant={variant}
        hideBorder={hideBorder}
        repliesBarProps={repliesBarProps}
        profileData={profileData}
        key={feedProps.key}
        onReactionSet={handleReactionSet}
        onReactionUnSet={handleReactionUnSet}
        onMemberClick={handleMemberClick}
        headerProps={feedProps.headerProps}
        isAnonymous={
          feedProps?.kind === FeedItemKind.EXTERNAL_ANONYMOUS ||
          feedProps?.kind === FeedItemKind.INTERNAL_ANONYMOUS
        }
        bodyProps={{
          blocks: feedProps.bodyProps,
          fileToPreview,
          filePreviewError,
          refetchFilePreviewLink,
          isFilePreviewLoading,
          fileToDownload,
          fileDownloadError,
          refetchFileDownloadLink,
          isConvertedPdfPreviewsEnabled,
          handleSetFilePreview,
          isExpandedOnLoad,
          onSeeFullPostClick,
          onDownloadFileClick,
        }}
        feedItemMetaData={{
          postId: feedProps.postId,
          flowId: feedProps.flowId,
          responseId: feedProps.responseId,
          type: feedProps.type,
        }}
        reactionBarProps={feedProps.reactionBarProps}
        activeAnnouncement={feedProps.activeAnnouncement}
        onFlowClick={onFlowClick}
        dropdownComponent={dropdownComponent}
        hideInteractions={hideInteractions}
        hideRepliesBar={hideRepliesBar}
        expandPostButton={!isInFlowDetailsPage}
        hideReactions={hideReactions}
        onViewAnnouncementInsightsClick={handleViewAnnouncementInsightsClick}
      />
    );
  }
  return null;
};

export default FlowPostController;
