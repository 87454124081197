import React from 'react';
import { CircularProgress } from '@mui/material';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { CircularProgressProps } from './interfaces';
import BenchmarkStatusIcon from '../BenchmarkStatusIcon';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: ({ size }: CircularProgressProps) => ({
    width: size,
    height: size,
    position: 'relative',
  }),
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  overlayText: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    color: ({ valueColor }: CircularProgressProps) => valueColor,
  },
  shadowClass: ({ shadowColor }: CircularProgressProps) => ({
    color: shadowColor,
  }),
  progressIndicatorClass: ({ color }: CircularProgressProps) => ({
    color,
    '& circle': {
      fill: ({ backgroundColor }: CircularProgressProps) =>
        backgroundColor || 'transparent',
    },
  }),
});

const CircularProgressComponent = (props: CircularProgressProps) => {
  const classes = useStyles(props);
  const {
    variant,
    showValue,
    value,
    size,
    showCircularShadow,
    showBenchmarkStatus,
    benchmarkStatus,
    thickness,
  } = props;
  return (
    <div className={classes.root}>
      {showCircularShadow && (
        <CircularProgress
          variant="determinate"
          value={100}
          size={size}
          classes={{
            root: classes.progress,
            svg: classes.shadowClass,
          }}
          thickness={thickness}
        />
      )}
      <CircularProgress
        variant={variant}
        value={value}
        size={size}
        classes={{
          root: classes.progress,
          svg: classes.progressIndicatorClass,
        }}
        thickness={thickness}
      />
      {showValue && value !== undefined && (
        <div className={classes.overlayText}>
          {showBenchmarkStatus && (
            <BenchmarkStatusIcon status={benchmarkStatus} isProfileInfo />
          )}
          <Body variant="subHead1">{`${value}%`}</Body>
        </div>
      )}
    </div>
  );
};

CircularProgressComponent.defaultProps = {
  variant: 'determinate',
  showValue: false,
  value: undefined,
  size: '24px',
  showCircularShadow: false,
  color: ThemeV2.palette.geekBlue6,
  shadowColor: ThemeV2.palette.gray3,
  valueColor: ThemeV2.palette.gray9,
};

export default CircularProgressComponent;
