import React, { useState, useMemo, useCallback, useEffect } from 'react';

import {
  CHARITY_REWARDS_SEARCH_PLACEHOLDER,
  REWARDS_SORT_PLACEHOLDER,
} from '../../../languages/en/rewards';
import ThemeV2 from '../../../theme';
import {
  charityCardsSortByMenuItems,
  REWARDS_PER_PAGE_DEFAULT,
} from '../../../Utils/data/rewards';
import {
  TextFieldType,
  TextFieldProps,
} from '../../../atomic/molecules/TextField_V2/interface';
import { CharityReward } from '../../../queries/Rewards/interfaces';
import useStringSearch from '../../../hooks/useSearchString';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

interface CharityRewardsComponentProps {
  rewards: CharityReward[];
  onSortChange: (value: unknown) => void;
}

const GiftCardControllerLogic = (props: CharityRewardsComponentProps) => {
  const { rewards, onSortChange } = props;
  const history = useHistory();
  const location = useLocation();
  const { id: cardId } = useParams<{ id: string }>();
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<
    CharityReward | undefined
  >();

  const [itemsPerPage, setItemsPerPage] = useState(REWARDS_PER_PAGE_DEFAULT);
  const [pageNumber, setPageNumber] = useState(1);

  const resetCurrentPage = useCallback(() => {
    setPageNumber(1);
  }, []);

  let rewardsToDisplay = [...rewards];

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsToDisplay, keys: ['brandName'] });

  const { searchResult } = searchModels;

  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  const onSearchChangeHandler = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
    },
    [resetCurrentPage],
  );

  const onSortChangeHandler = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: CHARITY_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: onSearchChangeHandler,
      icon: 'search',
    };
  }, [onSearchChangeHandler, searchValue]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: sortValue,
      onChange: onSortChangeHandler,
      dropdownItems: charityCardsSortByMenuItems,
      isEditable: false,
      inputIconColor: ThemeV2.palette.gray9,
    };
  }, [sortValue, onSortChangeHandler]);

  // search logic
  rewardsToDisplay = searchResult;

  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewards.find((reward) => reward.brandKey === id);
      if (currentReward) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);
      }
    },
    [rewards],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
    history.replace(location.pathname.replace(`/${cardId}`, ''));
  }, [cardId, history, location.pathname]);

  useEffect(() => {
    if (cardId && rewards.length > 0) {
      const currentReward = rewards.find(
        (reward) => reward.brandKey === cardId,
      );
      if (currentReward && !isModalOpen) {
        setIsModalOpen(true);
        setSelectedReward(currentReward);

        const selectedRewardIndex = rewards.findIndex(
          (reward) => reward.brandKey === cardId,
        );

        const selectedPage = Math.floor(selectedRewardIndex / itemsPerPage) + 1;
        setPageNumber(selectedPage);
      } else if (!isModalOpen) {
        setIsModalOpen(true);
      }
    }
  }, [cardId, isModalOpen, itemsPerPage, rewards]);

  return {
    pageNumber,
    setPageNumber,
    itemsPerPage,
    setItemsPerPage,
    searchProps,
    sortProps,
    rewardsToDisplay,
    handleOnRewardSelect,
    isModalOpen,
    selectedReward,
    onCloseRedeemModal,
  };
};

export default GiftCardControllerLogic;
