import React from 'react';
import { Collapse } from '@mui/material';

import styled, { css } from 'styled-components';

interface AccordionProps {
  accordionHeader: React.ReactNode;
  children: React.ReactNode | null;
  isAccordionOpen: boolean;
  onAccordionClick: () => void;
  className?: string;
  disabled?: boolean;
}

const Wrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => disabled && '30%'};
`;

const CollapseWrapper = styled(Collapse)`
  ${(props) =>
    props.in &&
    css`
      margin-top: 4px;
    `}
`;

const Accordion = (props: AccordionProps) => {
  const {
    accordionHeader,
    children,
    isAccordionOpen,
    onAccordionClick,
    className,
    disabled,
  } = props;
  return (
    <Wrapper className={className} disabled={disabled}>
      <div
        onClick={onAccordionClick}
        data-testid="accordionHeader"
        data-qa-id="txt-accordian-header"
      >
        {accordionHeader}
      </div>
      <CollapseWrapper
        in={isAccordionOpen}
        aria-expanded={isAccordionOpen}
        data-testid="accordionBody"
        data-qa-id="txt-accordian-body"
      >
        {children}
      </CollapseWrapper>
    </Wrapper>
  );
};

export default Accordion;
