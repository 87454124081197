import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import {
  device,
  PrimaryNavWidth,
  SecondaryNavWidth,
} from '../../../constants/layout';
import { LayoutStyleProps } from '../../../interfaces/Layout';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const IconButton = styled.button<{
  isProfileOpen?: boolean;
}>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  background-color: ${({ isProfileOpen }) =>
    isProfileOpen ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 12px;
  color: ${ThemeV2.palette.gray7};
  &:hover {
    background-color: ${ThemeV2.palette.gray2};
  }
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: fixed;
  transition-duration: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen ? `550ms` : `300ms`};
  transition-timing-function: ease;
  transition-property: left;
  bottom: 24px;
  left: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen
      ? `${PrimaryNavWidth + SecondaryNavWidth - (24 + 40)}px`
      : `${PrimaryNavWidth + 24}px`};
  height: 40px;
  border-radius: 50%;

  @media ${device.tablet} {
    display: ${({ isRightAsideOpen }) => isRightAsideOpen && 'none'};
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const PopoverContainer = styled.div<{
  width?: string;
}>`
  width: ${({ width }) => (width ? width : `232px`)};
  z-index: ${zIndexPopup};
`;
