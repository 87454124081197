import { FeedTypes } from '../../molecules/ActionBar/types';
import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export enum FlowPromptCardStatus {
  Open = 'OPEN',
  Closed = 'Closed',
  Schedule = 'Schedule',
}

export enum UserRole {
  Participant = 'PARTICIPANT',
  Viewer = 'VIEWER',
}

export type FlowPromptCardProps = {
  title: string;
  emoticon: string;
  description: string;
  status: FlowPromptCardStatus;
  userRole?: UserRole;
  dropdownMenuItems: MenuItemProps[];
  timestamp?: string;
  hasShortcut?: boolean;
  isAnswered?: boolean;
  dueDate?: string;
  feedType?: FeedTypes;
  dropdownItemClick: () => void;
  onViewFeedClick?: () => void;
  onAnswerClick?: () => void;
  onAnswerWithShortcutClick?: () => void;
  dataTestId?: string;
};
