export const DEPARTMENT_CARD_TOOLTIP = (
  everyoneCardTitle: string,
) => `These role settings will override '${everyoneCardTitle}',
but will not override individual role settings.`;

export const MEMBER_CARD_TOOLTIP = () =>
  'These manual role settings will override any role configured using rules.';

export const CHANNEL_CARD_TOOLTIP = (
  currentSetting: string,
) => `Members of this channel who were added via Connections tab
  ${currentSetting.toLowerCase()} in this flow.`;
