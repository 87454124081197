import React, { useState, useEffect, useCallback } from 'react';
import SVGIcon from '../../atoms/SVGIcon';
import {
  OptionItemEditorProps,
  OptionItemProps,
  OptionItemsProps,
} from './interface';
import {
  LabelHelperText,
  OtherLabelText,
  LabelWrapper,
  OptionLabel,
  OptionsWrapper,
  OrTextSeparator,
  StyledAddOptionButton,
  StyledCheckbox,
  StyledClickableClose,
  StyledClickableOption,
  StyledRadio,
  StyledTextField,
  StyledNumberedOptionItem,
} from './styles';
import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';
import {
  ADD,
  AN,
  ANOTHER,
  OPTION,
  OTHER,
} from '../../../languages/en/singleWords';
import { ADD_YOUR_ANSWER } from '../../../languages/en/flows/builder';

const OptionItem = (props: OptionItemsProps) => {
  const {
    option,
    index = null,
    onDismissClick,
    isDismissEnabled = Boolean(option?.helperText),
    onOptionEditChange,
    type,
    readOnly = Boolean(option?.helperText) || false,
    addOptionClickProps,
    addOtherClickProps,
    isAddOtherOptionPresent = false,
    onOptionEnterClick,
    focusOptionId,
  } = props;
  const [isInEditingMode, setIsInEditingMode] = useState(
    Boolean(option?.autoFocus || focusOptionId === option?.value),
  );

  useEffect(() => {
    if (focusOptionId === option?.value) {
      setIsInEditingMode(true);
    }
  }, [focusOptionId, option?.value]);

  const toggleSetEditingMode = () => {
    setIsInEditingMode(!isInEditingMode);
  };

  const renderChoiceIndicator = (indicatorType: string) => {
    switch (indicatorType.toLocaleLowerCase()) {
      case 'radio':
        return <StyledRadio disabled />;

      case 'numbered':
        return index ? (
          <StyledNumberedOptionItem>{index}.</StyledNumberedOptionItem>
        ) : null;

      default:
        return <StyledCheckbox checked={false} disabled />;
    }
  };

  return (
    <OptionsWrapper
      key={option?.value}
      data-testid="option-item"
      data-qa-id="option-item"
    >
      {renderChoiceIndicator(type || '')}
      {option?.value === 'other' && (
        <OtherLabelText
          color="gray9"
          variant="body2"
          inline
        >{`${OTHER}:`}</OtherLabelText>
      )}
      <LabelWrapper
        $isInEditingMode={
          isInEditingMode || readOnly || Boolean(option?.autoFocus)
        }
      >
        {!readOnly && onOptionEditChange && isInEditingMode ? (
          <StyledTextField
            background={option?.value === 'other' ? 'white' : ''}
            disabled={option?.value === 'other'}
            name={option?.value}
            onChange={(e) => onOptionEditChange(e, option)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                toggleSetEditingMode();

                if (option && onOptionEnterClick)
                  onOptionEnterClick(event, option);
              }
            }}
            value={
              option?.label === '\n' || !option?.label ? '' : option?.label
            }
            removeElevation
            multiline
            onBlur={toggleSetEditingMode}
            autoFocus={Boolean(option?.autoFocus) || true}
            placeholder={option?.value === 'other' ? ADD_YOUR_ANSWER : ''}
          />
        ) : (
          <>
            {addOptionClickProps?.enabled ? (
              <>
                <StyledAddOptionButton
                  variant="text"
                  color="secondary"
                  size="small"
                  onClick={addOptionClickProps.onClick}
                >
                  {addOtherClickProps?.enabled
                    ? `${ADD} ${AN} ${OPTION}`
                    : `${ADD} ${ANOTHER} ${OPTION}`}
                </StyledAddOptionButton>
                {addOtherClickProps?.enabled && !isAddOtherOptionPresent && (
                  <>
                    <OrTextSeparator>or</OrTextSeparator>
                    <Button
                      variant="text"
                      size="small"
                      onClick={addOtherClickProps?.onClick}
                    >
                      {`${ADD} ‘${OTHER}’`}
                    </Button>
                  </>
                )}
              </>
            ) : (
              <StyledClickableOption
                $isInEditingMode={isInEditingMode || readOnly}
                onClick={toggleSetEditingMode}
                data-testid="option-label"
                data-qa-id="option-label"
              >
                <OptionLabel variant="body2">
                  {option?.defaultLabel &&
                  (option?.label === '' || option?.label === '\n')
                    ? option?.defaultLabel
                    : option?.label}
                  <LabelHelperText>{option?.helperText}</LabelHelperText>
                </OptionLabel>
              </StyledClickableOption>
            )}
          </>
        )}
      </LabelWrapper>

      {onDismissClick && isDismissEnabled && (
        <StyledClickableClose onClick={() => onDismissClick(option)}>
          <SVGIcon
            size="16px"
            icon="round-close"
            color={ThemeV2.palette.gray8}
          />
        </StyledClickableClose>
      )}
    </OptionsWrapper>
  );
};

const OptionItemsEditor = (props: OptionItemEditorProps) => {
  const {
    className,
    type,
    optionItems,
    onDismissClick,
    onOptionEditChange,
    addOptionClickProps,
    addOtherClickProps,
  } = props;

  const [isAddOtherOptionPresent, checkIfAddOtherOptionIsPresent] =
    useState<boolean>(false);

  const [focusOptionId, setFocusOptionId] = useState<string>('');

  const onOptionEnterClick = useCallback(
    (e: any, option: OptionItemProps) => {
      if (e.key === 'Enter') {
        const nextOptionIdToBeFocused = optionItems.findIndex(
          (optionItem) => optionItem?.value === option?.value,
        );
        if (
          nextOptionIdToBeFocused + 1 < optionItems.length &&
          optionItems[nextOptionIdToBeFocused + 1]?.value !== 'other'
        ) {
          setFocusOptionId(optionItems[nextOptionIdToBeFocused + 1]?.value);
        } else {
          if (
            !optionItems[nextOptionIdToBeFocused + 1]?.value &&
            addOptionClickProps?.enabled &&
            addOptionClickProps?.onClick
          ) {
            addOptionClickProps?.onClick();
          }
        }
      }
    },
    [addOptionClickProps, optionItems],
  );

  useEffect(() => {
    const addOtherOptionPresentFlag =
      optionItems.filter((option) => option?.value === 'other').length > 0;
    checkIfAddOtherOptionIsPresent(addOtherOptionPresentFlag);
  }, [optionItems]);

  return (
    <div className={className}>
      {optionItems.map((option, index) => (
        <OptionItem
          type={type}
          index={index + 1}
          key={option.value}
          option={option}
          onDismissClick={onDismissClick}
          isDismissEnabled={optionItems.length > 0}
          onOptionEditChange={(e) => onOptionEditChange(e, option)}
          onOptionEnterClick={onOptionEnterClick}
          focusOptionId={focusOptionId}
        />
      ))}
      {addOptionClickProps.enabled && (
        <OptionItem
          type={type}
          addOptionClickProps={addOptionClickProps}
          addOtherClickProps={addOtherClickProps}
          readOnly
          isDismissEnabled={false}
          index={type === 'numbered' ? optionItems.length + 1 : null}
          isAddOtherOptionPresent={isAddOtherOptionPresent}
          focusOptionId={focusOptionId}
        />
      )}
    </div>
  );
};

export default OptionItemsEditor;
