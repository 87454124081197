import React from 'react';
import styled from 'styled-components';

import Modal from '../../atoms/Modal';
import ThemeV2 from '../../../theme';

const StyledModal = styled(Modal)`
  width: 100vw;
  height: 100%;

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    width: 90vw;
    height: 90vh;
    max-width: 1366px;
    max-height: 768px;
  }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  @media (min-width: ${ThemeV2.breakPoints.md}) {
    width: 100vw;
    height: 100vh;
  }
`;

export interface FlowsParticipationModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  handleClose?: () => void;
  isFullScreen?: boolean;
}

const FullScreenParticipationWrapper = ({
  children,
  handleClose,
  isOpen,
  isFullScreen,
}: FlowsParticipationModalProps) => {
  if (isFullScreen) {
    return <Wrapper>{children}</Wrapper>;
  } else {
    return (
      <StyledModal isOpen={isOpen} handleClose={handleClose}>
        {children}
      </StyledModal>
    );
  }
};

export default FullScreenParticipationWrapper;
