import styled from 'styled-components';

import Body from '../../../atoms/Body';
import Button from '../../../atoms/Button';
import Heading from '../../../atoms/Heading';

const ContentWrapper = styled.div`
  align-items: center;
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: auto;
  padding: 46px;
  text-align: center;
`;

const ActionButton = styled(Button)`
  margin-top: 8px;
`;

const Title = styled(Heading)`
  margin-bottom: 6px;
  margin-top: 32px;
`;

const EmptyFilePreview = (props: {
  cta: string;
  imgSrc: string;
  onClick?: () => void;
  subtitle: string;
  title: string;
}) => {
  const { cta, imgSrc, onClick, subtitle, title } = props;

  return (
    <div className="pg-driver-view">
      <ContentWrapper>
        <img alt="" height="216" src={imgSrc} width="270" />
        <Title variant="h6">{title}</Title>
        <Body color="gray9" variant="body1">
          {subtitle}
        </Body>
        <ActionButton
          color="secondary"
          icon="retry"
          onClick={onClick}
          size="small"
          variant="text"
        >
          <Body color="gray8" variant="body2">
            {cta}
          </Body>
        </ActionButton>
      </ContentWrapper>
    </div>
  );
};

export default EmptyFilePreview;
