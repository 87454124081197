import produce from 'immer';
import { useEffect } from 'react';
import { Channel } from 'pusher-js';
import { pusher } from '../../../../pusher/pusher-base';
import { InfiniteData, QueryKey, useQueryClient } from '@tanstack/react-query';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { makeAPICallWithDataReturn } from '../../../../queries/utils';

import { GetChatsResponse } from '../../../../interfaces/Chats';
import {
  GET_CHAT_BY_ID,
  GET_CHATS,
  GET_UNREAD_CHATS,
} from '../../../../constants/endpoints';

let channelAssembly: Channel;
let channelMember: Channel;

type NewRepliesSocketPayload = {
  commentID: string;
  toMemberId: string;
  fromMemberId: string;
};

type EventNameType = 'DIRECT_MESSAGE_READ' | 'NEW_COMMENT';

const useChatSocket = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();

  useEffect(() => {
    let channelAssemblyEventHandler: (
      eventName: EventNameType,
      socketPayload: NewRepliesSocketPayload,
    ) => Promise<void>;

    let channelMemberEventHandler: (name: EventNameType) => Promise<void>;

    if (data) {
      const { memberId } = data.member;
      const { assemblyId } = data.assembly;

      channelMember = pusher.subscribe(`private-member-${memberId}`);
      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      channelAssemblyEventHandler = async (
        eventName: string,
        socketPayload: NewRepliesSocketPayload,
      ) => {
        if (eventName === 'NEW_COMMENT' && socketPayload.toMemberId) {
          const newChatMessages = await makeAPICallWithDataReturn(
            GET_CHAT_BY_ID,
            undefined,
            { commentIds: socketPayload.commentID },
            {
              memberId:
                memberId === socketPayload.toMemberId
                  ? socketPayload.fromMemberId
                  : socketPayload.toMemberId,
            },
          );

          let queryKey: QueryKey;

          if (memberId === socketPayload.toMemberId) {
            queryKey = [GET_CHATS, socketPayload.fromMemberId];
          } else {
            queryKey = [GET_CHATS, socketPayload.toMemberId];
          }

          const [newChatMessage] = newChatMessages;
          const previousChats =
            queryClient.getQueryData<InfiniteData<GetChatsResponse>>(queryKey);

          if (newChatMessage && previousChats) {
            const updatedChats = produce(previousChats, (draft) => {
              const pageLength = draft.pages.length;
              const lastPage = draft.pages[pageLength - 1];
              const chatToBeReplaced = lastPage.data.find((chat) => {
                return (
                  chat.commentID === 'comment_id' &&
                  chat.message === newChatMessage.message
                );
              });

              if (chatToBeReplaced) {
                Object.assign(chatToBeReplaced, newChatMessage);
              } else {
                lastPage.data.push(newChatMessage);
                lastPage.total += 1;
              }
            });

            queryClient.setQueryData(queryKey, updatedChats);
          }

          await queryClient.refetchQueries([GET_UNREAD_CHATS]);
        }
      };

      channelMemberEventHandler = async (eventName: EventNameType) => {
        if (eventName === 'DIRECT_MESSAGE_READ') {
          await queryClient.refetchQueries([GET_UNREAD_CHATS]);
        }
      };

      channelAssembly.bind_global(channelAssemblyEventHandler);
      channelMember.bind_global(channelMemberEventHandler);
    }

    return () => {
      if (data) {
        channelAssembly.unbind_global(channelAssemblyEventHandler);
        channelMember.unbind_global(channelMemberEventHandler);
      }
    };
  }, [data]);
};

export default useChatSocket;
