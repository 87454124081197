import { useRewardsFetchQuery } from '../../../queries/Rewards';
import { RewardsFetchQueryParamsType } from '../../../queries/Rewards/interfaces';

const useCultureControllerModel = (params: RewardsFetchQueryParamsType) => {
  const {
    isInitialLoading: isLoading,
    isError,
    refetch,
    data: rewardsData,
  } = useRewardsFetchQuery(params);

  return {
    isLoading,
    isError,
    refetch,
    rewardsData,
  };
};

export default useCultureControllerModel;
