export enum PostTypes {
  CELEBRATED = 'celebrated',
  ANNIVERSARY = 'anniversary',
  BIRTHDAY = 'birthday',
  RECOGNITION = 'recognition',
  FLOW = 'flow',
  CHAT = 'chat',
  DEFAULT = 'default',
}

export enum FeedTypes {
  POST = 'post',
  COMMENT = 'comment',
}
