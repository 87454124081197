import { useCallback, useEffect, useMemo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import useLayoutStore from '../../../stores/layoutStore';
import {
  generateV2UserProfileRoute,
  V2_USER_PROFILE,
} from '../../../constants/routes';

import { HandleOnCloseClick, ProfileViewControllerProps } from './interfaces';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFetchMembersDetailsQuery } from '../../../queries/MembersDetails';
import { serializeMembersDetailsFromApi } from '../../../queries/MembersDetails/serializer';
import { MemberFromAPI } from '../../../interfaces/UserProfile';

import { copyToClipboard } from '../../../Utils/common';
import { showSuccessMessage } from '../../../Utils/toast';
import { COPIED_TO_CLIPBOARD } from '../../../languages/en/flows';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import {
  UpdateUserInfoPayload,
  useUserSettingsMutation,
} from '../../../queries/Settings';
import useSetRepliesToSidebar from '../../../hooks/useSetRepliesToSideBar';
import mobileStore from '../../../stores/mobileAppStore';

const mapMembersFromApiToMembersDetails = (membersFromApi: MemberFromAPI[]) => {
  if (!membersFromApi || !membersFromApi.length) {
    return [];
  }

  return membersFromApi.map((memberFromApi) =>
    serializeMembersDetailsFromApi(memberFromApi),
  );
};

const useProfileViewController = ({
  firstName,
  memberId,
}: ProfileViewControllerProps) => {
  const { userId } = useParams<{ userId: string }>();

  const {
    setShowChats,
    showRightAside,
    selectedMember,
    setSelectedMember,
    removeSelectedMember,
    toggleRightAsideOpen,
    setMemberAndToggleRightAsideOpen,
    isEmbeddedInMainApp,
  } = useLayoutStore(
    (state) => ({
      setShowChats: state.setShowChats,
      showRightAside: state.showRightAside,
      selectedMember: state.selectedMember,
      setSelectedMember: state.setSelectedMember,
      removeSelectedMember: state.removeSelectedMember,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
      isEmbeddedInMainApp: state.isEmbeddedInMainApp,
    }),
    shallow,
  );

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { data: ownProfileData } = useProfileInfoFetchQuery();
  const slugUrl = `${ownProfileData?.assembly.workspaceSlug.name}-${ownProfileData?.assembly.workspaceSlug.shortCode}`;

  const routeMatch = useRouteMatch(`/${slugUrl}${V2_USER_PROFILE}`);
  const isOnProfileRoute = !!routeMatch;

  const isCurrentUser =
    !memberId || memberId === ownProfileData?.member.memberId;
  const isFetchMembersDetailsQueryEnabled = !isCurrentUser;

  const {
    data: membersDetailsFetchData,
    isError: isMembersDetailsError,
    isInitialLoading: isMembersDetailsFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    memberId ? [memberId] : undefined,
    isFetchMembersDetailsQueryEnabled,
  );

  const memoizedMemberDetails = useMemo(() => {
    if (isFetchMembersDetailsQueryEnabled) {
      if (
        membersDetailsFetchData?.membersDetails &&
        membersDetailsFetchData?.membersDetails.length > 0
      ) {
        return serializeMembersDetailsFromApi(
          membersDetailsFetchData.membersDetails[0],
        );
      }
      return null;
    }

    return ownProfileData?.member
      ? serializeMembersDetailsFromApi(ownProfileData.member)
      : null;
  }, [
    isFetchMembersDetailsQueryEnabled,
    membersDetailsFetchData,
    ownProfileData,
  ]);

  const managerId = memoizedMemberDetails?.managerIds[0];
  const isManagerFetchQueryEnabled = !!managerId;
  const {
    data: managerDetailsData,
    isError: isManagerDetailsError,
    isInitialLoading: isManagerFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    managerId ? [managerId] : undefined,
    isManagerFetchQueryEnabled,
  );

  const directReportIds = memoizedMemberDetails?.reportIds;
  const isDirectReportsFetchQueryEnabled = !!directReportIds?.length;
  const {
    data: directReportsDetailsData,
    isError: isDirectReportsDetailsError,
    isInitialLoading: isDirectReportsFetchQueryLoading,
  } = useFetchMembersDetailsQuery(
    directReportIds && directReportIds.length > 0 ? directReportIds : undefined,
    isDirectReportsFetchQueryEnabled,
  );

  const memoizedManager = useMemo(() => {
    if (
      managerDetailsData?.membersDetails &&
      managerDetailsData?.membersDetails.length > 0
    ) {
      const manager = serializeMembersDetailsFromApi(
        managerDetailsData.membersDetails[0],
      );
      return {
        firstName: manager.profile.firstName,
        lastName: manager.profile.lastName,
        memberId: manager.memberId,
        profilePicture: manager.profile.image,
      };
    }

    return undefined;
  }, [managerDetailsData]);

  useEffect(() => {
    if (
      !firstName &&
      membersDetailsFetchData &&
      membersDetailsFetchData.membersDetails.length > 0
    ) {
      setSelectedMember({
        memberId,
        firstName: membersDetailsFetchData.membersDetails[0].profile.firstName,
      });
    }
  }, [firstName, memberId, membersDetailsFetchData, setSelectedMember]);

  const handleRemoveSelectedMember = () => {
    if (!showRightAside) {
      toggleRightAsideOpen();
      removeSelectedMember();
    } else {
      removeSelectedMember();
    }
  };

  const handleOnViewProfileClick = useCallback(() => {
    setShowChats(true);
    if (isOnProfileRoute && userId) {
      setMemberAndToggleRightAsideOpen({
        memberId: userId,
      });
    }
  }, [
    userId,
    setShowChats,
    isOnProfileRoute,
    setMemberAndToggleRightAsideOpen,
  ]);

  const { shouldResetReplies, handleSetReplies } = useSetRepliesToSidebar();

  const handleOnCloseClick: HandleOnCloseClick = useCallback(
    (args) => {
      if (!shouldResetReplies) {
        handleSetReplies();
      }
      removeSelectedMember();
      if (isMobileView) {
        toggleRightAsideOpen();
      }
      if (isOnProfileRoute && userId) {
        setSelectedMember({ memberId: userId });
      }
      if (args?.viewProfileClick && mobileStore.getState().isMobileAppV3) {
        queueMicrotask(() => {
          toggleRightAsideOpen();
        });
      }
    },
    [
      shouldResetReplies,
      removeSelectedMember,
      isMobileView,
      isOnProfileRoute,
      userId,
      handleSetReplies,
      toggleRightAsideOpen,
      setSelectedMember,
    ],
  );

  const handleOnCopyProfileLink = useCallback(() => {
    const id = selectedMember?.memberId;
    if (id) {
      const url = `/${slugUrl}${generateV2UserProfileRoute(id)}`;
      copyToClipboard(
        `${window.location.origin}${isEmbeddedInMainApp ? `/a${url}` : url}`,
      );
      showSuccessMessage(COPIED_TO_CLIPBOARD);
    }
  }, [isEmbeddedInMainApp, selectedMember?.memberId, slugUrl]);

  const isError =
    isMembersDetailsError ||
    isManagerDetailsError ||
    isDirectReportsDetailsError;

  const areQueriesLoading =
    isMembersDetailsFetchQueryLoading ||
    isDirectReportsFetchQueryLoading ||
    isManagerFetchQueryLoading;

  const { mutate: updateUserSettings } = useUserSettingsMutation();

  const handleAboutMeChange = (value: string) => {
    if (ownProfileData?.member) {
      const {
        profile: { lastName, department },
        managerIds,
        reportIds,
      } = ownProfileData.member;
      const payload = {
        department,
        firstName,
        lastName,
        managers: managerIds,
        reports: reportIds,
        aboutMe: value,
      };
      if (value && value !== ownProfileData.member.profile.aboutMe)
        updateUserSettings(payload as unknown as UpdateUserInfoPayload);
    }
  };

  return {
    areQueriesLoading,
    isOnProfileRoute,
    memberDetails: memoizedMemberDetails,
    setSelectedMemberProfile: setSelectedMember,
    directReportsDetails: directReportsDetailsData?.membersDetails
      ? mapMembersFromApiToMembersDetails(
          directReportsDetailsData.membersDetails,
        )
      : [],
    manager: memoizedManager,
    isError,
    isCurrentUser,
    toggleRightAsideOpen,
    removeSelectedMember: handleRemoveSelectedMember,
    handleOnCloseClick,
    handleOnViewProfileClick,
    handleOnCopyProfileLink,
    isProfileUserSelected: userId === selectedMember?.memberId,
    handleAboutMeChange,
  };
};

export default useProfileViewController;
