import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import { usePostNotebookTasksMutation } from '../queries/Notebook';
import { NOTEBOOK_EVENTS } from '../Utils/analytics/constants';
import { trackNotebookErrorEvent } from '../Utils/analytics/notebook';
import { NOTEBOOK_ASSIGNMENT_SUCCESS_TOAST } from '../languages/en/notebook';
import { NotebookOperations, NotebookViews } from '../interfaces/notebook';
import useNotebookStore from '../stores/notebookStore';
import { dismissAllToasts, showSuccessMessage } from '../Utils/toast';
import { NotebookStore } from '../stores/notebookStore/types';

const notebookPageSelector = (state: NotebookStore) => ({
  transactions: state.transactions,
  updateTransactions: state.updateTransactions,
  updateErrorIDs: state.updateErrorIDs,
  erroredTaskIDs: state.erroredTaskIDs,
  updateNoteIds: state.updateNoteIds,
  currentView: state.currentView,
});

export const useNotebookTransactionsController = () => {
  const {
    transactions,
    updateTransactions,
    updateErrorIDs,
    erroredTaskIDs,
    updateNoteIds,
    currentView,
  } = useNotebookStore(notebookPageSelector, shallow);
  const { mutate } = usePostNotebookTasksMutation(currentView);

  useEffect(() => {
    const { transactionQueue } = transactions;
    if (transactionQueue.length) {
      const payload = transactionQueue.map((item) => item.taskData);

      mutate(
        { payload },
        {
          onSuccess: (response) => {
            const doesPayloadHaveACreateTask = payload.some(
              ({ noteId }) => !noteId,
            );
            const processedTaskIDs = payload.map((item) => item.transactionId);
            const updatedTransactionQueue = transactionQueue.filter(
              (item) => !processedTaskIDs.includes(item.taskId),
            );
            if (
              transactionQueue[transactionQueue.length - 1].operation ===
              NotebookOperations.ASSIGN
            ) {
              dismissAllToasts();
              showSuccessMessage(NOTEBOOK_ASSIGNMENT_SUCCESS_TOAST);
            }
            if (doesPayloadHaveACreateTask) {
              const noteIds = response.data.map(
                (res: { noteId: string }) => res.noteId,
              );
              updateNoteIds(transactions, noteIds);
            }
            updateTransactions({
              ...transactions,
              transactionQueue: updatedTransactionQueue,
            });
            if (erroredTaskIDs.length !== 0) {
              updateErrorIDs([]);
            }
          },
          onError: () => {
            const processedNoteIDs = payload.map(
              ({ transactionId }) => transactionId,
            );
            const containsErrorIds =
              processedNoteIDs.filter((id) => !erroredTaskIDs.includes(id))
                .length > 0;
            if (containsErrorIds) {
              updateErrorIDs(processedNoteIDs);
              trackNotebookErrorEvent({
                error: NOTEBOOK_EVENTS.NOTEBOOK_ERROR,
                source:
                  currentView !== NotebookViews.Board &&
                  currentView !== NotebookViews.Deadline
                    ? currentView
                    : 'notebook',
                subSource: 'taskUpdate',
                errorIds: processedNoteIDs.map((id) => `${id}`),
              });
            }
          },
        },
      );
    }
  }, [
    updateNoteIds,
    transactions,
    mutate,
    updateTransactions,
    updateErrorIDs,
    erroredTaskIDs,
    currentView,
  ]);
};
