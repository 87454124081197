import styled from 'styled-components';
import { getAnnouncementGradients } from './AnnouncementBanner';
import Body from '../../../atoms/Body';
import ToolTip from '../../../molecules/ToolTipV2';

export const AnnouncementWrapper = styled.div<{
  selectedColor?: string;
}>`
  width: 100%;
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: ${({ selectedColor }) =>
    `${
      getAnnouncementGradients().find((x) => x.name === selectedColor)?.value
    }`};
`;

export const AnnouncementText = styled(Body)`
  flex: 1 1 0;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const AnnouncementToolTip = styled(ToolTip)`
  cursor: pointer;

  div {
    max-width: 320px;
  }
`;
