import React, { useMemo } from 'react';
import styled from 'styled-components';
import { AssemblyCurrencyIconType } from './interface';
import { lineHeightForFont } from '../../../Utils/styles/text';
import { AssemblyCurrencyType } from '../../../interfaces/assembly';

const StyledSpan = styled.span<{ size: string; blendMode: string }>`
  font-size: ${({ size }) => size};
  line-height: ${({ size }) => lineHeightForFont(size)};
  mix-blend-mode: ${({ blendMode }) => blendMode};
`;

const StyledImg = styled.img<{ size: string; blendMode: string }>`
  width: ${({ size }) => size};
  mix-blend-mode: ${({ blendMode }) => blendMode};
`;

const AssemblyCurrencyIcon = (props: AssemblyCurrencyIconType) => {
  const {
    active = true,
    size = '20px',
    className,
    dataTestId,
    dataQaId,
    assemblyCurrency,
  } = props;
  let { hexCode, currencyName, url } = props;

  const blendMode = useMemo(() => {
    return active ? 'normal' : 'luminosity';
  }, [active]);

  if (assemblyCurrency) {
    currencyName = assemblyCurrency.name;
    if (assemblyCurrency.type === AssemblyCurrencyType.REGULAR) {
      hexCode = assemblyCurrency.value;
      url = undefined;
    }
    if (assemblyCurrency.type === AssemblyCurrencyType.CUSTOM) {
      url = assemblyCurrency.value;
      hexCode = undefined;
    }
  }

  if (hexCode) {
    return (
      <StyledSpan
        className={className || ''}
        size={size}
        blendMode={blendMode}
        role="img"
        aria-label={currencyName}
        data-testid={dataTestId}
        data-qa-id="icon-trophy"
      >
        {String.fromCodePoint(parseInt(hexCode, 16))}
      </StyledSpan>
    );
  }

  if (url) {
    return (
      <StyledImg
        src={url}
        alt={currencyName}
        className={className || ''}
        size={size}
        blendMode={blendMode}
        data-testid={dataTestId}
        data-qa-id={dataQaId}
      />
    );
  }
  return null;
};

const MemoizedAssemblyCurrencyIcon = React.memo(AssemblyCurrencyIcon);
MemoizedAssemblyCurrencyIcon.displayName = 'AssemblyCurrencyIcon';

export default MemoizedAssemblyCurrencyIcon;
