import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';
import { ModalWrapper } from '../../templates/BasicModalTemplate';
import Heading from '../../atoms/Heading';
import IconButton from '../../atoms/IconButton_V2';
import { device } from '../../../constants/layout';

export const Container = styled.section`
  padding: 14px 0 0 0;
`;

export const InviteAndShareContainer = styled.section<{
  $noPadding?: boolean;
}>`
  ${({ $noPadding = false }) => !$noPadding && `padding: 14px 32px;`};
`;

export const RightPanelButtonsContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const FooterContainer = styled.section`
  display: flex;
  padding: 16px;
  justify-content: flex-end;
  border-top: 1px solid ${ThemeV2.palette.gray4};

  @media ${device.mobile} {
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
  }
`;

export const PrimaryButton = styled(Button)`
  margin-left: 16px;
`;

export const SharingOptionsModalContainer = styled(ModalWrapper)`
  max-width: 640px;
`;

export const HeaderContainer = styled.section`
  display: flex;
  padding: 0 16px 8px 16px;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled(Heading)`
  margin-left: -24px;
`;

export const CloseButton = styled(IconButton)`
  top: 12px;
  right: 16px;
  position: absolute;
  z-index: 1;
`;
