import { useEffect, useMemo, useState } from 'react';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import useLayoutStore from '../../../stores/layoutStore';

import { StyledTabComponent } from './styles';
import { getTabComponents } from './utils';

import { FluidContainer } from '../../../Utils/styles/display';
import { V2_HOME } from '../../../constants/routes';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { useOpenEditFlowInV3 } from '../../../hooks/useOpenEditFlowInV3';
import { usePopulateFlowDetailsToStore } from '../../../hooks/usePopulateFlowDetailsToStore';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import {
  setShareSheetModalOpenSelector,
  shareSheetModalOpenSelector,
} from '../../../stores/flowBuilderStore/selectors';
import FlowsShareSheetController from '../../flows/FlowsShareSheetController';
import { FlowAccessRoles } from '../../../queries/Flows/interfaces';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import { EDITOR } from '../../../languages/en/singleWords';
import { useQueryClient } from '@tanstack/react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  GET_FLOW_PARTICIPANTS,
  GET_FLOW_VIEWERS,
  GET_MAIN_NAV_CONTENTS,
} from '../../../constants/endpoints';

const FlowsBuilderTabsController = () => {
  const isShareSheetModalOpen = useFlowBuilderStore(
    shareSheetModalOpenSelector,
  );
  const queryClient = useQueryClient();
  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const history = useHistoryWrapper();
  const location = useLocation<{ flowIdToDuplicate: string }>();
  const { replace } = useHistory();

  const { flowId } = useParams<{ flowId: string }>();

  const { data: profileInfo, isFetching: isFetchingProfileInfo } =
    useProfileInfoFetchQuery();
  const slugUrl = `${profileInfo?.assembly.workspaceSlug.name}-${profileInfo?.assembly.workspaceSlug.shortCode}`;

  const { canUserCreateFlow } = useGetUserFlowPermissions();

  const { data: flowDetails, isFetching: isFetchingFlowDetails } =
    useFetchFlowDetailsQuery(flowId || location.state?.flowIdToDuplicate);
  const { populateFlowDetailsFromAPI } = usePopulateFlowDetailsToStore(flowId);
  useEffect(() => {
    populateFlowDetailsFromAPI();
  }, [populateFlowDetailsFromAPI]);

  const openEditFlowInV3 = useOpenEditFlowInV3();

  const isFlowEditor =
    flowDetails?.data.isFlowOwner ||
    Boolean(
      flowDetails?.data.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
    );

  useEffect(() => {
    if (profileInfo && flowDetails) {
      if (!isFlowEditor && !location.state?.flowIdToDuplicate) {
        history.push(V2_HOME);
      }
    }
  }, [
    profileInfo,
    flowDetails,
    history,
    location.state?.flowIdToDuplicate,
    isFlowEditor,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // created a new param instead of reusing showShareSheet to prevent regression
    if (params.has('openShareSheet')) {
      params.delete('openShareSheet');

      setShareSheetModalOpen(true);

      replace({ search: params.toString() });
    }
  }, [history, location, replace, setShareSheetModalOpen]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const { isLeftAsideOpen, toggleLeftAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
    }),
    shallow,
  );

  useEffect(() => {
    if (isLeftAsideOpen && location.pathname.includes('editor')) {
      toggleLeftAsideOpen();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    function handleInvalidateFlowDetails(
      event: MessageEvent<{ type: 'invalidate-flow-details' }>,
    ) {
      if (event.data.type === 'invalidate-flow-details') {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, 'builder']);
        queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, '']);
        queryClient.invalidateQueries([GET_FLOW_PARTICIPANTS]);
        queryClient.invalidateQueries([GET_FLOW_VIEWERS]);
      }
    }

    window.addEventListener('message', handleInvalidateFlowDetails);

    return () => {
      window.removeEventListener('message', handleInvalidateFlowDetails);
    };
  }, [flowId, queryClient]);

  const folderId = useMemo(() => {
    return new URLSearchParams(window.location.search).get('folderId');
  }, []);

  const tabComponentProps = useMemo(() => {
    return getTabComponents({
      flowId,
      folderId,
      slugUrl,
      canUserCreateFlow,
      onTabClick(event, label) {
        if (label === EDITOR.toLowerCase()) {
          openEditFlowInV3(flowId, {
            enabledCallback() {
              event.preventDefault();
            },
          });
          return;
        }
      },
    });
  }, [canUserCreateFlow, flowId, folderId, openEditFlowInV3, slugUrl]);

  const onSelectTabHandler = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <>
      {isShareSheetModalOpen && <FlowsShareSheetController />}
      {!isFetchingFlowDetails && !isFetchingProfileInfo && (
        <FluidContainer id="sisenseApp">
          <StyledTabComponent
            {...tabComponentProps}
            selectedTabIndex={selectedTabIndex}
            handleSelectTab={onSelectTabHandler}
            isTabPositionFixed
          />
        </FluidContainer>
      )}
    </>
  );
};

export default FlowsBuilderTabsController;
