import styled, { css } from 'styled-components';
import { zIndexPopup } from '../../../Utils/styles/z-index';
import { device } from '../../../constants/layout';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';

export const PrimaryHeaderWrapper = styled.div<{
  top?: string;
}>`
  top: ${({ top }) => top};
  position: fixed;
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${ThemeV2.palette.gray1};
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  z-index: ${zIndexPopup};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray1};
`;

export const StyledLogo = styled.img`
  max-width: 100%;
  background-color: ${ThemeV2.palette.gray1};
`;

export const CustomHeader = styled.h6`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
  color: #262626;
  margin-bottom: 0px;
  margin-top: 6px;
  margin-left: 12px;
  max-width: calc(100vw - 340px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HamburgerButton = styled.div`
  margin-right: 4px;
  @media ${device.tabletOrDesktop} {
    display: none;
  }
`;

export const SearchBarContainer = styled.div`
  position: relative;
  margin-left: 12px;
  max-width: 560px;
  width: 100%;
`;

export const SearchBar = styled.input`
  border: 1px solid ${ThemeV2.palette.gray7};
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 36px;
  background-color: transparent;
  text-align: left;
  font-weight: 500;
  cursor: pointer;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  position: absolute;
  top: 6px;
  left: 6px;
`;

export const ShortcutContainer = styled.div<{
  shortcut?: string;
}>`
  display: flex;
  position: absolute;
  align-items: center;
  top: 0;
  right: 12px;
  height: 100%;
  z-index: ${({ shortcut }) => (shortcut !== '' ? '-1' : '1')};
  @media (max-width: 600px) {
    display: none;
  }
`;

const baseShortcutStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  border-radius: 4px;
  min-width: 20px;
  height: 20px;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
`;

export const ShortcutKey = styled.div`
  ${baseShortcutStyles}
  background-color: ${ThemeV2.palette.gray3};
  color: ${ThemeV2.palette.gray8};
`;

export const ShortcutButtonKey = styled.button`
  ${baseShortcutStyles};
  background-color: ${ThemeV2.palette.geekBlue1};
  border: 0;
  color: ${ThemeV2.palette.geekBlue5};
  cursor: pointer;
  font-family: unset;
  &:hover {
    background-color: ${ThemeV2.palette.geekBlue2};
  }
  &:focus {
    outline: auto !important;
  }
`;

export const ModalBackground = styled.div`
  position: absolute;
  width: 728px;
  height: 560px;
  z-index: -1;
  background-image: url('/images/extensionModalBlur.svg');
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 728px) {
    width: 100vw;
  }
`;

export const InnerModal = styled.div`
  background-color: ${ThemeV2.palette.white};
  border-radius: 4px;
  padding: 32px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 3px 14px rgba(0, 0, 0, 0.02),
    0px 4px 5px rgba(0, 0, 0, 0.12);
`;
