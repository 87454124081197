import { useCallback, useEffect, useMemo } from 'react';
import { EmailNotificationSettingFlowItemProps } from '../../atomic/molecules/EmailNotificationSettingsRow/types';
import { EMAIL_NOTIFICATION_SETTINGS } from '../../languages/en/flows';
import {
  FlowsNotificationItem,
  FlowsNotificationItemPreferenceTypes,
  FlowsNotificationPayload,
  useFlowsEmailSettingsMutation,
  useGetFlowNotificationPreferencesQuery,
  useGlobalEmailSettingsMutation,
  User,
  useUserInfoQuery,
  useUserSettingsMutation,
} from '../../queries/Settings';
import { mapHexCodeToEmoticon } from '../../Utils/mappers';
import { showErrorMessage } from '../../Utils/toast';

const createPayloadForUpdateUserSettings = (
  currentUser: User,
  notificationIdToChange: string,
) => {
  const { department, firstName, lastName, managers, reports } =
    currentUser.profile;
  const {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  } = currentUser.settings.emailPreferences;

  return {
    firstName,
    lastName,
    managers,
    reports,
    department,
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
    [notificationIdToChange]:
      !currentUser.settings.emailPreferences[notificationIdToChange],
  };
};

const useNotificationSettings = () => {
  const {
    data,
    isError,
    isInitialLoading: isGlobalSettingsLoading,
  } = useUserInfoQuery();
  const { isError: isMutationError, mutate } = useUserSettingsMutation();
  const {
    data: flowNotificationPreferences,
    isInitialLoading: isFlowItemSettingsLoading,
  } = useGetFlowNotificationPreferencesQuery();

  const {
    isError: updateFlowSettingsOptionError,
    mutate: updateFlowSettingsOption,
  } = useFlowsEmailSettingsMutation();

  const { mutate: updateGlobalSettingsOption } =
    useGlobalEmailSettingsMutation();

  const flowItems = flowNotificationPreferences?.email.entities.flows;
  const flowAppItems = flowNotificationPreferences?.mobile.entities.flows;

  const flowItemsWithPreference:
    | EmailNotificationSettingFlowItemProps[]
    | undefined
    | 0 = useMemo(() => {
    const generateFlowGroups = (flow: FlowsNotificationItem) => {
      return [
        {
          groupName: EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.groupTitle,
          options: [
            {
              label:
                EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                  .whenThisFlowStarts,
              isOn: flow.preferences[0].value || false,
            },
            {
              label:
                EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                  .reminder,
              isOn: flow.preferences[1].value || false,
            },
          ],
        },
        {
          groupName:
            EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.secondaryGroupTitle,
          options: [
            {
              label:
                EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows
                  .whenNewResponseIsPosted,
              isOn: flow.preferences[2].value || false,
            },
          ],
        },
      ];
    };
    return (
      flowItems?.length &&
      flowItems.map((flow: FlowsNotificationItem) => {
        return {
          id: flow.entityId,
          title: flow.name,
          emoticon: mapHexCodeToEmoticon(flow.icon.value),
          flowGroup: generateFlowGroups(flow),
        };
      })
    );
  }, [flowItems]);

  const flowAppItemsWithPreference: EmailNotificationSettingFlowItemProps[] =
    useMemo(() => {
      const generateFlowGroups = (flow: FlowsNotificationItem) => {
        return [
          {
            groupName: EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.groupTitle,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                    .whenThisFlowStarts,
                isOn: flow.preferences[0].value || false,
              },
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.preferences
                    .reminder,
                isOn: flow.preferences[1].value || false,
              },
            ],
          },
          {
            groupName:
              EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows.secondaryGroupTitle,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.otherFlows
                    .whenNewResponseIsPosted,
                isOn: flow.preferences[2].value || false,
              },
            ],
          },
        ];
      };

      if (flowAppItems?.length) {
        return flowAppItems.map((flow: FlowsNotificationItem) => {
          return {
            id: flow.entityId,
            title: flow.name,
            emoticon: mapHexCodeToEmoticon(flow.icon.value),
            flowGroup: generateFlowGroups(flow),
          };
        });
      }

      return [];
    }, [flowAppItems]);

  const isNotifyAllowanceOn =
    data?.user.settings.emailPreferences.notifyAllowance;
  const isNotifyNewCarrotsOn =
    data?.user.settings.emailPreferences.notifyNewCarrots;

  const flowItemCelebrateTeamMate: EmailNotificationSettingFlowItemProps =
    useMemo(() => {
      return {
        id: 'celebrateTeamMate',
        title: EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.title,
        emoticon: '🎉',
        flowGroup: [
          {
            groupName:
              EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.participation,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate
                    .participationDescription,
                isOn: isNotifyAllowanceOn || false,
              },
            ],
          },
          {
            groupName:
              EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate.response,
            options: [
              {
                label:
                  EMAIL_NOTIFICATION_SETTINGS.flows.celebrateTeamMate
                    .responseDescription,
                isOn: isNotifyNewCarrotsOn || false,
              },
            ],
          },
        ],
      };
    }, [isNotifyAllowanceOn, isNotifyNewCarrotsOn]);

  const getCombinedFlowItems: EmailNotificationSettingFlowItemProps[] =
    flowItemsWithPreference !== 0 && flowItemsWithPreference?.length
      ? [flowItemCelebrateTeamMate, ...flowItemsWithPreference]
      : [flowItemCelebrateTeamMate];

  const getCombinedFlowAppItems: EmailNotificationSettingFlowItemProps[] =
    flowAppItemsWithPreference?.length
      ? [flowItemCelebrateTeamMate, ...flowAppItemsWithPreference]
      : [flowItemCelebrateTeamMate];

  const handleActivityUpdateItemToggle = useCallback(
    (itemID) => {
      if (
        itemID ===
        EMAIL_NOTIFICATION_SETTINGS.activityUpdate.assemblySummaryLookAhead.id
      ) {
        updateGlobalSettingsOption({
          email: {
            global: {
              ASSEMBLY_SUMMARY_LOOK_AHEAD:
                !flowNotificationPreferences?.email.global
                  .assemblySummaryLookAhead,
            },
          },
        });
      } else {
        updateGlobalSettingsOption({
          email: {
            global: {
              ASSEMBLY_SUMMARY_LOOK_BACK:
                !flowNotificationPreferences?.email.global
                  .assemblySummaryLookBack,
            },
          },
        });
      }
    },
    [
      flowNotificationPreferences?.email.global.assemblySummaryLookAhead,
      flowNotificationPreferences?.email.global.assemblySummaryLookBack,
      updateGlobalSettingsOption,
    ],
  );

  const handleAppActivityUpdateItemToggle = useCallback(
    (itemID) => {
      if (
        itemID ===
        EMAIL_NOTIFICATION_SETTINGS.activityUpdate
          .assemblySummaryLookAheadForMobileApp.id
      ) {
        updateGlobalSettingsOption({
          mobile: {
            global: {
              ASSEMBLY_SUMMARY_LOOK_AHEAD:
                !flowNotificationPreferences?.mobile.global
                  .assemblySummaryLookAhead,
            },
          },
        });
      } else {
        updateGlobalSettingsOption({
          mobile: {
            global: {
              ASSEMBLY_SUMMARY_LOOK_BACK:
                !flowNotificationPreferences?.mobile.global
                  .assemblySummaryLookBack,
            },
          },
        });
      }
    },
    [
      flowNotificationPreferences?.mobile.global.assemblySummaryLookAhead,
      flowNotificationPreferences?.mobile.global.assemblySummaryLookBack,
      updateGlobalSettingsOption,
    ],
  );

  const getActivityUpdatesItems = useMemo(() => {
    return [
      {
        id: EMAIL_NOTIFICATION_SETTINGS.activityUpdate.assemblySummaryLookAhead
          .id,
        title:
          EMAIL_NOTIFICATION_SETTINGS.activityUpdate.assemblySummaryLookAhead
            .title,
        isOn: flowNotificationPreferences?.email.global
          .assemblySummaryLookAhead,
        onToggle: handleActivityUpdateItemToggle,
      },
      {
        id: EMAIL_NOTIFICATION_SETTINGS.activityUpdate.assemblySummaryLookBack
          .id,
        title:
          EMAIL_NOTIFICATION_SETTINGS.activityUpdate.assemblySummaryLookBack
            .title,
        isOn: flowNotificationPreferences?.email.global.assemblySummaryLookBack,
        onToggle: handleActivityUpdateItemToggle,
      },
    ];
  }, [
    flowNotificationPreferences?.email.global.assemblySummaryLookAhead,
    flowNotificationPreferences?.email.global.assemblySummaryLookBack,
    handleActivityUpdateItemToggle,
  ]);

  const getMobileAppActivityUpdatesItems = useMemo(() => {
    return [
      {
        id: EMAIL_NOTIFICATION_SETTINGS.activityUpdate
          .assemblySummaryLookAheadForMobileApp.id,
        title:
          EMAIL_NOTIFICATION_SETTINGS.activityUpdate
            .assemblySummaryLookAheadForMobileApp.title,
        isOn: flowNotificationPreferences?.mobile.global
          .assemblySummaryLookAhead,
        onToggle: handleAppActivityUpdateItemToggle,
      },
      {
        id: EMAIL_NOTIFICATION_SETTINGS.activityUpdate
          .assemblySummaryLookBackForMobileApp.id,
        title:
          EMAIL_NOTIFICATION_SETTINGS.activityUpdate
            .assemblySummaryLookBackForMobileApp.title,
        isOn: flowNotificationPreferences?.mobile.global
          .assemblySummaryLookBack,
        onToggle: handleAppActivityUpdateItemToggle,
      },
    ];
  }, [
    flowNotificationPreferences?.mobile.global.assemblySummaryLookAhead,
    flowNotificationPreferences?.mobile.global.assemblySummaryLookBack,
    handleAppActivityUpdateItemToggle,
  ]);

  const handleUpdateGlobalPreference = useCallback(
    (notificationId: string) => {
      const currentUser = data?.user;
      if (currentUser) {
        const payload = createPayloadForUpdateUserSettings(
          currentUser,
          notificationId,
        );

        mutate(payload);
      }
    },
    [mutate, data?.user],
  );

  const handleToggle = useCallback(
    (notificationId: string) => {
      const currentUser = data?.user;
      if (currentUser && notificationId === 'task_activity') {
        currentUser.settings.emailPreferences.task_activity = <boolean>(
          flowNotificationPreferences?.email.global.tasks
        );
      }

      handleUpdateGlobalPreference(notificationId);
      if (notificationId === 'mentions') {
        updateGlobalSettingsOption({
          email: {
            global: {
              mentions: !flowNotificationPreferences?.email.global.mentions,
            },
          },
        });
      }

      if (notificationId === 'task_activity') {
        updateGlobalSettingsOption({
          email: {
            global: {
              task_activity: !flowNotificationPreferences?.email.global.tasks,
            },
          },
        });
      }
    },
    [
      data?.user,
      updateGlobalSettingsOption,
      handleUpdateGlobalPreference,
      flowNotificationPreferences?.email.global.tasks,
      flowNotificationPreferences?.email.global.mentions,
    ],
  );

  const onFlowItemOptionsToggle = (optionId?: string) => {
    let notificationId = '';
    if (optionId === 'celebrateTeamMate_0_0') {
      notificationId = 'notifyAllowance';
    }
    if (optionId === 'celebrateTeamMate_1_0') {
      notificationId = 'notifyNewCarrots';
    }
    handleUpdateGlobalPreference(notificationId);
    const flowPreference = optionId?.split('_');
    const flowId = flowPreference?.length && flowPreference[0];
    const groupId = flowPreference?.length && flowPreference[1];
    const selectedOptionId = flowPreference?.length && flowPreference[2];
    const updatingFlowItem = flowItems?.find(
      (flowItem) => flowItem.entityId === flowId,
    );

    if (updatingFlowItem) {
      const getSelectedEntityType = () => {
        const selectedEntity = `${groupId}_${selectedOptionId}`;
        const entityTypes: Record<
          string,
          FlowsNotificationItemPreferenceTypes
        > = {
          '0_0': FlowsNotificationItemPreferenceTypes.FLOW_TRIGGERED,
          '0_1': FlowsNotificationItemPreferenceTypes.FLOW_REMINDER,
          '1_0': FlowsNotificationItemPreferenceTypes.FLOW_RESPONSE,
        };
        return entityTypes[selectedEntity] ?? '';
      };
      const setEntityTypeValue = () => {
        const existingEntityTypeValue = updatingFlowItem.preferences.find(
          (item) => item.type === getSelectedEntityType(),
        );
        return !existingEntityTypeValue?.value || false;
      };
      const payload: FlowsNotificationPayload = {
        email: {
          entities: [
            {
              entityId: updatingFlowItem.entityId,
              entityType: 'FLOW',
              type: getSelectedEntityType(),
              value: setEntityTypeValue(),
            },
          ],
        },
      };
      updateFlowSettingsOption(payload);
    }
  };

  useEffect(() => {
    if (isMutationError || updateFlowSettingsOptionError) {
      showErrorMessage('Could not update your settings');
    }
  }, [isMutationError, updateFlowSettingsOptionError]);

  const handleFlowItemOptionsMobileAppToggle = useCallback(
    (optionId?: string) => {
      let notificationId = '';
      if (optionId === 'celebrateTeamMate_0_0_mobile') {
        notificationId = 'notifyAllowance';
      }
      if (optionId === 'celebrateTeamMate_1_0_mobile') {
        notificationId = 'notifyNewCarrots';
      }
      handleUpdateGlobalPreference(notificationId);
      const flowPreference = optionId?.split('_');
      const flowId = flowPreference?.length && flowPreference[0];
      const groupId = flowPreference?.length && flowPreference[1];
      const selectedOptionId = flowPreference?.length && flowPreference[2];
      const updatingFlowItem = flowAppItems?.find(
        (flowItem) => flowItem.entityId === flowId,
      );

      if (updatingFlowItem) {
        const getSelectedEntityType = () => {
          const selectedEntity = `${groupId}_${selectedOptionId}`;
          const entityTypes: Record<
            string,
            FlowsNotificationItemPreferenceTypes
          > = {
            '0_0': FlowsNotificationItemPreferenceTypes.FLOW_TRIGGERED,
            '0_1': FlowsNotificationItemPreferenceTypes.FLOW_REMINDER,
            '1_0': FlowsNotificationItemPreferenceTypes.FLOW_RESPONSE,
          };
          return entityTypes[selectedEntity] ?? '';
        };
        const setEntityTypeValue = () => {
          const existingEntityTypeValue = updatingFlowItem.preferences.find(
            (item) => item.type === getSelectedEntityType(),
          );
          return !existingEntityTypeValue?.value || false;
        };
        const payload: FlowsNotificationPayload = {
          mobile: {
            entities: [
              {
                entityId: updatingFlowItem.entityId,
                entityType: 'FLOW',
                type: getSelectedEntityType(),
                value: setEntityTypeValue(),
              },
            ],
          },
        };
        updateFlowSettingsOption(payload);
      }
    },
    [flowAppItems, handleUpdateGlobalPreference, updateFlowSettingsOption],
  );

  const handleMobileAppToggle = useCallback(
    (notificationId: string) => {
      if (notificationId === 'mobile_mentions') {
        updateGlobalSettingsOption({
          mobile: {
            global: {
              mentions: !flowNotificationPreferences?.mobile.global.mentions,
            },
          },
        });
      }

      if (notificationId === 'mobile_task_activity') {
        updateGlobalSettingsOption({
          mobile: {
            global: {
              task_activity: !flowNotificationPreferences?.mobile.global.tasks,
            },
          },
        });
      }
    },
    [
      updateGlobalSettingsOption,
      flowNotificationPreferences?.mobile.global.tasks,
      flowNotificationPreferences?.mobile.global.mentions,
    ],
  );

  const emailPreferences = {
    globalSettings: {
      ...data?.user.settings.emailPreferences,
      ...flowNotificationPreferences?.email.global,
    },
    flows: getCombinedFlowItems,
    activityUpdates: getActivityUpdatesItems,
  };

  const mobileAppPreferences = {
    globalSettings: {
      ...flowNotificationPreferences?.mobile.global,
    },
    flows: getCombinedFlowAppItems,
    activityUpdates: getMobileAppActivityUpdatesItems,
  };

  return {
    handleMobileAppToggle,
    handleFlowItemOptionsMobileAppToggle,
    mobileAppPreferences: mobileAppPreferences,
    emailPreferences: emailPreferences,
    handleToggle,
    onFlowItemOptionsToggle,
    isError,
    isLoading: isGlobalSettingsLoading || isFlowItemSettingsLoading,
  };
};

export default useNotificationSettings;
