import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import {
  device,
  PrimaryNavWidth,
  SecondaryNavWidth,
} from '../../../constants/layout';
import { LayoutStyleProps } from '../../../interfaces/Layout';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const IconButton = styled.button<{
  showUnread?: number;
  isNotificationsOpen?: boolean;
}>`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  background-color: ${({ isNotificationsOpen }) =>
    isNotificationsOpen ? ThemeV2.palette.gray3 : ThemeV2.palette.gray1};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 16px;
  padding: 4px;
  color: ${ThemeV2.palette.gray7};
  &:hover {
    background-color: ${ThemeV2.palette.gray2};
  }
  & > *,
  & > span > svg {
    height: 24px;
    min-width: 32px;
    width: 32px;
  }
  ${({ showUnread }) =>
    showUnread &&
    `
    &::before {
      content: "";
      font-size: 10px;
      color: white;
      position: absolute;
      top: 11.5%;
      right: 4%;
      width: 17px;
      height: 15px;
      border-radius: 100px;
      background-color: ${ThemeV2.palette.geekBlue6};
      border: 1px solid ${ThemeV2.palette.gray1};
      z-index: 2;
      @media ${device.tabletOrDesktop} {
        right: 4%;
      }
    }
    &::after {
      content: "${showUnread < 10 ? showUnread : `9+`}";
      font-size: 10px;
      font-weight: 400;
      font-style: normal;
      line-height: 12px;
      color: white;
      position: absolute;
      top: 18.5%;
      right: ${showUnread < 10 ? `11%` : `11%`};
      width: 12px;
      height: 12px;
      z-index: 3;
      @media ${device.tabletOrDesktop} {
        right: ${showUnread < 10 ? `11%` : `11%`};
      }
    }
`}
`;

export const NotificationsNumber = styled.div`
  position: absolute;
  z-index: 3;
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: fixed;
  transition-duration: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen ? `550ms` : `300ms`};
  transition-timing-function: ease;
  transition-property: left;
  bottom: 24px;
  left: ${({ isLeftAsideOpen }) =>
    isLeftAsideOpen
      ? `${PrimaryNavWidth + SecondaryNavWidth - (24 + 40)}px`
      : `${PrimaryNavWidth + 24}px`};
  height: 40px;
  border-radius: 50%;

  @media ${device.tablet} {
    display: ${({ isRightAsideOpen }) => isRightAsideOpen && 'none'};
  }

  @media ${device.mobile} {
    display: none;
  }
`;

export const PopoverContainer = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : `232px`)};
  z-index: ${zIndexPopup};
`;
