import React from 'react';
import GiphySelect from '../../../vendors/react-gif-select/components/GiphySelect';
import { GIPHY_KEY } from '../../../config';
import { GiphyProps } from './interface';

const Giphy = (props: GiphyProps) => {
  const { gifRatings, onGifSelect } = props;
  return (
    <GiphySelect
      autoFocus
      requestRating={gifRatings.toLocaleLowerCase()}
      requestKey={GIPHY_KEY}
      onEntrySelect={onGifSelect}
      requestDelay={500}
    />
  );
};

export default Giphy;
