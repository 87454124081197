import styled from 'styled-components';

import Dropdown from '../Dropdown_V2';
import ThemeV2 from '../../../theme';
import DrawerHeaderWithIcon from '../DrawerHeaderWithIcon';
import { device, MaximumRightContentWidth } from '../../../constants/layout';

export const LeaderboardWrapper = styled.div`
  height: 100%;
  padding: 16px 24px;
  background-color: ${ThemeV2.palette.gray1};
`;

export const StyledDropdown = styled(Dropdown)`
  margin-bottom: 8px;
  max-width: ${MaximumRightContentWidth}px;
`;

export const StyledDropdownTwo = styled(Dropdown)`
  margin-bottom: 16px;
  max-width: ${MaximumRightContentWidth}px;
`;

export const StyledDrawerHeaderWithIcon = styled(DrawerHeaderWithIcon)`
  @media ${device.mobile} {
    margin-top: 24px;
  }
`;
