import {
  FilterPayload,
  PostSortOptions,
  ReactionEmoji,
} from '../../interfaces/Feed';

export enum PageType {
  MainFeed = 'MAIN',
  FlowFeed = 'FLOW',
  ProfileFeed = 'PROFILE',
  SingleFlowFeed = 'SINGLE_POST',
  Recognition = 'RECOGNITION',
}

export type ReactionUpdatedPayload = {
  postId?: string;
  flowId?: string;
  responseId?: string;
  action: 'SET' | 'UNSET';
  reaction: ReactionEmoji;
  fromMember: {
    memberId: string;
    lastName: string;
    firstName: string;
  };
  commentId?: string;
};

export type ReactionUpdatedSocketProps = {
  pageType: PageType;
  sortValue?: PostSortOptions;
  filter?: FilterPayload;
  profileId?: string;
};
