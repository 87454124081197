import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Heading from '../../atoms/Heading';

export const RightDrawerHeaderContainer = styled(Flex)`
  border-bottom: 1px solid #e8e8e8;
`;

export const LogoContainer = styled(Flex)`
  height: 24px;
  width: 24px;
  overflow: hidden;
  border-radius: 4px;
  background-color: transparent;
  margin-left: 8px;
`;

export const StyledLogo = styled.img`
  max-width: 100%;
  background-color: transparent;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 0 8px;
`;
