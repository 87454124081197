import ThemeV2 from '../../../theme';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { FeedPostUserProps } from '../../../interfaces/Feed';
import { ANONYMOUS } from '../../../languages/en/singleWords';
import { MemberState } from '../../../interfaces/user';

export const getScheduleFlowEmptyStateSubHeading = (
  date: string,
  time: string,
  timeZone: string | undefined,
  isShortcut: boolean,
  isOwner: boolean,
) => {
  if (isShortcut) {
    return `${FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
      date,
      time,
      timeZone,
    )} ${isOwner ? 'Participants' : 'You'} ${
      FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES
        .SUB_HEADING_SUFFIX_WITH_SHORTCUT
    }`;
  }

  return `${FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
    date,
    time,
    timeZone,
  )} ${isOwner ? 'Participants and viewers' : 'You'} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT
  }`;
};

export const getScheduleFlowEmptyStateTriggeredOnceSubHeading = (
  date: string,
  time: string,
  timeZone: string | undefined,
  isShortcut: boolean,
  isOwner: boolean,
) => {
  if (isShortcut) {
    return `${FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
      date,
      time,
      timeZone,
    )} ${isOwner ? 'Participants' : 'You'} ${
      FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
        .SUB_HEADING_SUFFIX_WITH_SHORTCUT
    }`;
  }

  return `${FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES.GET_SUB_HEADING_PREFIX(
    date,
    time,
    timeZone,
  )} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_1
  } ${isOwner ? 'all participants' : 'you'} ${
    FLOW_FEED_STATES.SCHEDULED_FLOW_TRIGGERED_ONCE_EMPTY_STATES
      .SUB_HEADING_SUFFIX_WITHOUT_SHORTCUT_2
  }`;
};

export const mapUsersToChipProps = (users: FeedPostUserProps[]) => {
  return users.map((user: FeedPostUserProps) => ({
    id: user.memberID,
    title: user.name || ANONYMOUS,
    avatar: {
      img: user.image,
      userId: user.memberID,
      name: user.firstName,
      icon: user.memberState === MemberState.PENDING ? 'pending-person' : '',
      iconColor: ThemeV2.palette.white,
    },
  }));
};
