import styled from 'styled-components';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';

export const StyledFeedPostHeaderContent = styled.div`
  padding: 0 8px;
  overflow-x: hidden;
`;

export const FeedPostHeaderOptionsContainer = styled.div`
  margin-left: auto;
`;

export const OthersLabel = styled(Body)`
  cursor: pointer;

  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
`;
