import {
  FileFormat,
  fileFormatExtensionMap,
  FileType,
} from '../../atomic/organism/FileViewer/types';
import ThemeV2 from '../../theme';
import { FilterPayload } from '../../interfaces/Feed';

export const dataUrlToFile = async (dataUrl: string, fileName: string) => {
  const res: Response = await fetch(dataUrl, { credentials: 'same-origin' });
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
};

export const assignFileTypeInfo = (
  fileType: FileType,
  setColor?: React.Dispatch<React.SetStateAction<string>>,
  setIcon?: React.Dispatch<React.SetStateAction<string>>,
) => {
  if (fileFormatExtensionMap[FileFormat.document].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.blue7);
    }
    if (setIcon) {
      setIcon('docFile');
    }
  } else if (fileFormatExtensionMap[FileFormat.image].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.blue8);
    }
    if (setIcon) {
      setIcon('imageFile');
    }
  } else if (
    fileFormatExtensionMap[FileFormat.presentation].includes(fileType)
  ) {
    if (setColor) {
      setColor(ThemeV2.palette.volcano6);
    }
    if (setIcon) {
      setIcon('presentationFile');
    }
  } else if (fileFormatExtensionMap[FileFormat.pdf].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.red6);
    }
    if (setIcon) {
      setIcon('pdfFile');
    }
  } else if (
    fileFormatExtensionMap[FileFormat.spreadsheet].includes(fileType)
  ) {
    if (setColor) {
      setColor(ThemeV2.palette.green7);
    }
    if (setIcon) {
      setIcon('csvFile');
    }
  } else if (fileFormatExtensionMap[FileFormat.audio].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.orange6);
    }
    if (setIcon) {
      setIcon('audioFile');
    }
  } else if (fileFormatExtensionMap[FileFormat.video].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.red7);
    }
    if (setIcon) {
      setIcon('videoFile');
    }
  } else if (fileFormatExtensionMap[FileFormat.zip].includes(fileType)) {
    if (setColor) {
      setColor(ThemeV2.palette.gray8);
    }
    if (setIcon) {
      setIcon('zipFile');
    }
  } else if (fileType === 'url') {
    if (setColor) {
      setColor(ThemeV2.palette.geekBlue4);
    }
    if (setIcon) {
      setIcon('linkAttachment');
    }
  } else {
    if (setColor) {
      setColor(ThemeV2.palette.gray6);
    }
    if (setIcon) {
      setIcon('genericFile');
    }
  }
};

export const formatFileFilters = (
  filter: FilterPayload | undefined,
  searchWords?: string,
) => {
  if (filter) {
    if (filter.fileTypes) {
      const newFileTypes: FileType[] = [];
      filter.fileTypes.forEach((fileType) => {
        if (fileType === 'links') {
          newFileTypes.push('links');
        } else {
          newFileTypes.push(
            ...fileFormatExtensionMap[
              fileType as keyof typeof fileFormatExtensionMap
            ],
          );
        }
      });
      filter.fileTypes = newFileTypes;
    }
    if (searchWords) {
      filter.keywords = searchWords?.split(' ');
    }
    return filter;
  }
  if (searchWords) {
    filter = {
      keywords: searchWords?.split(' '),
    };
  }
  return filter;
};
