import { LayoutStoreState } from '../../interfaces/Layout';

const selectedMemberSelector = (state: LayoutStoreState) =>
  state.selectedMember;

const toggleRightAsideOpenSelector = (state: LayoutStoreState) =>
  state.toggleRightAsideOpen;

const showChatsSelector = (state: LayoutStoreState) => state.setShowChats;

const setRightAsideOpenToFalseSelector = (state: LayoutStoreState) =>
  state.setRightAsideOpenToFalse;

const isRightAsideOpenSelector = (state: LayoutStoreState) =>
  state.isRightAsideOpen;

const removeSelectedMemberSelector = (state: LayoutStoreState) =>
  state.removeSelectedMember;

export {
  showChatsSelector,
  selectedMemberSelector,
  isRightAsideOpenSelector,
  removeSelectedMemberSelector,
  toggleRightAsideOpenSelector,
  setRightAsideOpenToFalseSelector,
};
