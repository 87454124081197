import { useProfileInfoFetchQuery } from '../../queries/Profile';

const useMultiAssemblySlug = (isAuthenticated = false) => {
  const { data } = useProfileInfoFetchQuery(isAuthenticated);
  const slugUrl = `${data?.assembly.workspaceSlug.name}-${data?.assembly.workspaceSlug.shortCode}`;

  return {
    slugUrl,
    shortCode: data?.assembly.workspaceSlug.shortCode,
  };
};

export default useMultiAssemblySlug;
