import React from 'react';

import Button from '../../atoms/Button';
import {
  StyledArrowIconWrapper,
  StyledExternalLink,
  StyledNavInstructions,
  StyledNavigationButton,
  StyledFooter,
  VerticalDivider,
  StyledCreatorWrapper,
  StyledCreatorLogo,
  StyledPoweredByText,
  StyledPoweredByLogo,
  StyledDetailsWrapper,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import { LoadingProps, ComponentStatus } from '../../../interfaces/component';
import { AND_LOWER, CLOSE } from '../../../languages/en/singleWords';
import {
  KEYS_NAVIGATE,
  LETS_GET_STARTED,
  NEED_HELP,
  USE_THE,
} from '../../../languages/en/flows/participation';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import placeholderLogo from '../../../img/assembly-placeholder-logo.svg';
import { POWERED_BY_ASSEMBLY } from '../../../languages/en/flows';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

type LoadedFlowsParticipationFooterProps = {
  status: ComponentStatus.LOADED;
  hideNavigationButtons?: boolean;
  isArrowKeyNavigationSupported?: boolean;
  isPreviousButtonDisabled?: boolean;
  isNextButtonDisabled?: boolean;
  onPreviousButtonClick?: () => void;
  onNextButtonClick?: () => void;
  shouldDisplayHelpButton?: boolean;
  onNeedHelpButtonClick?: () => void;
  footerButtons: JSX.Element[];
  isExternalFlow?: boolean;
  creator?: {
    name: string;
    logo: string;
  };
};

type ErrorProps = {
  status: ComponentStatus.ERROR;
  onClick?: () => void;
};

export type FlowsParticipationFooterProps =
  | LoadedFlowsParticipationFooterProps
  | LoadingProps
  | ErrorProps;

const LoadedFlowsParticipationFooter = ({
  hideNavigationButtons = false,
  isArrowKeyNavigationSupported = false,
  isNextButtonDisabled,
  isPreviousButtonDisabled,
  onPreviousButtonClick,
  onNextButtonClick,
  shouldDisplayHelpButton = false,
  onNeedHelpButtonClick,
  footerButtons,
  isExternalFlow = false,
  creator,
}: LoadedFlowsParticipationFooterProps) => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  return (
    <StyledFooter>
      {shouldDisplayHelpButton && (
        <StyledExternalLink
          href="https://www.joinassembly.com/articles/how-to-answer-a-flow"
          target="blank"
        >
          <Button variant="text" size="large" onClick={onNeedHelpButtonClick}>
            {NEED_HELP}
          </Button>
        </StyledExternalLink>
      )}

      {isExternalFlow && (
        <>
          <StyledCreatorWrapper>
            <Flex>
              <StyledCreatorLogo
                src={creator?.logo || placeholderLogo}
                alt={creator?.name}
              />
              <Body variant="body1Medium" color="gray9">
                {creator?.name}
              </Body>
            </Flex>
          </StyledCreatorWrapper>
          <StyledDetailsWrapper>
            <Flex margin="0 12px">
              <StyledPoweredByText>{POWERED_BY_ASSEMBLY}</StyledPoweredByText>
              <StyledPoweredByLogo src={placeholderLogo} />
            </Flex>
          </StyledDetailsWrapper>
        </>
      )}

      {isArrowKeyNavigationSupported && (
        <StyledNavInstructions color="gray8" inline variant="body3">
          {USE_THE}&nbsp;
          <StyledArrowIconWrapper>
            <SVGIcon icon="arrow-up" size="12px" />
          </StyledArrowIconWrapper>
          &nbsp;{AND_LOWER}&nbsp;
          <StyledArrowIconWrapper>
            <SVGIcon icon="arrow-down" size="12px" />
          </StyledArrowIconWrapper>
          &nbsp;{KEYS_NAVIGATE}
        </StyledNavInstructions>
      )}
      {!hideNavigationButtons && (
        <>
          <StyledNavigationButton
            color="secondary"
            dataTestId="previousButton"
            dataQaId="btn-flow-previous"
            disabled={isPreviousButtonDisabled}
            onClick={onPreviousButtonClick}
            size={isMobileView ? 'medium' : 'large'}
            width={isMobileView ? '40px' : '48px'}
          >
            <SVGIcon
              color={
                isPreviousButtonDisabled
                  ? ThemeV2.palette.gray6
                  : ThemeV2.palette.geekBlue6
              }
              icon="carat-up"
              size={isMobileView ? '24px' : '40px'}
            />
          </StyledNavigationButton>

          <StyledNavigationButton
            color="secondary"
            dataTestId="nextButton"
            dataQaId="btn-flow-next"
            disabled={isNextButtonDisabled}
            onClick={onNextButtonClick}
            size={isMobileView ? 'medium' : 'large'}
            width={isMobileView ? '40px' : '48px'}
          >
            <SVGIcon
              color={
                isNextButtonDisabled
                  ? ThemeV2.palette.gray6
                  : ThemeV2.palette.geekBlue6
              }
              icon="carat-down"
              size={isMobileView ? '24px' : '40px'}
            />
          </StyledNavigationButton>
          <VerticalDivider />
        </>
      )}

      {footerButtons.map((ButtonElement) => ButtonElement)}
    </StyledFooter>
  );
};

const FlowsParticipationFooter = (props: FlowsParticipationFooterProps) => {
  switch (props.status) {
    case ComponentStatus.LOADED: {
      return <LoadedFlowsParticipationFooter {...props} />;
    }
    case ComponentStatus.ERROR: {
      const { onClick } = props;
      return (
        <StyledFooter>
          <Button size="large" color="secondary" onClick={onClick}>
            {CLOSE}
          </Button>
        </StyledFooter>
      );
    }
    default: {
      return (
        <StyledFooter>
          <Button disabled size="large">
            {LETS_GET_STARTED}
          </Button>
        </StyledFooter>
      );
    }
  }
};

export default FlowsParticipationFooter;
