import ContentLoader from 'react-content-loader';
import React from 'react';

import { RightWrapper, Wrapper } from './styles';
import ThemeV2 from '../../../theme';

const RankingEntryLoader = () => (
  <Wrapper isCurrentUser={false}>
    <div>
      <ContentLoader
        speed={2}
        width={142}
        height={40}
        viewBox="0 0 142 40"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="ranking-entry-left"
      >
        <circle cx="24" cy="20" r="12" />
        <rect x="48" y="14" rx="4" width="94" height="12" />
        <rect x="120" y="14" rx="4" width="17" height="12" />
      </ContentLoader>
    </div>

    <RightWrapper>
      <ContentLoader
        speed={2}
        width={34}
        height={40}
        viewBox="0 0 34 12"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="ranking-entry-right"
      >
        <rect x="0" y="0" rx="4" width="34" height="12" />
      </ContentLoader>
    </RightWrapper>
  </Wrapper>
);

export default RankingEntryLoader;
