import { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import FlowsRoutesController from '../flows/FlowsRoutesController';
import RewardsController from '../rewards/RoutesController';
import UserSettingsController from '../settings/UserSettingsController';

import {
  FLOWS,
  DELETE_POST,
  INVITE_PEOPLE,
  KNOWLEDGE_CENTER,
  PEOPLE_DIRECTORY,
  QUICK_SETUP,
  RECOGNITION_FEEDS,
  V2_HOME,
  V2_POST_PAGE,
  V2_REWARDS,
  V2_SETTINGS,
  V2_USER_PROFILE,
  V3_POST_PAGE,
} from '../../constants/routes';

import { fireConversionForSignup } from '../../Utils/analytics/gtag';
import { LayoutStoreState } from '../../interfaces/Layout';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getIsCurrentUserOwner } from '../../queries/Profile/utils';
import useLayoutStore from '../../stores/layoutStore';
import SinglePostController from '../SinglePostController';
import { MainFeedController } from '../home/MainFeedController';
import { ProfileFeedController } from '../home/ProfileFeedController';
import PrimaryLayoutController from '../layout/PrimaryLayoutController';

import SingleFlowFeedPostController from '../home/SingleFlowFeedPostController';

import { useMemberAuthenticated } from '../../hooks/useAuth';
import useUpdateTimezone from '../../hooks/useUpdateTimezone';
import KnowledgeCenterController from '../knowledgeCenter/KnowledgeCenterPageController';
import { PeopleDirectory } from '../peopleDirectory';

import useRepliesSidebar from '../../hooks/useRepliesSidebar';
import useMobileApp from '../../hooks/useMobileApp/index';
import { RecognitionController } from '../home/RecognitionController';
import CreateOneClickFlowController from '../flows/CreateOneClickFlowController';
import InvitePeopleController from '../InvitePeopleController';
import DeleteModalController from '../DeleteModalController';

const rightAsideDisabledRoutesWithSidebar = [
  V2_REWARDS,
  V2_SETTINGS,
  QUICK_SETUP,
];

// THIS PAGE IS EXPERIMENTAL. IT ONLY EXISTS TO PROVIDE STRUCTURE TO FUTURE ROUTES
// FOR V2.

export const layoutStoreSelectorForNotebook = (state: LayoutStoreState) => ({
  setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
  setShowRightAsideToFalse: state.setShowRightAsideToFalse,
});

export const layoutSelector = (state: LayoutStoreState) => ({
  setShowRightAsideToTrue: state.setShowRightAsideToTrue,
  setShowRightAsideToFalse: state.setShowRightAsideToFalse,
});

const V2RouteController = () => {
  useMobileApp();
  const { showReplies } = useRepliesSidebar();

  const { setShowRightAsideToTrue, setShowRightAsideToFalse } = useLayoutStore(
    layoutSelector,
    shallow,
  );

  const { pathname } = useLocation();
  const authenticated = useMemberAuthenticated();

  useEffect(() => {
    if (
      rightAsideDisabledRoutesWithSidebar.some((route) =>
        pathname.includes(route),
      )
    ) {
      setShowRightAsideToFalse();
    } else if (!showReplies && pathname.includes(V3_POST_PAGE)) {
      setShowRightAsideToFalse();
    } else {
      setShowRightAsideToTrue();
    }
  }, [
    pathname,
    showReplies,
    setShowRightAsideToTrue,
    setShowRightAsideToFalse,
  ]);

  const { data: profileData, isInitialLoading: isLoading } =
    useProfileInfoFetchQuery(authenticated);
  useUpdateTimezone(profileData?.member.timeZone, isLoading, authenticated);

  useEffect(() => {
    if (profileData && getIsCurrentUserOwner(profileData)) {
      fireConversionForSignup(profileData.member.memberId);
    }
  }, [profileData]);

  if (!authenticated) {
    return <div />;
  }

  return (
    <PrimaryLayoutController>
      <Switch>
        <Route path={`/:slugUrl/create-one-click-flow/:templateId`}>
          <CreateOneClickFlowController />
        </Route>
        <Route
          exact
          path={`/:slugUrl${V2_HOME}`}
          component={MainFeedController}
        />
        <Route
          exact
          path={`/:slugUrl${V2_USER_PROFILE}`}
          component={ProfileFeedController}
        />
        <Route path={`/:slugUrl${V2_REWARDS}`} component={RewardsController} />
        <Route
          path={`/:slugUrl${V2_SETTINGS}`}
          component={UserSettingsController}
        />
        <Route
          path={`/:slugUrl${V3_POST_PAGE}`}
          component={SingleFlowFeedPostController}
        />
        <Route
          path={`/:slugUrl${V2_POST_PAGE}`}
          component={SinglePostController}
        />
        <Route
          path={`/:slugUrl${RECOGNITION_FEEDS}`}
          component={RecognitionController}
        />
        <Route path={`/:slugUrl${FLOWS}`} component={FlowsRoutesController} />
        <Route
          path={`/:slugUrl${KNOWLEDGE_CENTER}/:view?/:flowId?/:responseId?/:blockId?/:fileName?`}
          component={KnowledgeCenterController}
        />
        <Route
          path={`/:slugUrl${PEOPLE_DIRECTORY}`}
          component={PeopleDirectory}
        />
        <Route
          path={`/:slugUrl${INVITE_PEOPLE}`}
          component={InvitePeopleController}
        />
        <Route
          path={`/:slugUrl${DELETE_POST}`}
          component={DeleteModalController}
        />
        <Route path={FLOWS} component={FlowsRoutesController} />
        <Redirect to={`/:slugUrl${V2_HOME}`} />
      </Switch>
    </PrimaryLayoutController>
  );
};

export default V2RouteController;
