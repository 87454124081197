import { useHistory, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import {
  CopyLinkButton,
  FeedHeaderMainContentInnerWrapper,
  FlowsDetailsWrapper,
  PopoverBody,
  Root,
  StyledBody,
  StyledButtonContainer,
  StyledDropdownIconButton,
  StyledFlowsFeedHeaderMainContent,
  StyledHeading,
  StyledIconButton,
} from './styles';

import { FlowsFeedHeaderProps } from './type';

import { defaultFlow, getIsScheduledFlowEnded } from '../../../Utils/flows';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import FlowDescriptionPopover from './flowDescriptionPopover';

import { MORE_OPTIONS, RESTART_FLOW } from '../../../languages/en/flows';
import {
  EXIT_FULL_SCREEN,
  FLOW_FEED_STATES,
  FLOW_REMIND_PARTICIPANTS,
  NO_DESCRIPTION,
} from '../../../languages/en/flows/feed';
import { COPY_LINK } from '../../../languages/en/home';
import { SHARE } from '../../../languages/en/singleWords';

import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { getWorkspaceTitlePrefix } from '../../../Utils/richMetaData';
import { Flex } from '../../../Utils/styles/display';
import { device } from '../../../constants/layout';
import useMobileApp from '../../../hooks/useMobileApp';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import { renderEditorShareButton } from './renderEditorShareButton';
import { DoraFlowSummariesButton } from '../DoraFlowSummariesButton';
import { isUserAdmin } from '../../../Utils/user';

const FlowsFeedHeader = (props: FlowsFeedHeaderProps) => {
  const {
    icon,
    kind,
    title,
    onClick,
    schedule,
    description,
    menuOptions,
    isFlowEditor,
    isFlowParticipant,
    isFlowViewer,
    isFullScreen,
    backgroundColor,
    openRunNowModel,
    onMenuOptionClick,
    hasActiveOccurrence,
    requestedUsersCount,
    isShareRequestsLoading,
    openShareSheetModal,
    handleCopyFlowFeedLink,
    openRequestAccessModal,
    openRemindParticipantsModal,
    hasPosts,
    hasOnlyGifBlocks,
    isFlowSummariesFeatureOn,
    isUpgradedWorkspace,
    onFlowSummariesActionClick,
  } = props;

  const { isEmbeddedInMainApp } = useLayoutStore();

  const { isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { data: profileData } = useProfileInfoFetchQuery();
  const isAdmin = profileData ? isUserAdmin(profileData.member) : false;

  const history = useHistory();
  const location = useLocation();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { isMobileApp } = useMobileApp();

  const isScheduledFlowEnded = getIsScheduledFlowEnded({
    isFlowOwner: isFlowEditor,
    kind,
    hasActiveOccurrence,
    schedule,
  });

  const onRestartFlow = () => {
    const { pathname } = location;
    history.push(`${pathname}/editor?action=${RESTART_FLOW}`);
  };

  const handleOpenShareSheetModal = () => {
    if (openShareSheetModal) {
      trackShareSheetActionEvent({
        tab: 'invite and share',
        action: SHARE_SHEET_EVENTS.SHARE_SHEET_STARTED,
        shareFlowStartSource: 'flow header',
      });
      openShareSheetModal(true);
    }
  };

  const handleOpenRequestAccessModal = () => {
    openRequestAccessModal?.(true);
  };

  const canDisplayRequestAccessShareButton =
    !isFlowEditor && (isFlowViewer || isFlowParticipant);

  const renderActionItem = () => {
    const doraButton =
      isFlowSummariesFeatureOn && isEmbeddedInMainApp ? (
        <DoraFlowSummariesButton
          hasPosts={!!hasPosts}
          isAdmin={isAdmin}
          isDisabled={!hasPosts || !!hasOnlyGifBlocks}
          isUpgraded={!!isUpgradedWorkspace}
          onFlowSummariesActionClick={onFlowSummariesActionClick}
        />
      ) : null;

    if (isFullScreen) {
      return (
        <StyledButtonContainer>
          <Button
            icon="exit-full-screen"
            size="medium"
            color="secondary"
            onClick={onClick}
            variant="text"
            dataTestId="exit-full-screen"
          >
            {EXIT_FULL_SCREEN}
          </Button>
        </StyledButtonContainer>
      );
    } else if (isFlowEditor) {
      if (!hasActiveOccurrence && kind === 'ONDEMAND') {
        return (
          <StyledButtonContainer>
            {doraButton}
            <Button
              icon={FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_ICON}
              onClick={openRunNowModel}
              size="medium"
              color="secondary"
            >
              {FLOW_FEED_STATES.FLOW_NOT_TRIGGERED.BUTTON_TEXT}
            </Button>
            {renderEditorShareButton({
              requestedUsersCount,
              isShareRequestsLoading,
              handleOpenShareSheetModal,
            })}
          </StyledButtonContainer>
        );
      } else if (hasActiveOccurrence && kind !== 'NO_TRIGGER') {
        return (
          <StyledButtonContainer>
            {doraButton}
            <Button
              icon={FLOW_REMIND_PARTICIPANTS.BUTTON_ICON}
              onClick={openRemindParticipantsModal}
              color="secondary"
              size="medium"
            >
              {FLOW_REMIND_PARTICIPANTS.BUTTON_TEXT}
            </Button>
            {renderEditorShareButton({
              requestedUsersCount,
              isShareRequestsLoading,
              handleOpenShareSheetModal,
            })}
          </StyledButtonContainer>
        );
      } else {
        return (
          <StyledButtonContainer>
            {doraButton}
            {isScheduledFlowEnded && (
              <Button
                icon="sync"
                onClick={onRestartFlow}
                color="secondary"
                size="medium"
              >
                {RESTART_FLOW}
              </Button>
            )}
            {renderEditorShareButton({
              requestedUsersCount,
              isShareRequestsLoading,
              handleOpenShareSheetModal,
            })}
          </StyledButtonContainer>
        );
      }
    } else if (canDisplayRequestAccessShareButton) {
      return (
        <StyledButtonContainer>
          {doraButton}
          <Button
            icon="share"
            onClick={handleOpenRequestAccessModal}
            size="medium"
            data-test-id="request-access-button"
          >
            {SHARE}
          </Button>
        </StyledButtonContainer>
      );
    }

    return !location.pathname.includes('recognition') ? (
      <StyledButtonContainer>
        <CopyLinkButton
          icon="copy"
          size="medium"
          color="secondary"
          data-test-id="copy-link-button"
          onClick={handleCopyFlowFeedLink}
        >
          {COPY_LINK}
        </CopyLinkButton>
      </StyledButtonContainer>
    ) : null;
  };

  const renderMobileActionItem = () => {
    const renderFlowTriggerButtons = () => {
      if (!hasActiveOccurrence && kind === 'ONDEMAND') {
        return (
          <StyledIconButton
            variant="outlined"
            color="secondary"
            dataTestId="runNowButton"
            icon="play"
            onClick={openRunNowModel}
            size={ButtonSize.Medium}
          />
        );
      }
      if (hasActiveOccurrence && kind === 'NO_TRIGGER') {
        return (
          <StyledIconButton
            variant="outlined"
            color="secondary"
            dataTestId="remindButton"
            icon="alarm"
            onClick={openRemindParticipantsModal}
            size={ButtonSize.Medium}
          />
        );
      }
    };
    const renderFlowOwnerActionItems = () => {
      return (
        <Flex>
          {renderFlowTriggerButtons()}
          <StyledIconButton
            variant="outlined"
            color="primary"
            dataTestId="shareFlowButton"
            icon="share"
            onClick={handleOpenShareSheetModal}
            size={ButtonSize.Medium}
          />
        </Flex>
      );
    };
    return (
      <Flex>
        {menuOptions && title !== defaultFlow.name && (
          <StyledDropdownIconButton
            iconColor={ThemeV2.palette.gray8}
            dropdownPlacement={DropdownPlacement.BottomEnd}
            icon="more"
            menuItems={menuOptions}
            onItemClick={onMenuOptionClick}
            title={MORE_OPTIONS}
            dataTestId="flowFeedHeaderDropdownOptions"
            onClick={onClick}
            size={ButtonSize.Medium}
          />
        )}
        {isFlowEditor && renderFlowOwnerActionItems()}
      </Flex>
    );
  };

  const pageTitle = `${icon} ${title}`;

  const openGraphTitle = `${pageTitle} | ${getWorkspaceTitlePrefix(
    profileData?.assembly.name,
  )}`;

  const paddingLeft =
    isEmbeddedInMainApp && !location.pathname.includes('/fullscreen')
      ? '0px'
      : '16px';

  return (
    <Root
      isMobileView={isMobileView}
      borderBottom={!isFlowEditor}
      isFullScreen={isFullScreen}
      isRightAsideOpen={isRightAsideOpen}
      paddingLeft={paddingLeft}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={openGraphTitle} />
        {description && (
          <meta property="og:description" content={description} />
        )}
      </Helmet>
      <EmoticonHolder
        emoticon={icon}
        size={EmoticonHolderSizes.Medium2}
        color={backgroundColor}
      />
      <StyledFlowsFeedHeaderMainContent>
        <FeedHeaderMainContentInnerWrapper>
          <StyledHeading
            variant="h6"
            isMobileApp={isMobileApp}
            isRightAsideOpen={isRightAsideOpen}
          >
            {title}
          </StyledHeading>
          {menuOptions && !isMobileView && (
            <StyledDropdownIconButton
              iconColor={ThemeV2.palette.gray8}
              dropdownPlacement={DropdownPlacement.Bottom}
              icon="more"
              size={ButtonSize.Small}
              menuItems={menuOptions}
              onItemClick={onMenuOptionClick}
              title={MORE_OPTIONS}
              dataTestId="flowFeedHeaderDropdownOptions"
              onClick={onClick}
            />
          )}
        </FeedHeaderMainContentInnerWrapper>
        {!isMobileView && (
          <FlowsDetailsWrapper>
            {description ? (
              <FlowDescriptionPopover
                referenceContent={
                  <StyledBody variant="body2" color="gray8" inline>
                    {description}
                  </StyledBody>
                }
                popoverContent={
                  <PopoverBody variant="body2" color="gray8" inline>
                    {description}
                  </PopoverBody>
                }
              />
            ) : (
              <FlowDescriptionPopover
                referenceContent={
                  <StyledBody variant="body2" color="gray7" inline>
                    {NO_DESCRIPTION}
                  </StyledBody>
                }
                popoverContent={
                  <PopoverBody variant="body2" color="gray7" inline>
                    {NO_DESCRIPTION}
                  </PopoverBody>
                }
              />
            )}
          </FlowsDetailsWrapper>
        )}
      </StyledFlowsFeedHeaderMainContent>
      {isMobileView ? renderMobileActionItem() : renderActionItem()}
    </Root>
  );
};

export default FlowsFeedHeader;
