import React, { useEffect, useState } from 'react';
import CsvViewer from './csvViewer';

const parseXlsx = (
  data: Iterable<number>,
  setXlsxView: {
    (value: React.SetStateAction<undefined>): void;
    (arg0: { sheets: string[]; names: string[] }): void;
  },
) => {
  import('xlsx').then((XLSX) => {
    const dataArr = new Uint8Array(data);
    const arr: any[] = [];

    for (let i = 0; i !== dataArr.length; i += 1) {
      arr.push(String.fromCharCode(dataArr[i]));
    }

    const workbook = XLSX.read(arr.join(''), { type: 'binary' });
    const names = Object.keys(workbook.Sheets);
    const sheets = names.map((name) =>
      XLSX.utils.sheet_to_csv(workbook.Sheets[name]),
    );

    setXlsxView({ sheets, names });
  });
};

const renderSheetNames = (names: string[], setCurSheetIndex: any) => {
  const sheets = names.map((name, index) => (
    <input
      key={name}
      style={{ float: 'left', marginLeft: index === 0 ? '32px' : '0px' }}
      type="button"
      value={name}
      onClick={() => {
        setCurSheetIndex(index);
      }}
    />
  ));

  return <div className="sheet-names">{sheets}</div>;
};

const renderSheetData = (sheet: string, props: { data: string }) => {
  const csvProps = Object.assign({}, props, { data: sheet });
  return <CsvViewer {...csvProps} />;
};

const XlsxViewer = (props: { data: string }) => {
  const { data } = props;
  /* @ts-ignore-next-line */
  const [xlsxView, setXlsxView] = useState();
  const [curSheetIndex, setCurSheetIndex] = useState(0);

  useEffect(() => {
    /* @ts-ignore-next-line */
    setXlsxView(parseXlsx(data, setXlsxView));
  }, [data]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* @ts-ignore-next-line */}
      {xlsxView && Boolean(xlsxView.sheets) && Boolean(xlsxView.names) && (
        <>
          {/* @ts-ignore-next-line */}
          <div>{renderSheetNames(xlsxView.names, setCurSheetIndex)}</div>
          <div className="spreadsheet-viewer">
            {/* @ts-ignore-next-line */}
            {renderSheetData(xlsxView.sheets[curSheetIndex || 0], props)}
          </div>
        </>
      )}
    </div>
  );
};

export default XlsxViewer;
