import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import LoadingPulse from '../atoms/LoadingPulse';
import { useSignOut } from '../../hooks/useSignOut';
import Body from '../atoms/Body';
import { authStore } from '../../stores/authStore';

const Signout = () => {
  const { handleUserSignOut } = useSignOut();

  useEffect(() => {
    if (authStore.getState().authenticated) handleUserSignOut();
  }, [handleUserSignOut]);

  return (
    <Grid container justifyContent="center" style={{ marginTop: 90 }}>
      <Body variant="body2Medium">Signing out</Body>
      <LoadingPulse isLoading />
    </Grid>
  );
};

export default Signout;
