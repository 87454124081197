import styled from 'styled-components';

import Body from '../../atoms/Body';
import IconButton from '../../atoms/IconButton_V2';
import { Flex } from '../../../Utils/styles/display';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import Image from '../../atoms/Image';
import feedbackSurveyImage from '../../../img/feedbackSurveyImage.png';
import Link from '../../atoms/Link_V2';
import { ASSEMBLY_V3_FEEDBACK_SURVEY_URL } from '../../../config';

type FeedbackSurveyModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
};

const CloseIconButton = styled(IconButton)`
  margin-bottom: 24px;
  right: 16px;
  height: 32px;
`;

const Wrapper = styled(BasicModalTemplate)`
  max-width: 512px;
`;

const Title = styled(Body)`
  max-width: 432px;
`;

const GiveFeedbackButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 12px;
  gap: 10px;
  width: 464px;
  height: 40px;
  background: #2f54eb;
  border-radius: 8px;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

const FeedbackSurveyModal = (props: FeedbackSurveyModalProps) => {
  const { isOpen, onModalClose } = props;
  return (
    <Wrapper
      hideCloseIcon
      hasTitleSection={false}
      isOpen={isOpen}
      onModalClose={onModalClose}
      childrenPadding="24px"
    >
      <Flex flexDirection="column" gap="12px" justifyContent="flex-start">
        <Flex>
          <Title variant="body1Medium">
            Thanks for trying our new experience! Could you give us some
            feedback?
          </Title>
          <CloseIconButton
            dataTestId="close-icon-feedback-survey"
            icon="close-rounded"
            onClick={onModalClose}
            size={ButtonSize.Small}
          />
        </Flex>
        <Image src={feedbackSurveyImage} alt="feedbackSurveyImage" />
        <GiveFeedbackButton
          color="#FFFFFF"
          isAnchor
          openInNewTab
          onClick={onModalClose}
          to={ASSEMBLY_V3_FEEDBACK_SURVEY_URL.toString()}
        >
          Give feedback
        </GiveFeedbackButton>
      </Flex>
    </Wrapper>
  );
};

export default FeedbackSurveyModal;
