import styled from 'styled-components';
import Theme from '../../../theme';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';

const Container = styled.div`
  display: flex;
  padding-left: 30px;
  cursor: pointer;
`;

const StyledIcon = styled(SVGIcon)`
  margin-top: 4px;
`;

export function LinkToUnreadsSection() {
  return (
    <Container
      onClick={() => {
        document.querySelector('#left-nav-drawer')?.scrollTo({
          top: 250,
          behavior: 'smooth',
        });
      }}
    >
      <Body color="geekBlue6">Check your unreads</Body>
      <StyledIcon icon="arrow-up" size="16px" color={Theme.palette.geekBlue6} />
    </Container>
  );
}
