import { useMemo } from 'react';

import { getAnswerFlowRoute } from '../../../Utils/flows';
import useFlowsListAccordion from '../../../hooks/useFlowsListAccordion';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { useMainNavQuery } from '../../../queries/Flows/Dashboard';
import {
  mapFlowsResponseToNavFlowItem,
  sortLeftDrawerNavFlowItemsByLabel,
} from '../utils';

const useAnswerNowFlowsController = () => {
  const history = useHistoryWrapper();

  const {
    handleAccordionClick,
    handleMenuItemClick: onMenuItemClick,
    isOpen,
  } = useFlowsListAccordion();

  const { data, isSuccess } = useMainNavQuery();

  const flows = data?.flattenedData;

  const sortedFlowItems = useMemo(() => {
    if (!flows) {
      return [];
    }

    return flows
      .filter(
        (flow) =>
          !!(
            flow.occurrence?.activeOccurrence &&
            flow.isFlowParticipant &&
            !flow.occurrence?.activeOccurrence.hasResponded
          ),
      )
      .map(mapFlowsResponseToNavFlowItem)
      .filter((item) => !item.isMuted)
      .sort(sortLeftDrawerNavFlowItemsByLabel);
  }, [flows]);

  const handleMenuItemClick = (id: string) => {
    onMenuItemClick();
    const clickedFlow = sortedFlowItems.find((flowItem) => flowItem.id === id);
    const occurrenceId =
      clickedFlow?.occurrence?.activeOccurrence?.occurrenceId;
    const answerNowLink = getAnswerFlowRoute(
      id,
      occurrenceId,
      location.pathname,
    );
    history.push(answerNowLink);
  };

  return {
    models: {
      isOpen,
      menuItems: sortedFlowItems,
      isSuccess,
    },
    operations: {
      handleAccordionClick,
      handleMenuItemClick,
    },
  };
};

export default useAnswerNowFlowsController;
