import Body from '../../../atomic/atoms/Body';

import { Flex } from '../../../Utils/styles/display';
import ReactCanvasConfetti from 'react-canvas-confetti';
import AssemblyLogo from '../../../atomic/atoms/SVGIcon/icons/assemblyLogo.svg';
import assemblyLogo from '../../../img/svgs/assembly-logo-mono.svg';
import {
  Wrapper,
  Header,
  LogoContainer,
  StyledLogo,
  CustomHeader,
  Content,
  Footer,
  AssemblyImage,
  StyledFlex,
  StyledHeading,
} from './styles';
import { useExternalFlowsCompletionController } from './useExternalFlowsCompletionController';
import { EXTERNAL_FLOWS_COMPLETION } from '../../../languages/en/flows';
import { SIGN_IN_BUTTON_TEXT } from '../../../languages/en/signIn';
import { NEW_APP_URL } from '../../../config';

const ExternalFlowsCompletionController = () => {
  const { getInstance, creator } = useExternalFlowsCompletionController();

  return (
    <Wrapper>
      <ReactCanvasConfetti
        refConfetti={getInstance}
        style={{
          position: 'fixed',
          pointerEvents: 'none',
          height: '100vh',
          width: '100vw',
          top: 0,
        }}
      />
      <Header>
        <Flex>
          <LogoContainer
            alignItems="center"
            justifyContent="center"
            height="40px"
            width="40px"
          >
            <StyledLogo src={creator?.logo || AssemblyLogo} alt="Express" />
          </LogoContainer>
          <CustomHeader>{creator?.name || ''}</CustomHeader>
        </Flex>
      </Header>
      <Content>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <AssemblyImage alt="assemblyLogo" src={assemblyLogo} />
          <StyledFlex flexDirection="column">
            <StyledHeading variant="h3" textAlign="center">
              {EXTERNAL_FLOWS_COMPLETION.HEADING}
            </StyledHeading>
          </StyledFlex>
        </Flex>
      </Content>
      <Footer>
        <Flex alignItems="center" justifyContent="center">
          <Body variant="body1">
            {EXTERNAL_FLOWS_COMPLETION.ALREADY_MEMBER}{' '}
            <a href={`${NEW_APP_URL}/login`}>{SIGN_IN_BUTTON_TEXT}</a>
          </Body>
        </Flex>
      </Footer>
    </Wrapper>
  );
};

export default ExternalFlowsCompletionController;
