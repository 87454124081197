import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { mapFeedPostToFeedBodyProps } from '../../../../Utils/home/feeds/post';
import { BlockProps } from '../../../../atomic/organism/FlowPostBody/types';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { useParticipationFlowMessageEvent } from '../../../../hooks/useParticipationFlowMessageEvent';
import { AppAction } from '../../../../interfaces/PostMessage';
import { useGetSinglePostQuery } from '../../../../queries/Feed';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { transformMemberToAutoCompleteDropdownItem } from '../../../home/ProfileFeedController/utils';
import useLayoutStore from '../../../../stores/layoutStore';

const useRecognitionParticipationController = () => {
  const history = useHistory();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const userIdQueryParam = params.get('userId') || '';
  const userIds = userIdQueryParam.split(',');
  const redirectUrl = params.get('redirectUrl') || '/';
  const redirectLocation = params.get('redirectLocation');
  const { postId } = useParams<{ postId: string }>();
  const { isEmbeddedInMainApp } = useLayoutStore();

  useParticipationFlowMessageEvent();

  const postCloseParticipationModalMessage = (url: string) => {
    window.parent.postMessage(
      { type: 'CLOSE_PARTICIPATION_MODAL', payload: url },
      '*',
    );
  };

  const { data: profileData, isInitialLoading: isLoadingProfileData } =
    useProfileInfoFetchQuery();
  const { isInitialLoading: isSinglePostLoading, data: postData } =
    useGetSinglePostQuery(postId, !!postId);
  const {
    data: selectedMemberDetails,
    status: memberDetailsLoadingStatus,
    fetchStatus: memberDetailsFetchStatus,
  } = useFetchMembersDetailsQuery(userIds, Boolean(userIdQueryParam));
  const isLoadingMemberDetails =
    memberDetailsLoadingStatus === 'loading' &&
    memberDetailsFetchStatus === 'fetching';
  const isLoading =
    (postId
      ? isLoadingProfileData || isSinglePostLoading
      : isLoadingProfileData) || isLoadingMemberDetails;

  const postBlockProps: BlockProps[] | [] =
    postData && profileData
      ? mapFeedPostToFeedBodyProps(postData, profileData, () => {})
      : [];

  const handleCloseParticipationModal = useCallback(() => {
    if (redirectLocation === 'mobile') {
      postMessageToMobileApp({
        action: AppAction.DraftActionPerformed,
        payload: {
          type: 'closeDraftModalAction',
        },
      });
      return;
    }
    if (
      redirectUrl?.startsWith('http://') ||
      redirectUrl?.startsWith('https://')
    ) {
      if (isEmbeddedInMainApp) {
        postCloseParticipationModalMessage(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      history.push(redirectUrl || '/');
    }
  }, [history, redirectLocation, redirectUrl, isEmbeddedInMainApp]);

  const loadingFlowHeaderContent = {
    TitleContent: '',
    DescriptionContent: '',
  };

  const profileDataForRecognitionModalDropdown = useMemo(() => {
    const isOwnProfileFeed =
      profileData?.member.memberId &&
      userIds.includes(profileData?.member.memberId);
    if (isOwnProfileFeed || !selectedMemberDetails) {
      return undefined;
    }

    return selectedMemberDetails.membersDetails.map((x) =>
      transformMemberToAutoCompleteDropdownItem(x),
    );
  }, [profileData?.member.memberId, userIds, selectedMemberDetails]);

  return {
    profileData,
    isLoading,
    postBlockProps,
    loadingFlowHeaderContent,
    handleCloseParticipationModal,
    profileDataForRecognitionModalDropdown,
    ...(!!postId && { postData }),
  };
};

export default useRecognitionParticipationController;
