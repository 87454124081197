import React from 'react';

import styled from 'styled-components';
import FeedFilterSearch from '../../../atomic/organism/FeedFilterSearch';
import SearchDropdown from '../../../atomic/organism/SearchDropdown';
import LoadingPulse from '../../../atomic/atoms/LoadingPulse';
import useFeedSearchBarLogic from './useFeedSearchBarLogic';
import { FeedSearchBarControllerProps } from './interface';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px auto 0;
`;

const FeedSearchBarController: React.FC<FeedSearchBarControllerProps> = ({
  searchValue,
  onSearchChange,
  placeholder,
  hideUserFieldSearch = false,
}) => {
  const {
    models: {
      isDropdownOpen,
      feedSearchPlaceholder,
      isFeedSearchDisabled,
      currencyNamePlural,
      feedSearchUpgradeButton,
      autoCompleteEmptyText,
      membersOptions,
      displayFormValues,
      clearButtonVisible,
      isGetActivePlansLoading,
      isMembersSearchLoading,
      showPremiumMessage,
    },
    operations: {
      onAutoCompleteInputChange,
      onAutoCompleteFocus,
      onSearchClick,
      onDisplayCloseClick,
      toggleDropDown,
    },
  } = useFeedSearchBarLogic({ searchValue, onSearchChange });

  if (isGetActivePlansLoading) {
    return <LoadingPulse />;
  }

  return (
    <StyledWrapper>
      <FeedFilterSearch
        formValues={displayFormValues}
        placeholderText={placeholder || feedSearchPlaceholder}
        disabled={isFeedSearchDisabled}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={toggleDropDown}
        upgradeButton={feedSearchUpgradeButton}
        showPremiumMessage={showPremiumMessage}
        currencyNamePlural={currencyNamePlural}
        onClearClick={onDisplayCloseClick}
        clearButtonVisible={clearButtonVisible}
      >
        <SearchDropdown
          formValues={searchValue}
          onAutoCompleteInputChange={onAutoCompleteInputChange}
          onSearchClick={onSearchClick}
          onCancelClick={toggleDropDown}
          autoCompleteOptions={membersOptions}
          keywordsOptions={[]}
          isAutoCompleteLoading={isMembersSearchLoading}
          onAutoCompleteFocus={onAutoCompleteFocus}
          autoCompleteEmptyText={autoCompleteEmptyText}
          currencyNamePlural={currencyNamePlural}
          hideUserFieldSearch={hideUserFieldSearch}
        />
      </FeedFilterSearch>
    </StyledWrapper>
  );
};

export default FeedSearchBarController;
