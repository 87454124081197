import { BaseEmoji, EmojiData } from 'emoji-mart';
import { ChangeEvent, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { FLOW_BUILDER_EVENTS } from '../../../../Utils/analytics/constants';
import { trackFlowBuilderActionEvent } from '../../../../Utils/analytics/flowsBuilder';
import { isDesktopPlatform } from '../../../../Utils/window';
import { useFlowBuilderValidation } from '../../../../hooks/useFlowBuilderValidation';
import useSaveFlow from '../../../../hooks/useSaveFlow';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  templateIdSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';
import useFlowBuilderEdit from '../../FlowsBuilderController/useFlowBuilderEdit';

const useHeaderController = (folderId: string | null) => {
  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const storeData = useFlowBuilderStore();
  const { flowId } = useParams<{ flowId: string }>();
  const { resetFlowDetails, flowIdToDuplicate, isScheduledFlowEnded } =
    useFlowBuilderEdit();
  const { data } = useFetchFlowDetailsQuery(
    flowId || flowIdToDuplicate,
    'builder',
  );
  const {
    emoji,
    setEmoji,
    flowName,
    setFlowName,
    isInEditMode,
    setIsInEditMode,
    setIsInviteNewTeammatesModalOpen,
  } = storeData;

  const openInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(true);
  };

  const closeInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(false);
  };

  const { saveFlow, isSavingFlow } = useSaveFlow({
    openInviteNewTeammatesModal,
    closeInviteNewTeammatesModal,
    folderId,
  });

  const { isValid } = useFlowBuilderValidation();

  const handleRevertChangesClick = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_EDIT_REVERTED,
      templateId,
      templateName,
      flowTitle: flowName,
    });
    resetFlowDetails();
  }, [flowName, resetFlowDetails, templateId, templateName]);

  const handleEmojiChange = useCallback(
    (emojiData: EmojiData) => {
      setEmoji(emojiData as BaseEmoji);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_EMOJI_ADDED,
        flowEmoji: emojiData?.name,
        templateId,
        templateName,
        flowTitle: flowName,
      });
    },
    [flowName, setEmoji, templateId, templateName],
  );

  const handleTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFlowName(event.target.value);
    },
    [setFlowName],
  );

  const handleTitleTextBlur = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_TITLE_ADDED,
      flowTitle: flowName,
      templateId,
      templateName,
    });
  }, [flowName, templateId, templateName]);

  const handleEditButtonClick = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_EDIT_CLICKED,
      templateId,
      templateName,
      flowTitle: flowName,
    });
    setIsInEditMode(true);
  }, [flowName, setIsInEditMode, templateId, templateName]);

  return {
    models: {
      emoji,
      flowId,
      isInEditMode,
      titleValue: flowName,
      isPreviewButtonEnabled: isValid,
      isEditFlowButtonDisabled: !isValid || !isDesktopPlatform,
      hasActiveOccurrence: Boolean(data?.data.hasActiveOccurrence),
      isScheduledFlowEnded,
      isSavingFlow,
    },
    operations: {
      handleRevertChangesClick,
      previewButtonClicked: saveFlow,
      onEmojiChange: handleEmojiChange,
      onTitleTextBlur: handleTitleTextBlur,
      onTitleTextChange: handleTitleChange,
      onEditButtonClick: handleEditButtonClick,
    },
  };
};

export default useHeaderController;
