import { arrayMoveImmutable } from 'array-move';
import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DELETE_FOLDER,
  GET_MAIN_NAV_CONTENTS,
  UPDATE_FOLDER_FOR_FLOW,
  UPSERT_FOLDER,
} from '../constants/endpoints';
import { FlowResponse, MainNavQueryAPIResponse } from './Flows/interfaces';
import { makeAPICall } from './utils';

export function useFolderUpsertMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (body: {
      name?: string;
      color?: string;
      folderId?: string;
      beforeId?: string;
      afterId?: string;
    }) => makeAPICall(UPSERT_FOLDER, body),
    {
      onError() {
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
      },
      onSuccess() {
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
      },
    },
  );
}

export function useFolderRearrangementMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (body: {
      name?: string;
      color?: string;
      folderId?: string;
      beforeId?: string;
      afterId?: string;
    }) => makeAPICall(UPSERT_FOLDER, body),
    {
      onError() {
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
      },
      onMutate: async (body) => {
        const queryKey = [GET_MAIN_NAV_CONTENTS, ''];
        await queryClient.cancelQueries(queryKey);
        const queriesData = queryClient.getQueryData(queryKey) as AxiosResponse;

        const { folderId, name, color, beforeId, afterId } = body;

        // we don't need optimistic UI updates for anything other than folder reordering
        if (!queriesData || name || color || !(beforeId || afterId)) {
          return;
        }

        const mainQueryResponseData = JSON.parse(
          JSON.stringify(queriesData?.data.data as MainNavQueryAPIResponse),
        ) as MainNavQueryAPIResponse['data'];

        if (!mainQueryResponseData) {
          return;
        }

        const updatedFolders = arrayMoveImmutable(
          mainQueryResponseData,
          mainQueryResponseData.findIndex(
            (group) => group.folder.folderId === folderId,
          ),
          afterId
            ? mainQueryResponseData.findIndex(
                (group) => group.folder.folderId === afterId,
              )
            : mainQueryResponseData.findIndex(
                (group) => group.folder.folderId === beforeId,
              ),
        );

        queryClient.setQueryData(queryKey, {
          data: {
            data: updatedFolders,
          },
        });
      },
    },
  );
}

export function useFolderDeletionMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    (folderId: string) =>
      makeAPICall(DELETE_FOLDER, undefined, undefined, { folderId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
      },
    },
  );
}

export function useUpdateFlowInFolder() {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      flowId,
      sourceFolderId,
      destinationFolderId,
    }: {
      flowId: string;
      sourceFolderId: string | null;
      destinationFolderId: string | null;
    }) =>
      makeAPICall(UPDATE_FOLDER_FOR_FLOW, {
        flowId,
        sourceFolderId,
        destinationFolderId,
      }),
    {
      onMutate: async (body) => {
        const queryKey = [GET_MAIN_NAV_CONTENTS, ''];
        await queryClient.cancelQueries(queryKey);

        const queriesData = queryClient.getQueryData(queryKey) as AxiosResponse;

        const { sourceFolderId, flowId, destinationFolderId } = body;

        const mainQueryResponseData = JSON.parse(
          JSON.stringify(queriesData?.data.data as MainNavQueryAPIResponse),
        ) as MainNavQueryAPIResponse['data'];

        if (!mainQueryResponseData) {
          return;
        }

        const flowsSource = mainQueryResponseData.find(
          (i) => i.folder?.folderId === sourceFolderId,
        )?.flows;

        if (!flowsSource) {
          return;
        }

        const positionOfMovedFlow = flowsSource.findIndex(
          (i) => i.flowId === flowId,
        );

        let flowToMove: FlowResponse | undefined;
        if (flowsSource) {
          [flowToMove] = flowsSource.splice(positionOfMovedFlow, 1);
        }

        const destinationFolderIndex = destinationFolderId
          ? mainQueryResponseData.findIndex(
              (i) => i.folder.folderId === destinationFolderId,
            )
          : mainQueryResponseData.length - 1;

        if (flowToMove) {
          mainQueryResponseData[destinationFolderIndex].flows.push(flowToMove);
        }

        queryClient.setQueryData(queryKey, {
          data: {
            data: mainQueryResponseData,
          },
        });
      },
    },
  );
}
