import { trackEvent } from '../index';
import { PostEventProps } from '../interfaces';
import { AnalyticsEvents, POST_EVENT } from '../constants';

export const trackPostActionEvent = (eventProps: PostEventProps) => {
  trackEvent(POST_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};
