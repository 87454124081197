import styled from 'styled-components';

import Image from '../../atoms/Image';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';

export const StyledImage = styled(Image)`
  margin: 10% auto 44px auto;
`;

export const TextWrapper = styled.div`
  max-width: 378px;
  text-align: center;
  margin: auto;
  > div:first-child {
    margin-bottom: 4px;
  }
`;

export const StyledButton = styled(Button)<{ $marginRight?: string }>`
  margin-top: 24px;
  margin-right: ${({ $marginRight }) => $marginRight && $marginRight};
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 4px 0;
`;
