import { useInfiniteQuery } from '@tanstack/react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { GET_MAIN_FEED_ATTACHMENTS } from '../../constants/endpoints';

import {
  GetFeedAttachmentResponse,
  PostMainFeedAttachmentsPayload,
} from '../../interfaces/Feed';

export const useGetMainFeedAttachmentsQuery = ({
  filter,
  sortBy,
  enabled = true,
}: PostMainFeedAttachmentsPayload) => {
  return useInfiniteQuery<GetFeedAttachmentResponse>(
    [GET_MAIN_FEED_ATTACHMENTS, filter, sortBy],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_MAIN_FEED_ATTACHMENTS, {
        cursor: pageParam,
        limit: 20,
        filter,
        sortBy,
      }),
    {
      enabled: enabled,
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};
