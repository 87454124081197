import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import Image from '../../atoms/Image';
import SVGIcon from '../../atoms/SVGIcon';

export const Wrapper = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 252px;
  overflow: hidden;
  border-radius: 4px;
  z-index: 0;
`;

export const GifBackground = styled.div<{ src: string }>`
  background: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  -webkit-filter: blur(23px); /* Safari 6.0 - 9.0 */
  filter: blur(23px);
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-size: cover;
`;

export const ImageWrapper = styled(Image)`
  width: auto;
  margin: 0 auto;
  height: 100%;
  object-fit: contain;
`;

export const SvgWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  height: 24px;
  width: 24px;
  z-index: 0;
  cursor: pointer;
`;

export interface GifContainerProps {
  src: string;
  onGifRemoveClick?: () => void;
  className?: string;
  lazyload?: boolean;
}

const GifContainer = (props: GifContainerProps) => {
  const { src, onGifRemoveClick, className, lazyload } = props;
  const { ref, inView } = useInView({
    triggerOnce: true,
    skip: !lazyload,
  });

  const calculatedSrc = useMemo(() => {
    if (lazyload) {
      return inView ? src : '';
    }
    return src;
  }, [src, inView, lazyload]);

  return (
    <Wrapper className={className} ref={ref}>
      <GifBackground src={calculatedSrc} data-testid="gif background" />
      {onGifRemoveClick && (
        <SvgWrapper
          onClick={onGifRemoveClick}
          data-testid="remove gif"
          data-qa-id="remove-gif"
        >
          <SVGIcon icon="close-circle-outlined" size="24px" />
        </SvgWrapper>
      )}
      <ImageWrapper src={calculatedSrc} alt="media" placeholder />
    </Wrapper>
  );
};

export default GifContainer;
