import { MemberState } from '../../../interfaces/user';

export type ProfileChatHeaderProps = {
  profile: {
    id: string;
    image: string;
    lastName: string;
    firstName: string;
    state: MemberState | undefined;
  } | null;
  isLoading: boolean;
  isMobileView: boolean;
  onCloseClick: () => void;
  hideCloseButton?: boolean;
  onViewProfileClick: () => void;
  onCopyProfileLinkClick: () => void;
};

export enum ProfileDropDownMenuItem {
  ViewProfile = 'View Profile',
  CopyProfileLink = 'Copy Profile Link',
}
