import styled, { css } from 'styled-components';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import IconButton from '../../atoms/IconButton_V2';
import Modal from '../../atoms/Modal';

export const ModalWrapper = styled(Modal)`
  width: 85vw;
  height: 85vh;
  min-height: 85vh;
  max-height: 85vh;
  border-width: 0px;
  border-radius: 2px;
  background-color: ${ThemeV2.palette.gray3};
  @media (max-width: 580px) {
    max-height: 100%;
    height: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
`;

export const ModalBackground = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 85vh;
  max-height: 85vh;
  background-color: ${ThemeV2.palette.gray3};
  @media (max-width: 580px) {
    max-height: 100%;
    height: 100%;
    overflow: auto;
  }
`;

export const ModalHeading = styled(Flex)`
  height: 72px;
  min-height: 72px;
  width: 100%;
  margin: 0px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  padding: 16px;
  background-color: ${ThemeV2.palette.gray1};
`;

export const ModalBody = styled.div<{
  fullHeight: boolean;
}>`
  display: flex;
  text-align: center;
  overflow: scroll;
  height: ${({ fullHeight }) =>
    fullHeight ? 'calc(85vh - 144px)' : 'calc(85vh - 72px)'};
  @media (max-width: 580px) {
    max-height: 100%;
    height: 100%;
  }
`;

export const SliderBox = styled.div`
  position: absolute;
  display: flex;
  height: 32px;
  width: 236px;
  bottom: 32px;
  padding: 4px;
  left: calc(50% - 118px);
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const SliderWrapper = styled.div`
  height: 24px;
  width: 124px;
  margin: -2px 12px 0px 12px;
  .MuiSlider-colorPrimary {
    color: ${ThemeV2.palette.gray8};
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const SubmitButton = styled(Button)`
  margin-left: 12px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-grow: 1;
`;

export const FileDescription = styled.div<{ hasMessage: boolean }>`
  height: ${({ hasMessage }) => (hasMessage ? '72px' : '0')};
  min-height: ${({ hasMessage }) => (hasMessage ? '72px' : '0')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  box-shadow: none;
  border: none;
  top: 18px;
  right: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: ${ThemeV2.palette.gray2};
  font-family: ${ThemeV2.typography.fontFamily};
  font-weight: ${ThemeV2.typography.fontWeightMedium};
  font-size: ${ThemeV2.typography.fontSize};
`;

export const StyledAvatar = styled(Avatar)`
  margin-right: 8px;
`;

export const StyledBodyHeaderTitle = styled(Body)`
  margin-right: 4px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const StyledSubheading = styled(Body)`
  display: none;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${ThemeV2.breakPoints.md}) {
    display: initial;
  }
`;

export const StyledIconButton = styled(IconButton)<{
  height: number;
  margin?: string;
}>`
  top: 16px;
  right: 16px;
  height: ${({ height }) => height}px;
  margin ${({ margin }) => margin};
`;

export const CloseIcon = styled.span`
  display: block;
  margin-bottom: 5px;
`;

export const VerticalBar = styled.div<{ height: number; sideMargin: number }>`
  border-left: 1px solid ${ThemeV2.palette.gray5};
  height: ${({ height }) => height}px;
  margin: 0px ${({ sideMargin }) => sideMargin}px;
  vertical-align: middle;
`;

export const PageSelector = styled.div`
  min-width: 200px;
  width: 200px;
  height: 100%;
  border-right: 1px solid ${ThemeV2.palette.gray5};
  @media (max-width: 580px) {
    display: none;
  }
`;

export const PageBreak = styled.div`
  width: 100%;
  height: 22px;
  margin-top: 4px;
`;

export const SideWrapper = styled.div`
  .wrappedCanvas {
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      border: 1px solid ${ThemeV2.palette.geekBlue6};
    }
  }
`;

export const NavigationButton = styled(IconButton)<{
  direction: 'left' | 'right';
}>`
  position: absolute;
  height: 40px;
  width: 40px;
  top: 50%;

  background: ${ThemeV2.palette.gray1};
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 8px;

  ${({ direction }) =>
    direction === 'left'
      ? css`
          left: 24px;
        `
      : css`
          right: 24px;
        `};

  transition: all 200ms ease-in-out;

  &:hover,
  &:focus {
    transform: scale(1.015);

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background: ${ThemeV2.palette.gray1};

    &:not(:disabled):hover span svg path,
    &:not(:disabled):focus-visible span svg path {
      fill: ${ThemeV2.palette.gray8};
    }
  }
`;

export const StickyFooter = styled.div`
  bottom: 0px;
  position: sticky;
`;

export const FooterContainer = styled.div`
  background-color: ${ThemeV2.palette.gray3};
  border-top: 1px solid ${ThemeV2.palette.gray5};
  padding: 10px 22px;
  text-align: center;
`;

export const DownloadButton = styled(Button)`
  margin-top: 12px;
`;
