import { AxiosError } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetExternalFlowsQuery } from '../../../../queries/Flows/ExternalFlows';
import { useVerifyFlowAuthorization } from '../../../../queries/Flows/Feed';

const useExternalFlowsController = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const [validationError, setValidationError] = useState<
    { code: number; message: string } | undefined
  >(undefined);

  const handleValidationErrors = (error: unknown) => {
    const axiosError = error as AxiosError<{ message: string }>;
    const errorMessage = axiosError?.response?.data?.message;
    const statusCode = axiosError?.response?.status;

    setValidationError(
      !isEmpty(errorMessage) && statusCode
        ? {
            code: statusCode,
            message: errorMessage || '',
          }
        : undefined,
    );
  };

  const {
    data: flowAuthorizationData,
    isError: isFlowAuthorizationError,
    isInitialLoading: isFlowAuthorizationLoading,
  } = useVerifyFlowAuthorization(flowId, true, handleValidationErrors);

  const { data: externalFlowData } = useGetExternalFlowsQuery(
    flowId,
    Boolean(!isFlowAuthorizationLoading && flowAuthorizationData),
  );

  return {
    flowId,
    isFlowAuthorizationError,
    externalFlowData,
    validationError,
  };
};

export default useExternalFlowsController;
