import styled from 'styled-components';
import React, { useCallback, useMemo } from 'react';

import FlowFeedPost from '../../../atomic/organism/FlowFeedPost';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import useFlowPostController from './useFlowResponseController';
import FlowsPostHeaderOption from '../../../atomic/organism/FlowsPostHeaderOption';
import { getFeedPostHeaderMenuOptions } from '../../../atomic/organism/FlowFeedPost/data';
import {
  Announcement,
  FlowFeedResponse,
} from '../../../queries/Flows/Feed/interfaces';
import { FeedItemKind } from '../../../interfaces/Feed';
import useRepliesBar from '../../../hooks/useRepliesBar';
import useLayoutStore from '../../../stores/layoutStore';

type FlowPostControllerProps = {
  className?: string;
  response: FlowFeedResponse;
  profileData?: GetProfileInfoResponse;
  showEditOption: boolean;
  onPostDelete?: () => void;
  handleEditPostClick?: (flow: { postId: string }) => void;
  hideInteractions?: boolean;
  isExpandedOnLoad?: boolean;
  hideRepliesBar?: boolean;
  hideBorder?: boolean;
  hideReactions?: boolean;
  isSamplePost?: boolean;
  variant?: 'feed' | 'sidebar';
  showPostInteractionSettings?: boolean;
  activeAnnouncement?: Announcement;
  canEndAnnouncement: boolean;
  canEditAnnouncement: boolean;
  canShareAsAnnouncement: boolean;
  canViewAnnouncementInsights: boolean;
};

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const FlowResponseController = (props: FlowPostControllerProps) => {
  const {
    profileData,
    handleEditPostClick,
    hideInteractions,
    isExpandedOnLoad,
    hideBorder,
    showEditOption = false,
    hideRepliesBar,
    hideReactions,
    variant,
    isSamplePost,
    className,
    showPostInteractionSettings,
    activeAnnouncement,
    canEndAnnouncement,
    canEditAnnouncement,
    canShareAsAnnouncement,
    canViewAnnouncementInsights,
  } = props;

  const {
    hasTrophies,
    showDeleteOption,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    isConvertedPdfPreviewsEnabled,
    handleSetFilePreview,
    handleDeletePostOptionChange,
    onCopyLinkClick,
    onFlowClick,
    handleMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    handleReactionSet,
    handleReactionUnSet,
    onDeleteModalSubmitClick,
    feedProps,
    onGotoPostLinkClick,
    notificationPreference,
    onNotificationPreferenceClick,
    currency,
    handlePostInteractionSettingsClick,
    handleViewAnnouncementInsightsClick,
    handleDeletePostClick,
  } = useFlowPostController(props);

  const { repliesBarProps, isInFlowDetailsPage } = useRepliesBar({
    postId: props.response.responseId,
    type: 'RESPONSE',
    enabled: props.response.commentsCount > 0,
  });

  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;

  const handleOnCreateAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'CREATE_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.response?.responseId,
          activeAnnouncement: props.response?.activeAnnouncement,
          hideReactions: props.response.hideReactions,
          hideReplies: props.response.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.response?.activeAnnouncement,
    props.response.hideReactions,
    props.response.hideReplies,
    props.response?.responseId,
  ]);

  const handleOnEndAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'END_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.response?.responseId,
          activeAnnouncement: props.response?.activeAnnouncement,
          hideReactions: props.response.hideReactions,
          hideReplies: props.response.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.response?.activeAnnouncement,
    props.response.hideReactions,
    props.response.hideReplies,
    props.response?.responseId,
  ]);

  const handleOnEditAnnouncementClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'EDIT_ANNOUNCEMENT',
        payload: {
          flowId: feedProps?.flowId,
          responseId: props.response?.responseId,
          activeAnnouncement: props.response?.activeAnnouncement,
          hideReactions: props.response.hideReactions,
          hideReplies: props.response.hideReplies,
        },
      },
      '*',
    );
  }, [
    feedProps?.flowId,
    props.response?.activeAnnouncement,
    props.response.hideReactions,
    props.response.hideReplies,
    props.response?.responseId,
  ]);

  const dropdownComponent = useMemo(
    () => (
      <DropdownContainer>
        <FlowsPostHeaderOption
          dropdownOptions={getFeedPostHeaderMenuOptions(
            showDeleteOption,
            showEditOption,
            variant,
            notificationPreference,
            showPostInteractionSettings,
            canEndAnnouncement && isEmbeddedInMainApp,
            canEditAnnouncement && isEmbeddedInMainApp,
            canShareAsAnnouncement && isEmbeddedInMainApp,
            canViewAnnouncementInsights && isEmbeddedInMainApp,
          )}
          icon="more"
          title="flows post options"
          onCopyLink={onCopyLinkClick}
          hasTrophies={hasTrophies}
          onDeletePostSelectedOption={handleDeletePostOptionChange}
          onDeleteModalSubmitClick={onDeleteModalSubmitClick}
          onGotoPostClick={onGotoPostLinkClick}
          onEditPost={() => {
            if (handleEditPostClick) {
              if (feedProps) {
                const postId = feedProps?.responseId;
                if (postId) {
                  handleEditPostClick({ postId });
                }
              }
            }
          }}
          onNotificationPreferenceClick={onNotificationPreferenceClick}
          onPostInteractionSettingsClick={handlePostInteractionSettingsClick}
          onViewAnnouncementsInsightsClick={handleViewAnnouncementInsightsClick}
          currency={currency}
          onEndAnnouncementClick={handleOnEndAnnouncementClick}
          onEditAnnouncementClick={handleOnEditAnnouncementClick}
          onCreateAnnouncementClick={handleOnCreateAnnouncementClick}
          onDeletePostClick={handleDeletePostClick}
        />
      </DropdownContainer>
    ),
    [
      showDeleteOption,
      showEditOption,
      variant,
      notificationPreference,
      showPostInteractionSettings,
      canEndAnnouncement,
      isEmbeddedInMainApp,
      canEditAnnouncement,
      canShareAsAnnouncement,
      canViewAnnouncementInsights,
      onCopyLinkClick,
      hasTrophies,
      handleDeletePostOptionChange,
      onDeleteModalSubmitClick,
      onGotoPostLinkClick,
      onNotificationPreferenceClick,
      handlePostInteractionSettingsClick,
      handleViewAnnouncementInsightsClick,
      currency,
      handleOnEndAnnouncementClick,
      handleOnEditAnnouncementClick,
      handleOnCreateAnnouncementClick,
      handleEditPostClick,
      feedProps,
      handleDeletePostClick,
    ],
  );

  if (profileData && feedProps) {
    return (
      <FlowFeedPost
        index={0}
        className={className}
        variant={variant}
        hideBorder={hideBorder}
        activeAnnouncement={activeAnnouncement}
        repliesBarProps={repliesBarProps}
        profileData={profileData}
        key={feedProps.key}
        onReactionSet={handleReactionSet}
        onReactionUnSet={handleReactionUnSet}
        onMemberClick={handleMemberClick}
        headerProps={feedProps.headerProps}
        expandPostButton={!isInFlowDetailsPage}
        isAnonymous={
          feedProps?.kind === FeedItemKind.EXTERNAL_ANONYMOUS ||
          feedProps?.kind === FeedItemKind.INTERNAL_ANONYMOUS
        }
        bodyProps={{
          blocks: feedProps.bodyProps,
          fileToPreview,
          filePreviewError,
          refetchFilePreviewLink,
          isFilePreviewLoading,
          fileToDownload,
          fileDownloadError,
          refetchFileDownloadLink,
          isConvertedPdfPreviewsEnabled,
          handleSetFilePreview,
          isExpandedOnLoad,
          onSeeFullPostClick,
          onDownloadFileClick,
        }}
        feedItemMetaData={{
          postId: feedProps.postId,
          flowId: feedProps.flowId,
          responseId: feedProps.responseId,
          type: feedProps.type,
        }}
        reactionBarProps={feedProps.reactionBarProps}
        onFlowClick={onFlowClick}
        dropdownComponent={dropdownComponent}
        handleEditPostClick={handleEditPostClick}
        hideInteractions={hideInteractions}
        hideRepliesBar={hideRepliesBar}
        isSamplePost={isSamplePost}
        hideReactions={hideReactions}
        onViewAnnouncementInsightsClick={handleViewAnnouncementInsightsClick}
      />
    );
  }
  return null;
};

export default FlowResponseController;
