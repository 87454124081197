import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import mobileStore from '../../stores/mobileAppStore';
import { isMobilePlatform } from '../../Utils/window';

const useMobileApp = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const jwt = searchParams.get('jwtToken');
  const mobileAppQueryParamValue = searchParams.get('isMobileApp');
  const mobileAppV3QueryParamValue = searchParams.get('isMobileAppV3');
  const showShareSheet =
    searchParams.get('showShareSheet') === 'true' ||
    searchParams.get('show-share-sheet') === 'true';
  const showTemplateGallery = searchParams.get('showTemplate') === 'true';

  const {
    jwtToken,
    setJwtToken,
    setIsTurnedOn,
    setIsMobileAppV3,
    isMobileApp: mobileAppStoreValue,
    isMobileAppV3,
  } = mobileStore.getState();

  useEffect(() => {
    if (jwt) {
      setJwtToken(jwt);
    }

    if (mobileAppQueryParamValue === 'true') {
      setIsTurnedOn(true);
    }

    if (mobileAppV3QueryParamValue === 'true') {
      setIsMobileAppV3(true);
    }

    if (mobileAppQueryParamValue === 'false') {
      setIsTurnedOn(false);
    }
  }, [
    jwt,
    mobileAppQueryParamValue,
    mobileAppV3QueryParamValue,
    setIsMobileAppV3,
    setIsTurnedOn,
    setJwtToken,
  ]);

  return {
    jwtToken,
    showShareSheet,
    showTemplateGallery,
    isMobileApp:
      isMobilePlatform &&
      (mobileAppStoreValue || mobileAppQueryParamValue === 'true'),
    isMobileAppV3,
  };
};

export default useMobileApp;
