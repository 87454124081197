import { arrayMoveImmutable } from 'array-move';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import { defaultFlow } from '../../../Utils/flows';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import { isDesktopPlatform } from '../../../Utils/window';
import FlowLeftDrawerCollapsibleNavigation from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import FlowsMenuAccordion from '../../../atomic/organism/MenuAccordion/FlowsMenuAccordion';
import useFlowsListAccordion from '../../../hooks/useFlowsListAccordion';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import { ComponentStatus } from '../../../interfaces/component';
import { FLOWS_LEFT_DRAWER_NAVIGATION_TITLE } from '../../../languages/en/flows';

import { useImmer } from 'use-immer';
import {
  trackFolderActionEvent,
  trackFolderErrorEvent,
} from '../../../Utils/analytics/folder';
import {
  useFetchFlowsActivity,
  useMainNavQuery,
} from '../../../queries/Flows/Dashboard';
import {
  FlowResponse,
  FlowsUpdatesResponse,
} from '../../../queries/Flows/interfaces';
import {
  useFolderRearrangementMutation,
  useUpdateFlowInFolder,
} from '../../../queries/folderAPI';
import { UnarchiveFlowsModal } from '../FlowsArchivedListController/UnarchiveFlowsModal';
import useFlowsArchivedListController from '../FlowsArchivedListController/useFlowsArchivedListController';
import {
  mapFlowsResponseToNavFlowItem,
  sortLeftDrawerNavFlowItemsByLabel,
} from '../utils';
import { DraggedCard, NavFlowGroup } from './NavFlowGroup';

function mapFlowDataToNavItems(
  flows: FlowResponse[] | undefined,
  flowsActivityData: FlowsUpdatesResponse[],
  selectedId: string,
  includeDefaultFlow: boolean,
) {
  if ((!flows || flows.length === 0) && !includeDefaultFlow) {
    return [];
  }

  const flowsNavItemsWithActivityUpdates: LeftDrawerCollapsibleNavItemProps[] =
    flows?.map(mapFlowsResponseToNavFlowItem).filter((navItem) => {
      const correspondingItems = flowsActivityData.find(
        (update) => update.flowId === navItem.id,
      );
      return !correspondingItems?.hasUnreadPost;
    }) || [];

  const activeFlowItems = flowsNavItemsWithActivityUpdates
    .filter((item) => !item.isMuted)
    .sort(sortLeftDrawerNavFlowItemsByLabel);

  const mutedFlowItems = flowsNavItemsWithActivityUpdates
    .filter((item) => item.isMuted)
    .sort(sortLeftDrawerNavFlowItemsByLabel);

  let sortedFlowsItemsData = activeFlowItems.concat(mutedFlowItems);

  if (includeDefaultFlow) {
    sortedFlowsItemsData = [
      mapFlowsResponseToNavFlowItem(defaultFlow),
      ...sortedFlowsItemsData,
    ];
  }

  return sortedFlowsItemsData.map((navItem) =>
    navItem.id === selectedId
      ? {
          ...navItem,
          isActive: true,
        }
      : navItem,
  );
}

type DragResultInfo = Pick<
  DropResult,
  'destination' | 'source' | 'type' | 'draggableId'
>;

export function FlowsCustomListController({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) {
  const {
    data,
    isError,
    isSuccess,
    isInitialLoading: isLoading,
  } = useMainNavQuery();

  const { data: flowsActivityUpdates } = useFetchFlowsActivity({
    isEnabled: true,
  });

  const [flowItem, setFlowItem] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);

  const pathNames = useLocation()
    .pathname.split('/')
    .filter((x) => x !== '' && x !== 'flows');

  const selectedId = pathNames[1] || '';

  const flowGroups = useMemo(() => {
    const flowsActivityData = flowsActivityUpdates?.data || [];

    return data?.groupedData.map((entry) => {
      const isCustomFolder = Boolean(entry.folder?.folderId);
      const flowNavItems = mapFlowDataToNavItems(
        entry.flows,
        flowsActivityData,
        selectedId,
        !isCustomFolder,
      );

      // We manually insert the recognition flow in the uncategorized folder so it should not be counted
      const noOfFlowsToCompare = entry.folder?.folderId
        ? flowNavItems.length
        : flowNavItems.length - 1;

      return {
        id: entry.folder?.folderId || 'uncategorized',
        title: entry.folder?.name || FLOWS_LEFT_DRAWER_NAVIGATION_TITLE,
        flows: flowNavItems,
        isCustomFolder,
        folderColor: entry.folder?.color,
        hasUnreads: noOfFlowsToCompare === 0 && entry.flows.length > 0,
        allFlowsAreUnread: entry.flows.length > 0 && flowNavItems.length === 0,
      };
    });
  }, [data?.groupedData, flowsActivityUpdates?.data, selectedId]);

  const { isOpen, toggleExpandNavigation } = useFlowsListAccordion();

  const { canUserCreateFlow = false } = useGetUserFlowPermissions();

  const isAddNewFlowOn = canUserCreateFlow && isDesktopPlatform;

  const isHovered = isLeftNavHovered || isLeftAsideOpen;

  const { mutateAsync: updateFolderLocation } =
    useFolderRearrangementMutation();
  const { mutateAsync: updateFlowLocation } = useUpdateFlowInFolder();

  const {
    models: {
      archiveFlowsNavItems,
      isArchiveModalOpen,
      isUnArchiveModalLoading,
      unArchivingFlowDetails,
    },
    operations: { onMenuItemClick, onUnArchiveClick, onModalClose },
  } = useFlowsArchivedListController();

  useEffect(() => {
    if (flowItem) onMenuItemClick(flowItem?.id);
  }, [flowItem, onMenuItemClick]);

  const [dragSessionDetails, setDragSessionDetails] = useImmer<
    DragResultInfo | undefined
  >(undefined);

  const handleMovementOfFlowsAndFolders = useCallback(
    (result: DragResultInfo) => {
      const { source, destination, type, draggableId } = result;

      if (!destination || !source || !type || !draggableId) {
        return;
      }

      if (type === 'flow') {
        if (
          destination.droppableId === 'uncategorized' &&
          source.droppableId === 'uncategorized'
        ) {
          // This is when a flow is moved between flows & archived sections, no reordering is required
          return;
        }

        const payload = {
          flowId: draggableId,
          destinationFolderId:
            destination.droppableId === 'uncategorized'
              ? null
              : destination.droppableId,
        } as Parameters<typeof updateFlowLocation>[0];

        if (source.droppableId !== 'uncategorized') {
          payload.sourceFolderId = source.droppableId;
        }

        (async () => {
          try {
            await updateFlowLocation(payload);
            trackFolderActionEvent({
              action: 'flowAddedToFolder',

              folder:
                destination.droppableId === 'uncategorized'
                  ? 'flows'
                  : 'customFolder',
              customFolderName:
                data?.folders.find(
                  (x) => x.folderId === destination.droppableId,
                )?.name || undefined,
            });
          } catch (error) {
            trackFolderErrorEvent({
              error: 'flowAddedToFolder',
              folder:
                destination.droppableId === 'uncategorized'
                  ? 'flows'
                  : 'customFolder',
              customFolderName:
                data?.folders.find(
                  (x) => x.folderId === destination.droppableId,
                )?.name || undefined,
            });
          }
        })();
      } else if (type === 'folder') {
        const folderBeingMoved = result.draggableId;

        const payload = {
          folderId: folderBeingMoved,
        } as Parameters<typeof updateFolderLocation>[0];

        if (data?.folders.length) {
          const newFolderOrder = arrayMoveImmutable(
            data?.folders,
            source.index,
            destination.index,
          );
          const newLocation = newFolderOrder.findIndex(
            (x) => x.folderId === draggableId,
          );
          const afterId = newFolderOrder[newLocation + 1]?.folderId;
          const beforeId = newFolderOrder[newLocation - 1]?.folderId;
          if (afterId) {
            payload.afterId = afterId;
          }
          if (beforeId) {
            payload.beforeId = beforeId;
          }
        }

        (async () => {
          try {
            await updateFolderLocation(payload);
            showSuccessMessage('Folder location updated successfully');

            trackFolderActionEvent({
              action: 'folderMoved',
              folder: 'customFolder',
              customFolderName: data?.folders.find(
                (x) => x.folderId === folderBeingMoved,
              )?.name,
            });
          } catch (error) {
            showErrorMessage(
              'Something went wrong while updating the folder location',
            );
            trackFolderErrorEvent({
              error: 'folderMoved',
              folder: 'customFolder',
              customFolderName: data?.folders.find(
                (x) => x.folderId === folderBeingMoved,
              )?.name,
            });
          }
        })();
      }
    },
    [data?.folders, updateFlowLocation, updateFolderLocation],
  );

  const handleReorder = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const { source, destination, type, draggableId } = result;

      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      if (type === 'flow') {
        const archivedFlow = archiveFlowsNavItems.find(
          (i) => i.id === draggableId,
        );

        if (archivedFlow) {
          setDragSessionDetails({
            source,
            destination,
            draggableId,
            type,
          });
          setFlowItem(archivedFlow);

          return;
        }
      }

      handleMovementOfFlowsAndFolders({
        source,
        destination,
        type,
        draggableId,
      });
    },

    [
      archiveFlowsNavItems,
      handleMovementOfFlowsAndFolders,
      setDragSessionDetails,
    ],
  );

  if (isLoading) {
    return (
      <FlowsMenuAccordion
        status={ComponentStatus.LOADING}
        accordionTitle={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
        isAccordionOpen={isOpen}
        onAccordionClick={toggleExpandNavigation}
        isHover={isHovered}
      />
    );
  }

  if (isSuccess && flowGroups?.length) {
    return (
      <DragDropContext onDragEnd={handleReorder}>
        <Droppable type="folder" droppableId="flowsFolder">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {flowGroups.map((group, index) => (
                <Draggable
                  draggableId={group.id || Math.random.toString()}
                  index={index || 0}
                  disableInteractiveElementBlocking
                  key={group.id}
                  isDragDisabled={group.id === 'uncategorized'}
                >
                  {(draggableProvided, draggableSnapshot) => (
                    <DraggedCard
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      ref={draggableProvided.innerRef}
                      isDragging={draggableSnapshot.isDragging}
                    >
                      <NavFlowGroup
                        isAddNewFlowOn={isAddNewFlowOn}
                        isHovered={isHovered}
                        flows={group.flows}
                        title={group.title}
                        key={group.id}
                        id={group.id}
                        isCustomFolder={group.isCustomFolder}
                        folderColor={group.folderColor}
                        groupIndex={index}
                        allFlowsAreUnread={group.allFlowsAreUnread}
                        hasUnreads={group.hasUnreads}
                      />
                    </DraggedCard>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <UnarchiveFlowsModal
          isArchiveModalOpen={isArchiveModalOpen && Boolean(flowItem)}
          isUnArchiveModalLoading={isUnArchiveModalLoading}
          onModalClose={() => {
            onModalClose();
            setFlowItem(undefined);
            setDragSessionDetails(undefined);
          }}
          onUnArchiveClick={async () => {
            await onUnArchiveClick();
            setFlowItem(undefined);
            if (dragSessionDetails) {
              handleMovementOfFlowsAndFolders(dragSessionDetails);
            }
          }}
          unArchivingFlowDetails={unArchivingFlowDetails}
          flowItem={flowItem}
        />
      </DragDropContext>
    );
  }

  if (isError) {
    return (
      <FlowLeftDrawerCollapsibleNavigation
        isExpanded={isOpen}
        onExpandClick={toggleExpandNavigation}
        heading={FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
        status={ComponentStatus.ERROR}
      />
    );
  }

  return null;
}
