import Bowser from 'bowser';
import { device } from '../constants/layout';

const browser = Bowser.getParser(window.navigator.userAgent);

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isWindowsOS = () => {
  if (window.navigator.platform && window.navigator.platform.includes('Win')) {
    return true;
  }
  return false;
};

export const isDesktopPlatform =
  browser.getPlatformType() === 'desktop' ||
  browser.parseOS().name?.toLowerCase() === 'chrome os';

export const isMobilePlatform = browser.getPlatformType() === 'mobile';

export const isTabletPlatform = browser.getPlatformType() === 'tablet';

export const isIPhoneOS = browser.parseOS().name === 'iOS';

export const isMacOS = browser.parseOS().name === 'macOS';

export const isChrome = browser.getBrowserName() === 'Chrome';

export const isEdge = browser.getBrowserName() === 'Microsoft Edge';

export const windowSizeAdapter = (
  isMobileApp: boolean,
  inputs: {
    mobileApp: string;
    mobileWeb: string;
    tablet: string;
    desktop: string;
    embeddedWeb?: string;
  },
  isEmbedded?: boolean,
  isFullScreen?: boolean,
  isMobileAppV3 = false,
) => {
  if (isFullScreen) {
    return 'unset';
  }
  if (isMobileAppV3) {
    return inputs.mobileApp;
  }
  if (isEmbedded) {
    if (window.matchMedia(device.mobile).matches) {
      return inputs.mobileWeb;
    } else {
      return inputs.embeddedWeb;
    }
  }
  if (isMobilePlatform) {
    if (isMobileApp) {
      return inputs.mobileApp;
    } else {
      return inputs.mobileWeb;
    }
  } else {
    if (window.matchMedia(device.mobile).matches) {
      return inputs.tablet;
    } else {
      return inputs.desktop;
    }
  }
};
