import { ReactNode, useState } from 'react';
import { FeedsGridContainerLayout } from '../../../Utils/styles/display';
import {
  ASSEMBLY_APP_STORE_URL,
  ASSEMBLY_PLAY_STORE_URL,
} from '../../../config';
import SecondaryDrawerController from '../../../controllers/home/SecondaryDrawerController';
import PrimaryHeaderController from '../../../controllers/layout/PrimaryHeaderController';
import PrimaryNavController from '../../../controllers/layout/PrimaryNavController';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import useMobileApp from '../../../hooks/useMobileApp';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import assemblyLogo from '../../../img/assembly-placeholder-logo.svg';
import useLayoutStore from '../../../stores/layoutStore';
import AssemblyBanner from '../../molecules/AssemblyBanner';
import MainBody from '../MainBody';
import SecondaryDrawer from '../SecondaryDrawer';
import { PageContent } from './styles';
import Bowser from 'bowser';

interface PrimaryLayoutProps {
  children: ReactNode;
  isFlowOwner: boolean;
  onSecondaryDrawerToggle?: (isRightAsideOpen: boolean) => void;
  isNotebookView?: boolean;
  hasTabComponent?: boolean;
}

const PrimaryLayout = ({
  children,
  isFlowOwner,
  isNotebookView,
  hasTabComponent,
  onSecondaryDrawerToggle,
}: PrimaryLayoutProps) => {
  const { treatment: displayAppBannerTreatment } = useFeatureSplit(
    SplitNames.DisplayAppBanner,
  );

  const browser = Bowser.getParser(window.navigator.userAgent);
  const mobileOperatingSystem = browser.getOSName();
  const { isMobileApp, isMobileAppV3 } = useMobileApp();
  const [isSmartBannerClosed, setSmartBannerClosed] = useState(false);
  const isMobilePlatform = browser.getPlatformType() === 'mobile';
  const { isEmbeddedInMainApp } = useLayoutStore();

  const canDisplayBanner =
    displayAppBannerTreatment === TreatmentTypes.ON &&
    isMobilePlatform &&
    !isMobileApp &&
    !isSmartBannerClosed &&
    !isEmbeddedInMainApp;

  return (
    <>
      {!isMobileApp && !isEmbeddedInMainApp && <PrimaryHeaderController />}

      <PageContent top={isMobileApp || isEmbeddedInMainApp ? 'unset' : '72px'}>
        {canDisplayBanner && (
          <AssemblyBanner
            buttonText="Open"
            icon="round-close"
            imgSrc={assemblyLogo}
            heading="Get the app!"
            linkUrl={
              mobileOperatingSystem === 'Android'
                ? ASSEMBLY_PLAY_STORE_URL
                : ASSEMBLY_APP_STORE_URL
            }
            subHeading="Download or open it now"
            onCloseClick={() => setSmartBannerClosed(true)}
          />
        )}
        {!isMobileApp && !isEmbeddedInMainApp && <PrimaryNavController />}
        <MainBody
          isMobileAppV3={isMobileAppV3}
          isMobileApp={isMobileApp}
          isFlowOwner={isFlowOwner}
          isNotebookView={isNotebookView}
          hasTabComponent={hasTabComponent}
        >
          <FeedsGridContainerLayout
            fullWidth={hasTabComponent}
            id="feeds-grid-container"
          >
            {children}
          </FeedsGridContainerLayout>
        </MainBody>
        <SecondaryDrawer onSecondaryDrawerToggle={onSecondaryDrawerToggle}>
          <SecondaryDrawerController />
        </SecondaryDrawer>
      </PageContent>
    </>
  );
};

export default PrimaryLayout;
