import styled from 'styled-components';
import ThemeV2 from '../../../../theme';
import { Flex } from '../../../../Utils/styles/display';

export const HorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin: 4px 0;
  background-color: ${ThemeV2.palette.lightGray5};
`;

export const ConditionWrapper = styled(Flex)`
  justify-content: center;
  width: 100%;
  padding: 12px 0;
`;
