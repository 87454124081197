import { IMemberDTO } from '../../../../interfaces/member';
import { ANONYMOUS_MEMBER_ID } from '../../../../Utils/rightDrawer';
import { FlowOccurrenceDetail } from '../../../../queries/Flows/interfaces';

export const mapDepartmentsDataToFilterOptions = (
  data: string[] | undefined,
) => {
  return !data || !data.length
    ? []
    : data.map((department: string) => {
        return {
          value: false,
          label: department,
          id: department,
        };
      });
};

export const mapParticipantsDataToFilterOptions = (
  searchedMembers: IMemberDTO[] | undefined,
) => {
  return !searchedMembers || !searchedMembers.length
    ? []
    : searchedMembers.map((member: IMemberDTO) => {
        return {
          value: false,
          label: member.name || '',
          id: member.memberID || '',
          img: member.image || '',
          showAvatar: true,
          memberState: member.memberState,
          isAnonymous: member.memberID === ANONYMOUS_MEMBER_ID,
        };
      });
};

export const getFormattedTimeFromDate = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const modifiedMinutes = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${modifiedMinutes} ${ampm}`;
};

export const mapOccurrenceDateToFilterOptions = (
  data: FlowOccurrenceDetail[],
) => {
  return data.map((occurrence: FlowOccurrenceDetail) => {
    return {
      value: false,
      label: `${new Date(
        occurrence.startTime,
      ).toLocaleDateString()} at ${getFormattedTimeFromDate(
        new Date(occurrence.startTime),
      )}`,
      id: occurrence.occurrenceId,
    };
  });
};
