export enum FileFormat {
  document = 'document',
  image = 'image',
  presentation = 'presentation',
  pdf = 'pdf',
  spreadsheet = 'spreadsheet',
  audio = 'audio',
  video = 'video',
  zip = 'zip',
}

const documentExtensions = ['doc', 'docx'];
const imageExtensions = ['jpg', 'jpeg', 'png', 'svg', 'bmp', 'gif'];
const presentationExtensions = ['ppt', 'pptx', 'pps', 'ppsx'];
const pdfExtensions = ['pdf'];
const spreadsheetExtensions = ['xls', 'xlsx', 'csv'];
const audioExtensions = ['mp3', 'wav', 'm4a', 'ogg'];
const videoExtensions = ['mp4', 'avi', 'mov', 'mpg', 'ogv', '3gp', '3d2'];
const zipExtensions = ['zip'];

const supportedFileExtensions = [
  ...documentExtensions,
  ...presentationExtensions,
  ...pdfExtensions,
  ...spreadsheetExtensions,
  ...audioExtensions,
  ...videoExtensions,
  ...zipExtensions,
] as const;

export const fileFormatExtensionMap = {
  [FileFormat.document]: documentExtensions,
  [FileFormat.image]: imageExtensions,
  [FileFormat.presentation]: presentationExtensions,
  [FileFormat.pdf]: pdfExtensions,
  [FileFormat.spreadsheet]: spreadsheetExtensions,
  [FileFormat.audio]: audioExtensions,
  [FileFormat.video]: videoExtensions,
  [FileFormat.zip]: zipExtensions,
};

export type FileType = (typeof supportedFileExtensions)[number];
