import { datadogLogs } from '@datadog/browser-logs';
import { DATA_DOG } from '../config';

export function setupDataDog() {
  const dataDogOptions = {
    ...DATA_DOG,
    silentMultipleInit: true,
    useCrossSiteSessionCookie: true,
    trackSessionAcrossSubdomains: true,
    version: import.meta.env.VITE_APP_VERSION,
    useSecureSessionCookie: import.meta.env.MODE !== 'development',
  };

  datadogLogs.init({
    ...dataDogOptions,

    sessionSampleRate: 100,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    forwardReports: ['csp_violation', 'deprecation', 'intervention'],
  });
}
