import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  GET_FLOW_DETAILS,
  GET_SHARE_REQUESTS,
  GET_TODO_FLOWS,
} from '../../constants/endpoints';
import { useFetchFlowActive } from '../../queries/Flows/Dashboard';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import { Channel } from 'pusher-js';

interface SocketPayloadType {
  flowId: string;
  shareRequestId: string;
}

let channelAssembly: Channel;

const useShareSheetSocket = () => {
  const { data } = useProfileInfoFetchQuery();
  const { refetch: refetchActiveFlow } = useFetchFlowActive();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      const channelAssemblyEventHandler = (
        socketData: string,
        socketPayload: SocketPayloadType,
      ) => {
        switch (socketData) {
          case 'SHARE_REQUEST_CREATED': {
            if (socketPayload?.flowId) {
              queryClient.invalidateQueries([
                GET_SHARE_REQUESTS,
                socketPayload.flowId,
              ]);
            }
            break;
          }
          case 'SHARE_REQUEST_APPROVED':
          case 'ASSEMBLY_MEMBER_DEACTIVATED': {
            refetchActiveFlow();
            queryClient.invalidateQueries([GET_TODO_FLOWS]);

            if (socketPayload?.flowId) {
              queryClient.invalidateQueries([
                GET_SHARE_REQUESTS,
                socketPayload.flowId,
              ]);
              queryClient.invalidateQueries([
                GET_FLOW_DETAILS,
                socketPayload.flowId,
                '',
              ]);
            }
            break;
          }
          default:
            break;
        }
      };

      channelAssembly.bind_global(channelAssemblyEventHandler);
    }
  }, [data, queryClient, refetchActiveFlow]);
};

export default useShareSheetSocket;
