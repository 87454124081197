import { createContext } from 'react';
import useArchiveFlowModal from '.';

import ArchiveFlowModal from './ArchiveFlowModal';
import {
  ArchiveFlowModalContextState,
  ArchiveFlowModalProviderProps,
} from './types';

export const ArchiveFlowModalContext =
  createContext<ArchiveFlowModalContextState>({
    flowDetails: undefined,
    handleOnArchiveClick: () => {},
    isArchiveFlowLoading: false,
    isArchiveModalOpen: false,
    setArchiveModalClose: () => {},
    setArchiveModalOpen: () => {},
    setFlowDetails: () => {},
  });

export const ArchiveFlowModalProvider = (
  props: ArchiveFlowModalProviderProps,
) => {
  const { children } = props;
  const modalValue = useArchiveFlowModal();
  return (
    <ArchiveFlowModalContext.Provider value={modalValue}>
      <ArchiveFlowModal />
      {children}
    </ArchiveFlowModalContext.Provider>
  );
};
