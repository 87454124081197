import styled from 'styled-components';

import { shadowElevation2Style } from './css';

import Body from '../../atomic/atoms/Body';
import ThemeV2 from '../../theme';

export const StyledActionBarContainer = styled.div`
  background-color: ${ThemeV2.palette.gray1};
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  ${shadowElevation2Style};
`;

export const StyledActionBarWrapper = styled.div`
  position: relative;
`;

export const StyledNotification = styled(Body)`
  background-color: ${ThemeV2.palette.geekBlue6};
  color: ${ThemeV2.palette.gray1};
  padding: 6px 16px;
  border-radius: 4px 4px 0 0;
`;
