import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ConnectionState } from '../types';
import useSlackUserStore from '../../../../../stores/userStore';
import useSlackCriteriaOptions from '../useSlackCriteriaOptions';
import useFlowBuilderStore from '../../../../../stores/flowBuilderStore';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { GetChannelsListResponse } from '../../../../../interfaces/Connections';
import { useDeleteConnectionIntegration } from '../../../../../queries/Connections';
import { AutocompleteDropdownItem } from '../../../../../atomic/organism/Autocomplete/interfaces';
import {
  useFetchExternalAuthKey,
  useGenerateExternalAuthKey,
} from '../../../../../queries/Integrations';

const useConnectionsController = () => {
  const {
    slackCode,
    refetchChannels,
    connections,
    isLoadingChannels,
    selectedConnection,
    channelsListResponse,
    isLoadingConnections,
    setSelectedConnection,
    setIsLoadingChannels,
    isGetChannelsListJobQueued,
  } = useSlackCriteriaOptions();

  const { flowId } = useParams<{ flowId: string }>();
  const { setIsConnectionsTabTouched } = useFlowBuilderStore();

  const [authKey, setAuthKey] = useState<string>('');
  const [connectedTriggers, setConnectedTriggers] = useState<number>(0);

  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');

  const onGenerateAPIKeySuccess = useCallback((generatedAuthKey: string) => {
    setAuthKey(generatedAuthKey);
  }, []);

  const {
    data: externalAuthKeyData,
    isInitialLoading: isFetchExternalAuthKeyLoading,
  } = useFetchExternalAuthKey(true, flowId);
  const {
    mutate: generateExternalAuthKey,
    isLoading: isGenerateExternalAuthKeyLoading,
  } = useGenerateExternalAuthKey(onGenerateAPIKeySuccess);

  const {
    applicationCacheId,
    setApplicationCacheId,
    setSelectedConnectionId,
    setSelectedPeopleToPostChannels,
    setSelectedPostFlowResponseChannels,
    setSelectedConnectionName,
  } = useSlackUserStore();

  const {
    mutate: deleteSlackConnection,
    isLoading: isDeletingConnection,
    isSuccess: isDisconnectedSuccess,
  } = useDeleteConnectionIntegration(flowId);

  const [state, setState] = useState(ConnectionState.ConnectState);
  const [addedConnectionId, setAddedConnectionId] = useState<string>('');

  const [connectionStatus, setConnectionStatus] = useState('');

  const [channelList, setChannelList] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  const [connectionsList, setConnectionsList] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  const [selectedPeopleToPostChannel, setSelectedPeopleToPostChannel] =
    useState<AutocompleteDropdownItem<string>[]>([]);

  const [selectedPostFlowResponseChannel, setSelectedPostFlowResponseChannel] =
    useState<AutocompleteDropdownItem<string>[]>([]);

  useEffect(() => {
    if (externalAuthKeyData?.authKey) {
      setAuthKey(externalAuthKeyData.authKey);
    }
    if (externalAuthKeyData?.triggersConnected) {
      setConnectedTriggers(externalAuthKeyData.triggersConnected);
    }
  }, [externalAuthKeyData]);

  useEffect(() => {
    if (selectedConnection && selectedConnection.length > 0) {
      setSelectedConnectionId(selectedConnection[0].id || '');
      setSelectedConnectionName(selectedConnection[0].name || '');
    }
  }, [selectedConnection, setSelectedConnectionId, setSelectedConnectionName]);

  useEffect(() => {
    if (slackCode && state === ConnectionState.DisconnectedState) {
      setIsConnectionsTabTouched(true);
      setState(ConnectionState.ConnectionEditState);
    }
  }, [setIsConnectionsTabTouched, slackCode, state]);

  useEffect(() => {
    if (channelsListResponse?.channels && !isLoadingChannels) {
      setSelectedPeopleToPostChannels(
        channelsListResponse.channels.filter((channel) =>
          selectedPeopleToPostChannel.find((item) => item.id === channel.id),
        ),
      );

      setSelectedPostFlowResponseChannels(
        channelsListResponse.channels.filter((channel) =>
          selectedPostFlowResponseChannel.find(
            (item) => item.id === channel.id,
          ),
        ),
      );
    }
  }, [
    isLoadingChannels,
    channelsListResponse,
    selectedPeopleToPostChannel,
    selectedPostFlowResponseChannel,
    setSelectedPeopleToPostChannels,
    setSelectedPostFlowResponseChannels,
  ]);

  useEffect(() => {
    if (isDisconnectedSuccess) {
      setState(ConnectionState.ConnectState);
    }
  }, [isDisconnectedSuccess]);

  useEffect(() => {
    if (!isLoadingChannels) {
      const response = channelsListResponse as GetChannelsListResponse;

      if (applicationCacheId !== response.applicationCacheId) {
        setApplicationCacheId(response.applicationCacheId);
      }
      setChannelList(
        response.channels.map((channel) => ({
          id: channel.id,
          title: channel.displayName,
          icon: channel.isPrivate ? 'lock' : 'hashtag-lite',
        })),
      );
    }
  }, [applicationCacheId, channelsListResponse, isLoadingChannels]);

  useEffect(() => {
    if (connections && !isLoadingConnections) {
      const dropdownList: AutocompleteDropdownItem<string>[] = [
        ...connections.data.map((connection) => ({
          id: connection.connectionId,
          title: connection.workspaceName,
          avatar: {
            img: connection.workspaceLogo,
            userId: connection.connectionId,
          },
        })),
      ];

      if (connectionsList.length !== dropdownList.length) {
        const addedConnection = dropdownList.filter(
          (item) =>
            !connectionsList.some((connection) => connection.id === item.id),
        );

        if (
          addedConnection.length === 1 &&
          addedConnectionId !== addedConnection[0].id
        ) {
          setAddedConnectionId(addedConnection[0].id);
        }

        setConnectionsList(dropdownList);
      }

      if (flowDetails && flowDetails.data && flowDetails.data.integrations) {
        let selectedConnectionId = '';
        const { participationNotification, responseNotification } =
          flowDetails.data.integrations;

        if (participationNotification.length > 0) {
          selectedConnectionId = participationNotification[0].connectionId;
        }

        if (responseNotification.length > 0) {
          selectedConnectionId = responseNotification[0].connectionId;
        }

        if (addedConnectionId) {
          selectedConnectionId = addedConnectionId;
        }

        if (
          state === ConnectionState.ConnectionEditState &&
          !selectedConnectionId
        ) {
          setSelectedConnection([dropdownList[dropdownList.length - 1]]);
        } else {
          const itemToSelect = dropdownList.filter(
            (item) => item.id === selectedConnectionId,
          );
          if (itemToSelect.length > 0) {
            setSelectedConnection(itemToSelect);
          } else {
            if (dropdownList.length > 0) {
              setSelectedConnection([dropdownList[dropdownList.length - 1]]);
            }
          }
        }
      } else {
        if (state === ConnectionState.ConnectionEditState) {
          if (addedConnectionId) {
            const itemToSelect = dropdownList.filter(
              (item) => item.id === addedConnectionId,
            );

            if (itemToSelect.length > 0) {
              setSelectedConnection(itemToSelect);
            } else {
              setSelectedConnection([dropdownList[dropdownList.length - 1]]);
            }
          } else {
            setSelectedConnection([dropdownList[dropdownList.length - 1]]);
          }
        }
      }
    }
  }, [
    state,
    connections,
    connectionsList,
    isLoadingConnections,
    flowDetails,
    setSelectedConnection,
    addedConnectionId,
  ]);

  useEffect(() => {
    if (
      connections &&
      !isLoadingConnections &&
      connections.data.length > 0 &&
      state === ConnectionState.ConnectToSlackState
    ) {
      setIsConnectionsTabTouched(true);
      setState(ConnectionState.ConnectionEditState);
    }
  }, [
    state,
    connections,
    connectionsList,
    isLoadingConnections,
    setSelectedConnection,
    setIsConnectionsTabTouched,
  ]);

  useEffect(() => {
    if (!selectedConnection || !selectedConnection.length) {
      setSelectedPeopleToPostChannel([]);
      setSelectedPostFlowResponseChannel([]);
    }
  }, [selectedConnection]);

  // Flow Details
  useEffect(() => {
    if (flowDetails && flowDetails.data) {
      let status = '';
      if (flowDetails.data.integrations) {
        const { participationNotification, responseNotification } =
          flowDetails.data.integrations;

        if (participationNotification && participationNotification.length > 0) {
          status = participationNotification[0].connectionStatus;
        }

        if (responseNotification && responseNotification.length > 0) {
          status = responseNotification[0].connectionStatus;
        }
      }

      if (
        (status === 'CONNECTED' || status === 'ARCHIVED') &&
        state !== ConnectionState.ConnectionEditState
      ) {
        setState(ConnectionState.ConnectionDisplayState);
      }

      if (
        (status === 'DISCONNECTED' || status === 'CONNECTION_BROKEN') &&
        state !== ConnectionState.ConnectionEditState
      ) {
        setState(ConnectionState.DisconnectedState);
      }
      setConnectionStatus(status);
    }
  }, [flowDetails, state]);

  useEffect(() => {
    if (flowDetails && channelList.length > 0) {
      if (flowDetails.data.integrations) {
        const {
          data: {
            integrations: { participationNotification, responseNotification },
          },
        } = flowDetails;

        if (
          participationNotification.length > 0 &&
          participationNotification[0].metaData &&
          participationNotification[0].metaData.channelInfo.length > 0
        ) {
          const channelsIdToBeSelected =
            participationNotification[0].metaData.channelInfo.map(
              (channel) => channel.id,
            );

          setSelectedPeopleToPostChannel(
            channelList.filter((channel) =>
              channelsIdToBeSelected.includes(channel.id),
            ),
          );
        }

        if (
          responseNotification.length > 0 &&
          responseNotification[0].metaData &&
          responseNotification[0].metaData.channelInfo.length > 0
        ) {
          const channelsIdToBeSelected =
            responseNotification[0].metaData.channelInfo.map(
              (channel) => channel.id,
            );

          setSelectedPostFlowResponseChannel(
            channelList.filter((channel) =>
              channelsIdToBeSelected.includes(channel.id),
            ),
          );
        }
      }
    }
  }, [channelList]);

  const deleteConnection = useCallback(() => {
    deleteSlackConnection();
    setIsConnectionsTabTouched(false);
  }, [deleteSlackConnection, setSelectedConnectionId]);

  const handleOnRefreshChannelClick = useCallback(() => {
    setSelectedPeopleToPostChannel([]);
    setSelectedPostFlowResponseChannel([]);
    setIsLoadingChannels(true);
    refetchChannels(true);
  }, [setIsLoadingChannels, refetchChannels]);

  const onGenerateAPIKeyClick = useCallback(() => {
    generateExternalAuthKey();
  }, [generateExternalAuthKey]);

  return {
    state,
    setState,
    channelList,
    flowDetails,
    connectionsList,
    connectionStatus,
    deleteConnection,
    isLoadingChannels,
    selectedConnection,
    isDeletingConnection,
    isLoadingConnections,
    setSelectedConnection,
    isGetChannelsListJobQueued,
    selectedPeopleToPostChannel,
    handleOnRefreshChannelClick,
    setSelectedPeopleToPostChannel,
    selectedPostFlowResponseChannel,
    setSelectedPostFlowResponseChannel,
    onGenerateAPIKeyClick,
    authKey,
    connectedTriggers,
    isExternalAuthKeyLoading:
      isFetchExternalAuthKeyLoading || isGenerateExternalAuthKeyLoading,
  };
};

export default useConnectionsController;
