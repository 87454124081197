import React from 'react';
import { makeStyles } from '@mui/styles';
import ThemeV2 from '../../../theme';

const useStyles = makeStyles({
  root: ({
    color,
    isFullWidth,
    marginTop,
    marginBottom,
  }: {
    color: string;
    isFullWidth: boolean;
    marginTop: string;
    marginBottom: string;
  }) => ({
    height: '1px',
    width: isFullWidth ? '100%' : '150px',
    background: color,
    marginTop,
    marginBottom,
    boxSizing: 'border-box',
  }),
});

interface DividerProps {
  color?: string;
  isFullWidth?: boolean;
  className?: string;
  marginTop?: string;
  marginBottom?: string;
}

const Divider = ({
  color = ThemeV2.palette.gray6,
  isFullWidth = false,
  className: parentClassName = '',
  marginTop = '0',
  marginBottom = '0',
}: DividerProps) => {
  const { root } = useStyles({ color, isFullWidth, marginTop, marginBottom });
  const className = `${root} ${parentClassName}`;
  return <div className={className} />;
};

export default Divider;
