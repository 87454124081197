import { useEffect } from 'react';
import postMessageToMobileApp from '../useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../interfaces/PostMessage';

export const useParticipationFlowMessageEvent = () => {
  useEffect(() => {
    postMessageToMobileApp({
      action: AppAction.ParticipationStart,
      payload: {
        type: 'flowParticipation',
      },
    });

    return () => {
      postMessageToMobileApp({
        action: AppAction.ParticipationClose,
        payload: {
          type: 'flowParticipation',
        },
      });
    };
  }, []);
};
