import React from 'react';
import parse from 'html-react-parser';

import { replaceAll } from '../../../Utils/text';
import {
  RewardsMessageContent,
  RewardsMessageTitle,
  RewardSuccessContentContainer,
  RewardSuccessContentList,
} from '../../../atomic/organism/RedeemSelectModal/styles';

const renderInstructions = (redemptionInstructions: string) => (
  <RewardSuccessContentList>
    {parse(replaceAll(redemptionInstructions, '<a ', "<a target='_blank' "))}
  </RewardSuccessContentList>
);

export const parseOrder = (orderInfo: string) => {
  const orderJSON = JSON.parse(orderInfo);
  if (orderJSON && orderJSON.reward) {
    const { reward } = orderJSON;

    return (
      <>
        {
          <RewardSuccessContentContainer>
            <RewardsMessageTitle variant="body1">
              Gift card details:
            </RewardsMessageTitle>
            <RewardsMessageContent variant="body2">
              Please check the email associated with your Assembly account for
              your gift card code and additional details.
            </RewardsMessageContent>
          </RewardSuccessContentContainer>
        }
        {renderInstructions(reward.redemptionInstructions)}
      </>
    );
  }
  return <></>;
};
