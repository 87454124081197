import React, { useCallback, useMemo } from 'react';
import { EmojiData } from 'emoji-mart';
import { ReactionBarProps } from './interface';

import {
  ReactedMembersListWrapper,
  StyledToggleButton,
  StyledReactionButton,
} from './styles';
import EmoticonPickerPopover from '../../molecules/EmoticonPickerPopover';
import {
  FEED_POST_REACTIONS_REACT_TEXT,
  REACTION_PICKER_TOOLTIP_TEXT,
} from '../../../languages/en/home';
import ToolTip from '../../molecules/ToolTipV2';

import { formatReactedMembersList } from './utils';
import { PopoverChildrenProps } from '../../../hooks/usePopover/interface';
import { Reaction } from '../../../interfaces/Feed';

const ReactionBar = (props: ReactionBarProps) => {
  const {
    currentUserId,
    reactions,
    isPickerDisabled = false,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    toggleButtonVariant,
    toggleButtonIconSize = '20px',
    reactionButtonSize,
    contentId,
    hideInteractions = false,
    variant,
    showReactions = true,
    className,
  } = props;

  const handleEmoticonSelect = useCallback(
    (emoji: EmojiData) => {
      onEmoticonSelect(emoji, contentId);
    },
    [onEmoticonSelect, contentId],
  );

  const handleReactionButtonClick = useCallback(
    (reaction) => {
      onReactionToggleClick(reaction as Reaction, contentId);
    },
    [onReactionToggleClick, contentId],
  );

  const renderEmoticon = useMemo(
    () => (
      <EmoticonPickerPopover onEmoticonSelect={handleEmoticonSelect}>
        {({ togglePopover }: PopoverChildrenProps) => (
          <StyledToggleButton
            onClick={() => {
              togglePopover();
              if (onReactButtonClick) {
                onReactButtonClick();
              }
            }}
            icon="reaction"
            varient={variant}
            disabled={isPickerDisabled}
            variant={toggleButtonVariant}
            showReactions={showReactions}
            iconSize={toggleButtonIconSize}
          >
            {variant !== 'sidebar' && FEED_POST_REACTIONS_REACT_TEXT}
          </StyledToggleButton>
        )}
      </EmoticonPickerPopover>
    ),
    [
      handleEmoticonSelect,
      variant,
      isPickerDisabled,
      toggleButtonVariant,
      showReactions,
      toggleButtonIconSize,
      onReactButtonClick,
    ],
  );

  return (
    <>
      {!hideInteractions && variant !== 'sidebar' && (
        <ToolTip
          position="top"
          disabled={!isPickerDisabled}
          toolTipComponent={renderEmoticon}
        >
          {REACTION_PICKER_TOOLTIP_TEXT}
        </ToolTip>
      )}
      {showReactions &&
        reactions.map((reactionObj: Reaction) => {
          const { active, timesReacted, reaction } = reactionObj;
          return (
            <React.Fragment key={reaction.name}>
              <ToolTip
                position="top"
                toolTipComponent={
                  <StyledReactionButton
                    active={active}
                    reaction={reaction}
                    size={reactionButtonSize}
                    label={reaction.displayName || reaction.name}
                    onClick={() => {
                      handleReactionButtonClick(reactionObj);
                    }}
                  >
                    {timesReacted}
                  </StyledReactionButton>
                }
                {...(timesReacted > 1 && { toolTipSize: '268px' })}
              >
                <ReactedMembersListWrapper>
                  {formatReactedMembersList(reactionObj.members, currentUserId)}
                </ReactedMembersListWrapper>
              </ToolTip>
            </React.Fragment>
          );
        })}
      {!hideInteractions &&
        variant === 'sidebar' &&
        (reactions.length > 0 || !showReactions) && (
          <ToolTip
            position="top"
            disabled={!isPickerDisabled}
            toolTipComponent={renderEmoticon}
            className={className}
          >
            {REACTION_PICKER_TOOLTIP_TEXT}
          </ToolTip>
        )}
    </>
  );
};

const MemoizedReactionBar = React.memo(ReactionBar);
MemoizedReactionBar.displayName = 'ReactionBar';
export default MemoizedReactionBar;
