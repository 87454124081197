import ThemeV2 from '../../../theme';
import { File } from './types';
import { bytesToMegaBytes } from '../../../controllers/flows/FlowsFileUploadInputBlockController/utils';
import { FlowFileForAPI } from '../../../interfaces/Flow';

export const formatFileSize = (bytes: number): string => {
  const ONE_MILLION = 1000000;
  if (bytes < ONE_MILLION) {
    const kilobytes = Math.floor(bytes / 1000);
    return `${kilobytes}KB`;
  }

  const megabytes = Math.floor(bytes / ONE_MILLION);
  return `${megabytes}MB`;
};

export const truncate = (str: string, maxLen: number) => {
  if (str.length > maxLen) {
    const extensionStartIndex = str.lastIndexOf('.');
    const truncatedFileName = str
      .slice(0, extensionStartIndex)
      .slice(0, maxLen);
    const extension = str.slice(extensionStartIndex, str.length);
    return `${truncatedFileName}..${extension}`;
  }
  return str;
};

export function getThumbnailTokens({
  fileName,
  hasError,
}: {
  fileName: string;
  hasError: boolean;
}) {
  let cardColor = ThemeV2.palette.gray6;
  let cardIcon = 'genericFile';

  if (hasError) {
    return {
      cardColor: ThemeV2.palette.dustRed2,
      cardIcon: 'warning',
      cardIconColor: ThemeV2.palette.dustRed6,
    };
  }

  switch (fileName.split('.')[1]) {
    case 'doc':
    case 'docx':
      cardColor = ThemeV2.palette.blue7;
      cardIcon = 'docFile';
      break;

    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'bmp':
    case 'gif':
      cardColor = ThemeV2.palette.blue8;
      cardIcon = 'imageFile';
      break;

    case 'ppt':
    case 'pptx':
    case 'pps':
    case 'ppsx':
      cardColor = ThemeV2.palette.volcano6;
      cardIcon = 'presentationFile';
      break;

    case 'pdf':
      cardColor = ThemeV2.palette.red6;
      cardIcon = 'pdfFile';
      break;

    case 'xls':
    case 'xlsx':
    case 'csv':
      cardColor = ThemeV2.palette.green7;
      cardIcon = 'csvFile';
      break;

    case 'mp3':
    case 'wav':
    case 'm4a':
    case 'ogg':
      cardColor = ThemeV2.palette.orange6;
      cardIcon = 'audioFile';
      break;

    case 'mp4':
    case 'avi':
    case 'mov':
    case 'mpg':
    case 'ogv':
    case '3gp':
    case '3d2':
      cardColor = ThemeV2.palette.red7;
      cardIcon = 'videoFile';
      break;

    case 'zip':
      cardColor = ThemeV2.palette.gray8;
      cardIcon = 'zipFile';
      break;

    default:
      cardColor = ThemeV2.palette.gray6;
      cardIcon = 'genericFile';
      break;
  }

  return { cardColor, cardIcon };
}

export function segregateFiles(files?: File[]) {
  const defaultFilesObject = {
    imageFiles: [] as File[],
    otherFiles: [] as File[],
  };

  return (
    files?.reduce((acc, file) => {
      if (file.type === 'IMAGE') {
        acc.imageFiles.push(file);
      } else {
        acc.otherFiles.push(file);
      }
      return acc;
    }, defaultFilesObject) || defaultFilesObject
  );
}
export const getFileUploadLimit = (fileUploadLimit: number) => {
  if (fileUploadLimit < 1024) return `${fileUploadLimit}MB`;
  else {
    const limit = Math.floor(fileUploadLimit / 1024);
    return `${limit}GB`;
  }
};

export const getFileUploadAnalyticsPropertiesForUppy = (
  files: FlowFileForAPI[],
) => {
  const fileType: string[] = [];
  const fileSize: string[] = [];
  let totalFileSize = 0;
  files.forEach((fileDetails) => {
    fileType.push(fileDetails.type);
    fileSize.push(`${bytesToMegaBytes(fileDetails.size)}`);
    totalFileSize += bytesToMegaBytes(fileDetails.size);
  });
  return {
    fileType,
    fileSize,
    totalFileSize,
    numFilesUploaded: files.length,
  };
};

export const getBlockTypeForFileUploadAnalytics = (blockType: string) => {
  if (blockType === 'OPEN_ENDED') return 'openEnded';
  else if (blockType === 'FILE_UPLOAD') return 'fileUpload';
  return undefined;
};
