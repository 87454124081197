import { WritableDraft } from 'immer/dist/types/types-external';
import { InfiniteData } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { dropdownOptions as commentsDropdownOptions } from '../../atomic/organism/CommentHeader/data';

import { CommentProps } from '../../atomic/organism/Comment/interfaces';
import { AssemblyCurrency } from '../../interfaces/assembly';
import {
  FeedPostUserProps,
  GetFeedResponse,
  GetCommentByPostResponse,
  FeedItemFromAPI,
  ReactionEmoji,
  UpdateReactionAction,
  FeedSearchPayload,
  MemberInteractionType,
  Reaction,
  FeedCommentFromAPI,
} from '../../interfaces/Feed';
import { getFormattedMessage } from '../../Utils/message';
import { serializeReactions } from '../../Utils/serializers/feed';
import {
  defaultValues,
  UseSearchDropDownValues,
} from '../../atomic/organism/SearchDropdown';
import { getEndDay, getStartDay } from '../../Utils/date';
import { getDropDownOptions, isGifURLPresent } from '../../Utils/posts';
import { Member } from '../Flows/Feed/interfaces';
import { ReactionMember } from '../../interfaces/Feed/Reaction';
import { EmojiData } from 'emoji-mart';

export const findCommentInPostData = (
  pages: WritableDraft<GetCommentByPostResponse>[],
  id: string,
) => {
  let commentIndex;
  const commentFoundPage = pages.find((page) => {
    commentIndex = page.data.findIndex((comment) => comment.commentID === id);
    return commentIndex !== -1;
  });
  if (commentIndex !== undefined && commentFoundPage) {
    return commentFoundPage.data[commentIndex];
  }
  return undefined;
};

export const findInPaginatedFeedData = (
  pages: WritableDraft<GetFeedResponse>[],
  id: string,
) => {
  let postIndex;
  if (!pages) {
    return undefined;
  }
  const postFoundPage = pages.find((page) => {
    postIndex = page.data.findIndex(
      (post) => post.postID === id || post.flowResponse?.responseId === id,
    );
    return postIndex !== -1;
  });

  if (postIndex !== undefined && postFoundPage) {
    return postFoundPage.data[postIndex];
  }
  return undefined;
};

export const getCommentPropsFromResponse = (
  commentsPaginatedData: InfiniteData<GetCommentByPostResponse> | undefined,
  currentUserId: string,
  isCurrentUserAdmin: boolean,
  canDelete: boolean,
  onMemberClick: (member: FeedPostUserProps | MemberInteractionType) => void,
  assemblyCurrency: AssemblyCurrency | undefined,
  onDeleteCommentClick: (
    commentId: string,
    commentHasTrophies: boolean,
    deleteCommentCarrotOption: string,
  ) => void,
  onCopyCommentLink: (commentId: string) => void,
  onCommentEmoticonClick: (
    reactions: Reaction[],
  ) => (emoji: EmojiData, contentID: string) => void,
  onCommentReactionToggleClick: (
    { reaction, active }: Reaction,
    contentID: string,
  ) => void,
): CommentProps[] => {
  const commentProps: CommentProps[] = [];
  if (commentsPaginatedData) {
    commentsPaginatedData.pages.forEach((page) => {
      page.data.forEach((commentFromResponse) => {
        const {
          commentID,
          fromMember,
          pointsEach: trophies,
          message: commentMessageFromApi,
          taggedUsers: commentTaggedUsers,
          createdAt: commentCreatedAt,
          gifURL: commentGifURL,
          imageURL: commentImageURL,
          version,
        } = commentFromResponse;
        const commentDropdownOptions = getDropDownOptions(
          commentsDropdownOptions,
          isCurrentUserAdmin,
          isGifURLPresent(commentGifURL),
          fromMember.memberID === currentUserId || canDelete,
        );
        const messageText = getFormattedMessage(
          commentMessageFromApi,
          commentTaggedUsers,
          onMemberClick,
        );
        const serializedReactions = serializeReactions(
          commentFromResponse,
          currentUserId,
        );
        const commentProp: CommentProps = {
          commentId: commentID,
          contentId: commentID,
          from: fromMember,
          trophies: trophies > 0 ? trophies : undefined,
          hasTrophies: trophies > 0,
          assemblyCurrency,
          messageText,
          reactions: serializedReactions,
          createdAt: commentCreatedAt,
          currentUserId,
          dropdownOptions: commentDropdownOptions,
          onCopyLink: onCopyCommentLink,
          onDeleteCommentClick,
          onClickUserName: onMemberClick,
          imageUrl: commentGifURL || commentImageURL,
          onEmoticonSelect: onCommentEmoticonClick(serializedReactions),
          onReactionToggleClick: onCommentReactionToggleClick,
          isLoading: commentID === 'OPTIMISTIC',
          version,
        };
        commentProps.push(commentProp);
      });
    });
  }
  return commentProps
    .filter((comment) => comment.version !== 2)
    .sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
};

export const updatePostReactionsInDraft = (
  updatedPost: WritableDraft<FeedItemFromAPI>,
  payload: ReactionEmoji,
  action: UpdateReactionAction,
  userData: ReactionMember,
) => {
  const reactionIndex = updatedPost.reactions.findIndex(
    (reaction) => reaction.name === payload.name,
  );
  // IF API CALL IS SETTING AN EMOTICON
  if (action === UpdateReactionAction.SET) {
    // IF THE SELECTED EMOTICON IS NOT ALREADY PRESENT
    if (reactionIndex < 0) {
      updatedPost.reactions.push({
        ...payload,
        members: [{ ...userData }],
      });
    } else {
      const hasMemberAlreadyReacted = updatedPost.reactions[
        reactionIndex
      ].members.some((member) => member.memberID === userData.memberID);

      if (!hasMemberAlreadyReacted) {
        updatedPost.reactions[reactionIndex].members.push({
          ...userData,
        });
      }
    }
  } else {
    // IF API CALL IS UNSETTING AN EMOTICON
    const currentReaction = updatedPost.reactions[reactionIndex];
    if (currentReaction.members.length > 1) {
      // IF EMOTICON HAS OTHER PEOPLE WHO HAVE SELECTED IT
      currentReaction.members = currentReaction.members.filter(
        (member) => member.memberID !== userData.memberID,
      );
    } else {
      const hasMemberAlreadyReacted = currentReaction.members.some(
        (member) => member.memberID === userData.memberID,
      );
      // IF USER IS THE ONLY PERSON WHO HAD SELECTED IT
      if (hasMemberAlreadyReacted) {
        updatedPost.reactions.splice(reactionIndex, 1);
      }
    }
  }
};

export const updateFlowPostReactionsInDraft = (
  updatedPost: WritableDraft<FeedItemFromAPI>,
  payload: ReactionEmoji,
  action: UpdateReactionAction,
  userData: ReactionMember,
) => {
  if (!updatedPost.flowResponse) {
    return;
  }
  const reactionIndex = updatedPost.flowResponse.reactions.findIndex(
    (reaction) => reaction.name === payload.name,
  );
  // IF API CALL IS SETTING AN EMOTICON
  if (action === UpdateReactionAction.SET) {
    // IF THE SELECTED EMOTICON IS NOT ALREADY PRESENT
    const member = { ...userData } as Member;
    if (reactionIndex < 0) {
      updatedPost.flowResponse.reactions.push({
        ...payload,
        members: [{ ...member }],
      });
    } else {
      // IF THE SELECTED EMOTICON IS PRESENT
      const hasMemberAlreadyReacted = updatedPost.flowResponse.reactions[
        reactionIndex
      ].members.some((mem) => mem.memberID === userData.memberID);
      if (!hasMemberAlreadyReacted) {
        updatedPost.flowResponse.reactions[reactionIndex].members.push({
          ...member,
        });
      }
    }
  } else {
    // IF API CALL IS UNSETTING AN EMOTICON
    const currentReaction = updatedPost.flowResponse.reactions[reactionIndex];

    if (currentReaction.members.length > 1) {
      // IF EMOTICON HAS OTHER PEOPLE WHO HAVE SELECTED IT
      currentReaction.members = currentReaction.members.filter(
        (member) => member.memberID !== userData.memberID,
      );
    } else {
      const hasMemberAlreadyReacted = currentReaction.members.some(
        (member) => member.memberID === userData.memberID,
      );
      // IF USER IS THE ONLY PERSON WHO HAD SELECTED IT
      if (hasMemberAlreadyReacted) {
        updatedPost.flowResponse.reactions.splice(reactionIndex, 1);
      }
    }
  }
};

export const updateCommentReactionsInDraft = (
  updatedComment: WritableDraft<FeedCommentFromAPI>,
  payload: ReactionEmoji,
  action: UpdateReactionAction,
  userData: ReactionMember,
) => {
  const reactionIndex = updatedComment.reactions.findIndex(
    (reaction) => reaction.name === payload.name,
  );
  // IF API CALL IS SETTING AN EMOTICON
  if (action === UpdateReactionAction.SET) {
    // IF THE SELECTED EMOTICON IS NOT ALREADY PRESENT
    if (reactionIndex < 0) {
      updatedComment.reactions = [
        ...updatedComment.reactions,
        {
          ...payload,
          members: [
            {
              ...userData,
            },
          ],
        },
      ];
    } else {
      // IF THE SELECTED EMOTICON IS PRESENT
      const hasMemberAlreadyReacted = updatedComment.reactions[
        reactionIndex
      ].members.some((member) => member.memberID === userData.memberID);
      if (!hasMemberAlreadyReacted) {
        updatedComment.reactions[reactionIndex].members.push({
          ...userData,
        });
      }
    }
  } else {
    // IF API CALL IS UNSETTING AN EMOTICON
    const currentReaction = updatedComment.reactions[reactionIndex];
    if (currentReaction.members.length > 1) {
      // IF EMOTICON HAS OTHER PEOPLE WHO HAVE SELECTED IT
      currentReaction.members = currentReaction.members.filter(
        (member) => member.memberID !== userData.memberID,
      );
    } else {
      const hasMemberAlreadyReacted = currentReaction.members.some(
        (member) => member.memberID === userData.memberID,
      );
      // IF USER IS THE ONLY PERSON WHO HAD SELECTED IT - REMOVE THE EMOTICON
      if (hasMemberAlreadyReacted) {
        updatedComment.reactions.splice(reactionIndex, 1);
      }
    }
  }
};

export const isFeedSearchEmpty = (search: UseSearchDropDownValues) =>
  isEqual(search, defaultValues);

export const getSearchPayload = (values: UseSearchDropDownValues) => {
  const search: FeedSearchPayload = {};
  if (values.from.length > 0) {
    const fromValue = values.from.map(({ id }) => id);
    search.from = fromValue;
  }
  if (values.to.length > 0) {
    const toValue = values.to.map(({ id }) => id);
    search.to = toValue;
  }
  if (values.keywords.length > 0) {
    search.keywords = values.keywords;
  }
  if (values.currencyMin && values.currencyMin > 0) {
    search.currencyMin = values.currencyMin;
  }
  if (values.currencyMax && values.currencyMax > 0) {
    search.currencyMax = values.currencyMax;
  }
  if (values.startDate) {
    search.startDate = getStartDay(values.startDate);
  }
  if (values.endDate) {
    search.endDate = getEndDay(values.endDate);
  }

  return search;
};
