import React from 'react';
import InviteModal from '../../atomic/organism/InviteModal';
import useInviteModalController from './useInviteModalController';

interface InviteModalControllerProps {
  isModalOpen: boolean;
  isFullScreen?: boolean;
  closeInviteModal: () => void;
}

const InviteModalController = (props: InviteModalControllerProps) => {
  const { isModalOpen, closeInviteModal, isFullScreen } = props;
  const {
    emails,
    textFieldValue,
    handleEmailsChange,
    handleCloseInviteModal,
    onInviteClick,
    onCopyInvite,
    setTextFieldValue,
    validation,
    isInviteRequiresApproval,
    canShowCopyInviteLink,
  } = useInviteModalController({
    closeInviteModal,
  });
  return (
    <InviteModal
      isOpen={isModalOpen}
      emails={emails}
      textFieldValue={textFieldValue}
      onClose={handleCloseInviteModal}
      handleEmailsChange={handleEmailsChange}
      onInviteClick={onInviteClick}
      onCopyInvite={onCopyInvite}
      setTextFieldValue={setTextFieldValue}
      validation={validation}
      isInviteRequiresApproval={isInviteRequiresApproval}
      canShowCopyInviteLink={canShowCopyInviteLink}
      isFullScreen={isFullScreen}
    />
  );
};

export default InviteModalController;
