import React from 'react';
import { shallow } from 'zustand/shallow';
import Button from '../../../../atomic/atoms/Button';
import DateFilter from '../../../../atomic/organism/DateFilter';
import DrawerHeaderWithIcon from '../../../../atomic/molecules/DrawerHeaderWithIcon';
import CheckboxFilter from '../../../../atomic/organism/CheckboxFilter';
import {
  StyledFilterWrapper,
  StyledSpaceSeparator,
  StyledButton,
  StyledFlex,
  LoadingWrapper,
} from '../styles';

import {
  CheckboxFilterEmptyProps,
  CheckboxFilterLoadingProps,
} from '../../CheckboxFilter/types';
import { DateFilterLoadingProps } from '../../DateFilter/types';

import {
  FILTER_BY,
  APPLY_FILTERS,
  UPLOADED_BY,
} from '../../../../languages/en/flows';
import { CLEAR } from '../../../../languages/en/singleWords';

import { ComponentStatus } from '../../../../interfaces/component';
import useLayoutStore from '../../../../stores/layoutStore';
import useKioskStore from '../../../../stores/kioskStore';
import { DATE_SHARED, FILE_TYPE } from '../../../../languages/en/flows';
import { LayoutStoreState } from '../../../../interfaces/Layout';
import { SHARED_IN } from '../../../../languages/en/feed';

type KnowledgeCenterFilterSkeletonTypes = {
  status: ComponentStatus.LOADING | ComponentStatus.EMPTY;
};

export const KnowledgeCenterFilterSkeleton = (
  props: KnowledgeCenterFilterSkeletonTypes,
) => {
  const { status } = props;
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const layoutSelector = (state: LayoutStoreState) => ({
    isRightAsideOpen: state.isRightAsideOpen,
    toggleRightAsideOpen: state.toggleRightAsideOpen,
  });
  const { toggleRightAsideOpen, isRightAsideOpen } = useLayoutStore(
    layoutSelector,
    shallow,
  );

  const dateFilterProps: DateFilterLoadingProps | CheckboxFilterEmptyProps = {
    status: status,
    headingText: DATE_SHARED,
  };
  const flowFilterProps: CheckboxFilterLoadingProps | CheckboxFilterEmptyProps =
    {
      status: status,
      headingText: SHARED_IN,
    };

  const postedByFilterProps:
    | CheckboxFilterLoadingProps
    | CheckboxFilterEmptyProps = {
    status: status,
    headingText: UPLOADED_BY,
  };

  const fileTypeFilterProps:
    | CheckboxFilterLoadingProps
    | CheckboxFilterEmptyProps = {
    status: status,
    headingText: FILE_TYPE,
  };

  return (
    <LoadingWrapper flexDirection="column" justifyContent="space-between">
      <div style={{ width: '100%' }}>
        <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
          {FILTER_BY}
        </DrawerHeaderWithIcon>
        <StyledFilterWrapper>
          <CheckboxFilter {...fileTypeFilterProps} />
          <StyledSpaceSeparator />
          <DateFilter {...dateFilterProps} />
          <StyledSpaceSeparator />
          <CheckboxFilter {...flowFilterProps} />
          <StyledSpaceSeparator />
          <CheckboxFilter {...postedByFilterProps} />
          <StyledSpaceSeparator />
        </StyledFilterWrapper>
      </div>
      <StyledFlex
        isKioskTurnedOn={isKioskTurnedOn}
        isRightAsideOpen={isRightAsideOpen}
      >
        <StyledButton
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          isLoading={status === ComponentStatus.LOADING}
          disabled={status === ComponentStatus.EMPTY}
        >
          {APPLY_FILTERS}
        </StyledButton>
        <Button
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          variant="text"
        >
          {CLEAR}
        </Button>
      </StyledFlex>
    </LoadingWrapper>
  );
};
