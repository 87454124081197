import styled from 'styled-components';
import TabComponent from '../../../atomic/molecules/TabComponent';

export const StyledTabComponent = styled(TabComponent)`
  button {
    &.MuiTab-root {
      flex-direction: row-reverse;
    }
  }
`;
