import styled from 'styled-components';

import GeneralSettingsAccordion from '../../atomic/organism/GeneralSettingsAccordion';

import useSettingsControllerLogic from './useSettingsControllerLogic';
import useSettingsControllerModel from './useSettingsControllerModel';

import LoadingPulse from '../../atomic/atoms/LoadingPulse';
import ManageAccount from './ManageAccount';
import { DeactivateAccountModal } from './DeactivateAccountModal';
import {
  AccordionHeader,
  AccordionHeaderInner,
  AccordionRoot,
} from '../../atomic/molecules/AccordionItem/styles';
import Body from '../../atomic/atoms/Body';
import ToolTip from '../../atomic/molecules/ToolTipV2';
import {
  DisableAPIKeyButton,
  StyledBodyDiv,
  StyledClickable,
  StyledRightDiv,
} from './styles';
import { useCallback, useState } from 'react';
import IconButton from '../../atomic/atoms/IconButton_V2';
import { ButtonSize } from '../../atomic/atoms/IconButton_V2/interface';
import { showSuccessMessage } from '../../Utils/toast';
import { COPIED_TO_CLIPBOARD } from '../../languages/en/flows';
import Button from '../../atomic/atoms/Button';
import { copyToClipboard } from '../../Utils/common';

const SettingsControllerWrapper = styled.div`
  max-width: 642px;
  margin: 0 auto;
`;

const SettingsController = () => {
  const [showAuthKey, setShowAuthKey] = useState(false);
  const {
    models: {
      workSpaceName,
      currentUserInfo,
      canShowSaveChangesButton,
      deactivatedMemberProps,
      authKey,
      isExternalAuthKeyLoading,
      isDeactivatingZapierApiKey,
    },
    operations: {
      deactivateMember,
      updateUserSettings,
      updateProfilePictureSettings,
      onGenerateAPIKeyClick,
      onDeactivateAPIClick,
    },
    metadata: { isCurrentUserInfoLoading },
  } = useSettingsControllerModel();

  const {
    models: {
      settingsAvatarProps,
      settingsEditNameProps,
      settingsPronounsProps,
      settingsEditJobTitleProps,
      settingsV2EditJobTitleProps,
      settingsEditLocationProps,
      settingsV2EditLocationProps,
      settingsEditWorkLocationProps,
      settingsHandleProps,
      settingsEmailProps,
      settingsDepartmentProps,
      settingsTimezoneProps,
      settingsManagerProps,
      settingsReportsProps,
      settingsStartDateProps,
      settingsBirthdayProps,
      settingsAboutMeProps,
      settingsSocialProfilesProps,
      managerPlaceholder,
      reportsPlaceholder,
      birthDayPlaceholder,
      startDatePlaceholder,
      aboutMePlaceholder,
      socialProfilesPlaceholder,
      accordionItems,
      isMobileApp,
      isManageAccountCollapseOpen,
      isDeactivateAccountModalOpen,
    },
    operations: {
      handleCollapse,
      toggleManageAccountCollapse,
      handleDeactivateAccountClick,
      handleDeactivateAccountModalClose,
    },
  } = useSettingsControllerLogic(
    updateUserSettings,
    updateProfilePictureSettings,
    currentUserInfo,
    deactivatedMemberProps,
  );

  const onCopyAuthKeyClick = useCallback(() => {
    copyToClipboard(authKey);
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  }, [authKey]);

  const onWhatsThisForClick = useCallback(() => {
    window.open(
      'https://www.joinassembly.com/articles/zapier-for-assembly',
      '_blank',
      'noopener, noreferrer',
    );
  }, []);

  const renderAPIKey = useCallback(() => {
    if (isExternalAuthKeyLoading) {
      return (
        <Body variant="body2" color="gray9" margin="0 0 0 8px">
          Loading...
        </Body>
      );
    }

    if (showAuthKey) {
      return (
        <>
          <Body variant="body2" color="gray9" margin="0 0 0 8px">
            {authKey.slice(0, 12)}...
          </Body>
          <IconButton
            icon="copy"
            title="Copy Auth Key"
            data-test-id="copy-auth-key"
            size={ButtonSize.Small}
            onClick={onCopyAuthKeyClick}
          />
          <DisableAPIKeyButton
            onClickCallback={() => {
              setShowAuthKey(false);
              onDeactivateAPIClick();
            }}
            disabled={isDeactivatingZapierApiKey}
          >
            {isDeactivatingZapierApiKey
              ? 'Deactivating...'
              : 'Deactivate API Key'}
          </DisableAPIKeyButton>
        </>
      );
    }

    if (authKey) {
      return (
        <>
          <StyledClickable
            onClickCallback={() => {
              setShowAuthKey(true);
            }}
          >
            Show API Key
          </StyledClickable>
          <DisableAPIKeyButton
            onClickCallback={() => {
              onDeactivateAPIClick();
            }}
            disabled={isDeactivatingZapierApiKey}
          >
            {isDeactivatingZapierApiKey
              ? 'Deactivating...'
              : 'Deactivate API Key'}
          </DisableAPIKeyButton>
        </>
      );
    } else {
      return (
        <StyledClickable onClickCallback={onGenerateAPIKeyClick}>
          Generate an API Key
        </StyledClickable>
      );
    }
  }, [
    authKey,
    isDeactivatingZapierApiKey,
    isExternalAuthKeyLoading,
    onCopyAuthKeyClick,
    onDeactivateAPIClick,
    onGenerateAPIKeyClick,
    showAuthKey,
  ]);

  if (isCurrentUserInfoLoading) {
    return <LoadingPulse isLoading />;
  }

  return (
    <SettingsControllerWrapper>
      <GeneralSettingsAccordion
        settingsAvatarProps={settingsAvatarProps}
        settingsEditNameProps={settingsEditNameProps}
        settingsPronounsProps={settingsPronounsProps}
        settingsEditJobTitleProps={settingsEditJobTitleProps}
        settingsEditLocationProps={settingsEditLocationProps}
        settingsV2EditJobTitleProps={settingsV2EditJobTitleProps}
        settingsV2EditLocationProps={settingsV2EditLocationProps}
        settingsEditWorkLocationProps={settingsEditWorkLocationProps}
        settingsHandleProps={settingsHandleProps}
        settingsEmailProps={settingsEmailProps}
        settingsDepartmentProps={settingsDepartmentProps}
        settingsTimezoneProps={settingsTimezoneProps}
        settingsManagerProps={settingsManagerProps}
        settingsReportsProps={settingsReportsProps}
        settingsStartDateProps={settingsStartDateProps}
        settingsBirthdayProps={settingsBirthdayProps}
        settingsAboutMeProps={settingsAboutMeProps}
        settingsSocialProfilesProps={settingsSocialProfilesProps}
        managerPlaceholder={managerPlaceholder}
        reportsPlaceholder={reportsPlaceholder}
        birthDayPlaceholder={birthDayPlaceholder}
        startDatePlaceholder={startDatePlaceholder}
        aboutMePlaceholder={aboutMePlaceholder}
        socialProfilesPlaceholder={socialProfilesPlaceholder}
        accordionItems={accordionItems}
        handleCollapse={handleCollapse}
        canShowSaveChangesButton={canShowSaveChangesButton}
      />
      {isMobileApp && (
        <ManageAccount
          workSpaceName={workSpaceName}
          onDeactivateAccountClick={handleDeactivateAccountClick}
          isManageAccountCollapseOpen={isManageAccountCollapseOpen}
          toggleManageAccountCollapse={toggleManageAccountCollapse}
        />
      )}
      <DeactivateAccountModal
        onDeactivateAccountClick={() => {
          deactivateMember();
        }}
        isOpen={isDeactivateAccountModalOpen}
        onClose={handleDeactivateAccountModalClose}
        isLoading={deactivatedMemberProps.isDeactivatingMember}
      />
      <AccordionRoot style={{ marginBottom: 42 }}>
        <AccordionHeader>
          <AccordionHeaderInner>
            <Body variant="body1Bold">Account Information</Body>
          </AccordionHeaderInner>
        </AccordionHeader>
        <StyledBodyDiv>
          <Body variant="body2Bold" color="gray9" margin="0 5px 0 0">
            API Key
          </Body>
          <ToolTip
            iconSize="16px"
            icon="question-mark-outlined"
            iconColor="White"
          >
            <div style={{ width: 200 }}>
              Use this API Key to connect your Assembly account to other apps
              and webhooks - including Zapier and the 5,000+ apps they offer.
            </div>
          </ToolTip>
          {renderAPIKey()}
          <StyledRightDiv>
            <Button
              variant="text"
              color="grayTertiary"
              onClick={onWhatsThisForClick}
            >
              {`What's this for?`}
            </Button>
          </StyledRightDiv>
        </StyledBodyDiv>
      </AccordionRoot>
    </SettingsControllerWrapper>
  );
};

export default SettingsController;
