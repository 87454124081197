import { useEffect } from 'react';
import { MenuItemIndividualItem } from '../../../../atomic/molecules/Dropdown_V2/interfaces';
import { LinkAccessSettings } from '../../../../atomic/molecules/ShareSheet/LinkAccessSettings';
import { ResponseAnonymitySettings } from '../../../../atomic/molecules/ShareSheet/ResponseAnonymitySettings';
import { ShareSheetModalScreen, ShareSheetRuleAccessTypes } from '../types';
import { ShareSheetRulesController } from './ShareSheetRulesController/ShareSheetRulesController';
import { SHARE_SHEET_EVENTS } from '../../../../Utils/analytics/constants';
import { trackShareSheetShowEvent } from '../../../../Utils/analytics/shareSheet';

type ShareSheetModalControllerProps = {
  isMobileView: boolean;
  visibilityTitleContent?: string;
  subtitleContent?: string;
  linkAccessType: string;
  anonymousSettings: MenuItemIndividualItem;
  handleChooseParticipants: () => void;
  handleChooseViewers: () => void;
  updateModalCurrentState: (currentState: ShareSheetModalScreen) => void;
  selectedLinkPermissionRole: string;
  shareSheetAccessType: ShareSheetRuleAccessTypes;
  handleShareSheetAccessTypeChange: (
    accessType: ShareSheetRuleAccessTypes,
  ) => void;
  isSimplifiedShareSheetTreatmentOn: boolean;
  handleOnModifiedChanges: (hasChanged: boolean) => void;
  handleMembersToBeInvited: (members: string[]) => void;
  handleSelectedTabIndexChange: (index: number) => void;
};

export const ShareSheetModalController = (
  props: ShareSheetModalControllerProps,
) => {
  const {
    isMobileView,
    visibilityTitleContent,
    subtitleContent,
    anonymousSettings,
    handleChooseParticipants,
    handleChooseViewers,
    updateModalCurrentState,
    linkAccessType,
    selectedLinkPermissionRole,
    shareSheetAccessType,
    handleShareSheetAccessTypeChange,
    isSimplifiedShareSheetTreatmentOn,
    handleOnModifiedChanges,
    handleMembersToBeInvited,
    handleSelectedTabIndexChange,
  } = props;

  useEffect(() => {
    trackShareSheetShowEvent({
      tab: 'invite and share',
      show: SHARE_SHEET_EVENTS.SHARE_SHEET_SHOW,
      ruleType: shareSheetAccessType,
    });
  }, [shareSheetAccessType]);

  return (
    <>
      <ShareSheetRulesController
        visibilityTitleContent={visibilityTitleContent}
        subtitleContent={subtitleContent}
        handleChooseParticipants={handleChooseParticipants}
        handleChooseViewers={handleChooseViewers}
        shareSheetAccessType={shareSheetAccessType}
        handleShareSheetAccessTypeChange={handleShareSheetAccessTypeChange}
        isSimplifiedShareSheetTreatmentOn={isSimplifiedShareSheetTreatmentOn}
        handleOnModifiedChanges={handleOnModifiedChanges}
        handleMembersToBeInvited={handleMembersToBeInvited}
        handlePreviewButtonClick={() =>
          updateModalCurrentState(ShareSheetModalScreen.PREVIEW_ACCESS_SETTINGS)
        }
        handleSelectedTabIndexChange={handleSelectedTabIndexChange}
      />
      <LinkAccessSettings
        selectedLinkPermissionRole={selectedLinkPermissionRole}
        linkAccessType={linkAccessType}
        isMobileView={isMobileView}
        updateModalCurrentState={updateModalCurrentState}
        isSimplifiedShareSheetTreatmentOn={isSimplifiedShareSheetTreatmentOn}
      />
      <ResponseAnonymitySettings
        isMobileView={isMobileView}
        anonymousSettings={anonymousSettings}
        updateModalCurrentState={updateModalCurrentState}
        isSimplifiedShareSheetTreatmentOn={isSimplifiedShareSheetTreatmentOn}
      />
    </>
  );
};
