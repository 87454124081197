import TextareaAutosize from '@mui/material/TextareaAutosize';
import styled from 'styled-components';
import ThemeV2 from '../../../theme';

export const SentenceInput = styled(TextareaAutosize)<{
  required: boolean;
  isPlaceHolder: boolean;
}>`
  padding: 4px 0;
  border: none;
  font-family: inherit;
  font-size: inherit;
  vertical-align: top;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  background: ${ThemeV2.palette.white};
  ${(props) => props.isPlaceHolder && `color: ${ThemeV2.palette.gray6}`};
  resize: none;

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    content: attr(placeholder);
    color: ${ThemeV2.palette.gray6};
  }

  &::after {
    content: '*';
    color: ${(props) =>
      props.required ? ThemeV2.palette.red6 : ThemeV2.palette.white};
  }
`;

export const InputWrapper = styled.div`
  padding: 4px 0;
  display: inline-block;
  min-width: 2em;
  min-height: 24px;
  margin-bottom: 0px;
  width: 100%;
`;

export const WidthControl = styled.span<{
  required: boolean;
  isPlaceHolder: boolean;
}>`
  ${(props) => props.isPlaceHolder && `color: ${ThemeV2.palette.gray6}`};

  &::after {
    content: '*';
    color: ${(props) =>
      props.required ? ThemeV2.palette.red6 : ThemeV2.palette.white};
  }
`;
