import {
  NotebookSocketData,
  NotebookTaskSocketPayload,
  NotebookSectionUpdateSocketPayload,
  NotebookSectionDeleteSocketPayload,
  NotebookSectionalTaskUpdateSocketPayload,
} from './types';

export const getTaskSocketData = (
  socketEventData: NotebookSocketData,
): NotebookTaskSocketPayload | null => {
  switch (socketEventData.type) {
    case 'TASK_CREATED':
    case 'TASK_DELETED':
    case 'TASK_UPDATED':
      return socketEventData.data as NotebookTaskSocketPayload;

    default:
      return null;
  }
};

export const getSectionDeleteSocketData = (
  socketEventData: NotebookSocketData,
): NotebookSectionDeleteSocketPayload | null => {
  switch (socketEventData.type) {
    case 'TASK_SECTION_DELETED':
      return socketEventData.data as NotebookSectionDeleteSocketPayload;

    default:
      return null;
  }
};

export const getSectionUpdateSocketData = (
  socketEventData: NotebookSocketData,
): NotebookSectionUpdateSocketPayload | null => {
  switch (socketEventData.type) {
    case 'TASK_SECTION_UPDATE':
      return socketEventData.data as NotebookSectionUpdateSocketPayload;

    default:
      return null;
  }
};

export const getSectionalTaskUpdateSocketData = (
  socketEventData: NotebookSocketData,
): NotebookSectionalTaskUpdateSocketPayload | null => {
  switch (socketEventData.type) {
    case 'TASK_MOVED_BETWEEN_SECTION':
      return socketEventData.data as NotebookSectionalTaskUpdateSocketPayload;

    default:
      return null;
  }
};
