import styled from 'styled-components';

import SpinningLoader from '../../atoms/SpinnerLoader';
import ThemeV2 from '../../../theme';

export const ButtonWrapper = styled.button<{
  $isLoading: boolean;
  $isDeleted?: boolean;
}>`
  background: ${ThemeV2.palette.gray3};
  border: 0;
  border-radius: 4px;
  cursor: ${({ $isDeleted }) => ($isDeleted ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  outline: 0;
  padding: 0px 4px;
  margin: 2px auto;
  word-break: break-word;

  &:disabled {
    background-color: ${ThemeV2.palette.gray3};
    color: inherit;
    border: 0;
    pointer-events: none;
    cursor: not-allowed;
    opacity: ${({ $isLoading }) => $isLoading && '0.5'};
  }

  &:hover,
  &:active {
    background-color: ${({ $isDeleted }) =>
      $isDeleted ? ThemeV2.palette.gray3 : ThemeV2.palette.gray4};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconWrapper = styled.div`
  margin: 4px 5.333px 0px 0px;
`;

export const LoadingIndicator = styled(SpinningLoader)`
  display: inline-flex;
  margin-bottom: -5px;
`;
