import { FileViewerProps } from '.';
import disconnectedCat from '../../../img/disconnected-cat.svg';
import { REFRESH_PAGE, RETRY, SOMETHING_WENT_WRONG } from './languages/en';
import EmptyFilePreview from './shared/EmptyFilePreview';

const PreviewError = (props: FileViewerProps) => {
  const { onRetry } = props;

  return (
    <EmptyFilePreview
      cta={RETRY}
      imgSrc={disconnectedCat}
      onClick={onRetry}
      subtitle={REFRESH_PAGE}
      title={SOMETHING_WENT_WRONG}
    />
  );
};

export default PreviewError;
