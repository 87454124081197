import React from 'react';

import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../../theme';

export const StatsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="120px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4px" ry="4px" width="33%" height="56px" />
      <rect x="35%" y="0" rx="4px" ry="4px" width="33%" height="56px" />
      <rect x="70%" y="0" rx="4px" ry="4px" width="33%" height="56px" />

      <rect x="0" y="70px" rx="4px" ry="4px" width="100%" height="56px" />
    </ContentLoader>
  );
};

export const TrendsLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="340px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4px" ry="4px" width="100%" height="331px" />
    </ContentLoader>
  );
};

export const WidgetLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="340px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4px" ry="4px" width="301px" height="20px" />
      <rect x="0" y="36px" rx="4px" ry="4px" width="218px" height="20px" />

      <rect x="0" y="70px" rx="4px" ry="4px" width="100%" height="331px" />
    </ContentLoader>
  );
};
