import { useCallback, useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { generateV2UserProfileRoute } from '../../constants/routes';
import useHistoryWrapper from '../../hooks/useHistoryWrapper';
import { IMemberDTO } from '../../interfaces/member';
import { COPIED_TO_CLIPBOARD } from '../../languages/en/flows';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useLayoutStore from '../../stores/layoutStore';
import { PEOPLE_DIRECTORY_EVENTS } from '../../Utils/analytics/constants';
import { trackPeopleDirectoryActionEvent } from '../../Utils/analytics/peopleDirectory';
import { showSuccessMessage } from '../../Utils/toast';
import usePeopleListController from '../home/PeopleListController/usePeopleListController';
import { copyToClipboard } from '../../Utils/common';

export const usePeopleDirectory = () => {
  const [search, setSearchValue] = useState('');
  const {
    infiniteMembers,
    hasMoreMembersToFetch,
    fetchMoreInfiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    searchedMembers,
    isMembersSearchLoading,
    isMembersFetchingError,
  } = usePeopleListController({ limit: 50, searchLimit: 25 });
  const {
    isRightAsideOpen,
    setSelectedMember,
    setRightAsideOpenToTrue,
    setShowChats,
    showChats,
    setRightAsideOpenToFalse,
  } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      setSelectedMember: state.setSelectedMember,
      setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
      setShowChats: state.setShowChats,
      showChats: state.showChats,
      setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
    }),
    shallow,
  );

  useEffect(() => {
    if (isRightAsideOpen) {
      setRightAsideOpenToFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onMembersSearchChange(search);
  }, [onMembersSearchChange, search]);

  const history = useHistoryWrapper();
  const { data: ownProfileData } = useProfileInfoFetchQuery();
  const slugUrl = `${ownProfileData?.assembly.workspaceSlug.name}-${ownProfileData?.assembly.workspaceSlug.shortCode}`;
  const { isEmbeddedInMainApp } = useLayoutStore();

  const handleOnCopyProfileLink = useCallback(
    (user: IMemberDTO) => {
      trackPeopleDirectoryActionEvent({
        action: PEOPLE_DIRECTORY_EVENTS.PROFILE_LINK_COPIED,
      });
      const id = user.memberID;
      if (id) {
        const url = `/${slugUrl}${generateV2UserProfileRoute(id)}`;

        copyToClipboard(
          `${window.location.origin}${isEmbeddedInMainApp ? `/a${url}` : url}`,
        );
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    },
    [isEmbeddedInMainApp, slugUrl],
  );

  const handleMessagePersonClick = useCallback(
    (user: IMemberDTO) => {
      trackPeopleDirectoryActionEvent({
        action: PEOPLE_DIRECTORY_EVENTS.SEND_MESSAGE_CLICKED,
      });
      setSelectedMember({
        memberId: user?.memberID,
        firstName: user?.firstName,
      });
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
      setShowChats(true);
    },
    [
      isRightAsideOpen,
      setRightAsideOpenToTrue,
      setSelectedMember,
      setShowChats,
    ],
  );

  const handleSeePersonDetailsClick = useCallback(
    (user: IMemberDTO) => {
      trackPeopleDirectoryActionEvent({
        action: PEOPLE_DIRECTORY_EVENTS.VIEW_PROFILE_CLICKED,
      });
      if (showChats) {
        setShowChats(false);
      }
      setSelectedMember({
        memberId: user?.memberID,
        firstName: user?.firstName,
      });
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
    },
    [
      isRightAsideOpen,
      setRightAsideOpenToTrue,
      setSelectedMember,
      setShowChats,
      showChats,
    ],
  );

  const handleViewActivityClick = (user) => {
    trackPeopleDirectoryActionEvent({
      action: PEOPLE_DIRECTORY_EVENTS.VIEW_PROFILE_FEED_CLICKED,
    });
    history.replace(`${generateV2UserProfileRoute(user.memberID)}`);
  };

  const handleItemClick = (value: string | number, user: IMemberDTO) => {
    if (value.toString().includes('Copy')) {
      handleOnCopyProfileLink(user);
    } else if (value.toString().includes('Message')) {
      handleMessagePersonClick(user);
    } else if (value.toString().includes('See')) {
      handleSeePersonDetailsClick(user);
    } else {
      handleViewActivityClick(user);
    }
  };

  return {
    data: infiniteMembers,
    hasMoreMembersToFetch,
    fetchMoreInfiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    setSearchValue,
    searchedMembers,
    isMembersSearchLoading,
    isMembersFetchingError,
    handleItemClick,
  };
};
