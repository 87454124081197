import React, { forwardRef, useCallback, useMemo } from 'react';
import LoadingCircle from '../LoadingCircle';
import SVGIcon from '../SVGIcon';
import { buttonColors, FontWeight, sizeVariant } from './buttonConfigs';
import { ButtonProps } from './interfaces';
import {
  LoadingDot,
  ButtonWrapper,
  LoadingWrapper,
  ButtonTextWrapper,
  StyledAssemblyIcon,
  ButtonText,
  ButtonTextInner,
  DropdownCaret,
  IconWrapper,
  SuffixAssemblyIcon,
} from './styles';

type Ref = HTMLButtonElement;
const Button = forwardRef<Ref, ButtonProps>((props: ButtonProps, ref) => {
  const [buttonFocused, setButtonFocus] = React.useState(false);
  const {
    type = 'button',
    children,
    value = '',
    disabled = false,
    onClick,
    className: parentClassName,
    icon,
    font = 'body1',
    color = 'primary',
    variant = 'contained',
    status = 'default',
    size = 'medium',
    padding,
    isLoading = false,
    loadingText,
    onMouseUp,
    onMouseDown,
    dataTestId,
    dataQaId,
    isFullWidth = false,
    isEndIcon = false,
    enableCaret = false,
    caretOpen = false,
    assemblyCurrency,
    'aria-describedby': ariaDescribedby,
    caretIconSize,
    hasBoxShadow = false,
    disabledButtonTextColor,
    isUnstyled = false,
    customCaretIcon,
  } = props;
  const loaderColor = buttonColors[color][variant][status].textColor;
  const loadingPlaceholder = (
    <>
      {loadingText}
      <LoadingDot>.</LoadingDot>
      <LoadingDot>.</LoadingDot>
      <LoadingDot>.</LoadingDot>
    </>
  );
  const handleMouseDown = useCallback(() => {
    if (onMouseDown) {
      onMouseDown();
    }
    setButtonFocus(true);
  }, [onMouseDown]);

  const handleMouseUp = useCallback(() => {
    if (onMouseUp) {
      onMouseUp();
    }
    setButtonFocus(false);
  }, [onMouseUp]);

  const handleMouseLeave = useCallback(() => {
    setButtonFocus(false);
  }, []);

  const iconWrapperArgs = useMemo(() => {
    return {
      disabled: isLoading || disabled,
      size,
      padding,
      color,
      variant,
      status,
      icon,
      isEndIcon,
    };
  }, [
    color,
    disabled,
    icon,
    isEndIcon,
    isLoading,
    padding,
    size,
    status,
    variant,
  ]);

  const fontWeight = useMemo(() => {
    if (isUnstyled) return undefined;
    return !isUnstyled ||
      variant === 'text' ||
      color === 'blueTertiary' ||
      color === 'grayTertiary'
      ? FontWeight.REGULAR
      : FontWeight.SEMIBOLD;
  }, [color, isUnstyled, variant]);

  return (
    <ButtonWrapper
      type={type}
      className={`${parentClassName} ${buttonFocused && 'button-focused'}`}
      disabled={isLoading || disabled}
      onClick={onClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      size={size}
      padding={padding}
      color={color}
      variant={variant}
      status={status}
      isFullWidth={isFullWidth}
      isLoading={isLoading}
      ref={ref}
      aria-describedby={ariaDescribedby}
      hasBoxShadow={hasBoxShadow}
      disabledButtonTextColor={disabledButtonTextColor}
      data-testid={`${dataTestId}-button`}
      data-qa-id={`${dataQaId}`}
      isUnstyled={isUnstyled}
    >
      {variant !== 'text' && isLoading && (
        <LoadingWrapper>
          <LoadingCircle size={16} stroke={2} color={loaderColor} />
        </LoadingWrapper>
      )}
      <ButtonTextWrapper
        variant={variant}
        isLoading={isLoading}
        enableCaret={enableCaret}
      >
        {icon && !isEndIcon && (
          <IconWrapper {...iconWrapperArgs}>
            <SVGIcon icon={icon} size="16px" />
          </IconWrapper>
        )}
        {assemblyCurrency && !isEndIcon && (
          <StyledAssemblyIcon
            assemblyCurrency={assemblyCurrency}
            id="id"
            currencyName="currencyName"
            size="14px"
            dataTestId="currency"
          />
        )}
        <ButtonText
          fontWeight={fontWeight}
          variant={font !== 'body1' ? font : sizeVariant[size].font}
          color="inherit"
          data-testid={dataTestId}
          data-qa-id={`btn-${dataTestId}`}
        >
          <ButtonTextInner>
            {isLoading && loadingText ? loadingPlaceholder : children || value}
          </ButtonTextInner>
          {assemblyCurrency && isEndIcon && (
            <SuffixAssemblyIcon
              assemblyCurrency={assemblyCurrency}
              id="id"
              currencyName="currencyName"
              size="14px"
              dataTestId="currency"
            />
          )}
          {enableCaret && (
            <DropdownCaret
              color={color}
              variant={variant}
              status={status}
              disabled={isLoading || disabled}
              isUnstyled={isUnstyled}
            >
              <SVGIcon
                icon={customCaretIcon ? customCaretIcon : 'triangle-down'}
                size={caretIconSize ? caretIconSize : '20px'}
                flip={caretOpen}
              />
            </DropdownCaret>
          )}
        </ButtonText>
        {isEndIcon && icon && (
          <IconWrapper {...iconWrapperArgs}>
            <SVGIcon icon={icon} size="16px" />
          </IconWrapper>
        )}
      </ButtonTextWrapper>
    </ButtonWrapper>
  );
});
Button.displayName = 'Button';

const MemoizedButton = React.memo(Button);
MemoizedButton.displayName = 'Button';

export default MemoizedButton;
