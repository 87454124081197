import styled from 'styled-components';

import Accordion from '../../../molecules/Accordion';
import Menu from '../../../molecules/Menu';
import MenuList from '../../../molecules/MenuItem';
import MenuListLoader from '../../../molecules/MenuItem/Loader';

import { ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import useDropDown from '../../../../hooks/useDropDown';
import { ComponentStatus } from '../../../../interfaces/component';
import Body from '../../../atoms/Body';
import { DropdownPlacement } from '../../../molecules/Dropdown_V2/interfaces';
import { FolderTitleStyle } from '../../../molecules/ExpandableListHolder/types';
import { LeftDrawerCollapsibleNavItemProps } from '../../../molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { LinkToUnreadsSection } from '../../LeftNav/LinkToUnreadsSection';
import { StyledPrefixIcon } from '../../LeftNav/styles';
import {
  FlowsMenuAccordionLoadedProps,
  FlowsMenuAccordionProps,
} from './types';

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledMenu = styled(Menu)`
  padding-right: 16px;
`;

const StyledAccordion = styled(Accordion)`
  overflow: hidden;
  white-space: nowrap;
`;

const StyledMenuList = styled(MenuList.Item)`
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
`;

type MenuAccordionHeaderProps = {
  title: string;
  isAccordionOpen: boolean;
  totalNotificationsCount?: number;
  isExpanded: boolean;
  disabled?: boolean;
  titleStyle?: FolderTitleStyle;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
};

const AccordionHeader = ({
  title,
  isAccordionOpen,
  totalNotificationsCount,
  isExpanded,
  disabled,
  titleStyle,
  prefixComponent,
  suffixComponent,
}: MenuAccordionHeaderProps) => (
  <StyledMenu
    text={title}
    rotate={isAccordionOpen}
    count={totalNotificationsCount}
    showCount={!isAccordionOpen}
    isExpanded={isExpanded}
    prefixComponent={prefixComponent}
    disabled={disabled}
    textBgColor={titleStyle?.bgColor}
    suffixComponent={suffixComponent}
  />
);

type LisItemProps = Omit<FlowsMenuAccordionLoadedProps, 'navItems'> & {
  navItem: LeftDrawerCollapsibleNavItemProps;
};

const ListItem = (props: LisItemProps) => {
  const {
    onMenuItemClick,
    dropdownMenuItems,
    onDropdownMenuItemClick,
    navItem,
    onMenuItemRightClick,
    isHover,
    disabled,
  } = props;

  const { anchorProps } = useDropDown({
    menuItems: dropdownMenuItems || [],
    onItemClick: onDropdownMenuItemClick,
  });

  const { referenceElement, anchorEl, toggleDropdown } = anchorProps;

  const textStyle = navItem.isUnread ? 'body1Bold' : 'body1';
  let textColor = 'gray8';
  if (navItem.isMuted) {
    textColor = 'gray6';
  }
  if (navItem.isUnread) {
    textColor = 'gray9';
  }

  return (
    <StyledMenuListItem
      key={navItem.id}
      isSelected={navItem.isActive}
      onClick={() => {
        if (!disabled) onMenuItemClick(navItem.id);
      }}
      onRightClick={(e) => {
        e.preventDefault();
        toggleDropdown();
        if (onMenuItemRightClick && !disabled) {
          onMenuItemRightClick(navItem);
        }
      }}
      refElement={referenceElement}
      anchorEl={anchorEl}
      isExpanded={isHover}
      disabled={disabled}
      folderColor={navItem.folderInfo?.color}
    >
      <MenuList.Emoticon emoticon={navItem.emoticon} />
      <MenuList.Text variant={textStyle} color={textColor}>
        {navItem.label}
      </MenuList.Text>
      {navItem.isUnread ? (
        <MenuList.SuffixElement>
          <Body variant="body3" color="gray1">
            {navItem.badgeText}
          </Body>
        </MenuList.SuffixElement>
      ) : (
        <></>
      )}
      <MenuList.DropdownListHolder
        {...anchorProps}
        dropdownPlacement={DropdownPlacement.BottomEnd}
      />
    </StyledMenuListItem>
  );
};

const Container = styled.div`
  padding: 16px 0;
`;

const FlowsMenuAccordionLoaded = (props: FlowsMenuAccordionLoadedProps) => {
  const {
    accordionTitle,
    isAccordionOpen = false,
    onAccordionClick,
    navItems,
    children,
    totalNotificationsCount,
    onMouseEnter,
    onMouseLeave,
    isHover = false,
    disabled,
    userMenuItems,
    onUserMenuItemClick,
    titleStyle,
    headerPrefixComponent,
    headerSuffixComponent,
    hasDraggableElements = false,
    droppablePlaceholder,
  } = props;

  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledAccordion
        isAccordionOpen={isAccordionOpen}
        accordionHeader={
          <AccordionHeader
            title={accordionTitle}
            isAccordionOpen={isAccordionOpen}
            totalNotificationsCount={totalNotificationsCount}
            isExpanded={isHover}
            disabled={disabled}
            titleStyle={titleStyle}
            prefixComponent={headerPrefixComponent}
            suffixComponent={headerSuffixComponent}
          />
        }
        onAccordionClick={() => {
          if (!disabled) onAccordionClick();
        }}
        disabled={disabled}
      >
        <MenuList>
          <>
            {navItems.map((menuItem, index) =>
              hasDraggableElements ? (
                <Draggable
                  draggableId={menuItem.id || Math.random.toString()}
                  index={index || 0}
                  disableInteractiveElementBlocking
                  key={menuItem.id}
                  isDragDisabled={menuItem.id === 'recognition'}
                >
                  {(dragContext) => (
                    <div
                      {...dragContext.draggableProps}
                      {...dragContext.dragHandleProps}
                      ref={dragContext.innerRef}
                    >
                      <ListItem
                        key={menuItem.id}
                        navItem={menuItem}
                        {...props}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </Draggable>
              ) : (
                <ListItem
                  key={menuItem.id}
                  navItem={menuItem}
                  {...props}
                  disabled={disabled}
                />
              ),
            )}
            {droppablePlaceholder ? droppablePlaceholder : null}
          </>
        </MenuList>
        {children}
        {props.hasUnreadItems && <LinkToUnreadsSection />}

        {userMenuItems && onUserMenuItemClick && (
          <MenuList>
            {userMenuItems.map((menuItem) => (
              <StyledMenuList
                key={menuItem.memberId}
                onClick={() => onUserMenuItemClick(menuItem)}
              >
                <MenuList.Avatar
                  size="24px"
                  userId={menuItem.memberId}
                  img={menuItem.imageUrl || undefined}
                  name={`${menuItem.firstName} ${menuItem.lastName}`}
                />

                <MenuList.Text
                  variant="body1Bold"
                  style={{ opacity: '1' }}
                >{`${menuItem.firstName} ${menuItem.lastName}`}</MenuList.Text>

                <MenuList.SuffixElement>
                  <Body variant="body3" color="gray1">
                    {menuItem.count}
                  </Body>
                </MenuList.SuffixElement>
              </StyledMenuList>
            ))}
          </MenuList>
        )}
      </StyledAccordion>
    </Container>
  );
};

const FlowsMenuAccordionLoading = (props: FlowsMenuAccordionProps) => {
  const {
    accordionTitle,
    isAccordionOpen,
    onAccordionClick,
    isHover = false,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const Header = () => {
    return (
      <Menu
        text={accordionTitle}
        rotate={isAccordionOpen}
        isExpanded={isHover}
        prefixComponent={<StyledPrefixIcon icon="routine" size="24px" />}
      />
    );
  };
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <StyledAccordion
        isAccordionOpen={isAccordionOpen}
        accordionHeader={<Header />}
        onAccordionClick={onAccordionClick}
      >
        <div style={{ padding: '0 12px 0 24px' }}>
          <MenuListLoader isExpanded={isHover} />
        </div>
      </StyledAccordion>
    </div>
  );
};

const FlowsMenuAccordion = (props: FlowsMenuAccordionProps) => {
  const { status } = props;
  switch (status) {
    case ComponentStatus.LOADED:
      return <FlowsMenuAccordionLoaded {...props} />;
    case ComponentStatus.LOADING:
      return <FlowsMenuAccordionLoading {...props} />;
    default:
      return <div>Empty</div>;
  }
};

export default FlowsMenuAccordion;
