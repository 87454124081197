import { SocialProfiles } from '../Settings';
import { MemberState } from '../user';

export interface AssemblyFromAPI {
  assemblyId: string;
  domain: string;
  logo: string;
  name: string;
}

export interface MemberFromAPI {
  email: string;
  reportIds: string[];
  managerIds: string[];
  memberId: string;
  totalPointsEarned: number;
  pointsEarned: number;
  totalPointsGiven: number;
  role: string[];
  profile: {
    birthday: BirthdayFromAPI;
    department: string;
    firstName: string;
    hiredday: HiredDayFromAPI;
    image: string;
    isManager: boolean;
    lastName: string;
    timezone: string;
    username: string;
    jobTitle: string;
    location: string;
    workLocation?: string;
    pronouns?: string;
    aboutMe?: string;
    socialProfiles?: SocialProfiles;
  };
  timeZone?: string;
  state?: MemberState;
}

export interface BirthdayFromAPI {
  day?: number;
  month?: number;
  isVisibleAndCelebrated?: boolean;
}
export interface HiredDayFromAPI {
  day?: number;
  month?: number;
  year?: number;
}

export enum AssemblyPlanType {
  BUSINESS = 'Business',
  FREE = 'Free',
  TEAM = 'Team',
  ENTERPRISE = 'Enterprise',
  STARTER = 'Starter',
}

export interface AllowList {
  assemblyId: string;
  name: string;
  logo: string;
  workspaceSlug: WorkspaceSlug;
  totalMembers?: number;
  createdAt: string;
  userId?: string;
  inviteToken?: string;
}

export interface WorkspaceSlug {
  name: string;
  shortCode: string;
}
