import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Body from '../../../atomic/atoms/Body';
import ShareSheetAnonymousSettings from '../../../atomic/molecules/ShareSheet/ShareSheetAnonymousSettings';

import BasicModalTemplate, {
  BasicModalTemplateProps,
} from '../../../atomic/templates/BasicModalTemplate';

import {
  COPY_LINK,
  COPY_LINK_TO_SHARE,
  GET_HELP,
} from '../../../languages/en/flows';
import { BACK, CANCEL, CONFIRM, DONE } from '../../../languages/en/singleWords';
import {
  ANONYMITY_LINK_SETTINGS_BANNER_TEXT,
  ANYONE_WITH_LINK,
  SHARE_SHEET_CONFIRMATION_MODAL,
} from '../../../languages/en/flows/sharesheet';
import ThemeV2 from '../../../theme';

import {
  GET_HELP_LINK,
  SHARE_SHEET_MODAL,
} from '../../../languages/en/flows/builder';

import {
  ShareSheetModalButtonProps,
  ShareSheetModalScreen,
  ShareSheetModalTabIndex,
  ShareSheetRuleAccessTypes,
} from './types';

import useFlowsShareSheetController from './useFlowsShareSheetController';
import LinkAccessSettingsController from './LinkAccessSettingsController';
import ShareSheetVisibilityController from './ShareSheetVisibilityController';
import ShareSheetParticipantsController from './ShareSheetParticipantsController';
import { anonymousSettingsMenuItems } from './ShareSheetModalController/data';
import SharingOptionsModal from '../../../atomic/organism/SharingOptionsModal';
import ConnectionsController from './ConnectionsController';
import { ShareSheetModalController } from './ShareSheetModalController';
import { ShareSheetAccessPreviewController } from './ShareSheetAccessPreviewController';
import useConnectionsController from './ConnectionsController/useConnectionsController';
import useRequestedUsersForAccessStore from '../../../stores/sharesheetStore';

type ModalProps = Omit<BasicModalTemplateProps, 'children' | 'onModalClose'>;

const FlowsShareSheetController = () => {
  const {
    setRequestedUsers,
    setRequestedUsersCount,
    setUpdatedRequestedUserID,
  } = useRequestedUsersForAccessStore();
  const {
    flowName,
    isMobileView,
    subtitleContent,
    visibilityCount,
    participantsCount,
    showInDrawerValue,
    setSelectedLinkAccessType,
    selectedLinkAccessType,
    selectedLinkPermissionRole,
    currentModalScreen,
    isShareSheetModalOpen,
    visibilityTitleContent,
    isConfirmationModalOpen,
    currentVisibilityBlockData,
    isUpdatingFlowAccessPolicy,
    currentParticipantsBlockData,
    visibilityParticipantsCriteria,
    handleOnUpdateSelectionClick,
    handleOnLinkSaveButtonClick,
    handleOnLinkBackButtonClick,
    anonymousSettings,
    handleAnonymousSettingsSelection,
    handleChooseViewers,
    handleBackButtonClick,
    handleCopyFlowFeedLink,
    setShareSheetModalOpen,
    handleChooseParticipants,
    handleConfirmButtonClick,
    handleOnDoneButtonClicked,
    setSelectedLinkPermission,
    updateParticipantsBlockData,
    handleConfirmationModalClose,
    handleOnShareSheetModalClose,
    handleVisibilityBackButtonClick,
    updateCurrentVisibilityBlockData,
    handleVisibilityConfirmButtonClick,
    updateModalCurrentState,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnFlowVisibleInFlowShareSheetChanged,
    shareSheetAccessType,
    handleShareSheetAccessTypeChange,
    handleOnModifiedChanges,
    handleMembersToBeInvited,
    isMemberInviteInProgress,
    isSwitchingModalOpen,
    setIsSwitchingModalClose,
    changeShareSheetAccessTypeChange,
    changeShareSheetAccessTypeText,
    changeShareSheetAccessTypeBoldText,
  } = useFlowsShareSheetController();

  const [selectedTabIndex, setSelectedTabIndex] = useState(
    ShareSheetModalTabIndex.InviteAndShare,
  );
  const { search } = useLocation();

  const connectionsController = useConnectionsController();

  const renderModalContent = useCallback(() => {
    if (
      currentParticipantsBlockData &&
      currentModalScreen === ShareSheetModalScreen.PARTICIPANTS
    ) {
      return (
        <ShareSheetParticipantsController
          variant={isMobileView ? 'mobileView' : 'default'}
          participantsBlockData={currentParticipantsBlockData}
          updateParticipantsBlockData={updateParticipantsBlockData}
        />
      );
    } else if (currentModalScreen === ShareSheetModalScreen.VIEWERS) {
      return (
        <ShareSheetVisibilityController
          variant={isMobileView ? 'mobileView' : 'default'}
          visibilityBlockData={currentVisibilityBlockData}
          updateVisibilityParticipantsBlockData={
            updateCurrentVisibilityBlockData
          }
          visibilityParticipantsCriteria={visibilityParticipantsCriteria}
        />
      );
    } else if (
      currentModalScreen === ShareSheetModalScreen.ANONYMOUS_SETTINGS
    ) {
      return (
        <ShareSheetAnonymousSettings
          title={SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.TITLE}
          subTitle={SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.SUBTITLE}
          options={anonymousSettingsMenuItems}
          handleOptionSelection={handleAnonymousSettingsSelection}
          selectedOption={anonymousSettings}
          infoAlert={
            selectedLinkAccessType === ANYONE_WITH_LINK
              ? {
                  icon: 'info-outlined',
                  alertType: 'custom',
                  text: (
                    <Body variant="body3" color="gray9" margin="2px 0 0 0">
                      {ANONYMITY_LINK_SETTINGS_BANNER_TEXT}
                    </Body>
                  ),
                  customVariant: {
                    background: ThemeV2.palette.geekBlue2,
                    iconColor: ThemeV2.palette.geekBlue6,
                  },
                }
              : undefined
          }
        />
      );
    } else if (
      currentModalScreen === ShareSheetModalScreen.LINK_ACCESS_SETTINGS
    ) {
      return (
        <LinkAccessSettingsController
          variant={isMobileView ? 'mobileView' : 'default'}
          isFlowVisibleInFlowSheet={showInDrawerValue}
          onChangeLinkAccess={(e: string | number) => {
            setSelectedLinkAccessType(e.toString());
          }}
          onChangeLinkPermission={(e: string | number) =>
            setSelectedLinkPermission(e.toString())
          }
          viewersCount={visibilityCount}
          participantsCount={participantsCount}
          selectedLinkAccess={selectedLinkAccessType}
          selectedLinkPermission={selectedLinkPermissionRole}
          onUpdateSelectionClick={handleOnUpdateSelectionClick}
          onFlowVisibleInFlowSheetChanged={
            handleOnFlowVisibleInFlowShareSheetChanged
          }
        />
      );
    } else if (
      currentModalScreen === ShareSheetModalScreen.PREVIEW_ACCESS_SETTINGS
    ) {
      return <ShareSheetAccessPreviewController />;
    }
    return (
      <ShareSheetModalController
        isMobileView={isMobileView}
        selectedLinkPermissionRole={selectedLinkPermissionRole}
        subtitleContent={subtitleContent}
        visibilityTitleContent={visibilityTitleContent}
        handleChooseParticipants={handleChooseParticipants}
        handleChooseViewers={handleChooseViewers}
        updateModalCurrentState={updateModalCurrentState}
        linkAccessType={selectedLinkAccessType}
        anonymousSettings={anonymousSettings}
        shareSheetAccessType={shareSheetAccessType}
        handleShareSheetAccessTypeChange={handleShareSheetAccessTypeChange}
        isSimplifiedShareSheetTreatmentOn={true}
        handleOnModifiedChanges={handleOnModifiedChanges}
        handleMembersToBeInvited={handleMembersToBeInvited}
        handleSelectedTabIndexChange={setSelectedTabIndex}
      />
    );
  }, [
    setSelectedTabIndex,
    currentParticipantsBlockData,
    currentModalScreen,
    isMobileView,
    selectedLinkPermissionRole,
    subtitleContent,
    visibilityTitleContent,
    handleChooseParticipants,
    handleChooseViewers,
    updateModalCurrentState,
    selectedLinkAccessType,
    anonymousSettings,
    shareSheetAccessType,
    handleShareSheetAccessTypeChange,
    handleOnModifiedChanges,
    handleMembersToBeInvited,
    updateParticipantsBlockData,
    currentVisibilityBlockData,
    updateCurrentVisibilityBlockData,
    visibilityParticipantsCriteria,
    handleAnonymousSettingsSelection,
    showInDrawerValue,
    visibilityCount,
    participantsCount,
    handleOnUpdateSelectionClick,
    handleOnFlowVisibleInFlowShareSheetChanged,
    setSelectedLinkAccessType,
    setSelectedLinkPermission,
  ]);

  const getModalButtonProps = useCallback(
    (
      onPrimaryButtonClick: () => void,
      onSecondaryButtonClick: () => void,
      onBackButtonClick: () => void,
    ) =>
      ({
        primaryButton: {
          text: CONFIRM,
          onClick: onPrimaryButtonClick,
          size: 'medium',
        },
        secondaryButton: {
          text: CANCEL,
          onClick: onSecondaryButtonClick,
          variant: 'text',
          color: 'secondary',
          size: 'medium',
        },
        headerButton: {
          text: BACK,
          onClick: onBackButtonClick,
          variant: 'text',
          size: 'medium',
          color: 'secondary',
          icon: 'arrow-left',
        },
      } as ShareSheetModalButtonProps),
    [],
  );

  const getModalProps: ModalProps = useMemo(() => {
    const baseProps = {
      childrenPadding: '24px 32px',
      isOpen: isShareSheetModalOpen,
    };
    if (currentModalScreen === ShareSheetModalScreen.PARTICIPANTS) {
      return {
        ...baseProps,
        childrenPadding: '0',
        heading: SHARE_SHEET_MODAL.PARTICIPANTS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleConfirmButtonClick,
          handleBackButtonClick,
          handleBackButtonClick,
        ),
      };
    } else if (currentModalScreen === ShareSheetModalScreen.VIEWERS) {
      return {
        ...baseProps,
        childrenPadding: '0',
        heading: SHARE_SHEET_MODAL.VIEWERS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleVisibilityConfirmButtonClick,
          handleVisibilityBackButtonClick,
          handleVisibilityBackButtonClick,
        ),
      };
    } else if (
      currentModalScreen === ShareSheetModalScreen.ANONYMOUS_SETTINGS
    ) {
      return {
        ...baseProps,
        heading: SHARE_SHEET_MODAL.ANONYMOUS_SETTINGS_BLOCK.MODAL_TITLE,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleAnonymitySettingsConfirmButtonClick,
          handleBackButtonClick,
          handleBackButtonClick,
        ),
        tertiaryButton: {
          text: GET_HELP,
          onClick: () =>
            window.open(GET_HELP_LINK, '_blank', 'noreferrer, noopener'),
          variant: 'text',
          size: 'medium',
        },
      };
    } else if (
      currentModalScreen === ShareSheetModalScreen.LINK_ACCESS_SETTINGS
    ) {
      return {
        ...baseProps,
        heading: SHARE_SHEET_MODAL.LINK_SETTINGS_BLOCK.MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleOnLinkSaveButtonClick,
          handleOnLinkBackButtonClick,
          handleOnLinkBackButtonClick,
        ),
        secondaryButton: {
          text: COPY_LINK,
          icon: 'Link',
          onClick: handleCopyFlowFeedLink,
          variant: 'text',
          color: 'primary',
          size: 'medium',
        },
        tertiaryButton: {
          text: GET_HELP,
          onClick: () =>
            window.open(GET_HELP_LINK, '_blank', 'noreferrer, noopener'),
          variant: 'text',
          size: 'medium',
        },
      };
    } else if (
      currentModalScreen === ShareSheetModalScreen.PREVIEW_ACCESS_SETTINGS
    ) {
      return {
        ...baseProps,
        heading: SHARE_SHEET_MODAL.PREVIEW_MODAL_HEADING,
        hideCloseIcon: true,
        ...getModalButtonProps(
          handleOnLinkSaveButtonClick,
          handleOnLinkBackButtonClick,
          handleOnLinkBackButtonClick,
        ),
        primaryButton: undefined,
        secondaryButton: undefined,
        tertiaryButton: undefined,
      };
    }
    return {
      ...baseProps,
      childrenPadding: '24px 32px 8px 32px',
      heading: `${SHARE_SHEET_MODAL.HEADING} ${flowName}`,
      primaryButton: {
        text: DONE,
        disabled:
          selectedTabIndex === ShareSheetModalTabIndex.Connections
            ? connectionsController.isLoadingChannels
            : false,
        isLoading: isUpdatingFlowAccessPolicy || isMemberInviteInProgress,
        onClick: handleOnDoneButtonClicked,
        size: 'medium',
      },
      secondaryButton: {
        text: COPY_LINK_TO_SHARE,
        icon: 'Link',
        onClick: handleCopyFlowFeedLink,
        variant: 'outlined',
        color: 'primary',
        size: 'medium',
      },
      tertiaryButton: {
        text: GET_HELP,
        onClick: () =>
          window.open(GET_HELP_LINK, '_blank', 'noreferrer, noopener'),
        variant: 'text',
        size: 'medium',
      },
    };
  }, [
    isShareSheetModalOpen,
    currentModalScreen,
    flowName,
    selectedTabIndex,
    connectionsController.isLoadingChannels,
    isUpdatingFlowAccessPolicy,
    isMemberInviteInProgress,
    handleOnDoneButtonClicked,
    handleCopyFlowFeedLink,
    getModalButtonProps,
    handleConfirmButtonClick,
    handleBackButtonClick,
    handleVisibilityConfirmButtonClick,
    handleVisibilityBackButtonClick,
    handleAnonymitySettingsConfirmButtonClick,
    handleOnLinkSaveButtonClick,
    handleOnLinkBackButtonClick,
  ]);

  useEffect(() => {
    if (search.includes('tab=connection')) {
      setSelectedTabIndex(1);
      setShareSheetModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BasicModalTemplate
        isOpen={isConfirmationModalOpen}
        heading={SHARE_SHEET_CONFIRMATION_MODAL.HEADING}
        primaryButton={{
          text: `${SHARE_SHEET_CONFIRMATION_MODAL.SAVE_CHANGES_LABEL}`,
          onClick: handleOnDoneButtonClicked,
        }}
        secondaryButton={{
          text: `${SHARE_SHEET_CONFIRMATION_MODAL.DISCARD_CHANGES_LABEL}`,
          onClick: () => {
            /* Reset the state of the share sheet Request status to the initial state */
            setRequestedUsers([]);
            setRequestedUsersCount(0);
            setUpdatedRequestedUserID('');
            setShareSheetModalOpen(false);
          },
          color: 'secondary',
          variant: 'contained',
          status: 'warning',
        }}
        onModalClose={handleConfirmationModalClose}
        disableContentScroll
      >
        <Body variant="body2">{SHARE_SHEET_CONFIRMATION_MODAL.BODY_TEXT}</Body>
      </BasicModalTemplate>
      (
      <BasicModalTemplate
        isOpen={isSwitchingModalOpen}
        heading="Switching sharing type"
        primaryButton={{
          text:
            shareSheetAccessType === ShareSheetRuleAccessTypes.Simple
              ? 'Change to advanced rules'
              : 'Change to simple rules',
          onClick: () =>
            changeShareSheetAccessTypeChange(
              shareSheetAccessType === ShareSheetRuleAccessTypes.Simple
                ? ShareSheetRuleAccessTypes.Advance
                : ShareSheetRuleAccessTypes.Simple,
            ),
        }}
        secondaryButton={{
          text: 'Don’t change',
          onClick: setIsSwitchingModalClose,
          color: 'secondary',
          variant: 'contained',
          status: 'warning',
        }}
        onModalClose={setIsSwitchingModalClose}
        disableContentScroll
      >
        <Body inline variant="body2">
          {changeShareSheetAccessTypeText}
        </Body>
        <Body inline variant="body2Medium">
          {changeShareSheetAccessTypeBoldText}
        </Body>
      </BasicModalTemplate>
      ) (
      <SharingOptionsModal
        {...getModalProps}
        screen={currentModalScreen}
        onModalClose={handleOnShareSheetModalClose}
        showHeading={currentModalScreen !== ShareSheetModalScreen.HOME}
        selectedTabIndex={selectedTabIndex}
        handleSelectedTabIndexChange={setSelectedTabIndex}
      >
        <SharingOptionsModal.InviteAndShareTab>
          {renderModalContent()}
        </SharingOptionsModal.InviteAndShareTab>
        <SharingOptionsModal.ConnectionsTab>
          <ConnectionsController {...connectionsController} />
        </SharingOptionsModal.ConnectionsTab>
      </SharingOptionsModal>
      )
    </>
  );
};

export default FlowsShareSheetController;
