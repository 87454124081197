import { useQuery } from '@tanstack/react-query';
import { makeAPICallWithDataReturn } from '../../utils';
import { GetFlowOccurrenceDetailsResponse } from '../interfaces';
import { GET_FLOWS_OCCURRENCE_DETAILS } from '../../../constants/endpoints';

export const useGetOccurrencesForFlowIdQuery = (flowId: string) => {
  return useQuery<GetFlowOccurrenceDetailsResponse>(
    [GET_FLOWS_OCCURRENCE_DETAILS, flowId],
    () =>
      makeAPICallWithDataReturn(
        GET_FLOWS_OCCURRENCE_DETAILS,
        undefined,
        undefined,
        {
          flowId,
        },
      ),
    {
      staleTime: Infinity,
      retry: 0,
    },
  );
};
