import React, { useCallback, useEffect, useMemo } from 'react';
import useDropDown from '../../../hooks/useDropDown';
import Button from '../../atoms/Button';
import DropdownListHolder, {
  DropdownProps,
  DropdownWrapper,
} from '../DropdownListHolder';
import { ButtonDropdownProps, DropdownPlacement } from './interfaces';

const Dropdown = (props: ButtonDropdownProps & DropdownProps) => {
  const {
    value,
    color,
    size,
    variant,
    icon,
    className,
    onItemClick,
    getItemLabel,
    dropdownPlacement = DropdownPlacement.Bottom,
    isDropdownPositionFixed,
    onButtonClick = () => {},
    onFocus,
    caretIconSize,
    hasBoxShadow = false,
    enableCaret = true,
    onToggle,
    padding,
    customCaretIcon,
    isUnstyled = false,
    font,
  } = props;

  const { anchorProps } = useDropDown(props);
  const {
    id,
    referenceElement,
    isOpen,
    toggleDropdown,
    isFullWidth,
    disabled,
    anchorEl,
  } = anchorProps;

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen, onToggle]);

  const onClick = useCallback(() => {
    onButtonClick(isOpen);
    toggleDropdown();
  }, [isOpen, onButtonClick, toggleDropdown]);

  const renderValue = useMemo(() => {
    return getItemLabel && value ? getItemLabel(value) : value;
  }, [getItemLabel, value]);

  return (
    <DropdownWrapper
      ref={referenceElement}
      isFullWidth={isFullWidth}
      className={className}
      onFocus={onFocus}
    >
      <Button
        font={font}
        variant={variant}
        disabled={disabled}
        isFullWidth={isFullWidth}
        color={color}
        size={size}
        onClick={onClick}
        enableCaret={enableCaret}
        customCaretIcon={customCaretIcon}
        caretOpen={!disabled && isOpen}
        isEndIcon
        icon={icon}
        aria-describedby={id}
        ref={anchorEl}
        dataTestId="dropdown"
        caretIconSize={caretIconSize}
        hasBoxShadow={hasBoxShadow}
        padding={padding}
        isUnstyled={isUnstyled}
      >
        {renderValue}
      </Button>
      <DropdownListHolder
        {...anchorProps}
        dropdownPlacement={dropdownPlacement}
        onItemClick={onItemClick}
        getItemLabel={getItemLabel}
        isDropdownPositionFixed={isDropdownPositionFixed}
      />
    </DropdownWrapper>
  );
};

Dropdown.defaultProps = {
  disabled: false,
  isFullWidth: false,
  color: 'primary',
  size: 'medium',
  variant: 'contained',
  icon: '',
  selectedMenuItemID: null,
  menuItems: [],
  onItemClick: () => {},
  multiSelect: false,
  className: '',
};

const MemoizedDropdown = React.memo(Dropdown);
MemoizedDropdown.displayName = 'Dropdown';
export default MemoizedDropdown;
