import React, { LegacyRef } from 'react';
import './styles/main.scss';

import Loading from './loading';
import TableWrapper from './tableWrapper';

import AudioViewer from './formats/audioViewer';
import DocxViewer from './formats/docxViewer';
import LargeFileViewer from './formats/largeFileViewer';
import PDFViewer from './formats/pdfViewer';
import PhotoViewer from './formats/photoViewer';
import TxtViewer from './formats/txtViewer';
import UnsupportedViewer from './formats/unsupportedViewer';
import VideoViewer from './formats/videoViewer';
import PreviewError from './previewError';
import { useMeasure } from '../../../hooks/useMeasure';

export interface FileViewerProps {
  fileType: string;
  filePath: string;
  zoomPercent?: number;
  setDefaultZoom: (value: number) => void;
  onError?: () => void;
  errorComponent?: React.ReactNode;
  unsupportedComponent?: React.ReactNode;
  responseType?: XMLHttpRequestResponseType;
  SubComponent?: (props: { index: number }) => JSX.Element;
  WrapperComponent?: (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => JSX.Element;
  viewerRef?: LegacyRef<HTMLDivElement> | undefined;
  idPrefix?: string;
  hideLoader?: boolean;
  hasNoPreview?: boolean;
  onDownloadClick?: () => void;
  FooterComponent?: () => JSX.Element;
  isLoading?: boolean;
  hasError?: boolean;
  onRetry?: () => void;
  isUnsupportedFileType?: boolean;
  flowId?: string;
  responseId?: string;
  blockId?: string;
}

const FileViewer = (props: FileViewerProps) => {
  const {
    fileType,
    filePath,
    hasError,
    hasNoPreview,
    isLoading,
    isUnsupportedFileType,
    viewerRef,
  } = props;

  const [ref, { height, width }] = useMeasure();

  const getDriver = (fileExtension: string) => {
    switch (fileExtension) {
      case 'csv':
      case 'xls':
      case 'xlsx': {
        return TableWrapper;
      }
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'bmp':
      case 'png': {
        return PhotoViewer;
      }
      case 'pdf': {
        return PDFViewer;
      }
      case 'docx': {
        return DocxViewer;
      }
      case 'mp3': {
        return AudioViewer;
      }
      case 'webm':
      case 'mp4':
      case 'mov': {
        return VideoViewer;
      }
      case 'txt': {
        return TxtViewer;
      }
      default: {
        return UnsupportedViewer;
      }
    }
  };

  const Driver = getDriver(fileType);

  let content;

  if (hasError) {
    content = <PreviewError {...props} />;
  } else if (isUnsupportedFileType) {
    content = <UnsupportedViewer {...props} />;
  } else if (filePath && !hasNoPreview) {
    content = <Driver {...props} width={width ?? 0} height={height ?? 0} />;
  } else if (hasNoPreview) {
    content = <LargeFileViewer {...props} />;
  } else if (isLoading) {
    content = <Loading />;
  }

  return (
    <div className="pg-viewer-wrapper" ref={viewerRef}>
      <div className="pg-viewer" id="pg-viewer" ref={ref}>
        {content}
      </div>
    </div>
  );
};

export default FileViewer;
