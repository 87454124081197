import React from 'react';

import TriggerConfigureSettings from '../../../../atomic/organism/TriggerConfigureSettings';

import { OnDemandControllerTypes } from './types';
import useOnDemandController from './useOnDemandController';
import ThemeV2 from '../../../../theme';
import Body from '../../../../atomic/atoms/Body';
import styled from 'styled-components';
import IconInfo from '../../../../atomic/molecules/IconInfo';

const InfoBanner = styled(IconInfo)`
  margin-bottom: 8px;
`;

const OnDemandController = (props: OnDemandControllerTypes) => {
  const { models } = useOnDemandController(props);

  const { flowAnswerAnytimeProps, flowDefaultDueDateProps } = models;

  return (
    <div>
      <InfoBanner
        backgroundColor={ThemeV2.palette.geekBlue2}
        icon="info-icon"
        iconColor={ThemeV2.palette.geekBlue6}
        iconSize="16px"
        paddingTopBottom="6px"
        paddingLeftRight="6px"
      >
        <Body variant="body3">
          To notify participants, Assembly will send out an email when you
          trigger the flow.
        </Body>
      </InfoBanner>
      <TriggerConfigureSettings
        triggerType="ONDEMAND"
        flowAnswerAnytimeProps={flowAnswerAnytimeProps}
        flowDefaultDueDateProps={flowDefaultDueDateProps}
      />
    </div>
  );
};

export default OnDemandController;
