import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import Body from '../../../atoms/Body';
import ThemeV2 from '../../../../theme';
import Button from '../../../atoms/Button';
import SVGIcon from '../../../atoms/SVGIcon';
import DropdownIconButton from '../../../molecules/DropdownIconButton';
import { device, MaximumRightContentWidth } from '../../../../constants/layout';

export const Wrapper = styled.div`
  padding: 16px 24px;
  width: 100%;
  overflow: auto;
  height: 100%;

  @media ${device.mobile} {
    height: calc(100% - 64px);
  }
`;

export const Block = styled.div`
  margin-bottom: 12px;
`;

export const BlockTitle = styled(Body)`
  margin: 8px 8px 0 8px;
`;

export const CreatedByText = styled(Body)``;

export const ErrorTitle = styled(Body)`
  margin-top: 20px;
`;
export const ErrorSubtitle = styled(Body)`
  margin-top: 8px;
  text-align: center;
`;

export const Header = styled(Flex)`
  margin-bottom: 8px;
`;

export const Heading = styled(Body)`
  margin-left: 4px;
`;

export const DescriptionPlaceholderText = styled(Body)`
  height: 20px;
  padding: 4px 8px;
  border: 0.5px solid ${ThemeV2.palette.white};
  border-radius: 4px;
  &:hover {
    border: 0.5px solid ${ThemeV2.palette.gray3};
    border-radius: 4px;
  }
`;

export const TextAreaWrapper = styled.div<{
  isDisabled: boolean;
}>`
  border: 0.5px solid ${ThemeV2.palette.white};
  border-radius: 4px;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
`;

export const AssigneeWrapper = styled.div<{
  isDisabled: boolean;
}>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
`;

export const ShowMoreButton = styled(Button)`
  margin-top: 8px;
  & path {
    fill: ${ThemeV2.palette.geekBlue6};
  }
`;

export const Description = styled.div`
  &:hover {
    color: ${ThemeV2.palette.gray9};
    background: ${ThemeV2.palette.gray3};
    border-radius: 4px;
  }
`;

export const StatusWrapper = styled(Flex)`
  background: ${ThemeV2.palette.gray3};
  width: fit-content;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 8px;
  cursor: pointer;
  &:hover {
    background: ${ThemeV2.palette.gray4};
  }
`;

export const IconButtonWrapper = styled.div``;

export const BlockContent = styled.div<{
  disabled?: boolean;
}>`
  margin-left: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const CompleteButton = styled(Flex)`
  width: 100%;
  padding: 6px;
  border: 0.5px solid ${ThemeV2.palette.polarGreen7};
  border-radius: 4px;
  color: ${ThemeV2.palette.polarGreen7};
  background: ${ThemeV2.palette.green1};
  pointer-events: none;
  margin: 8px 0;
`;

export const MarkAsCompleteButton = styled(Flex)<{
  disabled: boolean;
}>`
  width: 100%;
  max-width: ${MaximumRightContentWidth}px;
  padding: 6px;
  border: 0.5px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin: 8px 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  ${({ disabled }) => disabled && `background: ${ThemeV2.palette.gray4};`};

  &:hover {
    background: ${ThemeV2.palette.green1};
    border: 0.5px solid ${ThemeV2.palette.polarGreen7};
    color: ${ThemeV2.palette.polarGreen7};

    svg {
      path {
        stroke: ${ThemeV2.palette.polarGreen7};
      }
      circle {
        stroke: ${ThemeV2.palette.polarGreen7};
      }
    }
  }

  &:active {
    background: ${ThemeV2.palette.green2};
    border: 0.5px solid ${ThemeV2.palette.polarGreen7};
    color: ${ThemeV2.palette.polarGreen7};

    svg {
      path {
        stroke: ${ThemeV2.palette.polarGreen7};
      }
      circle {
        stroke: ${ThemeV2.palette.polarGreen7};
      }
    }
  }
`;

export const Icon = styled(SVGIcon)`
  margin-right: 4px;
`;

export const DropdownIcon = styled(DropdownIconButton)`
  margin-right: 8px;

  button {
    height: 24px;
    width: 24px;
  }
`;
