import React from 'react';
import ContentLoader from 'react-content-loader';
import ThemeV2 from '../../../theme';

import {
  KnowledgeCenterListItemNameWrapper,
  KnowledgeCenterListItemUserWrapper,
  KnowledgeCenterListItemWrapper,
  StyledContentLoader,
} from './styles';

const KnowledgeCenterListItemLoader = (props: {
  style: React.CSSProperties;
}) => {
  const { style } = props;

  return (
    <KnowledgeCenterListItemWrapper style={style}>
      <KnowledgeCenterListItemNameWrapper>
        <ContentLoader
          speed={2}
          width={32}
          height={32}
          viewBox="0 0 32 32"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="knowledge-center-list-loader-preview"
        >
          <rect y="0" width="32" height="32" rx="4" />
          <rect y="0" width="32" height="32" rx="4" />
        </ContentLoader>
        <StyledContentLoader
          speed={2}
          height={24}
          viewBox="0 0 264 24"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="knowledge-center-list-loader-name"
        >
          <rect y="4" x="8" width="212" height="16" rx="4" />
          <rect y="4" x="8" width="212" height="16" rx="4" />
        </StyledContentLoader>
      </KnowledgeCenterListItemNameWrapper>
      <KnowledgeCenterListItemUserWrapper>
        <ContentLoader
          speed={2}
          height={16}
          width={16}
          viewBox="0 0 16 16"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="knowledge-center-list-loader-avatar"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 16.0007C12.4183 16.0007 16 12.419 16 8.00073C16 3.58245 12.4183 0.000732422 8 0.000732422C3.58172 0.000732422 0 3.58245 0 8.00073C0 12.419 3.58172 16.0007 8 16.0007Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            // eslint-disable-next-line max-len
            d="M8 16.0007C12.4183 16.0007 16 12.419 16 8.00073C16 3.58245 12.4183 0.000732422 8 0.000732422C3.58172 0.000732422 0 3.58245 0 8.00073C0 12.419 3.58172 16.0007 8 16.0007Z"
          />
        </ContentLoader>
        <StyledContentLoader
          speed={2}
          height={22}
          viewBox="0 0 116 22"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="knowledge-center-list-loader-user"
        >
          <rect y="2" x="8" width="108" height="12" rx="4" />
          <rect y="2" x="8" width="108" height="12" rx="4" />
        </StyledContentLoader>
      </KnowledgeCenterListItemUserWrapper>
      <KnowledgeCenterListItemUserWrapper>
        <StyledContentLoader
          speed={2}
          height={12}
          viewBox="0 0 142 16"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          data-testid="knowledge-center-list-loader-user"
        >
          <rect y="4" width="138" height="12" rx="4" />
          <rect y="4" width="138" height="12" rx="4" />
        </StyledContentLoader>
      </KnowledgeCenterListItemUserWrapper>
    </KnowledgeCenterListItemWrapper>
  );
};

export default KnowledgeCenterListItemLoader;
