import { persist } from 'zustand/middleware';
import { createStore } from 'zustand/vanilla';

type MobileStoreState = {
  jwtToken: string;
  isMobileApp: boolean;
  isMobileAppV3: boolean;
  setJwtToken: (value: string) => void;
  setIsTurnedOn: (value: boolean) => void;
  setIsMobileAppV3: (value: boolean) => void;
};

const mobileStore = createStore<MobileStoreState>()(
  persist(
    (set) => ({
      jwtToken: '',
      isMobileApp: false,
      isMobileAppV3: false,
      setJwtToken: (value: string) => set(() => ({ jwtToken: value })),
      setIsTurnedOn: (value: boolean) => set(() => ({ isMobileApp: value })),
      setIsMobileAppV3: (value: boolean) =>
        set(() => ({ isMobileAppV3: value })),
    }),
    {
      name: 'mobile-app-store',
    },
  ),
);

export default mobileStore;
