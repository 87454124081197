import {
  checkIfFlowPostHasTrophies,
  getCurrencyFromProfileData,
  getFlowPostUrl,
} from '.';
import ThemeV2 from '../../../theme';

import { BlockProps } from '../../../atomic/organism/FlowPostBody/types';
import { MemberInteractionType } from '../../../interfaces/Feed';
import { PostTypes } from '../../../interfaces/Home';
import { MemberState } from '../../../interfaces/user';
import { COPIED_TO_CLIPBOARD } from '../../../languages/en/flows';
import {
  DropDownBlockResponseType,
  FeedResponse,
  FileUploadResponseType,
  FlowFeedResponse,
  GifBlockResponseType,
  GiveTrophiesBlockResponseType,
  KeyValuePair,
  OpenEndedBlockResponseType,
  PersonSelectorBlockResponseType,
  ScaleBlockResponseType,
} from '../../../queries/Flows/Feed/interfaces';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { copyToClipboard } from '../../common';
import { showSuccessMessage } from '../../toast';
import { getProfileFullName } from '../../user';
import { getFormattedMessage } from '../../message';
import {
  LegacyFileType,
  FileUploadStatus,
} from '../../../atomic/molecules/FileCard/types';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { getTaskCategoryFromAPI } from '../../notebook';

const mapFilesResponseToFiles = (feed: FeedResponse, ImageTypes: string[]) => {
  return feed.response.files.map((file) => {
    if (ImageTypes.includes(file.type) && file.thumbnails) {
      return {
        ...file,
        mimeType: file.type,
        url: file.thumbnails[360],
        type: LegacyFileType.Image,
      };
    }

    return {
      ...file,
      mimeType: file.type,
      type: LegacyFileType.File,
    };
  });
};

export const mapFeedResponseToFeedHeaderProps = (
  data: FlowFeedResponse,
  profile: GetProfileInfoResponse,
  isEditEnabled?: boolean,
) => {
  const hasTrophies = checkIfFlowPostHasTrophies(data);
  const canShowEditOption = isEditEnabled && data.canEdit;
  const getMemberState = () => {
    if (data.respondent?.memberState) {
      if (data.respondent.memberState === 'ACTIVE') {
        return MemberState.ACTIVE;
      } else if (data.respondent.memberState === 'DUMMY') {
        return MemberState.DUMMY;
      } else {
        return MemberState.DEACTIVATED;
      }
    }
  };

  return {
    flow: data.flow,
    hasTrophies,
    showDeleteOption: data.canDelete,
    showEditOption: !!canShowEditOption,
    postType: PostTypes.FLOW,
    createdAt: data.createdAt.toString(),
    person: data.respondent
      ? {
          ...data.respondent,
          isDeleted: false,
          memberState: getMemberState(),
        }
      : null,
    onCopyLink: () => {
      if (data.flow.flowId && data.responseId) {
        // eslint-disable-next-line max-len
        const url = getFlowPostUrl(data.flow.flowId, data.responseId);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    },
    currentUserId: profile.member.memberId,
    visibility: data.isPrivate ? 'private' : data.visibility,
    kind: data?.kind,
    source: data.source,
  };
};

export const mapFeedResponseToFeedBodyProps = (
  feeds: FlowFeedResponse,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  onRepliesLinkClicked?: (param: Record<string, string>) => void,
) => {
  let selectedPersons: number;
  const ImageTypes = ['jpg', 'jpeg', 'png', 'svg', 'gif'];
  const blocks: BlockProps[] = [];
  feeds.responses.forEach((feed) => {
    if (feed.response && feed.response.persons) {
      selectedPersons = feed.response.persons.length;
    }
    if (feed.state === 'SKIPPED') {
      blocks.push({
        id: feed.blockId,
        type: 'NO_RESPONSE',
        isEdited: feed.edited,
        content: {
          title: feed.content.title,
          value:
            feed.type === 'FILE_UPLOAD' && feed.edited
              ? 'File(s) deleted'
              : 'No answer',
        },
      });
      return;
    }
    switch (feed.type) {
      case 'PERSON_SELECTOR':
        blocks.push({
          id: feed.blockId,
          type: 'PERSON_SELECTOR',
          content: {
            ...(feed.content as PersonSelectorBlockResponseType),
            persons: feed.response.persons
              ? feed.response.persons.map((person) => {
                  const isActive =
                    person.memberState !== MemberState.DEACTIVATED;

                  return {
                    id: person.memberId,
                    label: isActive
                      ? getProfileFullName(person.name)
                      : 'Deactivated User',
                    avatar: {
                      userId: person.memberId,
                      name: person.name.firstName,
                      img: person.image,
                      icon:
                        person.memberState === MemberState.PENDING
                          ? 'pending-person'
                          : '',
                      iconColor: ThemeV2.palette.white,
                      isDeleted: !isActive,
                    },
                    clickable: isActive,
                  };
                })
              : [],
          },
          isEdited: feed.edited,
        });
        break;
      case 'GIVE_POINTS_STACK':
        blocks.push({
          id: feed.blockId,
          type: 'GIVE_TROPHIES',
          content: {
            ...(feed.content as GiveTrophiesBlockResponseType),
            content: feed.response.value.toString(),
            emoticon: getCurrencyFromProfileData(profile),
            showEachText: selectedPersons > 1,
          },
          isEdited: feed.edited,
        });
        break;
      case 'MULTI_CHOICE':
      case 'DROPDOWN':
        blocks.push({
          id: feed.blockId,
          type: 'DROPDOWN',
          content: {
            ...(feed.content as DropDownBlockResponseType),
            value: (feed.response.value as KeyValuePair[]).map((x) => x.value),
          },
          isEdited: feed.edited,
        });
        break;
      case 'OPEN_ENDED':
        blocks.push({
          id: feed.blockId,
          type: 'OPEN_ENDED',
          content: {
            ...(feed.content as OpenEndedBlockResponseType),
            gifUrl: feed.response.gifUrl,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
            message: getFormattedMessage(
              feed.response.value as string,
              feed.response.mentions
                ? feed.response.mentions.map((mention) => ({
                    ...mention,
                    memberID: mention.memberId,
                    isDeleted: false,
                  }))
                : [],
              onMemberClick,
              feed.response.tags,
              onRepliesLinkClicked,
              feed.response.tasks?.map((task) => ({
                note: task.title,
                noteId: task.id,
                type: getTaskCategoryFromAPI(
                  task.state,
                  profile.member.timeZone || '',
                  task.dueDate,
                ),
                isDeleted: task.state === 'DELETED',
              })),
            ),
          },
          isEdited: feed.edited,
        });
        break;
      case 'SCALE':
        blocks.push({
          id: feed.blockId,
          type: 'SCALE',
          content: {
            ...(feed.content as ScaleBlockResponseType),
            value: feed.response.value as number,
          },
          isEdited: feed.edited,
        });
        break;
      case 'GIF':
        blocks.push({
          id: feed.blockId,
          type: 'GIF_SELECTOR',
          content: {
            ...(feed.content as GifBlockResponseType),
            src: feed.response.value as string,
          },
          isEdited: feed.edited,
        });
        break;
      case 'FILE_UPLOAD':
        blocks.push({
          id: feed.blockId,
          type: 'FILE_UPLOAD',
          content: {
            ...(feed.content as FileUploadResponseType),
            isParticipation: false,
            status: FileUploadStatus.Success,
            files:
              feed.response.files && mapFilesResponseToFiles(feed, ImageTypes),
          },
          isEdited: feed.edited,
        });
        break;
      default:
        break;
    }
  });
  return blocks;
};

export const mapFeedResponseToReactionProps = (
  data: FlowFeedResponse,
  profile: GetProfileInfoResponse | undefined,
) => {
  const userId = profile?.member.memberId || '';

  const reactions = data.reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type:
        reaction.type === 'REGULAR'
          ? ReactionType.REGULAR
          : ReactionType.CUSTOM,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userId),
  }));

  return {
    reactions,
    contentId: data.responseId,
    currentUserId: userId,
  };
};

type FlowFeedPostProps = {
  response: FlowFeedResponse;
  profileData: GetProfileInfoResponse;
  isEditEnabled?: boolean;
  onMemberClick: (member: MemberInteractionType) => void;
};

export const mapResponseDataToFeedProps = ({
  response,
  profileData,
  isEditEnabled = false,
  onMemberClick,
}: FlowFeedPostProps) => {
  return {
    instanceId: response.instanceId || '',
    flowId: response.flow.flowId,
    responseId: response.responseId,
    postId: '',
    key: response.responseId,
    type: PostTypes.FLOW,
    headerProps: mapFeedResponseToFeedHeaderProps(
      response,
      profileData,
      isEditEnabled,
    ),
    bodyProps: mapFeedResponseToFeedBodyProps(
      response,
      profileData,
      onMemberClick,
    ),
    reactionBarProps: mapFeedResponseToReactionProps(response, profileData),
    commentsCount: response.commentsCount,
    trophyReceivers: [],
    kind: response?.kind,
  };
};
