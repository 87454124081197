import styled from 'styled-components';
import MenuList from '../../molecules/MenuItem';

import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

interface GeneralMenuProps {
  lists: {
    id: string;
    title: string;
    icon: string;
    isActive?: boolean;
    isEnabled?: boolean;
    count?: number;
  }[];
  onItemClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | string,
  ) => void;
  isHovered?: boolean;
}

export function GeneralMenu(props: GeneralMenuProps) {
  return (
    <MenuList>
      {props.lists
        .filter((list) => list.isEnabled)
        .map((list) => (
          <StyledMenuListItem
            id={list.id}
            key={list.id}
            isSelected={list.isActive}
            onClick={props.onItemClick}
            isExpanded={props.isHovered}
          >
            <MenuList.Icon icon={list.icon} color={ThemeV2.palette.gray9} />
            <MenuList.Text>{list.title}</MenuList.Text>
            {list.count ? (
              <MenuList.SuffixElement>
                <Body
                  data-testid={`${list.title.toLowerCase()}-count-indicator`}
                  variant="body3"
                  color="gray1"
                >
                  {list.count}
                </Body>
              </MenuList.SuffixElement>
            ) : (
              <></>
            )}
          </StyledMenuListItem>
        ))}
    </MenuList>
  );
}
