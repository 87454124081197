export enum EmoticonHolderSizes {
  Large = 'large',
  Medium = 'medium',
  Medium2 = 'medium2',
  Medium3 = 'medium3',
  Small = 'small',
  ExtraSmall = 'extraSmall',
}

export interface EmoticonHolderProps {
  className?: string;
  size?: EmoticonHolderSizes;
  color: string;
  emoticon: string;
  isRounded?: boolean;
  borderColor?: string;
}

export interface StyledIconHolderProps {
  color: string;
  size: string;
  isRounded?: boolean;
  borderColor?: string;
}
