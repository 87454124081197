import format from 'date-fns/format';
import getDate from 'date-fns/getDate';
import getDay from 'date-fns/getDay';
import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns/getYear';
import { RRule, Options } from 'rrule';

import { getWeekDetails, hasAllWeekDays } from '..';

// eslint-disable-next-line max-len
import {
  FlowFrequencyOptions,
  GetRRuleStringProps,
} from '../../../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';
import { hasWeekDays } from '../flowFrequencyOptions';

// needed to form the RRule
export const getDayNameRRule = (dayInNumber: number) => {
  switch (dayInNumber) {
    case 0:
      return RRule.MO;
    case 1:
      return RRule.TU;
    case 2:
      return RRule.WE;
    case 3:
      return RRule.TH;
    case 4:
      return RRule.FR;
    case 5:
      return RRule.SA;
    default:
      return RRule.SU;
  }
};

// just a util func to get dayName in a way ie required to form the RRuleString
export const getDayName = (date: Date | undefined) => {
  if (date !== undefined) {
    let dayInNumber = getDay(date);
    if (dayInNumber === 0) {
      dayInNumber = 6;
    } else {
      dayInNumber -= 1;
    }
    return getDayNameRRule(dayInNumber);
  }
  return null;
};

// just a util func to get time - made it as func since its been reused
export const getTime = (time: string | undefined) => {
  if (time !== undefined) {
    const timeArray = time.split(' ');
    const calculatedTime = timeArray[0];
    const hours = parseInt(calculatedTime.split(':')[0], 10);
    const minutes = parseInt(calculatedTime.split(':')[1], 10);

    if (timeArray[1] === 'pm' && hours !== 12) {
      return {
        hours: hours + 12,
        minutes,
      };
    }
    if (timeArray[1] === 'am' && hours === 12) {
      return {
        hours: 0,
        minutes,
      };
    }
    return {
      hours,
      minutes,
    };
  }
  return null;
};

// just a util func to get the date, month & year - made it as func since its been reused
export const getDateMonthYear = (receivedDate: Date | undefined) => {
  if (receivedDate !== undefined) {
    const date = getDate(receivedDate);
    const month = getMonth(receivedDate);
    const year = getYear(receivedDate);
    return {
      date,
      month,
      year,
    };
  }
  return null;
};

// to form the RRuleString
export const getRRuleString = (props: GetRRuleStringProps) => {
  const {
    flowStartDate,
    flowEndDate,
    flowFrequency,
    flowFrequencyTime,
    flowEndDateTime,
    selectedTimezone,
    selectedCustomRecurrenceTypes,
  } = props;

  const rruleOptions: Partial<Options> = {};
  if (flowStartDate) {
    const dayNumber = format(flowStartDate, 'd');
    const weekDetails = getWeekDetails(flowStartDate);
    const byWeekDay = getDayName(flowStartDate);

    if (selectedCustomRecurrenceTypes !== undefined) {
      rruleOptions.interval =
        selectedCustomRecurrenceTypes.repeatEveryCount > 1
          ? selectedCustomRecurrenceTypes.repeatEveryCount
          : 1;
      switch (selectedCustomRecurrenceTypes.repeatEvery) {
        case 'days': {
          rruleOptions.freq = RRule.DAILY;
          break;
        }
        case 'weeks': {
          const includeAllDays = hasAllWeekDays(
            selectedCustomRecurrenceTypes.selectedDayIds,
          );
          const onlyWeekDays = hasWeekDays(
            selectedCustomRecurrenceTypes.selectedDayIds,
          );
          rruleOptions.freq = RRule.WEEKLY;
          // all weekdays
          if (includeAllDays) {
            rruleOptions.byweekday = [
              RRule.SU,
              RRule.MO,
              RRule.TU,
              RRule.WE,
              RRule.TH,
              RRule.FR,
              RRule.SA,
            ];
          } else if (onlyWeekDays) {
            // only weekdays
            rruleOptions.byweekday = [
              RRule.MO,
              RRule.TU,
              RRule.WE,
              RRule.TH,
              RRule.FR,
            ];
          } else {
            rruleOptions.freq = RRule.WEEKLY;
            rruleOptions.byweekday =
              selectedCustomRecurrenceTypes.selectedDayIds.map((dayId) =>
                getDayNameRRule(dayId),
              );
          }
          break;
        }
        case 'years': {
          rruleOptions.freq = RRule.YEARLY;
          break;
        }
        default: {
          // repeatMonths
          rruleOptions.freq = RRule.MONTHLY;
          if (byWeekDay !== null && weekDetails !== undefined) {
            if (
              weekDetails.isLastWeek &&
              selectedCustomRecurrenceTypes.repeatOnMonth === 'repeatMonthlyOn'
            ) {
              rruleOptions.bysetpos = -1; // last day
              rruleOptions.byweekday = byWeekDay;
            } else if (
              selectedCustomRecurrenceTypes.repeatOnMonth === 'repeatMonthlyOn'
            ) {
              rruleOptions.byweekday = byWeekDay;
              rruleOptions.bysetpos = weekDetails.weekNumber; // ex. third THU
            } else if (
              selectedCustomRecurrenceTypes.repeatOnMonth === 'repeatMonthly'
            ) {
              rruleOptions.bymonthday = parseInt(dayNumber, 10);
            }
          }
          break;
        }
      }
    } else {
      if (flowFrequency?.id === FlowFrequencyOptions.ONCE) {
        rruleOptions.count = 1;
      }
      if (flowFrequency?.id === FlowFrequencyOptions.DAILY) {
        rruleOptions.freq = RRule.DAILY;
      }
      if (flowFrequency?.id === FlowFrequencyOptions.WEEKLY) {
        rruleOptions.freq = RRule.WEEKLY;
        if (byWeekDay !== null) {
          rruleOptions.byweekday = byWeekDay;
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.MONTHLY) {
        rruleOptions.freq = RRule.MONTHLY;
        if (byWeekDay !== null && weekDetails !== undefined) {
          rruleOptions.byweekday = byWeekDay;
          rruleOptions.bysetpos =
            weekDetails.weekNumber === 5 ? -1 : weekDetails.weekNumber;
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.MONTHLY_LAST) {
        rruleOptions.freq = RRule.MONTHLY;
        if (byWeekDay !== null) {
          rruleOptions.byweekday = byWeekDay;
          rruleOptions.bysetpos = -1; // for last day
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.ANNUALLY) {
        rruleOptions.freq = RRule.YEARLY;
      }
      if (flowFrequency?.id === FlowFrequencyOptions.BIWEEKLY) {
        rruleOptions.freq = RRule.WEEKLY;
        rruleOptions.interval = 2;
        if (byWeekDay !== null) {
          rruleOptions.byweekday = byWeekDay;
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.QUARTERLY) {
        rruleOptions.freq = RRule.MONTHLY;
        rruleOptions.interval = 3;
        if (byWeekDay !== null && weekDetails !== undefined) {
          rruleOptions.byweekday = byWeekDay;
          rruleOptions.bysetpos =
            weekDetails.weekNumber === 5 ? -1 : weekDetails.weekNumber;
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.QUARTERLY_LAST) {
        rruleOptions.freq = RRule.MONTHLY;
        rruleOptions.interval = 3;
        if (byWeekDay !== null && weekDetails !== undefined) {
          rruleOptions.byweekday = byWeekDay;
          rruleOptions.bysetpos = -1; // for last day
        }
      }
      if (flowFrequency?.id === FlowFrequencyOptions.EVERY_WEEKDAY) {
        rruleOptions.freq = RRule.WEEKLY;
        rruleOptions.byweekday = [
          RRule.MO,
          RRule.TU,
          RRule.WE,
          RRule.TH,
          RRule.FR,
        ];
      }
    }

    if (selectedTimezone) {
      rruleOptions.tzid = selectedTimezone.id;
    }

    const flowStartDateTimeInfo = getTime(flowFrequencyTime?.title);
    const flowStartDateInfo = getDateMonthYear(flowStartDate);

    const flowEndDateTimeInfo = getTime(flowEndDateTime?.title);
    const flowEndDateInfo = getDateMonthYear(flowEndDate);

    if (flowStartDateInfo !== null && flowStartDateTimeInfo !== null) {
      rruleOptions.dtstart = new Date(
        Date.UTC(
          flowStartDateInfo.year,
          flowStartDateInfo.month,
          flowStartDateInfo.date,
          flowStartDateTimeInfo.hours,
          flowStartDateTimeInfo.minutes,
        ),
      );
    } else if (flowStartDateInfo !== null && flowStartDateTimeInfo === null) {
      rruleOptions.dtstart = new Date(
        Date.UTC(
          flowStartDateInfo.year,
          flowStartDateInfo.month,
          flowStartDateInfo.date,
        ),
      );
    }
    if (flowEndDateInfo !== null && flowEndDateTimeInfo !== null) {
      rruleOptions.until = new Date(
        Date.UTC(
          flowEndDateInfo.year,
          flowEndDateInfo.month,
          flowEndDateInfo.date,
          flowEndDateTimeInfo.hours,
          flowEndDateTimeInfo.minutes,
        ),
      );
    } else if (flowEndDateInfo !== null && flowEndDateTimeInfo === null) {
      rruleOptions.until = new Date(
        Date.UTC(
          flowEndDateInfo.year,
          flowEndDateInfo.month,
          flowEndDateInfo.date,
        ),
      );
    }
  }
  const rule = new RRule(rruleOptions);
  return rule;
};
