import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { FLOW_POST_MAX_HEIGHT } from '../../../Utils/constants';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import BlockScale from '../../molecules/BlockScale';
import FileCard from '../../molecules/FileCard';
import FilePreviewModal from '../../molecules/FilePreviewModal';
import GifContainer from '../../molecules/GifContainer';
import ResponseBlock from '../../molecules/ResponseBlock';

import FlowBlockGiveTrophies from '../../molecules/FlowBlockGiveTrophies';
import FlowBlockPersonSelection from '../../molecules/FlowBlockPersonSelection';
import OpenEndedFlowsResponseBlock from '../../molecules/OpenEndedFlowsResponseBlock';

import {
  BlockWrapper,
  FlowPostBodyContainer,
  FlowPostBodyWrapper,
  SeeFullPostFlexWrapper,
  StyledBody,
  StyledMultipleChoiceText,
} from './styles';

import {
  BlockProps,
  DropDownBlockType,
  FileUploadBlockType,
  FlowPostBodyProps,
  GifBlockType,
  GiveTrophiesBlockType,
  NoResponseBlockType,
  OpenEndedBlockType,
  PersonSelectorBlockType,
  ScaleBlockType,
} from './types';

import { Collapse } from '@mui/material';
import { segregateFiles } from '../../molecules/FileCard/utils';
import {
  AccordionItemBody,
  AccordionItemHeader,
  StyledArrowIcon,
} from '../../molecules/FlowAccordionItem/styles';
import { ImageFiles } from '../../molecules/ImageFiles';

import { File, FileUploadStatus } from '../../molecules/FileCard/types';
import { useFilePreviewer } from '../../molecules/FilePreviewModal/useFilePreviewer';
import { FlowAccordionItemState as AccordionItemState } from '../../molecules/FlowAccordionItem/interface';
import { StyledFlex } from '../../molecules/PageHeader/styles';
import { useHistory, useLocation } from 'react-router';

const GifBlock = (props: BlockProps) => {
  const { content, isEdited } = props;
  const gifBlockProps = content as GifBlockType;

  return (
    <ResponseBlock label={gifBlockProps.title}>
      <GifContainer src={gifBlockProps.src} lazyload />
      {isEdited && (
        <Body
          inline
          variant="body3"
          color="gray8"
          data-testid="gif-block-edited"
        >
          (edited)
        </Body>
      )}
    </ResponseBlock>
  );
};

const ScaleBlock = (props: BlockProps) => {
  const { content, isEdited } = props;
  return (
    <>
      <BlockScale
        {...(content as ScaleBlockType)}
        type="normal"
        isReadOnly
        label={content.title}
      />
      {isEdited && (
        <Body
          inline
          variant="body3"
          color="gray8"
          data-testid="scale-block-edited"
        >
          (edited)
        </Body>
      )}
    </>
  );
};

const TrophiesBlock = (props: BlockProps) => {
  const { content } = props;
  return <FlowBlockGiveTrophies {...(content as GiveTrophiesBlockType)} />;
};

/*
   https://gist.github.com/markmcgowan/4ae4b897bc3db402fa5e0710a0369348
*/

const OpenEndedBlock = (props: BlockProps) => {
  const {
    content,
    onDownloadFileClick,
    id,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    isConvertedPdfPreviewsEnabled,
    flow,
    person,
    createdAt,
    visibility,
    handleSetFilePreview,
    responseId,
    isAnonymous = false,
    isEdited = false,
    variant,
  } = props;

  const openEndedBlockProps = content as OpenEndedBlockType;

  const doesBlockHaveFile =
    onDownloadFileClick &&
    handleSetFilePreview &&
    openEndedBlockProps?.files &&
    openEndedBlockProps.files[0];

  const onFilePreviewCallback = useCallback(
    (file?: File) => {
      if (file) {
        handleSetFilePreview?.({
          id: props.id,
          fileName: file.name,
          responseId: responseId,
          type: file.mimeType,
        });
      } else {
        handleSetFilePreview?.(undefined);
      }
    },
    [handleSetFilePreview, props.id, responseId],
  );

  const {
    filePreviewerState,
    previewFile,
    onFilePreviewNavigation,
    onCloseModal,
  } = useFilePreviewer(openEndedBlockProps?.files, onFilePreviewCallback);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const previewFileName = queryParams.get('fileName');

  const openFilePreview = useCallback(() => {
    const { imageFiles, otherFiles } = segregateFiles(
      (content as OpenEndedBlockType).files,
    );

    const requestedFile =
      imageFiles.find((file) => file.name === previewFileName) ||
      otherFiles.find((file) => file.name === previewFileName);

    if (requestedFile) {
      previewFile(requestedFile);
    }

    queryParams.delete('fileName');

    history.push({ search: queryParams.toString() });
  }, [content, history, previewFile, previewFileName, queryParams]);

  useEffect(() => {
    const hasFile = !!(content as OpenEndedBlockType)?.files?.some(
      (x) => x.name === previewFileName,
    );

    if (previewFileName && doesBlockHaveFile && !variant && hasFile) {
      openFilePreview();
    }
  }, []);

  return (
    <>
      <OpenEndedFlowsResponseBlock
        blockId={id}
        isEdited={isEdited}
        {...(content as OpenEndedBlockType)}
        flowId={flow?.flowId}
        responseId={responseId}
        onDownloadFileClick={onDownloadFileClick}
        onClickPreview={(file) => previewFile(file)}
      />
      {doesBlockHaveFile && filePreviewerState.isOpen && (
        <FilePreviewModal
          flow={flow}
          person={person}
          createdAt={createdAt}
          visibility={visibility}
          fileInformation={filePreviewerState.file}
          fileToDownload={fileToDownload}
          fileDownloadError={fileDownloadError}
          refetchDownloadLink={refetchFileDownloadLink}
          fileToPreview={fileToPreview}
          filePreviewError={filePreviewError}
          refetchPreviewLink={refetchFilePreviewLink}
          isFilePreviewLoading={isFilePreviewLoading}
          isConvertedPdfPreviewsEnabled={isConvertedPdfPreviewsEnabled}
          isOpen={filePreviewerState.isOpen}
          source={'feed'}
          hasPrevious={filePreviewerState.hasPrevious}
          hasNext={filePreviewerState.hasNext}
          onClickDownload={(selectedFile) =>
            onDownloadFileClick({
              id: props.id,
              fileName: selectedFile.name,
              responseId: responseId,
            })
          }
          onCloseModal={onCloseModal}
          isAnonymous={isAnonymous}
          onFilePreviewNavigation={onFilePreviewNavigation}
          responseId={responseId}
          blockId={id}
        />
      )}
    </>
  );
};

const FileUploadBlock = (props: BlockProps) => {
  const {
    content,
    onDownloadFileClick,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    isConvertedPdfPreviewsEnabled,
    flow,
    person,
    createdAt,
    visibility,
    handleSetFilePreview,
    responseId,
    isAnonymous = false,
    isEdited = false,
    variant,
  } = props;
  //This is just a multipurpose isExpanded, so I can use this A: as a way to determine if the modal should be open
  //or not and B to know which file in the array to open the preview for.
  const fileUploadBlockProps = content as FileUploadBlockType;

  const [isAttachmentsSectionExpanded, setIsAttachmentsSectionExpanded] =
    useState(true);

  const onFilePreviewCallback = useCallback(
    (file?: File) => {
      if (file) {
        handleSetFilePreview?.({
          id: props.id,
          fileName: file.name,
          responseId: responseId,
          type: file.mimeType,
        });
      } else {
        handleSetFilePreview?.(undefined);
      }
    },
    [handleSetFilePreview, props.id, responseId],
  );

  const {
    filePreviewerState,
    previewFile,
    onFilePreviewNavigation,
    onCloseModal,
  } = useFilePreviewer(fileUploadBlockProps?.files, onFilePreviewCallback);

  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const previewFileName = queryParams.get('fileName');

  const openFilePreview = useCallback(() => {
    const { imageFiles, otherFiles } = segregateFiles(
      (content as FileUploadBlockType).files,
    );

    const requestedFile =
      imageFiles.find((file) => file.name === previewFileName) ||
      otherFiles.find((file) => file.name === previewFileName);

    if (requestedFile) {
      previewFile(requestedFile);
    }

    queryParams.delete('fileName');

    history.push({ search: queryParams.toString() });
  }, [content, history, previewFile, previewFileName, queryParams]);

  useEffect(() => {
    const hasFile = !!(content as OpenEndedBlockType)?.files?.some(
      (x) => x.name === previewFileName,
    );

    if (previewFileName && !variant && hasFile) {
      openFilePreview();
    }
  }, []);

  if (!onDownloadFileClick || !handleSetFilePreview) {
    return null;
  }

  const { imageFiles, otherFiles } = segregateFiles(fileUploadBlockProps.files);

  const noOfFiles = fileUploadBlockProps.files?.length || 0;

  const attachmentAccordionText = `${noOfFiles} ${
    noOfFiles === 1 ? 'attachment' : 'attachments'
  }`;

  return (
    <ResponseBlock label={fileUploadBlockProps.title}>
      <>
        <AccordionItemHeader
          compactMode
          type="button"
          hideBorder={true}
          isOpen={isAttachmentsSectionExpanded}
          state={AccordionItemState.Focus}
          onClick={() =>
            setIsAttachmentsSectionExpanded(!isAttachmentsSectionExpanded)
          }
        >
          <StyledFlex compactMode>
            <Body
              variant="body3"
              color="gray8"
              style={{ whiteSpace: 'nowrap' }}
            >
              {isAttachmentsSectionExpanded
                ? `Hide ${attachmentAccordionText}`
                : `Show ${attachmentAccordionText}`}
            </Body>
            <StyledArrowIcon
              size="18px"
              icon="caret-rounded"
              rotate={isAttachmentsSectionExpanded ? 0 : 180}
              state={AccordionItemState.Focus}
              data-testid="accordionItemCaratIcon"
            />
          </StyledFlex>
        </AccordionItemHeader>
        <AccordionItemBody>
          <Collapse in={isAttachmentsSectionExpanded}>
            {imageFiles.length > 0 && (
              <ImageFiles files={imageFiles} onClickPreview={previewFile} />
            )}
            {otherFiles.map((file) => (
              <FileCard
                isNewThumbnailExperience
                key={file.name}
                flowId={flow?.flowId}
                responseId={responseId}
                file={file}
                status={
                  file.size === 0
                    ? FileUploadStatus.Error
                    : fileUploadBlockProps.status
                }
                onClickDownload={(selectedFile) =>
                  onDownloadFileClick({
                    id: props.id,
                    fileName: selectedFile.name,
                    responseId: responseId,
                  })
                }
                isParticipation={fileUploadBlockProps.isParticipation}
                onClickPreview={previewFile}
              />
            ))}
          </Collapse>
        </AccordionItemBody>
      </>
      {isEdited && (
        <Body
          inline
          variant="body3"
          color="gray8"
          data-testid="file-upload-block-edited"
        >
          (edited)
        </Body>
      )}
      {filePreviewerState.isOpen && (
        <FilePreviewModal
          flow={flow}
          person={person}
          createdAt={createdAt}
          visibility={visibility}
          fileInformation={filePreviewerState.file}
          fileToDownload={fileToDownload}
          fileDownloadError={fileDownloadError}
          refetchDownloadLink={refetchFileDownloadLink}
          fileToPreview={fileToPreview}
          filePreviewError={filePreviewError}
          refetchPreviewLink={refetchFilePreviewLink}
          isFilePreviewLoading={isFilePreviewLoading}
          isConvertedPdfPreviewsEnabled={isConvertedPdfPreviewsEnabled}
          isOpen={filePreviewerState.isOpen}
          source={'feed'}
          hasPrevious={filePreviewerState.hasPrevious}
          hasNext={filePreviewerState.hasNext}
          onClickDownload={(selectedFile) =>
            onDownloadFileClick({
              id: props.id,
              fileName: selectedFile.name,
              responseId: responseId,
            })
          }
          onCloseModal={onCloseModal}
          isAnonymous={isAnonymous}
          onFilePreviewNavigation={onFilePreviewNavigation}
          responseId={responseId}
          blockId={props.id}
        />
      )}
    </ResponseBlock>
  );
};

const PersonSelectorBlock = (props: BlockProps) => {
  const { content, onMemberClick, isEdited } = props;

  return (
    <>
      <FlowBlockPersonSelection
        {...(content as PersonSelectorBlockType)}
        onMemberClick={onMemberClick}
      />
      {isEdited && (
        <Body
          inline
          variant="body3"
          color="gray8"
          data-testid="person-selector-block-edited"
        >
          (edited)
        </Body>
      )}
    </>
  );
};

const MultiChoiceSelectBlock = (props: BlockProps) => {
  const { content, isEdited } = props;
  const multiChoiceSelectBlockProps = content as DropDownBlockType;

  return (
    <ResponseBlock label={multiChoiceSelectBlockProps.title}>
      {multiChoiceSelectBlockProps.value.map((val, index) => (
        <StyledMultipleChoiceText variant="body1" key={val}>
          {val}{' '}
          {isEdited &&
            multiChoiceSelectBlockProps.value.length - 1 === index && (
              <Body
                inline
                variant="body3"
                color="gray8"
                data-testid="multi-choice-block-edited"
              >
                (edited)
              </Body>
            )}
        </StyledMultipleChoiceText>
      ))}
    </ResponseBlock>
  );
};

const NoResponseBlock = (props: BlockProps) => {
  const { content, isEdited } = props;
  const noResponseBlockProps = content as NoResponseBlockType;

  return (
    <div>
      <StyledBody variant="body3" color="gray8">
        {noResponseBlockProps.title}
      </StyledBody>
      <Body variant="body1" color="gray6">
        {noResponseBlockProps.value}
        {isEdited && (
          <Body
            inline
            variant="body3"
            color="gray8"
            data-testid="open-ended-block-edited"
          >
            &nbsp; (edited)
          </Body>
        )}
      </Body>
    </div>
  );
};

const FlowPostBody = (props: FlowPostBodyProps) => {
  const {
    blocks,
    responseId,
    onMemberClick,
    onSeeFullPostClick,
    onDownloadFileClick,
    flow,
    person,
    createdAt,
    visibility,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    isConvertedPdfPreviewsEnabled,
    handleSetFilePreview,
    isAnonymous = false,
    isExpandedOnLoad = false,
    variant,
  } = props;
  const [isExpanded, setIsExpanded] = useState(isExpandedOnLoad);
  const [flowPostBodyHeight, setFlowPostBodyHeight] = useState(0);

  const MAX_HEIGHT = variant === 'sidebar' ? 0 : FLOW_POST_MAX_HEIGHT;

  const handleSeeFullPostClick = useCallback(() => {
    if (onSeeFullPostClick) {
      onSeeFullPostClick();
    }

    setIsExpanded(true);
  }, [onSeeFullPostClick]);

  const ref = useRef(null);

  useLayoutEffect(() => {
    function handleLongPosts() {
      if (ref.current !== null) {
        setFlowPostBodyHeight(
          (ref.current as Element).getBoundingClientRect().height,
        );
      }
    }
    handleLongPosts();

    const resizeObserver = new ResizeObserver(() => handleLongPosts());

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <FlowPostBodyContainer
      data-testid="flow-feed-post-body"
      data-qa-id="flow-feed-post-body"
    >
      <FlowPostBodyWrapper
        ref={ref}
        maxHeight={MAX_HEIGHT}
        isExpanded={isExpanded}
      >
        {blocks
          .map((block) => {
            switch (block.type) {
              case 'SCALE':
                return <ScaleBlock key={block.id} {...block} />;
              case 'PERSON_SELECTOR':
                return (
                  <PersonSelectorBlock
                    key={block.id}
                    {...block}
                    onMemberClick={onMemberClick}
                  />
                );
              case 'GIVE_TROPHIES': {
                return <TrophiesBlock key={block.id} {...block} />;
              }
              case 'OPEN_ENDED':
                return (
                  <OpenEndedBlock
                    variant={variant}
                    responseId={responseId}
                    key={block.id}
                    {...block}
                    onDownloadFileClick={onDownloadFileClick}
                    fileToDownload={fileToDownload}
                    fileDownloadError={fileDownloadError}
                    refetchFileDownloadLink={refetchFileDownloadLink}
                    fileToPreview={fileToPreview}
                    filePreviewError={filePreviewError}
                    refetchFilePreviewLink={refetchFilePreviewLink}
                    isFilePreviewLoading={isFilePreviewLoading}
                    isConvertedPdfPreviewsEnabled={
                      isConvertedPdfPreviewsEnabled
                    }
                    flow={flow}
                    person={person}
                    createdAt={createdAt}
                    visibility={visibility}
                    handleSetFilePreview={handleSetFilePreview}
                    isAnonymous={isAnonymous}
                  />
                );
              case 'DROPDOWN':
              case 'MULTI_CHOICE':
                return <MultiChoiceSelectBlock key={block.id} {...block} />;
              case 'FILE_UPLOAD':
                return (
                  <FileUploadBlock
                    variant={variant}
                    key={block.id}
                    responseId={responseId}
                    {...block}
                    onDownloadFileClick={onDownloadFileClick}
                    fileToDownload={fileToDownload}
                    fileDownloadError={fileDownloadError}
                    refetchFileDownloadLink={refetchFileDownloadLink}
                    fileToPreview={fileToPreview}
                    filePreviewError={filePreviewError}
                    refetchFilePreviewLink={refetchFilePreviewLink}
                    isFilePreviewLoading={isFilePreviewLoading}
                    isConvertedPdfPreviewsEnabled={
                      isConvertedPdfPreviewsEnabled
                    }
                    flow={flow}
                    person={person}
                    createdAt={createdAt}
                    visibility={visibility}
                    handleSetFilePreview={handleSetFilePreview}
                    isAnonymous={isAnonymous}
                  />
                );
              case 'GIF_SELECTOR':
                return <GifBlock key={block.id} {...block} />;
              case 'NO_RESPONSE':
                return <NoResponseBlock key={block.id} {...block} />;
              default:
                throw Error('Invalid Type');
            }
          })
          .map((block) => (
            <BlockWrapper key={block.key}>{block}</BlockWrapper>
          ))}
      </FlowPostBodyWrapper>
      {flowPostBodyHeight >= MAX_HEIGHT && MAX_HEIGHT > 0 && !isExpanded && (
        <SeeFullPostFlexWrapper justifyContent="center" margin="16px 0 21px 0">
          <Button
            onClick={handleSeeFullPostClick}
            icon="arrow-thick-down"
            size="small"
            variant="text"
          >
            See full post
          </Button>
        </SeeFullPostFlexWrapper>
      )}
    </FlowPostBodyContainer>
  );
};

export default memo(FlowPostBody);
