import isNil from 'lodash/isNil';
import Bowser from 'bowser';
import axios from 'axios';

class Client {
  static eventTypes = {
    REGISTRATION: 'REGISTRATION',
    PROFILE_COMPLETE: 'PROFILE_COMPLETE',
    SUBSCRIPTION: 'SUBSCRIPTION',
  };

  static ping() {
    console.log('Client ready');
  }

  static apiEndpoint(environment: string) {
    environment = environment || 'production';
    if (environment === 'development') {
      return 'https://api-dev.joinassembly.com';
    } else if (environment === 'qa') {
      return 'https://api-qa.joinassembly.com';
    } else if (environment === 'LOCAL') {
      return 'http://localhost:8080';
    }
    return 'https://api-prod.joinassembly.com';
  }

  static clientInformation() {
    const clientInformationObject = {
      platform: 'WEB',
      viewport: {
        width: NaN,
        height: NaN,
      },
      browser: {},
      os: {},
    };

    if (!isNil(window)) {
      clientInformationObject.viewport = {
        width: window.outerWidth,
        height: window.outerHeight,
      };
      const { browser, os } = Bowser.parse(window.navigator.userAgent);
      clientInformationObject.browser = browser;
      clientInformationObject.os = os;
    }

    return clientInformationObject;
  }

  static campaign({
    lander,
    referrer,
  }: {
    lander?: string;
    referrer?: string;
  }) {
    const campaignLander = lander || window.location.href;
    let campaignReferrer: string | null = referrer || window.document.referrer;
    if (!isNil(campaignReferrer)) {
      const match = campaignReferrer.match(/joinassembly\.com|carrothr\.com/);
      if (!isNil(match)) {
        campaignReferrer = null;
      }
    }
    return {
      lander: campaignLander,
      referrer: campaignReferrer,
    };
  }

  static async event({
    environment,
    type,
    employerId,
    userId,
    payload,
    lander,
    referrer,
  }: {
    environment: string;
    type?: string;
    employerId?: string;
    userId?: string;
    payload?: any;
    lander?: string;
    referrer?: string;
  }) {
    if (isNil(type)) {
      throw new Error('Please provide an event type');
    }

    const eventObject = {
      type,
      employerId,
      userId,
      campaign: Client.campaign({ lander, referrer }),
      payload,
      client: Client.clientInformation(),
    };
    const apiEndpoint = Client.apiEndpoint(environment);
    const url = `${apiEndpoint}/api/v2/metrics/events/record`;
    await axios.post(url, { event: eventObject });
  }
}

export default Client;
