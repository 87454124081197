import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Collapse from '../../../../atomic/atoms/Collapse';
import Theme from '../../../../theme';
import {
  ShareRequestsUsers,
  useGetShareRequestsQuery,
} from '../../../../queries/Flows/Sharesheet';
import { useLocation, useParams } from 'react-router-dom';
import useRequestedUsersForAccessStore, {
  RequestedUsersForAccess,
} from '../../../../stores/sharesheetStore';
import { ShareSheetMemberActions } from '../ShareSheetModalController/data';
import { RequestAccessHeader } from './Components/RequestAccessHeader';
import { RequestAccessCollapseContent } from './Components/RequestAccessCollapseContent';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { FlowAccessRoles } from '../../../../queries/Flows/interfaces';

const StyledCollapse = styled(Collapse)`
  width: 100%;
  border: 1px solid ${Theme.palette.white4};
  padding: 8px 12px;
  margin: 8px 0;
  border-radius: 10px;
`;

const permissionMap: Record<
  'VIEWER' | 'PARTICIPANT' | 'PARTICIPANT_AND_VIEWER',
  ShareSheetMemberActions
> = {
  VIEWER: ShareSheetMemberActions.canOnlyViewPosts,
  PARTICIPANT: ShareSheetMemberActions.canOnlyPost,
  PARTICIPANT_AND_VIEWER: ShareSheetMemberActions.canPostAndViewPosts,
};

const RequestAccessController = () => {
  const {
    requestedUsers,
    setRequestedUsers,
    requestedUsersCount,
    setRequestedUsersCount,
  } = useRequestedUsersForAccessStore();

  const requestsCount = useMemo(() => {
    const respondedRequestedUsersCount = requestedUsers.filter(
      (user) =>
        user.requestStatus === 'APPROVED' || user.requestStatus === 'REJECTED',
    ).length;
    return requestedUsersCount
      ? requestedUsersCount - respondedRequestedUsersCount
      : 0;
  }, [requestedUsers, requestedUsersCount]);

  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId);
  const isFlowEditor =
    flowDetails?.data.isFlowOwner ||
    Boolean(
      flowDetails?.data.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
    ) ||
    false;
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isInitialLoading: isLoading,
    isError,
  } = useGetShareRequestsQuery(flowId, 20, isFlowEditor || false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const { search } = useLocation();
  useEffect(() => {
    if (search.includes('open-request-tab=true')) {
      setIsCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareRequestsUsersData = useMemo(() => {
    return data?.pages.reduce<ShareRequestsUsers[]>(
      (acc, page) => [...acc, ...page.data],
      [],
    );
  }, [data]);

  /* Updating the store values */
  useEffect(() => {
    setRequestedUsersCount(data?.pages[0].total || 0);
    if (shareRequestsUsersData) {
      const formattedRequestedUsers: RequestedUsersForAccess[] =
        shareRequestsUsersData.map((user) => {
          return {
            name: user.requestedFor.name,
            permission: permissionMap[user.permission],
            requestId: user.requestId,
            email: user.requestedFor.email,
            memberID: user.requestedFor.memberID,
            image: user.requestedFor.image,
            requestStatus: 'PENDING',
            memberState: user.requestedFor.state,
          };
        });

      const updatedRequestedUsers = formattedRequestedUsers.map((user) => {
        const requestStatusUpdatedUsers = requestedUsers.find(
          (item) => item.email === user.email,
        );
        if (requestStatusUpdatedUsers) {
          return {
            ...user,
            requestStatus: requestStatusUpdatedUsers.requestStatus,
          };
        } else {
          return user;
        }
      });
      setRequestedUsers([...updatedRequestedUsers]);
    }
    // intentionally not adding requestedUsers to dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setRequestedUsersCount, setRequestedUsers, shareRequestsUsersData]);

  if (!requestsCount) {
    return null;
  }

  return (
    <StyledCollapse
      collapseHeader={
        <RequestAccessHeader
          isCollapsed={isCollapsed}
          requestsCount={requestsCount}
          isLoading={!shareRequestsUsersData && isLoading}
        />
      }
      isCollapseOpen={isCollapsed}
      collapseOpenCallback={() => setIsCollapsed(!isCollapsed)}
    >
      <RequestAccessCollapseContent
        isError={isError}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </StyledCollapse>
  );
};

export default RequestAccessController;
