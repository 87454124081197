import uniqBy from 'lodash/uniqBy';
import { getMemberSettingForSimpleRules } from '.';
import {
  ConditionTextMap,
  canOnlyPostSetting,
  SlackChannelAvatar,
  BulkRuleHelperTextMap,
  BulkMemberRuleAvatarMap,
} from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import {
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
} from '../../controllers/flows/FlowsShareSheetController/types';
import {
  CriteriaValueItem,
  CriteriaRule,
  CriteriaGroups,
} from '../../interfaces/Flow/Builder';
import { Connection, GroupRule } from '../../queries/Flows/interfaces';
import orderBy from 'lodash/orderBy';
import { Channel } from '../../interfaces/Connections';
import isEmpty from 'lodash/isEmpty';
import { capitalizeFirstLetter } from '../text';

const getValue = (currentValue: CriteriaValueItem, rule: CriteriaRule) => {
  if (rule.field === 'manager') {
    return currentValue.value === 'memberIsManager';
  } else {
    if (rule.field === 'member' || rule.field === 'directReport') {
      return [currentValue.id];
    } else {
      return [currentValue.value];
    }
  }
};

const getRules = (criteria: CriteriaGroups, isForPayload = false) => {
  return criteria.groups?.map((criteriaGroup) => {
    const rules = (criteriaGroup.groupRules || []).flatMap((rule) => {
      if (rule.field === 'everyone' && isForPayload) {
        return {
          field: rule.field,
          value: true,
          operator: rule.operator,
        };
      }
      return rule.value
        .filter((currentValue) => currentValue.id && currentValue.value)
        .map((currentValue) => ({
          field: rule.field,
          value: getValue(currentValue, rule),
          operator: rule.operator,
        }));
    });

    return {
      condition: criteriaGroup.groupCondition,
      rules: rules,
    };
  });
};

export const getSimpleShareSheetTypeFromBulkSelection = (type: string) => {
  switch (type) {
    case 'department':
      return SimplifiedShareSheetDataTypes.Department;
    case 'jobTitle':
      return SimplifiedShareSheetDataTypes.JobTitle;
    case 'workLocation':
      return SimplifiedShareSheetDataTypes.WorkLocation;
    case 'homeLocation':
      return SimplifiedShareSheetDataTypes.HomeLocation;
    default:
      return SimplifiedShareSheetDataTypes.Department;
  }
};

const getAutoCompleteOptionsForNonMembersFromCriteria = (
  criteria: CriteriaGroups,
) => {
  const miscellaneousRules: SimplifiedShareSheetCardData[] = [];

  criteria.groups?.forEach((criteriaGroup) => {
    const hasNonMemberRule = criteriaGroup.groupRules?.some((rule) => {
      return (
        rule.field === 'department' ||
        rule.field === 'workLocation' ||
        rule.field === 'homeLocation' ||
        rule.field === 'jobTitle' ||
        rule.field === 'everyone'
      );
    });

    if (hasNonMemberRule) {
      criteriaGroup.groupRules.forEach((rule) => {
        if (
          rule.field === 'department' ||
          rule.field === 'workLocation' ||
          rule.field === 'homeLocation' ||
          rule.field === 'jobTitle'
        ) {
          const type = getSimpleShareSheetTypeFromBulkSelection(rule.field);
          rule.value.forEach((currentValue) => {
            miscellaneousRules.push({
              id: `${currentValue.value.replaceAll(`-${rule.operator}`, '')}-${
                rule.operator
              }-${type}`,
              title: `${capitalizeFirstLetter(
                BulkRuleHelperTextMap[rule.field],
              )} ${
                ConditionTextMap[rule.operator]
              } ${currentValue.value.replace(`-${rule.operator}`, '')}`,
              type,
              avatar: BulkMemberRuleAvatarMap[rule.field],
              setting: canOnlyPostSetting,
              email:
                rule.field === 'department'
                  ? 'Loading members'
                  : `Members who have ${currentValue.value} as their ${
                      BulkRuleHelperTextMap[rule.field]
                    }`,
              condition: rule.operator,
              name: currentValue.value,
            });
          });
        }
      });
    }
  });

  return miscellaneousRules;
};

function filterDuplicateRules(array: GroupRule[]) {
  const seen = new Set();
  const result: GroupRule[] = [];

  for (const item of array) {
    const key = JSON.stringify(item.rules[0]);

    if (!seen.has(key)) {
      seen.add(key);
      result.push(item);
    }
  }

  return result;
}

const getRulesForMembersAndEmailsOnly = (criteria: CriteriaGroups) => {
  const criteriaToBeReturned = criteria.groups
    ?.filter((criteriaGroup) => {
      return criteriaGroup.groupRules?.some(
        (rule) =>
          rule.field !== 'department' &&
          rule.field !== 'everyone' &&
          rule.field !== 'workLocation' &&
          rule.field !== 'homeLocation' &&
          rule.field !== 'jobTitle' &&
          rule.field !== 'slack',
      );
    })
    .flatMap((criteriaGroup) => {
      const memberRules = criteriaGroup.groupRules?.filter(
        (rule) =>
          rule.field !== 'department' &&
          rule.field !== 'everyone' &&
          rule.field !== 'workLocation' &&
          rule.field !== 'homeLocation' &&
          rule.field !== 'jobTitle' &&
          rule.field !== 'slack',
      );
      if (!memberRules) return [];

      const rules = memberRules.flatMap((rule) => {
        return rule.value.map((value) => {
          return {
            field: rule.field,
            value: getValue(value, rule),
            operator: rule.operator,
          };
        });
      });
      return [
        {
          condition: criteriaGroup.groupCondition,
          rules,
        } as GroupRule,
      ];
    });
  return filterDuplicateRules(criteriaToBeReturned);
};

export const isEveryoneCriteria = (criteria: CriteriaGroups | undefined) =>
  Boolean(
    criteria &&
      ((criteria?.groups.length === 1 &&
        criteria?.groups[0]?.groupRules.length === 1 &&
        Array.isArray(criteria?.groups[0]?.groupRules[0]?.value) &&
        criteria?.groups[0]?.groupRules[0]?.value.some(
          (x) => x.id === 'everyone',
        )) ||
        criteria?.groups
          ?.flatMap((group) => group.groupRules)
          .some((x) => x.field === 'everyone')),
  );

export const mapRulesFromCriteriaGroupsAndReturnMembersOnly = (
  criteriaGroups: CriteriaGroups | undefined,
  noSkippedMembers?: boolean,
  canIncludeNewInviteMembers = true,
) => {
  if (criteriaGroups) {
    const rules = getRulesForMembersAndEmailsOnly(criteriaGroups);
    if (rules.length > 0) {
      const customCriteria = {
        rules,
        condition: criteriaGroups.groupsCondition,
      };

      return {
        criteria: {
          custom: { ...customCriteria },
        },
        ...(canIncludeNewInviteMembers && { isNewMembersAdded: true }),
        ...(!noSkippedMembers && { skippedMembers: [] }),
      };
    }
  }

  return undefined;
};

const isDepartmentPresent = (
  { id }: SimplifiedShareSheetCardData,
  departments: SimplifiedShareSheetCardData[],
) => departments.some(({ id: deptId }) => id === deptId);

export const sortItemsForSimpleShareSheet = (
  items: SimplifiedShareSheetCardData[],
) => {
  const miscellaneousRules = items.filter(
    ({ type }) =>
      type === SimplifiedShareSheetDataTypes.Department ||
      type === SimplifiedShareSheetDataTypes.WorkLocation ||
      type === SimplifiedShareSheetDataTypes.JobTitle ||
      type === SimplifiedShareSheetDataTypes.HomeLocation,
  );
  const members = orderBy(
    items.filter(
      ({ type, isOwner = false, isCollaborator = false }) =>
        type === SimplifiedShareSheetDataTypes.Member &&
        !isOwner &&
        !isCollaborator,
    ),
    ['title'],
    ['asc'],
  );

  const everyone = items.filter(
    ({ type }) => type === SimplifiedShareSheetDataTypes.Everyone,
  );
  const owner = items.filter(({ isOwner = false }) => isOwner);

  const collaborator = items.filter(
    ({ isCollaborator = false }) => isCollaborator,
  );

  const channels = items.filter(
    ({ type }) => type === SimplifiedShareSheetDataTypes.Channel,
  );

  return [
    ...owner,
    ...collaborator,
    ...everyone,
    ...channels,
    ...miscellaneousRules,
    ...members,
  ];
};

export const mapRulesFromCriteriaGroupsAndReturnDepartmentsOnly = (
  participationCriteria: CriteriaGroups | undefined,
  visibilityCriteria: CriteriaGroups | undefined,
  totalMembers = 0,
): SimplifiedShareSheetCardData[] => {
  const shouldReturnEmpty = !participationCriteria && !visibilityCriteria;
  if (shouldReturnEmpty) {
    return [];
  }

  const everyone: SimplifiedShareSheetCardData[] = [];
  const isEveryoneAParticipant = isEveryoneCriteria(participationCriteria);
  const isEveryoneAViewer = isEveryoneCriteria(visibilityCriteria);

  if (isEveryoneAParticipant || isEveryoneAViewer) {
    everyone.push({
      id: 'everyone',
      title: 'Everyone in this workspace',
      setting: getMemberSettingForSimpleRules({
        isMemberAParticipant: isEveryoneAParticipant || false,
        isMemberHavingVisibility: isEveryoneAViewer || false,
        isMemberAFlowOwner: false,
      }),
      icon: 'everyone',
      email: `${totalMembers} members`,
      type: SimplifiedShareSheetDataTypes.Everyone,
    });
  }
  const departmentsWhoAreParticipants = participationCriteria
    ? getAutoCompleteOptionsForNonMembersFromCriteria(participationCriteria)
    : [];
  const departmentsWhoAreViewers = visibilityCriteria
    ? getAutoCompleteOptionsForNonMembersFromCriteria(visibilityCriteria)
    : [];

  const departments = uniqBy(
    [...departmentsWhoAreParticipants, ...departmentsWhoAreViewers],
    'id',
  ).map((department) => ({
    ...department,
    setting: getMemberSettingForSimpleRules({
      isMemberAParticipant: isDepartmentPresent(
        department,
        departmentsWhoAreParticipants,
      ),
      isMemberHavingVisibility: isDepartmentPresent(
        department,
        departmentsWhoAreViewers,
      ),
      isMemberAFlowOwner: false,
    }),
  }));

  if (departments.length > 0 || everyone.length > 0) {
    return [...departments, ...everyone];
  }

  return [];
};

// TODO: Sumedha - Make this an object for arguments
export const mapRulesFromCriteriaGroups = (
  criteriaGroups: CriteriaGroups | undefined,
  noSkippedMembers?: boolean,
  canIncludeNewInviteMembers = true,
  isForPayload = false,
  isSimpleShareSheetTreatmentOn = false,
) => {
  if (criteriaGroups) {
    if (criteriaGroups && !criteriaGroups.groups.length) {
      return null;
    }

    if (
      !isSimpleShareSheetTreatmentOn &&
      criteriaGroups.groups.length === 1 &&
      criteriaGroups.groups[0].groupRules.length === 1 &&
      Array.isArray(criteriaGroups.groups[0].groupRules[0].value) &&
      criteriaGroups.groups[0].groupRules[0].value.findIndex(
        (x) => x.id === 'everyone',
      ) > -1
    ) {
      return {
        criteria: {
          everyone: true,
        },
        ...(canIncludeNewInviteMembers && { isNewMembersAdded: true }),
        ...(!noSkippedMembers && { skippedMembers: [] }),
      };
    }
    const customCriteria = {
      rules: getRules(criteriaGroups, isForPayload),
      condition: criteriaGroups.groupsCondition,
    };

    return {
      criteria: {
        custom: { ...customCriteria },
      },
      ...(canIncludeNewInviteMembers && { isNewMembersAdded: true }),
      ...(!noSkippedMembers && { skippedMembers: [] }),
    };
  }

  return null;
};

const isChannelPresent = ({ id }: Channel, channels: Channel[]) =>
  channels.some(({ id: channelId }) => id === channelId);

export const mapRulesFromConnectionsAndReturnSlackChannels = ({
  slackConnectionsParticipantsChannelsList,
  slackConnectionsVisibilityChannelsList,
}: {
  slackConnectionsParticipantsChannelsList: Connection[] | undefined;
  slackConnectionsVisibilityChannelsList: Connection[] | undefined;
}): SimplifiedShareSheetCardData[] => {
  if (
    slackConnectionsParticipantsChannelsList ||
    slackConnectionsVisibilityChannelsList
  ) {
    const participationChannels =
      slackConnectionsParticipantsChannelsList &&
      slackConnectionsParticipantsChannelsList?.length > 0
        ? slackConnectionsParticipantsChannelsList[0]?.metaData?.channelInfo?.map(
            (channel) => ({
              id: channel.id,
              name: channel.name,
              isPrivate: channel.isPrivate,
              displayName: channel.name,
            }),
          )
        : [];

    const visibilityChannels =
      slackConnectionsVisibilityChannelsList &&
      slackConnectionsVisibilityChannelsList?.length > 0
        ? slackConnectionsVisibilityChannelsList[0]?.metaData?.channelInfo?.map(
            (channel) => ({
              id: channel.id,
              name: channel.name,
              isPrivate: channel.isPrivate,
              displayName: channel.name,
            }),
          )
        : [];

    const allSlackChannels = uniqBy(
      [...(participationChannels || []), ...(visibilityChannels || [])],
      'id',
    );

    const workspaceName = [
      ...(slackConnectionsParticipantsChannelsList || []),
      ...(slackConnectionsVisibilityChannelsList || []),
    ]?.find((channel) => !isEmpty(channel?.workspaceName))?.workspaceName;

    const slackChannels = allSlackChannels.map((channel) => {
      const isChannelForParticipation = isChannelPresent(
        channel,
        participationChannels || [],
      );
      const isChannelForVisibility = isChannelPresent(
        channel,
        visibilityChannels || [],
      );
      return {
        id: channel.id,
        title: `Slack Channel is ${channel?.name}`,
        type: SimplifiedShareSheetDataTypes.Channel,
        avatar: SlackChannelAvatar,
        setting: getMemberSettingForSimpleRules({
          isMemberAParticipant: isChannelForParticipation,
          isMemberHavingVisibility: isChannelForVisibility,
          isMemberAFlowOwner: false,
        }),
        email: `${
          !isEmpty(workspaceName) ? workspaceName : ''
        } slack workspace`,
        condition: 'is',
      };
    });
    return slackChannels;
  }
  return [];
};

export const mapRulesFromChannelsAndReturnSlackChannels = ({
  slackConnectionsParticipantsChannelsList,
  slackConnectionsVisibilityChannelsList,
  workspaceName,
}: {
  slackConnectionsParticipantsChannelsList: Channel[] | undefined;
  slackConnectionsVisibilityChannelsList: Channel[] | undefined;
  workspaceName: string;
}): SimplifiedShareSheetCardData[] => {
  if (
    slackConnectionsParticipantsChannelsList ||
    slackConnectionsVisibilityChannelsList
  ) {
    const allSlackChannels = uniqBy(
      [
        ...(slackConnectionsParticipantsChannelsList || []),
        ...(slackConnectionsVisibilityChannelsList || []),
      ],
      'id',
    );
    const slackChannels = allSlackChannels.map((channel) => {
      const isChannelForParticipation = isChannelPresent(
        channel,
        slackConnectionsParticipantsChannelsList || [],
      );
      const isChannelForVisibility = isChannelPresent(
        channel,
        slackConnectionsVisibilityChannelsList || [],
      );
      return {
        id: channel.id,
        title: `Slack Channel is ${channel?.displayName}`,
        type: SimplifiedShareSheetDataTypes.Channel,
        avatar: SlackChannelAvatar,
        setting: getMemberSettingForSimpleRules({
          isMemberAParticipant: isChannelForParticipation,
          isMemberHavingVisibility: isChannelForVisibility,
          isMemberAFlowOwner: false,
        }),
        email: `${
          !isEmpty(workspaceName) ? workspaceName : ''
        } slack workspace`,
        condition: 'is',
      };
    });
    return slackChannels;
  }
  return [];
};
