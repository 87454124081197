import { useMemo } from 'react';
import { useFeatureSplit } from './useFeatureSplit';
import { SplitNames, TreatmentTypes } from './useSplitSdkConfig/constants';

export const useV3EditorEnabled = () => {
  const { treatment: flowCreationV3 } = useFeatureSplit(
    SplitNames.FlowCreation,
  );
  const { treatment: flowEditV3 } = useFeatureSplit(SplitNames.FlowEditV3);

  const isFlowCreation = flowCreationV3 === TreatmentTypes.ON;
  const isFlowEditV3 = flowEditV3 === TreatmentTypes.ON;

  return useMemo(
    () => ({
      isFlowCreation,
      isFlowEditV3: isFlowCreation && isFlowEditV3,
    }),
    [isFlowCreation, isFlowEditV3],
  );
};
