import { ContentState } from 'draft-js';
import React from 'react';

import { TaskToken } from '../TaskToken';

export const TaskDraftDecoratorToken = (props: {
  /**
   * The props are taken from `DraftDecoratorComponentProps`.
   * See the type in https://tinyurl.com/5dzhnbc8 (GitHub).
   */
  children: Array<React.ReactElement>;
  contentState: ContentState;
  decoratedText: string;
  entityKey: string;
}) => {
  const entityData = props.contentState.getEntity(props.entityKey).getData();
  const taskDetails = entityData.task;

  return (
    <TaskToken {...taskDetails} ariaLabel={props.decoratedText}>
      {props.children}
    </TaskToken>
  );
};
