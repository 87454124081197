import React, { memo, useMemo } from 'react';
import { FlowBlockHeaderProps } from './interface';
import { EDIT, LEARN, MORE, PREVIEW } from '../../../languages/en/singleWords';
import { FlowAccordionItemState } from '../FlowAccordionItem/interface';

import ThemeV2 from '../../../theme';

import {
  HeaderRoot,
  IconWrapper,
  StyledButton,
  StyledHeading,
  StyledSVGIcon,
  StyledLockIcon,
} from './styles';

const FlowBlockHeader = (props: FlowBlockHeaderProps) => {
  const {
    icon,
    title,
    className,
    dataTestId = '',
    onEditButtonClick,
    onLearnMoreButtonClick,
    onPreviewButtonClick,
    hidePreviewButton = false,
    state = FlowAccordionItemState.Blur,
  } = props;

  const buttonText = useMemo(() => {
    if (!onPreviewButtonClick && state === FlowAccordionItemState.Focus) {
      return `${LEARN} ${MORE}`;
    }
    if (onPreviewButtonClick) {
      return PREVIEW;
    }
    return EDIT;
  }, [onPreviewButtonClick, state]);

  const buttonClick = useMemo(() => {
    if (
      !onPreviewButtonClick &&
      state === FlowAccordionItemState.Focus &&
      onLearnMoreButtonClick
    ) {
      return onLearnMoreButtonClick;
    }
    if (onPreviewButtonClick) {
      return onPreviewButtonClick;
    }
    return onEditButtonClick;
  }, [onEditButtonClick, onLearnMoreButtonClick, onPreviewButtonClick, state]);

  const headerIconColor = useMemo(() => {
    if (state === FlowAccordionItemState.Blur) {
      return ThemeV2.palette.gray9;
    }
    if (state === FlowAccordionItemState.Focus) {
      return ThemeV2.palette.geekBlue6;
    }
    return ThemeV2.palette.gray6;
  }, [state]);

  return (
    <HeaderRoot
      className={className}
      state={state}
      data-testid={dataTestId}
      onClick={() => {
        if (state !== FlowAccordionItemState.Disabled) {
          onEditButtonClick();
        }
      }}
    >
      <IconWrapper>
        <StyledSVGIcon
          icon={icon}
          state={state}
          data-testid="headerIcon"
          data-qa-id="headerIcon"
          color={headerIconColor}
          size={onPreviewButtonClick && '30px'}
          isFlowBuilderCompleteAccordion={!!onPreviewButtonClick}
        />
      </IconWrapper>
      <StyledHeading variant="h5" weight="medium" state={state}>
        {title}
      </StyledHeading>
      {state !== FlowAccordionItemState.Disabled ? (
        !hidePreviewButton && (
          <StyledButton
            size="small"
            variant="text"
            state={state}
            data-testId="headerButton"
            data-qa-id="headerButton"
            onClick={buttonClick}
            icon={onPreviewButtonClick ? 'eye' : ''}
          >
            {buttonText}
          </StyledButton>
        )
      ) : (
        <StyledLockIcon
          icon="lock"
          size="18px"
          data-testid="lockIcon"
          data-qa-id="lockIcon"
          color={ThemeV2.palette.gray6}
        />
      )}
    </HeaderRoot>
  );
};

const MemoizedFlowBlockHeader = memo(FlowBlockHeader);
MemoizedFlowBlockHeader.displayName = 'FlowBlockHeader';
export default MemoizedFlowBlockHeader;
