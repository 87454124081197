import React, { useEffect } from 'react';
import styled from 'styled-components';
import Card from '../../atomic/atoms/Card';
import Heading from '../../atomic/atoms/Heading';
import Body from '../../atomic/atoms/Body';
import LoadingPulse from '../../atomic/atoms/LoadingPulse';
import GifContainer from '../../atomic/molecules/GifContainer';
import { FLOWS_ONBOARDING_JOB } from '../../constants/endpoints';
import RoutesList from '../../routesList';
import { showErrorMessage } from '../../Utils/toast';
import {
  SETUP_FLOWS_ERROR,
  SETUP_FLOWS_LOADING_INFO,
  SETUP_FLOWS_HEADER,
} from '../../languages/en/flows';
import useMultipleJobsPollingV2 from '../../hooks/useMultipleJobsPolling';
import useHistoryWrapper from '../../hooks/useHistoryWrapper';
import useMobileApp from '../../hooks/useMobileApp/index';
import { MOBILE_APP_URL } from '../../config';
import { Navbar } from '../../atomic/molecules/Navbar';

const CardContainer = styled(Card)`
  padding: 32px;
  width: 500px;
`;

const StyledGifContainer = styled(GifContainer)`
  margin: 24px 0;
`;

const MainContainer = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: center;
`;

const UserSetupController = () => {
  const { isMobileApp, jwtToken } = useMobileApp();
  const history = useHistoryWrapper();
  const { error, isJobStatusSuccessful } = useMultipleJobsPollingV2<undefined>({
    preJobsAPI: {
      apiName: FLOWS_ONBOARDING_JOB,
    },
    enabled: true,
  });
  useEffect(() => {
    if (error) {
      showErrorMessage(SETUP_FLOWS_ERROR);
    }
  }, [error]);
  useEffect(() => {
    if (isJobStatusSuccessful || error) {
      if (isMobileApp) {
        window.location.href = `${MOBILE_APP_URL}home?jwtToken=${jwtToken}`;
      } else {
        history.push(RoutesList.HOME);
      }
    }
  }, [isJobStatusSuccessful, history, error, isMobileApp, jwtToken]);
  return (
    <div>
      <Navbar />
      <MainContainer>
        <CardContainer>
          <Heading variant="h5">{SETUP_FLOWS_HEADER}</Heading>
          <Body variant="body2">{SETUP_FLOWS_LOADING_INFO}</Body>
          <StyledGifContainer src="https://media2.giphy.com/media/SWVzkIlHdEckF81gnA/200.gif" />
          <LoadingPulse size={32} />
        </CardContainer>
      </MainContainer>
    </div>
  );
};

export default UserSetupController;
