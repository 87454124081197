import React, { useEffect, useState } from 'react';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';

import {
  AccordionHeader,
  AccordionHeaderInner,
} from '../../molecules/AccordionItem/styles';
import EmailNotificationSettings, {
  EmailNotificationPreferencesProps,
} from '../../molecules/EmailNotificationSettings';

import { Flex } from '../../../Utils/styles/display';
import { CLOSE, EDIT } from '../../../languages/en/singleWords';
import { SETTINGS_HOW_YOU_NOTIFIED } from '../../../languages/en/settings';
import { EmailRowWrapper, StyledAccordionRoot, StyledSVGIcon } from './styles';
import MobileNotificationSettings from '../../molecules/MobileNotificationSettings';

export interface NotificationSettingsAccordionProps {
  isError: boolean;
  isLoading: boolean;
  hideMobileAppSettings: boolean;
  handleToggle: (notificationId: string) => void;
  onMobileAppToggle: (notificationId: string) => void;
  onFlowItemOptionsToggle: (optionId?: string) => void;
  emailPreferences?: EmailNotificationPreferencesProps;
  mobileAppPreferences?: EmailNotificationPreferencesProps;
  onFlowItemOptionsMobileAppToggle: (optionId?: string) => void;
}

const NotificationSettingsAccordion = ({
  isError,
  isLoading,
  handleToggle,
  emailPreferences,
  onMobileAppToggle,
  mobileAppPreferences,
  hideMobileAppSettings,
  onFlowItemOptionsToggle,
  onFlowItemOptionsMobileAppToggle,
}: NotificationSettingsAccordionProps) => {
  const [isEmailSettingsCollapsed, setIsEmailSettingsCollapsed] =
    useState(false);

  const [
    isNotificationsSettingsCollapsed,
    setIsNotificationsSettingsCollapsed,
  ] = useState(false);

  useEffect(() => {
    setIsEmailSettingsCollapsed(!hideMobileAppSettings);
  }, []);

  return (
    <StyledAccordionRoot>
      <AccordionHeader>
        <AccordionHeaderInner>
          <Body variant="body1Bold">{SETTINGS_HOW_YOU_NOTIFIED}</Body>
        </AccordionHeaderInner>
      </AccordionHeader>

      <EmailRowWrapper>
        <Flex justifyContent="space-between" width="100%">
          <Flex>
            <StyledSVGIcon icon="mail" />
            <Body variant="body2Bold">Email Notifications</Body>
          </Flex>
          <Button
            variant="text"
            size="small"
            onClick={() =>
              setIsEmailSettingsCollapsed(!isEmailSettingsCollapsed)
            }
          >
            {isEmailSettingsCollapsed ? EDIT : CLOSE}
          </Button>
        </Flex>
      </EmailRowWrapper>

      <EmailNotificationSettings
        isError={isError}
        isLoading={isLoading}
        onToggle={handleToggle}
        preferences={emailPreferences}
        isCollapsed={isEmailSettingsCollapsed}
        onFlowItemOptionsToggle={onFlowItemOptionsToggle}
      />

      {!hideMobileAppSettings && (
        <>
          <EmailRowWrapper>
            <Flex justifyContent="space-between" width="100%">
              <Flex>
                <StyledSVGIcon icon="flow_notification" />
                <Body variant="body2Bold">Mobile App Notifications</Body>
              </Flex>
              <Button
                variant="text"
                size="small"
                onClick={() =>
                  setIsNotificationsSettingsCollapsed(
                    !isNotificationsSettingsCollapsed,
                  )
                }
              >
                {isNotificationsSettingsCollapsed ? EDIT : CLOSE}
              </Button>
            </Flex>
          </EmailRowWrapper>
          <MobileNotificationSettings
            isError={isError}
            isLoading={isLoading}
            onToggle={onMobileAppToggle}
            preferences={mobileAppPreferences}
            isCollapsed={isNotificationsSettingsCollapsed}
            onFlowItemOptionsToggle={onFlowItemOptionsMobileAppToggle}
          />
        </>
      )}
    </StyledAccordionRoot>
  );
};

export default NotificationSettingsAccordion;
