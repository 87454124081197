export interface RewardsSwagsInfo {
  state: 'CONNECTED' | 'DISCONNECTED';
  name: 'AXOMO';
  uid: string;
  meta: InfoDetails;
  visibility: 'ON' | 'OFF';
  shippingCost: {
    currency: string;
    value: string;
  };
  exchangeRate: {
    source: {
      currency: string;
      value: number;
    };
    target: {
      currency: string;
      value: number;
    };
  };
}

interface InfoDetails {
  storeName: string;
  email: string;
}

export interface DisconnectAxomoPayload {
  storeName: string;
}

export interface ConnectAxomoPayload {
  storeName: string;
  token: string;
}

export interface AxomoPlaceOrderPayload {
  storeUID: string;
  uid: string;
  size: {
    id: string;
    name: string;
  };
  color: {
    id: string;
    name: string;
  };
  quantity: number;
  company?: string;
  shipping: {
    method: string | null;
    tier: number;
    address: {
      firstName: string;
      lastName: string;
      addressLine1: string;
      addressLine2?: string;
      zipCode: string;
      country: {
        name: string;
        code: string;
      };
      city: {
        name: string;
        code: string;
      };
      state: {
        name: string;
        code: string;
      };
      phone: string;
    };
  };
}

export interface MessageInterface {
  message: string;
}
export enum SwagCardStatuses {
  REDEEMABLE = 'REDEEMABLE',
  COMING_SOON = 'COMING_SOON',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

export interface InventorySwagsInterface {
  name: string;
  desc: string;
  image: string;
  productLink: string;
  rewardType: string;
  rewardProvider: string;
  uid: string;
  enabled: boolean;
  points: Array<number>;
  status: SwagCardStatuses;
}

export interface ShippingDatesDetails {
  estimatedShipDate: string;
  estimatedDeliveryDate: string;
}

interface ToggleRewardsPayloadDetails {
  storeUID: string;
  all?: boolean;
  itemIds?: string[];
}
export interface ToggleRewardsInterface {
  payload: ToggleRewardsPayloadDetails;
  status: 'ON' | 'OFF';
}

export interface AxomoShippingCountry {
  continentCode: string;
  countryCode: string;
  name: string;
  shortName: string;
}

export interface AxomoStatesResponse {
  data: { name: string; code: string }[];
}
