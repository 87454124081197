import React, { useMemo } from 'react';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import { Flex } from '../../../Utils/styles/display';
import Popover from '../Popover';
import SVGIcon from '../../atoms/SVGIcon';
import {
  POST_ANONYMOUSLY,
  POST_ANONYMOUSLY_AND_PRIVATELY,
  POST_PRIVATELY,
  SAVE_AS_A_DRAFT,
} from '../../../languages/en/flows/participation';
import { POST } from '../../../languages/en/singleWords';
import DropdownItem from '../DropdownListItem_V2';

const First = styled(Button)`
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
`;

const DropdownButton = styled(Button)`
  border-radius: 0 4px 4px 0;
  padding: 16px 8px;
  & span {
    line-height: 16px;
  }
`;

const StyledPopoverContent = styled.div`
  min-width: fit-content;
  padding: 4px;
`;

export interface SplitButtonProps {
  disabled?: boolean;
  isLoading?: boolean;
  isPrivatePost: boolean;
  allowPrivateResponse?: boolean;
  onPrivatePostToggle: () => void;
  onPostClick: () => void;
  isAnonymousPost: boolean;
  allowAnonymousResponse?: boolean;
  isAnonymityEnabled?: boolean;
  isDraftMode?: boolean;
  onAnonymousPostToggle?: () => void;
  onCloseAndSaveDraftClick: (draftSaveValues?: {
    saveRequested: boolean;
    isNewDraft?: boolean;
    onDraftSave?: () => void;
  }) => void;
}

const SplitButton = ({
  disabled = false,
  isLoading = false,
  isPrivatePost,
  isAnonymousPost,
  allowPrivateResponse,
  allowAnonymousResponse,
  isAnonymityEnabled,
  isDraftMode,
  onPostClick,
  onPrivatePostToggle,
  onAnonymousPostToggle,
  onCloseAndSaveDraftClick,
}: SplitButtonProps) => {
  const firstButtonText = useMemo(() => {
    if ((isAnonymousPost || isAnonymityEnabled) && isPrivatePost) {
      return POST_ANONYMOUSLY_AND_PRIVATELY;
    }
    if (isAnonymousPost || isAnonymityEnabled) {
      return POST_ANONYMOUSLY;
    }
    if (isPrivatePost) {
      return POST_PRIVATELY;
    }
    return POST;
  }, [isAnonymityEnabled, isAnonymousPost, isPrivatePost]);
  return (
    <Flex>
      <First
        onClick={onPostClick}
        disabled={disabled}
        isLoading={isLoading}
        size="large"
      >
        {firstButtonText}
      </First>
      <Popover
        containerPadding="2px"
        disabled={disabled || isLoading}
        popoverPosition={isAnonymityEnabled ? 'top' : 'bottom-end'}
        elementPadding="4px"
        referenceContent={
          <DropdownButton
            dataTestId="splitButtonDropdown"
            disabled={disabled}
            size="large"
          >
            <SVGIcon icon="caret-rounded" size="16px" color="gray1" />
          </DropdownButton>
        }
        popoverContent={
          <StyledPopoverContent>
            <DropdownItem
              value={SAVE_AS_A_DRAFT}
              id="draft"
              onItemClick={() =>
                onCloseAndSaveDraftClick({
                  saveRequested: true,
                  isNewDraft: isDraftMode,
                })
              }
            />
            {allowAnonymousResponse && onAnonymousPostToggle && (
              <DropdownItem
                value={POST_ANONYMOUSLY}
                id="anonymous"
                onItemClick={() => {
                  onAnonymousPostToggle();
                  onPostClick();
                }}
              />
            )}
            {allowPrivateResponse && (
              <DropdownItem
                value={POST_PRIVATELY}
                id="private"
                onItemClick={() => {
                  onPrivatePostToggle();
                  onPostClick();
                }}
              />
            )}
            {allowAnonymousResponse &&
              onAnonymousPostToggle &&
              allowPrivateResponse && (
                <DropdownItem
                  value={POST_ANONYMOUSLY_AND_PRIVATELY}
                  id="anonymousAndPrivate"
                  onItemClick={() => {
                    onAnonymousPostToggle();
                    onPrivatePostToggle();
                    onPostClick();
                  }}
                />
              )}
          </StyledPopoverContent>
        }
      />
    </Flex>
  );
};

export default SplitButton;
