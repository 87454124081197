import { useMemo } from 'react';
import pluralize from 'pluralize';
import { useParams } from 'react-router-dom';

import { ShareSheetParticipantsControllerProps } from '../index';
import { PARTICIPANT } from '../../../../../languages/en/singleWords';
import { CriteriaGroups } from '../../../../../interfaces/Flow/Builder';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { mapRulesFromCriteriaGroups } from '../../../../../Utils/flows/criteria';
import { GetMembersForPreviewRequest } from '../../../../../interfaces/Sharesheet';
import { useGetFinalizedMembersFromCriteriaForPreview } from '../../../../../hooks/useGetMembersFromCriteriaForPreview';
import { getCriteriaPayloadForParticipation } from '../../../../../Utils/flows/sharesheet';

const useShareSheetParticipantsController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const { variant, participantsBlockData, updateParticipantsBlockData } = props;
  const { participantsCriteria } = participantsBlockData;

  const rulesFromCriteria = useMemo(
    () =>
      mapRulesFromCriteriaGroups(participantsCriteria, undefined, true, true),
    [participantsCriteria],
  );

  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowBuilderData } = useFetchFlowDetailsQuery(flowId, 'builder');

  const {
    isLoading,
    data: participants,
    hasNextPage: hasMoreMembers,
    totalMembersCount: participantsCount,
    fetchNextPage: fetchMoreMembers,
    pendingMembersCount: pendingMembersParticipantCount,
  } = useGetFinalizedMembersFromCriteriaForPreview({
    ...getCriteriaPayloadForParticipation(
      rulesFromCriteria?.criteria,
      flowBuilderData,
    ),
  } as GetMembersForPreviewRequest);

  const handleOnCriteriaGroupsChange = (value: CriteriaGroups | undefined) => {
    updateParticipantsBlockData({
      ...participantsBlockData,
      participantsCriteria: value,
    });
  };

  const subtitleContent = useMemo(() => {
    return participantsCount > 0
      ? `${participantsCount} ${pluralize(
          PARTICIPANT,
          participantsCount,
          false,
        )}`
      : '';
  }, [participantsCount]);

  return {
    models: {
      variant,
      isLoading,
      flowBuilderData,
      hasNextPage: hasMoreMembers,
      participants,
      subtitleContent,
      participantsCount,
      pendingMembersParticipantCount,
      participantsCriteriaGroups: participantsCriteria,
    },
    operations: {
      fetchNextPage: fetchMoreMembers,
      handleOnCriteriaGroupsChange,
    },
  };
};

export default useShareSheetParticipantsController;
