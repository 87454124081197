import React, { ChangeEventHandler, useCallback, useState } from 'react';

import { BaseEmoji, EmojiData } from 'emoji-mart';
import { Popover } from '@mui/material';
import ThemeV2 from '../../../theme';
import {
  FLOW_NAME_MAX_LENGTH_ERROR,
  PLACEHOLDERS,
} from '../../../languages/en/flows/builder';

import { Flex } from '../../../Utils/styles/display';
import {
  FormBuilderPageHeaderRoot,
  StyledFlex,
  StyledFullWidthFlex,
  StyledHeader,
  StyledTitleTextField,
  StyledToggleButtonStyle,
} from './styles';

import Button from '../../atoms/Button';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { CREATE, FLOW, SHARE } from '../../../languages/en/singleWords';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import FlowBuilderSaveSplitButton from '../FlowBuilderSaveSplitButton';
import { BuilderFlowNameCharacterLimit } from '../../../constants/flowsBuilder';
import { RESTART_FLOW } from '../../../languages/en/flows';
import { StyledButtonContainer } from '../FlowsFeedHeader/styles';
import { getColorById } from '../../../Utils/flows/colors';
import EmoticonPicker from '../../atoms/EmoticonPicker';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { setShareSheetModalOpenSelector } from '../../../stores/flowBuilderStore/selectors';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import useLayoutStore from '../../../stores/layoutStore';

export interface FormBuilderPageHeaderProps {
  flowId: string;
  className?: string;
  titleValue: string;
  isInEditMode: boolean;
  isLoading?: boolean;
  emoji: BaseEmoji | null;
  onTitleTextBlur: () => void;
  isScheduledFlowEnded: boolean;
  onEditButtonClick: () => void;
  isPreviewButtonEnabled: boolean;
  isEditFlowButtonDisabled: boolean;
  onRevertChangesButtonClick: () => void;
  onEmojiChange: (arg: BaseEmoji) => void;
  previewButtonClicked: (arg: any) => void;
  onTitleTextChange: ChangeEventHandler<HTMLInputElement>;
}

const FormBuilderPageHeader = ({
  emoji,
  flowId,
  isLoading,
  isInEditMode,
  className,
  titleValue,
  onEmojiChange,
  onTitleTextBlur,
  onTitleTextChange,
  onEditButtonClick,
  previewButtonClicked,
  isScheduledFlowEnded,
  isEditFlowButtonDisabled,
  onRevertChangesButtonClick,
  isPreviewButtonEnabled = false,
}: FormBuilderPageHeaderProps) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const handleEmoticonSelect = useCallback(
    (selectedEmojiData: EmojiData) => {
      const currentEmoji = selectedEmojiData as BaseEmoji;
      if (currentEmoji.native) {
        onEmojiChange(currentEmoji);
      }
    },
    [onEmojiChange],
  );

  const hasError = titleValue.length > BuilderFlowNameCharacterLimit;

  const ActionButton = isScheduledFlowEnded ? (
    <StyledButtonContainer>
      <Button
        icon="sync"
        onClick={onEditButtonClick}
        color="primary"
        size="medium"
        data-qa-id="btn-flow-edit"
      >
        {RESTART_FLOW}
      </Button>
      <Button
        icon="share"
        onClick={() => {
          trackShareSheetActionEvent({
            action: SHARE_SHEET_EVENTS.SHARE_SHEET_STARTED,
            shareFlowStartSource: 'flow header',
            tab: 'invite and share',
          });
          setShareSheetModalOpen(true);
        }}
        data-qa-id="btn-introjs"
        color="primary"
        size="medium"
        className="introjs-sharesheet-share-button"
      >
        {SHARE}
      </Button>
    </StyledButtonContainer>
  ) : (
    <StyledButtonContainer>
      <Button
        icon="share"
        onClick={() => setShareSheetModalOpen(true)}
        color="primary"
        size="medium"
        data-test-id="share-button"
        data-qa-id="btn-share"
        className="introjs-sharesheet-share-button"
      >
        {SHARE}
      </Button>
    </StyledButtonContainer>
  );

  const emoticonBg = getColorById(flowId);

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = useCallback(
    (emoticon: EmojiData) => {
      handleEmoticonSelect(emoticon);
      handleClose();
    },
    [handleEmoticonSelect],
  );

  const isEmoticonPopoverOpen = Boolean(anchorEl);
  const id = isEmoticonPopoverOpen ? 'emoticonPopover' : undefined;

  return (
    <FormBuilderPageHeaderRoot
      flexDirection="row"
      className={className}
      isEmbeddedInMainApp={isEmbeddedInMainApp}
    >
      <div>
        <StyledToggleButtonStyle
          aria-describedby={id}
          disabled={Boolean(flowId) && !isInEditMode}
          onClick={handleClick}
          size={ButtonSize.Large}
          isRounded={false}
        >
          {emoji ? (
            <EmoticonHolder
              emoticon={emoji.native}
              color={emoticonBg}
              size={EmoticonHolderSizes.Medium2}
              borderColor={ThemeV2.palette.gray6}
            />
          ) : (
            <StyledHeader
              variant="h5"
              weight="regular"
              color={ThemeV2.palette.gray6}
            >
              +
            </StyledHeader>
          )}
        </StyledToggleButtonStyle>
        <Popover
          id={id}
          open={isEmoticonPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          data-qa-id="btn-popover"
        >
          <EmoticonPicker onEmoticonSelect={handleOnClick} />
        </Popover>
      </div>
      <StyledFlex>
        <StyledFullWidthFlex flexDirection="column" alignItems="flex-start">
          <StyledTitleTextField
            disabled={Boolean(flowId) && !isInEditMode}
            placeholder={PLACEHOLDERS.DEFAULT_TITLE_TEXT}
            value={titleValue}
            data-qa-id={`inp-${titleValue}`}
            onChange={onTitleTextChange}
            autoComplete="off"
            hasError={hasError}
            onBlur={onTitleTextBlur}
            helperText={hasError ? FLOW_NAME_MAX_LENGTH_ERROR : ''}
            fullWidth
          />
        </StyledFullWidthFlex>
      </StyledFlex>
      <Flex>
        {flowId &&
          (!isInEditMode ? (
            ActionButton
          ) : (
            <FlowBuilderSaveSplitButton
              onRevertChangesButtonClick={onRevertChangesButtonClick}
              disabled={isEditFlowButtonDisabled}
              onSaveButtonClick={() => {
                previewButtonClicked(null);
              }}
              isLoading={isLoading}
            />
          ))}

        {!flowId && (
          <Button
            icon="add-new"
            disabled={!isPreviewButtonEnabled}
            onClick={previewButtonClicked}
            status="success"
            isLoading={isLoading}
          >
            {`${CREATE} ${FLOW}`}
          </Button>
        )}
      </Flex>
    </FormBuilderPageHeaderRoot>
  );
};

export default FormBuilderPageHeader;
