import styled from 'styled-components';
import Modal from '../../atoms/Modal';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';
import Button from '../../atoms/Button';
import { device } from '../../../constants/layout';

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 545px;
  min-height: 576px;
  max-height: 100vh;

  @media ${device.desktop} {
    max-height: 90vh;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
`;

export const StyledHeading = styled(Heading)`
  margin: 0px auto;
`;

export const StyledIconButton = styled(IconButton)``;

export const ModalContent = styled.div`
  padding: 32px 36px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid ${ThemeV2.palette.gray4};
  margin-top: auto;
`;

export const StyledButton = styled(Button)`
  &:first-child {
    margin-right: 12px;
  }
`;
