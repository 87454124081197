import cookie from 'react-cookies';
import Client from '../../vendors/assembly_metrics';
import { checkIfProduction } from '../admin';

export const EventType = {
  REGISTRATION: 'REGISTRATION',
  PROFILE_COMPLETE: 'PROFILE_COMPLETE',
  SUBSCRIPTION: 'SUBSCRIPTION',
};

export function trackEvent(eventType, props) {
  const { userId } = props;

  const lander = cookie.load('landing_url') || null;
  const referrer = cookie.load('referring_url') || null;
  const userIdStored = cookie.load('userId');

  if (userId && userId !== userIdStored) {
    cookie.save('userId', userId, { path: '/' });
  }

  let type;
  switch (eventType) {
    case EventType.REGISTRATION:
      type = Client.eventTypes.REGISTRATION;
      break;
    case EventType.PROFILE_COMPLETE:
      type = Client.eventTypes.PROFILE_COMPLETE;
      break;
    case EventType.SUBSCRIPTION:
      type = Client.eventTypes.SUBSCRIPTION;
      break;
    default:
      type = eventType;
  }

  Client.event({
    environment: checkIfProduction() ? 'production' : 'development',
    type,
    lander,
    referrer,
    ...props,
  });
}
