import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';

export enum RadioOptionManagerValueTypes {
  DirectReport = 'directReport',
  MemberIsManager = 'memberIsManager',
  MemberIsNotManager = 'memberIsNotManager',
}

export type OptionItemProps = {
  label: string;
  value: string;
  defaultLabel?: string;
};

export type SelectedCriteriaManagerProps = {
  field: string;
  operator: string;
  value: AutocompleteDropdownItem<string>[];
};

export type FlowsManagerBuilderOptionProps<T extends string> = {
  className?: string;
  selectedOptionId: string;
  radioOptions: OptionItemProps[];
  onInputChange: (e: string) => void;
  autocompleteItems: AutocompleteDropdownItem<T>[];
  onAddButtonClick: (data: SelectedCriteriaManagerProps) => void;
  isLoading: boolean;
  onOptionsScroll: () => void;
};
