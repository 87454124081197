import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import IconContainer from '../IconContainer';
import { StyledPrimaryNavItem } from '../PrimaryNavItem/styles';
import SVGIcon from '../SVGIcon';
import { NavItemProps } from './types';

export const StyledNavItem = styled(StyledPrimaryNavItem)<NavItemProps>`
  position: relative;
  margin: 0;
  color: ${({ color }) => color};
  transition: 0.2s ease;
  transition-property: color, background;
  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
    background-color: ${ThemeV2.palette.geekBlue1};

    svg,
    > svg > path:last-of-type {
      fill: initial;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
    background-color: ${ThemeV2.palette.geekBlue2};
    color: ${ThemeV2.palette.geekBlue6};
  `}
`;

export const StyledEmoticon = styled.span`
  margin-right: 4px;
`;

export const Badge = styled.span`
  display: inline-block;
  background: ${({ color }) => color || ThemeV2.palette.geekBlue6};
  padding: 0 8px;
  margin-left: auto;
  border-radius: 25px;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-right: 4px;
`;

export const StyledSVGPrefixIcon = styled(SVGIcon)`
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  svg {
    margin-right: 0px;
  }
`;

export const ChildrenWrapper = styled.div`
  width: 100%;
`;

export const StyledIconContainer = styled(IconContainer)`
  margin-right: 8px;
  color: ${({ color }) => color};
`;

export const StyledMainIcon = styled(SVGIcon)`
  color: ${({ color }) => color};
  margin-right: 0;
  svg {
    margin-right: 0;
  }
  path {
    fill: ${({ color }) => color};
  }
`;
