import React, { useEffect } from 'react';
import Collapse from '@mui/material/Collapse';
import styled from 'styled-components';

import useToggle from '../../../hooks/useToggle';
import SVGIcon from '../SVGIcon';
import isEmpty from 'lodash/isEmpty';

export type CollapseProps = {
  collapseHeader: React.ReactNode;
  children: React.ReactNode;
  collapseOpenCallback?: (val: boolean) => void;
  isCollapseOpen?: boolean;
  className?: string;
  iconProps?:
    | {
        iconName: string;
        iconSize: string;
      }
    | undefined;
  headerHoverColor?: string;
};

const CollapseHeader = styled.div<{
  headerHoverColor?: string;
  hasCustomIcon?: boolean;
}>`
  cursor: default;
  display: ${({ hasCustomIcon }) => (hasCustomIcon ? 'flex' : 'inherit')};
  align-items: ${({ hasCustomIcon }) => (hasCustomIcon ? 'center' : 'inherit')};

  &:hover {
    background: ${({ headerHoverColor }) => headerHoverColor || 'inherit'};
  }
`;

const StyledSVGIcon = styled(SVGIcon)`
  margin-left: 12px;
  padding: 10px;
`;

const CollapseComponent = (props: CollapseProps) => {
  const {
    collapseHeader,
    children,
    collapseOpenCallback,
    isCollapseOpen = false,
    className,
    iconProps,
    headerHoverColor,
  } = props;
  const {
    models: { toggleValue: collapseOpen },
    operations: { setToggleValue: toggleCollapseOpen, setToggleToTrue },
  } = useToggle();

  const toggleCollapseOpenHandler = () => {
    if (collapseOpenCallback) {
      collapseOpenCallback(!collapseOpen);
    }
    toggleCollapseOpen();
  };

  useEffect(() => {
    if (isCollapseOpen) {
      setToggleToTrue();
    }
  }, [isCollapseOpen, setToggleToTrue]);

  return (
    <div className={className}>
      <CollapseHeader
        onClick={toggleCollapseOpenHandler}
        role="button"
        tabIndex={0}
        onKeyPress={toggleCollapseOpenHandler}
        headerHoverColor={headerHoverColor}
        hasCustomIcon={!isEmpty(iconProps)}
      >
        {iconProps && (
          <StyledSVGIcon
            icon={iconProps.iconName || 'caret'}
            size={iconProps.iconSize || '10px'}
            rotate={collapseOpen ? 180 : 0}
          />
        )}
        {collapseHeader}
      </CollapseHeader>
      <Collapse in={collapseOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapseComponent;
