import React from 'react';

import { ActionBarProps, FeedTypes } from './types';
import {
  StyledActionBar,
  StyledPromptToAnswerMain,
  StyledPromptToAnswerWrapper,
  StyledSVGIcon,
  StyledBody,
  DraftTag,
  StyledTooltip,
} from './styles';
import Avatar from '../../atoms/Avatar';

const ActionBar = (props: ActionBarProps) => {
  const {
    avatar = '',
    userName = '',
    userId = '',
    text = '',
    onClick,
    feedType,
    isDisabled = false,
    isAnonymous = false,
    hasDraft = false,
    tooltipContent,
  } = props;

  return (
    <StyledTooltip
      toolTipComponent={
        <StyledActionBar
          onClick={onClick}
          disabled={isDisabled}
          data-testid="action-bar"
          data-qa-id="btn-flow-actionbar"
        >
          <StyledPromptToAnswerWrapper>
            <Avatar
              img={avatar}
              name={userName}
              userId={userId}
              size="48px"
              isAnonymous={isAnonymous}
              {...(isAnonymous && { iconSize: '40px' })}
            />
            <StyledPromptToAnswerMain>
              <StyledBody variant="body2Medium" color="geekBlue6">
                {text}
              </StyledBody>
              {hasDraft && <DraftTag variant="body3Medium">{'DRAFT'}</DraftTag>}
              {feedType === FeedTypes.Main && (
                <StyledSVGIcon icon="triangle-down" size="16px" />
              )}
            </StyledPromptToAnswerMain>
          </StyledPromptToAnswerWrapper>
        </StyledActionBar>
      }
      disabled={!tooltipContent}
    >
      {tooltipContent}
    </StyledTooltip>
  );
};

export default ActionBar;
