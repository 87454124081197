import { useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CheckboxFilterLoadedProps,
  CheckboxFilterProps,
  CheckboxFilterValues,
} from '../../../../atomic/organism/CheckboxFilter/types';
import { DateFilterProps } from '../../../../atomic/organism/DateFilter/types';
import { SelectedFlowFeedFilterValueProps } from '../../../../atomic/organism/RightDrawerFilter/FlowFeedFilter/types';
import { ComponentStatus } from '../../../../interfaces/component';
import {
  FILTER_BY_DIFFERENT_BLOCK,
  FILTER_BY_ANOTHER_MEMBER,
  FILTER_BY_DIFFERENT_VERSION,
  POSTED_BY,
  SELECT_BLOCKS,
  SELECT_PERSON,
  SELECT_VERSION,
} from '../../../../languages/en/flows';
import {
  BLOCK,
  DATES,
  MENTIONED,
  VERSION,
} from '../../../../languages/en/singleWords';

import {
  useGetVersionsFilterOptionsQuery,
  useGetBlocksFilterOptionsQuery,
  useGetPostedByFilterOptionsQuery,
  useGetMentionsFilterOptionsQuery,
  useGetDateFilterOptionsQuery,
} from '../../../../queries/RightDrawer/Filter';
import {
  mapBlocksDataToFilterOptions,
  mapPersonDataToFilterOptions,
  mapVersionDataToFilterOptions,
  serializeFilterValues,
  isDateEqual,
  isArrayEqual,
  createQueryParam,
  getPostedByOptionsWithAnonymousFilter,
} from '../../utils';
import { FilterControllerProps, QueryStringTypes } from '../../types';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';

import useGetPageName from '../../../../hooks/useGetPageName';
import { RIGHT_DRAWER_EVENTS } from '../../../../Utils/analytics/constants';
import { useFetchFlowDetailsQuery } from '../../../../queries/Flows/Feed';
import { DrawerTabType } from '../../../../Utils/analytics/interfaces';
import { getFlowPerms } from '../../../../Utils/flows/feeds';
import {
  getAppliedFilter,
  GetAppliedFilterProps,
  getFiltersForAnonymousPostedBy,
} from '../../../../Utils/rightDrawer';
import { trackRightDrawerEvent } from '../../../../Utils/analytics';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import useLayoutStore from '../../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';

type FlowFeedFilterProps = FilterControllerProps & {
  flowId: string;
};

const useFilterControllerLogic = ({
  setIsFiltered,
  flowId,
}: FlowFeedFilterProps) => {
  const { search, pathname } = useLocation();
  const history = useHistoryWrapper();

  const {
    receivedStartDate,
    receivedEndDate,
    receivedVersionsId,
    receivedBlocksID,
    receivedPostedById,
    receivedMentionedById,
    parsedParams,
    isAnonymousPostedBy,
  } = useGetParsedFilterOptions();

  const { data: flowDetailsData } = useFetchFlowDetailsQuery(flowId);

  const { closeRightSideBarForMobile } = useLayoutStore(
    (state) => ({
      closeRightSideBarForMobile: state.closeRightSideBarForMobile,
    }),
    shallow,
  );

  const { page } = useGetPageName();

  const drawerTabViewed: DrawerTabType = 'filter';

  const {
    data: dateFilterData,
    isError: isDateFilterError,
    isInitialLoading: isDateFilterLoading,
  } = useGetDateFilterOptionsQuery(flowId);

  const {
    data: versionsData,
    isError: isVersionsDataError,
    isInitialLoading: isVersionsDataLoading,
  } = useGetVersionsFilterOptionsQuery(flowId);

  const {
    data: blocksData,
    isError: isBlocksDataError,
    isInitialLoading: isBlocksDataLoading,
  } = useGetBlocksFilterOptionsQuery(flowId);

  const {
    data: postedByData,
    isError: isPostedByError,
    isInitialLoading: isPostedByLoading,
  } = useGetPostedByFilterOptionsQuery(flowId);

  const {
    data: mentionsData,
    isError: isMentionsDataError,
    isInitialLoading: isMentionDataLoading,
  } = useGetMentionsFilterOptionsQuery(flowId);

  const [startDateValue, setStartDate] = useState<Date | undefined>(() =>
    receivedStartDate !== undefined ? new Date(receivedStartDate) : undefined,
  );
  const [endDateValue, setEndDate] = useState<Date | undefined>(() =>
    receivedEndDate !== undefined ? new Date(receivedEndDate) : undefined,
  );
  const [versionSelectedOptions, setVersionSelectedOptions] =
    useState<string[]>(receivedVersionsId);
  const [blockSelectedOptions, setBlockSelectedOptions] =
    useState<string[]>(receivedBlocksID);
  const [postedBySelectedOptions, setPostedSelectedByOptions] =
    useState<string[]>(receivedPostedById);
  const [mentionedSelectedOptions, setMentionedSelectedOptions] = useState<
    string[]
  >(receivedMentionedById);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isAnonymousPostedByFilter, setAnonymousPostedByFilter] =
    useState(isAnonymousPostedBy);

  const initialStartDate = useMemo(() => {
    if (dateFilterData?.startDate) {
      return new Date(dateFilterData?.startDate);
    }
    return undefined;
  }, [dateFilterData]);

  const versionOptions = useMemo(() => {
    if (versionsData?.data) {
      return mapVersionDataToFilterOptions(versionsData);
    }
    return [];
  }, [versionsData]);

  const blockOptions = useMemo(() => {
    if (blocksData?.data) {
      return mapBlocksDataToFilterOptions(blocksData);
    }
    return [];
  }, [blocksData]);

  const postedByOptions: CheckboxFilterValues = useMemo(() => {
    if (postedByData?.data) {
      const postedByOptionsFromData = mapPersonDataToFilterOptions(
        postedByData,
        'postedBy',
      );
      if (postedBySelectedOptions && postedBySelectedOptions?.length > 0) {
        return getPostedByOptionsWithAnonymousFilter(
          postedByOptionsFromData,
          isAnonymousPostedByFilter,
        );
      }
      return postedByOptionsFromData;
    }
    return [];
  }, [isAnonymousPostedByFilter, postedByData, postedBySelectedOptions]);

  const mentionedOptions: CheckboxFilterValues = useMemo(() => {
    if (mentionsData?.data) {
      return mapPersonDataToFilterOptions(mentionsData, 'mentionedBy');
    }
    return [];
  }, [mentionsData]);

  useEffect(() => {
    const { isAnonymous } = getFiltersForAnonymousPostedBy(
      postedBySelectedOptions,
    );
    setAnonymousPostedByFilter(isAnonymous);
  }, [postedBySelectedOptions]);

  const collapseClickHandler = useCallback(
    (isCollapseOpen: boolean) => {
      if (isCollapseOpen) {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_COLLAPSED, {
          feedViewed: page,
          drawerTabViewed,
          flowId,
          flowName: flowDetailsData?.data.name,
        });
      } else {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_EXPANDED, {
          feedViewed: page,
          drawerTabViewed,
          flowId,
          flowName: flowDetailsData?.data.name,
        });
      }
    },
    [flowDetailsData, flowId, page],
  );

  const dateFilterProps: DateFilterProps = {
    status: ComponentStatus.LOADED,
    startDate: startDateValue,
    endDate: endDateValue,
    onStartDateChange: setStartDate,
    onEndDateChange: setEndDate,
    initialStartDate,
    headingText: DATES,
    onCollapseClick: collapseClickHandler,
  };

  const versionFilterProps: CheckboxFilterProps = {
    status: ComponentStatus.LOADED,
    headingText: VERSION,
    options: versionOptions,
    selectedOptions: versionSelectedOptions,
    onSelectedOptionChange: setVersionSelectedOptions,
    filterByText: FILTER_BY_DIFFERENT_VERSION,
    autoCompleteText: SELECT_VERSION,
    onCollapseClick: collapseClickHandler,
  };

  const blockFilterProps: CheckboxFilterProps = {
    status: ComponentStatus.LOADED,
    headingText: BLOCK,
    options: blockOptions,
    selectedOptions: blockSelectedOptions,
    onSelectedOptionChange: setBlockSelectedOptions,
    filterByText: FILTER_BY_DIFFERENT_BLOCK,
    autoCompleteText: SELECT_BLOCKS,
    onCollapseClick: collapseClickHandler,
  };

  const postedByFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: POSTED_BY,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: postedByOptions,
    onSelectedOptionChange: setPostedSelectedByOptions,
    selectedOptions: postedBySelectedOptions,
    onCollapseClick: collapseClickHandler,
    disableFilterBy: isAnonymousPostedByFilter,
  };

  const mentionedFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: MENTIONED,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: mentionedOptions,
    onSelectedOptionChange: setMentionedSelectedOptions,
    selectedOptions: mentionedSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const selectedFlowFeedFilterValue: SelectedFlowFeedFilterValueProps = {
    selectedDates: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    selectedVersion: versionSelectedOptions,
    selectedBlock: blockSelectedOptions,
    selectedPostedBy: serializeFilterValues(
      postedBySelectedOptions,
      'postedBy',
    ),
    selectedMentionedBy: serializeFilterValues(
      mentionedSelectedOptions,
      'mentionedBy',
    ),
  };

  const handleOnSubmit = () => {
    const { postedBy: filteredPostedBy } = getFiltersForAnonymousPostedBy(
      selectedFlowFeedFilterValue?.selectedPostedBy || [],
    );

    const startDate = startDateValue?.toString().replace('+', '%2b');
    const endDate = endDateValue?.toString().replace('+', '%2b');
    const versionId = selectedFlowFeedFilterValue.selectedVersion.join();
    const blockId = selectedFlowFeedFilterValue.selectedBlock?.join();
    const postedBy = filteredPostedBy?.join();
    const mentionedBy = selectedFlowFeedFilterValue.selectedMentionedBy?.join();

    const queryString: QueryStringTypes = {
      startDate,
      endDate,
      versionId,
      blockId,
      postedBy,
      mentionedBy,
      isAnonymousPostedByFilter,
    };

    history.push(createQueryParam(queryString, `/flows/${flowId}?`));

    closeRightSideBarForMobile();

    const isNotFiltered =
      startDateValue === undefined &&
      endDateValue === undefined &&
      versionSelectedOptions.length === 0 &&
      blockSelectedOptions.length === 0 &&
      postedBySelectedOptions.length === 0 &&
      mentionedSelectedOptions.length === 0;

    if (isNotFiltered) {
      setIsFiltered(false);
    } else {
      setIsFiltered(true);
    }

    const getAppliedFilterProps: GetAppliedFilterProps = {
      startDate: startDateValue,
      endDate: endDateValue,
      versionId: versionSelectedOptions,
      blockId: blockSelectedOptions,
      postedBy: postedBySelectedOptions,
      mentioned: mentionedSelectedOptions,
    };

    const filterApplied = getAppliedFilter(getAppliedFilterProps);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_APPLIED, {
      filterApplied,
      feedViewed: page,
      drawerTabViewed,
      flowId,
      flowPerms: getFlowPerms(flowDetailsData?.data),
      flowName: flowDetailsData?.data.name,
    });
  };

  const handleOnClear = useCallback(() => {
    setStartDate(undefined);
    setEndDate(undefined);
    setVersionSelectedOptions([]);
    setBlockSelectedOptions([]);
    setPostedSelectedByOptions([]);
    setMentionedSelectedOptions([]);
    setIsFiltered(false);

    history.push(`/flows/${flowId}?`);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_CLEARED, {
      feedViewed: page,
      drawerTabViewed,
      flowId,
      flowPerms: getFlowPerms(flowDetailsData?.data),
      flowName: flowDetailsData?.data.name,
    });
  }, [page, flowId, history, setIsFiltered, flowDetailsData]);

  useEffect(() => {
    /* while navigating between flows, state persists & this to avoid that */
    if (Object.keys(parsedParams).length === 0) {
      handleOnClear();
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pathname]);

  const isAllOptionsAreEmpty =
    versionOptions.length === 0 &&
    blockOptions.length === 0 &&
    postedByOptions.length === 0 &&
    mentionedOptions.length === 0;

  useEffect(() => {
    if (initialStartDate === undefined) {
      setIsEmpty(true);
    }
    if (isAllOptionsAreEmpty) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [initialStartDate, isAllOptionsAreEmpty]);

  const isFilterOptionsRemainSame =
    isDateEqual(startDateValue, receivedStartDate) &&
    isDateEqual(endDateValue, receivedEndDate) &&
    isArrayEqual(versionSelectedOptions, receivedVersionsId) &&
    isArrayEqual(blockSelectedOptions, receivedBlocksID) &&
    isArrayEqual(postedBySelectedOptions, receivedPostedById) &&
    isArrayEqual(mentionedSelectedOptions, receivedMentionedById);

  const isLoading =
    isDateFilterLoading ||
    isVersionsDataLoading ||
    isBlocksDataLoading ||
    isPostedByLoading ||
    isMentionDataLoading;

  const isError =
    isDateFilterError ||
    isVersionsDataError ||
    isBlocksDataError ||
    isPostedByError ||
    isMentionsDataError;

  return {
    models: {
      isAnonymousPostedByFilter,
      dateFilterProps,
      versionFilterProps,
      blockFilterProps,
      postedByFilterProps,
      mentionedFilterProps,
      isLoading,
      isError,
      isEmpty,
      isFilterButtonEnabled: isFilterOptionsRemainSame,
    },
    operations: {
      handleOnSubmit,
      handleOnClear,
    },
  };
};

export default useFilterControllerLogic;
