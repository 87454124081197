import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
export interface SVGIconProps {
  icon: string;
  size?: string;
  color?: string;
  flip?: boolean;
  rotate?: number;
  stroke?: string;
  hoverColor?: string;
  className?: string;
  circleStroke?: string;
  fillAllPaths?: boolean;
}

const getTransform = ({ rotate, flip }: Partial<SVGIconProps>) =>
  `rotate(${rotate}deg)${flip ? ' scaleX(-1) scaleY(-1)' : ''}`;

const StyledIcon = styled.span<Partial<SVGIconProps>>`
  display: inline-flex;
  color: ${(props) => props.color};
  // sizes relative to set font-size
  vertical-align: top;
  min-width: ${(props) => props.size};
  height: ${(props) => props.size};
  text-align: center;
  transform: ${(props) => getTransform(props)};
  transition: transform 0.1s ease-out;
  & > svg {
    align-self: center;
    height: ${(props) => props.size};
    min-width: ${(props) => props.size};
    display: block;
    fill: ${(props) => props.color};
    & > path:last-of-type {
      fill: ${(props) => props.color};
      stroke: ${(props) => props.stroke};
    }
    & > circle {
      fill: ${(props) => props.color};
      stroke: ${(props) => props.circleStroke};
    }
    ${(props) =>
      props.fillAllPaths &&
      `& > path {
      fill: ${props.color};
      stroke: ${props.stroke};
    }`};
  }
  &:hover {
    & > svg {
      & > path:last-of-type {
        fill: ${(props) => props.hoverColor};
        stroke: ${(props) => props.hoverColor};
      }
      & > circle {
        stroke: ${(props) => props.hoverColor};
      }
    }
  }
`;

function useDynamicSVGImport(icon) {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        ImportedIconRef.current = (
          await import(`./icons/${icon}.svg`)
        ).ReactComponent;
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [icon]);

  return { error, loading, SvgIcon: ImportedIconRef.current as any };
}

const IconRender = ({ icon, ...rest }: SVGIconProps) => {
  const { error, loading, SvgIcon } = useDynamicSVGImport(icon);
  if (error || loading || !SvgIcon) {
    return <div style={{ height: '24px', width: '24px' }}></div>;
  }

  return <SvgIcon {...rest} />;
};

const StyledSVGIcon = styled(IconRender)<Partial<SVGIconProps>>`
  display: inline-flex;
  color: ${(props) => props.color};
  // sizes relative to set font-size
  vertical-align: top;
  min-width: ${(props) => props.size};
  height: ${(props) => props.size};
  text-align: center;
  transform: ${(props) => getTransform(props)};
  transition: transform 0.1s ease-out;
  & > svg {
    align-self: center;
    height: ${(props) => props.size};
    min-width: ${(props) => props.size};
    display: block;
    fill: ${(props) => props.color};
    & > path:last-of-type {
      fill: ${(props) => props.color};
      stroke: ${(props) => props.stroke};
    }
    & > circle {
      fill: ${(props) => props.color};
      stroke: ${(props) => props.circleStroke};
    }
  }
  &:hover {
    & > svg {
      & > path:last-of-type {
        fill: ${(props) => props.hoverColor};
        stroke: ${(props) => props.hoverColor};
      }
      & > circle {
        stroke: ${(props) => props.hoverColor};
      }
    }
  }
`;

/**
 * How to use SVGIcon :
 * 1. Export svg in SVG format from Figma and add it inside icons folder of this Component
 * 2. Replace the path's fill with 'currentColor' value
 * 3. Pass file's name as 'icon' prop to this Component to render the SVG
 * 4. Its better to have viewBox="0 0 24 24" and remove height and width of the svg
 **/
const SVGIcon = ({ icon, className, ...rest }: SVGIconProps) => {
  let svg;
  try {
    svg = (
      <StyledSVGIcon
        className={className}
        data-testid={`SVGIcon-${icon}`}
        data-qa-id={`SVGIcon-${icon}`}
        {...rest}
        data-cy={icon}
        icon={icon}
      />
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Icon not found:', `${icon}.svg`, e);
    svg = '<span>x</span>';
  }
  return (
    <StyledIcon
      className={className}
      data-testid={`SVGIcon-${icon}`}
      data-qa-id={`SVGIcon-${icon}`}
      {...rest}
      data-cy={icon}
    >
      {svg}
    </StyledIcon>
  );
};

SVGIcon.defaultProps = {
  flip: false,
  rotate: 0,
  size: '24px',
  color: '',
  stroke: '',
  hoverColor: undefined,
  className: '',
};

export default SVGIcon;
