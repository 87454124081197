import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  ESTABLISH_AXOMO_CONNECTION,
  GET_AXOMO_INFO,
  BREAK_AXOMO_CONNECTION,
  FETCH_INVENTORY_SWAGS,
  TOGGLE_REWARD_ITEMS,
  GET_PROFILE_INFO,
  TOGGLE_REWARDS_VISIBILITY,
  UPDATE_REWARD_ITEM,
  AXOMO_REWARD_CATALOGUE,
  UPDATE_SHIPPING_COST,
  AXOMO_PLACE_ORDER,
  GET_AXOMO_SHIPPING_COUNTRIES,
  GET_STATES_FROM_COUNTRY_CODE,
} from '../../../../constants/endpoints';
import { REWARDS_SWAGS } from '../../../../languages/en/admin';
import { showSuccessMessage } from '../../../../Utils/toast';
import { makeAPICallWithDataReturn, makeAPICall } from '../../../utils';
import {
  RewardsSwagsInfo,
  MessageInterface,
  InventorySwagsInterface,
  ToggleRewardsInterface,
  AxomoPlaceOrderPayload,
  DisconnectAxomoPayload,
  ConnectAxomoPayload,
  AxomoShippingCountry,
  AxomoStatesResponse,
} from './interface';

export const useEstablishAxomoConnection = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ConnectAxomoPayload) =>
      makeAPICall(ESTABLISH_AXOMO_CONNECTION, payload),
    {
      onSuccess: async () => {
        showSuccessMessage(REWARDS_SWAGS.AXOMO_CONNECTED_MESSAGE);
        await queryClient.invalidateQueries([GET_AXOMO_INFO]);
        await queryClient.invalidateQueries([GET_PROFILE_INFO]);
      },
      onError: () => queryClient.invalidateQueries([GET_AXOMO_INFO]),
    },
  );
};

export const useFetchRewardsSwagsInfoQuery = (makeRequest = true) => {
  return useQuery<RewardsSwagsInfo | MessageInterface>(
    [GET_AXOMO_INFO],
    () => makeAPICallWithDataReturn(GET_AXOMO_INFO),
    {
      enabled: makeRequest,
      staleTime: 60 * 5 * 1000,
      refetchOnWindowFocus: false,
    },
  );
};

export const useDisconnectAxomoConnection = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: DisconnectAxomoPayload) =>
      makeAPICall(BREAK_AXOMO_CONNECTION, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_AXOMO_INFO]);
        queryClient.invalidateQueries([AXOMO_REWARD_CATALOGUE]);
      },
      onError: () => queryClient.invalidateQueries([GET_AXOMO_INFO]),
    },
  );
};

export const useFetchInventorySwagsQuery = (
  storeUID: string,
  makeRequest?: boolean,
) => {
  return useQuery<InventorySwagsInterface[] | MessageInterface>(
    [FETCH_INVENTORY_SWAGS, storeUID],
    () => makeAPICallWithDataReturn(FETCH_INVENTORY_SWAGS),
    {
      enabled: makeRequest || false,
      refetchOnWindowFocus: false,
    },
  );
};

export const useToggleRewards = () => {
  return useMutation(({ payload, status }: ToggleRewardsInterface) => {
    return makeAPICall(TOGGLE_REWARD_ITEMS, payload, undefined, {
      status,
    });
  });
};

export const useUpdateRewardItem = () => {
  return useMutation(({ payload, params }: any) =>
    makeAPICall(UPDATE_REWARD_ITEM, payload, undefined, params),
  );
};

export const useUpdateShippingCost = () => {
  return useMutation(({ payload }: any) =>
    makeAPICall(UPDATE_SHIPPING_COST, payload),
  );
};

export const useAxomoPlaceOrder = () => {
  return useMutation((payload: AxomoPlaceOrderPayload) =>
    makeAPICallWithDataReturn(AXOMO_PLACE_ORDER, payload),
  );
};

export const useGetAxomoShippingCountries = (makeRequest: boolean) => {
  return useQuery<AxomoShippingCountry[]>(
    [GET_AXOMO_SHIPPING_COUNTRIES],
    () => makeAPICallWithDataReturn(GET_AXOMO_SHIPPING_COUNTRIES),
    {
      enabled: makeRequest,
    },
  );
};

export const useGetAxomoShippingStatesFromCountryCode = (
  countryCode: string,
  makeRequest: boolean,
) => {
  return useQuery<AxomoStatesResponse>(
    [GET_STATES_FROM_COUNTRY_CODE, countryCode],
    () =>
      makeAPICallWithDataReturn(
        GET_STATES_FROM_COUNTRY_CODE,
        undefined,
        undefined,
        { countryCode },
      ),
    { enabled: makeRequest },
  );
};

export const useToggleRewardsVisibility = () => {
  return useMutation(({ payload, status }: any) =>
    makeAPICall(TOGGLE_REWARDS_VISIBILITY, payload, undefined, {
      status,
    }),
  );
};
