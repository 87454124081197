import Body from '../../atomic/atoms/Body';
import Button from '../../atomic/atoms/Button';
import BasicModalTemplate from '../../atomic/templates/BasicModalTemplate';
import { Flex } from '../../Utils/styles/display';

export type DeactivateAccountModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading?: boolean;
  onDeactivateAccountClick: () => void;
};

export const DeactivateAccountModal = (props: DeactivateAccountModalProps) => {
  const { isOpen, isLoading, onClose, onDeactivateAccountClick } = props;
  return (
    <BasicModalTemplate
      isOpen={isOpen}
      onModalClose={onClose}
      hasTitleSection
      headerButton={{
        icon: 'carat-left',
        text: '',
        onClick: onClose,
        size: 'small',
        color: 'secondary',
        variant: 'text',
      }}
      hideCloseIcon
      heading="Deactivate my account"
    >
      <Flex flexDirection="column" gap="16px" alignItems="flex-start">
        <Body variant="body2">
          Are you sure you want to deactivate this account? Once you do, you’ll
          have to contact your Assembly workspace admin to reactivate it.
        </Body>
        <Button
          isFullWidth
          status="warning"
          isLoading={isLoading}
          onClick={onDeactivateAccountClick}
        >
          Deactivate my account
        </Button>
      </Flex>
    </BasicModalTemplate>
  );
};
