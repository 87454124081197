import styled from 'styled-components';

import Body from '../../atoms/Body';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import { FeedPostWrapper } from '../FeedPost/styles';
import { device } from '../../../constants/layout';
import Button from '../../atoms/Button';

export const StyledFlowPromptCard = styled(FeedPostWrapper)``;

export const StyledFlowPromptCardBody = styled.div`
  padding: 24px 24px 16px 24px;
`;

export const StyledStatusBar = styled(Body)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const StyledStatusBody = styled(Body)`
  align-items: center;
  display: flex;
  column-gap: 4px;
`;

export const StyledTitleBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-bottom: 8px;
  row-gap: 8px;

  @media ${device.desktop} {
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
  }
`;

export const StyledFlowPromptCardHeader = styled.div`
  margin-bottom: 8px;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  margin-left: auto;
`;

export const StyledButton = styled(Button)`
  margin-top: 16px;
  margin-bottom: 8px;
`;

export const StyledFlowPromptCardFooter = styled.div`
  display: flex;
  padding: 0 16px 8px;
  column-gap: 12px;
`;
