import React, { useCallback, useEffect, useState, memo, useMemo } from 'react';

import { getColor } from '../../../Utils/profilePic';

import { StyledOnlineStatus } from '../../../atomic/atoms/Avatar/styles';
import SVGIcon from '../../../atomic/atoms/SVGIcon';

import {
  AvatarProps,
  StyledAvatarTextProps,
} from '../../../atomic/atoms/Avatar/interfaces';
import ToolTip from '../../../atomic/molecules/ToolTipV2';
import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Theme from '../../../theme';
import useHover from '../../../hooks/useHover';
import { shadowElevation2Style } from '../../../Utils/styles/css';
import DropdownIconButton from '../../../atomic/molecules/DropdownIconButton';
import { MemberState } from '../../../interfaces/user';
import pendingPerson from '../../../img/pendingPerson.svg';
import { trackPeopleDirectoryActionEvent } from '../../../Utils/analytics/peopleDirectory';
import { PEOPLE_DIRECTORY_EVENTS } from '../../../Utils/analytics/constants';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

export const PeopleDirectoryProfilePictureContainer = styled.div<{
  size: string;
  isDeleted?: boolean;
  iconBgColor?: string;
}>`
  position: relative;
  max-width: 100%;
  min-height: 216px;
  width: auto;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const PeopleDirectoryProfilePicBackground = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  span {
    background: ${Theme.palette.gray10};
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    svg {
      height: 33.3%;
      min-width: 33.3%;
    }
    & :hover {
      opacity: 1;
    }
  }
`;

export const Img = styled.img<{ hide: boolean; verticalAlign?: string }>`
  display: ${({ hide }) => (hide ? 'none' : 'initial')};
  vertical-align: ${({ verticalAlign }) => verticalAlign};
  max-width: 278px;
  width: 100%;
  min-height: 216px;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

export const PeopleDirectoryProfilePictureText = styled(
  Flex,
)<StyledAvatarTextProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  font-family: ${Theme.typography.adminFontFamily};
  font-weight: ${Theme.typography.fontWeightSemibold};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
  max-width: 278px;
  min-height: 216px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const DropdownMenuWrapper = styled.div`
  top: 8px;
  right: 8px;
  position: absolute;
  border-radius: 4px;
  ${shadowElevation2Style};
  background-color: ${Theme.palette.white};
`;

const StyledDropdownIconButton = styled(DropdownIconButton)`
  span {
    background: transparent;
  }
`;

const PeopleDirectoryAvatar = ({
  img,
  className: parentClassName,
  size = '24px',
  verticalAlign,
  name = '',
  onHoverClick,
  onlineStatus,
  onlineStatusBorderColor = Theme.palette.white,
  userId = '',
  icon,
  toolTip,
  iconColor = Theme.palette.gray3,
  iconBgColor = Theme.palette.geekBlue6,
  backgroundColor = getColor({ _id: userId }).bg || '',
  onItemClick,
  dropdownItems,
  memberState,
}: AvatarProps) => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const [loading, setLoading] = useState(true);
  const onLoadingComplete = useCallback(() => setLoading(false), []);
  const {
    model: { isHovering },
    operations: {
      handlePopoverClose: onMouseLeave,
      handlePopoverOpen: onMouseEnter,
    },
  } = useHover();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    // THIS USE-EFFECT SHOULD ONLY BE CALLED WHEN IMAGE PROP IS CHANGED.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  const canShowAvatarText = userId && name;

  const renderImageFallback = useMemo(() => {
    // If there is no image url or image not loaded
    if (!icon && loading) {
      if (canShowAvatarText) {
        let avatarText = '';
        let color = '';
        const user = { _id: userId };
        const colors = getColor(user);
        avatarText = name.slice(0, 1).toUpperCase();
        color = colors.color;
        return (
          <PeopleDirectoryProfilePictureText
            size={size}
            backgroundColor={backgroundColor}
            color={color}
            fontSize="80px"
            justifyContent="center"
            alignItems="center"
          >
            {avatarText}
          </PeopleDirectoryProfilePictureText>
        );
      }

      return (
        <SVGIcon icon="user" color={Theme.palette.geekBlue1} size={size} />
      );
    }
    return null;
  }, [icon, loading, canShowAvatarText, userId, name, size, backgroundColor]);

  const renderAvatarContainer = () => {
    return (
      <PeopleDirectoryProfilePictureContainer
        size={size}
        className={parentClassName}
        iconBgColor={iconBgColor}
        data-testid="avatar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {(isHovering || isMobileView) && (
          <DropdownMenuWrapper>
            <StyledDropdownIconButton
              menuItems={dropdownItems || []}
              icon="more"
              title="more"
              onItemClick={onItemClick}
              iconColor={Theme.palette.gray9}
              iconBgColor={Theme.palette.gray3}
              stopEventPropagation
              onClick={() => {
                trackPeopleDirectoryActionEvent({
                  action: PEOPLE_DIRECTORY_EVENTS.PEOPLE_PROFILE_MENU_OPENED,
                });
              }}
            />
          </DropdownMenuWrapper>
        )}
        {icon ? (
          <SVGIcon icon={icon} color={iconColor} />
        ) : (
          <Img
            src={img}
            alt={name ? `${name} avatar` : 'Avatar'}
            onLoad={onLoadingComplete}
            hide={loading}
            data-testid={img}
            loading="eager"
            verticalAlign={verticalAlign}
          />
        )}
        {renderImageFallback}
        {onHoverClick && (
          <PeopleDirectoryProfilePicBackground
            data-testid="avatar-background"
            onClick={onHoverClick}
          >
            <SVGIcon icon="bin" color={Theme.palette.gray1} />
          </PeopleDirectoryProfilePicBackground>
        )}
        {onlineStatus && (
          <StyledOnlineStatus
            onlineStatus={onlineStatus}
            onlineStatusBorderColor={onlineStatusBorderColor}
            data-testid="online-status"
          />
        )}
      </PeopleDirectoryProfilePictureContainer>
    );
  };

  if (toolTip) {
    return (
      <ToolTip position="top" toolTipComponent={renderAvatarContainer()}>
        {toolTip}
      </ToolTip>
    );
  }

  if (memberState === MemberState.PENDING) {
    return (
      <Img
        src={pendingPerson}
        alt={name ? `${name} avatar` : 'Avatar'}
        onLoad={onLoadingComplete}
        hide={loading}
        data-testid="pending-person"
        loading="eager"
        verticalAlign={verticalAlign}
      />
    );
  }
  return renderAvatarContainer();
};

const MemoizedPeopleDirectoryAvatar = memo(PeopleDirectoryAvatar);
MemoizedPeopleDirectoryAvatar.displayName = 'PeopleDirectoryAvatar';

export default MemoizedPeopleDirectoryAvatar;
