import React from 'react';

import { PulseProps } from './types';
import { StyledPulse } from './styles';

const Pulse = (props: PulseProps) => {
  const { size = 20, color = '#000' } = props;

  return <StyledPulse size={size} color={color} data-testid="pulse" />;
};

export default Pulse;
