import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../theme';
import { StyledFlex } from './styles';

const EventsLoader = () => (
  <StyledFlex padding="12px" justifyContent="center">
    <ContentLoader
      speed={2}
      width={236}
      height={40}
      viewBox="0 0 236 40"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
    >
      <rect x="0" y="0" rx="4" ry="4" width="232" height="38" />
    </ContentLoader>
  </StyledFlex>
);

export default EventsLoader;
