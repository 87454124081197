import { useMemo } from 'react';

import { isUserAdmin, isUserManager } from '../../Utils/user';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { useFetchFlowDetailsQuery } from '../../queries/Flows/Feed';
import { useLocation, useParams } from 'react-router-dom';

const useGetUserFlowPermissions = () => {
  const location = useLocation<{ flowIdToDuplicate: string }>();
  const { flowId } = useParams<{ flowId: string }>();

  const { data } = useProfileInfoFetchQuery();
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId || location.state?.flowIdToDuplicate,
    '',
    Boolean(flowId || location.state?.flowIdToDuplicate),
  );

  const userFlowPermissions = data?.assembly.flow?.creation.value;
  const isAdmin = Boolean(data?.member && isUserAdmin(data.member));
  const isManager = Boolean(data?.member && isUserManager(data.member));

  const canCreateFlows = useMemo(() => {
    const flowsPermissionSelectOptions: AutocompleteDropdownItem<string>[] = [
      {
        title: 'Entire organization',
        id: 'EVERYONE',
      },
      {
        title: 'Managers',
        id: 'MANAGER',
      },
      {
        title: 'Workspace Owners & Admins only',
        id: 'ADMINS_AND_OWNER',
      },
    ];

    const flowOwnerId = flowDetails?.data?.owner?.memberID;
    const collaborators = flowDetails?.data?.collaborators;
    const userId = data?.member?.memberId;

    const isCollaborator = collaborators?.some(
      (collaborator) => collaborator.memberID === userId,
    );

    if (isAdmin || isCollaborator) {
      return true;
    }

    switch (userFlowPermissions) {
      case flowsPermissionSelectOptions[0].id:
        return true;
      case flowsPermissionSelectOptions[1].id:
        return isManager;
      case flowsPermissionSelectOptions[2].id: {
        return flowOwnerId === userId;
      }
      default:
        return false;
    }
  }, [isAdmin, userFlowPermissions, isManager, flowDetails, data]);

  return {
    canUserCreateFlow: canCreateFlows,
  };
};

export default useGetUserFlowPermissions;
