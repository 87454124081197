import { ReactNode } from 'react';

export enum FeedTypes {
  Main = 'MAIN',
  Flow = 'FLOW',
  Profile = 'PROFILE',
}

export type ActionBarGenericProps = {
  avatar?: string;
  userName?: string;
  userId?: string;
  text: string;
  feedType: FeedTypes;
  isDisabled?: boolean;
  isAnonymous?: boolean;
  hasDraft?: boolean;
  tooltipContent?: ReactNode;
};

export type ActionBarProps = ActionBarGenericProps & {
  onClick: () => void;
};
