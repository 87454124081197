import React from 'react';

import TriggerConfigureSettings from '../../../../atomic/organism/TriggerConfigureSettings';

import { ScheduleDateTimeControllerTypes } from './types';
import useScheduleDateTimeController from './useScheduleDateTimeController';
import IconInfo from '../../../../atomic/molecules/IconInfo';
import ThemeV2 from '../../../../theme';
import Body from '../../../../atomic/atoms/Body';
import styled from 'styled-components';

const InfoBanner = styled(IconInfo)`
  margin-bottom: 8px;
`;

const ScheduleDateTimeController = (props: ScheduleDateTimeControllerTypes) => {
  const { models } = useScheduleDateTimeController(props);
  const {
    flowStartProps,
    flowRecurProps,
    flowDeadlineProps,
    flowEndProps,
    flowShortcutConfigurationProps,
    flowTimezoneProps,
    customRecurrenceProps,
  } = models;

  return (
    <div>
      <InfoBanner
        backgroundColor={ThemeV2.palette.geekBlue2}
        icon="info-icon"
        iconColor={ThemeV2.palette.geekBlue6}
        iconSize="16px"
        paddingTopBottom="6px"
        paddingLeftRight="6px"
      >
        <Body variant="body3">
          To notify participants, Assembly will send out an email when you
          trigger the flow.
        </Body>
      </InfoBanner>
      <TriggerConfigureSettings
        triggerType="SCHEDULED"
        flowStartProps={flowStartProps}
        flowRecurProps={flowRecurProps}
        flowDeadlineProps={flowDeadlineProps}
        flowEndProps={flowEndProps}
        flowShortcutConfigurationProps={flowShortcutConfigurationProps}
        flowTimezoneProps={flowTimezoneProps}
        customRecurrenceProps={customRecurrenceProps}
      />
    </div>
  );
};

export default ScheduleDateTimeController;
