import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { FlowState } from '../../../Utils/analytics/interfaces';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import Body from '../../../atomic/atoms/Body';

import FlowLeftDrawerCollapsibleNavigation from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { StyledPrefixIcon } from '../../../atomic/organism/LeftNav/styles';
import FlowsMenuAccordion from '../../../atomic/organism/MenuAccordion/FlowsMenuAccordion';
import { FLOWS_FEEDS } from '../../../constants/routes';
import useFlowsListAccordion from '../../../hooks/useFlowsListAccordion';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { ComponentStatus } from '../../../interfaces/component';
import { LeftNavHorizontalRule } from '../../layout/PrimaryNavController/styles';
import { useAddNewFlowCallback } from '../FlowsActiveListController/useAddNewFlowCallback';
import FlowsArchivedListController from '../FlowsArchivedListController';

import axios from 'axios';
import { Droppable } from 'react-beautiful-dnd';
import { useImmer } from 'use-immer';
import {
  trackFolderActionEvent,
  trackFolderErrorEvent,
} from '../../../Utils/analytics/folder';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import {
  FolderColor,
  folderColorTextMap,
} from '../../../atomic/atoms/FolderColorPicker';
import { FLOWS_LEFT_DRAWER_FEED_EMPTY } from '../../../languages/en/flows';
import { useFolderDeletionMutation } from '../../../queries/folderAPI';
import ThemeV2 from '../../../theme';
import { FolderModal } from './FolderModal';
import { LeftNavHeaderSuffix } from './LeftNavHeaderSuffix';

type NavFlowGroupProps = {
  isAddNewFlowOn: boolean;
  isHovered: boolean;
  flows: LeftDrawerCollapsibleNavItemProps[];
  title: string;
  isCustomFolder: boolean;
  folderColor: string;
  id: string;
  groupIndex: number;
  hasUnreads: boolean;
  allFlowsAreUnread: boolean;
};

export const DraggedCard = styled.div<{ isDragging: boolean }>`
  ${({ isDragging }) =>
    isDragging &&
    css`
      background-color: ${ThemeV2.palette.gray3};
    `}
`;

const CollapsedHeader = styled(Body)<{ bgColor: string | undefined }>`
  ${({ bgColor }) =>
    bgColor &&
    css`
      margin-left: 24px;

      background-color: ${bgColor};
      padding: 0 8px;
      border-radius: 4px;
    `}
`;

export function NavFlowGroup(props: NavFlowGroupProps) {
  const {
    flowsDropdownMenuItems,
    handleAccordionClick,
    handleMenuItemClick: onMenuItemClick,
    handleMenuItemOptionsSelect,
    isOpen,
    onDropdownMenuItemRightClick,
    toggleExpandNavigation,
  } = useFlowsListAccordion();

  const history = useHistoryWrapper();

  const handleMenuItemClick = useCallback(
    (id?: string) => {
      onMenuItemClick();
      const flowFeedLink = FLOWS_FEEDS.replace(':flowId', `${id}`);
      history.push(flowFeedLink);
    },
    [history, onMenuItemClick],
  );

  const getFlowItemOnMenuClick = (id: string) =>
    props.flows.find((flow) => flow.id === id);

  const handleAddNewFlowClick = useAddNewFlowCallback(
    props.isCustomFolder ? props.id : undefined,
  );

  const folderModalInitialState = {
    name: '',
    id: props.isCustomFolder ? props.id : undefined,
    isOpen: false,
    color: '',
    currentSection: props.isCustomFolder ? props.id : 'uncategorized',
  };

  const [folderModalDetails, setFolderModalDetails] = useImmer(
    folderModalInitialState,
  );

  const titleStyle = props.folderColor
    ? {
        color: folderColorTextMap[props.folderColor as FolderColor],
        bgColor: props.folderColor,
      }
    : undefined;

  const { mutateAsync: deleteFolder } = useFolderDeletionMutation();

  const onFolderDelete = useCallback(() => {
    (async () => {
      const result = await deleteFolder(props.id);

      if (axios.isAxiosError(result)) {
        showErrorMessage('Failed to delete folder');
        trackFolderErrorEvent({
          error: 'folderDeleted',
          customFolderName: props.title,
          folder: 'customFolder',
        });
      } else {
        trackFolderActionEvent({
          action: 'folderDeleted',
          customFolderName: props.title,
          folder: 'customFolder',
        });
        showSuccessMessage('Folder deleted');
      }
    })();
  }, [deleteFolder, props.id, props.title]);

  return (
    <Droppable type="flow" droppableId={props.id}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {props.flows.length ? (
            <>
              <FlowsMenuAccordion
                status={ComponentStatus.LOADED}
                isAccordionOpen={isOpen}
                accordionTitle={props.title}
                onAccordionClick={handleAccordionClick}
                navItems={props.flows}
                onMenuItemClick={(id) => {
                  handleMenuItemClick(id as string);
                  const flowDetails = getFlowItemOnMenuClick(id as string);
                  trackLeftDrawerActionEvent({
                    action:
                      LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_CLICKED,
                    ...flowDetails,
                    folder: props.isCustomFolder ? 'customFolder' : 'flows',
                    flowState: FlowState.Default,
                  });
                }}
                dropdownMenuItems={flowsDropdownMenuItems}
                onDropdownMenuItemClick={handleMenuItemOptionsSelect}
                onMenuItemRightClick={onDropdownMenuItemRightClick}
                isAddNewFlowOn={props.isAddNewFlowOn}
                isHover={props.isHovered}
                canAddFlows
                addFlowsButtonClick={handleAddNewFlowClick}
                hasUnreadItems={props.hasUnreads}
                headerPrefixComponent={
                  props.isCustomFolder ? (
                    <div style={{ display: 'flex' }}>
                      <CollapsedHeader bgColor={props.folderColor}>
                        {props.title[0]}
                      </CollapsedHeader>
                    </div>
                  ) : (
                    <StyledPrefixIcon icon="routine" size="24px" />
                  )
                }
                hasDraggableElements
                headerSuffixComponent={
                  <LeftNavHeaderSuffix
                    isCustomFolder={props.isCustomFolder}
                    onFlowCreate={handleAddNewFlowClick}
                    onFolderCreate={() => {
                      setFolderModalDetails((draft) => {
                        draft.isOpen = true;
                        draft.id = undefined;
                        draft.currentSection = props.id;
                      });
                    }}
                    onFolderEdit={() => {
                      setFolderModalDetails((draft) => {
                        draft.name = props.title;
                        draft.id = props.id;
                        draft.color = props.folderColor;
                        draft.isOpen = true;
                      });
                      trackFolderActionEvent({
                        action: 'editFolderClicked',
                        folder: 'customFolder',
                        customFolderName: props.title,
                      });
                    }}
                    onFolderDelete={onFolderDelete}
                  />
                }
                titleStyle={titleStyle}
                droppablePlaceholder={provided.placeholder}
              />
              {!props.isCustomFolder && isOpen && (
                <FlowsArchivedListController
                  droppablePlaceholder={provided.placeholder}
                  isHover={props.isHovered}
                  disableDragAndDrop={false}
                />
              )}
              <LeftNavHorizontalRule />
            </>
          ) : (
            <>
              <FlowLeftDrawerCollapsibleNavigation
                groupIndex={props.groupIndex}
                folderId={props.id}
                showInlineAddFlowSection={false}
                isExpanded={isOpen}
                isHovered={props.isHovered}
                onExpandClick={toggleExpandNavigation}
                heading={props.title}
                canAddFlows
                addFlowsButtonClick={handleAddNewFlowClick}
                status={ComponentStatus.EMPTY}
                isAddNewFlowOn={props.isAddNewFlowOn}
                titleStyle={titleStyle}
                droppablePlaceholder={provided.placeholder}
                emptySectionContent={
                  props.isCustomFolder ? (
                    props.allFlowsAreUnread ? (
                      'These flows have moved temporarily.'
                    ) : snapshot.isDraggingOver ? (
                      <></>
                    ) : (
                      <>
                        <span role="presentation" aria-label="">
                          🍃
                        </span>
                        <span> No flows in this folder</span>
                      </>
                    )
                  ) : (
                    FLOWS_LEFT_DRAWER_FEED_EMPTY
                  )
                }
                hasUnreadItems={props.hasUnreads}
                headerSuffixComponent={
                  <LeftNavHeaderSuffix
                    isCustomFolder={props.isCustomFolder}
                    onFlowCreate={handleAddNewFlowClick}
                    onFolderCreate={() => {
                      setFolderModalDetails((draft) => {
                        draft.isOpen = true;
                        draft.id = undefined;
                        draft.currentSection = props.id;
                      });
                    }}
                    onFolderEdit={() => {
                      setFolderModalDetails((draft) => {
                        draft.name = props.title;
                        draft.id = props.id;
                        draft.color = props.folderColor;
                        draft.isOpen = true;
                      });
                      trackFolderActionEvent({
                        action: 'editFolderClicked',
                        folder: 'customFolder',
                        customFolderName: props.title,
                      });
                    }}
                    onFolderDelete={onFolderDelete}
                  />
                }
              />
              {props.isCustomFolder && <LeftNavHorizontalRule />}
            </>
          )}
          {folderModalDetails.isOpen && (
            <FolderModal
              name={folderModalDetails.name}
              id={folderModalDetails.id}
              color={folderModalDetails.color}
              isCreateMode={!folderModalDetails.id}
              beforeSection={folderModalDetails.currentSection}
              onClose={() => setFolderModalDetails(folderModalInitialState)}
            />
          )}
        </div>
      )}
    </Droppable>
  );
}
