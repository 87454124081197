import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { useEffect } from 'react';
import { Channel } from 'pusher-js';
import { pusher } from '../../pusher/pusher-base';
import {
  GET_FLOW_FEED,
  GET_FLOW_REPLIES_SUMMARY,
  GET_PROFILE_FEED,
  V3_GET_FEED,
} from '../../constants/endpoints';
import { FlowFeedResponse } from '../../queries/Flows/Feed/interfaces';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import produce from 'immer';
import {
  FilterPayload,
  GetFeedResponse,
  PostFilterOptions,
  PostSortOptions,
} from '../../interfaces/Feed';
import { findInPaginatedFlowFeedData } from '../../queries/Flows/Feed/utils';
import { findInPaginatedFeedData } from '../../queries/Feed/utils';

type ReplyEvent = {
  postId?: string;
  flowId?: string;
  responseId?: string;
  commentId: string;
  boost?: string[];
};

export const useRepliesUpdatedSocket = ({
  flowFeedsSort,
  filter,
  profileId,
}: {
  flowFeedsSort?: PostSortOptions;
  filter?: FilterPayload;
  profileId?: string;
}) => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();

  useEffect(() => {
    let channelAssembly: Channel | undefined;
    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);
    }

    const repliesUpdatedEvents = [
      'NEW_COMMENT',
      'COMMENT_DELETED',
      'COMMENT_EDITED',
    ];

    const handleCallback = async (event: string, payload: ReplyEvent) => {
      if (repliesUpdatedEvents.some((ev) => ev === event)) {
        const { postId, flowId, responseId, boost } = payload;
        const id = postId ? postId : responseId;
        const repliesSummaryQueryKey = [GET_FLOW_REPLIES_SUMMARY, id];
        if (boost && data?.member.memberId) {
          const isCurrentMemberBoosted = boost.includes(data.member.memberId);
          if (isCurrentMemberBoosted) {
            window.parent.postMessage({ type: 'INVALIDATE_USER_DETAILS' }, '*');
          }
        }
        switch (event) {
          case 'NEW_COMMENT':
            {
              const flowFeedQueryKey = [
                GET_FLOW_FEED,
                flowFeedsSort,
                flowId,
                filter,
              ];
              const flowFeedCache:
                | InfiniteData<PaginationResponse<FlowFeedResponse>>
                | undefined = queryClient.getQueryData(flowFeedQueryKey);
              if (flowFeedCache) {
                const updatedData = produce(flowFeedCache, (draft) => {
                  const flowPost = findInPaginatedFlowFeedData(
                    draft.pages,
                    responseId ?? '',
                  );
                  if (flowPost) {
                    if (
                      flowPost.responseId === responseId &&
                      flowPost.commentsCount === 0
                    ) {
                      flowPost.commentsCount += 1;
                    }
                  }
                });
                queryClient.setQueryData(flowFeedQueryKey, updatedData);
              }
              const profileFeedQueryKey = [GET_PROFILE_FEED, profileId, filter];
              const profileFeedCache:
                | InfiniteData<GetFeedResponse>
                | undefined = queryClient.getQueryData(profileFeedQueryKey);
              if (profileFeedCache) {
                const updatedData = produce(profileFeedCache, (draft) => {
                  const flowPost = findInPaginatedFeedData(
                    draft.pages,
                    responseId || '',
                  );
                  if (flowPost?.flowResponse) {
                    if (
                      flowPost.flowResponse.responseId === responseId &&
                      flowPost.flowResponse.commentsCount === 0
                    ) {
                      flowPost.commentsCount += 1;
                    }
                  } else {
                    if (flowPost?.postID === postId && flowPost) {
                      flowPost.commentsCount += 1;
                    }
                  }
                });
                queryClient.setQueryData(profileFeedQueryKey, updatedData);
              }
              const recognitionFeedQueryKey = [
                V3_GET_FEED,
                PostFilterOptions.ALL,
                PostSortOptions.DESC,
              ];
              const recognitionFeedCache:
                | InfiniteData<GetFeedResponse>
                | undefined = queryClient.getQueryData(recognitionFeedQueryKey);
              if (recognitionFeedCache) {
                const updatedData = produce(recognitionFeedCache, (draft) => {
                  const updatedPost = findInPaginatedFeedData(
                    draft.pages,
                    postId ? postId || '' : responseId || '',
                  );
                  if (postId) {
                    if (updatedPost) {
                      if (updatedPost.postID === postId) {
                        updatedPost.commentsCount += 1;
                      } else {
                        if (
                          updatedPost.flowResponse?.responseId === responseId
                        ) {
                          updatedPost.commentsCount += 1;
                        }
                      }
                    }
                  }
                });

                queryClient.setQueryData(recognitionFeedQueryKey, updatedData);
              }

              await queryClient.cancelQueries(repliesSummaryQueryKey, {
                type: 'all',
              });
              await queryClient.invalidateQueries(repliesSummaryQueryKey);
            }
            break;
          case 'COMMENT_DELETED':
            {
              await queryClient.cancelQueries(repliesSummaryQueryKey, {
                type: 'all',
              });
              await queryClient.invalidateQueries(repliesSummaryQueryKey);
            }
            break;
        }
      }
    };

    channelAssembly?.bind_global(handleCallback);
    return () => {
      channelAssembly?.unbind_global(handleCallback);
    };
  }, [data, filter, flowFeedsSort, profileId, queryClient]);
};
