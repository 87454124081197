import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_ARCHIVED_FLOWS,
  GET_FLOW_DETAILS,
  GET_MAIN_NAV_CONTENTS,
  TRIGGER_FLOW,
  TRIGGER_UNARCHIVE_FLOW,
} from '../../../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../../../utils';
import {
  GetFlowArchivedResponse,
  TriggerFlowPayload,
  UnArchiveFlowPayload,
} from './types';

export const useTriggerFlow = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: TriggerFlowPayload) =>
      makeAPICall(
        TRIGGER_FLOW,
        { endTimeInMinutes: payload.endTimeInMinutes },
        undefined,
        { flowId: payload.flowId },
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        queryClient.invalidateQueries([GET_FLOW_DETAILS]);
      },
    },
  );
};

export const useFetchArchivedFlows = () => {
  return useQuery<GetFlowArchivedResponse>([GET_ARCHIVED_FLOWS], () =>
    makeAPICallWithDataReturn(GET_ARCHIVED_FLOWS, undefined, { limit: 99 }),
  );
};

export const useTriggerUnArchiveFlow = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UnArchiveFlowPayload) =>
      makeAPICall(TRIGGER_UNARCHIVE_FLOW, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        queryClient.invalidateQueries([GET_ARCHIVED_FLOWS]);
      },
    },
  );
};
