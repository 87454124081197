import React from 'react';
import styled from 'styled-components';
import { MessageBanner } from './styles';
import ThemeV2 from '../../../theme';

const Link = styled.u`
  cursor: pointer;
  color: ${ThemeV2.palette.geekBlue6};
`;

type AddSlackBotMessageProps = {
  onClick?: () => void;
};

const AddSlackBotMessage = (props: AddSlackBotMessageProps) => {
  const { onClick } = props;

  return (
    <MessageBanner onMouseDown={onClick}>
      <div>
        To notify a private or shared Slack channel, first /invite @assembly-app
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        in that channel. Don't see the channel?{' '}
        <Link>Refresh channel list</Link>
      </div>
    </MessageBanner>
  );
};

const MemoizedAddSlackBotMessage = React.memo(AddSlackBotMessage);
MemoizedAddSlackBotMessage.displayName = 'AddSlackBotMessage';

export default MemoizedAddSlackBotMessage;
