import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import NotificationSettingsController from '../../NotificationSettings';
import SettingsController from '../../SettingsController';
import { SecondaryPanel } from '../../../interfaces/Layout';

import {
  V2_GENERAL_SETTINGS,
  V2_NOTIFICATION_SETTINGS,
} from '../../../constants/routes';
import useLayoutStore from '../../../stores/layoutStore';
import { Route, Switch, Redirect } from 'react-router-dom';
import { FluidContainer } from '../../../Utils/styles/display';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const GeneralSettingsPage = () => (
  <div>
    <SettingsController />
  </div>
);

const UserSettingsController = () => {
  const { slugUrl } = useMultiAssemblySlug();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const {
    setActiveNavItem,
    setActiveSecondaryPanel,
    setRightAsideOpenToFalse,
  } = useLayoutStore(
    (state) => ({
      setActiveNavItem: state.setActiveNavItem,
      setActiveSecondaryPanel: state.setActiveSecondaryPanel,
      setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
    }),
    shallow,
  );

  useEffect(() => {
    setActiveNavItem(SecondaryPanel.PROFILE);
    setActiveSecondaryPanel(SecondaryPanel.PROFILE);
    setRightAsideOpenToFalse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [
    {
      path: `/${slugUrl}${V2_GENERAL_SETTINGS}`,
      component: GeneralSettingsPage,
    },
    {
      path: `/${slugUrl}${V2_NOTIFICATION_SETTINGS}`,
      component: NotificationSettingsController,
    },
  ];

  return (
    <FluidContainer
      contentPadding={isMobileView ? '32px 32px 64px 32px' : '16px'}
    >
      <Switch>
        {routes.map((route) => (
          <Route
            key={route.path}
            exact
            path={route.path}
            component={route.component}
          />
        ))}
        <Redirect to={`/${slugUrl}${V2_GENERAL_SETTINGS}`} />
      </Switch>
    </FluidContainer>
  );
};

export default UserSettingsController;
