import React, { useMemo } from 'react';
import DayPicker, { Modifier, Modifiers } from 'react-day-picker';
import { makeStyles } from '@mui/styles';
import 'react-day-picker/lib/style.css';

import ThemeV2 from '../../../theme';
import CalendarNavElement, {
  getYearDropdownValues,
} from '../CalendarNavElement';

const useStyles = makeStyles({
  customDatePicker: {
    '& .DayPicker-Month': {
      marginTop: '8px',
    },
    '& .DayPicker-wrapper': {
      outline: 'none',
      fontFamily: ThemeV2.typography.adminFontFamily,
      paddingBottom: '8px',
    },
    '& .DayPicker-Weekday': {
      color: ThemeV2.palette.gray8,
      fontWeight: 400,
      borderRadius: 0,
    },
    '& .DayPicker-Day': {
      color: ThemeV2.palette.gray8,
    },
    '& .DayPicker-Day:hover': {
      borderRadius: 0,
    },
    '& .DayPicker-Day--outside': {
      color: ThemeV2.palette.gray6,
      borderRadius: 0,
    },
    '& .DayPicker-Weekdays': {
      borderBottom: `1px solid ${ThemeV2.palette.gray4}`,
    },
    '& .DayPicker-Day--disabled': {
      color: ThemeV2.palette.gray6,
    },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)':
      {
        backgroundColor: ThemeV2.palette.gray3,
        color: ThemeV2.palette.gray8,
      },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)':
      {
        color: ThemeV2.palette.gray3,
        backgroundColor: ThemeV2.palette.geekBlue6,
        outline: 'none',
        borderRadius: '2px',
        '&:hover': {
          backgroundColor: ThemeV2.palette.geekBlue6,
          color: ThemeV2.palette.gray3,
        },
      },
    '& .DayPicker:not(.DayPicker--interactionDisabled)': {
      '& .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside)':
        {
          '&:hover': {
            backgroundColor: ThemeV2.palette.geekBlue1,
          },
        },
    },
    '& .DayPicker-Day--selected': {
      borderRadius: 0,
    },
  },
});

interface CalendarProps {
  activeDate: Date | undefined;
  modifiers?: Partial<Modifiers>;
  handleDayClick: (
    date: Date,
    modifiers?: any,
    e?: React.MouseEvent<HTMLDivElement>,
  ) => void;
  numberOfYearsAllowedFromCurrentYear: number;
  handleDateChange: (date: Date, isRightCalendar?: boolean) => void;
  isRangePicker?: boolean;
  isRightCalendar?: boolean;
  selectedDays?: [Date | { from?: Date; to?: Date }];
  disabledDays?: Modifier | Modifier[];
  stopEventPropagation?: boolean;
}

// CAPTION COMPONENT IS RETURNING NULL BECAUSE WE ALREADY HAVE DROPDOWNS SHOWING THE
// CURRENT MONTH. BUT THE DAY PICKER REQUIRES A COMPONENT SO WE ARE USING THIS.
const CaptionComponent = () => null;

const Calendar = ({
  handleDateChange,
  activeDate,
  modifiers,
  handleDayClick,
  isRangePicker,
  isRightCalendar,
  numberOfYearsAllowedFromCurrentYear,
  selectedDays,
  disabledDays,
  stopEventPropagation = false,
}: CalendarProps) => {
  const classes = useStyles();
  const yearDropdownValues = useMemo(
    () => getYearDropdownValues(numberOfYearsAllowedFromCurrentYear),
    [numberOfYearsAllowedFromCurrentYear],
  );

  return (
    <div>
      <DayPicker
        modifiers={modifiers}
        month={activeDate}
        showOutsideDays
        enableOutsideDaysClick={false}
        captionElement={CaptionComponent}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        selectedDays={selectedDays}
        navbarElement={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <CalendarNavElement
            yearDropdownValues={yearDropdownValues}
            changeDates={handleDateChange}
            isRangePicker={isRangePicker}
            isRightCalendar={isRightCalendar}
            stopEventPropagation={stopEventPropagation}
            // The DayPicker component adds a bunch of props to this aswell like the month prop
          />
        }
        className={classes.customDatePicker}
        onDayClick={handleDayClick}
        disabledDays={disabledDays}
      />
    </div>
  );
};

export default Calendar;
