import React from 'react';
import Body from '../../atoms/Body';

import { FeedPostBodyProps } from './interface';
import {
  FeedPostBodyRoot,
  FeedPostCoreValue,
  FeedPostMessage,
  FeedPostImage,
} from './styles';

const FeedPostBody = (props: FeedPostBodyProps) => {
  const { coreValue, messageText, image, variant, isEdited } = props;
  return (
    <FeedPostBodyRoot variant={variant}>
      {coreValue && (
        <FeedPostCoreValue
          icon="star"
          iconSize="12px"
          paddingTopBottom="4px"
          paddingLeftRight="8px"
        >
          <Body variant="body3">Core Value: {coreValue}</Body>
        </FeedPostCoreValue>
      )}
      <FeedPostMessage variant="body2">
        {messageText}{' '}
        {isEdited && (
          <Body inline variant="body3" color="gray7">
            (Edited)
          </Body>
        )}
      </FeedPostMessage>
      {image && <FeedPostImage src={image} lazyload />}
    </FeedPostBodyRoot>
  );
};

export default FeedPostBody;
