import { useMutation, useQueryClient } from '@tanstack/react-query';

import { makeAPICall } from '../../utils';
import {
  SaveFlowPayload,
  UpdateFlowAccessPolicyPayload,
} from '../Feed/interfaces';

import {
  CREATE_FLOW,
  EDIT_FLOW,
  GET_ACTIVE_FLOWS,
  GET_FLOW_DETAILS,
  GET_FLOW_PARTICIPANTS,
  GET_FLOW_VIEWERS,
  GET_MAIN_NAV_CONTENTS,
  GET_MEMBERS_FROM_CRITERIA,
  UPDATE_FLOWS_ACCESS_POLICY,
} from '../../../constants/endpoints';

export const useCreateFlowMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SaveFlowPayload) => makeAPICall(CREATE_FLOW, payload),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        await queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
      },
    },
  );
};

export const useEditFlowMutation = (flowId: string, onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SaveFlowPayload) =>
      makeAPICall(EDIT_FLOW, payload, undefined, { flowId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, 'builder']);
        queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, '']);
        queryClient.invalidateQueries([GET_FLOW_PARTICIPANTS]);
        queryClient.invalidateQueries([GET_FLOW_VIEWERS]);
        onSuccess();
      },
    },
  );
};

export const useUpdateFlowAccessPolicy = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateFlowAccessPolicyPayload) =>
      makeAPICall(UPDATE_FLOWS_ACCESS_POLICY, payload, undefined, { flowId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_FLOW_DETAILS]);
        Promise.all([
          queryClient.invalidateQueries([GET_ACTIVE_FLOWS]),
          queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]),
          queryClient.invalidateQueries([GET_FLOW_VIEWERS]),
          queryClient.invalidateQueries([GET_FLOW_PARTICIPANTS]),
          queryClient.invalidateQueries([GET_MEMBERS_FROM_CRITERIA]),
        ]);
      },
    },
  );
};
