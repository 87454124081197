import { IndexedObject, UploadedUppyFile, UppyFile } from '@uppy/core';

import { File, FileUploadStatus, LegacyFileType } from '../../FileCard/types';
import { FlowFileForAPI } from '../../../../interfaces/Flow';

export interface AWSUploadedUppyFile
  extends UploadedUppyFile<IndexedObject<any>, IndexedObject<any>> {
  xhrUpload: {
    allowedMetaFields: string[];
    endpoint: string;
    formData: boolean;
    headers: Record<string, string>;
    method: string;
  };
}

export const determineFileStatus = (file: UppyFile): FileUploadStatus => {
  if (file?.size === 0) {
    return FileUploadStatus.Error;
  }

  if (file.progress?.uploadComplete) {
    return FileUploadStatus.Success;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  if (file.error) {
    return FileUploadStatus.Error;
  }

  return FileUploadStatus.Loading;
};

export const determineFileType = (file: UppyFile): LegacyFileType => {
  const imageFileExtensions = ['jpg', 'jpeg', 'png', 'svg'];
  if (imageFileExtensions.includes(file.extension.toLowerCase())) {
    return LegacyFileType.Image;
  }

  return LegacyFileType.File;
};

export const getFileCategoryByFileType = (fileType: string) => {
  const imageFileExtensions = ['jpg', 'jpeg', 'png', 'svg'];
  if (imageFileExtensions.includes(fileType.toLowerCase())) {
    return LegacyFileType.Image;
  }

  return LegacyFileType.File;
};

export const sanitizeFileName = (fileName: string) => {
  const fileNameWithoutExtension =
    fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
  const extension = fileName.split('.').pop();

  return `${fileNameWithoutExtension
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}.${extension?.toLowerCase()}`;
};

export const transformUploadedUppyFileToFileForAPI = (
  uploadedUppyFile: AWSUploadedUppyFile,
): FlowFileForAPI => {
  const { extension, name, progress, size, uploadURL, xhrUpload } =
    uploadedUppyFile;
  const preloadUrlObject = new URL(xhrUpload.endpoint);
  return {
    createdAt: new Date(progress?.uploadStarted || Date.now()),
    type: extension.toLowerCase(),
    location: uploadURL ?? preloadUrlObject.origin + preloadUrlObject.pathname,
    name: sanitizeFileName(name),
    originalName: name,
    size,
  };
};

export const mapResponseFileToPayloadFile = (files: File[]): File[] => {
  return files.map((file: File) => {
    const { type, size, createdAt, name, thumbnails, location = '' } = file;
    return {
      createdAt,
      type,
      location,
      name,
      size,
      thumbnails,
      id: file._id,
    };
  });
};

export const getExistingFiles = (allFiles: File[]) => {
  return allFiles.filter((currentFile: File) => currentFile.thumbnails);
};
