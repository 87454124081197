import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { parse } from 'qs';

import V2RouteController from '../controllers/V2RouteController';
import FlowsParticipationController from '../controllers/flows/FlowsParticipationController';
import RecognitionParticipationController from '../controllers/flows/RecognitionParticipationController';
import FlowsPreviewController from '../controllers/flows/FlowsPreviewController';
import {
  FLOWS,
  FLOWS_FEEDS_FULLSCREEN,
  FLOWS_PARTICIPATE,
  FLOW_EDIT_RESPONSE,
  KNOWLEDGE_CENTER,
  NOTEBOOK,
  PEOPLE_DIRECTORY,
  POST,
  RECOGNITION_EDIT_POST,
  RECOGNITION_FEEDS_FULLSCREEN,
  RECOGNITION_PARTICIPATE,
  TEMPLATE_PREVIEW,
  V2_GENERAL_SETTINGS,
  V2_HOME,
  V2_NOTIFICATION_SETTINGS,
  V2_REWARDS,
  V2_SETTINGS,
  V2_USER_PROFILE,
  FILE_PREVIEW,
  INVITE_PEOPLE,
  DELETE_POST,
} from '../constants/routes';
import { trackPageView } from '../Utils/analytics';
import { setSourceDeviceAsIPhone } from '../Utils/device';
import { DEVICE, DEVICES } from '../constants/devices';
import RecognitionFullScreenController from '../controllers/home/RecognitionFullScreenController';
import FlowsFeedsFullscreenController from '../controllers/flows/FlowsFeedsFullscreenController';
import useAnnouncementsSocket from '../hooks/useAnnouncementsSocket';

const SlugUrlController = () => {
  const { search, pathname } = useLocation();

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isDevicePresent = parsedParams && parsedParams[DEVICE];
    if (isDevicePresent) {
      const { device } = parsedParams;
      if (device === DEVICES.IPHONE) {
        setSourceDeviceAsIPhone();
      }
    }
  }, [search]);

  useAnnouncementsSocket();

  return (
    <Switch>
      <Route
        exact
        path={`/:slugUrl${RECOGNITION_PARTICIPATE}`}
        component={RecognitionParticipationController}
      />
      <Route
        exact
        path={`/:slugUrl${RECOGNITION_EDIT_POST}`}
        component={RecognitionParticipationController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOW_EDIT_RESPONSE}`}
        component={FlowsParticipationController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOWS_PARTICIPATE}`}
        component={FlowsParticipationController}
      />
      <Route
        exact
        path={`/:slugUrl${RECOGNITION_FEEDS_FULLSCREEN}`}
        component={RecognitionFullScreenController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOWS_FEEDS_FULLSCREEN}`}
        component={FlowsFeedsFullscreenController}
      />
      <Route
        exact
        path={`/:slugUrl${TEMPLATE_PREVIEW}`}
        component={FlowsPreviewController}
      />
      <Route path={`/:slugUrl${V2_HOME}`} component={V2RouteController} />
      <Route path={`/:slugUrl${V2_REWARDS}`} component={V2RouteController} />
      <Route
        path={`/:slugUrl${V2_USER_PROFILE}`}
        component={V2RouteController}
      />
      <Route path={`/:slugUrl${V2_SETTINGS}`} component={V2RouteController} />
      <Route
        path={`/:slugUrl${V2_GENERAL_SETTINGS}`}
        component={V2RouteController}
      />
      <Route
        path={`/:slugUrl${V2_NOTIFICATION_SETTINGS}`}
        component={V2RouteController}
      />
      <Route path={`/:slugUrl${POST}`} component={V2RouteController} />
      <Route path={`${POST}`} component={V2RouteController} />
      <Route path={`/:slugUrl${FLOWS}`} component={V2RouteController} />
      <Route
        path={`/:slugUrl/create-one-click-flow/:templateId`}
        component={V2RouteController}
      />
      <Route path={`/:slugUrl${NOTEBOOK}`} component={V2RouteController} />
      <Route
        path={`/:slugUrl${KNOWLEDGE_CENTER}`}
        component={V2RouteController}
      />
      <Route path={`/:slugUrl${FILE_PREVIEW}`} component={V2RouteController} />
      <Route
        path={`/:slugUrl${PEOPLE_DIRECTORY}`}
        component={V2RouteController}
      />
      <Route path={`/:slugUrl${INVITE_PEOPLE}`} component={V2RouteController} />
      <Route path={`/:slugUrl${DELETE_POST}`} component={V2RouteController} />
    </Switch>
  );
};

export default SlugUrlController;
