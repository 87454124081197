import { EmojiData } from 'emoji-mart';
import { useCallback, useMemo } from 'react';
import { InfiniteData, UseMutateFunction } from '@tanstack/react-query';
import { DownloadBlockType } from '../../atomic/organism/FlowPostBody/types';
import {
  DeletePostClickType,
  FeedContextState,
  FeedItemMetaData,
  FlowPostReactionMutationPayload,
  GetFeedResponse,
  MemberInteractionType,
  Reaction,
  ReactionMutationPayload,
  SinglePostResponse,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import { LayoutStoreState } from '../../interfaces/Layout';
import { COPIED_TO_CLIPBOARD } from '../../languages/en/flows';
import {
  COMMENT_DELETION_ERROR_MESSAGE,
  COMMENT_DELETION_INPROGRESS_MESSAGE,
  COMMENT_DELETION_SUCCESS_MESSAGE,
  POST_DELETION_ERROR_MESSAGE,
  POST_DELETION_INPROGRESS_MESSAGE,
  POST_DELETION_SUCCESS_MESSAGE,
} from '../../languages/en/giveRecognitionForm';
import { GIVE_TROPHIES_BACK_TO_SENDER } from '../../languages/en/home';
import { useLegacyDeletePost } from '../../queries/Feed';
import {
  useAdminDeleteComment,
  useUpdateCommentReactionMutation,
} from '../../queries/Feed/comments';
import { useDeleteFlowPost } from '../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useLayoutStore from '../../stores/layoutStore';
import { downloadFeedFile } from '../../Utils/flows';
import { getFlowFeedUrl, getPostUrl } from '../../Utils/home/feeds';
import {
  getEmojiPayload,
  serializeReactionTogglePayload,
} from '../../Utils/serializers/feed';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import { useFeedReactions } from '../useFeedReactions';
import useToggle from '../useToggle';
import { AxiosResponse } from 'axios';
import {
  FlowFeedResponse,
  ReactionRequestPayload,
} from '../../queries/Flows/Feed/interfaces';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import useHistoryWrapper from '../useHistoryWrapper';
import useRepliesSidebar from '../useRepliesSidebar';
import useMultiAssemblySlug from '../useMultiAssemblySlug';
import { trackPostActionEvent } from '../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../Utils/analytics/constants';
import { showChatsSelector } from '../../stores/layoutStore/selectors';
import { copyToClipboard } from '../../Utils/common';

type UseFlowFeedProps = {
  mutateFlowPostReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    FlowPostReactionMutationPayload,
    {
      previousData: InfiniteData<GetFeedResponse> | undefined;
    }
  >;
  mutatePostReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionMutationPayload,
    {
      previousData:
        | InfiniteData<GetFeedResponse>
        | SinglePostResponse
        | undefined;
    }
  >;
  mutateResponseReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionRequestPayload,
    {
      previousData:
        | InfiniteData<PaginationResponse<FlowFeedResponse>>
        | FlowFeedResponse;
    }
  >;
  onPostDelete?: () => void;
};

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const useFlowFeed = (props: UseFlowFeedProps) => {
  const { slugUrl } = useMultiAssemblySlug();

  const {
    onPostDelete,
    mutateFlowPostReaction,
    mutatePostReaction,
    mutateResponseReaction,
  } = props;

  const history = useHistoryWrapper();
  const {
    models: { toggleValue: showLoadMoreButton },
    operations: {
      setToggleToTrue: setShowLoadMoreButtonToTrue,
      setToggleToFalse: setShowLoadMoreButtonToFalse,
    },
  } = useToggle();
  const {
    data: profileData,
    isError: isProfileDataError,
    isInitialLoading: isProfileInfoLoading,
  } = useProfileInfoFetchQuery();

  const {
    reset: resetRightSidebar,
    flowId: selectedFlowIdInSidebar,
    postId: selectedPostIdInSidebar,
    responseId: selectedResponseIdInSidebar,
  } = useRepliesSidebar();

  const setShowChats = useLayoutStore(showChatsSelector);
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const onMemberClick = useCallback(
    (person: MemberInteractionType) => {
      resetRightSidebar();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });

      trackPostActionEvent({
        memberId: person.memberID,
        messageType: 'replies',
        action: POST_ANALYTICS_EVENTS.MEMBER_NAME_CLICKED,
      });
    },
    [resetRightSidebar, setMemberAndToggleRightAsideOpen, setShowChats],
  );

  const onFlowClick = useCallback(
    (flowId: string) => {
      history.push(getFlowFeedUrl(flowId));
    },
    [history],
  );

  const { onReactionSet, onReactionUnSet } = useFeedReactions({
    profileData,
    mutateFlowPostReaction,
    mutatePostReaction,
    mutateResponseReaction,
  });

  const handleAdminDeletePostSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(POST_DELETION_SUCCESS_MESSAGE);
    if (onPostDelete) {
      onPostDelete();
    }
  }, [onPostDelete]);

  const handleAdminDeletePostError = useCallback(() => {
    dismissAllToasts();
    showErrorMessage(POST_DELETION_ERROR_MESSAGE);
  }, []);

  const { mutate: deleteRecognitionPost } = useLegacyDeletePost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );
  const { mutate: deleteFlowPost } = useDeleteFlowPost(
    handleAdminDeletePostSuccess,
    handleAdminDeletePostError,
  );

  const onDeletePost: DeletePostClickType = useCallback(
    (
      {
        hasTrophies,
        currentFlowId,
        currentPostType,
        currentResponseId,
        currentPostId,
      },
      deletePostCarrotOption,
    ) => {
      let sendTrophiesVariable = false;
      if (
        hasTrophies &&
        deletePostCarrotOption === GIVE_TROPHIES_BACK_TO_SENDER
      ) {
        sendTrophiesVariable = true;
      }
      dismissAllToasts();
      showInfoMessage(POST_DELETION_INPROGRESS_MESSAGE);
      if (
        currentPostType === PostTypes.FLOW &&
        currentFlowId &&
        currentResponseId
      ) {
        if (
          selectedFlowIdInSidebar === currentFlowId &&
          selectedResponseIdInSidebar === currentResponseId
        ) {
          resetRightSidebar();
        }

        deleteFlowPost({
          flowId: currentFlowId,
          responseId: currentResponseId,
          returnPoints: sendTrophiesVariable,
        });
      } else {
        if (selectedPostIdInSidebar === currentPostId) {
          resetRightSidebar();
        }

        deleteRecognitionPost({
          postId: currentPostId,
          isWithCarrots: sendTrophiesVariable,
        });
      }
    },
    [
      deleteFlowPost,
      resetRightSidebar,
      deleteRecognitionPost,
      selectedFlowIdInSidebar,
      selectedPostIdInSidebar,
      selectedResponseIdInSidebar,
    ],
  );

  const handleAdminDeleteCommentSuccess = useCallback(() => {
    dismissAllToasts();
    showSuccessMessage(COMMENT_DELETION_SUCCESS_MESSAGE);
  }, []);

  const handleAdminDeleteCommentError = useCallback(() => {
    showErrorMessage(COMMENT_DELETION_ERROR_MESSAGE);
  }, []);

  const { mutate: deleteComment } = useAdminDeleteComment(
    handleAdminDeleteCommentSuccess,
    handleAdminDeleteCommentError,
  );

  const onDeleteCommentClick = useCallback(
    (
      commentId: string,
      hasTrophies: boolean,
      deleteCommentOption: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      dismissAllToasts();
      showInfoMessage(COMMENT_DELETION_INPROGRESS_MESSAGE);
      deleteComment({
        postId: feedItemMetaData.postId,
        commentId,
        isWithCarrots:
          hasTrophies && deleteCommentOption === GIVE_TROPHIES_BACK_TO_SENDER,
        postType: feedItemMetaData.type,
        flowId: feedItemMetaData.flowId,
        responseId: feedItemMetaData.responseId,
      });
    },
    [deleteComment],
  );

  const { mutate: updateCommentReaction } = useUpdateCommentReactionMutation();

  const onCommentReactionToggleClick = useCallback(
    (
      { reaction, active }: Reaction,
      currentContentId: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      if (profileData) {
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            reaction,
            currentContentId,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: feedItemMetaData.postId,
          postType: feedItemMetaData.type,
          flowId: feedItemMetaData.flowId,
          responseId: feedItemMetaData.responseId,
        });

        trackPostActionEvent({
          messageType: 'replies',
          reaction: reaction.name,
          postId: feedItemMetaData.postId,
          flowId: feedItemMetaData.flowId,
          memberId: profileData.member.memberId,
          action: active
            ? POST_ANALYTICS_EVENTS.REPLY_REACTION_ADDED
            : POST_ANALYTICS_EVENTS.REPLY_REACTION_REMOVED,
        });
      }
    },
    [profileData, updateCommentReaction],
  );

  const onCommentEmoticonClick = useCallback(
    (
      reactions: Reaction[],
      emoji: EmojiData,
      contentID: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      const [selectedReaction, payload] = getEmojiPayload(reactions, emoji);
      // CURRENTLY HANDLING IF AN EMOTICON THAT IS ALREADY SELECTED BY THE USER IS CLICKED
      if (profileData && !selectedReaction?.active) {
        updateCommentReaction({
          ...serializeReactionTogglePayload(
            payload,
            contentID,
            UpdateReactionAction.SET,
            profileData.member,
          ),
          postID: feedItemMetaData.postId,
          postType: feedItemMetaData.type,
          flowId: feedItemMetaData.flowId,
          responseId: feedItemMetaData.responseId,
        });

        trackPostActionEvent({
          messageType: 'replies',
          postId: feedItemMetaData.postId,
          flowId: feedItemMetaData.flowId,
          memberId: profileData.member.memberId,
          reaction: selectedReaction?.reaction.name,
          action: POST_ANALYTICS_EVENTS.REPLY_REACTION_ADDED,
        });
      }
    },
    [profileData, updateCommentReaction],
  );

  const onCopyCommentLink = useCallback(
    (commentId: string, feedItemMetaData: FeedItemMetaData) => {
      let link = getPostUrl({ ...feedItemMetaData, slugUrl });
      link = link
        .concat(`&commentId=${commentId}`)
        .concat(`&type=${feedItemMetaData.postId ? 'recognition' : 'flow'}`)
        .concat(`&showReplies=true`);
      copyToClipboard(link);
      dismissAllToasts();
      showSuccessMessage(COPIED_TO_CLIPBOARD);
    },
    [slugUrl],
  );

  const onDownloadFileClick = useCallback(
    async (
      fileDownload: DownloadBlockType,
      instanceId: string,
      flowId: string,
    ) => {
      showInfoMessage('Download in progress');
      try {
        await downloadFeedFile({
          blockId: fileDownload.id,
          responseId: fileDownload.responseId,
          instanceId,
          fileName: fileDownload.fileName,
          flowId,
        });
        dismissAllToasts();
        showSuccessMessage('File downloaded successfully');
      } catch (e) {
        dismissAllToasts();
        showErrorMessage('Error while downloading the file');
      }
    },
    [],
  );

  const feedContextProps: FeedContextState = useMemo(
    () => ({
      onReactionSet,
      onReactionUnSet,
      onFlowClick,
      onMemberClick,
      onDeleteCommentClick,
      onCommentReactionToggleClick,
      onCommentEmoticonClick,
      onDeletePost,
      onCopyCommentLink,
      onDownloadFileClick,
    }),
    [
      onReactionSet,
      onReactionUnSet,
      onFlowClick,
      onMemberClick,
      onDeleteCommentClick,
      onCommentReactionToggleClick,
      onCommentEmoticonClick,
      onDeletePost,
      onCopyCommentLink,
      onDownloadFileClick,
    ],
  );

  return {
    onFlowClick,
    feedContextProps,
    showLoadMoreButton,
    setShowLoadMoreButtonToTrue,
    setShowLoadMoreButtonToFalse,
    isProfileDataError,
    isProfileInfoLoading,
    profileData,
    onDeleteCommentClick,
  };
};

export default useFlowFeed;
