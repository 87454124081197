import { useState } from 'react';

import ThemeV2 from '../../../theme';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { flowItemsWithoutDeletePost } from '../KnowledgeCenterCard/data';
import {
  CardBottom,
  CardTop,
  NewStyledBody,
  NewStyledImage,
  StyledCard,
  StyledDropdownIconButton,
  StyledSVGIcon,
} from './styles';

import type { ResponseThumbnailProps } from './types';

export function ResponseImageThumbnail(props: ResponseThumbnailProps) {
  const {
    openPreviewModal,
    file,
    cardWidth,
    dataTestId,
    cardColor,
    cardIcon,
    onOptionsSelect,
    cardImageUrl,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard
      isParticipation={false}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => openPreviewModal(file)}
      cardWidth={cardWidth}
      data-testid={dataTestId}
    >
      <CardTop backgroundColor={cardColor}>
        {cardIcon && <StyledSVGIcon icon={cardIcon} size="32px" />}
        {isHovered && (
          <StyledDropdownIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            menuItems={flowItemsWithoutDeletePost}
            icon={'more'}
            title={'file card options'}
            onItemClick={onOptionsSelect}
            size={ButtonSize.Normal}
            stopEventPropagation={true}
            dropdownPlacement={DropdownPlacement.BottomEnd}
          />
        )}

        {cardIcon === 'imageFile' && (
          <NewStyledImage alt={file.name} src={cardImageUrl} />
        )}
      </CardTop>
      <CardBottom>
        <NewStyledBody isParticipation={false} variant="body2Medium">
          {file.name}
        </NewStyledBody>
      </CardBottom>
    </StyledCard>
  );
}
