import React from 'react';

import { ProfileViewControllerProps } from './interfaces';
import ProfileView from '../../../atomic/organism/ProfileView';
import useProfileViewController from './useProfileViewController';
import ProfileDrawer from '../../../atomic/molecules/ProfileDrawer';
import ErrorComponent from '../../../atomic/molecules/ErrorComponent';
import ProfileViewLoader from '../../../atomic/organism/ProfileView/Loader';

const ProfileViewController = (props: ProfileViewControllerProps) => {
  const {
    areQueriesLoading,
    isCurrentUser,
    isError,
    isOnProfileRoute,
    manager,
    memberDetails,
    toggleRightAsideOpen,
    removeSelectedMember,
    directReportsDetails,
    setSelectedMemberProfile,
    handleOnCloseClick,
    handleOnViewProfileClick,
    handleOnCopyProfileLink,
    isProfileUserSelected,
    handleAboutMeChange,
  } = useProfileViewController(props);
  const { firstName } = props;

  return (
    <ProfileDrawer
      name={isCurrentUser ? 'Me' : firstName || ''}
      removeSelectedMember={removeSelectedMember}
      shouldShowCloseIcon={!isOnProfileRoute}
    >
      {areQueriesLoading && <ProfileViewLoader />}
      {isError && <ErrorComponent />}
      {!areQueriesLoading && memberDetails && (
        <ProfileView
          isCurrentUser={isCurrentUser}
          manager={manager}
          directReportsDetails={directReportsDetails}
          memberDetails={memberDetails}
          toggleRightAsideOpen={toggleRightAsideOpen}
          setSelectedMemberProfile={setSelectedMemberProfile}
          onCloseClick={handleOnCloseClick}
          onViewProfileClick={handleOnViewProfileClick}
          onCopyProfileLinkClick={handleOnCopyProfileLink}
          hideCloseButton={isProfileUserSelected}
          handleAboutMeChange={handleAboutMeChange}
        />
      )}
    </ProfileDrawer>
  );
};

export default ProfileViewController;
