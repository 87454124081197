import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IconButton, PopoverContainer } from './styles';
import { ProfileButtonProps } from './interface';
import { SecondaryPanel } from '../../../interfaces/Layout';
import SVGIcon from '../../atoms/SVGIcon';
import Avatar from '../../atoms/Avatar';
import Popover from '@mui/material/Popover';
import ProfilePopover from '../../molecules/ProfilePopover';
import { getFullName, getProfileFullName } from '../../../Utils/user';
import ThemeV2 from '../../../theme';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import useLayoutStore from '../../../stores/layoutStore';
import { checkAdmin, checkIfProduction } from '../../../Utils/admin';

const ProfileButtonContent = (
  props: ProfileButtonProps & {
    loadIntercom: () => void;
  },
) => {
  const {
    currentUserId,
    currentUserProfile,
    isCurrentUserAdmin,
    hideRewardsLink,
    onViewProfileClick,
    rewardsProps,
    loadIntercom,
    onProfileButtonClick,
  } = props;

  const [isProfileOpen, setIsProfileOpen] = useState<boolean>(false);

  const isDesktop = useMediaQuery({
    query: device.desktop,
  });

  const { boot, hide } = useIntercom();

  const unparsedUserData = localStorage.getItem('user');
  const parsedData = unparsedUserData
    ? JSON.parse(unparsedUserData)
    : undefined;
  const userData = parsedData?.data?.data;

  useEffect(() => {
    if (isProfileOpen && userData) {
      const { user, email, intercomUserHash } = userData;
      const { firstName, lastName } = user.profile;
      const name = getProfileFullName({ firstName, lastName });

      loadIntercom();
      setTimeout(() => {
        boot({
          actionColor: 'blue',
          hideDefaultLauncher: true,
          email,
          userHash: intercomUserHash,
          userId: user._id,
          customAttributes: {
            name,
            firstName,
            lastName,
            isAdmin: checkAdmin(user.role),
            externalId: user._id,
          },
        });
        hide();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProfileOpen]);

  const [isQRModalOpen, setQRModalOpen] = useState(false);
  const toggleQRModalOpen = (isOpen: boolean) => {
    setQRModalOpen(isOpen);
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLLIElement | null>(
    null,
  );

  const openProfilePopover = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore-next-line */

      setPopoverAnchorEl(event.currentTarget);
      if (!isProfileOpen) {
        onProfileButtonClick?.();
      }
      setIsProfileOpen(!isProfileOpen);
    },
    [isProfileOpen, onProfileButtonClick],
  );

  const closeProfile = useCallback(() => {
    setIsProfileOpen(false);
  }, []);

  const currentUserFullName = useMemo(
    () => getFullName(currentUserProfile),
    [currentUserProfile],
  );

  const currentProfileImage = useMemo(() => {
    return currentUserProfile.image;
  }, [currentUserProfile.image]);

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const ProfileContent = useMemo(
    () => (
      <PopoverContainer width={widthCheck.matches ? '232px' : '100vw'}>
        <ProfilePopover
          img={currentProfileImage}
          onProfilePopoverClick={onViewProfileClick}
          userName={currentUserFullName}
          userId={currentUserId}
          isCurrentUserAdmin={isCurrentUserAdmin}
          hideRewardsLink={hideRewardsLink}
          rewardsProps={rewardsProps}
          widthCheck={widthCheck.matches}
          closePopover={closeProfile}
          {...(isDesktop && {
            showQRMenu: {
              isQRModalOpen,
              toggleQRModalOpen,
            },
          })}
        />
      </PopoverContainer>
    ),
    [
      widthCheck.matches,
      currentProfileImage,
      onViewProfileClick,
      currentUserFullName,
      currentUserId,
      isCurrentUserAdmin,
      hideRewardsLink,
      rewardsProps,
      closeProfile,
      isDesktop,
      isQRModalOpen,
    ],
  );

  return (
    <>
      <IconButton
        type="button"
        onClick={openProfilePopover}
        data-id={SecondaryPanel.PROFILE}
        aria-label={SecondaryPanel.PROFILE}
        isProfileOpen={isProfileOpen}
      >
        <Avatar
          img={currentUserProfile.image}
          name={currentUserFullName}
          userId={currentUserId}
          size="32px"
        />
        <SVGIcon
          color={ThemeV2.palette.gray8}
          icon="caret-rounded"
          rotate={isProfileOpen ? 180 : 0}
        />
      </IconButton>
      <Popover
        PaperProps={{ style: { maxWidth: '100%', maxHeight: '100%' } }}
        open={isProfileOpen}
        onClose={closeProfile}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: widthCheck.matches ? -8 : -16,
        }}
        marginThreshold={0}
      >
        {ProfileContent}
      </Popover>
    </>
  );
};

const ProfileButton = (props: ProfileButtonProps) => {
  const intercomAppToken = import.meta.env.VITE_INTERCOM_APP_ID;

  const isProduction = checkIfProduction();

  const [shouldLoadIntercom, setShouldLoadIntercom] = useState(false);
  const { isEmbeddedInMainApp } = useLayoutStore();

  return (
    <IntercomProvider
      autoBoot={false}
      shouldInitialize={
        !isEmbeddedInMainApp && shouldLoadIntercom && isProduction
      }
      appId={intercomAppToken}
    >
      <ProfileButtonContent
        {...props}
        loadIntercom={() => setShouldLoadIntercom(true)}
      />
    </IntercomProvider>
  );
};

export default ProfileButton;
