import ToolTip from '../ToolTipV2';
import styled, { css } from 'styled-components';
import ThemeV2 from '../../../theme';
import ClickableLink from '../ClickableLink';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import {
  AsideWidthWhenOpen,
  DefaultAsideWidthWhenRepliesOpen,
} from '../../../constants/layout';
import IconButton from '../../atoms/IconButton_V2';
import SVGIcon from '../../atoms/SVGIcon';

export const FlowNameLink = styled(ClickableLink)`
  flex: 1;
  text-align: left;
  overflow: hidden;
  margin-left: 4px;
  white-space: nowrap;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
`;

export const StyledClickableLink = styled(ClickableLink)`
  flex: 1;
  text-align: left;
  overflow: hidden;
  margin-left: 4px;
  white-space: nowrap;
  font-style: normal;
  text-overflow: ellipsis;
`;

export const StyledFlowsPostHeaderContent = styled.div`
  width: 100%;
  padding: 0 8px;
`;

const bulleted = css`
  margin-left: 4px;
  display: flex;
  align-items: center;
  &:before {
    content: ' ';
    display: block;
    height: 4px;
    width: 4px;
    margin-right: 4px;
    border-radius: 4px;
    background: ${ThemeV2.palette.gray7};
  }
`;

export const BulletedText = styled(Body)`
  ${bulleted}
`;

export const StyledVisibilityIcon = styled(ToolTip)`
  position: relative;
  display: flex;
  margin-left: 12px;
  ${bulleted}
`;

export const SubtitleContainer = styled(Flex)<{ rightAsideWidth: number }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: ${(props) =>
    (props.rightAsideWidth || DefaultAsideWidthWhenRepliesOpen) <
    AsideWidthWhenOpen
      ? `80px`
      : `${
          (props.rightAsideWidth || DefaultAsideWidthWhenRepliesOpen) - 280
        }px`};
`;

export const BackButton = styled(IconButton)`
  margin-right: 8px;
`;

export const HorizontalRule = styled.div`
  height: 1px;
  width: 100%;
  margin: 4px 0;
  background-color: ${ThemeV2.palette.lightGray5};
`;

export const StyledChartBarIcon = styled(SVGIcon)`
  vertical-align: baseline;
  cursor: pointer;
`;
