import isEmpty from 'lodash/isEmpty';
import {
  useGetFeedQuery,
  useGetSearchFeedQuery,
  useRecognitionFeedUpdatePostReactionMutation,
  useSearchRecognitionFeedUpdatePostReactionMutation,
} from '../../../../queries/Feed';

import {
  FeedSearchPayload,
  PostFilterOptions,
  PostSortOptions,
} from '../../../../interfaces/Feed';

export const useGetFeedsForRecognition = (
  searchPayload: FeedSearchPayload,
  feedsSortValue: PostSortOptions,
  showSearchResults: boolean,
  filter?: { tvMode: boolean },
) => {
  const {
    data: searchData,
    isInitialLoading: isLoadingSearchPosts,
    refetch: refetchSearchQuery,
    hasNextPage: hasMoreSearchPosts,
    fetchNextPage: fetchNextSearchItems,
    isFetchingNextPage: isFetchingMoreSearchResult,
  } = useGetSearchFeedQuery(
    searchPayload,
    showSearchResults && !isEmpty(searchPayload),
  );

  const {
    data: feedData,
    error: feedDataError,
    isInitialLoading: isLoadingFeeds,
    refetch: refetchFeedQuery,
    hasNextPage: hasMoreFeedPosts,
    fetchNextPage: fetchNextFeedPost,
    isFetchingNextPage: isFetchingMoreFeeds,
  } = useGetFeedQuery(
    PostFilterOptions.ALL,
    feedsSortValue,
    !showSearchResults,
    filter,
  );

  const { mutate: mutatePostReaction } =
    useRecognitionFeedUpdatePostReactionMutation(
      PostFilterOptions.ALL,
      feedsSortValue,
    );

  const { mutate: mutateSearchPostReaction } =
    useSearchRecognitionFeedUpdatePostReactionMutation(searchPayload);

  if (showSearchResults) {
    return {
      data: searchData,
      isRecognitionFeedLoading: isLoadingSearchPosts,
      fetchNextPost: fetchNextSearchItems,
      hasMorePosts: hasMoreSearchPosts,
      isFetchingMoreFeeds: isFetchingMoreSearchResult,
      mutatePostReaction: mutateSearchPostReaction,
      refetch: refetchSearchQuery,
    };
  }

  return {
    feedDataError,
    data: feedData,
    isRecognitionFeedLoading: isLoadingFeeds,
    fetchNextPost: fetchNextFeedPost,
    hasMorePosts: hasMoreFeedPosts,
    isFetchingMoreFeeds,
    mutatePostReaction,
    refetch: refetchFeedQuery,
  };
};
