import SVGIcon from '../../atoms/SVGIcon';
import IconButton from '../../atoms/IconButton_V2';

import ThemeV2 from '../../../theme';

import { ShareSheetContainerModalProps } from './types';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import {
  BodyWrapper,
  HeaderWrapper,
  HeadingText,
  HeadingTextWrapper,
  PrimaryButton,
  ShareSheetContainer,
} from './styles';
import React from 'react';
import Button from '../../atoms/Button';
import { Flex } from '../../../Utils/styles/display';

const ShareSheetContainerModal = (props: ShareSheetContainerModalProps) => {
  const {
    headerButton,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    alignActionButtons,
  } = props;
  if (props.variant === 'mobileView') {
    return (
      <ShareSheetContainer>
        <HeaderWrapper>
          <div>
            {headerButton && headerButton.icon ? (
              <IconButton onClick={headerButton.onClick}>
                <SVGIcon
                  size="18px"
                  icon={headerButton.icon}
                  color={ThemeV2.palette.gray8}
                />
              </IconButton>
            ) : (
              <IconButton onClick={props.onModalClose}>
                <SVGIcon
                  size="24px"
                  icon="carat-left"
                  color={ThemeV2.palette.gray8}
                />
              </IconButton>
            )}
          </div>
          <HeadingTextWrapper flex={1} justifyContent="center">
            <HeadingText variant="body1Bold">{props.heading}</HeadingText>
          </HeadingTextWrapper>
        </HeaderWrapper>
        <BodyWrapper alignItems="flex-start">{props.children}</BodyWrapper>
        {(primaryButton || secondaryButton) && (
          <Flex justifyContent={tertiaryButton ? 'space-between' : 'flex-end'}>
            {tertiaryButton && (
              <Button
                color={
                  tertiaryButton.color ? tertiaryButton.color : 'secondary'
                }
                variant={tertiaryButton.variant}
                status={tertiaryButton.status}
                disabled={tertiaryButton.disabled}
                isFullWidth={alignActionButtons === 'center'}
                isLoading={tertiaryButton.isLoading}
                onClick={tertiaryButton.onClick}
                icon={tertiaryButton.icon}
                size={tertiaryButton.size}
              >
                {tertiaryButton.text}
              </Button>
            )}

            <Flex>
              {secondaryButton && (
                <Button
                  color={
                    secondaryButton.color ? secondaryButton.color : 'secondary'
                  }
                  variant="outlined"
                  status={secondaryButton.status}
                  disabled={secondaryButton.disabled}
                  isFullWidth={alignActionButtons === 'center'}
                  isLoading={secondaryButton.isLoading}
                  onClick={secondaryButton.onClick}
                  icon={secondaryButton.icon}
                  size={secondaryButton.size}
                >
                  {secondaryButton.text}
                </Button>
              )}
              {primaryButton && (
                <PrimaryButton
                  color={primaryButton.color ? primaryButton.color : 'primary'}
                  variant={primaryButton.variant}
                  status={primaryButton.status}
                  disabled={primaryButton.disabled}
                  isFullWidth={alignActionButtons === 'center'}
                  isLoading={primaryButton.isLoading}
                  onClick={primaryButton.onClick}
                  icon={primaryButton.icon}
                  size={primaryButton.size}
                >
                  {primaryButton.text}
                </PrimaryButton>
              )}
            </Flex>
          </Flex>
        )}
      </ShareSheetContainer>
    );
  } else {
    return <BasicModalTemplate {...props}>{props.children}</BasicModalTemplate>;
  }
};

export default ShareSheetContainerModal;
