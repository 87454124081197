import React from 'react';
import routesList from '../../../routesList';
import {
  UPGRADE_HERE,
  ACCOUNT_SETUP,
  UPGRADE_THROUGH_DESKTOP,
} from '../../../languages/en/rewards';
import Body from '../../atoms/Body';
import {
  StyledRewardsEmptyState,
  StyledRewardsEmptyStateImage,
  StyledRewardsEmptyStateLink,
  StyledRewardsEmptyStateButton,
  StyledRewardsEmptyStateTitle,
} from './styles';
import { RewardsEmptyStateProps } from './interfaces';
import accountSetupImage from '../../../img/account-set-up.svg';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';
import isEmpty from 'lodash/isEmpty';
import { APP_URL } from '../../../config';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { useHistory } from 'react-router-dom';
import useLayoutStore from '../../../stores/layoutStore';

const RewardsEmptyState = (props: RewardsEmptyStateProps) => {
  const {
    title,
    message,
    actionButtonText,
    actionButtonLink = '#',
    hasActionButton = true,
    hasUpgradeLink = false,
    image = accountSetupImage,
  } = props;

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { isEmbeddedInMainApp } = useLayoutStore();
  const history = useHistory();

  return (
    <StyledRewardsEmptyState>
      <StyledRewardsEmptyStateImage src={image} alt={ACCOUNT_SETUP} />
      <StyledRewardsEmptyStateTitle
        variant="body1Bold"
        data-qa-id="noRewardsTitleText"
      >
        {title}
      </StyledRewardsEmptyStateTitle>
      <Body variant="body2">{message}</Body>
      {hasUpgradeLink &&
        !isMobileView &&
        (!isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) ? (
          <a
            href={`${APP_URL}${routesList.ADMIN_BILLING_ACCOUNT_LEGACY}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Body variant="body2" color="gold6" inline>
              {UPGRADE_HERE}
            </Body>
          </a>
        ) : (
          <StyledRewardsEmptyStateLink
            to={routesList.ADMIN_BILLING_ACCOUNT}
            data-qa-id="upgradeHereLinkRewards"
          >
            <Body variant="body2" color="gold6" inline>
              {UPGRADE_HERE}
            </Body>
          </StyledRewardsEmptyStateLink>
        ))}
      {isMobileView && (
        <Body variant="body2" inline>
          {UPGRADE_THROUGH_DESKTOP}
        </Body>
      )}
      {hasActionButton &&
        !isMobileView &&
        (!isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) ? (
          <a href={actionButtonLink} target="_blank" rel="noopener noreferrer">
            <Body variant="body2" color="geekBlue6" inline>
              {actionButtonText}
            </Body>
          </a>
        ) : (
          <StyledRewardsEmptyStateButton
            onClick={() => {
              if (isEmbeddedInMainApp) {
                window.parent.postMessage(
                  {
                    type: 'NAVIGATE_TO_ADMIN',
                    payload: actionButtonLink.replace('/admin', ''),
                  },
                  '*',
                );
              } else {
                history.push(actionButtonLink);
              }
            }}
            isFullWidth
            dataQaId={`${actionButtonText?.replaceAll(' ', '')}Btn`}
          >
            {actionButtonText}
          </StyledRewardsEmptyStateButton>
        ))}
    </StyledRewardsEmptyState>
  );
};

export default RewardsEmptyState;
