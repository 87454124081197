import styled from 'styled-components';

import Theme from '../../../../../theme';
import Body from '../../../../../atomic/atoms/Body';
import { RequestedUsersForAccess } from '../../../../../stores/sharesheetStore';
import { ShareSheetMemberActions } from '../../ShareSheetModalController/data';
import { Flex } from '../../../../../Utils/styles/display';
import Avatar from '../../../../../atomic/atoms/Avatar';
import { SingleSelectAutoComplete } from '../../../../../atomic/organism/Autocomplete';
import { AutocompleteDropdownItem } from '../../../../../atomic/organism/Autocomplete/interfaces';
import DropdownIconButton from '../../../../../atomic/molecules/DropdownIconButton';
import SVGIcon from '../../../../../atomic/atoms/SVGIcon';

export type UserItemsProps = Omit<RequestedUsersForAccess, 'requestId'> & {
  handleApproveClick: () => void;
  handleDenyClick: () => void;
  onChange: (val: ShareSheetMemberActions) => void;
};

const StyledUserIcon = styled(SVGIcon)`
  background-color: ${Theme.palette.geekBlue6};
  border-radius: 50%;
`;

const StyledUserNameBody = styled(Body)`
  width: 235px;
`;

const StyledSingleSelectAutoComplete = styled(SingleSelectAutoComplete)`
  width: 100%;
  max-width: 198px;
  & .MuiInputBase-root input {
    padding: 4px 16px !important;
    height: 24px;
  }
`;

const StyledDropdownIconButton = styled(DropdownIconButton)<{
  iconColor: string;
}>`
  &:not(:disabled):active {
    & span svg path {
      fill: ${({ iconColor }) => iconColor};
    }
  }
`;

const permissions: AutocompleteDropdownItem<string>[] = [
  {
    title: 'Can only view posts',
    id: ShareSheetMemberActions.canOnlyViewPosts,
  },
  {
    title: 'Can only post',
    id: ShareSheetMemberActions.canOnlyPost,
  },
  {
    title: 'Can post & view',
    id: ShareSheetMemberActions.canPostAndViewPosts,
  },
];

const customConfigColorForApproveIcon = {
  background: Theme.palette.gray1,
  textColor: Theme.palette.green7,
  borderColor: Theme.palette.gray1,
  hover: {
    background: Theme.palette.gray3,
    textColor: Theme.palette.green7,
    borderColor: Theme.palette.gray3,
  },
  active: {
    background: Theme.palette.gray3,
    textColor: Theme.palette.green7,
    borderColor: Theme.palette.gray3,
  },
};

const customConfigColorForRejectIcon = {
  background: Theme.palette.gray1,
  textColor: Theme.palette.red6,
  borderColor: Theme.palette.gray1,
  hover: {
    background: Theme.palette.gray3,
    textColor: Theme.palette.red6,
    borderColor: Theme.palette.gray3,
  },
  active: {
    background: Theme.palette.gray3,
    textColor: Theme.palette.red6,
    borderColor: Theme.palette.gray3,
  },
};

export const UserItem = (props: UserItemsProps) => {
  const {
    name,
    permission,
    image,
    memberID,
    memberState,
    email,
    onChange,
    handleApproveClick,
    handleDenyClick,
  } = props;

  return (
    <Flex margin="0 0 8px" key={memberID} gap="8px">
      {memberState === 'PENDING' ? (
        <StyledUserIcon icon="add-person-transparent" size="24px" />
      ) : (
        <Avatar img={image} name={name} userId={memberID} size="24px" />
      )}
      <StyledUserNameBody variant="body2" overflowEllipsis>
        {memberState === 'PENDING' ? email : name}
      </StyledUserNameBody>
      <StyledSingleSelectAutoComplete
        showClearIcon={false}
        value={permissions.filter((item) => item.id === permission)[0]}
        options={permissions}
        onChange={(
          val: AutocompleteDropdownItem<string | number, unknown> | null,
        ) => {
          onChange(val?.id as ShareSheetMemberActions);
        }}
        disableClearable
      />
      <StyledDropdownIconButton
        icon="check"
        iconColor={Theme.palette.green7}
        customColorConfig={customConfigColorForApproveIcon}
        variant="custom"
        onClick={handleApproveClick}
        menuItems={[]}
      />
      <StyledDropdownIconButton
        icon="close"
        iconColor={Theme.palette.red6}
        customColorConfig={customConfigColorForRejectIcon}
        variant="custom"
        onClick={handleDenyClick}
        menuItems={[]}
      />
    </Flex>
  );
};
