import styled from 'styled-components';
import ThemeV2 from '../../../theme';

import Body from '../../atoms/Body';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid ${ThemeV2.palette.gray4};
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const TopWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ChildrenWrapper = styled.div`
  margin-left: 20px;
`;

export const StyledBody = styled(Body)`
  margin-left: 4px;
`;
