import React, { useState, useEffect } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';

import styled from 'styled-components';
import Image from '../../atoms/Image';
import ThemeV2 from '../../../theme';
import KnowledgeCenterListItem from '../../../atomic/molecules/KnowledgeCenterListItem';
import KnowledgeCenterListItemLoader from '../../molecules/KnowledgeCenterListItem/Loader';
import Body from '../../../atomic/atoms/Body';
import { FixedSizeList } from 'react-window';
import { InfiniteData } from '@tanstack/react-query';
import {
  FeedAttachmentItemFromAPI,
  GetFeedAttachmentResponse,
} from '../../../interfaces/Feed';
import {
  trackKnowledgeCenterErrorEvent,
  trackKnowledgeCenterShowEvent,
} from '../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../Utils/analytics/constants';
import { Flex } from '../../../Utils/styles/display';
import { windowSizeAdapter } from '../../../Utils/window';

import {
  KNOWLEDGE_CENTER_EMPTY_SEARCH,
  KNOWLEDGE_CENTER_EMPTY_SEARCH_SUB,
  KNOWLEDGE_CENTER_ERROR,
  KNOWLEDGE_CENTER_ERROR_SUB,
  KNOWLEDGE_CENTER_LOADING,
  KNOWLEDGE_CENTER_LOADING_SUB,
} from '../../../languages/en/header';
import KnowledgeCenterLoadingImage from '../../../img/opening-folder.gif';
import KnowledgeCenterErrorImage from '../../../img/error.svg';
import KnowledgeCenterNoSearchImage from '../../../img/searching-girl.svg';
import useMobileApp from '../../../hooks/useMobileApp';

const StyledRowHeaders = styled.div`
  margin-top: 8px;
  height: 48px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  padding: 8px;
`;

const ScrollableCardList = styled.div<{
  isMobileApp: boolean;
}>`
  height: ${({ isMobileApp }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: 'calc(var(--mobileVh, 1vh) * 100 - 236px)',
      mobileWeb: 'calc(var(--mobileVh, 1vh) * 100 - 308px)',
      tablet: 'calc(100vh - 308px)',
      desktop: 'calc(100vh - 308px)',
    })};
  padding: 0 16px 16px 16px;
`;

const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const KnowledgeCenterController = (props: {
  data: InfiniteData<GetFeedAttachmentResponse> | undefined;
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  goToPost: (flowId: string, responseId: string) => void;
  handleFetchNextPage: () => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI | undefined) => void;
  copyPostLink: (flowId: string, responseId: string) => void;
  onDownloadFileClick: (
    fileDownload: FeedAttachmentItemFromAPI,
  ) => Promise<void>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isMobileApp } = useMobileApp();

  useEffect(() => {
    trackKnowledgeCenterShowEvent({
      show: KnowledgeCenterEvents.KnowledgeCenterShow,
      view: 'list',
    });
  }, []);

  const {
    data,
    hasNextPage,
    isLoading,
    goToPost,
    handleFetchNextPage,
    handleSetSelectedFile,
    copyPostLink,
    onDownloadFileClick,
    setModalOpen,
  } = props;
  const [flattenedData, setFlattenedData] = useState<
    FeedAttachmentItemFromAPI[]
  >([]);

  useEffect(() => {
    const allPages =
      data &&
      data.pages
        .map((page) => {
          return [...page.data];
        })
        .flat(1);
    setFlattenedData(allPages || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const isItemLoaded = (index: number) => {
    return !hasNextPage || index < flattenedData.length;
  };

  /* @ts-ignore-next-line */
  const RowItem = ({ index, style }) => {
    const listItem = flattenedData[index];
    if (!isItemLoaded(index)) {
      return <KnowledgeCenterListItemLoader style={style} />;
    } else if (listItem) {
      return (
        <KnowledgeCenterListItem
          key={
            listItem.response.blockId +
            (listItem.fileInfo?.name || listItem.urlInfo?.name) +
            listItem.response.responseId
          }
          listItem={listItem}
          handleSetSelectedFile={handleSetSelectedFile}
          goToPost={goToPost}
          openPreviewer={setModalOpen}
          onClickDownload={(listItemFile: FeedAttachmentItemFromAPI) =>
            onDownloadFileClick(listItemFile)
          }
          copyPostLink={copyPostLink}
          index={index}
          style={style}
        />
      );
    } else {
      return <div style={style}> All loaded! </div>;
    }
  };

  if (isLoading) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="loading-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_LOADING}
          src={KnowledgeCenterLoadingImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_LOADING}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_LOADING_SUB}</Body>
      </Flex>
    );
  } else if (data?.pages?.length === 0 || data?.pages[0].data?.length === 0) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="empty-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_EMPTY_SEARCH}
          src={KnowledgeCenterNoSearchImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_EMPTY_SEARCH}</Body>

        <Body variant="body1" style={{ width: '315px', marginTop: '4px' }}>
          {KNOWLEDGE_CENTER_EMPTY_SEARCH_SUB}
        </Body>
      </Flex>
    );
  } else if (!data) {
    trackKnowledgeCenterErrorEvent({
      error: KnowledgeCenterEvents.KnowledgeCenterError,
    });
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="error-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_ERROR}
          src={KnowledgeCenterErrorImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_ERROR}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_ERROR_SUB}</Body>
      </Flex>
    );
  } else {
    return (
      <ScrollableCardList isMobileApp={isMobileApp}>
        <StyledRowHeaders>
          <Body style={{ width: '50%' }} variant="body1">
            File
          </Body>
          <Body style={{ width: '25%' }} variant="body1">
            Uploaded by
          </Body>
          <Body style={{ width: '25%' }} variant="body1">
            Date shared
          </Body>
        </StyledRowHeaders>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={
                hasNextPage ? flattenedData.length + 1 : flattenedData.length
              }
              loadMoreItems={handleFetchNextPage}
              minimumBatchSize={20}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  itemCount={
                    hasNextPage
                      ? flattenedData.length + 1
                      : flattenedData.length
                  }
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  className="List"
                  itemSize={48}
                  height={height}
                  width={width}
                >
                  {RowItem}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </ScrollableCardList>
    );
  }
};

export default KnowledgeCenterController;
