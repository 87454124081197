import { useCallback, useEffect, useLayoutEffect } from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';

import useLayoutStore from '../../../stores/layoutStore';

import SVGIcon from '../../../atomic/atoms/SVGIcon';
import ThemeV2 from '../../../theme';

import { FeedsGridContainerLayout, Flex } from '../../../Utils/styles/display';

import FlowsBuilderCompletionController from '../FlowsBuilderCompletionController';
import FlowsBuilderContentController from '../FlowsBuilderContentController';
import FlowsBuilderTriggerController from '../FlowsBuilderTriggerController';
import useFlowBuilderController from './useFlowBuilderController';

import { shallow } from 'zustand/shallow';
import { FLOW_BUILDER_EVENTS } from '../../../Utils/analytics/constants';
import { trackFlowBuilderActionEvent } from '../../../Utils/analytics/flowsBuilder';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { StyledButton } from '../../../Utils/styles/button';
import Body from '../../../atomic/atoms/Body';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import FlowEndOccurrenceModal from '../../../atomic/molecules/FlowEndOccurrenceModal';
import { LinkButton } from '../../../atomic/molecules/FlowsFeedHeader/styles';
import DataChangeConfirmation from '../../../atomic/organism/DataChangeConfirmation';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import useCommonFeedSocket from '../../../hooks/useCommonFeedSocket';
import { WebSocketFeedTypes } from '../../../hooks/useCommonFeedSocket/types';
import useEndOccurrenceOption from '../../../hooks/useFlowFeedOptions/useEndOccurrenceOption';
import {
  ARCHIVE_IT,
  FLOWS_ARCHIVE_MODAL,
  FLOWS_END_OCCURRENCE_MODAL,
  FLOW_OWNER_ENDED_INFO,
} from '../../../languages/en/flows';
import {
  BUILDER_NAVIGATION_MODAL_TEXT,
  CREATE_FLOW_WITH_INVITE_MEMBERS,
} from '../../../languages/en/flows/builder';
import { ARCHIVE, WHEN, YOU } from '../../../languages/en/singleWords';
import FlowsParticipationPreviewController from '../../flows/FlowsParticipationPreviewController';
import { defaultBuilderBlocks } from './data';
import {
  Clickable,
  FlowNavigationModal,
  Message,
  Rectangle,
  StyledBody,
  StyledFlex,
  StyledIconContainer,
  StyledInfoAlert,
  TabItemContainer,
  WarningMessage,
} from './styles';

type ActiveOccurrenceMessageProps = {
  flowId: string;
};

const scrollingBlocks: string[] = [
  'trigger-block',
  'content-block',
  'completion-block',
];

const VerticalDivider = () => {
  return (
    <Flex justifyContent="center" alignItems="center">
      <Rectangle />
    </Flex>
  );
};

const ActiveOccurrenceMessage = ({ flowId }: ActiveOccurrenceMessageProps) => {
  const {
    models: { isEndOccurrenceModalOpen, isEndOccurrenceModalLoading },
    operations: {
      setEndOccurrenceModalClose,
      setIsEndOccurrenceModalOpen,
      handleEndOccurrenceButtonClick,
    },
  } = useEndOccurrenceOption();

  const handleOnClick = useCallback(() => {
    setIsEndOccurrenceModalOpen();
  }, [setIsEndOccurrenceModalOpen]);

  const handleOnEndOccurrenceClick = useCallback(() => {
    handleEndOccurrenceButtonClick(flowId);
  }, [handleEndOccurrenceButtonClick, flowId]);

  return (
    <>
      <FlowEndOccurrenceModal
        isOpen={isEndOccurrenceModalOpen}
        isLoading={isEndOccurrenceModalLoading}
        onModalClose={setEndOccurrenceModalClose}
        onCancelClick={setEndOccurrenceModalClose}
        onEndOccurrenceClick={handleOnEndOccurrenceClick}
        heading={FLOWS_END_OCCURRENCE_MODAL.modalHeading}
      />
      <WarningMessage>
        <SVGIcon
          icon="warning-hollow"
          color={ThemeV2.palette.gold6}
          size="16px"
        />
        <Message variant="body3">
          You have an active occurrence, you’ll have to wait until it ends to
          edit your flow.
        </Message>
        <Clickable onClick={handleOnClick}>End it early</Clickable>
      </WarningMessage>
    </>
  );
};

const FlowsBuilderController = ({ folderId }: { folderId: string | null }) => {
  const { models, operations } = useFlowBuilderController(folderId);

  const {
    emoji,
    flowId,
    flowName,
    blockData,
    templateId,
    templateName,
    currentStep,
    description,
    profileInfo,
    isInEditMode,
    touchedBlocks,
    disableSubmit,
    unlockedBlocks,
    isFlowBeingSaved,
    isArchiveModalOpen,
    isPreviewModalOpen,
    isArchiveFlowLoading,
    isScheduledFlowEnded,
    hasActiveOccurrence,
    disableNavigationModal,
    showDataChangeConfirm,
    inviteNewMemberEmailIds,
    isFlowDataChangeConfirmed,
    isInviteNewTeammatesModalOpen,
    revertBlockDataToDefaultState,
    isScheduledFlowCreatedUsingOneClick,
    flowIdToDuplicate,
  } = models;

  const {
    setEmoji,
    goToNextStep,
    onSubmitClick,
    setTemplateId,
    setCurrentStep,
    openArchiveModal,
    onInviteAndSaveFlow,
    setSpecificBlockData,
    setArchiveModalClose,
    handleOnArchiveClick,
    handleFlowNameChange,
    handleEditButtonClick,
    togglePreviewModalOpen,
    goToNextStepFromTrigger,
    isCurrentBlockDataValid,
    handleSetFlowDescription,
    setShowDataChangeConfirm,
    setIsFlowDataChangeConfirmed,
    closeInviteNewTeammatesModal,
  } = operations;

  const { removeSelectedMember, setLeftAsideOpenToTrue } = useLayoutStore(
    (state) => ({
      removeSelectedMember: state.removeSelectedMember,
      setLeftAsideOpenToTrue: state.setLeftAsideOpenToTrue,
    }),
    shallow,
  );

  useEffect(() => {
    return () => {
      setLeftAsideOpenToTrue();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useCommonFeedSocket({
    type: WebSocketFeedTypes.FLOW,
    flowId,
    setFlowFeedShowLoadMoreButtonToTrue: () => {},
    onFlowFeedFlowResponseDelete: () => {},
  });

  useLayoutEffect(() => {
    removeSelectedMember();
  }, [removeSelectedMember]);

  useLayoutEffect(() => {
    const scrollWrapper = document.getElementById('scrollableFeedContent');
    const activeBlock = document.getElementById(scrollingBlocks[currentStep]);

    setTimeout(() => {
      const activeBlockScrollPosition = activeBlock?.offsetTop || 0;
      scrollWrapper?.scroll({
        top: activeBlockScrollPosition - 135,
        behavior: 'smooth',
      });
    }, 400);
  }, [currentStep]);

  const { TRIGGER, CONTENT, COMPLETION, PARTICIPANTS } = defaultBuilderBlocks;

  const onEditButtonClick = () => {
    closeInviteNewTeammatesModal();
    setCurrentStep(PARTICIPANTS.index);
  };

  const renderFlowNameOrPlaceholder = () => {
    if (flowName && emoji) {
      return (
        <EmoticonWithText
          text={flowName}
          emoticon={mapHexCodeToEmoticon(emoji.unified)}
          hasBoldText
        />
      );
    }
    return <b>Name your flow...</b>;
  };

  const renderNavigationModal = (
    onConfirm: () => void,
    onCancel: () => void,
  ) => {
    const handleConfirmClick = () => {
      setTimeout(() => {
        onConfirm();
      }, 0);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_DRAFT_EXITED,
        templateId,
        templateName,
        flowTitle: flowName,
      });
    };
    const onDiscardFlow = () => {
      if (!templateId) {
        setCurrentStep(0);
      }
      revertBlockDataToDefaultState();
      setTemplateId('');
      handleConfirmClick();
    };

    if (isInEditMode) {
      return (
        <FlowNavigationModal
          isOpen={!disableNavigationModal}
          onModalClose={onCancel}
          heading={BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW.HEADING}
          primaryButton={{
            text: BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW.PRIMARY_BUTTON.TEXT,
            onClick: onSubmitClick,
            isLoading: isFlowBeingSaved,
            disabled: disableSubmit,
          }}
          secondaryButton={{
            text: BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW.SECONDARY_BUTTON.TEXT,
            onClick: handleConfirmClick,
            status: 'warning',
            variant: 'text',
          }}
          tertiaryButton={{
            text: BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW.TERTIARY_BUTTON.TEXT,
            onClick: onCancel,
            variant: 'text',
          }}
          alignActionButtons="right"
        >
          {
            BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW
              .BUILDER_NAVIGATION_MODAL_PREFIX_TEXT
          }{' '}
          {renderFlowNameOrPlaceholder()}{' '}
          {
            BUILDER_NAVIGATION_MODAL_TEXT.EDIT_FLOW
              .BUILDER_NAVIGATION_MODAL_SUFFIX_TEXT
          }
        </FlowNavigationModal>
      );
    }
    return (
      <FlowNavigationModal
        isOpen={!disableNavigationModal}
        onModalClose={onCancel}
        heading={BUILDER_NAVIGATION_MODAL_TEXT.CREATE_FLOW.HEADING}
        primaryButton={{
          text: BUILDER_NAVIGATION_MODAL_TEXT.CREATE_FLOW.PRIMARY_BUTTON.TEXT,
          onClick: onDiscardFlow,
          status: 'warning',
        }}
        secondaryButton={{
          text: BUILDER_NAVIGATION_MODAL_TEXT.CREATE_FLOW.SECONDARY_BUTTON.TEXT,
          onClick: onCancel,
        }}
      >
        {
          BUILDER_NAVIGATION_MODAL_TEXT.CREATE_FLOW
            .BUILDER_NAVIGATION_MODAL_PREFIX_TEXT
        }{' '}
        {renderFlowNameOrPlaceholder()}{' '}
        {
          BUILDER_NAVIGATION_MODAL_TEXT.CREATE_FLOW
            .BUILDER_NAVIGATION_MODAL_SUFFIX_TEXT
        }
      </FlowNavigationModal>
    );
  };

  let emojiIcon = '';
  if (emoji) {
    emojiIcon = mapHexCodeToEmoticon(emoji?.unified);
  }

  const ScheduleEndedBannerElement = (
    <Body variant="body3">
      {FLOW_OWNER_ENDED_INFO} {emojiIcon} {flowName} or{' '}
      <LinkButton type="button" onClick={openArchiveModal}>
        {ARCHIVE_IT}
      </LinkButton>
    </Body>
  );
  return (
    <FeedsGridContainerLayout>
      <TabItemContainer style={{ paddingTop: '16px' }}>
        {hasActiveOccurrence && <ActiveOccurrenceMessage flowId={flowId} />}
        <NavigationPrompt
          when={(currentLocation, nextLocation) => {
            return (
              !nextLocation?.pathname.includes('editor') &&
              !disableNavigationModal
            );
          }}
          renderIfNotActive={false}
        >
          {({ onConfirm, onCancel }) =>
            renderNavigationModal(onConfirm, onCancel)
          }
        </NavigationPrompt>
        {isScheduledFlowEnded && (
          <StyledInfoAlert
            id="flow-state-info-message"
            text={ScheduleEndedBannerElement}
            icon="info-circle"
            alertType="info"
          />
        )}
        <DataChangeConfirmation
          flowId={flowId}
          showDataChangeConfirm={showDataChangeConfirm}
          isFlowDataChangeConfirmed={isFlowDataChangeConfirmed}
          setIsFlowDataChangeConfirmed={setIsFlowDataChangeConfirmed}
          setShowDataChangeConfirm={setShowDataChangeConfirm}
        />
        <FlowsBuilderTriggerController
          id={scrollingBlocks[0]}
          index={TRIGGER.index}
          templateId={templateId}
          setShowDataChangeConfirm={setShowDataChangeConfirm}
          goToNextBlock={goToNextStepFromTrigger}
          isExpanded={currentStep === TRIGGER.index}
          isTouched={touchedBlocks.includes(TRIGGER.index)}
          isUnlocked={unlockedBlocks.includes(TRIGGER.index)}
          onEditButtonClick={handleEditButtonClick}
          triggerBlockData={blockData.TRIGGER}
          setSpecificBlockData={setSpecificBlockData}
          hasError={!isCurrentBlockDataValid(TRIGGER.index)}
          hasActiveOccurrence={hasActiveOccurrence}
          isDuplicateFlow={Boolean(flowIdToDuplicate)}
          isInEditMode={isInEditMode}
          isScheduledFlowCreatedUsingOneClick={
            isScheduledFlowCreatedUsingOneClick
          }
        />
        <VerticalDivider />
        <FlowsBuilderContentController
          id={scrollingBlocks[1]}
          index={CONTENT.index}
          setShowDataChangeConfirm={setShowDataChangeConfirm}
          goToNextBlock={goToNextStep}
          isExpanded={currentStep === CONTENT.index}
          isTouched={touchedBlocks.includes(CONTENT.index)}
          isUnlocked={unlockedBlocks.includes(CONTENT.index)}
          onEditButtonClick={handleEditButtonClick}
          blockData={blockData.CONTENT}
          setSpecificBlockData={setSpecificBlockData}
          hasError={!isCurrentBlockDataValid(CONTENT.index)}
        />
        <VerticalDivider />
        <FlowsBuilderCompletionController
          id={scrollingBlocks[2]}
          flowId={flowId}
          setShowDataChangeConfirm={setShowDataChangeConfirm}
          index={COMPLETION.index}
          isExpanded={currentStep === COMPLETION.index}
          isUnlocked={unlockedBlocks.includes(COMPLETION.index)}
          isTouched={touchedBlocks.includes(COMPLETION.index)}
          onEditButtonClick={handleEditButtonClick}
          onPreviewButtonClick={
            blockData.CONTENT.contentBlocks.length
              ? togglePreviewModalOpen
              : undefined
          }
          isCreateFlowButtonDisabled={disableSubmit}
          isCreateFlowButtonLoading={isFlowBeingSaved}
          onCreateFlowButtonClick={onSubmitClick}
          flowName={flowName}
          onFlowNameChange={handleFlowNameChange}
          flowDescription={description}
          onFlowDescriptionChange={handleSetFlowDescription}
          flowEmoticon={emoji}
          onFlowEmoticonSelect={setEmoji}
          hasError={!isCurrentBlockDataValid(COMPLETION.index)}
        />
        {isPreviewModalOpen && profileInfo && (
          <FlowsParticipationPreviewController
            togglePreviewModalOpen={togglePreviewModalOpen}
            profileInfo={profileInfo}
          />
        )}
        <BasicModalTemplate
          isOpen={isArchiveModalOpen}
          heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
          primaryButton={{
            text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
            onClick: handleOnArchiveClick,
            status: 'warning',
            isLoading: isArchiveFlowLoading,
          }}
          secondaryButton={{
            text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
            onClick: setArchiveModalClose,
          }}
          onModalClose={setArchiveModalClose}
        >
          <Body variant="body2" inline>
            {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
          </Body>
          {emojiIcon && (
            <Body variant="body2Medium" inline>
              <EmoticonWithText
                emoticon={emojiIcon}
                text={flowName}
                hasBoldText
              />
            </Body>
          )}
          <Body variant="body2" inline>
            {', '}
            {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
          </Body>
        </BasicModalTemplate>

        {/* Modal Invite new teammates to this flow */}
        {inviteNewMemberEmailIds && inviteNewMemberEmailIds.length > 0 && (
          <BasicModalTemplate
            isOpen={isInviteNewTeammatesModalOpen}
            heading={CREATE_FLOW_WITH_INVITE_MEMBERS.HEADING}
            primaryButton={{
              text: isInEditMode
                ? CREATE_FLOW_WITH_INVITE_MEMBERS.SAVE_AND_INVITE
                : CREATE_FLOW_WITH_INVITE_MEMBERS.CREATE_AND_INVITE,
              onClick: onInviteAndSaveFlow,
              isLoading: false,
            }}
            secondaryButton={{
              text: CREATE_FLOW_WITH_INVITE_MEMBERS.SECONDARY_BUTTON_TEXT,
              onClick: closeInviteNewTeammatesModal,
            }}
            onModalClose={closeInviteNewTeammatesModal}
          >
            <StyledBody variant="body2">
              {CREATE_FLOW_WITH_INVITE_MEMBERS.getAddedNewMembersInviteCountText(
                inviteNewMemberEmailIds.length,
              )}
            </StyledBody>
            <StyledFlex>
              <Body variant="body2Bold" inline>
                {inviteNewMemberEmailIds.join(', ')}
              </Body>
              <StyledButton onClick={onEditButtonClick}>
                <StyledIconContainer size="24px">
                  <SVGIcon icon="edit" size="16px" />
                </StyledIconContainer>
              </StyledButton>
            </StyledFlex>
            <StyledBody variant="body2">
              {CREATE_FLOW_WITH_INVITE_MEMBERS.getInviteMemberDescription(
                isInEditMode
                  ? CREATE_FLOW_WITH_INVITE_MEMBERS.SAVE_AND_INVITE
                  : CREATE_FLOW_WITH_INVITE_MEMBERS.CREATE_AND_INVITE,
              )}
            </StyledBody>
          </BasicModalTemplate>
        )}
      </TabItemContainer>
    </FeedsGridContainerLayout>
  );
};

export default FlowsBuilderController;
