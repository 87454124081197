import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';

import { isMobilePlatform } from '../../../Utils/window';

import { NewSingleFlowFeedPostController } from './NewSingleFlowFeedPostController';
import { LegacySingleFlowFeedPostController } from './LegacySingleFlowFeedPostController';
import useNotebookPusher from '../../notebook/NotebookViewController/useNotebookPusher';

const SingleFlowFeedPostController = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { postType } = useParams<{ postType: string }>();

  useEffect(() => {
    if (!isMobilePlatform) {
      const params = new URLSearchParams(search);
      params.set('type', postType === 'recognition' ? 'recognition' : 'flow');
      params.set('showReplies', 'true');
      history.replace({
        search: params.toString(),
      });
    }
  }, [postType, isMobilePlatform]);

  useNotebookPusher();

  if (postType === 'recognition') {
    return <LegacySingleFlowFeedPostController />;
  }

  return <NewSingleFlowFeedPostController />;
};

export default SingleFlowFeedPostController;
