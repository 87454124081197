import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { StyledComponentProps } from './interface';

export const NotificationLink = styled(Link)`
  text-decoration: none;
`;

export const NotificationImage = styled.div<{ isMobileApp?: boolean }>`
  margin-top: ${(props) => (props.isMobileApp ? '2px' : '1px')};
`;

export const StyledBody = styled(Body)``;

export const TimeAgoText = styled(Body)<StyledComponentProps>`
  white-space: nowrap;
  color: ${(props) =>
    !props.read ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray7};
  &:before {
    content: '';
    display: inline-block;
    margin: 0px 4px 2px 4px;
    height: 4px;
    width: 4px;
    background: ${(props) =>
      !props.read ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray9};
    border-radius: 12px;
  }
`;
TimeAgoText.displayName = 'TimeAgoText';

export const UnreadDot = styled.span<{ isMobileApp?: boolean }>`
  margin-top: ${(props) => (props.isMobileApp ? '7px' : '6px')};
  margin-left: 8px;
  margin-right: 4px;
  min-width: 8px;
  height: 8px;
  background: ${ThemeV2.palette.geekBlue6};
  border-radius: 100%;
`;

export const NotificationWrapper = styled.div<{ read?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};

    ${StyledBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }

    ${TimeAgoText} {
      color: ${ThemeV2.palette.geekBlue6};

      &:before {
        background: ${ThemeV2.palette.geekBlue6};
      }
    }
  }
`;
NotificationWrapper.displayName = 'NotificationWrapper';

export const NotificationTextWrapper = styled.div`
  width: 100%;
  margin-left: 8px;
`;
NotificationTextWrapper.displayName = 'NotificationTextWrapper';

export const TextContentWrapper = styled.div<StyledComponentProps>`
  display: inline;
  & span {
    color: ${(props) => !props.read && ThemeV2.palette.geekBlue6};
  }
`;
TextContentWrapper.displayName = 'TextContentWrapper';
