import React, { useCallback } from 'react';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import { device } from '../../../constants/layout';
import {
  StyledFlowPromptCard,
  StyledDropdownIconButton,
  StyledFlowPromptCardBody,
  StyledFlowPromptCardFooter,
  StyledFlowPromptCardHeader,
  StyledStatusBody,
  StyledStatusBar,
  StyledTitleBar,
  StyledButton,
} from './styles';
import { FlowPromptCardProps, FlowPromptCardStatus, UserRole } from './types';
import TextWithIcon from '../../atoms/TextWithIcon';
import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';
import {
  ANSWER_WITH_SHORTCUT,
  COLLECTING_ANSWERS,
  MORE_OPTIONS,
  OCCURRENCE_CLOSED,
  SET_SCHEDULE,
  T0DO,
  VIEW_FEED,
} from '../../../languages/en/flows';
import {
  ANSWER,
  IN,
  AT,
  DUE,
  CLOSES,
  OCCURRENCE,
} from '../../../languages/en/singleWords';
import Pulse from '../../atoms/Pulse';
import ThemeV2 from '../../../theme';
import BubbleText from '../../atoms/BubbleText';
import { getFormattedDate } from '../../../Utils/time';
import { capitalizeFirstLetter } from '../../../Utils/text';
import { FeedTypes } from '../../molecules/ActionBar/types';

const FlowPromptCard = (props: FlowPromptCardProps) => {
  const {
    title,
    emoticon,
    description,
    status,
    userRole,
    dropdownMenuItems,
    timestamp,
    hasShortcut,
    isAnswered,
    dueDate,
    feedType,
    dropdownItemClick,
    onViewFeedClick,
    onAnswerClick,
    dataTestId,
    onAnswerWithShortcutClick,
  } = props;

  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });

  const renderStatus = useCallback(() => {
    if (status === FlowPromptCardStatus.Open) {
      return (
        <StyledStatusBody color="polarGreen7" variant="body3">
          <Pulse size={8} color={ThemeV2.palette.polarGreen7} />
          {COLLECTING_ANSWERS}
        </StyledStatusBody>
      );
    }
    if (status === FlowPromptCardStatus.Closed) {
      return (
        <TextWithIcon
          icon="flow-prompt-card-indicator-closed"
          color="darkGray7"
        >
          {OCCURRENCE_CLOSED}
        </TextWithIcon>
      );
    }
    if (status === FlowPromptCardStatus.Schedule) {
      return (
        <StyledStatusBody color="geekBlue6" variant="body3">
          <Pulse size={8} color={ThemeV2.palette.geekBlue6} />
          {T0DO}
        </StyledStatusBody>
      );
    }
    return null;
  }, [status]);

  const renderDueDate = useCallback(() => {
    if (status === FlowPromptCardStatus.Closed || !dueDate) {
      return null;
    }

    if (userRole === UserRole.Participant && !isAnswered) {
      return (
        <BubbleText
          color="orange"
          text={`${DUE} ${IN.toLowerCase()} ${dueDate}`}
        />
      );
    }

    return (
      <BubbleText
        color="gray"
        text={`${CLOSES} ${IN.toLowerCase()} ${dueDate}`}
      />
    );
  }, [dueDate, isAnswered, status, userRole]);

  const renderTimeStamp = useCallback(() => {
    return (
      <Body variant="body3" color="gray7">
        {capitalizeFirstLetter(OCCURRENCE)}: {getFormattedDate(timestamp)} {AT}{' '}
        {moment(timestamp).format('h:mm a')}
      </Body>
    );
  }, [timestamp]);

  const renderFooter = useCallback(() => {
    let renderAnswerButton;
    if (status === FlowPromptCardStatus.Schedule && onAnswerClick) {
      renderAnswerButton = (
        <StyledButton isFullWidth onClick={onAnswerClick}>
          {SET_SCHEDULE}
        </StyledButton>
      );
    }
    if (userRole === UserRole.Participant) {
      if (hasShortcut && onAnswerWithShortcutClick) {
        renderAnswerButton = (
          <StyledButton
            disabled={isAnswered}
            isFullWidth
            onClick={onAnswerWithShortcutClick}
          >
            {isDesktopView ? ANSWER_WITH_SHORTCUT : ANSWER}
          </StyledButton>
        );
      } else if (status !== FlowPromptCardStatus.Closed && onAnswerClick) {
        renderAnswerButton = (
          <StyledButton
            disabled={isAnswered}
            isFullWidth
            onClick={onAnswerClick}
          >
            {ANSWER}
          </StyledButton>
        );
      }
    }

    return (
      <StyledFlowPromptCardFooter>
        {feedType !== FeedTypes.Flow && (
          <StyledButton isFullWidth color="secondary" onClick={onViewFeedClick}>
            {VIEW_FEED}
          </StyledButton>
        )}
        {renderAnswerButton}
      </StyledFlowPromptCardFooter>
    );
  }, [
    feedType,
    hasShortcut,
    isAnswered,
    isDesktopView,
    onAnswerClick,
    onAnswerWithShortcutClick,
    onViewFeedClick,
    status,
    userRole,
  ]);

  return (
    <StyledFlowPromptCard data-testid={dataTestId}>
      <StyledFlowPromptCardBody>
        <StyledFlowPromptCardHeader>
          <StyledStatusBar>
            {renderStatus()}
            <StyledDropdownIconButton
              dropdownPlacement={DropdownPlacement.BottomEnd}
              icon="more"
              menuItems={dropdownMenuItems}
              onItemClick={dropdownItemClick}
              title={MORE_OPTIONS}
              dataTestId="cardDropdownOptions"
            />
          </StyledStatusBar>
          <StyledTitleBar>
            <Heading variant="h5" marginTop="0" marginBottom="0">
              <span aria-label="img" role="img">
                {emoticon}
              </span>{' '}
              {title}
            </Heading>
            <div>{userRole && renderDueDate()}</div>
          </StyledTitleBar>
          {timestamp && renderTimeStamp()}
        </StyledFlowPromptCardHeader>
        <Body variant="body2" color="gray8">
          {description}
        </Body>
      </StyledFlowPromptCardBody>
      {renderFooter()}
    </StyledFlowPromptCard>
  );
};

export default FlowPromptCard;
