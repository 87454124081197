import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Collapse } from '@mui/material';
import GifContainer from '../../molecules/GifContainer';
import {
  GiveRecognitionFormBottomButtonsProps,
  PostType,
} from '../../molecules/GiveRecognitionFormBottomButtons/interface';
import Editor, { EditorProps } from '../RichTextEditor';
import mentionsStyles from '../RichTextEditor/mentionsStyles.module.css';
import editorStyles from './editor.module.css';

import { Flex } from '../../../Utils/styles/display';
import {
  EditorPlaceholder as EditorPlaceholderForComments,
  EditorPlaceholderRepliesTurnedOff,
} from '../../../languages/en/commentsForm/index';
import ThemeV2 from '../../../theme';
import Avatar from '../../atoms/Avatar';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { AddTrophiesInputProps } from '../../molecules/AddTrophiesInput';
import { GiveRecognitionTipsProps } from '../../molecules/GiveRecognitionTips';

import { EditorState } from 'draft-js';
import { PostInputBaseProps } from './interface';
import {
  ButtonsWrapper,
  CancelButton,
  CommentWrapper,
  EditorGifContainer,
  InputErrorMessageBody,
  PostIconButton,
  RecognitionEditorWrapper,
  RecognitionSectionWrapper,
  RepliesEditor,
  SaveButton,
  StyledBody,
  StyledFormButtons,
  StyledGifContainer,
  StyledGiveRecognitionTips,
  StyledIconButton,
  TooltipWrapper,
} from './styles';

import { POST_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { trackPostActionEvent } from '../../../Utils/analytics/post';
import ToolTip from '../../molecules/ToolTipV2';

export type PostInputProps = PostInputBaseProps &
  GiveRecognitionFormBottomButtonsProps &
  AddTrophiesInputProps &
  EditorProps &
  GiveRecognitionTipsProps & {
    onCommentButtonClick?: () => void;
    variant?: 'feed' | 'sidebar';
    setGifUrl?: (url: string) => void;
    setSelectedMentions?: any;
    setEditorState?: (editorState: EditorState) => void;
    isInEditMode?: boolean;
    onCancelClick?: () => void;
    showChatView?: boolean;
  };

const PostInput = (props: PostInputProps) => {
  const {
    containerRef,
    currentUser,
    hasText = false,
    isEditorFocused = false,
    showRecognitionTips,
    selectedGif,
    onGifRemoveClick,
    inputErrorMessage,
    isDummy = false,
    setEditorFocus,
    variant,
    isInEditMode,
    onCancelClick,
    showChatView,
    handleCommentDelete,
    isDisabled,
  } = props;
  const {
    postType = PostType.Recognition,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onAttachmentClick,
    isGifSelected,
    isAttachmentSelected,
    isPrivateMessageSelected,
    onPrivateMessageClick,
    onPostClick,
    showAddTrophy,
    isCreatingPostOrComment,
    isAttachmentButtonHidden,
    isPrivatePostButtonHidden,
    gifRatings,
    buttonColor,
    isTasksButtonHidden,
  }: GiveRecognitionFormBottomButtonsProps = props;
  const {
    value,
    maxValue,
    buttonText,
    assemblyCurrency,
    menuItems,
    splitBy,
    canAllowCustomAmount,
    onBlur,
    onChange,
    onItemClick,
  }: AddTrophiesInputProps = props;
  const {
    editorState,
    editorRefElement,
    onAddMention,
    onEditorBlur,
    onEditorChange,
    onEditorFocus,
    suggestions,
    onSearchChange,
    isMentionsLoading,
  }: EditorProps = props;

  const [isFocused, setIsFocused] = useState(false);
  const [isKeyDownFired, setIsKeyDownFired] = useState(false);
  const { isOpen, onToggleClick, onToggleKeyPress }: GiveRecognitionTipsProps =
    props;

  const handleOnEditorFocus = useCallback(() => {
    setIsFocused(true);
    if (onEditorFocus) {
      onEditorFocus();
    }
  }, [onEditorFocus]);

  const handleOnEditorBlur = useCallback(() => {
    setIsFocused(false);
    if (onEditorBlur) {
      onEditorBlur();
    }
  }, [onEditorBlur]);

  const handleOnPostClick = useCallback(() => {
    if (handleCommentDelete && !hasText && !isGifSelected && isInEditMode)
      handleCommentDelete();
    else if (onPostClick) {
      onPostClick();
      setIsKeyDownFired(false);
    }
  }, [handleCommentDelete, hasText, isGifSelected, isInEditMode, onPostClick]);

  useEffect(() => {
    if (isGifSelected && editorRefElement.current)
      editorRefElement.current.focus();
  }, [isGifSelected, editorRefElement]);

  const recognitionEditor = useMemo(() => {
    if (postType === PostType.Recognition) {
      return (
        <>
          <Flex padding="16px 16px 0">
            {variant !== 'sidebar' && <Avatar size="24px" {...currentUser} />}
            <StyledBody variant="body2" color={ThemeV2.palette.gray9}>
              {currentUser.name}
            </StyledBody>
          </Flex>
          <RecognitionEditorWrapper onClick={setEditorFocus}>
            <Editor
              editorRefElement={editorRefElement}
              editorState={editorState}
              onEditorChange={onEditorChange}
              suggestions={suggestions}
              onAddMention={onAddMention}
              onSearchChange={onSearchChange}
              onEditorFocus={handleOnEditorFocus}
              onEditorBlur={handleOnEditorBlur}
              isMentionsLoading={isMentionsLoading}
            />
            {inputErrorMessage && (
              <InputErrorMessageBody variant="body3" color="red6">
                {inputErrorMessage}
              </InputErrorMessageBody>
            )}
          </RecognitionEditorWrapper>
        </>
      );
    }
    return null;
  }, [
    postType,
    variant,
    currentUser,
    setEditorFocus,
    editorRefElement,
    editorState,
    onEditorChange,
    suggestions,
    onAddMention,
    onSearchChange,
    handleOnEditorFocus,
    handleOnEditorBlur,
    isMentionsLoading,
    inputErrorMessage,
  ]);

  const [hasAttachment, setAttachment] = useState(!!selectedGif);

  useEffect(() => {
    setAttachment(!!selectedGif);
  }, [selectedGif]);

  const renderIconButton = useMemo(
    () => (
      <StyledIconButton
        icon="email-send"
        dataTestId="createComment"
        size={ButtonSize.Normal}
        rounded
        color="primary"
        disabled={(!hasText || isCreatingPostOrComment) && !isGifSelected}
        onClick={handleOnPostClick}
      />
    ),
    [hasText, isCreatingPostOrComment, isGifSelected, handleOnPostClick],
  );

  const renderPostButton = useMemo(() => {
    if (isInEditMode) {
      return (
        <Flex>
          <CancelButton
            icon="close"
            color="primary"
            onClick={onCancelClick}
            size={ButtonSize.Normal}
            dataTestId="post-cancel-button"
            useSpacing={postType !== PostType.Recognition}
            disabled={isCreatingPostOrComment && !isGifSelected}
          />
          <SaveButton
            icon="check"
            color="primary"
            onClick={handleOnPostClick}
            size={ButtonSize.Normal}
            dataTestId="post-save-button"
            useSpacing={postType !== PostType.Recognition}
            disabled={isCreatingPostOrComment && !isGifSelected}
          />
        </Flex>
      );
    }

    return (
      <ToolTip
        toolTipComponent={
          <PostIconButton
            icon="email-send"
            dataTestId="createComment"
            size={ButtonSize.Normal}
            color="primary"
            useSpacing={postType !== PostType.Recognition}
            disabled={
              ((!hasText || isCreatingPostOrComment) && !isGifSelected) ||
              isDisabled
            }
            onClick={handleOnPostClick}
          />
        }
        disabled={!isDisabled}
        position="top-start"
      >
        <TooltipWrapper>
          Replies have been turned off for this post, so you cannot share this
          reply
        </TooltipWrapper>
      </ToolTip>
    );
  }, [
    isInEditMode,
    postType,
    hasText,
    isCreatingPostOrComment,
    isGifSelected,
    handleOnPostClick,
    isDisabled,
    onCancelClick,
  ]);

  const handleFormButtonClick = useCallback(() => {
    if (editorRefElement.current) {
      editorRefElement.current.focus();
    }
  }, [editorRefElement]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (!isKeyDownFired) {
        setIsKeyDownFired(true);

        trackPostActionEvent({
          action: POST_ANALYTICS_EVENTS.REPLY_STARTED,
          messageType: showChatView ? 'directMessages' : 'replies',
        });
      }

      const EDITOR_TOOLBAR_SIZE = 50;
      const editor = document.getElementById('editor');

      if (
        editor &&
        ((event.shiftKey && event.code === 'Enter') ||
          (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)))
      ) {
        setTimeout(() => {
          editor.scrollTop = editor.scrollHeight + EDITOR_TOOLBAR_SIZE;
        });
      }
    },
    [isKeyDownFired, showChatView],
  );

  const isFormButtonCollapsed =
    postType === PostType.Comment
      ? isEditorFocused || hasText || hasAttachment
      : true;

  return (
    <div ref={containerRef}>
      {variant !== 'sidebar' &&
        postType === PostType.Comment &&
        selectedGif !== '' && (
          <StyledGifContainer
            src={selectedGif}
            onGifRemoveClick={onGifRemoveClick}
          />
        )}
      {variant !== 'sidebar' && (
        <RecognitionSectionWrapper>
          {recognitionEditor}
          {postType === PostType.Recognition && selectedGif !== '' && (
            <GifContainer
              src={selectedGif}
              onGifRemoveClick={onGifRemoveClick}
            />
          )}
        </RecognitionSectionWrapper>
      )}

      <CommentWrapper
        variant={variant}
        postType={postType}
        isEditorFocused={isEditorFocused}
        hasText={hasText}
        hasAttachment={hasAttachment}
        isDisabled={isDisabled}
      >
        {postType === PostType.Comment && (
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            onKeyDown={handleKeyDown}
            justifyContent="space-between"
            onClick={handleFormButtonClick}
          >
            {variant !== 'sidebar' && <Avatar size="32px" {...currentUser} />}
            {variant !== 'sidebar' && (
              <Editor
                suggestions={suggestions}
                editorState={editorState}
                editorStyles={editorStyles}
                onEditorBlur={handleOnEditorBlur}
                onAddMention={onAddMention}
                onEditorFocus={handleOnEditorFocus}
                onSearchChange={onSearchChange}
                onEditorChange={onEditorChange}
                mentionsStyles={mentionsStyles}
                editorRefElement={editorRefElement}
                isMentionsLoading={isMentionsLoading}
                editorPlaceholder={EditorPlaceholderForComments}
              />
            )}
            {variant === 'sidebar' && (
              <RepliesEditor
                stripPastedStyles
                suggestions={suggestions}
                editorState={editorState}
                onAddMention={onAddMention}
                onEditorBlur={handleOnEditorBlur}
                onEditorFocus={handleOnEditorFocus}
                hasGifSelected={isGifSelected}
                onSearchChange={onSearchChange}
                onEditorChange={onEditorChange}
                mentionsStyles={mentionsStyles}
                editorRefElement={editorRefElement}
                onReturnKeyPress={() => {
                  if (!isDisabled) {
                    handleOnPostClick();
                  }
                }}
                isMentionsLoading={isMentionsLoading}
                editorPlaceholder={
                  isDisabled
                    ? EditorPlaceholderRepliesTurnedOff
                    : EditorPlaceholderForComments
                }
              />
            )}

            {postType === PostType.Comment && selectedGif !== '' && (
              <EditorGifContainer
                src={selectedGif}
                onGifRemoveClick={onGifRemoveClick}
              />
            )}

            {variant !== 'sidebar' && renderIconButton}
          </Flex>
        )}
        {postType === PostType.Recognition && showRecognitionTips && (
          <StyledGiveRecognitionTips
            isOpen={isOpen}
            onToggleClick={onToggleClick}
            onToggleKeyPress={onToggleKeyPress}
          />
        )}
        <Collapse in={isFormButtonCollapsed || variant === 'sidebar'}>
          {!isDummy && (
            <ButtonsWrapper>
              <div>
                <StyledFormButtons
                  isEndIcon
                  value={value}
                  onBlur={onBlur}
                  splitBy={splitBy}
                  postType={postType}
                  onChange={onChange}
                  maxValue={maxValue}
                  disabled={!hasText}
                  isFocused={isFocused}
                  menuItems={menuItems}
                  buttonText={buttonText}
                  gifRatings={gifRatings}
                  buttonColor={buttonColor}
                  onItemClick={onItemClick}
                  isGifSelected={isGifSelected}
                  showAddTrophy={showAddTrophy}
                  onPostClick={handleOnPostClick}
                  onEmoticonClick={onEmoticonClick}
                  assemblyCurrency={assemblyCurrency}
                  onAtMentionClick={onAtMentionClick}
                  onAttachmentClick={onAttachmentClick}
                  onGifSelectorClick={onGifSelectorClick}
                  isTasksButtonHidden={isTasksButtonHidden}
                  canAllowCustomAmount={canAllowCustomAmount}
                  isAttachmentSelected={isAttachmentSelected}
                  onPrivateMessageClick={onPrivateMessageClick}
                  isCreatingPostOrComment={isCreatingPostOrComment}
                  isPrivateMessageSelected={isPrivateMessageSelected}
                  isAttachmentButtonHidden={isAttachmentButtonHidden}
                  isPrivatePostButtonHidden={isPrivatePostButtonHidden}
                  iconSize={
                    variant === 'sidebar' ? ButtonSize.Normal : ButtonSize.Small
                  }
                  isEmoticonsButtonHidden={isDisabled}
                  isAtMentionButtonHidden={isDisabled}
                  isGifButtonHidden={isDisabled}
                />
              </div>
              {variant === 'sidebar' && renderPostButton}
            </ButtonsWrapper>
          )}
        </Collapse>
      </CommentWrapper>
    </div>
  );
};

const MemoizedPostOrganism = memo(PostInput);
MemoizedPostOrganism.displayName = 'PostInput';

export default MemoizedPostOrganism;
