import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import Body, { TextAlignType } from '../../atoms/Body';
import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';
import { OK, CANCEL, GO, BACK } from '../../../languages/en/singleWords';
import { ButtonStatuses } from '../../atoms/Button/interfaces';

const ContentWrapper = styled.div`
  text-align: center;

  & > h5 {
    margin: 0;
    margin-bottom: 8px;
  }
`;

export interface ThreeButtonModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  heading: string;
  content?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  tertiaryButtonText?: string;
  onPrimary?: () => void;
  onSecondary?: () => void;
  onTertiary?: () => void;
  mainContentTextAlign?: TextAlignType;
  primaryButtonStatus?: ButtonStatuses;
  contentTextAlign?: TextAlignType;
  contentColor?: string;
}

export const ThreeButtonModal = ({
  isOpen,
  onModalClose,
  content,
  heading,
  primaryButtonText,
  secondaryButtonText,
  tertiaryButtonText,
  onPrimary,
  onSecondary,
  onTertiary,
  primaryButtonStatus,
  contentTextAlign,
  contentColor,
}: ThreeButtonModalProps) => {
  const handlePrimaryClick = useCallback(() => {
    if (onPrimary) {
      onPrimary();
    }
    onModalClose();
  }, [onPrimary, onModalClose]);

  const handleSecondaryClick = useCallback(() => {
    if (onSecondary) {
      onSecondary();
    }
    onModalClose();
  }, [onSecondary, onModalClose]);

  const handleTertiaryClick = useCallback(() => {
    if (onTertiary) {
      onTertiary();
    }
    onModalClose();
  }, [onTertiary, onModalClose]);

  const primaryButton = useMemo<ModalButtonProps>(
    () => ({
      text: primaryButtonText || OK,
      isLoading: false,
      onClick: handlePrimaryClick,
      status: primaryButtonStatus,
    }),
    [primaryButtonText, primaryButtonStatus, handlePrimaryClick],
  );
  const secondaryButton = useMemo<ModalButtonProps>(
    () => ({
      text: secondaryButtonText || CANCEL,
      disabled: false,
      onClick: handleSecondaryClick,
      variant: 'text',
      status: 'warning',
    }),
    [secondaryButtonText, handleSecondaryClick],
  );
  const tertiaryButton = useMemo<ModalButtonProps>(
    () => ({
      text: tertiaryButtonText || GO + ' ' + BACK,
      disabled: false,
      onClick: handleTertiaryClick,
      variant: 'text',
    }),
    [tertiaryButtonText, handleTertiaryClick],
  );

  return (
    <BasicModalTemplate
      isOpen={isOpen}
      heading={heading}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      tertiaryButton={tertiaryButton}
      onModalClose={onModalClose}
      alignActionButtons={'right'}
    >
      <ContentWrapper>
        {content && (
          <Body
            variant="body2"
            textAlign={contentTextAlign}
            color={contentColor}
          >
            {content}
          </Body>
        )}
      </ContentWrapper>
    </BasicModalTemplate>
  );
};
