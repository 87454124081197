import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DELETE_ZAPIER_AUTH_KEY,
  GENERATE_EXTERNAL_AUTH_KEY_DETAILS,
  GET_EXTERNAL_AUTH_KEY_DETAILS,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

export interface FetchExternalAuthKeyResponse {
  authKey: string;
  triggersConnected: number;
}

export const useFetchExternalAuthKey = (enabled = true, flowId = '') => {
  return useQuery<FetchExternalAuthKeyResponse>(
    [GET_EXTERNAL_AUTH_KEY_DETAILS],
    () =>
      makeAPICallWithDataReturn(GET_EXTERNAL_AUTH_KEY_DETAILS, undefined, {
        integration: 'ZAPIER',
        flowId,
      }),
    {
      enabled,
    },
  );
};

export const useGenerateExternalAuthKey = (
  onSuccess: (authKey: string) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      makeAPICall(GENERATE_EXTERNAL_AUTH_KEY_DETAILS, {
        integration: 'ZAPIER',
      }),
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries({
          queryKey: [GET_EXTERNAL_AUTH_KEY_DETAILS],
        });

        onSuccess(response.data.authKey);
      },
    },
  );
};

export const useDeactivateZapierApiKey = (onSuccess: () => void = () => {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      makeAPICall(DELETE_ZAPIER_AUTH_KEY, {
        integration: 'ZAPIER',
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [GET_EXTERNAL_AUTH_KEY_DETAILS],
        });
        onSuccess();
      },
    },
  );
};
