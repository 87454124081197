/* eslint-disable max-len */
import axios from 'axios';
import fileDownload from 'js-file-download';
import titleize from 'titleize';
import uuid from 'uuid';
import ThemeV2 from '../../theme';
import { TemplateDetails } from '../../queries/Flows/Template/types';
import {
  AnonymityStates,
  EmoticonResponse,
  FlowItemResponse,
  FlowResponse,
  GetFlowDetailsResponse,
} from '../../queries/Flows/interfaces';

import envelopeImage from '../../atomic/atoms/SVGIcon/icons/envelope.svg';
import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { Schedule } from '../../atomic/molecules/FlowsFeedHeader/type';
import { OptionItemProps } from '../../atomic/molecules/OptionItemsEditor/interface';
import { Participant } from '../../atomic/molecules/ParticipantsList/types';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { ParticipationTemplateErrorTypes } from '../../atomic/pages/ParticipationTemplate/types';
import {
  DELETE_ATTACHMENT_FILE,
  GET_FLOW_POST_DOWNLOAD_URL,
} from '../../constants/endpoints';
import postMessageToMobileApp from '../../hooks/useMobileApp/postMessageToMobileApp';
import { OptionsSelectObject } from '../../interfaces/Flow/Builder';
import { AppAction, AppTargetEvent } from '../../interfaces/PostMessage';
import { FlowCollaborator, IMemberDTO } from '../../interfaces/member';
import { MemberState } from '../../interfaces/user';
import { ENTIRE_ORGANIZATION } from '../../languages/en';
import { NO_DESCRIPTION } from '../../languages/en/flows/feed';
import {
  CANT_FIND_FLOW,
  HEAD_TO_MAIN_FEED,
} from '../../languages/en/flows/participation';
import {
  CUSTOM,
  FLOW,
  ONLY,
  OPTION,
  OTHER,
  OWNER,
  PARTICIPANTS,
  POSTED,
  PRIVATELY,
} from '../../languages/en/singleWords';
import {
  DownloadSecureFileResponse,
  FileOperationsRequest,
  VisibilityType,
} from '../../queries/Flows/Feed/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import { makeAPICall, makeAPICallWithDataReturn } from '../../queries/utils';
import mobileStore from '../../stores/mobileAppStore';
import { mapHexCodeToEmoticon } from '../mappers';
import { canInviteMembers, getIsInviteRequiresApproval } from '../permissions';
import { capitalizeFirstLetter, getFullName } from '../text';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';

import {
  canPostAndViewPostsSetting,
  canOnlyPostSetting,
  canOnlyViewPostsSetting,
  ShareSheetMemberActions,
  excludedFromFlowSetting,
} from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import {
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
} from '../../controllers/flows/FlowsShareSheetController/types';
import { ShareSheetPermissions } from '../../controllers/flows/FlowsShareSheetController/ShareSheetAccessPreviewController/data';
import useLayoutStore from '../../stores/layoutStore';
import { FlowRequestPermission } from '../../interfaces/Flow';
import { ShareRequestRole } from '../analytics/interfaces';
import { serializeCollaboratorToMemberDTO } from './sharesheet';

export const defaultFlow: FlowResponse = {
  state: 'ACTIVE',
  isShortcut: true,
  isFlowOwner: false,
  flowId: 'recognition',
  createdAt: new Date(),
  updatedAt: new Date(),
  name: 'Give Recognition',
  color: ThemeV2.palette.blue2,
  icon: { kind: 'HEX_CODE', value: '1F389' },
  description: `Welcome to recognition's new home`,
  creationSource: 'PRELOAD',
  responseSettings: {
    anonymity: {
      state: AnonymityStates.DISABLED,
    },
  },
};

export const getVisibilityIcon = (visibility: VisibilityType) => {
  if (visibility === 'custom' || visibility === 'onlyParticipants') {
    return `people`;
  }
  if (visibility === 'private' || visibility === 'onlyOwners') {
    return 'private';
  }
  return 'eye';
};

export const getVisibilityTooltipText = (
  visibility: VisibilityType,
  viewCount?: number,
) => {
  switch (visibility) {
    case 'onlyParticipants':
      return `${titleize(ONLY)} ${FLOW} ${PARTICIPANTS} ${
        viewCount ? `(${viewCount})` : ''
      }`;
    case 'custom':
      return `${titleize(CUSTOM)} ${viewCount ? `(${viewCount})` : ''}`;
    case 'onlyOwners':
      return `${titleize(ONLY)} ${FLOW} ${OWNER} (1)`;
    case 'private':
      return `${titleize(POSTED)} ${PRIVATELY} (1)`;
    default:
      return `${ENTIRE_ORGANIZATION} ${viewCount ? `(${viewCount})` : ''}`;
  }
};

export const downloadFeedFile = async (request: FileOperationsRequest) => {
  if (request) {
    const downloadFileResponse = await makeAPICallWithDataReturn(
      GET_FLOW_POST_DOWNLOAD_URL,
      undefined,
      undefined,
      {
        ...request,
      },
    );

    const fileToDownload = downloadFileResponse as DownloadSecureFileResponse;

    if (mobileStore.getState().isMobileApp) {
      postMessageToMobileApp({
        action: AppAction.FileDownload,
        targetEvent: AppTargetEvent.Mobile,
        payload: {
          id: request.fileName,
          linkURL: fileToDownload.url,
        },
      });
    } else {
      const response = await axios.get(fileToDownload.url, {
        responseType: 'blob',
      });

      fileDownload(response.data, request.fileName);
    }
  }
};

export const deleteAttachmentFile = async (request: FileOperationsRequest) => {
  if (request) {
    await makeAPICall(DELETE_ATTACHMENT_FILE, undefined, undefined, {
      ...request,
    });
  }
};

export const getFlowNameWithEmoticon = (
  data:
    | {
        emoticon?: string;
        icon?: EmoticonResponse;
        name?: string;
        label?: string;
      }
    | undefined,
) => {
  let emoticon;
  let text;

  if (data && data.emoticon !== null && typeof data.emoticon === 'string') {
    emoticon = data.emoticon;
  }

  if (data && !data.emoticon && data.icon && typeof data.icon === 'object') {
    emoticon = mapHexCodeToEmoticon(data.icon.value);
  }

  if (data && data.name) {
    text = data.name;
  } else if (data && data.label) {
    text = data.label;
  }

  return `${emoticon} ${text}`;
};

export const canShowParticipationList = (
  data: FlowItemResponse | LeftDrawerCollapsibleNavItemProps | undefined,
) => Boolean(data?.isFlowViewer || data?.isFlowOwner);

export const getParticipantBlockForFlowParticipant = (
  participant: IMemberDTO,
  showLockIcon: boolean,
) => {
  const blockData: Participant = {
    image: participant?.image || '',
    memberId: participant.memberID,
    lastName: participant.lastName,
    firstName: participant.firstName,
    showLockIcon,
    memberState: participant.memberState,
  };

  return blockData;
};

export const getFormattedNewMembers = (emails: string[] | undefined) => {
  if (!emails) {
    return [];
  }
  return emails.map((email) => ({
    image: '',
    memberId: email,
    firstName: email,
    lastName: '',
    showLockIcon: false,
    memberState: MemberState.NEW,
  }));
};

export const pushNewOption = (
  options: OptionItemProps[],
  count: number,
  isOtherOption: boolean,
) => {
  if (isOtherOption) {
    const newOption: OptionItemProps = {
      defaultLabel: '',
      label: '',
      value: OTHER.toLowerCase(),
      autoFocus: true,
    };
    return [...options, newOption];
  }
  const isOtherOptionPresent =
    options.findIndex((option) => option?.value === OTHER.toLowerCase()) > -1;
  const newOption: OptionItemProps = {
    label: '',
    defaultLabel: `${capitalizeFirstLetter(OPTION)} ${count + 1}`,
    value: uuid.v4(),
    autoFocus: count > 0,
  };
  if (isOtherOptionPresent) {
    options.splice(options.length - 1, 0, newOption);
    return options;
  }
  return [...options, newOption];
};

export const generateMemberAutocompleteOptions = (
  members: IMemberDTO[],
  currentUserId: string,
  removeCurrentUser = true,
) => {
  return members.reduce<AutocompleteDropdownItem<string, IMemberDTO>[]>(
    (arr, member) => {
      const { memberID, image, memberState } = member;
      const name = getFullName(member);
      const isCurrentUser = memberID === currentUserId;
      if (!removeCurrentUser || !isCurrentUser) {
        arr.push({
          id: memberID,
          title: name,
          avatar: {
            img: image || '',
            name,
            userId: memberID,
            icon: memberState === MemberState.PENDING ? 'pending-person' : '',
            iconColor: ThemeV2.palette.white,
          },
          item: member,
        });
      }
      return arr;
    },
    [],
  );
};

export const getMinimumRange = (
  min: number,
  max: number,
  available: number,
) => {
  if (min >= available) {
    if (min > max) return max - 1 > 0 ? max - 1 : 1;
    return available - 1 > 0 ? available - 1 : 1;
  } else if (min < 1) return 1;
  else return min;
};

export const getMaximumRange = (
  min: number,
  max: number,
  available: number,
) => {
  if (max >= available) return available > 0 ? available : 1;
  else if (max > 0 && max < min)
    return min + 1 <= available ? min + 1 : available;
  else if (max < 1) return 1;
  else return max;
};

export const getUpdatedBlockSettingsOnOptionsUpdate = (
  optionsLength: number,
  selectedOption: OptionsSelectObject,
) => {
  let modifiedOptions: OptionsSelectObject | null;
  switch (selectedOption.type) {
    case 'EXACT_NUMBER': {
      if (selectedOption.exactOptions > optionsLength && optionsLength > 0) {
        modifiedOptions = {
          ...selectedOption,
          exactOptions: optionsLength,
        };
      } else {
        modifiedOptions = null;
      }
      break;
    }

    case 'RANGE': {
      if (selectedOption.maxOptions > optionsLength) {
        modifiedOptions = {
          ...selectedOption,
          minOptions: getMinimumRange(
            selectedOption.minOptions,
            selectedOption.maxOptions,
            optionsLength,
          ),
          maxOptions: getMaximumRange(
            selectedOption.minOptions,
            selectedOption.maxOptions,
            optionsLength,
          ),
        };
      } else {
        modifiedOptions = null;
      }
      break;
    }

    case 'UNLIMITED_OPTIONS':
      modifiedOptions = null;
      break;

    default:
      modifiedOptions = null;
      break;
  }
  return modifiedOptions;
};

type GetIsScheduledFlowEndedParams = {
  isFlowOwner?: boolean;
  kind?: string;
  hasActiveOccurrence?: boolean;
  schedule?: Schedule;
};

export const getIsScheduledFlowEnded = ({
  isFlowOwner,
  kind,
  hasActiveOccurrence,
  schedule,
}: GetIsScheduledFlowEndedParams) => {
  if (isFlowOwner && kind === 'SCHEDULED' && !hasActiveOccurrence) {
    return !schedule || (schedule && schedule.status === 'COMPLETED');
  }
  return false;
};

export const getParticipants = (
  participants: IMemberDTO[] | undefined,
  newMembers?: string[],
) => {
  if (!participants || (!participants.length && !newMembers?.length)) {
    return [];
  }

  let formattedNewMembers: Participant[] = [];
  const formattedParticipants = participants.map(
    (participant) =>
      ({
        image: participant.image || '',
        memberId: participant.memberID,
        lastName: participant.lastName,
        firstName: participant.firstName,
        memberState: participant.memberState,
      } as Participant),
  );

  if (newMembers) {
    formattedNewMembers = newMembers.map((email) => {
      return {
        image: '',
        memberId: email,
        firstName: email,
        lastName: '',
        memberState: MemberState.NEW,
      };
    });

    return [...formattedNewMembers, ...formattedParticipants];
  }

  return formattedParticipants;
};

export const mapParticipationTemplateErrorTypesToTitleMessage = (
  errorTypes: ParticipationTemplateErrorTypes,
  flowData: FlowItemResponse | null,
) => {
  switch (errorTypes) {
    case ParticipationTemplateErrorTypes.FLOW_UPDATED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED:
    case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND:
    case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH:
      return `${mapHexCodeToEmoticon(flowData?.icon.value || '')} ${
        flowData?.name
      }`;
    case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
    case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
    case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
    case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
    case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
    default: {
      return CANT_FIND_FLOW;
    }
  }
};

export const mapParticipationTemplateErrorTypesToDescriptionMessage = (
  errorTypes: ParticipationTemplateErrorTypes,
  flowData: FlowItemResponse | null,
) => {
  switch (errorTypes) {
    case ParticipationTemplateErrorTypes.FLOW_UPDATED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED:
    case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND:
    case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED:
    case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH:
      return flowData?.description || NO_DESCRIPTION;
    case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
    case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
    case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
    case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
    case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
    default:
      return HEAD_TO_MAIN_FEED;
  }
};

export const getAnswerFlowRoute = (
  flowId: string,
  occurrenceId: string | undefined,
  pathname: string,
  fromErrorDraft = false,
) => {
  pathname =
    pathname ||
    (new URLSearchParams(window.location.search).get('redirectUrl') as string);
  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;
  const appendErrorDraftParam = fromErrorDraft ? '&fromErrorDraft=true' : '';
  let answerFlowRoute = `/flows/${flowId}/answer?redirectUrl=${pathname}${appendErrorDraftParam}`;
  if (isEmbeddedInMainApp) {
    answerFlowRoute = `${answerFlowRoute}&source=embedded`;
  }
  if (occurrenceId) {
    answerFlowRoute = `${answerFlowRoute}&occurrenceId=${occurrenceId}${appendErrorDraftParam}`;
  }
  return answerFlowRoute;
};

export const formatFlowToTemplate = (
  flowData: GetFlowDetailsResponse,
): TemplateDetails => {
  const data = flowData.data;
  return {
    category: 'COMMUNITY',
    title: data.name,
    description: {
      text: data.description,
      icon: data.icon,
    },
    blocks: data?.action?.blocks || [],
    action: data?.action?.kind || '',
    endTimeInMinutes: data.endTimeInMinutes || 0,
    kind: data.kind,
    schedule: data.schedule,
    shortcut: data.shortcut,
    allowPrivateResponse: data.allowPrivateResponse,
  };
};

export const generateInviteNewMemberOption = (emailToInvite: string) => {
  return [
    {
      id: uuid.v4(),
      title: `Invite "${emailToInvite}" upon posting my response`,
      emailToInvite,
      avatar: {
        img: envelopeImage,
      },
    },
  ];
};

export const getPersonSelectorValue = (
  currentValue: AutocompleteDropdownItem<string, unknown>,
  isAdminApprovalRequired: boolean,
  avatarImg: string,
) => {
  const updatedValue = {
    ...currentValue,
    ...{
      title: currentValue.emailToInvite || '',
      avatar: {
        img: avatarImg,
        toolTip: isAdminApprovalRequired
          ? 'This person is not a member of your Assembly, you’ll send an invite request to your admin once you post your response.'
          : 'This person is not a member of your Assembly, they’ll be invited once you post your response.',
      },
    },
  };
  return currentValue.emailToInvite ? updatedValue : currentValue;
};

export const getInviteUserCapabilityFromPersonSelector = ({
  profileInfo,
  isUserManagementTreatmentOn,
}: {
  profileInfo: GetProfileInfoResponse;
  isUserManagementTreatmentOn: boolean;
}) => {
  const canInviteUsers = canInviteMembers(
    profileInfo.member,
    profileInfo.assembly.settings,
    isUserManagementTreatmentOn,
  );
  if (canInviteUsers) {
    return {
      isAdminApprovalRequired: getIsInviteRequiresApproval(
        profileInfo,
        isUserManagementTreatmentOn,
      ),
    };
  }
};

export const mapMemberDetailsToAutoCompleteDropdownData = (
  members: IMemberDTO[] | undefined,
) => {
  return members
    ? members.map((member) => ({
        title: `${member.firstName} ${member.lastName}`,
        id: member.memberID,
        icon: '',
        avatar: {
          img:
            member?.memberState === MemberState.PENDING ||
            member?.memberState === MemberState.NEW ||
            member?.image === null
              ? undefined
              : member?.image,
          name: `${member.firstName} ${member.lastName}`,
          userId: member.memberID,
        },
        key: member.memberID,
        name: `${member.firstName} ${member.lastName}`,
        memberState: member?.memberState,
        ...(member.memberID === 'invite-user-card' && {
          emailToInvite: member?.email,
        }),
        email: member?.email,
        ...((member?.memberState === MemberState.PENDING ||
          member?.memberState === MemberState.NEW) && {
          icon: 'circular-pending-user',
        }),
      }))
    : null;
};

export const getMemberSettingForSimpleRules = ({
  isMemberAParticipant,
  isMemberHavingVisibility,
  isMemberAFlowOwner,
}: {
  isMemberAParticipant: boolean;
  isMemberHavingVisibility: boolean;
  isMemberAFlowOwner: boolean;
}) => {
  if (
    isMemberAParticipant &&
    (isMemberHavingVisibility || isMemberAFlowOwner)
  ) {
    return canPostAndViewPostsSetting;
  } else if (
    isMemberAParticipant &&
    !(isMemberHavingVisibility || isMemberAFlowOwner)
  ) {
    return canOnlyPostSetting;
  } else if (
    (!isMemberAParticipant && isMemberHavingVisibility) ||
    isMemberAFlowOwner
  ) {
    return canOnlyViewPostsSetting;
  }
  return undefined;
};

const mapMemberDataToSimpleRulesData = ({
  member,
  flowOwnerId,
  memberSetting,
  isCollaborator,
}: {
  member: IMemberDTO;
  flowOwnerId: string;
  memberSetting?: {
    id: ShareSheetMemberActions;
    value: string;
  };
  isCollaborator: boolean;
}) => {
  const fullName = `${member.firstName} ${member.lastName}`.trim();
  return {
    title: fullName,
    id: member.memberID,
    avatar: {
      img:
        member?.memberState === MemberState.PENDING ||
        member?.memberState === MemberState.NEW ||
        member?.image === null
          ? undefined
          : member?.image,
      name: fullName,
      userId: member.memberID,
    },
    key: member.memberID,
    name: fullName,
    ...((member?.memberState === MemberState.PENDING ||
      member?.memberState === MemberState.NEW) && {
      icon: 'circular-pending-user',
    }),
    email: member?.email,
    setting: memberSetting,
    isOwner: member.memberID === flowOwnerId,
    isCollaborator,
    memberState: member?.memberState,
    type: SimplifiedShareSheetDataTypes.Member,
  };
};

export const mapMembersFromExclusionCriteria = ({
  members,
  flowOwnerId = '',
  flowCollaborators,
}: {
  members: IMemberDTO[] | undefined;
  flowOwnerId: string;
  flowCollaborators?: FlowCollaborator[] | undefined;
}): SimplifiedShareSheetCardData[] => {
  return members
    ? members.map((member) => {
        const isCollaborator = Boolean(
          flowCollaborators?.some((i) => i.memberID === member.memberID),
        );
        return {
          ...mapMemberDataToSimpleRulesData({
            member,
            flowOwnerId,
            memberSetting: excludedFromFlowSetting,
            isCollaborator,
          }),
        };
      })
    : [];
};

export const mapParticipationAndVisibilityDataToSimpleRules = ({
  participants,
  membersWithVisibility,
  flowOwner,
  flowCollaborators,
}: {
  participants: IMemberDTO[] | undefined;
  membersWithVisibility: IMemberDTO[] | undefined;
  flowOwner: IMemberDTO | undefined;
  flowCollaborators: FlowCollaborator[] | undefined;
}): SimplifiedShareSheetCardData[] => {
  // TODO: Sumedha - write better algorithm to get selected members
  const allMembers = [
    ...(participants || []),
    ...(membersWithVisibility || []),
    ...(flowOwner ? [flowOwner] : []),
    ...serializeCollaboratorToMemberDTO(flowCollaborators),
  ];

  const uniqueMembers = uniqBy(allMembers, 'memberID');
  return uniqueMembers.map((member) => {
    const isMemberAParticipant = !isEmpty(
      participants?.find((i) => member.memberID === i.memberID),
    );
    const isMemberHavingVisibility = !isEmpty(
      membersWithVisibility?.find((i) => member.memberID === i.memberID),
    );
    const isCollaborator = Boolean(
      flowCollaborators?.some((i) => i.memberID === member.memberID),
    );
    const memberSetting = getMemberSettingForSimpleRules({
      isMemberAParticipant,
      isMemberHavingVisibility,
      isMemberAFlowOwner:
        member.memberID === flowOwner?.memberID || isCollaborator,
    });
    return mapMemberDataToSimpleRulesData({
      member,
      flowOwnerId: flowOwner?.memberID || '',
      memberSetting,
      isCollaborator,
    });
  });
};

export const mapParticipationAndVisibilityDataToPreviewRules = ({
  participants,
  membersWithVisibility,
  flowOwner,
  displayType,
  flowCollaborators,
}: {
  participants: IMemberDTO[] | undefined;
  membersWithVisibility: IMemberDTO[] | undefined;
  flowOwner: IMemberDTO | undefined;
  displayType: string;
  flowCollaborators: FlowCollaborator[] | undefined;
}): SimplifiedShareSheetCardData[] => {
  const allMembers = [
    ...(participants?.length &&
    (displayType === ShareSheetPermissions.allPermissions ||
      displayType === ShareSheetPermissions.canPost)
      ? participants
      : []),
    ...(membersWithVisibility?.length &&
    (displayType === ShareSheetPermissions.allPermissions ||
      displayType === ShareSheetPermissions.canView)
      ? membersWithVisibility
      : []),
  ];
  const uniqueMembers = uniqBy(allMembers, 'memberID');
  return uniqueMembers.map((member) => {
    const isMemberAParticipant = !isEmpty(
      participants?.find((i) => member.memberID === i.memberID),
    );
    const isMemberHavingVisibility = !isEmpty(
      membersWithVisibility?.find((i) => member.memberID === i.memberID),
    );
    const isCollaborator = Boolean(
      flowCollaborators?.some((i) => i.memberID === member.memberID),
    );
    const memberSetting = getMemberSettingForSimpleRules({
      isMemberAParticipant,
      isMemberHavingVisibility,
      isMemberAFlowOwner: member.memberID === flowOwner?.memberID,
    });
    return mapMemberDataToSimpleRulesData({
      member,
      flowOwnerId: flowOwner?.memberID || '',
      memberSetting,
      isCollaborator,
    });
  });
};

export const getRequestRoleFromPermission = (
  permission: FlowRequestPermission,
) => {
  const requestRoleFromPermissions = {
    VIEWER: 'viewer',
    PARTICIPANT: 'participant',
    PARTICIPANT_AND_VIEWER: 'participantViewer',
  };
  return (requestRoleFromPermissions[permission] ||
    'viewer') as ShareRequestRole;
};
