import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../theme';
import Image from '../../../atomic/atoms/Image';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import Body from '../../../atomic/atoms/Body';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import { Flex } from '../../../Utils/styles/display';
import SpinningLoader from '../../../atomic/atoms/SpinnerLoader';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import Clickable from '../../../atomic/atoms/Clickable_V2';
import { device } from '../../../constants/layout';

export const StyledActionBarWrapper = styled.div`
  margin-bottom: 20px;
`;

export const FlowFeedPostWrapper = styled.div`
  background-color: white;
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 4px;
  margin: 0 auto 16px;
`;

export const StyledPostLoading = styled(Flex)`
  background-color: ${ThemeV2.palette.geekBlue2};
  width: 100%;
  margin: 0 auto 4px;
  border: 1px solid ${ThemeV2.palette.geekBlue6};
  border-radius: 4px;
`;

export const ErrorDraftBanner = styled(Flex)`
  background-color: ${ThemeV2.palette.dustRed1};
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid ${ThemeV2.palette.dustRed6};
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
`;

export const EditIcon = styled(SVGIcon)`
  margin-right: 8px;
`;

export const StyledPostLoadingText = styled(Body)`
  margin: 12px 16px;
`;

export const StyledContentLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
`;

export const StyledModalBodyContent = styled(Body)`
  b {
    font-weight: 500;
  }
`;

export const StyledImage = styled(Image)`
  margin: 16px auto 16px auto;
`;

export const StyledDropdown = styled(Dropdown)`
  div:first-child {
    z-index: 0;
    @media ${device.mobile} {
      z-index: 1;
    }
  }
`;

export const StyledSpinningLoader = styled(SpinningLoader)`
  margin-right: 20px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-bottom: 24px;
`;

export const DeleteIcon = styled(SVGIcon)`
  padding-left: 12px;
  border-left: 1px solid ${ThemeV2.palette.gray6};
`;

export const StyledClickable = styled(Clickable)`
  font-size: small;
  text-decoration: underline;
`;
