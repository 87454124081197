import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TextFieldProps,
  TextFieldType,
} from '../../../atomic/molecules/TextField_V2/interface';
import useStringSearch from '../../../hooks/useSearchString';
import {
  AXOMO_SWAG_REWARDS_SEARCH_PLACEHOLDER,
  AXOMO_SWAG_REWARDS_SORT_PLACEHOLDER,
  AXOMO_SWAG_REWARDS_TYPE_PLACEHOLDER,
  SWAGS_TURNED_ON,
  TURNING_REWARDS_ON,
} from '../../../languages/en/rewards';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import {
  useAxomoRewardsCategoryQuery,
  useAxomoRewardsFetchQuery,
  useGetGeneralRewardsInfo,
} from '../../../queries/Rewards';
import { SortByQueryParams } from '../../../Utils/data/rewards';
import ThemeV2 from '../../../theme';
import { sortbyItems } from './data';
import useLayoutStore from '../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';
import { useHistory } from 'react-router-dom';
import {
  useFetchRewardsSwagsInfoQuery,
  useToggleRewardsVisibility,
} from '../../../queries/Admin/rewards/Swags';
import {
  dismissAllToasts,
  showInfoMessage,
  showSuccessMessage,
} from '../../../Utils/toast';
import { useQueryClient } from '@tanstack/react-query';
import { GET_AXOMO_INFO } from '../../../constants/endpoints';
import { AXOMO_EVENTS } from '../../../Utils/analytics/constants';
import { trackAxomoShowEvent } from '../../../Utils/analytics/axomo';

export const useSwagRewardsController = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const history = useHistory();
  // State
  const [sortParams, setSortParams] = useState({
    sort: SortByQueryParams.NEWEST,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [swagType, setSwagType] = useState('');
  const [emptyRewardsState, setEmptyRewardsState] = useState(false);

  // Queries
  const { data: connectionStatus } = useFetchRewardsSwagsInfoQuery();
  const storeUID = useMemo(() => {
    if (connectionStatus && 'uid' in connectionStatus) {
      return connectionStatus.uid;
    }
  }, [connectionStatus]);

  const {
    data: rewardsCategory,
    isError: isAxomoRewardsCategoryError,
    isFetching: isAxomoRewardsCategoryFetching,
    isInitialLoading: isAxomoRewardsCategoryLoading,
  } = useAxomoRewardsCategoryQuery();

  const {
    data: profileData,
    isError: isProfileDataError,
    isInitialLoading: isProfileDataLoading,
  } = useProfileInfoFetchQuery();

  const { data: generalRewardsInfo, refetch } = useGetGeneralRewardsInfo(true);
  const isSwagsTabTurnedOff = useMemo(() => {
    if (generalRewardsInfo) {
      return generalRewardsInfo.data.swags.tabVisibility === 'OFF';
    }
  }, [generalRewardsInfo]);

  const {
    data: rewardsCatalogue,
    isError: isRewardCatalogueError,
    isFetching: isAxomoRewardsFetching,
    isInitialLoading: isAxomoRewardsLoading,
  } = useAxomoRewardsFetchQuery({ category: swagType }, !isSwagsTabTurnedOff);

  const { mutate: toggleRewardVisibilityMutate } = useToggleRewardsVisibility();

  // Mixpanel
  useEffect(() => {
    trackAxomoShowEvent({
      action: AXOMO_EVENTS.SHOW_SWAG,
      view: 'Rewards',
    });
  }, []);

  // Computed Values
  const isError =
    isRewardCatalogueError || isProfileDataError || isAxomoRewardsCategoryError;

  useEffect(() => {
    if (rewardsCatalogue) {
      if (rewardsCatalogue.length === 0) {
        setEmptyRewardsState(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading =
    isAxomoRewardsCategoryFetching ||
    isProfileDataLoading ||
    isAxomoRewardsCategoryLoading;

  const { models: searchModels, operations: searchOperations } =
    useStringSearch({ data: rewardsCatalogue || [], keys: ['name'] });

  const { searchResult } = searchModels;
  const queryClient = useQueryClient();

  // Handlers
  useEffect(() => {
    searchOperations.search(searchValue);
  }, [searchValue, searchOperations]);

  const onSortChange = useCallback((value = SortByQueryParams.NEWEST) => {
    setSortParams({ sort: value });
  }, []);

  const resetCurrentPage = useCallback(() => {
    setPageNumber(1);
  }, []);

  const onItemsPerPageValueChange = useCallback((value) => {
    setItemsPerPage(value);
  }, []);

  const filterItemValues = useMemo(() => {
    if (!rewardsCategory) return;

    return rewardsCategory.map((category: any) => {
      return {
        value: category.value,
        id: category.id,
        isSelectable: category.isSelectable,
        data: category.data,
      };
    });
  }, [rewardsCategory]);

  const handleSearchSwags = useCallback(
    (value: React.SetStateAction<string>) => {
      resetCurrentPage();
      setSearchValue(value);
      searchOperations.search(value);
    },
    [resetCurrentPage, searchOperations],
  );

  const handleFilterSwags = useCallback(
    (value: string, id?: number | string) => {
      resetCurrentPage();
      setFilterValue(value);
      if (id) {
        setSwagType(id.toString());
      } else {
        setSwagType('');
      }
    },
    [resetCurrentPage],
  );

  const handleSortSwags = useCallback(
    (value: React.SetStateAction<string>, id?: string | number) => {
      if (value !== sortValue) {
        resetCurrentPage();
        setSortValue(value);
        onSortChange(id);
      }
    },
    [onSortChange, resetCurrentPage, sortValue],
  );

  const { isLeftAsideOpen, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  const onHandlePaginationChange = useCallback((num) => {
    setPageNumber(num);
  }, []);

  const upgradeRequired = useMemo(() => {
    if (
      !profileData ||
      (rewardsCatalogue &&
        'message' in rewardsCatalogue &&
        rewardsCatalogue.message === 'REQUIRE_PAID_USER')
    ) {
      return true;
    }
  }, [profileData, rewardsCatalogue]);

  const handleGotoAdminSwagSettings = useCallback(() => {
    if (isEmbeddedInMainApp) {
      window.parent.postMessage(
        {
          type: 'NAVIGATE_TO_ADMIN',
          payload: '/rewards/swag',
        },
        '*',
      );
    } else {
      history.push(`/admin/rewards/swag`);
    }
  }, [history, isEmbeddedInMainApp]);

  const handleSwagsTabAlertTurnOn = useCallback(() => {
    showInfoMessage(TURNING_REWARDS_ON);
    toggleRewardVisibilityMutate(
      {
        payload: { storeUID },
        status: 'ON',
      },
      {
        onSuccess: () => {
          dismissAllToasts();
          showSuccessMessage(SWAGS_TURNED_ON);
          queryClient.invalidateQueries([GET_AXOMO_INFO]);
          refetch();
        },
      },
    );
  }, [queryClient, refetch, storeUID, toggleRewardVisibilityMutate]);

  const searchProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: AXOMO_SWAG_REWARDS_SEARCH_PLACEHOLDER,
      type: TextFieldType.Text,
      value: searchValue,
      onChange: handleSearchSwags,
      icon: 'search',
      disabled: isSwagsTabTurnedOff,
    };
  }, [handleSearchSwags, isSwagsTabTurnedOff, searchValue]);

  const filterProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: AXOMO_SWAG_REWARDS_TYPE_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'sort',
      value: filterValue,
      onChange: handleFilterSwags,
      expandableDropdownItems: filterItemValues,
      isEditable: true,
      inputIconColor: isSwagsTabTurnedOff
        ? ThemeV2.palette.gray6
        : ThemeV2.palette.gray9,
      hasDropdownFilter: true,
      disabled: isSwagsTabTurnedOff,
    };
  }, [filterValue, handleFilterSwags, filterItemValues, isSwagsTabTurnedOff]);

  const sortProps: TextFieldProps = useMemo(() => {
    return {
      placeholder: AXOMO_SWAG_REWARDS_SORT_PLACEHOLDER,
      type: TextFieldType.Text,
      icon: 'filter',
      value: sortValue,
      onChange: handleSortSwags,
      dropdownItems: sortbyItems,
      isEditable: false,
      inputIconColor: isSwagsTabTurnedOff
        ? ThemeV2.palette.gray6
        : ThemeV2.palette.gray9,
      disabled: isSwagsTabTurnedOff,
    };
  }, [sortValue, handleSortSwags, isSwagsTabTurnedOff]);

  const rewardsToDisplay: any[] = searchResult;
  const currentSwags = useMemo(() => {
    const modifiedRewards = [...rewardsToDisplay];
    switch (sortParams.sort) {
      case SortByQueryParams.ALPHABETICALLY:
        modifiedRewards.sort((a, b) => (a.name > b.name ? 1 : -1));
        break;
      case SortByQueryParams.POINTS_TO_REDEEM_HIGH_TO_LOW:
        modifiedRewards.sort((a, b) => (a.points[0] < b.points[0] ? 1 : -1));
        break;
      case SortByQueryParams.POINTS_TO_REDEEM_LOW_TO_HIGH:
        modifiedRewards.sort((a, b) => (a.points[0] > b.points[0] ? 1 : -1));
        break;
    }

    if (modifiedRewards && modifiedRewards.length > 0) {
      return modifiedRewards.slice(
        (pageNumber - 1) * itemsPerPage,
        (pageNumber - 1) * itemsPerPage + itemsPerPage,
      );
    }

    return [];
  }, [itemsPerPage, pageNumber, rewardsToDisplay, sortParams.sort]);

  return {
    isError,
    isLoading,
    upgradeRequired,
    rewardsCatalogue,
    searchProps,
    filterProps,
    sortProps,
    isLeftAsideOpen,
    isRightAsideOpen,
    profileData,
    rewardsToDisplay,
    itemsPerPage,
    onItemsPerPageValueChange,
    onHandlePaginationChange,
    pageNumber,
    sortParams,
    currentSwags,
    handleGotoAdminSwagSettings,
    isCategoryLoading: isAxomoRewardsFetching || isAxomoRewardsLoading,
    isSwagsTabTurnedOff,
    handleSwagsTabAlertTurnOn,
    emptyRewardsState,
  };
};
