import { useCallback, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { IMemberDTO } from '../../../../interfaces/member';
import { useMembersFetchInfiniteScrollQuery } from '../../../../queries/Members';
import { useMembersSearch } from '../../../../hooks/useMembersSearch';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useLayoutStore from '../../../../stores/layoutStore';

import useGetPageName from '../../../../hooks/useGetPageName';
import { RIGHT_DRAWER_EVENTS } from '../../../../Utils/analytics/constants';
import { canInviteMembers } from '../../../../Utils/permissions';
import { trackRightDrawerEvent } from '../../../../Utils/analytics';
import useRepliesSidebar from '../../../../hooks/useRepliesSidebar';
import useToggleInviteModal from '../../../../hooks/useToggleInviteModal';

const usePeopleListController = ({
  limit,
  searchLimit,
}: {
  limit?: number;
  searchLimit?: number;
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const {
    toggleLeftAsideOpen,
    setMemberAndToggleRightAsideOpen,
    setShowChats,
  } = useLayoutStore(
    (state) => ({
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
      setShowChats: state.setShowChats,
    }),
    shallow,
  );

  const { page } = useGetPageName();

  const widthCheck = window.matchMedia('(min-width: 769px)');

  const { reset } = useRepliesSidebar();

  const handleEntryClick = useCallback(
    (firstName: string, memberId: string) => {
      reset();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({ firstName, memberId });
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_CLICKED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
      if (!widthCheck.matches) {
        toggleLeftAsideOpen();
      }
    },
    [
      page,
      reset,
      setShowChats,
      widthCheck.matches,
      toggleLeftAsideOpen,
      setMemberAndToggleRightAsideOpen,
    ],
  );

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isError,
    isLoading: isInfiniteMembersLoading,
  } = useMembersFetchInfiniteScrollQuery({ limit: limit });
  const { data: profileData } = useProfileInfoFetchQuery();

  const hasInvitePermissions = canInviteMembers(
    profileData?.member,
    profileData?.assembly.settings,
    true,
  );

  const infiniteMembers = useMemo(() => {
    let allMembers: IMemberDTO[] = [];

    if (data) {
      data.pages.forEach((dataPage) => {
        const currentPageMembers = dataPage.members;
        allMembers = allMembers.concat(currentPageMembers);
      });
    }

    return allMembers;
  }, [data]);

  const {
    models: {
      isFetching: isMembersSearchFetching,
      isLoading: isMembersSearchLoading,
      searchedMembers,
      value: membersSearchValue,
    },
    operations: { onChange },
  } = useMembersSearch(false, undefined, undefined, searchLimit);
  const { isModalOpen, toggleInviteModal } = useToggleInviteModal();

  const onMembersSearchChange = useCallback(
    (value: string) => {
      onChange(value);
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.MEMBER_SEARCHED, {
        feedViewed: page,
        drawerTabViewed: 'people',
      });
    },
    [onChange, page],
  );

  const toggleExpandNavigation = () => {
    setIsOpen(!isOpen);
  };
  return {
    disableParticipantsMenuList: false,
    hasMoreMembersToFetch: hasNextPage,
    isMembersSearchLoading:
      isMembersSearchLoading ||
      isMembersSearchFetching ||
      isInfiniteMembersLoading,
    canInviteMembers: hasInvitePermissions,
    isOpen,
    isModalOpen,
    infiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    searchedMembers,
    totalNumberOfAssemblyMembers: data?.pages[0].total,
    fetchMoreInfiniteMembers: fetchNextPage,
    handleEntryClick,
    toggleExpandNavigation,
    toggleInviteModal,
    isMembersFetchingError: isError,
  };
};

export default usePeopleListController;
