/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AutocompleteRenderOptionState, PaperProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '../../molecules/NewTextfield';
import DropdownItem from '../../molecules/DropdownListItem_V2';

import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import CircularProgress from '../../molecules/CircularProgress';
import { VariantTypes } from '../../molecules/CircularProgress/interfaces';
import {
  AutoCompleteCoreProps,
  AutocompleteDropdownItem,
  MultiSelectAutoCompleteProps,
  PossibleAutoCompleteValues,
  SingleSelectAutoCompleteProps,
} from './interfaces';
import OptionLoader from './OptionLoader';
import {
  StyledChip,
  StyledDynamicPaper,
  StyledLoader,
  StyledPaper,
  TooltipForChips,
  useStyles,
  TooltipContent,
} from './styles';

const LOADING_SKELETON = 'loadingSkeleton';

function AutoCompleteCore<T extends string | number, U>({
  autoSelect = false,
  autoFocus = false,
  groupByCategory,
  label,
  placeholder,
  options,
  onChange,
  value,
  className,
  multiple = true,
  disableCloseOnSelect,
  limitTags,
  loading,
  textboxSize = 'medium',
  textboxValue,
  onTextboxValueChange,
  filterOptionsFunction,
  onBlur,
  onFocus,
  inputBaseRightPadding,
  ListboxProps,
  checkIfDisabled,
  removeElevation,
  disabled = false,
  hasError = false,
  multiline = false,
  helperText,
  showClearIcon = true,
  expandableDropdown,
  getOptionLabel = (option: AutocompleteDropdownItem<T, U>) =>
    option.title || '',
  dataTestId,
  validation,
  name,
  children,
  inputRef,
  renderCustomDropdownCard,
  overrideTextBoxValue = false,
  popupIconSize = '16px',
  disableClearable = false,
  noOptionsText,
  thresholdToShowTooltipForChips,
}: AutoCompleteCoreProps<T, U>) {
  const [isOpen, setIsOpen] = React.useState(false);
  const classes = useStyles({ inputBaseRightPadding });
  const [inputValue, setInputValue] = useState('');
  const onInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onTextboxValueChange) {
        onTextboxValueChange(e.target.value);
      } else {
        setInputValue(e.target.value);
      }
    },
    [onTextboxValueChange],
  );
  const onTagDelete = useCallback(
    (id: T) => () => {
      if (value) {
        // Manually assigning types as this function is only called when multiple
        // items can be selected and when value will not be empty.
        const currentValue = value as AutocompleteDropdownItem<T, U>[];
        onChange(
          undefined,
          currentValue.filter((item) => item.id !== id),
        );
      }
    },
    [onChange, value],
  );

  const renderInput = useCallback(
    (props) => (
      <TextField
        autoFocus={autoFocus}
        label={label}
        placeholder={placeholder}
        value={textboxValue || inputValue}
        onChange={onInputValueChange}
        inputProps={{
          ...props.inputProps,
          ...(overrideTextBoxValue
            ? {
                value: textboxValue,
              }
            : {}),
        }}
        InputLabelPropsObj={props.InputLabelProps}
        multiline={multiline}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          ...props.InputProps,
          ...(overrideTextBoxValue
            ? {
                value: textboxValue,
              }
            : {}),
          endAdornment: (
            <>
              {loading ? (
                <StyledLoader className="autocomplete-loader">
                  <CircularProgress
                    variant={VariantTypes.INDETERMINATE}
                    size="16px"
                  />
                </StyledLoader>
              ) : null}
              {props.InputProps.endAdornment}
            </>
          ),
        }}
        size={textboxSize}
        removeElevation={removeElevation}
        disabled={disabled}
        hasError={hasError}
        helperText={helperText}
        name={name}
        inputRef={inputRef}
      />
    ),
    [
      autoFocus,
      label,
      placeholder,
      textboxValue,
      inputValue,
      onInputValueChange,
      multiline,
      overrideTextBoxValue,
      loading,
      textboxSize,
      removeElevation,
      disabled,
      hasError,
      helperText,
      name,
      inputRef,
    ],
  );
  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteDropdownItem<T, U>,
    state: AutocompleteRenderOptionState,
  ) => {
    if (loading && option.id === LOADING_SKELETON) {
      return (
        <li {...props} key={option.id}>
          <OptionLoader />
        </li>
      );
    }

    if (renderCustomDropdownCard) {
      return (
        <li {...props} key={option.id}>
          {renderCustomDropdownCard(option, state)}
        </li>
      );
    }

    return (
      <li {...props} key={option.id}>
        <DropdownItem
          id={option.id}
          value={option.title}
          isSelected={state.selected}
          prefixIcon={option.icon}
          avatar={option.avatar}
          disabled={option.disabled}
          disableHoverActions
        />
      </li>
    );
  };

  const getOptionDisabled = (option: AutocompleteDropdownItem<T, U>) =>
    checkIfDisabled ? checkIfDisabled(option) : Boolean(option.disabled);

  const renderTags = (tags: AutocompleteDropdownItem<T, U>[]) =>
    tags.map((tag) =>
      thresholdToShowTooltipForChips &&
      tag.title.length > thresholdToShowTooltipForChips ? (
        <TooltipForChips
          key={`tag-${tag.id}`}
          position="top-start"
          toolTipComponent={
            <StyledChip
              icon={tag.icon}
              avatar={tag.avatar}
              label={tag.title}
              onDelete={onTagDelete(tag.id)}
              disabled={disabled}
              validation={validation}
            />
          }
          positionStrategy="fixed"
        >
          <TooltipContent>{tag.title}</TooltipContent>
        </TooltipForChips>
      ) : (
        <StyledChip
          icon={tag.icon}
          avatar={tag.avatar}
          label={tag.title}
          key={`tag-${tag.id}`}
          onDelete={onTagDelete(tag.id)}
          disabled={disabled}
          validation={validation}
        />
      ),
    );

  const generateInfiniteOptions = () => {
    if (loading) {
      const loadingSkeletonOption: AutocompleteDropdownItem<string> = {
        disabled: true,
        id: LOADING_SKELETON,
        title: LOADING_SKELETON,
      };
      return [...options, loadingSkeletonOption];
    }
    return options;
  };

  const getOptionSelected = (
    option: AutocompleteDropdownItem<T, U>,
    optionToTestAgainst: AutocompleteDropdownItem<T, U>,
  ) => option.id === optionToTestAgainst.id;

  const groupBy = useMemo(() => {
    if (groupByCategory) {
      return (option: AutocompleteDropdownItem<T, U>) => option.category || '';
    }
    return undefined;
  }, [groupByCategory]);

  const paperComponent = useMemo(() => {
    if (children) {
      // eslint-disable-next-line react/display-name
      return (props: PaperProps) => {
        return (
          <>
            <StyledPaper {...props} $paddingBottom={true} />
            {children}
          </>
        );
      };
    }

    return expandableDropdown ? StyledDynamicPaper : StyledPaper;
  }, [expandableDropdown]);

  const handleOnOpen = () => {
    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  return (
    <Autocomplete
      open={isOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      fullWidth
      multiple={multiple}
      value={value}
      onChange={onChange}
      id={`combo-box-${Date.now()}`}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /* @ts-ignore */
      options={generateInfiniteOptions()}
      loading={loading}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      filterOptions={filterOptionsFunction}
      clearIcon={
        showClearIcon && (
          <SVGIcon
            size="16px"
            icon="close-rounded"
            color={ThemeV2.palette.gray9}
          />
        )
      }
      groupBy={groupBy}
      renderInput={renderInput}
      PaperComponent={paperComponent}
      renderOption={renderOption}
      getOptionDisabled={getOptionDisabled}
      renderTags={renderTags}
      classes={classes}
      className={className}
      popupIcon={
        <SVGIcon
          size={popupIconSize}
          icon="caret-rounded"
          color={disabled ? ThemeV2.palette.gray6 : ThemeV2.palette.gray9}
        />
      }
      disableCloseOnSelect={disableCloseOnSelect}
      limitTags={limitTags}
      autoHighlight
      autoSelect={autoSelect}
      onBlur={onBlur}
      onFocus={onFocus}
      ListboxProps={ListboxProps}
      disabled={disabled}
      data-testid={dataTestId}
      validation={validation}
      disableClearable={disableClearable}
      noOptionsText={noOptionsText}
    />
  );
}

export function SingleSelectAutoComplete<T extends string | number, U>(
  props: SingleSelectAutoCompleteProps<T, U>,
) {
  const {
    value,
    onChange,
    disabled,
    validation,
    children,
    inputRef,
    noOptionsText,
  } = props;
  const handleOnChange = useCallback(
    (event: unknown, val: PossibleAutoCompleteValues<T, U>) => {
      onChange(val as AutocompleteDropdownItem<T, U> | null);
    },
    [onChange],
  );
  const convertedValue = useMemo(() => {
    return value as PossibleAutoCompleteValues<T, U>;
  }, [value]);
  return (
    <AutoCompleteCore
      {...props}
      value={convertedValue}
      onChange={handleOnChange}
      multiple={false}
      disabled={disabled}
      validation={validation}
      inputRef={inputRef}
      noOptionsText={noOptionsText}
    >
      {children}
    </AutoCompleteCore>
  );
}

export function MultiSelectAutoComplete<T extends string | number, U>(
  props: MultiSelectAutoCompleteProps<T, U>,
) {
  const {
    value,
    onChange,
    ListboxProps,
    optionsMaxLimit,
    validation,
    children,
    noOptionsText,
  } = props;
  const handleOnChange = useCallback(
    (event: unknown, val: PossibleAutoCompleteValues<T, U>) => {
      onChange(val as AutocompleteDropdownItem<T, U>[]);
    },
    [onChange],
  );
  const convertedValue = useMemo(() => {
    return value as PossibleAutoCompleteValues<T, U>;
  }, [value]);

  const checkIfDisabled = (option: AutocompleteDropdownItem<T, U>) =>
    value.length === optionsMaxLimit
      ? !value.some((item) => item.id === option.id)
      : Boolean(option.disabled);

  return (
    <AutoCompleteCore
      {...props}
      value={convertedValue}
      onChange={handleOnChange}
      multiple
      ListboxProps={ListboxProps}
      checkIfDisabled={optionsMaxLimit ? checkIfDisabled : undefined}
      validation={validation}
      noOptionsText={noOptionsText}
    >
      {children}
    </AutoCompleteCore>
  );
}
