import { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import Body from '../../../atomic/atoms/Body';
import Button from '../../../atomic/atoms/Button';
import Heading from '../../../atomic/atoms/Heading';
import Theme from '../../../theme';
import { OnboardingPaths } from '../../../interfaces/Onboarding';
import useElementOnScreen from '../../../hooks/useElementOnScreen';

const reactYoutube = lazy(() => import('react-youtube'));

interface OnBoardingScreenWithBookingProps {
  onClick: (id: string) => void;
}

const StyledHeading = styled(Heading)`
  margin: 30px 0 0;
`;

const StyledButton = styled.div`
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
`;

const StyledBody = styled(Body)`
  color: ${Theme.palette.gray8};
`;

const FloatingButton = styled(Button)`
  position: absolute;
  bottom: -120px;
  left: 0;
  right: 0;
  width: fit-content;
  margin: auto;
`;

const ContinueButton = styled(Button)`
  color: ${Theme.palette.gray8};
  &:before {
    background-color: ${Theme.palette.gray1};
    border: 1px solid ${Theme.palette.gray5};
    border-radius: 8px;
    box-shadow: none;
  }
  &:hover {
    color: ${Theme.palette.gray8} !important;
  }
  &:hover:before {
    background-color: ${Theme.palette.gray1} !important;
    border: 1px solid ${Theme.palette.gray5} !important;
  }
`;

const StyledPlaceholder = styled.div`
  width: 717px;
  height: 400px;
`;

const StyledVideo = styled(reactYoutube)<{ isVideoLoaded: boolean }>`
  display: ${({ isVideoLoaded }) => (isVideoLoaded ? 'block' : 'none')};
`;

const StyledBookingDemoWrapper = styled.div`
  width: 717px;
  height: 650px;
`;

const OnBoardingScreenWithBooking = (
  props: OnBoardingScreenWithBookingProps,
) => {
  const { onClick } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { isVisible: isElementOnScreen } = useElementOnScreen({
    ref: containerRef,
    options: {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    },
  });

  const [showFloatingButton, setShowFloatingButton] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const handleClick = () => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isElementOnScreen) setShowFloatingButton(!isElementOnScreen);
  }, [isElementOnScreen]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Heading variant="h4" marginTop="32px">
          Ready to see what Assembly can do for your team?
        </Heading>
        <StyledBody
          variant="body1"
          padding="8px 0 16px"
          color={Theme.palette.gray8}
        >
          Check out the video below or{' '}
          <StyledButton onClick={handleClick}>book a demo.</StyledButton>
        </StyledBody>
        {!isVideoLoaded && <StyledPlaceholder />}
        <Suspense fallback={<span>Loading youtube video...</span>}>
          <StyledVideo
            videoId="b9tbmXgntCQ"
            opts={{
              height: '400',
              width: '717',
              playerVars: {
                autoplay: 1,
              },
            }}
            onEnd={handleClick}
            onReady={() => setIsVideoLoaded(true)}
            isVideoLoaded={isVideoLoaded}
          />
        </Suspense>

        {showFloatingButton && (
          <FloatingButton
            color="primary"
            icon="arrow-thin-down"
            status="default"
            variant="showMore"
            onClick={handleClick}
            size="small"
          >
            Keep scrolling
          </FloatingButton>
        )}
      </div>
      <StyledHeading variant="h6">
        Want to learn more? Book a demo now:
      </StyledHeading>
      <StyledBookingDemoWrapper ref={containerRef} id="book-demo">
        <Helmet>
          <script
            type="text/javascript"
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        </Helmet>
        <div
          className="meetings-iframe-container"
          data-src="https://meetings.hubspot.com/connor7/assembly-top-of-site-culture-suite?embed=true"
        ></div>
      </StyledBookingDemoWrapper>
      <ContinueButton
        onClick={() => onClick(OnboardingPaths.Organisation)}
        isFullWidth
      >
        Continue exploring on my own
      </ContinueButton>
    </>
  );
};

export default OnBoardingScreenWithBooking;
