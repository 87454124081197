import styled from 'styled-components';

import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import IconContainer from '../../atoms/IconContainer';

export const StyledBody = styled(Body)`
  padding: 4px 8px 4px 0;
`;

export const Card = styled.div<{
  isDeactivatedUser?: boolean;
  disableUserInteraction?: boolean;
}>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
  height: 50px;
  cursor: ${({ isDeactivatedUser, disableUserInteraction }) =>
    isDeactivatedUser || disableUserInteraction ? 'not-allowed' : 'pointer'};
  user-select: none;
  &:hover {
    background-color: ${({ isDeactivatedUser, disableUserInteraction }) =>
      disableUserInteraction || isDeactivatedUser
        ? 'transparent'
        : ThemeV2.palette.geekBlue1};
    ${StyledBody} {
      color: ${({ disableUserInteraction, isDeactivatedUser }) =>
        !disableUserInteraction &&
        !isDeactivatedUser &&
        ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledAvatar = styled(Avatar)`
  margin: 0 8px;
`;

export const StyledIconContainer = styled(IconContainer)`
  margin: 0 8px;
  color: ${({ bgColor }) => bgColor};
`;

export const StyledMainIcon = styled(SVGIcon)`
  color: ${({ color }) => color};
  margin-right: 0;
  svg {
    margin-right: 0;
  }
  path {
    fill: ${({ color }) => color};
  }
`;
