import React, { useMemo } from 'react';

import IconTab from '../../../atomic/organism/IconTab';
import { IconTabItem } from '../../../atomic/organism/IconTab/type';
import TrendsTabPanel from '../../../atomic/molecules/Insights/TrendsTabPanel';
import SummaryTabPanel from '../../../atomic/molecules/Insights/SummaryTabPanel';

import ErrorImage from '../../../img/error.svg';
import useFlowsInsightsController from './useFlowsInsightsController';
import {
  EmptyBlock,
  StyledInfoAlert,
  SisenseFrameWrapper,
  StyledFeedEmptyState,
  FlowsInsightsContainer,
  StyledSection,
} from './styles';

import {
  TRENDS_LABEL,
  SUMMARY_LABEL,
  INSIGHTS_SYNC_MESSAGE_LABEL,
} from '../../../languages/en/flows/insights';
import useLayoutStore from '../../../stores/layoutStore';
import { Flex } from '../../../Utils/styles/display';

const FlowsInsightsController = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { models, operations } = useFlowsInsightsController();

  const {
    hasError,
    isLoading,
    blockWidgets,
    trendsWidgets,
    selectedIndex,
    occurrencesData,
  } = models;

  const { handleOnTabChanged } = operations;

  const tabItems: IconTabItem[] = useMemo(
    () => [
      {
        icon: 'summary',
        label: SUMMARY_LABEL,
        children: (
          <SummaryTabPanel isLoading={isLoading} widgets={blockWidgets} />
        ),
      },
      {
        icon: 'trends',
        label: TRENDS_LABEL,
        children: (
          <TrendsTabPanel
            isLoading={isLoading}
            widgets={trendsWidgets}
            showParticipationRateBlock={
              occurrencesData !== undefined && occurrencesData.data.length > 0
            }
          />
        ),
      },
    ],
    [isLoading, blockWidgets, trendsWidgets],
  );

  return (
    <StyledSection isEmbeddedInMainApp={isEmbeddedInMainApp}>
      {hasError && (
        <>
          <EmptyBlock />
          <StyledFeedEmptyState
            image={ErrorImage}
            title="Your insights for this flow failed to load."
            message="Try refreshing the page."
          />
        </>
      )}

      {!hasError && (
        <>
          <EmptyBlock />
          <Flex margin="0 24px 24px 24px">
            <StyledInfoAlert
              id={+new Date()}
              text={INSIGHTS_SYNC_MESSAGE_LABEL}
              icon="info-outlined"
              alertType="info"
              bodyVariant="body3"
            />
          </Flex>
          <FlowsInsightsContainer>
            <SisenseFrameWrapper>
              <iframe id="SisenseFrame" />
            </SisenseFrameWrapper>
            <IconTab
              items={tabItems}
              onTabChanged={handleOnTabChanged}
              selectedIndex={selectedIndex}
            />
          </FlowsInsightsContainer>
        </>
      )}
    </StyledSection>
  );
};

export default FlowsInsightsController;
