import React from 'react';

import Body from '../../atoms/Body';
import {
  LeftWrapper,
  RightWrapper,
  StyledNameBody,
  StyledAvatar,
  Wrapper,
} from './styles';
import { ME } from '../../../languages/en/singleWords';
import RankingEntryLoader from './Loader';

export interface RankingEntryProps {
  count?: number;
  isLoading: boolean;
  isCurrentUser?: boolean;
  fullName?: string;
  onClick?: () => void;
  profilePicture?: string;
  userId?: string;
}

const RankingEntry = ({
  count,
  fullName,
  isLoading,
  isCurrentUser = false,
  onClick,
  profilePicture = '',
  userId,
}: RankingEntryProps) => {
  const formattedName = isCurrentUser ? `${fullName} (${ME})` : fullName;

  if (isLoading) {
    return <RankingEntryLoader />;
  }

  if ((count || count === 0) && fullName && userId) {
    return (
      <Wrapper
        data-testid="ranking-entry"
        data-qa-id="ranking-entry"
        isCurrentUser={isCurrentUser}
        onClick={onClick}
      >
        <LeftWrapper>
          <StyledAvatar img={profilePicture} name={fullName} userId={userId} />
          <StyledNameBody data-testid="full-name" variant="body3">
            {formattedName}
          </StyledNameBody>
        </LeftWrapper>

        <RightWrapper>
          <Body variant="body3Bold">{count}</Body>
        </RightWrapper>
      </Wrapper>
    );
  }

  return null;
};

const MemoizedRankingEntry = React.memo(RankingEntry);
MemoizedRankingEntry.displayName = 'RankingEntry';
export default MemoizedRankingEntry;
