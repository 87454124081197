import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { QuickCreateEvents } from '../../../Utils/analytics/constants';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import IconButton from '../../../atomic/atoms/IconButton_V2';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import {
  DropdownColor,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { V2_HOME } from '../../../constants/routes';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { CREATE } from '../../../languages/en/singleWords';
import useActionBarStore from '../../../stores/actionBarStore';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';

import useActionBarControllerLogic from '../../ActionBarController/useActionBarControllerLogic';
import { CREATE_FLOW, CREATE_POST } from './constants';
import { getMenuItems } from './data';

const StyledDropdown = styled(Dropdown)`
  margin-left: 20px;
  margin-bottom: 16px;
`;

const StyledIconButton = styled(IconButton)`
  margin: 4px 20px 20px;
`;

const QuickCreateController = ({
  isExpandedMode,
}: {
  isExpandedMode: boolean;
}) => {
  const history = useHistoryWrapper();

  const {
    operations: { handleAddNewFlowClick },
  } = useActionBarControllerLogic();

  const { setIsActionBarOpen } = useActionBarStore();
  const { canUserCreateFlow = false } = useGetUserFlowPermissions();
  const isLoggedInThroughTeamsMobile =
    !isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) &&
    microsoftTeamsStore.getState().isLoggedInThroughMobileTeamsApp;

  const onCreateClick = (isOpen: boolean) => {
    const action = isOpen
      ? QuickCreateEvents.CreateClosed
      : QuickCreateEvents.CreateOpen;
    trackLeftDrawerActionEvent({ action });
  };

  const handleCreatePost = () => {
    history.push(V2_HOME);
    setIsActionBarOpen(true);
    trackLeftDrawerActionEvent({
      action: QuickCreateEvents.CreatePostClicked,
    });
  };

  const handleCreateFlow = () => {
    handleAddNewFlowClick();
    trackLeftDrawerActionEvent({
      action: QuickCreateEvents.CreateFlowClicked,
    });
  };

  const menuItems = [
    {
      id: 'create-menu',
      items: getMenuItems(canUserCreateFlow && !isLoggedInThroughTeamsMobile),
    },
  ];

  const onItemClick = (id: string | number) => {
    switch (id) {
      case CREATE_FLOW:
        return handleCreateFlow();
      case CREATE_POST:
        return handleCreatePost();
      default:
        return;
    }
  };

  return isExpandedMode ? (
    <StyledDropdown
      color={DropdownColor.Primary}
      variant={DropdownVariants.ShowMore}
      value={CREATE}
      menuItems={menuItems}
      onButtonClick={onCreateClick}
      onItemClick={onItemClick}
      caretIconSize="16px"
    />
  ) : (
    <StyledIconButton color="primary" icon="union" rounded />
  );
};

export default QuickCreateController;
