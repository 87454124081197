import { useMemo } from 'react';
import { CreditState } from '../../../interfaces/assembly';
import {
  useCurrentPlanFetchQuery,
  useProfileInfoFetchQuery,
} from '../../../queries/Profile';
import { isFeatureEnabledInCurrentPlan } from '../../../queries/Profile/utils';
import { FEATURE_LIST } from '../../../Utils/account';
import { isUserAdmin } from '../../../Utils/user';

const useRewardsRoutesControllerModel = () => {
  const { data: profileData } = useProfileInfoFetchQuery();

  const { data: currentPlan } = useCurrentPlanFetchQuery();

  const isAdmin = profileData ? isUserAdmin(profileData.member) : false;
  const isChargeable = profileData
    ? profileData.assembly.accounts.rewards.isChargeable
    : false;

  const isCreditStateDeniedByAdmin =
    profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN;

  const isCultureRewardsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.CULTURE_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  const isAxomoSwagsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(FEATURE_LIST.AXOMO_SWAGS, currentPlan);
  }, [currentPlan]);

  const isGiftCardsEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.GIFT_CARD_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  const isCharitiesEnabled = useMemo(() => {
    return isFeatureEnabledInCurrentPlan(
      FEATURE_LIST.CHARITY_REWARDS,
      currentPlan,
    );
  }, [currentPlan]);

  return {
    isAdmin,
    isCreditStateDeniedByAdmin,
    isCultureRewardsEnabled,
    isGiftCardsEnabled,
    isCharitiesEnabled,
    isChargeable,
    isAxomoSwagsEnabled,
  };
};

export default useRewardsRoutesControllerModel;
