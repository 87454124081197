import styled from 'styled-components';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import _ from 'lodash';

import KnowledgeCenterCardGrid from '../../../atomic/organism/KnowledgeCenterCardGrid';
import KnowledgeCenterList from '../../../atomic/organism/KnowledgeCenterList';
import FilePreviewModal from '../../../atomic/molecules/FilePreviewModal';
import useKnowledgeCenterController from './useKnowledgeCenterController';
import KnowledgeCenterLoadingImage from '../../../img/opening-folder.gif';
import KnowledgeCenterErrorImage from '../../../img/error.svg';
import KnowledgeCenterEmptyImage from '../../../img/group-waiting.svg';
import {
  KNOWLEDGE_CENTER_LOADING,
  KNOWLEDGE_CENTER_LOADING_SUB,
  KNOWLEDGE_CENTER_EMPTY,
  KNOWLEDGE_CENTER_EMPTY_SUB,
  KNOWLEDGE_CENTER_ERROR,
  KNOWLEDGE_CENTER_ERROR_SUB,
} from '../../../languages/en/header';
import Body from '../../../atomic/atoms/Body';
import Image from '../../../atomic/atoms/Image';
import KnowledgeCenterActions from '../../../atomic/molecules/KnowledgeCenterActions';
import KnowledgeCenterSort from '../../../atomic/molecules/KnowledgeCenterSort';
import { Flex, FluidContainer } from '../../../Utils/styles/display';
import useLayoutStore from '../../../stores/layoutStore';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { device } from '../../../constants/layout';
import { trackKnowledgeCenterErrorEvent } from '../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../Utils/analytics/constants';
import {
  KNOWLEDGE_CENTER_CARD_VIEW,
  KNOWLEDGE_CENTER_LIST_VIEW,
  V3_POST_PAGE,
} from '../../../constants/routes';
import { FeedItemKind } from '../../../interfaces/Feed';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';

const layoutSelector = (state: LayoutStoreState) => state.isRightAsideOpen;

const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const KnowledgeCenterWrapper = styled.div<{
  isRightAsideOpen?: boolean;
}>`
  height: 100%;
  transition: all 200ms ease-in-out;
  @media ${device.tabletOrDesktop} {
    margin-right: ${({ isRightAsideOpen }) =>
      isRightAsideOpen ? '0px' : '24px'};
  }
`;

const KnowledgeCenterController = () => {
  const { slugUrl } = useMultiAssemblySlug();
  const {
    modalOpen,
    setModalOpen,
    isLoading,
    data,
    selectedFile,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    isConvertedPdfPreviewsEnabled,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    hasNextPage,
    filter,
    sortedValue,
    hasCompletedInitialLoad,
    handleFetchNextPage,
    handleSetSelectedFile,
    onDownloadFileClick,
    copyPostLink,
    setSortedValue,
    setSearchValue,
  } = useKnowledgeCenterController();

  const isRightAsideOpen = useLayoutStore(layoutSelector);

  const noFiles = data?.pages?.[0]?.data?.length === 0;

  const { push } = useHistory();

  const goToPost = (flowId: string, responseId: string) => {
    push(
      `${V3_POST_PAGE}`.replace(
        ':postType',
        `flows?flowId=${flowId}&responseId=${responseId}`,
      ),
    );
  };

  if (isLoading && !hasCompletedInitialLoad) {
    return (
      <FluidContainer contentPadding="16px">
        <Flex
          flexDirection="column"
          alignItems="center"
          margin="32px 0 0"
          data-testid="loading-kc"
        >
          <StyledImage
            alt={KNOWLEDGE_CENTER_LOADING}
            src={KnowledgeCenterLoadingImage}
          />

          <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_LOADING}</Body>

          <Body variant="body1">{KNOWLEDGE_CENTER_LOADING_SUB}</Body>
        </Flex>
      </FluidContainer>
    );
  } else if (!filter.keywords && noFiles) {
    return (
      <FluidContainer contentPadding="16px">
        <Flex
          flexDirection="column"
          alignItems="center"
          margin="32px 0 0"
          data-testid="empty-kc"
        >
          <StyledImage
            alt={KNOWLEDGE_CENTER_EMPTY}
            src={KnowledgeCenterEmptyImage}
          />

          <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_EMPTY}</Body>

          <Body variant="body1" style={{ width: '315px', marginTop: '4px' }}>
            {KNOWLEDGE_CENTER_EMPTY_SUB}
          </Body>
        </Flex>
      </FluidContainer>
    );
  } else if (!hasCompletedInitialLoad && !data) {
    trackKnowledgeCenterErrorEvent({
      error: KnowledgeCenterEvents.KnowledgeCenterShow,
    });
    return (
      <FluidContainer contentPadding="16px">
        <Flex
          flexDirection="column"
          alignItems="center"
          margin="32px 0 0"
          data-testid="error-kc"
        >
          <StyledImage
            alt={KNOWLEDGE_CENTER_ERROR}
            src={KnowledgeCenterErrorImage}
          />

          <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_ERROR}</Body>

          <Body variant="body1">{KNOWLEDGE_CENTER_ERROR_SUB}</Body>
        </Flex>
      </FluidContainer>
    );
  } else {
    return (
      <FluidContainer>
        <KnowledgeCenterWrapper isRightAsideOpen={isRightAsideOpen}>
          <KnowledgeCenterActions setSearchValue={setSearchValue} />
          <KnowledgeCenterSort
            isSearched={Boolean(filter.fileTypes)}
            hasFiles={!noFiles}
            sortedValue={sortedValue}
            setSortedValue={setSortedValue}
          />
          <Switch>
            <Route
              path={`/${slugUrl}${KNOWLEDGE_CENTER_CARD_VIEW}`}
              render={() => (
                <KnowledgeCenterCardGrid
                  data={data}
                  isLoading={isLoading}
                  hasNextPage={hasNextPage}
                  goToPost={goToPost}
                  handleFetchNextPage={handleFetchNextPage}
                  handleSetSelectedFile={handleSetSelectedFile}
                  copyPostLink={copyPostLink}
                  onDownloadFileClick={onDownloadFileClick}
                  setModalOpen={setModalOpen}
                />
              )}
            />
            <Route
              path={`/${slugUrl}${KNOWLEDGE_CENTER_LIST_VIEW}`}
              render={() => (
                <KnowledgeCenterList
                  data={data}
                  isLoading={isLoading}
                  hasNextPage={hasNextPage}
                  goToPost={goToPost}
                  handleFetchNextPage={handleFetchNextPage}
                  handleSetSelectedFile={handleSetSelectedFile}
                  copyPostLink={copyPostLink}
                  onDownloadFileClick={onDownloadFileClick}
                  setModalOpen={setModalOpen}
                />
              )}
            />
            <Redirect to={`/${slugUrl}${KNOWLEDGE_CENTER_CARD_VIEW}`} />
          </Switch>
          {selectedFile?.fileInfo && !_.isEmpty(selectedFile?.fileInfo) && (
            <FilePreviewModal
              person={selectedFile?.respondent}
              createdAt={selectedFile?.fileInfo.createdAt}
              flowId={selectedFile?.response.flowId}
              responseId={selectedFile?.response.responseId}
              fileInformation={selectedFile?.fileInfo}
              fileToDownload={fileToDownload}
              fileDownloadError={fileDownloadError}
              refetchDownloadLink={refetchFileDownloadLink}
              fileToPreview={fileToPreview}
              filePreviewError={filePreviewError}
              refetchPreviewLink={refetchFilePreviewLink}
              isFilePreviewLoading={isFilePreviewLoading}
              isConvertedPdfPreviewsEnabled={isConvertedPdfPreviewsEnabled}
              isOpen={modalOpen}
              source={'knowledgeCenter'}
              isAnonymous={
                selectedFile?.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
                selectedFile?.kind === FeedItemKind.EXTERNAL_ANONYMOUS
              }
              goToPost={goToPost}
              onClickDownload={() => onDownloadFileClick(selectedFile)}
              onCloseModal={() => {
                setModalOpen(false);
                handleSetSelectedFile(undefined);
              }}
              flow={selectedFile?.flowInfo}
              visibility={selectedFile?.flowInfo.visibility}
              data-testid="knowledge-center-file-previewer"
              blockId={selectedFile?.response.blockId}
            />
          )}
        </KnowledgeCenterWrapper>
      </FluidContainer>
    );
  }
};

export default KnowledgeCenterController;
