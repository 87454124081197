import React, { useCallback, useState } from 'react';

import CurrentMembersCount from '../../atoms/CurrentMembersCount';
import DrawerHeaderWithIcon from '../../molecules/DrawerHeaderWithIcon';
import { MEMBERS_IN } from '../../../languages/en/home/mainFeedDrawer';
import {
  ContentWrapper,
  MembersListWrapper,
  StyledBody,
  TopWrapper,
  Wrapper,
  StyledInviteMembersButton,
} from './styles';
import TextField from '../../molecules/TextField_V2';
import Link from '../../atoms/Link_V2';
import { ADMIN_USER_MANAGE } from '../../../constants/routes';
import { INVITE_MEMBERS } from '../../../languages/en/feed';
export interface AssemblyMembersProps {
  heading?: string;
  assemblyName?: string;
  children: React.ReactNode;
  isError: boolean;
  isListExpanded: boolean;
  membersSearchValue: string;
  onMembersSearchChange: (newValue: string) => void;
  toggleRightAsideOpen: () => void;
  totalNumberOfAssemblyMembers?: number;
  isFlowFeedRightDrawerMembersList?: boolean;
}

const AssemblyMembers = ({
  heading = MEMBERS_IN,
  assemblyName,
  children,
  isError,
  isListExpanded,
  membersSearchValue,
  onMembersSearchChange,
  toggleRightAsideOpen,
  totalNumberOfAssemblyMembers,
  isFlowFeedRightDrawerMembersList,
}: AssemblyMembersProps) => {
  const [showBoxShadow, setShowBoxShadow] = useState(false);

  const handleScroll = useCallback(
    (event) => {
      const { target } = event;
      if (target.scrollTop > 5) {
        setShowBoxShadow(
          membersSearchValue.length > 1 ? false : isListExpanded,
        );
      } else {
        setShowBoxShadow(false);
      }
    },
    [isListExpanded, membersSearchValue.length],
  );

  return (
    <Wrapper>
      <DrawerHeaderWithIcon icon="members-list" onClick={toggleRightAsideOpen}>
        {heading} <StyledBody variant="body1Medium">{assemblyName}</StyledBody>
      </DrawerHeaderWithIcon>
      <ContentWrapper>
        <TopWrapper shouldShowBoxShadow={showBoxShadow}>
          <TextField
            disabled={isError}
            onChange={onMembersSearchChange}
            placeholder="Find members"
            value={membersSearchValue}
          />
          {!isError && (
            <CurrentMembersCount count={totalNumberOfAssemblyMembers} />
          )}
        </TopWrapper>
        <MembersListWrapper
          isListExpanded={isListExpanded}
          onScroll={handleScroll}
        >
          {children}
          {totalNumberOfAssemblyMembers === 1 &&
            !isFlowFeedRightDrawerMembersList && (
              <Link to={ADMIN_USER_MANAGE}>
                <StyledInviteMembersButton color="secondary">
                  {INVITE_MEMBERS}
                </StyledInviteMembersButton>
              </Link>
            )}
        </MembersListWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const MemoizedAssemblyMembers = React.memo(AssemblyMembers);
MemoizedAssemblyMembers.displayName = 'AssemblyMembers';
export default MemoizedAssemblyMembers;
