import React from 'react';

import ParticipantsList from '../../../../atomic/molecules/ParticipantsList';
import {
  criteriaMenuItems,
  criteriaConditionItems,
} from '../../../../constants/flowsBuilder';
import { ComponentStatus } from '../../../../interfaces/component';
import {
  CriteriaGroups,
  VisibilityBuilderBlockData,
} from '../../../../interfaces/Flow/Builder';
import { PARTICIPANT_LIST_TEXTS } from '../../../../languages/en/flows';
import {
  SHARE_SHEET_MODAL,
  ADD_VISIBILITY_CRITERIA_LABEL,
} from '../../../../languages/en/flows/builder';
import { Flex } from '../../../../Utils/styles/display';
import FlowsBuilderFilterCriteriaController from '../../../flowsBuilder/FlowsBuilderFilterCriteriaController';
import { visibilityTypes } from '../../../flowsBuilder/FlowsBuilderVisibilityController/data';
import { StyledRadioOptions } from '../../../flowsBuilder/FlowsBuilderVisibilityController/styles';
import {
  ParticipantDescription,
  ParticipantsCriteriaContainer,
  ParticipantsListWrapper,
  ParticipationCriteriaTitle,
} from '../styles';
import useShareSheetVisibilityController from './useShareSheetVisibilityController';
import CriteriaItem from '../../../../atomic/molecules/CriteriaItem';
import { SlackMessageWrapper } from './styles';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import Body from '../../../../atomic/atoms/Body';
import {
  ConditionWrapper,
  HorizontalRule,
} from '../ShareSheetParticipantsController/styles';
import { LockIcon } from '../../../../atomic/molecules/CriteriaItem/styles';
import ToolTip from '../../../../atomic/molecules/ToolTipV2';
import ThemeV2 from '../../../../theme';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import useSlackUserStore from '../../../../stores/userStore';
import { Channel } from '../../../../interfaces/Connections';
import { Connection } from '../../../../queries/Flows/interfaces';
import { Nullable } from '../../../../Utils/common';

export type ShareSheetParticipantsControllerProps = {
  variant: 'mobileView' | 'default';
  visibilityBlockData: VisibilityBuilderBlockData | null;
  visibilityParticipantsCriteria: CriteriaGroups | undefined;
  updateVisibilityParticipantsBlockData: (
    updatedBlockData: VisibilityBuilderBlockData,
  ) => void;
};

const ShareSheetVisibilityController = (
  props: ShareSheetParticipantsControllerProps,
) => {
  const { models, operations } = useShareSheetVisibilityController(props);
  const {
    value,
    variant,
    blockData,
    isLoading,
    hasNextPage,
    radioOptions,
    participants,
    flowBuilderData,
    participantsCount,
    pendingMembersParticipantCount,
  } = models;

  const { isConnectionsTabTouched } = useFlowBuilderStore();
  const { selectedPostFlowResponseChannels } = useSlackUserStore();

  const { onChange, fetchNextPage, handleOnCriteriaGroupsChange } = operations;

  const isCriteriaItemsEmpty =
    props.visibilityBlockData?.criteriaGroups?.groups.length === 0;

  const slackConnectionsList = isConnectionsTabTouched
    ? selectedPostFlowResponseChannels.map((x: Channel) => ({
        id: x.id,
        name: x.displayName,
        isPrivate: x.isPrivate,
      }))
    : flowBuilderData?.data?.integrations?.responseNotification[0]?.metaData?.channelInfo.map(
        (channel) => ({
          id: channel.id,
          name: channel.name,
          isPrivate: channel.isPrivate,
        }),
      ) || [];

  const getWorkspaceName = () => {
    const getWorkspaceFromCriteria = (connections: Nullable<Connection[]>) => {
      return connections && connections.length ? connections[0] : null;
    };

    const participationConnection = getWorkspaceFromCriteria(
      flowBuilderData?.data?.integrations?.participationCriteria,
    );

    const viewingConnection = getWorkspaceFromCriteria(
      flowBuilderData?.data?.integrations?.viewingCriteria,
    );

    return (
      participationConnection?.workspaceName ||
      viewingConnection?.workspaceName ||
      'workspace'
    );
  };

  return (
    <>
      {variant === 'mobileView' ? (
        SHARE_SHEET_MODAL.VIEWERS_BLOCK.DESCRIPTION
      ) : (
        <ParticipantDescription variant="body3">
          {SHARE_SHEET_MODAL.VIEWERS_BLOCK.DESCRIPTION}
        </ParticipantDescription>
      )}
      <Flex
        width="100%"
        alignItems="unset"
        flexDirection={variant === 'mobileView' ? 'column' : 'row'}
      >
        <ParticipantsCriteriaContainer
          variant={variant}
          flexDirection="column"
          alignItems="flex-start"
        >
          <ParticipationCriteriaTitle variant="body1Medium" color="gray9">
            Select viewers
          </ParticipationCriteriaTitle>
          <StyledRadioOptions
            radioOptions={radioOptions}
            value={value}
            name="visibility-options"
            onChange={onChange}
          />
          {value === visibilityTypes.CUSTOM && (
            <FlowsBuilderFilterCriteriaController
              hideEveryone={false}
              variant={variant}
              criteriaGroups={blockData?.criteriaGroups}
              criteriaMenuItems={criteriaMenuItems}
              criteriaLabel={ADD_VISIBILITY_CRITERIA_LABEL}
              criteriaConditionItems={criteriaConditionItems}
              onCriteriaGroupsChange={handleOnCriteriaGroupsChange}
              from="visibility"
            />
          )}
          {slackConnectionsList.length > 0 && (
            <>
              {value === visibilityTypes.CUSTOM ? (
                <>
                  {!isCriteriaItemsEmpty && <HorizontalRule />}
                  <ConditionWrapper>
                    <Body variant="body2" color="gray9">
                      Or participant matches
                    </Body>
                    <ToolTip
                      position="bottom"
                      toolTipComponent={
                        <LockIcon
                          icon="lock"
                          size="14px"
                          color={ThemeV2.palette.gray8}
                        />
                      }
                    >
                      Members of this channel who <br />
                      were added via Connections can post <br />
                      in this flow.
                    </ToolTip>
                  </ConditionWrapper>
                  {slackConnectionsList.map((connection) => (
                    <CriteriaItem
                      isSlackCriteria
                      isLoading={false}
                      key={connection.id}
                      id={connection.id}
                      criteriaValue={connection.name}
                      icon="slack-dark"
                      criteria="Slack Channel"
                      criteriaCondition="is"
                      memberState={undefined}
                      criteriaMessage={
                        <>
                          Members of this channel can <br />
                          see this flow’s responses in the <br />
                          channel. People who are not in <br />
                          your workspace will be invited.
                        </>
                      }
                    />
                  ))}
                </>
              ) : (
                <SlackMessageWrapper>
                  <SVGIcon icon="slack-logo" />
                  <Body variant="body2" color="gr">
                    Members of{' '}
                    <b>
                      {slackConnectionsList
                        .map((x) => x.name)
                        .reduce(
                          (acc, cur, idx) =>
                            acc +
                            (idx < slackConnectionsList.length - 1
                              ? cur + ', '
                              : (idx === 0 ? '' : ' and ') + cur),
                          '',
                        )}
                    </b>{' '}
                    in {getWorkspaceName()} can see this flow’s posts in the
                    channel. People who are not in your workspace will be
                    invited.
                  </Body>
                </SlackMessageWrapper>
              )}
            </>
          )}
        </ParticipantsCriteriaContainer>
        <ParticipantsListWrapper
          variant={variant}
          alignItems="unset"
          flexDirection="column"
        >
          {isLoading ? (
            <ParticipantsList
              title={PARTICIPANT_LIST_TEXTS.VIEWERS}
              status={ComponentStatus.LOADING}
              restrictHeight={false}
            />
          ) : (
            <ParticipantsList
              isInEditMode={true}
              title={PARTICIPANT_LIST_TEXTS.VIEWERS}
              participants={participants}
              hasMoreMembers={
                value === visibilityTypes.OWNER_AND_COLLABORATORS_ONLY
                  ? false
                  : hasNextPage
              }
              fetchNextPage={fetchNextPage}
              participantsCount={participantsCount}
              pendingMembersParticipantCount={pendingMembersParticipantCount}
              status={ComponentStatus.LOADED}
              restrictHeight={false}
              stickyHeader
            />
          )}
        </ParticipantsListWrapper>
      </Flex>
    </>
  );
};

export default ShareSheetVisibilityController;
