import styled from 'styled-components';

import Body from '../../atoms/Body';
import NewTextfield from '../NewTextfield';
import ThemeV2 from '../../../theme';

export const StyledScaleWrapper = styled.div<{
  columns: number;
  isReadOnly?: boolean;
}>`
  margin-top: ${({ isReadOnly }) => (isReadOnly ? '8px' : '0')};
  margin-bottom: ${({ isReadOnly }) => (isReadOnly ? '4px' : '12px')};
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
`;

export const StyledScaleItemLabel = styled.label<{
  isReadOnly?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: ${({ isReadOnly }) => (isReadOnly ? '32px' : '110px')};
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'unset' : 'pointer')};
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? ThemeV2.palette.gray2 : ThemeV2.palette.gray3};
  pointer-events: ${({ isReadOnly }) => (isReadOnly ? 'none' : 'auto')};

  &:hover {
    background-color: ${({ isReadOnly }) =>
      !isReadOnly && ThemeV2.palette.geekBlue1};
    > div:first-child {
      color: ${({ isReadOnly }) => !isReadOnly && ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledScaleRadioItem = styled.input<{
  isReadOnly?: boolean;
}>`
  position: absolute;
  opacity: 0;

  &.focus-visible + ${StyledScaleItemLabel} {
    outline: none;
    background: ${ThemeV2.palette.geekBlue1};
    div {
      color: ${({ isReadOnly }) => !isReadOnly && ThemeV2.palette.geekBlue6};
    }
  }

  &:checked + ${StyledScaleItemLabel} {
    background: ${ThemeV2.palette.geekBlue2};
    div {
      color: ${({ isReadOnly }) => !isReadOnly && ThemeV2.palette.geekBlue6};
    }
  }
`;

export const StyledLabelWrapper = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
`;

export const StyledLabelBody = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.low {
    text-align: left;
  }

  &.medium {
    text-align: center;
  }

  &.high {
    text-align: right;
  }
`;

export const StyledLabelInput = styled(NewTextfield)`
  input {
    border-radius: 4px;
  }
  .MuiInputBase-root:not(.Mui-focused) fieldset {
    border: none;
  }
  &.low {
    input {
      text-align: left;
    }
  }

  &.medium {
    input {
      text-align: center;
    }
  }

  &.high {
    input {
      text-align: right;
    }
  }
  &:hover {
    .MuiInputBase-root:not(.Mui-focused) input {
      background: ${ThemeV2.palette.gray3};
    }
  }
`;
