import React from 'react';
import { makeStyles } from '@mui/styles';
import ThemeV2 from '../../../theme';

import { PropsClasses } from '../../../interfaces/ClassesProps';

const useStyles = makeStyles({
  root: {
    display: 'inline-block',
    cursor: 'pointer',
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
});

interface LinkItemProps {
  id?: string;
  role?: string;
  className?: string;
  classes?: PropsClasses;
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  tabIndex?: number;
}

const LinkItem = (props: LinkItemProps) => {
  const classes = useStyles(props);
  const { className: parentClassName, ...rest } = props;
  return <div className={`${classes.root} ${parentClassName}`} {...rest} />;
};

export default LinkItem;
