import { ReactNode } from 'react';
import { Except } from 'type-fest';
import { FileType } from '../../organism/FileViewer/types';

export enum LegacyFileType {
  Image = 'IMAGE',
  File = 'FILE',
}

export enum FileUploadStatus {
  Loading = 'LOADING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export type File = {
  _id?: string;
  type: LegacyFileType | FileType;
  name: string;
  location?: string;
  size?: number;
  createdAt?: string;
  thumbnails?: {
    360: string;
  };
  url?: string;
  id?: string;
  originalName?: string;
  mimeType?: string;
};

export type FileCardProps = {
  file?: File;
  flowId?: string;
  responseId?: string;
  className?: string;
  errorMessage?: string;
  isParticipation: boolean;
  status: FileUploadStatus;
  loadingProgress?: number;
  dataTestId?: string;
  isNewThumbnailExperience?: boolean;
  onClickClose?: (data: unknown) => void;
  onClickRetry?: (data: unknown) => void;
  onClickDownload?: (data: File) => void;
  onClickPreview?: (data: File) => void;
};

export type FileCardWrapperProps = {
  children: ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
  isParticipation: boolean;
};

type ThumbnailProps = {
  file: File;
  cardColor: string;
  cardIcon?: string;
  cardImageUrl: string;
  isNewThumbnailExperience?: boolean;
  dataTestId: string;
  cardWidth: string;
};

export type ResponseThumbnailProps = Except<
  ThumbnailProps,
  'isNewThumbnailExperience'
> & {
  openPreviewModal: (fileToPreview: File) => void;
  onOptionsSelect: (value: string | number) => void;
};

export type ParticipationThumbnailProps = ThumbnailProps & {
  onClickClose?: (data: unknown) => void;
  dataQaId?: string;
};
