import React, { useEffect, useMemo, useState } from 'react';
import parse from 'html-react-parser';

import {
  AFTER_CLICKING_REDEEM,
  CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED,
  REDEEM_SUCCESS_MESSAGE,
  GIFT_CARD,
} from '../../../languages/en/rewards';
import RedeemSelectModal from '../../../atomic/organism/RedeemSelectModal';
import {
  RedeemPolicyContent,
  RedeemPolicyHeading,
} from '../../../atomic/organism/RedeemSelectModal/styles';
import useRedeemModalControllerLogic from './useRedeemModalControllerLogic';
import { GiftCardReward } from '../../../queries/Rewards/interfaces';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { useRedeemPostQuery } from '../../../queries/Rewards';
import { parseOrder } from './utility';
import { showSuccessMessage } from '../../../Utils/toast';
import { getDisplayAmount } from '../../../Utils/rewards';

interface RedeemModalControllerProps {
  reward: GiftCardReward;
  isModalOpen: boolean;
  exchangeRates: Record<string, number>;
  exchangeRate?: number;
  profileEmail: string;
  assemblyCurrency: AssemblyCurrency;
  onCloseRedeemModal: () => void;
  pointsEarned: number;
  policyTitle: string;
  policyDescription: string;
}

const RedeemModalController = (props: RedeemModalControllerProps) => {
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [rewardSuccessTitle, setRewardSuccessTitle] = useState<string>('');

  const {
    reward,
    isModalOpen,
    exchangeRates,
    exchangeRate = 1,
    profileEmail,
    assemblyCurrency,
    pointsEarned,
    onCloseRedeemModal,
    policyTitle,
    policyDescription,
  } = props;

  const rewardTerms = reward?.terms;

  const {
    isLoading: isRedeemRewardLoading,
    isSuccess: isRedeemRewardSuccess,
    data: redeemRewardSuccess,
    mutate: redeemRewardMutate,
    reset: redeemRewardReset,
  } = useRedeemPostQuery();

  const {
    modelMethods,
    getItemLabel,
    id,
    rewardTitle,
    label,
    isRedeemButtonDisabled,
    rewardImage,
    isRewardRated,
    dropdownItems,
    dropdownValue,
  } = useRedeemModalControllerLogic({
    reward,
    exchangeRates,
    exchangeRate,
    onCloseRedeemModal,
    assemblyCurrency,
    redeemRewardMutate,
    redeemRewardReset,
    profileEmail,
    pointsEarned,
  });

  useEffect(() => {
    if (isRedeemRewardSuccess && !hasRedeemed) {
      setHasRedeemed(true);
    }
  }, [isRedeemRewardSuccess, hasRedeemed]);

  useEffect(() => {
    if (isRedeemRewardSuccess) {
      const denomination = reward.denominations.find(
        (item) => item._id === dropdownValue,
      );
      let amount;

      if (denomination) {
        const { currencyCode, faceValue } = denomination;
        amount = getDisplayAmount(faceValue, currencyCode);
      }

      const title = `${amount} ${reward.brandName} ${GIFT_CARD}`;
      setRewardSuccessTitle(title);
      showSuccessMessage(REDEEM_SUCCESS_MESSAGE);
    }
  }, [
    dropdownValue,
    isRedeemRewardSuccess,
    reward.brandName,
    reward.denominations,
  ]);

  const redeemPolicyContent = useMemo(() => {
    return (
      <>
        <RedeemPolicyHeading variant="h6">{policyTitle}</RedeemPolicyHeading>
        <RedeemPolicyContent variant="body2">
          {policyDescription} <b>{profileEmail}</b>.
        </RedeemPolicyContent>
        <RedeemPolicyContent variant="body2">
          {AFTER_CLICKING_REDEEM}
        </RedeemPolicyContent>
        <RedeemPolicyContent variant="body2">
          {rewardTerms && parse(rewardTerms)}
        </RedeemPolicyContent>
      </>
    );
  }, [policyDescription, policyTitle, profileEmail, rewardTerms]);

  const rewardSuccessContent = useMemo(() => {
    if (redeemRewardSuccess && redeemRewardSuccess.data.data.order.orderInfo) {
      return <>{parseOrder(redeemRewardSuccess.data.data.order.orderInfo)}</>;
    }
    return '';
  }, [redeemRewardSuccess]);

  return (
    <RedeemSelectModal
      isOpen={isModalOpen}
      isLoadingPostRedeem={isRedeemRewardLoading}
      {...modelMethods}
      assemblyCurrency={assemblyCurrency}
      redeemPolicyContent={redeemPolicyContent}
      rewardSuccessContent={rewardSuccessContent}
      rewardSuccessHeading={CONGRATS_YOU_HAVE_SUCCESSFULLY_REDEEMED}
      getItemLabel={getItemLabel}
      id={id}
      rewardTitle={rewardTitle}
      label={label}
      isRedeemButtonDisabled={isRedeemButtonDisabled}
      rewardImage={rewardImage}
      isRewardRated={isRewardRated}
      dropdownItems={dropdownItems}
      dropdownValue={dropdownValue}
      rewardSuccessTitle={rewardSuccessTitle}
      hasRedeemed={hasRedeemed}
    />
  );
};

export default RedeemModalController;
