import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  AUTHENTICATE_SAML,
  GET_SAML_AUTH_CONFIG,
} from '../../../constants/endpoints';
import { makeAPICallWithDataReturn } from '../../utils';
import { SAMLAuthErrorCodes } from '../../../controllers/SAMLAuthController/data';

export interface AuthenticateSAMLResponse {
  user: any;
  jwtToken: string;
  refreshToken?: string;
}

export interface AuthenticateSAMLError {
  message: SAMLAuthErrorCodes;
}

export interface FetchSAMLAuthConfigResponse {
  name: string;
  uri: string;
}

export const useAuthenticateSAMLQuery = (makeRequest = true, token = '') => {
  return useQuery<AuthenticateSAMLResponse, AxiosError<AuthenticateSAMLError>>(
    [AUTHENTICATE_SAML],
    () => makeAPICallWithDataReturn(AUTHENTICATE_SAML, null, { code: token }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};

export const useSAMLConfigFetchQuery = (
  makeRequest = true,
  assemblyId = '',
) => {
  return useQuery<FetchSAMLAuthConfigResponse>(
    [GET_SAML_AUTH_CONFIG],
    () => makeAPICallWithDataReturn(GET_SAML_AUTH_CONFIG, null, { assemblyId }),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};
