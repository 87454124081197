import pluralize from 'pluralize';
import { EditorState, SelectionState } from 'draft-js';

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import Body from '../../../../../atomic/atoms/Body';
import PostInput from '../../../../../atomic/organism/PostInput';
import FeedPostComments from '../../../../../atomic/organism/FeedPostComments';
import FlowsPostHeaderOption from '../../../../../atomic/organism/FlowsPostHeaderOption';
import { getFeedPostHeaderMenuOptions } from '../../../../../atomic/organism/FlowFeedPost/data';

import {
  useGetSinglePostQuery,
  useSingleRecognitionUpdatePostReactionMutation,
} from '../../../../../queries/Feed';

import { useGetPostComments } from '../../../../../queries/Feed/comments';

import { Flex } from '../../../../../Utils/styles/display';
import { FeedContext } from '../../../../../Utils/flows/feeds';
import { COMMENTS_LIMIT } from '../../../../../Utils/constants';

import FlowPostController from '../../../../flows/FlowPostController';
import useFlowPostController from '../../../../flows/FlowPostController/useFlowPostController';
import { getOpenEndedEditedBlockInitialValue } from '../../../../flows/ParticipationFlowController/utils';

import {
  FeedItemFromAPI,
  FeedCommentFromAPI,
} from '../../../../../interfaces/Feed';

import { PostTypes } from '../../../../../interfaces/Home';
import { ComponentStatus } from '../../../../../interfaces/component';

import {
  HorizontalRule,
  CommentsContainer,
  DropdownContainer,
  RepliesTextWrapper,
  CommentInputWrapper,
  StyledFlowPostHeader,
  ViewMoreRepliesButton,
  PostAndCommentsContainer,
  StyledFeedCommentInputWrapper,
  PostAndCommentWrapper,
} from '../../styles';

import { CommentsLoader } from '../Loader';
import { EmptyChatWrapper } from '../../../ChatController/styles';
import startConversation from '../../../../../img/svgs/start_conversation.svg';

import useFlowFeed from '../../../../../hooks/useFlowFeed';
import useRepliesBar from '../../../../../hooks/useRepliesBar';
import { useCommentInput } from '../../../../../hooks/useFeedCommentInput';

import useLayoutStore from '../../../../../stores/layoutStore';
import { trackPostActionEvent } from '../../../../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../../../../Utils/analytics/constants';
import { GIVE_TROPHIES_BACK_TO_SENDER } from '../../../../../languages/en/home';
import { ModalContentProps } from '../../../../../atomic/organism/FeedPostHeaderOption/interface';
import { DeleteReplyConfirmationModal } from '../../../../../atomic/molecules/DeleteReplyConfirmationModal';
import { isIPhoneOS, isMobilePlatform } from '../../../../../Utils/window';

const RecognitionPost = ({
  postId,
  commentId,
  isMobileApp,
  handleCloseButtonClick,
}: any) => {
  const SHOW_REPLIES_BUTTON_DELTA = 50;

  const postContainer = useRef<HTMLDivElement>(null);
  const commentInputContainer = useRef<HTMLDivElement>(null);

  const [footerHeight, setFooterHeight] = useState(100);
  const [isAutoScrolled, setIsAutoScrolled] = useState(false);
  const [showRepliesButton, setShowRepliesButton] = useState(false);
  const [showBoxShadowForHeader, setShowBoxShadowForHeader] = useState(false);
  const [showBoxShadowForFooter, setShowBoxShadowForFooter] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfoContent, setModalInfoContent] = useState<ModalContentProps>(
    {},
  );

  const { isRightAsideOpen, isEmbeddedInMainApp } = useLayoutStore();

  const { repliesBarProps } = useRepliesBar({
    postId: postId,
    type: 'POST',
    enabled: true,
  });

  const { data } = useGetSinglePostQuery(postId);

  const [editedComment, setEditedComment] = useState<FeedCommentFromAPI | null>(
    null,
  );
  const { mutate } = useSingleRecognitionUpdatePostReactionMutation(postId);

  const { feedContextProps, profileData, onDeleteCommentClick } = useFlowFeed({
    mutatePostReaction: mutate,
  });

  const postCommentsObj = useGetPostComments(
    postId,
    COMMENTS_LIMIT,
    true,
    'asc',
  );

  const scrollSideDrawerInView = useCallback(() => {
    const element = postContainer.current;
    if (element) {
      element.scroll({ top: element.scrollHeight, behavior: 'auto' });
    }
  }, []);

  useEffect(() => {
    if (postContainer.current) {
      const element = postContainer.current;
      if (element) {
        const { scrollHeight, clientHeight, scrollTop } = element;
        setShowBoxShadowForHeader(scrollTop > 0);
        setShowBoxShadowForFooter(
          scrollHeight - (scrollTop + clientHeight) > 0,
        );
        setShowRepliesButton(
          scrollHeight - (scrollTop + clientHeight) > SHOW_REPLIES_BUTTON_DELTA,
        );
      }
    }
  }, [
    postContainer.current?.scrollTop,
    postContainer.current?.scrollHeight,
    postContainer.current?.clientHeight,
  ]);

  const {
    isLoadingComments,
    showMoreCommentsButton,
    commentsData,
    fetchNextPage,
  } = useMemo(() => {
    const {
      isFetching,
      data: commentsPaginatedData,
      hasNextPage: commentsHasNextPage,
      fetchNextPage: fetchNextComments,
      fetchPreviousPage: fetchPreviousComments,
    } = postCommentsObj;
    const selectedPaginationData = commentsPaginatedData;
    const commentsArr = selectedPaginationData
      ? selectedPaginationData.pages.reduce<FeedCommentFromAPI[]>(
          (arr, currentPage) => [
            ...arr,
            ...currentPage.data.map((x) => {
              return {
                ...x,
                message: x.version === 2 ? '' : x.message,
              };
            }),
          ],
          [],
        )
      : [];

    commentsArr.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
    return {
      isLoadingComments: isFetching,
      commentsData: commentsArr,
      showMoreCommentsButton:
        (data?.commentsCount || 0) > 3 && (commentsHasNextPage || false),
      fetchNextPage: async () => {
        if (postCommentsObj.hasNextPage) {
          await fetchNextComments();
        }
        await scrollSideDrawerInView();
      },
      fetchPreviousPage: async () => {
        if (postCommentsObj.hasPreviousPage) {
          await fetchPreviousComments();
        }
      },
    };
  }, [data?.commentsCount, postCommentsObj]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const element = event.target as HTMLDivElement;
      if (element) {
        const { scrollHeight, clientHeight, scrollTop } = element;

        const scrollBottom = scrollHeight - (scrollTop + clientHeight);

        setShowBoxShadowForHeader(scrollTop > 0);
        setShowBoxShadowForFooter(scrollBottom > 0);
        setShowRepliesButton(scrollBottom > SHOW_REPLIES_BUTTON_DELTA);

        if (scrollBottom <= 200 && postCommentsObj.hasNextPage) {
          fetchNextPage();
        }
      }
    };

    postContainer.current?.addEventListener('scroll', handleScroll);

    return () => {
      postContainer.current?.removeEventListener('scroll', handleScroll);
    };
  }, [
    fetchNextPage,
    postCommentsObj.hasNextPage,
    postContainer.current?.scrollTop,
    postContainer.current?.scrollHeight,
    postContainer.current?.clientHeight,
  ]);

  const { commentInputProps } = useCommentInput({
    profileData,
    postId,
    postType: data?.type || PostTypes.RECOGNITION,
    trophyReceivers: data?.to || [],
    editedComment: editedComment,
    onCommentMutated: scrollSideDrawerInView,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      commentInputProps.setEditorFocus();
    });
    return () => clearTimeout(timer);
  }, []);

  const flowPostControllerProps = useMemo(
    () => ({
      variant: 'sidebar',
      hideBorder: true,
      hideRepliesBar: true,
      hideReplyButton: true,
      post: data as FeedItemFromAPI,
      profileData: profileData,
      showEditOption: false,
    }),
    [data, profileData],
  );

  const handleEditComment = useCallback(
    (id: string) => {
      commentsData.forEach((comment) => {
        if (comment.commentID === id) {
          setEditedComment(comment);
          comment.isEditing = true;
          if (commentInputProps.setGifUrl) {
            commentInputProps?.setGifUrl(comment.gifURL);
          }
          const selectedMentions = comment.taggedUsers.map((x) => ({
            id: x.memberID,
            name: x.name || '',
          }));

          const editedState = getOpenEndedEditedBlockInitialValue({
            mentions: comment.taggedUsers.map((x) => ({
              memberId: x.memberID,
              lastName: x.lastName,
              firstName: x.firstName,
            })),
            gifUrl: comment.gifURL,
            files: [],
            tags: [],
            tasks: [],
            value: comment.message,
          });

          commentInputProps.setSelectedMentions.current = selectedMentions;

          if (commentInputProps.setEditorState) {
            const content = editedState.editorState.getCurrentContent();
            const blockMap = content.getBlockMap();

            const key = blockMap.last().getKey();
            const length = blockMap.last().getLength();

            const selection = new SelectionState({
              anchorKey: key,
              anchorOffset: length,
              focusKey: key,
              focusOffset: length,
            });

            EditorState.forceSelection(editedState.editorState, selection);
            commentInputProps.setEditorState(editedState.editorState);
            setTimeout(() => {
              commentInputProps.setEditorFocus();
            });
          }
        }
      });
    },
    [commentInputProps, commentsData],
  );

  const initialLoad = useRef(true);
  useEffect(() => {
    if (!isLoadingComments && initialLoad.current) {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('commentId');
      const isEditing = searchParams.get('editing') === 'true';
      if (isEditing && id) {
        handleEditComment(id);
      }
    }
  }, [isLoadingComments]);

  const {
    feedProps,
    hasTrophies,
    onCopyLinkClick,
    onGotoPostLinkClick,
    onDeleteModalSubmitClick,
    handleDeletePostOptionChange,
    notificationPreference,
    onNotificationPreferenceClick,
    currency,
  } = useFlowPostController(flowPostControllerProps);

  useEffect(() => {
    if (!isAutoScrolled && commentId && !isLoadingComments) {
      setIsAutoScrolled(true);
      const element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [commentId, isAutoScrolled, isLoadingComments]);

  const dropdownComponent = useMemo(
    () => (
      <DropdownContainer>
        <FlowsPostHeaderOption
          icon="more"
          hasTrophies={hasTrophies}
          title="flows post options"
          onCopyLink={onCopyLinkClick}
          onGotoPostClick={onGotoPostLinkClick}
          onDeleteModalSubmitClick={onDeleteModalSubmitClick}
          onDeletePostSelectedOption={handleDeletePostOptionChange}
          dropdownOptions={getFeedPostHeaderMenuOptions(
            true,
            false,
            'sidebar',
            notificationPreference,
          )}
          onNotificationPreferenceClick={onNotificationPreferenceClick}
          currency={currency}
        />
      </DropdownContainer>
    ),
    [
      hasTrophies,
      onCopyLinkClick,
      onGotoPostLinkClick,
      onDeleteModalSubmitClick,
      handleDeletePostOptionChange,
      notificationPreference,
      onNotificationPreferenceClick,
      currency,
    ],
  );

  const resetCommentEditor = useCallback(() => {
    if (editedComment && commentInputProps?.setEditorState) {
      if (commentInputProps?.setGifUrl) {
        commentInputProps?.setGifUrl('');
      }

      setEditedComment(null);
      editedComment.isEditing = false;
      commentInputProps.isInEditMode = false;
      commentInputProps.setSelectedMentions.current = [];
      commentInputProps.setEditorState(EditorState.createEmpty());
    }
  }, [commentInputProps, editedComment]);

  const handleCancelClick = useCallback(() => {
    resetCommentEditor();
    trackPostActionEvent({
      postId: postId,
      type: PostTypes.FLOW,
      messageType: 'replies',
      replyId: editedComment?.commentID,
      action: POST_ANALYTICS_EVENTS.REPLY_EDIT_CANCELLED,
    });
  }, [commentInputProps, editedComment, postId]);

  const handleCommentDelete = useCallback(() => {
    if (!commentInputProps.hasText) {
      setModalInfoContent({
        headerText: 'Delete reply?',
        // eslint-disable-next-line max-len
        contentText:
          'When you delete this reply, it will disappear from the post.  You will not be able to get it back',
      });
      setIsModalOpen(true);
    }
  }, [commentInputProps.hasText]);

  const handleModalSubmit = useCallback(() => {
    if (onDeleteCommentClick)
      onDeleteCommentClick(
        String(editedComment?.commentID),
        false,
        GIVE_TROPHIES_BACK_TO_SENDER,
        { postId: String(editedComment?.postID), type: PostTypes.RECOGNITION },
      );
    setIsModalOpen(false);
    resetCommentEditor();
  }, [editedComment, onDeleteCommentClick, resetCommentEditor]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (commentInputContainer.current) {
      let height = commentInputContainer.current?.scrollHeight || 100;

      if (commentInputProps.selectedGif) {
        height += 10;
      }

      setFooterHeight(height);
    }
  }, [commentInputProps.editorState, commentInputProps.selectedGif]);

  const PostAndCommentContainerHeight = useMemo(() => {
    if (isEmbeddedInMainApp) {
      return `calc(100vh - ${footerHeight + 140}px)`;
    } else if (isMobilePlatform && !isMobileApp) {
      return `calc(100vh - ${footerHeight + (isIPhoneOS ? 280 : 230)}px)`;
    } else {
      return `calc(100vh - ${footerHeight + 164}px)`;
    }
  }, [footerHeight, isEmbeddedInMainApp, isMobileApp]);

  return (
    <>
      {data && profileData && (
        <Flex
          width="100%"
          flexDirection="column"
          height={isEmbeddedInMainApp ? 'calc(100vh - 20px)' : '100%'}
        >
          <FeedContext.Provider value={feedContextProps}>
            {feedProps && (
              <StyledFlowPostHeader
                {...feedProps.headerProps}
                variant="sidebar"
                isMobileApp={isMobileApp}
                onCloseButtonClick={handleCloseButtonClick}
                dropdownComponent={dropdownComponent}
                showBoxShadow={showBoxShadowForHeader}
                onFlowClick={feedContextProps.onFlowClick}
                currentUserId={profileData.member.memberId}
                onClickUserName={feedContextProps.onMemberClick}
              />
            )}
            <PostAndCommentWrapper>
              <PostAndCommentsContainer
                width="100%"
                ref={postContainer}
                flexDirection="column"
                height={PostAndCommentContainerHeight}
              >
                <Flex alignItems="flex-start" width="100%">
                  <FlowPostController
                    post={data}
                    hideBorder
                    hideRepliesBar
                    variant="sidebar"
                    showEditOption={false}
                    profileData={profileData}
                    showPostInteractionSettings={false}
                  />
                </Flex>
                {repliesBarProps.status === ComponentStatus.LOADED &&
                  repliesBarProps.count > 0 && (
                    <RepliesTextWrapper>
                      <Body variant="body2" color="gray8">
                        {repliesBarProps.count > 0
                          ? pluralize('reply', repliesBarProps.count, true)
                          : 'No replies'}
                      </Body>
                      <HorizontalRule />
                    </RepliesTextWrapper>
                  )}
                <CommentsContainer>
                  <FeedPostComments
                    isDummy={false}
                    variant="sidebar"
                    onEditComment={handleEditComment}
                    fetchNextPage={fetchNextPage}
                    profileData={profileData}
                    commentsData={commentsData}
                    onShowMoreCommentsButtonClick={fetchNextPage}
                    showMoreCommentsButton={showMoreCommentsButton}
                    feedItemMetaData={{
                      postId: data.postID,
                      type: PostTypes.FLOW,
                    }}
                  />
                  {isLoadingComments && commentsData.length === 0 && (
                    <CommentsLoader />
                  )}
                </CommentsContainer>
                {commentInputProps && (
                  <StyledFeedCommentInputWrapper
                    footerHeight={footerHeight}
                    data-testid="feed-comment-input-wrapper"
                  >
                    {(showRepliesButton || showMoreCommentsButton) &&
                      !isMobileApp &&
                      isRightAsideOpen &&
                      repliesBarProps.status === ComponentStatus.LOADED &&
                      repliesBarProps.count > 0 && (
                        <Flex justifyContent="center" padding="16px 0">
                          <ViewMoreRepliesButton
                            icon="arrow-down"
                            onClick={fetchNextPage}
                            variant="showMorePrimary"
                            size="small"
                            color="grayTertiary"
                            isLoading={isLoadingComments}
                          >
                            See latest replies
                          </ViewMoreRepliesButton>
                        </Flex>
                      )}
                  </StyledFeedCommentInputWrapper>
                )}

                {!isLoadingComments &&
                  feedProps &&
                  commentsData.length === 0 && (
                    <Flex
                      height="100%"
                      flexDirection="column"
                      justifyContent="flex-end"
                    >
                      <img src={startConversation} />
                      <EmptyChatWrapper flexDirection="column">
                        <Body variant="body1Bold" color="gray">
                          Be the first to reply
                        </Body>
                        {feedProps.headerProps?.person?.firstName && (
                          <Body variant="body2" color="gray">
                            Write {feedProps.headerProps?.person?.firstName} a
                            reply below 👇
                          </Body>
                        )}
                      </EmptyChatWrapper>
                    </Flex>
                  )}
              </PostAndCommentsContainer>

              <CommentInputWrapper
                ref={commentInputContainer}
                className="introjs-replies-text"
                showBoxShadow={showBoxShadowForFooter}
              >
                <PostInput
                  {...commentInputProps}
                  variant="sidebar"
                  buttonText="Add"
                  isTasksButtonHidden
                  showAddTrophy={Boolean(
                    !(editedComment && editedComment.isEditing),
                  )}
                  onCancelClick={handleCancelClick}
                  isInEditMode={editedComment?.isEditing}
                  handleCommentDelete={handleCommentDelete}
                />
              </CommentInputWrapper>
            </PostAndCommentWrapper>
            <DeleteReplyConfirmationModal
              isModalOpen={isModalOpen}
              modalInfoContent={modalInfoContent}
              handleModalClose={handleModalClose}
              handleModalSubmit={handleModalSubmit}
            />
          </FeedContext.Provider>
        </Flex>
      )}
    </>
  );
};

export default RecognitionPost;
