import { useEffect, useState } from 'react';

type ElementOnScreenProps = {
  ref: React.RefObject<HTMLElement>;
  options?: IntersectionObserverInit;
};

const useElementOnScreen = (props: ElementOnScreenProps) => {
  const { options, ref } = props;
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    const { current: currentRef } = ref;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref, options]);

  return { isVisible };
};

export default useElementOnScreen;
