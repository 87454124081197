import { MutableRefObject, SyntheticEvent } from 'react';
import styled from 'styled-components';

import {
  ShareSheetModalTabIndex,
  ShareSheetRuleAccessTypes,
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
} from '../../../controllers/flows/FlowsShareSheetController/types';
import Theme from '../../../theme';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { getShareSheetCardDataFromAutoCompleteOption } from '../../../Utils/flows/sharesheet';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import { SimplifiedShareSheetUserCard } from '../../molecules/ShareSheet/SimplifiedShareSheetUserCard';
import { SingleSelectAutoComplete } from '../Autocomplete';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';
import Avatar from '../../atoms/Avatar';
import envelopeImage from '../../atoms/SVGIcon/icons/envelope.svg';
import { NonMemberRuleSelector } from './NonMemberRuleSelector';

const InviteBanner = styled(Flex)`
  position: absolute;
  bottom: 4px;
  background: ${Theme.palette.white};
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: calc(100% - 8px);
  height: 40px;
  border-top: 1px solid ${Theme.palette.gray4};
  padding: 8px;
`;

export const SimpleShareSheetPeopleSelector = ({
  selectedItems,
  textBoxValue,
  setTextBoxValue,
  canEmailInvite,
  isEveryoneSelected,
  handleBulkMemberActionSelection,
  resetAutoCompleteDropdown,
  onOptionsScroll,
  inputRef,
  isMembersLoading,
  handleAutoCompleteOptionSelection,
  displayOptions,
  value,
  currentUserId,
  handleSelectedTabIndexChange,
  canInviteMembersFromShareSheet,
  canShowEveryoneCriteria,
}: {
  currentUserId: string;
  value: AutocompleteDropdownItem<string, undefined> | null;
  displayOptions: AutocompleteDropdownItem<string, undefined>[];
  inputRef: MutableRefObject<HTMLInputElement | null>;
  isMembersLoading: boolean;
  textBoxValue: string;
  setTextBoxValue: (value: string) => void;
  selectedItems: SimplifiedShareSheetCardData[];
  canEmailInvite: boolean;
  isEveryoneSelected: boolean;
  handleBulkMemberActionSelection: (
    actionType:
      | 'department'
      | 'everyone'
      | 'jobTitle'
      | 'workLocation'
      | 'homeLocation',
  ) => void;
  resetAutoCompleteDropdown: (inputRef: any) => void;
  onOptionsScroll?: () => void;
  handleAutoCompleteOptionSelection: (
    selectedAutoCompleteOption: AutocompleteDropdownItem<string, undefined>,
    inputRef: MutableRefObject<HTMLInputElement | null>,
  ) => void;
  handleSelectedTabIndexChange: (index: number) => void;
  canInviteMembersFromShareSheet: boolean;
  canShowEveryoneCriteria: boolean;
}) => {
  const handleOptionsScroll = (event: SyntheticEvent) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollHeight * 0.7 <=
      listboxNode.scrollTop + listboxNode.clientHeight
    ) {
      onOptionsScroll?.();
    }
  };

  const onRuleSelection = (action: string) => {
    resetAutoCompleteDropdown(inputRef);
    if (action === 'everyone') {
      handleBulkMemberActionSelection('everyone');
    } else if (
      action === 'department' ||
      action === 'jobTitle' ||
      action === 'workLocation' ||
      action === 'homeLocation'
    ) {
      handleBulkMemberActionSelection(action);
    } else if (action === 'moveToConnectionsTab') {
      handleSelectedTabIndexChange(ShareSheetModalTabIndex.Connections);
      trackShareSheetActionEvent({
        action: SHARE_SHEET_EVENTS.SLACK_CHANNEL_RULE_CLICKED,
        tab: 'invite and share',
        ownerId: currentUserId,
        ruleType: ShareSheetRuleAccessTypes.Simple,
      });
    }
  };

  const renderCustomDropdownCard = (
    option: AutocompleteDropdownItem<string>,
  ) => {
    const currentOptionFromSelectedItems = selectedItems.find(
      (item) => item.id === option.id,
    );
    const cardData = currentOptionFromSelectedItems
      ? currentOptionFromSelectedItems
      : getShareSheetCardDataFromAutoCompleteOption({
          option,
          type: SimplifiedShareSheetDataTypes.Member,
        });
    if (option.id === 'bulk-member-actions') {
      return canEmailInvite ? null : (
        <NonMemberRuleSelector
          isEveryoneSelected={isEveryoneSelected}
          onRuleSelection={onRuleSelection}
          canShowEveryoneCriteria={canShowEveryoneCriteria}
        />
      );
    } else if (option.id === 'invite-user-card') {
      return (
        <Flex padding="8px">
          <Avatar img={envelopeImage} size="24px" />
          <Body margin="0 0 0 8px" color="gray8" variant="body2">
            {`Add "${option.emailToInvite}" to this flow`}
          </Body>
        </Flex>
      );
    }
    return (
      <SimplifiedShareSheetUserCard
        cardData={cardData}
        shouldDisplayDropdown={false}
        currentUserId={currentUserId || ''}
      />
    );
  };

  return (
    <SingleSelectAutoComplete
      options={displayOptions !== null ? displayOptions : []}
      value={value}
      textboxValue={textBoxValue}
      onChange={(selectedOption: AutocompleteDropdownItem<string> | null) => {
        if (selectedOption?.id === 'invite-user-card') {
          trackShareSheetActionEvent({
            action: SHARE_SHEET_EVENTS.INVITE_MEMBER_CLICKED,
            tab: 'invite and share',
            ownerId: currentUserId,
            ruleType: ShareSheetRuleAccessTypes.Simple,
          });
        }
        if (selectedOption)
          handleAutoCompleteOptionSelection(selectedOption, inputRef);
      }}
      onTextboxValueChange={(val: string) => {
        setTextBoxValue(val);
      }}
      onBlur={() => {
        setTextBoxValue('');
        resetAutoCompleteDropdown(inputRef);
      }}
      onFocus={() => {
        setTextBoxValue('');
      }}
      loading={isMembersLoading}
      inputRef={inputRef}
      renderCustomDropdownCard={renderCustomDropdownCard}
      placeholder="Add people or rules..."
      showClearIcon
      filterOptionsFunction={(opts) => opts}
      ListboxProps={{
        onScroll: handleOptionsScroll,
      }}
      expandableDropdown={false}
      overrideTextBoxValue
      popupIconSize="20px"
    >
      {!canEmailInvite && canInviteMembersFromShareSheet ? (
        <InviteBanner>
          <Body color="gray8" variant="body2">
            Don’t see who you’re looking for? Type an email to invite a new
            teammate.
          </Body>
        </InviteBanner>
      ) : undefined}
    </SingleSelectAutoComplete>
  );
};
