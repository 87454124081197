import React from 'react';
import SVGIcon from '../../atoms/SVGIcon';
import IconButton from '../../atoms/IconButton_V2';
import ThemeV2 from '../../../theme';
import { RightDrawerHeaderProps } from './type';
import {
  RightDrawerHeaderContainer,
  LogoContainer,
  StyledHeading,
  StyledLogo,
} from './styles';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { Flex } from '../../../Utils/styles/display';

export const RightDrawerHeader = (props: RightDrawerHeaderProps) => {
  const {
    children,
    title,
    icon,
    imageUrl,
    imageName,
    emoticon,
    emoticonBg,
    onCloseClick,
  } = props;

  return (
    <RightDrawerHeaderContainer
      alignItems="center"
      justifyContent="flex-start"
      padding="16px"
    >
      <IconButton onClick={onCloseClick}>
        <SVGIcon icon="carat-left" size="24px" color={ThemeV2.palette.gray8} />
      </IconButton>
      {icon && (
        <LogoContainer>
          <SVGIcon icon={icon} size="24px" />
        </LogoContainer>
      )}
      {imageUrl && imageName && (
        <LogoContainer>
          <StyledLogo src={imageUrl} alt={imageName} />
        </LogoContainer>
      )}
      {emoticon && emoticonBg && (
        <LogoContainer>
          <EmoticonHolder
            emoticon={emoticon}
            color={emoticonBg}
            size={EmoticonHolderSizes.ExtraSmall}
          />
        </LogoContainer>
      )}
      <StyledHeading variant="h8" weight="regular">
        {title}
      </StyledHeading>
      <Flex flexGrow={1} justifyContent={'right'}>
        {children}
      </Flex>
    </RightDrawerHeaderContainer>
  );
};
