import React from 'react';
import ThemeV2 from '../../../theme';
import { FeedPostWrapper, StyledContentLoader } from './styles';

const FeedPostLoader = () => {
  return (
    <FeedPostWrapper>
      <StyledContentLoader
        speed={2}
        width={476}
        height={600}
        viewBox="0 0 476 600"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="loader"
      >
        <circle cx="36" cy="36" r="20" />
        <rect x="66" y="20" rx="4" ry="4" width="298" height="12" />
        <rect x="66" y="42" rx="4" ry="4" width="173" height="12" />
        <rect x="16" y="102" rx="4" ry="4" width="400" height="16" />
        <rect x="16" y="132" rx="4" ry="4" width="220" height="16" />
        <rect x="16" y="184" rx="4" ry="4" width="444" height="377" />
      </StyledContentLoader>
    </FeedPostWrapper>
  );
};

export default FeedPostLoader;
