import ToolTip from '../ToolTipV2';
import ThemeV2 from '../../../theme';
import { useRef, useState } from 'react';
import {
  gradient,
  NotUpgradedPlanButton,
  TooltipParagraph,
  TooltipWrapper,
  UpgradeTextLink,
  UpgradedPlanButton,
} from './styles';
import { FLOW_SUMMARIZATION } from '../../../languages/en/flows';

const restStateColorConfig = {
  active: {
    background: gradient,
    borderColor: 'none',
    textColor: 'initial',
  },
  background: gradient,
  borderColor: 'none',
  hover: {
    background: '', // Prevent the background from being applied so that the transition works on hover.
    borderColor: 'none',
    textColor: 'initial',
  },
  textColor: ThemeV2.palette.gray1,
};

const goldColorConfig = {
  background: ThemeV2.palette.gold6,
  borderColor: 'none',
  textColor: ThemeV2.palette.gold6,
};

const hoverStateColorConfig = {
  active: goldColorConfig,
  background: ThemeV2.palette.gold6,
  borderColor: 'none',
  hover: goldColorConfig,
  textColor: ThemeV2.palette.gray1,
};

const InactiveDoraButton = ({ isAdmin }: { isAdmin: boolean }) => {
  const ref = useRef(null);
  const [isRest, setIsRest] = useState(true);
  const icon = isRest ? 'sparkles' : 'star';
  const iconBgColor = isRest ? ThemeV2.palette.gray2 : undefined;

  const customColorConfig = isRest
    ? restStateColorConfig
    : hoverStateColorConfig;

  const showHoverState = () => setIsRest(false);

  const navigateToAdminPage = () => {
    window.parent.postMessage(
      { type: 'NAVIGATE_TO_ADMIN', payload: '/billing/account' },
      '*',
    );
  };

  const toolTipComponent = (
    <NotUpgradedPlanButton
      buttonRef={ref}
      customColorConfig={customColorConfig}
      disabled={!isAdmin}
      icon={icon}
      iconBgColor={iconBgColor}
      onBlur={() => setIsRest(true)}
      onClick={navigateToAdminPage}
      onFocus={showHoverState}
      onPointerEnter={showHoverState}
      onPointerLeave={() => {
        if (ref.current !== document.activeElement) {
          setIsRest(true);
        }
      }}
      title="Summarize flow"
      variant="custom"
    />
  );

  return (
    <ToolTip
      enableToolTipHover
      position="bottom"
      toolTipComponent={toolTipComponent}
      toolTipSize="294px"
    >
      <TooltipWrapper>
        <TooltipParagraph>
          {FLOW_SUMMARIZATION.UPGRADE_TOOLTIP}
        </TooltipParagraph>

        <TooltipParagraph>
          {isAdmin ? (
            <>
              {FLOW_SUMMARIZATION.PREMIUM}{' '}
              <UpgradeTextLink onClick={navigateToAdminPage}>
                {FLOW_SUMMARIZATION.UPGRADE_LINK}
              </UpgradeTextLink>{' '}
              {FLOW_SUMMARIZATION.USE_FEATURE}
            </>
          ) : (
            FLOW_SUMMARIZATION.ASK_ADMIN
          )}
        </TooltipParagraph>
      </TooltipWrapper>
    </ToolTip>
  );
};

export const DoraFlowSummariesButton = ({
  hasPosts,
  isAdmin,
  isDisabled,
  isUpgraded,
  onFlowSummariesActionClick,
}: {
  hasPosts: boolean;
  isAdmin: boolean;
  isDisabled: boolean;
  isUpgraded: boolean;
  onFlowSummariesActionClick?: () => void;
}) => {
  if (!isUpgraded) {
    return <InactiveDoraButton isAdmin={isAdmin} />;
  }

  const upgradedButton = (
    <UpgradedPlanButton
      customColorConfig={restStateColorConfig}
      disabled={isDisabled}
      icon="sparkles"
      iconBgColor={ThemeV2.palette.gray2}
      onClick={onFlowSummariesActionClick}
      title="Summarize flow"
      variant="custom"
    />
  );

  if (isDisabled) {
    return (
      <ToolTip
        enableToolTipHover
        position="bottom"
        toolTipComponent={upgradedButton}
        toolTipSize="294px"
      >
        <TooltipWrapper>
          <TooltipParagraph>
            {FLOW_SUMMARIZATION.UPGRADE_TOOLTIP}
          </TooltipParagraph>
          <TooltipParagraph>
            {hasPosts
              ? FLOW_SUMMARIZATION.CANNOT_SUMMARIZE
              : FLOW_SUMMARIZATION.NO_POSTS}
          </TooltipParagraph>
        </TooltipWrapper>
      </ToolTip>
    );
  }

  return upgradedButton;
};
