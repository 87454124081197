import React from 'react';
import Link from '../../atoms/Link_V2';
import SVGIcon from '../../atoms/SVGIcon';
import {
  PrimaryHeaderWrapper,
  LogoContainer,
  StyledLogo,
  CustomHeader,
  HamburgerButton,
} from './styles';
import { PrimaryHeaderProps } from './interface';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';

const PrimaryHeader = (props: PrimaryHeaderProps) => {
  const { path, companyName, companyLogo, toggleLeftAsideOpen, children } =
    props;

  const isLoggedInThroughTeamsMobile =
    microsoftTeamsStore.getState().isLoggedInThroughMobileTeamsApp;

  return (
    <>
      <PrimaryHeaderWrapper>
        <div style={{ display: 'flex', flexGrow: '1' }}>
          {!isLoggedInThroughTeamsMobile && (
            <HamburgerButton onClick={toggleLeftAsideOpen}>
              <SVGIcon icon="hamburger" size="39px" />
            </HamburgerButton>
          )}
          <Link to={path}>
            <div style={{ display: 'flex', marginLeft: '12px' }}>
              <LogoContainer>
                <StyledLogo src={companyLogo} alt={companyName} />
              </LogoContainer>
              <CustomHeader>{companyName}</CustomHeader>
            </div>
          </Link>
        </div>
        <div style={{ display: 'flex' }}>{children}</div>
      </PrimaryHeaderWrapper>
    </>
  );
};

export default PrimaryHeader;
