import ProfanityJSON from '../profanity.json';

import Filter from 'bad-words';

const filter = new Filter({ emptyList: true });
filter.addWords(...ProfanityJSON);

export function checkForProfanity(message: string, wordsToCheck: string[]) {
  // build a set
  const wordsToCheckSet = new Set(wordsToCheck);

  if (wordsToCheckSet.size > 0) {
    const lowercaseMessage = message.toLowerCase();
    const words = lowercaseMessage.split(' ');

    let matchFound = words.some((word) => wordsToCheckSet.has(word));
    if (matchFound) {
      return true;
    }

    matchFound = wordsToCheck.some((blockedWord) =>
      lowercaseMessage.includes(blockedWord),
    );
    if (matchFound) {
      return true;
    }
  }

  return filter.isProfane(message);
}
