import React from 'react';

import Body from '../../atoms/Body';
import { IconTabProps } from './type';
import SVGIcon from '../../atoms/SVGIcon';
import Divider from '../../atoms/Divider';
import { TabPanel } from '../../atoms/Tabs';
import { StyledFlex, StyledTabs } from './styles';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

const IconTab = (props: IconTabProps) => {
  const { items, selectedIndex, onTabChanged } = props;
  return (
    <>
      <StyledTabs
        value={selectedIndex}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        {items.map((item, index) => (
          <StyledFlex
            flex={1}
            role="tab"
            key={index}
            tabIndex={index}
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              onTabChanged(index);
            }}
            isSelected={index === selectedIndex}
          >
            <Flex flexDirection="column">
              <SVGIcon
                size="21px"
                icon={item.icon}
                color={ThemeV2.palette.gray8}
              />
              <Body variant="body3" color={ThemeV2.palette.gray8}>
                {item.label}
              </Body>
            </Flex>
          </StyledFlex>
        ))}
      </StyledTabs>
      <Divider isFullWidth />
      {items.map((item, index) => (
        <TabPanel key={index} value={selectedIndex} index={index}>
          {item.children}
        </TabPanel>
      ))}
    </>
  );
};

export default IconTab;
