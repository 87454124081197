import mobileStore from '../stores/mobileAppStore';

const API_VERSION_V2 = '1.5.0';

const getCacheControl = () => {
  return {
    Pragma: 'no-cache',
  };
};

export const AuthHeaders = () => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      ...getCacheControl(),
    },
  };

  const mobileAuthToken = mobileStore.getState().jwtToken;
  if (import.meta.env.VITE_IS_MOBILE_DEV_MODE && mobileAuthToken) {
    // @ts-ignore
    headers.headers.Authorization = `JWT ${mobileAuthToken}`;
  }

  return headers;
};

export const HeadersWithoutAuth = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      ...getCacheControl(),
    },
  };
};

export const HeadersWithoutAuthV2 = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      clientAPIVersion: API_VERSION_V2,
      ...getCacheControl(),
    },
  };
};

export const AuthHeadersV2 = (enableCache = false) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      XClient:
        window.location.hostname +
        (window.location.port ? `:${window.location.port}` : ''),
      clientAPIVersion: API_VERSION_V2,
      ...(!!enableCache === false ? getCacheControl() : {}),
    },
  };

  const mobileAuthToken = mobileStore.getState().jwtToken;
  if (import.meta.env.VITE_IS_MOBILE_DEV_MODE && mobileAuthToken) {
    // @ts-ignore
    headers.headers.Authorization = `JWT ${mobileAuthToken}`;
  }

  return headers;
};
