import styled from 'styled-components';
import Body from '../../atoms/Body';
import LinkItem from '../../atoms/LinkItem';
import IconInfo from '../../molecules/IconInfo';
import ThemeV2 from '../../../theme';
import GifContainer from '../../molecules/GifContainer';

export const FeedPostBodyRoot = styled.div<{ variant?: 'feed' | 'sidebar' }>`
  padding: ${({ variant }) =>
    variant === 'sidebar' ? '0 16px 6px 4px' : '0 16px 12px 4px'};
`;
FeedPostBodyRoot.displayName = 'FeedPostBodyRoot';

export const FeedPostCoreValue = styled(IconInfo)`
  border-radius: 4px;
  margin-bottom: 12px;
`;
FeedPostCoreValue.displayName = 'FeedPostCoreValue';

export const FeedPostMessage = styled(Body)`
  display: inline-block;
  overflow-wrap: anywhere;
  a {
    &::after {
      content: ' ';
      opacity: 0;
    }

    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
`;
FeedPostMessage.displayName = 'FeedPostMessage';

export const FeedPostHashTag = styled(LinkItem)`
  color: ${ThemeV2.palette.geekBlue6};
`;
FeedPostHashTag.displayName = 'FeedPostHashTag';

export const FeedPostImage = styled(GifContainer)`
  margin-top: 12px;
`;
FeedPostImage.displayName = 'FeedPostImage';
