import { BaseEmoji } from 'emoji-mart';
import { useParams } from 'react-router-dom';
import { useMemo, useEffect, SyntheticEvent, useCallback } from 'react';

import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import { IMemberDTO } from '../../../interfaces/member';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { generateMemberAutocompleteOptions } from '../../../Utils/flows';
import { FLOW_BUILDER_EVENTS } from '../../../Utils/analytics/constants';
import useLayoutStore from '../../../stores/layoutStore';
import {
  trackFlowBuilderErrorEvent,
  trackFlowBuilderActionEvent,
} from '../../../Utils/analytics/flowsBuilder';
import { LayoutStoreState } from '../../../interfaces/Layout';

const layoutSelector = (state: LayoutStoreState) => state.toggleRightAsideOpen;

const useBuilderRightDrawerController = () => {
  const {
    owner,
    description,
    flowName,
    setFlowName,
    setEmoji,
    emoji,
    setDescription,
    setOwner,
    isInEditMode,
    templateId,
    templateName,
    collaborators,
    setCollaborators,
  } = useFlowBuilderStore();
  const {
    models: {
      isLoading,
      searchedMembers,
      value,
      isFetching,
      hasMoreMembers,
      isError: isMemberSearchError,
    },
    operations: { onChange, fetchMoreMembers },
  } = useMembersSearch(true);

  const {
    models: {
      isLoading: isCollaboratorSearchLoading,
      searchedMembers: searchedCollaborators,
      value: collaboratorSelectorValue,
      isFetching: isCollaboratorsFetching,
      hasMoreMembers: hasMoreCollaborators,
    },
    operations: {
      onChange: onCollaboratorInputChange,
      fetchMoreMembers: fetchMoreCollaborators,
    },
  } = useMembersSearch(true);

  const toggleRightAsideOpen = useLayoutStore(layoutSelector);

  const { flowId } = useParams<{ flowId: string }>();

  useEffect(() => {
    if (isMemberSearchError) {
      trackFlowBuilderErrorEvent({
        error: FLOW_BUILDER_EVENTS.FLOW_OWNER_ERROR,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMemberSearchError]);

  const updateCollaborators = useCallback(() => {
    const updatedCollaborators = [...collaborators, ...owner];
    setCollaborators(updatedCollaborators);
  }, [collaborators, owner, setCollaborators]);

  const onAutocompleteChange = (
    val: AutocompleteDropdownItem<string, IMemberDTO>[],
  ) => {
    if (owner.length > 0 && owner[0].title !== 'Deactivated user') {
      updateCollaborators();
    }
    setOwner(val);
    const {
      email = '',
      memberID = '',
      role = [],
    } = val ? val[0]?.item || {} : {};
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_OWNER_CHANGED,
      flowOwnerId: memberID,
      flowOwnerEmail: email,
      flowOwnerPermissions: role,
      flowTitle: flowName,
      templateId,
      templateName,
    });
    onChange('');
  };

  const onCollaboratorsChange = (
    val: AutocompleteDropdownItem<string, IMemberDTO>[],
  ) => {
    setCollaborators(val);
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_COLLABORATOR_UPDATED,
    });
    onCollaboratorInputChange('');
  };

  const memberOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (searchedMembers) {
        return generateMemberAutocompleteOptions(searchedMembers, '').filter(
          (member) => {
            return collaborators.length > 0
              ? collaborators.every(
                  (collaborator) => collaborator?.id !== member?.item?.memberID,
                )
              : true;
          },
        );
      }
      return [];
    }, [collaborators, searchedMembers]);

  const collaboratorsOptions: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (searchedCollaborators) {
        return generateMemberAutocompleteOptions(
          searchedCollaborators,
          owner[0]?.id || '',
          Boolean(owner[0]?.id),
        );
      }
      return [];
    }, [owner, searchedCollaborators]);

  const handleOnSetEmoji = useCallback(
    (selectedEmoji: BaseEmoji) => {
      setEmoji(selectedEmoji);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_EMOJI_ADDED,
        flowEmoji: selectedEmoji?.name,
        templateId,
        templateName,
        flowTitle: flowName,
      });
    },
    [flowName, setEmoji, templateId, templateName],
  );

  const handleOnDescriptionBlur = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_DESCRIPTION_ADDED,
      flowDescription: description,
      flowTitle: flowName,
      templateId,
      templateName,
    });
  }, [description, flowName, templateId, templateName]);

  const handleOnTitleBlur = useCallback(() => {
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.FLOW_TITLE_ADDED,
      flowTitle: flowName,
      templateId,
      templateName,
    });
  }, [flowName, templateId, templateName]);

  const handleMemberOptionsScroll = (event: SyntheticEvent) => {
    const listBoxNode = event.currentTarget;
    if (
      listBoxNode.scrollHeight * 0.7 <=
      listBoxNode.scrollTop + listBoxNode.clientHeight
    ) {
      if (hasMoreMembers && !isFetching) {
        fetchMoreMembers();
      }
      if (hasMoreCollaborators && !isCollaboratorsFetching) {
        fetchMoreCollaborators();
      }
    }
  };

  return {
    isPersonSearchLoading: isLoading,
    personSelectorValue: value,
    onInputChange: onChange,
    toggleRightAsideOpen,
    owner,
    description,
    flowName,
    setFlowName,
    setEmoji: handleOnSetEmoji,
    emoji,
    flowId,
    setDescription,
    onAutocompleteChange,
    memberOptions,
    handleMemberOptionsScroll,
    handleOnTitleBlur,
    handleOnDescriptionBlur,
    isInEditMode,
    collaboratorsOptions,
    collaboratorSelectorValue,
    onCollaboratorInputChange,
    isCollaboratorSearchLoading,
    onCollaboratorsChange,
    collaborators,
  };
};

export default useBuilderRightDrawerController;
