import { TextAlignType } from '../../../atomic/atoms/Body';

export const IconButtonWithToolTipData = {
  FORMAT: 'Show formatting',
  AT_MENTION: 'Mention a teammate',
  EMOTICONS: 'Add an emoji',
  GIF_SELECTOR: 'Add a GIF',
  ATTACHMENT: 'Attachment',
  PRIVATE_MESSAGE: 'Make it private',
  TASKS: 'Add a task',
};

export const PostButtonText = 'Post';
export const PostPrivateButtonText = 'Post Private';

export const EditorPlaceholder = 'What did your coworker(s) do?';

export const MODAL_POST_FORM_HEADING = 'Make it meaningful';

export const REMOVE_GIF_TO_SWITCH = 'Remove current GIF to switch';

export const CORE_VALUE_DEFAULT_TEXT = 'Choose a core value';

export const LEAVE_DRAFT_MODAL_LANGUAGES = {
  heading: 'Save as a draft?',
  // eslint-disable-next-line max-len
  content: `You can always come back and continue filling out this flow later! If you choose to save your answers, they’ll be here when you come back.`,
  primaryButtonText: 'Save draft',
  secondaryButtonText: "Don't save",
  tertiaryButtonText: 'Go back',
};

export const UPDATE_DRAFT_MODAL_LANGUAGES = {
  heading: 'Update saved draft?',
  // eslint-disable-next-line max-len
  content: `Update any changes to your existing draft and continue filling out this flow later! Don’t like your changes? Revert and we’ll restore your previous draft. Updating will save any new changes to the last draft.`,
  primaryButtonText: 'Update & close',
  secondaryButtonText: 'Revert & close',
  tertiaryButtonText: 'Go back',
};

export const CLEAR_DRAFT_MODAL_LANGUAGES = {
  heading: 'Clear drafted answers?',
  subContent: `This will clear all of your answers to all blocks. Are you sure you want to start fresh?`,
  successButtonText: 'Clear all',
  cancelButtonText: 'Cancel',
};

interface LeaveModalInterface {
  heading: string;
  mainContent: string;
  mainContentTextAlign: TextAlignType;
  successButtonText: string;
  cancelButtonText: string;
}

export const LEAVE_EDIT_MODAL_LANGUAGES = <LeaveModalInterface>{
  heading: 'Discard changes?',
  mainContent:
    "Any changes that you've made to your post will not be applied. Discard changes?",
  mainContentTextAlign: 'left',
  successButtonText: 'Yes, discard changes',
  cancelButtonText: 'No, keep editing',
};

export const LEAVE_CREATE_MODAL_LANGUAGES = <LeaveModalInterface>{
  heading: 'Draft will not be saved.',
  mainContent: [
    'Closing this window will not save your draft.',
    'You can finish your post or start your post over again the next time you open this window.',
  ].join(' '),
  mainContentTextAlign: 'left',
  successButtonText: 'Yes, discard post',
  cancelButtonText: 'No, keep editing',
};

export const POST_CREATION_INPROGRESS_MESSAGE = 'Celebration in progress';
export const POST_CREATION_SUCCESS_MESSAGE = 'Celebration successful!';
export const POST_CREATION_ERROR_MESSAGE =
  'Oops! Celebration unsuccessful. Check your connection and try again';
export const POST_UPDATED_SUCCESS_MESSAGE = 'Post updated successfully!';

export const COMMENT_CREATION_ERROR_MESSAGE =
  'Comment post unsuccessful. Check your connection and try again';

export const POST_DELETION_INPROGRESS_MESSAGE = 'Deleting the Post';
export const POST_DELETION_SUCCESS_MESSAGE = 'Post deleted successfully!';
export const POST_DELETION_ERROR_MESSAGE =
  'Oops! Post delete unsuccessful. Check your connection and try again';

export const COMMENT_DELETION_INPROGRESS_MESSAGE = 'Deleting comment';
export const COMMENT_DELETION_SUCCESS_MESSAGE = 'Comment deleted successfully!';
export const COMMENT_DELETION_ERROR_MESSAGE =
  'Oops! Comment delete unsuccessful. Check your connection and try again';

export const MESSAGE_LESS_THAN_LIMIT_ERRORS = [
  `People often say that motivation doesn’t last. Well, neither does a short message –
  we recommend writing a bit more.`,
  `The best things in life are messages a bit longer than this one. Write a bit more.`,
  `Nothing is impossible, the word itself says “I’m possible!" Write a little more.`,
  `Happiness is just sadness that hasn’t happened yet. Let's avoid sadness, write a bit more.`,
  `Write like a proton, always positive – write a bit more.`,
];

export const PROFANE_ERROR_MESSAGE_POST =
  'Please remove profanity to publish post';
export const PROFANE_ERROR_MESSAGE_COMMENT =
  'Please remove profanity to publish comment';
