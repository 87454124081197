import BasicModalTemplate from '../../templates/BasicModalTemplate';
import React from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import rewardsNotFound from '../../../img/rewards-not-found.svg';
import Image from '../../atoms/Image';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import Link from '../../atoms/Link';
import IconButton from '../../atoms/IconButton';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';

type RewardNotFoundModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
};

const RewardNotFoundRoot = styled(BasicModalTemplate)`
  width: 750px;
  min-height: 456px;
  max-width: 750px;
  padding: 0;
`;

const ContentWrapper = styled.section`
  display: grid;
  min-height: 456px;
  grid-template-columns: 1fr 1fr;
`;

const RightPanel = styled(Flex)`
  padding: 0;
  background-color: ${ThemeV2.palette.gray5};
`;

const LeftPanel = styled.div`
  padding: 56px 32px;
`;

const RewardNotFoundModal = (props: RewardNotFoundModalProps) => {
  const { isModalOpen, onModalClose } = props;
  return (
    <RewardNotFoundRoot
      childrenPadding="0"
      isOpen={isModalOpen}
      hasTitleSection={false}
      disableContentScroll={false}
      onModalClose={onModalClose}
    >
      <ContentWrapper>
        <LeftPanel>
          <Image
            src={rewardsNotFound}
            alt="Rewards not found image"
            height="125px"
            width="215px"
          />
          <Heading variant="h5" marginTop="16px">
            This reward cannot be found
          </Heading>
          <Body variant="body2" margin="8px 0">
            Please go back or go to the <Link to={`/rewards`}>Rewards </Link>{' '}
            page to find what you are looking for
          </Body>
        </LeftPanel>
        <RightPanel justifyContent="flex-end" alignItems="flex-start">
          <IconButton onClick={onModalClose}>
            <SVGIcon icon="close" />
          </IconButton>
        </RightPanel>
      </ContentWrapper>
    </RewardNotFoundRoot>
  );
};

export default RewardNotFoundModal;
