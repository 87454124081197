import React, { useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FLOWS_FEEDS } from '../../../constants/routes';
import { trackFeedEvent as trackFeedEventMetric } from '../../../Utils/analytics';
import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { FeedEventNameType } from '../../../Utils/analytics/interfaces';
import { getColorById } from '../../../Utils/flows/colors';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import FlowsFeedHeader from '../../../atomic/molecules/FlowsFeedHeader';
import { useFlowsFeedController } from '../FlowsFeedController/useFlowsFeedController';
import useFlowFeedHeaderControllerLogic from '../FlowFeedHeaderController/useFlowFeedHeaderControllerLogic';
import FullScreenController from '../FullScreenController';

const FlowsFeedsFullScreenController = () => {
  const { push } = useHistory();
  const { flowId } = useParams<{ flowId: string }>();
  const { models } = useFlowFeedHeaderControllerLogic();
  const { data: headerData } = models;
  const {
    feedContextProps,
    feedData,
    isLoading,
    profileData,
    refetchFlowFeed,
  } = useFlowsFeedController();
  const { data } = feedData;
  const emoticonBg = getColorById(flowId);
  const timestampOnLoad = useRef(Date.now());

  const trackFeedEvent = (action: FeedEventNameType, eventData?: any) => {
    trackFeedEventMetric(action, {
      flowId: headerData?.flowId,
      flowName: headerData?.name,
      templateId: headerData?.templateId,
      ...eventData,
    });
  };

  const exitFullScreen = () => {
    const durationInMin = Math.floor(
      (Date.now() - timestampOnLoad.current) / 1000 / 60,
    );
    push(FLOWS_FEEDS.replace(':flowId', flowId));
    trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_EXITED, {
      fullscreenDuration: String(durationInMin),
    });
  };

  return (
    <FullScreenController
      header={
        <FlowsFeedHeader
          title={headerData?.name || ''}
          description={headerData?.description}
          icon={headerData ? mapHexCodeToEmoticon(headerData.icon?.value) : ''}
          backgroundColor={emoticonBg}
          isFullScreen
          onClick={exitFullScreen}
        />
      }
      isLoading={isLoading}
      profileData={profileData}
      responses={data}
      exitFullScreen={exitFullScreen}
      feedContextProps={feedContextProps}
      refetchFeed={refetchFlowFeed}
      trackFeedEvent={trackFeedEvent}
    />
  );
};

export default FlowsFeedsFullScreenController;
