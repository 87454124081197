import React from 'react';

import ThemeV2 from '../../../theme';
import { RECOGNITION_FEEDS_FULLSCREEN } from '../../../constants/routes';
import { PostFilterOptions, PostSortOptions } from '../../../interfaces/Feed';
import { useGetFeedQuery } from '../../../queries/Feed';
import { trackFeedEvent } from '../../../Utils/analytics';
import { FEED_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { defaultFlow } from '../../../Utils/flows';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import FlowsFeedHeader from '../../../atomic/molecules/FlowsFeedHeader';
import { CELEBRATE_YOUR_TEAMMATES } from '../../../languages/en/flows';
import { allFlowFeedHeaderOptions } from '../FlowFeedHeaderController/data';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

type RecognitionFeedHeaderProps = {
  isFullScreen?: boolean;
  onClick?: () => void;
};

const recognitionFeedHeaderOptions = [
  {
    id: 'recognition-feed-header-options',
    items: [allFlowFeedHeaderOptions.enterFullScreen],
  },
];

const RecognitionFeedHeaderController = (props: RecognitionFeedHeaderProps) => {
  const { isFullScreen, onClick } = props;
  const history = useHistoryWrapper();
  const { data: feedData } = useGetFeedQuery(
    PostFilterOptions.ALL,
    PostSortOptions.DESC,
    false,
  );
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const hasMultiplePosts = feedData
    ? feedData.pages?.[0].data?.length > 1
    : false;

  const handleMenuOptionOnClick = (value: string | number) => {
    if (value === allFlowFeedHeaderOptions.enterFullScreen.id) {
      history.push(RECOGNITION_FEEDS_FULLSCREEN);
      trackFeedEvent(FEED_ANALYTICS_EVENTS.FULLSCREEN_ENTERED, {
        flowId: defaultFlow?.flowId,
        flowName: defaultFlow?.name,
      });
    }
  };

  return (
    <FlowsFeedHeader
      title={defaultFlow.name}
      description={CELEBRATE_YOUR_TEAMMATES}
      subTitle={defaultFlow.description}
      backgroundColor={ThemeV2.palette.geekBlue2}
      icon={mapHexCodeToEmoticon(defaultFlow.icon.value)}
      isFullScreen={isFullScreen}
      onClick={onClick}
      menuOptions={
        isFullScreen || !hasMultiplePosts || isMobileView
          ? []
          : recognitionFeedHeaderOptions
      }
      onMenuOptionClick={handleMenuOptionOnClick}
    />
  );
};

export default RecognitionFeedHeaderController;
