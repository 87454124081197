import styled from 'styled-components';

import Body from '../../atoms/Body';
import GifContainer from '../../molecules/GifContainer';
import GiveRecognitionFormBottomButtons from '../../molecules/GiveRecognitionFormBottomButtons';
import {
  GiveRecognitionFormBottomButtonsProps,
  PostType,
} from '../../molecules/GiveRecognitionFormBottomButtons/interface';
import { StyledProps } from './interface';

import ThemeV2 from '../../../theme';
import GiveRecognitionTips from '../../molecules/GiveRecognitionTips';
import { zIndexButton } from '../../../Utils/styles/z-index';
import IconButton from '../../atoms/IconButton_V2';
import Editor from '../RichTextEditor';

export const StyledFormButtons = styled(
  GiveRecognitionFormBottomButtons,
)<GiveRecognitionFormBottomButtonsProps>`
  padding: 8px 0px 0px 8px;
  background: ${({ isEmoticonsButtonHidden }) =>
    isEmoticonsButtonHidden && ThemeV2.palette.gray2};
`;

export const TooltipWrapper = styled.div`
  width: 162px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const CommentWrapper = styled.div<
  StyledProps & { variant?: 'feed' | 'sidebar' }
>`
  border-radius: ${({ variant }) => (variant === 'sidebar' ? `5px` : '20px')};
  border: ${({ postType }) =>
    postType === PostType.Comment ? `1px solid ${ThemeV2.palette.gray5}` : '0'};
  padding: ${({ postType, isEditorFocused, hasText, hasAttachment }) => {
    if (postType === PostType.Comment) {
      return isEditorFocused || hasText || hasAttachment ? '4px' : '4px';
    }
    return '0';
  }};
  transition: 0.2s ease;
  background: ${({ isDisabled }) => isDisabled && ThemeV2.palette.gray2};
`;

export const StyledGifContainer = styled(GifContainer)`
  margin-bottom: 8px;
`;

export const EditorGifContainer = styled(GifContainer)`
  width: 178px;
  height: 100px;

  // X icon container
  div:nth-child(2) {
    top: 4px;
    right: 4px;
  }
`;

export const StyledBody = styled(Body)`
  padding-left: 8px;
`;

export const StyledGiveRecognitionTips = styled(GiveRecognitionTips)`
  margin-top: 16px;
`;

export const RecognitionSectionWrapper = styled.div``;

export const RecognitionEditorWrapper = styled.div`
  min-height: 200px;
  cursor: text;
`;

export const InputErrorMessageBody = styled(Body)`
  padding: 0 16px;
`;

export const StyledIconButton = styled(IconButton)`
  z-index: ${zIndexButton};
`;

export const PostIconButton = styled(IconButton)<{ useSpacing?: boolean }>`
  margin-top: ${({ useSpacing }) => (useSpacing ? '6px' : '0')};
  z-index: ${zIndexButton};
`;

export const SaveButton = styled(PostIconButton)`
  background: ${ThemeV2.palette.green7};

  &:hover {
    background: ${ThemeV2.palette.green7} !important;
  }
`;

export const CancelButton = styled(PostIconButton)`
  background: ${ThemeV2.palette.red6};
  margin-right: 8px;

  &:hover {
    background: ${ThemeV2.palette.red6} !important;
  }
`;

export const RepliesEditor = styled(Editor)`
  overflow-y: auto;
  min-height: ${({ hasGifSelected }) => (hasGifSelected ? `80px` : '50px')};
  max-height: 350px;
`;
