import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import DropdownIconButton from '../DropdownIconButton';

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  max-width: 500px;
  width: 100%;
  height: 80px;
  background-color: ${ThemeV2.palette.gray1};

  transition: all 200ms ease-in-out;

  &:hover {
    transform: scale(1.015);
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
`;

export const CardImage = styled.div<{
  backgroundColor: string;
}>`
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-grow: 1;

  height: 80px;
  width: 100%;
  padding: 8px 12px;
  overflow: hidden;
  border: 1px solid ${ThemeV2.palette.gray4};
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const StyledDropdownIconButton = styled(DropdownIconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 8px;

  > button {
    border-radius: 8px;
  }
`;

export const NewStyledBody = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
