import cloneDeep from 'lodash/cloneDeep';
import { RequireExactlyOne } from 'type-fest';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { EmailNotificationGlobalSettings } from '../../interfaces/UserSettings';
import {
  GET_USER_INFO,
  UPDATE_USER_SETTINGS,
  GET_USER_LOCATION,
  GET_MEMBER_NOTIFICATION_PREFERENCES,
  UPDATE_MEMBER_NOTIFICATION_PREFERENCES,
  GET_PROFILE_INFO,
  UPDATE_PROFILE_PICTURE,
  DEACTIVATE_MEMBER,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { EmoticonResponse } from '../Flows/interfaces';
import { GET_MEMBERS_INFINITE } from '../Members/utils';
import { AxiosResponse } from 'axios';
import { GetProfileInfoResponse } from '../Profile';

type NotificationRequestPayload = {
  email: {
    entities: {
      entityId: string;
      entityType: 'FLOW';
      type: FlowsNotificationItemPreferenceTypes;
      value: boolean;
    }[];
  };
  mobile: {
    entities: {
      entityId: string;
      entityType: 'FLOW';
      type: FlowsNotificationItemPreferenceTypes;
      value: boolean;
    }[];
  };
};

export interface Timezone {
  name: string | undefined;
}
export interface User {
  _id: string;
  email: string;
  profile: {
    department: string;
    firstName: string;
    lastName: string;
    managers: string[];
    reports: string[];
    username: string;
    image?: {
      original?: {
        relativeUrl: string;
      };
      resized?: {
        relativeUrl: string;
      };
    };
    birthday?: {
      date?: number;
      monthNumber?: number;
      month?: string;
    };
    hiredday?: {
      date?: number;
      monthNumber?: number;
      month?: string;
      year?: number;
    };
  };
  settings: {
    emailPreferences: EmailNotificationGlobalSettings;
    timeZone?: Timezone;
  };
  isDeleted?: boolean;
}

export interface UserInfoResponse {
  data: {
    user: User;
  };
}

export interface UpdateUserInfoPayload {
  firstName: string;
  lastName: string;
  managers: string[];
  reports: string[];
  department: string;
  file?: string;
  fileName?: string;
  fileType?: string;
  notifyActivity: boolean;
  notifyAllowance: boolean;
  notifyAnniversary: boolean;
  notifyComments: boolean;
  notifyNewCarrots: boolean;
}

export enum FlowsNotificationItemPreferenceTypes {
  FLOW_TRIGGERED = 'flow_triggered',
  FLOW_REMINDER = 'flow_reminder',
  FLOW_RESPONSE = 'flow_response',
}

export type FlowsNotificationItem = {
  entityId: string;
  entityType: 'FLOW';
  name: string;
  icon: EmoticonResponse;
  preferences: {
    type: FlowsNotificationItemPreferenceTypes;
    value: boolean;
  }[];
};

export type FlowsNotificationResponse = {
  email: {
    entities: {
      flows: FlowsNotificationItem[];
    };
    global: {
      [key: string]: boolean;
    };
  };
  mobile: {
    entities: {
      flows: FlowsNotificationItem[];
    };
    global: {
      [key: string]: boolean;
    };
  };
};

export type FlowsNotificationPayload = RequireExactlyOne<
  NotificationRequestPayload,
  'email' | 'mobile'
>;

export type GlobalNotificationPayload = {
  email?: {
    global: {
      mentions?: boolean;
      task_activity?: boolean;
      ASSEMBLY_SUMMARY_LOOK_AHEAD?: boolean;
      ASSEMBLY_SUMMARY_LOOK_BACK?: boolean;
    };
  };
  mobile?: {
    global: {
      mentions?: boolean;
      task_activity?: boolean;
      ASSEMBLY_SUMMARY_LOOK_AHEAD?: boolean;
      ASSEMBLY_SUMMARY_LOOK_BACK?: boolean;
    };
  };
};

export type DeactivatedUserInfo = {
  errors: string[];
  memberIds: string[];
};

export type DeactivatedMemberProps = {
  isDeactivateMemberSuccess: boolean;
  isDeactivateMemberFailed: boolean;
  deactivatedMemberData: AxiosResponse<DeactivatedUserInfo> | undefined;
  deactivatedMemberErrorData: unknown;
  isDeactivatingMember?: boolean;
};

export enum ProfileEntityEnum {
  HomeLocation = 'HOME_LOCATION',
  WorkLocation = 'WORK_LOCATION',
  JobTitle = 'JOB_TITLE',
}

export type UserProfileLocationInfo = {
  data: [
    {
      key: ProfileEntityEnum.HomeLocation;
      values: string[];
    },
    {
      key: ProfileEntityEnum.WorkLocation;
      values: string[];
    },
    {
      key: ProfileEntityEnum.JobTitle;
      values: string[];
    },
  ];
};

export const useUserInfoQuery = () => {
  return useQuery(
    [GET_USER_INFO],
    () => makeAPICallWithDataReturn(GET_USER_INFO),
    {
      select: (res: UserInfoResponse) => res.data,
      staleTime: Infinity,
    },
  );
};

export const useGetFlowNotificationPreferencesQuery = () => {
  return useQuery(
    [GET_MEMBER_NOTIFICATION_PREFERENCES],
    () => makeAPICallWithDataReturn(GET_MEMBER_NOTIFICATION_PREFERENCES),
    {
      select: (res: FlowsNotificationResponse) => res,
      staleTime: Infinity,
    },
  );
};

export const useUpdateFlowNotificationPreferencesQuery = () => {
  return useQuery([UPDATE_MEMBER_NOTIFICATION_PREFERENCES], () =>
    makeAPICallWithDataReturn(UPDATE_MEMBER_NOTIFICATION_PREFERENCES),
  );
};

export const updateUserInfo = (
  previousUserInfo: UserInfoResponse,
  newSettings: UpdateUserInfoPayload,
) => {
  const updatedUserInfo = cloneDeep(previousUserInfo);

  const {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  } = newSettings;
  updatedUserInfo.data.user.settings.emailPreferences = {
    notifyActivity,
    notifyAllowance,
    notifyAnniversary,
    notifyComments,
    notifyNewCarrots,
  };

  return updatedUserInfo;
};

export const updateTimeZoneInfo = (
  previousUserInfo: UserInfoResponse,
  updatedTimeZone: { timeZone: string },
) => {
  const updatedUserInfo = cloneDeep(previousUserInfo);
  updatedUserInfo.data.user.settings.timeZone = {
    name: updatedTimeZone.timeZone,
  };
  return updatedUserInfo;
};

export const useUpdateTimeZoneMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { timeZone: string }) =>
      makeAPICall(UPDATE_USER_SETTINGS, payload),
    {
      onMutate: async (updatedTimeZone: { timeZone: string }) => {
        await queryClient.cancelQueries([GET_USER_INFO]);

        const previousUserInfo = queryClient.getQueryData<UserInfoResponse>([
          GET_USER_INFO,
        ]);

        if (previousUserInfo) {
          const newUserInfo = updateTimeZoneInfo(
            previousUserInfo,
            updatedTimeZone,
          );
          queryClient.setQueryData([GET_USER_INFO], newUserInfo);
        }
        return { previousUserInfo };
      },
      onSuccess: () => {
        queryClient.refetchQueries([GET_PROFILE_INFO]);
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData([GET_USER_INFO], context.previousUserInfo);
        }
      },
    },
  );
};

export const useUserSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateUserInfoPayload) =>
      makeAPICall(UPDATE_USER_SETTINGS, payload),
    {
      onMutate: async (newSettings: UpdateUserInfoPayload) => {
        await queryClient.cancelQueries([GET_USER_INFO]);

        const previousUserInfo = queryClient.getQueryData<UserInfoResponse>([
          GET_USER_INFO,
        ]);

        if (previousUserInfo) {
          const newUserInfo = updateUserInfo(previousUserInfo, newSettings);
          queryClient.setQueryData([GET_USER_INFO], newUserInfo);
        }

        return {
          previousUserInfo,
          previousMemberInfo: queryClient.getQueryData<GetProfileInfoResponse>([
            GET_PROFILE_INFO,
          ]),
        };
      },
      onSuccess: (_, payload, context) => {
        queryClient.refetchQueries([GET_PROFILE_INFO]);

        const messageData: { firstName?: string; lastName?: string } = {};

        if (
          payload.firstName !==
          context?.previousMemberInfo?.member.profile.firstName
        ) {
          messageData.firstName = payload.firstName;
        }

        if (
          payload.lastName !==
          context?.previousMemberInfo?.member.profile.lastName
        ) {
          messageData.lastName = payload.lastName;
        }
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData([GET_USER_INFO], context.previousUserInfo);
        }
      },
    },
  );
};

export const useUserProfilePictureMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateUserInfoPayload) =>
      makeAPICall(UPDATE_PROFILE_PICTURE, { fileName: payload.fileName }),
    {
      onMutate: async (newSettings: UpdateUserInfoPayload) => {
        await queryClient.cancelQueries([GET_USER_INFO]);

        const previousUserInfo = queryClient.getQueryData<UserInfoResponse>([
          GET_USER_INFO,
        ]);

        if (previousUserInfo) {
          const newUserInfo = updateUserInfo(previousUserInfo, newSettings);
          queryClient.setQueryData([GET_USER_INFO], newUserInfo);
        }
        return { previousUserInfo };
      },
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROFILE_INFO]);
        queryClient.invalidateQueries([GET_MEMBERS_INFINITE]);
      },
      onError: (err, variables, context) => {
        if (context?.previousUserInfo) {
          queryClient.setQueryData([GET_USER_INFO], context.previousUserInfo);
        }
      },
    },
  );
};

const updateFlowPreferences = (
  previousFlowSettings: FlowsNotificationResponse,
  newSettings: FlowsNotificationPayload,
) => {
  const flowSettings = cloneDeep(previousFlowSettings);
  if (newSettings && newSettings.mobile) {
    const updatingFlowItem = flowSettings.mobile.entities.flows.find(
      (item) => item.entityId === newSettings?.mobile?.entities[0].entityId,
    );

    const updatingFlowItemPreference = updatingFlowItem?.preferences.find(
      (preference) => preference.type === newSettings?.mobile?.entities[0].type,
    );

    if (updatingFlowItemPreference) {
      updatingFlowItemPreference.value = newSettings.mobile.entities[0].value;
    }
  }

  if (newSettings && newSettings.email) {
    const updatingFlowItem = flowSettings.email.entities.flows.find(
      (item) => item.entityId === newSettings?.email?.entities[0].entityId,
    );

    const updatingFlowItemPreference = updatingFlowItem?.preferences.find(
      (preference) => preference.type === newSettings?.email?.entities[0].type,
    );

    if (updatingFlowItemPreference) {
      updatingFlowItemPreference.value = newSettings.email.entities[0].value;
    }
  }
  return flowSettings;
};

export const useFlowsEmailSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: FlowsNotificationPayload) =>
      makeAPICall(UPDATE_MEMBER_NOTIFICATION_PREFERENCES, payload),
    {
      onMutate: (newSettings: FlowsNotificationPayload) => {
        const previousFlowSettings =
          queryClient.getQueryData<FlowsNotificationResponse>([
            GET_MEMBER_NOTIFICATION_PREFERENCES,
          ]);

        if (previousFlowSettings) {
          const updatedFlowEmailPreference = updateFlowPreferences(
            previousFlowSettings,
            newSettings,
          );
          queryClient.setQueryData(
            [GET_MEMBER_NOTIFICATION_PREFERENCES],
            updatedFlowEmailPreference,
          );
        }

        return { previousFlowSettings };
      },
      onError: (err, variables, context) => {
        if (context?.previousFlowSettings) {
          queryClient.setQueryData(
            [GET_MEMBER_NOTIFICATION_PREFERENCES],
            context.previousFlowSettings,
          );
        }
      },
    },
  );
};

export const useGlobalEmailSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: GlobalNotificationPayload) =>
      makeAPICall(UPDATE_MEMBER_NOTIFICATION_PREFERENCES, payload),
    {
      onMutate: (payload) => {
        const previousGlobalSettings =
          queryClient.getQueryData<FlowsNotificationResponse>([
            GET_MEMBER_NOTIFICATION_PREFERENCES,
          ]);

        const updatingGlobalSettings = cloneDeep(previousGlobalSettings);
        if (updatingGlobalSettings && payload.mobile) {
          if ('mentions' in payload.mobile.global) {
            if (
              updatingGlobalSettings.mobile.global.mentions !==
              payload.mobile.global.mentions
            ) {
              updatingGlobalSettings.mobile.global.mentions =
                !updatingGlobalSettings.mobile.global.mentions;
            }
          }

          if ('task_activity' in payload.mobile.global) {
            if (
              updatingGlobalSettings.mobile.global.tasks !==
              payload.mobile.global.task_activity
            ) {
              updatingGlobalSettings.mobile.global.tasks =
                !updatingGlobalSettings.mobile.global.tasks;
            }
          }

          if ('ASSEMBLY_SUMMARY_LOOK_AHEAD' in payload.mobile.global) {
            if (
              updatingGlobalSettings.mobile.global.assemblySummaryLookAhead !==
              payload.mobile.global.ASSEMBLY_SUMMARY_LOOK_AHEAD
            ) {
              updatingGlobalSettings.mobile.global.assemblySummaryLookAhead =
                !updatingGlobalSettings.mobile.global.assemblySummaryLookAhead;
            }
          }

          if ('ASSEMBLY_SUMMARY_LOOK_BACK' in payload.mobile.global) {
            if (
              updatingGlobalSettings.mobile.global.assemblySummaryLookBack !==
              payload.mobile.global.ASSEMBLY_SUMMARY_LOOK_BACK
            ) {
              updatingGlobalSettings.mobile.global.assemblySummaryLookBack =
                !updatingGlobalSettings.mobile.global.assemblySummaryLookBack;
            }
          }

          queryClient.setQueryData(
            [GET_MEMBER_NOTIFICATION_PREFERENCES],
            updatingGlobalSettings,
          );
        }

        if (updatingGlobalSettings && payload.email) {
          if ('mentions' in payload.email.global) {
            if (
              updatingGlobalSettings.email.global.mentions !==
              payload.email.global.mentions
            ) {
              updatingGlobalSettings.email.global.mentions =
                !updatingGlobalSettings.email.global.mentions;
            }
          }

          if ('task_activity' in payload.email.global) {
            if (
              updatingGlobalSettings.email.global.tasks !==
              payload.email.global.task_activity
            ) {
              updatingGlobalSettings.email.global.tasks =
                !updatingGlobalSettings.email.global.tasks;
            }
          }

          if ('ASSEMBLY_SUMMARY_LOOK_AHEAD' in payload.email.global) {
            if (
              updatingGlobalSettings.email.global.assemblySummaryLookAhead !==
              payload.email.global.ASSEMBLY_SUMMARY_LOOK_AHEAD
            ) {
              updatingGlobalSettings.email.global.assemblySummaryLookAhead =
                !updatingGlobalSettings.email.global.assemblySummaryLookAhead;
            }
          }

          if ('ASSEMBLY_SUMMARY_LOOK_BACK' in payload.email.global) {
            if (
              updatingGlobalSettings.email.global.assemblySummaryLookBack !==
              payload.email.global.ASSEMBLY_SUMMARY_LOOK_BACK
            ) {
              updatingGlobalSettings.email.global.assemblySummaryLookBack =
                !updatingGlobalSettings.email.global.assemblySummaryLookBack;
            }
          }

          queryClient.setQueryData(
            [GET_MEMBER_NOTIFICATION_PREFERENCES],
            updatingGlobalSettings,
          );
        }

        return { previousGlobalSettings };
      },
      onError: (err, variables, context: any) => {
        if (context?.previousGlobalSettings) {
          queryClient.setQueryData(
            [GET_MEMBER_NOTIFICATION_PREFERENCES],
            context.previousGlobalSettings,
          );
        }
      },
    },
  );
};

export const useDeactivateMember = () => {
  return useMutation(() => makeAPICall(DEACTIVATE_MEMBER));
};

export function useGetUserLocation(payload: string[]) {
  return useQuery<UserProfileLocationInfo>([GET_USER_LOCATION], () =>
    makeAPICallWithDataReturn(GET_USER_LOCATION, { keywords: payload }),
  );
}
