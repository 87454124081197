import React from 'react';
import { SwitchText } from './data';

import { Slider, StyledInput, StyledLabel } from './styles';
import { SwitchTextTypes, ToggleSwitchProps } from './types';

const ToggleSwitch = ({
  id,
  isOn = true,
  onChange,
  onChangeWithEvent,
  disabled = false,
  className,
  dataTestId,
  dataQaId,
  textType = SwitchTextTypes.YES_NO,
}: ToggleSwitchProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (onChangeWithEvent) {
      onChangeWithEvent(e);
    } else {
      onChange(id);
    }
  };

  return (
    <StyledLabel
      data-testid="toggle-switch"
      data-qa-id="btn-toggle-switch"
      htmlFor={id}
      className={className}
    >
      <StyledInput
        checked={isOn}
        id={id}
        onChange={handleChange}
        type="checkbox"
        disabled={disabled}
        trueText={SwitchText[textType].true}
        data-testid={dataTestId}
        data-qa-id={dataQaId}
      />
      <Slider
        disabled={disabled}
        falseText={SwitchText[textType].false}
        textType={textType}
      />
    </StyledLabel>
  );
};

export default ToggleSwitch;
