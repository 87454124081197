import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { CreditState } from '../../../interfaces/assembly';
import { UserStatus } from '../../../interfaces/user';
import { AssemblyPlanType } from '../../../interfaces/UserProfile';
import { useNotificationFetchQuery } from '../../../queries/Notifications';
import {
  useCurrentPlanFetchQuery,
  useGetQuickSetupGuideQuery,
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import {
  canCurrentUserGiveAllowance,
  getQuickSetupProgress,
  isUserAdmin,
} from '../../../Utils/user';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import { SplitNames } from '../../../hooks/useSplitSdkConfig/constants';

const usePrimaryNavModel = () => {
  const { pathname } = useLocation();
  const { data: notificationsData } = useNotificationFetchQuery();
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();
  const { setLeftAsideOpenToTrue, setLeftAsideOpenToFalse } = useLayoutStore(
    (state) => ({
      setLeftAsideOpenToTrue: state.setLeftAsideOpenToTrue,
      setLeftAsideOpenToFalse: state.setLeftAsideOpenToFalse,
    }),
    shallow,
  );

  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });
  const { treatment: hideV2DirectMessaging } = useFeatureSplit(
    SplitNames.HideV2DirectMessaging,
  );
  useEffect(() => {
    if (profileData?.member) {
      if (profileData.member.profile.showLeftDrawer && isDesktopView) {
        setLeftAsideOpenToTrue();
      } else {
        setLeftAsideOpenToFalse();
      }
    }
  }, [
    isDesktopView,
    profileData?.member,
    setLeftAsideOpenToTrue,
    setLeftAsideOpenToFalse,
  ]);

  const hideAllowanceDetails = () => {
    if (!profileData?.member) {
      return true;
    }
    return !canCurrentUserGiveAllowance(profileData.member);
  };

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;
  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const hideRewardsInfo = () => {
    if (!profileData?.member) {
      return true;
    }
    return (
      (profileData.member.status === UserStatus.OBSERVER ||
        profileData.member.status === UserStatus.GIVER) &&
      profileData.member.pointsLeftThisCycle === 0
    );
  };

  const { data: currentPlanData } = useCurrentPlanFetchQuery();

  const isCurrentUserAdmin = profileData?.member
    ? isUserAdmin(profileData?.member)
    : false;

  const showQuickSetupOption =
    (isCurrentUserAdmin && profileData?.member.profile.showQuickSetupPanel) ||
    false;

  const { data: quickSetupData, isInitialLoading: isQuickSetupDataLoading } =
    useGetQuickSetupGuideQuery(
      isCurrentUserAdmin ? profileData?.member?.memberId : '',
    );

  const isPremiumUser =
    currentPlanData && currentPlanData.name !== AssemblyPlanType.FREE;

  const quickSetupProgress = () => {
    if (quickSetupData && isPremiumUser !== undefined) {
      return getQuickSetupProgress(quickSetupData, isPremiumUser);
    }
    return 0;
  };

  return {
    models: {
      hideFlowsList: false,
      hideParticipantsList: hideV2DirectMessaging === 'on',
      unreadNotificationsCount:
        notificationsData?.pages[0].data.notificationCount || 0,
      pathname,
      currentUserData: profileData?.member,
      hideRedeemButton: !isRedeemable,
      assemblyData: profileData?.assembly,
      hideAllowanceDetails: hideAllowanceDetails(),
      hideRewardsLink: hideRewardsLink(),
      hideRewardsInfo: hideRewardsInfo(),
      isCurrentUserAdmin,
      quickSetupData,
      isQuickSetupDataLoading,
      isPremiumUser,
      quickSetupProgress: quickSetupProgress(),
      showQuickSetupOption,
    },
  };
};

export default usePrimaryNavModel;
