import ContentLoader from 'react-content-loader';
import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../theme';

const Wrapper = styled.div`
  display: flex;
  /* align-items: flex-start;
  padding: 0px 12px 0px 24px;
  margin-top: -4px;
  width: 100%; */
`;

const MenuItemLoader = ({
  className,
  isExpanded = false,
}: {
  className?: string;
  isExpanded?: boolean;
}) => {
  if (!isExpanded) {
    return (
      <Wrapper className={className}>
        <ContentLoader
          speed={2}
          width={142}
          height={40}
          viewBox="0 0 142 40"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
          uniqueKey="MenuItemLoader-loader"
        >
          <rect x="4" y="14" rx="12" width="24" height="24" />
        </ContentLoader>
      </Wrapper>
    );
  }
  return (
    <Wrapper className={className}>
      <ContentLoader
        speed={2}
        width={142}
        height={40}
        viewBox="0 0 142 40"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="members-list-entry-loader"
      >
        <circle cx="12" cy="20" r="12" />
        <rect x="34" y="14" rx="4" width="94" height="12" />
      </ContentLoader>
    </Wrapper>
  );
};

const MemoizedMenuItemLoader = React.memo(MenuItemLoader);
MemoizedMenuItemLoader.displayName = 'MenuItemLoader';
export default MemoizedMenuItemLoader;
