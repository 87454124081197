import { SETUP_PRELOADED_FLOWS_LOADING_INFO } from '../../../languages/en/flows';
import Body from '../../atoms/Body';
import Heading from '../../atoms/Heading';
import LoadingPulse from '../../atoms/LoadingPulse';
import { CardContainer, StyledGifContainer } from './styles';

const WorkspaceSetupContainer = (props: {
  title: string;
  marginTop?: string;
}) => {
  return (
    <CardContainer marginTop={props.marginTop}>
      <Heading variant="h5">{props.title}</Heading>
      <Body variant="body2">{SETUP_PRELOADED_FLOWS_LOADING_INFO}</Body>
      <StyledGifContainer src="https://media2.giphy.com/media/SWVzkIlHdEckF81gnA/200.gif" />
      <LoadingPulse size={32} />
    </CardContainer>
  );
};

export default WorkspaceSetupContainer;
