import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  GET_PROFILE_INFO,
  GET_CURRENT_PLAN,
  GET_REDEEM_INFO,
  QUICK_SETUP_GUIDE,
  UPDATE_PROFILE_INFO,
  UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS,
  GET_IDENTITY_MANAGER_DATA,
} from '../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { Assembly as AssemblyType } from '../../interfaces/assembly';
import { CurrentUser } from '../../interfaces/currentUser';
import { QuickSetupData } from '../../interfaces/user';
import { AssemblyPlanType } from '../../interfaces/UserProfile';
import { ResponseError } from '../../interfaces/ResponseError';
import { GifRatingsProps } from '../../atomic/atoms/Giphy/interface';
import { setAssemblyFlowPermissionValue } from './utils';
import { showErrorMessage, showSuccessMessage } from '../../Utils/toast';
import { FLOWS_PERMISSIONS_TEXTS } from '../../languages/en/settings/admin/flows';
import { IdentityManagerResponse } from '../../interfaces/identityManager/common';

export interface GetProfileInfoResponse {
  member: CurrentUser;
  assembly: AssemblyType;
}

export interface CurrentPlanResponse {
  name: AssemblyPlanType;
  features: {
    category: string;
    id: string;
    isPaymentRequired: boolean;
    status: string;
    value: number;
  }[];
  uniqueId?: string;
}

interface RedeemInfoResponse {
  isRedeemable: boolean;
  hasCharities: boolean;
  hasGiftCards: boolean;
}

interface QuickSetupGuideResponse {
  data: QuickSetupData;
}

export interface UpdateProfileQuickSetupPayload {
  showQuickSetupPanel: boolean;
}

export type UpdateFlowsPermissionsPayload = {
  creationPermission?: { value?: string };
  gifAccessibility?: { value: GifRatingsProps };
  criteriaAccessibilityForParticipation?: {
    everyone: Array<string | undefined>;
  };
  stackAccessibility?: {
    GIVE_POINTS_STACK: Array<string | undefined>;
  };
};

export const useCurrentPlanFetchQuery = (makeRequest = true) => {
  return useQuery<CurrentPlanResponse>(
    [GET_CURRENT_PLAN],
    () => makeAPICallWithDataReturn(GET_CURRENT_PLAN),
    {
      staleTime: Infinity,
      enabled: makeRequest,
    },
  );
};

export const useGetIdentityManagerQuery = (enabled = true) => {
  return useQuery<IdentityManagerResponse>(
    [GET_IDENTITY_MANAGER_DATA],
    () => makeAPICallWithDataReturn(GET_IDENTITY_MANAGER_DATA),
    {
      enabled,
    },
  );
};

export const useProfileInfoFetchQuery = (makeRequest = true) => {
  return useQuery<GetProfileInfoResponse, ResponseError>(
    [GET_PROFILE_INFO],
    () => makeAPICallWithDataReturn(GET_PROFILE_INFO),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useRedeemInfoFetchQuery = (makeRequest = true) => {
  return useQuery<RedeemInfoResponse>(
    [GET_REDEEM_INFO],
    () => makeAPICallWithDataReturn(GET_REDEEM_INFO),
    {
      staleTime: 60 * 30 * 1000,
      enabled: makeRequest,
    },
  );
};

export const useGetQuickSetupGuideQuery = (employeeID = '') => {
  return useQuery(
    [QUICK_SETUP_GUIDE],
    () => makeAPICallWithDataReturn(QUICK_SETUP_GUIDE),
    {
      staleTime: Infinity,
      select: (res: QuickSetupGuideResponse) => res.data,
      enabled: Boolean(employeeID),
      refetchOnMount: 'always',
    },
  );
};

export const useProfileInfoQuickSetupMutate = (successCallback: () => void) => {
  return useMutation(
    (payload: UpdateProfileQuickSetupPayload) =>
      makeAPICall(UPDATE_PROFILE_INFO, payload),
    {
      onSuccess: successCallback,
    },
  );
};

export const useUpdateFlowSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateFlowsPermissionsPayload) =>
      makeAPICall(UPDATE_FLOW_PERMISSIONS_AND_GIF_SETTINGS, payload),
    {
      onSuccess: (data, payload) => {
        showSuccessMessage(
          FLOWS_PERMISSIONS_TEXTS.PERMISSION_CHANGED_SUCCESSFULLY,
        );
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData([GET_PROFILE_INFO]);
        if (!cacheObject) {
          return;
        }
        if (!cacheObject.assembly.flow) {
          return;
        }
        const updatedCacheObject = { ...cacheObject };
        if (!updatedCacheObject.assembly.flow) {
          return;
        }
        updatedCacheObject.assembly.flow.creation.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.creation.value,
            payload.creationPermission?.value,
          );
        updatedCacheObject.assembly.flow.participationCriteriaAccessibility.everyone.splice(
          0,
          1,
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.participationCriteriaAccessibility
              .everyone[0],
            payload.criteriaAccessibilityForParticipation?.everyone[0],
          ),
        );
        updatedCacheObject.assembly.flow.contentStackAccessibility.GIVE_POINTS_STACK.splice(
          0,
          1,
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.contentStackAccessibility
              .GIVE_POINTS_STACK[0],
            payload.stackAccessibility?.GIVE_POINTS_STACK[0],
          ),
        );
        updatedCacheObject.assembly.flow.gifAccessibility.value =
          setAssemblyFlowPermissionValue(
            updatedCacheObject.assembly.flow.gifAccessibility.value,
            payload.gifAccessibility?.value,
          ) as GifRatingsProps;
        queryClient.setQueryData([GET_PROFILE_INFO], updatedCacheObject);
      },
      onError: () => {
        showErrorMessage(FLOWS_PERMISSIONS_TEXTS.PERMISSION_CHANGE_ERROR);
        const cacheObject: GetProfileInfoResponse | undefined =
          queryClient.getQueryData([GET_PROFILE_INFO]);
        queryClient.setQueryData([GET_PROFILE_INFO], cacheObject);
      },
    },
  );
};
