import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { showErrorMessage } from '../../Utils/toast';

type RedirectStoreState = {
  path: string;
  setPath: (path: string) => void;
  clearPath: () => void;
};
const useRedirectStore = create<RedirectStoreState>()(
  persist(
    (set) => ({
      path: '',
      setPath: (path: string) => {
        try {
          set(() => ({ path }));
        } catch (error: any) {
          if (error?.message?.includes('Failed to execute')) {
            showErrorMessage(
              'Error logging in. Please clear your browser cache before logging in again.',
            );
          }
        }
      },
      clearPath: () => {
        set(() => ({ path: '' }));
      },
    }),
    {
      name: 'path',
    },
  ),
);

export default useRedirectStore;
