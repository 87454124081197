import introJS from 'intro.js';
import { useEffect, useMemo } from 'react';

import { useFeatureIntrosStore } from '../../stores/featureIntrosStore';
import { featureIntrosSelector } from '../../stores/featureIntrosStore/selectors';

import { useUpdateFeatureIntrosQuery } from '../../queries/FeatureIntros';

import { isDesktopPlatform } from '../../Utils/window';
import { TourStatusEnum } from '../../Utils/featureIntros/types';

import { getShareSheetHintOptions } from '../../Utils/featureIntros/getShareSheetIntroStrings';
import { getFlowsFeedIntroOptions } from '../../Utils/featureIntros/getFlowFeedIntroSteps';

export enum FeatureIntro {
  FLOWS_FEED = 'FLOWS_FEED',
}

export enum FeatureHint {
  ACCESS_POLICY_SHARE_BUTTON = 'ACCESS_POLICY_SHARE_BUTTON',
}

export type FeatureIntroProps = {
  feature: FeatureIntro;
  enabled?: boolean;
  delay?: number;
  onIntroJsClose?: () => void;
};

export type FeatureHintProps = {
  feature: FeatureHint;
  enabled?: boolean;
  delay?: number;
};

export type FeatureIntroOptions = {
  getStepDetails: () => introJS.Step[];
  toolTipPosition?: string;
  doneLabel?: string;
  hidePrev?: boolean;
  showBullets?: boolean;
  prevLabel?: string;
  tooltipClass?: string;
  scrollToElement?: boolean;
};

export type FeatureHintOptions = {
  getHintDetails: () => introJS.Hint[];
  hintPosition?: string;
  tooltipPosition?: string;
  buttonClass?: string;
  hintButtonLabel?: string;
};

const featureIntroOptionsMap: Record<FeatureIntro, FeatureIntroOptions> = {
  FLOWS_FEED: getFlowsFeedIntroOptions(),
};

export const useFeatureIntro = ({
  feature,
  enabled = true,
  delay = 0,
  onIntroJsClose,
}: FeatureIntroProps) => {
  const { mutate } = useUpdateFeatureIntrosQuery(feature);
  const featureIntros = useFeatureIntrosStore(featureIntrosSelector);
  const canShowFeatureIntro =
    featureIntros[feature] &&
    featureIntros[feature] === TourStatusEnum.TODO &&
    enabled &&
    isDesktopPlatform;

  return useEffect(() => {
    const timer = setTimeout(() => {
      const {
        getStepDetails,
        toolTipPosition,
        doneLabel,
        hidePrev,
        showBullets,
        prevLabel,
        tooltipClass,
        scrollToElement = true,
      } = featureIntroOptionsMap[feature];
      if (canShowFeatureIntro) {
        introJS()
          .setOptions({
            steps: getStepDetails(),
            showButtons: true,
            tooltipPosition: toolTipPosition,
            doneLabel: doneLabel || 'Done',
            hidePrev: hidePrev || false,
            showBullets: showBullets,
            prevLabel: prevLabel || 'Back',
            tooltipClass: tooltipClass,
            scrollToElement,
          })
          .onexit(() => {
            mutate();
            if (onIntroJsClose) {
              onIntroJsClose();
            }
          })
          .start();
      }
      if (canShowFeatureIntro) {
        introJS()
          .setOptions({
            steps: getStepDetails(),
            showButtons: true,
            tooltipPosition: toolTipPosition,
            doneLabel: doneLabel || 'Done',
            hidePrev: hidePrev || false,
            showBullets: showBullets,
            prevLabel: prevLabel || 'Back',
            tooltipClass: tooltipClass,
            scrollToElement,
          })
          .onexit(mutate)
          .start();
      }
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [feature, canShowFeatureIntro, mutate, delay, onIntroJsClose]);
};

const featureHintOptionsMap: Record<FeatureHint, FeatureHintOptions> = {
  ACCESS_POLICY_SHARE_BUTTON: getShareSheetHintOptions(),
};

export const useHintIntro = ({ feature, enabled, delay }: FeatureHintProps) => {
  const { mutate } = useUpdateFeatureIntrosQuery(feature);
  const featureIntros = useFeatureIntrosStore(featureIntrosSelector);
  const canDisplayFeatureHint = useMemo(() => {
    return (
      enabled &&
      featureIntros[feature] &&
      featureIntros[feature] === TourStatusEnum.TODO
    );
  }, [enabled, feature, featureIntros]);

  return useEffect(() => {
    const timer = setTimeout(() => {
      const {
        getHintDetails,
        hintPosition,
        tooltipPosition,
        buttonClass,
        hintButtonLabel,
      } = featureHintOptionsMap[feature];
      if (canDisplayFeatureHint) {
        introJS()
          .setOptions({
            hints: getHintDetails(),
            showButtons: true,
            buttonClass: buttonClass,
            tooltipClass: 'introjs-paddingtop20',
            hintPosition: hintPosition,
            tooltipPosition: tooltipPosition,
            hintButtonLabel: hintButtonLabel || 'Got it',
          })
          .onexit(mutate)
          .onhintclose(() => {
            mutate();
          })
          .addHints();
      }
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [canDisplayFeatureHint, delay, feature, mutate]);
};
