import { useState, useCallback, useMemo } from 'react';

import uuid from 'uuid';
import { FlowAccordionItemState } from '../../../../atomic/molecules/FlowAccordionItem/interface';
import { MenuItemIndividualItem } from '../../../../atomic/molecules/Dropdown_V2/interfaces';

import { defaultCurrency } from '../../../../queries/Profile/utils';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  ContentBlockState,
  ContentBuilderBlockData,
  ContentPersonSelectorBlockState,
  FlowBuilderBlockTypes,
} from '../../../../interfaces/Flow/Builder';
import { FlowBlockAPITypes } from '../../../../interfaces/Flow';
import { pushNewOption } from '../../../../Utils/flows';
import { changeableBlockTypes } from '../../../../atomic/organism/FlowPostBody/types';
import { FLOW_BUILDER_EVENTS } from '../../../../Utils/analytics/constants';
import { getCanShowGivePointsStack } from '../../../../Utils/permissions';
import { trackFlowBuilderActionEvent } from '../../../../Utils/analytics/flowsBuilder';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import {
  templateIdSelector,
  templateNameSelector,
} from '../../../../stores/flowBuilderStore/selectors';

const moveBlockPositions = (
  blocks: ContentBlockState[],
  blockId: string,
  moveType: 'UP' | 'DOWN',
) => {
  const updatedBlocks = [...blocks];
  const currentIndex = updatedBlocks.findIndex((x) => x.id === blockId);
  if (currentIndex > -1) {
    const tempBlock = updatedBlocks[currentIndex];
    if (moveType === 'DOWN') {
      updatedBlocks[currentIndex] = updatedBlocks[currentIndex + 1];
      updatedBlocks[currentIndex + 1] = tempBlock;
    } else {
      updatedBlocks[currentIndex] = updatedBlocks[currentIndex - 1];
      updatedBlocks[currentIndex - 1] = tempBlock;
    }
    return updatedBlocks;
  }
  // RETURNS SAME BLOCKS ARRAY IF CURRENT_INDEX === -1 WHICH SHOULD IDEALLY NEVER HAPPEN.
  return blocks;
};

type FlowBuilderContentControllerProps = {
  isExpanded: boolean;
  blockData: ContentBuilderBlockData;
  setSpecificBlockData: (
    blockKey: FlowBuilderBlockTypes.CONTENT,
    updatedBlockData: ContentBuilderBlockData,
  ) => void;
  setShowDataChangeConfirm: (value: boolean) => void;
};

const useFlowBuilderContentController = ({
  blockData,
  isExpanded,
  setSpecificBlockData,
  setShowDataChangeConfirm,
}: FlowBuilderContentControllerProps) => {
  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);

  const { contentBlocks, errors } = blockData;

  const updateContentBlocks = useCallback(
    (updatedContentBlock: ContentBlockState[]) => {
      const updatedBlock: ContentBuilderBlockData = {
        contentBlocks: [...updatedContentBlock],
        errors: null,
      };
      setSpecificBlockData(FlowBuilderBlockTypes.CONTENT, updatedBlock);
    },
    [setSpecificBlockData],
  );

  const { data: profileData } = useProfileInfoFetchQuery();
  const assemblyCurrency = useMemo(
    () => profileData?.assembly.currency || defaultCurrency,
    [profileData],
  );

  const canShowGivePointsStack = getCanShowGivePointsStack(profileData);

  const generateBaseBlockDefaultState = () => ({
    id: uuid.v4(),
    title: '',
    description: null,
    isRequired: false,
    assemblyCurrency,
  });

  const [blockStatus, setBlockStatus] = useState(FlowAccordionItemState.Focus);

  const handleOnMoveDownClick = useCallback(
    (blockId: string) => {
      const blocks = moveBlockPositions([...contentBlocks], blockId, 'DOWN');
      updateContentBlocks(blocks);
    },
    [contentBlocks, updateContentBlocks],
  );

  const handleOnMoveUpClick = useCallback(
    (blockId: string) => {
      const blocks = moveBlockPositions([...contentBlocks], blockId, 'UP');
      updateContentBlocks(blocks);
    },
    [contentBlocks, updateContentBlocks],
  );

  const handleBlockValueChange = useCallback(
    (item: ContentBlockState) => {
      const blocksList = [...contentBlocks];
      const index = blocksList.findIndex((x) => x.id === item.id);

      if (index > -1) {
        if (
          isExpanded &&
          changeableBlockTypes.includes(blocksList[index].type)
        ) {
          setShowDataChangeConfirm(true);
        }

        blocksList[index] = { ...item };
        updateContentBlocks(blocksList);
      }
    },
    [contentBlocks, isExpanded, setShowDataChangeConfirm, updateContentBlocks],
  );

  const handleOnDeleteClick = (
    blockId: string,
    blockType: string,
    isLinkedBlock = false,
  ) => {
    let numBlocksRequired = 0;
    let contentBlockType = '';
    setShowDataChangeConfirm(true);
    const blocksList = contentBlocks.filter((block) => {
      if (block.id === blockId) contentBlockType = block.type;
      if (block.isRequired) {
        numBlocksRequired += 1;
      }
      // If the block is PERSON_SELECTOR and if it is linked to GIVE_POINTS_STACK, delete it
      if (
        blockType === 'GIVE_POINTS_STACK' &&
        block.type === 'PERSON_SELECTOR'
      ) {
        return !block.isLinkedBlock;
      }
      if (
        block.type === 'GIVE_POINTS_STACK' &&
        blockType === 'PERSON_SELECTOR' &&
        isLinkedBlock
      ) {
        return false;
      }

      return block.id !== blockId;
    });
    trackFlowBuilderActionEvent({
      action: FLOW_BUILDER_EVENTS.CONTENT_BLOCK_DELETED,
      contentBlockType: [contentBlockType],
      numBlocks: blocksList.length,
      numBlocksRequired,
      templateId,
      templateName,
    });
    updateContentBlocks(blocksList);
  };

  const personSelectorBlockContent = useMemo(() => {
    return (isLinkedBlock: boolean) => {
      const type = 'PERSON_SELECTOR';
      const personSelectorContent: ContentPersonSelectorBlockState = {
        type,
        ...generateBaseBlockDefaultState(),
        chosenParticipantSelection: 'ONE_PERSON',
        selectedBlockParticipants: 'EVERYONE',
        isLinkedBlock,
      };
      return personSelectorContent;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddBlockClick = (item: MenuItemIndividualItem) => {
    setShowDataChangeConfirm(true);
    const type = item.id as FlowBlockAPITypes;
    let block: ContentBlockState | undefined;
    let linkedPersonSelectorBlock: ContentBlockState | undefined;

    switch (type) {
      case 'NPS': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
          title:
            'How likely are you to recommend [Company/Product/Service] to a friend or colleague?',
        };
        break;
      }
      case 'SCALE': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
          minimumRange: 0,
          maximumRange: 5,
          labelInputOne: '',
          labelInputTwo: '',
          labelInputThree: '',
        };
        break;
      }
      case 'OPEN_ENDED': {
        const openEndedOptions = {
          gifs: true,
          emojis: true,
          mentions: true,
          attachments: true,
          tasks: true,
        };

        block = {
          type,
          ...generateBaseBlockDefaultState(),
          openEndedOptions,
        };
        break;
      }

      case 'FILE_UPLOAD': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
        };
        break;
      }

      case 'PERSON_SELECTOR': {
        block = personSelectorBlockContent(false);
        break;
      }

      case 'DROPDOWN': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
          currentOptionSelectObject: {
            type: 'EXACT_NUMBER',
            exactOptions: 1,
          },
          maximumSelectableOptions: 10,
          options: pushNewOption([], 0, false),
        };
        break;
      }

      case 'MULTI_CHOICE': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
          currentOptionSelectObject: {
            type: 'EXACT_NUMBER',
            exactOptions: 1,
          },
          maximumSelectableOptions: 10,
          options: pushNewOption([], 0, false),
        };
        break;
      }

      case 'GIF': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
        };
        break;
      }

      case 'GIVE_POINTS_STACK': {
        block = {
          type,
          ...generateBaseBlockDefaultState(),
          limitAmountDetails: undefined,
          hideCurrencyValues: false,
        };
        linkedPersonSelectorBlock = personSelectorBlockContent(true);
        break;
      }

      default:
        break;
    }
    if (block) {
      const updatedContentBlocks: ContentBlockState[] = [
        ...contentBlocks,
        ...(linkedPersonSelectorBlock ? [linkedPersonSelectorBlock] : []),
        block,
      ];
      const requiredBlocks = updatedContentBlocks.filter(
        (currentBlock: ContentBlockState) => currentBlock.isRequired,
      );
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.CONTENT_BLOCK_ADDED,
        contentBlockType: [type],
        numBlocks: updatedContentBlocks.length,
        numBlocksRequired: requiredBlocks.length,
        templateId,
        templateName,
      });
      updateContentBlocks(updatedContentBlocks);
    }
  };

  return {
    models: {
      blocks: contentBlocks,
      blockStatus,
      errors,
      canShowGivePointsStack,
    },
    operations: {
      setBlockStatus,
      handleAddBlockClick,
      handleOnDeleteClick,
      handleOnMoveUpClick,
      handleOnMoveDownClick,
      handleBlockValueChange,
    },
  };
};

export default useFlowBuilderContentController;
