/* eslint-disable max-len */
export const PLACEHOLDERS = {
  DEFAULT_TITLE_TEXT: 'Name your flow here...',
  DEFAULT_DESCRIPTION_TEXT: 'Add a description here (optional)',
  DEFAULT_ADD_BLOCK_TITLE_TEXT: 'Write your question or statement here...',
  INPUT_BLOCK_DESCRIPTION_TEXT: 'Enter Description...',
};

export const BUILDER_ACTION_BLOCK = {
  TITLE_TEXT: 'Action',
  CHOOSE_ACTION_TYPE_ACCORDION_ITEM_TEXT: 'Choose an action type',
  ACTION_BLOCK_TITLE: 'Create an app',
  ACTION_BLOCK_DESCRIPTION:
    'Automate workflows – collect, store, and discuss information.',
};

export const BUILDER_TRIGGER_BLOCK = {
  TITLE_TEXT: 'Trigger',
  TYPE_ACCORDION_ITEM_TEXT: 'Choose a trigger type',
  ON_DEMAND_ACCORDION_HEADING_TEXT: 'On demand',
  ON_DEMAND_ACCORDION_BODY_TEXT:
    'Manually prompt participants via email to answer the flow whenever you’d like.',
  SCHEDULED_DATE_TIME_ACCORDION_HEADING_TEXT: 'Scheduled date & time',
  SCHEDULED_DATE_TIME_ACCORDION_BODY_TEXT:
    'Automatically prompt participants via email to answer the flow on a scheduled date and time.',
  NO_TRIGGER_ACCORDION_HEADING_TEXT: 'No trigger',
  NO_TRIGGER_ACCORDION_BODY_TEXT:
    'Flows that don’t require prompting, participants can always answer the flow anytime.',
  TRIGGER_NOTIFICATION_ACCORDION_ITEM_TEXT: 'Trigger Notifications',
  CONFIGURATION_SETTINGS_ACCORDION_ITEM_TEXT: 'Configure Settings',
  DUE_DATE: 'due date',
};

export const BUILDER_COMPLETION_BLOCK = {
  ERROR:
    'You have errors in your flow - please fix them before you create the flow.',
};

export const FLOW_NAME_MAX_LENGTH_ERROR = 'Maximum limit is 30 characters';

export const CONFIGURE_SETTINGS = {
  DEADLINE_CONFIGURATION_HEADING_TEXT:
    'After it is triggered, how long do participants have to complete the form?',
  DEADLINE_CONFIGURATION_BODY_TEXT:
    'After this, you’ll be able to set up reminder notifications so that participants know to fill it out.',
  SHORTCUT_CONFIGURATION_HEADING_TEXT:
    'Can participants answer anytime outside of a triggered occurrence?',
  SHORTCUT_CONFIGURATION_BODY_TEXT:
    'Enabling this will allow participants to answer this flow anytime from the Main or Flow feeds, regardless of whether or not the flow has been triggered.',
  SHORTCUT_CONFIGURATION_LINK_TEXT: ' Learn more about answer anytime',
  SHORTCUT_CONFIGURATION_LINK:
    'https://www.joinassembly.com/articles/flows-to-dos-vs-answer-anytime-vs-view-feeds',
  SHORTCUT_CONFIGURATION_NO_TRIGGER_TOOLTIP_TEXT: `No trigger flows will always have this enabled. This is the only way participants can answer this flow without being prompted to do so.`,
  ALLOW_ANYTIME_CONFIGURATION_HEADING_TEXT:
    'Allow participants to answer this flow anytime?',
  ALLOW_ANYTIME_CONFIGURATION_BODY_TEXT:
    'Enabling this will allow participants to answer this flow anytime from the Main or Flow feeds, regardless of whether or not the flow has been triggered.',
  ONDEMAND_SET_DUE_DATE_HEADING_TEXT: 'Set a default due date',
  ONDEMAND_SET_DUE_DATE_BODY_TEXT:
    'After it is triggered, how long do participants have to complete the form?',
  ONDEMAND_VALUE_CANNOT_BE_ZERO: 'cannot be set to 0.',
};

export const CONFIGURE_SETTINGS_FLOW_START =
  'What date should this flow start?';
export const CONFIGURE_SETTINGS_FLOW_RECUR = 'When should this flow recur?';
export const CONFIGURE_SETTINGS_ERROR_MESSAGE = {
  START_TIME_OCCURRED_IN_THE_PAST: 'Start time cannot occur in the past',
  END_TIME_OCCURRED_IN_THE_PAST: 'End time cannot occur in the past',
  DUE_DATE_CANNOT_BE_LONGER_THAN_FLOW_SCHEDULE:
    'Due date cannot be longer than the flow schedule',
};
export const CONFIGURE_SETTINGS_PICK_A_FREQUENCY = 'Pick a frequency';
export const CONFIGURE_SETTINGS_PICK_A_TIME = 'Pick a time';
export const CONFIGURE_SETTINGS_PICK_A_DATE = 'Pick a date';
export const DUE_DATE_EXTENDS_ERROR = 'Due date extends beyond recurrence';
export const TIMEZONE_LABEL =
  'In what time zone would you like to run this flow?';
export const CHOOSE_TIMEZONE = 'Choose a time zone';

export const CONFIGURE_SETTINGS_FLOW_END_BLOCK = {
  TITLE_TEXT: `When should this flow's schedule end?`,
  MANUALLY: {
    LABEL_TEXT: 'Never',
    INFO_TEXT: 'You’ll be able to end your flow from the flow’s feed.',
  },
  ON: 'On',
  DISABLED_INFO_TEXT:
    'Since this flow will only run once, there’s no need to specify an ending.',
};

export const ONLY_ONCE = 'Only once';
export const DAILY = 'Daily';
export const WEEKLY_ON = 'Weekly on';
export const BIWEEKLY_ON = 'Biweekly on';
export const QUARTERLY_ON_THE = 'Quarterly on the';
export const ANNUALLY_ON = 'Annually on';
export const EVERY_WEEKDAY = 'Every weekday (Monday-Friday)';
export const MINUTES = 'minutes';
export const HOURS = 'hours';
export const DAYS = 'days';
export const WEEKS = 'weeks';

export const BUILDER_PARTICIPANTS_BLOCK = {
  TITLE_TEXT: 'Participation',
  PARTICIPANTS_ACCORDION_ITEM_TEXT: 'Select participants',
  DESCRIPTION_TITLE_TEXT: 'Choose your participants by selecting criteria.',
  DESCRIPTION_CONTENT_TEXT:
    'Choose a criteria to start, and see the filtered list on the right. Participants will be automatically added or removed from this list whenever they meet or do not meet the selected criteria.',
};

export const BUILDER_PREVIEW_BLOCK = {
  ACCORDION_ITEM_TEXT: 'Finishing touches',
  TITLE:
    'Before you create your flow, make sure it has a good, descriptive name.',
  DESCRIPTION:
    'You’ll want your participants and viewers to be able to tell your routine apart from the rest. Add a unique name, emoji, and description to make sure people can identify it.',
};

export const BUILDER_FLOW_CREATED_MODAL = {
  HEADING: 'Congrats, you created your flow!',
  ON_DEMAND_CONTENT_TITLE:
    'Want to prompt participants to start answering right away? Run',
  SCHEDULED_START_ON: 'It is scheduled to start on',
  SCHEDULED_IN_THE_MEAN_TIME:
    'In the meantime, you can view the flows feed or go to my feed.',
  SCHEDULED_FLOW: {
    PRIMARY_BUTTON_TEXT: 'View my feed',
    SECONDARY_BUTTON_TEXT: 'View flow feed',
  },
  ON_DEMAND_FLOW: {
    PRIMARY_BUTTON_TEXT: 'Run Now',
    SECONDARY_BUTTON_TEXT: 'View flow feed',
  },
  NO_TRIGGER_CONTENT_TITLE: `Any participant can now answer this flow at any time. You can create a post in this flow now by clicking “Answer now” below or view other activity in your feed.`,
  NO_TRIGGER_FLOW: {
    PRIMARY_BUTTON_TEXT: 'Answer now',
    SECONDARY_BUTTON_TEXT: 'View my feed',
  },
  QUICK_SETUP_CONTENT_TITLE:
    'Your flow is done and ready to go! When the meeting that you linked to this flow starts, the flow will be triggered. If you need to customize it -- like adjust the scheduled time, edit block content, or change permissions -- you can do that from the ‘Builder’ tab of your',
  QUICK_SETUP: {
    PRIMARY_BUTTON_TEXT: 'Go to the flow feed',
    SECONDARY_BUTTON_TEXT: 'Go to my feed',
  },
  SHARE_THIS_FLOW: 'Share this flow',
};

export const BUILDER_VISIBILITY_BLOCK = {
  TITLE_TEXT: 'Visibility',
  CONFIGURE_VISIBILITY_ACCORDION_ITEM_TEXT: 'Configure Visibility',
  RESPONSE_NOTIFICATIONS_VISIBILITY_ACCORDION_ITEM_TEXT:
    'Response Notifications',
  CUSTOM_OPTION_TEXT:
    'Flow owner will always have visibility, regardless of your criteria selection.',
  PARTICIPANTS_ONLY_OPTION_TEXT:
    'Flow owner will always have visibility, even if they are not a participant.',
  ENTIRE_ORGANIZATION: 'Entire organization',
  PARTICIPANTS_ONLY: 'Participants only',
  OWNER_ONLY: 'Owner only',
  OWNER_AND_COLLABORATORS_ONLY: 'Owner and collaborators only',
  CUSTOM: 'Custom',
  BODY_CONTENT_TITLE: 'Who can see this flow’s responses?',
  BODY_CONTENT_SUBTITLE:
    'The owner of the flow will always have visibility, and each participant can always view their own responses.',
};

export const BUILDER_CONTENT_BLOCK = {
  TITLE_TEXT: 'Content',
  ADD_CONTENT_ACCORDION_ITEM_TEXT: 'Add your content',
};

export const BUILDER_RIGHT_DRAWER_SETTINGS = {
  NAME_YOUR_FLOW: 'Name your flow',
  DESCRIPTION: 'Description (optional)',
};

export const SHARE_SHEET_MODAL = {
  HEADING: 'Share',
  FLOW_MEMBERS: 'Flow members',
  DESCRIPTION:
    'Share this flow with members of your workspace. Choosing participants will define who can respond to this flow, and choosing viewers will define who can view responses.',
  CHOOSE_PARTICIPANTS: 'Choose participants',
  CHOOSE_VIEWERS: 'Choose viewers',
  PARTICIPANTS_BLOCK: {
    MODAL_HEADING: 'Choose participants',
    DESCRIPTION: `Use criteria to select individual members or create an auto-updating
      list of participants selected by department, email, and other
      criteria.`,
    TITLE: 'Select participants',
  },
  VIEWERS_BLOCK: {
    MODAL_HEADING: 'Choose viewers',
    DESCRIPTION:
      'To select specific viewers, use the custom option below to select individual members or create an auto-updating list of viewers selected by department, email, and other criteria. ',
  },
  RESPONSE_SETTINGS: 'Response settings',
  RESPONSES_ARE: 'Responses are',
  NOT_ANONYMOUS: 'not anonymous',
  ANONYMOUS: 'anonymous',
  OPTIONALLY_ANONYMOUS: 'optionally anonymous',
  CHANGE_SETTINGS: 'Change settings',
  ANONYMOUS_SETTINGS_BLOCK: {
    MODAL_TITLE: 'Edit response settings',
    TITLE: 'Set response anonymity',
    SUBTITLE:
      'You can choose to have all responses be anonymous, no responses be anonymous, or give respondents the option to decide. Changing this setting at any time will only apply going forward.',
  },
  LINK_SETTINGS_BLOCK: {
    MODAL_HEADING: 'Edit link access settings',
  },
  LINK_ACCESS_SETTINGS: 'Link access settings',
  ONLY_GROUP_I_SELECT_LABEL: 'Only people or groups I select',
  EVERYONE_CAN_ACCESS_LABEL: 'Everyone in this workspace',
  ANYONE_WITH_LINK_CAN_ACCESS_LABEL: 'Anyone with the link',
  WHO_CAN_ACCESS_FLOW_LABEL: `Who can access this flow’s link?`,
  CAN_ACCESS_FLOW_DESCRIPTION: `You can keep it restricted to people you choose or grant access to anyone.`,
  ANYONE_IN_WORKSPACE_LABEL: 'Anyone in this workspace',
  NO_ONE_SELECTED_LABEL: 'No one selected',
  SHARE_YOUR_FLOW_LABEL: 'Share your flow with following links',
  FLOWS_LINK_VISIBILITY_LABEL:
    'Make this flow visible in everyone’s Flows list',
  ALL_RESPONSES_ARE_NOT_ANONYMOUS: 'All responses are not anonymous',
  ALL_RESPONSES_ARE_OPTIONALLY_ANONYMOUS: 'Anonymous responses are optional',
  ALL_RESPONSES_ARE_ANONYMOUS: 'All responses are anonymous',
  ACCESS_SETTINGS: 'Access settings',
  POST_SETTINGS: 'Post settings',
  POSTS_ARE: 'Posts are',
  PREVIEW_MODAL_HEADING: 'Preview list',
};

export const SHARE_SHEET_RULES = {
  HEADING: 'People with access',
  SUBHEADING: 'You are sharing using',
};

export const SCALE_LABEL = 'Scale';
export const DROPDOWN_LABEL = 'Dropdown';
export const OPEN_ENDED_LABEL = 'Open Ended';
export const FILE_UPLOAD_LABEL = 'File Upload';
export const GIF_SELECTOR_LABEL = 'Select a GIF';
export const MULTI_CHOICE_LABEL = 'Multiple Choice';
export const PERSON_SELECTOR_LABEL = 'Select Person';
export const GIVE_POINTS_STACK_LABEL = 'Give a person points';
export const GIVE_POINTS_STACK_EXISTING_LABEL =
  'Give points stack • Limit 1 per flow';

export const ADD_LEFT_LABEL = 'Add Left Label';
export const ADD_MIDDLE_LABEL = 'Add Middle Label';
export const ADD_RIGHT_LABEL = 'Add Right Label';
export const COLLECT_LABEL = 'COLLECT';
export const ADVANCED_COLLECT_LABEL = 'ADVANCED COLLECT';
export const LIMIT_PERCENTAGE = 'Limit up to a percentage of allowance';
export const LIMIT_VALUE = 'Limit up to an exact value';
export const MINIMUM_CHARACTERS = 'Minimum characters';
export const MAXIMUM_CHARACTERS = 'Maximum characters';
export const SHOW_ALL_FORMATTING_OPTIONS =
  'Show all formatting and attachment options';
export const MENTIONS_STRING = 'Mentions (@FirstNameLastName)';
export const PARTICIPANTS_CAN_SELECT = 'Participants can select';
export const CHOOSE_WHO_CAN_BE_SELECTED = 'Choose who can be selected';
export const A_RANGE = 'A range';
export const AN_EXACT_NUMBER = 'An exact number';
export const SAME_AS_PARTICIPANTS = 'Same as participants';
export const SAME_AS_VIEWERS = 'Same as viewers';
export const FORMS_EDITOR = {
  ADD_BLOCK_LABEL: 'Add a block to start building',
  ADD_ANOTHER_BLOCK_LABEL: 'Add another block',
};
export const ONLY_ONE_PERSON = 'Only one person';
export const UNLIMITED_PEOPLE = 'Unlimited people';
export const BLOCK_SETTINGS = 'Block Settings';
export const CHOOSE_RANGE = 'Choose a range';
export const REMOVE_DESCRIPTION = 'Remove description';
export const ADD_DESCRIPTION = 'Add a description';
export const GIVEN_IN_POSTS = 'given in posts';
export const LIMIT_AMOUNT_OF = 'Limit the amount of ';
export const toolTipContentForPointsStack = (pluralCurrency: string) =>
  `Not all Assembly members have ${pluralCurrency} to give, so this can’t be made a required block.`;

export const FILE_UPLOAD_TITLE = 'Upload File';
export const FILE_UPLOAD_LIMIT = 'Maximum: 25MB or 15 files';
export const PERSON_SELECTOR_PLACEHOLDER_LABEL = 'Select a Person';

export const UPTO = 'up to';

export const ADD_YOUR_ANSWER = 'Add your answer(optional)';
export const DROPDOWN_SELECTOR_PLACEHOLDER_LABEL = 'Select an option';

export const GIVE_POINTS = 'Give Points';
export const BLOCK_MUST_BE_LINKED_TO = 'block is linked to a';
export const PERSON_SELECTION = 'Person Selection';
export const DELETING_GIVE_POINTS_STACK_TITLE = 'Deleting Give Points Stack';
export const DELETING_GIVE_POINTS_STACK_INFO =
  // eslint-disable-next-line max-len
  'Deleting this Give Points block will also delete the linked Person Selection block. Please confirm this action.';
export const DELETING_LINKED_PERSON_SELECTOR_INFO =
  'Deleting this Person Selection block will also delete the linked Give Points block. Please confirm this action.';
export const CUSTOM_RECURRENCE = 'Custom recurrence';
export const REPEAT_EVERY = 'Repeat every';
export const REPEAT_ON = 'Repeat on';
export const MONTHLY_ON_THE = 'Monthly on the';
export const ADD_MORE_CRITERIA_LABEL = 'Add more criteria';
export const ADD_PARTICIPANT_CRITERIA_LABEL = 'Add criteria';
export const ADD_SELECTABLE_CRITERIA = 'Add selectable criteria';
export const ADD_VISIBILITY_CRITERIA_LABEL = 'Add criteria';
export const MenuItemsTexts = {
  criteriaMenuItems: {
    PARTICIPANTS_MATCHES_ANY: 'Participant matches any',
    PARTICIPANTS_MATCHES_ALL: 'Participant matches all',
  },
  criteriaConditionItems: {
    OR_PARTICIPANT_MATCHES: 'Or participant matches',
    AND_PARTICIPANT_MATCHES: 'And participant matches',
  },
};

export const CUSTOM_SELECTION = 'Custom Selection';
export const CUSTOM_PERSON_SELECTOR_BLOCK_SETTINGS =
  'Using custom selection to limit the list of available people within the person selection block works just like participants or visibility. Use the Member criteria if you have a few people in mind.';

export const FLOW_BUILDER_CREATION_ERROR =
  'Unable to create your flow at the moment. Please try again later.';

export const FLOW_BUILDER_EDIT_ERROR =
  'Unable to save your flow at the moment. Please try again later.';

export const PERSON_SELECTOR_BUILDER_BLOCK_LABEL =
  'Whomever the participant selects will receive the points specified in the linked block.';

export const LEARN_MORE_HELPER_LINK =
  'https://www.joinassembly.com/articles/flows-builder';
export const AMOUNT_OF = 'amount of';
export const GET_HELP_LINK =
  'https://www.joinassembly.com/articles/flows-sharing';
export const BUILDER_NAVIGATION_MODAL_TEXT = {
  CREATE_FLOW: {
    HEADING: 'Discard unfinished flow?',
    BUILDER_NAVIGATION_MODAL_PREFIX_TEXT: 'Are you sure you’d like to leave',
    BUILDER_NAVIGATION_MODAL_SUFFIX_TEXT:
      'and discard it? Your progress won’t be saved, and you will need to start over when building it again.',
    PRIMARY_BUTTON: {
      TEXT: 'Discard flow',
    },
    SECONDARY_BUTTON: {
      TEXT: 'Keep building',
    },
  },
  EDIT_FLOW: {
    HEADING: 'Leaving edit mode',
    BUILDER_NAVIGATION_MODAL_PREFIX_TEXT:
      ' Are you sure you want to leave? If you made changes to',
    BUILDER_NAVIGATION_MODAL_SUFFIX_TEXT:
      ' , choose whether to save or revert them before you go. Or, click “Go back” to keep editing this flow.',
    PRIMARY_BUTTON: {
      TEXT: 'Save and leave',
    },
    SECONDARY_BUTTON: {
      TEXT: 'Revert and leave',
    },
    TERTIARY_BUTTON: {
      TEXT: 'Go back',
    },
  },
};

export const HIDE_BUILDER_HEADER = 'The builder is only available on desktop';
export const HIDE_BUILDER_BODY =
  'Please head to your nearest computer to edit this flow!';

export const FLOW_COMPLETION_GENERIC_ERROR_TEXT =
  'Flow title and emoji are required';
export const FLOW_COMPLETION_EMOTICON_ERROR_TEXT = 'Emoji is required';
export const FLOW_COMPLETION_TITLE_ERROR_TEXT = 'Flow title is required';

export const NO_RESULTS = 'No results found';
export const ANSWER_ANYTIME = 'Answer anytime';

export const CREATE_FLOW_WITH_INVITE_MEMBERS = {
  HEADING: 'Invite new teammates to this flow',
  getAddedNewMembersInviteCountText: (count: number) =>
    `You added ${count} teammate who isn’t members of this Assembly yet:`,
  getInviteMemberDescription: (inviteText: string) =>
    `By clicking "${inviteText}" this invite will be sent out. When they accept the invite, this flow will be waiting for them once it runs.`,
  CREATE_AND_INVITE: 'Create and invite',
  SAVE_AND_INVITE: 'Save and invite',
  SECONDARY_BUTTON_TEXT: 'Go back',
};

export const NOT_A_MEMBER_TOOLTIP_TEXT =
  'This person is not a member of your Assembly, you’ll be prompted to invite them before you create this flow.';

export const PENDING_MEMBER_TOOLTIP_TEXT =
  'This person is pending, they haven’t accepted their invite yet.';

export const INVITE_PENDING = 'Invite pending';
export const INVITE_UPON_FLOW_CREATION = 'Invite upon flow creation';
export const INVITE_UPON_SAVE = 'Invite upon save';

export const WELCOME_TO_BUILDER_TOAST = {
  title: 'Welcome to the builder!',
  subTitle:
    'Create and edit your first flow from here. Get started with your first survey -- build and invite, or build a test run to see how it works.',
};
