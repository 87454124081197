import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import Theme from '../../../theme';
import Button from '../../atoms/Button';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

const MemberActionsWrapper = styled(Flex)`
  overflow-x: scroll;
  &:first-child {
    background: ${Theme.palette.white} !important;
    border-bottom: 1px solid ${Theme.palette.gray4};
  }
`;

const SimpleRuleBulkAddButton = styled(Button)`
  margin-right: 8px;
  &:not(:disabled):hover {
    &::before {
      background: ${Theme.palette.gray4};
    }
  }
`;

const bulkRuleOptions = [
  {
    id: 'workLocation',
    label: 'Work location',
  },
  {
    id: 'homeLocation',
    label: 'Home location',
  },
  {
    id: 'department',
    label: 'Department',
  },
  {
    id: 'jobTitle',
    label: 'Job title',
  },
];

export const NonMemberRuleSelector = ({
  isEveryoneSelected,
  onRuleSelection,
  canShowEveryoneCriteria,
}: {
  isEveryoneSelected: boolean;
  onRuleSelection: (action: string) => void;
  canShowEveryoneCriteria: boolean;
}) => {
  const { treatment: locationCriteria } = useFeatureSplit(
    SplitNames.LocationCriteria,
  );

  const isLocationCriteriaEnabled = locationCriteria === TreatmentTypes.ON;

  return (
    <MemberActionsWrapper
      width="100%"
      justifyContent="flex-start"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Body margin="8px 0 0 0" color="gray9" variant="body2Medium">
        Add a rule:
      </Body>
      <Flex margin="4px 0">
        {!isEveryoneSelected && canShowEveryoneCriteria && (
          <SimpleRuleBulkAddButton
            onClick={(e) => {
              e.stopPropagation();
              onRuleSelection('everyone');
            }}
            color="grayTertiary"
            variant="outlined"
            padding="4px 12px"
          >
            <Body color="gray8" variant="body2Medium">
              Everyone
            </Body>
          </SimpleRuleBulkAddButton>
        )}
        {isLocationCriteriaEnabled ? (
          <>
            {bulkRuleOptions.map((option) => (
              <SimpleRuleBulkAddButton
                key={option.id}
                onClick={(e) => {
                  e.stopPropagation();
                  onRuleSelection(option.id);
                }}
                color="grayTertiary"
                variant="outlined"
                padding="4px 12px"
              >
                <Body color="gray8" variant="body2Medium">
                  {option.label}
                </Body>
              </SimpleRuleBulkAddButton>
            ))}
          </>
        ) : (
          <SimpleRuleBulkAddButton
            onClick={(e) => {
              e.stopPropagation();
              onRuleSelection('department');
            }}
            color="grayTertiary"
            variant="outlined"
            padding="4px 12px"
          >
            <Body color="gray8" variant="body2Medium">
              Department
            </Body>
          </SimpleRuleBulkAddButton>
        )}

        <SimpleRuleBulkAddButton
          onClick={(e) => {
            e.stopPropagation();
            onRuleSelection('moveToConnectionsTab');
          }}
          color="grayTertiary"
          variant="outlined"
          padding="4px 12px"
        >
          <Body color="gray8" variant="body2Medium">
            Slack
          </Body>
        </SimpleRuleBulkAddButton>
      </Flex>
    </MemberActionsWrapper>
  );
};
