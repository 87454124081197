import styled from 'styled-components';

import Modal from '../../../atomic/atoms/Modal';
import ThemeV2 from '../../../theme';
import { renderPreviewTemplateModal } from '../utils';
import TemplateGalleryFrame from './Components/TemplateGalleryFrame';
import TemplateGalleryHeader from './Components/TemplateGalleryHeader';
import useTemplateGalleryController from './useTemplateGalleryController';

const StyledModal = styled(Modal)<{ isMobileView?: boolean }>`
  width: 100vw;
  padding-bottom: ${({ isMobileView }) => isMobileView && '4px'};
  height: ${({ isMobileView }) => (isMobileView ? '100vh' : '100%')};
  overflow: ${({ isMobileView }) => (isMobileView ? 'auto' : 'hidden')};
  overflow-y: ${({ isMobileView }) => (isMobileView ? 'auto' : 'hidden')};
  border-radius: ${({ isMobileView }) => !isMobileView && '8px'};
  @media (min-width: ${ThemeV2.breakPoints.md}) {
    width: 90vw;
    max-width: 1366px;
    height: 90vh;
    max-height: 768px;
  }
`;

const TemplateGalleryController = ({
  isCreateFlowModalOpen = false,
}: {
  isCreateFlowModalOpen?: boolean;
}) => {
  const {
    isMobileView,
    iFrameRef,
    getTemplateFrameURL,
    isTemplatesDataLoading,
    renderPreviewTemplateModalProps,
    handleTemplateGalleryModalClose,
  } = useTemplateGalleryController();

  return (
    <>
      <StyledModal
        isOpen
        isMobileView={isMobileView}
        handleClose={handleTemplateGalleryModalClose}
        hidden={!isCreateFlowModalOpen}
      >
        {!isMobileView && (
          <TemplateGalleryHeader onClose={handleTemplateGalleryModalClose} />
        )}
        <TemplateGalleryFrame
          isMobileApp={isMobileView}
          isLoading={isTemplatesDataLoading}
          src={getTemplateFrameURL()}
          iFrameRef={iFrameRef}
        />
      </StyledModal>
      {renderPreviewTemplateModal(renderPreviewTemplateModalProps)}
    </>
  );
};

export default TemplateGalleryController;
