import React from 'react';
import styled from 'styled-components';

import Link from '../../atoms/Link_V2';
import { V2_HOME } from '../../../constants/routes';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import Button from '../../atoms/Button';
import useLayoutStore from '../../../stores/layoutStore';

export const StyledButtonContainer = styled.div`
  margin-left: auto;
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

export const StyledHomeButton = styled(Button)`
  width: 24px;
`;

type MainFeedLinkButtonProps = {
  onClick?: () => void;
};

const MainFeedLinkButton = (props: MainFeedLinkButtonProps) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { onClick } = props;
  if (isEmbeddedInMainApp) {
    return null;
  }
  return (
    <StyledButtonContainer>
      <Link to={V2_HOME}>
        <StyledHomeButton
          color="secondary"
          size="small"
          onClick={onClick}
          dataTestId="home-button"
        >
          <SVGIcon icon="home" color={ThemeV2.palette.geekBlue6} size="24px" />
        </StyledHomeButton>
      </Link>
    </StyledButtonContainer>
  );
};

export default MainFeedLinkButton;
