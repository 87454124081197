import { Helmet } from 'react-helmet-async';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import useToggleInviteModal from '../../../hooks/useToggleInviteModal';
import {
  PEOPLE_DIRECTORY_SUBTITLE,
  PEOPLE_DIRECTORY_TITLE,
} from '../../../languages/en/header';
import InviteModalController from '../../InviteModalController';

const PeopleDirectoryHeader = () => {
  const { isModalOpen, toggleInviteModal } = useToggleInviteModal();
  const handleInviteTeammatesClick = () => {
    toggleInviteModal();
  };
  return (
    <>
      <Helmet>
        <title>People</title>
      </Helmet>
      <PageHeader
        headerType={HeaderType.PEOPLE_DIRECTORY}
        icon="people-directory"
        title={PEOPLE_DIRECTORY_TITLE}
        subTitle={PEOPLE_DIRECTORY_SUBTITLE}
        hasBottomBorder={true}
        onInviteButtonClick={handleInviteTeammatesClick}
      />
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={toggleInviteModal}
      />
    </>
  );
};

export default PeopleDirectoryHeader;
