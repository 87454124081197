import { GifRatingsProps } from '../../../atomic/atoms/Giphy/interface';
import {
  AssemblyCurrencyType,
  CreditState,
  CurrencyLevel,
  PrivateMessageAndImpactLevelValues,
} from '../../../interfaces/assembly';
import { FeedItemFromAPI } from '../../../interfaces/Feed';
import { PostTypes } from '../../../interfaces/Home';
import {
  AllowanceCycle,
  AllowanceType,
  MemberState,
  UserStatus,
} from '../../../interfaces/user';
import { GetProfileInfoResponse } from '../../../queries/Profile';

export const recognitionPost: FeedItemFromAPI = {
  message: 'Test Message @__60543129d6f70a2153ae71ab__@ 😘',
  postID: '60abb2ac177f94c0766d3ea8',
  assemblyID: '6051aa74a1aa76482e20f5ca',
  type: PostTypes.RECOGNITION,
  from: {
    name: 'Arun Selva Kumar Admin',
    firstName: 'Arun',
    lastName: 'Selva Kumar Admin',
    username: 'arun selvakumar',
    memberID: '6051aa38a1aa76645320f5ba',
    image: '',
    pointsGiven: 191,
    totalPointsGiven: 191,
    memberState: MemberState.ACTIVE,
    isDeleted: false,
  },
  to: [
    {
      name: 'Arun Selva Kumar',
      firstName: 'Arun',
      lastName: 'Selva Kumar',
      username: 'arun selvakumar3',
      memberID: '60583e48e2b912bd65cf6bda',
      image: '',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: MemberState.ACTIVE,
      isDeleted: false,
    },
  ],
  taggedUsers: [
    {
      name: 'Arun 6',
      firstName: 'Arun',
      lastName: '6',
      username: 'arun6',
      memberID: '60543129d6f70a2153ae71ab',
      image: '',
      pointsGiven: 0,
      totalPointsGiven: 0,
      memberState: MemberState.ACTIVE,
      isDeleted: false,
    },
  ],
  reactions: [],
  commentsCount: 0,
  pointsEach: 0,
  isPrivate: false,
  isDeleted: false,
  gifURL: 'https://media4.giphy.com/media/THD7thMQZoOYoyZ3EK/giphy.gif',
  imageURL: '',
  coreValue: 'Core value 1',
  createdAt: '2021-05-24T14:05:33.004Z',
  updatedAt: '2021-05-24T14:05:33.004Z',
};

export const profileData: GetProfileInfoResponse = {
  member: {
    memberId: '6051aa38a1aa76645320f5ba',
    email: 'arun+2@joinassembly.com',
    status: UserStatus.NORMAL,
    role: [],
    profile: {
      firstName: 'Arun',
      lastName: 'Selva Kumar Admin',
      department: '',
      jobTitle: 'staff software engineer',
      location: 'bat cave',
      image: '',
      username: 'arun selvakumar',
      isManager: true,
      timezone: 'America/Los_Angeles',
      birthday: {},
      hiredday: {},
      showQuickSetupPanel: true,
      showLeftDrawer: true,
    },
    allowance: {
      type: AllowanceType.REGULAR,
      points: 200,
      cycle: AllowanceCycle.MONTHLY,
    },
    pointsEarned: 200,
    totalPointsEarned: 200,
    totalPointsGiven: 191,
    pointsEarnedThisCycle: 191,
    pointsLeftThisCycle: 9,
    managerIds: [],
    reportIds: [],
    activatedAt: new Date(),
    isQuickSetupComplete: true,
  },
  assembly: {
    assemblyId: '6051aa74a1aa76482e20f5ca',
    name: 'Arun Enterprises',
    logo: '',
    domain: 'joinassembly.com',
    exchangeRate: '',
    timeZone: 'America/Los_Angeles',
    createdAt: new Date(),
    currency: {
      name: 'Trophy',
      pluralName: 'Trophies',
      type: AssemblyCurrencyType.REGULAR,
      value: '1F3C6',
    },
    accounts: {
      creditState: CreditState.VERIFIED,
      rewards: {
        isChargeable: true,
      },
    },
    settings: {
      activeSpecialMention: 'CORE_VALUES',
      badgeAttachability: {
        enabled: false,
      },
      blockWordsMessage: {
        enabled: true,
        value: [],
      },
      minMessageChar: {
        enabled: false,
        value: 25,
      },
      canDeletePosts: {
        enabled: true,
        value: 'ADMIN',
      },
      coreValues: {
        enabled: true,
        value: ['Teamwork', 'hello', 'Core value 1', 'Core value 2'],
      },
      gifAccessibility: {
        enabled: true,
        value: GifRatingsProps.PG_13,
      },
      imageAttachability: {
        enabled: true,
      },
      privateMessage: {
        enabled: false,
        value: PrivateMessageAndImpactLevelValues.MANAGER_ONLY,
      },
      postImpactLevel: {
        enabled: true,
        value: {
          customType: PrivateMessageAndImpactLevelValues.MANAGER_ONLY,
          levels: [
            {
              percentage: 5,
              name: CurrencyLevel.SMALL,
            },
            {
              percentage: 10,
              name: CurrencyLevel.SMALL,
            },
            {
              percentage: 15,
              name: CurrencyLevel.SMALL,
            },
            {
              percentage: 20,
              name: CurrencyLevel.SMALL,
            },
            {
              percentage: 25,
              name: CurrencyLevel.SMALL,
            },
          ],
        },
      },
      userManagement: {
        approvalAccess: {
          enabled: true,
          value: ['ADMINS_AND_OWNER'],
        },
        inviteAccess: {
          enabled: true,
          value: ['ADMINS_AND_OWNER'],
        },
        canLoginUsingLink: { enabled: true },
      },
    },
    workspaceSlug: {
      name: 'planet-express',
      shortCode: '12gqhjk89d',
    },
  },
};
