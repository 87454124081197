import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import Heading from '../../atoms/Heading';

export const StyledProfileNavHeaderTitle = styled.div`
  font-family: ${ThemeV2.typography.adminFontFamily};
  color: ${ThemeV2.palette.gray9};
  font-size: 14px;
  font-weight: ${ThemeV2.typography.fontWeightBold};
  line-height: 22px;
`;

export const StyledProfileNavViewProfile = styled.span`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 12px;
  color: ${ThemeV2.palette.gray8};
  text-decoration: none;
  line-height: 20px;
`;

export const ProfileHeading = styled(Heading)`
  margin: 0px;
  margin-left: 8px;
`;
ProfileHeading.displayName = 'ProfileHeading';

export const StyledProfileNavHeader = styled.div`
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const StyledProfileNavHeaderContent = styled.div`
  padding-left: 8px;
`;
