import styled from 'styled-components';
import { RewardsFooter as StyledRewardFooterUtil } from '../../../atomic/organism/RouteTabs/styles';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import Clickable from '../../../atomic/atoms/Clickable_V2';

export const RewardsFooter = styled(StyledRewardFooterUtil)`
  position: fixed;
  bottom: 0;
  z-index: 1;
`;

export const StyledToggleSwagTabsAlert = styled(InfoAlert)`
  margin-bottom: 16px;
`;

export const StyledClickable = styled(Clickable)`
  text-decoration: underline;
`;
