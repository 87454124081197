import { datadogLogs } from '@datadog/browser-logs';
import axios from 'axios';
import { API_URL_WITH_V3 } from '../config';
import { authStore } from '../stores/authStore';
import { AuthHeadersV2 } from './headerToken';
import { getAppSource, setAppSource } from './msteams';
import { serializeUser } from './user';

export const clearLocalStorage = () => {
  const appSource = getAppSource();

  // TODO: group these values into one object per assembly instance
  // to make it easier to handle this in multi-assembly world & to
  // clear the cache on sign out
  const keysToClear = [
    'user',
    'token',
    'dontShowClickedOnSlackSetupModal',
    'dontShowQuickSetupProgress',
    'userFirstLogin',
    'WEB_APP_SOURCE',
  ];

  keysToClear.forEach((key) => localStorage.removeItem(key));

  if (appSource) {
    setAppSource(appSource);
  }
};

export const authenticateUser = (me: any) => {
  localStorage.setItem('user', JSON.stringify(me));
  authStore.getState().setAuthStoreStateForAuthenticatedUser(serializeUser(me));
};

export const signOutAction = async () => {
  try {
    await axios.post(
      `${API_URL_WITH_V3}/users/session/logout`,
      {},
      AuthHeadersV2(),
    );
  } catch (error) {
    datadogLogs.logger.error('Error while signing out', error as object);
  }

  authStore.getState().resetAuthStore();

  clearLocalStorage();
};
