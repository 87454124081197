import { EditorState, Modifier } from 'draft-js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useProfileInfoFetchQuery } from '../../queries/Profile';
import {
  NOTEBOOK_EVENTS,
  NOTEBOOK_TASK_EVENTS,
  PARTICIPATION_ANALYTICS_EVENTS,
} from '../../Utils/analytics/constants';
import { NOTEBOOK_TASK_DRAFT_ENTITY_KEY } from '../../Utils/draftjs';
import { trackFlowParticipationActionEvent } from '../../Utils/analytics/flowsParticipation';
import { TaskOptionsHookProps } from './interfaces';
import {
  convertStringToTokenizedObject,
  convertTokenizedObjectToString,
  getNotebookTaskAnalyticsProps,
} from '../../Utils/notebook';
import {
  Assignee,
  NotebookTask,
  NotebookViews,
  TaskCategories,
  TaskPayload,
} from '../../interfaces/notebook';
import useNotebookStore from '../../stores/notebookStore';
import { ERROR_CREATING_TASK_TOAST } from '../../languages/en/flows/participation';
import { NotebookStore } from '../../stores/notebookStore/types';
import {
  extractAssignedToInformation,
  generateTask,
  getCreatedByFromProfile,
} from '../../controllers/notebook/NotebookViewController/utils';
import {
  trackNotebookErrorEvent,
  trackNotebookTaskActionEvent,
} from '../../Utils/analytics/notebook';
import { showErrorMessage } from '../../Utils/toast';
import { usePostNotebookTasksMutation } from '../../queries/Notebook';

const notebookTasksSelector = (state: NotebookStore) => ({
  createTask: state.createTask,
  removeTransaction: state.removeTransaction,
  updateNoteIds: state.updateNoteIds,
});

export const useTaskOptions = ({
  blockValue,
  editorState,
  enabled,
  focusEditor,
  onBlockChange,
  setEditorState,
}: TaskOptionsHookProps) => {
  const anchorElForTasksDropdown = useRef<Element | null>(null);
  const editorStateBeforeNewTaskEnter = useRef<EditorState | null>(null);
  const newTaskTokenEntitiesMap = useRef<Map<string, string>>(new Map());
  const [isTasksDropdownOpen, setIsTasksDropdownOpen] = useState(false);
  const [isCreateTaskInputOpen, setIsCreateTaskInputOpen] = useState(false);

  const [editorStateUpdates, setEditorStateUpdates] = useState<
    (TaskPayload | null)[]
  >([]);

  const handleCloseCreateTaskInput = useCallback(() => {
    setIsCreateTaskInputOpen(false);
  }, []);

  const handleCreateTaskClick = useCallback(() => {
    setIsCreateTaskInputOpen(true);
  }, []);

  const handleReferenceTaskClick = useCallback(() => {
    setIsTasksDropdownOpen(true);
  }, []);

  const handleCloseTasksDropdown = useCallback(() => {
    setIsTasksDropdownOpen(false);
  }, []);

  const handleCreateTaskLinkClick = useCallback(() => {
    setIsTasksDropdownOpen(false);
    setIsCreateTaskInputOpen(true);
  }, []);

  const handleTaskTokenInsertion = useCallback(
    (option: NotebookTask, isNew = false) => {
      const contentStateWithEntity = editorState
        .getCurrentContent()
        .createEntity(NOTEBOOK_TASK_DRAFT_ENTITY_KEY, 'IMMUTABLE', {
          task: { ...option, isLoading: isNew },
        });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const targetRange = editorState.getSelection();
      const endOffset = targetRange.getEndOffset();

      // Check if a range of text was highlighted/selected.
      const modifierMethod =
        targetRange.getStartOffset() !== endOffset
          ? 'replaceText'
          : 'insertText';

      let newContentState = Modifier[modifierMethod](
        contentStateWithEntity,
        targetRange,
        convertTokenizedObjectToString(option.note),
        editorState.getCurrentInlineStyle(),
        entityKey,
      );

      // If the mention is inserted at the end, a space is appended right after for
      // a smooth writing experience. This is meant to be similar to how mentions are handled.
      // See how it's similarly implemented in https://tinyurl.com/3ttrabj7 (GitHub).
      const blockSize = editorState
        .getCurrentContent()
        .getBlockForKey(targetRange.getAnchorKey())
        .getLength();

      if (blockSize === endOffset) {
        newContentState = Modifier.insertText(
          newContentState,
          newContentState.getSelectionAfter(),
          ' ',
        );
      }

      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-fragment',
      );

      if (
        !isNew &&
        !blockValue.tasks.some(({ noteId }) => noteId === option.noteId)
      ) {
        onBlockChange({
          ...blockValue,
          tasks: [...blockValue.tasks, option],
        });
      }

      if (isNew) {
        newTaskTokenEntitiesMap.current.set(option.taskId, entityKey);
      }

      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newContentState.getSelectionAfter(),
        ),
      );

      trackFlowParticipationActionEvent({
        action: PARTICIPATION_ANALYTICS_EVENTS.ADD_TASK,
        taskType: isNew ? 'new' : 'existing',
      });

      if (isNew) {
        const flowPath = window.location.pathname.split('/');
        const flowsIndex = flowPath.indexOf('flows');
        const flowId = flowsIndex >= 0 ? flowPath[flowsIndex + 1] : undefined;

        trackNotebookTaskActionEvent({
          ...getNotebookTaskAnalyticsProps(
            option,
            option.type,
            undefined,
            undefined,
            NotebookViews.Flow,
            'participation',
            flowId,
          ),
          action: NOTEBOOK_TASK_EVENTS.NOTEBOOK_TASK_CREATE,
        });
      }

      setTimeout(() => {
        focusEditor();
      }, 200);
    },
    [blockValue, editorState, focusEditor, onBlockChange, setEditorState],
  );

  const handleTaskOptionSelect = useCallback(
    (option: NotebookTask) => {
      handleTaskTokenInsertion(option);
      handleCloseTasksDropdown();
    },
    [handleCloseTasksDropdown, handleTaskTokenInsertion],
  );

  const [newTaskValue, setNewTaskValue] = useState('');

  const { createTask, removeTransaction, updateNoteIds } = useNotebookStore(
    notebookTasksSelector,
    shallow,
  );

  const { data: profileInfo } = useProfileInfoFetchQuery(enabled);
  const currentUserTimezone = useMemo(
    () => profileInfo?.member.timeZone || '',
    [profileInfo?.member.timeZone],
  );

  const handleNewTaskValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewTaskValue(e.target.value);
    },
    [],
  );

  const handleNewTaskValueClear = useCallback(() => {
    setNewTaskValue('');
  }, []);

  const { mutate } = usePostNotebookTasksMutation(NotebookViews.Flow);

  const handleNewTaskSubmit = useCallback(() => {
    const assignedTo: Assignee = extractAssignedToInformation(profileInfo);
    const createdBy = getCreatedByFromProfile(profileInfo);

    const task = {
      ...generateTask(TaskCategories.UNSCHEDULED, [], currentUserTimezone),
      assignedTo,
      createdBy,
      createdAt: new Date().toISOString(),
      note: convertStringToTokenizedObject(newTaskValue),
    };

    const transaction = createTask(task);

    editorStateBeforeNewTaskEnter.current = editorState;
    handleTaskTokenInsertion(task, true); // Optimistically insert token into editor

    if (transaction?.taskData) {
      mutate(
        { payload: [transaction.taskData] },
        {
          onSuccess: (response) => {
            /**
             * Update transactions in Zustand store.
             */
            const noteIds = response.data.map(
              (res: { noteId: string }) => res.noteId,
            );

            updateNoteIds({ transactionQueue: [transaction] }, noteIds);
            removeTransaction(transaction);

            const [noteIdForNewTask] = noteIds;

            setEditorStateUpdates((curEditorStateUpdates) => {
              return [
                ...curEditorStateUpdates,
                { ...transaction.taskData, noteId: noteIdForNewTask },
              ];
            });

            handleNewTaskValueClear();
            handleCloseCreateTaskInput();
          },
          onError: () => {
            trackNotebookErrorEvent({
              error: NOTEBOOK_EVENTS.NOTEBOOK_ERROR,
              source: 'participation',
              subSource: 'taskUpdate',
              errorIds: [transaction.taskData.transactionId],
            });

            showErrorMessage(ERROR_CREATING_TASK_TOAST);

            setEditorStateUpdates((curEditorStateUpdates) => {
              return [...curEditorStateUpdates, null];
            });

            setIsCreateTaskInputOpen(true);
          },
        },
      );
    }

    handleCloseCreateTaskInput();
  }, [
    createTask,
    currentUserTimezone,
    editorState,
    handleCloseCreateTaskInput,
    handleNewTaskValueClear,
    handleTaskTokenInsertion,
    mutate,
    newTaskValue,
    profileInfo,
    removeTransaction,
    updateNoteIds,
  ]);

  useEffect(() => {
    if (editorStateUpdates.length > 0) {
      setEditorStateUpdates([]);

      // This logic needs to be put inside `useEffect` because a warning, relating to
      // updating a component while rendering a different component, is thrown in the console
      // when `setEditorState` is invoked in the handlers for `onSuccess` or `onError` for a mutation.
      editorStateUpdates.forEach((editorStateUpdate) => {
        if (!editorStateUpdate) {
          // Failed: Revert to previous editor state
          if (editorStateBeforeNewTaskEnter.current) {
            setEditorState(editorStateBeforeNewTaskEnter.current);
          }
        } else {
          // Success: Update entities in editor state with new note ID returned by API.
          const entityKey = newTaskTokenEntitiesMap.current.get(
            editorStateUpdate.transactionId,
          );

          if (entityKey) {
            setEditorState((latestEditorState) => {
              const currentContent = latestEditorState.getCurrentContent();
              const curData = currentContent.getEntity(entityKey).getData();

              const mergedData = {
                ...curData.task,
                noteId: editorStateUpdate.noteId,
              };

              if (
                !blockValue.tasks.some(
                  ({ noteId }) => noteId === editorStateUpdate.noteId,
                )
              ) {
                onBlockChange({
                  ...blockValue,
                  tasks: [...blockValue.tasks, mergedData],
                });
              }

              newTaskTokenEntitiesMap.current.delete(
                editorStateUpdate.transactionId,
              );

              return EditorState.set(latestEditorState, {
                currentContent: Modifier.applyEntity(
                  currentContent.mergeEntityData(entityKey, {
                    task: { ...mergedData, isLoading: false },
                  }),
                  latestEditorState.getSelection(),
                  entityKey,
                ),
              });
            });
          }
        }
      });
    }
  }, [blockValue, editorStateUpdates, onBlockChange, setEditorState]);

  return {
    anchorElForTasksDropdown,
    handleCloseCreateTaskInput,
    handleCloseTasksDropdown,
    handleCreateTaskClick,
    handleCreateTaskLinkClick,
    handleNewTaskSubmit,
    handleNewTaskValueChange,
    handleNewTaskValueClear,
    handleReferenceTaskClick,
    handleTaskOptionSelect,
    handleTaskTokenInsertion,
    isCreateTaskInputOpen,
    isTasksDropdownOpen,
    newTaskValue,
  };
};
