import isEmpty from 'lodash/isEmpty';
import { parse } from 'qs';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Router, useLocation } from 'react-router-dom';

import { SplitFactory } from '@splitsoftware/splitio-react';

import LoadingPulse from '../atomic/atoms/LoadingPulse';
import AssemblyLanderLoadingScreen from '../atomic/molecules/AssemblyLanderLoadingScreen';
import KioskFooter from '../atomic/organism/KioskFooter';
import { ErrorFallback } from '../atomic/pages/ErrorFallback';
import { APP_URL } from '../config';
import { DEVICE, DEVICES } from '../constants/devices';
import { INVITE_PEOPLE, TEMPLATE } from '../constants/routes';
import history from '../history';
import useAdminSettingsUpdatedSocket from '../hooks/useAdminSettingsUpdatedSocket';
import { useMixPanelInitialization } from '../hooks/useMixPanelInitialization';
import { HttpsStatus } from '../interfaces/ResponseError';
import {
  GetProfileInfoResponse,
  useProfileInfoFetchQuery,
} from '../queries/Profile';
import RoutesList from '../routesList';
import { authStore } from '../stores/authStore';
import { microsoftTeamsStore } from '../stores/microsoftStore';
import { trackPageView } from '../Utils/analytics';
import { setSourceDeviceAsIPhone } from '../Utils/device';
import { getSplitIOFactory } from '../Utils/splitOptions';
import { Flex } from '../Utils/styles/display';
import MultiAssemblyRouters from './multiAssemblyRouters';
import { NO_SLUG_NO_REDIRECT_ROUTES, NO_SLUG_REDIRECT_ROUTES } from './utils';
import { datadogLogs } from '@datadog/browser-logs';

const SplitV2 = ({
  shortCode,
  slugUrl,
  data,
}: {
  slugUrl: string;
  shortCode?: string;
  data: GetProfileInfoResponse | undefined;
}) => {
  const { search, pathname } = useLocation();

  const slugFromUrl = pathname.split('/')[1];
  const slugCodeFromUrl = slugFromUrl.split('-');
  const slugFromApi = slugUrl.split('-');
  const slugCodeFromApi = slugFromApi[slugFromApi.length - 1];

  const WORKSPACE_ID = shortCode || '';

  const WORKSPACE_KEY = 'WORKSPACE_KEY';

  useEffect(() => {
    const handleStorage = (e: StorageEvent) => {
      const externalPaths = [TEMPLATE];
      const currentPath = window.location.pathname;
      if (!externalPaths.some((path) => currentPath.includes(path))) {
        const isUserLoggedInToAnotherAssembly =
          e.key === WORKSPACE_KEY &&
          !isEmpty(e.newValue) &&
          !isEmpty(WORKSPACE_ID) &&
          WORKSPACE_ID !== e.newValue;
        if (isUserLoggedInToAnotherAssembly) {
          window.location.href = '/404';
        }
      }
    };
    window.addEventListener('storage', handleStorage);
    window.localStorage.setItem(WORKSPACE_KEY, WORKSPACE_ID);
    return () => {
      window.removeEventListener('storage', handleStorage);
    };
  }, [WORKSPACE_ID]);

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    const fromMSTeams = Boolean(
      new URLSearchParams(search).get('fromMSTeams') || false,
    );
    if (fromMSTeams) {
      microsoftTeamsStore.getState().setIsLoggedInThroughNewTeamsApp(true);
    }
  }, [search]);

  useEffect(() => {
    const parsedParams = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isDevicePresent = parsedParams && parsedParams[DEVICE];
    if (isDevicePresent) {
      const { device } = parsedParams;
      if (device === DEVICES.IPHONE) {
        setSourceDeviceAsIPhone();
      }
    }
  }, [search]);

  if (data?.assembly) {
    if (
      !window.location.href.includes(`${window.location.origin}/admin`) &&
      !window.location.href.includes(shortCode || '') &&
      NO_SLUG_REDIRECT_ROUTES.some((route) =>
        window.location.href.includes(route),
      ) &&
      !NO_SLUG_NO_REDIRECT_ROUTES.some((route) =>
        window.location.pathname.startsWith(route),
      )
    ) {
      history.push(`/${slugUrl}${pathname}${search}`);
      return null;
    } else if (
      slugCodeFromApi === slugCodeFromUrl[slugCodeFromUrl.length - 1] &&
      slugFromUrl !== slugUrl
    ) {
      history.replace(
        `${window.location.pathname.replace(slugFromUrl, slugUrl)}${search}`,
      );
    } else if (
      window.location.href === `${APP_URL}${slugUrl}/` ||
      window.location.href === `${APP_URL}${slugUrl}`
    ) {
      history.replace(
        `${window.location.pathname.replace(`${slugFromUrl}/`, slugUrl)}/home`,
      );
    }
  }

  return <MultiAssemblyRouters slugUrl={slugUrl} />;
};

const Routers = () => {
  const authenticated = authStore.getState().authenticated;
  useMixPanelInitialization();
  useAdminSettingsUpdatedSocket();

  const {
    data,
    error,
    isError,
    isInitialLoading: isLoading,
  } = useProfileInfoFetchQuery(authenticated);

  const shortCode = data?.assembly.workspaceSlug.shortCode;
  const slugUrl = `${data?.assembly.workspaceSlug.name}-${shortCode}`;

  const splitConfig = getSplitIOFactory(data?.assembly?.assemblyId);

  useEffect(() => {
    const externalPaths = [TEMPLATE];
    const currentPath = window.location.pathname;
    if (
      isError &&
      error &&
      error.response &&
      error.response.status === HttpsStatus.UNAUTHORIZED &&
      !externalPaths.some((path) => currentPath.includes(path))
    ) {
      history.push(RoutesList.SIGN_OUT);
    }
  }, [error, isError]);

  const showLoadingSpinner = window.location.pathname.includes(INVITE_PEOPLE);
  if (isLoading && authenticated && showLoadingSpinner) {
    return (
      <Flex width="100vw" height="100vh" alignItems="center">
        <LoadingPulse size={24} />
      </Flex>
    );
  }

  if (isLoading && authenticated) {
    return <AssemblyLanderLoadingScreen />;
  }

  return (
    <SplitFactory factory={splitConfig} updateOnSdkUpdate>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(
          err: Error,
          info: {
            componentStack: string;
          },
        ) => {
          datadogLogs.logger.error('Error while rendering app', info, err);
        }}
      >
        <Router history={history}>
          <SplitV2 slugUrl={slugUrl} shortCode={shortCode} data={data} />
          <KioskFooter />
        </Router>
      </ErrorBoundary>
    </SplitFactory>
  );
};
export default Routers;
