import { SplitFactory } from '@splitsoftware/splitio';
import { SPLIT_AUTH_KEY } from '../config';
let factory: SplitIO.IBrowserSDK | null = null;

export function getSplitIOFactory(key?: string): SplitIO.IBrowserSDK {
  if (factory) {
    return factory;
  }

  const splitFactory = SplitFactory({
    core: {
      key: key ?? 'anonymous',
      authorizationKey: SPLIT_AUTH_KEY,
    },
  });

  if (key) {
    factory = splitFactory;
  }

  return splitFactory;
}

export function getFeatureFlagTreatment(featureFlagName: string) {
  if (!factory) {
    throw new Error('SplitIO factory not initialized');
  }

  return factory.client().getTreatment(featureFlagName);
}
