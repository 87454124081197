import { useMemo, useCallback } from 'react';

import useMobileApp from '../../../hooks/useMobileApp';

import {
  useNotificationFetchQuery,
  useMarkNotificationsAsReadMutation,
} from '../../../queries/Notifications';

import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { getAssemblyCurrency } from '../../../queries/Profile/utils';

import { NotificationTypes } from '../../../interfaces/Notifications';

import { serializeNotifications } from '../../../Utils/serializers/notifications';

import useModalsStore from '../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../stores/modalsStore/selectors';

import { Notification } from '../../../interfaces/Notifications';
import useToggle from '../../../hooks/useToggle';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../../interfaces/PostMessage';
import { trackDiscoverActionEvent } from '../../../Utils/analytics/discover';
import { DISCOVER_EVENTS } from '../../../Utils/analytics/constants';

export type HandleNotificationItemClickParamTypes = {
  id?: string;
  read?: boolean;
  flowId?: string;
  occurrenceId?: string;
  notificationType: string;
  linkURL: string;
};

const useNotificationsButtonController = () => {
  const { isMobileApp } = useMobileApp();
  const {
    models: { toggleValue: isUnreadActive },
    operations: {
      setToggleToTrue: handleUnreadClick,
      setToggleToFalse: handleShowAllClick,
    },
  } = useToggle();

  const { data: profileData } = useProfileInfoFetchQuery();
  const currency = useMemo(
    () => getAssemblyCurrency(profileData),
    [profileData],
  );

  const {
    data: notificationsData,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isError,
  } = useNotificationFetchQuery(isUnreadActive ? 'unread' : '');
  const { mutate } = useMarkNotificationsAsReadMutation();
  const formattedNotifications = useMemo(() => {
    if (!notificationsData) {
      return [];
    }
    const data = notificationsData.pages.reduce<Notification[]>(
      (arr, page) => [...arr, ...page.data.notifications],
      [],
    );
    return notificationsData && currency
      ? serializeNotifications(data, currency)
      : [];
  }, [currency, notificationsData]);

  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);

  const onMarkReadClick = useCallback(
    (id?: string) => {
      mutate(id);
    },
    [mutate],
  );

  const handleNotificationItemClick = ({
    read,
    notificationType,
    id,
    flowId,
    occurrenceId = '',
    linkURL,
  }: HandleNotificationItemClickParamTypes) => {
    if (!read) {
      onMarkReadClick(id);
    }

    if (isMobileApp) {
      if (
        notificationType === NotificationTypes.FLOW_TRIGGERED ||
        notificationType === NotificationTypes.FLOW_REMINDER
      ) {
        if (flowId) {
          postMessageToMobileApp({
            action: AppAction.NotificationClicked,
            payload: {
              linkURL: `/flows/${flowId}/answer?occurrenceId=${occurrenceId}`,
            },
          });
        }
      } else {
        postMessageToMobileApp({
          action: AppAction.NotificationClicked,
          payload: {
            linkURL,
          },
        });
      }
      return;
    }

    if (
      notificationType === NotificationTypes.FLOW_TRIGGERED ||
      notificationType === NotificationTypes.FLOW_REMINDER
    ) {
      if (flowId) {
        setParticipationFlow({
          participationFlowId: flowId,
          occurrenceId,
          canHandleAnswerWithShortcut: true,
        });
      }
    }
  };

  const handleSettingsButtonClick = useCallback(() => {
    if (isMobileApp) {
      postMessageToMobileApp({
        payload: {
          id: 'settings',
        },
        action: AppAction.NotificationSettingsClicked,
      });
    }
  }, [isMobileApp]);

  const trackNotificationMenuOpened = () => {
    trackDiscoverActionEvent({
      action: DISCOVER_EVENTS.NOTIFICATION_MENU_OPENED,
      v3enabled: false,
    });
  };

  const trackNotificationClicked = () => {
    trackDiscoverActionEvent({
      action: DISCOVER_EVENTS.NOTIFICATION_CLICKED,
      v3enabled: false,
    });
  };

  return {
    isMobileApp,
    handleSettingsButtonClick,
    notifications: formattedNotifications,
    unreadNotificationsCount:
      notificationsData?.pages[0].data.notificationCount || 0,
    isLoading,
    isError,
    isUnreadActive,
    onMarkReadClick,
    handleNotificationItemClick,
    hasNextPage,
    handleFetchNextPage: fetchNextPage,
    handleUnreadClick,
    handleAllMessageClick: handleShowAllClick,
    trackNotificationMenuOpened,
    trackNotificationClicked,
  };
};

export default useNotificationsButtonController;
