import React from 'react';
import { shallow } from 'zustand/shallow';
import Button from '../../atoms/Button';
import DateFilter from '../DateFilter';
import DrawerHeaderWithIcon from '../../molecules/DrawerHeaderWithIcon';
import CheckboxFilter from '../CheckboxFilter';
import {
  StyledFilterWrapper,
  StyledSpaceSeparator,
  StyledButton,
  StyledFlex,
  StyledScrollWrapper,
} from './styles';

import { DateFilterEmptyProps } from '../DateFilter/types';
import { CheckboxFilterEmptyProps } from '../CheckboxFilter/types';
import { ComponentStatus } from '../../../interfaces/component';

import {
  DATES,
  FLOW,
  VERSION,
  BLOCK,
  MENTIONED,
  CLEAR,
} from '../../../languages/en/singleWords';
import {
  FILTER_BY,
  APPLY_FILTERS,
  POSTED_BY,
} from '../../../languages/en/flows';

import { capitalizeFirstLetter } from '../../../Utils/text';
import useLayoutStore from '../../../stores/layoutStore';
import useKioskStore from '../../../stores/kioskStore';
import useMobileApp from '../../../hooks/useMobileApp';

type EmptyComponentProps = {
  isMainFeedFilterEmpty?: boolean;
  isFlowFeedFilterEmpty?: boolean;
  isProfileFeedFilterEmpty?: boolean;
  isKnowledgeCenterFilterEmpty?: boolean;
};

const EmptyComponent = (props: EmptyComponentProps) => {
  const {
    isMainFeedFilterEmpty,
    isFlowFeedFilterEmpty,
    isProfileFeedFilterEmpty,
    isKnowledgeCenterFilterEmpty,
  } = props;
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const { isMobileApp } = useMobileApp();
  const { isRightAsideOpen, toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const dateFilterProps: DateFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: DATES,
  };
  const flowFilterProps: CheckboxFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: capitalizeFirstLetter(FLOW),
  };
  const versionFilterProps: CheckboxFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: VERSION,
  };
  const blockFilterProps: CheckboxFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: BLOCK,
  };
  const postedByFilterProps: CheckboxFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: POSTED_BY,
  };
  const mentionedByFilterProps: CheckboxFilterEmptyProps = {
    status: ComponentStatus.EMPTY,
    headingText: MENTIONED,
  };

  return (
    <StyledScrollWrapper isTab isMobileApp={isMobileApp}>
      <div>
        <DrawerHeaderWithIcon icon="filter-by" onClick={toggleRightAsideOpen}>
          {FILTER_BY}
        </DrawerHeaderWithIcon>
        <StyledFilterWrapper>
          <DateFilter {...dateFilterProps} />
          <StyledSpaceSeparator />
          {(isMainFeedFilterEmpty ||
            isProfileFeedFilterEmpty ||
            isKnowledgeCenterFilterEmpty) && (
            <CheckboxFilter {...flowFilterProps} />
          )}
          <StyledSpaceSeparator />
          {isFlowFeedFilterEmpty && <CheckboxFilter {...versionFilterProps} />}
          <StyledSpaceSeparator />
          {isFlowFeedFilterEmpty && <CheckboxFilter {...blockFilterProps} />}
          <StyledSpaceSeparator />
          {(isMainFeedFilterEmpty ||
            isFlowFeedFilterEmpty ||
            isKnowledgeCenterFilterEmpty) && (
            <CheckboxFilter {...postedByFilterProps} />
          )}
          <StyledSpaceSeparator />
          <CheckboxFilter {...mentionedByFilterProps} />
          <StyledSpaceSeparator />
        </StyledFilterWrapper>
      </div>
      <StyledFlex
        isKioskTurnedOn={isKioskTurnedOn}
        isRightAsideOpen={isRightAsideOpen}
        justifyContent="center"
      >
        <StyledButton
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          disabled
        >
          {APPLY_FILTERS}
        </StyledButton>
        <Button
          color="primary"
          onClick={() => {}}
          size="medium"
          status="default"
          variant="text"
          disabled
        >
          {CLEAR}
        </Button>
      </StyledFlex>
    </StyledScrollWrapper>
  );
};

export default EmptyComponent;
