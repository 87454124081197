import { useEffect } from 'react';

import { fireConversionForEmployerOnboard } from '../../Utils/analytics/gtag';
import FlowsOnboardingWithQuickSetupController from './FlowsOnboardingWithQuickSetup';

const UserOnBoardingController = () => {
  useEffect(() => {
    fireConversionForEmployerOnboard();
  }, []);

  return <FlowsOnboardingWithQuickSetupController />;
};

export default UserOnBoardingController;
