import React from 'react';

import Body from '../../../atoms/Body';
import Heading from '../../../atoms/Heading';
import Divider from '../../../atoms/Divider';

import { TrendsTabPanelProps } from './type';

import ThemeV2 from '../../../../theme';
import { TrendsLoader, WidgetLoader } from '../Loader';
import { Flex } from '../../../../Utils/styles/display';

import {
  Widget,
  StyledBody,
  TabChildren,
  StyledHeading,
  LoaderWrapper,
  ContentWrapper,
  WidgetContainer,
} from './styles';

import {
  OVERVIEW_LABEL,
  BLOCK_BY_BLOCK_LABEL,
  OVERVIEW_DESCRIPTION,
  BLOCK_BY_BLOCK_DESCRIPTION,
  PARTICIPATION_OVER_TIME_LABEL,
} from '../../../../languages/en/flows/insights';

const TrendsTabPanel = (props: TrendsTabPanelProps) => {
  const { isLoading, widgets, showParticipationRateBlock } = props;

  return (
    <TabChildren>
      <Heading variant="h5">{OVERVIEW_LABEL}</Heading>
      <Body variant="body1" color="gray8">
        {OVERVIEW_DESCRIPTION}
      </Body>

      <Divider isFullWidth marginTop="16px" color={ThemeV2.palette.white} />

      <Heading variant="h6">{PARTICIPATION_OVER_TIME_LABEL}</Heading>

      {isLoading && (
        <LoaderWrapper>
          <TrendsLoader />
        </LoaderWrapper>
      )}

      {
        <ContentWrapper isLoading={isLoading}>
          {showParticipationRateBlock && (
            <>
              <Body variant="body1" color="gray8">
                Completion rate by week
              </Body>
              <Widget id="rate_over_time" />
            </>
          )}
          <Body variant="body1" color="gray8">
            Total responses by week
          </Body>
          <Widget id="respondants_by_week" />
          <Body variant="body1" color="gray8">
            Percentage of blocks completed over time
          </Body>
          <Widget id="percentage_of_blocks_completed_overtime" />
        </ContentWrapper>
      }

      <Divider
        isFullWidth
        marginTop="24px"
        marginBottom="24px"
        color={ThemeV2.palette.gray4}
      />

      <Heading variant="h5">{BLOCK_BY_BLOCK_LABEL}</Heading>
      <Body variant="body1" color="gray8" margin="0 0 24px 0">
        {BLOCK_BY_BLOCK_DESCRIPTION}
      </Body>

      {isLoading && (
        <LoaderWrapper>
          <WidgetLoader />
        </LoaderWrapper>
      )}

      {!isLoading &&
        widgets &&
        widgets.map((widget) => (
          <WidgetContainer key={widget.id}>
            <Flex>
              <StyledHeading variant="h6">{widget.title}</StyledHeading>
              {widget.isRequired && (
                <StyledHeading color="red" variant="h6">
                  *
                </StyledHeading>
              )}
            </Flex>

            {widget.description && (
              <StyledBody variant="body1" color="gray8">
                {widget.description}
              </StyledBody>
            )}
            <div id={widget.id} />
            <div id={widget.id + 'widgetOne'} />
            <div id={widget.id + 'widgetTwo'} />
          </WidgetContainer>
        ))}
    </TabChildren>
  );
};

export default TrendsTabPanel;
