import { useState } from 'react';
import InviteModalController from '../InviteModalController';

const InvitePeopleController = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const handleCloseInviteModal = () => {
    setModalOpen(false);
    window.parent.postMessage(
      { type: 'INVITE_MODAL_CLOSE', payload: location.pathname },
      '*',
    );
  };
  return (
    <div>
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={handleCloseInviteModal}
        isFullScreen
      />
    </div>
  );
};

export default InvitePeopleController;
