import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { PostInteractionSettingsUpdatedPayload } from './types';
import {
  GET_FLOW_FEED,
  GET_MAIN_FEED,
  GET_PROFILE_FEED,
} from '../../constants/endpoints';
import { PaginationResponse } from '../../queries/Flows/interfaces';
import { FlowFeedResponse } from '../../queries/Flows/Feed/interfaces';
import produce from 'immer';
import { findInPaginatedFlowFeedData } from '../../queries/Flows/Feed/utils';
import { GetFeedResponse } from '../../interfaces/Feed';
import { findInPaginatedFeedData } from '../../queries/Feed/utils';

export const updateInteractionSettingsStatusUpdated = ({
  queryKey,
  eventName,
  queryClient,
  socketPayload,
}: {
  eventName: string;
  queryKey: QueryKey;
  queryClient: QueryClient;
  socketPayload: PostInteractionSettingsUpdatedPayload;
}) => {
  let flowFeedPreviousData, singleFlowFeedPreviousData, previousFeedData;
  const selectedQueryKey = queryKey[0];
  const queryData = queryClient.getQueryData(queryKey);
  switch (selectedQueryKey) {
    case GET_FLOW_FEED:
      flowFeedPreviousData = queryData as InfiniteData<
        PaginationResponse<FlowFeedResponse>
      >;
      break;
    case GET_PROFILE_FEED:
    case GET_MAIN_FEED:
      previousFeedData = queryData as InfiniteData<GetFeedResponse> | undefined;
      break;
    default:
      singleFlowFeedPreviousData = queryData as FlowFeedResponse;
      break;
  }
  if (flowFeedPreviousData) {
    const updatedData = produce(flowFeedPreviousData, (draft) => {
      const flowPost = findInPaginatedFlowFeedData(
        draft.pages,
        socketPayload.responseId || '',
      );
      if (flowPost) {
        if (eventName === 'RESPONSE_REACTIONS_SETTING') {
          flowPost.hideReactions = socketPayload.hideReactions;
        }
        if (eventName === 'RESPONSE_REPLIES_SETTING') {
          flowPost.hideReplies = socketPayload.hideReplies;
        }
      }
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
  if (singleFlowFeedPreviousData) {
    const updatedData = produce(singleFlowFeedPreviousData, (flowPost) => {
      if (eventName === 'RESPONSE_REACTIONS_SETTING') {
        flowPost.hideReactions = socketPayload.hideReactions;
      }
      if (eventName === 'RESPONSE_REPLIES_SETTING') {
        flowPost.hideReplies = socketPayload.hideReplies;
      }
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
  if (previousFeedData) {
    const updatedData = produce(previousFeedData, (draft) => {
      const flowPost = findInPaginatedFeedData(
        draft.pages,
        socketPayload.responseId || '',
      );
      if (flowPost?.flowResponse) {
        if (eventName === 'RESPONSE_REACTIONS_SETTING') {
          flowPost.flowResponse.hideReactions = socketPayload.hideReactions;
        }
        if (eventName === 'RESPONSE_REPLIES_SETTING') {
          flowPost.flowResponse.hideReplies = socketPayload.hideReplies;
        }
      }
    });
    queryClient.setQueryData(queryKey, updatedData);
  }
};
