import styled from 'styled-components';

import ThemeV2 from '../../../../theme';
import { ConnectionState } from './types';
import ConnectToSlackButton from '../../../../atomic/organism/ConnectToSlack';
import Clickable from '../../../../atomic/atoms/Clickable_V2';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';

export const Container = styled.section`
  padding: 24px;
  overflow-y: auto;
  max-height: 80vh;
`;

export const SlackContainer = styled.section<{
  connectionState: ConnectionState;
}>`
  margin: 8px 0;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray5};
  background-color: ${({ connectionState }) =>
    connectionState === ConnectionState.ConnectionDisplayState
      ? ThemeV2.palette.gray2
      : 'unset'};

  display: flex;
  flex-direction: column;
`;

export const TeamsContainer = styled.section`
  margin-top: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${ThemeV2.palette.gray5};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ZapierContainer = styled.section`
  margin-top: 8px;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${ThemeV2.palette.gray5};
`;

export const ZapierHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ZapierBodyDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  height: 24px;
`;

export const StyledClickable = styled(Clickable)`
  color: ${ThemeV2.palette.gray9};
  font-size: 14px;
  text-decoration: underline;
  margin-left: 8px;
`;

export const StyledConnectToSlackButton = styled(ConnectToSlackButton)`
  width: 100%;
  margin-top: 8px;
`;

export const ActiveBadge = styled.div`
  margin-left: 8px;
  padding: 1px 8px;
  border-radius: 100px;
  background: ${ThemeV2.palette.geekBlue2};
`;

export const DisconnectedBadge = styled.div`
  margin-left: 8px;
  padding: 1px 8px;
  border-radius: 100px;
  background: ${ThemeV2.palette.dustRed2};
`;

export const Link = styled.u`
  cursor: pointer;
`;

export const ConnectionAppIcon = styled(SVGIcon)`
  width: 78px;
  height: 28px;
`;
