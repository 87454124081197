import { trackEvent } from '../index';
import { PeopleDirectoryEventProps } from '../interfaces';
import { AnalyticsEvents, PEOPLE_DIRECTORY_EVENT } from '../constants';

const trackPeopleDirectoryActionEvent = (
  eventProps: PeopleDirectoryEventProps,
) => {
  trackEvent(PEOPLE_DIRECTORY_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export { trackPeopleDirectoryActionEvent };
