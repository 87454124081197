import isEmpty from 'lodash/isEmpty';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';

import { MenuItemIndividualItem } from '../../../../../atomic/molecules/Dropdown_V2/interfaces';
import { AutocompleteDropdownItem } from '../../../../../atomic/organism/Autocomplete/interfaces';
import {
  useGetMembersFromCriteriaMutation,
  useMembersSearch,
} from '../../../../../hooks/useMembersSearch';
import { FlowBuilderBlockTypes } from '../../../../../interfaces/Flow/Builder';
import useFlowBuilderStore from '../../../../../stores/flowBuilderStore';
import { setSpecificBlockDataSelector } from '../../../../../stores/flowBuilderStore/selectors';
import {
  mapMemberDetailsToAutoCompleteDropdownData,
  mapMembersFromExclusionCriteria,
  mapParticipationAndVisibilityDataToSimpleRules,
} from '../../../../../Utils/flows';
import { mapCriteriaResponseToBlockData } from '../../../../../Utils/flows/builder/utils';
import {
  mapRulesFromCriteriaGroupsAndReturnDepartmentsOnly,
  mapRulesFromConnectionsAndReturnSlackChannels,
  sortItemsForSimpleShareSheet,
  mapRulesFromChannelsAndReturnSlackChannels,
  getSimpleShareSheetTypeFromBulkSelection,
} from '../../../../../Utils/flows/criteria';
import {
  getShareSheetCardDataFromAutoCompleteOption,
  getUpdatedSelectedItemsBasedOnDropdownSelection,
  serializeSimpleShareSheetRulesForAPIPayload,
} from '../../../../../Utils/flows/sharesheet';
import { isValidEmail } from '../../../../../Utils/validation';
import {
  ShareSheetRuleAccessTypes,
  SimplifiedShareSheetCardData,
  SimplifiedShareSheetDataTypes,
  VisibilityTypes,
} from '../../types';
import {
  animationEffect,
  animationOptions,
  bulkMemberActions,
  BulkMemberRuleAvatarMap,
  canOnlyPostSetting,
  canOnlyViewPostsSetting,
  canPostAndViewPostsSetting,
  getInviteUserCard,
  noResultsFoundCard,
  ShareSheetMemberActions,
} from '../data';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../../../../queries/Profile';
import { MemberState } from '../../../../../interfaces/user';
import { IMemberDTO, MemberRole } from '../../../../../interfaces/member';
import { useGetAllDepartmentsQuery } from '../../../../../queries/FlowBuilder/Participants';
import { useFetchMembersBasedOnFlowCriteria } from '../useFetchMembersBasedOnFlowCriteria';
import { useImmer } from 'use-immer';
import {
  canInviteMembers,
  getCanShowParticipationCriteriaEveryone,
} from '../../../../../Utils/permissions';
import { trackShareSheetActionEvent } from '../../../../../Utils/analytics/shareSheet';
import { SHARE_SHEET_EVENTS } from '../../../../../Utils/analytics/constants';
import useSlackUserStore from '../../../../../stores/userStore';
import { useParams } from 'react-router-dom';
import useRequestedUsersForAccessStore from '../../../../../stores/sharesheetStore';
import { useGetUserLocation } from '../../../../../queries/Settings';
import { BulkRuleTitleMap } from '../../../../../atomic/molecules/ShareSheet/BulkRuleSelector';
import { createMessage } from '../../../../../Utils/common';
import {
  CHANNEL_CARD_TOOLTIP,
  DEPARTMENT_CARD_TOOLTIP,
  MEMBER_CARD_TOOLTIP,
} from '../messages';

const BulkMemberActionKeyMap = {
  homeLocation: 'HOME_LOCATION',
  workLocation: 'WORK_LOCATION',
  jobTitle: 'JOB_TITLE',
};

const PREVIEW_PAGE_SIZE = 30;

export const useFlowsSimpleShareSheet = ({
  handleOnModifiedChanges,
  handleMembersToBeInvited,
}: {
  handleOnModifiedChanges: (hasDataChanged: boolean) => void;
  handleMembersToBeInvited: (members: string[]) => void;
}) => {
  const {
    requestedUsers,
    setRequestedUsers,
    updatedRequestedUserID,
    setUpdatedRequestedUserID,
  } = useRequestedUsersForAccessStore();

  const [textBoxValue, setTextBoxValue] = useState<string>('');
  const [selectedItems, setSelectedItems] = useState<
    SimplifiedShareSheetCardData[]
  >([]);

  const [value, setValue] = useState<AutocompleteDropdownItem<string> | null>(
    null,
  );

  const [nonMemberItemSelectionType, setNonMemberItemSelectionType] = useState<
    'department' | 'workLocation' | 'homeLocation' | 'jobTitle' | null
  >(null);

  /*
  TOOLTIP CONTENT LOGIC:
  1.When we have a department card with only members card and everyone card is not present,
  department cards will have no tooltip, only member cards will.
  2.When we have only members card, member cards will have no tooltip.
  3.When we have an everyone card, along with department card and member card,
  both member card and department card will have tooltip.
*/
  const tooltipContent = useCallback(
    (currentType: string, currentSetting: string) => {
      // Channel card tooltip
      if (currentType === SimplifiedShareSheetDataTypes.Channel) {
        return createMessage(CHANNEL_CARD_TOOLTIP, currentSetting);
      }

      const hasEveryoneCard = selectedItems.some(
        (i) => i.type === SimplifiedShareSheetDataTypes.Everyone,
      );
      const hasDepartmentCard = selectedItems.some(
        (i) => i.type === SimplifiedShareSheetDataTypes.Department,
      );
      const hasMemberCard = selectedItems.some(
        (i) => i.type === SimplifiedShareSheetDataTypes.Member,
      );

      const everyoneCardTitle = selectedItems.find(
        (i) => i.type === SimplifiedShareSheetDataTypes.Everyone,
      )?.title;

      // Department card tooltip
      if (currentType === SimplifiedShareSheetDataTypes.Department) {
        if (hasEveryoneCard && hasMemberCard) {
          return createMessage(DEPARTMENT_CARD_TOOLTIP, everyoneCardTitle);
        }
      }

      // Member card tooltip
      if (currentType === SimplifiedShareSheetDataTypes.Member) {
        if (hasEveryoneCard || hasDepartmentCard) {
          return createMessage(MEMBER_CARD_TOOLTIP);
        }
      }

      return undefined;
    },
    [selectedItems],
  );
  const {
    models: {
      hasMoreMembers,
      searchedMembers,
      isLoading: isMembersLoading,
      isFetching: isMembersFetching,
      canEmailInvite,
    },
    operations: { fetchMoreMembers, onChange, refetchMembers },
  } = useMembersSearch(true);

  const { flowId } = useParams<{ flowId: string }>();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');

  const { data: profileInfo } = useProfileInfoFetchQuery();

  const canInviteMembersFromShareSheet = canInviteMembers(
    profileInfo?.member,
    profileInfo?.assembly.settings,
    true,
  );

  const currentUserId = useMemo(() => {
    return profileInfo?.member?.memberId;
  }, [profileInfo]);

  const {
    isParticipantsCriteriaLoading,
    participantsSelected,
    isEveryoneCriteriaBeingFetched,
    totalParticipantsCount,
    isViewerCriteriaLoading,
    membersWithVisibility,
    participantsCriteria,
    visibilityCriteria,
    membersCountForEveryoneCriteria,
    fetchMoreMembers: fetchMoreMembersForDisplay,
    hasMoreMembers: hasMoreMembersToDisplay,
    excludedMembers,
    isExclusionCriteriaLoading = false,
  } = useFetchMembersBasedOnFlowCriteria({
    enableFetch: isEmpty(selectedItems),
    isMembersOnly: true,
  });

  const { mutateAsync: getDepartmentFromMemberCriteriaMutation } =
    useGetMembersFromCriteriaMutation();

  const { data: departments, isInitialLoading: isDepartmentsLoading } =
    useGetAllDepartmentsQuery();

  const {
    isInitialLoading: isBulkRuleSelectorDataLoading,
    data: bulkMemberActionsDropdownData,
  } = useGetUserLocation(['JOB_TITLE', 'HOME_LOCATION', 'WORK_LOCATION']);

  const departmentOptions: AutocompleteDropdownItem<string>[] = useMemo(() => {
    if (departments) {
      return departments.data.map((department: string) => {
        return {
          title: department,
          id: department,
        };
      });
    }
    return [];
  }, [departments]);

  const bulkRuleDropdownOptions: AutocompleteDropdownItem<string>[] =
    useMemo(() => {
      if (
        !bulkMemberActionsDropdownData ||
        nonMemberItemSelectionType === null
      ) {
        return [];
      } else {
        if (nonMemberItemSelectionType === 'department') {
          return departmentOptions;
        }
        if (
          nonMemberItemSelectionType === 'homeLocation' ||
          nonMemberItemSelectionType === 'workLocation' ||
          nonMemberItemSelectionType === 'jobTitle'
        ) {
          return bulkMemberActionsDropdownData.data
            .filter(
              (location) =>
                location.key ===
                BulkMemberActionKeyMap[nonMemberItemSelectionType],
            )[0]
            .values.map((val) => {
              return {
                title: val,
                id: val,
              };
            });
        } else {
          return [];
        }
      }
    }, [
      bulkMemberActionsDropdownData,
      departmentOptions,
      nonMemberItemSelectionType,
    ]);

  const [departmentCountMap, setDepartmentCountMap] = useImmer<
    Record<string, number>
  >({});

  const {
    selectedPeopleToPostChannels,
    selectedPostFlowResponseChannels,
    selectedConnectionName,
  } = useSlackUserStore();

  const { isConnectionsTabTouched, flowName } = useFlowBuilderStore();

  const getSlackChannelInformation = useCallback(() => {
    let slackChannelItems: SimplifiedShareSheetCardData[] = [];

    if (isConnectionsTabTouched) {
      slackChannelItems = mapRulesFromChannelsAndReturnSlackChannels({
        slackConnectionsParticipantsChannelsList: selectedPeopleToPostChannels,
        slackConnectionsVisibilityChannelsList:
          selectedPostFlowResponseChannels,
        workspaceName: selectedConnectionName,
      });
    } else {
      slackChannelItems = mapRulesFromConnectionsAndReturnSlackChannels({
        slackConnectionsParticipantsChannelsList:
          flowDetails?.data?.integrations?.participationNotification,
        slackConnectionsVisibilityChannelsList:
          flowDetails?.data?.integrations?.responseNotification,
      });
    }
    return slackChannelItems;
  }, [
    flowDetails?.data?.integrations?.participationNotification,
    flowDetails?.data?.integrations?.responseNotification,
    isConnectionsTabTouched,
    selectedPeopleToPostChannels,
    selectedPostFlowResponseChannels,
    selectedConnectionName,
  ]);

  useEffect(() => {
    // Since we use enabled flag with this query, invalidation will not work
    // https://tanstack.com/query/latest/docs/react/guides/disabling-queries
    // We need to trigger refetch manually
    return () => {
      refetchMembers();
    };
  }, [refetchMembers]);

  const memberOptions = useMemo(() => {
    if (searchedMembers) {
      if (canEmailInvite) {
        return [
          {
            firstName: '',
            lastName: '',
            memberState: MemberState.PENDING,
            email: textBoxValue,
            role: [MemberRole.Employee],
            username: textBoxValue,
            memberID: 'invite-user-card',
          },
        ] as IMemberDTO[];
      }
      return searchedMembers;
    }
    return [];
  }, [canEmailInvite, searchedMembers, textBoxValue]);

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const hasNonOwnerParticipants = useMemo(
    () =>
      selectedItems.some(
        (item) =>
          !item.isOwner &&
          (item.setting?.id === ShareSheetMemberActions.canPostAndViewPosts ||
            item.setting?.id === ShareSheetMemberActions.canOnlyPost),
      ),
    [selectedItems],
  );

  useEffect(() => {
    if (
      !isParticipantsCriteriaLoading &&
      !isEmpty(participantsCriteria) &&
      !isViewerCriteriaLoading &&
      !isEmpty(visibilityCriteria) &&
      !isExclusionCriteriaLoading
    ) {
      if (
        participantsSelected !== undefined &&
        membersWithVisibility !== undefined &&
        (selectedItems.length === 0 ||
          selectedItems.length % PREVIEW_PAGE_SIZE === 0)
      ) {
        const nonMemberItems = [
          ...mapRulesFromCriteriaGroupsAndReturnDepartmentsOnly(
            participantsCriteria,
            visibilityCriteria,
            totalParticipantsCount,
          ),
        ];

        const excludedMemberItems = mapMembersFromExclusionCriteria({
          members: excludedMembers,
          flowOwnerId: flowDetails?.data?.owner?.memberID || '',
          flowCollaborators: flowDetails?.data.collaborators,
        });

        const mappedDataForSimpleRules =
          mapParticipationAndVisibilityDataToSimpleRules({
            participants: participantsSelected || [],
            membersWithVisibility: membersWithVisibility || [],
            flowOwner: flowDetails?.data?.owner,
            flowCollaborators: flowDetails?.data.collaborators,
          });

        const slackChannelItems = getSlackChannelInformation();

        const sortedItems = sortItemsForSimpleShareSheet([
          ...slackChannelItems,
          ...nonMemberItems,
          ...mappedDataForSimpleRules,
          ...excludedMemberItems,
        ]);

        setSelectedItems(sortedItems);
      }
    }
  }, [
    isViewerCriteriaLoading,
    isParticipantsCriteriaLoading,
    membersWithVisibility,
    participantsSelected,
    flowDetails?.data?.owner,
    participantsCriteria,
    selectedItems,
    totalParticipantsCount,
    visibilityCriteria,
    flowDetails?.data.integrations?.participationNotification,
    flowDetails?.data.integrations?.responseNotification,
    hasNonOwnerParticipants,
    getSlackChannelInformation,
    isExclusionCriteriaLoading,
    excludedMembers,
    flowDetails?.data.collaborators,
    currentUserId,
  ]);

  useEffect(() => {
    onChange(textBoxValue);
  }, [onChange, textBoxValue]);

  const canShowEveryoneCriteria =
    getCanShowParticipationCriteriaEveryone(profileInfo);

  const getBlockDataFromSelectedItems = useCallback(
    (items: SimplifiedShareSheetCardData[]) => {
      const participants = items.filter(
        (i) =>
          i.type !== SimplifiedShareSheetDataTypes.Channel &&
          (i.setting?.id === ShareSheetMemberActions.canOnlyPost ||
            i.setting?.id === ShareSheetMemberActions.canPostAndViewPosts ||
            i.setting?.id === ShareSheetMemberActions.excludeFromFlow),
      );
      const viewers = items.filter(
        (i) =>
          i.type !== SimplifiedShareSheetDataTypes.Channel &&
          (i.setting?.id === ShareSheetMemberActions.canOnlyViewPosts ||
            i.setting?.id === ShareSheetMemberActions.canPostAndViewPosts ||
            i.setting?.id === ShareSheetMemberActions.excludeFromFlow),
      );
      const participationRules =
        serializeSimpleShareSheetRulesForAPIPayload(participants);
      const serializedParticipationCriteria = participationRules
        ? mapCriteriaResponseToBlockData(participationRules, true)
        : undefined;

      const visibilityRules =
        serializeSimpleShareSheetRulesForAPIPayload(viewers);
      const serializedViewingCriteria = visibilityRules
        ? mapCriteriaResponseToBlockData(visibilityRules, true)
        : undefined;

      return {
        serializedParticipationCriteria,
        serializedViewingCriteria,
        participants,
        viewers,
      };
    },
    [],
  );

  useEffect(() => {
    const membersToBeInvited = selectedItems
      .filter((i) => !isEmpty(i.emailToInvite))
      .map((i) => i.emailToInvite || '');

    if (membersToBeInvited.length > 0) {
      handleMembersToBeInvited(membersToBeInvited);
    }

    const {
      participants,
      viewers,
      serializedParticipationCriteria,
      serializedViewingCriteria,
    } = getBlockDataFromSelectedItems(selectedItems);

    if (participants.length > 0 && serializedParticipationCriteria) {
      setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
        participantsCriteria: serializedParticipationCriteria,
      });
    }

    if (viewers.length > 0 && serializedViewingCriteria) {
      setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
        criteriaGroups: serializedViewingCriteria || undefined,
        everyone: false,
        onlyParticipants: false,
        onlyOwnersAndCollaborators: false,
        custom: true,
        type: VisibilityTypes.CUSTOM,
      });
    }
  }, [
    getBlockDataFromSelectedItems,
    handleMembersToBeInvited,
    handleOnModifiedChanges,
    selectedItems,
    setSpecificBlockData,
  ]);

  const displayOptions = useMemo(() => {
    const individualMembers =
      mapMemberDetailsToAutoCompleteDropdownData(memberOptions);

    return [bulkMemberActions, ...(individualMembers || [])];
  }, [memberOptions]);

  const resetAutoCompleteDropdown = useCallback(
    (inputRef: MutableRefObject<HTMLInputElement | null>) => {
      if (inputRef?.current) {
        inputRef.current.blur();
        inputRef.current.value = '';
      }
    },
    [],
  );

  const scrollToOption = useCallback(
    (optionId: string, inputRef: MutableRefObject<HTMLInputElement | null>) => {
      const element = document.getElementById(`displayCard-${optionId}`);
      if (element) {
        element.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
        element.animate(animationEffect, animationOptions);
        setTimeout(() => {
          setValue(null);
          resetAutoCompleteDropdown(inputRef);
          setTextBoxValue('');
        }, 500);
      }
    },
    [resetAutoCompleteDropdown],
  );

  const scrollToTop = () => {
    const element = document.getElementById('simpleShareSheetPreviewPeople');
    if (element) {
      element.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  // handle approve/deny from request section
  useEffect(() => {
    if (updatedRequestedUserID) {
      const existingUser = selectedItems.find(
        (item) => item.id === updatedRequestedUserID,
      );
      const updatedRequestedUser = requestedUsers.find(
        (user) =>
          user.memberID === updatedRequestedUserID &&
          user.requestStatus === 'APPROVED',
      );
      const getPermissionSetting = (permission: ShareSheetMemberActions) => {
        switch (permission) {
          case ShareSheetMemberActions.canOnlyPost:
            return canOnlyPostSetting;
          case ShareSheetMemberActions.canOnlyViewPosts:
            return canOnlyViewPostsSetting;
          default:
            return canPostAndViewPostsSetting;
        }
      };
      if (existingUser) {
        const isPermissionChanged =
          existingUser.setting?.id !== updatedRequestedUser?.permission;
        const updatedSelectedItems = selectedItems.map((item) => {
          if (
            isPermissionChanged &&
            item.id === updatedRequestedUser?.memberID
          ) {
            return {
              ...existingUser,
              setting: getPermissionSetting(updatedRequestedUser.permission),
            };
          } else {
            return item;
          }
        });
        setSelectedItems([...updatedSelectedItems]);
        setUpdatedRequestedUserID('');
      } else {
        if (updatedRequestedUser) {
          const optionCreatedFromRequestedUsers: AutocompleteDropdownItem<string> =
            {
              id: updatedRequestedUser.memberID,
              title: updatedRequestedUser.name,
              email: updatedRequestedUser.email,
              avatar: {
                img: updatedRequestedUser.image,
                name: updatedRequestedUser.name,
                userId: updatedRequestedUser.memberID,
              },
              memberState: updatedRequestedUser.memberState as MemberState,
              ...(updatedRequestedUser.memberState === 'PENDING' && {
                icon: 'circular-pending-user',
                emailToInvite: updatedRequestedUser.email,
              }),
            };
          const currentSelectionCardData =
            getShareSheetCardDataFromAutoCompleteOption({
              option: optionCreatedFromRequestedUsers,
              setting: getPermissionSetting(updatedRequestedUser.permission),
              type: SimplifiedShareSheetDataTypes.Member,
            });
          const updatedSelectedItems = [
            currentSelectionCardData,
            ...selectedItems,
          ];
          setSelectedItems([...updatedSelectedItems]);
          setUpdatedRequestedUserID('');
        }
      }
      handleOnModifiedChanges(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRequestedUserID]);

  const handleAutoCompleteOptionSelection = useCallback(
    (
      selectedAutoCompleteOption: AutocompleteDropdownItem<string>,
      inputRef: MutableRefObject<HTMLInputElement | null>,
    ) => {
      setValue(null);
      const isExistingInSelectedItems =
        selectedItems.findIndex((option) =>
          selectedAutoCompleteOption?.id === 'invite-user-card'
            ? selectedAutoCompleteOption?.emailToInvite === option?.id
            : option.id === selectedAutoCompleteOption?.id,
        ) > -1;
      if (selectedAutoCompleteOption.id === 'bulk-member-actions') return;
      handleOnModifiedChanges(true);
      if (isExistingInSelectedItems) {
        scrollToOption(
          selectedAutoCompleteOption?.id === 'invite-user-card' &&
            selectedAutoCompleteOption?.emailToInvite
            ? selectedAutoCompleteOption?.emailToInvite
            : selectedAutoCompleteOption.id,
          inputRef,
        );
      } else {
        scrollToTop();
        const currentSelectionCardData =
          getShareSheetCardDataFromAutoCompleteOption({
            option: selectedAutoCompleteOption,
            setting: canPostAndViewPostsSetting,
            type: SimplifiedShareSheetDataTypes.Member,
          });
        const modifiedSelectedItems = [
          currentSelectionCardData,
          ...selectedItems,
        ];
        const { serializedParticipationCriteria, serializedViewingCriteria } =
          getBlockDataFromSelectedItems(modifiedSelectedItems);

        trackShareSheetActionEvent({
          action: SHARE_SHEET_EVENTS.MEMBER_CLICKED,
          tab: 'invite and share',
          ownerId: currentUserId,
          flowName: flowName,
          participantCriteriaSelected: serializedParticipationCriteria,
          viewerCriteriaSelected: serializedViewingCriteria,
          ruleType: ShareSheetRuleAccessTypes.Simple,
        });

        // update requestedUsers if the selected user is in the list
        const updatedUsers = requestedUsers.map((user) => {
          if (user.memberID === selectedAutoCompleteOption.id) {
            return {
              ...user,
              requestStatus: 'APPROVED' as const,
            };
          }
          return user;
        });
        setRequestedUsers(updatedUsers);

        setSelectedItems(modifiedSelectedItems);
      }
    },
    [
      selectedItems,
      handleOnModifiedChanges,
      scrollToOption,
      getBlockDataFromSelectedItems,
      currentUserId,
      flowName,
      requestedUsers,
      setRequestedUsers,
    ],
  );

  const handleBulkMemberActionSelection = useCallback(
    (
      actionType:
        | 'department'
        | 'everyone'
        | 'jobTitle'
        | 'workLocation'
        | 'homeLocation',
    ) => {
      let currentItem: SimplifiedShareSheetCardData | undefined;
      if (
        actionType === 'department' ||
        actionType === 'jobTitle' ||
        actionType === 'workLocation' ||
        actionType === 'homeLocation'
      ) {
        setNonMemberItemSelectionType(actionType);
      } else if (actionType === 'everyone') {
        currentItem = {
          id: 'everyone',
          title: 'Everyone in this workspace',
          setting: canPostAndViewPostsSetting,
          icon: 'everyone',
          email: `${totalParticipantsCount} members`,
          type: SimplifiedShareSheetDataTypes.Everyone,
        };

        if (currentItem) {
          const modifiedSelectedItems = [currentItem, ...selectedItems];
          setSelectedItems(modifiedSelectedItems);
          handleOnModifiedChanges(true);
          const { serializedParticipationCriteria, serializedViewingCriteria } =
            getBlockDataFromSelectedItems(modifiedSelectedItems);
          trackShareSheetActionEvent({
            action: SHARE_SHEET_EVENTS.EVERY_ONE_RULE_CLICKED,
            tab: 'invite and share',
            participantCriteriaSelected: serializedParticipationCriteria,
            viewerCriteriaSelected: serializedViewingCriteria,
            ownerId: currentUserId,
            ruleType: ShareSheetRuleAccessTypes.Simple,
            flowName: flowName,
          });
        }
      }
    },
    [
      currentUserId,
      flowName,
      getBlockDataFromSelectedItems,
      handleOnModifiedChanges,
      selectedItems,
      totalParticipantsCount,
    ],
  );

  const filterOptions = (
    options: AutocompleteDropdownItem<string, undefined>[],
    state: FilterOptionsState<AutocompleteDropdownItem<string, undefined>>,
  ) => {
    const filter =
      createFilterOptions<AutocompleteDropdownItem<string, undefined>>();
    const filtered = filter(options, state);
    if (state.inputValue !== '' && filtered.length === 0 && !isMembersLoading) {
      const emptyState = isValidEmail(state.inputValue)
        ? getInviteUserCard(state.inputValue)
        : noResultsFoundCard;
      filtered.push(emptyState);
    }
    return filtered;
  };

  const onOptionsScroll = () => {
    if (hasMoreMembers && !isMembersFetching) {
      fetchMoreMembers();
    }
  };

  const changeUserSettings = useCallback(
    (setting: MenuItemIndividualItem, userId: string) => {
      const modifiedSelectedItems =
        getUpdatedSelectedItemsBasedOnDropdownSelection({
          currentUserId: userId,
          setting,
          selectedItems,
        });
      const ownerIndex = modifiedSelectedItems.findIndex(
        (item) => item.isOwner,
      );
      const hasOtherParticipants = modifiedSelectedItems.some(
        (item) =>
          !item.isOwner &&
          (item.setting?.id === ShareSheetMemberActions.canPostAndViewPosts ||
            item.setting?.id === ShareSheetMemberActions.canOnlyPost),
      );

      if (!hasOtherParticipants && ownerIndex > -1) {
        modifiedSelectedItems[ownerIndex] = {
          ...modifiedSelectedItems[ownerIndex],
          setting: canPostAndViewPostsSetting,
        };
      }

      const { serializedParticipationCriteria, serializedViewingCriteria } =
        getBlockDataFromSelectedItems(modifiedSelectedItems);

      trackShareSheetActionEvent({
        action:
          setting.value === 'remove'
            ? SHARE_SHEET_EVENTS.REMOVE_MEMBER
            : SHARE_SHEET_EVENTS.CHANGE_ROLE,
        tab: 'invite and share',
        ownerId: currentUserId,
        flowName: flowName,
        participantCriteriaSelected: serializedParticipationCriteria,
        viewerCriteriaSelected: serializedViewingCriteria,
        ruleType: ShareSheetRuleAccessTypes.Simple,
      });

      // update requestedUsers when the user gets removed
      if (setting.id === 'remove') {
        const updatedUsers = requestedUsers.map((user) => {
          if (user.memberID === userId || user.email === userId) {
            return {
              ...user,
              requestStatus: 'PENDING' as const,
            };
          }
          return user;
        });
        setRequestedUsers(updatedUsers);
      }

      setSelectedItems(modifiedSelectedItems);
      handleOnModifiedChanges(true);
    },
    [
      selectedItems,
      getBlockDataFromSelectedItems,
      currentUserId,
      flowName,
      handleOnModifiedChanges,
      requestedUsers,
      setRequestedUsers,
    ],
  );

  const resetSelectedItems = useCallback(() => {
    setSelectedItems([]);
  }, []);

  const isEveryoneSelected = useMemo(() => {
    return selectedItems.some((item) => item.id === 'everyone');
  }, [selectedItems]);

  const onBulkRuleOptionSelect = useCallback(
    ({
      bulkRule,
      type,
      condition,
    }: {
      bulkRule: AutocompleteDropdownItem<string>[];
      condition: MenuItemIndividualItem;
      type: string;
    }) => {
      const currentItem = bulkRule
        .filter(
          (rule) =>
            !selectedItems.some(
              (i) => i.id === `${rule.id}-${condition.id}-${type}`,
            ),
        )
        .map((rule) => ({
          id: `${rule.id.replaceAll(`-${condition.id}`, '')}-${
            condition.id
          }-${type}`,
          title: `${BulkRuleTitleMap[type]} ${condition.value} ${rule.title}`,
          setting: canPostAndViewPostsSetting,
          email: `Members who have ${rule.title} as their ${BulkRuleTitleMap[type]}`,
          avatar: BulkMemberRuleAvatarMap[type],
          type: getSimpleShareSheetTypeFromBulkSelection(type),
          condition: `${condition.id}`,
        }));
      if (currentItem) {
        const modifiedSelectedItems = [...currentItem, ...selectedItems];
        setSelectedItems(modifiedSelectedItems);
        handleOnModifiedChanges(true);
        const { serializedParticipationCriteria, serializedViewingCriteria } =
          getBlockDataFromSelectedItems(modifiedSelectedItems);
        trackShareSheetActionEvent({
          action: SHARE_SHEET_EVENTS.DEPARTMENT_RULE_CLICKED,
          tab: 'invite and share',
          participantCriteriaSelected: serializedParticipationCriteria,
          viewerCriteriaSelected: serializedViewingCriteria,
          ownerId: currentUserId,
          ruleType: ShareSheetRuleAccessTypes.Simple,
          flowName: flowName,
        });
        setNonMemberItemSelectionType(null);
      }
    },
    [
      currentUserId,
      flowName,
      getBlockDataFromSelectedItems,
      handleOnModifiedChanges,
      selectedItems,
    ],
  );

  const onBulkRuleOptionDelete = useCallback(() => {
    setNonMemberItemSelectionType(null);
  }, []);

  const getDepartmentCountForItem = useCallback(
    async (item: SimplifiedShareSheetCardData) => {
      const serializedDeptQueryPayload =
        serializeSimpleShareSheetRulesForAPIPayload([item]);
      if (serializedDeptQueryPayload) {
        const { data: deptQueryResponse } =
          await getDepartmentFromMemberCriteriaMutation(
            serializedDeptQueryPayload,
          );
        setDepartmentCountMap((draft) => {
          draft[item.id] = deptQueryResponse.total;
        });
      }
    },
    [getDepartmentFromMemberCriteriaMutation, setDepartmentCountMap],
  );

  useEffect(() => {
    selectedItems.forEach((item) => {
      if (item.type === SimplifiedShareSheetDataTypes.Department)
        getDepartmentCountForItem(item);
    });
  }, [selectedItems, getDepartmentCountForItem]);

  return {
    isMembersLoading: isMembersLoading || isMembersFetching,
    displayOptions,
    handleAutoCompleteOptionSelection,
    onOptionsScroll,
    changeUserSettings,
    filterOptions,
    value,
    textBoxValue,
    selectedItems,
    setTextBoxValue,
    isParticipantsCriteriaLoading,
    isViewerCriteriaLoading,
    isEveryoneCriteriaBeingFetched,
    resetAutoCompleteDropdown,
    currentUserId,
    resetSelectedItems,
    canEmailInvite,
    memberOptions,
    handleBulkMemberActionSelection,
    isEveryoneSelected,
    bulkRuleDropdownOptions,
    isBulkRuleSelectorDataLoading:
      isDepartmentsLoading || isBulkRuleSelectorDataLoading,
    onBulkRuleOptionSelect,
    onBulkRuleOptionDelete,
    tooltipContent,
    departmentCountMap,
    membersCountForEveryoneCriteria,
    fetchMoreMembersForDisplay,
    hasMoreMembersToDisplay,
    hasNonOwnerParticipants,
    canInviteMembersFromShareSheet,
    isExclusionCriteriaLoading,
    nonMemberItemSelectionType,
    canShowEveryoneCriteria,
  };
};
