import React, { useCallback } from 'react';

import Button from '../../../atomic/atoms/Button';
import Dropdown from '../../../atomic/molecules/Dropdown_V2';
import CriteriaItem from '../../../atomic/molecules/CriteriaItem';
import PopoverWithSubMenu from '../../../atomic/organism/PopoverWithSubMenu';

import { FlowsBuilderFilterCriteriaControllerProps } from './types';

import { capitalizeFirstLetter } from '../../../Utils/text';
import { ENTIRE_ORGANIZATION } from '../../../languages/en';
import { AND, OR } from '../../../languages/en/singleWords';
import { ADD_MORE_CRITERIA_LABEL } from '../../../languages/en/flows/builder';
import useFlowsBuilderFilterCriteriaController from './useFlowsBuilderFilterCriteriaController';
import {
  optionItems,
  optionManagerItems,
} from '../FlowsBuilderParticipantsController/useFlowsBuilderParticipantController/data';
import FlowParticipationBuilderOptionsContent from '../../../atomic/organism/FlowParticipationBuilderOptionsContent';

import {
  DropdownColor,
  DropdownVariants,
} from '../../../atomic/molecules/Dropdown_V2/interfaces';

import {
  getCriteriaValue,
  getCriteriaCondition,
  getCriteriaFieldIcon,
} from './utils';

import {
  AddMoreCriteriaButton,
  FilterCriteriaWrapper,
  FilterCriteriaContainer,
  AddMoreCriteriaContainer,
  StyledPopoverWithSubMenu,
  GroupCriteriaConditionDropdown,
} from './styles';

import {
  CriteriaCondition,
  CriteriaField,
  CriteriaRule,
} from '../../../interfaces/Flow/Builder';

import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { pendingEmailsSelector } from '../../../stores/flowBuilderStore/selectors';
import { MemberState } from '../../../interfaces/user';
import FlowsManagerBuilderOptionsContent from '../../../atomic/organism/FlowsManagerBuilderOptionsContent';

const OR_CONDITION = OR.toLowerCase() as CriteriaCondition;
const AND_CONDITION = AND.toLowerCase() as CriteriaCondition;

const FlowsBuilderFilterCriteriaController = (
  props: FlowsBuilderFilterCriteriaControllerProps,
) => {
  const {
    variant,
    criteriaLabel,
    criteriaGroups,
    criteriaMenuItems,
    hideEveryone = false,
    onCriteriaGroupsChange,
    criteriaConditionItems,
    from,
  } = props;

  const { models, operations } = useFlowsBuilderFilterCriteriaController(
    hideEveryone,
    criteriaGroups,
    onCriteriaGroupsChange,
    from,
  );

  const pendingEmails = useFlowBuilderStore(pendingEmailsSelector);

  const {
    anchorEl,
    popoverId,
    locationOptions,
    jobTitleOptions,
    popoverMenuItems,
    isEveryOneSelected,
    workLocationOptions,
    isDepartmentsLoading,
    isCriteriaPopoverOpen,
    isMembersSearchLoading,
    isMembersSearchFetching,
    participationDepartments,
    isUserLocationDataLoading,
    canInviteMembersOnFlowBuilder,
    isMembersSearchByEmailLoading,
    isMembersSearchByEmailFetching,
  } = models;

  const {
    setAnchorEl,
    onMemberAddClick,
    setSelectedGroupId,
    onDepartmentAddClick,
    handleOnLocationAddClick,
    setIsEveryOneSelected,
    onMembersSearchChange,
    getParticipantMembers,
    handleRemoveRuleClick,
    handleOnEveryOneSelectedWithoutSimpleShareSheet,
    handleOnEveryOneSelectedWithSimpleShareSheet,
    handleGroupConditionChange,
    onCriteriaEmailOptionsScroll,
    onMembersSearchByEmailChange,
    onCriteriaMemberOptionsScroll,
    getParticipantsMembersByEmail,
    handleCriteriaGroupConditionChange,
    onManagerAddClick,
  } = operations;

  const renderSlideContent = useCallback(
    (id: string) => {
      switch (id) {
        case 'everyone': {
          if (!isEveryOneSelected) {
            handleOnEveryOneSelectedWithSimpleShareSheet();
            setIsEveryOneSelected(true);
          }

          return null;
        }

        case 'member':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              onAddButtonClick={onMemberAddClick}
              onInputChange={onMembersSearchChange}
              autocompleteItems={getParticipantMembers()}
              onOptionsScroll={onCriteriaMemberOptionsScroll}
              isLoading={isMembersSearchLoading || isMembersSearchFetching}
            />
          );

        case 'workLocation':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              onAddButtonClick={handleOnLocationAddClick}
              autocompleteItems={workLocationOptions}
              isLoading={isUserLocationDataLoading}
              noOptionsText="No results found, try another search."
            />
          );

        case 'homeLocation':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              onAddButtonClick={handleOnLocationAddClick}
              autocompleteItems={locationOptions}
              isLoading={isUserLocationDataLoading}
              noOptionsText="No results found, try another search."
            />
          );

        case 'jobTitle':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              onAddButtonClick={handleOnLocationAddClick}
              autocompleteItems={jobTitleOptions}
              isLoading={isUserLocationDataLoading}
              noOptionsText="No results found, try another search."
            />
          );

        case 'email': {
          const isFetching =
            isMembersSearchByEmailFetching || isMembersSearchByEmailLoading;

          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              isLoading={isFetching}
              radioOptions={optionItems}
              onAddButtonClick={onMemberAddClick}
              onInputChange={onMembersSearchByEmailChange}
              onOptionsScroll={onCriteriaEmailOptionsScroll}
              autocompleteItems={getParticipantsMembersByEmail()}
              isInviteUserOnFlowCreationEnabled={canInviteMembersOnFlowBuilder}
            />
          );
        }

        case 'department':
          return (
            <FlowParticipationBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionItems}
              autocompleteItems={participationDepartments}
              onAddButtonClick={onDepartmentAddClick}
              isLoading={isDepartmentsLoading}
            />
          );

        case 'manager':
          return (
            <FlowsManagerBuilderOptionsContent
              selectedOptionId={id}
              radioOptions={optionManagerItems}
              autocompleteItems={getParticipantMembers()}
              onInputChange={onMembersSearchChange}
              onAddButtonClick={onManagerAddClick}
              onOptionsScroll={onCriteriaMemberOptionsScroll}
              isLoading={isMembersSearchLoading || isMembersSearchFetching}
            />
          );

        default:
          return null;
      }
    },
    [
      onMemberAddClick,
      onMembersSearchChange,
      getParticipantMembers,
      onCriteriaMemberOptionsScroll,
      isMembersSearchLoading,
      isMembersSearchFetching,
      handleOnLocationAddClick,
      workLocationOptions,
      isUserLocationDataLoading,
      locationOptions,
      jobTitleOptions,
      participationDepartments,
      onDepartmentAddClick,
      isDepartmentsLoading,
      onManagerAddClick,
      isEveryOneSelected,
      setIsEveryOneSelected,
      handleOnEveryOneSelectedWithSimpleShareSheet,
      handleOnEveryOneSelectedWithoutSimpleShareSheet,
      isMembersSearchByEmailFetching,
      isMembersSearchByEmailLoading,
      onMembersSearchByEmailChange,
      onCriteriaEmailOptionsScroll,
      getParticipantsMembersByEmail,
      canInviteMembersOnFlowBuilder,
    ],
  );

  const getMembersState = (email: string) => {
    if (pendingEmails.includes(email)) {
      return MemberState.PENDING;
    }

    return undefined;
  };

  const getCriteriaFieldName = (field: CriteriaField) => {
    if (field !== 'everyone') {
      if (field === 'directReport') {
        return 'Reports';
      } else if (field === 'workLocation') {
        return 'Work location';
      } else if (field === 'homeLocation') {
        return 'Home location';
      } else if (field === 'jobTitle') {
        return 'Job title';
      }
      return capitalizeFirstLetter(field);
    }
    return ENTIRE_ORGANIZATION;
  };

  return (
    <>
      {criteriaGroups &&
        criteriaGroups.groups.length > 0 &&
        criteriaGroups.groups.map((criteriaItem, criteriaIndex) => {
          return (
            <FilterCriteriaWrapper key={criteriaItem.groupId}>
              <FilterCriteriaContainer
                key={criteriaItem.groupId}
                itemsLength={criteriaItem.groupRules.length}
                isEveryOneSelected={false}
              >
                {criteriaItem.groupRules.length > 1 && (
                  <Dropdown
                    isFullWidth
                    onItemClick={(value) =>
                      handleGroupConditionChange(
                        criteriaItem.groupId,
                        value.toString().includes('any')
                          ? OR_CONDITION
                          : AND_CONDITION,
                      )
                    }
                    menuItems={criteriaMenuItems}
                    color={DropdownColor.BlueTertiary}
                    value={
                      criteriaItem.groupCondition === AND_CONDITION
                        ? criteriaMenuItems[0].items[1].value
                        : criteriaMenuItems[0].items[0].value
                    }
                  />
                )}
                {criteriaItem.groupRules.map((criteriaRule: CriteriaRule) => {
                  if (criteriaRule.field === 'manager') {
                    return criteriaRule.value.map((criteriaValue) => {
                      return (
                        <CriteriaItem
                          data-testid="CriteriaItem-1"
                          isLoading={false}
                          key={criteriaRule.ruleId}
                          id={criteriaRule.ruleId}
                          criteriaValue={
                            criteriaValue.value === 'memberIsManager'
                              ? 'a Manager'
                              : 'not a Manager'
                          }
                          onDismissClick={(ruleId) =>
                            handleRemoveRuleClick(ruleId, criteriaRule.ruleId)
                          }
                          icon={getCriteriaFieldIcon(criteriaRule.field)}
                          criteria={'Member'}
                          criteriaCondition={getCriteriaCondition(
                            criteriaRule.operator,
                          )}
                          memberState={undefined}
                        />
                      );
                    });
                  }
                  if (criteriaRule.field === 'everyone') {
                    return (
                      <CriteriaItem
                        data-testid="everyoneCriteriaItem"
                        isLoading={false}
                        key={criteriaRule.field}
                        id={criteriaRule.ruleId}
                        criteriaValue={''}
                        onDismissClick={(ruleId) => {
                          handleRemoveRuleClick(ruleId, criteriaRule.field);
                        }}
                        icon="assembly"
                        criteria={ENTIRE_ORGANIZATION}
                        criteriaCondition={''}
                        memberState={undefined}
                      />
                    );
                  }
                  return criteriaRule.value.map((criteriaValue) => {
                    return (
                      <CriteriaItem
                        data-testid="CriteriaItem-1"
                        isLoading={
                          criteriaValue.id === criteriaValue.value &&
                          criteriaRule.field === 'member'
                        }
                        key={criteriaValue.id}
                        id={criteriaRule.ruleId}
                        criteriaValue={getCriteriaValue(
                          criteriaRule,
                          criteriaValue,
                        )}
                        onDismissClick={(ruleId) =>
                          handleRemoveRuleClick(ruleId, criteriaValue.id)
                        }
                        icon={getCriteriaFieldIcon(criteriaRule.field)}
                        criteria={getCriteriaFieldName(criteriaRule.field)}
                        criteriaCondition={
                          criteriaRule.field !== 'everyone'
                            ? getCriteriaCondition(criteriaRule.operator)
                            : ''
                        }
                        memberState={
                          criteriaValue.memberState ||
                          getMembersState(
                            getCriteriaValue(criteriaRule, criteriaValue),
                          )
                        }
                      />
                    );
                  });
                })}

                {criteriaItem.groupRules.flatMap((r) => r.field).length > 0 && (
                  <AddMoreCriteriaContainer>
                    <StyledPopoverWithSubMenu
                      isAutoWidth={variant === 'mobileView'}
                      id={popoverId}
                      isOpen={isCriteriaPopoverOpen}
                      anchorEl={anchorEl}
                      handleClose={() => {
                        setAnchorEl(null);
                      }}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      popoverItems={popoverMenuItems}
                      referenceContent={
                        <AddMoreCriteriaButton
                          aria-describedby={popoverId}
                          variant="text"
                          icon="union-rounded"
                          color={DropdownColor.BlueTertiary}
                          onClick={(event) => {
                            setAnchorEl(event.currentTarget);
                            setSelectedGroupId(criteriaItem.groupId);
                          }}
                        >
                          {ADD_MORE_CRITERIA_LABEL}
                        </AddMoreCriteriaButton>
                      }
                      slideContent={renderSlideContent}
                    />
                  </AddMoreCriteriaContainer>
                )}
              </FilterCriteriaContainer>
              {criteriaGroups.groups.length > 0 &&
                criteriaIndex !== criteriaGroups.groups.length - 1 && (
                  <GroupCriteriaConditionDropdown
                    onItemClick={(value) =>
                      handleCriteriaGroupConditionChange(
                        value.toString().toLowerCase().includes(OR_CONDITION)
                          ? OR_CONDITION
                          : AND_CONDITION,
                      )
                    }
                    menuItems={criteriaConditionItems}
                    variant={DropdownVariants.Text}
                    color={DropdownColor.BlueTertiary}
                    value={
                      criteriaGroups.groupsCondition === OR_CONDITION
                        ? criteriaConditionItems[0].items[0].value
                        : criteriaConditionItems[0].items[1].value
                    }
                  />
                )}
            </FilterCriteriaWrapper>
          );
        })}

      {
        <PopoverWithSubMenu
          id={popoverId}
          isAutoWidth={variant === 'mobileView'}
          isOpen={isCriteriaPopoverOpen}
          anchorEl={anchorEl}
          handleClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          popoverItems={popoverMenuItems}
          referenceContent={
            <Button
              variant="text"
              icon="union-rounded"
              aria-describedby={popoverId}
              disabled={false}
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setSelectedGroupId(undefined);
              }}
            >
              {criteriaLabel}
            </Button>
          }
          slideContent={renderSlideContent}
        />
      }
    </>
  );
};

export default FlowsBuilderFilterCriteriaController;
