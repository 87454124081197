import styled from 'styled-components';
import ThemeV2 from '../../../theme';

export const HighlightedTextRoot = styled.button<{ disableOnClick?: boolean }>`
  display: inline-flex;
  background: ${ThemeV2.palette.geekBlue1};
  color: ${ThemeV2.palette.geekBlue6};
  border: 0;
  outline: 0;
  border-radius: 4px;
  cursor: ${({ disableOnClick }) => (disableOnClick ? 'default' : 'pointer')};
  padding: 0px 4px;
  margin: 0px;
  transition: '0.4s ease all';
  &:hover,
  &:focus {
    background: ${ThemeV2.palette.geekBlue2};
  }
`;
HighlightedTextRoot.displayName = 'HighlightedTextRoot';
