import getSymbolFromCurrency from 'currency-symbol-map';
import orderBy from 'lodash/orderBy';

import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../atomic/molecules/Dropdown_V2/interfaces';
import { TrophyRangeItem } from '../../atomic/molecules/RewardCard/interface';
import { USD } from '../../constants/rewards';
import { SELECT_GIFT_CARD_VALUE } from '../../languages/en/rewards';
import {
  Denomination,
  DenominationRangeSelectedByEmployer,
  ExchangeRates,
  GiftCardReward,
} from '../../queries/Rewards/interfaces';

import countriesData from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

countriesData.registerLocale(enLocale);

export const getCountryName = (code) => {
  if (code === 'US') {
    return 'United States';
  }
  return countriesData.getName(code, 'en');
};

const DEFAULT_CURRENCY_CODE = 'USD';

export const getCarrotsForCurrencyExchange = (
  faceValue: number,
  exchangeRate: number,
  currencyExchangeRate: number,
): number => {
  return Math.ceil((faceValue * exchangeRate) / currencyExchangeRate);
};

export const getCountryCode = (countryName) => {
  if (countryName === 'United States') {
    return countriesData.getAlpha2Code('United States of America', 'en');
  }
  return countriesData.getAlpha2Code(countryName, 'en');
};

export const getCountryCodes = (giftCards: GiftCardReward[] = []) => {
  const countries: string[] = [];
  giftCards.forEach((giftCard) => {
    if (giftCard.items.length > 0) {
      giftCard.items[0].countries.forEach((country: string) => {
        if (countries.indexOf(country) === -1 && country !== 'UK') {
          countries.push(country);
        }
      });
    }
  });
  return countries;
};

export const getCurrencyCodes = (giftCards: GiftCardReward[] = []) => {
  const currencyCodes: string[] = [];
  giftCards.forEach((giftCard) => {
    if (giftCard.items.length > 0) {
      if (currencyCodes.indexOf(giftCard.items[0].currencyCode) === -1) {
        currencyCodes.push(giftCard.items[0].currencyCode);
      }
    }
  });
  return currencyCodes;
};

export const getSortedDenominations = (denominations: Denomination[]) => {
  return denominations.sort(
    (itemA, itemB) => itemA.faceValue - itemB.faceValue,
  );
};

export const getRewardCardsUnderSpecifiedRange = (
  rewards: GiftCardReward[],
  exchangeRates: ExchangeRates,
  denominationRangeSelectedByEmployer?: DenominationRangeSelectedByEmployer,
) => {
  let updatedGiftRewards: GiftCardReward[] = [];
  if (exchangeRates) {
    updatedGiftRewards = rewards
      .map((reward) => {
        return {
          ...reward,
          denominations: reward.denominations.filter((denomination) => {
            const usConvertedDenomination =
              denomination.currencyCode === USD
                ? denomination
                : {
                    ...denomination,
                    faceValue:
                      denomination.faceValue /
                      exchangeRates[denomination.currencyCode || USD],
                  };
            const minRangeValue = denominationRangeSelectedByEmployer?.min || 0;
            const maxRangeValue = denominationRangeSelectedByEmployer?.max || 0;
            //Filtering items that fall under the given range
            if (minRangeValue && maxRangeValue) {
              return (
                usConvertedDenomination.faceValue >= minRangeValue &&
                usConvertedDenomination.faceValue <= maxRangeValue
              );
            } else if (minRangeValue && !maxRangeValue) {
              return usConvertedDenomination.faceValue >= minRangeValue;
            } else if (maxRangeValue && !minRangeValue) {
              return usConvertedDenomination.faceValue <= maxRangeValue;
            } else {
              return true;
            }
          }),
        };
      })
      .filter((reward) => reward.denominations.length > 0);
  }
  return updatedGiftRewards;
};

export const getTrophyRange = (
  denominations: Denomination[],
  exchangeRates: ExchangeRates,
  exchangeRate: number,
) => {
  const range: TrophyRangeItem[] = [];
  const sortedDenominations = getSortedDenominations(denominations);
  sortedDenominations.forEach((denomination) => {
    const { faceValue } = denomination;
    const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;

    let currencyExchangeRate;
    if (currencyCode === USD) {
      currencyExchangeRate = 1;
    } else {
      currencyExchangeRate = exchangeRates[currencyCode];
    }

    const rangeItem = {
      cost: faceValue,
      trophies: getCarrotsForCurrencyExchange(
        faceValue,
        exchangeRate,
        currencyExchangeRate,
      ),
      currencyCode,
    };
    range.push(rangeItem);
  });
  return {
    range,
  };
};

export const getDropdownItems = (
  denominations: Denomination[],
  exchangeRates: ExchangeRates,
  exchangeRate: number,
  pointsEarned: number,
): MenuItemProps[] => {
  const dropdownItems: MenuItemIndividualItem[] = [];

  const sortedDenominations = getSortedDenominations(denominations);
  sortedDenominations.forEach((denomination) => {
    const id = denomination._id;

    const { faceValue } = denomination;
    const currencyCode = denomination.currencyCode || DEFAULT_CURRENCY_CODE;

    let currencyExchangeRate;
    if (currencyCode === USD) {
      currencyExchangeRate = 1;
    } else {
      currencyExchangeRate = exchangeRates[currencyCode];
    }

    const trophies = getCarrotsForCurrencyExchange(
      faceValue,
      exchangeRate,
      currencyExchangeRate,
    );

    const dropdownItem = {
      value: id,
      id,
      isSelected: false,
      disabled: pointsEarned < trophies,
    };

    dropdownItems.push(dropdownItem);
  });

  const dropdownOptions = [
    {
      id: 'category-one',
      category: SELECT_GIFT_CARD_VALUE,
      items: dropdownItems,
    },
  ];

  return dropdownOptions;
};

export const getDisplayAmount = (faceValue, currencyCode) => {
  let name;
  const countrySymbol = getSymbolFromCurrency(currencyCode);
  if (countrySymbol) {
    name = `${countrySymbol}${faceValue}`;
  } else {
    name = `${faceValue} ${currencyCode}`;
  }
  return name;
};

interface FilterItemsType {
  arr: any[];
  key: string;
  value: string;
}

interface FilterByCountry {
  arr: any[];
  countryCode: string;
}

export const filterItems = ({ arr, key, value }: FilterItemsType) => {
  return arr.filter((item) => {
    return item[key].toLowerCase().includes(value.toLowerCase());
  });
};

export const filterByCountry = ({ arr, countryCode }: FilterByCountry) => {
  return arr.filter((item) => {
    return item?.countries?.includes(countryCode);
  });
};

export const getCountriesMenu = (
  rewards: GiftCardReward[],
  defaultCountryCode: string,
): MenuItemProps[] => {
  const countryCodes = getCountryCodes(rewards);

  let giftCardCountryMenuItems = countryCodes.map((code) => {
    return {
      id: code,
      value: getCountryName(code),
      isSelected: code === defaultCountryCode,
    };
  });

  giftCardCountryMenuItems = orderBy(giftCardCountryMenuItems, 'value', 'asc');

  return [
    {
      id: 'filter-by',
      items: giftCardCountryMenuItems,
    },
  ];
};

export const canDisableRewardCardActionButton = (
  trophyRange: TrophyRangeItem[],
  pointsEarned: number,
) => {
  return (
    trophyRange.length === 0 ||
    (trophyRange.length > 0 &&
      typeof trophyRange[0].trophies === 'number' &&
      pointsEarned < trophyRange[0].trophies)
  );
};
