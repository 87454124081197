import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router-dom';
import { TEMPLATE_GALLERY_EVENTS } from '../../../Utils/analytics/constants';
import { trackTemplateGalleryShowEvent } from '../../../Utils/analytics/templateGallery';
import { device } from '../../../constants/layout';
import useLayoutStore from '../../../stores/layoutStore';
import { toggleRightAsideOpenSelector } from '../../../stores/layoutStore/selectors';
import useModalsStore from '../../../stores/modalsStore';

export function useAddNewFlowCallback(folderId?: string) {
  const { replace } = useHistory();
  const { search } = useLocation();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { openCreateFlowModal } = useModalsStore();
  const setRightAsideOpen = useLayoutStore(toggleRightAsideOpenSelector);

  return useCallback(() => {
    if (isMobileView) {
      const params = new URLSearchParams(search);
      params.set('showTemplate', 'true');

      replace({
        search: params.toString(),
      });

      setRightAsideOpen();
    }
    /* Mix panel */
    trackTemplateGalleryShowEvent({
      show: TEMPLATE_GALLERY_EVENTS.TEMPLATE_GALLERY_SHOW,
      source: 'leftDrawer',
    });
    /* Mix panel */

    openCreateFlowModal(folderId);
  }, [
    folderId,
    isMobileView,
    openCreateFlowModal,
    replace,
    search,
    setRightAsideOpen,
  ]);
}
