import React, { MutableRefObject } from 'react';
import { makeStyles } from '@mui/styles';

import ThemeV2 from '../../../theme';

type FlowMenuItemRefType =
  | MutableRefObject<HTMLButtonElement | null>
  | ((instance: HTMLButtonElement | null) => void)
  | null;

const useStyles = makeStyles({
  root: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: '0px',
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    fontFamily: ThemeV2.typography.adminFontFamily,
    fontWeight: ThemeV2.typography.fontWeightRegular,
    fontSize: '14px',
    lineHeight: '22px',
  },
});

type StyleProps = {
  emoticonBg: string;
};

const FlowMenuItemWrapper = (
  props: React.HTMLProps<HTMLButtonElement> & StyleProps,
  ref: FlowMenuItemRefType,
) => {
  const { children, className: parentClassName, emoticonBg, ...rest } = props;
  const classes = useStyles(props);
  const styles = `${classes.root} ${parentClassName}`;
  return (
    <button {...rest} type="button" className={styles} ref={ref}>
      {children}
    </button>
  );
};

export default React.forwardRef(FlowMenuItemWrapper);
