import React from 'react';
import titleize from 'titleize';
import Divider from '../../../atomic/atoms/Divider';
import DrawerHeaderWithIcon from '../../../atomic/molecules/DrawerHeaderWithIcon';
import {
  COLLABORATORS,
  OWNER,
  SETTINGS,
} from '../../../languages/en/singleWords';
import { SettingsWrapper, FormWrapper } from './styles';
import ThemeV2 from '../../../theme';
import { MultiSelectAutoComplete } from '../../../atomic/organism/Autocomplete';
import useBuilderRightDrawerController from './useBuilderRightDrawerController';
import FlowsNameAndEmoticonForm from '../../../atomic/molecules/FlowsNameAndEmoticonForm';

const FlowBuilderRightDrawerController = () => {
  const {
    description,
    setDescription,
    flowName,
    setFlowName,
    owner,
    flowId,
    isInEditMode,
    onAutocompleteChange,
    toggleRightAsideOpen,
    emoji,
    setEmoji,
    isPersonSearchLoading,
    memberOptions,
    onInputChange,
    personSelectorValue,
    handleMemberOptionsScroll,
    handleOnTitleBlur,
    handleOnDescriptionBlur,
    collaboratorsOptions,
    onCollaboratorInputChange,
    isCollaboratorSearchLoading,
    onCollaboratorsChange,
    collaboratorSelectorValue,
    collaborators,
  } = useBuilderRightDrawerController();

  return (
    <>
      <DrawerHeaderWithIcon
        icon="general-settings"
        onClick={toggleRightAsideOpen}
      >
        {SETTINGS}
      </DrawerHeaderWithIcon>
      <SettingsWrapper>
        <FormWrapper>
          <FlowsNameAndEmoticonForm
            disabled={Boolean(flowId) && !isInEditMode}
            flowName={flowName}
            onNameChange={(event) => setFlowName(event.target.value)}
            description={description}
            onDescriptionChange={(event) => setDescription(event.target.value)}
            emoticon={emoji}
            onEmoticonSelect={setEmoji}
            flowId={flowId}
            onTitleBlur={handleOnTitleBlur}
            onDescriptionBlur={handleOnDescriptionBlur}
          />
        </FormWrapper>

        <Divider
          isFullWidth
          color={ThemeV2.palette.gray4}
          marginTop="24px"
          marginBottom="24px"
        />

        <FormWrapper>
          <MultiSelectAutoComplete
            textboxValue={personSelectorValue}
            onTextboxValueChange={onInputChange}
            value={owner}
            onChange={onAutocompleteChange}
            label={titleize(OWNER)}
            options={memberOptions}
            optionsMaxLimit={1}
            textboxSize="large"
            removeElevation
            loading={isPersonSearchLoading}
            ListboxProps={{
              onScroll: handleMemberOptionsScroll,
            }}
            disabled={Boolean(flowId) && !isInEditMode}
          />
        </FormWrapper>

        <FormWrapper margin="16px 0 0 0">
          <MultiSelectAutoComplete
            textboxValue={collaboratorSelectorValue}
            onTextboxValueChange={onCollaboratorInputChange}
            value={collaborators}
            onChange={onCollaboratorsChange}
            label={titleize(COLLABORATORS)}
            options={collaboratorsOptions}
            textboxSize="large"
            removeElevation
            loading={isCollaboratorSearchLoading}
            ListboxProps={{
              onScroll: handleMemberOptionsScroll,
            }}
            disabled={Boolean(flowId) && !isInEditMode}
            inputBaseRightPadding="48px"
          />
        </FormWrapper>
      </SettingsWrapper>
    </>
  );
};

export default FlowBuilderRightDrawerController;
