import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type KioskStoreState = {
  isTurnedOn: boolean;
  setIsTurnedOn: (isTurnedOn: boolean) => void;
};

const useKioskStore = create<KioskStoreState>()(
  persist(
    (set) => ({
      isTurnedOn: false,
      setIsTurnedOn: (isTurnedOn: boolean) =>
        set(() => ({ isTurnedOn: isTurnedOn })),
    }),
    {
      name: 'kiosk-settings',
    },
  ),
);

export default useKioskStore;
