import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import { Channel } from 'pusher-js';
import { SocketPayloadType } from '../useCommonFeedSocket/types';
import {
  GET_FLOW_POST_COMMENTS,
  GET_POST_COMMENTS,
} from '../../constants/endpoints';
import { LayoutStoreState } from '../../interfaces/Layout';
import useLayoutStore from '../../stores/layoutStore';

let channelAssembly: Channel;

export enum WebSocketSinglePostTypes {
  LEGACY_POST = 'LEGACY_POST',
  NEW_FLOW_POST = 'NEW_FLOW_POST',
}

type LegacySinglePostSocketTypes = {
  type: WebSocketSinglePostTypes.LEGACY_POST;
};

type NewSinglePostSocketTypes = {
  type: WebSocketSinglePostTypes.NEW_FLOW_POST;
  flowId: string | undefined;
};

type CommonSinglePostParams =
  | LegacySinglePostSocketTypes
  | NewSinglePostSocketTypes;

const useSinglePostSocket = (options: CommonSinglePostParams) => {
  const { data, refetch } = useProfileInfoFetchQuery();

  const queryClient = useQueryClient();

  const showRepliesSelector = (state: LayoutStoreState) => state.showReplies;
  const showReplies = useLayoutStore(showRepliesSelector);

  useEffect(() => {
    let channelAssemblyEventHandler: (
      eventName: string,
      socketPayload: SocketPayloadType,
    ) => void | undefined;
    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      channelAssemblyEventHandler = (
        eventName: string,
        socketPayload: SocketPayloadType,
      ) => {
        switch (eventName) {
          case 'COMMENT_DELETED': {
            if (socketPayload.postId) {
              queryClient.invalidateQueries([
                GET_POST_COMMENTS,
                socketPayload.postId,
              ]);
            }

            if (socketPayload.flowId && socketPayload.responseId) {
              queryClient.invalidateQueries([
                GET_FLOW_POST_COMMENTS,
                socketPayload.flowId,
                socketPayload.responseId,
              ]);
            }

            break;
          }
          case 'NEW_COMMENT': {
            if (showReplies) {
              if (options.type === WebSocketSinglePostTypes.LEGACY_POST) {
                if (socketPayload.postId) {
                  queryClient.invalidateQueries([
                    GET_POST_COMMENTS,
                    socketPayload.postId,
                  ]);
                }
              }

              if (options.type === WebSocketSinglePostTypes.NEW_FLOW_POST) {
                if (options.flowId === socketPayload?.flowId) {
                  if (socketPayload.flowId && socketPayload.responseId) {
                    queryClient.invalidateQueries([
                      GET_FLOW_POST_COMMENTS,
                      socketPayload.flowId,
                      socketPayload.responseId,
                    ]);
                  }
                }
              }
            }

            break;
          }
          default:
            break;
        }
      };

      channelAssembly.bind_global(channelAssemblyEventHandler);
    }

    return () => {
      if (data) {
        channelAssembly.unbind_global(channelAssemblyEventHandler);
      }
    };
  }, [data, refetch, options, queryClient]);
};

export default useSinglePostSocket;
