import { AxiosError } from 'axios';
import React, { ReactNodeArray } from 'react';
import reactStringReplace from 'react-string-replace';

import styled from 'styled-components';
import { extractLinks } from '@draft-js-plugins/linkify';
import Body from '../atomic/atoms/Body';
import ThemeV2 from '../theme';
import HighlightedText from '../atomic/atoms/HighlightedText';
import { FeedPostUserProps, MemberInteractionType } from '../interfaces/Feed';
import { DEACTIVATED_USER } from '../languages/en/notifications';
import { MENTION_REGEX, getFullName } from './text';
import { isDeactivatedUser } from './user';
import { TagResponse } from '../queries/Flows/Feed/interfaces';
import {
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftEntity,
  RawDraftEntityRange,
} from 'draft-js';
import { NotebookTask } from '../interfaces/notebook';
import { convertTokenizedObjectToString } from './notebook';
import { NOTEBOOK_TASK_DRAFT_ENTITY_KEY } from './draftjs';
import { MentionsProps } from '../atomic/organism/RichTextEditor';
import { TaskFeedToken } from '../atomic/molecules/TaskFeedToken';

const StyledAnchor = styled.a`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-style: normal;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-size: 16px;
  line-height: 22px;
  color: ${ThemeV2.palette.geekBlue6};
  transition: 0.2s ease color;

  &:hover {
    color: ${ThemeV2.palette.geekBlue5};
  }
  &:focus {
    color: ${ThemeV2.palette.geekBlue7};
  }
  &:active {
    color: ${ThemeV2.palette.geekBlue7};
  }
`;

export const getFormattedMessage = (
  message: string,
  taggedUsers: FeedPostUserProps[],
  onUserClicked: (member: MemberInteractionType) => void,
  tags: TagResponse[] = [],
  onRepliesLinkClicked?: (param: Record<string, string>) => void,
  tasks?: Partial<NotebookTask>[],
) => {
  let messageText: ReactNodeArray = [message];
  // Vijay to confirm whether we need the following commented code
  // as the Emoji's work without these code
  // messageText = reactStringReplace(message, /#(\w+)/g, (match, i) => (
  //   <span key={match + i} className="feed-card-hashtag">
  //     #{match}
  //   </span>
  // ));
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  // messageText = reactStringReplace(messageText, /:([^\s]*?):/g, (match, i) => (
  //   <Emoji key={match + i} emoji={`:${match}:`} size={16} />
  // ));

  messageText = reactStringReplace(messageText, '\n', (match, index) => (
    <br key={index} />
  ));

  const matchedURLs = extractLinks(message);
  matchedURLs?.forEach((matchedURL) => {
    messageText = reactStringReplace(messageText, matchedURL.text, () => {
      return (
        <StyledAnchor
          target="_blank"
          href={matchedURL.url}
          key={matchedURL.url}
          onClick={(e) => {
            if (matchedURL.url.includes('showReplies=true')) {
              const params = new URLSearchParams(matchedURL.url.split(`?`)[1]);

              const paramsObj = Array.from(params.keys()).reduce(
                (acc, val) => ({ ...acc, [val]: params.get(val) }),
                {},
              );

              if (onRepliesLinkClicked) {
                e.preventDefault();
                onRepliesLinkClicked(paramsObj);
                return;
              }
            }
          }}
        >
          {matchedURL.text}
        </StyledAnchor>
      );
    });
  });

  if (taggedUsers.length || tags.length || tasks?.length) {
    messageText = reactStringReplace(messageText, MENTION_REGEX, (match) => {
      if (match.startsWith('tag:')) {
        const matchingTag = tags.find(
          ({ tag }) => match.split('tag:').pop() === tag,
        );
        if (matchingTag) {
          return (
            <HighlightedText
              key={matchingTag.tag}
              name={matchingTag.displayText}
              disableOnClick
            />
          );
        }
      } else if (match.startsWith('task:')) {
        const matchingTask = tasks?.find(
          ({ noteId }) => match.split('task:').pop() === noteId,
        );
        if (matchingTask) {
          const title = convertTokenizedObjectToString(matchingTask.note || []);

          return (
            <TaskFeedToken
              {...matchingTask}
              ariaLabel={title}
              key={matchingTask.noteId}
            >
              {title}
            </TaskFeedToken>
          );
        }
      }
      return taggedUsers
        .filter((member) => member.memberID === match)
        .map((member) =>
          isDeactivatedUser(member) ? (
            <Body
              key={DEACTIVATED_USER}
              variant="body2Medium"
              color="gray6"
              inline
            >
              {DEACTIVATED_USER}
            </Body>
          ) : (
            <HighlightedText
              key={member.memberID}
              name={member.isDeleted ? DEACTIVATED_USER : getFullName(member)}
              onClick={() => onUserClicked(member)}
            />
          ),
        );
    });
  }
  return messageText;
};

export const getErrorMessage = (
  { response }: AxiosError<{ message: string; body: string }>,
  customErrorMessage?: string,
) => {
  if (response?.status === 409 && response.data.message) {
    return response.data.message;
  }
  if (response?.status !== 500 && response?.data?.body) {
    return response.data.body;
  }
  if (response?.status !== 500 && response?.data?.message) {
    return response.data.message;
  }
  return customErrorMessage;
};

export const getErrorStatusCode = ({ response }: AxiosError) => {
  return response?.status;
};

export const getRawDraftContentState = (
  message = '',
  taggedUsers: Partial<MentionsProps>[] = [],
  tags: TagResponse[] = [],
  tasks: Partial<NotebookTask>[] = [],
): RawDraftContentState => {
  const blocks: Array<RawDraftContentBlock> = [];
  const entities: RawDraftEntity[] = [];
  const mentionsMap: { [key: string]: number } = {};
  const tasksMap: { [key: string]: number } = {};
  const lines = message.split('\n');

  lines.forEach((line) => {
    const entityRanges: Array<RawDraftEntityRange> = [];

    let revisedLine = line;
    let replaced;

    // Global string replacement cannot be done here because the offset of each match needs to be computed, and
    // the value changes for each mention that is replaced with its decorated form.
    while (replaced !== revisedLine) {
      replaced = revisedLine;
      revisedLine = revisedLine.replace(
        new RegExp(MENTION_REGEX),
        (match: string, matchId: string, offset: number) => {
          let decoratedText = '';
          let key = -1;

          if (matchId.startsWith('tag:')) {
            const matchingTag = tags.find(
              ({ tag }) => matchId.split('tag:').pop() === tag,
            );
            if (matchingTag) {
              decoratedText = matchingTag.displayText;

              if (!(matchingTag.tag in mentionsMap)) {
                mentionsMap[matchingTag.tag] = entities.length;
                entities.push({
                  data: {
                    mention: {
                      id: matchingTag.tag,
                      name: matchingTag.displayText,
                    },
                  },
                  mutability: 'IMMUTABLE',
                  type: 'mention',
                });
              }
              key = mentionsMap[matchingTag.tag];
            }
          } else if (matchId.startsWith('task:')) {
            const matchingTask = tasks.find(
              ({ noteId }) => matchId.split('task:').pop() === noteId,
            );
            if (matchingTask) {
              decoratedText = matchingTask.note
                ? convertTokenizedObjectToString(matchingTask.note)
                : '';

              if (matchingTask.noteId) {
                if (!(matchingTask.noteId in tasksMap)) {
                  tasksMap[matchingTask.noteId] = entities.length;
                  entities.push({
                    data: {
                      task: matchingTask,
                    },
                    mutability: 'IMMUTABLE',
                    type: NOTEBOOK_TASK_DRAFT_ENTITY_KEY,
                  });
                }
                key = tasksMap[matchingTask.noteId];
              }
            }
          } else {
            const matchingMember = taggedUsers.find(
              (member) => member.id === matchId,
            );

            if (matchingMember) {
              decoratedText = matchingMember.name || '';

              if (!(matchId in mentionsMap)) {
                mentionsMap[matchId] = entities.length;
                entities.push({
                  data: {
                    mention: {
                      id: matchingMember.id,
                      name: matchingMember.name,
                    },
                  },
                  mutability: 'IMMUTABLE',
                  type: 'mention',
                });
              }
              key = mentionsMap[matchId];
            }
          }

          entityRanges.push({ key, length: decoratedText.length, offset });

          return decoratedText;
        },
      );
    }

    blocks.push({
      depth: 0,
      entityRanges,
      inlineStyleRanges: [],
      key: '',
      text: revisedLine,
      type: 'unstyled',
    });
  });

  return {
    blocks,
    entityMap: entities.reduce<{ [key: string]: RawDraftEntity }>(
      (fullMap, entity, index) => {
        fullMap[index] = entity;

        return fullMap;
      },
      {},
    ),
  };
};
