import React from 'react';
import { Emoji } from 'emoji-mart';
import styled from 'styled-components';
import { ReactionEmoji } from '../../../interfaces/Feed';
import { ReactionType } from '../../../interfaces/Feed/Reaction';

const ReactionEmojiContainer = styled.div<{ size: number }>`
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  margin-right: 4px;
`;

const CustomEmoji = styled.img`
  height: 100%;
  width: 100%;
`;

interface ReactionsEmojiProps {
  reaction: ReactionEmoji;
  size?: number;
}

const ReactionsEmoji = ({ reaction, size = 16 }: ReactionsEmojiProps) => {
  return (
    <ReactionEmojiContainer size={size}>
      {reaction.type === ReactionType.CUSTOM ? (
        <CustomEmoji
          src={reaction.value}
          alt={reaction.name}
          data-testid="custom-emoji"
          data-qa-id="icon-custom-emoji"
        />
      ) : (
        <Emoji emoji={reaction.name} sheetSize={32} size={size} />
      )}
    </ReactionEmojiContainer>
  );
};

export default ReactionsEmoji;
