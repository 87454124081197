// SOURCE: https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/toOrdinalSuffix.md
export const addSuffixToNumber = (number: number) => {
  const digits = [number % 10, number % 100];
  const ordinals = ['st', 'nd', 'rd', 'th'];
  const oPattern = [1, 2, 3, 4];
  const tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
  return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
    ? number + ordinals[digits[0] - 1]
    : number + ordinals[3];
};

// SOURCE: https://cwestblog.com/2011/06/23/javascript-add-commas-to-numbers/
export const formatNumberWithCommas = (number: number) => {
  return `${number}`.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
};

export const truncateText = (limit: number, text = '') => {
  const arrayedText = text.split('');
  if (arrayedText.length >= limit) {
    const truncatedText = arrayedText.splice(0, limit).join('');
    return `${truncatedText}...`;
  }
  return text;
};

export const getPrettierFileSize = (bytes?: number) => {
  if (bytes) {
    if (bytes < 1024) {
      return `${bytes} BT`;
    } else if (bytes < 1000000) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else if (bytes < 1000000000) {
      return `${(bytes / 1000000).toFixed(2)} MB`;
    } else {
      return `${(bytes / 1000000000).toFixed(2)} GB`;
    }
  } else {
    return 'NaN';
  }
};
