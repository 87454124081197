/* eslint-disable react/no-array-index-key */
import { useHistory, useLocation } from 'react-router-dom';
import React, { ReactNode, useEffect, useState } from 'react';

import SVGIcon from '../../atoms/SVGIcon';

import ThemeV2 from '../../../theme';
import { TabComponentProps, TabPanelProps } from './types';
import { StyledMoreButton, StyledTab, StyledTabs } from './styles';
import useLayoutStore from '../../../stores/layoutStore';

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{
        height: 'calc(100vh-181px)',
      }}
    >
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && children}
      </div>
    </div>
  );
};

const MoreButton = () => {
  return (
    <StyledMoreButton>
      <SVGIcon icon="more" size="24px" color={ThemeV2.palette.gray8} />
    </StyledMoreButton>
  );
};

const TabComponent = (props: TabComponentProps) => {
  const {
    tabItems,
    splitEqually = false,
    selectedTabIndex = 0,
    isNavigationEnabled = false,
    variant = 'scrollable',
    onTabClick,
    isTabPositionFixed,
    className = '',
    scrollButtons = 'auto',
    handleSelectTab,
    hideTabs = false,
  } = props;

  const history = useHistory();
  const currentLocation = useLocation();
  const { isEmbeddedInMainApp } = useLayoutStore();

  const [tabContentToRender, setTabContentToRender] =
    useState<ReactNode | null>(null);

  const onTabSelectionChange = (
    event: React.SyntheticEvent,
    newIndex: number,
  ) => {
    const clickedTab = tabItems.find((route, key) => key === newIndex);
    const tabName: any = clickedTab?.tabHeader.label || '';
    if (onTabClick) {
      onTabClick(
        event,
        tabName.props === undefined
          ? tabName.toLowerCase()
          : tabName.props.children.toString().toLowerCase(),
      );
    }

    if (event.defaultPrevented) {
      return;
    }

    if (isNavigationEnabled) {
      const selectedRoute = tabItems.find((route, key) => key === newIndex);
      if (selectedRoute?.tabHeader.path) {
        history.push({ pathname: selectedRoute?.tabHeader.path });
      }
    } else {
      handleSelectTab(newIndex);
    }
  };

  useEffect(() => {
    if (isNavigationEnabled) {
      const currentRouteIndex = tabItems.findIndex((tab) =>
        tab.tabHeader && tab.tabHeader.path
          ? currentLocation.pathname === tab.tabHeader.path
          : '',
      );
      const selectedIndex = currentRouteIndex > -1 ? currentRouteIndex : 0;
      handleSelectTab(selectedIndex);
      const item = tabItems[selectedIndex];
      if (item) {
        setTabContentToRender(item.tabContent);
      }
    }
  }, [
    currentLocation,
    tabItems,
    isNavigationEnabled,
    selectedTabIndex,
    handleSelectTab,
  ]);

  const hasIcon = tabItems.some((tab) => tab.tabHeader.icon !== undefined);

  return (
    <>
      {!hideTabs && (
        <StyledTabs
          value={selectedTabIndex}
          onChange={onTabSelectionChange}
          textColor="primary"
          variant={variant}
          indicatorColor="primary"
          ScrollButtonComponent={MoreButton}
          data-testid="flows-tabs"
          data-qa-id="tab-flows"
          $hasIcon={hasIcon}
          $splitEqually={splitEqually}
          scrollButtons={scrollButtons}
          $isTabPositionFixed={isTabPositionFixed}
          className={className}
          isEmbeddedInMainApp={isEmbeddedInMainApp}
          {...(tabItems.some((tab) => tab.tabHeader.label === 'Feed') && {
            paddingLeft: '84px',
          })}
        >
          {tabItems.map((tab, index) => {
            const isFiltered = tab.isFiltered === true;
            return (
              <StyledTab
                $hasIcon={hasIcon}
                $splitEqually={splitEqually}
                value={index}
                key={index}
                tabIndex={index}
                $isFiltered={isFiltered}
                {...tab.tabHeader}
              />
            );
          })}
        </StyledTabs>
      )}
      {tabItems.map((tabPanel, index) =>
        isNavigationEnabled ? (
          <TabPanel value={selectedTabIndex} index={index} key={index}>
            {tabContentToRender}
          </TabPanel>
        ) : (
          <TabPanel value={selectedTabIndex} index={index} key={index}>
            {tabPanel.tabContent}
          </TabPanel>
        ),
      )}
    </>
  );
};

export default TabComponent;
