import React from 'react';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import IconButton from '../../atoms/IconButton_V2';
import Modal from '../../atoms/Modal';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import LoadingPulse from '../../atoms/LoadingPulse';
import {
  ButtonColors,
  ButtonStatuses,
  ButtonVariants,
  ButtonSizes,
} from '../../atoms/Button/interfaces';
import { device } from '../../../constants/layout';

const useStyles = makeStyles({
  topWrapper: {
    position: 'relative',
    padding: '16px',
    borderBottom: `1px solid ${ThemeV2.palette.gray4}`,
  },
  headerButton: () => ({
    position: 'absolute !important' as 'absolute',
    top: '12px',
    left: '12px',
  }),
  iconButton: () => ({
    position: 'absolute !important' as 'absolute',
    top: '16px',
    right: '16px',
  }),
  heading: {
    marginBottom: '0px',
    marginTop: '0px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  childrenWrapper: (props: BasicModalTemplateProps) => ({
    padding: props.childrenPadding,
    maxHeight: props.disableContentScroll ? 'auto' : '456px',
    overflowY: props.disableContentScroll ? 'visible' : 'auto',
    '& > *:first-child': {
      marginTop: 0,
    },
    '& > *:last-child': {
      marginBottom: 0,
    },
  }),
  buttonsWrapper: (props: BasicModalTemplateProps) => ({
    display: 'flex',
    justifyContent:
      props.alignActionButtons === 'left' ? 'flex-start' : 'flex-end',
    padding: '16px',
    borderTop: `1px solid ${ThemeV2.palette.gray4}`,
  }),
  primaryAndSecondaryButtonsWrapper: (props: BasicModalTemplateProps) => ({
    display: 'flex',
    justifyContent:
      props.alignActionButtons === 'left' ? 'flex-start' : 'flex-end',
    '& >:not(:first-child):last-child': {
      marginLeft: '16px',
    },
    width: '100%',
  }),
});

export const ModalWrapper = styled(Modal)`
  max-width: 496px;
  width: 100%;
`;

export interface ModalButtonProps {
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
  status?: ButtonStatuses;
  variant?: ButtonVariants;
  color?: ButtonColors;
  icon?: string;
  size?: ButtonSizes;
  onClick(): void;
}

export interface BasicModalTemplateProps {
  isLoading?: boolean;
  isOpen?: boolean;
  children: React.ReactNode;
  heading?: string;
  primaryButton?: ModalButtonProps;
  secondaryButton?: ModalButtonProps;
  tertiaryButton?: ModalButtonProps;
  childrenPadding?: string;
  disableContentScroll?: boolean;
  onModalClose(): void;
  className?: string;
  alignActionButtons?: 'left' | 'center' | 'right';
  hasTitleSection?: boolean;
  headerButton?: ModalButtonProps;
  hideCloseIcon?: boolean;
  dataIntroId?: string;
}

const BasicModalTemplate = (props: BasicModalTemplateProps) => {
  const classes = useStyles(props);
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const {
    isLoading = false,
    isOpen = true,
    children,
    heading,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    onModalClose,
    className,
    alignActionButtons = 'center',
    hasTitleSection = true,
    headerButton,
    hideCloseIcon = false,
    dataIntroId,
  } = props;

  return (
    <ModalWrapper
      isOpen={isOpen}
      handleClose={onModalClose}
      showMobileFullScreen
      className={className}
    >
      {isLoading && <LoadingPulse />}
      {!isLoading && (
        <div data-introjs-id={dataIntroId} id={dataIntroId}>
          {hasTitleSection && (
            <div className={classes.topWrapper}>
              {headerButton && (
                <Button
                  variant={headerButton?.variant}
                  color={headerButton?.color}
                  size={headerButton?.size}
                  icon={headerButton?.icon}
                  onClick={headerButton?.onClick}
                  className={classes.headerButton}
                >
                  {headerButton?.text}
                </Button>
              )}
              <Heading
                variant={isMobileView ? 'h6' : 'h5'}
                className={classes.heading}
              >
                {heading}
              </Heading>
              {!hideCloseIcon && (
                <IconButton
                  onClick={onModalClose}
                  className={classes.iconButton}
                >
                  <SVGIcon
                    icon="round-close"
                    color={ThemeV2.palette.gray6}
                    size="16px"
                  />
                </IconButton>
              )}
            </div>
          )}
          <div className={`${classes.childrenWrapper} modal-childWrapper`}>
            {children}
          </div>
          {(primaryButton || secondaryButton) && (
            <div className={`button-wrapper ${classes.buttonsWrapper}`}>
              {tertiaryButton && (
                <Button
                  color={
                    tertiaryButton.color ? tertiaryButton.color : 'secondary'
                  }
                  variant={tertiaryButton.variant}
                  status={tertiaryButton.status}
                  disabled={tertiaryButton.disabled}
                  isFullWidth={alignActionButtons === 'center'}
                  isLoading={tertiaryButton.isLoading}
                  onClick={tertiaryButton.onClick}
                  icon={tertiaryButton.icon}
                  size={tertiaryButton.size}
                >
                  {tertiaryButton.text}
                </Button>
              )}

              <div className={classes.primaryAndSecondaryButtonsWrapper}>
                {secondaryButton && (
                  <Button
                    color={
                      secondaryButton.color
                        ? secondaryButton.color
                        : 'secondary'
                    }
                    variant={secondaryButton.variant}
                    status={secondaryButton.status}
                    disabled={secondaryButton.disabled}
                    isFullWidth={alignActionButtons === 'center'}
                    isLoading={secondaryButton.isLoading}
                    onClick={secondaryButton.onClick}
                    icon={secondaryButton.icon}
                    size={secondaryButton.size}
                  >
                    {secondaryButton.text}
                  </Button>
                )}
                {primaryButton && (
                  <Button
                    color={
                      primaryButton.color ? primaryButton.color : 'primary'
                    }
                    variant={primaryButton.variant}
                    status={primaryButton.status}
                    disabled={primaryButton.disabled}
                    isFullWidth={alignActionButtons === 'center'}
                    isLoading={primaryButton.isLoading}
                    onClick={primaryButton.onClick}
                    icon={primaryButton.icon}
                    size={primaryButton.size}
                    className="introjs-sharesheet-viewers"
                  >
                    {primaryButton.text}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </ModalWrapper>
  );
};

BasicModalTemplate.defaultProps = {
  childrenPadding: '32px',
};

export default BasicModalTemplate;
