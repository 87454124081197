import styled from 'styled-components';
import Card from '../../atoms/Card';
import GifContainer from '../GifContainer';

export const CardContainer = styled(Card)<{ marginTop?: string }>`
  padding: 32px;
  width: 500px;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
`;

export const StyledGifContainer = styled(GifContainer)`
  margin: 24px 0;
`;
