import { Route, Switch } from 'react-router-dom';

import {
  MOBILE_SETTINGS_ROUTE_PATH,
  MOBILE_NOTIFICATIONS_ROUTE_PATH,
} from '../constants/routes';

import NotificationsController from '../controllers/mobile/NotificationsController';
import UserSettingsController from '../controllers/settings/UserSettingsController';

const MobileRouters = () => {
  return (
    <Switch>
      <Route
        path={MOBILE_NOTIFICATIONS_ROUTE_PATH}
        component={NotificationsController}
      />
      <Route
        path={MOBILE_SETTINGS_ROUTE_PATH}
        component={UserSettingsController}
      />
    </Switch>
  );
};

export default MobileRouters;
