import React, { useCallback, useMemo } from 'react';
import ThemeV2 from '../../../theme';
import useToggle from '../../../hooks/useToggle';
import {
  StyledCheckboxSpan,
  StyledInput,
  StyledLabel,
  StyledSVGIcon,
  StyledWrapper,
} from './styles';

export interface CheckboxProps {
  label?: React.ReactNode;
  name: string;
  value?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: string;
  color?: string;
  className?: string;
  disabled?: boolean;
  stopClickPropagation?: boolean;
  icon?: string | JSX.Element;
}

const CircularCheckbox = (props: CheckboxProps) => {
  const {
    name,
    label,
    value = false,
    size = '24px',
    color = ThemeV2.palette.green7,
    onChange,
    className,
    disabled = false,
    stopClickPropagation,
  } = props;
  const {
    models: { toggleValue: isFocused },
    operations: { setToggleToFalse, setToggleToTrue },
  } = useToggle();

  const handleOnFocus = () => {
    setToggleToTrue();
  };
  const handleOnBlur = () => {
    setToggleToFalse();
  };

  const svgColor = useMemo(() => {
    if (disabled) {
      return ThemeV2.palette.gray3;
    }
    if (!value) {
      return ThemeV2.palette.gray6;
    }
    return color;
  }, [color, value, disabled]);

  const onClick = useCallback(
    (e) => {
      if (stopClickPropagation) {
        e.stopPropagation();
      }
    },
    [stopClickPropagation],
  );

  return (
    <StyledWrapper className={className}>
      <StyledLabel htmlFor={name} checkboxSize={size} disabled={disabled}>
        <StyledCheckboxSpan onClick={onClick}>
          <StyledInput
            type="checkbox"
            value={label?.toString()}
            checked={value}
            id={name}
            name={name}
            onChange={onChange}
            disabled={disabled}
            onBlur={handleOnBlur}
            onKeyUp={handleOnFocus}
            data-testid="checkbox"
            data-qa-id="btn-checkbox"
          />
          <StyledSVGIcon
            icon={
              value ? 'checkbox-circle-checked' : 'checkbox-circle-unchecked'
            }
            size={size}
            color={value ? svgColor : undefined}
            hoverColor={disabled || value ? undefined : color}
            $isFocused={isFocused}
          />
        </StyledCheckboxSpan>
      </StyledLabel>
    </StyledWrapper>
  );
};

export default CircularCheckbox;
