import { useMemo } from 'react';
import moment from 'moment';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom';

import { FilterPayload } from '../../interfaces/Feed';
import { serializeFilterValues } from '../../controllers/rightDrawer/utils';
import { ANONYMOUS_MEMBER_ID } from '../../Utils/rightDrawer';

const useGetParsedFilterOptions = () => {
  const location = useLocation();
  const { pathname, search } = location;
  const parsedParams = parse(search, {
    ignoreQueryPrefix: true,
  });

  const isAnonymousPostedBy = useMemo(
    () =>
      (parsedParams &&
        parsedParams?.isAnonymous &&
        parsedParams?.isAnonymous === 'true') ||
      false,
    [parsedParams],
  );

  const receivedStartDate = useMemo(
    () => (parsedParams && (parsedParams.startDate as string)) || undefined,
    [parsedParams],
  );

  const receivedEndDate = useMemo(
    () => (parsedParams && (parsedParams.endDate as string)) || undefined,
    [parsedParams],
  );

  const receivedFlowsID =
    parsedParams.flowsId
      ?.toString()
      .split(',')
      .map((flows) => `${flows}+flows`) || [];

  const receivedVersionsId =
    parsedParams.versionId?.toString().split(',') || [];

  const receivedBlocksID = parsedParams.blockId?.toString().split(',') || [];

  const receivedPostedById =
    parsedParams.postedBy
      ?.toString()
      .split(',')
      .map((postedBy) => `postedBy-${postedBy}`) || [];

  const receivedMentionedById =
    parsedParams.mentionedBy
      ?.toString()
      .split(',')
      .map((mentionedBy) => `mentionedBy-${mentionedBy}`) || [];

  const receivedFileTypes = parsedParams.fileType?.toString().split(',') || [];

  const isFullScreen = pathname.includes('/fullscreen');
  let trackFilter = '';
  const filter: FilterPayload = {};
  if (receivedStartDate) {
    filter.startDate = moment(receivedStartDate).startOf('day').toISOString();
  }
  if (receivedEndDate) {
    filter.endDate = moment(receivedEndDate).startOf('day').toISOString();
  }
  if (receivedFlowsID.length > 0) {
    filter.flowIds = serializeFilterValues(receivedFlowsID, 'flows');
    trackFilter = 'flow';
  }
  if (receivedVersionsId.length > 0) {
    const updatedReceivedVersionsId = receivedVersionsId.map((actualString) =>
      actualString.replace(`version`, ''),
    );
    filter.flowVersions = updatedReceivedVersionsId.map((item) =>
      parseInt(item, 10),
    );
  }
  if (receivedBlocksID.length > 0) {
    filter.blockIds = receivedBlocksID;
  }
  if (receivedPostedById.length > 0) {
    filter.respondedBy = serializeFilterValues(receivedPostedById, 'postedBy');
  }
  if (isAnonymousPostedBy) {
    receivedPostedById.push(`postedBy-${ANONYMOUS_MEMBER_ID}`);
  }
  if (receivedMentionedById.length > 0) {
    filter.mentions = serializeFilterValues(
      receivedMentionedById,
      'mentionedBy',
    );
  }
  if (receivedFileTypes.length > 0) {
    filter.fileTypes = serializeFilterValues(receivedFileTypes, 'fileType');
  }
  if (isAnonymousPostedBy) {
    filter.isAnonymous = isAnonymousPostedBy;
  }
  if (isFullScreen) {
    filter.tvMode = isFullScreen;
  }

  // TODO: Mark maybe we can create something like this for knowledgeCenter
  const isMainFeedFilterApplied =
    Object.keys(parsedParams).length !== 0 &&
    (receivedStartDate !== undefined ||
      receivedEndDate !== undefined ||
      receivedFlowsID.length > 0 ||
      receivedPostedById.length > 0 ||
      receivedMentionedById.length > 0 ||
      receivedFileTypes.length > 0 ||
      isAnonymousPostedBy);

  const isFlowsFeedFilterApplied =
    Object.keys(parsedParams).length &&
    (receivedStartDate !== undefined ||
      receivedEndDate !== undefined ||
      receivedVersionsId.length > 0 ||
      receivedBlocksID.length > 0 ||
      receivedPostedById.length > 0 ||
      receivedMentionedById.length > 0 ||
      isAnonymousPostedBy);

  const isProfileFeedFilterApplied =
    Object.keys(parsedParams).length !== 0 &&
    (receivedStartDate !== undefined ||
      receivedEndDate !== undefined ||
      receivedFlowsID.length > 0 ||
      receivedMentionedById.length > 0);

  const filterApplied: any[] = [];
  if (Object.keys(parsedParams).length) {
    if (receivedStartDate !== undefined || receivedEndDate !== undefined) {
      filterApplied.push('date');
    }
    if (receivedFlowsID.length > 0) {
      filterApplied.push('flow');
    }
    if (receivedVersionsId.length > 0) {
      filterApplied.push('version');
    }
    if (receivedBlocksID.length > 0) {
      filterApplied.push('block');
    }
    if (receivedPostedById.length > 0 || isAnonymousPostedBy) {
      filterApplied.push('posted by');
    }
    if (receivedMentionedById.length > 0) {
      filterApplied.push('mentioned');
    }
  }

  return {
    receivedStartDate,
    receivedEndDate,
    receivedFlowsID,
    receivedVersionsId,
    receivedBlocksID,
    receivedPostedById,
    receivedMentionedById,
    receivedFileTypes,
    parsedParams,
    filter,
    isMainFeedFilterApplied,
    isFlowsFeedFilterApplied,
    isProfileFeedFilterApplied,
    filterApplied,
    trackFilter,
    isAnonymousPostedBy,
    isFullScreen,
  };
};

export default useGetParsedFilterOptions;
