/* eslint-disable @typescript-eslint/no-empty-interface */
import { FlowBlockContent, FlowBlockFromAPI } from '../../interfaces/Flow';
import { FlowCollaborator, IMemberDTO } from '../../interfaces/member';
import { VisibilityType } from './Feed/interfaces';

import {
  CriteriaCondition,
  CriteriaField,
  CriteriaOperator,
} from '../../interfaces/Flow/Builder';
import { ShareSheetRuleAccessTypes } from '../../controllers/flows/FlowsShareSheetController/types';

export type Rule =
  | {
      value: string[];
      field: CriteriaField;
      operator: CriteriaOperator;
    }
  | {
      value: [];
      field: 'everyone';
      operator: 'is';
    };

export interface GroupRule {
  rules: Rule[];
  condition: CriteriaCondition;
}

export interface PaginationResponse<T> {
  total: number;
  data: T[];
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
}

export interface EmoticonResponse {
  kind: string;
  value: string;
}

export type FlowKind =
  | 'ONDEMAND'
  | 'ONCE'
  | 'RECURRING'
  | 'NO_TRIGGER'
  | 'SCHEDULED';

type ActiveOccurrence = {
  occurrenceId: string;
  startTime: string;
  endTime: string;
  hasResponded: boolean;
  timeZone?: string;
  participantsNotRespondedCount: number;
};

type LastOccurrence = {
  occurrenceId: string;
  startTime: string;
  endTime: string;
};

export interface Occurrence {
  activeOccurrence?: ActiveOccurrence;
  lastOccurrence?: LastOccurrence;
}

export enum FlowsActivityStates {
  Active = 'ACTIVE',
  Muted = 'MUTED',
}

export interface FlowResponse {
  name: string;
  activityState?: FlowsActivityStates;
  state: string;
  color: string;
  flowId: string;
  createdAt: Date;
  updatedAt: Date;
  isShortcut: boolean;
  description: string;
  isFlowOwner: boolean;
  icon: EmoticonResponse;
  creationSource: string;
  isFlowParticipant?: boolean;
  isFlowViewer?: boolean;
  occurrence?: Occurrence;
  templateId?: string;
  responseSettings: {
    anonymity: {
      state: AnonymityStates;
    };
  };
  folderInfo?: Folder;
}

export interface Folder {
  name: string;
  color: string;
  folderId: string;
}

export type FlowsUpdatesResponse = {
  flowId: string;
  unreadMentionsCount: number;
  hasUnreadPost: boolean;
  folderInfo?: Folder;
};

export interface FlowInstanceResponse {
  state: string;
  flowId: string;
  instanceId: string;
  blocks: FlowBlockFromAPI[];
}

export interface CustomCriteriaResponse {
  rules: GroupRule[];
  condition: CriteriaCondition;
}

export interface CriteriaResponse {
  criteria: {
    onlyOwnersAndCollaborators?: boolean;
    everyone?: boolean;
    onlyParticipants?: boolean;
    custom?: CustomCriteriaResponse;
  };
  isNewMembersAdded?: boolean;
}

// Flows Dashboard Responses
export interface GetShortCutsResponse
  extends PaginationResponse<FlowResponse> {}

export interface GetToDoFlowsResponse
  extends PaginationResponse<FlowResponse> {}

export interface GetArchivedFlowsResponse
  extends PaginationResponse<FlowResponse> {}

export interface GetPublishedFlowsResponse
  extends PaginationResponse<FlowResponse> {}

export enum AnonymityStates {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  OPTIONAL = 'OPTIONAL',
}

export enum LinkAccessType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  RESTRICTED = 'RESTRICTED',
}

export enum AccessRole {
  VIEWER = 'VIEWER',
  PARTICIPANT = 'PARTICIPANT',
}

export enum FlowAccessRoles {
  Owner = 'OWNER',
  Viewer = 'VIEWER',
  Participant = 'PARTICIPANT',
  Collaborator = 'COLLABORATOR',
}

export enum CreationSource {
  Member = 'MEMBER',
  Preload = 'PRELOAD',
  OneClickFlow = 'ONE_CLICK',
}

export interface ChannelInfo {
  id: string;
  name: string;
  isPrivate: boolean;
}

export interface Connection {
  provider: string;
  connectionId: string;
  connectionStatus: 'CONNECTED' | 'ARCHIVED' | 'CONNECTION_BROKEN';
  metaData: {
    channelInfo: ChannelInfo[];
  };
  workspaceName: string;
  connectionMessage: string;
}

interface Integrations {
  viewingCriteria: Connection[];
  responseNotification: Connection[];
  participationCriteria: Connection[];
  participationNotification: Connection[];
}

// Flow Details Responses
export interface FlowItemResponse {
  shortcut: boolean;
  allowPrivateResponse: boolean;
  flowId: string;
  templateId: string;
  name: string;
  description: string;
  activityState?: FlowsActivityStates;
  icon: EmoticonResponse;
  participantsCount: number;
  isShortcut: boolean;
  isFlowOwner: boolean;
  isFlowParticipant: boolean;
  isFlowViewer: boolean;
  occurrence: Occurrence;
  kind: FlowKind;
  state: string;
  createdAt: string;
  updatedAt: string;
  creationSource: CreationSource;
  visibility: {
    criteria: VisibilityType;
    viewersCount: number;
  };
  owner: IMemberDTO;
  collaborators?: FlowCollaborator[];
  schedule?: {
    status: string;
    rule: string;
    humanFriendlyText: string;
    nextTimeInUTC: string;
  };
  action?: {
    kind: 'FORM' | 'MESSAGE';
    templatedId?: string;
    blocks: FlowBlockContent[];
  };
  endTimeInMinutes?: number;
  viewing?: CriteriaResponse;
  participation?: CriteriaResponse;
  hasActiveOccurrence?: boolean;
  pendingEmails: string[];
  responseSettings: {
    anonymity: { state: AnonymityStates };
  };
  accessPolicy?: {
    linkAccess: {
      roles: AccessRole[];
      type: LinkAccessType;
      showInLeftDrawer: boolean;
    };
  };
  showScheduleCard?: boolean;
  integrations?: Integrations;
  metaData?: {
    ruleSelected: ShareSheetRuleAccessTypes | null;
  };
  flowAccessRoles?: FlowAccessRoles[];
  hasAnyOccurrences?: boolean;
  participantsNotYetResCount?: number;
}
export interface GetFlowDetailsResponse {
  data: FlowItemResponse;
}

export type EntityPermissionApiResponse = {
  data: {
    data: {
      entityId: string;
      canEndAnnouncement?: boolean;
      canEditAnnouncement?: boolean;
      canShareAsAnnouncement: boolean;
    }[];
  };
};
export interface GetFlowFileResponse {
  data: File;
}

export interface GetFlowActiveResponse {
  data: FlowResponse[];
}

export interface MainNavQueryAPIResponse {
  data: {
    flows: FlowResponse[];
    folder: Folder;
  }[];
}

export interface MainNavQueryData {
  groupedData: { folder: Folder; flows: FlowResponse[] }[];
  flattenedData: FlowResponse[];
  folders: Folder[];
}

export type GetFlowsActivityUpdatesResponse = {
  data: FlowsUpdatesResponse[];
};

export type FlowOccurrenceDetail = {
  occurrenceId: string;
  startTime: string;
};

export type GetFlowOccurrenceDetailsResponse = {
  data: FlowOccurrenceDetail[];
};

export interface GetFlowAuthorizationResponse {
  data: {
    isLoggedIn: boolean;
    participation: 'INTERNAL' | 'EXTERNAL';
  };
}
