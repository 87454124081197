import { PointerEventHandler } from 'react';
import { ButtonVariants } from '../Button/interfaces';

export enum ButtonSize {
  Large = 'large',
  Normal = 'normal',
  Small = 'small',
  Medium = 'medium',
}
export type CustomButtonColorConfig = {
  background: string;
  textColor: string;
  borderColor: string;
  hover: {
    background: string;
    textColor: string;
    borderColor: string;
  };
  active: {
    background: string;
    textColor: string;
    borderColor: string;
  };
};

export type IconButtonColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'selectedTertiary'
  | 'text'
  | 'textWithBackground'
  | 'iconWithoutBackground';
export interface IconButtonProps {
  disabled?: boolean;
  size?: ButtonSize;
  children?: React.ReactNode;
  icon?: string;
  onClick?: (event: any) => void;
  rounded?: boolean;
  title?: string;
  className?: string;
  isSelected?: boolean;
  iconBgColor?: string;
  iconColor?: string;
  dataTestId?: string;
  dataQaId?: string;
  buttonRef?: any;
  color?: IconButtonColors;
  rotate?: number;
  variant?: ButtonVariants | 'custom';
  customColorConfig?: CustomButtonColorConfig;
  onBlur?: () => void;
  onFocus?: () => void;
  onPointerEnter?: PointerEventHandler<HTMLButtonElement>;
  onPointerLeave?: PointerEventHandler<HTMLButtonElement>;
}

export interface StylesComponentsProps {
  size?: ButtonSize;
  rounded?: boolean;
  isSelected?: boolean;
  iconBgColor?: string;
  color: IconButtonColors;
  icon?: string;
  variant?: ButtonVariants | 'custom';
  customColorConfig?: CustomButtonColorConfig;
}
