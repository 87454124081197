import { datadogLogs } from '@datadog/browser-logs';
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import { AuthHeadersV2 } from '../Utils/headerToken';
import endpointInfo from '../constants/endpoints';

if (!import.meta.env.VITE_IS_MOBILE_DEV_MODE) {
  axios.defaults.withCredentials = true;
}

axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    datadogLogs.logger.error('API call failed', error.toJSON());
    return Promise.reject(error);
  },
);

type StringDictionary = Record<string, string | number | boolean>;

export const makeAPICall = (
  apiName: string,
  payload: any = {},
  params: StringDictionary = {},
  substitutions: Record<string, string> = {},
  allowCache = false,
  source?: CancelTokenSource,
) => {
  const requestDetails = endpointInfo[apiName];
  let { endpointURL } = requestDetails;

  Object.keys(substitutions).forEach((key) => {
    const substitution = substitutions[key];
    endpointURL = endpointURL.replace(`:${key}`, substitution);
  });

  return axios.request({
    url: endpointURL,
    method: requestDetails.requestType,
    data: payload,
    params,
    headers: AuthHeadersV2(allowCache).headers,
    cancelToken: source?.token,
  });
};

export const makeAPICallWithDataReturn = async (
  apiName: string,
  payload: any = {},
  params: StringDictionary = {},
  substitutions: Record<string, string> = {},
  allowCache = false,
  source?: CancelTokenSource,
) => {
  const { data } = await makeAPICall(
    apiName,
    payload,
    params,
    substitutions,
    allowCache,
    source,
  );
  return data;
};
