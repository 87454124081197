import { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { FEATURE_LIST, getFeatureDetails } from '../../../../Utils/account';
import {
  deleteAttachmentFile,
  getInviteUserCapabilityFromPersonSelector,
} from '../../../../Utils/flows';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import { getWorkspaceTitlePrefix } from '../../../../Utils/richMetaData';
import Button from '../../../../atomic/atoms/Button';
import SplitButton from '../../../../atomic/molecules/SplitButton';
import { LoadedParticipationTemplate as ParticipationTemplate } from '../../../../atomic/pages/ParticipationTemplate';
import {
  GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL,
  GET_FILE_UPLOAD_SIGNED_URL,
} from '../../../../constants/endpoints';
import { device } from '../../../../constants/layout';
import { isBlockContentValid } from '../../../../hooks/useParticipationFlow/utils';
import {
  FlowBlockFromAPI,
  StaticBlockState,
} from '../../../../interfaces/Flow';
import { ComponentStatus } from '../../../../interfaces/component';
import {
  ANONYMOUS_BANNER_TEXT,
  EDIT_ANSWER_BANNER_TEXT,
  EDIT_DRAFT_BANNER_TEXT,
  EDIT_DRAFT_CLICKABLE_TEXT,
  POST_ANONYMOUSLY,
  SAVE_CHANGES,
} from '../../../../languages/en/flows/participation';
import { ExternalFlowDetailsResponse } from '../../../../queries/Flows/ExternalFlows';
import { FlowInstanceResponse } from '../../../../queries/Flows/interfaces';
import { useCurrentPlanFetchQuery } from '../../../../queries/Profile';
import { isFeatureEnabledInCurrentPlan } from '../../../../queries/Profile/utils';
import useUploadStore, {
  MAX_FILE_UPLOAD_SIZE,
} from '../../../../stores/uploadStore';
import ThemeV2 from '../../../../theme';
import { ExternalFlowDetails } from '../../ParticipationFlowController/useLoadedParticipationFlowController';
import { RenderSlideInfoObject, renderParticipationSlide } from '../../utils';
import { LoadedParticipationFlowControllerProps } from './types';
import useLoadedFlowsParticipationController from './useLoadedFlowsParticipationController';

const LoadedFlowsParticipationFlowController = ({
  flowInstance,
  handleSaveAndCloseParticipationModal,
  profileInfo,
  flowHeaderContent,
  isPrivatePost,
  isAnonymousPost,
  isMutationLoading,
  isEditMutationLoading,
  togglePrivatePost,
  toggleAnonymousPost,
  flowVariant,
  onFlowSubmit,
  identifier,
  creator,
  participationType,
  postData,
  isEditMode = false,
}: LoadedParticipationFlowControllerProps) => {
  const { flowId } = useParams<{ flowId: string }>();
  const flowInstanceResponse = flowInstance as FlowInstanceResponse;

  // @ts-ignore
  const externalFlowDetails: ExternalFlowDetails | undefined = useMemo(() => {
    if (participationType === 'INTERNAL') {
      return undefined;
    }

    const flow = flowInstance as ExternalFlowDetailsResponse;
    return {
      name: flow?.name,
      description: flow?.description,
      schedule: flow?.schedule,
      creator: creator,
      endTime: flow.endTime,
      icon: flow.icon,
      kind: flow.icon.kind,
    };
  }, [participationType, flowInstance, creator]);

  const {
    models: {
      blockErrors,
      currentStep,
      fieldErrors,
      hasVisitedLastStep,
      dynamicBlockData,
      values,
      stepData,
      containerRef,
      isFetchingUploadUrl,
      touched,
      allowPrivateResponse,
      isAnonymityEnabled,
      allowAnonymousResponse,
      editedBlockIds,
      isDraftMode,
      isInvitingUsers,
      flowData,
    },
    operations: {
      onFormCompleteClick,
      onStepChange,
      setFieldValue,
      goToNextStep,
      goToPreviousStep,
      handleModalClose,
      handleDeleteDraft,
      onNeedHelpButtonClick,
      setIsFetchingUploadUrl,
    },
  } = useLoadedFlowsParticipationController(
    flowInstance as FlowInstanceResponse,
    handleSaveAndCloseParticipationModal,
    profileInfo,
    onFlowSubmit,
    externalFlowDetails,
    postData,
  );

  const editPostInstanceId = postData?.instanceId || '';
  const instanceId = isEditMode
    ? editPostInstanceId
    : flowInstanceResponse.instanceId;

  const { uppyInstances, createUppyInstance } = useUploadStore(
    (state) => ({
      uppyInstances: state.uppyInstances,
      createUppyInstance: state.createUppyInstance,
    }),
    shallow,
  );

  const { data: currentPlanData } = useCurrentPlanFetchQuery(
    participationType === 'INTERNAL',
  );

  const fileUploadLimit = isFeatureEnabledInCurrentPlan(
    FEATURE_LIST.FLOW_FILE_UPLOAD_LIMIT,
    currentPlanData,
  )
    ? getFeatureDetails(
        currentPlanData?.features,
        FEATURE_LIST.FLOW_FILE_UPLOAD_LIMIT,
      ).value
    : MAX_FILE_UPLOAD_SIZE;
  useEffect(() => {
    flowInstanceResponse?.blocks.forEach((block: FlowBlockFromAPI) => {
      if (
        block.blockType === 'OPEN_ENDED' ||
        block.blockType === 'FILE_UPLOAD'
      ) {
        createUppyInstance(
          block,
          {
            flowId: flowInstanceResponse.flowId || flowId,
            instanceId,
            identifier: identifier,
          },
          identifier
            ? GET_EXTERNAL_FILE_UPLOAD_SIGNED_URL
            : GET_FILE_UPLOAD_SIGNED_URL,
          participationType === 'INTERNAL'
            ? fileUploadLimit
            : MAX_FILE_UPLOAD_SIZE,
        );
      }
    });
  }, []);

  const remainingAllowance = profileInfo.member.pointsLeftThisCycle;

  const handleDeleteFileClick = useCallback(
    async (fileName) => {
      const currentStepData = stepData[currentStep];
      const { id: blockId } = currentStepData;
      await deleteAttachmentFile({
        blockId,
        instanceId: instanceId,
        fileName,
        flowId: flowInstanceResponse.flowId,
      });
    },
    [currentStep, flowInstanceResponse.flowId, instanceId, stepData],
  );

  //Person Selector Dropdown Footer

  const inviteUserFromPersonSelectorProps =
    getInviteUserCapabilityFromPersonSelector({
      profileInfo,
      isUserManagementTreatmentOn: true,
    });

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const getDropdownFooterMessage = () => {
    if (isMobileView) {
      return 'Type emails to invite new teammates';
    } else {
      return inviteUserFromPersonSelectorProps?.isAdminApprovalRequired
        ? // eslint-disable-next-line max-len
          'Don’t see who you’re looking for? Type an email to invite a new teammate, and an invite request will be sent to admin.'
        : 'Don’t see who you’re looking for? Type an email to invite a new teammate.';
    }
  };

  const renderSlideInfo: RenderSlideInfoObject = {
    blockErrors,
    currentStep,
    fieldErrors,
    flowInstance: flowInstanceResponse,
    goToNextStep,
    handleDeleteFileClick,
    onStepChange,
    values,
    profileInfo,
    remainingAllowance,
    setFieldValue,
    setIsFetchingUploadUrl,
    stepData,
    touched,
    uppyInstances,
    flowId,
    isPreviewFlow: false,
    isExternalFlow: participationType === 'EXTERNAL',
    isEditMode,
    instanceId,
    inviteUserFromPersonSelectorProps,
    dropdownFooterMessage: getDropdownFooterMessage(),
  };

  const isPostDisabled = !(
    stepData.filter(
      (step: StaticBlockState) =>
        (step.type === 'SCALE' ? values[step.id] !== null : values[step.id]) &&
        isBlockContentValid(step, values[step.id]),
    ).length > 0 && hasVisitedLastStep
  );

  const externalFooterButtons: JSX.Element[] = [
    <Button
      disabled={isPostDisabled || isFetchingUploadUrl}
      isLoading={isMutationLoading}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
    >
      {POST_ANONYMOUSLY}
    </Button>,
  ];

  const internalSubmitButton = (
    <SplitButton
      disabled={isPostDisabled || isFetchingUploadUrl}
      isLoading={isMutationLoading || isInvitingUsers}
      isPrivatePost={isPrivatePost}
      isAnonymousPost={isAnonymousPost}
      allowPrivateResponse={allowPrivateResponse}
      onPrivatePostToggle={togglePrivatePost}
      isAnonymityEnabled={isAnonymityEnabled}
      allowAnonymousResponse={allowAnonymousResponse}
      isDraftMode={isDraftMode}
      onAnonymousPostToggle={toggleAnonymousPost}
      onPostClick={onFormCompleteClick}
      onCloseAndSaveDraftClick={handleModalClose}
      key="1"
    />
  );

  const internalEditPostSubmitButton = (
    <Button
      disabled={!editedBlockIds.length || isFetchingUploadUrl}
      isLoading={isEditMutationLoading}
      onClick={onFormCompleteClick}
      size="large"
      key="1"
      icon="tick"
      variant="contained"
      status="success"
      dataTestId="edit-submit"
    >
      {SAVE_CHANGES}
    </Button>
  );

  const internalFooterButtons: JSX.Element[] = [
    isEditMode ? internalEditPostSubmitButton : internalSubmitButton,
  ];

  const getBannerProps = () => {
    const bannerProps: any = [];
    if (isEditMode) {
      bannerProps.push({
        bannerIcon: 'edit',
        iconColor: ThemeV2.palette.gray8,
        bannerText: EDIT_ANSWER_BANNER_TEXT,
      });
    }

    if (isDraftMode) {
      bannerProps.push({
        bannerIcon: 'generic-file',
        iconColor: ThemeV2.palette.gray8,
        bannerText: EDIT_DRAFT_BANNER_TEXT,
        clickableText: EDIT_DRAFT_CLICKABLE_TEXT,
      });
    }

    if (isAnonymityEnabled || isAnonymousPost) {
      bannerProps.push({
        bannerIcon: 'anonymous',
        iconColor: ThemeV2.palette.gray8,
        bannerText: ANONYMOUS_BANNER_TEXT,
      });
    }

    return bannerProps;
  };

  const pageTitle = `${mapHexCodeToEmoticon(flowData.icon?.value || '')} ${
    flowData.name
  }`.trim();

  return (
    <ParticipationTemplate
      isFullScreen
      isAnonymousPost={isAnonymousPost}
      currentStep={currentStep}
      goToNextStep={goToNextStep}
      goToPreviousStep={goToPreviousStep}
      showCloseModalOption={participationType !== 'EXTERNAL'}
      isNextButtonDisabled={
        participationType === 'EXTERNAL'
          ? currentStep === flowInstanceResponse.blocks.length
          : currentStep === flowInstanceResponse.blocks.length - 1
      }
      member={profileInfo.member}
      isPreviousButtonDisabled={currentStep === 0}
      onCloseModal={handleModalClose}
      footerButtons={
        participationType === 'EXTERNAL'
          ? externalFooterButtons
          : internalFooterButtons
      }
      onStepChange={onStepChange}
      dynamicBlockData={dynamicBlockData}
      singleSlideContainerRef={containerRef}
      flowVariant={flowVariant}
      flowId={flowInstanceResponse.flowId}
      onNeedHelpButtonClick={onNeedHelpButtonClick}
      flowHeaderContent={flowHeaderContent}
      headerStatus={ComponentStatus.LOADED}
      bannerProps={getBannerProps()}
      isEditMode={isEditMode}
      isDraftMode={isDraftMode}
      handleDeleteDraft={handleDeleteDraft}
      creator={creator}
    >
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          property="og:title"
          content={`${pageTitle} | ${getWorkspaceTitlePrefix(
            profileInfo.assembly.name,
          )}`}
        />
        <meta property="og:description" content={flowData?.description} />
      </Helmet>
      {renderParticipationSlide(renderSlideInfo)}
    </ParticipationTemplate>
  );
};

export default LoadedFlowsParticipationFlowController;
