/* eslint-disable max-len */
import FlowPost from './Post/FlowPost';
import searching from '../../../img/searching.svg';
import RecognitionPost from './Post/RecognitionPost';
import { Flex } from '../../../Utils/styles/display';
import { PostTypes } from '../../../interfaces/Home';
import useRepliesSidebar from '../../../hooks/useRepliesSidebar';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';

import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';

import {
  useGetSinglePostQuery,
  useGetFlowResponseQuery,
} from '../../../queries/Feed';
import { PostLoader } from './Post/Loader';

import useMobileApp from '../../../hooks/useMobileApp';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { POST_NOT_FOUND, POST_SUBHEADER } from '../../../languages/en/header';
import Error from '../../../atomic/molecules/PageHeader/Error';
import useLayoutStore from '../../../stores/layoutStore';
import { setRightAsideOpenToFalseSelector } from '../../../stores/layoutStore/selectors';
import { shallow } from 'zustand/shallow';
import useSetRepliesToSidebar from '../../../hooks/useSetRepliesToSideBar';
import { AnnouncementBanner } from '../../../atomic/organism/FlowFeedPost/Components/AnnouncementBanner';
import styled from 'styled-components';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../interfaces/PostMessage';
import { useReactionsSocket } from '../../../hooks/useReactionsSocket';

const StyledAnnouncementBanner = styled(AnnouncementBanner)<{
  marginTop: number;
}>`
  border-radius: 0;
  margin-left: -2px;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`;

const ErrorState = () => {
  return (
    <EmptyErrorStateTemplate
      image={searching}
      variant={HeadingType.Bold}
      heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
      subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
    />
  );
};

const RepliesRightDrawerController = () => {
  const { isMobileApp } = useMobileApp();
  const { push } = useHistory();
  const { type, flowId, postId, responseId, reset, commentId, showReplies } =
    useRepliesSidebar();
  const { data: profileData } = useProfileInfoFetchQuery();
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const button = useMemo(() => {
    return {
      text: 'Log into a different workspace',
      onClick: () => push('/signout'),
    };
  }, [push]);

  const setRightAsideClose = useLayoutStore(setRightAsideOpenToFalseSelector);

  const { toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { shouldResetReplies } = useSetRepliesToSidebar();
  const shouldNotResetSelectedMember = location.pathname.includes('/user');

  const handleCloseButtonClick = () => {
    if (shouldResetReplies) {
      reset(shouldNotResetSelectedMember);
    } else {
      toggleRightAsideOpen();
    }
  };

  const NoPostsFoundComponent = () => {
    const subHeading = `${FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      You’re currently logged into ${profileData?.assembly.name}. Make sure you’re logged into the correct Assembly workspace.`;
    return (
      <>
        <Error
          title={POST_NOT_FOUND}
          subTitle={POST_SUBHEADER}
          onClick={() => {
            setRightAsideClose();
          }}
        />
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
          subHeading={subHeading}
          button={button}
        />
      </>
    );
  };

  const {
    isInitialLoading: isFlowsLoading,
    isError: isFlowsFetchingError,
    data: flowFeedResponseData,
  } = useGetFlowResponseQuery(
    flowId || '',
    responseId || '',
    type === PostTypes.FLOW,
  );

  const { isInitialLoading: isPostsLoading, isError: isPostFetchingError } =
    useGetSinglePostQuery(postId || '', type !== PostTypes.FLOW);

  useReactionsSocket();

  if (isFlowsLoading || isPostsLoading) {
    return <PostLoader />;
  }

  if (isPostFetchingError || isFlowsFetchingError) {
    return isMobileView ? <NoPostsFoundComponent /> : <ErrorState />;
  }

  return (
    <div className="introjs-replies-sidebar">
      <Flex alignItems="flex-start">
        {type === 'flow' ? (
          <Flex flexDirection="column" width="100%">
            {(isMobileApp || isMobileView) &&
              flowFeedResponseData?.activeAnnouncement && (
                <StyledAnnouncementBanner
                  disableToolTip
                  marginTop={isMobileApp ? 8 : 0}
                  onClick={(args) => {
                    postMessageToMobileApp({
                      action: AppAction.AnnouncementClicked,
                      targetEvent: AppTargetEvent.Mobile,
                      payload: {
                        description: args.description,
                      },
                    });
                  }}
                  title={flowFeedResponseData.activeAnnouncement.title}
                  description={
                    flowFeedResponseData.activeAnnouncement.description
                  }
                  selectedColor={flowFeedResponseData.activeAnnouncement.color}
                />
              )}
            <FlowPost
              flowId={flowId}
              commentId={commentId}
              responseId={responseId}
              isMobileApp={isMobileApp}
              showReplies={showReplies}
              handleCloseButtonClick={handleCloseButtonClick}
              data={flowFeedResponseData}
            />
          </Flex>
        ) : (
          <RecognitionPost
            postId={postId}
            commentId={commentId}
            isMobileApp={isMobileApp}
            showReplies={showReplies}
            handleCloseButtonClick={handleCloseButtonClick}
          />
        )}
      </Flex>
    </div>
  );
};

export default RepliesRightDrawerController;
