import { MenuItemIndividualItem } from '../../../atomic/molecules/Dropdown_V2/interfaces';
import { Folder } from '../../../queries/Flows/interfaces';
import { allFlowFeedHeaderOptions } from './data';

type GetFlowHeaderOptionItemsParams = {
  isFlowOwner: boolean;
  isFlowParticipant: boolean;
  isFlowViewer: boolean;
  isFlowActivityUpdatesTreatmentOn?: boolean;
  hasMultiplePosts?: boolean;
  isMobilePlatform?: boolean;
  isMobileView?: boolean;
  currentFolder: Folder | undefined;
  folders: Folder[];
  isFlowCollaborator?: boolean;
  isFlowSummariesEnabled?: boolean;
  canUserCreateFlow?: boolean;
  hasActiveOccurrence?: boolean;
};

export const getFlowHeaderOptionItems = (
  data: GetFlowHeaderOptionItemsParams,
) => {
  const {
    isFlowOwner,
    isFlowCollaborator,
    isFlowParticipant,
    isFlowViewer,
    hasMultiplePosts,
    isMobilePlatform,
    isMobileView,
    currentFolder,
    folders,
    isFlowSummariesEnabled,
    canUserCreateFlow,
    hasActiveOccurrence,
  } = data;

  const headerOptionItems: MenuItemIndividualItem[] = [];
  const isFlowEditor = isFlowOwner || isFlowCollaborator;

  if (isMobileView) {
    if (isFlowSummariesEnabled) {
      headerOptionItems.push(allFlowFeedHeaderOptions.summarizeFlow);
    }

    headerOptionItems.push(allFlowFeedHeaderOptions.filterFlowFeed);
    headerOptionItems.push(allFlowFeedHeaderOptions.aboutThisFlow);
  }

  if (isFlowEditor && canUserCreateFlow && !isMobilePlatform) {
    headerOptionItems.push(allFlowFeedHeaderOptions.editFlow);
  }

  if (isFlowEditor) {
    headerOptionItems.push(allFlowFeedHeaderOptions.shareAsTemplate);
  }

  if (isFlowEditor && hasActiveOccurrence) {
    headerOptionItems.push(allFlowFeedHeaderOptions.endOccurrence);
  }

  if ((isFlowViewer || isFlowEditor) && !isMobilePlatform) {
    headerOptionItems.push(allFlowFeedHeaderOptions.duplicateFlow);
  }

  if (isFlowParticipant || (isFlowEditor && isFlowViewer)) {
    headerOptionItems.push(allFlowFeedHeaderOptions.editNotificationSettings);
  }

  if (!isMobilePlatform && hasMultiplePosts) {
    headerOptionItems.push(allFlowFeedHeaderOptions.enterFullScreen);
  }

  folders
    .filter((folder) => folder.folderId !== currentFolder?.folderId)
    .forEach((folder) => {
      headerOptionItems.push({
        value: `Move to ${folder.name}`,
        id: `moveToFolder_${folder.folderId}`,
        prefixIcon: 'move-to-folder',
        isSelected: false,
      });
    });

  if (currentFolder?.folderId) {
    headerOptionItems.push(allFlowFeedHeaderOptions.removeFromFolder);
  }

  if (isFlowOwner) {
    let archiveOption = allFlowFeedHeaderOptions.archiveFlow;
    if (hasActiveOccurrence) {
      archiveOption = { ...archiveOption, disabled: true };
    }
    headerOptionItems.push(archiveOption);
  }

  return headerOptionItems;
};
