import React from 'react';
import RewardsButton from '../../../atomic/organism/RewardsButton';
import useRewardsButtonController from './useRewardsButtonController';

const RewardsButtonController = () => {
  const {
    assemblyData,
    currentUserData,
    hideRedeemButton,
    hideAllowanceDetails,
    hideRewardsLink,
    hideRewardsInfo,
    refreshDate,
    isObserver,
    rewardType,
    trackPointsMenuOpened,
    trackLeftDrawerRedeemClick,
  } = useRewardsButtonController();

  if (!currentUserData || !assemblyData) {
    return <div />;
  }

  return (
    <RewardsButton
      assemblyCurrency={assemblyData.currency}
      totMonthlyAllowance={currentUserData.allowance.points}
      remainingMonthlyAllowance={currentUserData.pointsLeftThisCycle}
      refreshInfoDate={refreshDate}
      trophyBalance={currentUserData.pointsEarned}
      hideRedeemButton={hideRedeemButton}
      hideAllowanceDetails={hideAllowanceDetails}
      hideRewardsLink={hideRewardsLink}
      hideRewardsInfo={hideRewardsInfo}
      isObserver={isObserver}
      redeemButtonClicked={() => {
        trackLeftDrawerRedeemClick();
      }}
      rewardType={rewardType}
      onRewardsButtonClick={trackPointsMenuOpened}
    />
  );
};

export default RewardsButtonController;
