import { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMediaQuery } from 'react-responsive';
import { shallow } from 'zustand/shallow';
import { TEMPLATE_GALLERY_EVENTS } from '../../../Utils/analytics/constants';
import { trackTemplateGalleryActionEvent } from '../../../Utils/analytics/templateGallery';
import { setFlowBannerStatus } from '../../../Utils/flows/feeds';
import { showErrorMessage } from '../../../Utils/toast';
import {
  GET_ACTIVE_FLOWS,
  GET_MAIN_NAV_CONTENTS,
  GET_MEMBER_NOTIFICATION_PREFERENCES,
} from '../../../constants/endpoints';
import { device } from '../../../constants/layout';
import { FLOWS_FEEDS, NEW_FLOWS_BUILDER } from '../../../constants/routes';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { EventMessage } from '../../../interfaces/PostMessage';
import {
  useCreateOneClickFlow,
  useGetFlowTemplate,
} from '../../../queries/Flows/Template';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import { toggleRightAsideOpenSelector } from '../../../stores/layoutStore/selectors';
import useModalsStore from '../../../stores/modalsStore';

import { buildURL } from '../../../Utils/common';
import { TEMPLATE_GALLERY_FRAME_URL } from '../../../config';
import { RenderPreviewTemplateModalProps } from '../utils';
import {
  FlowTemplateProps,
  TemplateAppAction,
  TemplateEventData,
} from './type';
import { authStore } from '../../../stores/authStore';

const useTemplateGalleryController = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<
    FlowTemplateProps | undefined
  >();
  const [isTemplatesDataLoading, setTemplatesDataLoading] = useState(true);
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const { push, replaceParams } = useHistoryWrapper();

  const { data: profileInfo } = useProfileInfoFetchQuery();
  const { isInitialLoading: isFlowTemplateLoading, data: flowTemplateData } =
    useGetFlowTemplate(selectedTemplate ? selectedTemplate.templateId : '');
  const { mutate: createOneClickFlow, isLoading: isCreatingOneClickFlow } =
    useCreateOneClickFlow();

  const iFrameRef = useRef<HTMLIFrameElement | null>(null);
  const iFrame = iFrameRef ? iFrameRef.current?.contentWindow : null;

  const { closeCreateFlowModal, folderId } = useModalsStore();
  const { isCreatingFlowForTheFirstTime } = authStore();

  //Preset Filters
  const params = new URLSearchParams(location.search);
  const onboardingPath = params.get('onboardingPath');
  const getTemplateFrameURL = () => {
    return buildURL(TEMPLATE_GALLERY_FRAME_URL, {
      isEmbedded: 'true',
    });
  };

  const handleTemplateGalleryModalClose = useCallback(() => {
    closeCreateFlowModal();
    setSelectedTemplate(undefined);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('showTemplate');
    searchParams.delete('onboardingPath');
    searchParams.delete('canSetManagerFilter');
    replaceParams({
      search: searchParams.toString(),
    });
    const message = JSON.stringify({
      targetEvent: 'templates',
      action: 'clearTemplates',
    });
    if (iFrame) {
      iFrame?.postMessage(message, '*');
    }
  }, [closeCreateFlowModal, iFrame, replaceParams]);

  const handleStartFromScratchClick = useCallback(() => {
    let path = NEW_FLOWS_BUILDER;
    if (folderId) {
      path += `?folderId=${folderId}`;
    }
    push(path);
    handleTemplateGalleryModalClose();
  }, [folderId, handleTemplateGalleryModalClose, push]);

  const setToggleRightAsideOpen = useLayoutStore(toggleRightAsideOpenSelector);

  const closeTemplateGalleryModal = useCallback(() => {
    handleTemplateGalleryModalClose();
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('showTemplateGallery');
    replaceParams({
      search: searchParams.toString(),
    });
    setToggleRightAsideOpen();
  }, [handleTemplateGalleryModalClose, replaceParams, setToggleRightAsideOpen]);

  const closePreviewTemplateModal = useCallback(
    () => setSelectedTemplate(undefined),
    [],
  );

  const {
    isRightAsideOpen,
    isLeftAsideOpen,
    toggleRightAsideOpen,
    toggleLeftAsideOpen,
  } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      isLeftAsideOpen: state.isLeftAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
    }),
    shallow,
  );
  const queryClient = useQueryClient();

  const onUseTemplateClick = useCallback(() => {
    if (selectedTemplate) {
      const { templateId, title, filterApplied, searchQuery } =
        selectedTemplate;
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.USE_TEMPLATE,
        templateId: templateId,
        templateName: title,
        filterApplied,
        searchQuery,
      });

      if (isRightAsideOpen) {
        toggleRightAsideOpen();
      }
      if (isMobileView && isLeftAsideOpen) {
        toggleLeftAsideOpen();
      }
      createOneClickFlow(
        { templateId, folderId },
        {
          onSuccess: async (res) => {
            handleTemplateGalleryModalClose();
            setFlowBannerStatus(res.data.flowId);
            push(
              FLOWS_FEEDS.replace(
                ':flowId',
                isCreatingFlowForTheFirstTime
                  ? `${res.data.flowId}?flow-created-from-template=true`
                  : `${res.data.flowId}?flow-created-from-template=true&show-share-sheet=true`,
              ),
            );
            await queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
            await queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
            await queryClient.invalidateQueries([
              GET_MEMBER_NOTIFICATION_PREFERENCES,
            ]);
          },
          onError: () => {
            showErrorMessage(
              'Whoops! We were unable to create flow from this template, please try again',
            );
          },
        },
      );
    }
  }, [
    selectedTemplate,
    isRightAsideOpen,
    isMobileView,
    isLeftAsideOpen,
    createOneClickFlow,
    folderId,
    toggleRightAsideOpen,
    toggleLeftAsideOpen,
    handleTemplateGalleryModalClose,
    push,
    isCreatingFlowForTheFirstTime,
    queryClient,
  ]);

  useEffect(() => {
    const handleReceivedEventMessage = (message: EventMessage) => {
      try {
        if (typeof message.data === 'string') {
          const eventData: TemplateEventData = JSON.parse(message.data);
          if (eventData.targetEvent !== 'templates') {
            return;
          }

          switch (eventData.action) {
            case TemplateAppAction.TemplateGalleryLoaded:
              setTemplatesDataLoading(false);
              break;

            case TemplateAppAction.StartFromScratchClicked:
              handleStartFromScratchClick();
              break;

            case TemplateAppAction.CloseTemplateGalleryClicked:
              closeTemplateGalleryModal();
              break;

            case TemplateAppAction.TemplateSelected:
              setSelectedTemplate(eventData.payload);
              break;
          }
        }
      } catch (e) {
        console.log(e, message);
      }
    };
    window.addEventListener('message', handleReceivedEventMessage);
    return () => {
      window.removeEventListener('message', handleReceivedEventMessage);
    };
  }, [closeTemplateGalleryModal, handleStartFromScratchClick]);

  const renderPreviewTemplateModalProps: RenderPreviewTemplateModalProps = {
    profileInfo,
    closePreviewTemplateModal,
    flowTemplateData,
    selectedTemplate,
    isCreatingOneClickFlow,
    onUseTemplateClick,
    isFlowTemplateLoading,
  };

  return {
    iFrameRef,
    isMobileView,
    onboardingPath,
    getTemplateFrameURL,
    isTemplatesDataLoading,
    renderPreviewTemplateModalProps,
    handleTemplateGalleryModalClose,
  };
};

export default useTemplateGalleryController;
