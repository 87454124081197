import { create } from 'zustand';
import { Channel } from '../../interfaces/Connections';

type SlackUserStore = {
  isTeamsNotified: boolean;
  applicationCacheId: string;
  selectedConnectionId: string;
  selectedConnectionName: string;
  isCreatingConnectionPool: boolean;
  selectedPeopleToPostChannels: Channel[];
  selectedPostFlowResponseChannels: Channel[];
  setIsTeamsNotified: (value: boolean) => void;
  setApplicationCacheId: (value: string) => void;
  setSelectedConnectionId: (value: string) => void;
  setSelectedConnectionName: (value: string) => void;
  setIsCreatingConnectionPool: (value: boolean) => void;
  setSelectedPeopleToPostChannels: (value: Channel[]) => void;
  setSelectedPostFlowResponseChannels: (value: Channel[]) => void;
};

const useSlackUserStore = create<SlackUserStore>()((set) => ({
  applicationCacheId: '',
  isTeamsNotified: false,
  selectedConnectionId: '',
  selectedConnectionName: '',
  isCreatingConnectionPool: false,
  selectedPeopleToPostChannels: [],
  selectedPostFlowResponseChannels: [],
  setIsTeamsNotified: (value: boolean) =>
    set(() => ({ isTeamsNotified: value })),
  setApplicationCacheId: (value: string) =>
    set(() => ({ applicationCacheId: value })),
  setSelectedConnectionId: (value: string) =>
    set(() => ({ selectedConnectionId: value })),
  setSelectedConnectionName: (value: string) =>
    set(() => ({ selectedConnectionName: value })),
  setSelectedPostFlowResponseChannels: (value: Channel[]) =>
    set(() => ({ selectedPostFlowResponseChannels: value })),
  setSelectedPeopleToPostChannels: (value: Channel[]) =>
    set(() => ({ selectedPeopleToPostChannels: value })),
  setIsCreatingConnectionPool: (value: boolean) =>
    set(() => ({ isCreatingConnectionPool: value })),
}));

export default useSlackUserStore;
