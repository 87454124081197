import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import { KioskFooterWrapper, LogOutButton } from './styles';

import Body from '../../atoms/Body';
import { useSignOut } from '../../../hooks/useSignOut';
import { Flex } from '../../../Utils/styles/display';
import useKioskStore from '../../../stores/kioskStore';

import { KIOSK_PAGE_PATH, SIGN_IN_PATH } from '../../../constants/routes';
import {
  KIOSK_INFO_MESSAGE_WITH_LOGOUT,
  KIOSK_INFO_MESSAGE_WITHOUT_LOGOUT,
  LOG_OUT_NOW_LABEL,
} from '../../../languages/en/kiosk';

const KioskFooter = () => {
  const { pathname } = useLocation();
  const { handleUserSignOut } = useSignOut();
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();

  const onIdle = () => {
    if (isKioskTurnedOn) {
      handleUserSignOut();
    }
  };

  const { start, reset } = useIdleTimer({
    onIdle,
    startManually: true,
    timeout: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (isKioskTurnedOn) {
      start();
    } else {
      reset();
    }
  }, [isKioskTurnedOn, reset, start]);

  if (isKioskTurnedOn) {
    return (
      <KioskFooterWrapper>
        {pathname === KIOSK_PAGE_PATH && <></>}
        {pathname !== SIGN_IN_PATH ? (
          <Flex>
            <Body variant="body1Medium">{KIOSK_INFO_MESSAGE_WITH_LOGOUT}</Body>
            <div onClick={handleUserSignOut}>
              <LogOutButton variant="body1Medium" color="geekBlue6">
                {LOG_OUT_NOW_LABEL}
              </LogOutButton>
            </div>
          </Flex>
        ) : (
          <Flex>
            <Body variant="body1Medium">
              {KIOSK_INFO_MESSAGE_WITHOUT_LOGOUT}
            </Body>
          </Flex>
        )}
      </KioskFooterWrapper>
    );
  }

  return null;
};

export default KioskFooter;
