import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import {
  useFetchFlowDetailsQuery,
  useVerifyFlowAuthorization,
} from '../../../../queries/Flows/Feed';
import { HttpsStatus } from '../../../../interfaces/ResponseError';
import {
  FlowItemResponse,
  FlowInstanceResponse,
  AnonymityStates,
} from '../../../../queries/Flows/interfaces';
import { ParticipationFlowHeaderContent } from '../../../../atomic/molecules/FlowsParticipationHeader';
import { ComponentStatus } from '../../../../interfaces/component';
import { FLOW_NOT_FOUND } from '../../../../languages/en/flows';
import {
  ANSWER_POSTED_SUCCESSFULLY,
  ANSWER_UPDATED_SUCCESSFULLY,
  ARE_ANSWERING,
  GO_BACK_TO_MAIN_FEED,
} from '../../../../languages/en/flows/participation';
import { Flex } from '../../../../Utils/styles/display';
import {
  StyledBodyFlowName,
  StyledBodyHeaderTitle,
  StyledEmoticon,
} from '../../../../atomic/molecules/FlowsParticipationHeader/styles';
import Body from '../../../../atomic/atoms/Body';
import { YOU } from '../../../../languages/en/singleWords';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import {
  useEditFlowResponse,
  useGetFlowInstanceQuery,
  useSubmitFlowInstanceMutation,
} from '../../../../queries/Flows/Dashboard';
import isEmpty from 'lodash/isEmpty';
import {
  ExternalFlowCreatorDetails,
  ExternalFlowDetailsResponse,
  useGetExternalFlowsQuery,
  useSubmitExternalFlowInstanceMutation,
} from '../../../../queries/Flows/ExternalFlows';
import { profileData } from '../../../../Utils/home/feeds/dummyData';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { NO_DESCRIPTION } from '../../../../languages/en/flows/feed';
import useToggle from '../../../../hooks/useToggle';
import {
  FlowFileForAPI,
  FlowSubmissionDetails,
} from '../../../../interfaces/Flow';
import { ParticipationTemplateErrorTypes } from '../../../../atomic/pages/ParticipationTemplate/types';
import {
  dismissAllToasts,
  postErrorToastMessage,
  postSuccessToastMessage,
  showErrorMessage,
  showSuccessMessage,
} from '../../../../Utils/toast';
import { formatBlockResponses } from '../../ParticipationFlowController/utils';
import useGoogleEnterpriseRecaptcha, {
  GoogleReCaptchaActionTypes,
} from '../../../../hooks/useGoogleEnterpriseRecaptcha';
import { GOOGLE_RECAPTCHA_SITE_KEY, NEW_APP_URL } from '../../../../config';
import { EXTERNAL_PATH_COMPLETION } from '../../../../constants/routes';
import { parse } from 'qs';
import useModalsStore from '../../../../stores/modalsStore';
import { removeParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';
import { PARTICIPATION_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import {
  trackFlowParticipationActionEvent,
  trackFlowParticipationErrorEvent,
} from '../../../../Utils/analytics/flowsParticipation';
import { useGetFlowResponseQuery } from '../../../../queries/Feed';
import {
  getTouchedBlockIds,
  mapPostResponseDetailsToEditablePostData,
} from './utils';
import { useDraftStore } from '../../../../stores/draftStore';
import { convertToRaw } from 'draft-js';
import useUploadStore from '../../../../stores/uploadStore';
import { shallow } from 'zustand/shallow';
import uniq from 'lodash/uniq';
import { sanitizeFileName } from '../../../../atomic/molecules/FlowInputBlocks/FlowsFileUploadInputBlock/utils';
import {
  getFileUploadAnalyticsPropertiesForUppy,
  getBlockTypeForFileUploadAnalytics,
} from '../../../../atomic/molecules/FileCard/utils';
import useLayoutStore from '../../../../stores/layoutStore';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction } from '../../../../interfaces/PostMessage';
import { useParticipationFlowMessageEvent } from '../../../../hooks/useParticipationFlowMessageEvent';

const useFlowsParticipationController = () => {
  // FlowId
  const history = useHistory();
  const { search } = useLocation();
  const { drafts, saveDraft, deleteDraft, saveErrorDraft } = useDraftStore();
  const { flowId } = useParams<{ flowId: string }>();
  const { responseId } = useParams<{ responseId: string }>();
  const { isEmbeddedInMainApp } = useLayoutStore();

  const parsedParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [erroredPostInstances, setErroredPostInstances] = useState<{
    [instanceId: string]: string[];
  }>({});

  useParticipationFlowMessageEvent();

  const occurrenceId =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const redirectedValue =
    (parsedParams && (parsedParams.isRedirected as string)) || undefined;

  const removeParticipationFlow = useModalsStore(
    removeParticipationFlowSelector,
  );

  const { postActiveUploads, uppyInstances, clearUppyInstances } =
    useUploadStore(
      (state) => ({
        postActiveUploads: state.postActiveUploads,
        uppyInstances: state.uppyInstances,
        clearUppyInstances: state.clearUppyInstances,
      }),
      shallow,
    );

  const isEditMode = !!responseId;

  const {
    data: postData,
    isError: isPostDataError,
    isInitialLoading: isPostDataLoading,
  } = useGetFlowResponseQuery(flowId, responseId, isEditMode);

  // Internal Participation
  const [errorMessage, setErrorMessage] = useState('');
  const [customError, setCustomError] = useState<
    ParticipationTemplateErrorTypes | undefined
  >(undefined);
  const [isOccurrenceClosed, setIsOccurrenceClosed] = useState(false);
  const [isUnAuthorizedError, setIsUnAuthorizedError] = useState(false);
  const [participationType, setParticipationType] = useState<
    'INTERNAL' | 'EXTERNAL'
  >('INTERNAL');
  const [flowData, setFlowData] = useState<FlowItemResponse | null>(null);
  const [flowPostData, setFlowPostData] = useState<
    FlowInstanceResponse | ExternalFlowDetailsResponse | null
  >(null);

  const postCloseParticipationModalMessage = (redirectUrl: string) => {
    window.parent.postMessage(
      { type: 'CLOSE_PARTICIPATION_MODAL', payload: redirectUrl },
      '*',
    );
  };

  const onFlowDetailsError = (error: AxiosError<{ message: string }>) => {
    if (error.response?.status === HttpsStatus.CONFLICT) {
      setErrorMessage(error.response.data.message);
    }

    const errorCode = error.response?.status;
    setIsUnAuthorizedError(
      errorCode === HttpsStatus.UNAUTHORIZED ||
        errorCode === HttpsStatus.FORBIDDEN,
    );
  };

  const onFlowInstanceError = (
    error: AxiosError<{ message: ParticipationTemplateErrorTypes }>,
  ) => {
    if (error.response?.status === HttpsStatus.CONFLICT) {
      setCustomError(error.response.data.message);
    }

    if (error.response?.status === HttpsStatus.FORBIDDEN) {
      setErrorMessage(error.response.data.message);
    }
  };

  const onExternalFlowInstanceError = (
    error: AxiosError<{ message: ParticipationTemplateErrorTypes }>,
  ) => {
    if (error.response?.status === HttpsStatus.CONFLICT) {
      if (
        error.response.data.message ===
          ParticipationTemplateErrorTypes.ACCESS_DENIED &&
        participationType === 'EXTERNAL'
      ) {
        window.location.href = `${NEW_APP_URL}/login`;
      }
      setCustomError(error.response.data.message);
    }

    if (error.response?.status === HttpsStatus.FORBIDDEN) {
      setErrorMessage(error.response.data.message);
    }
  };

  const {
    data: flowDetails,
    isError: isFlowDetailsError,
    isInitialLoading: isFlowDetailsLoading,
  } = useFetchFlowDetailsQuery(
    flowId,
    '',
    !isUnAuthorizedError,
    onFlowDetailsError,
  );

  const {
    data: flowDetailsInstance,
    isInitialLoading: isFlowDetailsInstanceLoading,
  } = useGetFlowInstanceQuery({
    flow: {
      participationFlowId: flowId,
      occurrenceId: occurrenceId,
    },
    enabled: !isFlowDetailsLoading && !isUnAuthorizedError && !isEditMode,
    onErrorCallback: onFlowInstanceError,
  });

  const handleUploadErrorForCurrentPostInstance = useCallback(
    (e: any) => {
      if (e?.detail?.data) {
        const failedFileName = e?.detail?.data?.name
          ? sanitizeFileName(`${e?.detail?.data?.name}`)
          : undefined;
        if (flowDetailsInstance?.instanceId && failedFileName) {
          const modifiedErrorInstance = {
            ...erroredPostInstances,
            [flowDetailsInstance?.instanceId]:
              erroredPostInstances[flowDetailsInstance?.instanceId]?.length > 0
                ? uniq([
                    ...erroredPostInstances[flowDetailsInstance?.instanceId],
                    failedFileName,
                  ])
                : [failedFileName],
          };
          setErroredPostInstances(modifiedErrorInstance);
        }
      }
    },
    [erroredPostInstances, flowDetailsInstance?.instanceId],
  );

  document.addEventListener(
    `erroredInstanceId:${flowDetailsInstance?.instanceId}`,
    handleUploadErrorForCurrentPostInstance,
  );

  useEffect(() => {
    setErroredPostInstances({});
    return () => {
      document.removeEventListener(
        `erroredInstanceId:${flowDetailsInstance?.instanceId}`,
        handleUploadErrorForCurrentPostInstance,
      );
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      if (postData) {
        setFlowPostData(mapPostResponseDetailsToEditablePostData(postData));
      }
    } else if (flowDetailsInstance) {
      setFlowPostData(flowDetailsInstance);
    }
  }, [flowDetailsInstance, flowId, isEditMode, postData]);

  useEffect(() => {
    if (flowDetails) {
      const data = flowDetails.data;
      setFlowData(data);
      setParticipationType('INTERNAL');

      if (!data.isShortcut) {
        if (data.occurrence?.activeOccurrence) {
          if (data.occurrence.activeOccurrence.hasResponded) {
            setIsOccurrenceClosed(true);
            setErrorMessage(
              ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED,
            );
          }
        } else {
          setIsOccurrenceClosed(true);
          setErrorMessage(
            ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED,
          );
        }
      }
    }
  }, [flowDetails]);

  // External Participation
  const [identifier, setIdentifier] = useState<string>();
  const [creator, setCreator] = useState<ExternalFlowCreatorDetails>();
  const [validationError, setValidationError] = useState<
    { code: number; message: string } | undefined
  >(undefined);

  const handleValidationErrors = (error: unknown) => {
    const axiosError = error as AxiosError<{ message: string }>;
    const axiosErrorMessage = axiosError?.response?.data?.message;
    const statusCode = axiosError?.response?.status;
    setValidationError(
      !isEmpty(axiosErrorMessage) && statusCode
        ? {
            code: statusCode,
            message: axiosErrorMessage || '',
          }
        : undefined,
    );
  };

  const { isInitialLoading: isFlowAuthorizationLoading } =
    useVerifyFlowAuthorization(
      flowId,
      isUnAuthorizedError,
      handleValidationErrors,
    );

  const {
    data: externalFlowData,
    isInitialLoading: isExternalFlowDataLoading,
  } = useGetExternalFlowsQuery(
    flowId,
    Boolean(!isFlowAuthorizationLoading && isUnAuthorizedError),
    onExternalFlowInstanceError,
  );

  useEffect(() => {
    if (externalFlowData) {
      setParticipationType('EXTERNAL');
      setCreator(externalFlowData.data.creator);
      setIdentifier(externalFlowData.data.identifier);
      setFlowPostData(externalFlowData.data.flow);
    }
  }, [flowId, externalFlowData]);

  const [flowHeaderContent, headerStatus]: [
    ParticipationFlowHeaderContent,
    ComponentStatus,
  ] = useMemo(() => {
    if (externalFlowData) {
      return [
        {
          TitleContent: (
            <Flex>
              <StyledBodyHeaderTitle color="gray8" variant="body2">
                <Body color="geekblue6" inline variant="body2Medium">
                  {`${YOU} `}
                </Body>
                {`${ARE_ANSWERING} `}
              </StyledBodyHeaderTitle>
              <StyledEmoticon>
                {mapHexCodeToEmoticon(externalFlowData.data.flow.icon.value)}
              </StyledEmoticon>
              <StyledBodyFlowName
                color="geekBlue6"
                inline
                variant="body2Medium"
              >
                {externalFlowData.data.flow.name}
              </StyledBodyFlowName>
            </Flex>
          ),
          DescriptionContent: (
            <Flex key="description">
              <Body variant="body3" color="gray7">
                {externalFlowData.data.flow.description || NO_DESCRIPTION}
              </Body>
            </Flex>
          ),
        },
        ComponentStatus.LOADED,
      ];
    }

    if (isFlowDetailsError && !externalFlowData && !isExternalFlowDataLoading) {
      return [
        {
          TitleContent: FLOW_NOT_FOUND,
          DescriptionContent: GO_BACK_TO_MAIN_FEED,
        },
        ComponentStatus.ERROR,
      ];
    }

    if (isFlowDetailsLoading || !flowDetails) {
      return [
        {
          TitleContent: '',
          DescriptionContent: '',
        },
        ComponentStatus.LOADING,
      ];
    }

    return [
      {
        TitleContent: (
          <Flex>
            <StyledBodyHeaderTitle color="gray8" variant="body2">
              <Body color="geekblue6" inline variant="body2Medium">
                {`${YOU} `}
              </Body>
              {`${ARE_ANSWERING} `}
            </StyledBodyHeaderTitle>
            <StyledEmoticon>
              {mapHexCodeToEmoticon(flowDetails.data.icon.value)}
            </StyledEmoticon>
            <StyledBodyFlowName color="geekBlue6" inline variant="body2Medium">
              {flowDetails.data.name}
            </StyledBodyFlowName>
          </Flex>
        ),
        DescriptionContent: (
          <Flex key="description">
            <Body variant="body3" color="gray7">
              {flowDetails.data.description || NO_DESCRIPTION}
            </Body>
          </Flex>
        ),
      },
      ComponentStatus.LOADED,
    ];
  }, [
    isExternalFlowDataLoading,
    externalFlowData,
    flowDetails,
    isFlowDetailsError,
    isFlowDetailsLoading,
  ]);

  const { loading: loadingCaptcha, generateToken } =
    useGoogleEnterpriseRecaptcha(
      GOOGLE_RECAPTCHA_SITE_KEY,
      GoogleReCaptchaActionTypes.EXTERNAL_PARTICIPATION_SUBMISSION,
      isUnAuthorizedError,
    );

  useEffect(() => {
    // This is to hide the Google Captcha badge
    // https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
    setTimeout(() => {
      const items = document.getElementsByClassName('grecaptcha-badge');
      if (items?.length) {
        items[0].setAttribute('style', 'visibility: hidden; position: fixed;');
      }
    }, 500);
  }, [loadingCaptcha]);

  const { data: profileInfoData } = useProfileInfoFetchQuery(
    !isFlowDetailsLoading && !isUnAuthorizedError,
  );

  // Misc Participation Information
  const {
    mutate: internalFlowMutation,
    isLoading: internalFlowMutationLoading,
  } = useSubmitFlowInstanceMutation(flowId || '');

  const { mutate: editResponseMutate, isLoading: isEditingResponseLoading } =
    useEditFlowResponse(flowId, responseId);

  const {
    mutate: externalFlowMutation,
    isLoading: externalFlowMutationLoading,
  } = useSubmitExternalFlowInstanceMutation(flowId, identifier || '');

  const {
    models: { toggleValue: isPrivatePost },
    operations: { setToggleValue: togglePrivatePost },
  } = useToggle();
  const {
    models: { toggleValue: isAnonymousPost },
    operations: { setToggleValue: toggleAnonymousPost },
  } = useToggle();

  const formatPayload = useCallback(
    (submittedValues: Record<string, any>) =>
      formatBlockResponses(
        submittedValues,
        flowPostData as FlowInstanceResponse,
        isPrivatePost,
        isAnonymousPost ||
          flowDetails?.data.responseSettings.anonymity.state ===
            AnonymityStates.ENABLED,
        participationType === 'EXTERNAL',
        isEditMode,
        erroredPostInstances,
      ),
    [
      flowPostData,
      isPrivatePost,
      isAnonymousPost,
      flowDetails?.data.responseSettings.anonymity.state,
      participationType,
      isEditMode,
      erroredPostInstances,
    ],
  );

  const handleCloseParticipationModal = useCallback(() => {
    removeParticipationFlow();
    const params = new URLSearchParams(search);
    const redirectUrl = params.get('redirectUrl');
    const redirectLocation = params.get('redirectLocation');
    if (redirectLocation === 'mobile') {
      postMessageToMobileApp({
        action: AppAction.DraftActionPerformed,
        payload: {
          type: 'closeDraftModalAction',
        },
      });
      return;
    }
    if (
      redirectUrl?.startsWith('http://') ||
      redirectUrl?.startsWith('https://')
    ) {
      if (isEmbeddedInMainApp) {
        postCloseParticipationModalMessage(redirectUrl);
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      if (isEmbeddedInMainApp) {
        if (!redirectUrl) {
          postCloseParticipationModalMessage('');
          return;
        }
      }
      history.push(params.get('redirectUrl') || '/');
    }
  }, [history, isEmbeddedInMainApp, removeParticipationFlow, search]);

  const handleSaveAndCloseParticipationModal = useCallback(
    (values?: Record<string, any>) => {
      if (values) {
        const newValues = { ...values };
        Object.keys(values).forEach((key) => {
          if (
            values[key] &&
            values[key].editorState &&
            values[key].editorState.getCurrentContent
          ) {
            newValues[key].editorState = convertToRaw(
              newValues[key].editorState.getCurrentContent(),
            );
          }
        });
        saveDraft(flowId, newValues);
      }
      handleCloseParticipationModal();
    },
    [flowId, handleCloseParticipationModal, saveDraft],
  );

  const handleMutationError = (error: unknown, editedBlockIds?: string[]) => {
    const saveResponseErrorMessage = (error as AxiosError<{ message: string }>)
      .response?.data.message;

    if (
      saveResponseErrorMessage !==
      ParticipationTemplateErrorTypes.INVALID_BLOCK_PARAMETERS
    ) {
      setCustomError(
        saveResponseErrorMessage as ParticipationTemplateErrorTypes,
      );
    }
    if (
      saveResponseErrorMessage !==
      ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
    ) {
      if (isEmbeddedInMainApp && saveResponseErrorMessage) {
        postErrorToastMessage(saveResponseErrorMessage);
      } else if (saveResponseErrorMessage) {
        showErrorMessage(saveResponseErrorMessage);
      }
    }

    if (isEditMode) {
      trackFlowParticipationErrorEvent({
        ...flowDetails?.data,
        editedBlockIds,
        numberOfBlocksEdited: editedBlockIds?.length,
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_FLOW_POST_ERROR,
      });
    }
  };

  const onFileUploadSuccess = useCallback(
    (values: FlowSubmissionDetails, instance: FlowInstanceResponse) => {
      instance.blocks.forEach(({ blockType, blockId }) => {
        switch (blockType) {
          case 'FILE_UPLOAD': {
            const { fileSize, fileType, totalFileSize, numFilesUploaded } =
              getFileUploadAnalyticsPropertiesForUppy(
                values.values[blockId] as FlowFileForAPI[],
              );
            trackFlowParticipationActionEvent({
              action: PARTICIPATION_ANALYTICS_EVENTS.FILE_UPLOADED,
              blockType: getBlockTypeForFileUploadAnalytics(blockType),
              internalParticipant: false,
              numFilesUploaded: `${numFilesUploaded}`,
              fileType,
              fileSize,
              totalFileSize: `${totalFileSize}`,
            });
            break;
          }
          case 'OPEN_ENDED': {
            const { files } = values.values[blockId];
            const { fileSize, fileType, totalFileSize, numFilesUploaded } =
              getFileUploadAnalyticsPropertiesForUppy(
                files as FlowFileForAPI[],
              );
            trackFlowParticipationActionEvent({
              action: PARTICIPATION_ANALYTICS_EVENTS.FILE_UPLOADED,
              blockType: getBlockTypeForFileUploadAnalytics(blockType),
              internalParticipant: false,
              numFilesUploaded: `${numFilesUploaded}`,
              fileType,
              fileSize,
              totalFileSize: `${totalFileSize}`,
            });
          }
        }
      });
    },
    [],
  );

  const onFlowSubmit = async (submissionDetails: FlowSubmissionDetails) => {
    const { values } = submissionDetails;
    let payload = formatPayload(values);

    if (flowDetailsInstance)
      onFileUploadSuccess(submissionDetails, flowDetailsInstance);
    if (participationType === 'EXTERNAL') {
      const captchaToken = await generateToken();
      // @ts-ignore
      payload = { ...payload, captchaToken };
    }

    const mutate =
      participationType === 'EXTERNAL'
        ? externalFlowMutation
        : internalFlowMutation;

    if (isEditMode) {
      // Manually overriding onError and onSuccess call for this mutate function.

      const editedBlockIds = getTouchedBlockIds(
        payload.responses,
        postData?.responses,
      );
      const editedBlocks = payload.responses.filter((block) =>
        editedBlockIds.includes(block.blockId),
      );
      const editPayload = {
        instanceId: payload.instanceId,
        responses: editedBlocks,
      };
      const submitEditPost = (isDelayed: boolean) => {
        editResponseMutate(editPayload, {
          onError: (error: unknown) => {
            handleMutationError(error, editedBlockIds);
            if (
              isDelayed &&
              uppyInstances[payload.instanceId] &&
              Object.keys(uppyInstances[payload.instanceId]).length > 0
            ) {
              clearUppyInstances(payload.instanceId);
            }
          },
          onSuccess: () => {
            if (
              uppyInstances[payload.instanceId] &&
              Object.keys(uppyInstances[payload.instanceId]).length > 0
            ) {
              clearUppyInstances(payload.instanceId);
            }
            trackFlowParticipationActionEvent({
              ...flowDetails?.data,
              editedBlockIds,
              numberOfBlocksEdited: editedBlockIds.length,
              action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_FLOW_POST_SAVED,
            });
            if (!isDelayed) {
              removeParticipationFlow();
              history.push(`/flows/${flowId}`);
            }
            if (isEmbeddedInMainApp) {
              postSuccessToastMessage(ANSWER_UPDATED_SUCCESSFULLY);
            } else {
              showSuccessMessage(ANSWER_UPDATED_SUCCESSFULLY);
            }
          },
        });
      };
      if (postActiveUploads[payload.instanceId]?.length > 0) {
        const handleSubmitEditPost = () => {
          submitEditPost(true);
          document.removeEventListener(
            `instanceId:${payload.instanceId}`,
            handleSubmitEditPost,
          );
        };
        document.addEventListener(
          `instanceId:${payload.instanceId}`,
          handleSubmitEditPost,
        );
        removeParticipationFlow();
        history.push(`/flows/${flowId}`);
      } else {
        submitEditPost(false);
      }
    } else {
      const submitPost = (isDelayed: boolean) =>
        mutate(payload, {
          onError: (error: unknown) => {
            handleMutationError(error);
            if (
              postActiveUploads[payload.instanceId]?.length > 0 &&
              participationType === 'INTERNAL'
            ) {
              if (values) {
                const newValues = { ...values };
                Object.keys(values).forEach((key) => {
                  if (
                    values[key] &&
                    values[key].editorState &&
                    values[key].editorState.getCurrentContent
                  ) {
                    newValues[key].editorState = convertToRaw(
                      newValues[key].editorState.getCurrentContent(),
                    );
                  }
                });
                saveErrorDraft(flowId, newValues);
              }
            }
            if (
              isDelayed &&
              uppyInstances[payload.instanceId] &&
              Object.keys(uppyInstances[payload.instanceId]).length > 0
            ) {
              clearUppyInstances(payload.instanceId);
            }
          },
          onSuccess: (flowResponse) => {
            if (drafts[flowId]) {
              deleteDraft(flowId);
            }
            if (participationType === 'EXTERNAL') {
              if (
                uppyInstances[payload.instanceId] &&
                Object.keys(uppyInstances[payload.instanceId]).length > 0
              ) {
                clearUppyInstances(payload.instanceId);
              }
              trackFlowParticipationActionEvent({
                ...flowDetails?.data,
                isExternalFlow: true,
                action: PARTICIPATION_ANALYTICS_EVENTS.POSTED,
              });
              if (!isDelayed) {
                removeParticipationFlow();
                history.push(`${EXTERNAL_PATH_COMPLETION}?flowId=${flowId}`);
              }
            } else {
              if (!isEditMode) {
                window.parent.postMessage(
                  {
                    type: 'FLOW_POST_SUCCESS',
                    payload: {
                      data: {
                        ...flowResponse,
                      },
                    },
                  },
                  '*',
                );
              }
              trackFlowParticipationActionEvent({
                ...flowDetails?.data,
                action: PARTICIPATION_ANALYTICS_EVENTS.POSTED,
              });
              if (!isDelayed) {
                removeParticipationFlow();
                const params = new URLSearchParams(search);
                const redirectUrl = params.get('redirectUrl');
                const redirectLocation = params.get('redirectLocation');
                if (redirectLocation === 'mobile') {
                  postMessageToMobileApp({
                    action: AppAction.DraftActionPerformed,
                    payload: {
                      type: 'closeDraftModalAction',
                    },
                  });
                  return;
                }
                if (
                  redirectUrl?.startsWith('http://') ||
                  redirectUrl?.startsWith('https://')
                ) {
                  postCloseParticipationModalMessage(redirectUrl);
                } else {
                  history.push(params.get('redirectUrl') || '/home');
                }
              }
              if (isEmbeddedInMainApp) {
                postSuccessToastMessage(ANSWER_POSTED_SUCCESSFULLY);
              } else {
                dismissAllToasts();
                showSuccessMessage(ANSWER_POSTED_SUCCESSFULLY);
              }
            }
          },
        });
      if (
        postActiveUploads[payload.instanceId]?.length > 0 &&
        participationType === 'INTERNAL'
      ) {
        const handleSubmitPost = () => {
          submitPost(true);
          document.removeEventListener(
            `instanceId:${payload.instanceId}`,
            handleSubmitPost,
          );
        };

        document.addEventListener(
          `instanceId:${payload.instanceId}`,
          handleSubmitPost,
        );
        removeParticipationFlow();
        const params = new URLSearchParams(search);
        const redirectUrl = params.get('redirectUrl');
        const redirectLocation = params.get('redirectLocation');
        if (redirectLocation === 'mobile') {
          postMessageToMobileApp({
            action: AppAction.DraftActionPerformed,
            payload: {
              type: 'closeDraftModalAction',
            },
          });
          return;
        }
        if (
          redirectUrl?.startsWith('http://') ||
          redirectUrl?.startsWith('https://')
        ) {
          postCloseParticipationModalMessage(redirectUrl);
        } else {
          history.push(params.get('redirectUrl') || '/home');
        }
      } else {
        submitPost(false);
      }
    }
  };

  useEffect(() => {
    removeParticipationFlow();
  }, [removeParticipationFlow]);

  const isParticipationDataLoading =
    isFlowDetailsLoading ||
    isFlowAuthorizationLoading ||
    isFlowDetailsInstanceLoading ||
    isExternalFlowDataLoading;

  const isEditDetailLoading = isParticipationDataLoading || isPostDataLoading;

  const isLoading = isEditMode
    ? isEditDetailLoading
    : isParticipationDataLoading;

  const isEditMutationLoading = isEditMode && isEditingResponseLoading;

  return {
    flowId,
    creator,
    flowData,
    identifier,
    headerStatus,
    errorMessage,
    validationError,
    flowHeaderContent,
    onFlowSubmit,
    toggleAnonymousPost,
    togglePrivatePost,
    customError,
    setCustomError,
    isRedirected: redirectedValue === 'true',
    isMutationLoading:
      internalFlowMutationLoading || externalFlowMutationLoading,
    isEditMutationLoading,
    isPrivatePost,
    isAnonymousPost,
    participationType,
    isOccurrenceClosed,
    isUnAuthorizedError,
    flowDetailsInstanceData: flowPostData,
    isError: isFlowDetailsError,
    handleCloseParticipationModal,
    handleSaveAndCloseParticipationModal,
    profileData: profileInfoData || profileData,
    isLoading,
    postData,
    isPostDataError,
    isEditMode,
  };
};

export default useFlowsParticipationController;
