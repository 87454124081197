import { BenchmarkStatus } from '../../../interfaces/analytics/benchmarking/interfaces';

export enum VariantTypes {
  INDETERMINATE = 'indeterminate',
  DETERMINATE = 'determinate',
}

export interface CircularProgressProps {
  variant: VariantTypes;
  showValue: boolean;
  value?: number;
  size: string;
  showCircularShadow: boolean;
  color: string;
  shadowColor: string;
  valueColor: string;
  showBenchmarkStatus?: boolean;
  benchmarkStatus?: BenchmarkStatus;
  thickness?: number;
  backgroundColor?: string;
}
