import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import Theme from '../../theme';

export const StyledContentLoader = styled(ContentLoader)`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`;

const StyledSpinner = styled.svg`
  animation: rotate 1s linear infinite;
  margin: 40px;
  width: 96px;
  height: 96px;

  & .path {
    stroke: #2f54eb;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
);

const SkeletonWrapper = styled.div`
  width: 400px;
  position: relative;
  z-index: 1;
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  height: 72px;
  background: #fff;
  z-index: 0;
`;

const Body = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100vh - 72px);
  top: 72px;
  background: #f5f5f5;
`;

const SpinnerWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

const Loader = () => {
  return (
    <>
      <Header />
      <SkeletonWrapper>
        <StyledContentLoader
          speed={2}
          width={'100%'}
          height={600}
          viewBox="0 0 476 600"
          backgroundColor={Theme.palette.gray4}
          foregroundColor={Theme.palette.gray3}
          data-testid="loader"
        >
          <circle cx="36" cy="36" r="24" />
          <rect x="65" y="16" rx="4" ry="4" width="367px" height="16" />
          <rect x="65" y="42" rx="4" ry="4" width="285px" height="16" />
        </StyledContentLoader>
      </SkeletonWrapper>
      <Body />
    </>
  );
};

const PreviewLoader = () => {
  return (
    <>
      <Loader />
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    </>
  );
};

export default PreviewLoader;
