import { FileType } from '../../atomic/organism/FileViewer/types';

export type GetAppliedFilterProps = {
  startDate?: Date;
  endDate?: Date;
  flowsId?: string[];
  blockId?: string[];
  versionId?: string[];
  postedBy?: string[];
  mentioned?: string[];
  fileType?: FileType[];
};

export const ANONYMOUS_MEMBER_ID = '000000000000000000000000';

export const getAppliedFilter = (data: GetAppliedFilterProps) => {
  const {
    startDate,
    endDate,
    flowsId,
    blockId,
    versionId,
    postedBy,
    mentioned,
  } = data;
  const filterApplied: string[] = [];
  if (startDate !== undefined || endDate !== undefined) {
    filterApplied.push('date');
  }
  if (flowsId && flowsId.length > 0) {
    filterApplied.push('flow');
  }
  if (versionId && versionId.length > 0) {
    filterApplied.push('version');
  }
  if (blockId && blockId.length > 0) {
    filterApplied.push('block');
  }
  if (postedBy && postedBy?.length > 0) {
    filterApplied.push('posted by');
  }
  if (mentioned && mentioned.length > 0) {
    filterApplied.push('mentioned');
  }

  return filterApplied;
};

export const getFiltersForAnonymousPostedBy = (postedBy: string[]) => {
  let isAnonymous = false;
  let filteredPostedBy: string[] = [];
  if (postedBy) {
    filteredPostedBy = postedBy.filter((item) => {
      const isAnonymousMember =
        item === ANONYMOUS_MEMBER_ID ||
        item === `postedBy-${ANONYMOUS_MEMBER_ID}`;
      if (isAnonymousMember) {
        isAnonymous = isAnonymousMember;
      }
      return !isAnonymousMember;
    });
  }
  return {
    postedBy: filteredPostedBy || [],
    isAnonymous,
  };
};
