import { useState } from 'react';

import ThemeV2 from '../../../theme';
import Body from '../../atoms/Body';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import { getPrettierFileSize } from '../../../Utils/formatting';
import {
  CardBottom,
  CardTop,
  NameWrapper,
  NewStyledBody,
  NewStyledImage,
  StyledCard,
  StyledIconButton,
  StyledSVGIcon,
} from './styles';

import type { ParticipationThumbnailProps } from './types';

export function ParticipationImageThumbnail(
  props: ParticipationThumbnailProps,
) {
  const {
    file,
    cardWidth,
    dataTestId,
    dataQaId,
    cardColor,
    cardIcon,
    onClickClose,
    cardImageUrl,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledCard
      isParticipation
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      cardWidth={cardWidth}
      data-testid={dataTestId}
      data-qa-id={dataQaId}
    >
      <CardTop backgroundColor={cardColor}>
        {cardIcon && <StyledSVGIcon icon={cardIcon} size="32px" />}

        {isHovered && (
          <StyledIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            icon={'close'}
            title={'file card close'}
            onClick={() => onClickClose && onClickClose(file)}
            size={ButtonSize.Normal}
          />
        )}
        {cardIcon === 'imageFile' && (
          <NewStyledImage alt={file.name} src={cardImageUrl} />
        )}
      </CardTop>
      <CardBottom>
        {
          <>
            <NameWrapper>
              <NewStyledBody isParticipation variant="body2Medium">
                {file.name.split('.')[0]}
              </NewStyledBody>
              <Body variant="body2Medium">.{file.name.split('.')[1]}</Body>
            </NameWrapper>
            <NewStyledBody
              isParticipation={false}
              color={ThemeV2.palette.gray8}
              variant="body3"
            >
              {getPrettierFileSize(file.size)}
            </NewStyledBody>
          </>
        }
      </CardBottom>
    </StyledCard>
  );
}
