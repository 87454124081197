import { DroppableProvided } from 'react-beautiful-dnd';
import {
  NotebookAnalyticsParameters,
  NotebookTaskAnalyticsParameters,
} from '../../../interfaces/notebook';
import { BoardSection } from '../../../stores/notebookStore/types';
import { NotebookNewSectionCreationData } from '../NotebookBoardController/useNotebookBoardController';

export type ColumnProps = {
  sectionId: string;
  index: number;
  isScrollable?: boolean;
  isCombineEnabled?: boolean;
  useClone?: boolean;
  dropProvided: DroppableProvided;
  toggleModal: (toggle: boolean, sectionId: string | null) => void;
  isLoading: boolean;
  setNewSectionCreationData: (data: NotebookNewSectionCreationData) => void;
  newSectionCreationData: NotebookNewSectionCreationData;
  dummySectionTitle: string;
  setDummySectionTitle: (value: string) => void;
  handleSectionCreation: (title: string, isBlur?: boolean) => void;
  isDummySectionLoading?: boolean;
  markTasksAsRead: (taskIds: string[]) => void;
};

export type SectionProps = {
  section: BoardSection;
  internalScroll?: boolean;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  taskCreationPosition?: TaskCreationPosition;
  isCreateTaskMode?: boolean;
  isLoading?: boolean;
  handleTaskCreationTextBlur: (content: string) => void;
  handleTaskCreationEnterClick: (content: string) => void;
  handleTaskCreationButtonClick: (position: TaskCreationPosition) => void;
  markTasksAsRead: (taskIds: string[]) => void;
  onTaskDataChange: (
    data: NotebookAnalyticsParameters | NotebookTaskAnalyticsParameters,
  ) => void;
};

export type InnerListProps = {
  dropProvided: DroppableProvided;
  title?: string;
};

export enum TaskCreationPosition {
  top = 'top',
  bottom = 'bottom',
}
