export const getPeopleDirectoryDropdownOptions = (userName: string) => {
  return [
    {
      id: 'people-card-options',
      items: [
        {
          isSelected: false,
          prefixIcon: 'Link',
          value: 'Copy profile link',
          id: 'Copy profile link',
        },
        {
          isSelected: false,
          prefixIcon: 'message',
          value: `Message ${userName}`,
          id: `Message ${userName}`,
        },
        {
          isSelected: false,
          prefixIcon: 'profileDetails',
          value: `See ${userName}’s details`,
          id: `See ${userName}’s details`,
        },
        {
          isSelected: false,
          prefixIcon: 'feed',
          value: `View ${userName}’s activity`,
          id: `View ${userName}’s activity`,
        },
      ],
    },
  ];
};
