import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import { isIPhoneOS, windowSizeAdapter } from '../../../Utils/window';

const desktopSize = (footerHeight: number, isEmbeddedInMainApp?: boolean) => {
  if (isEmbeddedInMainApp) {
    return `calc(100vh - ${footerHeight + 110}px)`;
  } else {
    return `calc(100vh - ${footerHeight + 186}px)`;
  }
};

export const InfiniteScrollWrapper = styled(Flex)<{
  isMobileApp: boolean;
  footerHeight: number;
  isEmbeddedInMainApp?: boolean;
}>`
  overflow-y: auto;
  height: ${({ isMobileApp, footerHeight, isEmbeddedInMainApp }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: `calc(var(--mobileVh, 1vh) * 100 - ${
        footerHeight + (isIPhoneOS ? 160 : 110)
      }px)`,
      mobileWeb: `calc(var(--mobileVh, 1vh) * 100 - ${
        footerHeight + (isIPhoneOS ? 228 : 178)
      }px)`,
      tablet: `calc(100vh - ${footerHeight + 186}px)`,
      desktop: desktopSize(footerHeight, isEmbeddedInMainApp),
    })};
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
`;

export const ChatPostInputWrapper = styled.div`
  margin: 16px;
`;

export const EmptyChatWrapper = styled(Flex)`
  margin: 10px 0;
`;
