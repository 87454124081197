import styled from 'styled-components';

import ClickableLink from '../ClickableLink';
import IconButton from '../../atoms/IconButton_V2';
import { Flex } from '../../../Utils/styles/display';

export const ProfileChatHeaderContainer = styled(Flex)`
  border-bottom: 1px solid #e8e8e8;
`;

export const ProfileInfoContainer = styled(Flex)`
  flex: 1;
  margin-left: 8px;
  align-items: flex-start;
`;

export const ViewProfileLink = styled(ClickableLink)`
  font-weight: 400;
  line-height: 20px;
  font-size: 12px;
`;

export const StyledBackButton = styled(IconButton)`
  margin-right: 8px;
`;

export const BackButton = styled(IconButton)`
  margin-right: 8px;
`;
