import { useState } from 'react';

export const ZOOM = {
  DEFAULT: 100,
  MAX: 500,
  MIN: 25,
  ONCHANGEDIFF: 25,
};

const useZoomSlider = (trackEvent: () => void) => {
  const [zoomPercent, setZoomPercent] = useState(ZOOM.DEFAULT);

  const setZoomPercentandTrack = (value: number) => {
    setZoomPercent(value);
    trackEvent();
  };

  const onSliderMove = (
    event: React.ChangeEvent<unknown> | Event,
    value: number | number[],
  ) => {
    setZoomPercentandTrack(value as number);
  };

  const onZoomOut = () => {
    if (zoomPercent - ZOOM.ONCHANGEDIFF > ZOOM.MIN) {
      setZoomPercentandTrack(zoomPercent - ZOOM.ONCHANGEDIFF);
    } else {
      setZoomPercentandTrack(ZOOM.MIN);
    }
  };

  const onZoomIn = () => {
    if (zoomPercent + ZOOM.ONCHANGEDIFF < ZOOM.MAX) {
      setZoomPercentandTrack(zoomPercent + ZOOM.ONCHANGEDIFF);
    } else {
      setZoomPercentandTrack(ZOOM.MAX);
    }
  };

  return {
    models: { zoomPercent },
    operations: {
      setZoomPercent: setZoomPercentandTrack,
      onSliderMove,
      onZoomIn,
      onZoomOut,
    },
  };
};

export default useZoomSlider;
