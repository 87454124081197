import Uppy, { UppyFile } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';

import { makeAPICallWithDataReturn } from '../../queries/utils';
import { kilobytes } from '../../controllers/flows/FlowsFileUploadInputBlockController/utils';

const useUploadProfilePicUppyInstance = (requestURL: string) => {
  const createUppyInstance = () => {
    const uppyInstance = new Uppy({
      autoProceed: true,
      restrictions: {
        allowedFileTypes: ['.jpg', '.jpeg', '.png', '.gif'],
        minFileSize: kilobytes(10),
      },
    });

    uppyInstance.use(AwsS3, {
      getUploadParameters: async (file: UppyFile) => {
        const payload = { fileName: file.name };
        const params = undefined;
        const urlSubstitutions = {};
        const { url: signedUrl } = await makeAPICallWithDataReturn(
          requestURL,
          payload,
          params,
          urlSubstitutions,
        );

        if (file?.type) {
          return {
            method: 'PUT',
            url: signedUrl,
            headers: {
              'Content-Type': file.type,
            },
          };
        }
        return {
          method: 'PUT',
          url: signedUrl,
        };
      },
    });

    return uppyInstance;
  };

  return { createUppyInstance };
};

export default useUploadProfilePicUppyInstance;
