import ContentLoader from 'react-content-loader';
import { Flex } from '../../../../../Utils/styles/display';
import Theme from '../../../../../theme';

export const UserItemLoader = () => {
  return (
    <Flex width="100%" margin="8px 0px">
      <ContentLoader
        speed={2}
        width={'100%'}
        height={36}
        backgroundColor={Theme.palette.gray4}
        foregroundColor={Theme.palette.gray3}
        uniqueKey="autocomplete-loader"
      >
        <circle cx="16" cy="16" r="16" />
        <rect x="40" y="8" rx="4" width="124" height="16" />
      </ContentLoader>
    </Flex>
  );
};
