import format from 'date-fns/format';
import isEqual from 'lodash/isEqual';

import { getWeekDetails, hasAllWeekDays, isSameDay } from '..';
import { SelectedCustomRecurrenceTypes } from '../../../../atomic/organism/CustomRecurrence/types';
// eslint-disable-next-line max-len
import { FlowFrequencyOptions } from '../../../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';
import {
  ANNUALLY_ON,
  BIWEEKLY_ON,
  DAILY,
  EVERY_WEEKDAY,
  MONTHLY_ON_THE,
  ONLY_ONCE,
  QUARTERLY_ON_THE,
  WEEKLY_ON,
} from '../../../../languages/en/flows/builder';
import { CUSTOM } from '../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../text';

// needed to form the flowFrequency options
/* if the weekdays has been selected through custom option then we need that to form the flowFrequency options */
export const getDayNameFromNumber = (dayInNumber: number) => {
  switch (dayInNumber) {
    case 0:
      return 'Monday';
    case 1:
      return 'Tuesday';
    case 2:
      return 'Wednesday';
    case 3:
      return 'Thursday';
    case 4:
      return 'Friday';
    case 5:
      return 'Saturday';
    default:
      return 'Sunday';
  }
};

// this is to build the frequency options
export const hasWeekDays = (selectedDays: number[]) => {
  const weekDays = [0, 1, 2, 3, 4];
  return isEqual(weekDays, selectedDays);
};

// to get the week count like first,second, etc,.
export const getWeekCountInWords = (weekNumber: number) => {
  switch (weekNumber) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    default:
      return 'fourth';
  }
};

// to get the frequency options
export const getFlowFrequencyOptions = (
  date: Date | undefined,
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined,
) => {
  if (date) {
    const day = format(date, 'EEEE');
    const dayNumber = format(date, 'd');
    const monthAndDate = format(date, 'MMMM d');
    const weekDetails = getWeekDetails(date);

    const array = [
      {
        title: EVERY_WEEKDAY,
        id: FlowFrequencyOptions.EVERY_WEEKDAY,
      },
      {
        title: DAILY,
        id: FlowFrequencyOptions.DAILY,
      },
      {
        title: `${WEEKLY_ON} ${day}`,
        id: FlowFrequencyOptions.WEEKLY,
      },
      {
        title: `${BIWEEKLY_ON} ${day}`,
        id: FlowFrequencyOptions.BIWEEKLY,
      },
    ];

    if (weekDetails) {
      switch (weekDetails.weekNumber) {
        case 1: {
          array.splice(4, 0, {
            title: `${MONTHLY_ON_THE} first ${day}`,
            id: FlowFrequencyOptions.MONTHLY,
          });
          array.splice(5, 0, {
            title: `${QUARTERLY_ON_THE} first ${day}`,
            id: FlowFrequencyOptions.QUARTERLY,
          });
          break;
        }
        case 2: {
          array.splice(4, 0, {
            title: `${MONTHLY_ON_THE} second ${day}`,
            id: FlowFrequencyOptions.MONTHLY,
          });
          array.splice(5, 0, {
            title: `${QUARTERLY_ON_THE} second ${day}`,
            id: FlowFrequencyOptions.QUARTERLY,
          });
          break;
        }
        case 3: {
          array.splice(4, 0, {
            title: `${MONTHLY_ON_THE} third ${day}`,
            id: FlowFrequencyOptions.MONTHLY,
          });
          array.splice(5, 0, {
            title: `${QUARTERLY_ON_THE} third ${day}`,
            id: FlowFrequencyOptions.QUARTERLY,
          });
          break;
        }
        case 4: {
          array.splice(4, 0, {
            title: `${MONTHLY_ON_THE} fourth ${day}`,
            id: FlowFrequencyOptions.MONTHLY,
          });
          array.splice(weekDetails.isLastWeek ? 6 : 5, 0, {
            title: `${QUARTERLY_ON_THE} fourth ${day}`,
            id: FlowFrequencyOptions.QUARTERLY,
          });
          break;
        }
        default:
          break;
      }
      if (weekDetails.isLastWeek) {
        array.splice(weekDetails.weekNumber === 4 ? 5 : 4, 0, {
          title: `${MONTHLY_ON_THE} last ${day}`,
          id: FlowFrequencyOptions.MONTHLY_LAST,
        });
        array.splice(weekDetails.weekNumber === 4 ? 7 : 5, 0, {
          title: `${QUARTERLY_ON_THE} last ${day}`,
          id: FlowFrequencyOptions.QUARTERLY_LAST,
        });
      }
    }

    array.push(
      {
        title: `${ANNUALLY_ON} ${monthAndDate}`,
        id: FlowFrequencyOptions.ANNUALLY,
      },
      {
        title: ONLY_ONCE,
        id: FlowFrequencyOptions.ONCE,
      },
      {
        title: `${capitalizeFirstLetter(CUSTOM)}...`,
        id: FlowFrequencyOptions.CUSTOM,
      },
    );

    if (selectedCustomRecurrenceTypes !== undefined) {
      switch (selectedCustomRecurrenceTypes.repeatEvery) {
        case 'days': {
          if (selectedCustomRecurrenceTypes.repeatEveryCount !== 1) {
            array.splice(array.length - 1, 0, {
              title: `Every ${selectedCustomRecurrenceTypes.repeatEveryCount} days`,
              id: FlowFrequencyOptions.REPEAT_DAYS,
            });
          }
          break;
        }
        case 'weeks': {
          const dayNames = selectedCustomRecurrenceTypes.selectedDayIds.map(
            (val) => getDayNameFromNumber(val),
          );
          const includeWeekDays = hasWeekDays(
            selectedCustomRecurrenceTypes.selectedDayIds,
          );
          const includeAllDays = hasAllWeekDays(
            selectedCustomRecurrenceTypes.selectedDayIds,
          );
          const isTheDaySame = isSameDay(
            date,
            selectedCustomRecurrenceTypes.selectedDayIds[0],
          );
          if (includeWeekDays || includeAllDays) {
            if (includeAllDays) {
              if (selectedCustomRecurrenceTypes.repeatEveryCount === 1) {
                array.splice(array.length - 1, 0, {
                  title: 'Weekly on all days',
                  id: FlowFrequencyOptions.REPEAT_ALL_DAYS,
                });
              } else {
                array.splice(array.length - 1, 0, {
                  title: `Every ${selectedCustomRecurrenceTypes.repeatEveryCount} weeks on all days`,
                  id: FlowFrequencyOptions.REPEAT_ALL_DAYS,
                });
              }
            } else if (includeWeekDays) {
              if (selectedCustomRecurrenceTypes.repeatEveryCount > 1) {
                array.splice(array.length - 1, 0, {
                  title: `Every ${selectedCustomRecurrenceTypes.repeatEveryCount} weeks on weekdays`,
                  id: FlowFrequencyOptions.REPEAT_WEEK_DAYS,
                });
              }
            }
          } else if (
            selectedCustomRecurrenceTypes.repeatEveryCount === 1 &&
            (dayNames.length > 1 ||
              (selectedCustomRecurrenceTypes.selectedDayIds.length === 1 &&
                isTheDaySame === false))
          ) {
            array.splice(array.length - 1, 0, {
              title: `Every week on ${dayNames.join()}`,
              id: FlowFrequencyOptions.REPEAT_WEEKS,
            });
          } else if (
            selectedCustomRecurrenceTypes.repeatEveryCount > 1 &&
            dayNames.length > 0
          ) {
            array.splice(array.length - 1, 0, {
              title: `Every ${
                selectedCustomRecurrenceTypes.repeatEveryCount
              } weeks on ${dayNames.join()}`,
              id: FlowFrequencyOptions.REPEAT_WEEKS,
            });
          }
          break;
        }
        case 'years': {
          if (selectedCustomRecurrenceTypes.repeatEveryCount !== 1) {
            array.splice(array.length - 1, 0, {
              title: `Every ${selectedCustomRecurrenceTypes.repeatEveryCount} years on ${monthAndDate}`,
              id: FlowFrequencyOptions.REPEAT_YEARS,
            });
          }
          break;
        }
        default: {
          if (selectedCustomRecurrenceTypes.repeatEveryCount === 1) {
            if (
              selectedCustomRecurrenceTypes.repeatOnMonth === 'repeatMonthly'
            ) {
              /* every month on the day 16 */
              array.splice(array.length - 1, 0, {
                title: `Every month on the day ${dayNumber}`,
                id: FlowFrequencyOptions.REPEAT_MONTHS,
              });
            }
          } else if (selectedCustomRecurrenceTypes.repeatEveryCount > 1) {
            if (
              selectedCustomRecurrenceTypes.repeatOnMonth ===
                'repeatMonthlyOn' &&
              weekDetails &&
              selectedCustomRecurrenceTypes.repeatEveryCount !== 3
            ) {
              /* every 2 months on the last/third TUE */
              array.splice(array.length - 1, 0, {
                title: `Every ${
                  selectedCustomRecurrenceTypes.repeatEveryCount
                } months on the ${
                  weekDetails.isLastWeek
                    ? 'last'
                    : getWeekCountInWords(weekDetails.weekNumber)
                } ${day}`,
                id: FlowFrequencyOptions.REPEAT_MONTHS,
              });
            } else if (
              selectedCustomRecurrenceTypes.repeatOnMonth === 'repeatMonthly'
            ) {
              /* every 2 months on the day 16 */
              array.splice(array.length - 1, 0, {
                title: `Every ${selectedCustomRecurrenceTypes.repeatEveryCount} months on the day ${dayNumber}`,
                id: FlowFrequencyOptions.REPEAT_MONTHS,
              });
            }
          }
          break;
        }
      }
    }
    return array;
  }
  return [];
};
