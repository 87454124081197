import { SwagCardStatuses } from '../Admin/rewards/Swags/interface';

interface Image {
  original: {
    relativeUrl: string;
  };
  meta?: {
    ratio: string;
  };
}
interface BrandRequirements {
  displayInstructions: string;
  termsAndConditionsInstructions: string;
  disclaimerInstructions: string;
  alwaysShowDisclaimer: boolean;
}

interface ImageUrls {
  '80w-326ppi': string;
  '130w-326ppi': string;
  '200w-326ppi': string;
  '278w-326ppi': string;
  '300w-326ppi': string;
  '1200w-326ppi': string;
}

interface GiftCardRewardItem {
  utid: string;
  rewardName: string;
  currencyCode: string;
  status: string;
  valueType: string;
  rewardType: string;
  isWholeAmountValueRequired: boolean;
  faceValue: number;
  createdDate: string;
  lastUpdateDate: string;
  countries: string[];
  credentialTypes: string[];
  redemptionInstructions: string;
}

export interface Denomination {
  _id: string;
  utid: string;
  faceValue: number;
  currencyCode?: string;
}
export interface CultureReward {
  image: Image;
  version: string;
  type: string;
  isActive: boolean;
  quantity: number;
  isLimit?: boolean;
  department: [];
  _id: string;
  name: string;
  description: string;
  clientID: string;
  pointsToRedeem: number;
  createdAt: string;
  activatedAt: string;
  maxLimit?: number;
}

export interface GiftCardReward {
  brandKey: string;
  brandName: string;
  disclaimer: string;
  description: string;
  shortDescription: string;
  terms: string;
  createdDate: string;
  lastUpdateDate: string;
  brandRequirements: BrandRequirements;
  imageUrls: ImageUrls;
  status: string;
  items: GiftCardRewardItem[];
  rewardType: string;
  isActive: boolean;
  denominations: Denomination[];
  countries: string[];
  maxLimit?: number;
}

export interface AxomoSwagReward {
  desc: string;
  enabled: boolean;
  image: string;
  name: string;
  points: number[];
  productLink: string;
  rewardProvider: string;
  rewardType: string;
  uid: string;
  storeUID: string;
  shippingCost: {
    currency: string;
    value: number;
  };
  status: SwagCardStatuses;
}

export interface AxomoRedeemReward {
  colors: Array<any>;
  desc: string;
  details: string;
  enabled: boolean;
  image: string;
  name: string;
  points: number[];
  productLink: string;
  rewardProvider: string;
  rewardType: string;
  sizes: Array<{ id: string; name: string }>;
  storeUID: string;
  uid: string;
  shippingCost: {
    currency: string;
    value: number;
  };
}

export interface ShippingDetails {
  shippingDate: string;
  status: OrderStatus;
  trackingLink: string;
}

export type CharityReward = GiftCardReward;

interface TangoInfo {
  amountCharged: {
    value: number;
    total: number;
    currencyCode: string;
  };
  denomination: {
    value: number;
    currencyCode: string;
  };
  rewardType: string;
}

export enum OrderStatus {
  CANCELED = 'CANCELED',
  DELIVERED = 'DELIVERED',
  ORDER_PLACED = 'ORDER_PLACED',
  SHIPPED = 'SHIPPED',
}
export interface Tango {
  amount: number;
  brandKey: string;
  brandName: string;
  createdAt: string;
  employerId: string;
  exchangeRate: number;
  imageUrls: ImageUrls;
  orderId: number;
  orderType: string;
  customRewardInfo?: {
    id: string;
    name: string;
  };
  orderInfo?: string;
  pointsRedeemed: number;
  resendEmailCount: number;
  status: string;
  tangoInfo: TangoInfo;
  temporaryEmail: string;
  updatedAt: string;
  user: string;
  utid: string;
  _id: string;
  axomoInfo: {
    size: string;
    color: string;
    quantity: number;
    orderStatus?: OrderStatus;
  };
}
export interface MyRewardsControllerProps {
  orders: Tango[];
}
export type ExchangeRates = Record<string, number>;
export interface GetRewardsResponse {
  data: {
    rewards: CultureReward[];
    exchangeRate: number;
  };
}

export interface GetCheapestRewardResponse {
  leastRewardPoint?: number;
  rewardType?: string;
  data: {
    leastRewardPoint: number;
    rewardType: string;
  };
}

export type RewardsFetchQueryParamsType = Record<string, string>;

export interface DenominationRangeSelectedByEmployer {
  min: number;
  max: number;
}
export interface GetGiftCardResponse {
  data: {
    rewards: GiftCardReward[];
    exchangeRate: number;
    denominationRangeSelectedByEmployer: DenominationRangeSelectedByEmployer;
  };
}

export interface GetDonationResponse {
  data: {
    rewards: CharityReward[];
    exchangeRate: number;
  };
}

export interface GetMyRewardsResponse {
  data: MyRewardsControllerProps;
}
export interface GetExchangeRatesResponse {
  data: {
    exchangeRates: ExchangeRates;
  };
}

export interface GetMyRewardsHistoryResponse {
  data: {
    history: {
      totalAmount: number;
      totalPointsEarned: number;
    };
  };
}

export type GetIPResponse = {
  country_name: string;
  region: string;
};

export type RedeemPostResponse = {
  data: {
    order: {
      status: string;
    };
    remainingPoints: number;
  };
};

export type RedeemPostPayloadType = {
  brandKey: string;
  utid: string;
  faceValue: number;
  rewardType: string;
  brandName: string;
  temporaryEmail: string | null;
  currencyCode: string;
  carrotsRedeemed: number;
};

export interface ErrorMesssageInterface {
  message: string;
}
