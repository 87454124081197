import styled, { keyframes } from 'styled-components';
import AssemblyCurrencyIcon from '../AssemblyCurrencyIcon';
import Body from '../Body';
import { buttonColors, sizeVariant } from './buttonConfigs';
import {
  ButtonWrapperProps,
  ButtonVariants,
  ButtonColors,
  ButtonStatuses,
} from './interfaces';

const disabledBackground = ({
  isLoading,
  variant,
  color,
  status,
}: ButtonWrapperProps) => {
  let background;
  if (isLoading) {
    if (variant === 'text') {
      background = 'transparent';
    } else {
      background = buttonColors[color][variant][status].background;
    }
  } else {
    background = buttonColors[color][variant].disabled.background;
  }
  return background;
};

const disabledTextColor = ({
  isLoading,
  variant,
  color,
  status,
  disabledButtonTextColor,
}: ButtonWrapperProps) => {
  let textColor;
  if (isLoading) {
    textColor = buttonColors[color][variant][status].textColor;
  } else if (disabledButtonTextColor) {
    textColor = disabledButtonTextColor;
  } else {
    textColor = buttonColors[color][variant].disabled.textColor;
  }
  return textColor;
};

const disabledBorderColor = ({
  isLoading,
  variant,
  color,
  status,
}: ButtonWrapperProps) => {
  let borderColor;
  if (isLoading) {
    borderColor = buttonColors[color][variant][status].borderColor;
  } else {
    borderColor = buttonColors[color][variant].disabled.borderColor;
  }
  return borderColor;
};

const blink = keyframes`
    0%{
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100%{
      opacity: 0.2;
    }
`;

const noShadow = (variant: string, color: string) =>
  variant === 'text' || color === 'blueTertiary' || color === 'grayTertiary';

export const ButtonTextWrapper = styled.div<{
  enableCaret: boolean;
  isLoading: boolean;
  variant: ButtonVariants;
}>`
  display: flex;
  align-items: center;
  visibility: ${({ variant, isLoading }) =>
    variant !== 'text' && isLoading && 'hidden'};
  width: ${({ enableCaret }) => enableCaret && '100%'};
  transition: 0.25s ease;
  transition-property: transform;
`;

export const ButtonWrapper = styled.button<ButtonWrapperProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ size, isUnstyled }) =>
    isUnstyled ? 'auto' : sizeVariant[size].height};
  width: ${({ isFullWidth }) => isFullWidth && '100%'};
  padding: ${({ size, padding }) =>
    padding ? padding : sizeVariant[size].padding};
  border-radius: ${({ variant }) => (variant === 'showMore' ? '100px' : '4px')};
  border-bottom-left-radius: ${({ color }) => color === 'blueTertiary' && 0};
  border-bottom-right-radius: ${({ color }) => color === 'blueTertiary' && 0};
  color: ${({ color, variant, status }) =>
    buttonColors[color][variant][status].textColor};
  text-transform: none;
  background: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  cursor: pointer;
  ${({ hasBoxShadow }) =>
    hasBoxShadow &&
    'box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12);'};

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    margin: auto;
    background-color: ${({ color, variant, status, isUnstyled }) =>
      isUnstyled
        ? 'transparent'
        : buttonColors[color][variant][status].background};
    border: 1px solid
      ${({ color, variant, status }) =>
        buttonColors[color][variant][status].borderColor};
    box-shadow: ${({ variant, color, isUnstyled }) =>
      noShadow(variant, color) || isUnstyled
        ? 'none'
        : '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12)'};
    border-radius: inherit;
    transition: 0.25s ease;
    transition-property: transform, box-shadow;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    background: transparent;
    color: ${({ variant, color, status }) =>
      variant === 'text'
        ? buttonColors[color].text[status].hover.textColor
        : buttonColors[color][variant][status].hover.textColor};
    &:before {
      background-color: ${({ variant, color, status, isUnstyled }) => {
        if (isUnstyled) return 'transparent';
        if (variant === 'text')
          return buttonColors[color].text[status].hover.background;
        return buttonColors[color][variant][status].hover.background;
      }};
      border-color: ${({ variant, color, status, isUnstyled }) => {
        if (isUnstyled) return 'transparent';
        if (variant === 'text')
          return buttonColors[color].text[status].hover.borderColor;
        return buttonColors[color][variant][status].hover.borderColor;
      }};
      box-shadow: ${({ variant, color }) =>
        noShadow(variant, color)
          ? 'none'
          : '0px 8px 10px rgba(0, 0, 0, 0.04), 0px 3px 14px rgba(0, 0, 0, 0.02), 0px 4px 5px rgba(0, 0, 0, 0.12)'};
    }
  }
  &:not(:disabled):active {
    background: transparent;
    color: ${({ variant, color, status }) =>
      variant === 'text'
        ? buttonColors[color].text[status].active.textColor
        : buttonColors[color][variant][status].active.textColor};
    ${ButtonTextWrapper} {
      transform: ${({ variant, isFullWidth }) =>
        variant !== 'text' && isFullWidth && 'scale(0.95)'};
    }
    &:before {
      background-color: ${({ variant, color, status }) =>
        variant === 'text'
          ? buttonColors[color].text[status].active.background
          : buttonColors[color][variant][status].active.background};
      border-color: ${({ variant, color, status }) =>
        variant === 'text'
          ? buttonColors[color].text[status].active.borderColor
          : buttonColors[color][variant][status].active.borderColor};
      box-shadow: ${({ variant }) =>
        variant === 'text'
          ? 'none'
          : '0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)'};
      transform: ${({ variant, isFullWidth }) =>
        (variant !== 'text' || isFullWidth) && 'scale(0.95)'};
    }
  }
  &:disabled {
    cursor: not-allowed;
    background: transparent;
    color: ${(props) => disabledTextColor(props)};
    &:before {
      background-color: ${(props) => disabledBackground(props)};
      border-color: ${(props) => disabledBorderColor(props)};
      box-shadow: ${({ variant, isLoading }) =>
        variant !== 'text' && isLoading
          ? '0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02), 0px 1px 5px rgba(0, 0, 0, 0.12)'
          : 'none'};
    }
    & div {
      color: inherit;
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
`;

export const IconWrapper = styled.div<{
  isEndIcon: boolean;
  icon?: string;
  disabled: boolean;
  color: ButtonColors;
  variant: ButtonVariants;
  status: ButtonStatuses;
}>`
  margin-right: ${({ icon, isEndIcon }) => (icon && !isEndIcon ? '8px' : '0')};
  margin-left: ${({ icon, isEndIcon }) => (icon && isEndIcon ? '8px' : '0')};
  fill: ${({ disabled, color, variant, status }) =>
    disabled
      ? buttonColors[color][variant].disabled.textColor
      : buttonColors[color][variant][status].textColor};
`;

export const StyledAssemblyIcon = styled(AssemblyCurrencyIcon)`
  z-index: 1;
  margin-right: 8px;
`;

export const SuffixAssemblyIcon = styled(AssemblyCurrencyIcon)`
  z-index: 1;
  margin-left: 8px;
  margin-right: -4px;
`;

export const ButtonText = styled(Body)<{ fontWeight?: number }>`
  display: flex;
  z-index: 1;
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  width: 100%;
`;

export const ButtonTextInner = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
`;

export const DropdownCaret = styled.div<{
  disabled: boolean;
  color: ButtonColors;
  variant: ButtonVariants;
  status: ButtonStatuses;
  isUnstyled: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: auto;
  ${({ isUnstyled }) => !isUnstyled && 'padding-left: 12px'};
  & svg path {
    fill: ${({ disabled, color, variant, status }) =>
      disabled || color === 'blueTertiary'
        ? buttonColors[color][variant].disabled.textColor
        : buttonColors[color][variant][status].textColor};
  }
`;

export const LoadingDot = styled.div`
  animation: ${blink} 1.3s infinite linear;
  animation-fill-mode: both;
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;
