import styled from 'styled-components';

import TextField from '../NewTextfield';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root {
    &:hover {
      .MuiOutlinedInput-input {
        border-color: ${ThemeV2.palette.gray5};
      }
    }
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-input {
      border-color: ${ThemeV2.palette.geekBlue6};
    }
  }
  .MuiInputBase-root fieldset {
    border-color: ${ThemeV2.palette.gray7};
  }
  .MuiFormLabel-root.MuiInputLabel-root {
    color: ${ThemeV2.palette.gray9};
  }
  .MuiOutlinedInput-adornedStart {
    padding-left: 0;
  }
  .MuiOutlinedInput-input {
    padding-left: 12px;
    border-left: 1px solid ${ThemeV2.palette.gray7};
  }
  .MuiInputAdornment-positionStart {
    width: 72px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin: 0;
    justify-content: space-between;
    cursor: pointer;
    box-sizing: border-box;
  }
  .MuiInputAdornment-root {
    & svg {
      & > path:last-of-type {
        fill: ${ThemeV2.palette.gray9};
      }
    }
  }
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin-left: 8px;
`;
