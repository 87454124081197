import { useCallback, useEffect, useState } from 'react';

import { useGetMainFeedAttachmentsQuery } from '../../../../queries/KnowledgeCenter';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFetchFlowActive } from '../../../../queries/Flows/Dashboard';
import { FeedAttachmentItemFromAPI } from '../../../../interfaces/Feed';
import { downloadFeedFile } from '../../../../Utils/flows';
import {
  useFetchFlowFileQuery,
  useFetchPreviewFileQuery,
} from '../../../../queries/Flows/Feed';
import { getFlowPostUrl } from '../../../../Utils/home/feeds';
import useGetParsedFilterOptions from '../../../../hooks/useGetParsedFilterOptions';
import { formatFileFilters } from '../../../../Utils/file';
import { trackKnowledgeCenterActionEvent } from '../../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../../Utils/analytics/constants';
import { pusher } from '../../../../pusher/pusher-base';
import { Channel } from 'pusher-js';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../../Utils/toast';
import { useParams } from 'react-router-dom';
import { copyToClipboard } from '../../../../Utils/common';
import useMultiAssemblySlug from '../../../../hooks/useMultiAssemblySlug';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { supportedPdfPreviewFileTypes } from '../../../../constants/files';

let channelAssembly: Channel;

const useKnowledgeCenterController = () => {
  const { filter } = useGetParsedFilterOptions();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<
    FeedAttachmentItemFromAPI | undefined
  >(undefined);
  const [sortedValue, setSortedValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [hasCompletedInitialLoad, setHasCompletedInitialLoad] = useState(false);

  const { data: activeFlowsData } = useFetchFlowActive();
  const activeFlows = activeFlowsData?.data;

  const formatFilter = useCallback(
    (filters, searchWords) => formatFileFilters(filters, searchWords),
    [],
  );

  const {
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isInitialLoading: isMainFeedLoading,
  } = useGetMainFeedAttachmentsQuery({
    filter: formatFilter(filter, searchValue),
    sortBy: sortedValue,
  });

  const { data: profileData, isInitialLoading: isProfileDataLoading } =
    useProfileInfoFetchQuery();

  useEffect(() => {
    let channelAssemblyEventHandler: (eventName: string) => void | undefined;
    if (profileData) {
      const assemblyId = profileData?.assembly.assemblyId;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      channelAssemblyEventHandler = (eventName: string) => {
        switch (eventName) {
          case 'POST_DELETED':
          case 'FLOW_RESPONSE_DELETED': {
            refetch();
            break;
          }
          default:
            break;
        }
      };

      channelAssembly.bind_global(channelAssemblyEventHandler);
    }

    return () => {
      if (profileData) {
        channelAssembly.unbind_global(channelAssemblyEventHandler);
      }
    };
  }, [profileData, refetch]);

  useEffect(() => {
    if (!hasCompletedInitialLoad && data) {
      setHasCompletedInitialLoad(true);
    }
  }, [data, hasCompletedInitialLoad]);

  const handleFetchNextPage = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleSetSelectedFile = useCallback(
    (value: FeedAttachmentItemFromAPI | undefined) => {
      trackKnowledgeCenterActionEvent({
        action: KnowledgeCenterEvents.FileViewed,
      });
      return setSelectedFile(value);
    },
    [setSelectedFile],
  );

  const { slugUrl } = useMultiAssemblySlug();
  const copyPostLink = useCallback(
    (flowId, responseId) => {
      // slice removes the ending /
      trackKnowledgeCenterActionEvent({
        action: KnowledgeCenterEvents.FileLinkCopied,
      });
      const url = getFlowPostUrl(flowId, responseId, slugUrl);
      copyToClipboard(url);
    },
    [slugUrl],
  );

  const onDownloadFileClick = useCallback(async (fileDownload) => {
    trackKnowledgeCenterActionEvent({
      action: KnowledgeCenterEvents.FileDownloaded,
    });

    showInfoMessage('Download in progress');

    try {
      await downloadFeedFile({
        blockId: fileDownload?.response.blockId,
        responseId: fileDownload?.response.responseId,
        fileName: fileDownload?.fileInfo.name,
        flowId: fileDownload?.response.flowId,
      });
      dismissAllToasts();
      showSuccessMessage('File downloaded successfully');
    } catch (e) {
      dismissAllToasts();
      showErrorMessage('Error while downloading the file');
    }
  }, []);

  const fileRequestSubstitutions = {
    responseId: selectedFile ? selectedFile.response.responseId : '',
    blockId: selectedFile ? selectedFile.response.blockId : '',
    fileName: selectedFile
      ? selectedFile.fileInfo?.name || selectedFile.urlInfo?.name || ''
      : '',
    flowId: selectedFile ? selectedFile.response.flowId : '',
  };

  const shouldFetchDownloadFileInfo = selectedFile !== undefined;

  const {
    data: fileToDownload,
    error: fileDownloadError,
    refetch: refetchFileDownloadLink,
  } = useFetchFlowFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo,
  );

  const { treatment: convertedPdfPreviewsTreatment } = useFeatureSplit(
    SplitNames.CONVERTED_PDF_PREVIEWS,
  );

  const isConvertedPdfPreviewsEnabled =
    convertedPdfPreviewsTreatment === TreatmentTypes.ON;

  const {
    data: fileToPreview,
    error: filePreviewError,
    isLoading: isFilePreviewLoading,
    refetch: refetchFilePreviewLink,
  } = useFetchPreviewFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo &&
      isConvertedPdfPreviewsEnabled &&
      !!selectedFile?.fileInfo?.type &&
      supportedPdfPreviewFileTypes.includes(selectedFile.fileInfo.type),
  );

  const {
    flowId: selectedFlowId,
    blockId: selectedBlockId,
    fileName: selectedFileName,
    responseId: selectedResponseId,
  } = useParams<{
    flowId: string;
    blockId: string;
    fileName: string;
    responseId: string;
  }>();

  const { data: files } = useGetMainFeedAttachmentsQuery({
    filter: {
      flowIds: [selectedFlowId],
      blockIds: [selectedBlockId],
      keywords: [selectedFileName],
    },
    sortBy: '',
    enabled: Boolean(selectedFlowId && selectedBlockId && selectedFileName),
  });

  useEffect(() => {
    const isFileSelectedFromUrl =
      selectedResponseId &&
      selectedBlockId &&
      selectedFileName &&
      selectedFlowId;

    if (isFileSelectedFromUrl) {
      setModalOpen(true);
      setSelectedFile(files?.pages[0].data[0] || undefined);
    }
  }, [
    files,
    selectedFileName,
    selectedFlowId,
    selectedResponseId,
    selectedBlockId,
  ]);

  const isLoading = isMainFeedLoading || isProfileDataLoading;

  return {
    modalOpen,
    setModalOpen,
    isLoading,
    activeFlows,
    hasNextPage,
    profileData,
    data,
    filter,
    isFetchingNextPage,
    selectedFile,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    fileToDownload,
    fileDownloadError,
    refetchFileDownloadLink,
    isConvertedPdfPreviewsEnabled,
    sortedValue,
    hasCompletedInitialLoad,
    refetch,
    handleFetchNextPage,
    handleSetSelectedFile,
    onDownloadFileClick,
    copyPostLink,
    setSortedValue,
    setSearchValue,
  };
};

export default useKnowledgeCenterController;
