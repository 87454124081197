import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import { AccordionItemHeader } from '../../molecules/FlowAccordionItem/styles';
import { Flex } from '../../../Utils/styles/display';
import Avatar from '../../atoms/Avatar';

export const FlowFeedPostWrapper = styled.div<{
  hideBorder?: boolean;
}>`
  width: 100%;
  background-color: ${ThemeV2.palette.white};
  border-radius: 4px;
  margin: 0 auto 16px;
  border: ${({ hideBorder }) =>
    hideBorder ? 0 : `1px solid ${ThemeV2.palette.gray5}`};
`;

export const FlowRepliesBarWrapper = styled.section`
  cursor: pointer;
  margin: 3px 11px 9px 13px;
  padding: 4px 4px 4px 14px;
  border: 1px solid ${ThemeV2.palette.white};
  border-radius: 4px;

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};
    border: 1px solid ${ThemeV2.palette.geekBlue6};

    span > div {
      border: 1px solid ${ThemeV2.palette.geekBlue1};
    }
  }
`;

export const StyledAccordionItemHeader = styled(AccordionItemHeader)`
  padding: 8px 12px 0 16px;
`;

export const StyledFlex = styled(Flex)`
  width: 150px;
`;

export const SamplePostWrapper = styled.div`
  padding: 8px 16px;
  background-color: ${ThemeV2.palette.gray3};
`;

export const LuminousAvatar = styled(Avatar)`
  mix-blend-mode: luminosity;
`;
