import styled from 'styled-components';
import { UserItem } from './UserItem';
import { UserItemLoader } from './UserItemLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Flex } from '../../../../../Utils/styles/display';
import SVGIcon from '../../../../../atomic/atoms/SVGIcon';
import Theme from '../../../../../theme';
import Body from '../../../../../atomic/atoms/Body';
import BasicModalTemplate from '../../../../../atomic/templates/BasicModalTemplate';
import useRequestedUsersForAccessStore, {
  ShareSheetCurrentModalScreen,
} from '../../../../../stores/sharesheetStore';
import useFlowBuilderStore from '../../../../../stores/flowBuilderStore';
import { useCallback, useState } from 'react';
import { ShareSheetMemberActions } from '../../ShareSheetModalController/data';
import IconButton from '../../../../../atomic/atoms/IconButton_V2';
import useLayoutStore from '../../../../../stores/layoutStore';

const StyledCollapsedContentWrapper = styled.div`
  padding: 12px 0 0;
`;

const InfiniteScrollWrapper = styled.div<{ isEmbeddedInMainApp: boolean }>`
  max-height: ${({ isEmbeddedInMainApp }) =>
    isEmbeddedInMainApp ? '93px' : '135px'};
  overflow: auto;
  width: 100%;
`;

export const RequestAccessCollapseContent = ({
  isError,
  hasNextPage,
  fetchNextPage,
}: {
  isError: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
}) => {
  const {
    requestedUsers,
    setRequestedUsers,
    setUpdatedRequestedUserID,
    setCanUpdateFlowPermission,
    currentShareSheetModalScreen,
    isModifyAndConditionModalOpen,
    setModifyAndConditionModalOpen,
  } = useRequestedUsersForAccessStore();

  const { isEmbeddedInMainApp } = useLayoutStore();
  const { blockData } = useFlowBuilderStore();
  const { PARTICIPANTS, VISIBILITY } = blockData;

  const [currentMemberId, setCurrentMemberId] = useState<string>('');
  const [criteriaName, setCriteriaName] = useState<string>('');

  const participantHasAndCondition =
    PARTICIPANTS.participantsCriteria?.groupsCondition === 'and' &&
    PARTICIPANTS?.participantsCriteria?.groups.length > 1;

  const viewerHasAndCondition =
    VISIBILITY?.criteriaGroups?.groupsCondition === 'and' &&
    VISIBILITY?.criteriaGroups?.groups.length > 1;

  const updateRequestStatus = useCallback(
    (requestStatus: 'PENDING' | 'APPROVED' | 'REJECTED', memberID: string) => {
      const updatedUsers = requestedUsers.map((user) => {
        if (user.memberID === memberID) {
          return {
            ...user,
            requestStatus,
          };
        }
        return user;
      });
      setUpdatedRequestedUserID(memberID);
      setRequestedUsers(updatedUsers);
    },
    [requestedUsers, setRequestedUsers, setUpdatedRequestedUserID],
  );

  const updatePermission = useCallback(
    (updatedPermission: ShareSheetMemberActions, memberID: string) => {
      const updatedUsers = requestedUsers.map((user) => {
        if (user.memberID === memberID) {
          return {
            ...user,
            permission: updatedPermission,
          };
        }
        return user;
      });
      setRequestedUsers(updatedUsers);
    },
    [requestedUsers, setRequestedUsers],
  );

  const handleApproveClick = (memberID: string) => {
    const userPermission = requestedUsers.find(
      (user) => user.memberID === memberID,
    )?.permission;

    const showPromptForParticipation =
      userPermission === ShareSheetMemberActions.canOnlyPost &&
      participantHasAndCondition;
    const showPromptForViewer =
      userPermission === ShareSheetMemberActions.canOnlyViewPosts &&
      viewerHasAndCondition;
    const showPromptForParticipantAndViewer =
      userPermission === ShareSheetMemberActions.canPostAndViewPosts &&
      (participantHasAndCondition || viewerHasAndCondition);

    if (showPromptForParticipantAndViewer) {
      setCriteriaName('participant and viewer');
    } else if (showPromptForParticipation) {
      setCriteriaName('participant');
    } else {
      setCriteriaName('viewer');
    }

    if (
      currentShareSheetModalScreen ===
      ShareSheetCurrentModalScreen.Advanced_Rule
    ) {
      if (
        showPromptForParticipation ||
        showPromptForViewer ||
        showPromptForParticipantAndViewer
      ) {
        setModifyAndConditionModalOpen(true);
        setCurrentMemberId(memberID);
      } else {
        updateRequestStatus('APPROVED', memberID);
        setCanUpdateFlowPermission(true);
      }
    } else {
      updateRequestStatus('APPROVED', memberID);
      setCanUpdateFlowPermission(true);
    }
  };

  const handleDenyClick = (memberID: string) => {
    updateRequestStatus('REJECTED', memberID);
  };

  return (
    <>
      <StyledCollapsedContentWrapper>
        {isError ? (
          <Flex margin="0 0 8px 0">
            <SVGIcon
              icon="warning-hollow"
              color={Theme.palette.dustRed6}
              size="16px"
            />
            <Body variant="body2" color="dustRed6" margin="0 0 0 12px">
              Failed to load requests, try refreshing the page.
            </Body>
          </Flex>
        ) : (
          requestedUsers.length > 0 && (
            <InfiniteScrollWrapper
              id="requestPeople"
              isEmbeddedInMainApp={isEmbeddedInMainApp}
            >
              <InfiniteScroll
                dataLength={requestedUsers.length}
                hasMore={Boolean(hasNextPage)}
                loader={<UserItemLoader />}
                next={fetchNextPage}
                scrollableTarget="requestPeople"
              >
                {requestedUsers.map(
                  (user) =>
                    user.requestStatus === 'PENDING' && (
                      <UserItem
                        name={user.name}
                        permission={user.permission}
                        memberID={user.memberID}
                        image={user.image}
                        requestStatus={user.requestStatus}
                        key={user.memberID}
                        memberState={user.memberState}
                        email={user.email}
                        handleApproveClick={() =>
                          handleApproveClick(user.memberID)
                        }
                        handleDenyClick={() => handleDenyClick(user.memberID)}
                        onChange={(updatedPermission) =>
                          updatePermission(updatedPermission, user.memberID)
                        }
                      />
                    ),
                )}
              </InfiniteScroll>
            </InfiniteScrollWrapper>
          )
        )}
      </StyledCollapsedContentWrapper>

      <BasicModalTemplate
        isOpen={isModifyAndConditionModalOpen}
        onModalClose={() => {
          setModifyAndConditionModalOpen(false);
        }}
        primaryButton={{
          text: 'Modify rules',
          onClick: () => {
            setCanUpdateFlowPermission(true);
            setModifyAndConditionModalOpen(false);
            updateRequestStatus('APPROVED', currentMemberId);
          },
        }}
        secondaryButton={{
          text: 'Don’t accept request or modify',
          onClick: () => {
            setCanUpdateFlowPermission(false);
            setModifyAndConditionModalOpen(false);
          },
        }}
        childrenPadding="12px 24px"
        hasTitleSection={false}
        hideCloseIcon
      >
        <Flex margin="0 0 4px 0" justifyContent="space-between">
          <Body variant="body1Medium">
            Modifying advanced rules to accept requests
          </Body>
          <IconButton
            onClick={() => {
              setModifyAndConditionModalOpen(false);
            }}
          >
            <SVGIcon icon="round-close" size="16px" />
          </IconButton>
        </Flex>
        <Body variant="body2">
          Right now, you have a set of rules linked together by “AND” (‘
          {criteriaName} also matches’) clauses. In order to add in requested
          members as 1-offs into your list, we have to switch the “OR” (‘Or{' '}
          {criteriaName} matches’) clause.
          <br />
          <br />
          This will likely change the list of people who can post and/or who can
          view posts. If you do not modify the rules, requested teammates will
          remain in the requested state.
        </Body>
      </BasicModalTemplate>
    </>
  );
};
