import styled from 'styled-components';

import Body from '../../../atomic/atoms/Body';
import Heading from '../../../atomic/atoms/Heading';
import Image from '../../../atomic/atoms/Image';
import NewTextfield from '../../../atomic/molecules/NewTextfield';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const Content = styled.main`
  grid-area: content;
  max-height: 100%;
  height: 100vh;
  overflow-y: hidden;
`;

export const AssemblyImage = styled(Image)`
  margin: 20px;
  margin-bottom: 32px;
  max-width: 220px;
`;

export const Wrapper = styled.main`
  background-color: #ffffff;
`;

export const Footer = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  text-align: center;
  position: fixed;
  background-color: ${ThemeV2.palette.white};
`;

export const Header = styled.div`
  position: fixed;
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${ThemeV2.palette.gray1};
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  z-index: ${zIndexPopup};
`;

export const LogoContainer = styled(Flex)`
  overflow: hidden;
  border-radius: 4px;
  background-color: ${ThemeV2.palette.gray1};
`;

export const StyledLogo = styled.img`
  max-width: 100%;
  background-color: ${ThemeV2.palette.gray1};
`;

export const CustomHeader = styled.h6`
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  line-height: 28px;
  color: #262626;
  margin-bottom: 0px;
  margin-top: 6px;
  margin-left: 12px;
  max-width: calc(100vw - 75px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledTextInput = styled(NewTextfield)`
  margin-right: 12px;
  max-width: 338px;
  .MuiInputBase-root input {
    height: 24px;
  }
`;

export const StyledFlex = styled(Flex)`
  width: 660px;
  max-width: 100%;
  padding: 0 16px;
`;

export const StyledHeading = styled(Heading)`
  font-weight: 500;
  margin-bottom: 8px;
`;

export const StyledBody = styled(Body)`
  font-size: 20px;
`;
