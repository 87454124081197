import { useMediaQuery } from 'react-responsive';
import { Modal, Popover } from '@mui/material';

import { AutocompleteView } from './AutocompleteView';
import { device } from '../../../constants/layout';
import { useStyles } from './styles';
import { TaskOptionsContext } from '../taskOptionsContext';
import ThemeV2 from '../../../theme';
import { useContext } from 'react';

export const TasksAutocompleteController = () => {
  const {
    anchorElForTasksDropdown,
    handleCloseTasksDropdown,
    handleCreateTaskLinkClick,
    handleTaskOptionSelect,
    isTasksDropdownOpen,
  } = useContext(TaskOptionsContext);

  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });

  const classes = useStyles();

  if (isDesktopView) {
    return (
      <Popover
        PaperProps={{
          style: {
            height: '332px',
            width: '438px',
          },
        }}
        anchorEl={anchorElForTasksDropdown.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        className={classes.root}
        onClose={handleCloseTasksDropdown}
        open={isTasksDropdownOpen}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
      >
        <AutocompleteView
          onClose={handleCloseTasksDropdown}
          onCreateTaskClick={handleCreateTaskLinkClick}
          onOptionSelect={handleTaskOptionSelect}
        />
      </Popover>
    );
  }
  return (
    <Modal
      BackdropProps={{ style: { background: ThemeV2.palette.white } }}
      open={isTasksDropdownOpen}
      style={{ overflow: 'scroll' }}
    >
      <AutocompleteView
        onClose={handleCloseTasksDropdown}
        onCreateTaskClick={handleCreateTaskLinkClick}
        onOptionSelect={handleTaskOptionSelect}
      />
    </Modal>
  );
};
