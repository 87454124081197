import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

import { FlowVariants } from '../../../interfaces/Flow';
import FlowsParticipationFooter from '../../molecules/FlowsParticipationFooter';
import FullScreenParticipationWrapper from '../../molecules/FlowsParticipationModal';
import Body from '../../atoms/Body';
import searching from '../../../img/searching-girl.svg';
import publicGroup from '../../../img/public-group.svg';
import onlineConversation from '../../../img/online-conversation.svg';
import FlowStepper from '../../molecules/FlowStepper';
import giveRecognition from '../../../img/give-recognition.svg';
import SimplePromptModal from '../../molecules/SimplePromptModal';
import { ThreeButtonModal } from '../../molecules/ThreeButtonModal';
import {
  LEAVE_DRAFT_MODAL_LANGUAGES,
  UPDATE_DRAFT_MODAL_LANGUAGES,
  CLEAR_DRAFT_MODAL_LANGUAGES,
  LEAVE_EDIT_MODAL_LANGUAGES,
  LEAVE_CREATE_MODAL_LANGUAGES,
} from '../../../languages/en/giveRecognitionForm';
import useToggle from '../../../hooks/useToggle';
import { ComponentStatus } from '../../../interfaces/component';
import {
  Banner,
  ClickableTextButton,
  Container,
  Content,
  ErrorCTAButton,
  ErrorImage,
  FullScreen,
  PreviewInfoContainer,
  SingleSlideContainer,
  Slide,
  SlideContainer,
  StyledBannerIcon,
  StyledFlexDiv,
  StyledSVG,
  StyledTextInput,
  TemplateLoader,
  variants,
} from './styles';
import ParticipationFlowHeader from '../../molecules/FlowsParticipationHeader';
import { Flex } from '../../../Utils/styles/display';
import Heading from '../../atoms/Heading';
import { Helmet } from 'react-helmet-async';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import useModalsStore from '../../../stores/modalsStore';
import {
  ALREADY_ANSWERED_OCCURRENCE,
  ANSWER_ANYTIME_TEXT,
  ANSWER_NEW_OCCURRENCE,
  ANSWER_NOW,
  ANSWER_WITH_SHORTCUT,
  ANSWER_WITH_SHORTCUT_TEXT,
  CANT_FIND_FLOW,
  EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS,
  HEAD_TO_MAIN_FEED,
  NEW_OCCURRENCE_AVAILABLE_DESCRIPTION,
  NEW_VERSION_AVAILABLE,
  NEW_VERSION_AVAILABLE_DESCRIPTION,
  NO_CURRENT_OCCURRENCE,
  OCCURRENCE_HAS_ENDED,
  RELOAD_FLOW,
  STILL_ANSWER_WITH_SHORTCUT,
  WAIT_TILL_NEXT_OCCURRENCE,
  YOU_ARE_PREVIEWING,
} from '../../../languages/en/flows/participation';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import EmoticonWithText from '../../atoms/EmoticonWithText';
import ThemeV2 from '../../../theme';
import { TEMPLATE, THE } from '../../../languages/en/singleWords';
import Button from '../../atoms/Button';
import {
  CommonParticipationTemplateProps,
  ExternalParticipationTemplateErrorProps,
  ExternalParticipationTemplateErrorTypes,
  ParticipationTemplateErrorProps,
  ParticipationTemplateErrorTypes,
  ParticipationTemplateProps,
} from './types';
import { isValidEmail } from '../../../Utils/validation';
import { AxiosResponse } from 'axios';
import { ValidateEmailRequestForOnboardingSuccessResponse } from '../../../queries/Onboarding/interfaces';
import { EMPLOYEE_VERIFY_LINK, SIGN_IN } from '../../../constants/routes';
import { useHistory } from 'react-router-dom';
import { useValidateEmailRequestForOnboarding } from '../../../queries/Onboarding';
import { getAnswerFlowRoute } from '../../../Utils/flows';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../interfaces/PostMessage';
import mobileStore from '../../../stores/mobileAppStore';

type WindowMessageEvent = { type: 'triggerSaveDraft' };

export const LoadedParticipationTemplate = (
  props: ParticipationTemplateProps,
) => {
  const shouldDisplayHelpButton = true;
  const {
    footerButtons,
    children,
    currentStep,
    goToNextStep,
    goToPreviousStep,
    isNextButtonDisabled,
    showCloseModalOption,
    isPreviousButtonDisabled,
    member,
    onCloseModal,
    onStepChange,
    dynamicBlockData,
    singleSlideContainerRef,
    flowHeaderContent,
    headerStatus,
    onNeedHelpButtonClick,
    onPromptOpen = () => {},
    onPromptClose = () => {},
    isDraftMode,
    isEditMode,
    handleDeleteDraft,
    flowVariant,
    previewContent,
    bannerProps,
    isFullScreen = false,
    isAnonymousPost = false,
    creator,
  }: ParticipationTemplateProps = props;

  const [closeDrawerOnConfirmation, setCloseDrawerOnConfirmation] =
    useState(false);

  const {
    models: { toggleValue: showClosePrompt },
    operations: {
      setToggleToFalse: setShowPromptToFalse,
      setToggleToTrue: setShowPromptToTrue,
    },
  } = useToggle();

  const {
    models: { toggleValue: showClearPrompt },
    operations: {
      setToggleToFalse: setClearPromptToFalse,
      setToggleToTrue: setClearPromptToTrue,
    },
  } = useToggle();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const postModalMessageToMobileApp = (actionType: string) => {
    postMessageToMobileApp({
      action: AppAction.ParticipationCloseModalStatus,
      payload: {
        type: actionType,
      },
    });
  };

  const showPrompt = useCallback(() => {
    setShowPromptToTrue();
    postModalMessageToMobileApp('open');
    if (onPromptOpen) {
      onPromptOpen();
    }
  }, [onPromptOpen, setShowPromptToTrue]);

  const hidePrompt = () => {
    setShowPromptToFalse();
    postModalMessageToMobileApp('closed');
    if (onPromptClose) {
      onPromptClose();
    }
  };

  useEffect(() => {
    const messageHandler = (
      message: MessageEvent<string | WindowMessageEvent>,
    ) => {
      if (typeof message.data === 'string') {
        if (message.data === 'triggerSaveDraft') {
          showPrompt();
        }
        return;
      }
      if (message.data.type === 'triggerSaveDraft') {
        setCloseDrawerOnConfirmation(true);
        showPrompt();
      }
    };
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, [showPrompt]);

  const isPreviewFlow = flowVariant === FlowVariants.PREVIEW_FLOW;
  const isExternalFlow = flowVariant === FlowVariants.EXTERNAL_FLOW;
  const closeModalText = isDraftMode
    ? UPDATE_DRAFT_MODAL_LANGUAGES
    : LEAVE_DRAFT_MODAL_LANGUAGES;

  const hideBannerForExternalFlow = isExternalFlow && currentStep === 0;

  const renderParticipationTemplateContent = () => {
    return (
      <>
        {isEditMode ? (
          <SimplePromptModal
            isOpen={showClosePrompt}
            onModalClose={hidePrompt}
            onSuccess={() => {
              postModalMessageToMobileApp('closed');
              onCloseModal?.();
            }}
            {...LEAVE_EDIT_MODAL_LANGUAGES}
          />
        ) : mobileStore.getState().isMobileAppV3 ? (
          <SimplePromptModal
            isOpen={showClosePrompt}
            onModalClose={hidePrompt}
            onSuccess={() => {
              if (closeDrawerOnConfirmation) {
                postMessageToMobileApp({
                  action: AppAction.DraftActionPerformed,
                  targetEvent: AppTargetEvent.Mobile,
                  payload: {
                    type: 'closeDraftModalAction',
                  },
                });
              } else {
                onCloseModal?.({
                  saveRequested: false,
                  isNewDraft: isDraftMode,
                });
              }
            }}
            {...LEAVE_CREATE_MODAL_LANGUAGES}
          />
        ) : (
          <ThreeButtonModal
            isOpen={showClosePrompt}
            onModalClose={hidePrompt}
            onSecondary={() => {
              postMessageToMobileApp({
                action: AppAction.DraftActionPerformed,
                targetEvent: AppTargetEvent.Mobile,
                payload: {
                  type: 'draftModalAction',
                },
              });
              postModalMessageToMobileApp('closed');
              onCloseModal?.({
                saveRequested: false,
                isNewDraft: isDraftMode,
              });
            }}
            onPrimary={() => {
              postMessageToMobileApp({
                action: AppAction.DraftActionPerformed,
                targetEvent: AppTargetEvent.Mobile,
                payload: {
                  type: 'draftModalAction',
                },
              });
              postModalMessageToMobileApp('closed');
              onCloseModal?.({
                saveRequested: true,
                isNewDraft: isDraftMode,
              });
            }}
            {...closeModalText}
          />
        )}
        <SimplePromptModal
          isOpen={showClearPrompt}
          onModalClose={setClearPromptToFalse}
          onSuccess={handleDeleteDraft}
          primaryButtonStatus="warning"
          {...CLEAR_DRAFT_MODAL_LANGUAGES}
        />
        <Container>
          <ParticipationFlowHeader
            member={member}
            onCloseModal={isPreviewFlow ? onCloseModal : showPrompt}
            flowHeaderContent={flowHeaderContent}
            status={headerStatus}
            isAnonymous={isAnonymousPost || (isPreviewFlow && isFullScreen)}
            showCloseModalOption={showCloseModalOption}
          />
          <FlowStepper
            currentStep={currentStep}
            onStepChange={onStepChange}
            stepStates={dynamicBlockData}
            status={ComponentStatus.LOADED}
            isPreviewFlow={isPreviewFlow}
            isExternalFlow={isExternalFlow}
          />
          <Content>
            {hideBannerForExternalFlow
              ? null
              : bannerProps &&
                bannerProps.map((banner) => (
                  <Banner
                    key={banner.bannerText}
                    padding={isMobileView ? '16px 32px' : '16px 15%'}
                  >
                    <StyledBannerIcon
                      icon={banner.bannerIcon}
                      color={banner.iconColor}
                      size={isMobileView ? '16px' : '24px'}
                    />
                    <Body
                      variant={isMobileView ? 'body3Medium' : 'body1Medium'}
                      style={{ display: 'flex' }}
                    >
                      {!isMobileView && banner.bannerText}
                      {banner.clickableText && (
                        <ClickableTextButton onClick={setClearPromptToTrue}>
                          {banner.clickableText}
                        </ClickableTextButton>
                      )}
                    </Body>
                  </Banner>
                ))}
            <SlideContainer>
              <SingleSlideContainer ref={singleSlideContainerRef}>
                <AnimatePresence initial={false}>
                  <Slide
                    key={currentStep}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                  >
                    {isPreviewFlow && previewContent && (
                      <PreviewInfoContainer>
                        <Helmet>
                          <title>{previewContent.title} Template</title>
                        </Helmet>
                        <StyledFlexDiv isExternalFlow={isExternalFlow}>
                          <StyledSVG
                            icon="eye"
                            size={isMobileView ? '12px' : '20px'}
                            color={ThemeV2.palette.geekBlue6}
                          />
                          <Body
                            variant={
                              isMobileView ? 'body3Medium' : 'body1Medium'
                            }
                            color="geekBlue6"
                          >
                            {YOU_ARE_PREVIEWING}{' '}
                            {previewContent.isTemplate && `${THE} `}
                            {previewContent.icon ? (
                              <>
                                <EmoticonWithText
                                  emoticon={mapHexCodeToEmoticon(
                                    previewContent.icon,
                                  )}
                                  text={previewContent.title}
                                />{' '}
                              </>
                            ) : (
                              previewContent.title
                            )}
                            {previewContent.isTemplate && TEMPLATE}
                          </Body>
                        </StyledFlexDiv>
                      </PreviewInfoContainer>
                    )}
                    {children}
                  </Slide>
                </AnimatePresence>
              </SingleSlideContainer>
            </SlideContainer>
          </Content>
          <FlowsParticipationFooter
            status={ComponentStatus.LOADED}
            isNextButtonDisabled={isNextButtonDisabled}
            isPreviousButtonDisabled={isPreviousButtonDisabled}
            onPreviousButtonClick={goToPreviousStep}
            onNextButtonClick={goToNextStep}
            shouldDisplayHelpButton={
              isExternalFlow || isFullScreen ? false : shouldDisplayHelpButton
            }
            onNeedHelpButtonClick={onNeedHelpButtonClick}
            footerButtons={footerButtons}
            creator={creator}
            isExternalFlow={isExternalFlow}
          />
        </Container>
      </>
    );
  };

  if (isFullScreen) {
    return <FullScreen>{renderParticipationTemplateContent()}</FullScreen>;
  }

  return (
    <FullScreenParticipationWrapper
      handleClose={isPreviewFlow ? onCloseModal : showPrompt}
      isOpen
    >
      {renderParticipationTemplateContent()}
    </FullScreenParticipationWrapper>
  );
};

export const LoadingParticipationTemplate = ({
  member,
  onCloseModal,
  flowHeaderContent,
  headerStatus,
  isFullScreen,
  showCloseOption = true,
}: CommonParticipationTemplateProps) => (
  <FullScreenParticipationWrapper
    isOpen
    handleClose={onCloseModal}
    isFullScreen={isFullScreen}
  >
    <Container>
      <ParticipationFlowHeader
        member={member}
        onCloseModal={onCloseModal}
        flowHeaderContent={flowHeaderContent}
        status={headerStatus}
        showCloseModalOption={showCloseOption}
      />
      <FlowStepper status={ComponentStatus.LOADING} />
      <Content>
        <SlideContainer>
          <TemplateLoader />
        </SlideContainer>
      </Content>
      <FlowsParticipationFooter status={ComponentStatus.LOADING} />
    </Container>
  </FullScreenParticipationWrapper>
);

export const ParticipationTemplateError = ({
  member,
  onCloseModal,
  flowId,
  templateError,
  onAnswerWithShortcutButtonClick,
  flowHeaderContent,
  headerStatus,
  setCustomError,
  returnOnlyContent = false,
  isFullScreen,
  hideFooter = false,
}: ParticipationTemplateErrorProps) => {
  useEffect(() => {
    if (templateError) {
      postMessageToMobileApp({
        action: AppAction.ParticipationError,
        payload: {
          errorType: templateError,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { setParticipationFlow } = useModalsStore();
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    flowId,
    '',
    templateError !== 'INVALID_FLOW_ID' && templateError !== 'FLOW_NOT_FOUND',
  );
  const [headingText, descriptionText, ErrorCTAElement, errorImageSrc] =
    useMemo(() => {
      let occurrenceId: string | undefined;
      let isShortcut = false;
      if (flowDetails) {
        occurrenceId =
          flowDetails.data.occurrence.activeOccurrence?.occurrenceId;
        isShortcut = flowDetails.data.isShortcut;
      }

      switch (templateError) {
        case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ID_MISMATCH: {
          const onClick = () => {
            setParticipationFlow({ occurrenceId, participationFlowId: flowId });
            window.location.href = getAnswerFlowRoute(flowId, occurrenceId, '');
          };
          return [
            OCCURRENCE_HAS_ENDED,
            NEW_OCCURRENCE_AVAILABLE_DESCRIPTION,
            flowDetails ? (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_NEW_OCCURRENCE}
              </ErrorCTAButton>
            ) : null,
            publicGroup,
          ];
        }
        case ParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = WAIT_TILL_NEXT_OCCURRENCE;
          if (isShortcut) {
            const onClick = () => {
              window.location.href = getAnswerFlowRoute(flowId, undefined, '');
            };
            imageSrc = onlineConversation;
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_WITH_SHORTCUT}
              </ErrorCTAButton>
            );
            text = ANSWER_WITH_SHORTCUT_TEXT;
          }
          return [OCCURRENCE_HAS_ENDED, text, ButtonElement, imageSrc];
        }
        case ParticipationTemplateErrorTypes.OCCURRENCE_ALREADY_RESPONDED: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = NO_CURRENT_OCCURRENCE;
          if (isShortcut) {
            imageSrc = onlineConversation;
            const onClick = () => {
              if (onAnswerWithShortcutButtonClick) {
                onAnswerWithShortcutButtonClick();
              }
            };

            // setParticipationFlow({ participationFlowId: flowId });
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_WITH_SHORTCUT}
              </ErrorCTAButton>
            );
            text = STILL_ANSWER_WITH_SHORTCUT;
          }
          return [ALREADY_ANSWERED_OCCURRENCE, text, ButtonElement, imageSrc];
        }

        case ParticipationTemplateErrorTypes.FLOW_UPDATED: {
          const imageSrc = giveRecognition;
          const onClick = () => {};

          const ButtonElement = (
            <ErrorCTAButton key="error" onClick={onClick}>
              {RELOAD_FLOW}
            </ErrorCTAButton>
          );
          return [
            NEW_VERSION_AVAILABLE,
            NEW_VERSION_AVAILABLE_DESCRIPTION,
            ButtonElement,
            imageSrc,
          ];
        }

        case ParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND: {
          let imageSrc = publicGroup;
          let ButtonElement: JSX.Element | null = null;
          let text = WAIT_TILL_NEXT_OCCURRENCE;
          if (isShortcut) {
            const onClick = () => {
              if (setCustomError) {
                setCustomError(undefined);
              }
              setParticipationFlow({ participationFlowId: flowId });
            };

            imageSrc = onlineConversation;
            ButtonElement = (
              <ErrorCTAButton key="error" onClick={onClick}>
                {ANSWER_NOW}
              </ErrorCTAButton>
            );
            text = ANSWER_ANYTIME_TEXT;
          }
          return [OCCURRENCE_HAS_ENDED, text, ButtonElement, imageSrc];
        }

        case ParticipationTemplateErrorTypes.INVALID_FLOW_ID:
        case ParticipationTemplateErrorTypes.FLOW_NOT_FOUND:
        case ParticipationTemplateErrorTypes.FLOW_NOT_AVAILABLE:
        case ParticipationTemplateErrorTypes.INVALID_OCCURRENCE_ID:
        case ParticipationTemplateErrorTypes.UNABLE_TO_BUILD_INSTANCE_FROM_BLOCKS:
        default: {
          return [CANT_FIND_FLOW, HEAD_TO_MAIN_FEED, null, searching];
        }
      }
    }, [
      flowDetails,
      flowId,
      onAnswerWithShortcutButtonClick,
      setCustomError,
      setParticipationFlow,
      templateError,
    ]);

  const content = useMemo(() => {
    return (
      <Content>
        <SlideContainer>
          <Flex
            alignItems="center"
            justifyContent="center"
            height="100%"
            flexDirection="column"
          >
            <ErrorImage alt="error-image" src={errorImageSrc} />
            <Flex flexDirection="column" width="380px">
              <Heading variant="h6">{headingText}</Heading>
              <Body variant="body1" textAlign="center">
                {descriptionText}
              </Body>
              {ErrorCTAElement}
            </Flex>
          </Flex>
        </SlideContainer>
      </Content>
    );
  }, [ErrorCTAElement, descriptionText, errorImageSrc, headingText]);
  if (returnOnlyContent) return content;

  return (
    <FullScreenParticipationWrapper
      isOpen
      handleClose={onCloseModal}
      isFullScreen={isFullScreen}
    >
      <Container>
        <ParticipationFlowHeader
          flowHeaderContent={flowHeaderContent}
          status={headerStatus}
          member={member}
          onCloseModal={onCloseModal}
        />
        <FlowStepper status={ComponentStatus.LOADING} />
        {content}
        {!hideFooter && (
          <FlowsParticipationFooter
            status={ComponentStatus.ERROR}
            onClick={onCloseModal}
          />
        )}
      </Container>
    </FullScreenParticipationWrapper>
  );
};

export const ExternalParticipationTemplateError = ({
  externalTemplateError,
}: ExternalParticipationTemplateErrorProps) => {
  const { push } = useHistory();
  const { mutate } = useValidateEmailRequestForOnboarding();
  const [email, setEmail] = useState('');
  const [hasError, setHasError] = useState(false);
  const handleExploreForFreeClick = useCallback(() => {
    if (email) {
      setHasError(!isValidEmail(email));

      if (!isValidEmail(email)) {
        return;
      }

      const payload = { email: email };

      mutate(payload, {
        onSuccess: (
          res: AxiosResponse<ValidateEmailRequestForOnboardingSuccessResponse>,
        ) => {
          push(`${EMPLOYEE_VERIFY_LINK}/${res.data.data.userId}`);
        },
        onError: () => {
          push(SIGN_IN);
        },
      });
    }
  }, [email]);
  const [headingText, descriptionText, ErrorCTAElement, errorImageSrc] =
    useMemo(() => {
      if (
        externalTemplateError ===
        ExternalParticipationTemplateErrorTypes.FLOW_UPDATED
      ) {
        const ButtonElement = (
          <ErrorCTAButton key="error" onClick={() => {}} icon="retry">
            {RELOAD_FLOW}
          </ErrorCTAButton>
        );
        return [
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.NEW_VERSION_AVAILABLE_HEADING,
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.NEW_VERSION_AVAILABLE_SUB_HEADING,
          ButtonElement,
          giveRecognition,
        ];
      }

      const TextInputAndButtonElement = (
        <Flex
          margin="20px 0 0 0"
          width="100%"
          alignItems="baseline"
          justifyContent="center"
          height="72px"
        >
          <StyledTextInput
            value={email}
            onChange={(e) => {
              setHasError(false);
              setEmail(e.target.value);
            }}
            placeholder={
              EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
            }
            hasError={hasError}
            helperText={
              hasError
                ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ERROR_TEXT
                : ''
            }
            label={
              hasError
                ? EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.PLACEHOLDER_TEXT
                : ''
            }
          />
          <Button key="error" onClick={handleExploreForFreeClick}>
            {EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.EXPLORE_FOR_FREE}
          </Button>
        </Flex>
      );
      if (
        externalTemplateError ===
          ExternalParticipationTemplateErrorTypes.ACTIVE_OCCURRENCE_ENDED ||
        externalTemplateError ===
          ExternalParticipationTemplateErrorTypes.NO_ACTIVE_OCCURRENCE_FOUND
      ) {
        const imageSrc = publicGroup;

        return [
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_HEADING,
          EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.ACTIVE_OCCURRENCE_ENDED_SUB_HEADING,
          TextInputAndButtonElement,
          imageSrc,
        ];
      }

      return [
        EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.FLOW_NOT_FOUND_HEADING,
        EXTERNAL_PARTICIPATION_ERROR_SCREENS_TEXTS.FLOW_NOT_FOUND_SUB_HEADING,
        TextInputAndButtonElement,
        searching,
      ];
    }, [email, externalTemplateError, handleExploreForFreeClick, hasError]);
  return (
    <Content>
      <SlideContainer>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100%"
          flexDirection="column"
        >
          <ErrorImage alt="error-image" src={errorImageSrc} />
          <Flex flexDirection="column" width="380px" justifyContent="center">
            <Heading variant="h6" textAlign="center">
              {headingText}
            </Heading>
            <Body variant="body1" textAlign="center">
              {descriptionText}
            </Body>
            {ErrorCTAElement}
          </Flex>
        </Flex>
      </SlideContainer>
    </Content>
  );
};
