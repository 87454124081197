import styled, { css } from 'styled-components';

import ThemeV2 from '../../../theme';

import {
  device,
  MainHeaderHeight,
  MainHeaderWithTabsHeight,
  MobileWebMainHeaderHeight,
  MobileWebMainHeaderWithTabsHeight,
  MobileAppMainHeaderHeight,
  MobileAppMainHeaderWithTabsHeight,
  EmbeddedHeaderWithTabsHeight,
  EmbeddedMainHeaderHeight,
} from '../../../constants/layout';

import { LayoutStyleProps } from '../../../interfaces/Layout';
import { windowSizeAdapter } from '../../../Utils/window';
import IconButton from '../../atoms/IconButton_V2';

export const Main = styled.main<{
  isFullViewportHeight: boolean;
  background?: string;
}>`
  overflow: hidden; // Do not remove this overflow settings
  margin-left: 0;
  height: ${(props) =>
    props.isFullViewportHeight ? '100vh' : 'calc(100vh - 72px)'};
  background-color: ${({ background }) =>
    `${background || ThemeV2.palette.gray2}`};
  transition-duration: 0.5s;
  transition-property: margin-right, margin-left;
  backface-visibility: hidden;
  width: 100%;
  position: relative;
`;

export const MainContent = styled.div<LayoutStyleProps>`
  @media ${device.mobile} {
    margin-top: ${(props) =>
      props.isMobileApp ? '0' : props.hasFlowBuilderTabs ? '110px' : '81px'};
  }
  margin-top: ${(props) => (props.hasFlowBuilderTabs ? '29px' : '0px')};
  overflow-y: auto;
  ${(props) => props?.hasRightMargin && 'margin-right: 16px'};
  margin-left: ${(props) => props?.leftMargin || '0'};

  height: ${(props) => {
    return windowSizeAdapter(
      props.isMobileApp || props.isMobileAppV3 || false,
      {
        mobileApp: props.hasTabComponent
          ? `calc(var(--mobileVh, 1vh) * 100 - ${MobileAppMainHeaderWithTabsHeight}px)`
          : `calc(var(--mobileVh, 1vh) * 100 - ${MobileAppMainHeaderHeight}px)`,
        mobileWeb: props.hasTabComponent
          ? `calc(var(--mobileVh, 1vh) * 100 - ${MobileWebMainHeaderWithTabsHeight}px)`
          : `calc(var(--mobileVh, 1vh) * 100 - ${MobileWebMainHeaderHeight}px)`,
        tablet: props.hasTabComponent
          ? `calc(100vh - ${MainHeaderWithTabsHeight}px)`
          : `calc(100vh - ${MainHeaderHeight}px)`,
        desktop: props.hasTabComponent
          ? `calc(100vh - ${MainHeaderWithTabsHeight}px)`
          : `calc(100vh - ${MainHeaderHeight}px)`,
        embeddedWeb: props.hasTabComponent
          ? `calc(100vh - ${EmbeddedHeaderWithTabsHeight}px)`
          : `calc(100vh - ${EmbeddedMainHeaderHeight}px)`,
      },
      props.isEmbeddedInMainApp,
      props.isFullScreen,
      props.isMobileAppV3,
    );
  }};
`;

export const MainHeader = styled.div<LayoutStyleProps>`
  ${({ isMobileApp, isEmbeddedInMainApp }) =>
    !isMobileApp &&
    css`
      @media ${device.mobile} {
        position: fixed;
        top: ${isEmbeddedInMainApp ? '0px' : '72px'};
        width: 100%;
      }
    `}
  position: sticky;
  top: 0;
  display: block;
  align-items: center;
  max-height: ${({ hasTabComponent }) => (hasTabComponent ? '110px' : '80px')};
  height: ${({ removeMargin, isMobileApp }) =>
    removeMargin && isMobileApp ? '110px' : 'unset'};
  margin-left: ${({ removeMargin }) => removeMargin && '0'};
  z-index: 3;
  background-color: ${({ background }) =>
    `${background || ThemeV2.palette.gray1}`};
  border-bottom: ${({ hasBorderBottom }) =>
    hasBorderBottom && `1px solid ${ThemeV2.palette.gray4}`};
`;

export const HeaderRouterContainer = styled.div<{
  isEmbeddedInMainApp?: boolean;
}>`
  margin-left: ${({ isEmbeddedInMainApp }) => !isEmbeddedInMainApp && '-12px'};
  width: 100%;
`;

export const StyledBackButton = styled(IconButton)<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || '0'};
  width: 40px;
  height: 40px;
  @media ${device.mobile} {
    width: 24px;
    height: 24px;
  }
`;

export const NotebookSunsetBannerWrapper = styled.div`
  margin: 16px;
`;
