import { useLegacySingleFlowFeedPostController } from './useLegacySingleFlowFeedPostController';

import { FeedsContainer } from '../../../../Utils/styles/display';

import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { FeedContext } from '../../../../Utils/flows/feeds';
import { getWorkspaceTitlePrefix } from '../../../../Utils/richMetaData';
import EmptyErrorStateTemplate from '../../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import useSinglePostSocket, {
  WebSocketSinglePostTypes,
} from '../../../../hooks/useSinglePostSocket';
import searching from '../../../../img/searching.svg';
import { PostTypes } from '../../../../interfaces/Home';
import { FLOW_FEED_STATES } from '../../../../languages/en/flows/feed';
import FlowPostController from '../../../flows/FlowPostController';
import FlowsFeedPostLoader from '../../../flows/FlowsFeedController/Loader';

export const LegacySingleFlowFeedPostController = () => {
  const {
    isError,
    isLoading,
    profileData,
    data,
    feedContextProps,
    handleEditPostClick,
  } = useLegacySingleFlowFeedPostController();

  useSinglePostSocket({
    type: WebSocketSinglePostTypes.LEGACY_POST,
  });

  const renderEmptyState = () => {
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  let pageTitle = '';
  if (data?.type === 'birthday') {
    const name = `${data.to[0].firstName} ${data.to[0].lastName}`.trim();
    pageTitle =
      // eslint-disable-next-line max-len
      `Birthday Post for ${name} in 🎉 Give Recognition on ${moment(
        data.createdAt,
      ).format('MMM Do YYYY')} | ${getWorkspaceTitlePrefix(
        profileData?.assembly.name,
      )}`;
  } else if (data?.type === 'anniversary') {
    const name = `${data.to[0].firstName} ${data.to[0].lastName}`.trim();
    pageTitle =
      // eslint-disable-next-line max-len
      `Anniversary Post for ${name} in 🎉 Give Recognition on ${moment(
        data.createdAt,
      ).format('MMM Do YYYY')} | ${getWorkspaceTitlePrefix(
        profileData?.assembly.name,
      )}`;
  } else {
    const name = `${data?.from?.firstName} ${data?.from?.lastName}`.trim();
    pageTitle =
      // eslint-disable-next-line max-len
      `Post by ${name} in 🎉 Give Recognition on ${moment(
        data?.createdAt,
      ).format('MMM Do YYYY')} | ${getWorkspaceTitlePrefix(
        profileData?.assembly.name,
      )}`;
  }

  return (
    <FeedsContainer>
      {isLoading && <FlowsFeedPostLoader />}
      {data && (
        <FeedContext.Provider value={feedContextProps}>
          <Helmet>
            <title>{pageTitle}</title>
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={data.message} />
          </Helmet>
          <FlowPostController
            post={data}
            profileData={profileData}
            showEditOption={
              data.type === PostTypes.RECOGNITION &&
              data.from?.memberID === profileData?.member.memberId
            }
            handleEditPostClick={handleEditPostClick}
            showPostInteractionSettings={false}
          />
        </FeedContext.Provider>
      )}
      {isError && renderEmptyState()}
    </FeedsContainer>
  );
};
