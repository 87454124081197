import ContentLoader from 'react-content-loader';
import React from 'react';

import { RightWrapper, Wrapper } from './styles';
import ThemeV2 from '../../../theme';

interface LoaderProps {
  className?: string;
}

const MyRankingEntryLoader = ({ className }: LoaderProps) => (
  <Wrapper className={className}>
    <div>
      <ContentLoader
        speed={2}
        width={120}
        height={48}
        viewBox="0 0 120 48"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="my-ranking-entry-left"
      >
        <circle cx="24" cy="24" r="16" />
        <rect x="48" y="14" rx="4" width="68" height="20" />
        <rect x="120" y="14" rx="4" width="25" height="20" />
      </ContentLoader>
    </div>

    <RightWrapper>
      <ContentLoader
        speed={2}
        width={34}
        height={48}
        viewBox="0 0 34 20"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        uniqueKey="my-ranking-entry-right"
      >
        <rect x="0" y="0" rx="4" width="34" height="20" />
      </ContentLoader>
    </RightWrapper>
  </Wrapper>
);

export default MyRankingEntryLoader;
