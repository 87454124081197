import { Flex } from '../../../../Utils/styles/display';
import { AvatarProps } from '../../../atoms/Avatar/interfaces';
import Body from '../../../atoms/Body';
import { LuminousAvatar } from '../styles';

export type FlowPostFooterHiddenStateProps = {
  text: string;
  avatarProps: AvatarProps;
};

const FlowPostFooterHiddenState = (props: FlowPostFooterHiddenStateProps) => {
  return (
    <div>
      <Flex
        width="100%"
        gap="4px"
        alignItems="center"
        margin="8px 16px 16px 16px"
      >
        <LuminousAvatar
          size="32px"
          fontSize="16px"
          iconSize="32px"
          img={props.avatarProps.img}
          name={props.avatarProps.name}
          userId={props.avatarProps.userId}
        />
        <Body variant="body2" color="gray8">
          {props.text}
        </Body>
      </Flex>
    </div>
  );
};

export default FlowPostFooterHiddenState;
