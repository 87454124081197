import React, { memo, useState } from 'react';

import CommentHeader from '../CommentHeader';
import FeedPostBody from '../FeedPostBody';
import ReactionBar from '../ReactionsBar';
import { ToggleButtonVariant } from '../../atoms/ToggleButton/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  dropdownOptions as commentsDropdownOptions,
  dropdownOptionsForChat,
  dropdownOptionsWithEdit,
} from '../CommentHeader/data';

import {
  CommentWrapper,
  StyledCommentBody,
  StyledReactionBarContainer,
} from './styles';
import {
  FeedCommentFromAPI,
  MemberInteractionType,
  Reaction,
} from '../../../interfaces/Feed';
import { AssemblyCurrency } from '../../../interfaces/assembly';
import { getFormattedMessage } from '../../../Utils/message';
import { serializeReactions } from '../../../Utils/serializers/feed';
import { EmojiData } from 'emoji-mart';
import {
  getDropDownOptions,
  getDropDownOptionsForRepliesSidebar,
  isGifURLPresent,
} from '../../../Utils/posts';
import useHover from '../../../hooks/useHover';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import { FeedTypes } from '../../../interfaces/Home';
import { GIVE_TROPHIES_BACK_TO_SENDER } from '../../../languages/en/home';
import CommentActions from '../CommentActions';
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
} from 'html-react-parser';

interface NewCommentProps {
  isDummy?: boolean;
  isLastComment?: boolean;
  comment: FeedCommentFromAPI;
  assemblyCurrency: AssemblyCurrency;
  currentUserId: string;
  isCurrentUserAdmin: boolean;
  onMemberClick: (person: MemberInteractionType) => void;
  onCopyLink: (commentId: string) => void;
  onEditComment?: (commentId: string) => void;
  onDeleteComment: (
    commentId: string,
    commentHasTrophies: boolean,
    deleteCommentCarrotOption: string,
  ) => void;
  onReactionToggleClick: (reaction: Reaction, contentId: string) => void;
  onEmoticonSelect: (
    reactions: Reaction[],
    emoji: EmojiData,
    contentId: string,
  ) => void;
  showChatView?: boolean;
  canDelete: boolean;
  variant?: 'feed' | 'sidebar';
  optionsDropDownPlacement?: DropdownPlacement;
}

const Comment = ({
  comment,
  isDummy = false,
  assemblyCurrency,
  currentUserId,
  isCurrentUserAdmin,
  onMemberClick,
  onDeleteComment,
  onCopyLink,
  onEditComment,
  onReactionToggleClick,
  onEmoticonSelect,
  canDelete,
  variant,
  showChatView = false,
}: NewCommentProps) => {
  const {
    editedAt,
    fromMember,
    pointsEach,
    createdAt,
    gifURL,
    imageURL,
    commentID,
    message,
    taggedUsers,
    messageHtml,
  } = comment;

  const messageText = getFormattedMessage(message, taggedUsers, onMemberClick);
  const reactions = serializeReactions(comment, currentUserId);
  const { search } = useLocation();
  const commentId = new URLSearchParams(search).get('commentId') || '';

  const handleEmoticonSelect = (emoji: EmojiData, contentId: string) =>
    onEmoticonSelect(reactions, emoji, contentId);

  const commentDropdownOptions =
    variant === 'sidebar'
      ? getDropDownOptionsForRepliesSidebar(
          showChatView ? dropdownOptionsForChat : dropdownOptionsWithEdit,
          isCurrentUserAdmin,
          isGifURLPresent(gifURL),
          fromMember.memberID === currentUserId || canDelete,
          fromMember.memberID === currentUserId && comment.version !== 2,
        )
      : getDropDownOptions(
          commentsDropdownOptions,
          isCurrentUserAdmin,
          isGifURLPresent(gifURL),
          fromMember.memberID === currentUserId || canDelete,
        );

  const {
    model: { isHovering },
    operations: {
      handlePopoverClose: onMouseLeave,
      handlePopoverOpen: onMouseEnter,
    },
  } = useHover();
  const [deleteCommentCarrotOption, setDeleteCommentCarrotOption] =
    useState<string>(GIVE_TROPHIES_BACK_TO_SENDER);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const parserOptions: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element) {
        if (domNode.tagName === 'a') {
          const href = domNode.attribs.href;

          if (!href) {
            return;
          }

          const url = new URL(href);
          const isExternal = window.location.hostname !== url.hostname;

          if (!isExternal) {
            return;
          }

          return (
            <a href={href} target="_blank" rel="noopener noreferrer">
              {domToReact(domNode.children, parserOptions)}
            </a>
          );
        }
        if (domNode.tagName === 'img') {
          const src = domNode.attribs.src;
          const alt = domNode.attribs.alt;
          const className = domNode.attribs.class;

          if (!src) {
            return;
          }

          if (
            domNode.parent instanceof Element &&
            domNode.parent.attribs['data-lexical-mention']
          ) {
            return;
          }

          return (
            <img
              alt={alt}
              src={src}
              className={className}
              style={{
                objectFit: 'contain',
                borderRadius: '8px',
              }}
            />
          );
        }
      }
    },
  };

  return (
    <CommentWrapper
      id={commentID}
      data-testid="comment"
      data-qa-id="comment"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      showAnimation={commentID === commentId}
      isLoading={commentID === 'OPTIMISTIC' || false}
    >
      <CommentHeader
        showChatView={showChatView}
        showActionsModal={isHovering && !isMobileView}
        variant={variant}
        commentId={commentID}
        from={fromMember}
        isDummy={isDummy}
        trophies={pointsEach || undefined}
        assemblyCurrency={assemblyCurrency}
        createdAt={createdAt}
        currentUserId={currentUserId}
        avatarSize="40px"
        onClickUserName={onMemberClick}
        dropdownOptions={commentDropdownOptions}
        hasTrophies={pointsEach > 0}
        onCopyLink={onCopyLink}
        onEditLink={onEditComment}
        onDeleteCommentClick={onDeleteComment}
        isLoading={commentID === 'OPTIMISTIC'}
        isEditing={comment.isEditing}
        reactions={reactions}
        onReactionToggleClick={onReactionToggleClick}
        onEmoticonSelect={(emoji: EmojiData, contentId: string) => {
          handleEmoticonSelect(emoji, contentId);
          onMouseLeave();
        }}
      />
      <StyledCommentBody variant={variant}>
        {messageHtml ? (
          <FeedPostBody
            variant={variant}
            messageText={parse(messageHtml, parserOptions)}
          />
        ) : (
          <FeedPostBody
            variant={variant}
            messageText={messageText}
            image={gifURL || imageURL}
            isEdited={!!editedAt}
          />
        )}
        <StyledReactionBarContainer>
          {isMobileView ? (
            <CommentActions
              icon="more"
              canShowReactions
              variant={variant}
              reactions={reactions}
              contentId={commentID}
              title="comment options"
              showChatView={showChatView}
              feedType={FeedTypes.COMMENT}
              hasTrophies={pointsEach > 0}
              currentUserId={currentUserId}
              showReactions={!!reactions.length}
              dropdownOptions={commentDropdownOptions}
              dropdownToggleButtonSize={ButtonSize.Small}
              dropdownPlacement={DropdownPlacement.TopEnd}
              onEmoticonSelect={(emoji: EmojiData, contentId: string) => {
                handleEmoticonSelect(emoji, contentId);
                onMouseLeave();
              }}
              onReactionToggleClick={onReactionToggleClick}
              onCopyLink={() => {
                if (onCopyLink) {
                  onCopyLink(commentID);
                }
              }}
              onEditReplyOptionClick={() => {
                if (onEditComment) {
                  onEditComment(commentID);
                }
              }}
              onDeleteModalSubmitClick={() => {
                if (onDeleteComment) {
                  onDeleteComment(
                    commentID,
                    pointsEach > 0 || false,
                    deleteCommentCarrotOption,
                  );
                }
              }}
              onDeletePostSelectedOption={(value: string) => {
                setDeleteCommentCarrotOption(value);
              }}
              currency={assemblyCurrency}
            />
          ) : (
            <ReactionBar
              contentId={commentID}
              variant={variant}
              currentUserId={currentUserId}
              reactions={reactions}
              onReactionToggleClick={onReactionToggleClick}
              onEmoticonSelect={handleEmoticonSelect}
              toggleButtonVariant={ToggleButtonVariant.Text}
              reactionButtonSize={ButtonSize.Small}
            />
          )}
        </StyledReactionBarContainer>
      </StyledCommentBody>
    </CommentWrapper>
  );
};

const MemoizedComment = memo(Comment);
MemoizedComment.displayName = 'Comment';

export default MemoizedComment;
