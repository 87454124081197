import styled from 'styled-components';
import { Flex } from '../../../../Utils/styles/display';
import ThemeV2 from '../../../../theme';
import Body from '../../../atoms/Body';
import SVGIcon from '../../../atoms/SVGIcon';
import CircularCheckbox from '../../../atoms/Checkbox/CircularCheckbox';

export const DragIconWrapper = styled.div`
  margin: 4px;
  min-width: 14px;
`;

export const EditorWrapper = styled.div`
  flex-grow: 100;
  position: relative;
`;

export const StyledCircularCheckbox = styled(CircularCheckbox)`
  margin: 2px 12px 4px 10px;
`;

export const CreatedByText = styled(Body)`
  margin-right: 4px;
`;

export const EditorFooterText = styled(Flex)`
  margin-top: 7px;
  cursor: default;
`;

export const StyledTiptapEditorWrapper = styled.div`
  .custom-tiptap {
    padding: 0px;
    font-size: 16px;
    line-height: 20px;

    p {
      margin: 0px;
    }

    &:focus-visible {
      outline: none;
    }
  }
`;

export const CustomTriggerButton = styled(Flex)<{
  customTriggerBackgroundColor: string;
  customTriggerHoverColor: string;
}>`
  background: ${({ customTriggerBackgroundColor }) =>
    customTriggerBackgroundColor || ThemeV2.palette.gray3};
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;

  &:hover {
    background: ${({ customTriggerHoverColor }) =>
      customTriggerHoverColor || ThemeV2.palette.gray3};
  }
`;

export const TaskItemDatePickerWrapper = styled.div`
  margin-top: 8px;
`;

export const DueDateLabel = styled(Body)``;

export const CustomTriggerIcon = styled(SVGIcon)`
  margin-right: 4px;
`;

export const TaskItemAssigneeWrapper = styled(Flex)`
  width: fit-content;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
`;
