import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

import {
  ConnectionConfigResponse,
  GetConnectionsListResponse,
  CreateConnectionPoolRequest,
  CreateConnectionPoolResponse,
  SaveConnectionIntegrationRequest,
} from '../../interfaces/Connections';

import {
  GET_CHANNELS_LIST,
  GET_BOT_OAUTH_CONFIG,
  GET_CONNECTIONS_LIST,
  CREATE_CONNECTION_POOL,
  GET_FLOW_DETAILS,
  SAVE_CONNECTION_INTEGRATION,
  DELETE_CONNECTION_INTEGRATION,
} from '../../constants/endpoints';
import { GetFlowDetailsResponse } from '../Flows/interfaces';
import produce from 'immer';

export const useGetChannelsList = (connectionId: string) => {
  return useMutation((refresh: boolean) => {
    return makeAPICallWithDataReturn(
      GET_CHANNELS_LIST,
      {},
      { refresh },
      { connectionId },
    );
  });
};

export const useGetConnectionConfig = (
  redirectUri: string,
  integrationName: string,
) => {
  return useQuery<ConnectionConfigResponse>(
    [GET_BOT_OAUTH_CONFIG, integrationName],
    () =>
      makeAPICallWithDataReturn(
        GET_BOT_OAUTH_CONFIG,
        {},
        { redirectUri },
        { sso: integrationName },
      ),
  );
};

export const useCreateConnectionPool = (
  payload: CreateConnectionPoolRequest,
  enabled: boolean,
) => {
  return useQuery<CreateConnectionPoolResponse>(
    [CREATE_CONNECTION_POOL, payload],
    () => makeAPICallWithDataReturn(CREATE_CONNECTION_POOL, payload),
    {
      enabled,
    },
  );
};

export const useGetConnectionsList = (integrationName: string) => {
  return useQuery<GetConnectionsListResponse>(
    [GET_CONNECTIONS_LIST, integrationName],
    () =>
      makeAPICallWithDataReturn(GET_CONNECTIONS_LIST, {}, { integrationName }),
  );
};

export const useSaveConnectionIntegration = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SaveConnectionIntegrationRequest) =>
      makeAPICall(SAVE_CONNECTION_INTEGRATION, payload, {}, { flowId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          GET_FLOW_DETAILS,
          flowId,
          'builder',
        ]);
      },
    },
  );
};

export const useDeleteConnectionIntegration = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => makeAPICall(DELETE_CONNECTION_INTEGRATION, {}, {}, { flowId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_CONNECTIONS_LIST, 'slack']);
        queryClient.invalidateQueries([GET_FLOW_DETAILS, flowId, 'builder']);
      },
      onMutate: () => {
        const flowDetailsQueryKey = [GET_FLOW_DETAILS, flowId, 'builder'];

        const previousFlowResponse =
          queryClient.getQueryData<GetFlowDetailsResponse>(flowDetailsQueryKey);

        const updatedFlowDetails = produce(previousFlowResponse, (draft) => {
          if (draft) {
            draft.data.integrations = {
              viewingCriteria: [],
              responseNotification: [],
              participationCriteria: [],
              participationNotification: [],
            };
          }
        });

        queryClient.setQueryData(flowDetailsQueryKey, updatedFlowDetails);
      },
    },
  );
};
