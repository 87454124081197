import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TaskContentFromAPI } from '../../../../interfaces/notebook/editor';
import Body, { variantMap } from '../../../atoms/Body';
import ThemeV2 from '../../../../theme';
import SVGIcon from '../../../atoms/SVGIcon';
import qs from 'qs';
import { useHistory } from 'react-router';

interface TaskContentProps {
  content: TaskContentFromAPI[];
  strikeThroughOverride?: boolean;
  disabled?: boolean;
  stopPropagation?: boolean;
  noPadding?: boolean;
  hasHoverEffect: boolean;
  variant?: string;
}

const TaskContentWrapper = styled(Body)<{
  strikeThroughOverride: boolean;
  noPadding: boolean;
  hasHoverEffect: boolean;
}>`
  cursor: default;
  word-break: break-word;
  padding: ${({ noPadding }) => (noPadding ? 'none' : '4px 8px')};
  border: 0.5px solid transparent;

  &:hover {
    border-radius: 4px;
    ${({ hasHoverEffect }) =>
      hasHoverEffect ? `background: ${ThemeV2.palette.gray3}` : ''};
  }

  & > * {
    text-decoration: ${({ strikeThroughOverride }) =>
      strikeThroughOverride ? `line-through` : 'inherit'};
    margin: 0px;
    padding: 0px;
    color: ${({ disabled }) =>
      disabled ? ThemeV2.palette.gray7 : ThemeV2.palette.gray9};
  }
  br {
    content: '';
    display: block;
    height: 20px;
  }
`;

const Link = styled.a<{ shouldDisplaySpanAsBlock: boolean }>`
  display: ${({ shouldDisplaySpanAsBlock }) =>
    shouldDisplaySpanAsBlock ? 'block' : 'initial'};
  align-items: center;
  color: ${ThemeV2.palette.geekBlue6};
  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
  }
  > span {
    margin-left: 2px;
    margin-top: 1px;
  }
`;

const Span = styled.span<{ shouldDisplaySpanAsBlock: boolean }>`
  display: ${({ shouldDisplaySpanAsBlock }) =>
    shouldDisplaySpanAsBlock ? 'block' : 'initial'};
  white-space: break-spaces;
`;

const TaskContent = ({
  content,
  strikeThroughOverride = false,
  disabled = false,
  stopPropagation = true,
  noPadding = false,
  hasHoverEffect = false,
  variant = 'body1',
}: TaskContentProps) => {
  const history = useHistory();
  const variantFromMap = variantMap[variant];
  const { fontSize, fontWeight, fontStyle, lineHeight } = variantFromMap;
  const fontColor = disabled ? 'gray6' : ThemeV2.palette.gray9;
  const palette = ThemeV2.palette as unknown as {
    [Key: string]: string;
  };
  const paletteColor = palette[fontColor];

  const bodyStyle = useMemo(
    () => ({
      fontSize,
      fontWeight,
      fontStyle,
      lineHeight,
      color: paletteColor,
    }),
    [fontSize, fontStyle, fontWeight, lineHeight, paletteColor],
  );

  return (
    <TaskContentWrapper
      strikeThroughOverride={strikeThroughOverride}
      disabled={disabled}
      variant="body1"
      noPadding={noPadding}
      hasHoverEffect={hasHoverEffect}
      {...bodyStyle}
    >
      {content.map((item, index) => {
        if (item.type === 'break') {
          if (content[index - 1].type !== 'break') {
            return null;
          }
          return <br key={`linebreak-${index}`} data-testid="task-linebreak" />;
        }
        const shouldDisplaySpanAsBlock = content[index + 1]?.type === 'break';
        if (item.props?.link) {
          return (
            <Link
              key={`${item.value}-${index}`}
              href={item.value}
              shouldDisplaySpanAsBlock={shouldDisplaySpanAsBlock}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="task-link"
              data-qa-id="task-link"
              onClick={(e) => {
                if (item.value.includes('showReplies=true')) {
                  e.preventDefault();
                  const parsed = qs.parse(item.value.split(`?`)[1]) as Record<
                    string,
                    string
                  >;
                  history.replace({
                    search: new URLSearchParams(parsed).toString(),
                  });
                }
                if (stopPropagation) {
                  e.stopPropagation();
                }
              }}
            >
              {item.value}
              <SVGIcon icon="external-link" size="16px" />
            </Link>
          );
        }
        return (
          <Span
            key={`${item.value}-${index}`}
            shouldDisplaySpanAsBlock={shouldDisplaySpanAsBlock}
            data-testid="task-text"
            data-qa-id="task-text"
            style={{ ...bodyStyle }}
          >
            {`${item.value}`}
          </Span>
        );
      })}
    </TaskContentWrapper>
  );
};

export default TaskContent;
