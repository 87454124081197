import React, { useCallback, MouseEvent } from 'react';
import theme from '../../../theme';
import { OF, SHOWING } from '../../../languages/en/singleWords';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import Dropdown from '../Dropdown_V2';
import { DropdownColor, DropdownVariants } from '../Dropdown_V2/interfaces';
import {
  PaginationContainer,
  PaginatedButton,
  DropdownContainer,
  PaginationStatus,
} from './styles';
import usePaginationLogic from './usePaginationLogic';

export const defaultItemsPerPageValues = [12, 24, 36, 48, 100];

export interface PaginationComponentProps {
  itemCount: number;
  pageNumber: number;
  onHandlePaginationChange: (pageNumber: number) => void;
  itemsPerPage: number;
  itemsPerPageValues: number[];
  onItemsPerPageValueChange: (value: number) => void;
  dropdownLabelText: string;
  hasRightSpacing?: boolean;
}

const PaginationComponent = (props: PaginationComponentProps) => {
  const {
    dropdownLabelText,
    itemsPerPage,
    pageNumber,
    itemCount,
    hasRightSpacing = false,
  } = props;
  const {
    models: { arrayOfPageNumbers, numberOfPages, dropdownValues },
    operations: {
      onDropdownValueChange,
      onNextPageClick,
      onPageClick,
      onPreviousPageClick,
    },
  } = usePaginationLogic(props);

  const handlePageClick = useCallback(
    (e: MouseEvent) => onPageClick(parseInt(e.currentTarget.innerHTML, 10)),
    [onPageClick],
  );
  return (
    <PaginationContainer hasRightSpacing={hasRightSpacing}>
      <DropdownContainer>
        <Body
          variant="body2"
          inline
          color={theme.palette.gray8}
          data-qa-id="paginationLabelText"
        >
          {dropdownLabelText}
        </Body>
        <Dropdown
          color={'secondary' as DropdownColor}
          menuItems={[
            {
              id: 'pagination-number-of-items',
              items: dropdownValues,
            },
          ]}
          onItemClick={onDropdownValueChange}
          value={itemsPerPage.toString()}
          variant={'text' as DropdownVariants}
        />
      </DropdownContainer>
      <PaginationStatus variant="body2" color={theme.palette.gray8}>
        {SHOWING} {(pageNumber - 1) * itemsPerPage + 1}-
        {Math.min(pageNumber * itemsPerPage, itemCount)} {OF} {itemCount}
      </PaginationStatus>
      <Flex>
        <PaginatedButton
          type="button"
          onClick={onPreviousPageClick}
          disabled={pageNumber === 1}
          isArrow
          data-testid="previous-page"
          data-qa-id="previous-page"
        >
          <SVGIcon icon="right-carat" rotate={180} size="16px" />
        </PaginatedButton>
        <Flex margin="0 16px">
          {arrayOfPageNumbers.map((num) => (
            <PaginatedButton
              type="button"
              key={num.value}
              isCurrentPage={num.value === pageNumber}
              onClick={num.showDots ? undefined : handlePageClick}
              nonClickable={num.showDots}
            >
              {num.showDots ? '...' : num.value}
            </PaginatedButton>
          ))}
        </Flex>
        <PaginatedButton
          type="button"
          onClick={onNextPageClick}
          disabled={pageNumber === numberOfPages}
          isArrow
          data-testid="next-page"
          data-qa-id="next-page"
        >
          <SVGIcon icon="right-carat" size="16px" />
        </PaginatedButton>
      </Flex>
    </PaginationContainer>
  );
};

export default PaginationComponent;
