import styled from 'styled-components';

import { LayoutStyleProps } from '../../../interfaces/Layout';
import ThemeV2 from '../../../theme';

import {
  device,
  AsideWidthWhenOpen,
  MinimumAsideWidthWhenOpen,
} from '../../../constants/layout';
import { Resizable } from 're-resizable';

export const StyledResizable = styled(Resizable)`
  transition: margin-right 0.5s;
`;

export const StyledAside = styled.aside<LayoutStyleProps>`
  transition: all 0.5s;
  box-sizing: border-box;
  top: ${({ isMobileApp, isEmbeddedInMainApp }) =>
    isMobileApp || isEmbeddedInMainApp ? 'unset' : '72px'};
  width: 100%;
  border-left: 1px solid ${ThemeV2.palette.gray4};
  background-color: ${ThemeV2.palette.white};
  overflow: auto;

  @media ${device.mobile} {
    position: fixed;
    left: ${({ isRightAsideOpen }) => (isRightAsideOpen ? '0' : '100%')};
  }

  @media ${device.tabletOrDesktop} {
    min-width: ${({ isResizable, isRightAsideOpen }) =>
      isResizable
        ? `unset`
        : `${isRightAsideOpen ? MinimumAsideWidthWhenOpen : 0}px`};
    width: ${({ isResizable, isRightAsideOpen }) =>
      isResizable ? `100%` : `${isRightAsideOpen ? AsideWidthWhenOpen : 0}px`};
  }
  z-index: 3;

  height: ${({ isKioskTurnedOn, isMobileApp, isEmbeddedInMainApp }) => {
    if (isEmbeddedInMainApp) {
      return '100vh';
    } else if (isKioskTurnedOn) {
      return 'calc(100vh - 114px)';
    }

    return isMobileApp ? '100vh' : 'calc(100vh - 72px)';
  }};
`;

export const AsideInner = styled.div<LayoutStyleProps>`
  position: relative;
  height: 100%;
  overflow-y: hidden;
`;

export const IconButton = styled.button<{
  isActive?: boolean;
  showUnread?: number;
}>`
  background-color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.gray1 : ThemeV2.palette.gray2};
  border: 0;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  outline: none;
  width: 48px;
  height: 48px;
  box-shadow: ${({ isActive }) =>
    isActive
      ? `0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12)`
      : 'none'};
  border-radius: 4px;
  cursor: pointer;
  margin-top: 16px;
  color: ${({ isActive }) =>
    isActive ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray7};
  &:hover {
    background-color: ${ThemeV2.palette.gray1};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
      0px 1px 5px rgba(0, 0, 0, 0.12);
  }
  & > *,
  & > span > svg {
    height: 24px;
    min-width: 24px;
    width: 24px;
  }
  @media ${device.tabletOrDesktop} {
    width: 64px;
    height: 64px;
    & > *,
    & > span > svg {
      height: 20px;
      min-width: 20px;
    }
  }
  ${({ showUnread }) =>
    showUnread &&
    `
    &::before {
      content: '';
      position: absolute;
      top: 25%;
      right: 20%;
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: ${ThemeV2.palette.geekBlue6};
      border: 2px solid ${ThemeV2.palette.gray1};
      z-index: 2;
      @media ${device.tabletOrDesktop} {
        right: 25%;
      }
    }
`}
`;

export const StyledIconButton = styled(IconButton)<LayoutStyleProps>`
  position: absolute;
  visibility: visible;
  opacity: 1;
  transition: right 0.5s;
  display: ${({ showRightAside }) => (!showRightAside ? 'none' : 'flex')};
  z-index: 99;
  bottom: ${({ isKioskTurnedOn }) => (isKioskTurnedOn ? '64px' : '32px')};
  left: ${({ isRightAsideOpen }) => (isRightAsideOpen ? `-10px` : `-32px`)};
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &:after {
    position: absolute;
    content: '';
    width: 48px;
    height: 48px;
  }
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 4px 5px rgba(0, 0, 0, 0.02),
      0px 1px 10px rgba(0, 0, 0, 0.12);
  }
  @media ${device.mobile} {
    display: none;
  }
`;
