import { useEffect, useState } from 'react';

import { MemberRole } from '../../interfaces/member';
import { useJobStatusQuery } from '../../queries/Settings/identityManager';

import {
  PreviewMember,
  GetMembersForPreviewRequest,
  GetMembersFromCriteriaJobResponse,
  isGetMembersFromCriteriaJobResponseType,
} from '../../interfaces/Sharesheet';

import {
  useGetMembersPreview,
  useGetMembersFromCriteriaForPreview,
} from '../../queries/Sharesheet';
import { MemberState } from '../../interfaces/user';

export const useGetFinalizedMembersFromCriteriaForPreview = (
  payload: GetMembersForPreviewRequest,
  limit = 20,
  enabled = true,
) => {
  const INTERVAL = 500;

  const [isLoading, setIsLoading] = useState(true);
  const [jobId, setJobId] = useState<string | undefined>(undefined);
  const [previewId, setPreviewId] = useState<string | undefined>(undefined);

  const {
    data: getMembersResponse,
    isError: getMembersResponseError,
    isInitialLoading: isLoadingGetPreviewJob,
  } = useGetMembersFromCriteriaForPreview(payload, enabled);

  const {
    data: jobData,
    error: jobError,
    refetch: refetchJobStatus,
    isInitialLoading: isLoadingJobStatus,
  } = useJobStatusQuery(jobId);

  const {
    data,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isError: responseError,
    isInitialLoading: isLoadingPreviewMembers,
  } = useGetMembersPreview(previewId, limit, !jobId && !!previewId);

  const getMemberStateFromMember = (member: PreviewMember) => {
    if (!member.memberId) {
      return MemberState.NEW;
    }

    return member.state === 'ACTIVE' ? MemberState.ACTIVE : MemberState.PENDING;
  };

  useEffect(() => {
    if (getMembersResponse) {
      const response = getMembersResponse;

      setJobId(
        isGetMembersFromCriteriaJobResponseType(response)
          ? (response as GetMembersFromCriteriaJobResponse).jobId
          : undefined,
      );
      setPreviewId(response.previewId);
    }
  }, [getMembersResponse]);

  useEffect(() => {
    if (jobData) {
      const {
        data: { status },
      } = jobData;

      if (status === 'SUCCESS') {
        setIsLoading(false);
        setJobId(undefined);
      }

      if (status === 'QUEUED' || status === 'PROCESSING') {
        setIsLoading(true);
        setTimeout(() => {
          refetchJobStatus();
        }, INTERVAL);
      }
    }
  }, [jobData, refetchJobStatus]);

  useEffect(() => {
    if (data) {
      setJobId(undefined);
      setIsLoading(false);
    }

    if (responseError || getMembersResponseError) {
      setJobId(undefined);
      setIsLoading(false);
      setPreviewId(undefined);
    }
  }, [data, responseError, getMembersResponseError]);

  if (!payload) {
    return {
      data: [],
      isLoading: false,
      isFetching: false,
      hasNextPage: false,
      totalMembersCount: 0,
      pendingMembersCount: 0,
      fetchNextPage: () => {},
    };
  }

  return {
    data: (
      data?.pages.reduce<PreviewMember[]>(
        (acc, page) => [...acc, ...page.data],
        [],
      ) || []
    ).map((member) => ({
      ...member,
      image: member.image || '',
      role: [MemberRole.Employee],
      // When we have a pending user, we do not get the member ID in the initial response
      // This causes regression in the UI, so we need to use the email as the member ID since
      // it is unique and fixes the UI regression by eliminating empty member IDs
      memberId: member.memberId || member.email,
      memberID: member.memberId || member.email,
      username: member.memberId || '',
      lastName: member.lastName || '',
      memberState: getMemberStateFromMember(member),
    })),
    jobError,
    isFetching,
    hasNextPage,
    fetchNextPage,
    totalMembersCount: data?.pages[0]?.total || 0,
    pendingMembersCount: data?.pages[0]?.metadata.pendingCount || 0,
    isLoading:
      isLoading ||
      isLoadingJobStatus ||
      isLoadingGetPreviewJob ||
      isLoadingPreviewMembers,
  };
};
