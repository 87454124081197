import React from 'react';
import styled from 'styled-components';
import { IMemberDTO } from '../../../interfaces/member';
import Body from '../../atoms/Body';
import {
  EDIT_PEOPLE,
  YOU_ARE_GIVING_POINTS_TO,
} from '../../../languages/en/recognitionFlow';
import { AND_LOWER, OTHERS_LOWER } from '../../../languages/en/singleWords';
import { AutocompleteDropdownItem } from '../../organism/Autocomplete/interfaces';
import { Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../theme';
import UserListDropdown from '../UserListDropdown';

const StyledBody = styled(Body)<{ underline?: boolean }>`
  margin-left: 4px;
  ${({ underline }) =>
    underline &&
    `
  text-decoration: underline;
  `};
`;

const AndBody = styled(Body)`
  margin-left: 4px;
`;

const StyledAnchor = styled.a`
  text-decoration: underline;
  color: ${ThemeV2.palette.geekBlue6};
  margin-left: 4px;
  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
  }
`;
interface FlowUserReceivingPointsInfoProps {
  onEditPeopleClick: () => void;
  users: AutocompleteDropdownItem<string, IMemberDTO>[];
}

const FlowUserReceivingPointsInfo = ({
  onEditPeopleClick,
  users,
}: FlowUserReceivingPointsInfoProps) => {
  const showOthers = users.length > 4;
  const renderUsers = () => {
    if (!showOthers) {
      return (
        <>
          {users.map((user, index) => (
            <>
              <StyledBody key={user.id} inline variant="body3Bold">{`${
                user.title
              }${index < users.length - 2 ? ', ' : ''}`}</StyledBody>
              {users.length > 1 && index === users.length - 2 && (
                <AndBody inline variant="body3">
                  {AND_LOWER}
                </AndBody>
              )}
            </>
          ))}
        </>
      );
    }
    return (
      <>
        {users.slice(0, 3).map((user, index) => (
          <StyledBody key={user.id} inline variant="body3Bold">{`${user.title}${
            index < 2 ? ', ' : ''
          }`}</StyledBody>
        ))}
      </>
    );
  };
  const otherUsers = users.slice(3);
  return (
    <Flex flexWrap="wrap">
      <Body inline variant="body3">
        {YOU_ARE_GIVING_POINTS_TO}
      </Body>
      {renderUsers()}
      {showOthers && (
        <>
          <AndBody inline variant="body3">
            {AND_LOWER}
          </AndBody>
          <UserListDropdown
            users={otherUsers.map(({ item }) => ({
              firstName: item?.firstName || '',
              lastName: item?.lastName || '',
              memberID: item?.memberID || '',
              image: item?.image || '',
              isDeleted: false,
              memberState: item?.memberState,
            }))}
            startIndex={0}
            endIndex={otherUsers.length}
            disableUserInteraction
            isSearchEnabled
            // visibility="owner"
            // viewCount={3}
          >
            <StyledBody
              inline
              variant="body3Bold"
              underline
            >{`${otherUsers.length} ${OTHERS_LOWER}`}</StyledBody>
          </UserListDropdown>
        </>
      )}
      .
      <StyledAnchor onClick={onEditPeopleClick} role="button" tabIndex={0}>
        {EDIT_PEOPLE}
      </StyledAnchor>
    </Flex>
  );
};

export default FlowUserReceivingPointsInfo;
