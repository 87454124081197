import styled from 'styled-components';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

import FlowMenuItemWrapper from './MenuItemWrapper';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import { FullWidthStyle } from '../../../Utils/styles/css';
import { StyledFlowMenuItemWrapperProps } from './types';
import IconContainer from '../../atoms/IconContainer';

export const StyledBody = styled(Body)`
  background-color: ${ThemeV2.palette.gray3};
  margin-left: 4px;
  padding: 0 4px;
`;

export const StyledFlowItemIconContainer = styled(IconContainer)`
  margin-right: 8px;
`;

export const StyledEmoticonHolder = styled(EmoticonHolder)`
  margin-right: 8px;
`;

export const StyledRightCarrotIcon = styled(SVGIcon)``;
export const StyledSVGIcon = styled(SVGIcon)`
  margin-left: 4px;
`;

export const StyledTextContent = styled(Flex)`
  flex: 1;
  justify-content: center;
`;

export const StyledFlowMenuItemWrapper = styled(
  FlowMenuItemWrapper,
)<StyledFlowMenuItemWrapperProps>`
  margin: 2px 0;
  align-items: center;
  border-radius: 4px;
  text-align: initial;
  display: flex;
  cursor: pointer;
  padding: 8px;

  ${FullWidthStyle};

  &:hover {
    background-color: ${ThemeV2.palette.geekBlue1};

    ${StyledFlowItemIconContainer} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }

    ${StyledBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }

    ${StyledRightCarrotIcon} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }

    ${StyledEmoticonHolder} {
      background-color: ${({ emoticonBg }) => emoticonBg};
    }
  }

  &:focus {
    background-color: ${ThemeV2.palette.geekBlue2};

    ${StyledFlowItemIconContainer} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }

    ${StyledBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }

    ${StyledRightCarrotIcon} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  }

  &:focus-visible {
    background-color: ${ThemeV2.palette.geekBlue1};

    ${StyledFlowItemIconContainer} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }

    ${StyledBody} {
      color: ${ThemeV2.palette.geekBlue6};
    }

    ${StyledRightCarrotIcon} {
      svg path {
        fill: ${ThemeV2.palette.geekBlue6};
      }
    }
  }
`;
