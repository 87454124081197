import React from 'react';
import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import { renderStatusColor } from '../../molecules/TextField_V2/styles';

export interface TextAreaProps {
  name?: string;
  value?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> &
    ((event: React.ChangeEventHandler<HTMLTextAreaElement>) => void);
  onBlur: () => void;
  disabled?: boolean;
  hasError?: boolean;
  helperText?: string;
  rows?: number;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  resize: none;
  border-radius: 4px;
  padding: 12px;
  border: 1px solid
    ${(props) =>
      props.disabled
        ? ThemeV2.palette.gray3
        : renderStatusColor(ThemeV2.palette.gray6, props)};
  box-sizing: border-box;
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-size: 14px;
  line-height: 22px;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-style: normal;
`;

const HelperText = styled.p<{ disabled?: boolean; hasError?: boolean }>`
  font-size: 14px;
  line-height: 22px;
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  color: ${(props) =>
    props.disabled
      ? ThemeV2.palette.gray3
      : renderStatusColor(ThemeV2.palette.gray6, props)};
`;

const TextArea = (props: TextAreaProps) => {
  const {
    name,
    value,
    rows = 10,
    helperText,
    hasError,
    disabled,
    onChange,
    onBlur,
  } = props;
  return (
    <>
      <StyledTextArea
        name={name}
        rows={rows}
        aria-label={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      {helperText && (
        <HelperText hasError={hasError} disabled={disabled}>
          {helperText}
        </HelperText>
      )}
    </>
  );
};

export default TextArea;
