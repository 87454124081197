import React, { useCallback, useState } from 'react';

import {
  removeAttachmentModalInfo,
  feedPostHeaderModalSubmitButtonText,
  feedPostHeaderModalCancelButtonText,
  getDeleteFeedConfirmationModalInfo,
} from '../../../languages/en/home';

import { feedPostDeleteModalDropdown } from './data';

import { FeedPostHeaderOptionProps, ModalContentProps } from './interface';
import { FeedTypes } from '../../../interfaces/Home';
import { DropdownColor } from '../../molecules/Dropdown_V2/interfaces';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import Body from '../../atoms/Body';
import DropdownIconButton from '../../molecules/DropdownIconButton';
import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';

import { ModalBodyDropdown, ModalBodyHeading } from './styles';

const FeedPostHeaderOption = (props: FeedPostHeaderOptionProps) => {
  const {
    dropdownOptions,
    icon,
    title,
    hasTrophies,
    dropdownToggleButtonSize = ButtonSize.Normal,
    feedType = FeedTypes.POST,
    onDeleteModalSubmitClick,
    onModalClose,
    onRemoveGif,
    onCopyLink,
    onEditReplyOptionClick,
    onDeletePostSelectedOption,
    className,
    showChatView,
    dropdownPlacement,
    currency,
  } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [modalInfoContent, setModalInfoContent] = useState<ModalContentProps>(
    {},
  );
  const [modalButtonDropdownValue, setModalButtonDropdownValue] = useState('');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const handleModalClose = useCallback(() => {
    setIsModalOpen(false);
    setIsDeleteConfirmationModalOpen(false);
    setModalButtonDropdownValue('');
    if (onModalClose) {
      onModalClose();
    }
  }, [onModalClose]);

  const handleModalSubmit = useCallback(() => {
    if (selectedOption === 'delete') {
      if (hasTrophies) {
        setModalInfoContent(
          getDeleteFeedConfirmationModalInfo(feedType, currency),
        );
        setIsDeleteConfirmationModalOpen(true);
        if (modalButtonDropdownValue !== '' && onDeleteModalSubmitClick) {
          onDeleteModalSubmitClick();
          handleModalClose();
        }
      } else if (onDeleteModalSubmitClick) {
        onDeleteModalSubmitClick();
        handleModalClose();
      }
    }
    if (selectedOption === 'remove-gif') {
      if (onRemoveGif) {
        onRemoveGif();
        handleModalClose();
      }
    }
    return null;
  }, [
    feedType,
    onRemoveGif,
    hasTrophies,
    onDeleteModalSubmitClick,
    selectedOption,
    handleModalClose,
    modalButtonDropdownValue,
  ]);

  const primaryButton: ModalButtonProps = {
    text: feedPostHeaderModalSubmitButtonText,
    status: 'warning',
    isLoading: false,
    onClick: handleModalSubmit,
    disabled: isDeleteConfirmationModalOpen && !modalButtonDropdownValue,
  };

  const secondaryButton: ModalButtonProps = {
    text: feedPostHeaderModalCancelButtonText,
    isLoading: false,
    onClick: handleModalClose,
  };

  const onOptionsSelect = useCallback(
    (value: string | number) => {
      if (value === 'remove-gif') {
        setModalInfoContent(removeAttachmentModalInfo);
        setIsModalOpen(true);
      }

      if (value === 'delete') {
        setModalInfoContent({
          headerText: showChatView ? 'Delete message?' : 'Delete reply?',
          // eslint-disable-next-line max-len
          contentText: showChatView
            ? `When you delete this message, it will disappear from the chat.  You will not be able to get it back`
            : `When you delete this reply, it will disappear from the post.  You will not be able to get it back`,
        });
        setIsModalOpen(true);
      }

      if (value === 'copy-link') {
        if (onCopyLink) {
          onCopyLink();
        }
      }

      if (value === 'edit-reply') {
        if (onEditReplyOptionClick) {
          onEditReplyOptionClick();
        }
      }

      setSelectedOption(value as string);
    },
    [feedType, onCopyLink, onEditReplyOptionClick],
  );

  const handleDropdownButtonOptionSelect = useCallback(
    (value: string | number) => {
      const selectedItem =
        (feedPostDeleteModalDropdown &&
          feedPostDeleteModalDropdown.options[0].items.filter(
            (dropdownItem) => dropdownItem.id === value,
          )) ||
        '';
      setModalButtonDropdownValue(selectedItem && selectedItem[0].value);
      if (onDeletePostSelectedOption) {
        onDeletePostSelectedOption(selectedItem[0].value || '');
      }
    },
    [onDeletePostSelectedOption],
  );

  return (
    <div className={className}>
      <DropdownIconButton
        icon={icon}
        title={title}
        isDropdownPositionFixed
        menuItems={dropdownOptions}
        onItemClick={onOptionsSelect}
        size={dropdownToggleButtonSize}
        dataTestId="dropdown-icon-button"
        dropdownPlacement={dropdownPlacement}
      />
      <BasicModalTemplate
        isOpen={isModalOpen}
        heading={modalInfoContent.headerText || ''}
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        onModalClose={handleModalClose}
        disableContentScroll
      >
        {modalInfoContent.contentHeading && (
          <ModalBodyHeading variant="h5">
            {modalInfoContent.contentHeading}
          </ModalBodyHeading>
        )}
        {modalInfoContent.contentText && (
          <Body variant="body2">{modalInfoContent.contentText}</Body>
        )}
        {isDeleteConfirmationModalOpen && (
          <ModalBodyDropdown
            isFullWidth
            color={DropdownColor.Secondary}
            value={
              modalButtonDropdownValue || feedPostDeleteModalDropdown.value
            }
            menuItems={feedPostDeleteModalDropdown.options}
            onItemClick={handleDropdownButtonOptionSelect}
          />
        )}
      </BasicModalTemplate>
    </div>
  );
};

const MemoizedFeedPostHeaderOption = React.memo(FeedPostHeaderOption);
MemoizedFeedPostHeaderOption.displayName = 'FeedPostHeaderOption';
export default FeedPostHeaderOption;
