import React, { useState } from 'react';
import styled from 'styled-components';
import {
  feedPostHeaderModalSubmitButtonText,
  feedPostHeaderModalCancelButtonText,
  getDeleteFeedConfirmationModalInfo,
  GET_DELETE_FLOW_MODEL_INFO,
  GIVE_TROPHIES_BACK_TO_SENDER,
} from '../../../languages/en/home';

import { DropdownColor } from '../../molecules/Dropdown_V2/interfaces';

import Body from '../../atoms/Body';
import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';

import {
  ModalBodyDropdown,
  ModalBodyHeading,
} from '../FeedPostHeaderOption/styles';
import { ModalContentProps } from '../FeedPostHeaderOption/interface';
import { feedPostDeleteModalDropdown } from '../FeedPostHeaderOption/data';
import { FeedTypes } from '../../../interfaces/Home';
import { AssemblyCurrency } from '../../../interfaces/assembly';

const ContentWrapper = styled.div`
  height: 128px;
`;

type DeleteModalProps = {
  hasTrophies?: boolean;
  onDeleteModalSubmitClick?: (returnPoints?: boolean) => void;
  onModalClose?: () => void;
  onDeletePostSelectedOption?: (value: string) => void;
  feedType?: FeedTypes;
  currency?: AssemblyCurrency;
};

const DeleteModal = (props: DeleteModalProps) => {
  const {
    hasTrophies,
    onDeleteModalSubmitClick,
    onModalClose,
    onDeletePostSelectedOption,
    feedType = FeedTypes.POST,
    currency,
  } = props;

  const [modalInfoContent, setModalInfoContent] = useState<ModalContentProps>(
    GET_DELETE_FLOW_MODEL_INFO,
  );
  const [modalButtonDropdownValue, setModalButtonDropdownValue] = useState('');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState<boolean>(false);

  const handleModalClose = () => {
    setIsDeleteConfirmationModalOpen(false);
    setModalButtonDropdownValue('');
    if (onModalClose) {
      onModalClose();
    }
  };

  const handleModalSubmit = () => {
    if (hasTrophies) {
      setModalInfoContent(
        getDeleteFeedConfirmationModalInfo(feedType, currency),
      );
      setIsDeleteConfirmationModalOpen(true);
      if (modalButtonDropdownValue !== '' && onDeleteModalSubmitClick) {
        const returnPoints =
          GIVE_TROPHIES_BACK_TO_SENDER === modalButtonDropdownValue;
        onDeleteModalSubmitClick(returnPoints);
        handleModalClose();
      }
    } else if (onDeleteModalSubmitClick) {
      onDeleteModalSubmitClick(false);
      handleModalClose();
    }
  };

  const primaryButton: ModalButtonProps = {
    text: feedPostHeaderModalSubmitButtonText,
    status: 'warning',
    isLoading: false,
    onClick: handleModalSubmit,
    disabled: isDeleteConfirmationModalOpen && !modalButtonDropdownValue,
  };
  const secondaryButton: ModalButtonProps = {
    text: feedPostHeaderModalCancelButtonText,
    isLoading: false,
    onClick: handleModalClose,
  };

  const handleDropdownButtonOptionSelect = (value: string | number) => {
    const selectedItem =
      (feedPostDeleteModalDropdown &&
        feedPostDeleteModalDropdown.options[0].items.filter(
          (dropdownItem) => dropdownItem.id === value,
        )) ||
      '';
    setModalButtonDropdownValue(selectedItem && selectedItem[0].value);
    if (onDeletePostSelectedOption) {
      onDeletePostSelectedOption(selectedItem[0].value || '');
    }
  };

  return (
    <BasicModalTemplate
      isOpen
      heading={modalInfoContent.headerText || ''}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={handleModalClose}
      disableContentScroll
    >
      <ContentWrapper>
        {modalInfoContent.contentHeading && (
          <ModalBodyHeading variant="h5">
            {modalInfoContent.contentHeading}
          </ModalBodyHeading>
        )}
        {modalInfoContent.contentText && (
          <Body variant="body2">{modalInfoContent.contentText}</Body>
        )}
        {isDeleteConfirmationModalOpen && (
          <ModalBodyDropdown
            isFullWidth
            color={DropdownColor.Secondary}
            value={
              modalButtonDropdownValue || feedPostDeleteModalDropdown.value
            }
            menuItems={feedPostDeleteModalDropdown.options}
            onItemClick={handleDropdownButtonOptionSelect}
          />
        )}
      </ContentWrapper>
    </BasicModalTemplate>
  );
};

export default DeleteModal;
