import pluralize from 'pluralize';
import { EditorState, SelectionState } from 'draft-js';

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import Body from '../../../../../atomic/atoms/Body';
import PostInput from '../../../../../atomic/organism/PostInput';
import FeedPostComments from '../../../../../atomic/organism/FeedPostComments';
import FlowsPostHeaderOption from '../../../../../atomic/organism/FlowsPostHeaderOption';
import { getFeedPostHeaderMenuOptions } from '../../../../../atomic/organism/FlowFeedPost/data';

import { useGetFlowPostComments } from '../../../../../queries/Feed/comments';
import { FlowFeedResponse } from '../../../../../queries/Flows/Feed/interfaces';
import { useUpdateSingleFlowReactionMutation } from '../../../../../queries/Flows/Feed';

import {
  FeedItemKind,
  FeedCommentFromAPI,
} from '../../../../../interfaces/Feed';

import { PostTypes } from '../../../../../interfaces/Home';
import { ComponentStatus } from '../../../../../interfaces/component';

import useFlowFeed from '../../../../../hooks/useFlowFeed';
import useRepliesBar from '../../../../../hooks/useRepliesBar';
import { useCommentInput } from '../../../../../hooks/useFeedCommentInput';

import { Flex } from '../../../../../Utils/styles/display';
import { FeedContext } from '../../../../../Utils/flows/feeds';
import { COMMENTS_LIMIT } from '../../../../../Utils/constants';

import {
  HorizontalRule,
  StyledInfoAlert,
  CommentsContainer,
  DropdownContainer,
  RepliesTextWrapper,
  CommentInputWrapper,
  StyledFlowPostHeader,
  ViewMoreRepliesButton,
  PostAndCommentsContainer,
  StyledFlowResponseController,
  StyledFeedCommentInputWrapper,
  PostAndCommentWrapper,
} from '../../styles';

import { CommentsLoader } from '../Loader';

import ThemeV2 from '../../../../../theme';
import { EmptyChatWrapper } from '../../../ChatController/styles';
import { useGetFlowPermissions } from '../../../../../queries/Flows/Replies';
import startConversation from '../../../../../img/svgs/start_conversation.svg';

import useFlowPostController from '../../../../flows/FlowResponseController/useFlowResponseController';
import { getOpenEndedEditedBlockInitialValue } from '../../../../flows/ParticipationFlowController/utils';

import { isIPhoneOS, isMobilePlatform } from '../../../../../Utils/window';
import useLayoutStore from '../../../../../stores/layoutStore';
import { trackPostActionEvent } from '../../../../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../../../../Utils/analytics/constants';
import { GIVE_TROPHIES_BACK_TO_SENDER } from '../../../../../languages/en/home';
import { ModalContentProps } from '../../../../../atomic/organism/FeedPostHeaderOption/interface';
import { DeleteReplyConfirmationModal } from '../../../../../atomic/molecules/DeleteReplyConfirmationModal';
import usePostInteractionSettingUpdatedSocket from '../../../../../hooks/usePostInteractionSettingUpdatedSocket';
import { PageType } from '../../../../../hooks/useReactionUpdatedSocket/types';

const FlowPost = ({
  flowId,
  commentId,
  responseId,
  isMobileApp,
  handleCloseButtonClick,
  data,
}: any) => {
  const SHOW_REPLIES_BUTTON_DELTA = 50;

  const postContainer = useRef<HTMLDivElement>(null);
  const alertContainer = useRef<HTMLDivElement>(null);
  const commentInputContainer = useRef<HTMLDivElement>(null);

  const [footerHeight, setFooterHeight] = useState(100);
  const [isAutoScrolled, setIsAutoScrolled] = useState(false);
  const [showRepliesButton, setShowRepliesButton] = useState(false);
  const [showBoxShadowForHeader, setShowBoxShadowForHeader] = useState(false);
  const [showBoxShadowForFooter, setShowBoxShadowForFooter] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalInfoContent, setModalInfoContent] = useState<ModalContentProps>(
    {},
  );

  const { isRightAsideOpen, isEmbeddedInMainApp } = useLayoutStore();

  const { repliesBarProps } = useRepliesBar({
    postId: responseId,
    type: 'RESPONSE',
    enabled: true,
  });
  const [editedComment, setEditedComment] = useState<FeedCommentFromAPI | null>(
    null,
  );
  const { mutate } = useUpdateSingleFlowReactionMutation(flowId);
  const { feedContextProps, profileData, onDeleteCommentClick } = useFlowFeed({
    mutateResponseReaction: mutate,
  });

  const { data: permissions } = useGetFlowPermissions({
    flowId: flowId,
    memberId: profileData?.member.memberId || '',
  });

  usePostInteractionSettingUpdatedSocket({
    pageType: PageType.SingleFlowFeed,
  });

  const responseCommentsObj = useGetFlowPostComments(
    flowId || '',
    responseId || '',
    COMMENTS_LIMIT,
    true,
    'asc',
  );

  useEffect(() => {
    const handleScroll = (event: Event) => {
      const element = event.target as HTMLDivElement;
      if (element) {
        const { scrollHeight, clientHeight, scrollTop } = element;

        setShowBoxShadowForHeader(scrollTop > 0);
        setShowBoxShadowForFooter(
          scrollHeight - (scrollTop + clientHeight) > 0,
        );
        setShowRepliesButton(
          scrollHeight - (scrollTop + clientHeight) > SHOW_REPLIES_BUTTON_DELTA,
        );
      }
    };

    postContainer.current?.addEventListener('scroll', handleScroll);

    return () => {
      postContainer.current?.removeEventListener('scroll', handleScroll);
    };
  }, [postContainer]);

  const scrollSideDrawerInView = useCallback(() => {
    const element = postContainer.current;
    if (element) {
      element.scroll({ top: element.scrollHeight });
    }
  }, []);

  const {
    isLoadingComments,
    showMoreCommentsButton,
    commentsData,
    fetchNextPage,
    fetchPreviousPage,
  } = useMemo(() => {
    const {
      isFetching,
      data: flowCommentsPaginatedData,
      hasNextPage: flowCommentsHasNextPage,
      fetchNextPage: fetchNextFlowComments,
      fetchPreviousPage: fetchPreviousFlowComments,
    } = responseCommentsObj;
    const commentsArr = flowCommentsPaginatedData
      ? flowCommentsPaginatedData.pages.reduce<FeedCommentFromAPI[]>(
          (arr, currentPage) => [
            ...arr,
            ...currentPage.data.map((x) => {
              return {
                ...x,
                message: x.version === 2 ? '' : x.message,
              };
            }),
          ],
          [],
        )
      : [];

    commentsArr.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );

    return {
      isLoadingComments: isFetching,
      commentsData: commentsArr,
      showMoreCommentsButton: flowCommentsHasNextPage || false,
      fetchNextPage: async () => {
        if (responseCommentsObj.hasNextPage) {
          await fetchNextFlowComments();
        }

        await scrollSideDrawerInView();
      },
      fetchPreviousPage: async () => {
        if (responseCommentsObj.hasPreviousPage) {
          await fetchPreviousFlowComments();
        }
      },
    };
  }, [responseCommentsObj, scrollSideDrawerInView]);

  useEffect(() => {
    if (postContainer.current) {
      const element = postContainer.current;
      if (element) {
        const { scrollHeight, clientHeight, scrollTop } = element;
        const scrollBottom = scrollHeight - (scrollTop + clientHeight);

        setShowBoxShadowForHeader(scrollTop > 0);
        setShowBoxShadowForFooter(scrollBottom > 30);
        setShowRepliesButton(scrollBottom > SHOW_REPLIES_BUTTON_DELTA);

        if (scrollBottom <= 200 && responseCommentsObj.hasNextPage) {
          fetchNextPage();
        }
      }
    }
  }, [
    fetchNextPage,
    responseCommentsObj.hasNextPage,
    postContainer.current?.scrollTop,
    postContainer.current?.scrollHeight,
    postContainer.current?.clientHeight,
  ]);

  const { commentInputProps } = useCommentInput({
    profileData,
    postId: '',
    postType: PostTypes.FLOW,
    responseId,
    flowId,
    trophyReceivers: [],
    editedComment: editedComment,
    onCommentMutated: scrollSideDrawerInView,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      commentInputProps.setEditorFocus();
    });
    return () => clearTimeout(timer);
  }, []);

  const handleEditComment = useCallback(
    (id: string) => {
      commentsData.forEach((comment) => {
        if (comment.commentID === id) {
          setEditedComment(comment);
          comment.isEditing = true;
          if (commentInputProps.setGifUrl) {
            commentInputProps?.setGifUrl(comment.gifURL);
          }
          const selectedMentions = comment.taggedUsers.map((x) => ({
            id: x.memberID,
            name: x.name || '',
          }));

          const editedState = getOpenEndedEditedBlockInitialValue({
            mentions: comment.taggedUsers.map((x) => ({
              memberId: x.memberID,
              lastName: x.lastName,
              firstName: x.firstName,
            })),
            gifUrl: comment.gifURL,
            files: [],
            tags: [],
            tasks: [],
            value: comment.message,
          });

          commentInputProps.setSelectedMentions.current = selectedMentions;

          if (commentInputProps.setEditorState) {
            const content = editedState.editorState.getCurrentContent();
            const blockMap = content.getBlockMap();

            const key = blockMap.last().getKey();
            const length = blockMap.last().getLength();

            const selection = new SelectionState({
              anchorKey: key,
              anchorOffset: length,
              focusKey: key,
              focusOffset: length,
            });

            EditorState.forceSelection(editedState.editorState, selection);
            commentInputProps.setEditorState(editedState.editorState);
            setTimeout(() => {
              commentInputProps.setEditorFocus();
            });
          }
        }
      });
    },
    [commentInputProps, commentsData],
  );

  const flowPostControllerProps = useMemo(
    () => ({
      variant: 'sidebar',
      hideBorder: true,
      hideRepliesBar: true,
      hideReplyButton: true,
      response: data as FlowFeedResponse,
      profileData: profileData,
      showEditOption: false,
    }),
    [data, profileData],
  );

  const {
    feedProps,
    hasTrophies,
    onCopyLinkClick,
    onGotoPostLinkClick,
    onDeleteModalSubmitClick,
    handleDeletePostOptionChange,
    notificationPreference,
    onNotificationPreferenceClick,
    currency,
  } = useFlowPostController(flowPostControllerProps);

  const initialLoad = useRef(true);
  useEffect(() => {
    if (!isLoadingComments && initialLoad.current) {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('commentId');
      const isEditing = searchParams.get('editing') === 'true';
      if (isEditing && id) {
        handleEditComment(id);
      }
      initialLoad.current = false;
    }
  }, [isLoadingComments]);

  useEffect(() => {
    if (!isAutoScrolled && commentId && !isLoadingComments) {
      setIsAutoScrolled(true);
      const element = document.getElementById(commentId);
      if (element) {
        element.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [commentId, isAutoScrolled, isLoadingComments]);

  const dropdownComponent = useMemo(
    () => (
      <DropdownContainer>
        <FlowsPostHeaderOption
          icon="more"
          hasTrophies={hasTrophies}
          title="flows post options"
          onCopyLink={onCopyLinkClick}
          onGotoPostClick={onGotoPostLinkClick}
          onDeleteModalSubmitClick={onDeleteModalSubmitClick}
          onDeletePostSelectedOption={handleDeletePostOptionChange}
          dropdownOptions={getFeedPostHeaderMenuOptions(
            true,
            false,
            'sidebar',
            notificationPreference,
          )}
          onNotificationPreferenceClick={onNotificationPreferenceClick}
          currency={currency}
        />
      </DropdownContainer>
    ),
    [
      hasTrophies,
      onCopyLinkClick,
      onGotoPostLinkClick,
      onDeleteModalSubmitClick,
      handleDeletePostOptionChange,
      notificationPreference,
      onNotificationPreferenceClick,
      currency,
    ],
  );

  const resetCommentEditor = useCallback(() => {
    if (editedComment && commentInputProps?.setEditorState) {
      if (commentInputProps?.setGifUrl) {
        commentInputProps?.setGifUrl('');
      }

      setEditedComment(null);
      editedComment.isEditing = false;
      commentInputProps.isInEditMode = false;
      commentInputProps.setSelectedMentions.current = [];
      commentInputProps.setEditorState(EditorState.createEmpty());
    }
  }, [editedComment, commentInputProps, flowId, responseId]);

  const handleCancelClick = useCallback(() => {
    resetCommentEditor();
    trackPostActionEvent({
      flowId: flowId,
      responseId: responseId,
      type: PostTypes.FLOW,
      messageType: 'replies',
      replyId: editedComment?.commentID,
      action: POST_ANALYTICS_EVENTS.REPLY_EDIT_CANCELLED,
    });
  }, [commentInputProps, editedComment, resetCommentEditor]);

  const handleCommentDelete = useCallback(() => {
    if (!commentInputProps.hasText) {
      setModalInfoContent({
        headerText: 'Delete reply?',
        // eslint-disable-next-line max-len
        contentText:
          'When you delete this reply, it will disappear from the post.  You will not be able to get it back',
      });
      setIsModalOpen(true);
    }
  }, [commentInputProps.hasText]);

  const handleModalSubmit = useCallback(() => {
    if (onDeleteCommentClick)
      onDeleteCommentClick(
        String(editedComment?.commentID),
        false,
        GIVE_TROPHIES_BACK_TO_SENDER,
        { postId: String(editedComment?.postID), type: PostTypes.RECOGNITION },
      );
    setIsModalOpen(false);
    resetCommentEditor();
  }, [editedComment, onDeleteCommentClick, resetCommentEditor]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const getErrorMessage = useMemo(() => {
    if (!permissions) {
      return null;
    }

    const isInternalAnonymous =
      feedProps?.headerProps.kind === FeedItemKind.INTERNAL_ANONYMOUS;
    const isExternalAnonymous =
      feedProps?.headerProps.kind === FeedItemKind.EXTERNAL_ANONYMOUS;

    if (
      (isInternalAnonymous && permissions.isFlowViewer) ||
      (isExternalAnonymous && permissions.isFlowViewer)
    ) {
      return 'Reply will not be anonymous.';
    }

    if (
      (isInternalAnonymous && !permissions?.isFlowViewer) ||
      (isExternalAnonymous && !permissions?.isFlowViewer)
    ) {
      return 'Reply will be visible to the person who posted and won’t be anonymous.';
    }

    if (!permissions.isFlowViewer && feedProps?.headerProps.person?.firstName) {
      return `Reply will also be visible to ${feedProps?.headerProps.person?.firstName}`;
    }
  }, [
    permissions,
    feedProps?.headerProps.kind,
    feedProps?.headerProps.person?.firstName,
  ]);

  useEffect(() => {
    if (commentInputContainer.current) {
      let height = commentInputContainer.current.scrollHeight || 100;

      if (alertContainer.current) {
        const ALERT_CONTAINER_PADDING = 40;
        height += alertContainer.current.scrollHeight + ALERT_CONTAINER_PADDING;
      }

      if (commentInputProps.selectedGif) {
        height += 10;
      }

      setFooterHeight(height);
    }
  }, [
    getErrorMessage,
    commentInputProps.editorState,
    commentInputProps.selectedGif,
  ]);

  const PostAndCommentContainerHeight = useMemo(() => {
    if (isEmbeddedInMainApp) {
      return `calc(100vh - 196px)`;
    } else if (isMobilePlatform && !isMobileApp) {
      return `calc(100vh - ${footerHeight + (isIPhoneOS ? 280 : 230)}px)`;
    } else {
      return `calc(100vh - ${footerHeight + 164}px)`;
    }
  }, [isEmbeddedInMainApp, isMobileApp, footerHeight]);

  const getRepliesHeaderText = ({ repliesCount }: { repliesCount: number }) => {
    if (data?.hideReplies) {
      return 'Replies have been turned off';
    }
    return repliesCount > 0
      ? pluralize('reply', repliesCount, true)
      : 'No replies';
  };

  return (
    <>
      {data && profileData && (
        <Flex
          width="100%"
          flexDirection="column"
          height={isEmbeddedInMainApp ? '100vh' : '100%'}
        >
          <FeedContext.Provider value={feedContextProps}>
            {feedProps && (
              <StyledFlowPostHeader
                {...feedProps.headerProps}
                variant="sidebar"
                onCloseButtonClick={handleCloseButtonClick}
                dropdownComponent={dropdownComponent}
                showBoxShadow={showBoxShadowForHeader}
                onFlowClick={feedContextProps.onFlowClick}
                onClickUserName={feedContextProps.onMemberClick}
                currentUserId={profileData.member.memberId}
              />
            )}

            <PostAndCommentWrapper isEmbeddedInMainApp={isEmbeddedInMainApp}>
              <PostAndCommentsContainer
                width="100%"
                ref={postContainer}
                flexDirection="column"
                height={PostAndCommentContainerHeight}
              >
                <Flex alignItems="flex-start" width="100%">
                  <StyledFlowResponseController
                    hideBorder
                    hideRepliesBar
                    response={data}
                    variant="sidebar"
                    showEditOption={false}
                    profileData={profileData}
                    canEndAnnouncement={false}
                    canEditAnnouncement={false}
                    canShareAsAnnouncement={false}
                    hideReactions={data.hideReactions}
                    canViewAnnouncementInsights={false}
                  />
                </Flex>
                {repliesBarProps.status === ComponentStatus.LOADED && (
                  <RepliesTextWrapper>
                    <Body variant="body2" color="gray8">
                      {getRepliesHeaderText({
                        repliesCount: repliesBarProps.count,
                      })}
                    </Body>
                    <HorizontalRule />
                  </RepliesTextWrapper>
                )}

                {!data.hideReplies && (
                  <CommentsContainer>
                    <FeedPostComments
                      isDummy={false}
                      variant="sidebar"
                      onEditComment={handleEditComment}
                      profileData={profileData}
                      commentsData={commentsData}
                      fetchNextPage={fetchNextPage}
                      fetchPreviousPage={fetchPreviousPage}
                      onShowMoreCommentsButtonClick={fetchNextPage}
                      showMoreCommentsButton={showMoreCommentsButton}
                      feedItemMetaData={{
                        flowId,
                        responseId,
                        postId: '',
                        type: PostTypes.FLOW,
                      }}
                    />
                    {isLoadingComments && commentsData.length === 0 && (
                      <CommentsLoader />
                    )}
                  </CommentsContainer>
                )}
                {!data.hideReplies && commentInputProps && (
                  <StyledFeedCommentInputWrapper
                    footerHeight={footerHeight}
                    data-testid="feed-comment-input-wrapper"
                  >
                    {(showRepliesButton || showMoreCommentsButton) &&
                      !isMobileApp &&
                      isRightAsideOpen &&
                      repliesBarProps.status === ComponentStatus.LOADED &&
                      repliesBarProps.count > 0 && (
                        <Flex justifyContent="center" padding="16px 0">
                          <ViewMoreRepliesButton
                            icon="arrow-down"
                            onClick={fetchNextPage}
                            variant="showMorePrimary"
                            size="small"
                            color="grayTertiary"
                            isLoading={isLoadingComments}
                          >
                            See latest replies
                          </ViewMoreRepliesButton>
                        </Flex>
                      )}
                  </StyledFeedCommentInputWrapper>
                )}

                {!isLoadingComments &&
                  feedProps &&
                  commentsData.length === 0 &&
                  !data.hideReplies && (
                    <Flex
                      height="100%"
                      flexDirection="column"
                      justifyContent="flex-end"
                    >
                      <img src={startConversation} />
                      <EmptyChatWrapper flexDirection="column">
                        <Body variant="body1Bold" color="gray">
                          Be the first to reply
                        </Body>
                        {feedProps.headerProps?.person?.firstName && (
                          <Body variant="body2" color="gray">
                            Write {feedProps.headerProps?.person?.firstName} a
                            reply below 👇
                          </Body>
                        )}
                      </EmptyChatWrapper>
                    </Flex>
                  )}
              </PostAndCommentsContainer>

              <CommentInputWrapper showBoxShadow={showBoxShadowForFooter}>
                {getErrorMessage && !data.hideReplies && (
                  <div ref={alertContainer}>
                    <StyledInfoAlert
                      alertType="custom"
                      id="toggleInfoAlert"
                      icon="info-outlined"
                      text={getErrorMessage}
                      customVariant={{
                        background: ThemeV2.palette.gray3,
                        iconColor: ThemeV2.palette.gray9,
                      }}
                    />
                  </div>
                )}
                <div
                  ref={commentInputContainer}
                  className="introjs-replies-text"
                >
                  <PostInput
                    {...commentInputProps}
                    variant="sidebar"
                    isTasksButtonHidden
                    onCancelClick={handleCancelClick}
                    isInEditMode={editedComment?.isEditing}
                    hasText={
                      isMobilePlatform ? true : commentInputProps.hasText
                    }
                    handleCommentDelete={handleCommentDelete}
                    isDisabled={data.hideReplies}
                  />
                </div>
              </CommentInputWrapper>
            </PostAndCommentWrapper>
            <DeleteReplyConfirmationModal
              isModalOpen={isModalOpen}
              modalInfoContent={modalInfoContent}
              handleModalSubmit={handleModalSubmit}
              handleModalClose={handleModalClose}
            />
          </FeedContext.Provider>
        </Flex>
      )}
    </>
  );
};

export default FlowPost;
