import { trackEvent } from '../index';
import { OnboardingEventProps } from '../interfaces';
import { AnalyticsEvents, ONBOARDING_EVENTS } from '../constants';

const trackOnboardingActionEvent = (eventProps: OnboardingEventProps) => {
  trackEvent(ONBOARDING_EVENTS, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export { trackOnboardingActionEvent };
