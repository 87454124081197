import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { FluidContainer } from '../../../Utils/styles/display';
import searching from '../../../img/searching.svg';
import useToggleInviteModal from '../../../hooks/useToggleInviteModal';
import InviteModalController from '../../InviteModalController';

const EmptyState = () => {
  const { isModalOpen, toggleInviteModal } = useToggleInviteModal();
  return (
    <FluidContainer contentPadding="16px">
      <EmptyErrorStateTemplate
        heading="We couldn’t find a person who matches this search"
        subHeading="Try another search to find who you’re looking for, or invite a teammate."
        button={{
          icon: 'add-person-transparent',
          onClick: () => {
            toggleInviteModal();
          },
          text: 'Invite teammates',
        }}
        image={searching}
      />
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={toggleInviteModal}
      />
    </FluidContainer>
  );
};

export default EmptyState;
