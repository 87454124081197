import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GET_FLOW_DETAILS, GET_TODO_FLOWS } from '../../constants/endpoints';
import { useFetchFlowActive } from '../../queries/Flows/Dashboard';
import { useFetchArchivedFlows } from '../../queries/Flows/FlowFeedOptions/RunFlow';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { pusher } from '../../pusher/pusher-base';
import { Channel } from 'pusher-js';

interface SocketPayloadType {
  flowId: string;
}

let channelAssembly: Channel;

const useArchiveSocket = () => {
  const { data } = useProfileInfoFetchQuery();
  const { refetch: refetchActiveFlow } = useFetchFlowActive();
  const { refetch: refetchArchivedFlows } = useFetchArchivedFlows();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      const channelAssemblyEventHandler = (
        socketData: string,
        socketPayload: SocketPayloadType,
      ) => {
        switch (socketData) {
          case 'FLOW_ARCHIVED':
          case 'FLOW_UNARCHIVED': {
            refetchActiveFlow();
            refetchArchivedFlows();
            queryClient.invalidateQueries([GET_TODO_FLOWS]);

            if (socketPayload?.flowId) {
              queryClient.invalidateQueries([
                GET_FLOW_DETAILS,
                socketPayload.flowId,
              ]);
            }
            break;
          }
          default:
            break;
        }
      };

      channelAssembly.bind_global(channelAssemblyEventHandler);
    }
  }, [data, queryClient, refetchActiveFlow, refetchArchivedFlows]);
};

export default useArchiveSocket;
