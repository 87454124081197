import { useEffect, useCallback } from 'react';
import { Channel } from 'pusher-js';

import { pusher } from '../../pusher/pusher-base';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { authStore } from '../../stores/authStore';

const useAdminSettingsUpdatedSocket = () => {
  const isAuthenticated = authStore.getState().authenticated;
  const { data, refetch } = useProfileInfoFetchQuery(isAuthenticated);

  const channelAssemblyEventHandler = useCallback(
    (eventName: string) => {
      if (eventName === 'ADMIN_SETTINGS_UPDATED') {
        refetch();
      }
    },
    [refetch],
  );

  useEffect(() => {
    let channelAssembly: Channel | null = null;

    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);

      channelAssembly.bind_global(channelAssemblyEventHandler);
    }

    return () => {
      if (channelAssembly) {
        channelAssembly.unbind_global(channelAssemblyEventHandler);
      }
    };
  }, [data, channelAssemblyEventHandler]);

  return null;
};

export default useAdminSettingsUpdatedSocket;
