import React from 'react';
import { Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RESET } from '../../../languages/en/singleWords';
import ThemeV2 from '../../../theme';
import { ZOOM } from '../../../hooks/useZoomSlider';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import Tooltip from '../../atoms/ToolTip';
import {
  StyledIconButton,
  SliderWrapper,
  SliderBox,
  VerticalBar,
} from './styles';

const useStyles = makeStyles({
  popper: {
    top: '10px !important',
  },
});

type ZoomSliderProps = {
  onZoomOut: () => void;
  onZoomIn: () => void;
  setZoomPercent: (value: number) => void;
  onSliderMove: (
    event: React.ChangeEvent<unknown> | Event,
    value: number | number[],
  ) => void;
  zoomPercent: number;
};

const ZoomSlider = (props: ZoomSliderProps) => {
  const classes = useStyles();
  const { onZoomOut, onZoomIn, setZoomPercent, zoomPercent, onSliderMove } =
    props;

  return (
    <SliderBox>
      <StyledIconButton
        height={24}
        icon="zoom-out"
        size={ButtonSize.Normal}
        iconColor={ThemeV2.palette.gray8}
        onClick={onZoomOut}
      />
      <SliderWrapper>
        <Slider
          aria-label="Zoom slider"
          value={zoomPercent}
          onChange={onSliderMove}
          valueLabelDisplay="auto"
          track={false}
          min={ZOOM.MIN}
          max={ZOOM.MAX}
        />
      </SliderWrapper>
      <StyledIconButton
        height={24}
        icon="zoom-in"
        size={ButtonSize.Normal}
        iconColor={ThemeV2.palette.gray8}
        onClick={onZoomIn}
      />
      <VerticalBar height={24} sideMargin={8} />
      <Tooltip arrow={false} title={RESET} classes={classes}>
        <div>
          <StyledIconButton
            height={24}
            icon="reset-zoom"
            size={ButtonSize.Normal}
            iconColor={ThemeV2.palette.gray8}
            onClick={() => setZoomPercent(ZOOM.DEFAULT)}
          />
        </div>
      </Tooltip>
    </SliderBox>
  );
};

export default ZoomSlider;
