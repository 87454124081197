import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { useEffect } from 'react';
import { pusher } from '../../pusher/pusher-base';
import useLayoutStore from '../../stores/layoutStore';
import {
  GET_ENTITY_PERMISSION_DETAILS,
  GET_FLOW_FEED,
  GET_FLOW_RESPONSE,
  GET_PROFILE_FEED,
} from '../../constants/endpoints';
import { useQueryClient } from '@tanstack/react-query';

const useAnnouncementsSocket = () => {
  const queryClient = useQueryClient();
  const { data: currentUserData } = useProfileInfoFetchQuery();

  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;

  useEffect(() => {
    let announcementsEventHandler: (
      eventName: string,
      socketPayload: unknown,
    ) => void | undefined;

    if (currentUserData && isEmbeddedInMainApp) {
      const { assemblyId } = currentUserData.assembly;
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );

      announcementsEventHandler = (eventName: string) => {
        if (
          eventName === 'ANNOUNCEMENT_CREATED' ||
          eventName === 'ANNOUNCEMENT_UPDATED' ||
          eventName === 'ANNOUNCEMENT_ENDED'
        ) {
          const queryCache = queryClient.getQueryCache();

          queryCache.getAll().map((query) => {
            const queryKey = query.queryKey;
            const keysToUpdate = [
              GET_ENTITY_PERMISSION_DETAILS,
              GET_FLOW_RESPONSE,
              GET_PROFILE_FEED,
              GET_FLOW_FEED,
            ];

            if (
              Array.isArray(queryKey) &&
              keysToUpdate.includes(queryKey[0] as string)
            ) {
              queryClient.refetchQueries(queryKey);
            }
          });
        }
      };

      channelAssembly.bind_global(announcementsEventHandler);
      return () => {
        channelAssembly.unbind_global(announcementsEventHandler);
      };
    }
  }, [currentUserData, isEmbeddedInMainApp, queryClient]);
};

export default useAnnouncementsSocket;
