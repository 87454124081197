import ContentLoader from 'react-content-loader';
import { StyledCard } from './PeopleDirectoryView';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const SkeletonLoader = () => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  return (
    <Flex
      margin="0 16px"
      alignItems="center"
      justifyContent={isMobileView ? 'center' : 'flex-start'}
    >
      <StyledCard {...(!isMobileView && { maxWidth: '180px' })}>
        <ContentLoader
          speed={2}
          viewBox="0 0 188 188"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect width="188" height="188" />
        </ContentLoader>

        <ContentLoader
          speed={2}
          height="68px"
          viewBox="0 0 240 68"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <rect x="16" y="16" width="174" height="12" rx="4" />
          <rect x="36" y="40" width="97" height="12" rx="4" />
          <rect x="16" y="38" width="16" height="16" rx="8" />
        </ContentLoader>
      </StyledCard>
    </Flex>
  );
};

export default SkeletonLoader;
