import { useInfiniteQuery } from '@tanstack/react-query';

import { makeAPICallWithDataReturn } from '../utils';
import { GetFeedResponse, PostProfileFeedPayload } from '../../interfaces/Feed';
import { GET_PROFILE_FEED } from '../../constants/endpoints';

export const useGetProfileFeedQuery = ({
  userId,
  filter,
}: PostProfileFeedPayload) => {
  return useInfiniteQuery<GetFeedResponse>(
    [GET_PROFILE_FEED, userId, filter],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_PROFILE_FEED,
        {
          cursor: pageParam,
          filter,
        },
        undefined,
        { profileId: userId },
      ),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
    },
  );
};
