import styled from 'styled-components';

import Body from '../../atoms/Body';
import Dropdown from '../../molecules/Dropdown_V2';
import CalendarInput from '../../molecules/CalendarInput';
import Input from '../../molecules/Input';
import Link from '../../atoms/Link_V2';

import { SingleSelectAutoComplete } from '../Autocomplete';
import { Flex } from '../../../Utils/styles/display';
import IconInfo from '../../molecules/IconInfo';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import RadioGroup from '../../molecules/RadioGroup';

export const StyledBody = styled(Body)`
  margin-bottom: 16px;
`;
export const StyledErrorBody = styled(Body)`
  position: absolute;
  top: 40px;
`;

export const StyledCalendarInputWrapper = styled.div`
  max-width: 255px;
`;

export const StyledCalendarInput = styled(CalendarInput)`
  overflow: hidden;
  &.MuiOutlinedInput-root {
    border-radius: 4px;
  }
`;

export const StyledFlowRecurWrapper = styled.div`
  width: 255px;
  flex: 1;
  position: relative;
  & input.MuiInputBase-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 75%;
  }
`;

export const StyledSingleSelectAutoComplete = styled(SingleSelectAutoComplete)`
  flex: 1;
  padding-left: 12px;
`;

export const StyleFlowRecurBody = styled(Body)`
  margin: 0 8px;
`;

export const StyledDeadlineConfigurationFlex = styled(Flex)`
  width: 170px;
  margin-top: 16px;
`;

export const StyledDropdown = styled(Dropdown)`
  margin-left: 8px;
`;

export const StyledInput = styled(Input)`
  min-width: 72px;
`;

export const StyledIconInfo = styled(IconInfo)`
  border-radius: 4px;
  margin-bottom: 16px;
`;

export const StyledFlex = styled(Flex)`
  flex: 1;
  padding-left: 12px;
`;

export const StyledFlowEndCalendarInputWrapper = styled.div`
  width: 222px;
  margin-right: 12px;
`;

export const StyledSingleSelectAutoCompleteWrapper = styled.div`
  width: 222px;
  margin-left: 12px;
  height: 40px;
  position: relative;
`;

export const StyledShortcutConfigurationBody = styled(Body)`
  padding: 4px 0 16px;
`;

export const StyledLink = styled(Link)`
  font-size: 12px;
  line-height: 20px;
`;

export const StyledFlowTimezone = styled.div`
  margin-top: 24px;
`;

export const StyledAnswerAnytimeSwitch = styled(ToggleSwitch)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const FlowEndRadioGroup = styled(RadioGroup)`
  overflow-x: auto;
  overflow-y: hidden;
`;
