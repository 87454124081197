import produce from 'immer';

import {
  useQuery,
  useMutation,
  InfiniteData,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { Nullable } from '../../Utils/common';
import { mapChatRequestPayloadToChat } from './utils';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

import {
  GET_CHATS,
  CREATE_CHAT,
  GET_UNREAD_CHATS,
  MARK_CHATS_AS_READ,
  DELETE_CHAT_MESSAGES,
  UPDATE_CHAT_REACTIONS,
} from '../../constants/endpoints';

import {
  GetChatsResponse,
  CreateChatRequest,
  GetUnreadChatsResponse,
  DeleteChatMessageRequest,
} from '../../interfaces/Chats';

import {
  UpdateReactionAction,
  UpdateCommentReactionMutationVariables,
} from '../../interfaces/Feed';

import { WritableDraft } from 'immer/dist/types/types-external';

export const useCreateChat = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateChatRequest) => {
      return makeAPICall(CREATE_CHAT, payload, undefined, {
        memberId: payload.memberId,
      });
    },
    {
      onMutate: async (payload: CreateChatRequest) => {
        const queryKey = [GET_CHATS, payload.memberId];

        const previousChats =
          queryClient.getQueryData<InfiniteData<GetChatsResponse>>(queryKey);

        if (previousChats) {
          const updatedChats = produce(previousChats, (draft) => {
            const pageLength = draft.pages.length;
            const lastPage = draft.pages[pageLength - 1];
            lastPage.data.push(mapChatRequestPayloadToChat(payload));
            lastPage.total += 1;
          });

          queryClient.setQueryData(queryKey, updatedChats);
        } else {
          queryClient.setQueryData(queryKey, {
            pages: [
              {
                data: [payload],
                metadata: {
                  pagination: {
                    cursor: {
                      previous: 'previous',
                      next: 'next',
                    },
                  },
                },
                total: 1,
              },
            ],
            pageParams: [],
          });
        }
      },
    },
  );
};

export const useGetChats = (memberId: Nullable<string>) => {
  const queryClient = useQueryClient();
  return useInfiniteQuery<GetChatsResponse>(
    [GET_CHATS, memberId],
    ({ pageParam = undefined }) => {
      return makeAPICallWithDataReturn(
        GET_CHATS,
        undefined,
        { cursor: pageParam, limit: 99 },
        { memberId: memberId || '' },
      );
    },
    {
      enabled: !!memberId,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
      onSuccess: async () => {
        await queryClient.refetchQueries([GET_UNREAD_CHATS]);
      },
    },
  );
};

export const useGetUnreadChats = () => {
  return useQuery<GetUnreadChatsResponse>(
    [GET_UNREAD_CHATS],
    () => makeAPICallWithDataReturn(GET_UNREAD_CHATS, undefined, undefined, {}),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 3 * 60 * 1000,
    },
  );
};

export const useMarkChatsAsRead = (memberId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => makeAPICall(MARK_CHATS_AS_READ, {}, {}, { memberId }),
    {
      onMutate: async () => {
        await queryClient.refetchQueries([GET_UNREAD_CHATS]);
      },
    },
  );
};

export const useDeleteChatMessage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ commentId }: DeleteChatMessageRequest) => {
      return makeAPICall(DELETE_CHAT_MESSAGES, {}, {}, { commentId });
    },
    {
      onMutate: async ({ memberId, commentId }: DeleteChatMessageRequest) => {
        const queryKey = [GET_CHATS, memberId];

        const previousChats =
          queryClient.getQueryData<InfiniteData<GetChatsResponse>>(queryKey);

        if (previousChats) {
          const updatedChats = produce(previousChats, (draft) => {
            draft.pages.forEach((page) => {
              page.data = page.data.filter(
                (chat) => chat.commentID !== commentId,
              );
              page.total = page.data.length;
            });
          });

          queryClient.setQueryData(queryKey, updatedChats);
        }
      },
    },
  );
};

export const useUpdateChatReactions = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ contentID, action, payload }: UpdateCommentReactionMutationVariables) =>
      makeAPICall(
        UPDATE_CHAT_REACTIONS,
        payload,
        {},
        { commentId: contentID, action },
      ),
    {
      onMutate: ({
        userData,
        payload,
        contentID,
        action,
        selectedMemberId,
      }) => {
        const queryKey = [GET_CHATS, selectedMemberId];

        const previousChats =
          queryClient.getQueryData<InfiniteData<GetChatsResponse>>(queryKey);

        if (previousChats) {
          const findChat = (
            pages: WritableDraft<GetChatsResponse>[],
            id: string,
          ) => {
            let index;
            const chatPage = pages.find((page) => {
              index = page.data.findIndex(
                (comment) => comment.commentID === id,
              );
              return index !== -1;
            });
            if (index !== undefined && chatPage) {
              return chatPage.data[index];
            }

            return null;
          };

          const updatedChats = produce(previousChats, (draft) => {
            const selectedChat = findChat(draft.pages, contentID);

            if (selectedChat) {
              const reactionIndex = selectedChat.reactions.findIndex(
                (reaction) => reaction.name === payload.name,
              );
              // IF API CALL IS SETTING AN EMOTICON
              if (action === UpdateReactionAction.SET) {
                // IF THE SELECTED EMOTICON IS NOT ALREADY PRESENT
                if (reactionIndex < 0) {
                  selectedChat.reactions = [
                    ...selectedChat.reactions,
                    {
                      ...payload,
                      members: [{ ...userData }],
                    },
                  ];
                } else {
                  // IF THE SELECTED EMOTICON IS PRESENT
                  selectedChat.reactions[reactionIndex].members.push({
                    ...userData,
                  });
                }
              } else {
                // IF API CALL IS UNSETTING AN EMOTICON
                const currentReaction = selectedChat.reactions[reactionIndex];
                if (currentReaction.members.length > 1) {
                  // IF EMOTICON HAS OTHER PEOPLE WHO HAVE SELECTED IT
                  currentReaction.members = currentReaction.members.filter(
                    (member) => member.memberID !== userData.memberID,
                  );
                } else {
                  // IF USER IS THE ONLY PERSON WHO HAD SELECTED IT
                  selectedChat.reactions.splice(reactionIndex, 1);
                }
              }
            }
          });

          queryClient.setQueryData(queryKey, updatedChats);
        }
      },
    },
  );
};
