import pluralize from 'pluralize';
import { getFileUploadLimit } from '../../../atomic/molecules/FileCard/utils';
import { OPTION } from '../singleWords';

/* eslint-disable max-len */
export const TAB_HEADER = {
  Dashboard: {
    TEXT: 'Dashboard',
    ARIA_LABEL: 'Dashboard Tab',
  },
  MyFlows: {
    TEXT: 'My Flows',
    ARIA_LABEL: 'My Flows Tab',
  },
  Archived: {
    TEXT: 'Archived',
    ARIA_LABEL: 'Archived Tab',
  },
};

export const FLOWS_LOADER_LABELS = {
  PRIMARY_TEXT: 'Gathering your flows...',
  SECONDARY_TEXT: 'Hold on, we have to make sure',
  TERTIARY_TEXT: 'everything is in order for you.',
};

export const FLOWS_ERROR_LABELS = {
  PRIMARY_TEXT: 'Flows failed to load.',
  SECONDARY_TEXT: 'Try refreshing the page.',
};

export const CUSTOMIZE_FLOWS_TEXT = {
  DESKTOP: 'Want to customize this flow’s content? Go to',
  MOBILE:
    'Want to customize this flow’s content? Edit the flow on your computer browser',
};

export const SCHEDULE_CARD_TEXT = {
  DESKTOP:
    'To help save you time, you’ll set an automated schedule on which to send out your form or survey to participants',
  MOBILE:
    'To help save you time, set an automated schedule to send out your form or survey by editing the flow on your computer browser',
};

/* Flows Dashboard */
export const SHORTCUTS = {
  LABEL: 'Shortcuts',
  EMPTY_SHORTCUTS_TEXT:
    'No shortcuts here...yet. Shortcuts allow you to answer a flow at any time, outside of any schedule.',
  SEE_MORE_LABEL: 'See more',
  COLLAPSE_ALL_LABEL: 'Collapse',
};

/* My Flows */
export const MY_FLOWS = {
  MY_PUBLISHED_FLOWS: 'My Published Flows',
  COLLECTING_ANSWERS_LABEL: 'Collecting Answers',
  COLLECTING_ANSWERS_EMPTY_MESSAGE:
    'You don’t have any flows collecting answers right now. Start running one below!',
  MENU_ITEMS: {
    ALL: 'All',
    FORMS: 'Forms',
    ARCHIVE: 'Archive',
    RUN_NOW: 'Run now',
    MOST_RAN: 'Most ran',
    MESSAGES: 'Messages',
    LEAST_RAN: 'Least ran',
    ON_DEMAND: 'On demand',
    SCHEDULED: 'Scheduled',
    NEWEST_FLOW: 'Newest flow',
    OLDEST_FLOW: 'Oldest flow',
    DOWNLOAD_DATA: 'Download data',
    WITH_SHORTCUT: 'With Shortcut',
    ALPHABETICALLY: 'Alphabetically',
    TRANSFER_OWNER: 'Transfer owner',
    END_OCCURRENCE: 'End occurrence',
    ON_DEMAND_FLOWS: 'On demand flows',
    SCHEDULED_FLOWS: 'Scheduled flows',
    LATEST_RUN_DATE: 'Latest run date',
    OLDEST_RUN_DATE: 'Oldest run date',
    WITHOUT_SHORTCUT: 'Without Shortcut',
    ALL_TRIGGER_TYPES: 'All trigger types',
    MOST_PARTICIPANTS: 'Most Participants',
    LEAST_PARTICIPANTS: 'Least participants',
    SEE_PARTICIPATION_STATS: 'See participation stats',
  },
};

export const FLOWS_STATUS_CARD = {
  MY_FLOWS_NO_FLOWS_CREATED: {
    mainText: 'You haven’t created any flows yet',
    subText:
      // eslint-disable-next-line max-len
      'This is where you’ll find all of the flows you create. You’ll be able to view their status and manage them. But first, browse our templates.Set up a flow for your team, or just to keep track of your own work.',
    buttonText: 'Browse templates',
  },
  DASHBOARD_NO_FLOWS_PRESENT: {
    mainText: 'There’s no flows here!',
    subText:
      // eslint-disable-next-line max-len
      'This is your dashboard for all things flows. We’ll make sure to show you what you have to answer and when. But first, browse our templates. Set up a flow for your team, or just to keep track of your own work.',
    buttonText: 'Browse templates',
  },
  DASHBOARD_ALL_FLOWS_COMPLETED: {
    mainText: 'You finished everything for today!',
    subText:
      // eslint-disable-next-line max-len
      'You’re a flow finishing rock star. Got the stomach for more? Create another flow. Start by browsing our templates here.',
  },
};

export const ARCHIVED_FLOWS = {
  MAIN_TEXT_LABEL: 'Archived Flows',
  ALL_LABEL: 'All',
  MOST_RECENT_LABEL: 'Most Recent',
};

export const FLOWS_END_OCCURRENCE_MODAL = {
  modalHeading: 'End this occurrence',
  modalContentText:
    'If you end this occurrence, it will end before the due date that you set, and participants will no longer be able to answer this flow.',
};

export const FLOW_DATA_CHANGES_MODAL = {
  HEADING: 'Changes to flow data',
  CONTENT_TITLE_LABEL:
    'This change to your flow content will create a new data set for this flow. To track changes to your flow’s data, we will create a new tab in your data download table as soon as the edited flow version has new responses (NOTE: If your flow does not have any responses yet, this will not affect your data). We store data for up to 20 of the latest versions of your data.',
  CONTENT_SUB_TITLE_LABEL: 'Changes that create a new version of your flow',
  CONTENT_CONDITION_LABEL_1: 'Changing a block’s title',
  CONTENT_CONDITION_LABEL_2: 'Changing a block’s type',
  CONTENT_CONDITION_LABEL_3: 'Adding or deleting a block',
  CONTENT_CONDITION_LABEL_4:
    '                Adding, changing, or removing options in the dropdown, multiple\n' +
    '                choice, or scale blocks',
  CONTENT_DATA_DOWNLOAD_TEXT: 'Want to download all current data now?',
  DOWNLOAD_LABEL: 'Download this flow’s data',
};

export const FLOWS_DELETE_FOREVER_MODAL = {
  CANCEL_LABEL: 'Cancel',
  DELETE_FOREVER_LABEL: 'Delete forever',
  MODAL_HEADING: 'Delete this flow forever',
  MODAL_CONTENT_TEXT:
    'You will no longer have any record of this flow within Assembly. You may download the data before you delete if you’d like to reference it at a later date.',
};

export const FLOWS_UNARCHIVE_MODAL = {
  CANCEL_LABEL: 'Cancel',
  UNARCHIVE_LABEL: 'Unarchive',
  MODAL_HEADING: 'This flow is archived',
  MODAL_CONTENT_TEXT:
    ' to view its feed. This will restore all posts to the feed and they will be visible to all viewers. You can archive it again at any time.',
};

export const FLOWS_ARCHIVE_MODAL = {
  CANCEL_LABEL: 'Cancel',
  ARCHIVE_LABEL: 'Archive',
  MODAL_HEADING: 'Archive this flow',
  MODAL_CONTENT_TEXT:
    'you will no longer have access to its feed, and it will not be visible to participants or viewers. You may unarchive it if you make a mistake. You’ll be able to find your archived flows in a folder under your active flow feeds.',
};

export const FLOW_REMINDER_PARTICIPANT_MODAL = {
  CANCEL_LABEL: 'Cancel',
  SEND_REMINDER_LABEL: 'Send reminder',
  MODAL_HEADING: 'Remind participants',
  SEND_A_REMINDER: 'Send a reminder to all',
  THAT_HAVEN_NOT_ANSWERED: 'that haven’t answered the',
  get_not_responded_participants_count: (participantsCount: number) =>
    pluralize('participant', participantsCount, true),
};

export const FLOWS_ARCHIVED_MODAL = {
  CANCEL_LABEL: 'Cancel',
  UNARCHIVE_LABEL: 'Unarchive',
  MODAL_HEADING: 'This flow is archived',
  MODAL_CONTENT_TEXT:
    'Unarchive this flow to view its feed. You can still download the flow data at any time.',
};
export const FLOWS_TO_DO = 'Flows To Do';
export const FLOWS_TO_DO_DESCRIPTION =
  'Flows to do are time sensitive -- check out what you’ve got to do today!';
export const FLOW_OWNER = 'Flow owner';
export const UPDATE_FLOW_OWNER = 'Update flow owner';

export const START_FROM_SCRATCH = 'Start from scratch';
export const VIEW_FEED = 'View Feed';
export const COLLECTING_ANSWERS = 'Collecting answers';
export const OCCURRENCE_CLOSED = 'This occurrence is closed';
export const ANSWER_WITH_SHORTCUT = 'Answer with Shortcut';
export const MORE_OPTIONS = 'more options';
export const SEE_VISIBILITY_LABEL = 'See visibility';
export const VISIBILITY_LABEL = 'Visibility';
export const T0DO = 'To-do';
export const SET_SCHEDULE = 'Set schedule';

export const FLOW_RUN_MODAL = {
  PARTICIPANT_LABEL: 'Participants',
  HEADER: 'Run this flow',
  RUN_NOW_LABEL: 'Run Now',
  DESCRIPTION_LABEL:
    'Review the details of this occurrence. Make sure the due date and participants are still accurate.',
  OCCURRENCE_LABEL: 'Occurrence Due Date',
  OCCURRENCE_DESCRIPTION:
    'Assembly will send out notifications to make sure your participants know when the flow is due. Due date must be between 5 minutes and 4 weeks.',
  PARTICIPANTS_LABEL: 'Participants',
  PARTICIPANTS_DESCRIPTION:
    "If you'd like to make changes to your participants, please contact",
  VISIBILITY_LABEL: 'Visibility',
  ONLY_FLOW_PARTICIPANTS_LABEL: 'Only flow participants',
  MEMBERS_LABEL: 'members',
};

export const ANSWER_NOW_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE = 'Answer now';
export const UNREAD_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE = 'Unreads';
export const FLOWS_LEFT_DRAWER_NAVIGATION_TITLE = 'Flows';
export const FLOWS_FEED_POST_TYPE_TEXT = 'posted in';

export const FLOWS_ACTION_BAR_OPTIONS = {
  VIEW_FEEDS: 'View Feeds',
  SHORTCUTS: 'Shortcuts',
  ANSWER_ANYTIME: 'Answer Anytime',
  NO_FEEDS: '👋 No feeds for you to view... yet.',
  NO_FLOWS_TO_ANSWER:
    '🍃 Nothing here...yet. These will be flows you can answer at any time.',
  FLOWS_TO_DO: 'Flows To Do',
  ADD_FLOW: 'add a new flow.',
  NO_TODOS: '✌️ You have no time-sensitive flows, answer a flow anytime below',
  TRY_SEARCHING_SOMETHING_ELSE: 'Try searching for something else!',
  NO_RESULTS: 'No results for this search',
  SEARCH_RESULTS: 'Search Results',
  ERROR: ' Flow menu failed to load. Please refresh the page to try again.',
  NEED_HELP: 'Need help?',
  NEED_HELP_REDIRECT_URL:
    'https://www.joinassembly.com/articles/how-to-answer-a-flow',
};

export const FLOW_USER_LIST_DROPDOWN_NO_RESULTS =
  'No members found that match your criteria.';

export const FLOW_LEFT_DRAWER_ADD_NEW_FLOWS = 'Add a new flow';
export const FLOWS_LEFT_DRAWER_FEED_ERROR = 'Error loading flow feeds';
export const FLOWS_LEFT_DRAWER_FEED_EMPTY =
  'You aren’t a part of any flows yet';

export const MEMBERS_LEFT_DRAWER_FEED_ERROR = 'Error loading flow feeds';

export const FLOW_LEFT_DRAWER_ADD_TEAMMATES = 'Add teammates';

export const FILE_TYPE = 'File type';
export const DATE_SHARED = 'Date shared';

export const FILTER_BY = 'Filter by';
export const FILTER_BY_ANOTHER_FLOW = 'Filter by another flow...';
export const FILTER_BY_ANOTHER_MEMBER = 'Filter by another member...';
export const FILTER_BY_DIFFERENT_VERSION = 'Filter by a different version...';
export const FILTER_BY_DIFFERENT_BLOCK = 'Filter by a different block...';
export const FILTER_BY_ANOTHER_TYPE = 'Filter by another type...';

export const SELECT_PERSON = 'Select person';
export const SELECT_FLOWS = 'Select flows';
export const SELECT_BLOCKS = 'Select blocks';
export const SELECT_VERSION = 'Select version';
export const SELECT_TYPE = 'Select type';

export const POSTED_BY = 'Posted by';
export const UPLOADED_BY = 'Uploaded by';
export const NOTHING_TO_FILTER_BY = '🍃 Nothing to filter by';
export const APPLY_FILTERS = 'Apply filters';
export const SEARCH_GIFS = 'Search GIFs';
export const NO_GIFS_FOUND = 'No GIFs found.';
export const FLOW_ID = 'Flow ID';
export const RESPONSE_VISIBILITY = 'Response Visibility';
export const LINK_ACCESS_SETTINGS_LABEL = 'Link Access Settings';
export const WHOS_INCLUDED = `Who's included?`;
export const ERROR_PULLING_DATA = 'Error pulling data';
export const ONLY_FLOW_PARTICIPANTS = 'Only flow participants';
export const ONLY_OWNER = 'Owner only ';
export const DOWNLOAD_ALL_RESPONSES = 'Download all responses';
export const DATA_DOWNLOADED_SUCCESSFULLY = 'Data downloaded successfully!';
export const PREPARING_DATA_FOR_DOWNLOAD = 'Preparing data for download';
export const DATA_DOWNLOAD_ERROR =
  'Whoops! An error occurred, please try again.';

export const FLOWS_ADDITIONAL_OPTIONS_END_OCCURRENCE_TEXT =
  'If you end this occurrence, it will end before the due date that you set, and participants will no longer be able to answer it.';

export const FLOWS_ADDITIONAL_OPTIONS_ARCHIVE_FLOW_TEXT_ENABLED =
  'You will no longer have access to this feed, and it will not be visible to participants or viewers. You may unarchive it if you make a mistake.';

export const FLOWS_ADDITIONAL_OPTIONS_ARCHIVE_FLOW_TEXT_DISABLED =
  'If you’d like to archive this flow, you must end its active occurrence first.';

export const CELEBRATE_YOUR_TEAMMATES =
  'Celebrate all of the amazing things your teammates do!';

export const ON_DEMAND = 'On demand';
export const SCHEDULED = 'Scheduled';
export const NO_TRIGGER = 'No trigger';
export const SCHEDULED_OR_ANSWER_ANYTIME = 'Scheduled or answer anytime';
export const ON_DEMAND_OR_ANSWER_ANYTIME = 'On demand or answer anytime';
export const FLOW_NOT_FOUND = 'Flow not found';
export const FLOW_NOT_FOUND_DESCRIPTION =
  'This flow has been deleted or does not exist';

export const FLOW_FEED_HEADER_OPTIONS = {
  EDIT_FLOW: 'Edit flow',
  COPY_FLOW_FEED_LINK: 'Copy flow feed link',
  HIDE_ACTIVITY: 'Hide activity',
  SHOW_ACTIVITY: 'Show activity',
  EDIT_NOTIFICATION_SETTINGS: 'Edit notification settings',
  RUN_FLOW_NOW: 'Run flow now',
  ARCHIVE_FLOW: 'Archive flow',
  REMIND_PARTICIPANTS: 'Remind participants',
  END_OCCURRENCE: 'End occurrence',
  ENTER_FULL_SCREEN: 'Enter full screen',
  DUPLICATE_FLOW: 'Duplicate flow',
  SHARE_AS_TEMPLATE: 'Share flow as template',
  ABOUT_THIS_FLOW: 'About this flow',
  FILTER_FLOW_FEED: 'Filter flow feed',
  REMOVE_FROM_FOLDER: 'Remove from folder',
  SUMMARIZE_FLOW: 'Summarize flow',
};

export const INVITE_MODAL_PLACEHOLDER =
  'Start typing emails here, separated by commas or spaces';

export const SINGLE_INVALID_EMAIL = 'You have added an invalid email.';
export const MULTI_INVALID_EMAILS = 'A few emails are invalid';

export const INVITE_PENDING = 'Sending invites';
export const INVITE_SUCCESS = 'Teammates successfully invited.';
export const REQUEST_INVITE_PENDING = 'Sending invite request';
export const REQUEST_INVITE_SUCCESS = 'Successfully requested invite(s)';

export const INVITE_ERROR = 'Failed to invite teammates. Try again.';
export const REQUEST_INVITE_ERROR =
  'Failed teammates invite request. Try again.';

export const INVITE_ASSEMBLY = 'Invite people to Assembly';
export const INVITE_HELPER_TEXT =
  'Type or copy/paste new emails, separated by commas or spaces.';

export const ENTER_TEAMMATES_EMAIL = 'Enter teammates email';

export const REQUEST_INVITE_TEAMMATES = 'Request admins to invite teammates';

export const COPIED_TO_CLIPBOARD = 'Copied to clipboard';
export const TEMPLATE_LINK_COPIED =
  'Template link copied to clipboard. Paste to share with others.';
export const TEMPLATE_LINK_COPIED_FAILED =
  'Failed to copy template link. Try again.';

export const START_POST = 'Start a post';
export const GET_FLOWS_TODO_NOTIFICATION_TEXT = (todoCount: number) =>
  `You have ${pluralize('flow', todoCount, true)} to do`;
export const FLOW_ACTION_BAR_NOTIFICATION_TEXT = 'Collecting answers ';
export const IS_CLOSED = 'is closed';

export const CHOOSE_AN_OPTION = 'Choose an option.';
export const CHOOSE_OPTIONS = 'Choose options.';
export const getMultipleOptionsHelperText = (num: number) =>
  `Choose ${num} options.`;
export const getRangeHelperText = (min: number, max: number) =>
  `Choose between ${min} and ${max} options.`;
export const getDropdownMaxErrorText = (max: number) =>
  `Select a maximum of ${max} options`;
export const getDropdownMinErrorText = (min: number) =>
  `Select atleast ${min} options`;
export const getDropdownExactErrorText = (exact: number) =>
  `Select ${exact} options`;
export const getMembersText = (memberCount: number) =>
  `${pluralize('member', memberCount, true)}`;
export const SELECT_ATLEAST_ONE_PERSON_WARNING = (
  pluralCurrency: string,
) => `You must select atleast one person to give ${pluralCurrency}
to.`;
export const SELECT_PEOPLE = 'Select people';
export const getMultiplePeopleHelperText = (num: number) =>
  `Select ${num} people.`;
export const getPeopleRangeHelperText = (min: number, max: number) =>
  `Select between ${min} and ${max} people.`;
export const getPeopleMaxErrorText = (max: number) =>
  `Select a maximum of ${max} people`;
export const getPeopleMinErrorText = (min: number) =>
  `Select atleast ${min} people`;
export const getPeopleExactErrorText = (exact: number) =>
  `Select ${exact} people`;
export const getOptionsRangeHelperText = (min: number, max: number) =>
  `Select between ${min} and ${max} options.`;
export const getUnlimitedOptionsHelperText = (count: number) =>
  `Select up to ${count} options.`;
export const getExactOptionsHelperText = (count: number) =>
  `Select exactly ${pluralize(OPTION, count, true)}.`;
export const EMAIL_NOTIFICATION_SETTINGS = {
  flows: {
    notificationType: 'Flows',
    description: 'Notify me about participation and activity',

    celebrateTeamMate: {
      title: 'Give Recognition',
      participation: 'Participation',
      participationDescription: 'Reminders',
      response: 'Response',
      responseDescription: 'Notify me when I’m celebrated',
    },
    otherFlows: {
      groupTitle: 'Participation',
      preferences: {
        whenThisFlowStarts: 'When this flow starts',
        reminder: 'Reminders',
      },
      secondaryGroupTitle: 'Responses',
      whenNewResponseIsPosted: 'Notify me each time a new response is posted',
    },
  },
  recognition: {
    notificationType: 'Recognition',
    description: 'Notify me when I receive recognition',
  },
  tasks: {
    notificationType: 'Tasks',
    description: 'Notify me about new activity on my tasks',
  },
  mentions: {
    notificationType: 'Mentions',
    description: 'Notify me when I am mentioned in a post or comment',
  },
  allowance: {
    notificationType: 'Give recognition reminder',
    description: 'Remind me if I forget to give recognition',
  },
  anniversaryNBirthday: {
    notificationType: 'Birthdays & Anniversaries',
    description: 'Notify me on my birthday and work anniversary',
  },
  comments: {
    notificationType: 'Replies',
    description: 'Notify me of replies on posts I am a part of',
  },
  activityUpdate: {
    notificationType: 'Activity update',
    description: 'Notify me of any fun or interesting activity',
    assemblySummaryLookAhead: {
      id: 'assemblySummaryLookAhead',
      title: 'Monday’s week ahead',
    },
    assemblySummaryLookBack: {
      id: 'assemblySummaryLookBack',
      title: 'Friday’s week in review',
    },
    assemblySummaryLookAheadForMobileApp: {
      id: 'appAssemblySummaryLookAhead',
      title: 'Monday’s week ahead',
    },
    assemblySummaryLookBackForMobileApp: {
      id: 'appAssemblySummaryLookBack',
      title: 'Friday’s week in review',
    },
  },
};

export const SEARCH_PLACEHOLDER_TEXT = 'Search Members';
export const EMAIL_NOTIFICATION_SETTINGS_EMPTY_FLOWS =
  'You are not a participant in any flows yet. Once you are, your settings will be here.';

export const FLOW_FILE_UPLOAD_ERROR_TEXTS = {
  MORE_FILES: 'You can only upload 15 files',
  MAX_FILE_SIZE: 'This file exceeds maximum allowed size of 24 MB',
  MORE_THAN_15_FILES: '15 file limit reached',
  MORE_THAN_25_MB: '25MB limit reached',
};

export const PARTICIPANT_LIST_TEXTS = {
  MEMBERS: 'members',
  VIEWERS: 'Viewers',
  PARTICIPANTS: 'Participants',
  NO_PARTICIPANTS:
    'No participants yet. Select criteria to add participants to your flow.',
  NO_MATCHING_PARTICIPANTS: 'No one currently matches these criteria.',
};

export const ONDEMAND = 'On-demand';
export const REVERT_CHANGES_LABEL = 'Revert Changes';
export const SETUP_FLOWS_HEADER = 'Your account is getting set up!';
export const SETUP_FLOWS_ERROR =
  'SOMETHING WENT WRONG WHILE INITIALIZING FLOWS DURING SETUP.';
export const SETUP_FLOWS_LOADING_INFO = `You’re almost ready to join your team. Hold tight for a minute while
  we load up your flows and get your workspace ready for you. You can leave this window, but please keep the tab open for us.`;
export const SETUP_PRELOADED_FLOWS_LOADING_INFO = `Hold tight for a minute while
  we load up your flows and get your workspace ready for you. You can leave this window, but please keep the tab open for us.`;

export const ERROR_UPLOADING = 'Error uploading';
export const getMaxLimitUploadText = (fileUploadLimit: number) =>
  `Maximum: ${getFileUploadLimit(fileUploadLimit)} or 15 files`;
export const PREVIEW_DISABLE_UPLOAD_TEXT = `Can't upload files in Preview mode`;
export const UPLOAD_FILE = 'Upload File';

export const CLICK_POST_TO_SUBMIT = 'Click Post to submit';
export const PRESS_ENTER = 'Press Enter';
export const PRESS_SHIFT = 'Press Shift';
export const TO_PROCEED = 'to proceed';
export const FOR_LINE_BREAK = 'for line break';
export const RESTART_FLOW = 'Restart flow';

export const FLOW_OWNER_ENDED_INFO =
  'Flow schedule ended. Click restart to reactivate';
export const FLOW_OWNER_SHORTCUT_ENDED_INFO =
  'This flow can still be answered anytime, outside of the ended schedule.';
export const FLOW_ENDED_INFO =
  'Flow schedule ended. This flow will run again when the flow owner restarts it.';
export const FLOW_SHORTCUT_ENDED_INFO =
  'Flow schedule ended. The flow will run again when the flow owner restarts it. You can still answer this flow anytime in the meantime.';

export const ARCHIVE_IT = 'archive it';

export const ASSEMBLY_TEMPLATE_DESCRIPTION = `The team at Assembly is committed to engaging and empowering employees
to do extraordinary things by providing you with best-in-class
templates for work related functions.`;

export const MIN_OPTION_ERROR = 'You must add at least 1 option.';

export const INVITE_MEMBER_HELPER_TEXT =
  'To invite new members to your Assembly for this flow, type or copy and paste their emails, separated by commas.';

export const INVITE_MEMBER_EMAIL_VALIDATION_ERROR =
  'This is not a valid email, please correct this to continue.';

export const GET_HELP = 'Get help';
export const COPY_LINK = 'Copy link';
export const COPY_LINK_TO_SHARE = 'Copy link to share';

export const EXTERNAL_FLOWS_COMPLETION = {
  HEADING: 'Woohoo! Thanks for responding 🙌 ',
  SUB_HEADING:
    'Interested in surveys, engaging your team, or running better meetings?',
  ALREADY_MEMBER: 'Already a member of an Assembly workspace?',
};

export const POWERED_BY_ASSEMBLY = 'Powered by Assembly 💪';

export const FLOW_SUMMARIZATION = {
  ASK_ADMIN: 'Please ask your workspace admin to upgrade your plan for access.',
  NO_POSTS:
    'DoraAI will summarize the content of posts in this flow. Once you have posts to summarize, you can use this feature!',
  PREMIUM: 'This is a premium feature. Please',
  UPGRADE_LINK: 'upgrade your plan',
  UPGRADE_TOOLTIP: 'Summarize this flow with DoraAI',
  USE_FEATURE: 'to use this feature.',
  CANNOT_SUMMARIZE: `DoraAI can't summarize this flow! Try DoraAI on a flow with open ended answers.`,
};
