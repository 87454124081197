import { AxiosResponse } from 'axios';
import { EmojiData } from 'emoji-mart';
import { useCallback } from 'react';
import { InfiniteData, UseMutateFunction } from '@tanstack/react-query';
import {
  FeedItemMetaData,
  FlowPostReactionMutationPayload,
  GetFeedResponse,
  Reaction,
  ReactionMutationPayload,
  SinglePostResponse,
  UpdateReactionAction,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import {
  FlowFeedResponse,
  ReactionRequestPayload,
} from '../../queries/Flows/Feed/interfaces';
import { PaginationResponse } from '../../queries/Flows/interfaces';

import { GetProfileInfoResponse } from '../../queries/Profile';
import { mapEmojiToReaction } from '../../Utils/flows/feeds';
import {
  doesPostReactionsHasReactionFromSameUser,
  hasReactionFromSameUser,
} from '../../Utils/home/feeds/reactions';
import { serializeReactionTogglePayload } from '../../Utils/serializers/feed';

type UseFeedReactionsProps = {
  profileData?: GetProfileInfoResponse;
  mutateFlowPostReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    FlowPostReactionMutationPayload,
    {
      previousData: InfiniteData<GetFeedResponse> | undefined;
    }
  >;
  mutatePostReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionMutationPayload,
    {
      previousData:
        | InfiniteData<GetFeedResponse>
        | SinglePostResponse
        | undefined;
    }
  >;
  mutateResponseReaction?: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    ReactionRequestPayload,
    {
      previousData:
        | InfiniteData<PaginationResponse<FlowFeedResponse>>
        | FlowFeedResponse;
    }
  >;
};

export const useFeedReactions = (props: UseFeedReactionsProps) => {
  const {
    profileData,
    mutateFlowPostReaction,
    mutatePostReaction,
    mutateResponseReaction,
  } = props;

  const onReactionSet = useCallback(
    (
      emoji: EmojiData,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
      reactions: Reaction[],
    ) => {
      if (profileData?.member) {
        if (
          doesPostReactionsHasReactionFromSameUser(
            reactions,
            emoji,
            profileData,
          )
        ) {
          return;
        }
        if (
          feedItemMetaData.type === PostTypes.FLOW &&
          mutateFlowPostReaction
        ) {
          mutateFlowPostReaction({
            flowId: feedItemMetaData.flowId || '',
            responseId: feedItemMetaData.responseId || '',
            ...serializeReactionTogglePayload(
              mapEmojiToReaction(emoji),
              feedItemMetaData.postId,
              UpdateReactionAction.SET,
              profileData?.member,
            ),
          });
        } else if (mutatePostReaction) {
          mutatePostReaction(
            serializeReactionTogglePayload(
              mapEmojiToReaction(emoji),
              contentId,
              UpdateReactionAction.SET,
              profileData?.member,
            ),
          );
        } else if (mutateResponseReaction) {
          mutateResponseReaction({
            responseId: contentId,
            action: 'set',
            payload: mapEmojiToReaction(emoji),
            user: {
              ...profileData?.member.profile,
              memberID: profileData?.member.memberId,
            },
          });
        } else {
          return;
        }
      }
    },
    [
      profileData,
      mutateFlowPostReaction,
      mutatePostReaction,
      mutateResponseReaction,
    ],
  );

  const onReactionUnSet = useCallback(
    (
      reaction: Reaction,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      let action = UpdateReactionAction.UNSET;

      if (profileData?.member) {
        if (!hasReactionFromSameUser(reaction, profileData)) {
          action = UpdateReactionAction.SET;
        }
        if (
          feedItemMetaData.type === PostTypes.FLOW &&
          mutateFlowPostReaction
        ) {
          mutateFlowPostReaction({
            flowId: feedItemMetaData.flowId || '',
            responseId: feedItemMetaData.responseId || '',
            ...serializeReactionTogglePayload(
              reaction.reaction,
              feedItemMetaData.postId,
              action,
              profileData?.member,
            ),
          });
        } else if (mutatePostReaction) {
          mutatePostReaction(
            serializeReactionTogglePayload(
              reaction.reaction,
              contentId,
              action,
              profileData?.member,
            ),
          );
        } else if (mutateResponseReaction) {
          mutateResponseReaction({
            action,
            responseId: contentId,
            payload: reaction.reaction,
            user: {
              ...profileData?.member.profile,
              memberID: profileData?.member.memberId,
            },
          });
        } else {
          return;
        }
      }
    },
    [
      mutateFlowPostReaction,
      mutatePostReaction,
      mutateResponseReaction,
      profileData,
    ],
  );

  return {
    onReactionSet,
    onReactionUnSet,
  };
};
