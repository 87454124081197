import ContentLoader from 'react-content-loader';
import React from 'react';

import ThemeV2 from '../../../theme';

const AutocompleteOptionLoader = () => (
  <ContentLoader
    speed={2}
    width={292}
    height={32}
    viewBox="0 0 292 32"
    backgroundColor={ThemeV2.palette.gray4}
    foregroundColor={ThemeV2.palette.gray3}
    uniqueKey="autocomplete-loader"
  >
    <circle cx="24" cy="16" r="12" />
    <rect x="44" y="8" rx="4" width="182" height="16" />
  </ContentLoader>
);

export default AutocompleteOptionLoader;
