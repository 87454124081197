import { getProfileFullName, getTaggedUsersFromMessage } from '../text';
import { getAgoTime } from '../time';
import {
  Notification,
  NotificationTypes,
  FormattedNotifications,
  FromUserStates,
  ResponseId,
  Comment,
  CommentTypes,
} from '../../interfaces/Notifications';
import { PostTypes } from '../../interfaces/Home';
import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import {
  getAnniversaryMessage,
  getBirthdayMessage,
  getDeletedUserAddedAndCommented,
  getDeletedUserGave,
  getUserAddedAndCommented,
  getUserCommented,
  getUserGave,
  getUserMentionInComment,
  getUserRecognizedInPost,
} from '../languages/notifications';
import {
  DEACTIVATED_USER,
  DEACTIVATED_USER_COMMENTED,
  DEACTIVATED_USER_MENTIONED_IN_COMMENT,
  DEACTIVATED_USER_MENTIONED_IN_POST,
  getBodyTextForDeactivatedOrAnonymousMentions,
} from '../../languages/en/notifications';
import { AssemblyCurrency } from '../../interfaces/assembly';
import { mapHexCodeToEmoticon } from '../mappers';
import { CELEBRATE_TEAM_MEMBER } from '../../languages/en/feed';
import { FeedItemKind } from '../../interfaces/Feed';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import { TaskHistoryActivityTypes } from '../../atomic/molecules/Notebook/TaskHistory/interface';

const getHowManyCarrotsString = (
  carrots: number,
  name: string,
  pluralName: string,
) => `${carrots} ${carrots === 1 ? name : pluralName}`;

// if kind = internal_anonymous or external_anonymous and comment object exists, show the person's name
// else if kind = internal_anonymous or external_anonymous , show anonymous tag
const checkAnonymous = (responseId?: string | ResponseId, comment?: Comment) =>
  typeof responseId !== 'string' &&
  (responseId?.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
    responseId?.kind === FeedItemKind.EXTERNAL_ANONYMOUS) &&
  isEmpty(comment);

const getNotificationTexts = (
  n: Notification,
  { name, pluralName }: AssemblyCurrency,
) => {
  let headerText = '';
  let bodyText = '';

  const {
    post,
    type,
    carrots,
    from,
    comment,
    flowDetails,
    responseId,
    metaData,
    taskActivityDetails,
    shareRequestDetails,
    flowShareRequestDetails,
  } = n;
  const isAnonymous = checkAnonymous(responseId, comment);
  const shouldShowDeactivatedFromUserTag =
    from?.state !== FromUserStates.ACTIVE || isAnonymous;
  const getFlowNameFromFlowDetails = () => {
    let flowName;
    if (flowDetails) {
      const { flow } = flowDetails;
      if (flow) {
        if (flow.icon) {
          const emoticon = mapHexCodeToEmoticon(flow.icon.value);
          flowName = `${emoticon} ${flow.name}`;
        } else {
          flowName = `${flow.name}`;
        }
      }
    }
    return flowName || '';
  };

  if (type === NotificationTypes.POST) {
    if (post?.type === PostTypes.BIRTHDAY) {
      bodyText = getBirthdayMessage(getProfileFullName(n.user));
    } else if (post?.type === PostTypes.ANNIVERSARY) {
      bodyText = getAnniversaryMessage(getProfileFullName(n.user));
    } else if (n.carrots === 0) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_MENTIONED_IN_POST
        : getUserRecognizedInPost(getProfileFullName(from));
    } else {
      const howMuch = getHowManyCarrotsString(carrots, name, pluralName);
      bodyText = shouldShowDeactivatedFromUserTag
        ? getDeletedUserGave(howMuch, isAnonymous)
        : getUserGave(getProfileFullName(from), howMuch);
    }
    if (post) {
      const body = getTaggedUsersFromMessage(post.message, post.taggedUsers);
      headerText = body.length > 60 ? `${body.substring(0, 60 - 3)}...` : body;
    }
  }
  if (
    (type === NotificationTypes.COMMENT ||
      type === NotificationTypes.TAGGED_IN_COMMENT) &&
    comment
  ) {
    const commentHasCarrots = comment.carrots > 0;
    if (type === NotificationTypes.TAGGED_IN_COMMENT) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_MENTIONED_IN_COMMENT
        : getUserMentionInComment(getProfileFullName(from));
    } else if (commentHasCarrots) {
      const howMuch = getHowManyCarrotsString(
        comment.carrots,
        name,
        pluralName,
      );
      bodyText = shouldShowDeactivatedFromUserTag
        ? getDeletedUserAddedAndCommented(howMuch)
        : getUserAddedAndCommented(getProfileFullName(from), howMuch);
    } else if (flowDetails) {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_COMMENTED
        : getProfileFullName(from);
    } else {
      bodyText = shouldShowDeactivatedFromUserTag
        ? DEACTIVATED_USER_COMMENTED
        : getUserCommented(getProfileFullName(from));
    }
    const textLength = commentHasCarrots ? 40 : 60;
    const body = getTaggedUsersFromMessage(
      comment.message,
      comment.taggedUsers,
      true,
    );

    if (flowDetails) {
      headerText = getFlowNameFromFlowDetails();
    } else {
      headerText =
        body.length > textLength
          ? `${body.substring(0, textLength - 3)}...`
          : body;
    }

    if (metaData?.commentType === CommentTypes.THREAD_COMMENT) {
      bodyText = getProfileFullName(from);
      if (responseId) {
        headerText = getFlowNameFromFlowDetails();
      } else {
        headerText = '🎉 Give Recognition';
      }
    }
  }

  if (
    type === NotificationTypes.MENTIONS ||
    type === NotificationTypes.TAGGED_IN_POST
  ) {
    bodyText = shouldShowDeactivatedFromUserTag
      ? getBodyTextForDeactivatedOrAnonymousMentions(isAnonymous)
      : getProfileFullName(from);
  }

  if (
    type === NotificationTypes.FLOW_TRIGGERED ||
    type === NotificationTypes.FLOW_REMINDER ||
    type === NotificationTypes.MENTIONS
  ) {
    headerText = getFlowNameFromFlowDetails();
    if (post && comment) {
      headerText = `🎉 ${CELEBRATE_TEAM_MEMBER}`;
    }

    if (post && !comment) {
      if (n.carrots === 0) {
        bodyText = shouldShowDeactivatedFromUserTag
          ? DEACTIVATED_USER_MENTIONED_IN_POST
          : getUserRecognizedInPost(getProfileFullName(from));
      } else {
        const howMuch = getHowManyCarrotsString(carrots, name, pluralName);
        bodyText = shouldShowDeactivatedFromUserTag
          ? getDeletedUserGave(howMuch, isAnonymous)
          : getUserGave(getProfileFullName(from), howMuch);
      }

      const body = getTaggedUsersFromMessage(post.message, post.taggedUsers);
      headerText = body.length > 60 ? `${body.substring(0, 60 - 3)}...` : body;
    }
  }

  if (type === NotificationTypes.TAGGED_IN_POST) {
    headerText = `🎉 ${CELEBRATE_TEAM_MEMBER}`;
  }

  if (type === NotificationTypes.FLOW_RESPONSE) {
    if (metaData) {
      const latestRespondent = from ? getProfileFullName(from) : 'Anonymous';
      const multipleUsersRespondedText = `${latestRespondent} and ${pluralize(
        'other',
        metaData.unreadCount - 1,
        true,
      )}`;
      bodyText =
        metaData.unreadCount === 1
          ? latestRespondent
          : multipleUsersRespondedText;
    }
    headerText = `posted in ${getFlowNameFromFlowDetails()}`;
  }

  if (
    type === NotificationTypes.TASK_ACTIVITY &&
    taskActivityDetails?.taskActivity
  ) {
    if (!taskActivityDetails) {
      return [];
    }

    const { activity, content, notebookId } = taskActivityDetails.taskActivity;

    const title = notebookId.title.find((token) => token.type === 'text');
    const state = content.after?.other?.toLowerCase();

    bodyText = shouldShowDeactivatedFromUserTag
      ? DEACTIVATED_USER
      : getProfileFullName(from);

    headerText =
      activity === TaskHistoryActivityTypes.ASSIGNMENT
        ? `assigned a task to you : "${title?.value}"`
        : `${state} a task you assigned them : "${title?.value}"`;
  }
  if (
    type === NotificationTypes.SHARE_REQUEST_CREATED &&
    flowShareRequestDetails &&
    flowShareRequestDetails.requestedForMembers
  ) {
    const requestedUsersCount =
      flowShareRequestDetails.requestedForMembers.length;
    const initialRequestedMember =
      flowShareRequestDetails.requestedForMembers[0];
    const isRequestingForSameUser =
      requestedUsersCount === 1 && initialRequestedMember._id === from?._id;
    const fullName = getProfileFullName(initialRequestedMember);
    const getRequestedUsersText = () => {
      if (requestedUsersCount === 1) {
        return fullName;
      } else if (requestedUsersCount === 2) {
        return `${fullName} and 1 other`;
      } else {
        return `${fullName}, and ${requestedUsersCount - 1} others`;
      }
    };
    bodyText = getProfileFullName(flowShareRequestDetails.requestedBy);
    headerText = isRequestingForSameUser
      ? `requested to join ${getFlowNameFromFlowDetails()}`
      : `requested to share ${getFlowNameFromFlowDetails()} with ${getRequestedUsersText()}`;
  }

  if (
    type === NotificationTypes.SHARE_REQUEST_APPROVED &&
    shareRequestDetails
  ) {
    const { requestPermission } =
      shareRequestDetails.shareRequestId.stateInfo.accepted;
    const getRequestRoleText = () => {
      const requestRoleMap = {
        VIEWER: 'view the posts in',
        PARTICIPANT: 'post in',
        PARTICIPANT_AND_VIEWER: 'post and view the posts in',
      };
      return requestRoleMap[requestPermission] || 'post in';
    };
    headerText = `You can now ${getRequestRoleText()} ${getFlowNameFromFlowDetails()}`;
  }

  return [headerText, bodyText];
};

type SerializeNotificationsType = (
  notifications: Notification[],
  currency: AssemblyCurrency,
) => FormattedNotifications[];

export const serializeNotifications: SerializeNotificationsType = (
  notifications,
  currency,
) =>
  notifications.map((notification) => {
    const [bodyText, headerText] = getNotificationTexts(notification, currency);
    let icon;
    const {
      _id: id,
      from,
      createdAt,
      isRead,
      post,
      flowDetails,
      type,
      responseId,
      comment,
      taskActivityDetails,
      metaData,
    } = notification;
    const postType = post?.type;
    const postID = post?._id;
    let emoticon = '';
    let isAnonymous = false;

    if (flowDetails) {
      const { flow } = flowDetails;
      if (flow && flow.icon) {
        emoticon = mapHexCodeToEmoticon(flow.icon.value);
      }
    }

    if (postType === PostTypes.ANNIVERSARY) {
      icon = 'anniversary';
    } else if (postType === PostTypes.BIRTHDAY) {
      icon = 'birthday';
    }

    if (
      (type === NotificationTypes.COMMENT ||
        type === NotificationTypes.MENTIONS ||
        type === NotificationTypes.FLOW_RESPONSE) &&
      typeof responseId !== 'string'
    ) {
      isAnonymous = checkAnonymous(responseId, comment);
    }

    const avatarImg =
      from?.profile?.image?.resized?.relativeUrl ||
      from?.profile?.image?.original?.relativeUrl ||
      '';

    return {
      read: isRead,
      timeAgo: getAgoTime(createdAt),
      headerText,
      bodyText,
      id,
      avatar: {
        img: avatarImg,
        isDeleted: from?.state !== FromUserStates.ACTIVE && !isAnonymous,
        name: getProfileFullName(from),
        userId: from?._id || '',
        ...(isAnonymous && {
          iconSize: '40px',
        }),
        isAnonymous,
      } as AvatarProps,
      icon,
      postID: postID || '',
      flowId: flowDetails?.flow._id,
      occurrenceId: flowDetails?.occurrence,
      emoticon,
      type,
      responseId,
      commentId: comment?._id || '',
      isAnonymous,
      taskId: taskActivityDetails?.taskActivity?.notebookId?._id,
      metaData: metaData,
    };
  });
