import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  THERES_NO_REWARDS,
  CULTURE_REWARDS_PROVIDE_YOU,
  TAKE_ME_TO_ADMIN_CULTURE_REWARDS,
  REWARDS_PER_PAGE,
  COULD_NOT_FIND_ANY_REWARDS,
  TRY_DIFFERENT_SEARCH,
  PREVIOUS_PAGE,
  NEXT_PAGE,
} from '../../../languages/en/rewards';
import {
  REWARDS_PER_PAGE_VALUES,
  SortByQueryParams,
} from '../../../Utils/data/rewards';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useCultureControllerModel from './useCultureControllerModel';
import useCultureControllerLogic from './useCultureControllerLogic';
import useLayoutStore from '../../../stores/layoutStore';
import {
  BackgroundSizeTypes,
  RewardTypes,
} from '../../../atomic/molecules/RewardCard/interface';
import { CultureReward } from '../../../queries/Rewards/interfaces';
import {
  StyledRewardsWrapper,
  StyledRewardsLoaderWrapper,
  RewardsFooter,
} from '../../../atomic/organism/RouteTabs/styles';
import RewardsHeader from '../../../atomic/molecules/RewardsHeader';
import RewardsEmptyState from '../../../atomic/molecules/RewardsEmptyState';
import RewardCard from '../../../atomic/molecules/RewardCard';
import RewardCardLoader from '../../../atomic/molecules/RewardCard/Loader';
import CultureRedeemModalController from '../CultureRedeemModalController';
import PaginationComponent from '../../../atomic/molecules/PaginationComponent';
import { ADMIN_CULTURES_REWARDS, V2_HOME } from '../../../constants/routes';
import rewardsNotFoundImage from '../../../img/searching.svg';
import cultureRewardPlaceholder from '../../../img/culture-reward-placeholder.svg';

import { Flex } from '../../../Utils/styles/display';
import usePageNavigationLogic from '../usePageNavigationLogic';
import {
  StyledNavigateNextButton,
  StyledNavigatePreviousButton,
} from '../styles';
import { REWARD_IMAGE_RATIO } from '../../../constants/rewards';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import RewardNotFoundModal from '../../../atomic/molecules/RewardNotFoundModal';

export interface CultureControllerProp {
  isAdmin: boolean;
  isCultureRewardsEnabled?: boolean;
}

const CultureController = (props: CultureControllerProp) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const history = useHistoryWrapper();
  const { isAdmin, isCultureRewardsEnabled } = props;
  const [sortParams, setSortParams] = useState({
    sort: SortByQueryParams.ALPHABETICALLY,
  });

  const { rewardsData, isLoading, isError, refetch } =
    useCultureControllerModel(sortParams);
  const { data: profileData } = useProfileInfoFetchQuery();
  const { isLeftAsideOpen, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  const onSortChange = useCallback(
    (value = SortByQueryParams.ALPHABETICALLY) => {
      setSortParams({ sort: value });
    },
    [],
  );

  useEffect(() => {
    refetch();
  }, [refetch, sortParams]);

  const {
    searchProps,
    filterProps,
    sortProps,
    rewardsToDisplay,
    isModalOpen,
    selectedReward,
    handleOnRewardSelect,
    onCloseRedeemModal,
    itemsPerPage,
    setItemsPerPage,
    pageNumber,
    setPageNumber,
  } = useCultureControllerLogic({
    rewards: (rewardsData && rewardsData.rewards) || [],
    onSortChange,
  });

  const onHandlePaginationChange = useCallback((num) => {
    setPageNumber(num);
  }, []);

  const rewardsPageNavigationProps = {
    pageNumber,
    itemCount: rewardsToDisplay.length,
    itemsPerPage,
    onHandlePaginationChange,
  };
  const { models, operations } = usePageNavigationLogic(
    rewardsPageNavigationProps,
  );
  const { numberOfPages } = models;
  const { onPreviousButtonClick, onNextButtonClick } = operations;

  const currentRewards = useMemo(() => {
    if (rewardsToDisplay.length > 0) {
      const activeRewards = rewardsToDisplay.filter(
        (reward) => reward.isActive,
      );
      return activeRewards.slice(
        (pageNumber - 1) * itemsPerPage,
        (pageNumber - 1) * itemsPerPage + itemsPerPage,
      );
    }

    return [];
  }, [itemsPerPage, pageNumber, rewardsToDisplay]);

  const hasCultureRewardsSetup = useMemo(() => {
    if (rewardsData && rewardsData.rewards) {
      const activeRewards = rewardsData.rewards.filter(
        (reward) => reward.isActive,
      );
      return activeRewards.length > 0;
    }

    return false;
  }, [rewardsData]);

  if (isError) return <div>Error...</div>;

  // Redirect to home
  if (!isAdmin && !isCultureRewardsEnabled) {
    history.push(V2_HOME);
  }

  // Show upgrade link
  if (isAdmin && !isCultureRewardsEnabled) {
    return (
      <RewardsEmptyState
        title={THERES_NO_REWARDS}
        message={CULTURE_REWARDS_PROVIDE_YOU}
        hasUpgradeLink
        hasActionButton={false}
      />
    );
  }

  // Show setup link
  if (
    isAdmin &&
    isCultureRewardsEnabled &&
    !hasCultureRewardsSetup &&
    !isLoading
  ) {
    return (
      <RewardsEmptyState
        title={THERES_NO_REWARDS}
        message={CULTURE_REWARDS_PROVIDE_YOU}
        actionButtonText={TAKE_ME_TO_ADMIN_CULTURE_REWARDS}
        actionButtonLink={ADMIN_CULTURES_REWARDS}
      />
    );
  }

  return (
    <>
      <RewardsHeader
        search={searchProps}
        filter={filterProps}
        sort={sortProps}
        isLeftAsideOpen={isLeftAsideOpen}
        isRightAsideOpen={isRightAsideOpen}
      />
      {isLoading && (
        <StyledRewardsLoaderWrapper>
          <RewardCardLoader rewardType={RewardTypes.Culture} />
        </StyledRewardsLoaderWrapper>
      )}
      {!isLoading && isCultureRewardsEnabled && rewardsData && profileData && (
        <>
          {currentRewards.length === 0 && (
            <RewardsEmptyState
              title={COULD_NOT_FIND_ANY_REWARDS}
              message={TRY_DIFFERENT_SEARCH}
              hasActionButton={false}
              image={rewardsNotFoundImage}
            />
          )}
          <StyledRewardsWrapper
            isLeftAsideOpen={isLeftAsideOpen}
            isRightAsideOpen={isRightAsideOpen}
          >
            {currentRewards.map((reward: CultureReward) => (
              <RewardCard
                id={reward._id}
                key={reward._id}
                rewardType={RewardTypes.Culture}
                image={
                  reward.image?.original?.relativeUrl ||
                  cultureRewardPlaceholder
                }
                backgroundSize={
                  (!reward.image && BackgroundSizeTypes.Cover) ||
                  (reward.image &&
                    reward.image.meta &&
                    reward.image.meta.ratio === REWARD_IMAGE_RATIO)
                    ? BackgroundSizeTypes.Cover
                    : BackgroundSizeTypes.Square
                }
                title={reward.name}
                assemblyCurrency={profileData.assembly.currency}
                cost={reward.pointsToRedeem}
                description={reward.description}
                onRewardSelect={handleOnRewardSelect}
                rewardsCurrentAvailability={reward.maxLimit}
                disabled={
                  reward.quantity === 0 ||
                  profileData.member.pointsEarned < reward.pointsToRedeem
                }
                isLimit={reward.isLimit}
              />
            ))}
          </StyledRewardsWrapper>
          <div>
            {numberOfPages > 1 && (
              <Flex justifyContent="center">
                <StyledNavigatePreviousButton
                  onClick={onPreviousButtonClick}
                  disabled={pageNumber === 1}
                  icon="arrow-left"
                >
                  {PREVIOUS_PAGE}
                </StyledNavigatePreviousButton>
                <StyledNavigateNextButton
                  onClick={onNextButtonClick}
                  disabled={pageNumber === numberOfPages}
                  icon="arrow-right"
                  isEndIcon
                >
                  {NEXT_PAGE}
                </StyledNavigateNextButton>
              </Flex>
            )}
            {currentRewards.length > 0 && (
              <RewardsFooter
                isLeftAsideOpen={isLeftAsideOpen}
                isRightAsideOpen={isRightAsideOpen}
                isEmbeddedInMainApp={isEmbeddedInMainApp}
              >
                <PaginationComponent
                  dropdownLabelText={REWARDS_PER_PAGE}
                  itemCount={rewardsToDisplay.length}
                  itemsPerPage={itemsPerPage}
                  itemsPerPageValues={REWARDS_PER_PAGE_VALUES}
                  onItemsPerPageValueChange={setItemsPerPage}
                  onHandlePaginationChange={onHandlePaginationChange}
                  pageNumber={pageNumber}
                  hasRightSpacing
                />
              </RewardsFooter>
            )}
          </div>
          {isModalOpen && !selectedReward && (
            <RewardNotFoundModal
              isModalOpen={isModalOpen}
              onModalClose={onCloseRedeemModal}
            />
          )}
          {isModalOpen && selectedReward && (
            <CultureRedeemModalController
              isModalOpen={isModalOpen}
              reward={selectedReward}
              profileEmail={profileData.member.email}
              assemblyCurrency={profileData.assembly.currency}
              onCloseRedeemModal={onCloseRedeemModal}
            />
          )}
        </>
      )}
    </>
  );
};

export default CultureController;
