import Body from '../../../../../atomic/atoms/Body';
import Dropdown from '../../../../../atomic/molecules/Dropdown_V2';
import {
  DropdownPlacement,
  DropdownSizes,
  DropdownVariants,
} from '../../../../../atomic/molecules/Dropdown_V2/interfaces';
import { SHARE_SHEET_RULES } from '../../../../../languages/en/flows/builder';
import { ShareSheetRuleAccessTypes } from '../../types';
import {
  AdvancedFreeShareSheetController,
  AdvancedPaidShareSheetController,
} from './AdvancedShareSheetController';
import { dropdownOptions, shareSheetDropdownItems } from '../data';
import { SimplifiedShareSheetController } from './SimplifiedShareSheetController';
import Button from '../../../../../atomic/atoms/Button';
import Theme from '../../../../../theme';
import SVGIcon from '../../../../../atomic/atoms/SVGIcon';
import { Flex } from '../../../../../Utils/styles/display';
import { AssemblyPlanType } from '../../../../../interfaces/UserProfile';
import { useCurrentPlanFetchQuery } from '../../../../../queries/Profile';
import styled from 'styled-components';

const PreviewButton = styled(Button)`
  height: 32px;
`;

export const ShareSheetRulesController = ({
  visibilityTitleContent,
  subtitleContent,
  handleChooseParticipants,
  handleChooseViewers,
  shareSheetAccessType,
  isSimplifiedShareSheetTreatmentOn,
  handleShareSheetAccessTypeChange,
  handleOnModifiedChanges,
  handleMembersToBeInvited,
  handlePreviewButtonClick,
  handleSelectedTabIndexChange,
}: {
  handleChooseParticipants: () => void;
  handleChooseViewers: () => void;
  visibilityTitleContent?: string;
  subtitleContent?: string;
  shareSheetAccessType: ShareSheetRuleAccessTypes;
  handleShareSheetAccessTypeChange: (
    accessType: ShareSheetRuleAccessTypes,
  ) => void;
  isSimplifiedShareSheetTreatmentOn: boolean;
  handleOnModifiedChanges: (hasChanged: boolean) => void;
  handleMembersToBeInvited: (members: string[]) => void;
  handlePreviewButtonClick: () => void;
  handleSelectedTabIndexChange: (index: number) => void;
}) => {
  const { data: currentPlanData } = useCurrentPlanFetchQuery(
    isSimplifiedShareSheetTreatmentOn,
  );

  // If the FF is on, we put advanced share sheet behind a paywall
  // else, it should show up as expected
  const isPremiumUser = isSimplifiedShareSheetTreatmentOn
    ? currentPlanData &&
      currentPlanData.name !== AssemblyPlanType.FREE &&
      currentPlanData.name !== AssemblyPlanType.STARTER
    : true;

  return (
    <>
      {isSimplifiedShareSheetTreatmentOn && (
        <>
          <Body variant="body1Bold">{SHARE_SHEET_RULES.HEADING}</Body>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Body variant="body2" margin="4px 0">
              {SHARE_SHEET_RULES.SUBHEADING}
              <Dropdown
                value={shareSheetAccessType}
                size={DropdownSizes.Small}
                variant={DropdownVariants.Text}
                enableCaret
                hasBoxShadow={false}
                menuItems={dropdownOptions}
                onItemClick={(item) => {
                  handleShareSheetAccessTypeChange(
                    item as ShareSheetRuleAccessTypes,
                  );
                }}
                getItemLabel={(value: string) => {
                  const currentItem = shareSheetDropdownItems.find(
                    (item) => item?.id === value,
                  );
                  return currentItem ? currentItem?.value : value;
                }}
                customCaretIcon="carat-down"
                isUnstyled
                font="body2Medium"
                padding="0 8px"
                dropdownPlacement={DropdownPlacement.BottomStart}
              />
            </Body>
            <PreviewButton onClick={handlePreviewButtonClick} variant="text">
              <Flex>
                <SVGIcon
                  icon="eye"
                  size="16px"
                  color={Theme.palette.geekBlue6}
                />
                <Body
                  variant="body2"
                  margin="0 0 0 8px"
                  color={Theme.palette.geekBlue6}
                >
                  Preview list
                </Body>
              </Flex>
            </PreviewButton>
          </Flex>
        </>
      )}
      {shareSheetAccessType === ShareSheetRuleAccessTypes.Simple && (
        <SimplifiedShareSheetController
          handleOnModifiedChanges={handleOnModifiedChanges}
          handleMembersToBeInvited={handleMembersToBeInvited}
          handleSelectedTabIndexChange={handleSelectedTabIndexChange}
        />
      )}
      {shareSheetAccessType === ShareSheetRuleAccessTypes.Advance &&
        (isPremiumUser ? (
          <AdvancedPaidShareSheetController
            handleChooseParticipants={handleChooseParticipants}
            handleChooseViewers={handleChooseViewers}
            visibilityTitleContent={visibilityTitleContent}
            subtitleContent={subtitleContent}
            isSimplifiedShareSheetTreatmentOn={
              isSimplifiedShareSheetTreatmentOn
            }
          />
        ) : (
          <AdvancedFreeShareSheetController />
        ))}
    </>
  );
};
