export const WORKING_ON_SUPPORTING =
  'We’re working on supporting all file types. In the meantime, download this file.';

export const UNABLE_TO_PREVIEW = 'Unable to preview file';

export const PREVIEW_NOT_AVAILABLE = 'Preview not available';

export const FOR = 'for';

export const FILES = 'files';

export const DOWNLOAD_TO_VIEW = 'Please download this file to view';
export const FILE_TOO_BIG = 'This file is too big to preview';
export const PREVIEW_PAGE_LIMIT =
  'Previews limited to 25 slides. Want to see every slide?';

export const REFRESH_PAGE = 'Please refresh your page to try again.';
export const RETRY = 'Retry';
export const SOMETHING_WENT_WRONG = 'Oops! It looks like something went wrong.';
