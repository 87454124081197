import styled from 'styled-components';

import Body from '../../../atomic/atoms/Body';

import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import ShareSheetContainerModal from '../../../atomic/organism/ShareSheetContainerModal';

export const StyledShareSheetBasicModalTemplate = styled(
  ShareSheetContainerModal,
)<{
  overflow?: 'hidden' | 'auto' | 'none' | 'inherit';
}>`
  max-width: 640px;
  transition: height 1s ease-in-out;

  .button-wrapper {
    button {
      width: auto;
    }
  }
  .modal-childWrapper {
    ${({ overflow }) => overflow && `overflow-y: ${overflow}`};
  }
`;

export const ParticipantDescription = styled(Body)`
  padding: 16px;
  border: 1px solid ${ThemeV2.palette.gray4};
`;

export const ParticipantsCriteriaContainer = styled(Flex)<{
  variant: 'mobileView' | 'default';
}>`
  padding: ${({ variant }) =>
    variant === 'mobileView' ? '16px 0' : '16px 48px 30px'};
  min-height: ${({ variant }) =>
    variant === 'mobileView' ? 'unset' : '330px'};
  flex: 4;
  max-height: ${({ variant }) =>
    variant === 'mobileView' ? '230px' : '380px'};
  overflow-y: auto;
`;

export const ParticipationCriteriaTitle = styled(Body)`
  padding-bottom: 8px;
`;

export const ParticipantsListWrapper = styled(Flex)<{
  variant: 'mobileView' | 'default';
}>`
  flex: 3;
  width: 100%;
  min-height: 283px;
  height: ${({ variant }) => (variant === 'mobileView' ? '283px' : 'unset')};
  max-width: ${({ variant }) => (variant === 'mobileView' ? 'unset' : '245px')};
  overflow-x: hidden;
  & > div {
    flex-basis: 0;
    flex-grow: 1;
    overflow-y: auto;
  }
`;
