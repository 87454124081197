import { useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import {
  FLOWS,
  FLOWS_FEEDS_FULLSCREEN,
  NEW_FLOWS_BUILDER,
  TEMPLATE_PREVIEW,
  V2_NOTIFICATION_SETTINGS,
} from '../../constants/routes';
import {
  COPIED_TO_CLIPBOARD,
  TEMPLATE_LINK_COPIED,
  TEMPLATE_LINK_COPIED_FAILED,
} from '../../languages/en/flows';
import {
  useHideFlowActivity,
  useShowFlowActivity,
} from '../../queries/Flows/FlowFeedOptions/Activity';
import { useShareFlowAsTemplateQuery } from '../../queries/Flows/FlowFeedOptions/ShareAsTemplate';
import { GetFlowDetailsResponse } from '../../queries/Flows/interfaces';
import { TemplateDetails } from '../../queries/Flows/Template/types';
import useLayoutStore from '../../stores/layoutStore';
import { copyToClipboard } from '../../Utils/common';
import { formatFlowToTemplate } from '../../Utils/flows';
import { showSuccessMessage, showErrorMessage } from '../../Utils/toast';
import useHistoryWrapper from '../useHistoryWrapper';
import useMultiAssemblySlug from '../useMultiAssemblySlug';
import { ANYONE_WITH_LINK } from '../../languages/en/flows/sharesheet';
import { useOpenEditFlowInV3 } from '../useOpenEditFlowInV3';

const useOptions = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { slugUrl } = useMultiAssemblySlug();
  const { search } = useLocation();
  const historyWrapper = useHistoryWrapper();
  const { toggleRightAsideOpen } = useLayoutStore(
    (state) => ({
      toggleRightAsideOpen: state.toggleRightAsideOpen,
    }),
    shallow,
  );

  const { mutate: mutateHideFlowActivity } = useHideFlowActivity();
  const { mutate: mutateShowFlowActivity } = useShowFlowActivity();
  const { mutateAsync: shareFlowAsTemplate } = useShareFlowAsTemplateQuery();

  const openEditFlowInV3 = useOpenEditFlowInV3();

  const handleCopyFlowFeedLink = (selectedLinkAccessType?: string) => {
    const isExternalParticipationLink =
      selectedLinkAccessType === ANYONE_WITH_LINK && isEmbeddedInMainApp;

    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const searchParams = window.location.search;
    const url = `${origin}${pathname}${searchParams}`;
    const updatedUrl =
      isEmbeddedInMainApp && !isExternalParticipationLink
        ? `${origin}/a${pathname}${searchParams}`
        : url.replace(`/${slugUrl}`, '');
    const urlToCopy = isExternalParticipationLink
      ? updatedUrl.replace('?source=embedded', '')
      : updatedUrl;
    copyToClipboard(urlToCopy, isExternalParticipationLink);
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  };

  const handleAboutIconClick = () => {
    toggleRightAsideOpen();
    const params = new URLSearchParams(search);
    params.set('showAboutSection', 'true');

    historyWrapper.replaceParams({
      search: params.toString(),
    });
  };

  const handleFilterFlowFeedClick = () => {
    toggleRightAsideOpen();
  };

  const handleDuplicateFlowClick = (flowId?: string) => {
    if (flowId) {
      historyWrapper.push({
        pathname: NEW_FLOWS_BUILDER,
        state: { flowIdToDuplicate: flowId },
      });
    }
  };

  const handleEditNotificationSettings = () => {
    historyWrapper.push(V2_NOTIFICATION_SETTINGS);
  };

  const handleEnterFullScreenClick = (flowId?: string) => {
    if (flowId) {
      historyWrapper.push(FLOWS_FEEDS_FULLSCREEN.replace(':flowId', flowId));
    }
  };

  const handleEditFlow = (flowId?: string) => {
    if (flowId) {
      openEditFlowInV3(flowId, {
        disabledCallback() {
          historyWrapper.push(`${FLOWS}/${flowId}/editor`);
        },
      });
    }
  };

  const handleHideActivityClick = (flowId?: string) => {
    if (flowId) {
      mutateHideFlowActivity({ flowId });
    }
  };

  const handleShareAsTemplateClick = async (
    flowId?: string,
    flowData?: GetFlowDetailsResponse,
  ) => {
    if (flowId && flowData) {
      const template: TemplateDetails = formatFlowToTemplate(flowData);
      const data = await shareFlowAsTemplate(
        {
          flowId,
          template,
        },
        {
          onError: () => {
            showErrorMessage(TEMPLATE_LINK_COPIED_FAILED);
          },
        },
      );
      if (data?.template?.id) {
        if (isEmbeddedInMainApp) {
          copyToClipboard(
            `${window.location.origin}/${slugUrl}${TEMPLATE_PREVIEW}`.replace(
              ':templateId',
              data.template.id,
            ),
          );
        } else {
          copyToClipboard(
            `${window.location.origin}${TEMPLATE_PREVIEW}`.replace(
              ':templateId',
              data.template.id,
            ),
          );
        }
        showSuccessMessage(TEMPLATE_LINK_COPIED);
      }
    } else {
      showErrorMessage(TEMPLATE_LINK_COPIED_FAILED);
    }
  };

  const handleShowActivityClick = (flowId?: string) => {
    if (flowId) {
      mutateShowFlowActivity({ flowId });
    }
  };

  return {
    operations: {
      handleEditFlow,
      handleCopyFlowFeedLink,
      handleDuplicateFlowClick,
      handleEditNotificationSettings,
      handleEnterFullScreenClick,
      handleHideActivityClick,
      handleShareAsTemplateClick,
      handleShowActivityClick,
      handleAboutIconClick,
      handleFilterFlowFeedClick,
    },
  };
};

export default useOptions;
