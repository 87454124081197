import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  GET_FLOW_POST_COMMENTS,
  GET_POST_COMMENTS,
} from '../../constants/endpoints';
import { pusher } from '../../pusher/pusher-base';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { ReactionUpdatedPayload } from '../useReactionUpdatedSocket/types';
import { Channel } from 'pusher-js';

export const useReactionsSocket = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();

  useEffect(() => {
    let channelAssembly: Channel | undefined;
    if (data) {
      const { assemblyId } = data.assembly;

      channelAssembly = pusher.subscribe(`private-assembly-${assemblyId}`);
    }

    const handleCallback = async (
      event: string,
      payload: ReactionUpdatedPayload,
    ) => {
      switch (event) {
        case 'COMMENT_REACTION_CHANGED': {
          if (payload.postId) {
            const queryKey = [GET_POST_COMMENTS, payload.postId];
            await queryClient.cancelQueries(queryKey, { type: 'all' });
            queryClient.invalidateQueries([GET_POST_COMMENTS, payload.postId], {
              type: 'all',
            });
            return;
          }
          if (payload.flowId && payload.responseId) {
            const queryKey = [
              GET_FLOW_POST_COMMENTS,
              payload.flowId,
              payload.responseId,
            ];
            await queryClient.cancelQueries(queryKey, { type: 'all' });
            queryClient.invalidateQueries(
              [GET_FLOW_POST_COMMENTS, payload.flowId, payload.responseId],
              { type: 'all' },
            );
            return;
          }
        }
      }
    };

    channelAssembly?.bind_global(handleCallback);
    return () => {
      channelAssembly?.unbind_global(handleCallback);
    };
  }, [data, queryClient]);
};
