import React, { useMemo, memo, useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { getAgoTime } from '../../../Utils/time';
import Avatar from '../../atoms/Avatar';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { FlowsPostHeaderProps, FlowType } from './interfaces';
import {
  BackButton,
  BulletedText,
  FlowNameLink,
  HorizontalRule,
  StyledChartBarIcon,
  StyledClickableLink,
  StyledFlowsPostHeaderContent,
  StyledVisibilityIcon,
  SubtitleContainer,
} from './styles';
import ThemeV2 from '../../../theme';
import { FLOWS_FEED_POST_TYPE_TEXT } from '../../../languages/en/flows';
import ClickableLink from '../ClickableLink';
import {
  A,
  ANONYMOUS,
  EDITED,
  MESSAGE,
  SENT,
} from '../../../languages/en/singleWords';
import {
  getVisibilityIcon,
  getVisibilityTooltipText,
} from '../../../Utils/flows';
import { PostTypes } from '../../../interfaces/Home';
import {
  ANNIVERSARY_TEXT,
  BIRTHDAY_TEXT,
  THE_ASSEMBLY,
} from '../../../languages/en/feed';
import { DEACTIVATED_USER } from '../../../languages/en/notifications';
import birthdayIcon from '../../atoms/SVGIcon/icons/birthday.svg';
import anniversaryIcon from '../../atoms/SVGIcon/icons/anniversary.svg';
import assemblyLogo from '../../atoms/SVGIcon/icons/assemblyLogo.svg';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { YOU } from '../../../languages/en/home';
import { MemberState } from '../../../interfaces/user';
import {
  FeedItemKind,
  FeedPostUserProps,
  MemberInteractionType,
} from '../../../interfaces/Feed';
import ToolTip from '../ToolTipV2';
import { formatPronouns } from '../../../Utils/pronouns';
import { getProfileFullName } from '../../../Utils/user';
import IconButton from '../../atoms/IconButton_V2';
import SVGIcon from '../../atoms/SVGIcon';
import useLayoutStore from '../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import Clickable from '../../atoms/Clickable';

const AvatarComponent = ({
  postType,
  person,
  isAnonymous,
}: {
  postType: PostTypes;
  person?: FeedPostUserProps | null;
  isAnonymous: boolean;
}) => {
  if (isAnonymous) {
    return <Avatar size="40px" fontSize="16px" iconSize="40px" isAnonymous />;
  }
  if (postType === PostTypes.DEFAULT) {
    return (
      <Avatar size="40px" fontSize="16px" img={assemblyLogo} name="assembly" />
    );
  }
  if (person) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={person.image || ''}
        name={person.firstName || ''}
        userId={person.memberID || ''}
        isDeleted={person.memberState === MemberState.DEACTIVATED}
      />
    );
  }

  if (postType === PostTypes.BIRTHDAY) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={birthdayIcon}
        name={BIRTHDAY_TEXT}
      />
    );
  }

  if (postType === PostTypes.ANNIVERSARY) {
    return (
      <Avatar
        size="40px"
        fontSize="16px"
        img={anniversaryIcon}
        name={ANNIVERSARY_TEXT}
      />
    );
  }

  return null;
};

const PersonComponent = ({
  postType,
  flow,
  handleFlowClick,
  person,
  currentUserId,
  onClickUserName,
  isAnonymous,
}: {
  postType: PostTypes;
  flow: FlowType;
  handleFlowClick: () => void;
  person?: FeedPostUserProps | null;
  currentUserId: string;
  onClickUserName: (member: MemberInteractionType) => void;
  isAnonymous: boolean;
}) => {
  {
    if (isAnonymous) {
      return <Body variant="body2Medium">{ANONYMOUS}</Body>;
    }
    if (postType === PostTypes.DEFAULT) {
      return (
        <ClickableLink data={flow} onClickCallback={handleFlowClick}>
          {THE_ASSEMBLY}
        </ClickableLink>
      );
    }
    if (person) {
      if (person.memberState === MemberState.DEACTIVATED) {
        return (
          <Body variant="body2Medium" color="gray6">
            {DEACTIVATED_USER}
          </Body>
        );
      }
      if (person.memberState === MemberState.DUMMY) {
        return (
          <Body variant="body2Medium" color="gray8">
            {getProfileFullName(person)}
          </Body>
        );
      }

      const link = (
        <ClickableLink
          data={person}
          onClickCallback={() =>
            onClickUserName({
              memberID: person.memberID,
              firstName: person.firstName,
            })
          }
        >
          {person.memberID === currentUserId ? YOU : getProfileFullName(person)}
        </ClickableLink>
      );
      const pronouns = formatPronouns(person.pronouns);

      if (pronouns) {
        return (
          <ToolTip position="top" toolTipComponent={link}>
            {`(${pronouns})`}
          </ToolTip>
        );
      }
      return link;
    }

    if (postType === PostTypes.BIRTHDAY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {BIRTHDAY_TEXT}
        </Body>
      );
    }

    if (postType === PostTypes.ANNIVERSARY) {
      return (
        <Body variant="body2Bold" color="gray8" inline>
          {ANNIVERSARY_TEXT}
        </Body>
      );
    }

    return <Body variant="body2Medium">{ANONYMOUS}</Body>;
  }
};

const FlowsPostHeader = (props: FlowsPostHeaderProps) => {
  const {
    person,
    kind,
    createdAt,
    flow,
    visibility,
    currentUserId,
    postType,
    isEdited,
    onFlowClick,
    onClickUserName,
    dropdownComponent,
    variant,
    className,
    onCloseButtonClick,
    isSamplePost,
    source,
    announcementInsights,
  } = props;
  const location = useLocation();
  const isFullScreen = location?.pathname?.includes('/fullscreen');
  const { flowId } = useParams<{ flowId: string }>();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { rightAsideWidth } = useLayoutStore(
    (state) => ({
      rightAsideWidth: state.rightAsideWidth,
    }),
    shallow,
  );

  const isAnonymous = useMemo(
    () =>
      kind === FeedItemKind.INTERNAL_ANONYMOUS ||
      kind === FeedItemKind.EXTERNAL_ANONYMOUS,
    [kind],
  );

  const isDefaultPost = postType === PostTypes.DEFAULT;

  const handleFlowClick = useCallback(() => {
    onFlowClick(isSamplePost ? flowId : flow.flowId);
  }, [flow.flowId, flowId, isSamplePost, onFlowClick]);

  const handleClickUserName = () => {
    handleFlowClick();
    if (person) {
      onClickUserName({
        memberID: person.memberID,
        firstName: person.firstName,
      });
    }
  };

  if (variant === 'sidebar') {
    return (
      <>
        <Flex
          className={className}
          padding="16px 16px 0"
          alignItems={isMobileView ? 'center' : 'start'}
        >
          {isMobileView && (
            <div onClick={onCloseButtonClick}>
              <BackButton>
                <SVGIcon
                  size="24px"
                  icon="carat-left"
                  color={ThemeV2.palette.gray8}
                />
              </BackButton>
            </div>
          )}

          <AvatarComponent
            person={person}
            postType={postType}
            isAnonymous={isAnonymous}
          />
          <StyledFlowsPostHeaderContent>
            <Flex flexWrap="wrap">
              <Body variant="body2" color="gray8" margin="0 4px 0 0">
                Post by
              </Body>
              <PersonComponent
                flow={flow}
                person={person}
                postType={postType}
                isAnonymous={isAnonymous}
                handleFlowClick={handleFlowClick}
                currentUserId={currentUserId || ''}
                onClickUserName={
                  isFullScreen ? handleClickUserName : onClickUserName
                }
              />
            </Flex>
            <Flex>
              {source === 'ZAPIER' && (
                <Flex>
                  <SVGIcon icon="zapier-posted" size="12px" />
                  <Body inline variant="body3" color="gray8">
                    &nbsp;Zapier
                  </Body>
                  <BulletedText>&nbsp;</BulletedText>
                </Flex>
              )}
              <Body
                inline
                variant="body3"
                color="gray8"
                margin="0 4px 0 0"
                overflowEllipsis
              >
                {getAgoTime(createdAt)} in
              </Body>
              <span>
                {isDefaultPost ? (
                  <>
                    <ClickableLink
                      onClickCallback={() =>
                        person &&
                        onClickUserName({
                          memberID: person.memberID,
                          firstName: person.firstName,
                        })
                      }
                    >
                      {YOU}
                    </ClickableLink>
                    <Body variant="body2" color="gray8">
                      &nbsp;{A} {MESSAGE}.
                    </Body>
                  </>
                ) : (
                  <SubtitleContainer rightAsideWidth={rightAsideWidth}>
                    <span>{mapHexCodeToEmoticon(flow.icon.value)}</span>
                    <FlowNameLink
                      data={flow}
                      fontSize="12px"
                      lineHeight="20px"
                      onClickCallback={handleFlowClick}
                    >
                      {flow.name}
                    </FlowNameLink>
                  </SubtitleContainer>
                )}
              </span>
            </Flex>
          </StyledFlowsPostHeaderContent>
          {dropdownComponent}

          {!isMobileView && (
            <div onClick={onCloseButtonClick}>
              <IconButton>
                <SVGIcon
                  icon="round-close"
                  size="16px"
                  color={ThemeV2.palette.gray8}
                />
              </IconButton>
            </div>
          )}
        </Flex>
        {isMobileView && <HorizontalRule />}
      </>
    );
  }

  return (
    <Flex padding="16px 16px 0" alignItems="start">
      <AvatarComponent
        isAnonymous={isAnonymous}
        person={person}
        postType={postType}
      />
      <StyledFlowsPostHeaderContent>
        <Flex flexWrap="wrap">
          <PersonComponent
            postType={postType}
            person={person}
            currentUserId={currentUserId || ''}
            flow={flow}
            onClickUserName={
              isFullScreen ? handleClickUserName : onClickUserName
            }
            handleFlowClick={handleFlowClick}
            isAnonymous={isAnonymous}
          />
          <Body variant="body2" color="gray8">
            &nbsp;{isDefaultPost ? SENT : FLOWS_FEED_POST_TYPE_TEXT}
            &nbsp;
          </Body>
          {isDefaultPost ? (
            <>
              <ClickableLink
                onClickCallback={() =>
                  person &&
                  onClickUserName({
                    memberID: person.memberID,
                    firstName: person.firstName,
                  })
                }
              >
                {YOU}
              </ClickableLink>
              <Body variant="body2" color="gray8">
                &nbsp;{A} {MESSAGE}.
              </Body>
            </>
          ) : (
            <>
              <span>{mapHexCodeToEmoticon(flow.icon.value)}</span>
              <StyledClickableLink
                data={flow}
                onClickCallback={handleFlowClick}
              >
                {flow.name}
              </StyledClickableLink>
            </>
          )}
        </Flex>
        <Flex flexWrap="wrap">
          <Body inline variant="body3" color="gray7">
            {getAgoTime(createdAt)}
          </Body>
          {source === 'ZAPIER' && (
            <BulletedText inline variant="body3" color="gray7">
              Posted with&nbsp;
              <SVGIcon icon="zapier-posted" size="12px" />
              &nbsp;Zapier
            </BulletedText>
          )}
          {visibility && (
            <StyledVisibilityIcon
              icon={getVisibilityIcon(visibility)}
              iconColor={ThemeV2.palette.gray7}
              iconSize="16px"
            >
              {getVisibilityTooltipText(visibility)}
            </StyledVisibilityIcon>
          )}
          {announcementInsights && (
            <BulletedText inline variant="body3" color="gray7">
              <Clickable
                onClick={() =>
                  announcementInsights.onViewAnnouncementInsightsClick?.()
                }
              >
                <StyledChartBarIcon
                  icon="chart-bar"
                  size="12px"
                  color="gray8"
                />
              </Clickable>
            </BulletedText>
          )}
          {isEdited && (
            <BulletedText inline variant="body3" color="gray7">
              {EDITED}
            </BulletedText>
          )}
        </Flex>
      </StyledFlowsPostHeaderContent>
      {dropdownComponent}
    </Flex>
  );
};

export default memo(FlowsPostHeader);
