import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import { trackLeftDrawerShowEvent } from '../../../Utils/analytics/leftDrawer';
import { updateShowLeftDrawer } from '../../../Utils/leftDrawer';
import HomeDrawer from '../../../atomic/organism/HomeDrawer';
import PrimaryNav from '../../../atomic/organism/PrimaryNavbar';
import { ArchiveFlowModalProvider } from '../../../hooks/useArchiveFlowModal/archiveFlowModalContext';
import { EndFlowOccurrenceModalProvider } from '../../../hooks/useEndFlowOccurrenceModal/endFlowOccurrenceModalContext';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import { SecondaryPanel } from '../../../interfaces/Layout';
import { APP_NAME } from '../../../languages/en';
import useKioskStore from '../../../stores/kioskStore';
import useLayoutStore from '../../../stores/layoutStore';
import Theme from '../../../theme';
import AnswerNowFlowsController from '../../flows/AnswerNowFlowsController';
import { FlowsCustomListController } from '../../flows/FlowsCustomListController';
import UnreadFlowsController from '../../flows/UnreadFlowsController';
import LeftDrawerGeneralListController from '../../home/LeftDrawerGeneralListController';
import PeopleDropdownListController from '../../home/PeopleListController';
import QuickCreateController from '../../home/QuickCreateController';
import { LeftNavHorizontalRule } from './styles';
import usePrimaryNavLogic from './usePrimaryNavLogic';
import usePrimaryNavModel from './usePrimaryNavModel';

const AccordionWrapper = styled.div<{
  fullHeight: boolean;
  isExpandedMode: boolean;
}>`
  ${({ fullHeight, isExpandedMode }) =>
    fullHeight
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100% - ${isExpandedMode ? 64 : 60}px);
          padding-top: 8px;
          overflow-y: auto;
          overflow-x: hidden;
          border-bottom: 1px solid ${Theme.palette.gray4};
        `}
`;

const PrimaryNavController: React.FC = () => {
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();
  const {
    activeSecondaryPanel,
    activeNavItem,
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    isLeftNavHovered,
    setLeftNavHoveredToTrue,
    setLeftNavHoveredToFalse,
  } = useLayoutStore(
    (state) => ({
      activeSecondaryPanel: state.activeSecondaryPanel,
      activeNavItem: state.activeNavItem,
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      isLeftNavHovered: state.isLeftNavHovered,
      setLeftNavHoveredToTrue: state.setLeftNavHoveredToTrue,
      setLeftNavHoveredToFalse: state.setLeftNavHoveredToFalse,
    }),
    shallow,
  );

  const {
    models: {
      hideFlowsList,
      hideParticipantsList,
      unreadNotificationsCount,
      pathname,
      currentUserData,
      hideRedeemButton,
      assemblyData,
      hideAllowanceDetails,
      hideRewardsLink,
      hideRewardsInfo,
      isCurrentUserAdmin,
      isPremiumUser,
      isQuickSetupDataLoading,
      quickSetupProgress,
      showQuickSetupOption,
    },
  } = usePrimaryNavModel();

  const {
    operations: {
      toggleNavBar,
      onProfilePopoverClick,
      onProfileClick,
      onProfileOutsideClick,
      onProfileAnywhereElseClick,
      trackLeftDrawerCollapsed,
      trackLeftDrawerExpanded,
      trackLeftDrawerRedeemClick,
      trackLeftDrawerMainFeedClick,
      trackLeftDrawerRewardsClick,
      handleScroll,
    },
    models: { showQuickSetupPanel, refreshDate },
  } = usePrimaryNavLogic(
    isQuickSetupDataLoading,
    isCurrentUserAdmin,
    showQuickSetupOption,
  );

  useEffect(() => {
    trackLeftDrawerShowEvent();
  }, []);

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  const isExpandedMode = isLeftAsideOpen || isLeftNavHovered;

  const renderInfoPanelContent = useCallback(
    ({ isHoveredWhenCollapsed }) => {
      switch (activeSecondaryPanel) {
        case SecondaryPanel.ASSEMBLY:
        case SecondaryPanel.PROFILE: {
          if (!currentUserData || !assemblyData) {
            return <div />;
          }
          return (
            <div data-testid="assemblyContent">
              <HomeDrawer
                isKioskTurnedOn={isKioskTurnedOn}
                isHoveredWhenCollapsed={isHoveredWhenCollapsed}
                isBeta={false}
                trophyBalance={currentUserData.pointsEarned}
                totMonthlyAllowance={currentUserData.allowance.points}
                remainingMonthlyAllowance={currentUserData.pointsLeftThisCycle}
                refreshInfoDate={refreshDate}
                pathname={pathname}
                assemblyCurrency={assemblyData.currency}
                hideAllowanceDetails={hideAllowanceDetails}
                redeemButtonClicked={() => {
                  trackLeftDrawerRedeemClick();
                }}
                hideRedeemButton={hideRedeemButton}
                hideRewardsLink={hideRewardsLink}
                hideRewardsInfo={hideRewardsInfo}
                assemblyName={isPremiumUser ? assemblyData.name : APP_NAME}
                onRewardsLinkClick={trackLeftDrawerRewardsClick}
                onHomeLinkClick={trackLeftDrawerMainFeedClick}
                handleScroll={handleScroll}
              >
                <QuickCreateController isExpandedMode={isExpandedMode} />

                <AccordionWrapper
                  isExpandedMode={isExpandedMode}
                  onScroll={handleScroll}
                  fullHeight
                >
                  <LeftDrawerGeneralListController
                    isLeftAsideOpen={isLeftAsideOpen}
                    isLeftNavHovered={isLeftNavHovered}
                  />
                  <LeftNavHorizontalRule />
                  <div id="intro-flows-onboarding-step-2">
                    <UnreadFlowsController
                      isLeftAsideOpen={isLeftAsideOpen}
                      isLeftNavHovered={isLeftNavHovered}
                    />

                    <AnswerNowFlowsController
                      isLeftAsideOpen={isLeftAsideOpen}
                      isLeftNavHovered={isLeftNavHovered}
                    />

                    {!hideFlowsList && (
                      <FlowsCustomListController
                        isLeftAsideOpen={isLeftAsideOpen}
                        isLeftNavHovered={isLeftNavHovered}
                      />
                    )}
                  </div>

                  {!hideParticipantsList && (
                    <PeopleDropdownListController
                      isLeftAsideOpen={isLeftAsideOpen}
                      isLeftNavHovered={isLeftNavHovered}
                    />
                  )}
                </AccordionWrapper>
              </HomeDrawer>
            </div>
          );
        }
        case SecondaryPanel.QUICK_SETUP:
          return null;

        default: {
          return 'Default';
        }
      }
    },
    [
      activeSecondaryPanel,
      currentUserData,
      assemblyData,
      isKioskTurnedOn,
      refreshDate,
      pathname,
      hideAllowanceDetails,
      hideRedeemButton,
      hideRewardsLink,
      hideRewardsInfo,
      isPremiumUser,
      trackLeftDrawerRewardsClick,
      trackLeftDrawerMainFeedClick,
      handleScroll,
      isExpandedMode,
      isLeftAsideOpen,
      isLeftNavHovered,
      hideFlowsList,
      hideParticipantsList,
      trackLeftDrawerRedeemClick,
    ],
  );

  if (!currentUserData) {
    return null;
  }

  return (
    <ArchiveFlowModalProvider>
      <EndFlowOccurrenceModalProvider>
        <PrimaryNav
          isLeftAsideOpen={isLeftAsideOpen}
          isRightAsideOpen={isRightAsideOpen}
          isLeftNavHovered={isLeftNavHovered}
          setLeftNavHoveredToTrue={setLeftNavHoveredToTrue}
          setLeftNavHoveredToFalse={setLeftNavHoveredToFalse}
          toggleLeftAsideOpen={() => {
            toggleLeftAsideOpen();
            updateShowLeftDrawer({ showLeftDrawer: !isLeftAsideOpen });
            if (isLeftAsideOpen) {
              trackLeftDrawerCollapsed();
            } else {
              trackLeftDrawerExpanded();
            }
          }}
          activeNavItem={activeNavItem}
          activeSecondaryPanel={activeSecondaryPanel}
          toggleNavBar={toggleNavBar}
          onProfilePopoverClick={onProfilePopoverClick}
          onProfileClick={onProfileClick}
          onProfileOutsideClick={onProfileOutsideClick}
          onProfileAnywhereElseClick={onProfileAnywhereElseClick}
          unreadNotificationsCount={unreadNotificationsCount}
          renderInfoPanelContent={renderInfoPanelContent}
          currentUserId={currentUserData.memberId}
          currentUserProfile={currentUserData.profile}
          isCurrentUserAdmin={isCurrentUserAdmin}
          showQuickStepProgress={showQuickSetupPanel}
          quickSetupProgress={quickSetupProgress}
          hideRewardsLink={hideRewardsLink}
          hideQuickSetup
          isKioskTurnedOn={isKioskTurnedOn}
        />
      </EndFlowOccurrenceModalProvider>
    </ArchiveFlowModalProvider>
  );
};

export default PrimaryNavController;
