import { create } from 'zustand';
import AwsS3 from '@uppy/aws-s3';
import Uppy, { UploadResult, UppyFile } from '@uppy/core';

import { customUppyStore } from '../../stores/uppyStore';
import { defaultAllowedFileTypes } from './constants';
import { makeAPICallWithDataReturn } from '../../queries/utils';
import {
  FileUploadFlowBlock,
  FlowBlockFromAPI,
} from '../../interfaces/Flow/index';
import { sanitizeFileName } from '../../atomic/molecules/FlowInputBlocks/FlowsFileUploadInputBlock/utils';
import {
  generateAllowedFileTypes,
  megabytes,
} from '../../controllers/flows/FlowsFileUploadInputBlockController/utils';

export const MAX_FILE_UPLOAD_SIZE = 25;

type IDs = {
  flowId: string;
  instanceId: string;
  identifier?: string;
};

interface AWSUploadResult extends UploadResult {
  uploadID: string;
}

type UploadStoreState = {
  uppyInstances: { [instanceId: string]: { [blockId: string]: Uppy } };
  fileUploadLimit: number;
  postActiveUploads: { [instanceId: string]: { key: string; value: string }[] };
  createUppyInstance: (
    block: FlowBlockFromAPI,
    { flowId, instanceId, identifier }: IDs,
    signedUrlName: string,
    fileUploadLimit: number,
  ) => void;
  clearUppyInstances: (instanceId: string) => void;
};

const useUploadStore = create<UploadStoreState>()((set, get) => ({
  uppyInstances: {},
  fileUploadLimit: 25,
  postActiveUploads: {},
  createUppyInstance: (
    block: FlowBlockFromAPI,
    { flowId, instanceId, identifier }: IDs,
    signedUrlName: string,
    fileUploadLimit: number,
  ) => {
    const blockContent = block.content as FileUploadFlowBlock;
    const allowedFileTypes = blockContent.rules?.type
      ? generateAllowedFileTypes(blockContent.rules.type)
      : defaultAllowedFileTypes;

    const uppyInstance = new Uppy({
      autoProceed: true,
      id: block.blockId,
      restrictions: {
        allowedFileTypes,
        // megabytes(fileUploadLimit) because backend will be sending the limit based on customer plan
        maxFileSize: megabytes(fileUploadLimit),
        maxTotalFileSize: megabytes(fileUploadLimit),
        maxNumberOfFiles: 15,
      },
      store: customUppyStore(block.blockId),
    });

    uppyInstance.use(AwsS3, {
      getUploadParameters: async (file: UppyFile) => {
        const payload = undefined;
        const params = { identifier: identifier || '' };
        const urlSubstitutions = {
          flowId,
          instanceId,
          blockId: block.blockId,
          fileId: sanitizeFileName(file.name),
        };
        const { url: signedUrl } = await makeAPICallWithDataReturn(
          signedUrlName,
          payload,
          params,
          urlSubstitutions,
        );

        if (file?.type) {
          return {
            method: 'PUT',
            url: signedUrl,
            headers: {
              'Content-Type': file.type,
            },
          };
        }
        return {
          method: 'PUT',
          url: signedUrl,
        };
      },
    });

    uppyInstance.on('upload', (data) => {
      set((state: { postActiveUploads: any }) => ({
        postActiveUploads: state.postActiveUploads[instanceId]
          ? {
              ...state.postActiveUploads,
              [instanceId]: [
                ...state.postActiveUploads[instanceId],
                { key: data.id, value: data.id },
              ],
            }
          : {
              ...state.postActiveUploads,
              [instanceId]: [{ key: data.id, value: data.id }],
            },
      }));
    });

    uppyInstance.on('upload-error', (data) => {
      const uploadError = new CustomEvent(`erroredInstanceId:${instanceId}`, {
        detail: { data: data?.data },
      });
      document.dispatchEvent(uploadError);
    });

    uppyInstance.on('complete', (data) => {
      const awsData = data as AWSUploadResult;
      set((state: { postActiveUploads: any }) => ({
        postActiveUploads: {
          ...state.postActiveUploads,
          [instanceId]: state.postActiveUploads[instanceId].filter(
            (upload: { key: string; value: string }) =>
              upload.key !== awsData.uploadID,
          ),
        },
      }));
      const postActiveUploads = get().postActiveUploads;
      if (postActiveUploads[instanceId].length === 0) {
        set((state: { postActiveUploads: any }) => ({
          postActiveUploads: Object.keys(state.postActiveUploads)
            .filter((item) => item !== instanceId)
            .reduce((obj, key) => {
              return {
                ...obj,
                [key]: state.postActiveUploads[key],
              };
            }, {}),
        }));
        const uploadsComplete = new CustomEvent(`instanceId:${instanceId}`);
        document.dispatchEvent(uploadsComplete);
      }
    });

    set((state: { uppyInstances: any }) => ({
      uppyInstances: {
        ...state.uppyInstances,
        [instanceId]: {
          ...state.uppyInstances[instanceId],
          [block.blockId]: uppyInstance,
        },
      },
      fileUploadLimit,
    }));
  },
  clearUppyInstances: (instanceId: string) => {
    Object.keys(get().uppyInstances[instanceId]).forEach((blockId) => {
      get().uppyInstances[instanceId][blockId].close();
    });
  },
}));

export default useUploadStore;
