import React, { useState, useRef, useEffect } from 'react';
import Loading from '../loading';
import { usePdf } from './parsers/pdfParser';
import { datadogLogs } from '@datadog/browser-logs';
import { RenderingCancelledException } from 'pdfjs-dist/build/pdf';

function PdfViewer({
  height,
  width,
  filePath,
  SubComponent,
  WrapperComponent,
  idPrefix,
  hideLoader,
  FooterComponent,
  flowId,
  responseId,
  blockId,
}: {
  height: number;
  width: number;
  filePath: string;
  SubComponent?: (props: { index: number }) => JSX.Element;
  WrapperComponent?: (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => JSX.Element;
  idPrefix?: string;
  hideLoader?: boolean;
  FooterComponent?: () => JSX.Element;
  flowId?: string;
  responseId?: string;
  blockId?: string;
}) {
  /* @ts-ignore-next-line */
  const [pdfDocument, setPdfDocument] = useState<pdfjs.PDFDocumentProxy>();
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [canvasElements, setCanvasElements] = useState<JSX.Element[]>([]);
  const canvassesRef = useRef([]);

  useEffect(() => {
    (async function () {
      // We import this here so that it's only loaded during client-side rendering.
      const pdfJS = await import('pdfjs-dist/build/pdf');
      pdfJS.GlobalWorkerOptions.workerSrc =
        window.location.origin + '/pdf.worker.min.js';
      /* @ts-ignore-next-line */
      const config: pdfjs.PDFSource = { url: filePath };
      await pdfJS.getDocument(config).promise.then((loadedPdfDocument: any) => {
        setPdfDocument(loadedPdfDocument);
      });
    })();
  }, [filePath]);

  useEffect(() => {
    if (pdfDocument) {
      setNumberOfPages(pdfDocument.numPages);
    }
  }, [pdfDocument]);

  useEffect(() => {
    const pagesToDisplay = numberOfPages > 25 ? 25 : numberOfPages;
    const canvasses: any[] = [];
    for (let pageNum = 1; pageNum <= pagesToDisplay; pageNum++) {
      const key = `${idPrefix}${pageNum}${width}${height}${numberOfPages}`;

      canvasses.push(
        <div>
          {WrapperComponent ? (
            <>
              <WrapperComponent index={pageNum}>
                <canvas
                  /* @ts-ignore-next-line */
                  ref={(el) => (canvassesRef.current[pageNum] = el)}
                  className={'wrappedCanvas'}
                  key={key}
                />
              </WrapperComponent>
              {SubComponent && <SubComponent index={pageNum} />}
            </>
          ) : (
            <>
              <canvas
                /* @ts-ignore-next-line */
                ref={(el) => (canvassesRef.current[pageNum] = el)}
                id={`${idPrefix}` + pageNum}
                key={key}
              />
              {SubComponent && <SubComponent index={pageNum} />}
            </>
          )}
        </div>,
      );
    }
    setCanvasElements(canvasses);
  }, [SubComponent, WrapperComponent, height, idPrefix, numberOfPages, width]);

  usePdf({
    pdfDocument,
    numberOfPages,
    canvassesRef,
    height,
    width,
    onPageLoadFail: (err) => {
      datadogLogs.logger.error(
        'Failed to load PDF',
        { blockId, flowId, responseId },
        err,
      );
    },
    onPageRenderFail: (err) => {
      if (!(err instanceof RenderingCancelledException)) {
        datadogLogs.logger.error(
          'Failed to render PDF',
          { blockId, flowId, responseId },
          err,
        );
      }
    },
  });

  return (
    <>
      {!pdfDocument && !hideLoader && <Loading />}
      {canvasElements}
      {FooterComponent && numberOfPages > 25 && <FooterComponent />}
    </>
  );
}

export default PdfViewer;
