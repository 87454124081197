import React from 'react';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../theme';

type LoaderProps = {
  className?: string;
};

const Loader = (props: LoaderProps) => {
  const { className } = props;
  return (
    <ContentLoader
      speed={2}
      width={210}
      height={20}
      viewBox="0 0 210 20"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      className={className}
    >
      <rect x="2" y="2" rx="2" ry="2" width="16" height="16" />
      <rect x="24" y="0" rx="4" ry="4" width="185" height="20" />
    </ContentLoader>
  );
};

export default Loader;
