import { useCallback, useEffect, useMemo, useState } from 'react';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import forMyselfImage from '../../../img/forMyself.svg';
import forMyTeamImage from '../../../img/forMyTeam.svg';
import forMyOrgImage from '../../../img/forMyOrg.svg';
import ThemeV2 from '../../../theme';
import placeholderLogo from '../../../img/assembly-placeholder-logo.svg';
import { OnboardingPaths } from '../../../interfaces/Onboarding';
import { useRunAccountSetupJob } from '../../../queries/Onboarding';
import { ClickableSelectionItemProps } from '../../../atomic/molecules/ClickableSelectionItem';
import useUpdateTimezone from '../../../hooks/useUpdateTimezone';
import { trackOnboardingActionEvent } from '../../../Utils/analytics/onboarding';
import useRedirectStore from '../../../stores/redirectStore';

export const useFlowsOnboardingWithQuickSetupController = () => {
  const {
    mutate: runAccountJobMutate,
    isSuccess,
    data: preJobResponse,
  } = useRunAccountSetupJob();

  // This is to clear the redirect path from store when user
  // logs in to the application via onboard flow
  const clearPath = useRedirectStore((store) => store.clearPath);
  useEffect(() => {
    clearPath();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: profileInfoQueryData, isInitialLoading: isLoading } =
    useProfileInfoFetchQuery();

  useUpdateTimezone(profileInfoQueryData?.member.timeZone, isLoading, true);
  const companyLogo = profileInfoQueryData?.assembly.logo || placeholderLogo;
  const companyName = profileInfoQueryData?.assembly.name || '';
  const [selectedItemId, setSelectedItemId] = useState('');
  const [isNewOnboardingScreen, setIsNewOnboardingScreen] = useState(false);
  const handleOptionItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      //Mixpanel
      trackOnboardingActionEvent({
        clickPath: id,
      });
      trackOnboardingActionEvent({
        completeOnboarding: id,
      });
      runAccountJobMutate({
        onboardingPath: id,
      });
    },
    [runAccountJobMutate],
  );
  const optionItems: ClickableSelectionItemProps[] = useMemo(() => {
    return [
      {
        id: OnboardingPaths.Myself,
        title: 'Explore for my personal use',
        helperText:
          'Achieve my personal goals, stay organized and stay on track',
        image: {
          src: forMyselfImage,
          width: '54',
          height: '72',
          backgroundColor: ThemeV2.palette.geekBlue1,
        },
        margin: '24px 0 16px 0',
        isChecked: selectedItemId === OnboardingPaths.Myself,
        onItemClick: handleOptionItemClick,
      },
      {
        id: OnboardingPaths.Team,
        title: 'Explore for my team’s use',
        helperText:
          'Elevate my team’s performance with 1:1s, meeting agendas, and communication',
        image: {
          src: forMyTeamImage,
          width: '84',
          height: '72',
          backgroundColor: ThemeV2.palette.blue1,
        },
        margin: '16px 0',
        isChecked: selectedItemId === OnboardingPaths.Team,
        onItemClick: handleOptionItemClick,
      },
      {
        id: OnboardingPaths.Organisation,
        title: 'Explore for my organization’s use',
        helperText:
          'Improve employee engagement and culture with our hybrid work solution',
        image: {
          src: forMyOrgImage,
          width: '58',
          height: '63',
          backgroundColor: ThemeV2.palette.volcano,
        },
        isChecked: selectedItemId === OnboardingPaths.Organisation,
        onItemClick: () => setIsNewOnboardingScreen(true),
      },
    ];
  }, [handleOptionItemClick, selectedItemId]);
  const params = new URLSearchParams(location.search);
  const canSetManagerFilter = params.get('isManager') === 'yes';

  return {
    isSuccess,
    companyLogo,
    companyName,
    optionItems,
    canSetManagerFilter,
    isNewOnboardingScreen,
    onContinueExploringButtonClick: handleOptionItemClick,
    selectedItemId: selectedItemId || OnboardingPaths.Team,
    preJobResponse: preJobResponse?.data,
  };
};
