import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  FLOWS,
  FLOWS_BUILDER,
  FLOWS_FEEDS,
  NEW_FLOWS_BUILDER,
  RECOGNITION_FEEDS,
  V2_GENERAL_SETTINGS,
  V2_HOME,
  NOTEBOOK,
  V2_NOTIFICATION_SETTINGS,
  V2_POST_PAGE,
  V2_REWARDS,
  V2_USER_PROFILE,
  V3_POST_PAGE,
  QUICK_SETUP,
  V2_SETTINGS,
  KNOWLEDGE_CENTER,
  UNARCHIVE_FLOW,
  PEOPLE_DIRECTORY,
} from '../../../constants/routes';
import {
  GENERAL,
  MY_SETTINGS,
  NOTIFICATIONS,
} from '../../../languages/en/settings';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import MainFeedHeaderController from '../MainFeed';
import ProfileHeaderController from '../Profile';
import {
  KNOWLEDGE_CENTER_SUBTITLE,
  KNOWLEDGE_CENTER_TITLE,
  NOTEBOOK_HEADER_SUBTITLE,
  NOTEBOOK_HEADER_TITLE,
} from '../../../languages/en/header';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { settingsTabButtons } from '../../../atomic/organism/RouteTabs/data';
import SinglePostHeaderController from '../SinglePost';
import FlowsBuilderHeaderController from '../../flowsBuilder/FlowsBuilderHeaderController';
import FlowFeedHeaderController from '../../flows/FlowFeedHeaderController';
import RecognitionFeedHeaderController from '../../flows/RecognitionFeedHeaderController';
import SingleFlowFeedPostHeaderController from '../SingleFlowFeedPost';
import {
  quickSetupHeader,
  quickSetupSubHeader,
} from '../../../languages/en/quickSetup';
import RewardsHeaderController from '../../rewards/RewardsHeaderController';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import { Helmet } from 'react-helmet-async';
import PeopleDirectoryHeader from '../../peopleDirectory/Components/PeopleDirectoryHeader';

const GeneralSettingsHeader = ({ tabRouteBase }: { tabRouteBase: string }) => (
  <>
    <Helmet>
      <title>General Settings</title>
    </Helmet>
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="general-settings"
      title={GENERAL}
      subTitle={MY_SETTINGS}
      hasBottomBorder={false}
      pageTabs={settingsTabButtons}
      tabRouteBase={tabRouteBase}
    />
  </>
);

const NotificationSettingsHeader = ({
  tabRouteBase,
}: {
  tabRouteBase: string;
}) => (
  <>
    <Helmet>
      <title>General Settings</title>
    </Helmet>
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="notification-bubble"
      title={NOTIFICATIONS}
      subTitle={MY_SETTINGS}
      hasBottomBorder={false}
      pageTabs={settingsTabButtons}
      tabRouteBase={tabRouteBase}
    />
  </>
);

const NotebookHeader = () => {
  return (
    <>
      <Helmet>
        <title>Notebook - My Tasks</title>
      </Helmet>
      <PageHeader
        headerType={HeaderType.OTHER}
        icon="notebook-checklist"
        title={NOTEBOOK_HEADER_TITLE}
        subTitle={NOTEBOOK_HEADER_SUBTITLE}
        hasBottomBorder={false}
      />
    </>
  );
};

const KnowledgeCenterHeader = () => {
  return (
    <>
      <Helmet>
        <title>Knowledge Center</title>
      </Helmet>
      <PageHeader
        headerType={HeaderType.KNOWLEDGE_CENTER}
        icon="file-browsing"
        title={KNOWLEDGE_CENTER_TITLE}
        subTitle={KNOWLEDGE_CENTER_SUBTITLE}
        hasBottomBorder={true}
      />
    </>
  );
};

const QuickSetupHeader = () => (
  <>
    <Helmet>
      <title>Quick Setup</title>
    </Helmet>
    <PageHeader
      headerType={HeaderType.OTHER}
      title={quickSetupHeader}
      icon="notebook-checklist"
      subTitle={quickSetupSubHeader}
      hasBottomBorder={true}
    />
  </>
);

const PageHeaderRouter = () => {
  const { slugUrl } = useMultiAssemblySlug();

  return (
    <Switch>
      <Route path={V2_HOME} component={MainFeedHeaderController} />
      <Route
        path={`/:slugUrl${V2_HOME}`}
        component={MainFeedHeaderController}
      />
      <Route path={V2_REWARDS} component={RewardsHeaderController} />
      <Route
        path={`/:slugUrl${V2_REWARDS}`}
        component={RewardsHeaderController}
      />
      <Route path={V2_USER_PROFILE} component={ProfileHeaderController} />
      <Route
        path={`/:slugUrl${V2_USER_PROFILE}`}
        component={ProfileHeaderController}
      />
      <Route
        path={`/:slugUrl${V2_GENERAL_SETTINGS}`}
        component={() => (
          <GeneralSettingsHeader tabRouteBase={`/${slugUrl}${V2_SETTINGS}`} />
        )}
      />
      <Route
        path={V2_GENERAL_SETTINGS}
        component={() => <GeneralSettingsHeader tabRouteBase={V2_SETTINGS} />}
      />
      <Route
        path={V3_POST_PAGE}
        component={SingleFlowFeedPostHeaderController}
      />
      <Route
        path={`/:slugUrl${V3_POST_PAGE}`}
        component={SingleFlowFeedPostHeaderController}
      />
      <Route path={V2_POST_PAGE} component={SinglePostHeaderController} />
      <Route
        path={`/:slugUrl${V2_POST_PAGE}`}
        component={SinglePostHeaderController}
      />
      <Route
        path={NEW_FLOWS_BUILDER}
        component={FlowsBuilderHeaderController}
      />
      <Route
        path={`/:slugUrl${NEW_FLOWS_BUILDER}`}
        component={FlowsBuilderHeaderController}
      />
      <Route path={FLOWS_BUILDER} component={FlowsBuilderHeaderController} />
      <Route
        path={`/:slugUrl${FLOWS_BUILDER}`}
        component={FlowsBuilderHeaderController}
      />
      <Route
        path={`/:slugUrl${RECOGNITION_FEEDS}`}
        component={RecognitionFeedHeaderController}
      />
      <Route
        path={RECOGNITION_FEEDS}
        component={RecognitionFeedHeaderController}
      />
      <Route path={NEW_FLOWS_BUILDER} />
      <Route path={`/:slugUrl${NEW_FLOWS_BUILDER}`} />
      <Route path={`/:slugUrl${UNARCHIVE_FLOW}`} />
      <Route path={NOTEBOOK} component={NotebookHeader} />
      <Route path={`/:slugUrl${NOTEBOOK}`} component={NotebookHeader} />
      <Route path={QUICK_SETUP} component={QuickSetupHeader} />
      <Route path={KNOWLEDGE_CENTER} component={KnowledgeCenterHeader} />
      <Route
        path={`/:slugUrl${KNOWLEDGE_CENTER}`}
        component={KnowledgeCenterHeader}
      />
      <Route path={PEOPLE_DIRECTORY} component={PeopleDirectoryHeader} />
      <Route
        path={`/:slugUrl${PEOPLE_DIRECTORY}`}
        component={PeopleDirectoryHeader}
      />
      <Route path={FLOWS_FEEDS} component={FlowFeedHeaderController} />
      <Route
        path={`/:slugUrl${FLOWS_FEEDS}`}
        component={FlowFeedHeaderController}
      />
      <Route
        path={`/:slugUrl${V2_NOTIFICATION_SETTINGS}`}
        component={() => (
          <NotificationSettingsHeader
            tabRouteBase={`/${slugUrl}${V2_SETTINGS}`}
          />
        )}
      />
      <Route
        path={V2_NOTIFICATION_SETTINGS}
        component={() => (
          <NotificationSettingsHeader tabRouteBase={V2_SETTINGS} />
        )}
      />
      <Route path={FLOWS}>
        <Redirect from="/" to="/home" />
      </Route>
    </Switch>
  );
};

export default PageHeaderRouter;
