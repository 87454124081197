import { useEffect, useState } from 'react';

import { useGetChannelsList } from '../../../../../queries/Connections';
import { useJobStatusQuery } from '../../../../../queries/Settings/identityManager';

import {
  GetChannelsListResponse,
  GetChannelsListJobResponse,
  isGetChannelsListResponseType,
  isGetChannelsListJobResponseType,
} from '../../../../../interfaces/Connections';

export const useGetChannelsJob = (connectionId: string) => {
  const INTERVAL = 500;

  const [isJobQueued, setIsJobQueued] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<GetChannelsListResponse>();
  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const { data, mutate, isError } = useGetChannelsList(connectionId);

  const {
    data: jobData,
    error: jobError,
    refetch: refetchJobStatus,
  } = useJobStatusQuery(jobId);

  useEffect(() => {
    if (connectionId) {
      setIsLoading(true);
      mutate(false);
    }
  }, [connectionId, mutate]);

  useEffect(() => {
    if (data && isGetChannelsListJobResponseType(data)) {
      const {
        integrationDataJob: { id },
      } = data as GetChannelsListJobResponse;

      setJobId(id);
    } else {
      setJobId(undefined);
    }
  }, [data]);

  useEffect(() => {
    if (jobData) {
      const {
        data: { status },
      } = jobData;

      if (status === 'SUCCESS') {
        setIsJobQueued(false);
        mutate(false);
      }

      if (status === 'QUEUED' || status === 'PROCESSING') {
        setIsJobQueued(true);
        setTimeout(() => {
          refetchJobStatus();
        }, INTERVAL);
      }
    }
  }, [jobData, mutate, refetchJobStatus]);

  useEffect(() => {
    if (data && isGetChannelsListResponseType(data)) {
      setResponse(data as GetChannelsListResponse);
      setIsLoading(false);
    }
  }, [data]);

  return {
    isError,
    isLoading,
    isJobQueued,
    data: response,
    isJobError: !!jobError,
    refetchChannels: mutate,
    setIsLoadingChannels: setIsLoading,
  };
};
