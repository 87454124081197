import React from 'react';

import mainFeedLogo from './main-feed-logo.svg';
import { COMPANY_LOGO_ALT_TEXT, MAIN_FEED } from '../../../languages/en/header';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import PageHeader from '../../../atomic/molecules/PageHeader';
import useMainFeedHeaderController from './useMainFeedHeaderController';
import InviteModalController from '../../InviteModalController';
import { Helmet } from 'react-helmet-async';

const MainFeedHeaderController = () => {
  const {
    isModalOpen,
    membersCount,
    toggleInviteModal,
    isMembersCountError,
    canShowTemplateButton,
    isMembersCountLoading,
    handleInviteButtonClick,
    handleTemplatesButtonClick,
  } = useMainFeedHeaderController();
  return (
    <>
      <Helmet>
        <title>My Feed</title>
      </Helmet>
      <PageHeader
        title={MAIN_FEED}
        imageUrl={mainFeedLogo}
        membersCount={membersCount}
        headerType={HeaderType.MAIN_FEED}
        imageName={COMPANY_LOGO_ALT_TEXT}
        isMembersCountError={isMembersCountError}
        isMembersCountLoading={isMembersCountLoading}
        onInviteButtonClick={handleInviteButtonClick}
        canShowTemplateButton={canShowTemplateButton}
        onTemplatesButtonClick={handleTemplatesButtonClick}
      />
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={toggleInviteModal}
      />
    </>
  );
};

export default MainFeedHeaderController;
