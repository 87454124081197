import styled from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import ThemeV2 from '../../../theme';

import IconButtonWithToolTip from '../IconButtonWithToolTip';
import AddTrophiesInput from '../AddTrophiesInput';
import ToolTip from '../ToolTipV2';
import { PostType } from './interface';
import EmoticonPickerPopover from '../EmoticonPickerPopover';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const Wrapper = styled(Flex)<{
  postType: PostType;
  showAddTrophy: boolean;
}>`
  justify-content: ${({ postType, showAddTrophy }) =>
    postType === PostType.Comment && showAddTrophy
      ? 'space-between'
      : 'flex-end'};
  padding: ${({ postType }) =>
    postType === PostType.Comment ? '4px' : '8px 0'};
  align-items: ${({ postType }) =>
    postType === PostType.Comment ? 'flex-end' : 'center'};
  max-width: 100%;
  background: ${ThemeV2.palette.gray1};
  border-radius: 0 0 4px 4px;
`;

export const IconButtonWrapper = styled(IconButtonWithToolTip)<{
  isFocused: boolean;
}>`
  padding: 0;
  width: 28px;
  height: 28px;

  & span {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;

      path:not(:hover) {
        fill: ${({ isFocused }) =>
          isFocused ? ThemeV2.palette.gray8 : ThemeV2.palette.gray6};
      }
    }
  }
`;

export const StyledTrophiesInput = styled(AddTrophiesInput)`
  & button {
    padding: 0;
    border-bottom-left-radius: 20px;

    &:before {
      border-bottom-left-radius: 20px;
    }
  }

  &:not(:disabled):hover {
    & button {
      &:before {
        background: transparent;
        border: none;
      }
    }
  }
`;

export const StyledToolTip = styled(ToolTip)`
  margin-left: 8px;
`;

export const TooltipContent = styled.div`
  width: 182px;
`;

export const StyledEmoticonPickerPopover = styled(EmoticonPickerPopover)`
  z-index: ${zIndexPopup};
`;

export const ButtonContentFlexWrapper = styled(Flex)`
  & > div {
    margin-right: 4px;
  }
`;
