import produce from 'immer';
import {
  useQueryClient,
  useMutation,
  InfiniteData,
  useInfiniteQuery,
} from '@tanstack/react-query';

import {
  ADMIN_DELETE_COMMENT,
  GET_FLOW_FEED,
  GET_FLOW_POST_COMMENTS,
  GET_FLOW_REPLIES_SUMMARY,
  GET_MAIN_FEED,
  GET_POST_COMMENTS,
  GET_PROFILE_FEED,
  GET_PROFILE_INFO,
  UPDATE_COMMENT_REACTION,
  V3_GET_FEED,
} from '../../constants/endpoints';
import {
  UpdateCommentReactionMutationVariables,
  UpdateReactionAction,
  GetCommentByPostResponse,
} from '../../interfaces/Feed';
import { PostTypes } from '../../interfaces/Home';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { findCommentInPostData } from './utils';

export const useGetPostComments = (
  postId: string,
  limit = 3,
  enabled = true,
  sortBy = 'asc',
) => {
  return useInfiniteQuery<GetCommentByPostResponse>(
    [GET_POST_COMMENTS, postId],
    ({ pageParam = undefined }) =>
      makeAPICallWithDataReturn(
        GET_POST_COMMENTS,
        undefined,
        {
          limit: limit,
          sortBy: sortBy,
          cursor: pageParam,
        },
        {
          postId,
        },
      ),
    {
      enabled,
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useGetFlowPostComments = (
  flowId: string,
  responseId: string,
  limit = 3,
  enabled = true,
  sortBy = 'asc',
) => {
  return useInfiniteQuery<GetCommentByPostResponse>(
    [GET_FLOW_POST_COMMENTS, flowId, responseId],
    ({ pageParam = undefined }) =>
      makeAPICallWithDataReturn(
        GET_FLOW_POST_COMMENTS,
        undefined,
        {
          cursor: pageParam,
          limit: limit,
          sortBy: sortBy,
        },
        {
          flowId,
          responseId,
        },
      ),
    {
      enabled,
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export interface AdminDeleteCommentArgs {
  postId: string;
  commentId: string;
  isWithCarrots?: boolean;
  postType?: PostTypes;
  flowId?: string;
  responseId?: string;
}

export const useAdminDeleteComment = (
  onSuccessCallback?: () => void,
  onErrorCallback?: () => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ commentId, isWithCarrots = false }: AdminDeleteCommentArgs) => {
      return makeAPICall(ADMIN_DELETE_COMMENT, {
        commentId,
        returnPoints: isWithCarrots,
      });
    },
    {
      onSuccess: (
        deletedCommentData,
        {
          postId,
          isWithCarrots,
          postType,
          responseId,
          flowId,
        }: AdminDeleteCommentArgs,
      ) => {
        if (postType && postType === PostTypes.FLOW) {
          if (flowId && responseId) {
            queryClient.invalidateQueries([
              GET_FLOW_POST_COMMENTS,
              flowId,
              responseId,
            ]);
          }
        } else {
          queryClient.invalidateQueries([GET_POST_COMMENTS, postId]);
        }

        queryClient.invalidateQueries([GET_FLOW_REPLIES_SUMMARY]);
        queryClient.invalidateQueries([V3_GET_FEED]);
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries([GET_FLOW_FEED]);
        queryClient.invalidateQueries([GET_PROFILE_FEED]);
        queryClient.invalidateQueries([
          GET_FLOW_POST_COMMENTS,
          flowId,
          responseId,
        ]);
        if (isWithCarrots) {
          queryClient.invalidateQueries([GET_PROFILE_INFO]);
        }

        if (onSuccessCallback) {
          onSuccessCallback();
        }
      },
      onError: () => {
        if (onErrorCallback) {
          onErrorCallback();
        }
      },
    },
  );
};

export const useUpdateCommentReactionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ contentID, action, payload }: UpdateCommentReactionMutationVariables) =>
      makeAPICall(
        UPDATE_COMMENT_REACTION,
        payload,
        {},
        { commentId: contentID, action },
      ),
    {
      onMutate: ({
        userData,
        postID,
        payload,
        contentID,
        action,
        postType,
        responseId,
        flowId,
      }) => {
        let currentQueryKeys = [GET_POST_COMMENTS, postID];
        if (postType && postType === PostTypes.FLOW) {
          if (flowId && responseId) {
            currentQueryKeys = [GET_FLOW_POST_COMMENTS, flowId, responseId];
          }
        }
        const previousData: InfiniteData<GetCommentByPostResponse> | undefined =
          queryClient.getQueryData(currentQueryKeys);
        if (previousData) {
          const updatedData = produce(previousData, (draft) => {
            const updatedComment = findCommentInPostData(
              draft.pages,
              contentID,
            );
            if (updatedComment) {
              const reactionIndex = updatedComment.reactions.findIndex(
                (reaction) => reaction.name === payload.name,
              );
              // IF API CALL IS SETTING AN EMOTICON
              if (action === UpdateReactionAction.SET) {
                // IF THE SELECTED EMOTICON IS NOT ALREADY PRESENT
                if (reactionIndex < 0) {
                  updatedComment.reactions = [
                    ...updatedComment.reactions,
                    {
                      ...payload,
                      members: [{ ...userData }],
                    },
                  ];
                } else {
                  // IF THE SELECTED EMOTICON IS PRESENT
                  updatedComment.reactions[reactionIndex].members.push({
                    ...userData,
                  });
                }
              } else {
                // IF API CALL IS UNSETTING AN EMOTICON
                const currentReaction = updatedComment.reactions[reactionIndex];
                if (currentReaction.members.length > 1) {
                  // IF EMOTICON HAS OTHER PEOPLE WHO HAVE SELECTED IT
                  currentReaction.members = currentReaction.members.filter(
                    (member) => member.memberID !== userData.memberID,
                  );
                } else {
                  // IF USER IS THE ONLY PERSON WHO HAD SELECTED IT
                  updatedComment.reactions.splice(reactionIndex, 1);
                }
              }
            }
          });
          queryClient.setQueryData(currentQueryKeys, updatedData);
        }
      },
    },
  );
};
