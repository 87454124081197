import styled from 'styled-components';
import qs from 'qs';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react';

import SVGIcon from '../../../atomic/atoms/SVGIcon';
import TabComponent from '../../../atomic/molecules/TabComponent';

import FilterController from './FilterController';
import FlowDetailsController from './FlowDetailsController';

import { FILTER, ABOUT } from '../../../languages/en/singleWords';
import { CELEBRATE_TEAMMATE_FLOW_ID } from '../../../constants/common';
import {
  StyledWrapper,
  Wrapper,
} from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import useGetPageName from '../../../hooks/useGetPageName';
import { trackRightDrawerEvent } from '../../../Utils/analytics';
import { TabComponentProps } from '../../../atomic/molecules/TabComponent/types';
import { NotebookTaskDetailsController } from '../NotebookTaskDetailsController';
import { RightDrawerHeader } from '../../../atomic/molecules/RightDrawerHeader';
import useLayoutStore from '../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { getColorById } from '../../../Utils/flows/colors';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import useMobileApp from '../../../hooks/useMobileApp';
import ShareSheetRightDrawerController from '../ShareSheetRightDrawerController';
import { DrawerTabType } from '../../../Utils/analytics/interfaces';

const AboutTabContentWrapper = styled.div`
  max-height: calc(100vh - 124px);
  overflow-y: auto;
  padding-bottom: 64px;
`;

const FlowFeedRightDrawerController = () => {
  const { page } = useGetPageName();
  const { search } = useLocation();
  const { replace } = useHistory();
  const { showShareSheet } = useMobileApp();

  const { setRightAsideOpenToFalse, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      setRightAsideOpenToFalse: state.setRightAsideOpenToFalse,
    }),
    shallow,
  );

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const shouldShowAboutSection =
    'showAboutSection' in
      qs.parse(location.search, { ignoreQueryPrefix: true }) &&
    isRightAsideOpen;

  const handleFlowFeedRightDrawerClose = useCallback(() => {
    if (shouldShowAboutSection) {
      const params = new URLSearchParams(search);
      params.delete('showAboutSection');
      replace({
        search: params.toString(),
      });
    }
    setRightAsideOpenToFalse();
  }, [replace, search, setRightAsideOpenToFalse, shouldShowAboutSection]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    setSelectedTabIndex(isMobileView && !shouldShowAboutSection ? 1 : 0);
  }, [isMobileView, shouldShowAboutSection]);

  const [isFiltered, setIsFiltered] = useState(false);
  const { isFlowsFeedFilterApplied } = useGetParsedFilterOptions();

  useEffect(() => {
    if (isFlowsFeedFilterApplied) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabClickHandler = useCallback(
    (_event: SyntheticEvent, drawerTabViewed: string) => {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.TAB_CLICKED, {
        drawerTabViewed: drawerTabViewed as DrawerTabType,
        feedViewed: page,
      });
    },
    [page],
  );

  const onSelectTabHandler = (index: number) => {
    setSelectedTabIndex(index);
  };

  const { flowId } = useParams<{ flowId: string }>();

  const { data } = useFetchFlowDetailsQuery(flowId);

  const flowDetailsData = data?.data;

  const emoticonBg = getColorById(flowId);

  const tabComponentProps: TabComponentProps = {
    tabItems: [
      {
        tabHeader: {
          label: <span>{ABOUT}</span>,
          icon: <SVGIcon icon="info-filled" size="16px" />,
        },
        tabContent: (
          <AboutTabContentWrapper>
            <FlowDetailsController />
          </AboutTabContentWrapper>
        ),
      },
      {
        tabHeader: {
          label: <span>{FILTER}</span>,
          icon: <SVGIcon icon="filter" size="16px" />,
        },
        tabContent: (
          <Wrapper>
            <FilterController setIsFiltered={setIsFiltered} isTab />
          </Wrapper>
        ),
        isFiltered,
      },
    ],
    splitEqually: true,
    onTabClick: onTabClickHandler,
    handleSelectTab: onSelectTabHandler,
  };

  if (flowId === CELEBRATE_TEAMMATE_FLOW_ID) {
    return null;
  }

  const shouldShowTask =
    'taskId' in qs.parse(location.search, { ignoreQueryPrefix: true });

  if (shouldShowTask) {
    return <NotebookTaskDetailsController />;
  }

  if (showShareSheet && isMobileView) {
    return <ShareSheetRightDrawerController />;
  }

  return (
    <>
      {isMobileView && flowDetailsData && (
        <RightDrawerHeader
          title={flowDetailsData.name}
          emoticon={mapHexCodeToEmoticon(flowDetailsData.icon.value)}
          emoticonBg={emoticonBg}
          onCloseClick={handleFlowFeedRightDrawerClose}
        />
      )}
      <StyledWrapper>
        <TabComponent
          {...tabComponentProps}
          scrollButtons={false}
          selectedTabIndex={selectedTabIndex}
        />
      </StyledWrapper>
    </>
  );
};

export default FlowFeedRightDrawerController;
