import styled from 'styled-components';
import Button from '../../atoms/Button';

export const SortIconButton = styled(Button)`
  cursor: pointer;
`;

export const KnowledgeCenterSortWrapper = styled.div`
  padding: 0 16px 4px;
`;
