import { useCallback } from 'react';
import { CreditState } from '../../../interfaces/assembly';
import { MemberInteractionType } from '../../../interfaces/Feed';
import { LayoutStoreState } from '../../../interfaces/Layout';
import {
  useProfileInfoFetchQuery,
  useRedeemInfoFetchQuery,
} from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import { isUserAdmin } from '../../../Utils/user';
import useRepliesSidebar from '../../../hooks/useRepliesSidebar';
import { showChatsSelector } from '../../../stores/layoutStore/selectors';
import { trackDiscoverActionEvent } from '../../../Utils/analytics/discover';
import { DISCOVER_EVENTS } from '../../../Utils/analytics/constants';

const layoutSelector = (state: LayoutStoreState) =>
  state.setMemberAndToggleRightAsideOpen;

const usePrimaryHeaderController = () => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: redeemInfoData } = useRedeemInfoFetchQuery();

  const { reset } = useRepliesSidebar();
  const setShowChats = useLayoutStore(showChatsSelector);
  const setMemberAndToggleRightAsideOpen = useLayoutStore(layoutSelector);

  const onViewProfileClick = useCallback(
    (person: MemberInteractionType) => {
      reset();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [reset, setMemberAndToggleRightAsideOpen, setShowChats],
  );

  const isRedeemable = redeemInfoData ? redeemInfoData.isRedeemable : false;

  const hideRewardsLink = () => {
    if (
      profileData?.assembly.accounts.creditState === CreditState.DENIED_BY_ADMIN
    ) {
      return true;
    }
    if (
      !profileData?.member ||
      (!isRedeemable && !isUserAdmin(profileData.member))
    ) {
      return true;
    }
    if (isUserAdmin(profileData.member)) {
      return false;
    }
    return !isRedeemable;
  };

  const isCurrentUserAdmin = profileData?.member
    ? isUserAdmin(profileData?.member)
    : false;

  const trackProfileMenuOpened = () => {
    trackDiscoverActionEvent({
      action: DISCOVER_EVENTS.PROFILE_MENU_OPENED,
      v3enabled: false,
    });
  };

  return {
    currentUserData: profileData?.member,
    hideRewardsLink: hideRewardsLink(),
    isCurrentUserAdmin,
    onViewProfileClick,
    trackProfileMenuOpened,
  };
};

export default usePrimaryHeaderController;
