import { useState } from 'react';

import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import useArchiveFlowOption from '../useFlowFeedOptions/useArchiveFlowOption';

const useArchiveFlowModal = () => {
  const [flowDetails, setFlowDetails] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >();
  const flowId = flowDetails ? flowDetails.id : '';
  const {
    models: { isArchiveFlowLoading, isArchiveModalOpen },
    operations: {
      handleOnArchiveClick,
      setArchiveModalClose,
      setArchiveModalOpen,
    },
  } = useArchiveFlowOption(flowId);

  return {
    flowDetails,
    handleOnArchiveClick,
    isArchiveFlowLoading,
    isArchiveModalOpen,
    setArchiveModalClose,
    setArchiveModalOpen,
    setFlowDetails,
  };
};

export default useArchiveFlowModal;
