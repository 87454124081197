import React from 'react';
import useNotificationsButtonController from './useNotificationsButtonController';
import NotificationsButton from '../../../atomic/organism/NotificationsButton';
import useNotificationSocket from '../../../hooks/useNotificationFeedSocket';
import useFlowActivityUpdatesSocket from '../../../hooks/useFlowActivityUpdatesSocket';

const NotificationsController = () => {
  const {
    notifications,
    unreadNotificationsCount,
    isLoading,
    isError,
    hasNextPage,
    onMarkReadClick,
    handleFetchNextPage,
    handleNotificationItemClick,
    handleUnreadClick,
    handleAllMessageClick,
    isUnreadActive,
    trackNotificationMenuOpened,
    trackNotificationClicked,
  } = useNotificationsButtonController();

  useFlowActivityUpdatesSocket();
  useNotificationSocket();

  return (
    <NotificationsButton
      unreadNotificationsCount={unreadNotificationsCount}
      notificationData={notifications}
      data-testid="notificationsContent"
      isLoading={isLoading}
      onMarkReadClick={() => {
        onMarkReadClick();
      }}
      handleNotificationItemClick={(item) => {
        trackNotificationClicked();
        handleNotificationItemClick(item);
      }}
      isError={isError}
      onUnreadClick={handleUnreadClick}
      onShowAllClick={handleAllMessageClick}
      onNotificationSettingsClick={() => {}}
      handleFetchNextPage={handleFetchNextPage}
      hasNextPage={Boolean(hasNextPage)}
      isUnreadActive={isUnreadActive}
      onNotificationsButtonClick={trackNotificationMenuOpened}
    />
  );
};

export default NotificationsController;
