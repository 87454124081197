import uuid from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import pluralize from 'pluralize';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CriteriaGroup,
  CriteriaGroups,
  FlowBuilderBlockTypes,
  ParticipantsBuilderBlockData,
  VisibilityBuilderBlockData,
} from '../../../../interfaces/Flow/Builder';
import {
  LOADING_LABEL,
  PARTICIPANT,
} from '../../../../languages/en/singleWords';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import { setSpecificBlockDataSelector } from '../../../../stores/flowBuilderStore/selectors';
import { SHARE_SHEET_EVENTS } from '../../../../Utils/analytics/constants';
import { trackShareSheetActionEvent } from '../../../../Utils/analytics/shareSheet';
import { mapRulesFromCriteriaGroups } from '../../../../Utils/flows/criteria';
import {
  mapCriteriaResponseToBlockData,
  serializeVisibilityBlock,
} from '../../../../Utils/flows/builder/utils';
import { ShareSheetModalScreen, ShareSheetRuleAccessTypes } from '../types';
import { useFetchMembersBasedOnFlowCriteria } from './useFetchMembersBasedOnFlowCriteria';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import {
  getCriteriaGroup,
  getFlattenedCriteriaBasedOnUserIds,
  getOwnerOnlyCriteriaBasedOnUserId,
  getUpdatedVisibilityCriteriaGroups,
} from '../../../../Utils/flows/sharesheet';
import useRequestedUsersForAccessStore, {
  RequestedUsersForAccess,
} from '../../../../stores/sharesheetStore';
import { MemberState } from '../../../../interfaces/user';
import { getFullName } from '../../../../Utils/text';
import { visibilityTypes } from '../../../flowsBuilder/FlowsBuilderVisibilityController/data';
import { ShareSheetMemberActions } from './data';

export const useFlowsShareSheetRules = ({
  updateModalCurrentState,
  setHasModifiedChanges,
}: {
  updateModalCurrentState: (currentState: ShareSheetModalScreen) => void;
  setHasModifiedChanges: (hasDataChanged: boolean) => void;
}) => {
  const {
    requestedUsers,
    setRequestedUsers,
    setUpdatedRequestedUserID,
    updatedRequestedUserID,
    canUpdateFlowPermission,
  } = useRequestedUsersForAccessStore();

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const { isConnectionsTabTouched, flowName } = useFlowBuilderStore();

  useEffect(() => {
    if (isConnectionsTabTouched) {
      setHasModifiedChanges(true);
    }
  }, [isConnectionsTabTouched, setHasModifiedChanges]);

  const [currentVisibilityBlockData, setCurrentVisibilityBlockData] =
    useState<VisibilityBuilderBlockData | null>(null);

  const [currentParticipantsBlockData, setCurrentParticipantsBlockData] =
    useState<ParticipantsBuilderBlockData>();

  const { data: profileData } = useProfileInfoFetchQuery();

  const {
    isParticipantsCriteriaLoading,
    totalParticipantsCount,
    participantsCriteria,
    participantsBlockData,
    isViewerCriteriaLoading,
    totalViewersCount,
    visibilityBlockData,
    visibilityCriteria,
    participantsSelected,
    membersWithVisibility,
  } = useFetchMembersBasedOnFlowCriteria({
    enableFetch: true,
    isMembersOnly: false,
  });

  useEffect(() => {
    setCurrentParticipantsBlockData(cloneDeep(participantsBlockData));
  }, [participantsBlockData]);

  useEffect(() => {
    setCurrentVisibilityBlockData(cloneDeep(visibilityBlockData));
  }, [visibilityBlockData]);

  const updateParticipantsBlockData = useCallback(
    (updatedContentBlock: ParticipantsBuilderBlockData) => {
      setCurrentParticipantsBlockData(updatedContentBlock);
    },
    [],
  );

  const updateCurrentVisibilityBlockData = useCallback(
    (updatedContentBlock: VisibilityBuilderBlockData) =>
      setCurrentVisibilityBlockData(updatedContentBlock),
    [],
  );

  const existingParticipantMembersIds =
    currentParticipantsBlockData?.participantsCriteria?.groups
      .map((group) => {
        return group.groupRules.reduce<string[]>((acc, rule) => {
          if (Array.isArray(rule.value)) {
            return acc.concat(rule.value.map((val: { id: string }) => val.id));
          } else {
            return acc;
          }
        }, []);
      })
      .flat();

  const existingViewersIds = currentVisibilityBlockData?.criteriaGroups?.groups
    .map((group) => {
      return group.groupRules.reduce<string[]>((acc, rule) => {
        if (Array.isArray(rule.value)) {
          return acc.concat(rule.value.map((val: { id: string }) => val.id));
        } else {
          return acc;
        }
      }, []);
    })
    .flat();

  const getUsersToCompareFromPermission = useCallback(
    (memberID: string, permission: ShareSheetMemberActions) => {
      const memberIDsToCompare = {
        [ShareSheetMemberActions.canOnlyPost]:
          !existingParticipantMembersIds?.includes(memberID),
        [ShareSheetMemberActions.canOnlyViewPosts]:
          !existingViewersIds?.includes(memberID),
        [ShareSheetMemberActions.canPostAndViewPosts]:
          !existingViewersIds?.includes(memberID) &&
          !existingParticipantMembersIds?.includes(memberID),
      };
      return memberIDsToCompare[permission] || false;
    },
    [existingParticipantMembersIds, existingViewersIds],
  );

  const updateUsersInRequestedTab = useCallback(
    (existingMembersIds: string[], criteria: 'participant' | 'viewer') => {
      let updatedRequestedUsers: RequestedUsersForAccess[] = [];
      // to handle the case when user is removed from the participants list
      updatedRequestedUsers = requestedUsers.map((user) => {
        return user.requestStatus === 'APPROVED' &&
          getUsersToCompareFromPermission(user.memberID, user.permission)
          ? { ...user, requestStatus: 'PENDING' as const }
          : user;
      });

      // to handle the case when the user is added back to the participants list
      updatedRequestedUsers = updatedRequestedUsers.map((user) => {
        if (
          user.requestStatus === 'PENDING' &&
          existingMembersIds?.includes(user.memberID)
        ) {
          if (criteria === 'participant') {
            if (user.permission === ShareSheetMemberActions.canOnlyPost) {
              return { ...user, requestStatus: 'APPROVED' as const };
            } else if (
              (user.permission === ShareSheetMemberActions.canOnlyViewPosts ||
                user.permission ===
                  ShareSheetMemberActions.canPostAndViewPosts) &&
              existingViewersIds?.includes(user.memberID)
            ) {
              return { ...user, requestStatus: 'APPROVED' as const };
            } else {
              return user;
            }
          } else if (criteria === 'viewer') {
            if (user.permission === ShareSheetMemberActions.canOnlyViewPosts) {
              return { ...user, requestStatus: 'APPROVED' as const };
            } else if (
              (user.permission === ShareSheetMemberActions.canOnlyPost ||
                user.permission ===
                  ShareSheetMemberActions.canPostAndViewPosts) &&
              existingParticipantMembersIds?.includes(user.memberID)
            ) {
              return { ...user, requestStatus: 'APPROVED' as const };
            } else {
              return user;
            }
          }
          return user;
        } else {
          return user;
        }
      });

      if (updatedRequestedUsers) {
        setRequestedUsers(updatedRequestedUsers);
      }
    },
    [
      requestedUsers,
      getUsersToCompareFromPermission,
      existingViewersIds,
      existingParticipantMembersIds,
      setRequestedUsers,
    ],
  );

  // to handle the case when user is added to the participants list
  useEffect(() => {
    if (updatedRequestedUserID) {
      const approvedRequestedUserWithParticipantPermission =
        requestedUsers.find(
          (user) =>
            user.memberID === updatedRequestedUserID &&
            user.requestStatus === 'APPROVED' &&
            (user.permission === ShareSheetMemberActions.canOnlyPost ||
              user.permission === ShareSheetMemberActions.canPostAndViewPosts),
        );
      const isUserAlreadyAParticipant = participantsSelected?.find(
        (member) =>
          member.memberID ===
          approvedRequestedUserWithParticipantPermission?.memberID,
      );

      if (
        !isUserAlreadyAParticipant &&
        approvedRequestedUserWithParticipantPermission &&
        canUpdateFlowPermission
      ) {
        const groups: CriteriaGroup[] = [
          {
            groupId: uuid.v4(),
            groupCondition: 'or',
            groupRules: [
              {
                value: [
                  {
                    id:
                      approvedRequestedUserWithParticipantPermission?.memberID ||
                      '',
                    value:
                      approvedRequestedUserWithParticipantPermission?.name ||
                      '',
                    memberState:
                      approvedRequestedUserWithParticipantPermission?.memberState as MemberState,
                  },
                ],
                operator: 'is',
                field: 'member',
                ruleId: uuid.v4(),
              },
            ],
          },
        ];
        let newParticipantsCriteriaGroups;
        if (!participantsBlockData?.participantsCriteria) {
          newParticipantsCriteriaGroups = {
            groups,
            groupsCondition: 'or',
          };
        } else {
          newParticipantsCriteriaGroups = getUpdatedVisibilityCriteriaGroups(
            participantsBlockData.participantsCriteria,
            participantsBlockData.participantsCriteria.groups,
            groups,
          );
        }
        updateParticipantsBlockData({
          ...participantsBlockData,
          participantsCriteria: newParticipantsCriteriaGroups,
        });
        setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
          ...participantsBlockData,
          participantsCriteria: newParticipantsCriteriaGroups,
        });
        setUpdatedRequestedUserID('');
        setHasModifiedChanges(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRequestedUserID]);

  const visibilityCount = useMemo(() => {
    if (!isViewerCriteriaLoading) {
      return totalViewersCount;
    }

    return 1;
  }, [totalViewersCount, isViewerCriteriaLoading]);

  const subtitleContent = useMemo(() => {
    if (isParticipantsCriteriaLoading) {
      return LOADING_LABEL;
    }

    return totalParticipantsCount > 0
      ? `${totalParticipantsCount} ${pluralize(
          PARTICIPANT,
          totalParticipantsCount,
          false,
        )}`
      : `0 ${PARTICIPANT}`;
  }, [isParticipantsCriteriaLoading, totalParticipantsCount]);

  const handleChooseParticipants = useCallback(() => {
    updateModalCurrentState(ShareSheetModalScreen.PARTICIPANTS);
  }, [updateModalCurrentState]);

  const handleChooseViewers = useCallback(() => {
    updateModalCurrentState(ShareSheetModalScreen.VIEWERS);
  }, [updateModalCurrentState]);

  const handleVisibilityConfirmButtonClick = useCallback(() => {
    if (existingViewersIds) {
      updateUsersInRequestedTab(existingViewersIds, 'viewer');
    }
    if (currentVisibilityBlockData) {
      trackShareSheetActionEvent({
        tab: 'invite and share',
        action: SHARE_SHEET_EVENTS.VIEWERS_SELECTED,
        modalVariant: 'choose viewers',
        flowName: flowName,
        viewerCriteriaSelected: serializeVisibilityBlock(visibilityBlockData),
        ruleType: ShareSheetRuleAccessTypes.Advance,
      });
      setSpecificBlockData(
        FlowBuilderBlockTypes.VISIBILITY,
        currentVisibilityBlockData,
      );
    }

    updateModalCurrentState(ShareSheetModalScreen.HOME);
    setHasModifiedChanges(true);
  }, [
    flowName,
    existingViewersIds,
    visibilityBlockData,
    setSpecificBlockData,
    setHasModifiedChanges,
    updateModalCurrentState,
    currentVisibilityBlockData,
    updateUsersInRequestedTab,
  ]);

  const handleVisibilityBackButtonClick = useCallback(() => {
    if (visibilityBlockData)
      updateCurrentVisibilityBlockData(cloneDeep(visibilityBlockData));
    updateModalCurrentState(ShareSheetModalScreen.HOME);
  }, [
    updateCurrentVisibilityBlockData,
    updateModalCurrentState,
    visibilityBlockData,
  ]);

  const handleBackButtonClick = useCallback(() => {
    setCurrentParticipantsBlockData(cloneDeep(participantsBlockData));
    updateModalCurrentState(ShareSheetModalScreen.HOME);
  }, [participantsBlockData, updateModalCurrentState]);

  const handleConfirmButtonClick = useCallback(() => {
    const existingMembersIds =
      currentParticipantsBlockData?.participantsCriteria?.groups
        .map((group) => {
          return group.groupRules.reduce<string[]>((acc, rule) => {
            if (Array.isArray(rule.value)) {
              return acc.concat(
                rule.value.map((val: { id: string }) => val.id),
              );
            } else {
              return acc;
            }
          }, []);
        })
        .flat();
    if (existingMembersIds) {
      updateUsersInRequestedTab(existingMembersIds, 'participant');
    }
    if (currentParticipantsBlockData) {
      trackShareSheetActionEvent({
        tab: 'invite and share',
        flowName: flowName,
        action: SHARE_SHEET_EVENTS.PARTICIPANTS_SELECTED,
        modalVariant: 'choose participants',
        participantCriteriaSelected: mapRulesFromCriteriaGroups(
          participantsBlockData.participantsCriteria,
          undefined,
          false,
          true,
        ),
        ruleType: ShareSheetRuleAccessTypes.Advance,
      });
      setSpecificBlockData(
        FlowBuilderBlockTypes.PARTICIPANTS,
        currentParticipantsBlockData,
      );
    }

    updateModalCurrentState(ShareSheetModalScreen.HOME);
    setHasModifiedChanges(true);
  }, [
    flowName,
    setHasModifiedChanges,
    setSpecificBlockData,
    updateModalCurrentState,
    currentParticipantsBlockData,
    updateUsersInRequestedTab,
    participantsBlockData.participantsCriteria,
  ]);

  const resetParticipationCriteria = useCallback(() => {
    if (profileData?.member.memberId) {
      const criteria = getOwnerOnlyCriteriaBasedOnUserId(
        profileData?.member.memberId,
      );
      setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
        participantsCriteria: mapCriteriaResponseToBlockData(criteria, true),
      });
    }
  }, [profileData?.member.memberId, setSpecificBlockData]);

  const resetVisibilityCriteria = useCallback(() => {
    if (profileData?.member.memberId) {
      const criteria = getOwnerOnlyCriteriaBasedOnUserId(
        profileData?.member.memberId,
      );
      const updatedVisibilityBlockDataData = {
        criteriaGroups: mapCriteriaResponseToBlockData(criteria, true),
        everyone: false,
        onlyParticipants: false,
        onlyOwnersAndCollaborators: false,
        custom: true,
        type: 'custom',
      };
      setSpecificBlockData(
        FlowBuilderBlockTypes.VISIBILITY,
        updatedVisibilityBlockDataData,
      );
    }
  }, [profileData?.member.memberId, setSpecificBlockData]);

  const flattenParticipationCriteria = useCallback(() => {
    if (!isParticipantsCriteriaLoading) {
      const userIdsOfParticipants = participantsSelected?.map(
        (member) => member.memberId,
      );
      if (userIdsOfParticipants) {
        const criteria = getFlattenedCriteriaBasedOnUserIds(
          userIdsOfParticipants,
        );
        setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
          participantsCriteria: mapCriteriaResponseToBlockData(criteria, true),
        });
      }
    }
  }, [
    isParticipantsCriteriaLoading,
    participantsSelected,
    setSpecificBlockData,
  ]);

  const flattenVisibilityCriteria = useCallback(() => {
    if (!isViewerCriteriaLoading) {
      const userIdsOfViewers = membersWithVisibility?.map(
        (member) => member.memberId,
      );
      if (userIdsOfViewers) {
        const criteria = getFlattenedCriteriaBasedOnUserIds(userIdsOfViewers);
        const updatedVisibilityBlockDataData = {
          criteriaGroups: mapCriteriaResponseToBlockData(criteria, true),
          everyone: false,
          onlyParticipants: false,
          onlyOwnersAndCollaborators: false,
          custom: true,
          type: 'custom',
        };
        setSpecificBlockData(
          FlowBuilderBlockTypes.VISIBILITY,
          updatedVisibilityBlockDataData,
        );
      }
    }
  }, [isViewerCriteriaLoading, setSpecificBlockData, membersWithVisibility]);

  // Handle Viewers
  useEffect(() => {
    if (updatedRequestedUserID) {
      const approvedRequestedUserWithViewerPermission = requestedUsers.find(
        (user) =>
          user.memberID === updatedRequestedUserID &&
          user.requestStatus === 'APPROVED' &&
          (user.permission === ShareSheetMemberActions.canOnlyViewPosts ||
            user.permission === ShareSheetMemberActions.canPostAndViewPosts),
      );
      const isUserAlreadyAViewer = existingViewersIds?.includes(
        approvedRequestedUserWithViewerPermission?.memberID || '',
      );
      if (
        !isUserAlreadyAViewer &&
        approvedRequestedUserWithViewerPermission &&
        canUpdateFlowPermission
      ) {
        const groups = getCriteriaGroup({
          criteriaField: 'member',
          criteriaValue: [
            {
              id: approvedRequestedUserWithViewerPermission?.memberID || '',
              value: approvedRequestedUserWithViewerPermission?.name || '',
              memberState:
                approvedRequestedUserWithViewerPermission?.memberState as MemberState,
            },
          ],
        });
        let updatedVisibilityCriteriaGroups: CriteriaGroups;
        if (!visibilityBlockData?.criteriaGroups) {
          updatedVisibilityCriteriaGroups = {
            groups,
            groupsCondition: 'or',
          };
        } else {
          if (
            visibilityBlockData.onlyParticipants &&
            currentParticipantsBlockData?.participantsCriteria &&
            currentParticipantsBlockData.participantsCriteria.groups
          ) {
            updatedVisibilityCriteriaGroups =
              getUpdatedVisibilityCriteriaGroups(
                visibilityBlockData.criteriaGroups,
                currentParticipantsBlockData.participantsCriteria.groups,
                groups,
              );
          } else if (
            visibilityBlockData.onlyOwnersAndCollaborators &&
            profileData?.member.memberId
          ) {
            const ownerGroups = getCriteriaGroup({
              criteriaField: 'member',
              criteriaValue: [
                {
                  id: profileData?.member.memberId || '',
                  value: getFullName(profileData.member.profile) || '',
                  memberState: 'ACTIVE' as MemberState,
                },
              ],
            });
            updatedVisibilityCriteriaGroups =
              getUpdatedVisibilityCriteriaGroups(
                visibilityBlockData.criteriaGroups,
                ownerGroups,
                groups,
              );
          } else if (visibilityBlockData.everyone) {
            const everyoneGroup = getCriteriaGroup({
              criteriaField: 'everyone',
              criteriaValue: [],
            });
            updatedVisibilityCriteriaGroups =
              getUpdatedVisibilityCriteriaGroups(
                visibilityBlockData.criteriaGroups,
                everyoneGroup,
                groups,
              );
          } else {
            updatedVisibilityCriteriaGroups =
              getUpdatedVisibilityCriteriaGroups(
                visibilityBlockData.criteriaGroups,
                visibilityBlockData.criteriaGroups.groups,
                groups,
              );
          }
        }

        const updatedVisibilityBlockDataData: VisibilityBuilderBlockData = {
          criteriaGroups: updatedVisibilityCriteriaGroups || undefined,
          everyone: false,
          onlyParticipants: false,
          onlyOwnersAndCollaborators: false,
          custom: true,
          type: visibilityTypes.CUSTOM,
        };

        setSpecificBlockData(
          FlowBuilderBlockTypes.VISIBILITY,
          updatedVisibilityBlockDataData,
        );
        updateCurrentVisibilityBlockData(updatedVisibilityBlockDataData);
        setUpdatedRequestedUserID('');
      }
      setHasModifiedChanges(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRequestedUserID]);

  return {
    handleChooseParticipants,
    handleChooseViewers,
    participantsCriteria,
    isViewerCriteriaLoading,
    visibilityCount,
    subtitleContent,
    updateCurrentVisibilityBlockData,
    updateParticipantsBlockData,
    currentParticipantsBlockData,
    handleVisibilityConfirmButtonClick,
    handleBackButtonClick,
    handleVisibilityBackButtonClick,
    visibilityBlockData,
    participantsBlockData,
    participantsCount: totalParticipantsCount,
    currentVisibilityBlockData,
    setSpecificBlockData,
    handleConfirmButtonClick,
    totalViewersCount,
    visibilityCriteria,
    resetParticipationCriteria,
    resetVisibilityCriteria,
    flattenParticipationCriteria,
    flattenVisibilityCriteria,
  };
};
