import get from 'lodash/get';
import moment from 'moment';
import { getMonthFromDateObject } from './date';

export const checkAdmin = (role: string[]) => {
  let isAdmin = false;
  if (role && role.find((rol: string) => rol === 'Admin' || rol === 'Owner')) {
    isAdmin = true;
  }
  return isAdmin;
};

export const checkManager = (user: any) =>
  get(user, ['profile', 'isManager'], false);

export const checkIfProduction = () => import.meta.env.MODE === 'production';

export const getUserBirthday = (user: any) => {
  if (user.profile.birthday) {
    const birthDay = user.profile.birthday.date;
    const birthMonth = getMonthFromDateObject(user.profile.birthday);
    if (birthDay && birthMonth) {
      return `${birthMonth}, ${birthDay}`;
    }
  }
  return 'NA';
};

export const getUserAnniversary = (user: any) => {
  if (user.profile.hiredday) {
    const hireDay = user.profile.hiredday.date;
    const hireMonth = getMonthFromDateObject(user.profile.hiredday);
    const hireYear = user.profile.hiredday.year;
    if (hireDay && hireMonth && hireYear) {
      return moment(
        `${hireMonth} ${hireDay} ${hireYear}`,
        'MMMM D YYYY',
      ).format('MM/D/YYYY');
    }
  }
  return 'NA';
};
