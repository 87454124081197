import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FLOWS_FEEDS } from '../../../constants/routes';
import useHistoryWrapper from '../../../hooks/useHistoryWrapper';
import { useCreateOneClickFlow } from '../../../queries/Flows/Template';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import routesList from '../../../routesList';
import { FeedsContainer } from '../../../Utils/styles/display';
import { showErrorMessage } from '../../../Utils/toast';
import { isUserAdmin } from '../../../Utils/user';
import FlowsFeedPostLoader from '../FlowsFeedController/Loader';
import { authStore } from '../../../stores/authStore';

const CreateOneClickFlowController = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const history = useHistoryWrapper();
  const { isCreatingFlowForTheFirstTime } = authStore();
  const { mutate: createOneClickFlow } = useCreateOneClickFlow();
  const { data } = useProfileInfoFetchQuery();
  const isAdmin = data ? isUserAdmin(data.member) : false;

  useEffect(() => {
    if (isAdmin) {
      createOneClickFlow(
        { templateId, folderId: undefined },
        {
          onSuccess: async (res) => {
            history.push(
              FLOWS_FEEDS.replace(
                ':flowId',
                isCreatingFlowForTheFirstTime
                  ? `${res.data.flowId}?flow-created-from-template=true`
                  : `${res.data.flowId}?flow-created-from-template=true&show-share-sheet=true`,
              ),
            );
          },
          onError: () => {
            showErrorMessage(
              'Whoops! We were unable to create flow from this template, please try again',
            );
          },
        },
      );
    } else {
      showErrorMessage(
        'Whoops! you are not authorized to create flow. Please contact your admin',
      );
      history.push(routesList.HOME);
    }
    // this is intentional as we wanna call the API only on mount/when we get the profile value
  }, [isAdmin]);

  return (
    <FeedsContainer>
      <FlowsFeedPostLoader />
    </FeedsContainer>
  );
};

export default CreateOneClickFlowController;
