import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';

export const ContentWrapper = styled.div`
  background-color: ${ThemeV2.palette.gray1};
  overflow: hidden;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBody = styled(Body)`
  display: inline;
`;

export const StyledInviteMembersButton = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const MembersListWrapper = styled.div<{ isListExpanded: boolean }>`
  padding: 0px 24px 16px;
  ${({ isListExpanded }) =>
    isListExpanded &&
    `
      padding-right: 0px;

  `}
`;
MembersListWrapper.displayName = 'MembersListWrapper';

export const TopWrapper = styled.div<{ shouldShowBoxShadow: boolean }>`
  padding: 16px 24px 8px;
  box-shadow: ${({ shouldShowBoxShadow }) =>
    shouldShowBoxShadow ? '0px 2px 8px rgba(0, 0, 0, 0.15)' : null};
`;

const ASSEMBLY_MEMBERS_SECTION_HEIGHT = '590px';
export const Wrapper = styled.div`
  min-height: ${ASSEMBLY_MEMBERS_SECTION_HEIGHT};
`;
