import React from 'react';
import { makeStyles } from '@mui/styles';
import ThemeV2 from '../../../theme';

interface CircleIndicatorProps {
  checked?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  root: (props: CircleIndicatorProps) => ({
    background: props.checked
      ? ThemeV2.palette.polarGreen6
      : ThemeV2.palette.gray3,
    width: 12,
    height: 12,
    borderRadius: '100%',
    transition: '0.2s ease background',
  }),
});

const CircleIndicator = (props: CircleIndicatorProps) => {
  const { checked, className: parentClassName } = props;
  const classes = useStyles({ checked });
  const className = `${classes.root} ${parentClassName}`;
  return <div className={className} />;
};

export default CircleIndicator;
