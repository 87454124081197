import isEmpty from 'lodash/isEmpty';
import { InfiniteData } from '@tanstack/react-query';
import {
  Assignee,
  GetNotebookTaskAssigneeResponse,
  NotebookTask,
} from '../../interfaces/notebook';
import { MemberRole, MemberState } from '../../interfaces/user';
import { getProfileFullName } from '../../Utils/user';

export const getAssigneesList = ({
  taskIdFocused,
  assigneesListData,
  searchValueForAssignees,
}: {
  taskIdFocused: string;
  searchValueForAssignees: string;
  assigneesListData: InfiniteData<GetNotebookTaskAssigneeResponse> | undefined;
}) => {
  if (!isEmpty(taskIdFocused)) {
    if (assigneesListData) {
      const { pages } = assigneesListData;
      const { canEmailInvite } = pages[0];
      return canEmailInvite
        ? ([
            {
              name: `Invite and assign to “${searchValueForAssignees}”`,
              emailToInvite: searchValueForAssignees,
            },
          ] as Assignee[])
        : pages.reduce<Assignee[]>((arr, page) => [...arr, ...page.data], []);
    }
  }
};

export const getUpdatedNotebookTask = (
  inviteUsersData: { data: { addedMembers: any[] } },
  task: NotebookTask,
) => {
  const invitedMember = inviteUsersData.data.addedMembers[0];
  const { firstName, lastName, memberId } = invitedMember;
  const invitedUserName = getProfileFullName({
    firstName: firstName,
    lastName: lastName,
  });
  const updatedAssignee: Assignee = {
    name: invitedUserName,
    firstName: firstName,
    lastName: lastName,
    username:
      invitedMember.profile.firstName.charAt(0).toLowerCase() +
      invitedMember.profile.firstName.slice(1),
    memberID: memberId,
    memberState: MemberState.PENDING,
    image: '',
    pointsGiven: '0',
    totalPointsGiven: '0',
    role: [MemberRole.Employee],
  };
  return { ...task, assignedTo: updatedAssignee };
};
