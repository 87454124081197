import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import useFlowFeed from '../../../../hooks/useFlowFeed';
import {
  useGetSinglePostQuery,
  useSingleRecognitionUpdatePostReactionMutation,
} from '../../../../queries/Feed';
import routesList from '../../../../routesList';
import { FLOWS } from '../../../../constants/routes';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import useLayoutStore from '../../../../stores/layoutStore';

export const useLegacySingleFlowFeedPostController = () => {
  const history = useHistoryWrapper();
  const { search } = useLocation();
  const postId = new URLSearchParams(search).get('postId') || '';
  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;

  const { mutate: mutatePostReaction } =
    useSingleRecognitionUpdatePostReactionMutation(postId);

  const onPostDelete = useCallback(() => {
    if (isEmbeddedInMainApp) {
      window.parent.postMessage({ type: 'GO_TO_HOME_FEED' }, '*');
    } else {
      history.push(routesList.HOME);
    }
  }, [history, isEmbeddedInMainApp]);

  const { feedContextProps, isProfileInfoLoading, profileData } = useFlowFeed({
    onPostDelete,
    mutatePostReaction,
  });

  const {
    data,
    isError,
    isInitialLoading: isSinglePostLoading,
  } = useGetSinglePostQuery(postId);

  const handleEditPostClick = useCallback(
    (flow: { postId: string }) => {
      // eslint-disable-next-line max-len
      const editPostUrl = `${FLOWS}/recognition/edit/${flow.postId}?redirectUrl=${location.pathname}?postId=${flow.postId}`;
      history.push(editPostUrl);
    },
    [history],
  );

  const isLoading = isSinglePostLoading || isProfileInfoLoading;

  return {
    isError,
    isLoading,
    profileData,
    data,
    feedContextProps,
    handleEditPostClick,
  };
};
