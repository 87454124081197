export const NOTEBOOK_PAGINATION_LIMIT = 20;

export const FORMAT_DATE = 'MMMM dd, yyyy';

export const UNASSIGNED_MEMBER_ID = '000000000000000000000000';

export enum DefinedNotebookSections {
  NoSection = 'no_section',
  Completed = 'completed',
}
