import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import {
  InfiniteData,
  Query,
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  DELETE_NOTEBOOK_BOARD_SECTIONS,
  GET_FLOW_FEED,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_NOTEBOOK_BOARD_SECTIONS,
  GET_NOTEBOOK_BOARD_TASKS,
  GET_NOTEBOOK_RIGHT_DRAWER_TASK,
  GET_NOTEBOOK_RIGHT_DRAWER_TASK_SECTION,
  GET_NOTEBOOK_SECTION,
  GET_NOTEBOOK_TASK_ASSIGNEE_LIST,
  GET_NOTEBOOK_TASK_HISTORY,
  GET_NOTEBOOK_TODO_COUNT,
  GET_NOTEBOOK_UNIFIED_SECTION,
  GET_PROFILE_FEED,
  POST_NOTEBOOK_SECTION,
  POST_NOTEBOOK_TASKS,
  PUT_NOTEBOOK_MARK_TASKS_AS_READ,
  SEARCH_NOTEBOOK_TASKS,
} from '../../constants/endpoints';
import { NOTEBOOK_PAGINATION_LIMIT } from '../../constants/notebook';
import { GetFeedResponse } from '../../interfaces/Feed';
import {
  GetNotebookBoardSectionDataResponse,
  GetNotebookSectionResponse,
  GetNotebookTaskAssigneeResponse,
  GetTaskHistoryLogResponse,
  NotebookTabs,
  NotebookViews,
  StatusPayload,
  TaskCategories,
  TaskFromAPI,
  TaskPayload,
} from '../../interfaces/notebook';
import { FeedResponse, FlowFeedResponse } from '../Flows/Feed/interfaces';
import { PaginationResponse } from '../Flows/interfaces';
import { makeAPICall, makeAPICallWithDataReturn } from '../utils';

export const useUpdateTasksAsRead = () =>
  useMutation((taskIds: string[]) =>
    makeAPICall(PUT_NOTEBOOK_MARK_TASKS_AS_READ, { taskIds }),
  );

export const invalidateTaskHistoryQuery = (
  client: QueryClient,
  taskId: string,
) => {
  client.invalidateQueries([GET_NOTEBOOK_TASK_HISTORY, taskId]);
};

export const refetchSectionalTaskQuery = (
  client: QueryClient,
  sectionId: string,
) => {
  client.refetchQueries([GET_NOTEBOOK_BOARD_TASKS, sectionId]);
};

export const updateFeedQueryCachesAfterTaskUpdate = (
  client: QueryClient,
  updatedTask: TaskFromAPI,
) => {
  client.setQueriesData(
    {
      predicate: (query: Query) => {
        const firstKey = query.queryKey[0];

        return (
          firstKey === GET_MAIN_FEED ||
          firstKey === GET_PROFILE_FEED ||
          firstKey === GET_FLOW_FEED
        );
      },
    },
    (oldData) => {
      return (
        oldData &&
        produce(
          oldData,
          (
            draft: InfiniteData<
              GetFeedResponse | PaginationResponse<FlowFeedResponse>
            >,
          ) => {
            draft.pages.forEach((page) => {
              page.data.forEach((feedPost) => {
                let responses: FeedResponse[] = [];

                if ('flowResponse' in feedPost) {
                  responses = feedPost.flowResponse?.responses || [];
                } else if ('responses' in feedPost) {
                  responses = feedPost.responses || [];
                }

                responses.forEach((resp) => {
                  const tasksInResp = resp.response?.tasks || [];
                  const indexOfTask = tasksInResp.findIndex(
                    (task) => task.id === updatedTask.id,
                  );

                  if (indexOfTask >= 0) {
                    tasksInResp.splice(indexOfTask, 1, updatedTask);
                  }
                });
              });
            });
          },
        )
      );
    },
  );
};

export const updateFlowResponseQueryCachesAfterTaskUpdate = (
  client: QueryClient,
  updatedTask: TaskFromAPI,
) => {
  client.setQueriesData(
    {
      predicate: (query: Query) => {
        const firstKey = query.queryKey[0];

        return firstKey === GET_FLOW_RESPONSE;
      },
    },
    (oldData) => {
      return (
        oldData &&
        produce(oldData, (draft: FlowFeedResponse) => {
          (draft.responses || []).forEach((resp) => {
            const tasksInResp = resp.response?.tasks || [];
            const indexOfTask = tasksInResp.findIndex(
              (task) => task.id === updatedTask.id,
            );

            if (indexOfTask >= 0) {
              tasksInResp.splice(indexOfTask, 1, updatedTask);
            }
          });
        })
      );
    },
  );
};

export const invalidateTaskRightDrawerQuery = (
  client: QueryClient,
  taskId: string,
) => {
  client.invalidateQueries([GET_NOTEBOOK_RIGHT_DRAWER_TASK_SECTION, taskId]);
};

export const useGetNotebookSectionQuery = (
  type: TaskCategories,
  tabType: NotebookTabs,
  limit = 20,
  enabled = true,
) =>
  useInfiniteQuery<GetNotebookSectionResponse>(
    [GET_NOTEBOOK_SECTION, type, tabType],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_SECTION,
        undefined,
        {
          limit,
          ...(!isEmpty(pageParam) ? { cursor: pageParam } : {}),
        },
        {
          section: type.toLowerCase(),
          tab: tabType.toLowerCase(),
        },
      ),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );

export const useGetNotebookViewSectionQuery = (
  type: TaskCategories,
  limit = 20,
  enabled = true,
) =>
  useInfiniteQuery<GetNotebookSectionResponse>(
    [GET_NOTEBOOK_UNIFIED_SECTION, type],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_UNIFIED_SECTION,
        undefined,
        {
          limit,
          ...(!isEmpty(pageParam) ? { cursor: pageParam } : {}),
        },
        {
          section: type.toLowerCase(),
        },
      ),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );

export const usePostNotebookTasksMutation = (view: NotebookViews) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ payload }: { payload: TaskPayload[] }) => {
      return makeAPICall(
        POST_NOTEBOOK_TASKS,
        {
          data: payload,
        },
        undefined,
        {
          view,
        },
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_NOTEBOOK_TODO_COUNT]);
        queryClient.invalidateQueries([SEARCH_NOTEBOOK_TASKS]);
      },
    },
  );
};

export const useGetNotebookTodoCountQuery = () =>
  useQuery([GET_NOTEBOOK_TODO_COUNT], () =>
    makeAPICallWithDataReturn(GET_NOTEBOOK_TODO_COUNT, undefined),
  );

export const useGetNotebookRightDrawerTaskQuery = (
  taskIds: string[],
  enabled: boolean,
  onError?: (error: unknown) => void,
) => {
  return useQuery(
    [GET_NOTEBOOK_RIGHT_DRAWER_TASK, taskIds],
    () =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_RIGHT_DRAWER_TASK,
        undefined,
        {
          taskIds: taskIds.join(','),
        },
        undefined,
      ),
    {
      enabled,
      onError,
    },
  );
};

export const useGetNotebookRightDrawerTaskSectionQuery = (
  taskId: string,
  enabled: boolean,
  onError?: (error: unknown) => void,
) => {
  return useQuery(
    [GET_NOTEBOOK_RIGHT_DRAWER_TASK_SECTION, taskId],
    () =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_RIGHT_DRAWER_TASK_SECTION,
        undefined,
        undefined,
        {
          taskId,
        },
      ),
    {
      enabled,
      onError,
    },
  );
};

export const prefetchOtherNotebookTab = (
  queryClient: QueryClient,
  queryKey: string[],
  type: TaskCategories,
  prefetchTab: NotebookTabs,
) =>
  queryClient.prefetchInfiniteQuery(queryKey, () =>
    makeAPICallWithDataReturn(
      GET_NOTEBOOK_SECTION,
      undefined,
      {
        limit: NOTEBOOK_PAGINATION_LIMIT,
      },
      {
        section: type.toLowerCase(),
        tab: prefetchTab.toLowerCase(),
      },
    ),
  );

export const useGetNotebookTaskAssigneeList = (
  taskId: string,
  enabled: boolean,
  searchValueForAssignees: string,
) => {
  return useInfiniteQuery<GetNotebookTaskAssigneeResponse>(
    [GET_NOTEBOOK_TASK_ASSIGNEE_LIST, taskId, searchValueForAssignees],
    (data) => {
      const { pageParam = '' } = data;
      return makeAPICallWithDataReturn(
        GET_NOTEBOOK_TASK_ASSIGNEE_LIST,
        undefined,
        !isEmpty(pageParam)
          ? {
              cursor: pageParam,
            }
          : undefined,
        {
          taskId,
          keyword: encodeURIComponent(searchValueForAssignees),
        },
      );
    },
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useGetNotebookTaskHistoryQuery = (
  taskId: string,
  sort: string,
  enabled = true,
) => {
  return useInfiniteQuery<GetTaskHistoryLogResponse>(
    [GET_NOTEBOOK_TASK_HISTORY, taskId, sort],
    (data) => {
      const { pageParam = '' } = data;
      return makeAPICallWithDataReturn(
        GET_NOTEBOOK_TASK_HISTORY,
        undefined,
        {
          limit: 10,
          cursor: !isEmpty(pageParam) ? pageParam : undefined,
          sortBy: sort,
        },
        {
          taskId,
        },
      );
    },
    {
      enabled: !isEmpty(taskId) && enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useGetNotebookBoardSectionsQuery = (limit = '', enabled = true) =>
  useInfiniteQuery<GetNotebookBoardSectionDataResponse>(
    [GET_NOTEBOOK_BOARD_SECTIONS],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_NOTEBOOK_BOARD_SECTIONS, undefined, {
        ...(!isEmpty(limit) ? { limit } : {}),
        ...(!isEmpty(pageParam) ? { cursor: pageParam } : {}),
      }),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );

export const useGetNotebookBoardViewTasksQuery = (
  status: string,
  limit = '',
  enabled = true,
  lastNDays?: string | undefined,
) =>
  useInfiniteQuery<GetNotebookSectionResponse>(
    [GET_NOTEBOOK_BOARD_TASKS, status, lastNDays],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_NOTEBOOK_BOARD_TASKS,
        undefined,
        {
          sectionId: status.toLowerCase(),
          ...(!isEmpty(limit) ? { limit } : {}),
          ...(!isEmpty(pageParam) ? { cursor: pageParam } : {}),
          ...(!isEmpty(lastNDays) ? { lastNDays } : {}),
        },
        {},
      ),
    {
      enabled,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );

export const useSearchNotebookTasksQuery = (keyword: string) =>
  useQuery([SEARCH_NOTEBOOK_TASKS, keyword], () =>
    makeAPICallWithDataReturn(SEARCH_NOTEBOOK_TASKS, undefined, { keyword }),
  );

export const usePostNotebookSectionsMutation = (
  onSuccess: (sectionId: string, payload: StatusPayload) => void,
) => {
  return useMutation(
    ({ payload }: { payload: StatusPayload }) => {
      return makeAPICall(
        POST_NOTEBOOK_SECTION,
        {
          ...payload,
        },
        undefined,
        undefined,
      );
    },
    {
      onSuccess: (response, { payload }) => {
        onSuccess(response?.data?.id, payload);
      },
    },
  );
};

export const useDeleteNotebookSectionMutation = (
  handleSectionDeleteMutationSuccess: (section: string) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({
      targetSectionId = null,
      deletedSectionId,
      archiveAllTasks = false,
    }: {
      targetSectionId?: string | null;
      deletedSectionId: string;
      archiveAllTasks?: boolean;
    }) => {
      return makeAPICall(
        DELETE_NOTEBOOK_BOARD_SECTIONS,
        {
          ...(targetSectionId !== null && { moveTo: targetSectionId }),
          archiveAllTasks,
        },
        undefined,
        {
          sectionId: deletedSectionId,
        },
      );
    },
    {
      onSuccess: (response, { targetSectionId = null, deletedSectionId }) => {
        handleSectionDeleteMutationSuccess(deletedSectionId);
        if (targetSectionId !== null) {
          queryClient.refetchQueries([
            GET_NOTEBOOK_BOARD_TASKS,
            targetSectionId,
          ]);
        }
      },
    },
  );
};
