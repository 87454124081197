import React from 'react';
import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import BasicModalTemplate from '../../templates/BasicModalTemplate';

import { StyledBody } from '../FlowEndOccurrenceModal/styles';
import { FlowsBuilderDataChangeConfirmationModalProps } from './types';

import {
  FLOW_DATA_CHANGES_MODAL,
  REVERT_CHANGES_LABEL,
} from '../../../languages/en/flows';
import { StyledClickable } from '../../atoms/Clickable_V2';
import { ACCEPT } from '../../../languages/en/singleWords';

const StyledBasicModalTemplate = styled(BasicModalTemplate)`
  width: 500px;
  height: 535px;
  max-width: unset;
`;

const ListWrapper = styled.div`
  margin-top: 8px;
  margin-left: -24px;
`;

const DownloadButton = styled(StyledClickable)`
  margin-left: 5px;
  color: ${ThemeV2.palette.geekBlue6};
`;

const FlowsBuilderDataChangeConfirmationModal = (
  props: FlowsBuilderDataChangeConfirmationModalProps,
) => {
  const {
    isOpen,
    onModalClose,
    onAcceptClick,
    onDownloadClick,
    onRevertChangesClick,
  } = props;

  return (
    <StyledBasicModalTemplate
      isOpen={isOpen}
      heading={FLOW_DATA_CHANGES_MODAL.HEADING}
      primaryButton={{
        text: ACCEPT,
        status: 'default',
        onClick: onAcceptClick,
      }}
      secondaryButton={{
        text: REVERT_CHANGES_LABEL,
        status: 'warning',
        onClick: onRevertChangesClick,
      }}
      onModalClose={onModalClose}
    >
      <>
        <StyledBody variant="body2">
          {FLOW_DATA_CHANGES_MODAL.CONTENT_TITLE_LABEL}
        </StyledBody>
        <br />
        <StyledBody variant="body2">
          {FLOW_DATA_CHANGES_MODAL.CONTENT_SUB_TITLE_LABEL}
          <ListWrapper>
            <ol>
              <li>{FLOW_DATA_CHANGES_MODAL.CONTENT_CONDITION_LABEL_1}</li>
              <li>{FLOW_DATA_CHANGES_MODAL.CONTENT_CONDITION_LABEL_2}</li>
              <li>{FLOW_DATA_CHANGES_MODAL.CONTENT_CONDITION_LABEL_3}</li>
              <li>{FLOW_DATA_CHANGES_MODAL.CONTENT_CONDITION_LABEL_4}</li>
            </ol>
          </ListWrapper>
        </StyledBody>

        <StyledBody variant="body2">
          {FLOW_DATA_CHANGES_MODAL.CONTENT_DATA_DOWNLOAD_TEXT}
          <DownloadButton
            onClick={onDownloadClick}
            color={ThemeV2.palette.geekBlue6}
          >
            {FLOW_DATA_CHANGES_MODAL.DOWNLOAD_LABEL}
          </DownloadButton>
        </StyledBody>
      </>
    </StyledBasicModalTemplate>
  );
};

export default FlowsBuilderDataChangeConfirmationModal;
