import format from 'date-fns/format';
import { BaseEmoji, CustomEmoji } from 'emoji-mart';
import _ from 'lodash';
import { createContext } from 'react';

import { RRule } from 'rrule';
import { FeedContextState, FlowBannerStatus } from '../../../interfaces/Feed';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { FlowPermissions } from '../../analytics/interfaces';
import {
  getUTCDateFromDate,
  getUTCTimeFromDate,
} from '../scheduler/dateAndTime';
import { getDateMonthYear, getTime } from '../scheduler/formRRulString';

const defaultFn = _.noop;

export const FeedContext = createContext<FeedContextState>({
  onReactionSet: defaultFn,
  onReactionUnSet: defaultFn,
  onMemberClick: defaultFn,
  onFlowClick: defaultFn,
  onDeleteCommentClick: defaultFn,
  onCommentEmoticonClick: defaultFn,
  onCommentReactionToggleClick: defaultFn,
  onDeletePost: defaultFn,
  onCopyCommentLink: defaultFn,
  onDownloadFileClick: defaultFn,
});

export const mapEmojiToReaction = (emoji: BaseEmoji | CustomEmoji) => {
  const customEmoji = { ...emoji } as CustomEmoji;

  if (customEmoji.imageUrl) {
    return {
      type: ReactionType.CUSTOM,
      name: customEmoji.colons || '',
      value: customEmoji.imageUrl,
      displayName: customEmoji.name,
    };
  }

  const baseEmoji = { ...emoji } as BaseEmoji;
  return {
    type: ReactionType.REGULAR,
    name: baseEmoji.colons,
    value: '',
    displayName: baseEmoji.id,
  };
};

export const getFlowPerms = (
  data:
    | {
        isFlowOwner: boolean;
        isFlowViewer: boolean;
        isFlowParticipant: boolean;
      }
    | undefined,
) => {
  const flowPerms: FlowPermissions[] = [];
  if (data) {
    if (data.isFlowOwner) {
      flowPerms.push('owner');
    }
    if (data.isFlowViewer) {
      flowPerms.push('viewer');
    }
    if (data.isFlowParticipant) {
      flowPerms.push('participant');
    }
  }
  return flowPerms;
};

export const getFormattedDateAndTimeFromUtcTime = (
  rrule: string | undefined,
) => {
  if (!rrule) {
    return {
      formattedDate: '',
      formattedTime: '',
    };
  }

  if (rrule !== '') {
    const parsedString = RRule.parseString(rrule);
    if (parsedString.dtstart) {
      const dateDetails = getDateMonthYear(
        getUTCDateFromDate(parsedString.dtstart),
      );
      const timeDetails = getTime(getUTCTimeFromDate(parsedString.dtstart));
      if (dateDetails !== null && timeDetails !== null) {
        const scheduledDate = new Date(
          dateDetails.year,
          dateDetails.month,
          dateDetails.date,
          timeDetails.hours,
          timeDetails.minutes,
        );
        const formattedDate = format(scheduledDate, 'MMMM d, yyyy');
        const formattedTime = format(scheduledDate, 'h:mm a');
        const formattedFullDate = format(
          scheduledDate,
          "EEEE, LLLL do y 'at' hh:mmaaa",
        );
        return {
          formattedDate,
          formattedTime,
          formattedFullDate,
        };
      }
    }
  }
  return {
    formattedDate: '',
    formattedTime: '',
  };
};

export const getFormattedNextTriggerDateAndTime = (
  nextTimeInUTC: string | undefined,
) => {
  if (!nextTimeInUTC) {
    return {
      formattedNextTriggerDate: '',
      formattedNextTriggerTime: '',
    };
  }
  const nextTriggerDate = new Date(nextTimeInUTC);
  const formattedNextTriggerDate = format(nextTriggerDate, 'MMMM d, yyyy');
  const formattedNextTriggerTime = format(nextTriggerDate, 'h:mm a');
  return {
    formattedNextTriggerDate,
    formattedNextTriggerTime,
  };
};

export const getFlowBannerStatus = (flowId: string) => {
  const flowBannerStatus = localStorage.getItem(flowId);
  if (flowBannerStatus) {
    const parsedFlowBannerStatus: FlowBannerStatus =
      JSON.parse(flowBannerStatus);
    return {
      canDisplayBanner: parsedFlowBannerStatus?.canDisplayBanner,
      viewCount: parsedFlowBannerStatus?.viewCount,
    };
  }
  return { canDisplayBanner: false, viewCount: 0 };
};

export const setFlowBannerStatus = (
  flowId: string,
  updateBannerStatus?: {
    showBanner: boolean;
    viewCount?: number;
  },
) => {
  if (updateBannerStatus) {
    localStorage.setItem(
      flowId,
      JSON.stringify({
        canDisplayBanner: updateBannerStatus.showBanner,
        viewCount: updateBannerStatus.viewCount || 0,
      }),
    );
  } else {
    localStorage.setItem(
      flowId,
      JSON.stringify({
        canDisplayBanner: true,
        viewCount: 0,
      }),
    );
  }
};

export const updateFlowBannerViewCount = (flowId: string) => {
  const { viewCount } = getFlowBannerStatus(flowId);
  if (viewCount) {
    if (viewCount < 3) {
      setFlowBannerStatus(flowId, {
        showBanner: true,
        viewCount: viewCount + 1,
      });
    } else {
      setFlowBannerStatus(flowId, { showBanner: false });
    }
  } else {
    setFlowBannerStatus(flowId, { showBanner: true, viewCount: 1 });
  }
};
