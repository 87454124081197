import { useMemo } from 'react';
import { AnonymousSettings } from '../../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import {
  ShareSheetSettings,
  ResponseSettingsAppButton,
  ChangeResponseSettingsButton,
} from '../../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/styles';
import { ShareSheetModalScreen } from '../../../controllers/flows/FlowsShareSheetController/types';
import { SHARE_SHEET_MODAL } from '../../../languages/en/flows/builder';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import { MenuItemIndividualItem } from '../../molecules/Dropdown_V2/interfaces';

export const ResponseAnonymitySettings = ({
  isMobileView,
  anonymousSettings,
  updateModalCurrentState,
  isSimplifiedShareSheetTreatmentOn,
}: {
  isMobileView: boolean;
  anonymousSettings: MenuItemIndividualItem;
  updateModalCurrentState: (currentState: ShareSheetModalScreen) => void;
  isSimplifiedShareSheetTreatmentOn: boolean;
}) => {
  const anonymousSettingsSubtitle = useMemo(() => {
    switch (anonymousSettings?.id) {
      case AnonymousSettings.ANONYMOUS_ON:
        return SHARE_SHEET_MODAL.ANONYMOUS;
      case AnonymousSettings.ANONYMOUS_OFF:
        return SHARE_SHEET_MODAL.NOT_ANONYMOUS;
      case AnonymousSettings.ANONYMOUS_OPTIONAL:
        return SHARE_SHEET_MODAL.OPTIONALLY_ANONYMOUS;
      default:
        return '';
    }
  }, [anonymousSettings?.id]);

  return (
    <>
      <Flex
        alignItems={isMobileView ? 'baseline' : 'center'}
        justifyContent="space-between"
        flexDirection={isMobileView ? 'column' : 'row'}
        width={isMobileView ? '100%' : 'auto'}
      >
        <div>
          <Body variant="body1Medium" color="gray9">
            {isSimplifiedShareSheetTreatmentOn
              ? SHARE_SHEET_MODAL.POST_SETTINGS
              : SHARE_SHEET_MODAL.RESPONSE_SETTINGS}
          </Body>
          <Flex alignItems="flex-start">
            <Body variant="body2" color="gray9" margin="4px 0">
              {isSimplifiedShareSheetTreatmentOn
                ? SHARE_SHEET_MODAL.POSTS_ARE
                : SHARE_SHEET_MODAL.RESPONSES_ARE}
            </Body>
            <ShareSheetSettings
              onClick={() =>
                updateModalCurrentState(
                  ShareSheetModalScreen.ANONYMOUS_SETTINGS,
                )
              }
            >
              <Body
                variant="body2Medium"
                color="geekBlue6"
                margin="4px 0 0 4px"
              >
                {anonymousSettingsSubtitle}
              </Body>
            </ShareSheetSettings>
          </Flex>
        </div>

        {isMobileView ? (
          !isSimplifiedShareSheetTreatmentOn && (
            <Flex width="100%">
              <ResponseSettingsAppButton
                color="secondary"
                size="medium"
                variant="text"
                onClick={() =>
                  updateModalCurrentState(
                    ShareSheetModalScreen.ANONYMOUS_SETTINGS,
                  )
                }
              >
                {!isSimplifiedShareSheetTreatmentOn &&
                  SHARE_SHEET_MODAL.CHANGE_SETTINGS}
              </ResponseSettingsAppButton>
            </Flex>
          )
        ) : (
          <ShareSheetSettings>
            <ChangeResponseSettingsButton
              color="secondary"
              size="small"
              variant="text"
              onClick={() =>
                updateModalCurrentState(
                  ShareSheetModalScreen.ANONYMOUS_SETTINGS,
                )
              }
            >
              {SHARE_SHEET_MODAL.CHANGE_SETTINGS}
            </ChangeResponseSettingsButton>
          </ShareSheetSettings>
        )}
      </Flex>
    </>
  );
};
