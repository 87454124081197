import React from 'react';

import Body from '../../atoms/Body';
import QuickStatsLoader from './Loader';
import { StyledFlex, StyledHeading } from './styles';

import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import { ComponentStatus } from '../../../interfaces/component';
import { QuickStatsProps } from './interfaces';

const QuickStats = (props: QuickStatsProps) => {
  switch (props.status) {
    case ComponentStatus.LOADING: {
      return <QuickStatsLoader />;
    }
    case ComponentStatus.EMPTY: {
      const { assemblyCurrency, title } = props;
      return (
        <StyledFlex padding="12px" justifyContent="center">
          <StyledHeading variant="h5">
            <>
              {assemblyCurrency && (
                <AssemblyCurrencyIcon
                  id={assemblyCurrency.name}
                  currencyName={assemblyCurrency.name}
                  assemblyCurrency={assemblyCurrency}
                  size="24px"
                  dataQaId="assemblyCurrencyIcon"
                />
              )}
              0
            </>
          </StyledHeading>
          <Body variant="body2">{title}</Body>
        </StyledFlex>
      );
    }
    default: {
      const { title, value, assemblyCurrency, className } = props;
      return (
        <StyledFlex
          padding="12px"
          justifyContent="center"
          className={className}
          data-qa-id="pointsRedeemedFlex"
        >
          <StyledHeading variant="h5">
            <>
              {assemblyCurrency && (
                <AssemblyCurrencyIcon
                  id={assemblyCurrency.name}
                  currencyName={assemblyCurrency.name}
                  assemblyCurrency={assemblyCurrency}
                  size="24px"
                  dataQaId="assemblyCurrencyIcon"
                />
              )}
              {value}
            </>
          </StyledHeading>
          <Body variant="body2">{title}</Body>
        </StyledFlex>
      );
    }
  }
};

export default QuickStats;
