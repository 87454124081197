/* eslint-disable react/button-has-type */
import React, { useCallback, useMemo, useState } from 'react';
import IconButton from '../../../atoms/IconButton_V2';
import { Flex } from '../../../../Utils/styles/display';
import DropdownIconButton from '../../../molecules/DropdownIconButton';
import FlowBuilderBlockSettings from '../../FlowBuilderBlockSettings';

import { dropdownOptions } from './data';
import {
  InputBlockWrapper,
  ContentFlex,
  ContentWrapperFlex,
  StyledDivider,
  StyledInfoAlert,
  SettingsContainer,
} from './styles';
import ThemeV2 from '../../../../theme';
import { PLACEHOLDERS } from '../../../../languages/en/flows/builder';
import {
  BlockBuilderSettingState,
  ContentBlockState,
} from '../../../../interfaces/Flow/Builder';
import { FlowBlockAPITypes } from '../../../../interfaces/Flow';
import { AssemblyCurrency } from '../../../../interfaces/assembly';
import TextEditor from '../../../atoms/TextEditor';

interface FormsEditorBaseInputBlockProps {
  blockSettingState: BlockBuilderSettingState;
  bannerText?: React.ReactNode;
  blockData: ContentBlockState;
  children: React.ReactNode;
  onDeleteClick: (
    blockId: string,
    blockType: FlowBlockAPITypes,
    isLinkedBlock?: boolean,
  ) => void;
  onMoveUpClick: (blockId: string) => void;
  onMoveDownClick: (blockId: string) => void;
  onValueChange: (updatedBlock: ContentBlockState) => void;
  currentIndex: number;
  totalNumberOfItems: number;
  assemblyCurrency: AssemblyCurrency;
}

const customBannerVariant = {
  background: ThemeV2.palette.geekBlue1,
  border: ThemeV2.palette.geekBlue1,
  color: ThemeV2.palette.geekBlue6,
  focusColor: ThemeV2.palette.geekBlue1,
  iconColor: ThemeV2.palette.geekBlue6,
};

const placeholder = PLACEHOLDERS.DEFAULT_ADD_BLOCK_TITLE_TEXT;

const FormsEditorBaseInputBlock = ({
  totalNumberOfItems,
  onValueChange,
  onMoveUpClick,
  onMoveDownClick,
  onDeleteClick,
  currentIndex,
  blockSettingState,
  children,
  bannerText,
  blockData,
  assemblyCurrency,
}: FormsEditorBaseInputBlockProps) => {
  const { id, title, description, type, isLinkedBlock = false } = blockData;

  const [isFocus, toggleShowBlockIconsOnFocus] = useState(false);
  const [isHover, toggleShowBlockIconsOnHover] = useState(false);

  const handleTitleChange = useCallback(
    (updatedTitle: string) => {
      onValueChange({
        ...blockData,
        title: updatedTitle,
      });
    },
    [blockData, onValueChange],
  );

  const handleDescriptionChange = (updatedDescription: string) => {
    onValueChange({
      ...blockData,
      description: updatedDescription,
    });
  };

  const isDescriptionNeeded = useMemo(
    () => description !== null,
    [description],
  );

  const handleOnMoveUpClick = () => {
    onMoveUpClick(id);
  };

  const handleOnMoveDownClick = () => {
    onMoveDownClick(id);
  };

  const handleOnDeleteClick = () => {
    onDeleteClick(id, type, isLinkedBlock);
  };

  return (
    <InputBlockWrapper
      onMouseEnter={() => toggleShowBlockIconsOnHover(true)}
      onMouseLeave={() => toggleShowBlockIconsOnHover(false)}
    >
      <Flex alignItems="flex-start">
        <Flex flexDirection="column" alignItems="flex-start">
          <IconButton
            rounded
            iconBgColor="white"
            icon="arrow-up"
            dataTestId="arrow-up"
            disabled={currentIndex === 0}
            onClick={handleOnMoveUpClick}
          />
          <IconButton
            rounded
            iconBgColor="white"
            icon="arrow-down"
            dataTestId="arrow-down"
            onClick={handleOnMoveDownClick}
            disabled={
              !totalNumberOfItems || currentIndex === totalNumberOfItems - 1
            }
          />
        </Flex>
        <ContentWrapperFlex flexDirection="column" alignItems="flex-start">
          {bannerText && (
            <StyledInfoAlert
              id={+new Date()}
              text={bannerText}
              icon="Link"
              alertType="custom"
              bodyVariant="body3"
              customVariant={customBannerVariant}
            />
          )}
          <ContentFlex flex={1}>
            <Flex
              width="100%"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <TextEditor
                onFocus={() => {
                  toggleShowBlockIconsOnFocus(true);
                }}
                onBlur={() => {
                  toggleShowBlockIconsOnFocus(false);
                }}
                autoFocus
                autoComplete={false}
                required={blockSettingState.isRequired}
                placeholder={placeholder}
                value={title}
                onChange={(value: string) => {
                  handleTitleChange(value);
                }}
                dataTestId="title-input"
                className="forms-builder-title"
              />
              <SettingsContainer
                onFocus={() => {
                  toggleShowBlockIconsOnFocus(true);
                }}
                onBlur={() => {
                  toggleShowBlockIconsOnFocus(false);
                }}
                alignItems="stretch"
              >
                {(isFocus || isHover) && (
                  <>
                    <FlowBuilderBlockSettings
                      assemblyCurrency={assemblyCurrency}
                      blockSettingState={blockSettingState}
                    />
                    <div>
                      <DropdownIconButton
                        menuItems={dropdownOptions}
                        icon="more"
                        title="more"
                        onItemClick={handleOnDeleteClick}
                        iconColor={ThemeV2.palette.gray8}
                      />
                    </div>
                  </>
                )}
              </SettingsContainer>
            </Flex>
          </ContentFlex>
          <ContentFlex width="100%">
            {isDescriptionNeeded && (
              <TextEditor
                onFocus={() => {
                  toggleShowBlockIconsOnFocus(true);
                }}
                onBlur={() => toggleShowBlockIconsOnFocus(false)}
                autoFocus
                autoComplete={false}
                placeholder={placeholder}
                value={description || ''}
                onChange={(value: string) => {
                  handleDescriptionChange(value);
                }}
                required={false}
                dataTestId="description-input"
                className="forms-builder-description"
                textVariant="body2"
              />
            )}
          </ContentFlex>
          <ContentFlex
            onFocus={() => {
              toggleShowBlockIconsOnFocus(true);
            }}
            onBlur={() => {
              toggleShowBlockIconsOnFocus(false);
            }}
          >
            {children}
          </ContentFlex>
        </ContentWrapperFlex>
      </Flex>
      <StyledDivider isFullWidth color={ThemeV2.palette.gray4} />
    </InputBlockWrapper>
  );
};

export default FormsEditorBaseInputBlock;
