import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import Image from '../../../atomic/atoms/Image';
import { Flex, FluidContainer } from '../../../Utils/styles/display';
import PeopleDirectoryLoadingImage from '../../../img/opening-folder.gif';

const StyledImage = styled(Image)`
  margin: 48px 0;
`;

export const Loader = () => {
  return (
    <FluidContainer contentPadding="16px">
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="loading-people-directory"
      >
        <StyledImage
          alt="Loading your people..."
          src={PeopleDirectoryLoadingImage}
        />

        <Body variant="subHead2Bold">Loading your people...</Body>

        <Body variant="body1">Beaming people up now.</Body>
      </Flex>
    </FluidContainer>
  );
};
