import styled from 'styled-components';

import Body from '../../atoms/Body';
import Input from '../../molecules/Input';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import BasicModalTemplate from '../../templates/BasicModalTemplate';
import MembersListEntryLoader from '../../molecules/MembersListEntry/Loader';

export const StyledBody = styled(Body)`
  padding: 4px 8px;
`;

export const StyledFlex = styled(Flex)`
  padding-top: 8px;
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  box-sizing: border-box;
  justify-content: flex-start;
`;

export const StyledWrapper = styled.div`
  padding-bottom: 16px;
`;

export const StyledInput = styled(Input)`
  margin-right: 8px;
  width: 72px;
  &.MuiFormControl-fullWidth {
    width: 72px;
  }

  & .MuiFormHelperText-root {
    position: absolute;
    margin-left: 0px;
    margin-top: 44px;
    white-space: nowrap;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 256px;
`;

export const LeftPanelWrapper = styled.div`
  padding: 24px 32px 32px 32px;
`;

export const DescriptionBody = styled(Body)`
  padding: 16px;
`;

export const RightPanelWrapper = styled.div`
  padding: 24px;
  background-color: ${ThemeV2.palette.gray3};
  border-left: 1px solid ${ThemeV2.palette.gray4};
  max-height: 400px;
  overflow-y: auto;
`;

export const StyledBasicModalTemplate = styled(BasicModalTemplate)`
  max-width: 675px;
`;

export const StyledMembersListEntryLoader = styled(MembersListEntryLoader)`
  margin-left: 0px;
`;
