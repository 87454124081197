import React, { useCallback } from 'react';
import { string, oneOfType, number, bool, array } from 'prop-types';
import { useField, useFormikContext } from 'formik';

import Input, { inputTypePropType } from '../Input';

const Field = ({
  type,
  value: fieldValue,
  helperText,
  isMultiSelectEnable,
  ...props
}) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const { name, hasDropdown } = props;
  const { onBlur, onChange, value: formValues } = field;
  const { error, touched } = meta;
  // DO NOT Use useField helpers as its not memoized
  // https://github.com/formium/formik/issues/2268
  // const { setValue, setTouched } = helpers;
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const value =
    type === 'button' || type === 'submit' ? fieldValue : formValues;
  const handleChange = useCallback(
    (valueOrEvent, secondArg) => {
      if (
        [
          'dropdownWithMultiSelect',
          'date',
          'textV2_text',
          'textV2_number',
          'textV2_password',
          'dropdown',
        ].indexOf(type) !== -1
      ) {
        setFieldValue(name, valueOrEvent);
        setFieldTouched(name, true);
      } else if (['textWithChips'].indexOf(type) !== -1) {
        setFieldValue(name, secondArg);
        setFieldTouched(name, true);
      } else {
        onChange(valueOrEvent);
      }
    },
    [type, setFieldTouched, name, setFieldValue, onChange],
  );
  return (
    <Input
      name={name}
      type={type}
      {...props}
      value={value}
      onBlur={onBlur}
      onChange={handleChange}
      error={touched && !!error}
      helperText={touched && !!error ? error : helperText}
      isMultiSelectEnable={isMultiSelectEnable}
      hasDropdown={hasDropdown}
    />
  );
};

Field.propTypes = {
  name: string,
  type: inputTypePropType,
  value: oneOfType([string, number, bool, array]),
  helperText: string,
  hasDropdown: bool,
  isMultiSelectEnable: bool,
};

Field.defaultProps = {
  name: '',
  type: 'text',
  value: '',
  helperText: null,
  hasDropdown: false,
  isMultiSelectEnable: true,
};

export default Field;
