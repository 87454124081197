export enum ShareSheetPermissions {
  canPost = 'canPost',
  canView = 'canView',
  allPermissions = 'allPermissions',
}

export const allPermissionAccessPermission = {
  id: ShareSheetPermissions.allPermissions,
  value: 'All permissions',
};

export const AccessPreviewDropdownOptions = [
  allPermissionAccessPermission,
  {
    id: ShareSheetPermissions.canPost,
    value: 'Can post',
  },
  {
    id: ShareSheetPermissions.canView,
    value: 'Can view',
  },
];
export const AccessPreviewDropdownMenu = [
  {
    id: 'previewDropdownItems',
    items: AccessPreviewDropdownOptions,
  },
];
