import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { GET_MEMBERS_DETAILS } from '../../constants/endpoints';
import { anonymousSettingsOptions } from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
// eslint-disable-next-line max-len
import { GetRRuleStringProps } from '../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';
import { visibilityTypes } from '../../controllers/flowsBuilder/FlowsBuilderVisibilityController/data';
import { FlowBlockContent } from '../../interfaces/Flow';
import {
  ContentBlockState,
  FlowBuilderBlockTypes,
  TriggerType,
} from '../../interfaces/Flow/Builder';
import { useFetchFlowDetailsQuery } from '../../queries/Flows/Feed';
import { AnonymityStates } from '../../queries/Flows/interfaces';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useFlowBuilderStore from '../../stores/flowBuilderStore';
import {
  blockDataSelector,
  setCurrentScheduleSelector,
  setDescriptionSelector,
  setEmojiSelector,
  setFlowNameSelector,
  setPendingEmailsSelector,
  setSpecificBlockDataSelector,
  shareSheetSetAnonymousSettingsSelector,
} from '../../stores/flowBuilderStore/selectors';
import {
  findTimeZoneIndex,
  formattedTimezones,
  getBaseEmojiFromIcon,
  mapContentBlockFromTemplateResponse,
  mapCriteriaResponseToBlockData,
} from '../../Utils/flows/builder/utils';
import {
  getDefaultFlowStartDate,
  getFrequencyTimeForOneClickScheduler,
} from '../../Utils/flows/scheduler/dateAndTime';
import { getRRuleString } from '../../Utils/flows/scheduler/formRRulString';
import useGetTimeOptions from '../useGetTimeOptions';

const determineSelectedAnonymityOption = (option: AnonymityStates) => {
  switch (option) {
    case AnonymityStates.ENABLED:
      return anonymousSettingsOptions[2];
    case AnonymityStates.OPTIONAL:
      return anonymousSettingsOptions[1];
    default:
      return anonymousSettingsOptions[0];
  }
};

export const usePopulateFlowDetailsToStore = (
  flowId: string,
  isScheduledFlowCreatedUsingOneClick?: boolean,
  isDuplicateFlow?: boolean,
) => {
  const { data: profileData } = useProfileInfoFetchQuery();
  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');
  const queryClient = useQueryClient();
  const setEmoji = useFlowBuilderStore(setEmojiSelector);
  const blockData = useFlowBuilderStore(blockDataSelector);
  const setFlowName = useFlowBuilderStore(setFlowNameSelector);
  const setDescription = useFlowBuilderStore(setDescriptionSelector);
  const setPendingEmails = useFlowBuilderStore(setPendingEmailsSelector);
  const setCurrentSchedule = useFlowBuilderStore(setCurrentScheduleSelector);
  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );
  const setAnonymousSettings = useFlowBuilderStore(
    shareSheetSetAnonymousSettingsSelector,
  );

  const { flowFrequencyTimeOptions: timeOptions } = useGetTimeOptions();

  const populateFlowDetailsFromAPI = useCallback(async () => {
    if (flowDetails?.data) {
      const currentFlow = flowDetails.data;
      const contentBlocks: ContentBlockState[] = [];

      setFlowName(currentFlow.name);
      setDescription(currentFlow.description);
      setCurrentSchedule(currentFlow.schedule);
      setEmoji(getBaseEmojiFromIcon(currentFlow.icon?.value || ''));
      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, {
        ...blockData.TRIGGER,
        isSchedulerTouched: false,
      });
      setAnonymousSettings(
        determineSelectedAnonymityOption(
          flowDetails?.data?.responseSettings?.anonymity?.state,
        ),
      );

      setPendingEmails(currentFlow.pendingEmails);

      if (currentFlow.viewing) {
        const { criteria } = currentFlow.viewing;
        let type = visibilityTypes.ENTIRE_ORGANIZATION;
        if (criteria.onlyOwnersAndCollaborators) {
          type = visibilityTypes.OWNER_AND_COLLABORATORS_ONLY;
        }

        if (criteria.onlyParticipants) {
          type = visibilityTypes.PARTICIPANTS_ONLY;
        }

        if (criteria.custom) {
          type = visibilityTypes.CUSTOM;
        }

        if (!isDuplicateFlow) {
          setSpecificBlockData(FlowBuilderBlockTypes.VISIBILITY, {
            type,
            errors: null,
            custom: Boolean(criteria.custom),
            everyone: Boolean(criteria.everyone),
            onlyOwnersAndCollaborators: Boolean(
              criteria.onlyOwnersAndCollaborators,
            ),
            onlyParticipants: Boolean(criteria.onlyParticipants),
            criteriaGroups: mapCriteriaResponseToBlockData(
              currentFlow.viewing,
              true,
            ),
          });
        }
      }

      if (currentFlow.participation && !isDuplicateFlow) {
        const newCriteriaGroups = mapCriteriaResponseToBlockData(
          currentFlow.participation,
          true,
        );
        await queryClient.invalidateQueries([GET_MEMBERS_DETAILS]);

        setSpecificBlockData(FlowBuilderBlockTypes.PARTICIPANTS, {
          errors: null,
          participantsCriteria: newCriteriaGroups,
        });
      }

      if (currentFlow.action) {
        const { action } = currentFlow;

        if (action.blocks && action.blocks.length) {
          action.blocks.forEach((block) => {
            const updatedBlock: FlowBlockContent =
              block.type === 'SCALE' && block.isNPSEnabled
                ? { ...block, type: 'NPS' }
                : block;
            const mappedBlockData =
              mapContentBlockFromTemplateResponse(updatedBlock);
            if (mappedBlockData) contentBlocks.push(mappedBlockData);
          });

          setSpecificBlockData(FlowBuilderBlockTypes.CONTENT, {
            errors: null,
            contentBlocks: [...contentBlocks],
          });
        }
      }

      let defaultScheduleRule;
      let triggerType: TriggerType = 'ONDEMAND';
      if (currentFlow.kind === 'SCHEDULED') {
        triggerType = 'SCHEDULED';
      } else if (currentFlow.kind === 'NO_TRIGGER') {
        triggerType = 'NO_TRIGGER';
      } else if (isScheduledFlowCreatedUsingOneClick) {
        triggerType = 'SCHEDULED';
        const userTimeZone = profileData?.member.timeZone;
        const getRRuleStringProps: GetRRuleStringProps = {
          flowStartDate: getDefaultFlowStartDate(undefined),
          flowEndDate: undefined,
          flowFrequency: null,
          flowFrequencyTime: getFrequencyTimeForOneClickScheduler(timeOptions),
          flowEndDateTime: null,
          selectedTimezone: formattedTimezones[findTimeZoneIndex(userTimeZone)],
          selectedCustomRecurrenceTypes: undefined,
        };
        defaultScheduleRule = getRRuleString(getRRuleStringProps);
      }

      setSpecificBlockData(FlowBuilderBlockTypes.TRIGGER, {
        selectedCustomRecurrenceTypes: undefined,
        schedule: !isScheduledFlowCreatedUsingOneClick
          ? currentFlow.schedule
          : {
              rule:
                blockData.TRIGGER.schedule?.rule.toString() ||
                defaultScheduleRule?.toString() ||
                '',
            },
        shortcut: currentFlow.shortcut || false,
        endTimeInMinutes: currentFlow.endTimeInMinutes
          ? currentFlow.endTimeInMinutes
          : 0,
        triggerType,
        isSchedulerTouched: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setEmoji,
    setFlowName,
    flowDetails,
    setDescription,
    setCurrentSchedule,
    setSpecificBlockData,
  ]);

  return {
    populateFlowDetailsFromAPI,
  };
};
