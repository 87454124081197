import { Tabs } from '@mui/material';

import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

export const StyledTabs = styled(Tabs)`
  padding: 0 16px 16px 16px;
`;

export const StyledFlex = styled(Flex)<{ isSelected: boolean }>`
  margin-right: 6px;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
  color: ${({ isSelected }) =>
    isSelected ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray8};

  background-color: ${({ isSelected }) =>
    isSelected ? ThemeV2.palette.geekBlue2 : ThemeV2.palette.white};

  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
    background-color: ${({ isSelected }) =>
      isSelected ? ThemeV2.palette.geekBlue2 : ThemeV2.palette.geekBlue1};

    path {
      fill: ${ThemeV2.palette.geekBlue6} !important;
    }
  }

  path {
    fill: ${({ isSelected }) =>
      isSelected
        ? ThemeV2.palette.geekBlue6
        : ThemeV2.palette.gray8} !important;
  }
`;
