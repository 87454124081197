import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { shallow } from 'zustand/shallow';
import { pusher } from '../../../../pusher/pusher-base';
import {
  refetchSectionalTaskQuery,
  invalidateTaskHistoryQuery,
  updateFeedQueryCachesAfterTaskUpdate,
  updateFlowResponseQueryCachesAfterTaskUpdate,
} from '../../../../queries/Notebook';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useNotebookStore from '../../../../stores/notebookStore';
import { NotebookStore } from '../../../../stores/notebookStore/types';
import {
  NotebookSectionDeleteSocketPayload,
  NotebookSectionUpdateSocketPayload,
  NotebookSocketEventTypes,
  NotebookTaskSocketPayload,
} from './types';
import {
  getSectionalTaskUpdateSocketData,
  getSectionDeleteSocketData,
  getSectionUpdateSocketData,
  getTaskSocketData,
} from './utils';

const selector = (store: NotebookStore) => ({
  onPusherCreate: store.onPusherTaskCreate,
  onPusherDelete: store.onPusherTaskDelete,
  onPusherUpdate: store.onPusherTaskUpdate,
  onPusherSectionDelete: store.onPusherSectionDelete,
  onPusherSectionUpdate: store.onPusherSectionUpdate,
  onPusherSectionalTaskUpdate: store.onPusherSectionalTaskUpdate,
});

const useNotebookPusher = () => {
  const queryClient = useQueryClient();
  const { data } = useProfileInfoFetchQuery();
  const storeFunctions = useNotebookStore(selector, shallow);
  const currentUserTimezone = useMemo(
    () => data?.member.timeZone || '',
    [data?.member.timeZone],
  );

  useEffect(() => {
    if (data) {
      const { memberId } = data.member;
      const { assemblyId } = data.assembly;
      const channelMember = pusher.subscribe(`private-member-${memberId}`);
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );
      const channelMemberEventHandler = (
        socketEventType: NotebookSocketEventTypes,
        socketPayload:
          | NotebookTaskSocketPayload
          | NotebookSectionDeleteSocketPayload
          | NotebookSectionUpdateSocketPayload,
      ) => {
        switch (socketEventType) {
          case 'TASK_CREATED': {
            const socket = getTaskSocketData({
              type: 'TASK_CREATED',
              data: socketPayload,
            });
            if (socket !== null) {
              storeFunctions.onPusherCreate(
                socket.data.task,
                memberId,
                currentUserTimezone,
              );
            }
            break;
          }
          case 'TASK_DELETED': {
            const socket = getTaskSocketData({
              type: 'TASK_DELETED',
              data: socketPayload,
            });
            if (socket !== null) {
              storeFunctions.onPusherDelete(
                socket.data.task,
                currentUserTimezone,
                socket.data.section,
              );
            }
            break;
          }

          case 'TASK_SECTION_UPDATE': {
            const socket = getSectionUpdateSocketData({
              type: 'TASK_SECTION_UPDATE',
              data: socketPayload,
            });
            if (socket !== null) {
              storeFunctions.onPusherSectionUpdate(socket);
            }
            break;
          }

          case 'TASK_MOVED_BETWEEN_SECTION': {
            const socket = getSectionalTaskUpdateSocketData({
              type: 'TASK_MOVED_BETWEEN_SECTION',
              data: socketPayload,
            });
            if (socket !== null) {
              storeFunctions.onPusherSectionalTaskUpdate(
                socket,
                memberId,
                currentUserTimezone,
              );
              setTimeout(() => {
                refetchSectionalTaskQuery(
                  queryClient,
                  socket?.data?.section?.to,
                );
              }, 100);
            }
            break;
          }

          case 'TASK_SECTION_DELETED': {
            const socket = getSectionDeleteSocketData({
              type: 'TASK_SECTION_DELETED',
              data: socketPayload,
            });
            if (socket !== null) {
              storeFunctions.onPusherSectionDelete(socket);
              if (socket?.movedTo) {
                // TODO: Sumedha - check with backend on latency and response returning stale data
                setTimeout(() => {
                  refetchSectionalTaskQuery(queryClient, socket?.movedTo);
                }, 100);
              }
            }
            break;
          }
          default:
            break;
        }
      };
      const channelAssemblyEventHandler = (
        socketData: string,
        socketPayload: NotebookTaskSocketPayload,
      ) => {
        switch (socketData) {
          case 'TASK_UPDATED': {
            storeFunctions.onPusherUpdate(
              socketPayload.data.task,
              memberId,
              currentUserTimezone,
            );
            updateFeedQueryCachesAfterTaskUpdate(
              queryClient,
              socketPayload.data.task,
            );
            updateFlowResponseQueryCachesAfterTaskUpdate(
              queryClient,
              socketPayload.data.task,
            );
            invalidateTaskHistoryQuery(queryClient, socketPayload.data.task.id);
            break;
          }
          default:
            break;
        }
      };
      channelMember.bind_global(channelMemberEventHandler);
      channelAssembly.bind_global(channelAssemblyEventHandler);
      return () => {
        channelMember.unbind_global(channelMemberEventHandler);
        channelAssembly.unbind_global(channelAssemblyEventHandler);
      };
    }
    return;
  }, [data, storeFunctions, currentUserTimezone, queryClient]);
};

export default useNotebookPusher;
