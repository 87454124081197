import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import SVGIcon from '../SVGIcon';
import React from 'react';
import { Flex } from '../../../Utils/styles/display';

const NotebookSunsetContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  color: ${ThemeV2.palette.gray9};
  border: 1px solid ${ThemeV2.palette.orange6};
  background-color: ${ThemeV2.palette.orange1};
`;

const NotebookSunsetBannerTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${ThemeV2.palette.gray10};
`;

export const NotebookSunsetBanner = () => {
  return (
    <NotebookSunsetContainer>
      <Flex gap="12px" alignItems="start">
        <SVGIcon
          data-testid="error-warning-icon"
          color={ThemeV2.palette.orange6}
          icon="error-warning"
          size="21px"
        />
        <Flex alignItems="start" flexDirection="column">
          <NotebookSunsetBannerTitle>
            Assembly will be sunsetting our Notebook feature on Friday, April
            26, 2024.
          </NotebookSunsetBannerTitle>
          <div>
            Thank you for using tasks in Assembly! Unfortunately we are no
            longer be supporting Notebook and tasks, and they will be removed
            from all accounts. To download your task history, please email{' '}
            <a href="mailto:reports@joinassembly.com">
              reports@joinassembly.com
            </a>
          </div>
        </Flex>
      </Flex>
    </NotebookSunsetContainer>
  );
};
