import { getCurrencyFromProfileData, getRecognitionPostUrl } from '.';
import ThemeV2 from '../../../theme';

import { BlockProps } from '../../../atomic/organism/FlowPostBody/types';
import {
  FeedItemFromAPI,
  MemberInteractionType,
} from '../../../interfaces/Feed';
import { PostTypes } from '../../../interfaces/Home';
import { MemberState } from '../../../interfaces/user';
import { COPIED_TO_CLIPBOARD } from '../../../languages/en/flows';
import { RECOGNITION_POST_LABELS } from '../../../languages/en/flows/feed';
import { ANONYMOUS } from '../../../languages/en/singleWords';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import {
  getAssemblyCurrency,
  getCanDeletePosts,
} from '../../../queries/Profile/utils';
import { copyToClipboard } from '../../common';
import { showSuccessMessage } from '../../toast';
import { getFormattedMessage } from '../../message';
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import {
  mapFeedResponseToFeedBodyProps,
  mapFeedResponseToFeedHeaderProps,
  mapFeedResponseToReactionProps,
} from './response';

const mapFeedPostToFeedHeaderProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
  isEditEnabled?: boolean,
) => {
  const canDeletePosts = getCanDeletePosts(profile);
  const canEditPosts =
    isEditEnabled &&
    data.type === PostTypes.RECOGNITION &&
    data.from?.memberID === profile?.member.memberId;

  return {
    flow: {
      flowId: 'recognition',
      name: 'Give Recognition',
      icon: { kind: 'HEX_CODE', value: '1F389' },
      kind: '',
      version: 0,
    },
    postType: data.type as PostTypes,
    hasTrophies: data.pointsEach > 0,
    showDeleteOption: canDeletePosts,
    showEditOption: !!canEditPosts,
    createdAt: data.createdAt.toString(),
    person: data.from
      ? {
          ...data.from,
          memberState:
            data.from?.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null,
    onCopyLink: () => {
      if (data.postID) {
        const url = getRecognitionPostUrl(data.postID);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    },
    currentUserId: profile.member.memberId,
    visibility: data.isPrivate ? 'private' : data.flowResponse?.visibility,
    isEdited: !!data.editedAt, // Todo: change editedAt variable
    source: data.source,
  };
};

export const mapFeedPostToFeedBodyProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
  onMemberClick: (member: MemberInteractionType) => void,
  onRepliesLinkClicked?: (param: Record<string, string>) => void,
) => {
  const blocks: BlockProps[] = [];
  const { coreValue, gifURL, message, pointsEach, taggedUsers, to } = data;

  if (to.length) {
    const celebrateBlock: BlockProps = {
      id: '1',
      type: 'PERSON_SELECTOR',
      content: {
        title: RECOGNITION_POST_LABELS.CELEBRATE_LABEL,
        persons: to.map((user) => {
          const isActive = user.memberState !== MemberState.DEACTIVATED;

          return {
            id: user.memberID,
            label: isActive ? user.name || ANONYMOUS : 'Deactivated User',
            avatar: {
              img: user.image,
              userId: user.memberID,
              name: user.firstName,
              icon:
                user.memberState === MemberState.PENDING
                  ? 'pending-person'
                  : '',
              iconColor: ThemeV2.palette.white,
              isDeleted: !isActive,
            },
            clickable: isActive,
          };
        }),
      },
    };
    blocks.push(celebrateBlock);
  }

  if (coreValue) {
    const coreValueBlock: BlockProps = {
      id: '2',
      type: 'DROPDOWN',
      content: {
        title: RECOGNITION_POST_LABELS.CORE_VALUE_LABEL,
        options: [],
        value: [coreValue],
      },
    };

    blocks.push(coreValueBlock);
  }

  if (message) {
    const openEndedBlock: BlockProps = {
      id: '3',
      type: 'OPEN_ENDED',
      content: {
        gifUrl: gifURL,
        title: RECOGNITION_POST_LABELS.OPEN_ENDED_LABEL,
        message: getFormattedMessage(
          message,
          taggedUsers,
          onMemberClick,
          [],
          onRepliesLinkClicked,
        ),
      },
    };

    blocks.push(openEndedBlock);
  }

  if (pointsEach) {
    const emoticon = getCurrencyFromProfileData(profile);

    const coreValueBlock: BlockProps = {
      id: '4',
      type: 'GIVE_TROPHIES',
      content: {
        title: `${RECOGNITION_POST_LABELS.TROPHIES_BLOCK_LABEL} ${
          getAssemblyCurrency(profile).pluralName
        }!`,
        content: pointsEach.toString(),
        showEachText: to.length > 1,
        emoticon,
      },
    };

    blocks.push(coreValueBlock);
  }

  return blocks;
};

const mapFeedPostToReactionProps = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
) => {
  const userId = profile?.member.memberId || '';
  const reactions = data.reactions.map((reaction) => ({
    members: reaction.members.map((member) => ({
      name: member.name,
      memberID: member.memberID,
    })),
    reaction: {
      name: reaction.name,
      type:
        reaction.type === 'REGULAR'
          ? ReactionType.REGULAR
          : ReactionType.CUSTOM,
      displayName: reaction?.displayName,
      value: reaction.value,
    },
    timesReacted: reaction.members.length,
    active: reaction.members.some((member) => member.memberID === userId),
  }));

  return {
    reactions,
    contentId: data.postID,
    currentUserId: userId,
  };
};

type PostDataToPostPropsType = {
  feed: FeedItemFromAPI;
  profileData: GetProfileInfoResponse;
  isEditEnabled?: boolean;
  onMemberClick: (member: MemberInteractionType) => void;
  onRepliesLinkClicked?: (param: Record<string, string>) => void;
};

export const mapPostDataToPostProps = (props: PostDataToPostPropsType) => {
  const {
    feed,
    profileData,
    isEditEnabled,
    onMemberClick,
    onRepliesLinkClicked,
  } = props;
  return {
    activeAnnouncement: feed.flowResponse?.activeAnnouncement,
    instanceId: feed.flowResponse?.instanceId || '',
    flowId: feed.flowResponse?.flow.flowId,
    responseId: feed.flowResponse?.responseId,
    postId: feed.postID,
    key: feed.flowResponse?.responseId || feed.postID,
    type: feed.type,
    headerProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapFeedResponseToFeedHeaderProps(
            feed.flowResponse,
            profileData,
            isEditEnabled,
          )
        : mapFeedPostToFeedHeaderProps(feed, profileData, isEditEnabled),
    bodyProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapFeedResponseToFeedBodyProps(
            feed.flowResponse,
            profileData,
            onMemberClick,
            onRepliesLinkClicked,
          )
        : mapFeedPostToFeedBodyProps(
            feed,
            profileData,
            onMemberClick,
            onRepliesLinkClicked,
          ),
    reactionBarProps:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? mapFeedResponseToReactionProps(feed.flowResponse, profileData)
        : mapFeedPostToReactionProps(feed, profileData),
    commentsCount:
      feed.type === PostTypes.FLOW && feed.flowResponse
        ? feed.flowResponse.commentsCount
        : feed.commentsCount,
    trophyReceivers:
      feed.type === PostTypes.FLOW && feed.flowResponse ? [] : feed.to,
  };
};
