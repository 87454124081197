import React from 'react';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import { NotebookTask, TaskCategories } from '../../../interfaces/notebook';
import {
  ButtonWrapper,
  ContentWrapper,
  IconWrapper,
  LoadingIndicator,
} from './styles';
import ThemeV2 from '../../../theme';

export type TaskTokenProps = Partial<NotebookTask> & {
  ariaLabel: string;
  children: React.ReactNode | Array<React.ReactNode>;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  textVariant?: string;
  isDeleted?: boolean;
};

export const TaskToken = ({
  ariaLabel,
  children,
  disabled = true,
  isLoading = false,
  onClick,
  textVariant = 'body2',
  type,
  isDeleted,
}: TaskTokenProps) => {
  const isArchived = type === TaskCategories.ARCHIVED;
  const isCompleted = type === TaskCategories.COMPLETED;
  const handleTaskTokenClick = () => {
    if (!isDeleted && onClick) {
      onClick();
    }
  };

  return (
    <ButtonWrapper
      $isLoading={isLoading}
      $isDeleted={isDeleted}
      aria-label={ariaLabel}
      disabled={disabled}
      onClick={handleTaskTokenClick}
      style={{ opacity: 0.5, pointerEvents: 'none' }}
    >
      <ContentWrapper>
        {!isArchived && !isDeleted && (
          <IconWrapper>
            <SVGIcon
              circleStroke={isCompleted ? ThemeV2.palette.green7 : undefined}
              color={
                isCompleted ? ThemeV2.palette.green7 : ThemeV2.palette.gray3
              }
              icon="checkbox-circle-unchecked"
              size="13.3333px"
              stroke={
                isCompleted ? ThemeV2.palette.white : ThemeV2.palette.gray6
              }
            />
          </IconWrapper>
        )}
        <Body
          color={isArchived || isDeleted ? 'gray7' : 'gray9'}
          style={isCompleted ? { textDecoration: 'line-through' } : undefined}
          textAlign="left"
          variant={textVariant}
        >
          {isDeleted ? 'Can’t find task ' : children}
          {isLoading && <LoadingIndicator scale={0.12} />}
        </Body>
      </ContentWrapper>
    </ButtonWrapper>
  );
};
