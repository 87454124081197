import { ModalBodyHeading } from '../../organism/FeedPostHeaderOption/styles';
import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';
import Body from '../../atoms/Body';
import { DeleteReplyConfirmationModalProps } from './interface';
import {
  feedPostHeaderModalCancelButtonText,
  feedPostHeaderModalSubmitButtonText,
} from '../../../languages/en/home';

export const DeleteReplyConfirmationModal = ({
  isModalOpen,
  modalInfoContent,
  handleModalClose,
  handleModalSubmit,
}: DeleteReplyConfirmationModalProps) => {
  const primaryButton: ModalButtonProps = {
    text: feedPostHeaderModalSubmitButtonText,
    status: 'warning',
    isLoading: false,
    onClick: handleModalSubmit,
  };

  const secondaryButton: ModalButtonProps = {
    text: feedPostHeaderModalCancelButtonText,
    isLoading: false,
    onClick: handleModalClose,
  };

  return (
    <BasicModalTemplate
      isOpen={isModalOpen}
      heading={modalInfoContent.headerText || ''}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      onModalClose={handleModalClose}
      disableContentScroll
    >
      {modalInfoContent.contentHeading && (
        <ModalBodyHeading variant="h5">
          {modalInfoContent.contentHeading}
        </ModalBodyHeading>
      )}
      {modalInfoContent.contentText && (
        <Body variant="body2">{modalInfoContent.contentText}</Body>
      )}
    </BasicModalTemplate>
  );
};
