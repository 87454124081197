import { v4 as uuidv4 } from 'uuid';
import { BaseEmoji } from 'emoji-mart/dist-es';
import intersectionWith from 'lodash/intersectionWith';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import memoize from 'lodash/memoize';
import momentTimezone from 'moment-timezone';
import { RRule, Options } from 'rrule';
import format from 'date-fns/format';
import {
  ContentBlockBaseState,
  ContentBuilderBlockData,
  ContentFileUploadBlockState,
  ContentGifBlockState,
  ContentGivePointsBlockState,
  ContentMultiOptionBlockState,
  ContentNPSBlockState,
  ContentOpenEndedBlockState,
  ContentPersonSelectorBlockState,
  ContentScaleBlockState,
  CriteriaGroup,
  CriteriaGroups,
  CriteriaRule,
  CriteriaValueItem,
  FlowBuilderBlockData,
  FlowBuilderState,
  OptionsSelectObject,
  ScheduleRule,
  VisibilityBuilderBlockData,
} from '../../../interfaces/Flow/Builder';
import {
  AllowedOpenEndedMediaTypes,
  BasicFlowBlockAttributes,
  DropdownFlowBlock,
  FileUploadFlowBlock,
  FlowBlockContent,
  FlowRuleLimit,
  GifUploadFlowBlock,
  GiveTrophiesStackFlowBlock,
  MultichoiceBlockRules,
  MultiChoiceFlowBlock,
  OpenEndedFlowBlock,
  PersonSelectorFlowBlock,
  ScaleFlowBlock,
} from '../../../interfaces/Flow';
import { mapHexCodeToEmoticon } from '../../mappers';
import { visibilityTypes } from '../../../controllers/flowsBuilder/FlowsBuilderVisibilityController/data';
import { SaveFlowPayload } from '../../../queries/Flows/Feed/interfaces';
import { IMemberDTO } from '../../../interfaces/member';
import { AutocompleteDropdownItem } from '../../../atomic/organism/Autocomplete/interfaces';
import { defaultCurrency } from '../../../queries/Profile/utils';
import {
  AnonymityStates,
  CriteriaResponse,
  Rule,
} from '../../../queries/Flows/interfaces';
import { supportedUploadFileTypes } from '../../../constants/files';
import { getMemberSettingForSimpleRules, pushNewOption } from '../index';
import { getFlowFrequency } from '../scheduler/frequencyValue';
import { getDateMonthYear, getTime } from '../scheduler/formRRulString';
import {
  getDefaultFlowStartDate,
  getUTCDateFromDate,
  getUTCTimeFromDate,
} from '../scheduler/dateAndTime';
import { getSelectedCustomRecurrenceTypes } from '../scheduler/customOccurrence';
import { getFlowFrequencyOptions } from '../scheduler/flowFrequencyOptions';
import {
  AnonymousSettings,
  canOnlyPostSetting,
  ConditionTextMap,
  DepartmentAvatar,
} from '../../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import timeZones from '../../data/timezones/index.json';
import { reduceMinutesFromDeadline } from '../scheduler/deadline';
import { stripHTMLTagsFromString } from '../../common';
import {
  SimplifiedShareSheetDataTypes,
  SimplifiedShareSheetCardData,
} from '../../../controllers/flows/FlowsShareSheetController/types';
import uniqBy from 'lodash/uniqBy';
import { mapRulesFromCriteriaGroups } from '../criteria';

const DEFAULT_LIMIT = 20;

export const getMultioptionsRuleLimit = (
  currentOptionSelectObject: OptionsSelectObject,
) => {
  let ruleObj: FlowRuleLimit = {};
  switch (currentOptionSelectObject.type) {
    case 'UNLIMITED_OPTIONS': {
      ruleObj = { noLimit: true };
      return ruleObj;
    }
    case 'EXACT_NUMBER': {
      ruleObj = { exact: currentOptionSelectObject.exactOptions };
      return ruleObj;
    }
    case 'RANGE': {
      ruleObj = {
        range: {
          max: currentOptionSelectObject.maxOptions,
          min: currentOptionSelectObject.minOptions,
        },
      };
      return ruleObj;
    }
    default: {
      return ruleObj;
    }
  }
};
const getValue = (currentValue: CriteriaValueItem, rule: CriteriaRule) => {
  if (rule.field === 'manager') {
    return currentValue.value === 'memberIsManager';
  } else {
    if (rule.field === 'member' || rule.field === 'directReport') {
      return [currentValue.id];
    } else {
      return [currentValue.value];
    }
  }
};

const getAutoCompleteOptionsForDepartmentsOnlyFromCriteria = (criteria) => {
  return (
    criteria.groups?.flatMap((criteriaGroup) =>
      criteriaGroup.groupRules
        ?.filter((rule) => rule.field === 'department')
        .flatMap((rule) =>
          rule.value.map((currentValue) => ({
            id: `${currentValue.value.replaceAll(`-${rule.operator}`, '')}-${
              rule.operator
            }`,
            title: `Department ${
              ConditionTextMap[rule.operator]
            } ${currentValue.value.replaceAll(`-${rule.operator}`, '')}`,
            type: SimplifiedShareSheetDataTypes.Department,
            avatar: DepartmentAvatar,
            setting: canOnlyPostSetting,
            email: 'Loading members',
            condition: rule.operator,
            name: currentValue.value,
          })),
        ),
    ) || []
  );
};

const getRulesForMembersOnly = (criteria: CriteriaGroups) => {
  return criteria.groups
    ?.filter((criteriaGroup) => {
      return criteriaGroup.groupRules?.some((rule) => rule.field === 'member');
    })
    .flatMap((criteriaGroup) => {
      const memberRules = criteriaGroup.groupRules?.filter(
        (rule) => rule.field === 'member',
      );
      if (!memberRules) return [];

      const rules = memberRules.flatMap((rule) => {
        return rule.value.map((value) => {
          return {
            field: rule.field,
            value: getValue(value, rule),
            operator: rule.operator,
          };
        });
      });

      return [
        {
          condition: criteriaGroup.groupCondition,
          rules,
        },
      ];
    });
};

const isEveryone = (criteria: CriteriaGroups | undefined) =>
  criteria &&
  criteria.groups.length === 1 &&
  criteria.groups[0].groupRules.length === 1 &&
  Array.isArray(criteria.groups[0].groupRules[0].value) &&
  criteria.groups[0].groupRules[0].value.some((x) => x.id === 'everyone');

export const mapRulesFromCriteriaGroupsAndReturnMembersOnly = (
  criteriaGroups: CriteriaGroups | undefined,
  noSkippedMembers?: boolean,
  canIncludeNewInviteMembers = true,
) => {
  if (criteriaGroups) {
    if (
      (criteriaGroups && !criteriaGroups.groups.length) ||
      isEveryone(criteriaGroups)
    ) {
      return undefined;
    }

    const rules = getRulesForMembersOnly(criteriaGroups);

    if (rules.length > 0) {
      const customCriteria = {
        rules,
        condition: criteriaGroups.groupsCondition,
      };

      return {
        criteria: {
          custom: { ...customCriteria },
        },
        ...(canIncludeNewInviteMembers && { isNewMembersAdded: true }),
        ...(!noSkippedMembers && { skippedMembers: [] }),
      };
    }
  }

  return undefined;
};

const isDepartmentPresent = (
  { id }: SimplifiedShareSheetCardData,
  departments: SimplifiedShareSheetCardData[],
) => departments.some(({ id: deptId }) => id === deptId);

export const mapRulesFromCriteriaGroupsAndReturnDepartmentsOnly = (
  participationCriteria: CriteriaGroups | undefined,
  visibilityCriteria: CriteriaGroups | undefined,
  totalMembers = 0,
) => {
  const shouldReturnEmpty =
    !participationCriteria ||
    !participationCriteria.groups.length ||
    !visibilityCriteria ||
    !visibilityCriteria.groups.length;

  if (shouldReturnEmpty) {
    return [];
  }

  const everyone: SimplifiedShareSheetCardData[] = [];
  const isEveryoneAParticipant = isEveryone(participationCriteria);
  const isEveryoneAViewer = isEveryone(visibilityCriteria);

  if (isEveryoneAParticipant || isEveryoneAViewer) {
    everyone.push({
      id: 'everyone',
      title: 'Everyone in this workspace',
      setting: getMemberSettingForSimpleRules({
        isMemberAParticipant: isEveryoneAParticipant || false,
        isMemberHavingVisibility: isEveryoneAViewer || false,
        isMemberAFlowOwner: false,
      }),
      icon: 'everyone',
      email: `${totalMembers} members`,
      type: SimplifiedShareSheetDataTypes.Everyone,
    });
  }

  const departmentsWhoAreParticipants =
    getAutoCompleteOptionsForDepartmentsOnlyFromCriteria(participationCriteria);
  const departmentsWhoAreViewers =
    getAutoCompleteOptionsForDepartmentsOnlyFromCriteria(visibilityCriteria);

  const departments = uniqBy(
    [...departmentsWhoAreParticipants, ...departmentsWhoAreViewers],
    'id',
  ).map((department) => ({
    ...department,
    setting: getMemberSettingForSimpleRules({
      isMemberAParticipant: isDepartmentPresent(
        department,
        departmentsWhoAreParticipants,
      ),
      isMemberHavingVisibility: isDepartmentPresent(
        department,
        departmentsWhoAreViewers,
      ),
      isMemberAFlowOwner: false,
    }),
  }));

  if (departments.length > 0 || everyone.length > 0) {
    return [...departments, ...everyone];
  }

  return [];
};

export const serializeBlocks = (
  contentBlockData: ContentBuilderBlockData,
): FlowBlockContent[] => {
  const { contentBlocks = [] } = contentBlockData;
  // this ID should be between 5 to 12 characters long
  const randomId = `${Math.random().toString().slice(2, 11)}`;
  return contentBlocks.map((block) => {
    const basicBlockAttributes: BasicFlowBlockAttributes = {
      // Title should have a default value of ... if it does not have a value
      title: stripHTMLTagsFromString(block.title) || '...',
      type: block.type,
      blockId: block.blockId,
      description: block.description
        ? {
            text: block.description,
          }
        : undefined,
    };
    switch (block.type) {
      case 'NPS': {
        const { isRequired } = block;
        return {
          ...basicBlockAttributes,
          type: 'SCALE',
          min: 0,
          max: 10,
          rules: {
            required: isRequired,
          },
          isNPSEnabled: true,
          labels: {
            low: 'Not likely at all',
            high: 'Very likely',
          },
        } as ScaleFlowBlock;
      }
      case 'SCALE': {
        const {
          minimumRange,
          maximumRange,
          labelInputOne,
          labelInputThree,
          labelInputTwo,
          isRequired,
        } = block;
        const isAtleastOneLabelPresent = Boolean(
          labelInputOne || labelInputTwo || labelInputThree,
        );
        return {
          ...basicBlockAttributes,
          min: minimumRange,
          max: maximumRange,
          rules: {
            required: isRequired,
          },
          labels: isAtleastOneLabelPresent
            ? {
                low: labelInputOne || undefined,
                middle: labelInputTwo || undefined,
                high: labelInputThree || undefined,
              }
            : undefined,
          isNPSEnabled: false,
        } as ScaleFlowBlock;
      }
      case 'GIF': {
        return {
          ...basicBlockAttributes,
          rules: {
            required: block.isRequired,
          },
        } as GifUploadFlowBlock;
      }
      case 'FILE_UPLOAD': {
        return {
          ...basicBlockAttributes,
          rules: {
            required: block.isRequired,
          },
        } as FileUploadFlowBlock;
      }
      case 'OPEN_ENDED': {
        const {
          maximumCharacters,
          minimumCharacters,
          isRequired,
          openEndedOptions,
        } = block;
        const allowedMedia: AllowedOpenEndedMediaTypes[] = [];
        if (openEndedOptions.attachments) {
          allowedMedia?.push('FILES');
        }
        if (openEndedOptions.emojis) {
          allowedMedia?.push('EMOJI');
        }
        if (openEndedOptions.gifs) {
          allowedMedia?.push('GIF');
        }
        if (openEndedOptions.mentions) {
          allowedMedia?.push('MENTION');
        }
        if (openEndedOptions.tasks) {
          allowedMedia?.push('TASKS');
        }
        return {
          ...basicBlockAttributes,
          rules: {
            max: maximumCharacters,
            min: minimumCharacters,
            required: isRequired,
            allowedMedia,
            fileType: openEndedOptions.attachments
              ? supportedUploadFileTypes
              : undefined,
          },
        } as OpenEndedFlowBlock;
      }
      case 'DROPDOWN': {
        const { options, currentOptionSelectObject } = block;
        return {
          ...basicBlockAttributes,
          options: options.map(({ value, label, defaultLabel }) => ({
            id: value,
            value: label !== '' ? label : defaultLabel,
          })),
          rules: {
            required: block.isRequired,
            limit: getMultioptionsRuleLimit(currentOptionSelectObject),
          },
        } as DropdownFlowBlock;
      }
      case 'MULTI_CHOICE': {
        const { options, currentOptionSelectObject } = block;
        const filteredOptions = options.filter(
          (option) => option.value.toLowerCase() !== 'other',
        );
        const optionType =
          currentOptionSelectObject.type === 'EXACT_NUMBER' &&
          currentOptionSelectObject.exactOptions === 1
            ? 'SINGLE'
            : 'MULTI';
        const allowOther = filteredOptions.length < options.length;
        return {
          ...basicBlockAttributes,
          options: filteredOptions.map(({ value, label, defaultLabel }) => ({
            id: value,
            value: label !== '' ? label : defaultLabel,
          })),
          rules: {
            required: block.isRequired,
            limit: getMultioptionsRuleLimit(currentOptionSelectObject),
            allowOther,
          },
          optionType,
        } as MultiChoiceFlowBlock;
      }
      case 'PERSON_SELECTOR': {
        const {
          isLinkedBlock,
          chosenParticipantSelection,
          selectedBlockParticipants,
          criteriaGroups,
        } = block;

        const selectSinglePerson = chosenParticipantSelection === 'ONE_PERSON';
        const rules = {
          select: 'CUSTOM',
          ...mapRulesFromCriteriaGroups(criteriaGroups, true, false),
        };
        return {
          ...basicBlockAttributes,
          select_type: selectSinglePerson ? 'SINGLE_PERSON' : 'MULTI_PERSON',
          key: isLinkedBlock ? randomId : undefined,
          rules: {
            required: block.isRequired,
            select: selectedBlockParticipants,
            limit: !selectSinglePerson ? { noLimit: true } : undefined,
            ...(!isEmpty(criteriaGroups) ? rules : {}),
          },
        } as PersonSelectorFlowBlock;
      }
      case 'GIVE_POINTS_STACK': {
        const { hideCurrencyValues, limitAmountDetails } = block;
        let pointsRule;
        if (limitAmountDetails) {
          if (limitAmountDetails.type === 'EXACT') {
            pointsRule = {
              limitType: 'EXACT_VALUE',
              limit: limitAmountDetails.value,
              noLimit: false,
            };
          } else {
            pointsRule = {
              limitType: 'PERCENTAGE',
              limit: limitAmountDetails.value,
              noLimit: false,
            };
          }
        } else {
          pointsRule = {
            noLimit: true,
          };
        }
        return {
          ...basicBlockAttributes,
          dependentKeys: [randomId],
          rules: {
            required: block.isRequired,
            hidePoints: hideCurrencyValues,
            points: pointsRule,
          },
        } as GiveTrophiesStackFlowBlock;
      }
      default: {
        return block;
      }
    }
  });
};

export const getBaseEmojiFromIcon = (icon: string) => {
  return {
    id: icon,
    skin: 1,
    name: '',
    colons: '',
    unified: icon,
    emoticons: [],
    native: mapHexCodeToEmoticon(icon),
  } as BaseEmoji;
};

const getRuleValue = (x: Rule) => {
  if (x.field === 'everyone') {
    return [];
  }
  return x.field === 'manager'
    ? [
        {
          id: uuidv4(),
          value: x.value ? 'memberIsManager' : 'memberIsNotManager',
        },
      ]
    : x.value.map((val) => ({ id: val, value: val }));
};

export const mapCriteriaResponseToBlockData = (
  criteriaResponse: CriteriaResponse,
  isSimplifiedShareSheetTreatmentOn: boolean,
): CriteriaGroups => {
  const { criteria } = criteriaResponse;

  if (criteria?.custom) {
    const newCriteriaGroups: CriteriaGroups = {
      groups: [],
      groupsCondition: criteria.custom.condition,
    };

    criteria?.custom.rules?.forEach((groupRule) => {
      newCriteriaGroups.groups.push({
        groupId: uuidv4(),
        groupCondition: groupRule.condition,
        groupRules: groupRule.rules?.map((x) => ({
          value: getRuleValue(x),
          operator: x.operator,
          field: x.field,
          ruleId: uuidv4(),
        })),
      });
    });

    return newCriteriaGroups;
  }

  if (criteria.everyone) {
    const groups: CriteriaGroup[] = [
      {
        groupId: uuidv4(),
        groupCondition: isSimplifiedShareSheetTreatmentOn ? 'or' : 'and',
        groupRules: [
          {
            value: [{ id: 'everyone', value: 'everyone' }],
            operator: 'is',
            field: 'everyone',
            ruleId: uuidv4(),
          },
        ],
      },
    ];
    return {
      groups,
      groupsCondition: isSimplifiedShareSheetTreatmentOn ? 'or' : 'and',
    };
  }

  return {
    groups: [],
    groupsCondition: 'and',
  };
};

export const mapContentBlockFromTemplateResponse = (
  block: FlowBlockContent,
) => {
  const blockBase: ContentBlockBaseState = {
    id: uuidv4(),
    type: 'SCALE',
    title: block.title,
    blockId: block.blockId,
    description: block.description?.text || null,
    isRequired: Boolean(block.rules?.required),
    assemblyCurrency: defaultCurrency,
  };

  switch (block.type) {
    case 'NPS': {
      return {
        ...blockBase,
        type: 'NPS',
      } as ContentNPSBlockState;
    }
    case 'SCALE': {
      return {
        ...blockBase,
        type: 'SCALE',
        minimumRange: block.min,
        maximumRange: block.max,
        labelInputOne: block.labels?.low,
        labelInputTwo: block.labels?.middle,
        labelInputThree: block.labels?.high,
      } as ContentScaleBlockState;
    }

    case 'GIF': {
      return {
        ...blockBase,
        type: 'GIF',
      } as ContentGifBlockState;
    }

    case 'FILE_UPLOAD': {
      return {
        ...blockBase,
        type: 'FILE_UPLOAD',
      } as ContentFileUploadBlockState;
    }

    case 'OPEN_ENDED': {
      return {
        ...blockBase,
        type: 'OPEN_ENDED',
        minimumCharacters: block.rules?.min,
        maximumCharacters: block.rules?.max,
        openEndedOptions: {
          gifs: Boolean(block.rules?.allowedMedia?.includes('GIF')),
          emojis: Boolean(block.rules?.allowedMedia?.includes('EMOJI')),
          attachments: Boolean(block.rules?.allowedMedia?.includes('FILES')),
          mentions: Boolean(block.rules?.allowedMedia?.includes('MENTION')),
          tasks: Boolean(block.rules?.allowedMedia?.includes('TASKS')),
        },
      } as ContentOpenEndedBlockState;
    }

    case 'DROPDOWN':
    case 'MULTI_CHOICE': {
      let currentOptionSelectObject: OptionsSelectObject = {
        type: 'UNLIMITED_OPTIONS',
      };

      if (block.rules && block.rules.limit && !block.rules.limit.noLimit) {
        if (block.rules.limit.range) {
          currentOptionSelectObject = {
            type: 'RANGE',
            maxOptions: block.rules.limit.range.max,
            minOptions: block.rules.limit.range.min,
          };
        } else {
          currentOptionSelectObject = {
            type: 'EXACT_NUMBER',
            exactOptions: block.rules.limit?.exact
              ? block.rules.limit?.exact
              : 1,
          };
        }
      }

      let options = block.options.map((x) => ({
        value: x.id,
        label: x.value,
      }));

      if ((block.rules as MultichoiceBlockRules)?.allowOther) {
        options = pushNewOption(options, options.length, true);
      }

      return {
        ...blockBase,
        type: block.type,
        optionType: block.rules?.limit?.exact === 1 ? 'SINGLE' : 'MULTI',
        options,
        maximumSelectableOptions: block.rules?.limit?.range?.max
          ? block.rules?.limit?.range.max
          : block.options.length,
        currentOptionSelectObject,
      } as ContentMultiOptionBlockState;
    }

    case 'GIVE_POINTS_STACK': {
      return {
        ...blockBase,
        type: 'GIVE_POINTS_STACK',
        hideCurrencyValues: block.rules?.hidePoints,
        limitAmountDetails:
          block.rules?.points?.limit || block.rules?.points?.limitType
            ? {
                value: block.rules.points?.limit || DEFAULT_LIMIT,
                type:
                  block.rules.points?.limitType === 'EXACT_VALUE'
                    ? 'EXACT'
                    : 'PERCENT',
              }
            : undefined,
      } as ContentGivePointsBlockState;
    }

    case 'PERSON_SELECTOR': {
      return {
        ...blockBase,
        type: 'PERSON_SELECTOR',
        isRequired: Boolean(block.rules?.required),
        isLinkedBlock: Boolean(block.key),
        selectedBlockParticipants: block.rules?.select || 'EVERYONE',
        chosenParticipantSelection:
          block.select_type === 'SINGLE_PERSON'
            ? 'ONE_PERSON'
            : 'UNLIMITED_PEOPLE',
        ...(block.rules?.select === 'CUSTOM' &&
          block.rules?.criteria && {
            criteriaGroups: mapCriteriaResponseToBlockData(
              block.rules as CriteriaResponse,
              false,
            ),
          }),
      } as ContentPersonSelectorBlockState;
    }

    default:
      return null;
  }
};

export const serializeVisibilityBlock = (
  blockData: VisibilityBuilderBlockData | null,
) => {
  const defaultCriteria = {
    criteria: {
      everyone: true,
    },
    skippedMembers: [],
  };
  if (blockData !== null) {
    switch (blockData?.type) {
      case visibilityTypes.ENTIRE_ORGANIZATION:
        return defaultCriteria;
      case visibilityTypes.PARTICIPANTS_ONLY:
        return {
          criteria: {
            onlyParticipants: true,
          },
          skippedMembers: [],
        };
      case visibilityTypes.OWNER_AND_COLLABORATORS_ONLY:
        return {
          criteria: {
            onlyOwnersAndCollaborators: true,
          },
          skippedMembers: [],
        };
      case visibilityTypes.CUSTOM:
        if (
          blockData?.criteriaGroups &&
          blockData?.criteriaGroups.groups.length === 0
        ) {
          return {
            criteria: {
              onlyOwnersAndCollaborators: true,
            },
            skippedMembers: [],
          };
        }

        return {
          ...mapRulesFromCriteriaGroups(
            blockData?.criteriaGroups,
            undefined,
            false,
            true,
          ),
          skippedMembers: [],
        };
      default:
        break;
    }
  }
  return defaultCriteria;
};

const returnKind = (
  blockData: FlowBuilderBlockData,
  currentSchedule: ScheduleRule | undefined,
) => {
  if (
    blockData.TRIGGER?.triggerType === 'SCHEDULED' &&
    blockData.TRIGGER?.schedule?.rule.toString() ===
      currentSchedule?.rule.toString()
  ) {
    /* we shouldn't send kind, when we edit the SCHEDULED flow
    without changing anything related to schedule */
    return null;
  }
  return {
    kind: blockData.TRIGGER?.triggerType || 'ONDEMAND',
  };
};

const returnSchedule = (
  blockData: FlowBuilderBlockData,
  currentSchedule: ScheduleRule | undefined,
) => {
  if (blockData.TRIGGER?.triggerType === 'SCHEDULED') {
    return {
      schedule:
        blockData.TRIGGER?.schedule?.rule.toString() ===
        currentSchedule?.rule.toString()
          ? undefined
          : blockData.TRIGGER?.schedule,
    };
  }
  return null;
};

type SerializeBuilderBlockData = FlowBuilderState & {
  canIncludeNewInviteMembers?: boolean;
  isInSchedulerMode?: boolean;
};

const getEndTimeInMinutesForSaveFlowPayload = (
  blockData: FlowBuilderBlockData,
  isInSchedulerMode: boolean | undefined,
) => {
  if (isInSchedulerMode) {
    return reduceMinutesFromDeadline(blockData?.TRIGGER.endTimeInMinutes);
  }
  return blockData.TRIGGER?.endTimeInMinutes || 1440;
};

export const serializeBuilderBlockData = ({
  flowName,
  description,
  owner,
  emoji,
  blockData,
  templateId,
  currentSchedule,
  isInEditMode,
  canIncludeNewInviteMembers,
  isInSchedulerMode,
  collaborators,
}: SerializeBuilderBlockData): SaveFlowPayload => ({
  name: flowName,
  ownerId: owner[0]?.id,
  collaborators: collaborators?.map((collaborator) => collaborator.id),
  description: description || undefined,
  endTimeInMinutes: getEndTimeInMinutesForSaveFlowPayload(
    blockData,
    isInSchedulerMode,
  ),
  participation: mapRulesFromCriteriaGroups(
    blockData.PARTICIPANTS.participantsCriteria,
    undefined,
    canIncludeNewInviteMembers,
  ),
  viewing: serializeVisibilityBlock(blockData.VISIBILITY),
  icon: emoji
    ? {
        kind: 'HEX_CODE',
        value: emoji?.unified || '',
      }
    : undefined,
  action: {
    kind: 'FORM',
    blocks: serializeBlocks(blockData.CONTENT),
    templateId: templateId && !isInEditMode ? templateId : undefined,
  },
  shortcut: blockData.TRIGGER?.shortcut || false,
  ...returnKind(blockData, currentSchedule),
  ...returnSchedule(blockData, currentSchedule),
});

export const checkOwnerExclusionFromCriteria = (
  criteriaGroups: CriteriaGroups | undefined,
  owner: AutocompleteDropdownItem<string, IMemberDTO>[],
  isExcluded = true,
) => {
  const allExcludedMembers = criteriaGroups
    ? criteriaGroups.groups
        .map((x) =>
          x.groupRules
            .filter((y) => y.operator === (isExcluded ? 'isNot' : 'is'))
            .map((y) => y.value),
        )
        .flat()
        .flat()
    : [];
  const allOwners = owner.map((ownerData) => ({
    id: ownerData.id,
    value: ownerData.title,
  }));
  return !isEmpty(intersectionWith(allExcludedMembers, allOwners, isEqual));
};

export const covertToLocalTime = (timeInUTC: string) => {
  if (timeInUTC === '') {
    return {
      formattedDate: '',
      formattedTime: '',
      timeZone: '',
    };
  }
  return {
    formattedDate: format(new Date(timeInUTC), 'MMMM d, yyyy'),
    formattedTime: format(new Date(timeInUTC), 'h:m aa'),
    timeZone: momentTimezone
      .utc(timeInUTC)
      ?.tz(momentTimezone.tz.guess())
      ?.format('z'),
  };
};

export const getTimezoneAbbr = (
  scheduleDetails: '' | Partial<Options> | undefined,
) => {
  const timezones = momentTimezone.tz.names();
  let defaultTimeZoneIndex = 0;
  let scheduledDate = Date.now();

  if (scheduleDetails) {
    defaultTimeZoneIndex = timezones.findIndex(
      (timezone) => timezone === scheduleDetails?.tzid,
    );

    scheduledDate = +new Date(scheduleDetails.dtstart || Date.now());
  }

  if (defaultTimeZoneIndex < 0) {
    defaultTimeZoneIndex = 0;
  }

  return momentTimezone.tz
    .zone(timezones[defaultTimeZoneIndex])
    ?.abbr(scheduledDate);
};

export const getFlowFrequencyText = (scheduleRule: string | undefined) => {
  if (!scheduleRule) {
    return '';
  }
  const parsedString = RRule.parseString(scheduleRule);

  const customRecurrence = getSelectedCustomRecurrenceTypes(parsedString);

  if (parsedString.dtstart) {
    const flowFrequencyOptions = getFlowFrequencyOptions(
      getDefaultFlowStartDate({ rule: scheduleRule }),
      customRecurrence,
    );

    const flowFrequencyValue = getFlowFrequency(parsedString);

    const filteredFlowFrequencyOptions = flowFrequencyOptions.filter(
      (x) => x.id === flowFrequencyValue,
    );

    if (filteredFlowFrequencyOptions.length === 0) {
      return '';
    }

    const [{ title }] = filteredFlowFrequencyOptions;

    const dateDetails = getDateMonthYear(
      getUTCDateFromDate(parsedString.dtstart),
    );
    const timeDetails = getTime(getUTCTimeFromDate(parsedString.dtstart));

    if (dateDetails !== null && timeDetails !== null) {
      const scheduledDate = new Date(
        dateDetails.year,
        dateDetails.month,
        dateDetails.date,
        timeDetails.hours,
        timeDetails.minutes,
      );
      const formattedTime = format(scheduledDate, 'h:mm a');
      const timeZone = getTimezoneAbbr(RRule.parseString(scheduleRule));

      return `${title} at ${formattedTime} ${timeZone}`;
    }
    return '';
  }
  return '';
};

export const filterByUniqueCriteria = (
  criteriaGroups: CriteriaGroup[],
  selectedMembers: AutocompleteDropdownItem<string, IMemberDTO | undefined>[],
) => {
  const allMembers = criteriaGroups
    ? criteriaGroups
        .map((x) => x.groupRules.map((y) => y.value))
        .flat()
        .flat()
    : [];
  const allOwners = selectedMembers.map((ownerData) => ({
    id: ownerData.id,
    value: ownerData.title,
  }));

  const commonIDs = intersectionWith(allMembers, allOwners, isEqual).map(
    (i) => i.id,
  );

  return !isEmpty(intersectionWith(allMembers, allOwners, isEqual))
    ? selectedMembers.filter((i) => !commonIDs.includes(i.id))
    : selectedMembers;
};

export const getPreExistingMemberIDs = (
  criteriaGroups: CriteriaGroup[],
  selectedGroupId: string,
) => {
  return criteriaGroups
    .filter(
      (allCriteriaOfGroup) => allCriteriaOfGroup.groupId === selectedGroupId,
    )
    .map((x) => x.groupRules.map((y) => y.value))
    .flat()
    .flat()
    .map((x) => x?.id);
};

export const getLengthOfConditions = (
  criteriaGroups: CriteriaGroups | undefined,
) => {
  const criteriaLength = criteriaGroups?.groups
    .map((x) => x.groupRules.map((y) => y.value))
    .flat()
    .flat().length;
  return criteriaLength || undefined;
};

export const getCriteriaForAnonymityBasedOnDropdown = (
  selectedValue: AnonymousSettings,
): AnonymityStates => {
  switch (selectedValue) {
    case AnonymousSettings.ANONYMOUS_ON:
      return AnonymityStates.ENABLED;

    case AnonymousSettings.ANONYMOUS_OFF:
      return AnonymityStates.DISABLED;

    case AnonymousSettings.ANONYMOUS_OPTIONAL:
      return AnonymityStates.OPTIONAL;

    default:
      return AnonymityStates.DISABLED;
  }
};

export const formattedTimezones = memoize(() =>
  timeZones.map((timezone) => {
    return {
      value: timezone.value,
      id: timezone.key,
      title: timezone.value,
    };
  }),
)();

export const findTimeZoneIndex = (timeZoneString: string | undefined) => {
  let timeZoneIndex = 0;
  if (timeZoneString) {
    timeZoneIndex = formattedTimezones.findIndex(
      (timezone) => timezone.id === timeZoneString,
    );

    if (timeZoneIndex < 0) {
      return 0;
    }
  }

  return timeZoneIndex;
};
