import styled, { css } from 'styled-components';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';

import ThemeV2 from '../../theme';

export interface GridInterface {
  inlineGrid?: boolean;
  columnGap?: string;
  rowGap?: string;
}

export const Grid = styled.div<GridInterface>`
  display: ${({ inlineGrid }) => (inlineGrid ? `inline-grid` : `grid`)};
  column-gap: ${({ columnGap }) => columnGap && `${columnGap}`};
  row-gap: ${({ rowGap }) => rowGap && `${rowGap}`};
  grid-template-columns: repeat(auto-fill, minmax(242px, 1fr));
`;

export interface FlexInterface {
  borderBottom?: boolean;
  justifyContent?: string;
  alignItems?: string;
  textAlign?: string;
  margin?: string;
  padding?: string;
  height?: string;
  flexWrap?: string;
  flexGrow?: number;
  flexDirection?: string;
  flex?: number;
  width?: string;
  inlineFlex?: boolean;
  gap?: string;
  alignContent?: string;
}

export const Flex = styled.div<FlexInterface>`
  display: ${({ inlineFlex }) => (inlineFlex ? `inline-flex` : `flex`)};
  flex-direction: ${({ flexDirection }) => flexDirection || `row`};
  align-items: ${({ alignItems }) => alignItems || `center`};
  justify-content: ${({ justifyContent }) => justifyContent || `unset`};
  text-align: ${({ textAlign }) => textAlign && `${textAlign}`};
  padding: ${({ padding }) => (padding ? `${padding}` : `unset`)};
  margin: ${({ margin }) => (margin ? `${margin}` : `unset`)};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${ThemeV2.palette.gray4}` : 'unset'};
  height: ${({ height }) => height && `${height}`};
  width: ${({ width }) => width && `${width}`};
  flex-wrap: ${(props) => props.flexWrap && props.flexWrap};
  flex-grow: ${({ flexGrow }) => flexGrow && `${flexGrow}`};
  flex: ${({ flex }) => flex && flex};
  gap: ${({ gap }) => gap && `${gap}`};
  align-content: ${({ alignContent }) => alignContent && alignContent};
  box-sizing: border-box;
`;

export const FlexCSS = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
`;

export const Spacing = createTheme({
  spacing: 4,
});

export const themeStyles = makeStyles({
  displayNone: {
    display: 'none',
  },
  displayFlex: {
    display: 'flex',
  },
  displayBlock: {
    display: 'block',
  },
  displayInlineBlock: {
    display: 'inline-block',
  },
  displayInlineFlex: {
    display: 'inline-flex',
  },
  flexJustifyBetween: {
    justifyContent: 'space-between',
  },
  flexJustifyAround: {
    justifyContent: 'space-around',
  },
  flexJustifyStart: {
    justifyContent: 'flex-start',
  },
  flexJustifyEnd: {
    justifyContent: 'flex-end',
  },
  flexJustifyCenter: {
    justifyContent: 'center',
  },
  flexAlignItemsCenter: {
    alignItems: 'center',
  },
  flexAlignItemsBaseline: {
    alignItems: 'baseline',
  },
  flexAlignItemsStart: {
    alignItems: 'flex-start',
  },
  flexAlignItemsEnd: {
    alignItems: 'flex-end',
  },
  flexAlignItemsStretch: {
    alignItems: 'stretch',
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  flex4: { flex: 4 },
  flex5: { flex: 5 },
  flex6: { flex: 6 },
  flex7: { flex: 7 },
  flex8: { flex: 8 },
  margin: ({ top, right, bottom, left }: MarginPaddingProps) => ({
    margin: `${top}px ${right}px ${bottom}px ${left}px`,
  }),
  padding: ({ top, right, bottom, left }: MarginPaddingProps) => ({
    padding: `${top}px ${right}px ${bottom}px ${left}px`,
  }),
  marginRight: ({ right }: { right?: number }) => ({
    marginRight: `${right}px !important`,
  }),
  marginBottom: ({ bottom }: { bottom?: number }) => ({
    marginBottom: `${bottom}px !important`,
  }),
});

interface MarginPaddingProps {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export const margin = (top = 0, right = 0, bottom = 0, left = 0) => {
  const spacing = themeStyles({ top, right, bottom, left });
  return spacing.margin;
};

export const padding = (top = 0, right = 0, bottom = 0, left = 0) => {
  const spacing = themeStyles({ top, right, bottom, left });
  return spacing.padding;
};

export const marginRight = (right = 0) => {
  const spacing = themeStyles({ right });
  return spacing.marginRight;
};

export const marginBottom = (bottom = 0) => {
  const spacing = themeStyles({ bottom });
  return spacing.marginBottom;
};

export const FeedsGridContainerLayout = styled.main<{
  fullWidth?: boolean;
}>`
  width: 100%;
  max-width: ${({ fullWidth }) => !fullWidth && '1062px'};

  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
`;

export const NoteBookContainer = styled.section`
  margin: 0 auto;

  grid-column-start: 1;
  grid-column-end: 13;

  @media (min-width: 1377px) {
    grid-column-start: 2;
    grid-column-end: 12;
  }
`;

export const FeedsContainer = styled.section<{
  isFullScreen?: boolean;
}>`
  padding: ${({ isFullScreen }) => (isFullScreen ? '112px 16px 16px' : '16px')};
  grid-column-start: 1;
  grid-column-end: 13;
  transition: all 0.5s;

  @media (min-width: 320px) and (max-width: 1214px) {
    grid-column-start: 1;
    grid-column-end: 13;
  }

  @media (min-width: 1215px) and (max-width: 1376px) {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  @media (min-width: 1377px) {
    grid-column-start: 3;
    grid-column-end: 11;
  }
`;

export const FluidContainer = styled.section<{
  contentPadding?: string;
  contentMargin?: string;
}>`
  margin: ${({ contentMargin }) => (contentMargin ? contentMargin : '0')};
  padding: ${({ contentPadding }) => (contentPadding ? contentPadding : '0')};
  grid-column-start: 1;
  grid-column-end: 13;
`;
