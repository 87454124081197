export interface ToggleSwitchProps {
  id: string;
  isOn: boolean;
  onChange: (id: string) => void;
  onChangeWithEvent?: (
    e: React.ChangeEvent<HTMLInputElement> | undefined,
  ) => void;
  disabled?: boolean;
  className?: string;
  dataTestId?: string;
  dataQaId?: string;
  textType?: SwitchTextTypes;
}

export enum SwitchTextTypes {
  ON_OFF = 'ON_OFF',
  YES_NO = 'YES_NO',
  EMPTY = 'EMPTY',
}
