import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import * as Analytics from '../Utils/analytics';
import { PAGE_NAMES } from '../Utils/analytics/constants';
import useRedirectStore from '../stores/redirectStore';
import { NEW_APP_URL } from '../config';
import { microsoftTeamsStore } from '../stores/microsoftStore';
import isEmpty from 'lodash/isEmpty';
import { signOutAction } from '../Utils/session';

export const useSignOut = () => {
  const clearPath = useRedirectStore((store) => store.clearPath);
  const queryClient = useQueryClient();

  const handleUserSignOut = useCallback(async () => {
    queryClient.removeQueries();
    Analytics.trackPageView(PAGE_NAMES.SIGN_OUT);
    Analytics.signoutEvent();
    await signOutAction();
    if (!isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint)) {
      window.location.href = `${NEW_APP_URL}/ms-teams/login`;
    } else {
      clearPath();
      window.location.href = NEW_APP_URL;
    }
  }, [queryClient, clearPath]);

  return {
    handleUserSignOut,
  };
};
