import React from 'react';
import { EmptyErrorStateTemplateProps, HeadingType } from './interfaces';
import {
  StyledButton,
  StyledHeading,
  StyledImage,
  TextWrapper,
} from './styles';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';

const EmptyErrorStateTemplate = (props: EmptyErrorStateTemplateProps) => {
  const {
    image,
    heading,
    className,
    subHeading,
    button,
    secondaryButton,
    variant = HeadingType.Regular,
  } = props;

  return (
    <div className={className}>
      <StyledImage src={image} alt={subHeading} />
      <TextWrapper>
        {variant === HeadingType.Regular && (
          <Body variant="body1Medium">{heading}</Body>
        )}
        {variant === HeadingType.Bold && (
          <StyledHeading variant="h6">{heading}</StyledHeading>
        )}
        <Body variant="body2">{subHeading}</Body>
        {button && (
          <Flex justifyContent="center">
            {secondaryButton && (
              <StyledButton
                icon={secondaryButton.icon}
                onClick={secondaryButton.onClick}
                color={secondaryButton.color}
                $marginRight="16px"
              >
                {secondaryButton.text}
              </StyledButton>
            )}
            <StyledButton
              icon={button.icon}
              onClick={button.onClick}
              color={button.color}
            >
              {button.text}
            </StyledButton>
          </Flex>
        )}
      </TextWrapper>
    </div>
  );
};

export default EmptyErrorStateTemplate;
