import { useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

import { FlowDetailsLoadedProps } from '../../../../atomic/organism/RightDrawerAbout/FlowDetails/types';
import { ComponentStatus } from '../../../../interfaces/component';

import { UserListModalProps } from '../../../../atomic/molecules/UserListModal/interfaces';
import {
  FeedPostUserProps,
  PostSortOptions,
} from '../../../../interfaces/Feed';

import { getFlowFrequencyText } from '../../../../Utils/flows/builder/utils';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../../../Utils/toast';
import { AvatarProps } from '../../../../atomic/atoms/Avatar/interfaces';
import { users } from '../../../../atomic/molecules/UserListDropdown/data';
import { GENERATE_FLOW_DATA } from '../../../../constants/endpoints';
import useJobPollingForFlowDownload, {
  DownloadDataJobProps,
} from '../../../../hooks/useJobPollingForDownload';
import { useFlowViewersSearch } from '../../../../hooks/useMembersSearch';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../../interfaces/PostMessage';
import {
  DATA_DOWNLOADED_SUCCESSFULLY,
  DATA_DOWNLOAD_ERROR,
  PREPARING_DATA_FOR_DOWNLOAD,
} from '../../../../languages/en/flows';
import {
  useFetchFlowDetailsQuery,
  useGetFlowFeedsQuery,
} from '../../../../queries/Flows/Feed';
import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';
import useLayoutStore from '../../../../stores/layoutStore';
import mobileStore from '../../../../stores/mobileAppStore';
import useModalsStore from '../../../../stores/modalsStore';
import { setParticipationFlowSelector } from '../../../../stores/modalsStore/selectors';
import { FlowAccessRoles } from '../../../../queries/Flows/interfaces';

const useFlowDetailsControllerLogic = () => {
  const { flowId } = useParams<{ flowId: string }>();

  const {
    data,
    isError,
    isInitialLoading: isLoading,
  } = useFetchFlowDetailsQuery(flowId);

  const { data: flowDetails } = useFetchFlowDetailsQuery(flowId, 'builder');
  const { data: flowFeedsData } = useGetFlowFeedsQuery({
    flowId,
    flowFeedsSort: PostSortOptions.DESC,
  });
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);

  const {
    models: {
      totalMembers,
      searchedMembers,
      isError: isUsersError,
      value: memberSearchValue,
      isLoading: isUsersLoading,
      hasMoreMembers: hasNextPage,
    },
    operations: {
      fetchMoreMembers: fetchMoreUsers,
      onChange: onMemberSearchChange,
    },
  } = useFlowViewersSearch(flowId);

  const { downloadFlowData } = useJobPollingForFlowDownload({
    preJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
    postJobAPI: {
      apiName: GENERATE_FLOW_DATA,
      substitutions: {
        flowId,
      },
    },
  });

  const flowFeeds: FlowFeedResponse[] = useMemo(() => {
    if (!flowFeedsData) {
      return [];
    }

    return flowFeedsData.pages.reduce<FlowFeedResponse[]>(
      (acc, page) => [...acc, ...page.data],
      [],
    );
  }, [flowFeedsData]);

  const flowDetailsData = data?.data;

  const [isOpen, setIsOpen] = useState(false);

  const viewersModalProps: UserListModalProps = useMemo(() => {
    return {
      isOpen,
      handleClose: () => {
        setIsOpen(false);
      },
      onShowMoreUserClick: (member: FeedPostUserProps) => {
        console.log('member: ', member);
      },
      users,
      heading: 'Viewers',
      placeholder: 'Search for members with visibility',
    };
  }, [isOpen]);

  const flowOwnerProps: AvatarProps = useMemo(() => {
    return {
      img: flowDetailsData?.owner.image || '',
      name: flowDetailsData?.owner.name,
      userId: flowDetailsData?.owner.memberID,
      memberState: flowDetailsData?.owner.memberState,
    };
  }, [flowDetailsData]);

  const flowCollaborators = useMemo(() => {
    if (flowDetailsData?.collaborators) {
      return flowDetailsData.collaborators.map((collaborator) => {
        return {
          img: collaborator.image,
          name: collaborator.name,
          userId: collaborator.memberID,
          memberState: collaborator.state,
        };
      });
    }
    return [];
  }, [flowDetailsData?.collaborators]);

  const WhosIncludedClickHandler = () => {
    setIsOpen(true);
  };

  const downloadClickHandler = useCallback(async () => {
    showInfoMessage(PREPARING_DATA_FOR_DOWNLOAD);
    const downloadData: DownloadDataJobProps = await downloadFlowData();

    if (
      downloadData.data.url !== undefined &&
      downloadData.status === 'SUCCESS'
    ) {
      if (mobileStore.getState().isMobileApp) {
        postMessageToMobileApp({
          action: AppAction.FileDownload,
          targetEvent: AppTargetEvent.Mobile,
          payload: {
            linkURL: downloadData.data.url,
          },
        });
      } else {
        window.open(downloadData.data.url, '_self', 'noopener,noreferrer');
        dismissAllToasts();
        setTimeout(() => showSuccessMessage(DATA_DOWNLOADED_SUCCESSFULLY), 500);
      }
    } else {
      showErrorMessage(DATA_DOWNLOAD_ERROR);
    }
  }, [downloadFlowData]);

  const visibleUsers = searchedMembers?.map((member) => {
    return {
      ...member,
      image: member.image,
    } as FeedPostUserProps;
  });

  const userDetails = useMemo(() => {
    return {
      users: visibleUsers,
      hasMoreUsersToFetch: !!hasNextPage,
      fetchMoreMembers: fetchMoreUsers,
      isUsersError,
      isUsersLoading,
      onMemberSearchValueChange: onMemberSearchChange,
      memberSearchValue,
    };
  }, [
    fetchMoreUsers,
    hasNextPage,
    isUsersError,
    isUsersLoading,
    memberSearchValue,
    onMemberSearchChange,
    visibleUsers,
  ]);

  const {
    toggleRightAsideOpen,
    setMemberAndToggleRightAsideOpen,
    setShowChats,
  } = useLayoutStore(
    (state) => ({
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
      setShowChats: state.setShowChats,
    }),
    shallow,
  );

  const onMemberClick = useCallback(
    ({ memberID, name }) => {
      if (memberID && name) {
        setShowChats(true);
        setMemberAndToggleRightAsideOpen({
          memberId: memberID,
          firstName: name,
        });
      }
    },
    [setMemberAndToggleRightAsideOpen],
  );

  const handleAnswerAnytimeClick = useCallback(() => {
    setParticipationFlow({
      participationFlowId: flowId,
      occurrenceId: data?.data.occurrence.activeOccurrence?.occurrenceId,
    });
  }, [setParticipationFlow, flowId, data]);

  const scheduleRule = flowDetailsData?.schedule?.rule;

  const canShowDownloadOption =
    flowDetailsData?.isFlowOwner ||
    Boolean(
      flowDetailsData?.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
    );

  const flowDetailsProps: FlowDetailsLoadedProps = useMemo(() => {
    return {
      status: ComponentStatus.LOADED,
      toggleRightAsideOpen,
      flowOwnerProps,
      flowDescription: flowDetailsData?.description || '',
      flowId: flowDetailsData?.flowId || '',
      visibility: flowDetailsData?.visibility.criteria || 'everyone',
      visibilityCount: totalMembers,
      onWhosIncludedClick: WhosIncludedClickHandler,
      viewersModalProps,
      onDownloadClick: downloadClickHandler,
      showDownloadOption: canShowDownloadOption,
      disableDownloadOption: flowFeeds.length === 0,
      userDetails,
      onMemberClick,
      kind: flowDetailsData?.kind,
      isShortcut: flowDetailsData?.isShortcut,
      flowFrequencyText: getFlowFrequencyText(scheduleRule),
      onAnswerAnytimeClick: handleAnswerAnytimeClick,
      linkAccess: flowDetails?.data.accessPolicy?.linkAccess,
      flowCollaborators,
    };
  }, [
    toggleRightAsideOpen,
    flowOwnerProps,
    flowDetailsData?.description,
    flowDetailsData?.flowId,
    flowDetailsData?.visibility.criteria,
    flowDetailsData?.kind,
    flowDetailsData?.isShortcut,
    totalMembers,
    viewersModalProps,
    downloadClickHandler,
    canShowDownloadOption,
    flowFeeds.length,
    userDetails,
    onMemberClick,
    scheduleRule,
    handleAnswerAnytimeClick,
    flowDetails?.data.accessPolicy?.linkAccess,
    flowCollaborators,
  ]);

  return {
    models: { flowDetailsProps, isLoading, isError },
    operations: { handleAnswerAnytimeClick },
  };
};

export default useFlowDetailsControllerLogic;
