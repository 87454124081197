/* eslint-disable max-len */
import { useMemo } from 'react';
import { FeedContext } from '../../../../Utils/flows/feeds';
import { FeedsContainer } from '../../../../Utils/styles/display';
import EmptyErrorStateTemplate from '../../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import useSinglePostSocket, {
  WebSocketSinglePostTypes,
} from '../../../../hooks/useSinglePostSocket';
import searching from '../../../../img/searching.svg';
import { FLOW_FEED_STATES } from '../../../../languages/en/flows/feed';
import FlowResponseController from '../../../flows/FlowResponseController';
import FlowsFeedPostLoader from '../../../flows/FlowsFeedController/Loader';
import { useNewSingleFlowFeedPostController } from './useNewSingleFlowFeedPostController';

import moment from 'moment';
import format from 'date-fns/format';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { Nullable } from '../../../../Utils/common';
import { mapHexCodeToEmoticon } from '../../../../Utils/mappers';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import { getWorkspaceTitlePrefix } from '../../../../Utils/richMetaData';
import { FlowFeedResponse } from '../../../../queries/Flows/Feed/interfaces';
import useLayoutStore from '../../../../stores/layoutStore';

const NoPostsFoundComponent = ({
  profileData,
}: {
  profileData: Nullable<GetProfileInfoResponse>;
}) => {
  const { push } = useHistory();

  const button = useMemo(() => {
    return {
      text: 'Log into a different workspace',
      onClick: () => push('/signout'),
    };
  }, [push]);

  const subHeading = `${FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      You’re currently logged into ${profileData?.assembly.name}. Make sure you’re logged into the correct Assembly workspace.`;

  return (
    <EmptyErrorStateTemplate
      button={button}
      image={searching}
      subHeading={subHeading}
      variant={HeadingType.Bold}
      heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
    />
  );
};

export const NewSingleFlowFeedPostController = () => {
  const {
    isError,
    isLoading,
    profileData,
    data,
    feedContextProps,
    handleEditPostClick,
  } = useNewSingleFlowFeedPostController();

  const { isEmbeddedInMainApp } = useLayoutStore();

  useSinglePostSocket({
    type: WebSocketSinglePostTypes.NEW_FLOW_POST,
    flowId: data?.flow.flowId,
  });

  const pageTitle = `Post by ${data?.respondent?.name} in ${
    data?.flow.name
  } on ${moment(data?.createdAt).format('MMM Do YYYY')}`;

  const workspaceName = profileData?.assembly.name;

  const flowTitle = `${
    data?.flow.icon.value && mapHexCodeToEmoticon(data?.flow.icon.value)
  } ${data?.flow.name}`.trim();

  const createdAt =
    data?.createdAt &&
    format(new Date(data?.createdAt), 'MM/dd/yy') +
      ' at ' +
      format(new Date(data?.createdAt), 'hh:mmaaa');

  const openGraphTitle = `Post by ${
    data?.respondent?.name
  } in ${flowTitle} on ${createdAt} | ${getWorkspaceTitlePrefix(
    workspaceName,
  )}`;

  const isLoaded = !isLoading && !isError && Boolean(data);

  const showPostInteractionSettings =
    isEmbeddedInMainApp && data?.showTurnOffSettings;

  return (
    <FeedsContainer>
      {isLoading && <FlowsFeedPostLoader />}
      {isError && <NoPostsFoundComponent profileData={profileData} />}
      {isLoaded && (
        <FeedContext.Provider value={feedContextProps}>
          <Helmet>
            <title>{pageTitle}</title>
            <meta property="og:title" content={openGraphTitle} />
          </Helmet>
          <FlowResponseController
            response={data as FlowFeedResponse}
            profileData={profileData}
            showEditOption={data?.canEdit || false}
            handleEditPostClick={handleEditPostClick}
            showPostInteractionSettings={showPostInteractionSettings}
            hideRepliesBar={(data as FlowFeedResponse).hideReplies}
            hideReactions={(data as FlowFeedResponse).hideReactions}
            activeAnnouncement={data?.activeAnnouncement}
            canEndAnnouncement={Boolean(data?.canEndAnnouncement)}
            canEditAnnouncement={Boolean(data?.canEditAnnouncement)}
            canShareAsAnnouncement={Boolean(data?.canShareAsAnnouncement)}
            canViewAnnouncementInsights={Boolean(
              data?.activeAnnouncement?.showInsights,
            )}
          />
        </FeedContext.Provider>
      )}
    </FeedsContainer>
  );
};
