import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NEW_APP_URL } from '../../config';
import {
  ANSWER_FLOW,
  AUTH_SAML,
  USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN,
  EXTERNAL_PATH,
  FLOWS,
  ONBOARDING_SETUP,
  SIGN_OUT,
} from '../../constants/routes';
import { authStore } from '../../stores/authStore';

import useRedirectStore from '../../stores/redirectStore';
import useMultiAssemblySlug from '../useMultiAssemblySlug';
import { datadogLogs } from '@datadog/browser-logs';

export const useMemberAuthenticated = () => {
  const isAuthenticated = authStore((store) => store.authenticated);
  const setPath = useRedirectStore((store) => store.setPath);
  const storePath = useRedirectStore((store) => store.path);
  const clearPath = useRedirectStore((store) => store.clearPath);
  const { push } = useHistory();
  const { pathname, search } = useLocation();

  const { slugUrl } = useMultiAssemblySlug(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      const handleExtSignOut = (ev: MessageEvent) => {
        if (
          typeof ev.data === 'object' &&
          ev.data.event === 'assemblySignout' &&
          ev.data.source === 'browser_extension'
        ) {
          authStore.getState().resetAuthStore();
        }
      };

      window.addEventListener('message', handleExtSignOut);

      return () => window.removeEventListener('message', handleExtSignOut);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      let path = pathname;
      if (search) {
        path = `${path}${search}`;
      }
      setPath(path);
      if (path.startsWith(FLOWS) && !path.includes('editor')) {
        if (!path.includes(ANSWER_FLOW))
          push(`${pathname}${ANSWER_FLOW}?isRedirected=true`);
        return;
      } else if (
        path.includes(AUTH_SAML) ||
        path.includes(`${EXTERNAL_PATH}/`) ||
        path.match(/\/signin\/authorize/g) ||
        path.startsWith('/template/')
      ) {
        push(path);
      } else if (path.includes('referrer=extension')) {
        sessionStorage.setItem('referrer', 'extension');
        window.location.href = `${NEW_APP_URL}${path}`;
      } else {
        window.location.href = NEW_APP_URL;
      }
    } else if (sessionStorage.getItem('referrer') === 'extension') {
      window.close();
    }
  }, [push, search, slugUrl, setPath, isAuthenticated, pathname]);

  useEffect(() => {
    if (isAuthenticated) {
      const redirectUrl = storePath;
      const NO_REDIRECT_ROUTES = [
        ONBOARDING_SETUP,
        USER_ONBOARDING_WITHOUT_OPTIONS_SCREEN,
        SIGN_OUT,
      ];
      if (
        redirectUrl &&
        !NO_REDIRECT_ROUTES.includes(pathname) &&
        !NO_REDIRECT_ROUTES.includes(redirectUrl)
      ) {
        clearPath();
        const slugFromRedirectUrl = redirectUrl.split('/')[1];
        if (slugFromRedirectUrl === slugUrl) {
          try {
            push(redirectUrl);
          } catch (errorAttributes) {
            datadogLogs.logger.error(
              'pushing redirect URL to history errored out',
              {
                errorAttributes,
                redirectUrl,
                isAuthenticated,
                slugFromRedirectUrl,
              },
            );
          }
        }
      }
    }
  }, [clearPath, isAuthenticated, storePath, push, pathname, slugUrl]);

  return isAuthenticated;
};
