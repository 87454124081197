import { BaseEmoji } from 'emoji-mart';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

import useFlowBuilderStore, {
  createBlockDataObject,
} from '../../../../stores/flowBuilderStore';

import {
  FlowBuilderBlockData,
  FlowBuilderBlockTypes,
} from '../../../../interfaces/Flow/Builder';

import { useFlowBuilderValidation } from '../../../../hooks/useFlowBuilderValidation';
import useArchiveFlowOption from '../../../../hooks/useFlowFeedOptions/useArchiveFlowOption';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import useFlowBuilderEdit from '../useFlowBuilderEdit';

import {
  FLOW_BUILDER_EVENTS,
  INVITE_MEMBER_EVENTS,
} from '../../../../Utils/analytics/constants';

import useSaveFlow from '../../../../hooks/useSaveFlow';
import useToggle from '../../../../hooks/useToggle';

import {
  blockDataSelector,
  descriptionSelector,
  disableNavigationModalSelector,
  emojiSelector,
  flowNameSelector,
  isFlowDataChangeConfirmedSelector,
  isInEditModeSelector,
  isInviteNewTeammatesModalOpenSelector,
  newContentMembersToInviteSelector,
  newParticipantsToInviteSelector,
  newVisibilityMembersToInviteSelector,
  ownerSelector,
  revertBlockDataToDefaultStateSelector,
  setCollaboratorsSelector,
  setDescriptionSelector,
  setDisableNavigationModalSelector,
  setEmojiSelector,
  setFlowNameSelector,
  setIsFlowDataChangeConfirmedSelector,
  setIsInEditModeSelector,
  setIsInviteNewTeammatesModalOpenSelector,
  setOwnerSelector,
  setShowDataChangeConfirmSelector,
  setSpecificBlockDataSelector,
  showDataChangeConfirmSelector,
  templateNameSelector,
  templateTypeSelector,
} from '../../../../stores/flowBuilderStore/selectors';

import {
  trackFlowBuilderActionEvent,
  trackFlowBuilderShowEvent,
} from '../../../../Utils/analytics/flowsBuilder';

import { trackInviteMemberActionEvent } from '../../../../Utils/analytics/inviteMembers';
import { getProfileFullName } from '../../../../Utils/text';
import { showWelcomeToBuilderToast } from '../../../../Utils/toast';

import { FLOWS_FEEDS } from '../../../../constants/routes';
import useHistoryWrapper from '../../../../hooks/useHistoryWrapper';
import { defaultBuilderBlocks } from '../data';
import { AutocompleteDropdownItem } from '../../../../atomic/organism/Autocomplete/interfaces';
import { IMemberDTO } from '../../../../interfaces/member';
import { MemberState } from '../../../../interfaces/user';

const defaultBlockData = createBlockDataObject();

const useFlowBuilderController = (folderId: string | null) => {
  const { search } = useLocation();

  const emoji = useFlowBuilderStore(emojiSelector);
  const owner = useFlowBuilderStore(ownerSelector);
  const flowName = useFlowBuilderStore(flowNameSelector);
  const setOwner = useFlowBuilderStore(setOwnerSelector);
  const setEmoji = useFlowBuilderStore(setEmojiSelector);
  const blockData = useFlowBuilderStore(blockDataSelector);
  const description = useFlowBuilderStore(descriptionSelector);
  const setFlowName = useFlowBuilderStore(setFlowNameSelector);
  const isInEditMode = useFlowBuilderStore(isInEditModeSelector);
  const templateType = useFlowBuilderStore(templateTypeSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const setDescription = useFlowBuilderStore(setDescriptionSelector);
  const setIsInEditMode = useFlowBuilderStore(setIsInEditModeSelector);
  const setCollaborators = useFlowBuilderStore(setCollaboratorsSelector);

  const { TRIGGER, PARTICIPANTS, VISIBILITY, CONTENT, COMPLETION } =
    defaultBuilderBlocks;

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );
  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );

  const newContentMembersToInvite = useFlowBuilderStore(
    newContentMembersToInviteSelector,
  );

  const allNewMembers = Array.from(
    new Set([
      ...newParticipantsToInvite,
      ...newVisibilityMembersToInvite,
      ...newContentMembersToInvite,
    ]),
  );

  const setSpecificBlockData = useFlowBuilderStore(
    setSpecificBlockDataSelector,
  );

  const showDataChangeConfirm = useFlowBuilderStore(
    showDataChangeConfirmSelector,
  );

  const disableNavigationModal = useFlowBuilderStore(
    disableNavigationModalSelector,
  );

  const setShowDataChangeConfirm = useFlowBuilderStore(
    setShowDataChangeConfirmSelector,
  );

  const setDisableNavigationModal = useFlowBuilderStore(
    setDisableNavigationModalSelector,
  );

  const isFlowDataChangeConfirmed = useFlowBuilderStore(
    isFlowDataChangeConfirmedSelector,
  );

  const setIsFlowDataChangeConfirmed = useFlowBuilderStore(
    setIsFlowDataChangeConfirmedSelector,
  );

  const revertBlockDataToDefaultState = useFlowBuilderStore(
    revertBlockDataToDefaultStateSelector,
  );

  const isInviteNewTeammatesModalOpen = useFlowBuilderStore(
    isInviteNewTeammatesModalOpenSelector,
  );

  const setIsInviteNewTeammatesModalOpen = useFlowBuilderStore(
    setIsInviteNewTeammatesModalOpenSelector,
  );

  const [templateId, setTemplateId] = useState('');
  const { data: profileInfo } = useProfileInfoFetchQuery();
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [touchedBlocks, setTouchedBlocks] = useState<number[]>([]);
  const [unlockedBlocks, setUnlockedBlocks] = useState<number[]>([]);

  const {
    flowId,
    flowOwner,
    collaborators,
    flowIdToDuplicate,
    hasActiveOccurrence,
    isScheduledFlowEnded,
    isScheduledFlowCreatedUsingOneClick,
  } = useFlowBuilderEdit();

  const { isValid, isCurrentBlockDataValid } = useFlowBuilderValidation();

  const {
    models: { toggleValue: isPreviewModalOpen },
    operations: { setToggleValue: togglePreviewModalOpen },
  } = useToggle();

  const openInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(true);
  };

  const allBlocks = Object.keys(defaultBuilderBlocks) as Array<
    keyof typeof FlowBuilderBlockTypes
  >;

  const blocksToBeUnLocked = [
    PARTICIPANTS.index,
    VISIBILITY.index,
    CONTENT.index,
    COMPLETION.index,
  ];

  const flowOwners: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (flowIdToDuplicate && profileInfo) {
        const fullName = getProfileFullName(profileInfo.member);
        return [
          {
            id: profileInfo.member.memberId || '',
            title: fullName,
            avatar: {
              userId: profileInfo.member.memberId,
              img: profileInfo.member.profile.image,
              name: fullName,
              memberState: profileInfo.member.state,
              isDeleted: profileInfo.member.state === MemberState.DEACTIVATED,
            },
          },
        ];
      }
      const loggedInUser = profileInfo?.member;
      const memberState = flowOwner
        ? flowOwner.memberState
        : loggedInUser?.state;
      const fullName =
        memberState === MemberState.DEACTIVATED
          ? 'Deactivated user'
          : getProfileFullName(
              flowOwner
                ? {
                    profile: {
                      firstName: flowOwner.firstName,
                      lastName: flowOwner.lastName,
                    },
                  }
                : loggedInUser,
            );
      const flowOwnerData: AutocompleteDropdownItem<string, IMemberDTO>[] = [
        {
          id: flowOwner ? flowOwner.memberID : loggedInUser?.memberId || '',
          title: fullName,
          avatar: {
            userId: flowOwner
              ? flowOwner.memberID
              : loggedInUser?.memberId || '',
            img: flowOwner
              ? flowOwner.image || undefined
              : loggedInUser?.profile.image || undefined,
            name: fullName,
            memberState: memberState,
            isDeleted: flowOwner
              ? flowOwner.memberState === MemberState.DEACTIVATED
              : loggedInUser?.state === MemberState.DEACTIVATED,
          },
        },
      ];
      return flowOwnerData.length ? flowOwnerData : [];
    }, [flowIdToDuplicate, flowOwner, profileInfo]);

  const flowCollaborators: AutocompleteDropdownItem<string, IMemberDTO>[] =
    useMemo(() => {
      if (collaborators?.length) {
        const collaboratorsData: AutocompleteDropdownItem<
          string,
          IMemberDTO
        >[] = collaborators.map((collaborator) => {
          return {
            id: collaborator.memberID,
            title:
              collaborator.state === MemberState.DEACTIVATED
                ? 'Deactivated user'
                : collaborator.name,
            avatar: {
              userId: collaborator.memberID,
              img: collaborator.image || undefined,
              name: collaborator.name,
              isDeleted: collaborator.state === MemberState.DEACTIVATED,
              icon:
                collaborator.state === MemberState.PENDING
                  ? 'pending-person'
                  : '',
              fillAllIconPaths: true,
            },
            memberState: collaborator.state,
          };
        });
        return collaboratorsData;
      }
      return [];
    }, [collaborators]);

  useEffect(() => {
    setOwner(flowOwners);
    setCollaborators(flowCollaborators);
  }, [flowCollaborators, flowOwners, setCollaborators, setOwner]);

  useEffect(() => {
    if (isInviteNewTeammatesModalOpen) {
      let subSource = 'participantCriteria';
      if (newParticipantsToInvite.length) {
        subSource = 'participantCriteria';
      }
      if (newVisibilityMembersToInvite.length) {
        subSource = 'viewerCriteria';
      }
      if (newContentMembersToInvite.length) {
        subSource = 'personSelection';
      }
      trackInviteMemberActionEvent({
        action: INVITE_MEMBER_EVENTS.INVITE_START,
        inviterId: profileInfo?.member.memberId,
        inviterRole: profileInfo?.member.role,
        source: 'builder',
        subSource: subSource,
      });
    }
  }, [
    isInviteNewTeammatesModalOpen,
    newContentMembersToInvite,
    newParticipantsToInvite,
    newVisibilityMembersToInvite,
    profileInfo,
  ]);

  const closeInviteNewTeammatesModal = () => {
    setIsInviteNewTeammatesModalOpen(false);
  };

  const { saveFlow, isSavingFlow, inviteAndSaveFlow } = useSaveFlow({
    openInviteNewTeammatesModal,
    closeInviteNewTeammatesModal,
    folderId,
  });

  const {
    models: { isArchiveModalOpen, isArchiveFlowLoading },
    operations: {
      setArchiveModalOpen,
      setArchiveModalClose,
      handleOnArchiveClick,
    },
  } = useArchiveFlowOption(flowId);

  const openArchiveModal = () => {
    setArchiveModalOpen();
  };

  useEffect(() => {
    setDisableNavigationModal(false);
  }, [setDisableNavigationModal]);

  useEffect(() => {
    setTemplateId(new URLSearchParams(search).get('template') || '');
    trackFlowBuilderShowEvent({
      show: FLOW_BUILDER_EVENTS.BUILDER_SHOW,
    });
  }, [search]);

  useEffect(() => {
    if (new URLSearchParams(search).get('flow') === 'survey_Team') {
      showWelcomeToBuilderToast();
    }
  }, [search]);

  useEffect(() => {
    switch (currentStep) {
      case defaultBuilderBlocks.ACTION.index:
        // TO DO: Sumedha - Check the right event
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.BUILDER_SHOW,
        });
        break;
      case defaultBuilderBlocks.TRIGGER.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.TRIGGER_SHOW,
        });
        break;
      case defaultBuilderBlocks.PARTICIPANTS.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.PARTICIPATION_SHOW,
        });
        break;
      case defaultBuilderBlocks.VISIBILITY.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.VISIBILITY_SHOW,
        });
        break;
      case defaultBuilderBlocks.CONTENT.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.CONTENT_SHOW,
        });
        break;
      case defaultBuilderBlocks.COMPLETION.index:
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.CONFIRMATION_SHOW,
        });
        break;
      default:
        break;
    }
  }, [currentStep]);

  const history = useHistoryWrapper();

  // To set the builder in edit mode only when set schedule is triggered from schedule card(One-click-flows)
  useEffect(() => {
    if (isScheduledFlowCreatedUsingOneClick) {
      history.replace(
        FLOWS_FEEDS.replace(':flowId', `${flowId}/editor?setSchedule=false`),
      );
      setIsInEditMode(true);
    }
  }, [isScheduledFlowCreatedUsingOneClick, flowId, history, setIsInEditMode]);

  useEffect(() => {
    if (templateId) {
      setCurrentStep(0);
      setTouchedBlocks([0, 1, 2]);
      setUnlockedBlocks([0, 1, 2]);
    }

    setCurrentStep(0);

    if (flowId) {
      if (isInEditMode) {
        setCurrentStep(0);
        setTouchedBlocks([0]);
        setUnlockedBlocks([0, 1, 2]);
      } else {
        setCurrentStep(-1);
      }
      setDisableNavigationModal(!isInEditMode);
    }

    return;
  }, [
    flowId,
    isInEditMode,
    templateType,
    templateId,
    setDisableNavigationModal,
  ]);

  const updateEditMode = (editMode: boolean) => {
    const isInSchedulerMode = search.includes('setSchedule');
    if (!isInSchedulerMode) {
      if (flowId) {
        setIsInEditMode(editMode);
        setUnlockedBlocks([]);
      }
    }
  };

  useEffect(() => {
    // In the beginning if in EDIT mode, unlock blocks
    // Else, show builder in disabled state
    updateEditMode(!hasActiveOccurrence);

    return () => {
      updateEditMode(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActiveOccurrence]);

  const handleFlowNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFlowName(e.target.value);
  };

  const handleSetFlowDescription = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };

  const handledTouchedBlocks = useCallback(() => {
    const newTouchedBlocks = !touchedBlocks.includes(currentStep)
      ? [...touchedBlocks, currentStep]
      : touchedBlocks;
    setTouchedBlocks(newTouchedBlocks);
  }, [currentStep, touchedBlocks]);

  const checkBlockDataChanges = useMemo(() => {
    const currentBlockKey = allBlocks[
      currentStep
    ] as keyof FlowBuilderBlockData;
    // Check if the blockData for the current block is changed as compared to default block data
    const hasBlockDataChanged =
      currentStep !== COMPLETION.index
        ? !isEqual(
            defaultBlockData[currentBlockKey],
            blockData[currentBlockKey],
          )
        : !isEmpty(flowName) && !isEmpty(emoji);
    return hasBlockDataChanged;
  }, [blockData, currentStep, emoji, flowName]);

  useEffect(() => {
    if (checkBlockDataChanges && currentStep !== TRIGGER.index) {
      handledTouchedBlocks();
    }
  }, [
    checkBlockDataChanges,
    currentStep,
    handledTouchedBlocks,
    emoji,
    flowName,
  ]);

  const goToNextStep = useCallback(() => {
    const newUnlockedBlocks = uniq([...unlockedBlocks, currentStep]);
    setUnlockedBlocks(newUnlockedBlocks);
    handledTouchedBlocks();
    setCurrentStep(currentStep + 1);
  }, [currentStep, handledTouchedBlocks, unlockedBlocks]);

  const goToNextStepFromTrigger = useCallback(() => {
    const newUnlockedBlocks = uniq([
      ...unlockedBlocks,
      currentStep,
      ...blocksToBeUnLocked,
    ]);
    setUnlockedBlocks(newUnlockedBlocks);
    handledTouchedBlocks();
    setCurrentStep(currentStep + 1);
  }, [currentStep, handledTouchedBlocks, unlockedBlocks]);

  const handleEditButtonClick = useCallback(
    (index: number) => {
      setCurrentStep(index);
    },
    [setCurrentStep],
  );

  const handleOnSetEmoji = useCallback(
    (selectedEmoji: BaseEmoji) => {
      setEmoji(selectedEmoji);
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_EMOJI_ADDED,
        flowEmoji: selectedEmoji?.name,
      });
    },
    [setEmoji],
  );

  const handlePreviewModalOpen = useCallback(() => {
    if (!isPreviewModalOpen)
      trackFlowBuilderActionEvent({
        action: FLOW_BUILDER_EVENTS.FLOW_PREVIEWED,
      });
    togglePreviewModalOpen();
  }, [isPreviewModalOpen, togglePreviewModalOpen]);

  return {
    models: {
      emoji,
      owner,
      flowId,
      flowName,
      blockData,
      templateId,
      templateName,
      currentStep,
      description,
      profileInfo,
      isInEditMode,
      touchedBlocks,
      unlockedBlocks,
      isArchiveModalOpen,
      isPreviewModalOpen,
      isArchiveFlowLoading,
      isScheduledFlowEnded,
      hasActiveOccurrence,
      showDataChangeConfirm,
      disableNavigationModal,
      disableSubmit: !isValid,
      isFlowDataChangeConfirmed,
      revertBlockDataToDefaultState,
      isFlowBeingSaved: isSavingFlow,
      isInviteNewTeammatesModalOpen,
      isScheduledFlowCreatedUsingOneClick,
      inviteNewMemberEmailIds: allNewMembers,
      flowIdToDuplicate,
    },
    operations: {
      goToNextStep,
      setTemplateId,
      setCurrentStep,
      openArchiveModal,
      setArchiveModalClose,
      setSpecificBlockData,
      handleFlowNameChange,
      handleOnArchiveClick,
      handleEditButtonClick,
      goToNextStepFromTrigger,
      togglePreviewModalOpen: handlePreviewModalOpen,
      isCurrentBlockDataValid,
      onSubmitClick: saveFlow,
      onInviteAndSaveFlow: inviteAndSaveFlow,
      handleSetFlowDescription,
      setShowDataChangeConfirm,
      setEmoji: handleOnSetEmoji,
      setIsFlowDataChangeConfirmed,
      openInviteNewTeammatesModal,
      closeInviteNewTeammatesModal,
    },
  };
};

export default useFlowBuilderController;
