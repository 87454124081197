import Pusher, { Options } from 'pusher-js';
import Ajax from 'pusher-js/types/src/core/http/ajax';
import { API_URL_WITH_V3, PUSHER_KEY } from '../config';
import mobileStore from '../stores/mobileAppStore';

// Enable pusher logging - don't include this in production
// Pusher.logToConsole = true;

const mobileAuthToken = mobileStore.getState().jwtToken;

Pusher.Runtime.createXHR = () => {
  const xhr = new XMLHttpRequest();

  if (!import.meta.env.VITE_IS_MOBILE_DEV_MODE) {
    xhr.withCredentials = true;
  }

  return xhr as Ajax;
};

const pusherOptions: Options = {
  cluster: 'mt1',
  forceTLS: true,
  authEndpoint: `${API_URL_WITH_V3}/users/pusher/auth`,
};

if (import.meta.env.VITE_IS_MOBILE_DEV_MODE && mobileAuthToken) {
  pusherOptions.auth = {
    headers: {
      Authorization: `JWT ${mobileAuthToken}`,
    },
  };
}

export const pusher = new Pusher(PUSHER_KEY, pusherOptions);
