import styled from 'styled-components';

import ThemeV2 from '../../../theme';

export const ToolTipText = styled.span<{
  toolTipSize?: string;
  enableToolTipHover?: boolean;
}>`
  width: ${({ toolTipSize }) => (toolTipSize ? toolTipSize : 'max-content')};
  background-color: ${ThemeV2.palette.gray9};
  padding: 4px 8px;
  border-radius: 4px;
  margin: ${({ enableToolTipHover }) => (enableToolTipHover ? '0px' : '4px')};
  z-index: 99;
`;

export const ToolTipRoot = styled.div`
  position: relative;
  display: inline-flex;
`;
