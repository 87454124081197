import React from 'react';

import ThemeV2 from '../../../theme';
import { FlowFeedPostWrapper, StyledContentLoader } from './styles';

const FlowsFeedPostLoader = () => {
  return (
    <FlowFeedPostWrapper>
      <StyledContentLoader
        speed={2}
        width={474}
        height={170}
        viewBox="0 0 474 170"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
        data-testid="flow-feed-post-loader"
      >
        <circle cx="40" cy="36" r="20" />
        <rect x="68" y="18" rx="4" ry="4" width="257" height="16" />
        <rect x="68" y="38" rx="4" ry="4" width="189" height="16" />
        <rect x="24" y="102" rx="4" ry="4" width="426" height="12" />
        <rect x="24" y="124" rx="4" ry="4" width="313" height="12" />
      </StyledContentLoader>
    </FlowFeedPostWrapper>
  );
};

export default FlowsFeedPostLoader;
