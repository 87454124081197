import { datadogLogs } from '@datadog/browser-logs';
import { app } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { device } from '../constants/layout';
import { microsoftTeamsStore } from '../stores/microsoftStore';

export const useMicrosoftTeamsContext = () => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const getTeamsContext = async () => {
    try {
      await app.initialize();
      const context = await app.getContext();
      if (context && context.user?.loginHint) {
        microsoftTeamsStore
          .getState()
          .setMSTeamsLoginHint(context.user?.loginHint);
        if (isMobileView) {
          microsoftTeamsStore
            .getState()
            .setIsLoggedInThroughMobileTeamsApp(
              Boolean(context.user?.loginHint),
            );
        }
      }
    } catch (err) {
      datadogLogs.logger.error('Error getting context for teams');
    }
  };

  useEffect(() => {
    getTeamsContext();
  }, []);

  return;
};
