import React, { memo } from 'react';
import Modal from '../../atoms/Modal';
import SVGIcon from '../../atoms/SVGIcon';
import {
  ModalBackground,
  MobileModalHeader,
  ColumnLeft,
  ColumnRight,
  ModalNavigation,
  ModalCloseButton,
  ColumnRightContent,
  StyledHeading,
  StyledIconButton,
} from './styles';
import { TwoColumnModalProps } from './interface';
import ThemeV2 from '../../../theme';

const TwoColumnModal = (props: TwoColumnModalProps) => {
  const {
    isOpen,
    onClose,
    contentLeft,
    contentRight,
    label,
    height = '444px',
    width = '750px',
    contentRightHeight,
    isMobileView,
  } = props;
  return (
    <Modal
      aria-labelledby={label}
      isOpen={isOpen}
      handleClose={onClose}
      showMobileFullScreen={isMobileView}
    >
      {isMobileView && (
        <MobileModalHeader padding="16px" alignItems="center">
          <StyledHeading variant="h7" weight="bold">
            {'Redeem Reward'}
          </StyledHeading>
          <StyledIconButton onClick={onClose}>
            <SVGIcon icon="close" size="24px" color={ThemeV2.palette.gray8} />
          </StyledIconButton>
        </MobileModalHeader>
      )}
      <ModalBackground
        height={height}
        width={width}
        isMobileView={isMobileView}
      >
        <ColumnLeft>{contentLeft}</ColumnLeft>
        <ColumnRight>
          {!isMobileView && (
            <ModalNavigation>
              <ModalCloseButton
                onClick={onClose}
                rounded
                dataTestId="modal-close-button"
              >
                <SVGIcon
                  icon="modal-close"
                  size="32px"
                  color={ThemeV2.palette.gray6}
                />
              </ModalCloseButton>
            </ModalNavigation>
          )}
          <ColumnRightContent height={contentRightHeight}>
            {contentRight}
          </ColumnRightContent>
        </ColumnRight>
      </ModalBackground>
    </Modal>
  );
};
const MemoizedTwoColumnModal = memo(TwoColumnModal);
MemoizedTwoColumnModal.displayName = 'TwoColumnModal';
export default MemoizedTwoColumnModal;
