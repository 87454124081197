import { useEffect, useMemo, useRef } from 'react';

import useParticipationFlow from '../../../hooks/useParticipationFlow';
import {
  FlowSubmissionDetails,
  StaticBlockState,
} from '../../../interfaces/Flow';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { processFlowBlocks } from './utils';
import { PARTICIPATION_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { isBlockEmpty } from '../../../hooks/useParticipationFlow/utils';
import {
  trackFlowParticipationActionEvent,
  trackFlowParticipationShowEvent,
} from '../../../Utils/analytics/flowsParticipation';

export type ExternalFlowDetails = {
  name: string;
  category?: string;
  description: string;
  kind: string;
  icon: {
    kind: string;
    value: string;
  };
  schedule: {
    rule: string;
  };
  creator: {
    name: string;
    logo: string;
  };
};

const useParticipationFlowController = (
  flowId: string,
  instanceDetails: FlowInstanceResponse,
  toggleParticipationModalOpen: () => void,
  profileInfo: GetProfileInfoResponse,
  onFlowSubmit: (submissionDetails: FlowSubmissionDetails) => void,
  externalFlowDetails?: ExternalFlowDetails,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { data: flowDetails } = useFetchFlowDetailsQuery(
    instanceDetails.flowId,
  );

  const flowData = flowDetails?.data || {
    ...externalFlowDetails,
    flowId: flowId,
  };

  const [schema, initialValues, stepData] = useMemo(
    () =>
      processFlowBlocks(
        instanceDetails.blocks,
        profileInfo,
        undefined,
        externalFlowDetails,
      ),
    [externalFlowDetails, instanceDetails.blocks, profileInfo],
  );

  useEffect(() => {
    trackFlowParticipationShowEvent({
      ...flowData,
      flowId: flowDetails?.data.flowId || flowId,
      action: PARTICIPATION_ANALYTICS_EVENTS.START,
    });
  }, []);

  const postStepChange = (
    blockData: StaticBlockState,
    values: Record<string, any>,
  ) => {
    const isSkipped = isBlockEmpty(blockData, values);
    trackFlowParticipationActionEvent({
      ...flowData,
      action: isSkipped
        ? PARTICIPATION_ANALYTICS_EVENTS.BLOCK_SKIPPED
        : PARTICIPATION_ANALYTICS_EVENTS.BLOCK_ANSWERED,
    });
  };

  const { models, operations } = useParticipationFlow({
    staticBlockData: stepData,
    schema,
    initialValues,
    containerRef,
    postStepChange,
    onFlowSubmit,
  });

  const handleModalClose = (draftSaveValues?: { onDraftSave?: () => void }) => {
    trackFlowParticipationActionEvent({
      ...flowData,
      action: PARTICIPATION_ANALYTICS_EVENTS.EXIT_LEAVE_CLICKED,
    });

    toggleParticipationModalOpen();
    operations.resetForm();
    draftSaveValues?.onDraftSave?.();
  };

  return {
    models: {
      ...models,
      stepData,
      containerRef,
      allowPrivateResponse: Boolean(flowDetails?.data.allowPrivateResponse),
      allowAnonymousResponse: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.OPTIONAL,
      ),
      isAnonymityEnabled: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.ENABLED,
      ),
    },
    operations: {
      ...operations,
      handleModalClose,
    },
  };
};

export default useParticipationFlowController;
