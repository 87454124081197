import React, { useCallback } from 'react';
import { FeedPostReactionsProps } from './interface';
import { FeedPostReactionsRoot } from './styles';
import ReactionBar from '../ReactionsBar/newIndex';
import Divider from '../../atoms/Divider';
import ThemeV2 from '../../../theme';
import { Reaction } from '../../../interfaces/Feed';
import { EmojiData } from 'emoji-mart';

const FeedPostReactions = (props: FeedPostReactionsProps) => {
  const {
    feedItemMetaData,
    currentUserId,
    reactions,
    isPickerDisabled,
    onReactButtonClick,
    onReactionToggleClick,
    onEmoticonSelect,
    contentId,
    hideInteractions = false,
    hideBorder,
    variant,
  } = props;

  const handleReactionToggleClick = useCallback(
    (reaction: Reaction, currentContentId: string) =>
      onReactionToggleClick(reaction, currentContentId, feedItemMetaData),
    [onReactionToggleClick, feedItemMetaData],
  );

  const handleEmoticonSelect = useCallback(
    (emoji: EmojiData, currentContentId: string) =>
      onEmoticonSelect(emoji, currentContentId, feedItemMetaData, reactions),
    [onEmoticonSelect, feedItemMetaData, reactions],
  );

  if (hideInteractions && reactions.length === 0) {
    return null;
  }

  return (
    <FeedPostReactionsRoot>
      {!hideBorder && (
        <Divider isFullWidth color={ThemeV2.palette.gray4} marginBottom="8px" />
      )}
      <ReactionBar
        variant={variant}
        currentUserId={currentUserId}
        reactions={reactions}
        isPickerDisabled={isPickerDisabled}
        onReactButtonClick={onReactButtonClick}
        contentId={contentId}
        hideInteractions={hideInteractions}
        onReactionToggleClick={handleReactionToggleClick}
        onEmoticonSelect={handleEmoticonSelect}
      />
    </FeedPostReactionsRoot>
  );
};

const MemoizedFeedPostReactions = React.memo(FeedPostReactions);
MemoizedFeedPostReactions.displayName = 'FeedPostReactions';
export default MemoizedFeedPostReactions;
