import { EmojiData } from 'emoji-mart';
import { useHistory } from 'react-router';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useLayoutStore from '../../../../stores/layoutStore';

import {
  showChatsSelector,
  selectedMemberSelector,
  removeSelectedMemberSelector,
} from '../../../../stores/layoutStore/selectors';

import useRepliesSidebar from '../../../../hooks/useRepliesSidebar';
import { useCommentInput } from '../../../../hooks/useFeedCommentInput';
import useMultiAssemblySlug from '../../../../hooks/useMultiAssemblySlug';

import { isUserAdmin } from '../../../../Utils/user';
import { copyToClipboard } from '../../../../Utils/common';
import { showSuccessMessage } from '../../../../Utils/toast';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';

import { getCanDeletePosts } from '../../../../queries/Profile/utils';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { useFetchMembersDetailsQuery } from '../../../../queries/MembersDetails';
import {
  useDeleteChatMessage,
  useGetChats,
  useMarkChatsAsRead,
  useUpdateChatReactions,
} from '../../../../queries/Chats';

import { Chat } from '../../../../interfaces/Chats';
import { PostTypes } from '../../../../interfaces/Home';

import {
  generateV2UserProfileRoute,
  V2_USER_PROFILE,
} from '../../../../constants/routes';

import {
  MemberInteractionType,
  Reaction,
  UpdateReactionAction,
} from '../../../../interfaces/Feed';

import {
  getEmojiPayload,
  serializeReactionTogglePayload,
} from '../../../../Utils/serializers/feed';

import { trackPostActionEvent } from '../../../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../../constants/layout';
import { shallow } from 'zustand/shallow';
import useMobileApp from '../../../../hooks/useMobileApp';
import postMessageToMobileApp from '../../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../../interfaces/PostMessage';
import useSetRepliesToSidebar from '../../../../hooks/useSetRepliesToSideBar';

const useChatController = () => {
  const { reset } = useRepliesSidebar();
  const { isMobileApp } = useMobileApp();
  const { userId } = useParams<{ userId: string }>();
  const { search } = useLocation();
  const { handleSetReplies, shouldResetReplies } = useSetRepliesToSidebar();

  const { slugUrl } = useMultiAssemblySlug();

  const setShowChats = useLayoutStore(showChatsSelector);
  const selectedMember = useLayoutStore(selectedMemberSelector);
  const removeSelectedMember = useLayoutStore(removeSelectedMemberSelector);

  const {
    isRightAsideOpen,
    setMemberAndToggleRightAsideOpen,
    setRightAsideOpenToTrue,
    closeRightSideBarForMobile,
    isEmbeddedInMainApp,
  } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
      setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
      closeRightSideBarForMobile: state.closeRightSideBarForMobile,
      isEmbeddedInMainApp: state.isEmbeddedInMainApp,
    }),
    shallow,
  );

  const {
    hasNextPage,
    fetchNextPage,
    fetchPreviousPage,
    data: paginatedChats,
    isInitialLoading: isFetchingChats,
  } = useGetChats(selectedMember?.memberId);

  const routeMatch = useRouteMatch(`/${slugUrl}${V2_USER_PROFILE}`);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { mutate } = useMarkChatsAsRead(selectedMember?.memberId || '');

  const chatsContainer = useRef<HTMLDivElement>(null);

  const scrollChatsInView = useCallback(() => {
    const element = chatsContainer.current;
    if (element) {
      element.scroll({ top: element.scrollHeight });
    }
  }, []);

  const chats = useMemo(() => {
    if (isMobileApp && selectedMember?.memberId) {
      postMessageToMobileApp({
        targetEvent: AppTargetEvent.Mobile,
        action: AppAction.ChatLoaded,
        payload: {
          id: selectedMember?.memberId,
        },
      });
    }
    const unsortedChats = paginatedChats?.pages?.reduce<Chat[]>(
      (acc, page) => [...acc, ...page.data],
      [],
    );

    if (unsortedChats && unsortedChats.length) {
      mutate();
    }
    setTimeout(scrollChatsInView, 0);

    return unsortedChats?.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }, [
    mutate,
    isMobileApp,
    scrollChatsInView,
    paginatedChats?.pages,
    selectedMember?.memberId,
  ]);

  const { data: profileData } = useProfileInfoFetchQuery();

  const { commentInputProps } = useCommentInput({
    postId: '',
    flowId: '',
    profileData,
    responseId: '',
    trophyReceivers: [],
    postType: PostTypes.CHAT,
    chatToMemberId: selectedMember?.memberId,
  });

  const { assemblyCurrency, currentUserId, isCurrentUserAdmin } =
    useMemo(() => {
      return {
        assemblyCurrency: profileData?.assembly.currency,
        currentUserId: profileData?.member.memberId,
        isCurrentUserAdmin: profileData
          ? isUserAdmin(profileData.member)
          : false,
        canDelete: getCanDeletePosts(profileData),
      };
    }, [profileData]);

  const {
    data,
    isError: isMembersDetailsLoadError,
    isLoading: isMembersDetailsLoading,
  } = useFetchMembersDetailsQuery(
    selectedMember?.memberId ? [selectedMember.memberId] : [],
    !!selectedMember?.memberId,
  );

  const selectedProfile = useMemo(() => {
    if (Array.isArray(data?.membersDetails) && data?.membersDetails.length) {
      const [selectedMemberDetails] = data.membersDetails;

      return {
        id: selectedMemberDetails?.memberId,
        image: selectedMemberDetails?.profile.image,
        lastName: selectedMemberDetails?.profile.lastName,
        firstName: selectedMemberDetails?.profile.firstName,
        state: selectedMemberDetails?.state,
      };
    }

    return null;
  }, [data?.membersDetails]);

  const handleOnViewProfileClick = useCallback(() => {
    setShowChats(false);
  }, [setShowChats]);

  const handleOnCloseClick = useCallback(() => {
    if (!shouldResetReplies) {
      handleSetReplies();
    }
    removeSelectedMember();
    const isOnProfileRoute = !!routeMatch;
    if (isOnProfileRoute && userId && !isMobileView) {
      setMemberAndToggleRightAsideOpen({
        memberId: userId,
      });
    }

    closeRightSideBarForMobile();
  }, [
    shouldResetReplies,
    removeSelectedMember,
    routeMatch,
    userId,
    isMobileView,
    handleSetReplies,
    setMemberAndToggleRightAsideOpen,
    closeRightSideBarForMobile,
  ]);

  const handleOnCopyProfileLink = useCallback(() => {
    const memberId = selectedMember?.memberId;
    if (memberId) {
      const url = `/${slugUrl}${generateV2UserProfileRoute(memberId)}`;
      copyToClipboard(
        `${window.location.origin}${isEmbeddedInMainApp ? `/a${url}` : url}`,
      );

      showSuccessMessage(COPIED_TO_CLIPBOARD);
    }
  }, [isEmbeddedInMainApp, selectedMember?.memberId, slugUrl]);

  // Responsive Chat Container
  const [footerHeight, setFooterHeight] = useState(100);
  const chatInputContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatInputContainer.current) {
      let height = chatInputContainer.current.scrollHeight || 100;

      if (commentInputProps.selectedGif) {
        height += 10;
      }

      setFooterHeight(height);
    }
  }, [commentInputProps.editorState, commentInputProps.selectedGif]);

  // Handle Route Change
  const history = useHistory();

  useEffect(() => {
    const showChats =
      new URLSearchParams(search).get('showDirectMessage') === 'true';
    if (selectedMember?.memberId && !showChats) {
      const params = new URLSearchParams(search);
      params.set('showDirectMessage', 'true');
      params.set('profileId', selectedMember.memberId);

      history.replace({
        search: params.toString(),
      });
    } else if (!isRightAsideOpen && showChats) {
      setRightAsideOpenToTrue();
    }
  }, [history, search, selectedMember?.memberId]);

  // Handle Reactions
  const { mutate: mutateChatReaction } = useUpdateChatReactions();

  const handleOnEmotionSelect = useCallback(
    (reactions: Reaction[], emoji: EmojiData, contentId: string) => {
      if (profileData) {
        const [, payload] = getEmojiPayload(reactions, emoji);
        mutateChatReaction({
          ...serializeReactionTogglePayload(
            payload,
            contentId,
            UpdateReactionAction.SET,
            profileData.member,
          ),
          selectedMemberId: selectedMember?.memberId,
        });

        trackPostActionEvent({
          reaction: emoji.name,
          memberId: selectedMember?.memberId,
          messageType: 'directMessages',
          action: POST_ANALYTICS_EVENTS.REPLY_REACTION_ADDED,
        });
      }
    },
    [mutateChatReaction, profileData, selectedMember?.memberId],
  );

  const handleOnReactionToggle = useCallback(
    ({ reaction, active }: Reaction, contentId: string) => {
      if (profileData) {
        mutateChatReaction({
          ...serializeReactionTogglePayload(
            reaction,
            contentId,
            active ? UpdateReactionAction.UNSET : UpdateReactionAction.SET,
            profileData.member,
          ),
          selectedMemberId: selectedMember?.memberId,
        });

        trackPostActionEvent({
          reaction: reaction.name,
          memberId: selectedMember?.memberId,
          messageType: 'directMessages',
          action: active
            ? POST_ANALYTICS_EVENTS.REPLY_REACTION_ADDED
            : POST_ANALYTICS_EVENTS.REPLY_REACTION_REMOVED,
        });
      }
    },
    [mutateChatReaction, profileData, selectedMember?.memberId],
  );

  // Handle Member Click
  const handleOnMemberClick = useCallback(
    (person: MemberInteractionType) => {
      const { memberID, firstName } = person;
      reset();
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({ memberId: memberID, firstName });
    },
    [reset, setMemberAndToggleRightAsideOpen, setShowChats],
  );

  // Handle Chat Copy Link
  const handleOnCopyChatLink = () => {
    const url = window.location.href;
    copyToClipboard(url);
    showSuccessMessage('Link copied to clipboard');

    trackPostActionEvent({
      messageType: 'directMessages',
      action: POST_ANALYTICS_EVENTS.REPLY_LINK_COPIED,
    });
  };

  // Handle Chat Delete
  const { mutate: deleteChat } = useDeleteChatMessage();
  const handleOnChatMessageDelete = useCallback(
    (commentId: string) => {
      if (selectedMember?.memberId) {
        deleteChat({
          commentId,
          memberId: selectedMember?.memberId,
        });

        trackPostActionEvent({
          responseId: commentId,
          messageType: 'directMessages',
          action: POST_ANALYTICS_EVENTS.REPLY_DELETED,
        });
      }
    },
    [deleteChat, selectedMember?.memberId],
  );

  // Set Editor focus on load
  useEffect(() => {
    const timer = setTimeout(() => {
      commentInputProps.setEditorFocus();
    });
    return () => clearTimeout(timer);
  }, []);

  return {
    chats,
    hasNextPage,
    footerHeight,
    fetchNextPage,
    currentUserId,
    chatsContainer,
    selectedProfile,
    isFetchingChats,
    assemblyCurrency,
    commentInputProps,
    fetchPreviousPage,
    isCurrentUserAdmin,
    chatInputContainer,
    handleOnCloseClick,
    handleOnMemberClick,
    handleOnCopyChatLink,
    handleOnEmotionSelect,
    handleOnReactionToggle,
    isMembersDetailsLoading,
    handleOnCopyProfileLink,
    handleOnViewProfileClick,
    isMembersDetailsLoadError,
    handleOnChatMessageDelete,
  };
};

export default useChatController;
