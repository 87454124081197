import styled from 'styled-components';
import Body from '../../../../../atomic/atoms/Body';
import { Flex } from '../../../../../Utils/styles/display';
import SVGIcon from '../../../../../atomic/atoms/SVGIcon';
import ToolTip from '../../../../../atomic/molecules/ToolTipV2';
import ContentLoader from 'react-content-loader';
import Theme from '../../../../../theme';

const TooltipContent = styled.div`
  width: 212px;
`;

const StyledFlex = styled(Flex)`
  cursor: pointer;
  user-select: none;
`;

const StyledBody = styled(Body)`
  margin: 0 8px 0 10px;
`;

export const RequestAccessHeader = ({
  requestsCount,
  isCollapsed,
  isLoading,
}: {
  requestsCount: number;
  isCollapsed: boolean;
  isLoading: boolean;
}) => {
  return (
    <StyledFlex justifyContent="space-between" alignItems="center" tabIndex={0}>
      <Flex>
        <SVGIcon
          icon="add-user"
          size="16px"
          data-testid="SVGIcon"
          data-qa-id="icon-svg"
        />
        <StyledBody variant="body1" color="gray9">
          Requests (
          {isLoading || !requestsCount ? (
            <ContentLoader
              speed={2}
              width={12}
              height={15}
              viewBox="0 0 12 15"
              backgroundColor={Theme.palette.gray4}
              foregroundColor={Theme.palette.gray3}
              data-testid="loadingWrapper"
              data-qa-id="loadingWrapper"
              style={{ marginBottom: '-2px' }}
            >
              <rect x="0" y="0" rx="4" ry="4" width="12" height="15" />
            </ContentLoader>
          ) : (
            requestsCount
          )}
          )
        </StyledBody>
        <SVGIcon
          icon="carat-down"
          size={'14px'}
          data-testid="SVGIcon"
          data-qa-id="icon-svg"
          rotate={isCollapsed ? 180 : 0}
        />
      </Flex>
      <ToolTip
        position="right"
        toolTipComponent={<SVGIcon icon="info-italic" size="16px" />}
      >
        <TooltipContent>
          Anyone who is a part of this flow can request to add more teammates to
          the flow. Approve them here and grant permissions.
        </TooltipContent>
      </ToolTip>
    </StyledFlex>
  );
};
