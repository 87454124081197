import { AppMessage, AppTargetEvent } from '../../interfaces/PostMessage';

const postMessageToMobileApp = (payload: AppMessage) => {
  const message = JSON.stringify({
    ...payload,
    targetEvent: AppTargetEvent.Mobile,
  });

  if (
    window?.ReactNativeWebView &&
    'postMessage' in window.ReactNativeWebView
  ) {
    window.ReactNativeWebView.postMessage(message);
    return;
  }

  if (window.parent) {
    window.parent.postMessage(
      { type: 'FORWARD_MOBILE', payload: message },
      '*',
    );
    return;
  }
};

export default postMessageToMobileApp;
