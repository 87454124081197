import styled from 'styled-components';
import SVGIcon from '../../atoms/SVGIcon';
import Image from '../../../atomic/atoms/Image';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';

export const KioskPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  border: none;
  padding: 32px;
  background-color: ${ThemeV2.palette.gray1};
  box-shadow: 0 10px 25px rgba(202, 202, 202, 0.75);
`;

export const AssemblyLogo = styled(Image)`
  height: 16px;
`;

export const KioskModeContent = styled(Flex)`
  margin: 16px 0 8px 0;
`;

export const KioskIcon = styled(SVGIcon)`
  margin: 0 4px 0 2px;
`;
