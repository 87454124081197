import {
  ContentNPSBlockState,
  ContentBlockLocalProperties,
  NPSBlockBuilderSettingState,
} from '../../../../interfaces/Flow/Builder';
import BlockScale from '../../../molecules/BlockScale';
import FormsEditorBaseInputBlock from '../FormsEditorInputBaseBlock';

export interface FormsEditorNPSInputBlockProps
  extends ContentBlockLocalProperties {
  blockData: ContentNPSBlockState;
}

const FormsEditorNPSInputBlock = ({
  blockData,
  currentIndex,
  handleToggleIsDescriptionNeeded,
  handleToggleIsRequired,
  onValueChange,
  totalNumberOfItems,
  onDeleteClick,
  onMoveDownClick,
  onMoveUpClick,
}: FormsEditorNPSInputBlockProps) => {
  const { isRequired, description, assemblyCurrency } = blockData;

  const blockSettingState: NPSBlockBuilderSettingState = {
    type: 'NPS',
    disableRequiredToggle: false,
    toggleIsRequired: handleToggleIsRequired,
    isRequired,
    isDescriptionNeeded: description !== null,
    toggleDescriptionNeeded: handleToggleIsDescriptionNeeded,
  };

  return (
    <FormsEditorBaseInputBlock
      blockData={blockData}
      onDeleteClick={onDeleteClick}
      onMoveDownClick={onMoveDownClick}
      onMoveUpClick={onMoveUpClick}
      onValueChange={onValueChange}
      currentIndex={currentIndex !== undefined ? currentIndex : 0}
      assemblyCurrency={assemblyCurrency}
      totalNumberOfItems={totalNumberOfItems}
      blockSettingState={blockSettingState}
    >
      <div style={{ width: '100%' }}>
        <BlockScale
          isReadOnly
          value={null}
          type="normal"
          labels={{ low: 'Not likely at all', high: 'Very likely' }}
          min={0}
          max={10}
        />
      </div>
    </FormsEditorBaseInputBlock>
  );
};

export default FormsEditorNPSInputBlock;
