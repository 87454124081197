import styled, { css } from 'styled-components';

import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';

import { ReactNode } from 'react';
import ThemeV2 from '../../../theme';
import CountIndicator from '../../atoms/CountIndicator';

interface MenuProps {
  text: string;
  count?: number;
  showCount?: boolean;
  rotate?: boolean;
  disabled?: boolean;
  isExpanded?: boolean;
  className?: string;
  textBgColor?: string;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
}

const StyledIconButtonWrapper = styled.div`
  display: none;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 16px;
`;

const StyledMenu = styled(Flex)<{ disabled?: boolean }>`
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  min-height: 24px;
  justify-content: space-between;
  transition: all o.3s;
  &:hover {
    ${StyledIconButtonWrapper} {
      display: block;
    }
  }
`;

const StyledSVGIcon = styled(SVGIcon)`
  margin: 0 4px 0 12px;
`;

const StyledCountIndicator = styled(CountIndicator)`
  height: 20px;
  margin-top: 3px;
  margin-left: 4px;
`;

const StyledBody = styled(Body)<{ bgColor: string | undefined }>`
  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
      padding: 0 8px;
      border-radius: 4px;
      max-width: 180px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`;

const Menu = (props: MenuProps) => {
  const {
    prefixComponent,
    text,
    count,
    showCount,
    rotate,
    isExpanded,
    className,
    disabled,
    textBgColor,
    suffixComponent,
  } = props;

  return (
    <StyledMenu
      data-qa-id={`txt-${text}-title`}
      className={className}
      disabled={disabled}
    >
      <Flex>
        {!isExpanded && prefixComponent}
        {isExpanded && (
          <div style={{ display: 'flex' }}>
            <StyledSVGIcon
              icon={rotate ? 'carat-up' : 'carat-down'}
              size="24px"
              color={ThemeV2.palette.gray8}
            />
            <StyledBody bgColor={textBgColor}>{text}</StyledBody>
            {showCount && count !== 0 && count && (
              <StyledCountIndicator>
                <Body variant="body3" color="gray1">
                  {count}
                </Body>
              </StyledCountIndicator>
            )}
          </div>
        )}
      </Flex>
      {suffixComponent && (
        <StyledIconButtonWrapper>{suffixComponent}</StyledIconButtonWrapper>
      )}
    </StyledMenu>
  );
};

export default Menu;
