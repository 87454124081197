/* eslint-disable max-len */
import { PostTypes } from '../../../interfaces/Home';
import { defaultCurrency } from '../../../queries/Profile/utils';
import { GetProfileInfoResponse } from '../../../queries/Profile';
import { AssemblyCurrencyIconType } from '../../../atomic/atoms/AssemblyCurrencyIcon/interface';
import { FeedItemMetaData, FeedItemFromAPI } from '../../../interfaces/Feed';
import { FlowFeedResponse } from '../../../queries/Flows/Feed/interfaces';
import { MemberState } from '../../../interfaces/user';

const searchParams = new URLSearchParams(window.location.search);
const isEmbedded = searchParams.get('source') === 'embedded';

const buildPrefixedUrl = (url: string) => {
  return isEmbedded
    ? `${window.location.origin}/a/${url}`
    : `${window.location.origin}/${url}`;
};

export const checkIfFlowPostHasTrophies = (flowPost: FlowFeedResponse) => {
  let hasTrophies = false;
  flowPost.responses.forEach((response) => {
    if (response.type === 'GIVE_POINTS_STACK') {
      hasTrophies =
        typeof response.response?.value === 'number' &&
        response.response?.value > 0;
    }
  });
  return hasTrophies;
};

export const getCurrencyFromProfileData = (profile: GetProfileInfoResponse) => {
  let emoticon: AssemblyCurrencyIconType = {
    id: defaultCurrency.name,
    currencyName: defaultCurrency.name,
    ...defaultCurrency,
  };

  if (profile) {
    emoticon = {
      id: profile.assembly.currency.name,
      assemblyCurrency: profile.assembly.currency,
      currencyName: profile.assembly.currency.name,
    };
  }
  return emoticon;
};

export const getFlowPostUrl = (
  flowId: string,
  responseId: string,
  slugUrl?: string,
) => {
  if (slugUrl) {
    return buildPrefixedUrl(
      `${slugUrl}/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`,
    );
  }
  return buildPrefixedUrl(
    `post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`,
  );
};

export const getRecognitionPostUrl = (postId: string, slugUrl?: string) => {
  return slugUrl
    ? buildPrefixedUrl(
        `${slugUrl}/post/flow/recognition?postId=${postId}&showReplies=true`,
      )
    : buildPrefixedUrl(
        `post/flow/recognition?postId=${postId}&showReplies=true`,
      );
};

export const getPostUrl = (feedItemMetaData: FeedItemMetaData) => {
  const { slugUrl } = feedItemMetaData;
  if (feedItemMetaData.postId) {
    return slugUrl
      ? buildPrefixedUrl(
          `${slugUrl}/post/flow/recognition?postId=${feedItemMetaData.postId}`,
        )
      : buildPrefixedUrl(
          `post/flow/recognition?postId=${feedItemMetaData.postId}`,
        );
  } else {
    // eslint-disable-next-line max-len
    return slugUrl
      ? buildPrefixedUrl(
          `${slugUrl}/post/flow/flows?flowId=${feedItemMetaData.flowId}&responseId=${feedItemMetaData.responseId}`,
        )
      : buildPrefixedUrl(
          `post/flow/flows?flowId=${feedItemMetaData.flowId}&responseId=${feedItemMetaData.responseId}`,
        );
  }
};

export const getFlowHeaderAttributesFromResponse = (
  postType: PostTypes,
  response?: FlowFeedResponse,
  data?: FeedItemFromAPI,
) => {
  const isFlowPost = postType === PostTypes.FLOW && response;
  const person =
    isFlowPost && response
      ? response.respondent
        ? {
            ...response.respondent,
            isDeleted: false,
            memberState:
              response.respondent.memberState === 'ACTIVE'
                ? MemberState.ACTIVE
                : MemberState.DEACTIVATED,
          }
        : null
      : data && data.from
      ? {
          ...data.from,
          memberState:
            data.from?.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null;
  const visibility =
    response?.isPrivate || data?.isPrivate ? 'private' : response?.visibility;

  const flow =
    isFlowPost && response
      ? response.flow
      : {
          flowId: 'recognition',
          name: 'Give Recognition',
          icon: { kind: 'HEX_CODE', value: '1F389' },
          kind: '',
          version: 0,
        };
  return {
    flow,
    visibility,
    person,
  };
};

export const getFlowHeaderAttributesFromData = (
  data: FeedItemFromAPI,
  profile: GetProfileInfoResponse,
) => {
  const isFlowPost = data.type === PostTypes.FLOW && data.flowResponse;
  const createdAt = data.createdAt.toString();
  const postType = isFlowPost ? PostTypes.FLOW : data.type;
  const currentUserId = profile.member.memberId;
  const person =
    isFlowPost && data.flowResponse
      ? data.flowResponse.respondent
        ? {
            ...data.flowResponse.respondent,
            isDeleted: false,
            memberState:
              data.flowResponse.respondent.memberState === 'ACTIVE'
                ? MemberState.ACTIVE
                : MemberState.DEACTIVATED,
          }
        : null
      : data.from
      ? {
          ...data.from,
          memberState:
            data.from?.memberState === 'ACTIVE'
              ? MemberState.ACTIVE
              : MemberState.DEACTIVATED,
        }
      : null;
  const visibility =
    data.flowResponse?.isPrivate || data.isPrivate
      ? 'private'
      : data.flowResponse?.visibility;

  const flow =
    isFlowPost && data.flowResponse
      ? data.flowResponse.flow
      : {
          flowId: 'recognition',
          name: 'Give Recognition',
          icon: { kind: 'HEX_CODE', value: '1F389' },
          kind: '',
          version: 0,
        };
  return {
    responseId: data.flowResponse?.responseId,
    flow,
    createdAt,
    postType,
    currentUserId,
    visibility,
    person,
  };
};

export const getCommonFlowPostAttributes = (post: FeedItemFromAPI) => ({
  postId: post.postID,
  responseId: post.flowResponse?.responseId,
  flowId: post.flowResponse?.flow.flowId,
  postType: post.type,
  hasTrophies:
    post.type === PostTypes.FLOW && post.flowResponse
      ? checkIfFlowPostHasTrophies(post.flowResponse)
      : post.pointsEach > 0,
});

export const getFlowFeedUrl = (flowId: string) => {
  return `/flows/${flowId}`;
};
