import Uppy from '@uppy/core';
import { FormikErrors } from 'formik';
import last from 'lodash/last';
import uuid from 'uuid';
import FlowGIFInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowGIFInputBlock';
import FlowMultiselectInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowMultiselectInputBlock';
import FlowPersonSelectorBlock from '../../atomic/molecules/FlowInputBlocks/FlowPersonSelectorBlock';
import FlowsDropdownInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsDropdownInputBlock';
// eslint-disable-next-line max-len
import FlowsMultipleChoiceMultiselectInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsMultipleChoiceMultiselectInputBlock';
// eslint-disable-next-line max-len
import { TEMPLATE_GALLERY_EVENTS } from '../../Utils/analytics/constants';
import { trackTemplateGalleryActionEvent } from '../../Utils/analytics/templateGallery';
import { mapHexCodeToEmoticon } from '../../Utils/mappers';
import FlowsMultipleChoiceSingleSelectInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsMultipleChoiceSingleSelectInputBlock';
import FlowsOpenEndedInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsOpenEndedInputBlock';
import FlowsPreviewBlock from '../../atomic/molecules/FlowInputBlocks/FlowsPreviewBlock';
import FlowsScaleInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsScaleInputBlock';
import FlowsTrophiesInputBlock from '../../atomic/molecules/FlowInputBlocks/FlowsTrophiesInputBlock';
import { LeftDrawerCollapsibleNavItemProps } from '../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { FlowRequestTemplateCardProps } from '../../atomic/molecules/FlowRequestTemplateCard/type';
import FlowUserReceivingPointsInfo from '../../atomic/molecules/FlowUsersReceivingPointsInfo';
import FlowsPointsAdjustmentWarning from '../../atomic/molecules/FlowsPointsAdjustmentWarning';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { LoadingParticipationTemplate } from '../../atomic/pages/ParticipationTemplate';
import {
  FlowVariants,
  PointStackStaticBlockState,
  SelectablePeopleSelectorOptions,
  StaticBlockState,
} from '../../interfaces/Flow';
import {
  CriteriaGroups,
  VisibilityBuilderBlockData,
} from '../../interfaces/Flow/Builder';
import { ComponentStatus } from '../../interfaces/component';
import { IMemberDTO } from '../../interfaces/member';
import { TROPHY_BLOCK_NOT_EDITABLE_WARNING } from '../../languages/en/flows/participation';
import { GetFlowTemplateResponse } from '../../queries/Flows/Template/types';
import {
  FlowInstanceResponse,
  FlowResponse,
  FlowsActivityStates,
} from '../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../queries/Profile';
import FlowsFileUploadInputBlockController from './FlowsFileUploadInputBlockController';
import FlowsTemplatePreviewController from './FlowsTemplatePreviewController';

export const defaultCriteriaForEveryone: SelectablePeopleSelectorOptions = {
  type: 'EVERYONE',
  criteria: {
    groups: [
      {
        groupId: '1',
        groupCondition: 'and',
        groupRules: [
          {
            value: [
              {
                id: 'everyone',
                value: 'everyone',
              },
            ],
            operator: 'is',
            field: 'everyone',
            ruleId: '1',
          },
        ],
      },
    ],
    groupsCondition: 'and',
  },
};

const getCriteriaForPersonSelectorPreview = (
  selectableOptions: SelectablePeopleSelectorOptions | undefined,
  participantsCriteria: CriteriaGroups | undefined,
  viewersBlockData: VisibilityBuilderBlockData | undefined,
  owner: AutocompleteDropdownItem<string, IMemberDTO>[] | undefined,
): SelectablePeopleSelectorOptions => {
  const ownerAndCollaboratorsOnlyCriteria = owner
    ? {
        groups: [
          {
            groupId: uuid.v4(),
            groupCondition: 'or',
            groupRules: [
              {
                value: [{ id: owner[0]?.id, value: owner[0]?.title }],
                operator: 'is',
                field: 'member',
                ruleId: uuid.v4(),
              },
            ],
          },
        ],
        groupsCondition: 'or',
      }
    : undefined;

  if (selectableOptions) {
    switch (selectableOptions.type) {
      case 'PARTICIPANTS': {
        if (participantsCriteria) {
          return {
            type: selectableOptions.type,
            criteria: participantsCriteria,
          };
        }
        return defaultCriteriaForEveryone;
      }
      case 'VIEWERS': {
        if (viewersBlockData) {
          let viewersCriteria = defaultCriteriaForEveryone.criteria;
          if (viewersBlockData.custom && viewersBlockData.criteriaGroups) {
            viewersCriteria = viewersBlockData.criteriaGroups;
          } else if (
            viewersBlockData.onlyParticipants &&
            participantsCriteria
          ) {
            viewersCriteria = participantsCriteria;
          } else if (
            viewersBlockData.onlyOwnersAndCollaborators &&
            ownerAndCollaboratorsOnlyCriteria
          ) {
            return {
              type: selectableOptions.type,
              criteria: ownerAndCollaboratorsOnlyCriteria,
            };
          }
          return {
            type: selectableOptions.type,
            criteria: viewersCriteria,
          };
        }
        return defaultCriteriaForEveryone;
      }
      case 'CUSTOM': {
        if (selectableOptions.criteria) {
          return {
            type: selectableOptions.type,
            criteria: selectableOptions.criteria as CriteriaGroups,
          };
        }
        return defaultCriteriaForEveryone;
      }
      case 'EVERYONE':
      default: {
        return defaultCriteriaForEveryone;
      }
    }
  }
  return defaultCriteriaForEveryone;
};

export interface RenderSlideInfoObject {
  owner?: AutocompleteDropdownItem<string, IMemberDTO>[];
  stepData: StaticBlockState[];
  currentStep: number;
  values: Record<string, any>;
  touched: Record<string, any>;
  blockErrors: Record<string, string>;
  fieldErrors: Record<string, string>;
  goToNextStep: () => void;
  profileInfo: GetProfileInfoResponse;
  flowInstance: FlowInstanceResponse;
  remainingAllowance: number;
  onStepChange: (stepNumber: number) => void;
  setIsFetchingUploadUrl?: (isFetching: boolean) => void;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<Record<string, any>>>;
  handleDeleteFileClick?: (fileName: any) => Promise<void>;
  uppyInstances: {
    [instanceId: string]: {
      [id: string]: Uppy;
    };
  };
  isPreviewFlow?: boolean;
  participantsCriteria?: CriteriaGroups;
  viewersBlockData?: VisibilityBuilderBlockData;
  flowId?: string;
  isExternalFlow?: boolean;
  flowEndTime?: string;
  isEditMode?: boolean;
  instanceId: string;
  inviteUserFromPersonSelectorProps?: {
    isAdminApprovalRequired: boolean;
  };
  dropdownFooterMessage?: string;
}

export const renderParticipationSlide = ({
  owner,
  blockErrors,
  currentStep,
  fieldErrors,
  stepData,
  values,
  goToNextStep,
  profileInfo,
  setFieldValue,
  touched,
  flowInstance,
  remainingAllowance,
  onStepChange,
  setIsFetchingUploadUrl,
  uppyInstances,
  isPreviewFlow,
  participantsCriteria,
  viewersBlockData,
  flowId,
  isExternalFlow,
  flowEndTime,
  isEditMode,
  instanceId,
  inviteUserFromPersonSelectorProps,
  dropdownFooterMessage,
}: RenderSlideInfoObject) => {
  const currentStepData = stepData[currentStep];
  const { id: currentStepId, title, isRequired, description } = currentStepData;
  const isLastBlock = last(stepData)?.id === currentStepData.id;
  const uppyInstance =
    uppyInstances[instanceId] && uppyInstances[instanceId][currentStepId];

  switch (currentStepData.type) {
    case 'OPEN_ENDED': {
      const { allowEmojis, allowGifs, allowMentions, allowFiles, allowTasks } =
        currentStepData;

      if (allowFiles && !uppyInstance) {
        return null;
      }

      return (
        <FlowsOpenEndedInputBlock
          blockValue={values[currentStepId]}
          blockError={blockErrors[currentStepId]}
          description={description}
          fieldError={fieldErrors[currentStepId] as string}
          gifRatings={profileInfo.assembly.flow?.gifAccessibility.value}
          goToNextStep={goToNextStep}
          hasError={
            !!blockErrors[currentStepId] || !!fieldErrors[currentStepId]
          }
          isRequired={isRequired}
          onBlockChange={(newBlockValue) => {
            setFieldValue(currentStepId, newBlockValue);
          }}
          title={title}
          hideMentions={!allowMentions}
          hideGifs={!allowGifs}
          hideEmoticons={!allowEmojis}
          hideTasks={!allowTasks}
          uppy={uppyInstance}
          setIsFetchingUploadUrl={setIsFetchingUploadUrl}
          blockId={currentStepId}
          hideAttachment={!allowFiles}
          isLastBlock={isLastBlock}
          flowVariant={FlowVariants.PARTICIPATION_FLOW}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'SINGLE_SELECT_DROPDOWN': {
      const { helperText } = currentStepData;
      return (
        <FlowsDropdownInputBlock
          description={description}
          goToNextStep={goToNextStep}
          title={title}
          inputLabel={helperText}
          value={values[currentStepId]}
          onChange={(val) => setFieldValue(currentStepId, val)}
          options={currentStepData.options}
          isRequired={Boolean(isRequired)}
          blockError={blockErrors[currentStepId]}
          fieldError={fieldErrors[currentStepId] as string}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'MULTI_SELECT_DROPDOWN': {
      const { helperText, maxOptions } = currentStepData;
      return (
        <FlowMultiselectInputBlock
          description={description}
          goToNextStep={goToNextStep}
          title={title}
          inputLabel={helperText}
          value={values[currentStepId]}
          onChange={(val) => setFieldValue(currentStepId, val)}
          options={currentStepData.options}
          isRequired={Boolean(isRequired)}
          blockError={blockErrors[currentStepId]}
          fieldError={fieldErrors[currentStepId] as string}
          optionsMaxLimit={maxOptions}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'FILE_UPLOAD': {
      if (!uppyInstance) {
        return null;
      }

      return (
        <FlowsFileUploadInputBlockController
          description={description}
          blockId={currentStepId}
          flowId={flowInstance.flowId}
          goToNextStep={goToNextStep}
          instanceId={flowInstance.instanceId}
          isRequired={currentStepData.isRequired}
          setFieldValue={setFieldValue}
          title={title}
          uppy={uppyInstance}
          setIsFetchingUploadUrl={setIsFetchingUploadUrl}
          value={values[currentStepId]}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'GIF_SELECTOR': {
      return (
        <FlowGIFInputBlock
          title={title}
          selectedGif={values[currentStepId]}
          isRequired={Boolean(isRequired)}
          handleGifClick={(gifUrl) => setFieldValue(currentStepId, gifUrl)}
          description={description}
          fieldError={fieldErrors[currentStepId] as string}
          blockError={blockErrors[currentStepId]}
          goToNextStep={goToNextStep}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'SCALE': {
      const { max, min, labels } = currentStepData;
      return (
        <FlowsScaleInputBlock
          description={description}
          goToNextStep={goToNextStep}
          isRequired={Boolean(isRequired)}
          value={values[currentStepId]}
          onChange={(value) => setFieldValue(currentStepId, value)}
          title={title}
          isLastBlock={isLastBlock}
          fieldError={fieldErrors[currentStepId] as string}
          blockError={blockErrors[currentStepId]}
          labels={labels}
          minValue={min}
          maxValue={max}
          isPreviewFlow={isPreviewFlow}
        />
      );
    }
    case 'MULTI_CHOICE_MULTI_SELECT': {
      const { allowOther, limit, options } = currentStepData;
      return (
        <FlowsMultipleChoiceMultiselectInputBlock
          allowOther={allowOther}
          description={description}
          title={title}
          options={options}
          isTouched={Boolean(touched[currentStepId])}
          isRequired={Boolean(isRequired)}
          value={values[currentStepId]}
          onChange={(value) => setFieldValue(currentStepId, value)}
          goToNextStep={goToNextStep}
          limit={limit}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
          blockError={blockErrors[currentStepId]}
        />
      );
    }
    case 'MULTI_CHOICE_SINGLE_SELECT': {
      const { allowOther, options } = currentStepData;
      return (
        <FlowsMultipleChoiceSingleSelectInputBlock
          allowOther={allowOther}
          description={description}
          title={title}
          options={options}
          isRequired={Boolean(isRequired)}
          value={values[currentStepId]}
          onChange={(val) => setFieldValue(currentStepId, val)}
          goToNextStep={goToNextStep}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
          blockError={blockErrors[currentStepId]}
        />
      );
    }
    case 'SINGLE_PERSON_SELECTOR_DROPDOWN':
    case 'MULTI_PERSON_SELECTOR_DROPDOWN': {
      const {
        helperText,
        maxOptions,
        description: personSelectorDescription,
        referenceStackId,
        selectableOptions,
      } = currentStepData;

      const selectableCriteria = getCriteriaForPersonSelectorPreview(
        selectableOptions,
        participantsCriteria,
        viewersBlockData,
        owner,
      );
      let referenceStackMaxPoints: undefined | number;
      let referenceStackIndex = 0;
      let referenceStackValue: string | undefined;
      if (referenceStackId) {
        referenceStackValue = values[referenceStackId];
        referenceStackIndex = stepData.findIndex(
          (step) => step.id === referenceStackId,
        );
        const referenceStackData = stepData[referenceStackIndex] as
          | PointStackStaticBlockState
          | undefined;
        const numberOfPeopleSelected: number =
          values[currentStepId]?.length || 1;
        referenceStackMaxPoints = Math.min(
          Math.floor(remainingAllowance / numberOfPeopleSelected),
          referenceStackData?.maxPoints || 0,
        );
      }

      return (
        <FlowPersonSelectorBlock
          title={title}
          isRequired={Boolean(isRequired)}
          fieldError={fieldErrors[currentStepId] as string}
          blockError={blockErrors[currentStepId]}
          goToNextStep={goToNextStep}
          inputLabel={helperText}
          currentUserId={profileInfo.member.memberId}
          value={values[currentStepId]}
          onChange={(val) => setFieldValue(currentStepId, val)}
          flowId={flowId !== undefined ? flowId : flowInstance.flowId}
          blockId={currentStepId}
          optionsMaxLimit={maxOptions}
          onPointsStackChange={
            referenceStackId
              ? (newValue: string) => setFieldValue(referenceStackId, newValue)
              : undefined
          }
          warning={
            <FlowsPointsAdjustmentWarning
              adjustedTo={referenceStackValue || ''}
              assemblyCurrency={profileInfo.assembly.currency}
              onCallToActionClick={() => onStepChange(referenceStackIndex)}
            />
          }
          referenceStackMaxPoints={referenceStackMaxPoints}
          referenceStackValue={referenceStackValue}
          description={personSelectorDescription}
          isLastBlock={isLastBlock}
          isPreviewFlow={isPreviewFlow}
          selectableCriteria={selectableCriteria}
          isExternalFlow={isExternalFlow}
          disabled={!!(isEditMode && referenceStackId)}
          isEditMode={isEditMode}
          inviteUserFromPersonSelectorProps={inviteUserFromPersonSelectorProps}
          dropdownFooterMessage={dropdownFooterMessage}
        />
      );
    }
    case 'GIVE_POINTS_STACK': {
      const { dependentBlockId, maxPoints } = currentStepData;
      const dependentBlockValue = values[dependentBlockId];
      const isDependentBlockAnArray = Array.isArray(dependentBlockValue);
      const isDependentBlockEmpty = isDependentBlockAnArray
        ? !dependentBlockValue.length
        : !dependentBlockValue;
      const splitBy = isDependentBlockAnArray
        ? dependentBlockValue.length || 1
        : 1;
      const maxPointsProps = Math.min(
        Math.floor(remainingAllowance / splitBy),
        maxPoints,
      );
      const onEditPeopleClick = () => {
        const dependentBlockIndex = stepData.findIndex(
          ({ id }) => id === dependentBlockId,
        );
        onStepChange(dependentBlockIndex >= 0 ? dependentBlockIndex : 0);
      };

      return (
        <FlowsTrophiesInputBlock
          maxPoints={maxPointsProps}
          assemblyCurrency={profileInfo.assembly.currency}
          fieldError={fieldErrors[currentStepId] as string}
          splitBy={splitBy}
          monthlyAllowance={profileInfo.member.allowance.points}
          remainingAllowance={remainingAllowance}
          title={title}
          description={description}
          onChange={(newValue) => setFieldValue(currentStepId, newValue, false)}
          value={values[currentStepId]}
          onEditPeopleClick={onEditPeopleClick}
          isDependentBlockEmpty={isDependentBlockEmpty}
          info={
            !isEditMode && !isDependentBlockEmpty ? (
              <FlowUserReceivingPointsInfo
                onEditPeopleClick={onEditPeopleClick}
                users={
                  isDependentBlockAnArray
                    ? dependentBlockValue
                    : [dependentBlockValue]
                }
              />
            ) : null
          }
          warning={isEditMode && TROPHY_BLOCK_NOT_EDITABLE_WARNING}
          isEditMode={isEditMode}
        />
      );
    }
    case 'PREVIEW_SUMMARY': {
      return (
        <FlowsPreviewBlock
          goToNextStep={goToNextStep}
          blockValue={currentStepData}
        />
      );
    }
    case 'EXTERNAL_FLOW': {
      return (
        <FlowsPreviewBlock
          goToNextStep={goToNextStep}
          blockValue={currentStepData}
          isExternalFlow
          flowEndTime={flowEndTime}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const sortLeftDrawerNavFlowItemsByLabel = (
  a: LeftDrawerCollapsibleNavItemProps,
  b: LeftDrawerCollapsibleNavItemProps,
) => {
  const labelA = a.label.toLocaleLowerCase();
  const labelB = b.label.toLocaleLowerCase();
  if (labelA < labelB) {
    return -1;
  }
  if (labelA > labelB) {
    return 1;
  }
  return 0;
};

export const mapFlowsResponseToNavFlowItem = (
  flowItem: FlowResponse,
): LeftDrawerCollapsibleNavItemProps => {
  return {
    isMuted: Boolean(flowItem.activityState === FlowsActivityStates.Muted),
    id: flowItem.flowId,
    label: flowItem.name,
    occurrence: flowItem.occurrence,
    isFlowOwner: Boolean(flowItem.isFlowOwner),
    isFlowViewer: Boolean(flowItem.isFlowViewer),
    emoticon: mapHexCodeToEmoticon(flowItem.icon.value),
    isFlowParticipant: Boolean(flowItem.isFlowParticipant),
    folderInfo: flowItem.folderInfo,
  } as LeftDrawerCollapsibleNavItemProps;
};

export type RenderPreviewTemplateModalProps = {
  onUseTemplateClick: () => void;
  isFlowTemplateLoading: boolean;
  isCreatingOneClickFlow: boolean;
  closePreviewTemplateModal: () => void;
  profileInfo: GetProfileInfoResponse | undefined;
  flowTemplateData: GetFlowTemplateResponse | undefined;
  selectedTemplate: FlowRequestTemplateCardProps | undefined;
};

export const renderPreviewTemplateModal = (
  props: RenderPreviewTemplateModalProps,
) => {
  const {
    profileInfo,
    flowTemplateData,
    selectedTemplate,
    onUseTemplateClick,
    isFlowTemplateLoading,
    isCreatingOneClickFlow,
    closePreviewTemplateModal,
  } = props;

  if (profileInfo && selectedTemplate) {
    if (isFlowTemplateLoading) {
      return (
        <LoadingParticipationTemplate
          member={profileInfo.member}
          onCloseModal={closePreviewTemplateModal}
          flowId=""
          headerStatus={ComponentStatus.LOADING}
          flowHeaderContent={{ DescriptionContent: '', TitleContent: '' }}
        />
      );
    }
    if (flowTemplateData) {
      /* Mix panel */
      trackTemplateGalleryActionEvent({
        action: TEMPLATE_GALLERY_EVENTS.PREVIEW_TEMPLATE,
        templateId: selectedTemplate.templateId,
        templateName: selectedTemplate.title,
      });
      /* Mix panel */
      return (
        <FlowsTemplatePreviewController
          showCloseModalOption={true}
          profileInfo={profileInfo}
          flowTemplateData={flowTemplateData}
          closePreviewTemplateModal={closePreviewTemplateModal}
          onUseTemplateClick={onUseTemplateClick}
          isCreatingOneClickFlow={isCreatingOneClickFlow}
        />
      );
    }
  }
  return null;
};
