import styled from 'styled-components';
import PeopleMenuAccordion from '../../../atomic/organism/MenuAccordion/PeopleMenuAccordion';
import { DIRECT_MESSAGES } from '../../../languages/en/home';
import InviteModalController from '../../InviteModalController';
import usePeopleListController from './usePeopleListController';

const PeopleSectionWrapper = styled.div`
  padding: 16px 0;
`;

const PeopleDropdownListController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    disableParticipantsMenuList,
    hasMoreMembersToFetch,
    canInviteMembers,
    isOpen,
    isModalOpen,
    infiniteMembers,
    membersSearchValue,
    onMembersSearchChange,
    searchedMembers,
    handleEntryClick,
    toggleExpandNavigation,
    toggleInviteModal,
  } = usePeopleListController({});

  return (
    <PeopleSectionWrapper>
      <PeopleMenuAccordion
        disabled={disableParticipantsMenuList}
        isAccordionOpen={isOpen}
        onAccordionClick={toggleExpandNavigation}
        accordionTitle={DIRECT_MESSAGES}
        lists={infiniteMembers}
        canInviteMembers={!disableParticipantsMenuList && canInviteMembers}
        hasMoreMembersToFetch={hasMoreMembersToFetch}
        searchedMembers={searchedMembers}
        membersSearchValue={membersSearchValue}
        handleEntryClick={handleEntryClick}
        toggleInviteModal={toggleInviteModal}
        onMembersSearchChange={onMembersSearchChange}
        isHovered={isLeftNavHovered || isLeftAsideOpen}
      />
      <InviteModalController
        isModalOpen={isModalOpen}
        closeInviteModal={toggleInviteModal}
      />
    </PeopleSectionWrapper>
  );
};

export default PeopleDropdownListController;
