import { ComponentStatus, LoadingProps } from '../../../interfaces/component';

export enum CardWithIconSize {
  Regular = 'regular',
  Small = 'small',
}

type CardWithIconLoadingProps = LoadingProps & {
  size?: CardWithIconSize;
  className?: string;
  children?: React.ReactNode;
  color?: string;
  emoticon?: string;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  dataTestId?: string;
  onClick?: () => void;
};

export type LoadedProps = {
  status: ComponentStatus.LOADED;
  children: React.ReactNode;
  size?: CardWithIconSize;
  color?: string;
  emoticon: string;
  onClick?: () => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  dataTestId?: string;
  dataQaId?: string;
};

export type CardWithIconProps = CardWithIconLoadingProps | LoadedProps;
