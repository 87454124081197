import React, { memo, useCallback, useMemo } from 'react';
import parse from 'html-react-parser';

import AssemblyCurrencyIcon from '../../atoms/AssemblyCurrencyIcon';
import Popover from '../Popover';
import { getDisplayAmount } from '../../../Utils/rewards';
import { RewardCardProps, RewardTypes, BackgroundSizeTypes } from './interface';
import ThemeV2 from '../../../theme';
import {
  CardHeader,
  RewardAvailability,
  RewardCost,
  RewardDescriptionLink,
  RewardImageWrapper,
  RewardImage,
  RewardPopoverDescriptionText,
  RewardTitle,
  CardBody,
  RewardCardButton,
  TrophyRangeTooltip,
  RewardPopoverTooltipIcon,
  CardHeaderPopoverWrapper,
  StyledCard,
  StyledSwagToolTipText,
  ShippingCostContent,
} from './styles';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import {
  GET_SHIPPING_COST_TOOLTIP_TEXT,
  MORE_ABOUT_THIS_REWARD,
  REWARDS_AVAILABILITY_OF_TEXT,
  REWARDS_AVAILABILITY_TEXT,
  REWARDS_BUTTON_TEXT_CHARITY,
  REWARDS_BUTTON_TEXT_COMING_SOON,
  REWARDS_BUTTON_TEXT_OUT_OF_STOCK,
  REWARDS_BUTTON_TEXT_REWARD,
  REWARDS_BUTTON_TEXT_SWAGS,
  REWARDS_COST,
  SHIPPING,
} from '../../../languages/en/rewards';
import { Flex } from '../../../Utils/styles/display';
import ToolTip from '../ToolTipV2';
import { SwagCardStatuses } from '../../../queries/Admin/rewards/Swags/interface';

const RewardCard = (props: RewardCardProps) => {
  const {
    id,
    rewardType,
    image,
    backgroundSize = BackgroundSizeTypes.Square,
    title,
    assemblyCurrency,
    cost,
    description,
    onRewardSelect,
    disabled,
    rewardsCurrentAvailability,
    trophyRange,
    isLimit = false,
    isSwagTabTurnedOff,
    shippingCost,
    status,
    canShowTooltip,
  } = props;

  const renderHeaderContent = useMemo(() => {
    if (
      rewardType === RewardTypes.Culture &&
      isLimit &&
      rewardsCurrentAvailability &&
      rewardsCurrentAvailability >= 0
    ) {
      return (
        <RewardAvailability>
          <Body variant="body3">
            {`${rewardsCurrentAvailability} `} {REWARDS_AVAILABILITY_TEXT}
          </Body>
        </RewardAvailability>
      );
    }
    if (
      rewardType !== RewardTypes.Culture &&
      rewardType !== RewardTypes.Swags
    ) {
      return (
        <CardHeaderPopoverWrapper>
          <Popover
            popoverContent={
              <RewardPopoverDescriptionText variant="body3">
                {parse(description)}
              </RewardPopoverDescriptionText>
            }
            referenceContent={
              <RewardPopoverTooltipIcon>
                <SVGIcon
                  icon="info-icon"
                  size="16px"
                  color={ThemeV2.palette.gray7}
                />
              </RewardPopoverTooltipIcon>
            }
          />
        </CardHeaderPopoverWrapper>
      );
    }
    return null;
  }, [description, isLimit, rewardType, rewardsCurrentAvailability]);

  const getDisplayAmountCb = useCallback(
    (costValue: string | number, currencyCode: string) => {
      return getDisplayAmount(costValue, currencyCode);
    },
    [],
  );

  const assemblyTrophyIcon = useMemo(() => {
    return (
      <AssemblyCurrencyIcon
        assemblyCurrency={assemblyCurrency}
        currencyName={assemblyCurrency.name}
        id={assemblyCurrency.name}
        size="14px"
      />
    );
  }, [assemblyCurrency]);

  const renderBodyContent = useMemo(() => {
    if (
      rewardType === RewardTypes.Culture ||
      rewardType === RewardTypes.Swags
    ) {
      return (
        <>
          <Flex>
            <RewardCost
              variant="body2"
              data-testid="reward-cost"
              disabled={isSwagTabTurnedOff}
            >
              {`${REWARDS_COST}:`}&nbsp;
              {assemblyTrophyIcon}&nbsp;
              {cost}&nbsp; {shippingCost && shippingCost > 0 ? `+` : null}
              &nbsp;
              {shippingCost && shippingCost > 0 ? (
                <ToolTip
                  toolTipComponent={
                    <StyledSwagToolTipText color="gray8" variant="body2">
                      {SHIPPING}
                    </StyledSwagToolTipText>
                  }
                  position="top"
                >
                  <ShippingCostContent variant="body3" color="gray1">
                    {assemblyTrophyIcon}&nbsp;
                    {GET_SHIPPING_COST_TOOLTIP_TEXT(shippingCost)}
                  </ShippingCostContent>
                </ToolTip>
              ) : null}
            </RewardCost>
          </Flex>
          {rewardType === RewardTypes.Culture && (
            <Popover
              popoverContent={
                <RewardPopoverDescriptionText variant="body3">
                  {description}
                </RewardPopoverDescriptionText>
              }
              referenceContent={
                <RewardDescriptionLink variant="body3">
                  {MORE_ABOUT_THIS_REWARD}
                </RewardDescriptionLink>
              }
            />
          )}
        </>
      );
    }

    if (trophyRange && trophyRange.range.length > 0) {
      return (
        <RewardCost variant="body2">
          {`${REWARDS_COST}:`}&nbsp;
          {assemblyTrophyIcon}&nbsp;
          {trophyRange.range[0].trophies}
          {' -'}
          &nbsp;{assemblyTrophyIcon}&nbsp;
          {trophyRange?.range[trophyRange?.range.length - 1].trophies}
          {canShowTooltip && (
            <TrophyRangeTooltip>
              {trophyRange.range.map((range) => (
                <div key={range.trophies}>
                  {getDisplayAmountCb(range.cost, range.currencyCode)}
                  {` `}
                  {`${REWARDS_AVAILABILITY_OF_TEXT} `}
                  {assemblyTrophyIcon}
                  {range.trophies}
                </div>
              ))}
            </TrophyRangeTooltip>
          )}
        </RewardCost>
      );
    }

    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    assemblyTrophyIcon,
    cost,
    description,
    rewardType,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    trophyRange?.range,
    isSwagTabTurnedOff,
  ]);

  const renderButtonText = useMemo(() => {
    switch (rewardType) {
      case RewardTypes.Charity:
        return REWARDS_BUTTON_TEXT_CHARITY;
      case RewardTypes.Swags: {
        if (status) {
          if (status === SwagCardStatuses.COMING_SOON) {
            return REWARDS_BUTTON_TEXT_COMING_SOON;
          } else if (status === SwagCardStatuses.OUT_OF_STOCK) {
            return REWARDS_BUTTON_TEXT_OUT_OF_STOCK;
          } else {
            return REWARDS_BUTTON_TEXT_SWAGS;
          }
        }
        return REWARDS_BUTTON_TEXT_SWAGS;
      }
      default:
        return REWARDS_BUTTON_TEXT_REWARD;
    }
  }, [rewardType, status]);

  const onClickRewardSelect = useCallback(() => {
    onRewardSelect(id);
  }, [id, onRewardSelect]);

  return (
    <StyledCard data-testid="reward-card" data-qa-id="reward-card">
      <CardHeader>
        <RewardImageWrapper rewardType={rewardType}>
          <RewardImage
            image={image}
            disabled={isSwagTabTurnedOff}
            aria-label="reward-image"
            backgroundSize={backgroundSize}
          />
        </RewardImageWrapper>
        {renderHeaderContent}
      </CardHeader>
      <CardBody rewardType={rewardType}>
        <RewardTitle
          variant="body1Bold"
          data-testid="reward-title"
          data-qa-id="rewardTitleText"
          disabled={isSwagTabTurnedOff}
        >
          {title}
        </RewardTitle>
        {renderBodyContent}
        <RewardCardButton
          variant="outlined"
          isFullWidth
          disabled={disabled}
          onClick={onClickRewardSelect}
        >
          {renderButtonText}
        </RewardCardButton>
      </CardBody>
    </StyledCard>
  );
};

const MemoizedRewardCard = memo(RewardCard);
MemoizedRewardCard.displayName = 'MemoizedRewardCard';

export default MemoizedRewardCard;
