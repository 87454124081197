import { PopoverItemProps } from '../../../../atomic/organism/PopoverWithSubMenu/types';

export const popoverItems: PopoverItemProps[] = [
  {
    id: 'everyone',
    title: 'Entire organization',
    icon: 'assembly',
  },
  {
    id: 'member',
    title: 'Member',
    icon: 'person-filled',
  },
  {
    id: 'email',
    title: 'Email',
    icon: 'mail',
  },
  {
    id: 'department',
    title: 'Department',
    icon: 'department',
  },
  {
    id: 'manager',
    title: 'Manager',
    icon: 'person-filled',
  },
];

export const popoverLocationItems: PopoverItemProps[] = [
  {
    id: 'everyone',
    title: 'Entire organization',
    icon: 'assembly',
  },
  {
    id: 'member',
    title: 'Member',
    icon: 'person-filled',
  },
  {
    id: 'email',
    title: 'Email',
    icon: 'mail',
  },
  {
    id: 'department',
    title: 'Department',
    icon: 'department',
  },
  {
    id: 'manager',
    title: 'Manager',
    icon: 'person-filled',
  },
  {
    id: 'workLocation',
    title: 'Work location',
    icon: 'building',
  },
  {
    id: 'homeLocation',
    title: 'Home location',
    icon: 'home',
  },
  {
    id: 'jobTitle',
    title: 'Job title',
    icon: 'job-title',
  },
];
