import { useEffect } from 'react';
import { pusher } from '../../pusher/pusher-base';
import { useQueryClient } from '@tanstack/react-query';

import { PostFilterOptions } from '../../interfaces/Feed';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import {
  GET_CHATS,
  GET_FLOW_FEED,
  GET_FLOW_POST_COMMENTS,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_POST_COMMENTS,
  GET_PROFILE_FEED,
  GET_SINGLE_POST,
  V3_GET_FEED,
} from '../../constants/endpoints';
import {
  PageType,
  ReactionUpdatedPayload,
  ReactionUpdatedSocketProps,
} from './types';
import {
  updateCommentQueryReactionData,
  updateFeedQueryReactionData,
  updateFlowFeedQueryReactionData,
} from './utils';

const useReactionUpdatedSocket = (props: ReactionUpdatedSocketProps) => {
  const { pageType, sortValue, filter, profileId } = props;
  const queryClient = useQueryClient();
  const { data: currentUserData } = useProfileInfoFetchQuery();

  useEffect(() => {
    let reactionUpdatedEventHandler: (
      eventName: string,
      socketPayload: ReactionUpdatedPayload,
    ) => void | undefined;
    if (currentUserData) {
      const { assemblyId } = currentUserData.assembly;
      const channelAssembly = pusher.subscribe(
        `private-assembly-${assemblyId}`,
      );
      reactionUpdatedEventHandler = (
        eventName: string,
        socketPayload: ReactionUpdatedPayload,
      ) => {
        if (eventName === 'POST_REACTION_CHANGED') {
          switch (pageType) {
            case PageType.MainFeed: {
              updateFeedQueryReactionData({
                queryKey: [GET_MAIN_FEED, sortValue, filter],
                queryClient,
                socketPayload,
              });
              break;
            }
            case PageType.FlowFeed: {
              updateFlowFeedQueryReactionData({
                queryKey: [
                  GET_FLOW_FEED,
                  sortValue,
                  socketPayload.flowId || '',
                  filter,
                ],
                queryClient,
                socketPayload,
              });
              break;
            }
            case PageType.ProfileFeed: {
              updateFeedQueryReactionData({
                queryKey: [GET_PROFILE_FEED, profileId || '', filter],
                queryClient,
                socketPayload,
              });
              break;
            }
            case PageType.SingleFlowFeed: {
              updateFlowFeedQueryReactionData({
                queryKey: [
                  GET_FLOW_RESPONSE,
                  socketPayload.flowId || '',
                  socketPayload.responseId || '',
                ],
                queryClient,
                socketPayload,
              });
              break;
            }
            case PageType.Recognition: {
              updateFeedQueryReactionData({
                queryKey: [V3_GET_FEED, PostFilterOptions.ALL, sortValue],
                queryClient,
                socketPayload,
              });
              break;
            }
            default: {
              return;
            }
          }
        }
        if (eventName === 'COMMENT_REACTION_CHANGED') {
          if (socketPayload.flowId && socketPayload.responseId) {
            updateCommentQueryReactionData({
              queryKey: [
                GET_FLOW_POST_COMMENTS,
                socketPayload.flowId,
                socketPayload.responseId,
              ],
              queryClient,
              socketPayload,
            });
          } else if (socketPayload.postId) {
            updateCommentQueryReactionData({
              queryKey: [GET_POST_COMMENTS, socketPayload.postId],
              queryClient,
              socketPayload,
            });
          } else {
            updateCommentQueryReactionData({
              queryKey: [GET_CHATS, socketPayload.fromMember.memberId],
              queryClient,
              socketPayload,
            });
          }
        }
        if (eventName === 'FOLLOWED_OR_UNFOLLOWED_POST') {
          switch (pageType) {
            case PageType.FlowFeed: {
              queryClient.invalidateQueries([
                GET_FLOW_FEED,
                socketPayload.flowId || '',
              ]);
              break;
            }
            case PageType.ProfileFeed: {
              queryClient.invalidateQueries([
                GET_PROFILE_FEED,
                socketPayload.flowId || '',
              ]);
              break;
            }
            case PageType.SingleFlowFeed: {
              queryClient.invalidateQueries([
                GET_FLOW_RESPONSE,
                socketPayload.flowId || '',
                socketPayload.responseId || '',
              ]);
              queryClient.invalidateQueries([
                GET_SINGLE_POST,
                socketPayload.postId || '',
              ]);
              break;
            }
            case PageType.Recognition: {
              queryClient.invalidateQueries([V3_GET_FEED]);
              break;
            }
            default: {
              return;
            }
          }
        }
      };
      channelAssembly.bind_global(reactionUpdatedEventHandler);
      return () => {
        channelAssembly.unbind_global(reactionUpdatedEventHandler);
      };
    }
  }, [currentUserData, filter, pageType, profileId, queryClient, sortValue]);
};

export default useReactionUpdatedSocket;
