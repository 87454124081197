import styled from 'styled-components';

import { Flex } from '../../../../Utils/styles/display';
import Divider from '../../../atoms/Divider';
import InfoAlert from '../../../molecules/InfoAlert';

export const InputBlockWrapper = styled.div``;

export const ContentFlex = styled(Flex)`
  padding-left: 16px;
  width: 100%;
`;
export const ContentWrapperFlex = styled(Flex)`
  width: 100%;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  padding: 8px;
  margin-bottom: 16px;
  width: 100%;
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

export const SettingsContainer = styled(Flex)`
  min-width: 64px;
`;
