import isEmpty from 'lodash/isEmpty';
import IconButton from '../../../atomic/atoms/IconButton_V2';
import { ButtonSize } from '../../../atomic/atoms/IconButton_V2/interface';
import DropdownIconButton from '../../../atomic/molecules/DropdownIconButton';
import ToolTip from '../../../atomic/molecules/ToolTipV2';
import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';

const folderMenuItems = [
  {
    id: 'folder-menu',
    items: [
      {
        value: 'Edit folder',
        id: 'editFolder',
        prefixIcon: 'edit',
      },
      {
        value: 'Delete folder',
        id: 'deleteFolder',
        prefixIcon: 'delete',
      },
    ],
  },
];

const flowMenuItems = [
  {
    id: 'flow-menu',
    items: [
      {
        value: 'Create a flow',
        id: 'flow',
        prefixIcon: 'queue-list',
      },
      {
        value: 'Create a folder',
        id: 'folder',
        prefixIcon: 'folder',
      },
    ],
  },
];

type LeftNavHeaderSuffixProps = {
  isCustomFolder: boolean;
  onFlowCreate: () => void;

  onFolderCreate: () => void;
  onFolderDelete: () => void;
  onFolderEdit: () => void;
};

export function LeftNavHeaderSuffix(props: LeftNavHeaderSuffixProps) {
  const { canUserCreateFlow = false } = useGetUserFlowPermissions();
  const isLoggedInThroughTeamsMobile =
    !isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) &&
    microsoftTeamsStore.getState().isLoggedInThroughMobileTeamsApp;

  const allowedToCreateFlow =
    canUserCreateFlow && !isLoggedInThroughTeamsMobile;

  const createMenuToolTip = allowedToCreateFlow
    ? 'Create a flow or folder'
    : 'Create a folder';

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      {props.isCustomFolder && (
        <DropdownIconButton
          icon="more"
          title="Edit or delete a folder"
          menuItems={folderMenuItems}
          onItemClick={(value) => {
            if (value === 'editFolder') {
              props.onFolderEdit();
            } else if (value === 'deleteFolder') {
              props.onFolderDelete();
            }
          }}
          dataTestId="folder-menu-button"
          dataQaId="folder-menu-button"
          size={ButtonSize.Small}
          stopEventPropagation
          isDropdownPositionFixed
        />
      )}
      <ToolTip
        toolTipComponent={
          allowedToCreateFlow ? (
            <DropdownIconButton
              icon="union-rounded"
              title={createMenuToolTip}
              menuItems={flowMenuItems}
              onItemClick={(value) =>
                value === 'flow' ? props.onFlowCreate() : props.onFolderCreate()
              }
              dataTestId="flow-folder-create-button"
              dataQaId="flow-folder-create-button"
              size={ButtonSize.Small}
              stopEventPropagation
              isDropdownPositionFixed
            />
          ) : (
            <IconButton
              icon="union-rounded"
              title={createMenuToolTip}
              onClick={(e) => {
                e.stopPropagation();
                props.onFolderCreate();
              }}
              dataTestId="folder-create-button"
              dataQaId="folder-create-button"
            />
          )
        }
        position="bottom"
        positionStrategy="fixed"
      >
        {createMenuToolTip}
      </ToolTip>
    </div>
  );
}
