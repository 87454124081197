import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { AxiosError } from 'axios';

import { makeAPICall, makeAPICallWithDataReturn } from '../../utils';
import {
  FlowInstanceResponse,
  FlowResponse,
  Folder,
  GetArchivedFlowsResponse,
  GetFlowActiveResponse,
  GetFlowsActivityUpdatesResponse,
  GetPublishedFlowsResponse,
  GetShortCutsResponse,
  GetToDoFlowsResponse,
  MainNavQueryData,
} from '../interfaces';

import {
  EDIT_FLOW_RESPONSE,
  FLOW_RESPONSE_NOTIFICATION_PREFERENCE,
  GET_ACTIVE_FLOWS,
  GET_ARCHIVED_FLOWS,
  GET_DATE_FILTER_OPTIONS,
  GET_FLOWS_ACTIVITY,
  GET_FLOWS_BLOCKS_FILTER_OPTIONS,
  GET_FLOWS_FILTER_OPTIONS,
  GET_FLOWS_INSTANCE,
  GET_FLOWS_MENTIONS_FILTER_OPTIONS,
  GET_FLOWS_TODO_COUNT,
  GET_FLOWS_VERSIONS_FILTER_OPTIONS,
  GET_FLOW_FEED,
  GET_FLOW_RESPONSE,
  GET_MAIN_FEED,
  GET_MAIN_NAV_CONTENTS,
  GET_POSTED_BY_FILTER_OPTIONS,
  GET_PUBLISHED_FLOWS,
  GET_SHORTCUTS,
  GET_TODO_FLOWS,
  SUBMIT_FLOWS_INSTANCE,
} from '../../../constants/endpoints';
import {
  SubmitInstancePayload,
  UpdateNotificationsPreferencePayload,
} from '../../../interfaces/Flow';
import { ParticipationFlowDetails } from '../../../stores/modalsStore';
import { ParticipationTemplateErrorTypes } from '../../../atomic/pages/ParticipationTemplate/types';

export const useGetShortcutsQuery = (searchQuery?: string) => {
  return useQuery<GetShortCutsResponse>(
    [GET_SHORTCUTS, searchQuery],
    () => {
      if (searchQuery) {
        return makeAPICallWithDataReturn(
          GET_SHORTCUTS,
          {
            search: {
              name: searchQuery,
            },
          },
          { limit: 99 },
        );
      }

      return makeAPICallWithDataReturn(GET_SHORTCUTS, undefined, { limit: 99 });
    },
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetToDoFlowsQuery = (searchQuery?: string) => {
  return useQuery<GetToDoFlowsResponse>(
    [GET_TODO_FLOWS, searchQuery],
    () => {
      if (searchQuery) {
        return makeAPICallWithDataReturn(
          GET_TODO_FLOWS,
          {
            search: {
              name: searchQuery,
            },
          },
          {
            limit: 99,
          },
        );
      }

      return makeAPICallWithDataReturn(GET_TODO_FLOWS, undefined, {
        limit: 99,
      });
    },
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
    },
  );
};

export const useGetArchivedFlowsQuery = () => {
  return useInfiniteQuery<GetArchivedFlowsResponse>(
    [GET_ARCHIVED_FLOWS],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_ARCHIVED_FLOWS, undefined, {
        cursor: pageParam,
        limit: 20,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.pagination?.cursor?.next || undefined,
    },
  );
};

export const useSubmitFlowInstanceMutation = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: SubmitInstancePayload) => {
      const response = await makeAPICall(
        SUBMIT_FLOWS_INSTANCE,
        payload,
        undefined,
        {
          flowId,
        },
      );

      return response.data.response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries([GET_FLOWS_TODO_COUNT]);
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0] === GET_FLOW_FEED &&
            query.queryKey.some((key: string) => key === flowId),
        });
        queryClient.invalidateQueries([GET_FLOWS_FILTER_OPTIONS]);
        queryClient.invalidateQueries([GET_POSTED_BY_FILTER_OPTIONS]);
        queryClient.invalidateQueries([GET_FLOWS_VERSIONS_FILTER_OPTIONS]);
        queryClient.invalidateQueries([GET_FLOWS_MENTIONS_FILTER_OPTIONS]);
        queryClient.invalidateQueries([GET_DATE_FILTER_OPTIONS, flowId]);
        queryClient.invalidateQueries([
          GET_FLOWS_VERSIONS_FILTER_OPTIONS,
          flowId,
        ]);
        queryClient.invalidateQueries([
          GET_FLOWS_BLOCKS_FILTER_OPTIONS,
          flowId,
        ]);
        queryClient.invalidateQueries([GET_POSTED_BY_FILTER_OPTIONS, flowId]);
        queryClient.invalidateQueries([
          GET_FLOWS_MENTIONS_FILTER_OPTIONS,
          flowId,
        ]);
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);

        // eslint-disable-next-line max-len
        // I have used setTimeout(), to make the below invalidate query to be executed only after onSuccess(), gets completed !
        // Did this using the concept of eventLoops.
        setTimeout(() => {
          queryClient.invalidateQueries([GET_FLOWS_INSTANCE, flowId]);
        }, 0);
      },
    },
  );
};

export const useEditFlowResponse = (flowId = '', responseId = '') => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: SubmitInstancePayload) =>
      makeAPICall(EDIT_FLOW_RESPONSE, payload, undefined, {
        flowId,
        responseId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0] === GET_FLOW_FEED &&
            query.queryKey.some((key: string) => key === flowId),
        });
        queryClient.invalidateQueries([GET_FLOWS_MENTIONS_FILTER_OPTIONS]);
        queryClient.invalidateQueries([
          GET_FLOWS_MENTIONS_FILTER_OPTIONS,
          flowId,
        ]);
      },
    },
  );
};

export const useUpdateFlowResponseNotificationPreference = (
  flowId,
  responseId,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateNotificationsPreferencePayload) =>
      makeAPICall(FLOW_RESPONSE_NOTIFICATION_PREFERENCE, payload, undefined, {
        flowId,
        responseId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MAIN_FEED]);
        queryClient.invalidateQueries({
          predicate: (query: any) =>
            query.queryKey[0] === GET_FLOW_FEED &&
            query.queryKey.some((key: string) => key === flowId),
        });
        queryClient.invalidateQueries([GET_FLOWS_MENTIONS_FILTER_OPTIONS]);
        queryClient.invalidateQueries([
          GET_FLOWS_MENTIONS_FILTER_OPTIONS,
          flowId,
        ]);
        queryClient.invalidateQueries([GET_FLOW_RESPONSE, flowId, responseId]);
      },
    },
  );
};

export const useGetFlowInstanceQuery = ({
  flow = { participationFlowId: '' },
  enabled,
  onErrorCallback,
}: {
  flow?: ParticipationFlowDetails;
  enabled: boolean;
  onErrorCallback?: (
    error: AxiosError<{ message: ParticipationTemplateErrorTypes }>,
  ) => void;
}) => {
  const { occurrenceId, participationFlowId } = flow;
  return useQuery<FlowInstanceResponse>(
    [GET_FLOWS_INSTANCE, participationFlowId, occurrenceId],
    () =>
      makeAPICallWithDataReturn(
        GET_FLOWS_INSTANCE,
        undefined,
        occurrenceId
          ? {
              occurrenceId,
            }
          : undefined,
        {
          flowId: participationFlowId,
        },
      ),
    {
      onError: (error: unknown) => {
        if (onErrorCallback) {
          onErrorCallback(
            error as AxiosError<{ message: ParticipationTemplateErrorTypes }>,
          );
        }
      },
      refetchOnMount: 'always',
      cacheTime: 0,
      enabled,
      retry: false,
    },
  );
};

export const useGetPublishedFlowsQuery = () => {
  return useInfiniteQuery<GetPublishedFlowsResponse>(
    [GET_PUBLISHED_FLOWS],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(GET_PUBLISHED_FLOWS, undefined, {
        cursor: pageParam,
        limit: 20,
      }),
    {
      staleTime: Infinity,
      refetchOnMount: 'always',
      getNextPageParam: (lastPage) =>
        lastPage.metadata?.pagination?.cursor?.next || undefined,
    },
  );
};

export const useFetchFlowsActivity = ({
  isEnabled,
}: {
  isEnabled: boolean;
}) => {
  return useQuery<GetFlowsActivityUpdatesResponse>(
    [GET_FLOWS_ACTIVITY],
    () => makeAPICallWithDataReturn(GET_FLOWS_ACTIVITY, undefined, undefined),
    {
      enabled: isEnabled,
    },
  );
};

export const useFetchFlowActive = (searchQuery?: string) => {
  return useQuery<GetFlowActiveResponse>(
    [GET_ACTIVE_FLOWS, searchQuery ? searchQuery : ''],
    () => {
      if (searchQuery) {
        return makeAPICallWithDataReturn(
          GET_ACTIVE_FLOWS,
          {
            search: {
              name: searchQuery,
            },
          },
          {
            limit: 99,
          },
        );
      }

      return makeAPICallWithDataReturn(GET_ACTIVE_FLOWS, undefined, {
        limit: 99,
      });
    },
  );
};

export const useMainNavQuery = ({
  searchQuery,
}: {
  searchQuery?: string;
} = {}) =>
  useQuery(
    [GET_MAIN_NAV_CONTENTS, searchQuery ? searchQuery : ''],
    () =>
      makeAPICall(
        GET_MAIN_NAV_CONTENTS,
        searchQuery ? { search: { name: searchQuery } } : undefined,
        { limit: 99 },
      ),
    {
      select: (response) => {
        const responseData = response?.data?.data;

        if (!Array.isArray(responseData)) {
          return { flattenedData: [], groupedData: [], folders: [] };
        }

        const groupedData = [] as MainNavQueryData['groupedData'];
        let flattenedData = [] as MainNavQueryData['flattenedData'];
        const folders: Folder[] = [];

        for (const entry in responseData) {
          const folder = responseData[entry].folder as Folder;

          const flows = responseData[entry].flows.map((f: FlowResponse) => ({
            ...f,
            folderInfo: folder,
          })) as FlowResponse[];

          groupedData.push({
            folder,
            flows,
          });
          flattenedData = [...flattenedData, ...flows];

          if (folder?.folderId) {
            folders.push(folder);
          }
        }

        return { groupedData, flattenedData, folders };
      },
    },
  );
