import React from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import Divider from '../../atoms/Divider';
import EmailNotificationSettingsRow from '../EmailNotificationSettingsRow';
import ThemeV2 from '../../../theme';
import { EMAIL_NOTIFICATION_SETTINGS } from '../../../languages/en/flows';
import {
  EmailNotificationAccordionWithToggleSubItemProps,
  EmailNotificationSettingFlowItemProps,
} from '../EmailNotificationSettingsRow/types';
import { EmailNotificationGlobalSettings } from '../../../interfaces/UserSettings';

const Wrapper = styled.div<{ isCollapsed: boolean }>`
  box-shadow: inset 0 0 8px 1px rgba(0, 0, 0, 0.15);
  height: ${({ isCollapsed }) => (isCollapsed ? 0 : 'auto')};
  visibility: ${({ isCollapsed }) => (isCollapsed ? 'collapse' : 'visible')};
  padding: ${({ isCollapsed }) => (isCollapsed ? 0 : '0 16px 20px 16px')};
`;

const HeadingWrapper = styled.div`
  padding-left: 8px;
  padding-top: 16px;
  margin-bottom: 16px;
`;

export interface EmailNotificationPreferencesProps {
  globalSettings?: EmailNotificationGlobalSettings;
  flows?: EmailNotificationSettingFlowItemProps[];
  activityUpdates?: EmailNotificationAccordionWithToggleSubItemProps[];
}

export interface EmailNotificationSettingsProps {
  isError: boolean;
  isLoading: boolean;
  isCollapsed: boolean;
  onToggle: (notificationId: string) => void;
  preferences?: EmailNotificationPreferencesProps;
  onFlowItemOptionsToggle: (optionId?: string) => void;
}

const EmailNotificationSettings = ({
  isError,
  isLoading,
  onToggle,
  preferences,
  isCollapsed,
  onFlowItemOptionsToggle,
}: EmailNotificationSettingsProps) => {
  return (
    <Wrapper isCollapsed={isCollapsed}>
      <HeadingWrapper>
        <Body variant="body2Bold">Notification Types</Body>
      </HeadingWrapper>

      <EmailNotificationSettingsRow
        id="notifyFlowRecognition"
        isError={isError}
        isLoading={isLoading}
        notificationType={EMAIL_NOTIFICATION_SETTINGS.flows.notificationType}
        description={EMAIL_NOTIFICATION_SETTINGS.flows.description}
        type="accordion"
        accordionSubItems={preferences?.flows}
        onToggle={onFlowItemOptionsToggle}
      />
      <Divider color={ThemeV2.palette.gray4} isFullWidth />

      <EmailNotificationSettingsRow
        id="task_activity"
        isError={isError}
        onToggle={onToggle}
        type="toggleSwitch"
        isLoading={isLoading}
        isOn={preferences?.globalSettings?.tasks}
        notificationType={EMAIL_NOTIFICATION_SETTINGS.tasks.notificationType}
        description={EMAIL_NOTIFICATION_SETTINGS.tasks.description}
      />
      <Divider color={ThemeV2.palette.gray4} isFullWidth />

      <EmailNotificationSettingsRow
        id="mentions"
        isError={isError}
        isLoading={isLoading}
        isOn={preferences?.globalSettings?.mentions}
        notificationType={EMAIL_NOTIFICATION_SETTINGS.mentions.notificationType}
        description={EMAIL_NOTIFICATION_SETTINGS.mentions.description}
        onToggle={onToggle}
        type="toggleSwitch"
      />
      <Divider color={ThemeV2.palette.gray4} isFullWidth />

      <EmailNotificationSettingsRow
        id="notifyComments"
        isError={isError}
        isLoading={isLoading}
        isOn={preferences?.globalSettings?.notifyComments}
        notificationType={EMAIL_NOTIFICATION_SETTINGS.comments.notificationType}
        description={EMAIL_NOTIFICATION_SETTINGS.comments.description}
        onToggle={onToggle}
        type="toggleSwitch"
      />
      <Divider color={ThemeV2.palette.gray4} isFullWidth />

      <EmailNotificationSettingsRow
        id="notifyAnniversary"
        isError={isError}
        isLoading={isLoading}
        isOn={preferences?.globalSettings?.notifyAnniversary}
        notificationType={
          EMAIL_NOTIFICATION_SETTINGS.anniversaryNBirthday.notificationType
        }
        description={
          EMAIL_NOTIFICATION_SETTINGS.anniversaryNBirthday.description
        }
        onToggle={onToggle}
        type="toggleSwitch"
      />
      <Divider color={ThemeV2.palette.gray4} isFullWidth />

      <EmailNotificationSettingsRow
        id="notifyActivityAccordion"
        isError={isError}
        isLoading={isLoading}
        notificationType={
          EMAIL_NOTIFICATION_SETTINGS.activityUpdate.notificationType
        }
        description={EMAIL_NOTIFICATION_SETTINGS.activityUpdate.description}
        type="accordionWithToggleSwitch"
        accordionSubItems={preferences?.activityUpdates}
      />
    </Wrapper>
  );
};

export default EmailNotificationSettings;
