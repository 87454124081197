import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  GET_ACTIVE_FLOWS,
  GET_ARCHIVED_FLOWS,
  GET_MAIN_NAV_CONTENTS,
  TRIGGER_ARCHIVE_FLOW,
} from '../../../../constants/endpoints';
import { makeAPICall } from '../../../utils';
import { ArchiveFlowPayload } from '../RunFlow/types';

export const useArchiveFlow = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: ArchiveFlowPayload) =>
      makeAPICall(TRIGGER_ARCHIVE_FLOW, undefined, undefined, {
        flowId: payload.flowId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
        queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
        queryClient.invalidateQueries([GET_ARCHIVED_FLOWS]);
      },
    },
  );
};
