import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import { IMemberDTO } from '../../../interfaces/member';
import { Flex } from '../../../Utils/styles/display';
import PeopleDirectoryProfilePicture from './PeopleDirectoryAvatar';
import InfiniteScroll from 'react-infinite-scroll-component';
import useLayoutStore from '../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';
import EmptyState from './EmptyState';
import SkeletonLoader from './SkeletonLoader';
import { device } from '../../../constants/layout';
import ThemeV2 from '../../../theme';
import { useCallback } from 'react';
import { MemberState } from '../../../interfaces/user';
import { trackPeopleDirectoryActionEvent } from '../../../Utils/analytics/peopleDirectory';
import { PEOPLE_DIRECTORY_EVENTS } from '../../../Utils/analytics/constants';
import { getPeopleDirectoryDropdownOptions } from '../utils';

export const StyledCard = styled.div<{ maxWidth?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-items: center;
  transition: all 200ms ease-in-out;
  border: 1px solid ${ThemeV2.palette.gray5};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : '278px')};
  min-width: 180px;
  width: 100%;

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    border-radius: 8px;
  }

  @media ${device.mobile} {
    max-width: 250px;
  }
`;

const Name = styled(Body)`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  word-break: break-all;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  padding: 16px;
  gap: 16px;
  @media ${device.mobile} {
    justify-content: center;
  }
  justify-items: center;
`;

const LoadedView = ({
  data,
  onItemClick,
}: {
  data: IMemberDTO[];
  onItemClick: (value: string | number, user: IMemberDTO) => void;
}) => {
  const { setSelectedMember, isRightAsideOpen, setRightAsideOpenToTrue } =
    useLayoutStore(
      (state) => ({
        setSelectedMember: state.setSelectedMember,
        isRightAsideOpen: state.isRightAsideOpen,
        setRightAsideOpenToTrue: state.setRightAsideOpenToTrue,
      }),
      shallow,
    );
  const handleCardClick = useCallback(
    (user: IMemberDTO) => {
      setSelectedMember({
        memberId: user?.memberID,
        firstName: user?.firstName,
      });
      if (!isRightAsideOpen) {
        setRightAsideOpenToTrue();
      }
      trackPeopleDirectoryActionEvent({
        action: PEOPLE_DIRECTORY_EVENTS.PEOPLE_PROFILE_CLICKED,
      });
    },
    [isRightAsideOpen, setRightAsideOpenToTrue, setSelectedMember],
  );

  return (
    <>
      {data.map((user) => (
        <StyledCard onClick={() => handleCardClick(user)} key={user.memberID}>
          <PeopleDirectoryProfilePicture
            img={user.profileThumbnails?.[200]}
            name={user.firstName}
            userId={user.memberID}
            isAnonymous={false}
            onItemClick={(value) => onItemClick(value, user)}
            dropdownItems={getPeopleDirectoryDropdownOptions(user.firstName)}
            memberState={user.memberState}
          />
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            padding="8px 12px"
          >
            <Name margin="0 8px" variant="body2Medium">
              {user.memberState === MemberState.PENDING
                ? user.email
                : `${user.firstName} ${user.lastName}`}
            </Name>
            <Name margin="0 8px" variant="body3">
              {user?.jobTitle || 'Team member'}
            </Name>
          </Flex>
        </StyledCard>
      ))}
    </>
  );
};

export const PeopleDirectoryView = ({
  data,
  hasMoreMembersToFetch,
  fetchMoreInfiniteMembers,
  isMembersLoading,
  membersSearchValue,
  onItemClick,
}: {
  data: IMemberDTO[];
  hasMoreMembersToFetch: boolean;
  fetchMoreInfiniteMembers: () => void;
  isMembersLoading: boolean;
  membersSearchValue: string;
  onItemClick: (value, user) => void;
}) => {
  if (!data.length && !isMembersLoading) {
    return <EmptyState />;
  }
  if (membersSearchValue && isMembersLoading) {
    return <SkeletonLoader />;
  }
  return (
    <>
      <StyledInfiniteScroll
        next={fetchMoreInfiniteMembers}
        dataLength={data.length}
        hasMore={Boolean(hasMoreMembersToFetch)}
        scrollableTarget="scrollableFeedContent"
        loader={isMembersLoading && <SkeletonLoader />}
      >
        <LoadedView data={data} onItemClick={onItemClick} />
      </StyledInfiniteScroll>
    </>
  );
};
