/* eslint-disable max-len */
import { ReactionType } from '../../../interfaces/Feed/Reaction';
import { PostTypes } from '../../../interfaces/Home';
import { MemberState } from '../../../interfaces/user';
import { VisibilityType } from '../../../queries/Flows/Feed/interfaces';
import { DummyFlowFeedPostData } from './types';

const dummyFunction = () => {};

const visibility: VisibilityType = 'everyone';

const demoMember = {
  name: 'The Assembly Team',
  firstName: 'The Assembly Team',
  lastName: '',
  username: 'theAssemblyTeam',
  memberID: '60f66e5d8ba62526767170e2',
  image: '',
  pointsGiven: 100,
  totalPointsGiven: 100,
  memberState: MemberState.ACTIVE,
};

const reactionBarProps = {
  reactions: [
    {
      members: [demoMember],
      reaction: {
        name: ':raised_hands:',
        type: ReactionType.REGULAR,
        displayName: 'raised_hands',
        value: '',
      },
      timesReacted: 1,
      active: false,
    },
  ],
  contentId: '61713cd8d2db1fff3fb1dacb',
  currentUserId: '6081484184d8987b291e40b3',
};

const commentsProps = {
  feedCommentProps: [],
  showMoreCommentsButton: false,
  onShowMoreCommentsButtonClick: dummyFunction,
};

export const celebrateTeammateHeaderProps = {
  flow: {
    flowId: 'recognition',
    name: 'Give Recognition',
    icon: { kind: 'HEX_CODE', value: '1F389' },
    kind: '',
    version: 0,
  },
  postType: PostTypes.RECOGNITION,
  hasTrophies: false,
  showDeleteOption: true,
  showEditOption: false,
  createdAt: '2021-10-21T10:11:36.965Z',
  visibility,
  person: demoMember,
  onCopyLink: dummyFunction,
  handleMemberClick: dummyFunction,
  handleOnFlowClick: dummyFunction,
};

export const celebrateTeamMateDummyPostData: DummyFlowFeedPostData = {
  reactionBarProps,
  commentsProps,
  bodyProps: {
    flow: undefined,
    blocks: [
      {
        id: '1',
        type: 'PERSON_SELECTOR',
        content: {
          title: 'Who would you like to celebrate?',
          persons: [
            {
              id: '6081484184d8987b291e40b3',
              label: 'Maybe this is the flow owner?',
              avatar: {
                img: '',
                userId: '6081484184d8987b291e40b3',
                name: 'Maybe',
              },
            },
          ],
        },
      },
      {
        id: '2',
        type: 'OPEN_ENDED',
        content: {
          gifUrl: 'https://media3.giphy.com/media/lMameLIF8voLu8HxWV/giphy.gif',
          title: 'What did your teammate(s) do?',
          message: [
            `Thanks for signing up for Assembly! We’re so excited that you took the initiative
            to empower your team to achieve more in their work. This flow allows everyone to give
            concrete, specific celebrations for jobs well done. This positive reinforcement
            is great for developing a strong company culture!`,
          ],
        },
      },
      {
        id: '3',
        type: 'DROPDOWN',
        content: {
          title: 'Choose a core value.',
          options: [],
          value: ['Initiative'],
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
};

export const simpleFlow = {
  bodyProps: {
    blocks: [
      {
        id: '60f136bf87b6c8e999e7d71e',
        type: 'DROPDOWN',
        content: {
          type: 'DROPDOWN',
          title: 'How are you feeling about this week, today?',
          options: [
            { id: 'op-1', value: 'Angry' },
            { id: 'op-2', value: 'Upset' },
            { id: 'op-3', value: 'Happy' },
          ],
          value: ['Happy'],
        },
      },
    ],
    onSeeFullPostClick: dummyFunction,
    onDownloadFileClick: dummyFunction,
  },
  reactionBarProps: {
    reactions: [],
    contentId: '6176aa72d5b928770db4089d',
    currentUserId: '6081484184d8987b291e40b3',
  },
  headerProps: {
    flow: {
      flowId: '60f136bf87b6c85721e7d71b',
      version: 1,
      name: 'Flow with shortcut',
      kind: 'ONDEMAND',
      icon: { kind: 'HEX_CODE', value: '1F60A' },
    },
    hasTrophies: false,
    showDeleteOption: true,
    showEditOption: false,
    postType: 'flow',
    createdAt: '2021-10-25T13:00:34.588Z',
    person: {
      name: 'Surender Lohia',
      firstName: 'Surender',
      lastName: 'Lohia',
      username: 'surenderlohia',
      memberID: '6081484184d8987b291e40b3',
      image: '',
      pointsGiven: 200,
      totalPointsGiven: 200,
      memberState: 'ACTIVE',
      role: ['Employee', 'Admin'],
      isDeleted: false,
    },
    onCopyLink: dummyFunction,
    handleMemberClick: dummyFunction,
    handleOnFlowClick: dummyFunction,
    currentUserId: '6081484184d8987b291e40b6',
    visibility: 'everyone',
  },
};
