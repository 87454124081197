import { create } from 'zustand';
import { device } from '../../constants/layout';
import {
  LayoutStoreState,
  SecondaryPanel,
  SelectedMember,
  SelectedPost,
} from '../../interfaces/Layout';

const useLayoutStore = create<LayoutStoreState>()((set) => ({
  selectedPost: {},
  showChats: false,
  rightAsideWidth: 0,
  isLeftAsideOpen: !window.matchMedia(device.mobile),
  isRightAsideOpen: !window.matchMedia(device.mobile),
  showRightAside: true,
  showReplies: false,
  activeNavItem: SecondaryPanel.ASSEMBLY,
  activeSecondaryPanel: SecondaryPanel.ASSEMBLY,
  isLeftNavHovered: false,
  taskIdForNotebook: '',
  isEmbeddedInMainApp: false,
  setRightAsideWidth: (width: number) =>
    set(() => ({ rightAsideWidth: width })),
  setShowChats: (show: boolean) =>
    set(() => ({
      showChats: show,
    })),
  enableEmbeddedModeInMainApp: () =>
    set(() => ({
      isEmbeddedInMainApp: true,
    })),
  setShowReplies: (show: boolean) =>
    set(() => ({
      showReplies: show,
      isRightAsideOpen: true,
    })),
  setSelectedPost: (selectedPost: SelectedPost) =>
    set(() => ({
      selectedPost,
    })),
  toggleLeftAsideOpen: () =>
    set(({ isLeftAsideOpen }) => {
      return {
        isLeftAsideOpen: !isLeftAsideOpen,
      };
    }),
  toggleRightAsideOpen: () =>
    set(({ isRightAsideOpen }) => ({ isRightAsideOpen: !isRightAsideOpen })),
  toggleShowRightAside: () =>
    set(({ showRightAside }) => ({ showRightAside: !showRightAside })),
  setLeftNavHoveredToTrue: () => set(() => ({ isLeftNavHovered: true })),
  setLeftNavHoveredToFalse: () => set(() => ({ isLeftNavHovered: false })),
  setLeftAsideOpenToTrue: () => set(() => ({ isLeftAsideOpen: true })),
  setLeftAsideOpenToFalse: () => set(() => ({ isLeftAsideOpen: false })),
  setRightAsideOpenToTrue: () => set(() => ({ isRightAsideOpen: true })),
  setRightAsideOpenToFalse: () => set(() => ({ isRightAsideOpen: false })),
  setShowRightAsideToTrue: () => set(() => ({ showRightAside: true })),
  setShowRightAsideToFalse: () => set(() => ({ showRightAside: false })),
  closeRightSideBarForMobile: () =>
    set(() => ({
      isRightAsideOpen: !window.matchMedia(device.mobile),
    })),
  setActiveNavItem: (navItem) =>
    set(() => ({
      activeNavItem: navItem,
    })),
  setActiveSecondaryPanel: (navItem) =>
    set(() => ({
      activeSecondaryPanel: navItem,
    })),
  selectedMember: undefined,
  removeSelectedMember: () => {
    set(() => ({ selectedMember: undefined }));
  },
  setSelectedMember: (selectedMember: SelectedMember) => {
    set(() => ({ selectedMember }));
  },
  setMemberAndToggleRightAsideOpen: (selectedMember: SelectedMember) => {
    set(() => ({ selectedMember, isRightAsideOpen: true }));
  },
  setTaskIdForNotebook: (taskId: string) =>
    set(() => ({
      showReplies: false,
      taskIdForNotebook: taskId,
    })),
}));

export default useLayoutStore;
