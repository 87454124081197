import isEqual from 'lodash/isEqual';
import { File } from '../../../../atomic/molecules/FileCard/types';
import {
  FileUploadFlowBlock,
  FlowFileForAPI,
} from '../../../../interfaces/Flow';
import {
  FeedResponse,
  FlowBlockResponsePerson,
  FlowFeedResponse,
} from '../../../../queries/Flows/Feed/interfaces';
import { FlowInstanceResponse } from '../../../../queries/Flows/interfaces';
import { replaceAll } from '../../../../Utils/text';
import { datadogLogs } from '@datadog/browser-logs';

const formatApiFiles = (files: File[]) => {
  return files.map((file: File) => {
    const { thumbnails, ...rest } = file;
    return rest;
  });
};

const formatInputFiles = (files: FlowFileForAPI[]) => {
  return files.map((file: FlowFileForAPI) => {
    const { id, thumbnails, thumbnailUrl, ...rest } = file;
    return rest;
  });
};

export const getTouchedBlockIds = (
  inputBlocks: Record<string, any>,
  apiBlocks: FeedResponse[] = [],
) => {
  const touchedBlockIds: any[] = [];

  for (let i = 0; i < inputBlocks.length; i++) {
    const inputBlock = inputBlocks[i];
    const apiBlock = apiBlocks[i];
    const inputBlockResponse = inputBlock.response;
    const apiBlockResponse = apiBlock.response;

    if (
      apiBlock.type === 'DROPDOWN' ||
      apiBlock.type === 'SCALE' ||
      apiBlock.type === 'MULTI_CHOICE' ||
      apiBlock.type === 'GIF'
    ) {
      if (!isEqual(inputBlockResponse?.value, apiBlockResponse?.value)) {
        touchedBlockIds.push(apiBlock.blockId);
      }
    }

    if (apiBlock.type === 'OPEN_ENDED') {
      // Todo: Temp fix by Suren:
      // To remove extra new line appended by editor while populate mentions data in edit mode
      // Because of this new line, it considered as edited field, so.
      // Once we fixed new line append issue, we can safely remove this.

      // TO DO: Sumedha - Remove logger once issue is analysed
      if (!inputBlockResponse) {
        datadogLogs.logger.error(
          'inputBlockResponse is undefined',
          {},
          new Error(JSON.stringify(inputBlocks)),
        );
      }
      const MENTION_REGEX_WITH_NEW_LINE = /\n?(@__.*?__@)\n?/;
      const formattedCurrentMessage = replaceAll(
        inputBlockResponse?.value || '',
        MENTION_REGEX_WITH_NEW_LINE,
        (_allMatch: string, mention: string) => {
          return ` ${mention} `;
        },
      ).replace(/\s+/g, ' ');
      const formattedResponseMessage = replaceAll(
        apiBlockResponse.value || '',
        MENTION_REGEX_WITH_NEW_LINE,
        (_allMatch: string, mention: string) => {
          return ` ${mention} `;
        },
      ).replace(/\s+/g, ' ');

      if (!isEqual(formattedCurrentMessage, formattedResponseMessage)) {
        touchedBlockIds.push(apiBlock.blockId);
      }

      if (inputBlockResponse.gifUrl || apiBlockResponse.gifUrl) {
        if (!isEqual(inputBlockResponse.gifUrl, apiBlockResponse.gifUrl)) {
          touchedBlockIds.push(apiBlock.blockId);
        }
      }

      const formattedResponseFiles = formatApiFiles(apiBlockResponse.files);
      const formattedInputFiles = formatInputFiles(inputBlockResponse.files);

      if (!isEqual(formattedInputFiles, formattedResponseFiles)) {
        touchedBlockIds.push(apiBlock.blockId);
      }
    }

    if (apiBlock.type === 'PERSON_SELECTOR') {
      const apiBlockPersons = apiBlockResponse?.persons?.map(
        (person: FlowBlockResponsePerson) => person.memberId,
      );

      if (!isEqual(inputBlockResponse.persons, apiBlockPersons)) {
        touchedBlockIds.push(apiBlock.blockId);
      }
    }

    if (apiBlock.type === 'FILE_UPLOAD') {
      const formattedResponseFiles = formatApiFiles(apiBlockResponse.files);
      const formattedInputFiles = formatInputFiles(inputBlockResponse.files);
      const content = apiBlock.content as FileUploadFlowBlock;

      if (content.rules?.required) {
        if (
          !isEqual(formattedInputFiles, formattedResponseFiles) &&
          inputBlockResponse.files.length > 0
        ) {
          touchedBlockIds.push(apiBlock.blockId);
        }
      } else {
        if (!isEqual(formattedInputFiles, formattedResponseFiles)) {
          touchedBlockIds.push(apiBlock.blockId);
        }
      }
    }
  }

  return touchedBlockIds;
};

export const mapPostResponseDetailsToEditablePostData = (
  postResponse: FlowFeedResponse,
) => {
  const {
    instanceId,
    responses,
    flow: { flowId },
  } = postResponse;
  const blocks = responses.map((res) => {
    const { blockId, type: blockType, content } = res;
    return {
      blockId,
      blockType,
      content,
    };
  });
  return {
    state: 'DRAFT',
    flowId,
    instanceId,
    blocks,
  } as FlowInstanceResponse;
};
