import React from 'react';
import { useHistory } from 'react-router-dom';

import ThemeV2 from '../../../theme';

import { RouteTabsProps, LinkTabProp, RouteTabButton } from './interfaces';
import { useStyles } from './styles';

import SVGIcon from '../../atoms/SVGIcon';
import {
  StyledMoreButton,
  StyledTab,
  StyledTabs,
} from '../../atoms/Tabs/styles';
import useLayoutStore from '../../../stores/layoutStore';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const LinkTab = (props: LinkTabProp) => {
  const history = useHistory();
  const { href } = props;

  return (
    <StyledTab
      onClick={(event) => {
        event.preventDefault();
        history.replace(href);
      }}
      {...props}
    />
  );
};

const MoreButton = () => {
  return (
    <StyledMoreButton>
      <SVGIcon icon="more" size="24px" color={ThemeV2.palette.gray8} />
    </StyledMoreButton>
  );
};

function a11yProps(id: string) {
  return {
    id: `<scrollable-auto-tab-$>id</scrollable-auto-tab-$>`,
    'aria-controls': `scrollable-auto-tabpanel-${id}`,
  };
}

const renderRouteTabButtons = (
  routeTabButtons: RouteTabButton[],
  urlBase: string,
) => {
  return routeTabButtons.map((button) => (
    <LinkTab
      label={button.label}
      href={`${urlBase}/${button.id}`}
      key={button.id}
      value={button.id}
      data-testid={button.id}
      data-qa-id={`tab-rewards-${button.id}`}
      {...a11yProps(button.id)}
    />
  ));
};

const RouteTabs = (props: RouteTabsProps) => {
  const { routeTabButtons, urlBase, handleTabChange, isFixed = false } = props;
  const pathname = window.location.pathname;
  const activeTab = pathname.substr(pathname.lastIndexOf('/') + 1);
  const classes = useStyles({ isFixed });
  const isMobileView = useMediaQuery({ query: device.mobile });
  const { isEmbeddedInMainApp } = useLayoutStore();
  const noPaddingLeftPathnames = ['/settings', '/rewards'];
  const routeTabMarginPathnames = ['/notebook'];
  if (isMobileView) {
    routeTabMarginPathnames.push('/rewards');
  }
  const paddingLeft =
    isEmbeddedInMainApp &&
    !noPaddingLeftPathnames.some((path) => pathname.includes(path))
      ? '84px'
      : '0px';
  const marginLeft =
    isEmbeddedInMainApp &&
    routeTabMarginPathnames.some((path) => pathname.includes(path))
      ? '-84px'
      : '0px';
  const marginRight =
    isEmbeddedInMainApp &&
    routeTabMarginPathnames.some((path) => pathname.includes(path))
      ? '84px'
      : '0px';

  return (
    <div className={classes.root}>
      <StyledTabs
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons={isFixed ? 'auto' : false}
        ScrollButtonComponent={MoreButton}
        $isTabPositionFixed={isFixed}
        onChange={(e, newTab) => {
          if (handleTabChange) {
            handleTabChange(e, newTab);
          }
        }}
        paddingLeft={paddingLeft}
        marginLeft={marginLeft}
        marginRight={marginRight}
      >
        {renderRouteTabButtons(routeTabButtons, urlBase)}
      </StyledTabs>
    </div>
  );
};

export default RouteTabs;
