import { useRef } from 'react';

import { useDynamicCategoryAndTimeFrameFetchQuery } from '../../../queries/Leaderboard';
import { calculateFromQueryParameter } from '../../../Utils/leaderboard';

const useLeaderboardControllerModel = (
  category: string,
  timeFrame: string,
  isLeaderboardQueryEnabled = true,
) => {
  const nowRef = useRef(Date.now());
  const from = calculateFromQueryParameter(timeFrame, nowRef.current);

  const { currentUserQuery, leaderboardQuery } =
    useDynamicCategoryAndTimeFrameFetchQuery(
      category,
      from,
      nowRef.current,
      isLeaderboardQueryEnabled,
    );

  return {
    metadata: {
      isError: currentUserQuery.isError || leaderboardQuery.isError,
      isLoading:
        currentUserQuery.isInitialLoading || leaderboardQuery.isInitialLoading,
    },
    data: {
      currentUser: currentUserQuery.data,
      leaderboard: leaderboardQuery.data,
    },
  };
};

export default useLeaderboardControllerModel;
