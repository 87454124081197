import type { RequireAtLeastOne } from 'type-fest';
import { ParticipationTemplateErrorTypes } from '../../atomic/pages/ParticipationTemplate/types';

export enum AppAction {
  FeedLoaded = 'feedLoaded',
  FeedErrored = 'feedErrored',
  NotificationClicked = 'notificationClicked',
  ApplicationBootstrapped = 'applicationBootstrapped',
  NotificationSettingsClicked = 'notificationSettingsClicked',
  FileDownload = 'fileDownload',
  ChatLoaded = 'chatLoaded',
  ParticipationCloseModalStatus = 'participationCloseModalStatus',
  CloseModalStatus = 'CloseModalStatus',
  AccountDeactivationSuccess = 'accountDeactivationSuccess',
  ParticipationError = 'participationError',
  DraftActionPerformed = 'draftActionPerformed',
  AnnouncementClicked = 'announcementClicked',
  CloseDrawer = 'closeDrawer',
  ParticipationStart = 'participationStart',
  ParticipationClose = 'participationClose',
}

export enum AppTargetEvent {
  Mobile = 'Mobile',
}

interface Payload {
  id: string;
  type: string;
  postId: string;
  flowId: string;
  linkURL: string;
  memberId: string;
  responseId: string;
  description: string;
  occurrenceId: string;
  errorType: ParticipationTemplateErrorTypes;
}

export interface EventMessage {
  origin: string;
  data: string;
}

export interface AppMessage {
  action: AppAction;
  targetEvent?: AppTargetEvent;
  payload: RequireAtLeastOne<Payload>;
}
