import { useMemo, useCallback, useState, useEffect } from 'react';
import get from 'lodash/get';

import {
  MyRewardsControllerProps,
  Tango,
} from '../../../queries/Rewards/interfaces';

import { RewardTypes } from '../../../atomic/molecules/RewardCard/interface';
import { useGetAxomoShippingDetails } from '../../../queries/Rewards';
import { useQueryClient } from '@tanstack/react-query';
import { GET_MY_REWARDS } from '../../../constants/endpoints';

const useMyRewardsControllerLogic = (
  props: MyRewardsControllerProps | undefined,
  resendEmailForMyRewards: (orderId: string) => void,
) => {
  const queryClient = useQueryClient();
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>();
  const {
    isSuccess,
    data: shippingDetails,
    isInitialLoading: isRedemptionDetailsLoading,
  } = useGetAxomoShippingDetails(
    selectedOrderId || '',
    Boolean(selectedOrderId),
  );
  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries([GET_MY_REWARDS]);
    }
  }, [isSuccess, queryClient]);
  const rewards = useMemo(() => {
    if (props) {
      const { orders } = props;
      return orders.map((reward: Tango) => {
        const {
          amount,
          orderType,
          pointsRedeemed,
          createdAt,
          brandName,
          imageUrls,
          tangoInfo,
          resendEmailCount,
          orderInfo,
          customRewardInfo,
          _id: ID,
          axomoInfo,
        }: Tango = reward;
        let rewardType;
        let image;
        let title;
        let description;
        let rewardInfo;
        let swagDetails;
        if (orderType === 'tango') {
          rewardType =
            get(tangoInfo, ['rewardType']) === 'donation'
              ? RewardTypes.Charity
              : RewardTypes.GiftCard;
          image = get(imageUrls, ['200w-326ppi']);
          title = brandName;
          description = brandName;
          rewardInfo = orderInfo;
        } else if (orderType === 'axomo') {
          rewardType = RewardTypes.Swags;
          if (orderInfo) {
            const swagInfo = get(JSON.parse(orderInfo), ['0']);
            const item = get(swagInfo, ['Cart', 'Items', '0']);
            const shipAddress = get(swagInfo, ['Cart', 'ShipAddress']);
            const firstName = get(swagInfo, ['Cart', 'ShipAddress', 'First']);
            const lastName = get(swagInfo, ['Cart', 'ShipAddress', 'Last']);
            rewardType = RewardTypes.Swags;
            image = get(item, ['MainPic']);
            title = get(item, ['Description']);
            description = get(item, ['Description']);
            swagDetails = {
              size: axomoInfo.size,
              quantity: String(axomoInfo.quantity),
              firstName: firstName,
              lastName: lastName,
              address: shipAddress.Address1,
              apartment: shipAddress.Address2,
              city: shipAddress.City,
              state: shipAddress.State,
              pincode: shipAddress.Zip,
              orderStatus: axomoInfo.orderStatus,
            };
          }
        } else if (orderInfo) {
          rewardType = RewardTypes.Culture;
          image = get(JSON.parse(orderInfo), [
            'image',
            'original',
            'relativeUrl',
          ]);
          title = get(customRewardInfo, ['name']);
          description = get(customRewardInfo, ['name']);
        }
        return {
          currencyAmount: amount,
          rewardType,
          image,
          title,
          redeemedPoints: pointsRedeemed,
          description,
          redeemedOn: createdAt,
          resendEmailCount: !!resendEmailCount,
          orderId: ID,
          orderInfo: rewardInfo,
          swagDetails,
        };
      });
    }
    return null;
  }, [props]);

  const onButtonClick = useCallback(
    (orderId: string, rewardType: RewardTypes) => {
      if (rewardType === RewardTypes.Swags) {
        setSelectedOrderId(orderId);
      } else {
        resendEmailForMyRewards(orderId);
      }
    },
    [resendEmailForMyRewards],
  );

  return {
    data: {
      rewards,
      isRedemptionDetailsLoading,
      trackingLink: shippingDetails?.trackingLink,
      shippingStatus: shippingDetails?.status,
    },
    operations: {
      onButtonClick,
    },
  };
};

export default useMyRewardsControllerLogic;
