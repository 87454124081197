import { COPY_LINK, DELETE_COMMENT } from '../../../languages/en/home';

import { MenuItemProps } from '../../molecules/Dropdown_V2/interfaces';

export const dropdownOptions: MenuItemProps[] = [
  {
    id: 'comment-header-options',
    items: [
      {
        value: COPY_LINK,
        id: 'copy-link',
        prefixIcon: 'Link',
        isSelected: false,
      },
      {
        value: DELETE_COMMENT,
        id: 'delete',
        prefixIcon: 'delete',
        deleteItem: true,
        isSelected: false,
      },
    ],
  },
];

export const dropdownOptionsWithEdit: MenuItemProps[] = [
  {
    id: 'comment-header-options',
    items: [
      {
        value: 'Copy reply link',
        id: 'copy-link',
        prefixIcon: 'Link',
        isSelected: false,
      },
      {
        value: 'Edit reply',
        id: 'edit-reply',
        prefixIcon: 'edit',
        isSelected: false,
      },
      {
        value: 'Delete reply',
        id: 'delete',
        prefixIcon: 'delete',
        deleteItem: true,
        isSelected: false,
      },
    ],
  },
];

export const dropdownOptionsForChat: MenuItemProps[] = [
  {
    id: 'chat-header-options',
    items: [
      {
        value: 'Copy chat link',
        id: 'copy-link',
        prefixIcon: 'Link',
        isSelected: false,
      },
      {
        value: 'Delete message',
        id: 'delete',
        prefixIcon: 'delete',
        deleteItem: true,
        isSelected: false,
      },
    ],
  },
];
