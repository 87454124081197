import React from 'react';
import styled from 'styled-components';
import { device } from '../../../constants/layout';
import ThemeV2 from '../../../theme';

import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';

const StyledBody = styled(Body)`
  margin-left: 8px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: 20px;
`;

const CloseInfoPanel = styled.span`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  @media ${device.tabletOrDesktop} {
    display: none;
  }
`;

export interface DrawerHeaderWithIconProps {
  children: React.ReactNode;
  icon: string;
  className?: string;
  onClick?: () => void;
}

const DrawerHeaderWithIcon = ({
  children,
  icon,
  onClick,
  className,
}: DrawerHeaderWithIconProps) => {
  return (
    <Wrapper data-testid="drawer-header" className={className}>
      <Flex alignItems="center">
        <SVGIcon icon={icon} size="32px" />
        <StyledBody>{children}</StyledBody>
      </Flex>
      {onClick && (
        <CloseInfoPanel onClick={onClick}>
          <SVGIcon icon="round-close" color={ThemeV2.palette.gray6} />
        </CloseInfoPanel>
      )}
    </Wrapper>
  );
};

const MemoizedDrawerHeaderWithIcon = React.memo(DrawerHeaderWithIcon);
MemoizedDrawerHeaderWithIcon.displayName = 'DrawerHeaderWithIcon';
export default MemoizedDrawerHeaderWithIcon;
