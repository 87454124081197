import React, { useMemo, useCallback } from 'react';
import { DateUtils } from 'react-day-picker';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import IconButton from '../../atoms/IconButton';
import SVGIcon from '../../atoms/SVGIcon';
import Dropdown from '../../atoms/Dropdown';
import ThemeV2 from '../../../theme';
import { Item } from '../../atoms/Dropdown/interfaces';

const useStyles = makeStyles({
  navButtons: {
    padding: '8px',
    height: 'fit-content',
    alignSelf: 'center',
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 5px',
  },
  dropdown: {
    '& .MuiSelect-select .placeholder': {
      color: ThemeV2.palette.gray9,
      fontWeight: 'bold',
      opacity: 1,
    },
    '& .caret': {
      top: '12px',
      '& svg': {
        width: '16px',
        height: '16px',
      },
      '& path': {
        fill: ThemeV2.palette.gray7,
      },
    },
  },
});

type CalendarNavComponentPropTypes = {
  changeDates: (targetDate: Date, isRightCalendar: boolean) => void;
  isRangePicker?: boolean;
  isRightCalendar?: boolean;
  month: Date;
  yearDropdownValues: Item[];
  stopEventPropagation?: boolean;
};

export const getYearDropdownValues = (
  numberOfYearsAllowedFromCurrentYear: number,
) => {
  const year = new Date().getFullYear();
  const arr = [];
  for (
    let i = year - numberOfYearsAllowedFromCurrentYear;
    i <= year + numberOfYearsAllowedFromCurrentYear;
    i += 1
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    arr.push({ id: i, value: i.toString() });
  }
  return arr;
};

const CalendarNavElement = ({
  isRightCalendar = false,
  isRangePicker = false,
  month,
  changeDates,
  yearDropdownValues,
  stopEventPropagation = false,
}: CalendarNavComponentPropTypes) => {
  const formattedMonthNames = useMemo(
    () =>
      moment.monthsShort().map((name, index) => ({
        id: index,
        value: name,
        disabled:
          isRangePicker && isRightCalendar
            ? month.getFullYear() === yearDropdownValues[0]?.id && index === 0
            : month.getFullYear() ===
                yearDropdownValues[yearDropdownValues.length - 1]?.id &&
              index === 11,
      })),
    [isRangePicker, isRightCalendar, month, yearDropdownValues],
  );
  const classes = useStyles();
  const onMonthDropdownChange = useCallback(
    (id) => {
      const newTargetDate = new Date(month);
      newTargetDate.setMonth(id);
      changeDates(newTargetDate, isRightCalendar);
    },
    [changeDates, isRightCalendar, month],
  );

  const onYearDropdownChange = useCallback(
    (id) => {
      const newTargetDate = new Date(month);
      newTargetDate.setFullYear(id);
      changeDates(newTargetDate, isRightCalendar);
    },
    [changeDates, isRightCalendar, month],
  );
  const handlePreviousMonthClick = useCallback(
    () => changeDates(DateUtils.addMonths(month, -1), isRightCalendar),
    [changeDates, isRightCalendar, month],
  );
  const handleNextMonthClick = useCallback(
    () => changeDates(DateUtils.addMonths(month, 1), isRightCalendar),
    [changeDates, isRightCalendar, month],
  );
  const disableLeftNavButton = useMemo(
    () =>
      month.getFullYear() === yearDropdownValues[0]?.id &&
      month.getMonth() === (isRangePicker && isRightCalendar ? 1 : 0),
    [isRangePicker, isRightCalendar, month, yearDropdownValues],
  );
  const disableRightNavButton = useMemo(
    () =>
      month.getFullYear() ===
        yearDropdownValues[yearDropdownValues.length - 1]?.id &&
      month.getMonth() === (isRangePicker && !isRightCalendar ? 10 : 11),
    [isRangePicker, isRightCalendar, month, yearDropdownValues],
  );
  return (
    <div
      onClick={(e) => {
        if (stopEventPropagation) e?.stopPropagation();
      }}
      className={classes.navContainer}
    >
      <IconButton
        onClick={handlePreviousMonthClick}
        disabled={disableLeftNavButton}
        className={classes.navButtons}
        data-testid="previous-month-nav-button"
        data-qa-id="btn-previous-month"
      >
        <SVGIcon
          icon="caret"
          size="8px"
          rotate={90}
          color={ThemeV2.palette.gray7}
        />
      </IconButton>
      <div>
        <Dropdown
          data-testid="month-dropdown"
          data-qa-id="ddown-month"
          selectedMenuItemId={month.getMonth()}
          menuItems={formattedMonthNames}
          onChange={onMonthDropdownChange}
          width="80px"
          removeBorder
          className={classes.dropdown}
        />
        <Dropdown
          data-testid="year-dropdown"
          data-qa-id="ddown-year"
          selectedMenuItemId={month.getFullYear()}
          menuItems={yearDropdownValues}
          onChange={onYearDropdownChange}
          width="80px"
          removeBorder
          className={classes.dropdown}
        />
      </div>

      <IconButton
        onClick={handleNextMonthClick}
        disabled={disableRightNavButton}
        className={classes.navButtons}
        data-testid="next-month-nav-button"
        data-qa-id="btn-next-month"
      >
        <SVGIcon
          icon="caret"
          size="8px"
          rotate={-90}
          color={ThemeV2.palette.gray7}
        />
      </IconButton>
    </div>
  );
};

export default CalendarNavElement;
