import { AnonymityStates } from '../../queries/Flows/interfaces';

export const GIVE_RECOGNITION_FLOW = {
  flowId: 'recognition',
  name: 'Give Recognition',
  description: "Welcome to recognition's new home",
  isShortcut: true,
  kind: 'ONDEMAND',
  state: 'ACTIVE',
  createdAt: new Date(),
  updatedAt: new Date(),
  icon: {
    kind: 'HEX_CODE',
    value: '1F389',
  },
  isFlowOwner: false,
  isFlowParticipant: true,
  isFlowViewer: true,
  occurrence: {},
  participantsCount: 0,
  visibility: {
    criteria: 'everyone',
    viewersCount: 0,
  },
  owner: {},
  color: '',
  creationSource: 'PRELOAD',
  responseSettings: {
    anonymity: {
      state: AnonymityStates.DISABLED,
    },
  },
};
