import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../../theme';

const LoadingWrapper = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
  width: 100%;
  height: 100vh;
  max-width: 1366px;
`;

const PrimaryLoadingWrapper = styled.div`
  padding: 12px 16px;
  border-right: 1px solid ${ThemeV2.palette.gray4};
`;

const SecondaryLoadingWrapper = styled.div`
  padding: 12px 16px;
`;

const Loader = () => {
  return (
    <>
      <ContentLoader
        width="100%"
        height="40px"
        backgroundColor={ThemeV2.palette.gray5}
      >
        <rect rx="4" ry="4" width="95%" height="40px" />
      </ContentLoader>
      <ContentLoader width="100%" backgroundColor={ThemeV2.palette.gray5}>
        <rect rx="4" ry="4" width="30%" height="16px" />
      </ContentLoader>
    </>
  );
};

const TemplateGalleryLoader = ({ isMobileApp }: { isMobileApp: boolean }) => {
  return (
    <LoadingWrapper>
      {!isMobileApp && (
        <PrimaryLoadingWrapper>
          <Loader />
        </PrimaryLoadingWrapper>
      )}
      <SecondaryLoadingWrapper>
        <Loader />
      </SecondaryLoadingWrapper>
    </LoadingWrapper>
  );
};

export default TemplateGalleryLoader;
