import { useEffect } from 'react';
import { PLAN_IDS } from '../config';
import { MemberRole } from '../interfaces/user';
import {
  useProfileInfoFetchQuery,
  useCurrentPlanFetchQuery,
  useGetIdentityManagerQuery,
} from '../queries/Profile';
import { authStore } from '../stores/authStore';
import { getCurrentHRISConnectionName } from '../Utils/identity/hris';
import * as Analytics from '../Utils/analytics';
import { checkIfProduction } from '../Utils/admin';

export const useMixPanelInitialization = () => {
  const authenticated = authStore.getState().authenticated;

  const { data } = useProfileInfoFetchQuery(authenticated);
  const { data: planData } = useCurrentPlanFetchQuery(authenticated);
  const { data: identityManagerData } = useGetIdentityManagerQuery(
    authenticated && !!data?.member.role.includes(MemberRole.Admin),
  );

  useEffect(() => {
    if (data && !checkIfProduction()) {
      const whiteList = data?.assembly?.whitelistedDomains || [];
      Analytics.registerPropsFromUser(data.member);
      Analytics.registerSuperProps({
        whitelistEnabled: whiteList?.length > 0,
        whitelistedDomains: whiteList,
        assemblyID: data.assembly.assemblyId,
      });
    }
  }, [data]);

  useEffect(() => {
    const currentHRIS = identityManagerData
      ? getCurrentHRISConnectionName(identityManagerData)
      : undefined;

    if (currentHRIS && !checkIfProduction())
      Analytics.registerSuperProps({
        hris: currentHRIS?.connectionName,
      });
  }, [identityManagerData]);

  useEffect(() => {
    if (planData && !checkIfProduction()) {
      Analytics.registerUserProps({
        planType: planData.uniqueId,
        isPaid: planData.uniqueId !== PLAN_IDS.tier1,
      });
    }
  }, [planData]);

  return {};
};
