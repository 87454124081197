import styled from 'styled-components';
import ResponseBlock from '../ResponseBlock';

import Body from '../../atoms/Body';
import FileCard from '../FileCard';

import { Collapse } from '@mui/material';
import { useState } from 'react';
import { FileUploadStatus } from '../FileCard/types';
import { segregateFiles } from '../FileCard/utils';
import { FlowAccordionItemState as AccordionItemState } from '../FlowAccordionItem/interface';
import {
  AccordionItemBody,
  AccordionItemHeader,
  StyledArrowIcon,
} from '../FlowAccordionItem/styles';
import GifContainer from '../GifContainer';
import { ImageFiles } from '../ImageFiles';
import { StyledFlex } from '../PageHeader/styles';
import { OpenEndedFlowsResponseBlockType } from './types';

export const StyledGifContainer = styled(GifContainer)`
  margin-top: 8px;
`;

const StyledFeedPostMessage = styled(Body)`
  display: inline-block;
  overflow-wrap: anywhere;
`;

export default function OpenEndedFlowsResponseBlock(
  props: OpenEndedFlowsResponseBlockType,
) {
  const {
    title,
    message,
    gifUrl,
    files,
    onDownloadFileClick,
    onClickPreview,
    blockId,
    flowId,
    responseId,
    isEdited,
  } = props;

  const { imageFiles, otherFiles } = segregateFiles(files);

  const [isAttachmentsSectionExpanded, setIsAttachmentsSectionExpanded] =
    useState(true);

  const noOfFiles = files?.length || 0;

  const attachmentAccordionText = `${noOfFiles} ${
    noOfFiles === 1 ? 'attachment' : 'attachments'
  }`;

  return (
    <ResponseBlock label={title}>
      <StyledFeedPostMessage variant="body1">
        {message}{' '}
        {isEdited && (
          <Body
            inline
            variant="body3"
            color="gray8"
            data-testid="open-ended-block-edited"
            data-qa-id="open-ended-block-edited"
          >
            (edited)
          </Body>
        )}
      </StyledFeedPostMessage>
      <br />

      {noOfFiles > 0 && (
        <>
          <AccordionItemHeader
            compactMode
            type="button"
            hideBorder={true}
            isOpen={isAttachmentsSectionExpanded}
            state={AccordionItemState.Focus}
            onClick={() =>
              setIsAttachmentsSectionExpanded(!isAttachmentsSectionExpanded)
            }
          >
            <StyledFlex compactMode>
              <Body variant="body3" color="gray8">
                {isAttachmentsSectionExpanded
                  ? `Hide ${attachmentAccordionText}`
                  : `Show ${attachmentAccordionText}`}
              </Body>
              <StyledArrowIcon
                size="18px"
                icon="caret-rounded"
                rotate={isAttachmentsSectionExpanded ? 0 : 180}
                state={AccordionItemState.Focus}
                data-testid="accordionItemCaratIcon"
                data-qa-id="accordionItemCaratIcon"
              />
            </StyledFlex>
          </AccordionItemHeader>
          <AccordionItemBody>
            <Collapse in={isAttachmentsSectionExpanded}>
              {imageFiles.length > 0 && (
                <ImageFiles
                  files={imageFiles}
                  onClickPreview={onClickPreview}
                />
              )}
              {otherFiles?.map((file) => (
                <FileCard
                  flowId={flowId}
                  responseId={responseId}
                  isNewThumbnailExperience
                  file={file}
                  key={file.location}
                  isParticipation={false}
                  status={FileUploadStatus.Success}
                  onClickDownload={() =>
                    onDownloadFileClick?.({
                      id: blockId,
                      fileName: file.name,
                      responseId: responseId,
                    })
                  }
                  onClickPreview={onClickPreview}
                />
              ))}
            </Collapse>
          </AccordionItemBody>
        </>
      )}

      {gifUrl && <StyledGifContainer src={gifUrl} lazyload />}
    </ResponseBlock>
  );
}
