import { create } from 'zustand';

export type ParticipationFlowDetails = {
  participationFlowId: string;
  occurrenceId?: string;
  canHandleAnswerWithShortcut?: boolean;
};

export type ModalsStoreState = {
  participationFlow?: ParticipationFlowDetails;
  folderId?: string;
  setParticipationFlow: (flow: ParticipationFlowDetails) => void;
  removeParticipationFlow: () => void;
  isCreateFlowModalOpen: boolean;
  openCreateFlowModal: (folderId: string | undefined) => void;
  closeCreateFlowModal: () => void;
};

const useModalsStore = create<ModalsStoreState>()((set) => ({
  participationFlow: undefined,
  folderId: undefined,
  setParticipationFlow: (flow: ParticipationFlowDetails) => {
    set(() => ({ participationFlow: flow }));
  },
  removeParticipationFlow: () => {
    set(() => ({ participationFlow: undefined }));
  },
  isCreateFlowModalOpen: false,
  openCreateFlowModal: (folderId: string | undefined) => {
    set(() => ({ isCreateFlowModalOpen: true, folderId }));
  },
  closeCreateFlowModal: () =>
    set(() => ({ isCreateFlowModalOpen: false, folderId: undefined })),
}));

export default useModalsStore;
