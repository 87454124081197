import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

import NotebookRightDrawerController from '../notebook/NotebookRightDrawerController';
import useNotebookStore from '../../stores/notebookStore';
import { NotebookStore } from '../../stores/notebookStore/types';
import { useNotebookTransactionsController } from '../../hooks/useNotebookTransactionsController';
import { NotebookViews } from '../../interfaces/notebook';

const notebookPageSelector = (state: NotebookStore) => ({
  toggleCurrentView: state.toggleCurrentView,
});

export const NotebookTaskDetailsController = () => {
  useNotebookTransactionsController();

  const { toggleCurrentView } = useNotebookStore(notebookPageSelector, shallow);

  useEffect(() => {
    toggleCurrentView(NotebookViews.Feed);
  }, [toggleCurrentView]);

  return <NotebookRightDrawerController />;
};
