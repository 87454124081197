import { useCallback, useEffect, useMemo, useState } from 'react';
import { AvatarProps } from '../../atomic/atoms/Avatar/interfaces';
import { SettingsDropdownProps } from '../../atomic/molecules/SettingsDropdown/interface';
import { SettingsStringFieldProps } from '../../atomic/molecules/SettingsEditStringField';
import { SettingsAvatarProps } from '../../atomic/organism/SettingsAvatar/interface';
import {
  SocialProfile,
  SocialProfileNameEnum,
  UserInfo,
} from '../../interfaces/Settings/index';
import { userNameValidationSchema } from './../../atomic/molecules/SettingsEditStringField/validationSchema';
import { JOB_TITLE, LOCATION } from './../../languages/en/home/profileDrawer';

import { menuItems } from '../../atomic/molecules/Dropdown_V2/data';
import {
  MultiSelectDropdownType,
  MultiSelectDropdownValues,
  SettingsMultiSelectDropdownProps,
} from '../../atomic/molecules/SettingsMultiSelectDropdown/interface';
import { SelectedItemProps } from '../../atomic/molecules/TextField_V2/interface';
import {
  SettingsEmailProps,
  SettingsHandleProps,
} from '../../atomic/organism/GeneralSettingsAccordion';

import {
  DeactivatedMemberProps,
  DeactivatedUserInfo,
  UpdateUserInfoPayload,
  useGetUserLocation,
} from '../../queries/Settings';
import { UpdatedEmployeeDepartments } from '../../Utils/CompanyDetailsDatastore';

import { OptionsProps } from '../../atomic/molecules/DropdownWithMultiSelect';
import {
  SettingsAutocompleteProps,
  SettingsAutocompleteValues,
} from '../../atomic/molecules/SettingsAutocomplete';
import { useAccordionToggleLogic } from '../../atomic/organism/GeneralSettingsAccordion/useAccordionToggleLogic';
import { useMembersSearch } from '../../hooks/useMembersSearch';
import { IMemberDTO } from '../../interfaces/member';
import {
  BIRTHDAY_SETTINGS_PROFILE_SETTINGS_SUB_TITLE,
  BIRTHDAY_SETTINGS_PROFILE_SETTINGS_TITLE,
  LABEL,
} from '../../languages/en/settings';
import {
  ABOUT_ME,
  AVATAR,
  BIRTHDAY,
  DEPARTMENT,
  START,
  DATE,
  DIRECT,
  HOME_LOCATION,
  MANAGER,
  NAME,
  PRONOUNS,
  REPORTS,
  SOCIAL_PROFILES,
  TIME_ZONE,
  WORK_LOCATION,
} from '../../languages/en/singleWords';
import { autoCompleteEmpty } from '../../Utils/data/feedSearch/dropdown';
import { getIds } from '../../Utils/text';

import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../atomic/molecules/Dropdown_V2/interfaces';
import { GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL } from '../../constants/endpoints';
import useUploadProfilePicUppyInstance from '../../hooks/useUploadProfilePicUppyInstance';
import { useFetchMembersDetailsQuery } from '../../queries/MembersDetails';
import { serializeMembersDetailsFromApi } from '../../queries/MembersDetails/serializer';
import timezones from '../../Utils/data/timezones/index.json';
import {
  getHireYearOptions,
  getMonthByNumber,
  getMonthNumbers,
  mapTimezoneValue,
  monthNames,
} from '../../Utils/date';
import { dataUrlToFile } from '../../Utils/file';
import { showErrorMessage } from '../../Utils/toast';
import {
  GENDER_PRONOUNS_CUSTOM_OPTION_VALUE,
  GENDER_PRONOUNS_DROPDOWN_ITEMS,
  GENDER_PRONOUNS_OPTIONS,
  GENDER_PRONOUNS_VALIDATION_SCHEMA,
  formatPronouns,
} from '../../Utils/pronouns';
import { SettingsDropdownWithCustomFieldInitialValues } from '../../atomic/molecules/SettingsDropdownWithCustomField/interfaces';
import useMobileApp from '../../hooks/useMobileApp';
import { ResponseError } from '../../interfaces/ResponseError';
import postMessageToMobileApp from '../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../interfaces/PostMessage';
import { getSocialProfilesPlaceholder } from './utils';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import { isUserAdmin } from '../../Utils/user';
import { useProfileInfoFetchQuery } from '../../queries/Profile';

const createPayloadForUpdateSettings = (user: UserInfo, dataToUpdate: any) => {
  const { department, firstName, lastName, managers, reports } = user;
  return {
    department,
    firstName,
    lastName,
    managers,
    reports,
    ...dataToUpdate,
  };
};

const useSettingsControllerLogic = (
  updateUserSettings: (payload: UpdateUserInfoPayload) => void,
  updateProfilePictureSettings: (payload: UpdateUserInfoPayload) => void,
  currentUserInfo: UserInfo | null,
  deactivatedMemberProps?: DeactivatedMemberProps,
) => {
  const { treatment: locationCriteria } = useFeatureSplit(
    SplitNames.LocationCriteria,
  );

  const { data: profileData } = useProfileInfoFetchQuery();

  const isCurrentUserAdmin = useMemo(() => {
    if (profileData) {
      return isUserAdmin(profileData?.member);
    }
    return false;
  }, [profileData]);

  const isLocationCriteriaEnabled = locationCriteria === TreatmentTypes.ON;

  const {
    models: { searchedMembers, isLoading: isMembersSearchLoading },
    operations: { onChange: onUsersSearchChange },
  } = useMembersSearch();
  const {
    models: { accordionItems },
    operations: { handleCollapse, handleSaveClick },
  } = useAccordionToggleLogic();

  const { data } = useGetUserLocation([
    'JOB_TITLE',
    'HOME_LOCATION',
    'WORK_LOCATION',
  ]);

  const locationOptions = useMemo(() => {
    if (data) {
      const locations = data.data
        .filter((location) => location.key === 'HOME_LOCATION')[0]
        .values.map((val, index) => {
          return {
            value: val,
            id: index,
          };
        });
      return {
        id: 'home-locations',
        items: locations || [],
      };
    }
    return {
      id: 'home-locations',
      items: [],
    };
  }, [data]);

  const workLocationOptions = useMemo(() => {
    if (data) {
      const locations = data.data
        .filter((location) => location.key === 'WORK_LOCATION')[0]
        .values.map((val, index) => {
          return {
            value: val,
            id: index,
          };
        });
      return {
        id: 'work-locations',
        items: locations || [],
      };
    }
    return {
      id: 'work-locations',
      items: [],
    };
  }, [data]);

  const jobTitleOptions = useMemo(() => {
    if (data) {
      const jobTitles = data.data
        .filter((location) => location.key === 'JOB_TITLE')[0]
        .values.map((val, index) => {
          return {
            value: val,
            id: index,
          };
        });
      return {
        id: 'job-title',
        items: jobTitles || [],
      };
    }
    return {
      id: 'job-title',
      items: [],
    };
  }, [data]);

  const [isAutoCompletePristine, setIsAutoCompletePristine] =
    useState<boolean>(true);

  const [profilePictureUploadError, setProfilePictureUploadError] =
    useState('');

  const [isProfilePictureUploading, setProfilePictureUploading] =
    useState(false);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

  const [avatarImage, setAvatarImage] = useState<string | undefined>();

  const [isAvatarSet, setAvatarSet] = useState(false);

  const [isHideBirthdayOnProfileTurnedOn, setHideBirthdayOnProfile] = useState(
    (currentUserInfo?.birthday &&
      !currentUserInfo?.birthday?.isVisibleAndCelebrated) ||
      false,
  );

  useEffect(() => {
    if (profilePictureUploadError !== '') {
      showErrorMessage(profilePictureUploadError);
      setProfilePictureUploadError('');
    }
  }, [currentUserInfo, profilePictureUploadError]);

  useEffect(() => {
    if (currentUserInfo?.image !== undefined && !isAvatarSet) {
      setAvatarImage(currentUserInfo.image);
      setAvatarSet(true);
    }
  }, [currentUserInfo, isAvatarSet]);

  const { createUppyInstance } = useUploadProfilePicUppyInstance(
    GET_PROFILE_PICTURE_UPLOAD_SIGNED_URL,
  );

  const settingsAvatarProps: SettingsAvatarProps = useMemo(() => {
    const removeImageHandler = () => {
      setButtonDisabled(false);
      setAvatarImage('');
    };
    const handleImageSelectedClick = async (src: string, fileName: string) => {
      const file = await dataUrlToFile(src, fileName);

      const fileExtension = fileName
        .substring(fileName.lastIndexOf('.') + 1)
        .toLowerCase();
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];

      if (allowedExtensions.indexOf(fileExtension) < 0) {
        setAvatarImage('');
        setButtonDisabled(true);
      } else {
        setAvatarImage(src);
        setButtonDisabled(false);
      }

      const uppy = createUppyInstance();

      try {
        uppy.addFile({
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (err: any) {
        if (err?.isRestriction) {
          setProfilePictureUploadError(err?.message);
        } else {
          console.error(err);
        }
      }

      uppy.on('upload', () => {
        setProfilePictureUploading(true);
      });

      uppy.on('upload-success', () => {
        setProfilePictureUploading(false);
        setAvatarImage(src);
      });
    };
    const handleSaveChangesClick = async (fileName: string) => {
      const updatedData = {
        file: avatarImage,
        fileType: 'profile',
        fileName,
      };

      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateProfilePictureSettings(payload);
        handleSaveClick(AVATAR);
        setButtonDisabled(true);
      }
    };
    return {
      avatarImage: avatarImage,
      onSaveChanges: handleSaveChangesClick,
      onImageSelected: handleImageSelectedClick,
      isLoading: isProfilePictureUploading,
      isDisabled: buttonDisabled,
      onRemoveImageClick: removeImageHandler,
    };
  }, [
    avatarImage,
    isProfilePictureUploading,
    buttonDisabled,
    createUppyInstance,
    currentUserInfo,
    updateProfilePictureSettings,
    handleSaveClick,
  ]);

  const settingsEditNameProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'userName',
      label: 'Change name',
      validationSchema: userNameValidationSchema,
      initialValues: {
        userName: currentUserInfo?.fullName || '',
      },
      onSaveChanges: ({ userName }) => {
        const updatedFullName = userName?.split(' ') || '';
        const updatedData = {
          firstName: updatedFullName[0],
          lastName: updatedFullName[1],
        };
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(NAME);
        }
      },
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsPronounsProps = useMemo(() => {
    const onSaveChanges = (
      values: SettingsDropdownWithCustomFieldInitialValues,
    ) => {
      if (currentUserInfo) {
        const { custom, dropdownOption } = values;
        const pronouns =
          dropdownOption === GENDER_PRONOUNS_CUSTOM_OPTION_VALUE
            ? custom
            : dropdownOption;
        const updatedData = {
          pronouns,
        };
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );

        updateUserSettings(payload);
        handleSaveClick(PRONOUNS);
      }
    };
    const rawPronouns = currentUserInfo?.pronouns || '';
    const formattedPronouns = formatPronouns(rawPronouns);

    const isCustom =
      !GENDER_PRONOUNS_OPTIONS.includes(formattedPronouns) &&
      rawPronouns.trim() !== '';
    const initialCustomTextFieldValue = isCustom ? rawPronouns : '';
    const initialDropdownValue = isCustom
      ? GENDER_PRONOUNS_CUSTOM_OPTION_VALUE
      : formattedPronouns;

    return {
      accordianPlaceholderValue: formattedPronouns,
      customDropdownValue: GENDER_PRONOUNS_CUSTOM_OPTION_VALUE,
      customTextFieldLabel: LABEL.EDIT_CUSTOM_PRONOUNS,
      dropdownItems: GENDER_PRONOUNS_DROPDOWN_ITEMS,
      dropdownLabel: LABEL.EDIT_PRONOUNS,
      idPrefix: 'pronouns',
      initialValues: {
        custom: initialCustomTextFieldValue,
        dropdownOption: initialDropdownValue,
      },
      onSaveChanges,
      validationSchema: GENDER_PRONOUNS_VALIDATION_SCHEMA,
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsEditJobTitleProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'jobTitle',
      label: 'Change job title',
      initialValues: {
        jobTitle: currentUserInfo?.jobTitle || '',
      },
      onSaveChanges: ({ jobTitle }) => {
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(currentUserInfo, {
            jobTitle,
          });
          updateUserSettings(payload);
          handleSaveClick(JOB_TITLE);
        }
      },
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsV2EditJobTitleProps: SettingsDropdownProps = useMemo(() => {
    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(currentUserInfo, {
          jobTitle: val,
        });
        updateUserSettings(payload);
        handleSaveClick(JOB_TITLE);
      }
    };

    return {
      dropdownType: 'SINGLE_SELECT',
      label: 'Update job title',
      initialValue: currentUserInfo?.jobTitle || '',
      dropdownItems: [jobTitleOptions],
      onSaveChanges,
    };
  }, [currentUserInfo, handleSaveClick, jobTitleOptions, updateUserSettings]);

  const settingsEditLocationProps: SettingsStringFieldProps = useMemo(() => {
    return {
      fieldName: 'location',
      label: 'Change location',
      initialValues: {
        location: currentUserInfo?.location || '',
      },
      onSaveChanges: ({ location }) => {
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(currentUserInfo, {
            location,
          });
          updateUserSettings(payload);
          handleSaveClick(isLocationCriteriaEnabled ? HOME_LOCATION : LOCATION);
        }
      },
    };
  }, [
    currentUserInfo,
    handleSaveClick,
    isLocationCriteriaEnabled,
    updateUserSettings,
  ]);

  const settingsV2EditLocationProps: SettingsDropdownProps = useMemo(() => {
    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(currentUserInfo, {
          location: val,
        });
        updateUserSettings(payload);
        handleSaveClick(isLocationCriteriaEnabled ? HOME_LOCATION : LOCATION);
      }
    };

    return {
      dropdownType: 'SINGLE_SELECT',
      label: 'Change location',
      initialValue: currentUserInfo?.location || '',
      dropdownItems: [locationOptions],
      onSaveChanges,
    };
  }, [
    currentUserInfo,
    handleSaveClick,
    locationOptions,
    updateUserSettings,
    isLocationCriteriaEnabled,
  ]);

  const settingsEditWorkLocationProps: SettingsDropdownProps = useMemo(() => {
    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(currentUserInfo, {
          workLocation: val,
        });
        updateUserSettings(payload);
        handleSaveClick(WORK_LOCATION);
      }
    };

    return {
      dropdownType: 'SINGLE_SELECT',
      label: 'Change location',
      initialValue: currentUserInfo?.workLocation || '',
      dropdownItems: [workLocationOptions],
      onSaveChanges,
    };
  }, [
    currentUserInfo,
    handleSaveClick,
    updateUserSettings,
    workLocationOptions,
  ]);

  const settingsHandleProps: SettingsHandleProps = useMemo(() => {
    return {
      handle: currentUserInfo?.handle,
    };
  }, [currentUserInfo]);

  const settingsEmailProps: SettingsEmailProps = useMemo(() => {
    return {
      email: currentUserInfo?.email,
    };
  }, [currentUserInfo]);

  const settingsDepartmentProps: SettingsDropdownProps = useMemo(() => {
    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      const updatedData = {
        department: val,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(DEPARTMENT);
      }
    };
    return {
      dropdownType: 'SINGLE_SELECT',
      label: LABEL.ADD_DEPARTMENT,
      initialValue: currentUserInfo?.department || '',
      dropdownItems: UpdatedEmployeeDepartments,
      onSaveChanges,
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const settingsTimezoneProps: SettingsDropdownProps = useMemo(() => {
    const formattedTimezones = timezones.map((timezone) => {
      return {
        value: timezone.value,
        id: timezone.key,
      };
    });
    const dropdownItemsTimezones = [
      {
        id: 'time-zone',
        items: formattedTimezones,
      },
    ];

    const onSaveChanges = (val: SelectedItemProps[] | string | undefined) => {
      if (currentUserInfo && val) {
        const filteredTimezoneDetails = formattedTimezones.filter(
          (timezone) => timezone.value === val,
        );
        if (filteredTimezoneDetails.length > 0) {
          const updatedData = {
            timeZone: filteredTimezoneDetails[0].id,
          };
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(TIME_ZONE);
        } else {
          showErrorMessage('Invalid timezone');
        }
      } else {
        showErrorMessage('Select a valid timezone');
      }
    };

    return {
      dropdownType: 'SINGLE_SELECT',
      label: LABEL.TIMEZONE,
      initialValue:
        currentUserInfo?.timeZone !== undefined
          ? mapTimezoneValue(currentUserInfo.timeZone)
          : '',
      dropdownItems: dropdownItemsTimezones,
      onSaveChanges,
    };
  }, [currentUserInfo, handleSaveClick, updateUserSettings]);

  const monthDropDownItems: MenuItemProps[] = useMemo(() => {
    const monthItems: MenuItemIndividualItem[] = monthNames.map((month) => {
      return {
        id: month,
        value: month,
        isSelected: false,
      };
    });
    return [{ id: 'monthDropdownItemValues', items: monthItems }];
  }, []);

  const startYearDropDownItems: MenuItemProps[] = useMemo(() => {
    return [
      {
        id: 'startYearItemValues',
        items: getHireYearOptions().map((year) => ({
          isSelected: false,
          id: year.value.toString(),
          value: year.value.toString(),
        })),
      },
    ];
  }, []);

  const settingsBirthdayProps: SettingsMultiSelectDropdownProps =
    useMemo(() => {
      const handleCheckboxValueChange = () => {
        setHideBirthdayOnProfile(!isHideBirthdayOnProfileTurnedOn);
      };
      const onSaveChangesButtonClick = (values: {
        fieldOneValue: string;
        fieldTwoValue: string;
      }) => {
        let updatedData;
        if (values.fieldOneValue === '' || values.fieldTwoValue === '') {
          updatedData = {
            birthday: '',
          };
          setHideBirthdayOnProfile(false);
        } else {
          updatedData = {
            birthday: {
              isVisibleAndCelebrated: !isHideBirthdayOnProfileTurnedOn,
              date: parseInt(values.fieldTwoValue),
              monthNumber: parseInt(
                getMonthNumbers([values.fieldOneValue]).toString(),
              ),
            },
          };
        }
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(BIRTHDAY);
        }
      };
      return {
        multiSelectDropDownType: MultiSelectDropdownType.Birthday,
        isDisabled: !!(
          currentUserInfo?.birthday?.month && currentUserInfo?.birthday.day
        ),
        initialValues: {
          fieldOneValue: currentUserInfo?.birthday?.month
            ? getMonthByNumber(currentUserInfo.birthday.month).toString()
            : '',
          fieldTwoValue: currentUserInfo?.birthday.day?.toString() || '',
        },
        monthDropDownItems,
        onSaveChanges: onSaveChangesButtonClick,
        checkBoxProps: {
          value: isHideBirthdayOnProfileTurnedOn,
          onChange: handleCheckboxValueChange,
          title: BIRTHDAY_SETTINGS_PROFILE_SETTINGS_TITLE,
          subTitle: BIRTHDAY_SETTINGS_PROFILE_SETTINGS_SUB_TITLE,
          canDisableSaveButton:
            currentUserInfo?.birthday.isVisibleAndCelebrated !==
            isHideBirthdayOnProfileTurnedOn,
        },
      };
    }, [
      currentUserInfo,
      handleSaveClick,
      isHideBirthdayOnProfileTurnedOn,
      monthDropDownItems,
      updateUserSettings,
    ]);

  const settingsStartDateProps: SettingsMultiSelectDropdownProps =
    useMemo(() => {
      const onSaveChangesButtonClick = (values: MultiSelectDropdownValues) => {
        let updatedData;
        if (
          values.fieldOneValue === '' ||
          values.fieldTwoValue === '' ||
          values.fieldThreeValue === ''
        ) {
          updatedData = {
            hireDate: '',
          };
        } else {
          updatedData = {
            hireDate: {
              date: parseInt(values.fieldTwoValue),
              monthNumber: parseInt(
                getMonthNumbers([values.fieldOneValue]).toString(),
              ),
              year: values.fieldThreeValue
                ? parseInt(values.fieldThreeValue)
                : 0,
            },
          };
        }
        if (currentUserInfo) {
          const payload = createPayloadForUpdateSettings(
            currentUserInfo,
            updatedData,
          );
          updateUserSettings(payload);
          handleSaveClick(`${START} ${DATE}`);
        }
      };

      return {
        multiSelectDropDownType: MultiSelectDropdownType.StartDate,
        isDisabled: true,
        initialValues: {
          fieldOneValue: currentUserInfo?.startDate.joiningMonth || '',
          fieldTwoValue:
            currentUserInfo?.startDate.joiningDay?.toString() || '',
          fieldThreeValue:
            currentUserInfo?.startDate.joiningYear?.toString() || '',
        },
        dayDropDownItems: menuItems,
        monthDropDownItems: monthDropDownItems,
        yearDropDownItems: startYearDropDownItems,
        onSaveChanges: onSaveChangesButtonClick,
      };
    }, [
      currentUserInfo,
      monthDropDownItems,
      startYearDropDownItems,
      updateUserSettings,
      handleSaveClick,
    ]);

  const currentUserID = useMemo(() => currentUserInfo?._id, [currentUserInfo]);

  const membersOptions: OptionsProps[] = useMemo(() => {
    const members = searchedMembers || [];
    const filteredMembers = members.map(
      ({ memberID, firstName, lastName, username, image }: IMemberDTO) => {
        const fullName = `${firstName} ${lastName}`;
        return {
          displayText: fullName,
          secondaryText: username && username.length > 0 ? `@${username}` : '',
          img: image || undefined,
          id: memberID,
          avatar: {
            img: image,
            name: fullName,
            userId: memberID,
          } as AvatarProps,
        };
      },
    );
    return filteredMembers.filter((member) => member.id !== currentUserID);
  }, [currentUserID, searchedMembers]);

  const handleAutoCompleteInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onUsersSearchChange(value);
    },
    [onUsersSearchChange],
  );

  const handleAutoCompleteFocus = useCallback(() => {
    setIsAutoCompletePristine(true);
  }, []);

  const managerId = currentUserInfo?.managers && currentUserInfo?.managers[0];
  const isManagerFetchQueryEnabled = !!managerId;
  const { data: managerDetailsData } = useFetchMembersDetailsQuery(
    managerId ? [managerId] : undefined,
    isManagerFetchQueryEnabled,
  );

  const memoizedManager = useMemo(() => {
    if (managerDetailsData && managerDetailsData?.membersDetails.length > 0) {
      const manager = serializeMembersDetailsFromApi(
        managerDetailsData.membersDetails[0],
      );
      return {
        firstName: manager.profile.firstName,
        lastName: manager.profile.lastName,
        memberId: manager.memberId,
        profilePicture: manager.profile.image,
        username: manager.profile.username,
      };
    }

    return undefined;
  }, [managerDetailsData]);

  const settingsManagerProps: SettingsAutocompleteProps = useMemo(() => {
    let setInitialValues: any = null;
    if (memoizedManager !== undefined) {
      setInitialValues = {
        displayText: `${memoizedManager.firstName} ${memoizedManager.lastName}`,
        id: memoizedManager.memberId,
        secondaryText: memoizedManager.username,
      };
    }

    const initialValues = {
      coWorkers: setInitialValues,
    };
    const onSaveChanges = (values: SettingsAutocompleteValues) => {
      const { coWorkers } = values;
      const managers: any[] = [];
      if (coWorkers && !Array.isArray(coWorkers)) {
        managers.push(coWorkers?.id);
      }
      const updatedData = {
        managers,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(MANAGER);
      }
    };
    return {
      label: LABEL.MANAGER,
      initialValues,
      autoCompleteOptions: membersOptions,
      onAutoCompleteInputChange: handleAutoCompleteInputChange,
      onAutoCompleteFocus: handleAutoCompleteFocus,
      isAutoCompleteLoading: isMembersSearchLoading,
      autoCompleteEmptyText: isAutoCompletePristine
        ? autoCompleteEmpty.PRISTINE
        : autoCompleteEmpty.TOUCHED,
      onSaveChanges,
      isMultiSelectEnable: false,
      isSettingsDisabled: !isCurrentUserAdmin,
    };
  }, [
    memoizedManager,
    membersOptions,
    handleAutoCompleteInputChange,
    handleAutoCompleteFocus,
    isMembersSearchLoading,
    isAutoCompletePristine,
    isCurrentUserAdmin,
    currentUserInfo,
    updateUserSettings,
    handleSaveClick,
  ]);

  const reportsIds = currentUserInfo?.reports;
  const isReportsFetchQueryEnabled = !!reportsIds;
  const { data: reportsDetailsData } = useFetchMembersDetailsQuery(
    reportsIds && reportsIds.length > 0 ? reportsIds : undefined,
    isReportsFetchQueryEnabled,
  );

  const memoizedReports = useMemo(() => {
    if (reportsDetailsData?.membersDetails) {
      return reportsDetailsData?.membersDetails.map((memberDetail) => {
        const report = serializeMembersDetailsFromApi(memberDetail);
        return {
          displayText: `${report.profile.firstName} ${report.profile.lastName}`,
          id: report.memberId,
          secondaryText: report.profile.username,
        };
      });
    }
    return undefined;
  }, [reportsDetailsData]);

  const settingsReportsProps: SettingsAutocompleteProps = useMemo(() => {
    const setInitialValues = memoizedReports
      ? memoizedReports.map((coWorker: OptionsProps) => {
          return {
            displayText: coWorker.displayText,
            id: coWorker.id,
            secondaryText: coWorker.img,
          };
        })
      : [];

    const initialValues = {
      coWorkers: setInitialValues,
    };
    const onSaveChanges = (values: SettingsAutocompleteValues) => {
      const { coWorkers } = values;
      const reports = getIds(coWorkers);
      const updatedData = {
        reports,
      };
      if (currentUserInfo) {
        const payload = createPayloadForUpdateSettings(
          currentUserInfo,
          updatedData,
        );
        updateUserSettings(payload);
        handleSaveClick(`${DIRECT} ${REPORTS}`);
      }
    };
    return {
      label: LABEL.REPORTS,
      initialValues,
      autoCompleteOptions: membersOptions,
      onAutoCompleteInputChange: handleAutoCompleteInputChange,
      onAutoCompleteFocus: handleAutoCompleteFocus,
      isAutoCompleteLoading: isMembersSearchLoading,
      autoCompleteEmptyText: isAutoCompletePristine
        ? autoCompleteEmpty.PRISTINE
        : autoCompleteEmpty.TOUCHED,
      onSaveChanges,
      isSettingsDisabled: !isCurrentUserAdmin,
    };
  }, [
    memoizedReports,
    membersOptions,
    handleAutoCompleteInputChange,
    handleAutoCompleteFocus,
    isMembersSearchLoading,
    isAutoCompletePristine,
    isCurrentUserAdmin,
    currentUserInfo,
    updateUserSettings,
    handleSaveClick,
  ]);

  const { initialValues } = settingsManagerProps;
  const managerPlaceholder = useMemo(() => {
    if (initialValues.coWorkers && !Array.isArray(initialValues.coWorkers)) {
      return initialValues.coWorkers?.displayText;
    } else if (!isCurrentUserAdmin) {
      return 'Not set';
    }
    return '';
  }, [initialValues.coWorkers, isCurrentUserAdmin]);

  const { initialValues: reportsInitialValues } = settingsReportsProps;
  const reportsPlaceholder = useMemo(() => {
    if (
      reportsInitialValues.coWorkers &&
      Array.isArray(reportsInitialValues.coWorkers)
    ) {
      return reportsInitialValues.coWorkers
        .map((report: OptionsProps) => report.displayText)
        .join(', ');
    } else if (!isCurrentUserAdmin) {
      return 'Not set';
    }
    return '';
  }, [isCurrentUserAdmin, reportsInitialValues.coWorkers]);

  const {
    initialValues: { fieldOneValue: birthMonth, fieldTwoValue: birthDay },
  } = settingsBirthdayProps;
  const birthDayPlaceholder =
    birthMonth && birthDay ? `${birthMonth} ${birthDay}` : '';

  const { initialValues: startDateValues } = settingsStartDateProps;
  const {
    fieldOneValue: joiningMonth,
    fieldTwoValue: joiningDay,
    fieldThreeValue: joiningYear,
  } = startDateValues;
  const startDatePlaceholder =
    joiningMonth && joiningDay && joiningYear
      ? `${joiningMonth} ${joiningDay}, ${joiningYear}`
      : '';

  //About me
  const [aboutMe, setAboutMe] = useState(currentUserInfo?.aboutMe || '');
  const handleAboutMeValueChange = (e) => {
    setAboutMe(e.target.value);
  };
  const handleAboutMeSaveChangesClick = () => {
    if (currentUserInfo) {
      const updatedData = {
        aboutMe,
      };
      const payload = createPayloadForUpdateSettings(
        currentUserInfo,
        updatedData,
      );

      updateUserSettings(payload);
      handleSaveClick(ABOUT_ME);
    }
  };
  const aboutMePlaceholder = currentUserInfo?.aboutMe
    ? currentUserInfo?.aboutMe
    : '';
  const settingsAboutMeProps = {
    value: aboutMe,
    onValueChange: handleAboutMeValueChange,
    isSaveChangesDisabled: aboutMe === currentUserInfo?.aboutMe,
    onSaveChangesClick: handleAboutMeSaveChangesClick,
  };

  //Social Profiles
  const socialProfilesPlaceholder = currentUserInfo?.socialProfiles
    ? getSocialProfilesPlaceholder(currentUserInfo?.socialProfiles)
    : '';
  const [socialHandles, setSocialHandles] = useState([
    { name: 'LINKEDIN', handle: '' },
    { name: 'TWITTER', handle: '' },
    { name: 'INSTAGRAM', handle: '' },
    { name: 'FACEBOOK', handle: '' },
    { name: 'MEDIUM', handle: '' },
  ]);
  const [
    isSocialProfilesSaveChangesDisabled,
    setSocialProfileSaveChangesDisabled,
  ] = useState(true);

  useEffect(() => {
    if (currentUserInfo?.socialProfiles) {
      const updatedSocialHandles = socialHandles.map((socialHandle) => {
        return {
          ...socialHandle,
          handle:
            currentUserInfo.socialProfiles?.find(
              (profile) => profile.name === socialHandle.name,
            )?.handle || '',
        };
      });
      setSocialHandles(updatedSocialHandles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo?.socialProfiles]);

  const handleSocialProfileValueChange = (
    socialProfile: SocialProfileNameEnum,
    handle: string,
  ) => {
    const existingHandle = currentUserInfo?.socialProfiles?.find(
      (profile) => profile.name === (socialProfile as string),
    );
    if (existingHandle) {
      setSocialProfileSaveChangesDisabled(existingHandle.handle === handle);
    } else {
      setSocialProfileSaveChangesDisabled(!handle);
    }

    const updateSocialHandleValue = socialHandles.map((socialHandle) => {
      if (socialProfile === socialHandle.name) {
        return { ...socialHandle, handle };
      } else {
        return socialHandle;
      }
    });
    setSocialHandles(updateSocialHandleValue);
  };

  const handleSocialProfilesSaveChangesClick = () => {
    setSocialProfileSaveChangesDisabled(true);
    if (currentUserInfo) {
      const updatedData = { socialProfiles: socialHandles };
      const payload = createPayloadForUpdateSettings(
        currentUserInfo,
        updatedData,
      );

      updateUserSettings(payload);
      handleSaveClick(SOCIAL_PROFILES);
    }
  };

  const settingsSocialProfilesProps = {
    socialHandles: socialHandles as SocialProfile[],
    handleSocialProfileValueChange,
    onSaveChangesClick: handleSocialProfilesSaveChangesClick,
    isSaveChangesDisabled: isSocialProfilesSaveChangesDisabled,
  };

  //Deactivate Account
  const { isMobileApp } = useMobileApp();
  const [isManageAccountCollapseOpen, setManageAccountCollapseOpen] =
    useState(false);
  const toggleManageAccountCollapse = () => {
    setManageAccountCollapseOpen(!isManageAccountCollapseOpen);
  };
  const [isDeactivateAccountModalOpen, setIsDeactivateAccountModalOpen] =
    useState(false);
  const handleDeactivateAccountModalClose = () => {
    setIsDeactivateAccountModalOpen(false);
  };
  const handleDeactivateAccountClick = () => {
    setIsDeactivateAccountModalOpen(true);
  };

  useEffect(() => {
    if (deactivatedMemberProps) {
      const {
        isDeactivateMemberFailed,
        isDeactivateMemberSuccess,
        deactivatedMemberData,
        deactivatedMemberErrorData,
      } = deactivatedMemberProps;
      if (isDeactivateMemberSuccess) {
        const deactivatedMemberResponse =
          deactivatedMemberData?.data as DeactivatedUserInfo;
        if (deactivatedMemberResponse?.errors.length) {
          showErrorMessage('Member already deactivated');
          setIsDeactivateAccountModalOpen(false);
        }
        if (deactivatedMemberResponse?.memberIds.length) {
          postMessageToMobileApp({
            action: AppAction.AccountDeactivationSuccess,
            targetEvent: AppTargetEvent.Mobile,
            payload: {
              type: 'accountDeactivation',
            },
          });
        }
      }
      if (isDeactivateMemberFailed) {
        const deactivatedMemberError =
          deactivatedMemberErrorData as ResponseError;
        showErrorMessage(deactivatedMemberError.response.data.body);
        setIsDeactivateAccountModalOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deactivatedMemberProps?.isDeactivateMemberSuccess,
    deactivatedMemberProps?.isDeactivateMemberFailed,
  ]);

  return {
    models: {
      settingsAvatarProps,
      settingsEditNameProps,
      settingsPronounsProps,
      settingsEditJobTitleProps,
      settingsV2EditJobTitleProps,
      settingsEditLocationProps,
      settingsV2EditLocationProps,
      settingsEditWorkLocationProps,
      settingsHandleProps,
      settingsEmailProps,
      settingsDepartmentProps,
      settingsTimezoneProps,
      settingsManagerProps,
      settingsReportsProps,
      settingsStartDateProps,
      settingsBirthdayProps,
      settingsAboutMeProps,
      settingsSocialProfilesProps,
      managerPlaceholder,
      reportsPlaceholder,
      birthDayPlaceholder,
      startDatePlaceholder,
      aboutMePlaceholder,
      socialProfilesPlaceholder,
      accordionItems,
      isMobileApp,
      isManageAccountCollapseOpen,
      isDeactivateAccountModalOpen,
    },
    operations: {
      handleCollapse,
      toggleManageAccountCollapse,
      handleDeactivateAccountClick,
      handleDeactivateAccountModalClose,
    },
    metadata: {},
  };
};

export default useSettingsControllerLogic;
