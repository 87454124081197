import { useEffect, useState } from 'react';

import useGetPageName from '../useGetPageName';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { INVITE_MEMBER_EVENTS } from '../../Utils/analytics/constants';
import { trackInviteMemberActionEvent } from '../../Utils/analytics/inviteMembers';

const useToggleInviteModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleInviteModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const { page } = useGetPageName();
  const { data: profileData } = useProfileInfoFetchQuery();

  useEffect(() => {
    if (isModalOpen) {
      trackInviteMemberActionEvent({
        action: INVITE_MEMBER_EVENTS.INVITE_START,
        inviterId: profileData?.member.memberId,
        inviterRole: profileData?.member.role,
        source: page,
        subSource: 'leftDrawer',
      });
    }
  }, [isModalOpen, profileData, page]);

  return {
    isModalOpen,
    toggleInviteModal,
  };
};

export default useToggleInviteModal;
