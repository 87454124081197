import { Redirect, Route, Switch } from 'react-router-dom';

import FlowsController from '../FlowsController';

import {
  FLOWS_BUILDER,
  FLOWS_FEEDS,
  FLOW_FEED,
  FLOW_INSIGHTS,
  NEW_FLOWS_BUILDER,
  UNARCHIVE_FLOW,
} from '../../../constants/routes';

import useGetUserFlowPermissions from '../../../hooks/useGetUserFlowPermissions';
import FlowsBuilderTabsController from '../../flowsBuilder/FlowsBuilderTabsController';
import { FlowsUnArchiveController } from './FlowsUnArchiveController';

const FlowsBuilderComponent = () => {
  const { canUserCreateFlow } = useGetUserFlowPermissions();

  if (canUserCreateFlow) {
    return <FlowsBuilderTabsController />;
  }
  return <Redirect to="/" />;
};

const FlowsRoutesController = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/:slugUrl${FLOWS_BUILDER}`}
        component={FlowsBuilderComponent}
      />
      <Route
        exact
        path={`/:slugUrl${NEW_FLOWS_BUILDER}`}
        component={FlowsBuilderComponent}
      />
      <Route
        exact
        path={`/:slugUrl${FLOW_INSIGHTS}`}
        component={FlowsBuilderTabsController}
      />
      <Route
        exact
        path={`/:slugUrl${FLOW_FEED}`}
        component={() => <FlowsBuilderTabsController />}
      />
      <Route exact path={`/:slugUrl${UNARCHIVE_FLOW}`}>
        <FlowsUnArchiveController />
      </Route>
      <Route path={`/:slugUrl${FLOWS_FEEDS}`}>
        <FlowsController />
      </Route>
    </Switch>
  );
};

export default FlowsRoutesController;
