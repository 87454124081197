export type GetMembersFromCriteriaJobResponse = {
  jobId: string;
  previewId: string;
  createdAt: string;
};

export type GetMembersFromCriteriaResponse = {
  previewId: string;
  createdAt: string;
};

export type GetMembersFromCriteriaForPreviewResponse =
  | GetMembersFromCriteriaJobResponse
  | GetMembersFromCriteriaResponse;

export type PreviewMember = {
  name: string;
  email: string;
  image?: string;
  firstName: string;
  lastName?: string;
  memberId?: string;
  state?: 'ACTIVE' | 'NEW' | 'PENDING';
};

export type GetMembersForPreviewRequest = {
  criteria: any;
  others?: any;
  includes?: [{ type: 'MEMBER'; value: string[] }];
};

export type GetMembersForPreviewResponse = {
  total: number;
  data: PreviewMember[];
  metadata: {
    pendingCount: number;
    pagination: { cursor: { previous: null; next: null } };
  };
};

export const isGetMembersFromCriteriaJobResponseType = (
  data: GetMembersFromCriteriaJobResponse | GetMembersFromCriteriaResponse,
) => {
  return 'jobId' in data;
};
