import React from 'react';
import { BubbleTextProps, ColorVariants } from './interfaces';
import { StyledBody, StyledSVGIcon, Wrapper } from './styles';
import ThemeV2 from '../../../theme';

export const getColors: { [Key: string]: ColorVariants } = {
  green: {
    background: ThemeV2.palette.green2,
    foreground: ThemeV2.palette.green9,
  },
  blue: {
    background: ThemeV2.palette.geekBlue2,
    foreground: ThemeV2.palette.geekBlue8,
  },
  gray: {
    background: ThemeV2.palette.gray3,
    foreground: ThemeV2.palette.gray8,
  },
  orange: {
    background: ThemeV2.palette.orange2,
    foreground: ThemeV2.palette.orange9,
  },
};

const BubbleText = (props: BubbleTextProps) => {
  const {
    text,
    icon,
    color = 'green',
    dataTestId,
    className,
    customColorVariant,
  } = props;

  const background = customColorVariant
    ? customColorVariant.background
    : getColors[color].background;

  const foreground = customColorVariant
    ? customColorVariant.foreground
    : getColors[color].foreground;

  return (
    <Wrapper className={className} color={background} data-testid={dataTestId}>
      {icon && (
        <StyledSVGIcon
          icon={icon}
          size="16px"
          color={foreground}
          data-testid="bubbleIcon"
        />
      )}
      <StyledBody variant="body3" color={foreground} inline>
        {text}
      </StyledBody>
    </Wrapper>
  );
};

export default BubbleText;
BubbleText.displayName = 'BubbleText';
