import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import styled, { css } from 'styled-components';
import ThemeV2 from '../../../theme';
import { device } from '../../../constants/layout';

interface StyledTabsProps {
  $splitEqually?: boolean;
  $hasIcon?: boolean;
  $isFiltered?: boolean;
  $isTabPositionFixed?: boolean;
  paddingLeft?: string;
  marginLeft?: string;
  marginRight?: string;
}

const tabsIconStyle = css`
  & .Mui-selected {
    svg > path:last-of-type {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
`;

const tabsSplitStyle = css`
  justify-content: space-around;
  background: ${ThemeV2.palette.gray1};
  border-bottom: none;
`;

export const StyledTabs = styled(Tabs)<StyledTabsProps>`
  padding: 0px 24px;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : '24px')};
  min-height: auto;
  width: 100%;
  @media ${device.mobile} {
    background-color: ${ThemeV2.palette.white};
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : '0px')};
  }
  background-color: inherit;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  position: ${({ $isTabPositionFixed }) => $isTabPositionFixed && 'fixed'};
  z-index: ${({ $isTabPositionFixed }) => $isTabPositionFixed && 2};
  margin-top: ${({ $isTabPositionFixed }) => $isTabPositionFixed && '-37px'};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : '0px')};

  .MuiTab-root {
    color: ${ThemeV2.palette.gray7};
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 6px 12px;
  }

  .MuiTabs-indicator {
    border-radius: 4px 4px 0 0;
    background-color: ${ThemeV2.palette.geekBlue6};
    transition-duration: 150ms;
  }

  .MuiTabs-scrollButtonsDesktop {
    @media (max-width: 599.95px) {
      display: inline-flex;
    }

    &:disabled {
      display: none;
    }
  }

  ${({ $splitEqually }) => $splitEqually && tabsSplitStyle}
  ${({ $hasIcon }) => $hasIcon && tabsIconStyle}
`;

const tabIconStyle = css`
  & > .MuiTab-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      margin-bottom: 0px !important;
      margin-right: 4px;
    }
    span:nth-child(2) {
      margin-right: 0;
    }
  }
  svg > path:last-of-type {
    fill: ${ThemeV2.palette.gray7};
  }
  &:hover svg > path:last-of-type {
    fill: ${ThemeV2.palette.geekBlue6};
  }
`;

const StyledTabSplitStyle = css`
  flex: 1;
`;

export const StyledTab = styled(Tab)<StyledTabsProps>`
  border-radius: 4px;
  text-transform: capitalize;
  color: ${ThemeV2.palette.gray7};
  margin: 2px 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  height: 32px;
  min-width: auto;
  min-height: auto;

  &:hover {
    color: ${ThemeV2.palette.geekBlue6};
  }

  &.Mui-selected {
    color: ${ThemeV2.palette.geekBlue6};
  }

  ${({ $hasIcon }) => $hasIcon && tabIconStyle};
  ${({ $splitEqually }) => $splitEqually && StyledTabSplitStyle};

  & > .MuiTab-root {
    span:last-child {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        right: -16px;
        top: 8px;
        background-color: ${({ $isFiltered }) =>
          $isFiltered && ThemeV2.palette.geekBlue6};
      }
    }
  }
`;

export const StyledMoreButton = styled.button`
  align-items: center;
  padding: 0;
  background: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
`;
