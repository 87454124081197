import isNil from 'lodash/isNil';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { AxomoSwagReward } from '../../../queries/Rewards/interfaces';
import { useAxomoRewardItemQuery } from '../../../queries/Rewards';
import { showErrorMessage } from '../../../Utils/toast';
import { useGetAxomoShippingCountries } from '../../../queries/Admin/rewards/Swags';
import { trackAxomoActionEvent } from '../../../Utils/analytics/axomo';
import { AXOMO_EVENTS } from '../../../Utils/analytics/constants';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

interface RewardCardControllerModelProps {
  rewardsData: AxomoSwagReward[];
  rewardCardData: AxomoSwagReward;
  totalPoints: number;
  rewardPoints: number;
}

const useRewardCardControllerModel = (
  props: RewardCardControllerModelProps,
) => {
  const history = useHistory();
  const location = useLocation();
  const { id: cardId } = useParams<{ id: string }>();
  const { rewardsData, rewardCardData, totalPoints, rewardPoints } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState<string | undefined>();

  const {
    data: rewardItemData,
    isError: isRewardItemError,
    isInitialLoading: isRewardItemLoading,
  } = useAxomoRewardItemQuery(
    rewardCardData.uid,
    rewardCardData.storeUID,
    Boolean(selectedReward),
  );
  const { data: axomoShippingCountries } = useGetAxomoShippingCountries(
    Boolean(selectedReward),
  );
  const handleOnRewardSelect = useCallback(
    (id) => {
      const currentReward = rewardsData.find((reward) => reward.uid === id);
      if (currentReward && !isRewardItemLoading) {
        setIsModalOpen(true);
        setSelectedReward(id);
        trackAxomoActionEvent({
          action: AXOMO_EVENTS.SWAG_VIEWED,
          swagItem: currentReward.name,
          shippingCost: currentReward.shippingCost.value,
          swagItemCost: currentReward.points[0],
        });
      }
    },
    [isRewardItemLoading, rewardsData],
  );

  const onCloseRedeemModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedReward(undefined);
    history.replace(location.pathname.replace(`/${cardId}`, ''));
  }, [cardId, history, location.pathname]);

  useEffect(() => {
    if (isRewardItemError) showErrorMessage('error');
  }, [isRewardItemError]);

  const quantityDropdownValues = useMemo(() => {
    if (isNil(rewardCardData?.shippingCost.value)) return [];
    const totalRedeemablePoints =
      totalPoints - rewardCardData.shippingCost.value;
    const maxQuantity = Math.floor(totalRedeemablePoints / (rewardPoints || 1));
    if (maxQuantity <= 0 || isNaN(maxQuantity)) return [];

    const dropdownArray = Array.from(new Array(maxQuantity + 1).keys());
    dropdownArray.shift();
    return [
      {
        id: 'quantity-dropdown',
        items: dropdownArray.map((value) => {
          return {
            id: value,
            value: value.toString(),
            isSelected: false,
          };
        }),
      },
    ];
  }, [rewardCardData.shippingCost.value, totalPoints, rewardPoints]);

  useEffect(() => {
    if (cardId === rewardCardData.uid) {
      setIsModalOpen(true);
      setSelectedReward(rewardCardData.uid);
    }
  }, [cardId, rewardCardData.uid]);

  return {
    isModalOpen,
    selectedReward,
    rewardItemData,
    handleOnRewardSelect,
    onCloseRedeemModal,
    isRewardItemLoading,
    isRewardItemError,
    quantityDropdownValues,
    axomoShippingCountries,
    shippingCost: rewardCardData.shippingCost.value,
  };
};

export default useRewardCardControllerModel;
