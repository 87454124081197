import { AllowedFlowFileTypes } from '../../../interfaces/Flow';

export const generateAllowedFileTypes = (
  allowedFileTypesFromApi: AllowedFlowFileTypes[],
): string[] => {
  return allowedFileTypesFromApi.map((fileType) => `.${fileType}`);
};

export const gigabytes = (bytes: number) => bytes * 1000000000;
export const megabytes = (bytes: number) => bytes * 1000000;
export const kilobytes = (bytes: number) => bytes * 1024;

export const bytesToMegaBytes = (bytes: number) => {
  return bytes / (1024 * 1024);
};
