import React from 'react';
import ProfileButton from '../../../atomic/organism/ProfileButton';
import useRewardsButtonController from '../RewardsButtonController/useRewardsButtonController';
import useProfileButtonController from './useProfileButtonController';

const ProfileButtonController = () => {
  const {
    currentUserData,
    hideRewardsLink,
    isCurrentUserAdmin,
    onViewProfileClick,
    trackProfileMenuOpened,
  } = useProfileButtonController();

  const {
    assemblyData,
    hideRedeemButton,
    hideAllowanceDetails,
    hideRewardsInfo,
    refreshDate,
    trackLeftDrawerRedeemClick,
    rewardType,
  } = useRewardsButtonController();

  if (!currentUserData || !assemblyData) {
    return <div />;
  }

  const rewardsProps = {
    assemblyCurrency: assemblyData.currency,
    totMonthlyAllowance: currentUserData.allowance.points,
    remainingMonthlyAllowance: currentUserData.pointsLeftThisCycle,
    refreshInfoDate: refreshDate,
    trophyBalance: currentUserData.pointsEarned,
    hideRedeemButton: hideRedeemButton,
    hideAllowanceDetails: hideAllowanceDetails,
    hideRewardsInfo: hideRewardsInfo,
    redeemButtonClicked: () => {
      trackLeftDrawerRedeemClick();
    },
    rewardType: rewardType,
  };

  return (
    <ProfileButton
      currentUserId={currentUserData.memberId}
      currentUserProfile={currentUserData.profile}
      isCurrentUserAdmin={isCurrentUserAdmin}
      hideRewardsLink={hideRewardsLink}
      onViewProfileClick={onViewProfileClick}
      rewardsProps={rewardsProps}
      onProfileButtonClick={trackProfileMenuOpened}
    />
  );
};

export default ProfileButtonController;
