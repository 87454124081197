import { useContext, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { useMediaQuery } from 'react-responsive';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../Utils/analytics/constants';
import {
  trackFolderActionEvent,
  trackFolderErrorEvent,
} from '../Utils/analytics/folder';
import { trackLeftDrawerActionEvent } from '../Utils/analytics/leftDrawer';
import { showErrorMessage, showSuccessMessage } from '../Utils/toast';
import { MenuItemProps } from '../atomic/molecules/Dropdown_V2/interfaces';
import { LeftDrawerCollapsibleNavItemProps } from '../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import { device } from '../constants/layout';
import { allFlowFeedHeaderOptions } from '../controllers/flows/FlowFeedHeaderController/data';
import { getFlowHeaderOptionItems } from '../controllers/flows/FlowFeedHeaderController/utils';
import { useMainNavQuery } from '../queries/Flows/Dashboard';
import { useFetchFlowDetailsQuery } from '../queries/Flows/Feed';
import { useUpdateFlowInFolder } from '../queries/folderAPI';
import useLayoutStore from '../stores/layoutStore';
import { ArchiveFlowModalContext } from './useArchiveFlowModal/archiveFlowModalContext';
import { EndFlowOccurrenceModalContext } from './useEndFlowOccurrenceModal/endFlowOccurrenceModalContext';
import useOptions from './useFlowFeedOptions/useOptions';
import useTriggerFlowOption from './useFlowFeedOptions/useTriggerFlowOption';

const useFlowsListAccordion = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleExpandNavigation = () => setIsOpen(!isOpen);

  const handleAccordionClick = () => {
    toggleExpandNavigation();
    if (isOpen) {
      trackLeftDrawerActionEvent({
        action:
          LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FEEDS_LIST_COLLAPSED,
      });
    } else {
      trackLeftDrawerActionEvent({
        action:
          LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FEEDS_LIST_EXPANDED,
      });
    }
  };

  const {
    isLeftAsideOpen,
    isRightAsideOpen,
    showChats,
    toggleLeftAsideOpen,
    toggleRightAsideOpen,
    setShowChats,
  } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
      showChats: state.showChats,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      setShowChats: state.setShowChats,
    }),
    shallow,
  );

  const isMobileView = useMediaQuery({ query: device.mobile });

  const handleMenuItemClick = () => {
    // close the left drawer before navigating to specific flow
    if (isLeftAsideOpen && isMobileView) {
      toggleLeftAsideOpen();
    }
    if (showChats && isMobileView) {
      setShowChats(false);
    }
    if (
      (!isRightAsideOpen && !isMobileView) ||
      (isRightAsideOpen && isMobileView)
    ) {
      toggleRightAsideOpen();
    }
  };

  const {
    operations: {
      handleEditFlow,
      handleCopyFlowFeedLink,
      handleDuplicateFlowClick,
      handleEditNotificationSettings,
      handleHideActivityClick,
      handleShareAsTemplateClick,
      handleShowActivityClick,
    },
  } = useOptions();

  const [flowDetails, setFlowDetails] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);

  const [flowsDropdownMenuItems, setFlowsDropdownMenuItems] = useState<
    MenuItemProps[]
  >([]);

  const { setArchiveModalOpen, setFlowDetails: setFlowDetailsForArchiveModal } =
    useContext(ArchiveFlowModalContext);

  const {
    setIsEndOccurrenceModalOpen,
    setFlowDetails: setFlowDetailsForEndOccurrenceModal,
  } = useContext(EndFlowOccurrenceModalContext);

  const flowId = flowDetails ? flowDetails.id : '';
  const { data: flowData } = useFetchFlowDetailsQuery(flowId, 'builder');
  const {
    operations: { setIsTriggerFlowModalOpen },
  } = useTriggerFlowOption(flowId);

  const { mutateAsync: removeFromFolder } = useUpdateFlowInFolder();
  const { mutateAsync: updateFlowLocation } = useUpdateFlowInFolder();

  const { data } = useMainNavQuery();

  const handleMenuItemOptionsSelect = async (value: any) => {
    if (value === allFlowFeedHeaderOptions.runFlowNow.id) {
      setIsTriggerFlowModalOpen();
    }
    if (value === allFlowFeedHeaderOptions.endOccurrence.id) {
      setIsEndOccurrenceModalOpen();
    }

    if (value === allFlowFeedHeaderOptions.copyFlowFeedLink.id) {
      handleCopyFlowFeedLink();
      trackLeftDrawerActionEvent({
        action:
          LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_LINK_COPIED,
        ...flowDetails,
        folder: flowDetails?.folderInfo?.folderId ? 'customFolder' : 'flows',
      });
    }

    if (value === allFlowFeedHeaderOptions.duplicateFlow.id) {
      handleDuplicateFlowClick(flowDetails?.id);
      trackLeftDrawerActionEvent({
        action: LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_DUPLICATE_FLOW,
        ...flowDetails,
        folder: flowDetails?.folderInfo?.folderId ? 'customFolder' : 'flows',
      });
    }

    if (value === allFlowFeedHeaderOptions.editNotificationSettings.id) {
      handleEditNotificationSettings();
      trackLeftDrawerActionEvent({
        action:
          LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_EDIT_NOTIFICATION_SETTINGS_CLICKED,
        ...flowDetails,
        folder: flowDetails?.folderInfo?.folderId ? 'customFolder' : 'flows',
      });
    }

    if (value === allFlowFeedHeaderOptions.archiveFlow.id) {
      setArchiveModalOpen();
    }

    if (value === allFlowFeedHeaderOptions.hideActivity.id) {
      handleHideActivityClick(flowDetails?.id);
    }

    if (value === allFlowFeedHeaderOptions.shareAsTemplate.id) {
      handleShareAsTemplateClick(flowDetails?.id, flowData);
      trackLeftDrawerActionEvent({
        action:
          LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_SHARED_AS_TEMPLATE,
        ...flowDetails,
        folder: flowDetails?.folderInfo?.folderId ? 'customFolder' : 'flows',
      });
    }

    if (value === allFlowFeedHeaderOptions.showActivity.id) {
      handleShowActivityClick(flowDetails?.id);
    }

    if (value === allFlowFeedHeaderOptions.editFlow.id) {
      handleEditFlow(flowDetails?.id);
    }

    if (
      value === allFlowFeedHeaderOptions.removeFromFolder.id &&
      flowDetails?.id &&
      flowDetails?.folderInfo?.folderId
    ) {
      try {
        await removeFromFolder({
          flowId: flowDetails?.id,
          sourceFolderId: flowDetails?.folderInfo?.folderId,
          destinationFolderId: null,
        });

        showSuccessMessage('Flow removed from folder');
      } catch (error) {
        showErrorMessage('Error removing flow from folder');
      }
    }

    if (value.startsWith('moveToFolder') && flowDetails?.id) {
      const destinationFolderId = value.split('_')[1].toString();

      try {
        const payload = {
          flowId: flowDetails?.id,
          destinationFolderId,
        } as Parameters<typeof updateFlowLocation>[0];

        if (flowDetails.folderInfo?.folderId) {
          payload.sourceFolderId = flowDetails.folderInfo?.folderId;
        }

        await updateFlowLocation(payload);

        trackFolderActionEvent({
          action: 'flowAddedToFolder',

          folder: 'customFolder',
          customFolderName:
            data?.folders.find((x) => x.folderId === destinationFolderId)
              ?.name || undefined,
        });
      } catch (error) {
        trackFolderErrorEvent({
          error: 'flowAddedToFolder',
          folder: 'customFolder',
          customFolderName:
            data?.folders.find((x) => x.folderId === destinationFolderId)
              ?.name || undefined,
        });
      }
    }
  };

  const onDropdownMenuItemRightClick = (
    item: LeftDrawerCollapsibleNavItemProps,
  ) => {
    const items = getFlowHeaderOptionItems({
      isFlowOwner: item.isFlowOwner,
      isFlowParticipant: item.isFlowParticipant,
      isFlowViewer: item.isFlowViewer,
      // occurrence: item.occurrence || {}, since V2 is discontinued, I'm not going to fix this properly
      currentFolder: item.folderInfo,
      folders: data?.folders || [],
    });

    setFlowDetails(item);
    setFlowDetailsForArchiveModal(item);
    setFlowDetailsForEndOccurrenceModal(item);
    setFlowsDropdownMenuItems([
      {
        id: 'flows-navigation-dropdown-menu',
        items,
      },
    ]);

    trackLeftDrawerActionEvent({
      action: LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_MENU_OPENED,
      ...item,
      folder: flowDetails?.folderInfo?.folderId ? 'customFolder' : 'flows',
    });
  };

  return {
    flowsDropdownMenuItems,
    handleAccordionClick,
    handleMenuItemClick,
    handleMenuItemOptionsSelect,
    isOpen,
    onDropdownMenuItemRightClick,
    toggleExpandNavigation,
  };
};

export default useFlowsListAccordion;
