import { create } from 'zustand';
import { FeatureIntrosStore } from '../../interfaces/FeatureIntros';
import { TourStatusEnum } from '../../Utils/featureIntros/types';

export const useFeatureIntrosStore = create<FeatureIntrosStore>()((set) => ({
  data: {},
  setData: (updatedValue: Record<string, TourStatusEnum>) =>
    set(({ data }) => {
      for (const key in updatedValue) {
        data[key] = updatedValue[key];
      }
      return { data };
    }),
}));
