import Collapse from '@mui/material/Collapse';
import { useCallback, useState } from 'react';

import Body from '../../atoms/Body';
import {
  ExpandableDropdownItemContainer,
  StyledDropdownArrowIcon,
} from './styles';
import { ExpandableDropdownListItemProps } from './type';

const ExpandableDropdownListItem = (props: ExpandableDropdownListItemProps) => {
  const { value, id, onItemClick, isSelectable, data, depth } = props;
  const [isExpanded, setExpanded] = useState(false);
  const [depthLevel] = useState((depth || 0) + 1);

  const handleItemClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (data.length > 0) {
        setExpanded(!isExpanded);
      } else if (onItemClick && isSelectable && data.length <= 0) {
        onItemClick(id, value);
      }
    },
    [data.length, id, isSelectable, isExpanded, onItemClick, value],
  );

  const handleItemLabelClick = useCallback(() => {
    if (onItemClick && isSelectable) {
      onItemClick(id, value);
    } else if (onItemClick && !isSelectable) {
      setExpanded(!isExpanded);
    }
  }, [id, isSelectable, isExpanded, onItemClick, value]);

  const handleDropdownButtonClick = useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded]);

  return (
    <div onClick={handleItemClick}>
      <ExpandableDropdownItemContainer
        alignItems="center"
        justifyContent="space-between"
        $depthLevel={depthLevel}
      >
        <div onClick={handleItemLabelClick}>
          <Body variant="body2">{value}</Body>
        </div>
        {data.length > 0 && (
          <div onClick={handleDropdownButtonClick}>
            <StyledDropdownArrowIcon
              icon={isExpanded ? 'carat-left' : 'carat-right'}
              size="16px"
              rotate={90}
              data-testid="expandableDropdownItemIcon"
            />
          </div>
        )}
      </ExpandableDropdownItemContainer>
      <Collapse in={isExpanded}>
        {data.length > 0 &&
          data.map((item) => (
            <ExpandableDropdownListItem
              key={item.id}
              value={item.value}
              id={item.id}
              isSelectable={item.isSelectable}
              onItemClick={onItemClick}
              data={item.data}
              depth={depthLevel}
            />
          ))}
      </Collapse>
    </div>
  );
};

export default ExpandableDropdownListItem;
