import React from 'react';

import { SingleSelectAutoComplete } from '../Autocomplete';
import { Flex } from '../../../Utils/styles/display';
import {
  StyledBody,
  StyledFlowRecurWrapper,
  StyleFlowRecurBody,
  StyledErrorBody,
} from './styles';

import { FlowRecurProps } from './types';
import {
  CONFIGURE_SETTINGS_ERROR_MESSAGE,
  CONFIGURE_SETTINGS_FLOW_RECUR,
  CONFIGURE_SETTINGS_PICK_A_FREQUENCY,
  CONFIGURE_SETTINGS_PICK_A_TIME,
} from '../../../languages/en/flows/builder';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';

const FlowRecur = (props: FlowRecurProps) => {
  const {
    flowFrequency,
    onFlowFrequencyChange,
    flowFrequencyOptions,
    flowFrequencyTime,
    onFlowFrequencyTimeChange,
    flowFrequencyTimeOptions,
    disabled,
    hasPastTimeError = false,
    onFlowFrequencyFocus,
    onFlowFrequencyTimeFocus,
  } = props;

  const filterOptionsFunction = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: AutocompleteDropdownItem<number, undefined>) =>
      option.title,
  });

  return (
    <>
      <StyledBody variant="body1Medium">
        {CONFIGURE_SETTINGS_FLOW_RECUR}
      </StyledBody>
      <Flex>
        <StyledFlowRecurWrapper>
          <SingleSelectAutoComplete
            label={CONFIGURE_SETTINGS_PICK_A_FREQUENCY}
            value={flowFrequency}
            onChange={onFlowFrequencyChange}
            options={flowFrequencyOptions}
            showClearIcon={false}
            onFocus={onFlowFrequencyFocus}
            expandableDropdown
          />
        </StyledFlowRecurWrapper>
        <StyleFlowRecurBody variant="body3">at</StyleFlowRecurBody>
        <StyledFlowRecurWrapper>
          <SingleSelectAutoComplete
            label={CONFIGURE_SETTINGS_PICK_A_TIME}
            value={flowFrequencyTime}
            onChange={onFlowFrequencyTimeChange}
            filterOptionsFunction={filterOptionsFunction}
            options={flowFrequencyTimeOptions}
            disabled={disabled}
            hasError={hasPastTimeError}
            onFocus={onFlowFrequencyTimeFocus}
            showClearIcon={false}
            autoSelect
          />
          {hasPastTimeError && (
            <StyledErrorBody variant="body4" color="red6">
              {CONFIGURE_SETTINGS_ERROR_MESSAGE.START_TIME_OCCURRED_IN_THE_PAST}
            </StyledErrorBody>
          )}
        </StyledFlowRecurWrapper>
      </Flex>
    </>
  );
};

export default FlowRecur;
