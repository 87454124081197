import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';

import ThemeV2 from '../../theme';
import Body from '../../atomic/atoms/Body';
import Button from '../../atomic/atoms/Button';
import SVGIcon from '../../atomic/atoms/SVGIcon';
import { Flex } from '../../Utils/styles/display';

const ManageAccountWrapper = styled.div`
  border: 1px solid ${ThemeV2.palette.gray5};
  border-radius: 8px;
  padding: 16px;
`;

const ContentWrapper = styled(Flex)`
  border-top: 0.5px solid ${ThemeV2.palette.gray4};
  padding: 16px 0;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const StyledSVGIcon = styled(SVGIcon)`
  margin: 0 4px;
`;

const DeactivateButton = styled(Button)`
  width: 100%;
  margin-top: 8px;
  span {
    color: ${ThemeV2.palette.gray9};
  }
`;

export type ManageAccountProps = {
  workSpaceName: string;
  onDeactivateAccountClick: () => void;
  isManageAccountCollapseOpen: boolean;
  toggleManageAccountCollapse: () => void;
};

const ManageAccount = (props: ManageAccountProps) => {
  const {
    workSpaceName,
    onDeactivateAccountClick,
    isManageAccountCollapseOpen,
    toggleManageAccountCollapse,
  } = props;

  return (
    <ManageAccountWrapper>
      <Flex
        onClick={toggleManageAccountCollapse}
        justifyContent="space-between"
      >
        <Body variant="body2Bold">Manage Account</Body>
        <StyledSVGIcon
          icon={isManageAccountCollapseOpen ? 'carat-up' : 'carat-down'}
          size="24px"
          color={ThemeV2.palette.gray8}
        />
      </Flex>
      <Collapse in={isManageAccountCollapseOpen}>
        <ContentWrapper>
          <Body variant="body2Bold">Deactivate Account</Body>
          <Body variant="body2">
            If you no longer need your account in your{' '}
            <Body inline variant="body2Bold">
              {workSpaceName}
            </Body>{' '}
            workspace, you can deactivate it. Any other Assembly workspaces you
            are a part of will not be affected.
          </Body>
          <Body variant="body2">
            To reactivate, or to delete your account permanently, please contact
            your Assembly workspace admin.
          </Body>
          <DeactivateButton
            color="secondary"
            onClick={onDeactivateAccountClick}
          >
            Deactivate my account
          </DeactivateButton>
        </ContentWrapper>
      </Collapse>
    </ManageAccountWrapper>
  );
};

export default ManageAccount;
