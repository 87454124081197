import styled from 'styled-components';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';

export const ChildrenWrapper = styled.div`
  background-color: ${ThemeV2.palette.gray1};
  margin-bottom: 40px;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  height: 86px;
`;

export const StyledBody = styled(Body)`
  display: inline;
`;

export const IconButtonWrapper = styled.div`
  padding-right: 16px;
`;
