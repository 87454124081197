import React, { ReactNode, useCallback } from 'react';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';
import ShortcutActionCard from '../../atoms/DropdownCard_V2';
import { DropdownCategoryLabel as ShortcutActionCategoryLabel } from '../Dropdown_V2/styles';
import { DropdownCardWrapper as ShortcutActionCardWrapper } from '../../atoms/DropdownCard_V2/styles';
import { DropdownPlacement, MenuItemProps } from '../Dropdown_V2/interfaces';
import ShortcutActionListItem from '../DropdownListItem_V2';
import ToolTip from '../ToolTipV2';

export interface ShortcutActionProps {
  id?: string | number;
  items?: any; // FIXME: Used only in TextField_V2, needs refactor.
  disabled?: boolean;
  isFullWidth?: boolean;
  menuItems: MenuItemProps[];
  onItemClick?: (value: string | number) => void;
  multiSelect?: boolean;
  getItemLabel?: (value: string) => ReactNode;
  onFocus?: () => void;
}
export interface ShortActionListProps extends ShortcutActionProps {
  referenceElement: React.MutableRefObject<null>;
  isOpen: boolean;
  closeDropdown: () => void;
  dropdownPlacement?: DropdownPlacement;
  isDropdownPositionFixed?: boolean;
  anchorEl: React.MutableRefObject<null>;
}

const ShortcutActionList = (props: ShortActionListProps) => {
  const {
    id: popperId,
    referenceElement,
    menuItems,
    onItemClick = () => {},
    disabled,
    isFullWidth,
    multiSelect,
    isOpen,
    closeDropdown,
    dropdownPlacement = DropdownPlacement.Bottom,
    isDropdownPositionFixed,
    getItemLabel,
    anchorEl,
  } = props;

  useOnClickOutside(referenceElement, closeDropdown);

  const handleMenuItemClick = useCallback(
    (id: string | number) => {
      if (!multiSelect) {
        closeDropdown();
      }
      onItemClick(id);
    },
    [multiSelect, onItemClick, closeDropdown],
  );

  return (
    <ShortcutActionCardWrapper isFullWidth={isFullWidth}>
      <ShortcutActionCard
        id={popperId as string | undefined}
        isOpen={!disabled && isOpen}
        anchorEl={anchorEl.current}
        dropdownPlacement={dropdownPlacement}
        isDropdownPositionFixed={isDropdownPositionFixed}
        isFullWidth={isFullWidth}
      >
        {menuItems.map((menuItem) => (
          <div
            key={menuItem.id}
            style={{
              display: 'flex',
            }}
          >
            {menuItem.category && (
              <ShortcutActionCategoryLabel variant="body3">
                {menuItem.category}
              </ShortcutActionCategoryLabel>
            )}
            {menuItem.items.map((item, index) => (
              <ToolTip
                key={index}
                toolTipComponent={
                  <ShortcutActionListItem
                    key={item.id}
                    value={''}
                    id={item.id}
                    isSelected={item.isSelected}
                    prefixIcon={item.prefixIcon}
                    helperText={item.helperText}
                    prefixImg={item.prefixImg}
                    disabled={item.disabled}
                    deleteItem={item.deleteItem}
                    assemblyCurrency={item.assemblyCurrency}
                    emoji={item.emoji}
                    onItemClick={handleMenuItemClick}
                    getItemLabel={getItemLabel}
                    isActionListItem
                  />
                }
                position={
                  menuItem.items.length === index + 1 ? 'top-start' : 'top'
                }
                positionStrategy="fixed"
              >
                {item.value}
              </ToolTip>
            ))}
          </div>
        ))}
      </ShortcutActionCard>
    </ShortcutActionCardWrapper>
  );
};

const MemoizedShortcutActionList = React.memo(ShortcutActionList);
MemoizedShortcutActionList.displayName = 'ShortcutActionList';
export default MemoizedShortcutActionList;
