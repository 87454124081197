import styled, { css } from 'styled-components';
import ThemeV2 from '../../../theme';
import ToggleButton from '../../atoms/ToggleButton';

export const ReactedMembersListWrapper = styled.div`
  max-width: 426px;
`;
ReactedMembersListWrapper.displayName = 'ReactedMembersListWrapper';

export const StyledToggleButton = styled(ToggleButton)<{
  varient?: 'sidebar' | 'feed';
  showReactions?: boolean;
}>`
  margin-bottom: ${({ varient }) => (varient === 'sidebar' ? '0' : '8px')};
  margin-right: ${({ varient }) => (varient === 'sidebar' ? '0' : '8px')};
  padding-left: ${({ varient }) => (varient === 'sidebar' ? '8px' : '8px')};
  padding-right: ${({ varient }) => (varient === 'sidebar' ? '8px' : '12px')};
  padding-top: ${({ varient }) => varient === 'sidebar' && '3px'};
  padding-bottom: ${({ varient }) => varient === 'sidebar' && '3px'};

  ${({ showReactions }) =>
    showReactions &&
    css`
      padding-bottom: 2px;
      border-radius: 12px;

      color: ${ThemeV2.palette.gray8};
      background: ${ThemeV2.palette.gray3};

      &:hover {
        color: ${ThemeV2.palette.geekBlue6};
        background: ${ThemeV2.palette.geekBlue2};
      }
    `}

  > div:first-child {
    margin-right: 0;
  }
`;
StyledToggleButton.displayName = 'StyledToggleButton';

export const StyledReactionButton = styled(ToggleButton)`
  margin-right: 8px;
  margin-bottom: 8px;
`;

StyledReactionButton.displayName = 'StyledReactionButton';
