import InputAdornment from '@mui/material/InputAdornment';
import last from 'lodash/last';
import { useCallback } from 'react';
import styled from 'styled-components';
import { useImmer } from 'use-immer';
import {
  trackFolderActionEvent,
  trackFolderErrorEvent,
} from '../../../Utils/analytics/folder';
import { showErrorMessage, showSuccessMessage } from '../../../Utils/toast';
import Body from '../../../atomic/atoms/Body';
import Button from '../../../atomic/atoms/Button';
import {
  FolderColorPicker,
  folderColorTextMap,
} from '../../../atomic/atoms/FolderColorPicker';
import Heading from '../../../atomic/atoms/Heading';
import IconButton from '../../../atomic/atoms/IconButton_V2';
import { ButtonSize } from '../../../atomic/atoms/IconButton_V2/interface';
import Label from '../../../atomic/atoms/Label';
import SimpleModal from '../../../atomic/atoms/Modal';
import TextField from '../../../atomic/atoms/TextField';
import { useMainNavQuery } from '../../../queries/Flows/Dashboard';
import { useFolderUpsertMutation } from '../../../queries/folderAPI';

type FolderModalProps = {
  name?: string;
  id?: string;
  color?: string;
  isCreateMode: boolean;
  beforeSection: string;
  onClose: () => void;
};

const Modal = styled(SimpleModal)`
  width: 512px;
  height: 336px;
  padding: 24px;
  gap: 24px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 16px;
  width: 16px;
`;

const FormSection = styled.div`
  margin-top: 24px;
`;

export function FolderModal({
  name,
  color,
  onClose,
  isCreateMode,
  id,
  beforeSection,
}: FolderModalProps) {
  const [folderDetails, setFolderDetails] = useImmer({
    name: name || '',
    color: color || Object.keys(folderColorTextMap)[0],
    isFolderNameValid: true,
  });

  const { mutateAsync: createFolder, isLoading } = useFolderUpsertMutation();

  const { data } = useMainNavQuery();

  const handleSubmission = useCallback(async () => {
    const folderName = folderDetails.name.trim() || 'Untitled';
    const body: Parameters<typeof createFolder>[0] = {
      name: folderName,
      color: folderDetails.color,
    };

    if (!isCreateMode && id) {
      body.folderId = id;
    }

    if (isCreateMode && data?.folders && data.folders.length > 0) {
      if (beforeSection === 'uncategorized') {
        body.beforeId = last(data?.folders)?.folderId;
      } else {
        body.afterId = beforeSection;
        const afterFolder =
          data.folders[
            data.folders.findIndex((x) => x.folderId === beforeSection) - 1
          ];

        if (afterFolder) {
          body.beforeId = afterFolder.folderId;
        }
      }
    }

    try {
      await createFolder(body);
      if (isCreateMode) {
        trackFolderActionEvent({
          action: 'folderCreated',
          folder: 'customFolder',
          customFolderName: folderName,
        });
      }

      showSuccessMessage(
        isCreateMode
          ? 'Folder created successfully'
          : 'Folder updated successfully',
      );
    } catch (error) {
      showErrorMessage(
        isCreateMode ? 'Error creating folder' : 'Error updating folder',
      );

      if (isCreateMode) {
        trackFolderErrorEvent({
          error: 'folderCreated',
          folder: 'customFolder',
          customFolderName: folderName,
        });
      }
    }

    onClose();
  }, [
    beforeSection,
    createFolder,
    data?.folders,
    folderDetails.color,
    folderDetails.name,
    id,
    isCreateMode,
    onClose,
  ]);

  const updateFolderName = useCallback(
    (e) =>
      setFolderDetails((draft) => {
        const folderName = e.target.value;

        if (folderName.length === 0) {
          draft.isFolderNameValid = true;
          draft.name = folderName;
          return;
        }

        const isFolderNameValid =
          folderName.length > 0 && folderName.length <= 40;

        draft.name = folderName;
        draft.isFolderNameValid = isFolderNameValid;
      }),
    [],
  );

  return (
    <Modal isOpen={true} handleClose={onClose}>
      <CloseButton
        icon="close-rounded"
        onClick={onClose}
        size={ButtonSize.Normal}
      />
      <Heading variant="h7">
        {isCreateMode ? 'Create Folder' : 'Edit Folder'}
      </Heading>
      <p>
        Folders make it easier for you to navigate Assembly by allowing you to
        group flows together.
      </p>
      <FormSection>
        <Label id="folder-name">
          <Body variant="body2Bold">Folder name</Body>
          <TextField
            autoFocus
            value={folderDetails.name}
            error={!folderDetails.isFolderNameValid}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                updateFolderName(e);
                handleSubmission();
              }
            }}
            onChange={updateFolderName}
            placeholder="Enter a name"
            endAdornment={
              !folderDetails.isFolderNameValid &&
              folderDetails.name.length !== 0 && (
                <InputAdornment position="end">
                  {40 - folderDetails.name.length}
                </InputAdornment>
              )
            }
          />
        </Label>
      </FormSection>
      <FormSection>
        <Label id="folder-color">
          <Body variant="body2Bold">Choose a color</Body>
          <FolderColorPicker
            selectedColor={folderDetails.color}
            onColorChange={(folderColor) =>
              setFolderDetails((draft) => {
                draft.color = folderColor;
              })
            }
          />
        </Label>
      </FormSection>

      <Button
        isFullWidth
        disabled={
          !folderDetails.isFolderNameValid || !folderDetails.color || isLoading
        }
        onClick={handleSubmission}
      >
        Save
      </Button>
    </Modal>
  );
}
