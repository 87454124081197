import React from 'react';
import { IconButtonProps, ButtonSize } from './interface';
import SVGIcon from '../SVGIcon';
import { IconButtonRoot } from './styles';

const IconButton = (props: IconButtonProps) => {
  const {
    disabled,
    size,
    icon,
    rounded,
    title,
    className,
    children,
    onClick,
    isSelected = false,
    iconBgColor,
    iconColor,
    dataTestId = '',
    dataQaId = '',
    buttonRef,
    color = 'text',
    rotate = 0,
    customColorConfig,
    variant,
    onBlur,
    onFocus,
    onPointerEnter,
    onPointerLeave,
  } = props;
  return (
    <IconButtonRoot
      className={className}
      disabled={disabled}
      size={size}
      rounded={rounded}
      onClick={onClick}
      isSelected={isSelected}
      iconBgColor={iconBgColor}
      aria-label={title}
      data-testid={dataTestId}
      data-qa-id={dataQaId}
      color={color}
      ref={buttonRef}
      icon={icon}
      customColorConfig={customColorConfig}
      variant={variant}
      onBlur={onBlur}
      onFocus={onFocus}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
    >
      {icon && (
        <SVGIcon
          icon={icon}
          size={size === ButtonSize.Small ? '14px' : '20px'}
          data-testid="SVGIcon"
          data-qa-id="icon-svg"
          color={iconColor}
          rotate={rotate}
        />
      )}
      {children}
    </IconButtonRoot>
  );
};

export default IconButton;
