import React from 'react';
import { makeStyles } from '@mui/styles';

import CircleIndicator from '../../atoms/CircleIndicator';
import Body from '../../atoms/Body';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  indicator: {
    position: 'absolute',
    top: '5px',
    left: '4px',
  },
  bodyText: {
    position: 'relative',
    paddingLeft: '24px',
  },
});

type ValidationTextProps = {
  checked?: boolean;
  children: React.ReactNode;
  className?: string;
};

const ValidationText = ({
  checked,
  children,
  className: parentClassName,
  ...rest
}: ValidationTextProps) => {
  const classes = useStyles();
  const className = `${classes.root} ${parentClassName}`;

  return (
    <div className={className} {...rest}>
      <CircleIndicator checked={checked} className={classes.indicator} />
      <Body variant="body2" className={classes.bodyText}>
        {children}
      </Body>
    </div>
  );
};

export default ValidationText;
