import { Collapse } from '@mui/material';
import styled from 'styled-components';
import Body from '../../atoms/Body';
import Clickable from '../../atoms/Clickable';
import SVGIcon from '../../atoms/SVGIcon';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  margin-bottom: 0px;
`;

export const StyledClickable = styled(Clickable)`
  width: 100%;
  padding: 12px 8px 12px 8px !important;
`;

export const SubItemRoot = styled.div`
  &:last-child {
    margin-bottom: 0px;
  }
  > div {
    padding: 0px 8px;
  }
`;

export const ItemTitle = styled(Body)`
  display: flex;
  align-items: center;
`;

export const ItemCaretSVGIcon = styled(SVGIcon)`
  margin-left: auto;
`;

export const SubAccordionInnerContent = styled.div`
  padding: 8px 24px;
`;

export const SettingsOption = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0px;
  margin-bottom: 0px;
  &:last-child {
    margin-bottom: 4px;
  }
`;

export const StyledBodyErrorMessage = styled(Body)`
  margin: 9px 12px 20px 12px;
`;

export const FlowItemCollapseWrapper = styled(Collapse)`
  padding: 0px 8px;
`;
