import { useLocation, useParams } from 'react-router-dom';

import { profileData } from '../../../Utils/home/feeds/dummyData';
import {
  LoadingParticipationTemplate,
  ParticipationTemplateError,
} from '../../../atomic/pages/ParticipationTemplate';
import { ParticipationTemplateErrorTypes } from '../../../atomic/pages/ParticipationTemplate/types';
import { APP_URL } from '../../../config';
import { ComponentStatus } from '../../../interfaces/component';
import {
  useCreateOneClickFlow,
  useGetFlowTemplate,
} from '../../../queries/Flows/Template';
import FlowsTemplatePreviewController from '../FlowsTemplatePreviewController';
import { useCallback } from 'react';
import history from '../../../history';
import { FLOWS_FEEDS } from '../../../constants/routes';
import { authStore } from '../../../stores/authStore';
import { useQueryClient } from '@tanstack/react-query';
import useModalsStore from '../../../stores/modalsStore';
import {
  GET_ACTIVE_FLOWS,
  GET_MAIN_NAV_CONTENTS,
  GET_MEMBER_NOTIFICATION_PREFERENCES,
} from '../../../constants/endpoints';
import { showErrorMessage } from '../../../Utils/toast';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';

const FlowsPreviewController = () => {
  const queryClient = useQueryClient();
  const { folderId } = useModalsStore();
  const { isCreatingFlowForTheFirstTime } = authStore();
  const { templateId } = useParams<{ templateId: string }>();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const isComingFromTemplateGallery = params.get('from') === 'templateGallery';

  const {
    isError,
    data: flowTemplateData,
    isInitialLoading: isFlowTemplateLoading,
  } = useGetFlowTemplate(templateId);

  const { mutate: createOneClickFlow } = useCreateOneClickFlow();

  const { treatment: flowCreationV3 } = useFeatureSplit(
    SplitNames.FlowCreation,
  );

  const isFlowCreation = flowCreationV3 === TreatmentTypes.ON;

  const handleOnUseTemplateClick = () => {
    const { searchParams } = new URL(window.location.href);
    const url = new URL(`${APP_URL}flows/editor?template=${templateId}`);
    searchParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        url.searchParams.append(key, value);
      }
    });

    window.open(url.toString(), '_blank', 'noopener, noreferrer');
  };

  const onUseTemplateClick = useCallback(() => {
    if (isFlowCreation) {
      window.parent.postMessage(
        { type: 'SELECTED_TEMPLATE', payload: { templateId } },
        '*',
      );
      return;
    }

    createOneClickFlow(
      { templateId, folderId },
      {
        onSuccess: async (res) => {
          history.push(
            FLOWS_FEEDS.replace(
              ':flowId',
              isCreatingFlowForTheFirstTime
                ? `${res.data.flowId}?flow-created-from-template=true`
                : `${res.data.flowId}?flow-created-from-template=true&show-share-sheet=true`,
            ),
          );
          await queryClient.invalidateQueries([GET_ACTIVE_FLOWS]);
          await queryClient.invalidateQueries([GET_MAIN_NAV_CONTENTS]);
          await queryClient.invalidateQueries([
            GET_MEMBER_NOTIFICATION_PREFERENCES,
          ]);
        },
        onError: () => {
          showErrorMessage(
            'Whoops! We were unable to create flow from this template, please try again',
          );
        },
      },
    );
  }, [
    isFlowCreation,
    createOneClickFlow,
    templateId,
    folderId,
    isCreatingFlowForTheFirstTime,
    queryClient,
  ]);

  if (!isFlowTemplateLoading && isError) {
    return (
      <ParticipationTemplateError
        flowId=""
        isFullScreen
        member={profileData.member}
        headerStatus={ComponentStatus.LOADED}
        flowHeaderContent={{
          TitleContent: <></>,
          DescriptionContent: '',
        }}
        templateError={ParticipationTemplateErrorTypes.INVALID_FLOW_ID}
      />
    );
  }

  if (!isFlowTemplateLoading && flowTemplateData) {
    if (isComingFromTemplateGallery) {
      return (
        <FlowsTemplatePreviewController
          isFullScreen
          profileInfo={profileData}
          showCloseModalOption={false}
          flowTemplateData={flowTemplateData}
          onUseTemplateClick={onUseTemplateClick}
          closePreviewTemplateModal={() => {
            window.parent.postMessage(
              {
                type: 'GO_TO_TEMPLATES',
                payload: `/templates`,
              },
              '*',
            );
          }}
        />
      );
    } else {
      return (
        <FlowsTemplatePreviewController
          isFullScreen
          profileInfo={profileData}
          showCloseModalOption={false}
          flowTemplateData={flowTemplateData}
          onUseTemplateClick={handleOnUseTemplateClick}
        />
      );
    }
  }

  return (
    <LoadingParticipationTemplate
      flowId=""
      isFullScreen
      showCloseOption={false}
      member={profileData.member}
      headerStatus={ComponentStatus.LOADING}
      flowHeaderContent={{ DescriptionContent: '', TitleContent: '' }}
    />
  );
};

export default FlowsPreviewController;
