import React from 'react';

import Body from '../../atoms/Body';
import { CurrentUser, Member } from '../../../interfaces/Leaderboard';
import Leaderboard from '../../molecules/Leaderboard/index';
import LeaderboardList from '../../molecules/LeaderboardList/index';
import LeaderboardListLoader from '../../molecules/LeaderboardList/Loader';
import {
  MainFeedDrawerErrorWrapper,
  MainFeedDrawerWrapper,
  StyledImage,
  StyledDownloadButton,
  StyledDummyLeaderboardWrapper,
} from './styles';
import RightDrawerErrorImage from '../../../img/home/right-drawer-error.svg';
import { SOMETHING_WENT_WRONG, TRY_AGAIN } from '../../../languages/en/home';
import { SelectedMember } from '../../../interfaces/Layout';
import DummyLeaderboard from '../../molecules/DummyLeaderBoard';
import LeaderboardNoActivity from '../../molecules/LeaderboardNoActivity';

import MainFeedRightDrawerController from '../../../controllers/rightDrawer/MainFeedRightDrawerController';
import { DOWNLOAD_ALL_RESPONSES } from '../../../languages/en/flows';
import useLayoutStore from '../../../stores/layoutStore';

export interface MainFeedDrawerProps {
  currentMember?: CurrentUser;
  hasFullError: boolean;
  hasMoreLeaderboardMembers?: boolean;
  hasLeaderboardError: boolean;
  isLeaderboardLoading: boolean;
  leaderboardMembers?: Member[];
  toggleRightAsideOpen: () => void;
  onCategoryChange: (category: string) => void;
  onTimeFrameChange: (category: string) => void;
  selectedCategory: string;
  selectedTimeFrame: string;
  setSelectedMemberProfile: (selectedMember: SelectedMember) => void;
  showLegacySidebar?: boolean;
  canShowRightDrawer?: boolean;
  showDownloadOption?: boolean;
  disableDownloadOption?: boolean;
  onDownloadClick?: () => void;
}

const MainFeedDrawer = ({
  currentMember,
  hasFullError = false,
  isLeaderboardLoading,
  leaderboardMembers = [],
  toggleRightAsideOpen,
  onCategoryChange,
  onTimeFrameChange,
  selectedCategory,
  selectedTimeFrame,
  setSelectedMemberProfile,
  onDownloadClick,
  showLegacySidebar = false,
  canShowRightDrawer = false,
  showDownloadOption = false,
  disableDownloadOption = false,
}: MainFeedDrawerProps) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  if (hasFullError) {
    return (
      <MainFeedDrawerErrorWrapper>
        <StyledImage alt={SOMETHING_WENT_WRONG} src={RightDrawerErrorImage} />
        <Body variant="body1Bold">{SOMETHING_WENT_WRONG}</Body>
        <Body variant="body2">{TRY_AGAIN}</Body>
      </MainFeedDrawerErrorWrapper>
    );
  }

  if (!showLegacySidebar) {
    if (canShowRightDrawer) {
      return <></>;
    }

    return <MainFeedRightDrawerController />;
  }

  return (
    <MainFeedDrawerWrapper isEmbeddedInMainApp={isEmbeddedInMainApp}>
      {showDownloadOption && (
        <StyledDownloadButton
          color="secondary"
          onClick={onDownloadClick}
          icon="download"
          disabled={disableDownloadOption}
          isFullWidth
        >
          {DOWNLOAD_ALL_RESPONSES}
        </StyledDownloadButton>
      )}
      <Leaderboard
        selectedCategory={selectedCategory}
        selectedTimeFrame={selectedTimeFrame}
        onCategoryChange={onCategoryChange}
        onTimeFrameChange={onTimeFrameChange}
        toggleRightAsideOpen={toggleRightAsideOpen}
      >
        {isLeaderboardLoading && <LeaderboardListLoader />}
        {currentMember && leaderboardMembers && (
          <div>
            <LeaderboardList
              currentMember={currentMember}
              members={leaderboardMembers}
              setSelectedMemberProfile={setSelectedMemberProfile}
            />
            {currentMember.total <= 1 && leaderboardMembers.length === 0 && (
              <StyledDummyLeaderboardWrapper>
                <DummyLeaderboard />
              </StyledDummyLeaderboardWrapper>
            )}
            {currentMember.total >= 5 && leaderboardMembers.length === 0 && (
              <StyledDummyLeaderboardWrapper>
                <LeaderboardNoActivity />
              </StyledDummyLeaderboardWrapper>
            )}
          </div>
        )}
      </Leaderboard>
    </MainFeedDrawerWrapper>
  );
};

const MemoizedMainFeedDrawer = React.memo(MainFeedDrawer);
MemoizedMainFeedDrawer.displayName = 'MainFeedDrawer';
export default MemoizedMainFeedDrawer;
