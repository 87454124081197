import qs from 'qs';
import React from 'react';
import { useLocation } from 'react-router-dom';

import AssemblyMembersController from '../AssemblyMembersController';
import { NotebookTaskDetailsController } from '../../rightDrawer/NotebookTaskDetailsController';

export const FlowsPostDrawerController = () => {
  const location = useLocation();

  const shouldShowTask =
    'taskId' in qs.parse(location.search, { ignoreQueryPrefix: true });

  if (shouldShowTask) {
    return <NotebookTaskDetailsController />;
  }

  return <AssemblyMembersController />;
};
