import { RefObject } from 'react';
import styled from 'styled-components';
import TemplateGalleryLoader from './TemplateGalleryLoader';

type TemplateGalleryFrameProps = {
  src: string;
  isLoading: boolean;
  isMobileApp: boolean;
  iFrameRef?: RefObject<HTMLIFrameElement> | null;
};

const StyledFrame = styled.iframe<{ $isLoading: boolean }>`
  width: 100%;
  height: calc(100% - 72px);
  max-height: calc(100vh - 16px);
  display: ${({ $isLoading }) => $isLoading && 'none'};
`;

const TemplateGalleryFrame = (props: TemplateGalleryFrameProps) => {
  const { src, isLoading, isMobileApp, iFrameRef } = props;

  return (
    <>
      {isLoading && <TemplateGalleryLoader isMobileApp={isMobileApp} />}
      <StyledFrame
        src={src}
        frameBorder={0}
        $isLoading={isLoading}
        title="templateGalleryFrame"
        id="templateGalleryFrame"
        ref={iFrameRef}
      />
    </>
  );
};

export default TemplateGalleryFrame;
