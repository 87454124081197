import { CreateChatRequest } from '../../interfaces/Chats';
import { MemberState } from '../../interfaces/user';

export const mapChatRequestPayloadToChat = (payload: CreateChatRequest) => {
  return {
    createdAt: new Date().toString(),
    updatedAt: new Date().toString(),
    kind: '',
    gifURL: payload.gifUrl || '',
    postID: '',
    unread: false,
    message: payload.message || '',
    imageURL: '',
    commentID: 'comment_id',
    fromMember: payload.fromMember,
    pointsEach: 0,
    isDeleted: false,
    toMemberId: payload.memberId,
    reactions: [],
    taggedUsers:
      payload.selectedMentions?.map((member) => ({
        memberID: member.id,
        firstName: member.name,
        lastName: '',
        memberState: MemberState.ACTIVE,
        name: member.name,
      })) || [],
  };
};
