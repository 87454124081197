import styled from 'styled-components';

import IconButton from '../../atoms/IconButton_V2';
import ThemeV2 from '../../../theme';

const doraButtonStyles = `
  background-size: 300% 100%;
  border-radius: 8px;
  height: 40px;
  margin: 8px 0px 8px 8px;
  width: 40px;
`;

const doraIconStyles = `
  & span svg path {
    fill: transparent;
    stroke: ${ThemeV2.palette.gray1};
    stroke-width: 1.5;
  }
`;

const premiumIconStyles = `
  & span svg path {
    fill: ${ThemeV2.palette.gold6};
    stroke: ${ThemeV2.palette.gray1};
  }
`;

export const gradient = `linear-gradient(
  to right,
  #1d3fc8,
  ${ThemeV2.palette.purple7},
  #a309ca,
  #a309ca,
  #1d3fc8,
  ${ThemeV2.palette.purple7}
)`;

export const NotUpgradedPlanButton = styled(IconButton)`
  ${doraButtonStyles}
  ${doraIconStyles}

  &:hover {
    ${premiumIconStyles}
  }

  &:disabled {
    ${doraIconStyles}
    background: ${gradient};
    background-size: 300% 100%;

    &:hover {
      ${premiumIconStyles}
      background: ${ThemeV2.palette.gold6};
    }
  }
`;

export const TooltipParagraph = styled.p`
  margin: 0;
`;

export const TooltipWrapper = styled.div`
  text-align: center;
`;

export const UpgradeTextLink = styled.button`
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: ${ThemeV2.palette.gray1};
  font-size: 12px;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const UpgradedPlanButton = styled(IconButton)`
  ${doraButtonStyles}
  ${doraIconStyles}
  transition: all 0.4s ease-in-out;

  &:disabled span svg path {
    fill: ${ThemeV2.palette.gray1};
    stroke: ${ThemeV2.palette.gray7};
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    ${doraIconStyles}
    background-position: 100% 0;
  }
`;
