import { BaseEmoji } from 'emoji-mart/dist-es';
import { create } from 'zustand';
import { MenuItemIndividualItem } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { AutocompleteDropdownItem } from '../../atomic/organism/Autocomplete/interfaces';
import { anonymousSettingsOptions } from '../../controllers/flows/FlowsShareSheetController/ShareSheetModalController/data';
import {
  FlowBuilderBlockData,
  FlowBuilderState,
  ScheduleRule,
  TriggerType,
} from '../../interfaces/Flow/Builder';
import { IMemberDTO } from '../../interfaces/member';
import { EVERYONE } from '../../languages/en/singleWords';

export const createBlockDataObject = (): FlowBuilderBlockData => ({
  ACTION: null,
  TRIGGER: {
    triggerType: undefined,
    endTimeInMinutes: 1440, // this is to make sure the deadline doesn't meet the next occurrence
    shortcut: false,
    schedule: undefined,
    selectedCustomRecurrenceTypes: undefined,
    isSchedulerTouched: false,
    errors: [],
  },
  PARTICIPANTS: {
    participantsCriteria: undefined,
    errors: null,
  },
  VISIBILITY: {
    type: EVERYONE.toUpperCase(),
    everyone: true,
    onlyParticipants: false,
    onlyOwnersAndCollaborators: false,
    custom: false,
  },
  CONTENT: {
    contentBlocks: [],
    errors: null,
  },
});

const useFlowBuilderStore = create<FlowBuilderState>()((set) => ({
  flowId: null,
  blockData: createBlockDataObject(),
  isFlowDataChangeConfirmed: false,
  showDataChangeConfirm: false,
  description: '',
  emoji: null,
  owner: [],
  collaborators: [],
  flowName: '',
  templateId: '',
  templateName: '',
  isInEditMode: false,
  anonymousSettings: anonymousSettingsOptions[0],
  currentSchedule: undefined,
  disableNavigationModal: false,
  templateType: undefined,
  isInviteNewTeammatesModalOpen: false,
  pendingEmails: [],
  newParticipantsToInvite: [],
  newVisibilityMembersToInvite: [],
  newContentMembersToInvite: [],
  isShareSheetModalOpen: false,
  isConnectionsTabTouched: false,
  setFlowId: (flowId: string) => set(() => ({ flowId })),
  setDescription: (updatedDescription: string) =>
    set(() => ({ description: updatedDescription })),
  setFlowName: (updatedName: string) => set(() => ({ flowName: updatedName })),
  setIsInEditMode: (updatedIsInEditMode: boolean) =>
    set(() => ({ isInEditMode: updatedIsInEditMode })),
  setTemplateId: (updatedTemplateId: string) =>
    set(() => ({ templateId: updatedTemplateId })),
  setTemplateName: (updatedTemplateName: string) =>
    set(() => ({ templateName: updatedTemplateName })),
  setEmoji: (selectedEmoji: BaseEmoji) => set(() => ({ emoji: selectedEmoji })),
  setIsFlowDataChangeConfirmed: (updatedIsFlowDataChangeConfirmed: boolean) =>
    set(() => ({
      isFlowDataChangeConfirmed: updatedIsFlowDataChangeConfirmed,
    })),
  setShowDataChangeConfirm: (updatedShowDataChangeConfirm: boolean) =>
    set(() => ({
      showDataChangeConfirm: updatedShowDataChangeConfirm,
    })),
  revertBlockDataToDefaultState: () =>
    set(() => ({
      emoji: null,
      flowName: '',
      description: '',
      isInEditMode: false,
      showDataChangeConfirm: false,
      isConnectionsTabTouched: false,
      isFlowDataChangeConfirmed: false,
      blockData: createBlockDataObject(),
    })),
  setOwner: (updatedOwner: AutocompleteDropdownItem<string, IMemberDTO>[]) =>
    set(() => ({ owner: updatedOwner })),
  setCollaborators: (
    updatedCollaborators: AutocompleteDropdownItem<string, IMemberDTO>[],
  ) => set(() => ({ collaborators: updatedCollaborators })),
  setSpecificBlockData: <Key extends keyof FlowBuilderBlockData>(
    blockKey: Key,
    updatedBlockData: FlowBuilderBlockData[Key],
  ) =>
    set(({ blockData }) => ({
      blockData: {
        ...blockData,
        [blockKey]: updatedBlockData,
      },
    })),
  setCurrentSchedule: (updatedCurrentSchedule: ScheduleRule | undefined) =>
    set(() => ({ currentSchedule: updatedCurrentSchedule })),
  setDisableNavigationModal: (disable: boolean) =>
    set(() => ({ disableNavigationModal: disable })),
  setTemplateType: (updatedTemplateType: TriggerType | undefined) =>
    set(() => ({ templateType: updatedTemplateType })),
  setIsInviteNewTeammatesModalOpen: (
    updateIsInviteNewTeammatesModalOpen: boolean,
  ) =>
    set(() => ({
      isInviteNewTeammatesModalOpen: updateIsInviteNewTeammatesModalOpen,
    })),
  setPendingEmails: (updatedPendingEmails: string[]) =>
    set(() => ({ pendingEmails: updatedPendingEmails })),
  setNewParticipantsToInvite: (updatedNewMembers: string[]) =>
    set(() => ({ newParticipantsToInvite: updatedNewMembers })),
  setNewVisibilityMembersToInvite: (updatedNewMembers: string[]) =>
    set(() => ({ newVisibilityMembersToInvite: updatedNewMembers })),
  setNewContentMembersToInvite: (updatedNewMembers: string[]) =>
    set(() => ({ newContentMembersToInvite: updatedNewMembers })),
  setShareSheetModalOpen: (updateIsShareSheetModalOpen: boolean) =>
    set(() => ({ isShareSheetModalOpen: updateIsShareSheetModalOpen })),
  setIsConnectionsTabTouched: (value: boolean) =>
    set(() => ({ isConnectionsTabTouched: value })),
  setAnonymousSettings: (updatedAnonymousSettings: MenuItemIndividualItem) =>
    set(() => ({ anonymousSettings: updatedAnonymousSettings })),
}));

export default useFlowBuilderStore;
