import mobileStore from './stores/mobileAppStore';

const PLATFORM = 'web';

const SISENSE_CONFIG = {
  SERVER: 'https://assembly.sisense.com',
  DASHBOARD: {
    GENERAL_SUMMARY_STATS: '61f075fe86aef30035050665',
    STATS_OVERTIME_WITH_OCCURRENCES: '61f0769fca42150035f8c677',
    STATS_OVERTIME_WITHOUT_OCCURRENCES: '61f07c2786aef30035050675',
    OPEN_ENDED_DASHBOARD: '6193acd9ca42150035f8bf9b',
    GIF_DASHBOARD: '6193acc486aef3003504fec5',
    FILE_UPLOAD_DASHBOARD: '6193acb186aef3003504feaf',
    SCALE_DASHBOARD: '6193aceaca42150035f8bfa1',
    NPS_DASHBOARD: '63d16676a852d10033e236df',
    DROPDOWN_DASHBOARD: '6193accfca42150035f8bf96',
    MULTI_SELECT_DASHBOARD: '6193accfca42150035f8bf96',
    MULTI_SELECT_DASHBOARD_OTHERS: '6193fe7b86aef3003504ff2d',
    PERSON_SELECTOR_SINGLE_DASHBOARD: '6193fbcaca42150035f8bff6',
    PERSON_SELECTOR_MULTI_DASHBOARD: '6193ace186aef3003504fecd',
    GIVE_POINTS_DASHBOARD: '61b2788bca42150035f8c1b7',
    TRENDS_STATS: '61945b87ca42150035f8c06d',
    FILE_UPLOAD_TRENDS: '6195a49eca42150035f8c0f2',
    GIF_TRENDS: '6195a37fca42150035f8c0e9',
    SCALE_TRENDS: '619599fc86aef3003504ffce',
    NPS_TRENDS: '63d2cd41a852d10033e236fb',
    GIVE_POINTS_TRENDS: '6195a2b8ca42150035f8c0e3',
    MULTI_SELECT_TRENDS: '6195970986aef3003504ffbc',
    OPEN_ENDED_TRENDS: '61958b1486aef3003504ff97',
    PERSON_SELECTOR_SINGLE_TRENDS: '61959e17ca42150035f8c0c1',
    PERSON_SELECTOR_MULTIPLE_TRENDS: '61959f0e86aef3003504ffeb',
    SINGLE_SELECT_TRENDS: '6195927486aef3003504ffa8',
  },
  WIDGETS: {
    UNIQUE_RESPONDANTS_PER_WEEK_NO_OCCURRANCE: '61f07c2786aef30035050677',
    PERCENTAGE_BLOCK_COMPLETED_NO_OCCURRANCE: '61f07c2786aef30035050678',
    COMPLETION_RATE_BY_WEEK: '61f0769fca42150035f8c678',
    TOTAL_RESPONSES_BY_WEEK: '61f07aaeca42150035f8c683',
    PERCENTAGE_OF_BLOCKS_COMPLETED_OVERTIME: '61f07b4bca42150035f8c685',
    STARTS: '624f659d2756a200394c5815',
    TOTAL_RESPONSES: '61f075fe86aef30035050666',
    PARTICIPATION_RATE_DASHBOARD: '61f0851b86aef30035050689',
    PERSON_RESPONSES_STAT: '61f0851b86aef30035050689',
    UNIQUE_RESPONDANTS: '61e1eab586aef30035050594',
    OCCURRENCES_PARTICIPATION_RATE: '61e1eab586aef30035050595',
    PARTICIPATION_STATS: '61f07faa86aef3003505067e',
    UNIQUE_FLOWS_PARTICIPANTS: '61f09851ca42150035f8c711',
    CURRENT_PARTICIPANTS: '6193acb486aef3003504feb5',
    COMPLETION_RATE: '624f66d22756a200394c5819',
    NUMBER_OF_OCCURANCES: '6193acb486aef3003504feb9',
    ANSWER_ANYTIME_PARTICIPATION_RATE: '6193acb486aef3003504feb7',
    ANSWER_ANYTIME_RESPONSE_PER_PERSON: '6193acb486aef3003504feb8',
    BLOCKS_COMPLETED: '6193acb486aef3003504feba',
    TOTAL_PARTICIPATION: '6193acb486aef3003504febb',
    OPEN_ENDED: '6193acd9ca42150035f8bf9c',
    GIF: '6193acc486aef3003504fec6',
    FILE_UPLOAD: '6193acb186aef3003504feb0',
    SCALE: '6193aceaca42150035f8bfa2',
    NPS_SCORE: '63d2cc4aa852d10033e236f3',
    NPS_STATS: '63d2d057a852d10033e23708',
    NPS_COUNT_AND_PERCENTAGE: '63d16676a852d10033e236e0',
    DROPDOWN: '6193accfca42150035f8bf97',
    MULTI_SELECT: '6193accfca42150035f8bf97',
    MULTI_SELECT_OTHERS: '6193fe7b86aef3003504ff2e',
    MULTI_SELECT_OTHERS_VALUE: '619401eeca42150035f8c014',
    PERSON_SELECTOR_SINGLE: '6193fbcaca42150035f8bff7',
    GIVE_POINTS: '61b2788bca42150035f8c1b8',
    PERSON_SELECTOR_MULTI: '6193ace186aef3003504fece',
    TRENDS_STATS: '619461db86aef3003504ff86',
    FILE_UPLOAD_TRENDS: '6195a49eca42150035f8c0f3',
    GIF_TRENDS: '6195a39786aef3003504fff8',
    SCALE_TRENDS: '61959a7fca42150035f8c0b6',
    NPS_SCORE_TRENDS: '63d2cd41a852d10033e236fc',
    NPS_STATS_TRENDS: '63efd8289eb0cb0034d73e2f',
    NPS_AVERAGE_TRENDS: '63efd9409eb0cb0034d73e35',
    GIVE_POINTS_TRENDS: '6195a32286aef3003504fff2',
    MULTI_SELECT_TRENDS: '61959811ca42150035f8c0af',
    OPEN_ENDED_TRENDS: '6195917c86aef3003504ff9e',
    PERSON_SELECTOR_SINGLE_TRENDS: '61959eddca42150035f8c0dc',
    PERSON_SELECTOR_MULTIPLE_TRENDS: '61959fb186aef3003504ffee',
    SINGLE_SELECT_TRENDS: '619592ddca42150035f8c092',
  },
};

const config = {
  API_URL: import.meta.env.VITE_API_URL,
  IP_STACK_ACCESS_TOKEN: 'fb377cf9b19ecf35b1358686fdc8a54f',
  MOBILE_APP_URL: 'com.joinassembly.assemblymobile://',
  STRIPE_PUBLISHABLE: import.meta.env.VITE_STRIPE_PUBLISHABLE,
  GIPHY_KEY: 'jAE9Ya0RlClP2vSDqo8umIhz14JYKwy4',
  APP_URL: `${import.meta.env.VITE_APP_URL}/`,
  ADMIN_APP_URL: import.meta.env.VITE_ADMIN_APP_URL,
  NEW_APP_URL: import.meta.env.VITE_NEW_APP_URL,
  SOURCE: 'API',
  SPLIT_AUTH_KEY: import.meta.env.VITE_SPLIT_AUTH_KEY,
  MS_TEAMS: {
    ENTITY_ID: 'joinAssembly-tab',
    NAME: 'Assembly',
  },
  SLACK: {
    REDIRECT_PATH: `slack-redirect`,
    CLIENT_ID: import.meta.env.VITE_SLACK_CLIENT_ID,
  },
  PUSHER_KEY: import.meta.env.VITE_PUSHER_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
  DATA_DOG: {
    applicationId: import.meta.env.VITE_DATA_DOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATA_DOG_CLIENT_TOKEN,
    env: import.meta.env.VITE_DATA_DOG_ENV,
    service: 'assembly_frontend',
  },
  ADP_MARKETPLACE_URL: import.meta.env.VITE_ADP_MARKETPLACE_URL,
  MIXPANEL_KEY: import.meta.env.VITE_MIXPANEL_KEY,
};

const PLAN_IDS = {
  tier1: 'free',
  tier2: 'lite',
  tier3: 'standard',
  tier4: 'premium',
};

const { API_URL } = config;

const API_URL_WITH_V2 = `${API_URL}/api/v2`;
const API_URL_WITH_V3 = `${API_URL}/api/v3`;
const {
  STRIPE_PUBLISHABLE,
  SLACK,
  GIPHY_KEY,
  APP_URL,
  ADMIN_APP_URL,
  SOURCE,
  SPLIT_AUTH_KEY,
  MS_TEAMS,
  ADP_MARKETPLACE_URL,
  MIXPANEL_KEY,
  DATA_DOG,
  PUSHER_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY,
  NEW_APP_URL,
  MOBILE_APP_URL,
} = config;

export const GOOGLE_RECAPTCHA_ENDPOINT = `https://www.google.com/recaptcha/enterprise.js`;

export type Tiers = 'tier1' | 'tier2' | 'tier3' | 'tier4';

const GET_PLATFORM = (isFromMSTeams: boolean) => {
  if (isFromMSTeams) {
    return 'MS Teams';
  }

  if (mobileStore.getState().isMobileApp) {
    return 'mobile_app';
  }

  return PLATFORM;
};

const GO_TO_AXOMO_LINK =
  'https://shop.axomo.com/store-generator?referralCode=ASSEMBLY';
const AXOMO_URL = 'https://axomo.com';
const TEMPLATE_GALLERY_FRAME_URL = `${NEW_APP_URL}/templates`;
const ASSEMBLY_APP_STORE_URL =
  'https://apps.apple.com/in/app/assembly-your-digital-hq/id6443841178';
const ASSEMBLY_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.joinassembly.assemblymobile';
const ASSEMBLY_V3_FEEDBACK_SURVEY_URL =
  'https://app.joinassembly.com/e/flows/6478a909ec762bd84a6b18c1';

export {
  ADMIN_APP_URL,
  ADP_MARKETPLACE_URL,
  API_URL,
  API_URL_WITH_V2,
  API_URL_WITH_V3,
  APP_URL,
  ASSEMBLY_APP_STORE_URL,
  ASSEMBLY_PLAY_STORE_URL,
  AXOMO_URL,
  DATA_DOG,
  GET_PLATFORM,
  GIPHY_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY,
  GO_TO_AXOMO_LINK,
  MIXPANEL_KEY,
  MOBILE_APP_URL,
  MS_TEAMS,
  NEW_APP_URL,
  PLAN_IDS,
  PUSHER_KEY,
  SISENSE_CONFIG,
  SLACK,
  SOURCE,
  SPLIT_AUTH_KEY,
  STRIPE_PUBLISHABLE,
  TEMPLATE_GALLERY_FRAME_URL,
  ASSEMBLY_V3_FEEDBACK_SURVEY_URL,
};
