import ThemeV2 from '../../../theme';
import { StyledButton } from '../../../Utils/styles/button';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import ProgressBar from '../../atoms/ProgressBar';
import SVGIcon from '../../atoms/SVGIcon';

import {
  File,
  FileCardProps,
  FileCardWrapperProps,
  FileUploadStatus,
  LegacyFileType,
} from './types';

import { APP_URL } from '../../../config';
import { V3_POST_PAGE } from '../../../constants/routes';

import { ParticipationDocumentThumbnail } from './ParticipationDocumentThumbnail';
import { ParticipationImageThumbnail } from './ParticipationImageThumbnail';
import { ResponseDocumentThumbnail } from './ResponseDocumentNewThumbnail';
import { ResponseErrorDocumentThumbnail } from './ResponseErrorDocumentThumbnail';
import { ResponseImageThumbnail } from './ResponseImageThumbnail';
import {
  ErrorCardFileName,
  StyledFileCard,
  StyledFileCardCloseIcon,
  StyledFileCardFooter,
  StyledFileCardHeader,
  StyledFileCardIconContainer,
  StyledFileCardMain,
  StyledLoaderContainer,
  StyledTextWithIcon,
} from './styles';
import { getThumbnailTokens } from './utils';
import { copyToClipboard } from '../../../Utils/common';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';
import useLayoutStore from '../../../stores/layoutStore';

const FileCardWrapper = (props: FileCardWrapperProps) => {
  const { children, onMouseEnter, onMouseLeave, onClick, isParticipation } =
    props;
  return (
    <StyledFileCard
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      isParticipation={isParticipation}
      data-testid="file-card-wrapper"
    >
      <StyledFileCardMain>{children}</StyledFileCardMain>
    </StyledFileCard>
  );
};

const closeButtonProps = {
  icon: 'close-rounded',
  size: ButtonSize.Small,
  iconColor: ThemeV2.palette.gray8,
  dataTestId: 'close-button',
  dataQaId: 'btn-close',
};

export default function FileCard(props: FileCardProps) {
  const {
    file,
    flowId,
    responseId,
    status,
    onClickClose,
    onClickRetry,
    onClickDownload,
    onClickPreview,
    loadingProgress = 0,
    isParticipation = true,
    dataTestId = 'file-card',
    isNewThumbnailExperience,
  } = props;

  const { cardColor, cardIcon, cardIconColor } = getThumbnailTokens({
    fileName: file?.name ?? '',
    hasError: file?.size === 0,
  });

  const feedCardWidth = '132px';
  const flowCardWidth = '176px';

  const openPreviewModal = (fileToPreview: File) => {
    if (onClickPreview && fileToPreview) {
      onClickPreview(fileToPreview);
    }
  };

  const { slugUrl } = useMultiAssemblySlug();
  const isEmbeddedInMainApp = useLayoutStore(
    (state) => state.isEmbeddedInMainApp,
  );
  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link') {
      const url = `${APP_URL.slice(0, -1)}/${
        isEmbeddedInMainApp ? `a/${slugUrl}` : slugUrl
      }${V3_POST_PAGE}`.replace(
        ':postType',
        `flows?flowId=${flowId}&responseId=${responseId}`,
      );
      copyToClipboard(url);
    } else if (value === 'download-file' && file) {
      onClickDownload?.(file);
    }
  };

  const getImageUrl = (cardFile: File) => {
    if (cardFile.thumbnails) {
      return cardFile.thumbnails['360'];
    } else {
      return cardFile.url || '';
    }
  };

  switch (status) {
    case FileUploadStatus.Loading:
      if (file && onClickClose) {
        return (
          <FileCardWrapper isParticipation={isParticipation}>
            <StyledFileCardHeader>
              <StyledFileCardCloseIcon
                onClick={() => onClickClose(file.name)}
                {...closeButtonProps}
                dataTestId="file-card-remove-button"
              />
            </StyledFileCardHeader>
            <StyledLoaderContainer>
              <ProgressBar
                height="8px"
                color="flowProgress"
                value={loadingProgress}
              />
            </StyledLoaderContainer>
          </FileCardWrapper>
        );
      }
      return null;

    case FileUploadStatus.Success:
      if (file?.type === LegacyFileType.Image) {
        const cardImageUrl = getImageUrl(file);
        return isParticipation ? (
          <ParticipationImageThumbnail
            file={file}
            cardColor={cardColor}
            cardIcon={cardIcon}
            cardImageUrl={cardImageUrl}
            cardWidth={flowCardWidth}
            isNewThumbnailExperience={isNewThumbnailExperience}
            dataTestId={dataTestId}
            onClickClose={onClickClose}
          />
        ) : (
          <ResponseImageThumbnail
            file={file}
            cardColor={cardColor}
            cardIcon={cardIcon}
            cardImageUrl={cardImageUrl}
            cardWidth={feedCardWidth}
            dataTestId={dataTestId}
            openPreviewModal={openPreviewModal}
            onOptionsSelect={onOptionsSelect}
          />
        );
      }

      if (file?.type === LegacyFileType.File) {
        const cardImageUrl = getImageUrl(file);

        return isParticipation ? (
          <ParticipationDocumentThumbnail
            file={file}
            cardColor={cardColor}
            cardIcon={cardIcon}
            cardImageUrl={cardImageUrl}
            cardWidth={flowCardWidth}
            isNewThumbnailExperience={isNewThumbnailExperience}
            dataTestId={dataTestId}
            onClickClose={onClickClose}
          />
        ) : (
          <ResponseDocumentThumbnail
            file={file}
            cardColor={cardColor}
            cardIcon={cardIcon}
            cardWidth={feedCardWidth}
            cardImageUrl={cardImageUrl}
            dataTestId={dataTestId}
            openPreviewModal={openPreviewModal}
            onOptionsSelect={onOptionsSelect}
          />
        );
      }
      return null;

    case FileUploadStatus.Error:
      if (file) {
        return (file?.size || 0) > 0 ? (
          <FileCardWrapper isParticipation={isParticipation}>
            <StyledFileCardHeader>
              {onClickClose && (
                <StyledFileCardCloseIcon
                  onClick={() => onClickClose(file.name)}
                  {...closeButtonProps}
                />
              )}
            </StyledFileCardHeader>
            <StyledFileCardIconContainer>
              <SVGIcon
                size="48px"
                icon="warning-hollow"
                color={ThemeV2.palette.dustRed6}
              />
            </StyledFileCardIconContainer>
            <StyledFileCardFooter>
              <ErrorCardFileName variant="body3Medium" color="gray9">
                {file.name}
              </ErrorCardFileName>
              {onClickRetry && (
                <StyledButton onClick={() => onClickRetry(file.name)}>
                  <StyledTextWithIcon
                    color="dustRed6"
                    icon="retry"
                    iconSize="12px"
                    iconColor={ThemeV2.palette.dustRed6}
                  >
                    Try again
                  </StyledTextWithIcon>
                </StyledButton>
              )}
            </StyledFileCardFooter>
          </FileCardWrapper>
        ) : (
          <ResponseErrorDocumentThumbnail
            file={file}
            cardColor={cardColor}
            cardIcon={cardIcon}
            cardIconColor={cardIconColor}
            cardWidth={feedCardWidth}
            dataTestId={dataTestId}
            openPreviewModal={openPreviewModal}
            onOptionsSelect={onOptionsSelect}
          />
        );
      }
      return null;

    default:
      return null;
  }
}
