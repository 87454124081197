import { useState, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useMultipleJobsStatusQuery } from '../../queries/Polling';
import {
  MakeAPICallInterface,
  PreJobResponseInterface,
  useGenericAPIQuery,
} from '../../queries/Settings/identityManager';
import configureAssemblyStatus from '../../Utils/identity/configureAssemblyStatus';

interface UseMultipleJobsPollingV2Props {
  preJobsAPI: MakeAPICallInterface;
  postJobsAPI?: MakeAPICallInterface;
  enabled?: boolean;
  preJobResponse?: PreJobResponseInterface;
}

function useMultipleJobsPollingV2<T>(props: UseMultipleJobsPollingV2Props) {
  const queryClient = useQueryClient();
  const { preJobsAPI, postJobsAPI, enabled = false, preJobResponse } = props;
  const [jobIds, setJobIds] = useState<string[] | undefined>(undefined);
  const [isPostJobsEnabled, setIsPostJobsEnabled] = useState<boolean>(false);
  const [jobsFailureError, setJobsFailureError] = useState<
    { message: string } | undefined
  >();

  const {
    data: preJobAPIData,
    error: preJobAPIError,
    isInitialLoading: isPreJobAPILoading,
  } = useGenericAPIQuery(preJobsAPI, enabled);

  const {
    data: postJobAPIData,
    error: postJobAPIError,
    refetch: refetchPostJobAPI,
    isInitialLoading: isPostJobAPILoading,
  } = useGenericAPIQuery(postJobsAPI, isPostJobsEnabled);

  useEffect(() => {
    if (preJobResponse) {
      const { details } = preJobResponse;
      const responseIDs = details.map(
        (item: { job: { id: string } }) => item.job.id,
      );
      setJobIds(responseIDs);
    } else if (preJobAPIData && preJobAPIData.details) {
      const { details } = preJobAPIData;
      const responseIDs = details.map(
        (item: { job: { id: string } }) => item.job.id,
      );
      setJobIds(responseIDs);
    }
  }, [preJobAPIData, preJobResponse]);

  const { data: jobData, error: jobError } = useMultipleJobsStatusQuery(jobIds);

  const successfulJobs = useMemo(() => {
    if (jobData) {
      const { data = [] } = jobData;
      return data.filter(
        (job) => job?.status === configureAssemblyStatus.SUCCESS,
      );
    }
    return [];
  }, [jobData]);

  const failedJobs = useMemo(() => {
    if (jobData) {
      const { data = [] } = jobData;
      return data.filter(
        (job) => job?.status === configureAssemblyStatus.ERROR,
      );
    }
    return [];
  }, [jobData]);

  const isJobStatusSuccessful = useMemo(
    () => jobData && successfulJobs.length === jobData?.data?.length,
    [jobData, successfulJobs],
  );

  useEffect(() => {
    if (jobData) {
      if (successfulJobs.length === jobIds?.length) {
        setJobIds(undefined);
        if (postJobsAPI) {
          setIsPostJobsEnabled(true);
          refetchPostJobAPI();
        }
      } else if (failedJobs.length > 0) {
        setJobIds(undefined);
        setJobsFailureError({ message: configureAssemblyStatus.ERROR });
      }
    }
  }, [
    isPostJobsEnabled,
    jobData,
    refetchPostJobAPI,
    postJobsAPI,
    jobIds,
    successfulJobs,
    failedJobs,
  ]);

  useEffect(() => {
    if (jobError) {
      setJobIds(undefined);
    }
  }, [jobError]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries([preJobsAPI?.apiName]);
      queryClient.removeQueries([postJobsAPI?.apiName]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: isPreJobAPILoading || isPostJobAPILoading,
    isJobRunning: jobIds !== undefined,
    error: preJobAPIError || postJobAPIError || jobError || jobsFailureError,
    data: postJobAPIData as T,
    isJobStatusSuccessful,
  };
}

export default useMultipleJobsPollingV2;
