import { useEffect } from 'react';
import { ADMIN_APP_URL } from '../../config';
import { withRouter } from 'react-router-dom';
import useLayoutStore from '../../stores/layoutStore';

const NewAdminRoutesComponent = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  useEffect(() => {
    const urlToReplace = window.location.href.includes(
      `${window.location.origin}/admin`,
    )
      ? `${window.location.origin}/admin`
      : window.location.origin;
    const urlToNavigate = window.location.href.replace(
      urlToReplace,
      ADMIN_APP_URL,
    );
    if (isEmbeddedInMainApp) {
      window.parent.postMessage(
        {
          type: 'NAVIGATE_TO_ADMIN',
          payload: location.pathname.replace('/admin', ''),
        },
        '*',
      );
    }
    window.location.replace(urlToNavigate);
  });

  return <></>;
};

export default withRouter(NewAdminRoutesComponent);
