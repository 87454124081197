import { useCallback, useContext, useMemo, useState } from 'react';
import { useFeatureSplit } from '../../../../hooks/useFeatureSplit';
import { EmojiData } from 'emoji-mart';
import {
  DeletePostClickArgs,
  FeedItemFromAPI,
  FeedItemMetaData,
  FeedProps,
  MemberInteractionType,
  Reaction,
} from '../../../../interfaces/Feed';
import { PostTypes } from '../../../../interfaces/Home';
import { GetProfileInfoResponse } from '../../../../queries/Profile';
import { getCanDeletePosts } from '../../../../queries/Profile/utils';
import {
  useFetchFlowFileQuery,
  useFetchPreviewFileQuery,
  useUpdateFlowPostNotificationPreference,
} from '../../../../queries/Flows/Feed';
import { POST_ANALYTICS_EVENTS } from '../../../../Utils/analytics/constants';
import useGetPageName from '../../../../hooks/useGetPageName';
import { trackPostEvent as trackPostEventMetric } from '../../../../Utils/analytics';
import { DownloadBlockType } from '../../../../atomic/organism/FlowPostBody/types';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';
import {
  getCommonFlowPostAttributes,
  getCurrencyFromProfileData,
  getFlowPostUrl,
  getRecognitionPostUrl,
} from '../../../../Utils/home/feeds';
import { showErrorMessage, showSuccessMessage } from '../../../../Utils/toast';
import { FeedContext } from '../../../../Utils/flows/feeds';
import { mapPostDataToPostProps } from '../../../../Utils/home/feeds/post';
import { useHistory } from 'react-router';
import useMultiAssemblySlug from '../../../../hooks/useMultiAssemblySlug';
import { trackPostActionEvent } from '../../../../Utils/analytics/post';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../../hooks/useSplitSdkConfig/constants';
import { useUpdateFlowResponseNotificationPreference } from '../../../../queries/Flows/Dashboard';
import { copyToClipboard } from '../../../../Utils/common';
import { supportedPdfPreviewFileTypes } from '../../../../constants/files';

type UseFlowPostControllerProps = {
  profileData?: GetProfileInfoResponse;
  post: FeedItemFromAPI;
};
const useFlowPostController = (props: UseFlowPostControllerProps) => {
  const { slugUrl } = useMultiAssemblySlug();

  const history = useHistory();
  const { profileData, post } = props;
  const { page } = useGetPageName();
  const {
    onReactionSet,
    onReactionUnSet,
    onMemberClick,
    onDeletePost,
    onFlowClick,
    onDownloadFileClick,
  } = useContext(FeedContext);

  const newTrackPostEvent = useCallback(
    (action: string) => {
      const postEventProps = {
        feedViewed: page,
        numPostComments: post.commentsCount,
        responseId: post.flowResponse?.responseId || null,
        numPostReactions: post.flowResponse?.reactions.length || 0,
        postId: post.type === PostTypes.RECOGNITION ? post.postID : null,
        flowId:
          post.flowResponse?.flow.flowId || PostTypes.RECOGNITION.toString(),
        flowName: post.flowResponse?.flow.name,
      };
      trackPostEventMetric('post.events', {
        ...postEventProps,
        event: 'action',
        action,
      });
    },
    [page, post],
  );

  const [deletePostCarrotOption, setDeletePostCarrotOption] =
    useState<string>();

  const [filePreviewFile, setFilePreviewFile] = useState<
    DownloadBlockType | undefined
  >(undefined);

  const {
    postId,
    responseId,
    flowId,
    instanceId,
    hasTrophies,
    showDeleteOption,
    isNotificationMuted,
    currency,
  } = useMemo(() => {
    const { flowResponse, type } = post;
    const isFlowPostBool = Boolean(type === PostTypes.FLOW && flowResponse);
    return {
      isFlowPost: isFlowPostBool,
      instanceId: flowResponse?.instanceId,
      commentsCount: isFlowPostBool
        ? flowResponse?.commentsCount || 0
        : post.commentsCount,
      ...getCommonFlowPostAttributes(post),
      trophyReceivers: isFlowPostBool ? [] : post.to,
      showDeleteOption: isFlowPostBool
        ? flowResponse?.canDelete
        : getCanDeletePosts(profileData) ||
          post.from?.memberID === profileData?.member.memberId,
      isNotificationMuted: isFlowPostBool
        ? flowResponse?.isMuted || false
        : post.isMuted || false,
      currency: getCurrencyFromProfileData(
        profileData as GetProfileInfoResponse,
      ),
    };
  }, [post, profileData]);

  const fileRequestSubstitutions = {
    responseId: filePreviewFile ? responseId : '',
    blockId: filePreviewFile ? filePreviewFile.id : '',
    instanceId: instanceId ? instanceId : '',
    fileName: filePreviewFile ? filePreviewFile.fileName : '',
    flowId: flowId ? flowId : '',
  };

  const shouldFetchDownloadFileInfo = filePreviewFile !== undefined;

  const {
    data: fileToDownload,
    error: fileDownloadError,
    refetch: refetchFileDownloadLink,
  } = useFetchFlowFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo,
  );

  const { treatment: convertedPdfPreviewsTreatment } = useFeatureSplit(
    SplitNames.CONVERTED_PDF_PREVIEWS,
  );

  const isConvertedPdfPreviewsEnabled =
    convertedPdfPreviewsTreatment === TreatmentTypes.ON;

  const {
    data: fileToPreview,
    error: filePreviewError,
    isLoading: isFilePreviewLoading,
    refetch: refetchFilePreviewLink,
  } = useFetchPreviewFileQuery(
    fileRequestSubstitutions,
    shouldFetchDownloadFileInfo &&
      isConvertedPdfPreviewsEnabled &&
      !!filePreviewFile?.type &&
      supportedPdfPreviewFileTypes.includes(filePreviewFile.type),
  );

  const handleSetFilePreview = useCallback(
    (value: DownloadBlockType | undefined) => setFilePreviewFile(value),
    [setFilePreviewFile],
  );

  const handleDeletePostOptionChange = useCallback(
    (value: string) => setDeletePostCarrotOption(value),
    [setDeletePostCarrotOption],
  );

  const handleDeletePost = useCallback(
    (obj: DeletePostClickArgs) => onDeletePost(obj, deletePostCarrotOption),
    [deletePostCarrotOption, onDeletePost],
  );

  const handleMemberClick = useCallback(
    (member: MemberInteractionType) => {
      newTrackPostEvent(POST_ANALYTICS_EVENTS.LINK_COPIED);
      onMemberClick(member);
    },
    [newTrackPostEvent, onMemberClick],
  );

  const handleReactionSet = useCallback(
    (
      emoji: EmojiData,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
      reactions: Reaction[],
    ) => {
      onReactionSet(emoji, contentId, feedItemMetaData, reactions);

      trackPostActionEvent({
        postId: postId,
        flowId: flowId,
        type: post.type,
        reaction: emoji.name,
        responseId: responseId,
        action: POST_ANALYTICS_EVENTS.POST_REACTION_ADDED,
        flowName: post.flowResponse?.flow.name || PostTypes.RECOGNITION,
      });
    },
    [
      postId,
      flowId,
      post.type,
      responseId,
      onReactionSet,
      post.flowResponse?.flow.name,
    ],
  );

  const handleReactionUnSet = useCallback(
    (
      reaction: Reaction,
      contentId: string,
      feedItemMetaData: FeedItemMetaData,
    ) => {
      onReactionUnSet(reaction, contentId, feedItemMetaData);

      trackPostActionEvent({
        postId: postId,
        flowId: flowId,
        type: post.type,
        responseId: responseId,
        reaction: reaction?.reaction?.name,
        action: POST_ANALYTICS_EVENTS.POST_REACTION_REMOVED,
        flowName: post.flowResponse?.flow.name || PostTypes.RECOGNITION,
      });
    },
    [
      postId,
      flowId,
      post.type,
      responseId,
      onReactionUnSet,
      post.flowResponse?.flow.name,
    ],
  );

  const handleFlowClick = useCallback(
    (flowID: string) => {
      onFlowClick(flowID);
      newTrackPostEvent(POST_ANALYTICS_EVENTS.FLOW_CLICKED);
    },
    [newTrackPostEvent, onFlowClick],
  );

  const onSeeFullPostClick = useCallback(() => {
    newTrackPostEvent(POST_ANALYTICS_EVENTS.SEE_FULL_POST_CLICK);
  }, [newTrackPostEvent]);

  const handleDownloadFileClick = useCallback(
    async (fileDownload: DownloadBlockType) => {
      if (flowId) {
        onDownloadFileClick(fileDownload, instanceId || '', flowId);
        newTrackPostEvent(POST_ANALYTICS_EVENTS.FILE_DOWNLOADED);
      }
    },
    [flowId, onDownloadFileClick, newTrackPostEvent, instanceId],
  );

  const onCopyLinkClick = useCallback(() => {
    const { flowResponse, type, postID } = post;
    if (type === PostTypes.FLOW && flowResponse) {
      if (flowResponse.flow.flowId && flowResponse.responseId) {
        const url = getFlowPostUrl(
          flowResponse.flow.flowId,
          flowResponse.responseId,
          slugUrl,
        );
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    } else {
      if (postID) {
        const url = getRecognitionPostUrl(postID, slugUrl);
        copyToClipboard(url);
        showSuccessMessage(COPIED_TO_CLIPBOARD);
      }
    }

    trackPostActionEvent({
      type,
      postId: postID,
      flowId: flowResponse?.flow.flowId,
      responseId: flowResponse?.responseId,
      action: POST_ANALYTICS_EVENTS.LINK_COPIED,
      flowName: flowResponse?.flow.name || PostTypes.RECOGNITION,
    });
  }, [post, slugUrl]);

  const onGotoPostLinkClick = useCallback(() => {
    const { flowResponse, type, postID } = post;
    if (type === PostTypes.FLOW && flowResponse) {
      if (flowResponse.flow.flowId && flowResponse.responseId) {
        // eslint-disable-next-line max-len
        history.push(
          `/post/flow/flows?flowId=${flowId}&responseId=${responseId}&showReplies=true&type=flow`,
        );
      }
    } else {
      if (postID) {
        history.push(
          `/post/flow/recognition?postId=${postId}&showReplies=true`,
        );
      }
    }

    trackPostActionEvent({
      type,
      postId: postID,
      flowId: flowResponse?.flow.flowId,
      responseId: flowResponse?.responseId,
      action: POST_ANALYTICS_EVENTS.GO_TO_POST_CLICKED,
      flowName: flowResponse?.flow.name || PostTypes.RECOGNITION,
    });
  }, [flowId, history, post, postId, responseId]);

  const onDeleteModalSubmitClick = useCallback(() => {
    const obj = getCommonFlowPostAttributes(post);
    handleDeletePost({
      hasTrophies: obj.hasTrophies,
      currentPostId: obj.postId,
      currentPostType: obj.postType,
      currentFlowId: obj.flowId,
      currentResponseId: obj.responseId,
    });

    trackPostActionEvent({
      flowId: obj.flowId,
      type: obj.postType,
      postId: obj.postId,
      responseId: responseId,
      action: POST_ANALYTICS_EVENTS.REPLY_DELETED,
    });
  }, [post, handleDeletePost, responseId]);

  const handleOnRepliesLinkClicked = useCallback(
    (params: Record<string, string>) => {
      history.replace({
        search: new URLSearchParams(params).toString(),
      });
    },
    [history],
  );

  const feedProps: FeedProps | undefined = useMemo(() => {
    if (!post || !profileData) {
      return undefined;
    }

    return mapPostDataToPostProps({
      feed: post,
      profileData,
      onMemberClick: handleMemberClick,
      onRepliesLinkClicked: handleOnRepliesLinkClicked,
    });
  }, [post, profileData, handleMemberClick, handleOnRepliesLinkClicked]);

  const { mutate: updateFlowResponseNotificationPreference } =
    useUpdateFlowResponseNotificationPreference(flowId, responseId);
  const { mutate: updateFlowPostNotificationPreference } =
    useUpdateFlowPostNotificationPreference(postId);

  const onNotificationPreferenceClick = () => {
    trackPostActionEvent({
      action: isNotificationMuted
        ? POST_ANALYTICS_EVENTS.FOLLOW_POST_CLICKED
        : POST_ANALYTICS_EVENTS.UN_FOLLOW_POST_CLICKED,
    });
    if (post.flowResponse) {
      updateFlowResponseNotificationPreference(
        {
          type: isNotificationMuted ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
        },
        {
          onSuccess: () => {
            showSuccessMessage(
              `You successfully ${
                isNotificationMuted ? 'followed' : 'unfollowed'
              } ${
                post?.flowResponse?.respondent?.firstName || 'Someone'
              }'s post.`,
            );
          },
          onError: () => {
            showErrorMessage(
              `Unable to ${
                isNotificationMuted ? 'follow' : 'unfollow'
              } this post, please try again.`,
            );
          },
        },
      );
    } else {
      updateFlowPostNotificationPreference(
        {
          type: isNotificationMuted ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
        },
        {
          onSuccess: () => {
            showSuccessMessage(
              `You successfully ${
                isNotificationMuted ? 'followed' : 'unfollowed'
              } ${post.from?.firstName || ''}'s post.`,
            );
          },
          onError: () => {
            showErrorMessage(
              `Unable to ${
                isNotificationMuted ? 'follow' : 'unfollow'
              } this post, please try again.`,
            );
          },
        },
      );
    }
  };

  const handleDeletePostClick = () => {
    const { type } = post;
    if (type === PostTypes.FLOW) {
      window.parent.postMessage(
        {
          type: 'OPEN_DELETE_POST_MODAL',
          payload: {
            flowId,
            responseId,
            replyId: null,
            hasTrophies,
          },
        },
        '*',
      );
    } else {
      window.parent.postMessage(
        {
          type: 'OPEN_DELETE_POST_MODAL',
          payload: {
            flowId: 'recognition',
            responseId: postId,
            replyId: null,
            hasTrophies,
          },
        },
        '*',
      );
    }
  };

  return {
    hasTrophies,
    showDeleteOption,
    fileToPreview,
    filePreviewError,
    refetchFilePreviewLink,
    isFilePreviewLoading,
    fileDownloadError,
    fileToDownload,
    refetchFileDownloadLink,
    isConvertedPdfPreviewsEnabled,
    handleSetFilePreview,
    onFlowClick: handleFlowClick,
    onCopyLinkClick,
    handleDeletePostOptionChange,
    handleMemberClick,
    onDownloadFileClick: handleDownloadFileClick,
    onSeeFullPostClick,
    handleReactionSet,
    handleReactionUnSet,
    onDeleteModalSubmitClick,
    feedProps,
    onGotoPostLinkClick,
    notificationPreference: {
      isNotificationMuted,
    },
    onNotificationPreferenceClick,
    currency: currency?.assemblyCurrency,
    handleDeletePostClick,
  };
};

export default useFlowPostController;
