import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '../../../atoms/Modal';
import { ButtonSize } from '../../../atoms/IconButton_V2/interface';
import Divider from '../../../atoms/Divider';
import {
  ModalBackground,
  CloseIconButton,
  ModalHeading,
  ModalBody,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
  Dropdown,
} from './styles';
import { Flex } from '../../../../Utils/styles/display';
import { dropdownItems } from './data';
import { TaskCategories } from '../../../../interfaces/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import { ModalProps } from '../../../../stores/notebookStore/types';
import ThemeV2 from '../../../../theme';
import Checkbox from '../../../atoms/Checkbox';
import { DONOT_SHOW_MODAL } from '../../../../languages/en/notebook';
import Body from '../../../atoms/Body';
import {
  MenuItemIndividualItem,
  MenuItemProps,
} from '../../Dropdown_V2/interfaces';

type DropdownProps = {
  id: string;
  value: string;
};

type ConfirmationModalProps = {
  isOpen: boolean;
  modalProps: ModalProps;
  hasDropdown: boolean;
  hasCheckbox: boolean;
  toggleOpen: (toggle: boolean) => void;
  onPrimaryButtonClick: (value: string | undefined) => void;
  onSecondaryButtonClick: () => void;
  handleDisableModalPrompts?: () => void;
  customDropdownOptions?: MenuItemIndividualItem[];
  defaultCustomDropdownSelection?: DropdownProps;
};

const defaultDropdown: DropdownProps = {
  id: TaskCategories.UNSCHEDULED,
  value: capitalizeFirstLetter(TaskCategories.UNSCHEDULED),
};

const ConfirmationModal = ({
  isOpen,
  modalProps,
  hasDropdown,
  hasCheckbox,
  customDropdownOptions,
  toggleOpen,
  handleDisableModalPrompts,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
}: ConfirmationModalProps) => {
  const [selectedOption, onDropdownSelect] = useState<
    DropdownProps | MenuItemIndividualItem | undefined
  >(undefined);

  useEffect(() => {
    // set default option as the first item in the array of custom dropdown options if it exists
    onDropdownSelect(
      customDropdownOptions && customDropdownOptions?.length > 0
        ? customDropdownOptions[0]
        : defaultDropdown,
    );
  }, [customDropdownOptions]);

  const modalDropdownData: MenuItemProps[] = useMemo(
    () => [
      {
        id: 'notebook-modal-options',
        items: (customDropdownOptions && customDropdownOptions?.length > 0
          ? customDropdownOptions
          : dropdownItems
        ).filter(
          (item: MenuItemIndividualItem) => item?.id !== selectedOption?.id,
        ),
      },
    ],
    [customDropdownOptions, selectedOption?.id],
  );

  const [isCheckboxSelected, toggleCheckbox] = useState(false);

  const handleCheckboxClick = useCallback(() => {
    toggleCheckbox(!isCheckboxSelected);
  }, [isCheckboxSelected]);

  const onClose = useCallback(() => {
    onDropdownSelect(defaultDropdown);
    onSecondaryButtonClick();
    toggleOpen(false);
  }, [onSecondaryButtonClick, toggleOpen]);

  const handleClick = useCallback(() => {
    onDropdownSelect(defaultDropdown);
    if (handleDisableModalPrompts && isCheckboxSelected) {
      handleDisableModalPrompts();
    }
    onPrimaryButtonClick(
      hasDropdown ? `${selectedOption?.id}` || undefined : undefined,
    );
  }, [
    hasDropdown,
    isCheckboxSelected,
    handleDisableModalPrompts,
    onPrimaryButtonClick,
    selectedOption?.id,
  ]);

  return (
    <Modal isOpen={isOpen} handleClose={onClose}>
      <ModalBackground>
        <Flex
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <CloseIconButton
            dataTestId="close-icon-notebook-modal"
            icon="close-rounded"
            onClick={onClose}
            size={ButtonSize.Normal}
          />
          <ModalHeading variant="h5">{modalProps.heading}</ModalHeading>
          <Divider color={ThemeV2.palette.gray4} isFullWidth />
          <ModalBody hasDropdown={hasDropdown}>
            {modalProps.bodyText}
            {hasDropdown && (
              <Dropdown
                dataTestId="notebook-modal-dropdown"
                dropdownItems={modalDropdownData}
                value={selectedOption?.value || ''}
                label="Move to"
                icon="drag-reorder"
                inputIconColor="gray9"
                onChange={(value, id) => {
                  onDropdownSelect({
                    id: (id as string) || '',
                    value,
                  });
                }}
              />
            )}
            {hasCheckbox && (
              <Flex margin="12px 0 0 0">
                <Checkbox
                  name="notebook-confirmation-modal"
                  onChange={handleCheckboxClick}
                  size="20px"
                  value={isCheckboxSelected}
                />
                <Body variant="body2" color="gray9" margin="0 0 0 8px">
                  {DONOT_SHOW_MODAL}
                </Body>
              </Flex>
            )}
          </ModalBody>
          <Divider color={ThemeV2.palette.gray4} isFullWidth />
          <ModalFooter>
            <SecondaryButton color="secondary" onClick={onClose}>
              {modalProps.secondaryButtonText}
            </SecondaryButton>
            <PrimaryButton
              status={modalProps.primaryButtonType}
              onClick={handleClick}
            >
              {modalProps.primaryButtonText}
            </PrimaryButton>
          </ModalFooter>
        </Flex>
      </ModalBackground>
    </Modal>
  );
};

export default ConfirmationModal;
