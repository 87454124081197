import React, { useState } from 'react';
import styled from 'styled-components';
import { EmoticonWithTextProps } from './type';
import ThemeV2 from '../../../theme';

const Wrapper = styled.div`
  display: inline;
`;

const Text = styled.span<{ hasBoldText?: boolean }>`
  font-weight: ${({ hasBoldText }) =>
    hasBoldText && ThemeV2.typography.fontWeightMedium};
`;

const EmoticonWithText = (props: EmoticonWithTextProps) => {
  const {
    text: initialText,
    emoticon,
    dataTestId,
    hasBoldText,
    className,
    dataQaId,
    persistText,
  } = props;

  const [persistedText] = useState(initialText);

  const text = persistText ? persistedText : initialText;

  return (
    <Wrapper
      className={className}
      data-testid={dataTestId}
      data-qa-id={dataQaId}
    >
      <span role="img" aria-label={text || 'emoticon'}>
        {emoticon}
      </span>{' '}
      {text && <Text hasBoldText={hasBoldText}>{text}</Text>}
    </Wrapper>
  );
};

export default EmoticonWithText;
