import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  CheckboxFilterLoadedProps,
  CheckboxFilterValues,
} from '../../../../../atomic/organism/CheckboxFilter/types';
import { DateFilterProps } from '../../../../../atomic/organism/DateFilter/types';
// eslint-disable-next-line max-len
import { SelectedMainFeedFilterValueProps } from '../../../../../atomic/organism/RightDrawerFilter/MainFeedFilter/types';
import { ComponentStatus } from '../../../../../interfaces/component';
import {
  FeedItemFromAPI,
  PostSortOptions,
} from '../../../../../interfaces/Feed';
import {
  DATE_SHARED,
  FILE_TYPE,
  FILTER_BY_ANOTHER_FLOW,
  FILTER_BY_ANOTHER_MEMBER,
  FILTER_BY_ANOTHER_TYPE,
  SELECT_FLOWS,
  SELECT_PERSON,
  SELECT_TYPE,
  UPLOADED_BY,
} from '../../../../../languages/en/flows';
import { useGetMainFeedQuery } from '../../../../../queries/MainFeed';
import {
  useGetDateFilterOptionsQuery,
  useGetFlowsFilterOptionsQuery,
  useGetPostedByFilterOptionsQuery,
} from '../../../../../queries/RightDrawer/Filter';
import { getFeedArrayFromQueryData } from '../../../../../Utils/home/feeds/reactions';

import { V2_HOME } from '../../../../../constants/routes';
import useGetParsedFilterOptions from '../../../../../hooks/useGetParsedFilterOptions';

import isEqual from 'lodash/isEqual';
import useGetPageName from '../../../../../hooks/useGetPageName';
import { trackRightDrawerEvent } from '../../../../../Utils/analytics';
import {
  KnowledgeCenterEvents,
  RIGHT_DRAWER_EVENTS,
} from '../../../../../Utils/analytics/constants';
import { DrawerTabType } from '../../../../../Utils/analytics/interfaces';
import {
  getAppliedFilter,
  GetAppliedFilterProps,
  getFiltersForAnonymousPostedBy,
} from '../../../../../Utils/rightDrawer';
import {
  createQueryParam,
  getPostedByOptionsWithAnonymousFilter,
  mapDataToFilterOptions,
  mapPersonDataToFilterOptions,
  serializeFilterValues,
} from '../../../../rightDrawer/utils';
import { QueryStringTypes } from '../../../../rightDrawer/types';
import { SHARED_IN } from '../../../../../languages/en/feed';
import { fileTypeOptions } from '../data';
import { trackKnowledgeCenterActionEvent } from '../../../../../Utils/analytics/knowledgeCenter';
import useHistoryWrapper from '../../../../../hooks/useHistoryWrapper';
import useLayoutStore from '../../../../../stores/layoutStore';
import { shallow } from 'zustand/shallow';

const useFilterControllerLogic = () => {
  const { search, pathname } = useLocation();
  const history = useHistoryWrapper();
  const location = useLocation();
  const {
    receivedStartDate,
    receivedEndDate,
    receivedFlowsID,
    receivedPostedById,
    receivedFileTypes,
    parsedParams,
    isAnonymousPostedBy,
  } = useGetParsedFilterOptions();

  const { closeRightSideBarForMobile } = useLayoutStore(
    (state) => ({
      closeRightSideBarForMobile: state.closeRightSideBarForMobile,
    }),
    shallow,
  );

  const { page } = useGetPageName();

  const drawerTabViewed: DrawerTabType = 'filter';

  const {
    data: mainFeedData,
    isInitialLoading: isMainFeedDataLoading,
    isError: isMainFeedDataError,
  } = useGetMainFeedQuery({ feedsSort: PostSortOptions.DESC, filter: {} });
  const {
    data: flowsData,
    isInitialLoading: isFlowsDataLoading,
    isError: isFlowsDataError,
  } = useGetFlowsFilterOptionsQuery('', true);
  const {
    data: postedByData,
    isInitialLoading: isPostedByLoading,
    isError: isPostedByError,
  } = useGetPostedByFilterOptionsQuery('', '', true);
  const {
    data: dateFilterData,
    isInitialLoading: isDateFilterLoading,
    isError: isDateFilterError,
  } = useGetDateFilterOptionsQuery();

  const [startDateValue, setStartDate] = useState<Date | undefined>(() =>
    receivedStartDate !== undefined ? new Date(receivedStartDate) : undefined,
  );
  const [endDateValue, setEndDate] = useState<Date | undefined>(() =>
    receivedEndDate !== undefined ? new Date(receivedEndDate) : undefined,
  );
  const [flowSelectedOptions, setFlowSelectedOptions] =
    useState<string[]>(receivedFlowsID);
  const [postedBySelectedOptions, setPostedSelectedByOptions] =
    useState<string[]>(receivedPostedById);
  const [fileTypeSelectedOptions, setFileTypeSelectedOptions] =
    useState<string[]>(receivedFileTypes);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isAnonymousPostedByFilter, setAnonymousPostedByFilter] =
    useState(isAnonymousPostedBy);

  const feedData: FeedItemFromAPI[] = useMemo(() => {
    return getFeedArrayFromQueryData(mainFeedData);
  }, [mainFeedData]);

  const initialStartDate = useMemo(() => {
    if (dateFilterData?.startDate) {
      return new Date(dateFilterData?.startDate);
    }
    return undefined;
  }, [dateFilterData]);

  const flowsDataOptions: CheckboxFilterValues = useMemo(() => {
    if (flowsData?.data) {
      return mapDataToFilterOptions(flowsData, 'flows');
    }
    return [];
  }, [flowsData]);

  const postedByOptions: CheckboxFilterValues = useMemo(() => {
    if (postedByData?.data) {
      const postedByOptionsFromData = mapPersonDataToFilterOptions(
        postedByData,
        'postedBy',
      );
      if (postedBySelectedOptions && postedBySelectedOptions?.length > 0) {
        return getPostedByOptionsWithAnonymousFilter(
          postedByOptionsFromData,
          isAnonymousPostedByFilter,
        );
      }
      return postedByOptionsFromData;
    }
    return [];
  }, [isAnonymousPostedByFilter, postedByData, postedBySelectedOptions]);

  useEffect(() => {
    const { isAnonymous } = getFiltersForAnonymousPostedBy(
      postedBySelectedOptions,
    );
    setAnonymousPostedByFilter(isAnonymous);
  }, [postedBySelectedOptions]);

  const collapseClickHandler = useCallback(
    (isCollapseOpen: boolean) => {
      if (isCollapseOpen) {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_COLLAPSED, {
          feedViewed: page,
          drawerTabViewed,
        });
      } else {
        trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTER_SECTION_EXPANDED, {
          feedViewed: page,
          drawerTabViewed,
        });
      }
    },
    [page],
  );

  const postedByFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: UPLOADED_BY,
    filterByText: FILTER_BY_ANOTHER_MEMBER,
    autoCompleteText: SELECT_PERSON,
    options: postedByOptions,
    onSelectedOptionChange: setPostedSelectedByOptions,
    selectedOptions: postedBySelectedOptions,
    onCollapseClick: collapseClickHandler,
    disableFilterBy: isAnonymousPostedByFilter,
  };

  const dateFilterProps: DateFilterProps = {
    status: ComponentStatus.LOADED,
    headingText: DATE_SHARED,
    startDate: startDateValue,
    endDate: endDateValue,
    onStartDateChange: setStartDate,
    onEndDateChange: setEndDate,
    initialStartDate,
    onCollapseClick: collapseClickHandler,
  };

  const fileTypeFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: FILE_TYPE,
    filterByText: FILTER_BY_ANOTHER_TYPE,
    autoCompleteText: SELECT_TYPE,
    options: fileTypeOptions,
    onSelectedOptionChange: setFileTypeSelectedOptions,
    selectedOptions: fileTypeSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const flowsFilterProps: CheckboxFilterLoadedProps = {
    status: ComponentStatus.LOADED,
    headingText: SHARED_IN,
    filterByText: FILTER_BY_ANOTHER_FLOW,
    autoCompleteText: SELECT_FLOWS,
    options: flowsDataOptions,
    onSelectedOptionChange: setFlowSelectedOptions,
    selectedOptions: flowSelectedOptions,
    onCollapseClick: collapseClickHandler,
  };

  const selectedMainFeedFilterValue: SelectedMainFeedFilterValueProps = {
    selectedDates: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    selectedFlows: serializeFilterValues(flowSelectedOptions, 'flows'),
    selectedPostedBy: serializeFilterValues(
      postedBySelectedOptions,
      'postedBy',
    ),
    selectedFileType: serializeFilterValues(
      fileTypeSelectedOptions,
      'fileType',
    ),
  };

  const handleOnSubmit = () => {
    const getAppliedFilterProps: GetAppliedFilterProps = {
      startDate: startDateValue,
      endDate: endDateValue,
      flowsId: flowSelectedOptions,
      postedBy: postedBySelectedOptions,
      fileType: fileTypeSelectedOptions,
    };

    const { postedBy: filteredPostedBy } = getFiltersForAnonymousPostedBy(
      selectedMainFeedFilterValue?.selectedPostedBy,
    );

    /* The + character has a special meaning in a URL => it means whitespace - .
     If we want to use the literal + sign, we need to URL encode it to %2b: */
    // Ref: https://stackoverflow.com/questions/5450190/how-to-encode-the-plus-symbol-in-a-url
    // Thu Jul 01 2021 12:00:00 GMT+0530 (India Standard Time) - to get the plus from the query param
    const startDate =
      startDateValue && encodeURIComponent(startDateValue?.toString());
    const endDate =
      endDateValue && encodeURIComponent(endDateValue?.toString());
    const flowsId = selectedMainFeedFilterValue.selectedFlows.join();
    const postedBy = filteredPostedBy.join();
    const fileType = fileTypeSelectedOptions.join();

    const queryString: QueryStringTypes = {
      startDate,
      endDate,
      flowsId,
      postedBy,
      fileType,
      isAnonymousPostedByFilter,
    };

    const filterList: string[] = [];

    if (startDate || endDate) filterList.push('date');
    if (flowsId) filterList.push('flow');
    if (postedBy) filterList.push('uploadedBy');
    if (fileType) filterList.push('fileType');

    trackKnowledgeCenterActionEvent({
      action: KnowledgeCenterEvents.FiltersApplied,
      filterApplied: filterList,
    });

    if (location.pathname.includes('card')) {
      history.push(createQueryParam(queryString, '/knowledge-center/card?'));
    } else {
      history.push(createQueryParam(queryString, '/knowledge-center/list?'));
    }

    closeRightSideBarForMobile();

    const filterApplied = getAppliedFilter(getAppliedFilterProps);

    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_APPLIED, {
      filterApplied,
      feedViewed: page,
      drawerTabViewed,
    });
  };

  const handleOnClear = useCallback(() => {
    trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.FILTERS_CLEARED, {
      feedViewed: page,
      drawerTabViewed,
    });

    setStartDate(undefined);
    setEndDate(undefined);
    setFlowSelectedOptions([]);
    setPostedSelectedByOptions([]);
    setFileTypeSelectedOptions([]);

    if (location.pathname.includes('card')) {
      history.push('/knowledge-center/card');
    } else {
      history.push('/knowledge-center/list');
    }
  }, [history, location.pathname, page]);

  useEffect(() => {
    setIsEmpty(initialStartDate === undefined || feedData.length === 0);
  }, [feedData, initialStartDate]);

  useEffect(
    function resetFilter() {
      if (Object.keys(parsedParams).length === 0 && pathname === V2_HOME) {
        handleOnClear();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, pathname],
  );

  const isStartDateEqual =
    startDateValue?.toString() === receivedStartDate && true;
  const isEndDateEqual = endDateValue?.toString() === receivedEndDate && true;
  const isFlowsIdEqual = isEqual(flowSelectedOptions, receivedFlowsID);
  const isPostedByEqual = isEqual(postedBySelectedOptions, receivedPostedById);
  const isFileTypeEqual = isEqual(fileTypeSelectedOptions, receivedFileTypes);

  const isFilterButtonEnabled =
    isStartDateEqual &&
    isEndDateEqual &&
    isFlowsIdEqual &&
    isPostedByEqual &&
    isFileTypeEqual;

  const isLoading =
    isMainFeedDataLoading ||
    isDateFilterLoading ||
    isFlowsDataLoading ||
    isPostedByLoading;
  const isError =
    isMainFeedDataError ||
    isDateFilterError ||
    isFlowsDataError ||
    isPostedByError;

  return {
    models: {
      isAnonymousPostedByFilter,
      flowsFilterProps,
      postedByFilterProps,
      dateFilterProps,
      fileTypeFilterProps,
      isLoading,
      isError,
      isEmpty,
      isFilterButtonEnabled,
    },
    operations: {
      handleOnSubmit,
      handleOnClear,
    },
  };
};

export default useFilterControllerLogic;
