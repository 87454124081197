import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import IconButton from '../../atoms/IconButton_V2';

export const SliderBox = styled.div`
  position: fixed;
  display: flex;
  height: 32px;
  width: 236px;
  bottom: 32px;
  padding: 4px;
  right: 40px;
  background: ${ThemeV2.palette.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`;

export const SliderWrapper = styled.div`
  height: 24px;
  width: 124px;
  margin: -2px 12px 0px 12px;
  .MuiSlider-colorPrimary {
    color: ${ThemeV2.palette.gray8};
  }
`;

export const StyledIconButton = styled(IconButton)<{
  height: number;
  margin?: string;
}>`
  top: 16px;
  right: 16px;
  height: ${({ height }) => height}px;
  margin ${({ margin }) => margin};
`;

export const VerticalBar = styled.div<{ height: number; sideMargin: number }>`
  border-left: 1px solid ${ThemeV2.palette.gray5};
  height: ${({ height }) => height}px;
  margin: 0px ${({ sideMargin }) => sideMargin}px;
  vertical-align: middle;
`;
