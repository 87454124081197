export const device = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
  tabletOrDesktop: '(min-width: 768px)',
};

export const PrimaryNavWidth = 96;
export const SecondaryNavWidth = 336;
export const PrimaryNavWidthMobile = 72;

export const MaxAsideWidth = 500;
export const AsideWidthWhenOpen = 450;
export const AsideWidthWhenClosed = 24;
export const MaximumRightContentWidth = 380;
export const MinimumAsideWidthWhenOpen = 320;
export const DefaultAsideWidthWhenRepliesOpen = 413;

export const MainHeaderHeight = 152;
export const EmbeddedMainHeaderHeight = 81;
export const MainHeaderWithTabsHeight = 181;
export const EmbeddedHeaderWithTabsHeight = 96;
export const MobileWebMainHeaderHeight = 153;
export const MobileWebMainHeaderWithTabsHeight = 153;
export const MobileAppMainHeaderHeight = 80;
export const MobileAppMainHeaderWithTabsHeight = 109;
