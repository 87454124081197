import React, { MutableRefObject } from 'react';

import { ActionMenuItemProp } from './types';

import {
  StyledBody,
  StyledTextContent,
  StyledEmoticonHolder,
  StyledRightCarrotIcon,
  StyledFlowMenuItemWrapper,
  StyledFlowItemIconContainer,
  StyledSVGIcon,
} from './styles';
import Body from '../../atoms/Body';
import IconContainer from '../../atoms/IconContainer';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import ToolTip from '../ToolTipV2';

type ActionItemRefType =
  | MutableRefObject<HTMLButtonElement | null>
  | ((instance: HTMLButtonElement | null) => void)
  | null;

const ActionMenuItem = (props: ActionMenuItemProp, ref: ActionItemRefType) => {
  const {
    icon,
    emoticon,
    subTitle,
    title,
    onClick,
    emoticonBg,
    toolTipProps,
    hasDraft,
  } = props;

  return (
    <StyledFlowMenuItemWrapper
      ref={ref}
      onClick={onClick}
      className="menu-item"
      data-testid="menu-item"
      emoticonBg={emoticonBg}
    >
      {icon && (
        <StyledFlowItemIconContainer size="44px">
          <SVGIcon icon={icon} size="24px" />
        </StyledFlowItemIconContainer>
      )}
      {emoticon && (
        <StyledEmoticonHolder
          emoticon={emoticon}
          color="transparent"
          size={EmoticonHolderSizes.Medium3}
        />
      )}
      <StyledTextContent
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Flex>
          <Body variant="body1Medium">{title}</Body>
          <StyledBody variant="body3Medium">{hasDraft && 'DRAFT'}</StyledBody>
          {toolTipProps && (
            <ToolTip
              toolTipComponent={
                <StyledSVGIcon icon={toolTipProps.toolTipIcon} />
              }
              position="top"
              positionStrategy="fixed"
            >
              {toolTipProps.toolTipText}
            </ToolTip>
          )}
        </Flex>
        <Body variant="body3" color="gray8">
          {subTitle}
        </Body>
      </StyledTextContent>
      <IconContainer size="32px">
        <StyledRightCarrotIcon icon="right-carat" size="24px" />
      </IconContainer>
    </StyledFlowMenuItemWrapper>
  );
};

export default React.forwardRef(ActionMenuItem);
