import { APP_URL, SLACK } from '../../../config';
import { useGetConnectionConfig } from '../../../queries/Connections';
import { useCallback } from 'react';

const useOpenSlackModal = () => {
  const { data, isLoading, isError } = useGetConnectionConfig(
    `${APP_URL}${SLACK.REDIRECT_PATH}`,
    'slack',
  );

  const open = useCallback(() => {
    if (!data) {
      return;
    }

    const options: { [x: string]: string | number } = {
      width: 600,
      height: 750,
      popup: 'yes',
      status: 'no',
      toolBar: 'no',
      menuBar: 'no',
      titleBar: 'no',
      location: 'no',
      resizable: 'yes',
      scrollbars: 'yes',
      directories: 'no',
      top: `${screen.height / 4}`,
      left: `${screen.width / 2}`,
    };

    let stringifiedOptions = '';
    for (const key in options) {
      stringifiedOptions += `${key}=${String(options[key])},`;
    }

    window.open(data.authorizeUri, '_blank', stringifiedOptions);
  }, [data]);

  return {
    open,
    isError,
    isLoading,
  };
};

export default useOpenSlackModal;
