import { DefinedNotebookSections } from '../../../../constants/notebook';
import { SectionFromAPI } from '../../../../interfaces/notebook';
import { BoardSection } from '../../../../stores/notebookStore/types';

export const getSectionId = (section: SectionFromAPI) => {
  if (section.sectionId !== null) return section.sectionId;
  else return section.title;
};

export const getMappedSectionsFromAPIResponse = (
  sectionsFromAPI: SectionFromAPI[],
) => {
  let completedSection = '';
  let unassignedSection = '';
  const mappedSections: BoardSection[] = sectionsFromAPI.map(
    (currentSection: SectionFromAPI) => {
      const sectionId = getSectionId(currentSection);
      if (
        currentSection.type.toLocaleLowerCase() ===
        DefinedNotebookSections.Completed
      ) {
        completedSection = sectionId;
      }
      if (
        currentSection.type.toLocaleLowerCase() ===
        DefinedNotebookSections.NoSection
      ) {
        unassignedSection = sectionId;
      }
      return {
        sectionId,
        name: currentSection.title,
        type: currentSection.type,
        isCompleted:
          currentSection.type.toLocaleLowerCase() ===
          DefinedNotebookSections.Completed,
        isUnassigned:
          currentSection.type.toLocaleLowerCase() ===
          DefinedNotebookSections.NoSection,
        count: currentSection.count || 0,
      };
    },
  );
  return {
    sections: mappedSections,
    completedSection,
    unassignedSection,
  };
};
