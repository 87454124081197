import styled from 'styled-components';

import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import ThemeV2 from '../../../theme';
import { device } from '../../../constants/layout';

export const StyledFooter = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 16px;
  grid-area: footer;
`;

export const StyledExternalLink = styled.a`
  margin-right: auto;
  display: none;
  @media ${device.tabletOrDesktop} {
    display: block;
  }
`;

export const StyledNavInstructions = styled(Body)`
  display: none;

  @media ${device.tabletOrDesktop} {
    display: flex;
    align-items: center;
    margin-right: 16px;
  }
`;

export const StyledArrowIconWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: ${ThemeV2.palette.gray3};
`;

export const StyledNavigationButton = styled(Button)<{ width: string }>`
  width: ${({ width }) => width};
  margin-right: 8px;
`;

export const VerticalDivider = styled.div`
  height: 48px;
  margin: 0 16px 0 8px;
  border-left: 1px solid ${ThemeV2.palette.gray4};
  background-color: ${ThemeV2.palette.gray3};
`;

export const StyledDetailsWrapper = styled.div`
  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledCreatorWrapper = styled.div`
  margin-right: auto;
  @media ${device.mobile} {
    display: none;
  }
`;

export const StyledCreatorLogo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const StyledPoweredByText = styled(Body)`
  margin-right: 8px;
`;

export const StyledPoweredByLogo = styled.img`
  width: 24px;
  height: 24px;
`;
