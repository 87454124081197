import React, { useCallback, useState } from 'react';

import ThemeV2 from '../../../../theme';
import Body from '../../../../atomic/atoms/Body';
import Button from '../../../../atomic/atoms/Button';
import SVGIcon from '../../../../atomic/atoms/SVGIcon';
import { Flex } from '../../../../Utils/styles/display';
import useSlackUserStore from '../../../../stores/userStore';
import { ConnectionControllerProp, ConnectionState } from './types';
import { SHARE_SHEET_EVENTS } from '../../../../Utils/analytics/constants';
import useOpenSlackModal from '../../../../atomic/organism/ConnectToSlack/useOpenSlackModal';
import SlackWorkspaceStateButton from '../../../../atomic/molecules/SlackWorkspaceStateButton';
import SlackWorkspaceAndChannelsSelector from '../../../../atomic/molecules/SlackWorkspaceAndChannelsSelector';

import {
  ActiveBadge,
  Container,
  DisconnectedBadge,
  Link,
  SlackContainer,
  StyledClickable,
  StyledConnectToSlackButton,
  TeamsContainer,
  ZapierBodyDiv,
  ZapierContainer,
  ZapierHeaderDiv,
  ConnectionAppIcon,
} from './styles';

import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import { trackShareSheetActionEvent } from '../../../../Utils/analytics/shareSheet';
import useFlowBuilderStore from '../../../../stores/flowBuilderStore';
import ToolTip from '../../../../atomic/molecules/ToolTipV2';
import IconButton from '../../../../atomic/atoms/IconButton_V2';
import { ButtonSize } from '../../../../atomic/atoms/IconButton_V2/interface';
import { COPIED_TO_CLIPBOARD } from '../../../../languages/en/flows';
import { showSuccessMessage } from '../../../../Utils/toast';
import { copyToClipboard } from '../../../../Utils/common';

const ConnectionsController = (props: ConnectionControllerProp) => {
  const { open } = useOpenSlackModal();
  const { data } = useProfileInfoFetchQuery();

  const { isTeamsNotified, setIsTeamsNotified } = useSlackUserStore();

  const {
    state,
    setState,
    channelList,
    flowDetails,
    connectionsList,
    connectionStatus,
    deleteConnection,
    isLoadingChannels,
    selectedConnection,
    isLoadingConnections,
    isDeletingConnection,
    isGetChannelsListJobQueued,
    handleOnRefreshChannelClick,
    setSelectedConnection,
    selectedPeopleToPostChannel,
    setSelectedPeopleToPostChannel,
    selectedPostFlowResponseChannel,
    setSelectedPostFlowResponseChannel,
    onGenerateAPIKeyClick,
    authKey,
    connectedTriggers,
    isExternalAuthKeyLoading,
  } = props;

  const { flowName } = useFlowBuilderStore();

  const [showAuthKey, setShowAuthKey] = useState(false);

  const handleTeamsNotifyMeClick = useCallback(() => {
    trackShareSheetActionEvent({
      flowName,
      tab: 'invite and share',
      email: data?.member.email,
      action: SHARE_SHEET_EVENTS.MS_TEAMS_NOTIFY_CLICKED,
    });

    setIsTeamsNotified(true);
  }, [flowName, data?.member.email, setIsTeamsNotified]);

  const handleOnDisconnectSlackClick = useCallback(() => {
    trackShareSheetActionEvent({
      flowName,
      app: 'slack',
      tab: 'connections',
      action: SHARE_SHEET_EVENTS.SLACK_DISCONNECTED,
    });

    deleteConnection();
    setSelectedPeopleToPostChannel([]);
    setSelectedPostFlowResponseChannel([]);
    setState(ConnectionState.ConnectState);
  }, [
    flowName,
    setState,
    deleteConnection,
    setSelectedPeopleToPostChannel,
    setSelectedPostFlowResponseChannel,
  ]);

  const onCopyAuthKeyClick = useCallback(() => {
    copyToClipboard(authKey);
    showSuccessMessage(COPIED_TO_CLIPBOARD);
  }, [authKey]);

  const onZapierConnectClick = useCallback(() => {
    window.open(
      'https://zapier.com/app/dashboard',
      '_blank',
      'noreferrer, noopener',
    );
  }, []);

  const renderAPIKey = useCallback(() => {
    if (isExternalAuthKeyLoading) {
      return (
        <Body variant="body2" color="gray9" margin="0 0 0 8px">
          Loading...
        </Body>
      );
    }

    if (showAuthKey) {
      return (
        <>
          <Body variant="body2" color="gray9" margin="0 0 0 8px">
            {authKey.slice(0, 12)}...
          </Body>
          <IconButton
            icon="copy"
            title="Copy Auth Key"
            data-test-id="copy-auth-key"
            size={ButtonSize.Small}
            onClick={onCopyAuthKeyClick}
          />
        </>
      );
    }

    if (authKey) {
      return (
        <StyledClickable
          onClickCallback={() => {
            setShowAuthKey(true);
          }}
        >
          Show API Key
        </StyledClickable>
      );
    } else {
      return (
        <StyledClickable onClickCallback={onGenerateAPIKeyClick}>
          Generate an API Key
        </StyledClickable>
      );
    }
  }, [
    authKey,
    isExternalAuthKeyLoading,
    onCopyAuthKeyClick,
    onGenerateAPIKeyClick,
    showAuthKey,
  ]);

  return (
    <Container>
      <>
        <Body variant="body1Medium" color="gray9">
          Connect this flow to your other apps
        </Body>
        <Body variant="body2" color="gray9">
          Send notifications and posts to specific channels to engage teammates
          where they work.
        </Body>
        <SlackContainer connectionState={state}>
          <Flex justifyContent="space-between">
            <Flex>
              <ConnectionAppIcon icon="slack-logo" />
              {connectionStatus === 'CONNECTED' && (
                <ActiveBadge>
                  <Body variant="body3" color="geekBlue8">
                    Active
                  </Body>
                </ActiveBadge>
              )}
              {(connectionStatus === 'DISCONNECTED' ||
                connectionStatus === 'CONNECTION_BROKEN') &&
                state !== ConnectionState.ConnectionEditState && (
                  <DisconnectedBadge>
                    <Body variant="body3" color="dustRed7">
                      Connection Broken
                    </Body>
                  </DisconnectedBadge>
                )}
            </Flex>
            <SlackWorkspaceStateButton
              state={state}
              onStateChange={setState}
              connectionsList={connectionsList}
              selectedConnection={selectedConnection}
              isDeletingConnection={isDeletingConnection}
              onSelectedConnectionChange={setSelectedConnection}
            />
          </Flex>

          {state === ConnectionState.DisconnectedState && (
            <>
              <Body variant="body2" color="gray9" margin="8px 0 0 0">
                You are no longer connected to Slack.{' '}
                <Link onClick={open}>Reconnect</Link> or
                <Link onClick={() => deleteConnection()}>
                  {' '}
                  dismiss this warning
                </Link>
              </Body>
            </>
          )}

          {state === ConnectionState.ConnectToSlackState && (
            <>
              <Body variant="body2Medium" color="gray9">
                Connect your Slack to get started
              </Body>
              <Body variant="body3" color="gray9">
                Once you do, choose channels to send reminders to post and posts
                to your teammates!
              </Body>
              <StyledConnectToSlackButton disabled={isLoadingConnections} />
            </>
          )}

          {(state === ConnectionState.ConnectionDisplayState ||
            state === ConnectionState.ConnectionEditState) &&
            !isDeletingConnection && (
              <SlackWorkspaceAndChannelsSelector
                state={state}
                setState={setState}
                channelList={channelList}
                flowDetails={flowDetails}
                connectionsList={connectionsList}
                isLoadingChannels={isLoadingChannels}
                selectedConnection={selectedConnection}
                onConnectDifferentWorkspaceClick={open}
                isDeletingConnection={isDeletingConnection}
                isLoadingConnections={isLoadingConnections}
                setSelectedConnection={setSelectedConnection}
                onDisconnectSlackClick={handleOnDisconnectSlackClick}
                isGetChannelsListJobQueued={isGetChannelsListJobQueued}
                handleOnRefreshChannelClick={handleOnRefreshChannelClick}
                selectedPeopleToPostChannel={selectedPeopleToPostChannel}
                setSelectedPeopleToPostChannel={setSelectedPeopleToPostChannel}
                selectedPostFlowResponseChannel={
                  selectedPostFlowResponseChannel
                }
                setSelectedPostFlowResponseChannel={
                  setSelectedPostFlowResponseChannel
                }
              />
            )}
        </SlackContainer>

        <TeamsContainer>
          <ConnectionAppIcon icon="teams-logo" />
          {isTeamsNotified ? (
            <>
              <Flex>
                <SVGIcon
                  icon="check"
                  size="16px"
                  color={ThemeV2.palette.gray8}
                ></SVGIcon>
                <Body variant="body2" color="gray8" margin="0 8px">
                  {`We'll notify you when available!`}
                </Body>
              </Flex>
            </>
          ) : (
            <Button
              variant="text"
              icon="alarm"
              size="small"
              onClick={handleTeamsNotifyMeClick}
            >
              Notify Me
            </Button>
          )}
        </TeamsContainer>
        <ZapierContainer>
          <ZapierHeaderDiv>
            <ConnectionAppIcon icon="zapier" />
            <Body variant="body2" color="gray8" margin="0 8px">
              {`Connect to 5,000+ apps to automate triggers, posts, and more.`}
            </Body>
            <Button
              size="small"
              variant="text"
              color="primary"
              onClick={onZapierConnectClick}
            >
              {connectedTriggers > 0 ? 'Edit' : 'Connect'}
            </Button>
          </ZapierHeaderDiv>
          <ZapierBodyDiv>
            <Body variant="body2Bold" color="gray8" margin="0 5px 0 0">
              API Key
            </Body>
            <ToolTip
              iconSize="16px"
              icon="question-mark-outlined"
              iconColor="White"
            >
              <div style={{ width: 200 }}>
                Use this API Key to connect your Assembly account to other apps
                and webhooks - including Zapier and the 5,000+ apps they offer.
              </div>
            </ToolTip>
            {renderAPIKey()}
          </ZapierBodyDiv>
          {connectedTriggers > 0 && (
            <ZapierBodyDiv>
              <Body variant="body2Bold" color="gray8" margin="0 5px 0 0">
                Your Zaps
              </Body>
              <Body variant="body2" color="gray9" margin="0 0 0 8px">
                This flow triggers {connectedTriggers}{' '}
                {`Zap${connectedTriggers > 1 ? 's' : ''}`}
              </Body>
            </ZapierBodyDiv>
          )}
        </ZapierContainer>
      </>
    </Container>
  );
};

export default ConnectionsController;
