import _ from 'lodash';
import { CheckboxFilterValues } from '../../atomic/organism/CheckboxFilter/types';

import {
  GetBlocksFilterResponse,
  GetFlowsFilterResponse,
  GetPersonsFilterResponse,
  GetVersionsFilterResponse,
} from '../../interfaces/RightDrawer/Filter';
import { ANONYMOUS_MEMBER_ID } from '../../Utils/rightDrawer';
import { QueryStringTypes } from './types';

export const serializeFilterValues = (
  actualStrings: string[],
  stringToBeReplaced = 'filter',
) => {
  return actualStrings.map((actualString) =>
    actualString
      .replace(`+${stringToBeReplaced}`, '')
      .replace(`${stringToBeReplaced}-`, ''),
  );
};

export const mapPersonDataToFilterOptions = (
  persons: GetPersonsFilterResponse,
  appendString: string,
) => {
  if (!persons?.data) {
    return [];
  }
  return persons?.data.map((member) => {
    return {
      value: false,
      label: member.name || `${member.firstName} ${member.lastName}`,
      id: `${appendString}-${member.memberID}`,
      img: member.image || '',
      showAvatar: true,
      memberState: member.memberState,
      isAnonymous: member.memberID === ANONYMOUS_MEMBER_ID,
    };
  });
};

export const mapDataToFilterOptions = (
  data: GetFlowsFilterResponse,
  appendString: string,
) => {
  if (!data?.data) {
    return [];
  }
  return data?.data.map((flow) => {
    return {
      value: false,
      label: flow.name,
      icon: flow.icon?.value,
      id: `${flow.flowId}+${appendString}`,
    };
  });
};

export const mapVersionDataToFilterOptions = (
  versionsData: GetVersionsFilterResponse,
) => {
  return versionsData.data.map((version) => {
    return {
      value: false,
      label: `V${version.version}`,
      id: `${version.version}version`,
    };
  });
};

export const mapBlocksDataToFilterOptions = (
  blocksData: GetBlocksFilterResponse,
) => {
  return blocksData.data.map((block) => {
    return {
      value: false,
      label: block.title,
      id: `${block.blockId}`,
      // info: `V${block.flowVersion}`,
    };
  });
};

export const isDateEqual = (stateValue?: Date, receivedValue?: string) =>
  stateValue?.toString() === receivedValue;

export const isArrayEqual = (stateValue: string[], receivedValue: string[]) =>
  _.isEqual(stateValue, receivedValue);

export const createQueryParam = (
  receivedQueryString: QueryStringTypes,
  initialQueryString: string,
) => {
  const {
    startDate,
    endDate,
    flowsId,
    fileType,
    versionId,
    blockId,
    postedBy,
    mentionedBy,
    isAnonymousPostedByFilter,
  } = receivedQueryString;

  let queryString = initialQueryString;
  if (startDate) {
    queryString += `startDate=${startDate}&`;
  }
  if (endDate) {
    queryString += `endDate=${endDate}&`;
  }
  if (flowsId) {
    queryString += `flowsId=${flowsId}&`;
  }
  if (fileType) {
    queryString += `fileType=${fileType}&`;
  }
  if (versionId) {
    queryString += `versionId=${versionId}&`;
  }
  if (blockId) {
    queryString += `blockId=${blockId}&`;
  }
  if (postedBy) {
    queryString += `postedBy=${postedBy}&`;
  }
  if (mentionedBy) {
    queryString += `mentionedBy=${mentionedBy}&`;
  }
  if (isAnonymousPostedByFilter) {
    queryString += `isAnonymous=true&`;
  }
  return queryString;
};

export const getPostedByOptionsWithAnonymousFilter = (
  postedByOptions: CheckboxFilterValues,
  isAnonymousPostedByFilter: boolean,
) => {
  return postedByOptions.map((option) => ({
    ...option,
    disabled: isAnonymousPostedByFilter
      ? `postedBy-${ANONYMOUS_MEMBER_ID}` !== option?.id
      : `postedBy-${ANONYMOUS_MEMBER_ID}` === option?.id,
  }));
};
