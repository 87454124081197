import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import KnowledgeCenterCard from '../../molecules/KnowledgeCenterCard';
import Image from '../../atoms/Image';

import {
  FeedAttachmentItemFromAPI,
  GetFeedAttachmentResponse,
} from '../../../interfaces/Feed';
import { InfiniteData } from '@tanstack/react-query';
import KnowledgeCenterCardLoader from '../../molecules/KnowledgeCenterCard/Loader';
import {
  trackKnowledgeCenterErrorEvent,
  trackKnowledgeCenterShowEvent,
} from '../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../Utils/analytics/constants';
import { Flex } from '../../../Utils/styles/display';
import { windowSizeAdapter } from '../../../Utils/window';
import {
  KNOWLEDGE_CENTER_EMPTY_SEARCH,
  KNOWLEDGE_CENTER_EMPTY_SEARCH_SUB,
  KNOWLEDGE_CENTER_ERROR,
  KNOWLEDGE_CENTER_ERROR_SUB,
  KNOWLEDGE_CENTER_LOADING,
  KNOWLEDGE_CENTER_LOADING_SUB,
} from '../../../languages/en/header';
import KnowledgeCenterLoadingImage from '../../../img/opening-folder.gif';
import KnowledgeCenterErrorImage from '../../../img/error.svg';
import KnowledgeCenterNoSearchImage from '../../../img/searching-girl.svg';

import styled from 'styled-components';
import Body from '../../atoms/Body';
import useMobileApp from '../../../hooks/useMobileApp';

const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const ScrollableCardGrid = styled.div<{
  isMobileApp: boolean;
}>`
  height: ${({ isMobileApp }) =>
    windowSizeAdapter(isMobileApp, {
      mobileApp: 'calc(var(--mobileVh, 1vh) * 100 - 196px)',
      mobileWeb: 'calc(var(--mobileVh, 1vh) * 100 - 268px)',
      tablet: 'calc(100vh - 268px)',
      desktop: 'calc(100vh - 268px)',
    })};
  overflow: auto;
  padding: 8px 16px 16px 16px;
`;

const KnowledgeCenterCardGrid = (props: {
  data: InfiniteData<GetFeedAttachmentResponse> | undefined;
  hasNextPage: boolean | undefined;
  isLoading: boolean;
  goToPost: (flowId: string, responseId: string) => void;
  handleFetchNextPage: () => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI | undefined) => void;
  copyPostLink: (flowId: string, responseId: string) => void;
  onDownloadFileClick: (
    fileDownload: FeedAttachmentItemFromAPI,
  ) => Promise<void>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isMobileApp } = useMobileApp();

  useEffect(() => {
    trackKnowledgeCenterShowEvent({
      show: KnowledgeCenterEvents.KnowledgeCenterShow,
      view: 'gallery',
    });
  }, []);

  const {
    data,
    hasNextPage,
    isLoading,
    goToPost,
    handleFetchNextPage,
    handleSetSelectedFile,
    copyPostLink,
    onDownloadFileClick,
    setModalOpen,
  } = props;

  if (isLoading) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="loading-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_LOADING}
          src={KnowledgeCenterLoadingImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_LOADING}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_LOADING_SUB}</Body>
      </Flex>
    );
  } else if (data?.pages?.length === 0 || data?.pages[0].data?.length === 0) {
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="empty-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_EMPTY_SEARCH}
          src={KnowledgeCenterNoSearchImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_EMPTY_SEARCH}</Body>

        <Body variant="body1" style={{ width: '350px', marginTop: '4px' }}>
          {KNOWLEDGE_CENTER_EMPTY_SEARCH_SUB}
        </Body>
      </Flex>
    );
  } else if (!data) {
    trackKnowledgeCenterErrorEvent({
      error: KnowledgeCenterEvents.KnowledgeCenterError,
    });
    return (
      <Flex
        flexDirection="column"
        alignItems="center"
        margin="32px 0 0"
        data-testid="error-kc"
      >
        <StyledImage
          alt={KNOWLEDGE_CENTER_ERROR}
          src={KnowledgeCenterErrorImage}
        />

        <Body variant="subHead2Bold">{KNOWLEDGE_CENTER_ERROR}</Body>

        <Body variant="body1">{KNOWLEDGE_CENTER_ERROR_SUB}</Body>
      </Flex>
    );
  } else {
    return (
      <ScrollableCardGrid id="scrollableCardGrid" isMobileApp={isMobileApp}>
        <InfiniteScroll
          next={handleFetchNextPage}
          dataLength={data.pages.length}
          hasMore={Boolean(hasNextPage)}
          style={{
            overflow: 'inherit',
            display: 'grid',
            columnGap: '16px',
            rowGap: '16px',
            gridTemplateColumns: 'repeat(auto-fill, minmax(242px, 1fr))',
          }}
          scrollableTarget="scrollableCardGrid"
          loader={<KnowledgeCenterCardLoader />}
        >
          {data.pages.map((page, pageIndex) => (
            <>
              {page.data.map((card, cardIndex) => (
                <KnowledgeCenterCard
                  key={
                    card.response.blockId +
                    (card.fileInfo?.name || card.urlInfo?.name) +
                    card.response.responseId
                  }
                  card={card}
                  handleSetSelectedFile={handleSetSelectedFile}
                  openPreviewer={setModalOpen}
                  goToPost={goToPost}
                  onClickDownload={(cardFile) => onDownloadFileClick(cardFile)}
                  copyPostLink={copyPostLink}
                  pageIndex={pageIndex}
                  cardIndex={cardIndex}
                />
              ))}
            </>
          ))}
        </InfiniteScroll>
      </ScrollableCardGrid>
    );
  }
};

export default KnowledgeCenterCardGrid;
