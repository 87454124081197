import { EditorState } from 'draft-js';
import { TestContext } from 'yup';
import { Mention } from '../../interfaces/user';
import { getLiteralTextFromEditor } from '../draftjs/mention';
import { VALIDATION_ERRORS } from '../../languages/en/flows/participation';
import { File } from '../../atomic/molecules/FileCard/types';
import { checkForProfanity } from '../giveCarrots/validation';

type TestContextType = TestContext<Record<string, any>>;

export const exactLengthTest = (exactLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length === exactLength;

export const minimumCountTest = (minLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length >= minLength;

export const maximumCountTest = (maxLength: number) => (arr: any) =>
  arr?.length === 0 || arr?.length <= maxLength;

export const arrayRequiredTest = (arr: any[] | undefined) =>
  Boolean(arr?.length);

type OpenEndedRequiredTestParams = {
  editorState: EditorState;
  files: File[];
  gifUrl: string;
};

export const openEndedRequiredTest = (props: OpenEndedRequiredTestParams) => {
  const { editorState, files, gifUrl } = props;
  return (
    (editorState.getCurrentContent().hasText() &&
      editorState.getCurrentContent().getPlainText().trim().length > 0) ||
    files.length > 0 ||
    Boolean(gifUrl)
  );
};

export const checkOpenEndedBlockMinimumCharacters = (
  editorState: EditorState,
  selectedMentions: Mention[],
  testContext: TestContextType,
  minimumCharacterLimit: number,
) => {
  const messageLength = getLiteralTextFromEditor(editorState).trim().length;

  if (messageLength < minimumCharacterLimit) {
    const amountUnderCharacterLimit = minimumCharacterLimit - messageLength;
    const message = VALIDATION_ERRORS.generateMinimumCharacterLimitError(
      amountUnderCharacterLimit,
    );
    return testContext.createError({ message });
  }

  return messageLength >= minimumCharacterLimit;
};

export const checkOpenEndedBlockMaximumCharacters = (
  editorState: EditorState,
  selectedMentions: Mention[],
  testContext: TestContextType,
  maximumCharacterLimit: number,
) => {
  const messageLength = getLiteralTextFromEditor(editorState).trim().length;
  if (messageLength > maximumCharacterLimit) {
    const amountOverCharacterLimit = messageLength - maximumCharacterLimit;
    const message = VALIDATION_ERRORS.generateMaximumCharacterLimitError(
      amountOverCharacterLimit,
    );
    return testContext.createError({ message });
  }

  return maximumCharacterLimit >= messageLength;
};

export const openEndedProfanityTest = (
  editorState: EditorState,
  testContext: TestContextType,
  wordsToCheck: string[],
) => {
  const message = getLiteralTextFromEditor(editorState).trim().toLowerCase();
  if (checkForProfanity(message, wordsToCheck)) {
    return testContext.createError({
      message: VALIDATION_ERRORS.PROFANE_ERROR_MESSAGE,
    });
  }
  return true;
};
