type Connection = {
  connectionId: string;
  workspaceName: string;
  workspaceLogo: string;
};

export type Channel = {
  id: string;
  isPrivate: boolean;
  displayName: string;
};

type Source = {
  id: string[];
  type: string;
};

type Integrations = {
  type: string;
  source: Source;
};

export type ConnectionConfigResponse = {
  clientId: string;
  authorizeUri: string;
  oauthScopes: string[];
};

export type CreateConnectionPoolRequest = {
  code: string;
  redirectUri: string;
  integrationName: 'slack';
};

export type CreateConnectionPoolResponse = {
  connectionId: string;
};

export type GetConnectionsListResponse = {
  data: Connection[];
};

export type GetChannelsListJobResponse = {
  integrationDataJob: {
    id: string;
    status: string;
  };
};

export type GetChannelsListResponse = {
  channels: Channel[];
  applicationCacheId: string;
};

export type SaveConnectionIntegrationRequest = {
  connectionId: string;
  applicationCacheId: string;
  integrations: Integrations[];
};

export const isGetChannelsListResponseType = (
  data: GetChannelsListResponse | GetChannelsListJobResponse,
) => {
  return 'channels' in data;
};

export const isGetChannelsListJobResponseType = (
  data: GetChannelsListResponse | GetChannelsListJobResponse,
) => {
  return 'integrationDataJob' in data;
};
