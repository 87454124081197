import qs from 'qs';
import React from 'react';
import useLayoutStore from '../../../stores/layoutStore';

import ProfileViewController from '../../home/ProfileViewController';

import { LayoutStoreState } from '../../../interfaces/Layout';
import { NotebookTaskDetailsController } from '../NotebookTaskDetailsController';

const layoutSelector = (state: LayoutStoreState) => state.selectedMember;

const ProfileFeedRightDrawerController = () => {
  const selectedMember = useLayoutStore(layoutSelector);

  const shouldShowTask =
    'taskId' in qs.parse(location.search, { ignoreQueryPrefix: true });

  if (shouldShowTask) {
    return <NotebookTaskDetailsController />;
  }

  return (
    <ProfileViewController
      firstName={selectedMember?.firstName}
      memberId={selectedMember?.memberId || ''}
    />
  );
};

export default ProfileFeedRightDrawerController;
