import { convertToRaw } from 'draft-js';
import { FeedPostUserProps } from '../../interfaces/Feed';
import { NotebookTask } from '../../interfaces/notebook';
import { Mention } from '../../interfaces/user';
import { convertTokenizedObjectToString } from '../notebook';

const getLiteralTextFromEditor = (editorState) => {
  try {
    const { blocks } = convertToRaw(editorState.getCurrentContent());

    const mappedBlocks = blocks.map(
      (block) => (!block.text.trim() && '\n') || block.text,
    );

    let newText = '';
    for (let i = 0; i < mappedBlocks.length; i++) {
      const block = mappedBlocks[i];

      // handle last block
      if (i === mappedBlocks.length - 1) {
        newText += block;
      } else {
        // otherwise we join with \n, except if the block is already a \n
        if (block === '\n') newText += block;
        else newText += `${block}\n`;
      }
    }
    return newText;
  } catch (e) {
    return '';
  }
};

const getTextFromEditor = (
  editorState,
  mentionsToReplace = [] as Mention[],
  tagsToReplace = [] as Mention[],
  tasksToReplace = [] as NotebookTask[],
) => {
  const replacedMentions: Mention[] = [];
  const replacedTags: Mention[] = [];
  const replacedTasks: NotebookTask[] = [];
  let newText = getLiteralTextFromEditor(editorState);
  mentionsToReplace.forEach((mention) => {
    const originalText = newText;
    newText = newText.replace(
      new RegExp(mention.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'g'),
      `@__${mention.id}__@`,
    );
    if (originalText !== newText) {
      replacedMentions.push(mention);
    }
  });
  tagsToReplace.forEach((tag) => {
    const originalText = newText;
    newText = newText.replace(new RegExp(tag.name, 'g'), `@__tag:${tag.id}__@`);
    if (originalText !== newText) {
      replacedTags.push(tag);
    }
  });
  tasksToReplace.forEach((task) => {
    const originalText = newText;
    // Escape special characters that may exist in the title before doing the replacement in the full text
    const escapedTitle = convertTokenizedObjectToString(task.note).replace(
      /[-[\]/{}()*+?.\\^$|]/g,
      '\\$&',
    );

    newText = newText.replace(
      new RegExp(escapedTitle, 'g'),
      `@__task:${task.noteId}__@`,
    );
    if (originalText !== newText) {
      replacedTasks.push(task);
    }
  });
  return { text: newText, replacedTags, replacedMentions, replacedTasks };
};

const getTrimmedMessage = (message) => {
  return message.trim(' ↵ ');
};

const getMentionsToReplace = (selectedMentions, employees) => {
  return selectedMentions.map((id) => {
    const employee = employees.find((emp) => emp.id === id);
    return {
      id,
      name: employee.name || '',
    };
  });
};

// https://stackoverflow.com/questions/47420136/how-to-check-empty-spaces-in-draft-js-editor
const getIsEditorHavingText = (editorState) => {
  const content = editorState.getCurrentContent();
  const isEditorEmpty = !content.hasText();
  const currentPlainText = content.getPlainText();
  const lengthOfEditorContent = currentPlainText.length;
  const lengthOfTrimmedContent = currentPlainText.trim().length;
  const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent;
  return lengthOfEditorContent !== 0 && !isContainOnlySpaces;
};

const mapTaggedUsersToSelectedMembers = (taggedUsers: FeedPostUserProps[]) => {
  return taggedUsers.map((user) => {
    return {
      id: user.memberID,
      name: `${user.firstName} ${user.lastName}`,
      link: '',
      avatar: user.image,
      memberState: user.memberState,
    };
  });
};

export {
  getTextFromEditor,
  getMentionsToReplace,
  getLiteralTextFromEditor,
  getIsEditorHavingText,
  getTrimmedMessage,
  mapTaggedUsersToSelectedMembers,
};
