import React from 'react';
import styled from 'styled-components';

import Heading from '../../atoms/Heading';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import Link from '../../atoms/Link';
import ThemeV2 from '../../../theme';
import {
  ButtonColors,
  ButtonStatuses,
  ButtonVariants,
} from '../../atoms/Button/interfaces';
import { BaseCSSProperties } from '@mui/styles';

export const StyledRoot = styled.div`
  width: 486px;
  margin: auto;
  padding: 24px 25px;
  background: ${ThemeV2.palette.gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 0;
  margin-bottom: 4px;
  text-align: center;
`;

export const StyledBody = styled(Body)`
  text-align: center;
  max-width: 436px;
  margin: 0 auto 24px;
`;

export const StyledSubText = styled(Body)`
  text-align: center;
  max-width: 436px;
  margin: 24px auto 0;
`;

interface StyleProps {
  root: BaseCSSProperties;
  headingText: BaseCSSProperties;
  bodyText: BaseCSSProperties;
  actionButton: BaseCSSProperties;
}

type PropsClasses = Record<keyof StyleProps, string>;

interface SSOInfoCardProps {
  headingText: string;
  bodyText: string;
  buttonText: string;
  buttonOnClick(): void;
  className?: string;
  classes?: PropsClasses;
  buttonIcon?: string;
  subText?: {
    text: string;
    linkText?: string;
    linkPath?: string;
  };
  buttonProps?: {
    variant?: ButtonVariants;
    status?: ButtonStatuses;
    colour?: ButtonColors;
  };
  password?: {
    passwordVal: string | null;
    onPasswordChange: () => void;
    onNextClick: () => void;
    passwordErrors?: string;
    isLoading?: boolean;
  };
}

const SSOInfoCard = (props: SSOInfoCardProps) => {
  const {
    headingText,
    bodyText,
    buttonText,
    buttonOnClick,
    className,
    buttonIcon,
    subText,
    buttonProps,
  } = props;

  let buttonColour: ButtonColors = 'primary';
  let buttonVariant: ButtonVariants = 'contained';
  let buttonStatus: ButtonStatuses = 'default';
  if (buttonProps) {
    buttonColour = buttonProps.colour || buttonColour;
    buttonVariant = buttonProps.variant || buttonVariant;
    buttonStatus = buttonProps.status || buttonStatus;
  }

  return (
    <StyledRoot className={className}>
      <StyledHeading variant="h5">{headingText}</StyledHeading>
      <StyledBody variant="body1">{bodyText}</StyledBody>
      <Button
        onClick={buttonOnClick}
        isFullWidth
        variant={buttonVariant}
        color={buttonColour}
        status={buttonStatus}
        icon={buttonIcon}
      >
        {buttonText}
      </Button>
      {subText && (
        <StyledSubText variant="body1">
          {subText.text}{' '}
          {subText.linkText && (
            <Link to={subText.linkPath}>{subText.linkText}</Link>
          )}
        </StyledSubText>
      )}
    </StyledRoot>
  );
};

export default SSOInfoCard;
