import { useCallback, useEffect, useMemo, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import {
  defaultValues,
  UseSearchDropDownValues,
} from '../../../atomic/organism/SearchDropdown';
import {
  useCurrentPlanFetchQuery,
  useProfileInfoFetchQuery,
} from '../../../queries/Profile';
import {
  getAssemblyCurrency,
  isFeatureEnabledInCurrentPlan,
  getIsCurrentUserAdmin,
} from '../../../queries/Profile/utils';
import { FEATURE_LIST } from '../../../Utils/account';
import useToggle from '../../../hooks/useToggle';
import {
  feedSearchPlaceholderLanguage,
  UpgradeButton,
} from '../../../Utils/data/feedSearch/input';
import { useMembersSearch } from '../../../hooks/useMembersSearch';
import { IMemberDTO } from '../../../interfaces/member';
import { AvatarProps } from '../../../atomic/atoms/Avatar/interfaces';
import { autoCompleteEmpty } from '../../../Utils/data/feedSearch/dropdown';
import { FilterSearchFormValues } from '../../../atomic/molecules/FilterSearchInput';
import { FeedSearchBarProps } from './interface';
import useLayoutStore from '../../../stores/layoutStore';

const ADMIN_BILLING_ACCOUNT = '/admin/billing/account/';

// interface SearchPostsSearchPayload {
//   from: string[];
//   to: string[];
//   keywords: string[];
//   currencyMin: number;
//   currencyMax: number;
//   startDate: Date;
//   endDate: Date;
// }

// interface SearchPostsPayload {
//   payload: {
//     search: Partial<SearchPostsSearchPayload>;
//     afterID?: string;
//     limit?: number;
//   };
// }

// Maybe use useRef for this
const defaultDisplayFormValues = {};

const getDisplayFormValues = (values: UseSearchDropDownValues) => ({
  from: values.from.map(({ displayText }) => displayText),
  to: values.to.map(({ displayText }) => displayText),
  keywords: values.keywords,
  startDate: values.startDate
    ? moment(values.startDate.toString()).format('MMMM D, YYYY')
    : undefined,
  endDate: values.endDate
    ? moment(values.endDate?.toString()).format('MMMM D, YYYY')
    : undefined,
  minCurrency:
    values.currencyMin && values.currencyMin > 0
      ? values.currencyMin.toString()
      : undefined,
  maxCurrency:
    values.currencyMax && values.currencyMax
      ? values.currencyMax.toString()
      : undefined,
});

const useFeedSearchBarLogic = (props: FeedSearchBarProps) => {
  const { push } = useHistory();
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { searchValue, onSearchChange } = props;

  const [isAutoCompletePristine, setIsAutoCompletePristine] =
    useState<boolean>(true);

  const [displayFormValues, setDisplayFormValues] =
    useState<FilterSearchFormValues>(
      !isEqual(searchValue, defaultValues)
        ? getDisplayFormValues(searchValue)
        : defaultDisplayFormValues,
    );
  const { data: currentPlan, isInitialLoading: isGetActivePlansLoading } =
    useCurrentPlanFetchQuery();
  const isFeedSearchFeatureEnabled = useMemo(
    () => isFeatureEnabledInCurrentPlan(FEATURE_LIST.FEED_SEARCH, currentPlan),
    [currentPlan],
  );
  const { data: profileData } = useProfileInfoFetchQuery();
  const assemblyCurrency = useMemo(
    () => getAssemblyCurrency(profileData),
    [profileData],
  );

  const {
    models: { toggleValue: isDropdownOpen },
    operations: { setToggleValue: toggleDropDown, setToggleToFalse },
  } = useToggle();

  const {
    models: {
      searchedMembers,
      isLoading: isMembersSearchLoading,
      isFetching: isMembersSearchFetching,
    },
    operations: { onChange: onUsersSearchChange },
  } = useMembersSearch(true);

  //Set Birthday/Anniversary Filter for V3 Important Celebration Card from V3
  const params = new URLSearchParams(window.location.search);
  const searchKeyword = params.get('searchKeyword');
  useEffect(() => {
    if (searchKeyword) {
      const values: UseSearchDropDownValues = {
        from: [],
        to: [],
        currencyMin: 0,
        currencyMax: 0,
        keywords: [searchKeyword],
      };
      onSearchChange(values);
      setDisplayFormValues(getDisplayFormValues(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserAdmin = getIsCurrentUserAdmin(profileData);

  const clearButtonVisible = !isEqual(
    displayFormValues,
    defaultDisplayFormValues,
  );

  const feedSearchPlaceholder = useMemo(() => {
    if (!isFeedSearchFeatureEnabled) {
      if (isUserAdmin) {
        return feedSearchPlaceholderLanguage.FREE_ADMIN;
      }
    }
    return feedSearchPlaceholderLanguage.PAID;
  }, [isFeedSearchFeatureEnabled, isUserAdmin]);

  const membersOptions = useMemo(() => {
    const members = searchedMembers || [];
    return members.map(
      ({ memberID, firstName, lastName, username, image }: IMemberDTO) => {
        const fullName = `${firstName} ${lastName}`;
        return {
          displayText: fullName,
          secondaryText: username && username.length > 0 ? `@${username}` : '',
          img: image || undefined,
          id: memberID,
          avatar: {
            img: image,
            name: fullName,
            userId: memberID,
          } as AvatarProps,
        };
      },
    );
  }, [searchedMembers]);

  const handleAutoCompleteInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onUsersSearchChange(value);
      if (value === '') {
        setIsAutoCompletePristine(true);
      } else {
        setIsAutoCompletePristine(false);
      }
    },
    [onUsersSearchChange],
  );

  const handleAutoCompleteFocus = useCallback(() => {
    setIsAutoCompletePristine(true);
  }, []);

  const feedSearchUpgradeButton = useMemo(() => {
    return {
      onUpgradeClick: () => {
        if (isEmbeddedInMainApp) {
          window.parent.postMessage(
            { type: 'NAVIGATE_TO_ADMIN', payload: '/billing/account' },
            '*',
          );
        } else {
          push(ADMIN_BILLING_ACCOUNT);
        }
      },
      buttonText: UpgradeButton.DEFAULT,
      disabled: false,
    };
  }, [isEmbeddedInMainApp, push]);

  const showPremiumMessage = !isFeedSearchFeatureEnabled && !isUserAdmin;

  const clearSearchPost = useCallback(() => {
    if (searchKeyword) {
      window.parent.postMessage(
        { type: 'CLEAR_QUERY_PARAMS', payload: 'searchKeyword' },
        '*',
      );
    }
    onSearchChange({ ...defaultValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSearchChange]);

  const handleSearchClick = useCallback(
    (values: UseSearchDropDownValues) => {
      onSearchChange(values);
      if (isEqual(values, defaultValues)) {
        clearSearchPost();
        setDisplayFormValues(defaultDisplayFormValues);
      } else {
        setDisplayFormValues(getDisplayFormValues(values));
      }
      toggleDropDown();
    },
    [clearSearchPost, onSearchChange, toggleDropDown],
  );

  const handleDisplayCloseClick = useCallback(() => {
    setToggleToFalse();
    setDisplayFormValues(defaultDisplayFormValues);
    clearSearchPost();
  }, [clearSearchPost, setToggleToFalse]);

  // isMembersSearchFetching
  //   ? autoCompleteEmpty.LOADING
  //   : !isAutoCompletePristine && membersOptions.length === 0
  //   ? autoCompleteEmpty.NO_OPTIONS
  //   : autoCompleteEmpty.PRISTINE,
  const autoCompleteEmptyText = useMemo(() => {
    if (isMembersSearchFetching) {
      return autoCompleteEmpty.LOADING;
    }
    if (!isAutoCompletePristine && membersOptions.length === 0) {
      return autoCompleteEmpty.NO_OPTIONS;
    }
    return autoCompleteEmpty.PRISTINE;
  }, [isAutoCompletePristine, isMembersSearchFetching, membersOptions]);

  return {
    operations: {
      onAutoCompleteInputChange: handleAutoCompleteInputChange,
      onAutoCompleteFocus: handleAutoCompleteFocus,
      onSearchClick: handleSearchClick,
      toggleDropDown,
      onDisplayCloseClick: handleDisplayCloseClick,
    },
    models: {
      isDropdownOpen,
      isGetActivePlansLoading,
      feedSearchPlaceholder,
      isFeedSearchDisabled: !isFeedSearchFeatureEnabled,
      currencyNamePlural: assemblyCurrency.pluralName,
      membersOptions,
      feedSearchUpgradeButton,
      showPremiumMessage,
      autoCompleteEmptyText,
      clearButtonVisible,
      displayFormValues,
      searchValue,
      isMembersSearchLoading,
    },
  };
};

export default useFeedSearchBarLogic;
