export const useCanvasConfetti = (
  parentElementId: string,
  maxParticleCount: number,
) => {
  let waveAngle = 0;
  const particleSpeed = 1;
  const particles: any[] = [];
  let animationTimer: any = null;
  let streamingConfetti = false;
  const colors = [
    'DodgerBlue',
    'OliveDrab',
    'Gold',
    'Pink',
    'SlateBlue',
    'LightBlue',
    'Violet',
    'PaleGreen',
    'SteelBlue',
    'SandyBrown',
    'Chocolate',
    'Crimson',
  ];

  const resetParticle = (particle: any, width: number, height: number) => {
    particle.color = colors[(Math.random() * colors.length) | 0];
    particle.x = Math.random() * width;
    particle.y = Math.random() * height - height;
    particle.diameter = Math.random() * 10 + 5;
    particle.tilt = Math.random() * 10 - 10;
    particle.tiltAngleIncrement = Math.random() * 0.07 + 0.05;
    particle.tiltAngle = 0;
    return particle;
  };

  const drawParticles = (context: any) => {
    let particle;
    let x;
    for (let i = 0; i < particles.length; i++) {
      particle = particles[i];
      context.beginPath();
      context.lineWidth = particle.diameter;
      context.strokeStyle = particle.color;
      x = particle.x + particle.tilt;
      context.moveTo(x + particle.diameter / 2, particle.y);
      context.lineTo(x, particle.y + particle.tilt + particle.diameter / 2);
      context.stroke();
    }
  };

  const updateParticles = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    let particle;
    waveAngle += 0.01;
    for (let i = 0; i < particles.length; i++) {
      particle = particles[i];
      if (!streamingConfetti && particle.y < -15) particle.y = height + 100;
      else {
        particle.tiltAngle += particle.tiltAngleIncrement;
        particle.x += Math.sin(waveAngle);
        particle.y +=
          (Math.cos(waveAngle) + particle.diameter + particleSpeed) * 0.5;
        particle.tilt = Math.sin(particle.tiltAngle) * 15;
      }
      if (particle.x > width + 20 || particle.x < -20 || particle.y > height) {
        if (streamingConfetti && particles.length <= maxParticleCount)
          resetParticle(particle, width, height);
        else {
          particles.splice(i, 1);
          i--;
        }
      }
    }
  };

  const startConfetti = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        function (callback) {
          return window.setTimeout(callback, 16.6666667);
        }
      );
    })();
    let canvas = document.getElementById(
      'confetti-canvas',
    ) as HTMLCanvasElement;
    if (canvas === null) {
      canvas = document.createElement('canvas');
      canvas.setAttribute('id', 'confetti-canvas');
      canvas.setAttribute(
        'style',
        'display:block;z-index:0;pointer-events:none;position:absolute',
      );
      const parentElement = document.getElementById(parentElementId);
      parentElement?.appendChild(canvas);
      canvas.width = width;
      canvas.height = height;
      window.addEventListener(
        'resize',
        function () {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        },
        true,
      );
    }
    const context = canvas.getContext('2d');
    while (particles.length < maxParticleCount)
      particles.push(resetParticle({}, width, height));
    streamingConfetti = true;
    if (animationTimer === null) {
      (function runAnimation() {
        if (context) {
          context.clearRect(0, 0, window.innerWidth, window.innerHeight);
          if (particles.length === 0) animationTimer = null;
          else {
            updateParticles();
            drawParticles(context);
            animationTimer = requestAnimationFrame(runAnimation);
          }
        }
      })();
    }
  };

  const stopConfetti = () => {
    particles.push([]);
    streamingConfetti = false;
  };

  return {
    stopConfetti,
    startConfetti,
  };
};
