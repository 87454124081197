import React from 'react';
import styled from 'styled-components';
import { Redirect, useParams } from 'react-router-dom';

import Image from '../../atomic/atoms/Image';

export const StyledImage = styled(Image)`
  margin: 48px 0;
`;

const SinglePostController = () => {
  const { postId } = useParams<{ postId: string }>();
  return <Redirect to={`/post/flow/recognition?postId=${postId}`} />;
};

export default SinglePostController;
