import {
  COPY_POST_LINK,
  DELETE_POST_AND_ANSWER,
  EDIT_ANNOUNCEMENT,
  EDIT_MY_POST,
  END_ANNOUNCEMENT,
  FOLLOW_THIS_POST,
  MANAGE_REPLIES_AND_REACTIONS,
  SHARE_AS_AN_ANNOUNCEMENT,
  UNFOLLOW_THIS_POST,
} from '../../../languages/en/home';
import { Nullable } from '../../../Utils/common';

const goToPostLink = {
  id: 'goto-post-link',
  prefixIcon: 'post-bw',
  isSelected: false,
  value: 'Go to post',
};

const copyPostLink = {
  id: 'copy-link',
  prefixIcon: 'Link',
  isSelected: false,
  value: 'Copy post link',
};

const copyLinkItem = {
  id: 'copy-link',
  prefixIcon: 'Link',
  isSelected: false,
  value: COPY_POST_LINK,
};

const deleteItem = {
  id: 'delete',
  prefixIcon: 'delete',
  deleteItem: true,
  isSelected: false,
  value: DELETE_POST_AND_ANSWER,
};

const editPostInteractionSettings = {
  id: 'edit-post-interaction-settings',
  prefixIcon: 'adjustments',
  isSelected: false,
  value: MANAGE_REPLIES_AND_REACTIONS,
};

const editPostItem = {
  value: EDIT_MY_POST,
  id: 'edit-post',
  prefixIcon: 'edit',
  isSelected: false,
  permissionRequired: true,
};

const shareAnnouncementItem = {
  isSelected: false,
  id: 'share-announcement',
  prefixIcon: 'mega-phone',
  permissionRequired: false,
  value: SHARE_AS_AN_ANNOUNCEMENT,
};

const editAnnouncement = {
  prefixIcon: 'edit',
  isSelected: false,
  id: 'edit-announcement',
  value: EDIT_ANNOUNCEMENT,
  permissionRequired: false,
};

const endAnnouncement = {
  isSelected: false,
  id: 'end-announcement',
  value: END_ANNOUNCEMENT,
  prefixIcon: 'stop-outline',
  permissionRequired: false,
};

const viewAnnouncementInsights = {
  isSelected: false,
  id: 'view-insights',
  value: 'View Insights',
  prefixIcon: 'chart-bar',
  permissionRequired: false,
};

const getNotificationPreferenceItem = (isNotificationMuted: boolean) => ({
  value: isNotificationMuted ? FOLLOW_THIS_POST : UNFOLLOW_THIS_POST,
  id: 'notifications-preference',
  prefixIcon: isNotificationMuted ? 'alarm' : 'muted',
  isSelected: false,
});

export const menuItemsWithCopyLink = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem],
  },
];

export const menuItemsWithCopyLinkAndDeletePost = [
  {
    id: 'feed-post-header-options',
    items: [copyLinkItem, deleteItem],
  },
];

export const getFeedPostHeaderMenuOptions = (
  isDeletable: boolean,
  isEditable: boolean,
  variant: Nullable<string> = null,
  notificationPreference?: { isNotificationMuted: boolean },
  showPostInteractionSettings?: boolean,
  canEndAnnouncement = false,
  canEditAnnouncement = false,
  canShareAsAnnouncement = false,
  canViewAnnouncementInsights = false,
) => {
  if (variant === 'sidebar') {
    const items = [goToPostLink, copyPostLink];
    if (notificationPreference) {
      items.push(
        getNotificationPreferenceItem(
          notificationPreference.isNotificationMuted,
        ),
      );
    }
    return [
      {
        id: 'feed-post-header-options',
        items: items,
      },
    ];
  }

  const menuItems = [copyLinkItem];

  if (canShareAsAnnouncement) {
    menuItems.unshift(shareAnnouncementItem);
  }

  if (canEditAnnouncement) {
    menuItems.push(editAnnouncement);
  }

  if (canEndAnnouncement) {
    menuItems.push(endAnnouncement);
  }

  if (canViewAnnouncementInsights) {
    menuItems.push(viewAnnouncementInsights);
  }

  if (isEditable) {
    menuItems.push(editPostItem);
  }
  if (notificationPreference) {
    menuItems.push(
      getNotificationPreferenceItem(notificationPreference.isNotificationMuted),
    );
  }

  if (showPostInteractionSettings) {
    menuItems.push(editPostInteractionSettings);
  }

  if (isDeletable) {
    menuItems.push(deleteItem);
  }

  return [
    {
      id: 'feed-post-header-options',
      items: menuItems,
    },
  ];
};
