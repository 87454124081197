import ContentLoader from 'react-content-loader';
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import ToolTip from '../ToolTipV2';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import { SlackWorkspaceAndChannelsSelectorProps } from './types';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';
import { feedPostHeaderModalCancelButtonText } from '../../../languages/en/home';
import { setShareSheetModalOpenSelector } from '../../../stores/flowBuilderStore/selectors';
import { ConnectionState } from '../../../controllers/flows/FlowsShareSheetController/ConnectionsController/types';

import {
  MultiSelectAutoComplete,
  SingleSelectAutoComplete,
} from '../../organism/Autocomplete';

import AddSlackBotMessage from './AddSlackBotMessage';

import {
  Link,
  Value,
  IconWrapper,
  ErrorMessage,
  StyledToolTip,
  DropdownWrapper,
  ChannelContainer,
  ToolTipContainer,
  SlackWorkspaceWrapper,
  ToolTipMessageWrapper,
  ChannelsListContainer,
  DisconnectSlackButton,
  DisconnectSlackWrapper,
  DropdownOptionsContainer,
  ConnectToWorkspaceHelperWrapper,
} from './styles';

import BasicModalTemplate, {
  ModalButtonProps,
} from '../../templates/BasicModalTemplate';

import responseSlackPreviewImage from '../../../img/response_slack_preview.png';
import participationSlackPreview from '../../../img/participation_slack_preview.png';
import { AutocompleteDropdownItem } from '../../organism/Autocomplete/interfaces';

const ParticipationPreviewTooltip = () => {
  return (
    <ToolTipContainer>
      <img src={participationSlackPreview} />
      <Body variant="body3" color="gray1" margin="8px 4px">
        This is how a participation reminder will look when it is sent to the
        channel you choose.
      </Body>
    </ToolTipContainer>
  );
};

const ResponsePreviewTooltip = () => {
  return (
    <ToolTipContainer>
      <img src={responseSlackPreviewImage} />
      <Body variant="body3" color="gray1" margin="8px 4px">
        This is how a response will look when it is sent to the channel that you
        choose.
      </Body>
    </ToolTipContainer>
  );
};

const Loader = () => (
  <Flex>
    <ContentLoader
      speed={2}
      viewBox="0 0 406 76"
      height="56px"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      data-testid="loader"
    >
      <rect x="0" y="0" rx="0" ry="0" width="318" height="16" />
      <rect x="0" y="24" rx="4" ry="4" width="318" height="16" />
      <rect x="0" y="48" rx="4" ry="4" width="318" height="16" />
    </ContentLoader>
  </Flex>
);

const SlackWorkspaceAndChannelsSelector = (
  props: SlackWorkspaceAndChannelsSelectorProps,
) => {
  const {
    state,
    setState,
    channelList,
    flowDetails,
    connectionsList,
    isLoadingChannels,
    selectedConnection,
    isLoadingConnections,
    isDeletingConnection,
    setSelectedConnection,
    onDisconnectSlackClick,
    isGetChannelsListJobQueued,
    selectedPeopleToPostChannel,
    handleOnRefreshChannelClick,
    setSelectedPeopleToPostChannel,
    selectedPostFlowResponseChannel,
    onConnectDifferentWorkspaceClick,
    setSelectedPostFlowResponseChannel,
  } = props;

  const history = useHistory();
  const { pathname } = useLocation();
  const setShareSheetModalOpen = useFlowBuilderStore(
    setShareSheetModalOpenSelector,
  );

  const { setIsConnectionsTabTouched, flowName } = useFlowBuilderStore();

  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState(false);

  const primaryButton: ModalButtonProps = {
    text: `Yes, disconnect`,
    status: 'warning',
    isLoading: false,
    onClick: onDisconnectSlackClick,
  };

  const secondaryButton: ModalButtonProps = {
    text: feedPostHeaderModalCancelButtonText,
    isLoading: false,
    onClick: () => setIsDisconnectModalOpen(false),
  };

  const hasNoChannelsSelectedForParticipation =
    !flowDetails?.data.integrations ||
    !flowDetails?.data.integrations.participationNotification.length ||
    !flowDetails?.data.integrations.participationNotification[0].metaData ||
    flowDetails?.data.integrations.participationNotification[0].metaData
      .channelInfo.length === 0;

  const hasNoChannelsSelectedForResponse =
    !flowDetails?.data.integrations ||
    !flowDetails?.data.integrations.responseNotification.length ||
    !flowDetails?.data.integrations.responseNotification[0].metaData ||
    flowDetails?.data.integrations.responseNotification[0].metaData?.channelInfo
      .length === 0;

  const isLoadingChannelsAndConnectionSelected =
    isLoadingChannels && selectedConnection && selectedConnection.length > 0;

  const handleOnChangeTriggerClick = useCallback(() => {
    history.push(`${pathname}/editor`);
    setShareSheetModalOpen(false);
  }, [history, pathname, setShareSheetModalOpen]);

  const handlePostFlowResponseChannelChange = useCallback(
    (e) => {
      trackShareSheetActionEvent({
        app: 'slack',
        tab: 'connections',
        flowName: flowName,
        channelType: 'response',
        responseNotificationChannel: e?.map((item) => item.title).join(', '),
        action: SHARE_SHEET_EVENTS.CHANNEL_SELECTED,
      });

      setSelectedPostFlowResponseChannel(e);
    },
    [flowName, setSelectedPostFlowResponseChannel],
  );

  const handleOnChangePeopleToPostChannel = useCallback(
    (e) => {
      trackShareSheetActionEvent({
        app: 'slack',
        tab: 'connections',
        flowName: flowName,
        channelType: 'notification',
        participationNotificationChannel: e
          ?.map((item) => item.title)
          .join(', '),
        action: SHARE_SHEET_EVENTS.CHANNEL_SELECTED,
      });

      setSelectedPeopleToPostChannel(e);
    },
    [flowName, setSelectedPeopleToPostChannel],
  );

  const handleOnConnectDifferentWorkspaceClick = useCallback(
    (val: AutocompleteDropdownItem<string> | null) => {
      if (val) {
        setSelectedConnection([val]);

        trackShareSheetActionEvent({
          app: 'slack',
          tab: 'connections',
          flowName: flowName,
          action: SHARE_SHEET_EVENTS.WORKSPACE_CLICKED,
        });
      }
    },
    [flowName, setSelectedConnection],
  );

  const handleOnAddNewWorkspaceClick = useCallback(() => {
    trackShareSheetActionEvent({
      app: 'slack',
      tab: 'connections',
      flowName: flowName,
      action: SHARE_SHEET_EVENTS.ADD_NEW_WORKSPACE_CLICKED,
    });
    onConnectDifferentWorkspaceClick();
  }, [flowName, onConnectDifferentWorkspaceClick]);

  return (
    <>
      {state === ConnectionState.ConnectionEditState && (
        <section>
          <section>
            <Body variant="body2Medium" color="gray9">
              Choose a workspace
            </Body>

            <Body variant="body3" color="gray9">
              Select the workspace would you like to notify people to post and
              share responses in.
            </Body>
            <SlackWorkspaceWrapper>
              <SingleSelectAutoComplete
                autoFocus
                label="Choose a workspace"
                onChange={handleOnConnectDifferentWorkspaceClick}
                options={connectionsList}
                value={
                  selectedConnection && selectedConnection.length > 0
                    ? selectedConnection[0]
                    : null
                }
                multiple={false}
              >
                <ConnectToWorkspaceHelperWrapper variant="body2" color="gray8">
                  <Flex
                    style={{ width: '100%' }}
                    onMouseDown={handleOnAddNewWorkspaceClick}
                  >
                    <IconWrapper>
                      <SVGIcon size="16px" icon="connect" />
                    </IconWrapper>
                    <div>Connect to a different workspace</div>
                  </Flex>
                </ConnectToWorkspaceHelperWrapper>
              </SingleSelectAutoComplete>
            </SlackWorkspaceWrapper>

            <DropdownOptionsContainer>
              <Body variant="body2Medium" color="gray9">
                Notify people to post in the flow
              </Body>

              <Body variant="body3" color="gray9">
                Choose a channel to notify people to post in the flow. Anyone in
                this channel will be able to post. <br /> What does this look
                like?{' '}
                <ToolTip
                  position="bottom-start"
                  toolTipComponent={<Link>See Preview</Link>}
                  positionStrategy="fixed"
                >
                  <ParticipationPreviewTooltip />
                </ToolTip>
              </Body>

              <DropdownWrapper>
                {flowDetails?.data.kind === 'NO_TRIGGER' ? (
                  <StyledToolTip
                    toolTipComponent={
                      <MultiSelectAutoComplete
                        disabled
                        autoFocus
                        multiple={true}
                        options={channelList}
                        value={selectedPeopleToPostChannel}
                        label="Choose a channel"
                        onChange={() => {}}
                      >
                        <AddSlackBotMessage />
                      </MultiSelectAutoComplete>
                    }
                    position="top"
                    enableToolTipHover
                    positionStrategy="fixed"
                  >
                    <ToolTipMessageWrapper onClick={handleOnChangeTriggerClick}>
                      <div>This flow does not notify people to respond.</div>
                      <div>
                        <u>Change the trigger</u> to enable these notifications
                      </div>
                    </ToolTipMessageWrapper>
                  </StyledToolTip>
                ) : (
                  <MultiSelectAutoComplete
                    multiple={true}
                    options={channelList}
                    inputBaseRightPadding="24px"
                    value={selectedPeopleToPostChannel}
                    label={
                      isGetChannelsListJobQueued
                        ? 'Loading channels... this could take up to 15 minutes if you have a lot'
                        : 'Choose a channel'
                    }
                    helperText={
                      isGetChannelsListJobQueued
                        ? 'Feel free to leave and come back while they load'
                        : ' '
                    }
                    onChange={handleOnChangePeopleToPostChannel}
                    loading={isLoadingChannelsAndConnectionSelected}
                    disabled={isLoadingChannels}
                  >
                    <AddSlackBotMessage onClick={handleOnRefreshChannelClick} />
                  </MultiSelectAutoComplete>
                )}
              </DropdownWrapper>
            </DropdownOptionsContainer>

            <DropdownOptionsContainer>
              <Body variant="body2Medium" color="gray9">
                Post flow responses
              </Body>

              <Body variant="body3" color="gray9">
                Send submitted posts to a Slack channel. Everyone in this
                channel will be able to see the posts. <br /> What does this
                look like?{' '}
                <ToolTip
                  position="bottom-start"
                  positionStrategy="fixed"
                  toolTipComponent={<Link>See Preview</Link>}
                >
                  <ResponsePreviewTooltip />
                </ToolTip>
              </Body>

              <DropdownWrapper>
                <MultiSelectAutoComplete
                  autoFocus
                  multiple={true}
                  options={channelList}
                  label={
                    isGetChannelsListJobQueued
                      ? 'Loading channels... this could take up to 15 minutes if you have a lot'
                      : 'Choose a channel'
                  }
                  helperText={
                    isGetChannelsListJobQueued
                      ? 'Feel free to leave and come back while they load'
                      : ' '
                  }
                  inputBaseRightPadding="24px"
                  loading={isLoadingChannelsAndConnectionSelected}
                  value={selectedPostFlowResponseChannel}
                  disabled={isLoadingChannels}
                  onChange={handlePostFlowResponseChannelChange}
                >
                  <AddSlackBotMessage onClick={handleOnRefreshChannelClick} />
                </MultiSelectAutoComplete>
              </DropdownWrapper>
            </DropdownOptionsContainer>
          </section>

          <BasicModalTemplate
            disableContentScroll
            isOpen={isDisconnectModalOpen}
            heading="Disconnect Slack?"
            primaryButton={primaryButton}
            secondaryButton={secondaryButton}
            onModalClose={() => setIsDisconnectModalOpen(false)}
          >
            <Body variant="body2">
              Disconnecting Slack from this flow will stop sending post
              notifications and/or new posts into your specified channels. You
              can re-connect at anytime. Do you want to disconnect?
            </Body>
          </BasicModalTemplate>

          <DisconnectSlackWrapper>
            <Body variant="body2" color="gray9">
              Don’t want this flow to be integrated with Slack?
            </Body>
            <DisconnectSlackButton
              size="small"
              variant="text"
              status="warning"
              icon="api-filled"
              color="secondary"
              disabled={
                isLoadingChannels ||
                isLoadingConnections ||
                isDeletingConnection
              }
              isLoading={isDeletingConnection}
              onClick={() => setIsDisconnectModalOpen(true)}
            >
              Disconnect
            </DisconnectSlackButton>
          </DisconnectSlackWrapper>
        </section>
      )}

      {state === ConnectionState.ConnectionDisplayState && (
        <>
          {!selectedConnection || selectedConnection.length === 0 ? (
            <Loader />
          ) : (
            <section>
              <Flex>
                <Body variant="body2Medium" color="gray9">
                  Workspace
                </Body>
                <Value variant="body2" color="gray9">
                  {selectedConnection[0].title}
                </Value>
              </Flex>

              <ChannelsListContainer>
                <Body variant="body2Medium" color="gray9" inline>
                  Notify people to post in the flow
                </Body>
                <Value
                  inline
                  variant="body2"
                  color={
                    hasNoChannelsSelectedForParticipation ? 'gray7' : 'gray9'
                  }
                >
                  {hasNoChannelsSelectedForParticipation ? (
                    ' no channel selected'
                  ) : flowDetails?.data.integrations
                      ?.participationNotification[0].connectionStatus ===
                    'CONNECTED' ? (
                    flowDetails?.data.integrations?.participationNotification[0]?.metaData?.channelInfo?.map(
                      (channelInfo, index) => (
                        <React.Fragment key={channelInfo.id}>
                          {index !== 0 && ', '}
                          {channelInfo.isPrivate ? (
                            <ChannelContainer>
                              <SVGIcon icon="lock" size="14px" />{' '}
                              {channelInfo.name}
                            </ChannelContainer>
                          ) : (
                            <span># {channelInfo.name}</span>
                          )}
                        </React.Fragment>
                      ),
                    )
                  ) : (
                    <ErrorMessage>
                      {
                        flowDetails?.data.integrations
                          ?.participationNotification[0].connectionMessage
                      }
                      ,{' '}
                      <Link
                        onClick={() => {
                          setIsConnectionsTabTouched(true);
                          setState(ConnectionState.ConnectionEditState);
                        }}
                      >
                        connect a new one
                      </Link>
                    </ErrorMessage>
                  )}
                </Value>
              </ChannelsListContainer>

              <ChannelsListContainer>
                <Body variant="body2Medium" color="gray9" inline>
                  Post flow responses
                </Body>
                <Value
                  inline
                  variant="body2"
                  color={hasNoChannelsSelectedForResponse ? 'gray7' : 'gray9'}
                >
                  {hasNoChannelsSelectedForResponse ? (
                    ' no channel selected'
                  ) : flowDetails?.data.integrations?.responseNotification[0]
                      .connectionStatus === 'CONNECTED' ? (
                    flowDetails?.data.integrations?.responseNotification[0]?.metaData?.channelInfo?.map(
                      (channelInfo, index) => (
                        <React.Fragment key={channelInfo.id}>
                          {index !== 0 && ', '}
                          {channelInfo.isPrivate ? (
                            <ChannelContainer>
                              <SVGIcon icon="lock" size="14px" />{' '}
                              {channelInfo.name}
                            </ChannelContainer>
                          ) : (
                            <span># {channelInfo.name}</span>
                          )}
                        </React.Fragment>
                      ),
                    )
                  ) : (
                    <ErrorMessage>
                      {
                        flowDetails?.data.integrations?.responseNotification[0]
                          .connectionMessage
                      }
                      ,{' '}
                      <Link
                        onClick={() => {
                          setIsConnectionsTabTouched(true);
                          setState(ConnectionState.ConnectionEditState);
                        }}
                      >
                        connect a new one
                      </Link>
                    </ErrorMessage>
                  )}
                </Value>
              </ChannelsListContainer>
            </section>
          )}
        </>
      )}
    </>
  );
};

export default SlackWorkspaceAndChannelsSelector;
