/* eslint-disable max-len */
import React, { useMemo, memo, useCallback, useState } from 'react';
import { ComponentStatus } from '../../../../interfaces/component';
import {
  Assignee,
  DropdownOptions,
  NotebookTask,
  TaskCategories,
} from '../../../../interfaces/notebook';
import {
  NOTEBOOK_TASK_DETAILS_HEADER,
  NOTEBOOK_TASK_DETAILS_URL_DETAILS,
  CREATED_BY,
  DUE_DATE,
  ASSIGNEE,
  TASK_HISTORY,
  ARCHIVED_DATE,
  COMPLETED_DATE,
  NOTEBOOK_TASK_DETAILS_ERROR,
  DELETED_TASK_BANNER,
  DESCRIPTION_PLACEHOLDER,
  ABOUT_NOTEBOOK_TITLE,
  ABOUT_NOTEBOOK_DESCRIPTION_1,
  ABOUT_NOTEBOOK_DESCRIPTION_2,
  SEE_MORE,
  NOTEBOOK_TASK_DETAILS_ACCESS_ERROR,
  MARK_AS_COMPLETED,
  LINK_COPY_SUCCESS_TOAST,
  COMPLETED,
  NOTEBOOK_TASK_DETAILS_DELETED_ERROR,
} from '../../../../languages/en/notebook';
import ThemeV2 from '../../../../theme';
import {
  DESCRIPTION,
  ON,
  SECTION,
  TASK,
  YOU,
} from '../../../../languages/en/singleWords';
import {
  convertStringToTokenizedObject,
  convertTokenizedObjectToString,
  isAssignedToCurrentMember,
} from '../../../../Utils/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import Button from '../../../atoms/Button';
import Image from '../../../atoms/Image';
import {
  NotebookRightDrawerAssigneeLoader,
  NotebookRightDrawerDateLoader,
  NotebookRightDrawerTaskHistoryLoader,
  NotebookRightDrawerTaskLoader,
} from '../../../molecules/Notebook/Loader';
import TaskItemDatePicker from '../../../molecules/Notebook/TaskItem/TaskItemDatePicker';
import TaskItemTextArea from '../../../molecules/Notebook/TaskItem/TaskItemTextArea';
import {
  Wrapper,
  Block,
  BlockTitle,
  CreatedByText,
  ErrorTitle,
  ErrorSubtitle,
  Header,
  Heading,
  DescriptionPlaceholderText,
  TextAreaWrapper,
  ShowMoreButton,
  AssigneeWrapper,
  Description,
  StatusWrapper,
  IconButtonWrapper,
  BlockContent,
  MarkAsCompleteButton,
  CompleteButton,
  Icon,
  DropdownIcon,
} from './styles';
import RightDrawerErrorImage from '../../../../img/home/right-drawer-error.svg';
import RightDrawerAccessError from '../../../atoms/SVGIcon/icons/clip-data-protection.svg';
import { Flex } from '../../../../Utils/styles/display';
import SVGIcon from '../../../atoms/SVGIcon';
import format from 'date-fns/format';
import { FORMAT_DATE } from '../../../../constants/notebook';
import InfoAlert from '../../../molecules/InfoAlert';
import TaskContent from '../../../molecules/Notebook/TaskContent';
import { copyToClipboard, isEmpty } from '../../../../Utils/common';
import TaskItemAssigneeSelector from '../TaskItemAssigneeSelector';
import { getFullName } from '../../../../Utils/user';
import TaskHistory from '../../../molecules/Notebook/TaskHistory';
import { TaskHistoryActivity } from '../../../molecules/Notebook/TaskHistory/interface';
import IconButton from '../../../atoms/IconButton_V2';
import { ButtonSize } from '../../../atoms/IconButton_V2/interface';
import { SelectedMember } from '../../../../interfaces/Layout';
import Body from '../../../atoms/Body';
import { showSuccessMessage } from '../../../../Utils/toast';
import {
  getTaskCategoryBasedOnDueDate,
  getTaskItemDueDate,
} from '../../../../controllers/notebook/NotebookViewController/utils';
import { useLocation } from 'react-router-dom';
import Divider from '../../../atoms/Divider';
import { RightDrawerTaskDropdownOperations } from '../../../../controllers/notebook/NotebookRightDrawerController/useNotebookRightDrawerController/data';
import DropdownWithChildren from '../../../molecules/DropdownWithChildren';
import {
  DropdownPlacement,
  MenuItemIndividualItem,
} from '../../../molecules/Dropdown_V2/interfaces';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../../constants/layout';
import { RightDrawerHeader } from '../../../molecules/RightDrawerHeader';
import useLayoutStore from '../../../../stores/layoutStore';
import { NotebookSunsetBanner } from '../../../atoms/NotebookSunsetBanner';

const customBannerVariant = {
  background: ThemeV2.palette.dustRed2,
  border: ThemeV2.palette.dustRed6,
  color: ThemeV2.palette.darkGray9,
  focusColor: ThemeV2.palette.dustRed6,
  iconColor: ThemeV2.palette.dustRed6,
};

export enum ReadOnlyComponentStatus {
  READ_ONLY = 'readOnly',
}

type LoadedNotebookTaskDetailsProps = {
  handleUserClick: (member: SelectedMember) => void;
  toggleHistoryLogSort: () => void;
  historyLogSort: string;
  fetchMoreLogs: () => void;
  hasMoreLogsToFetch?: boolean;
  isFetchingMoreLogs: boolean;
  taskHistoryList: TaskHistoryActivity[];
  isTaskHistoryLoading: boolean;
  currentUserId: string;
  task: NotebookTask;
  status: ComponentStatus.LOADED;
  editTaskContent: (updatedTask: NotebookTask) => void;
  handleTaskUpdate: (
    updatedTask: NotebookTask,
    previousCategory: TaskCategories,
  ) => void;
  setTaskIdOfFocusedTask: (taskId: string, isDelete?: boolean) => void;
  fetchMoreAssignees: () => void;
  assigneesList: Assignee[] | undefined;
  hasMoreAssigneesToFetch: boolean;
  isFetchingMoreAssignees: boolean;
  searchValue: string;
  handleSearchOnAssigneeList: (searchValue: string) => void;
  handleAssigneeChange: (
    task: NotebookTask,
    oldAssignee: Assignee,
    newAssignee: Assignee,
  ) => void;
  currentUserTimezone: string;
  closeRightDrawer: () => void;
  dropdownOptions?: DropdownOptions[];
  archiveTask: (task: NotebookTask) => void;
  sectionDropdownOptions?: MenuItemIndividualItem[];
  isTaskSectionDataLoading?: boolean;
  isReadOnly: boolean;
  deleteTask: (taskId: string) => void;
};

type LoadingNotebookTaskDetailsProps = {
  status: ComponentStatus.LOADING;
};

type ErrorNotebookTaskDetailsProps = {
  status: ComponentStatus.ERROR;
  isAccessError: boolean;
  isDeletedError: boolean;
};

type ReadOnlyNotebookTaskDetailsProps = {
  status: ReadOnlyComponentStatus.READ_ONLY;
};

const LoadedNotebookTaskDetails = ({
  historyLogSort,
  toggleHistoryLogSort,
  currentUserId,
  dropdownOptions,
  task,
  assigneesList,
  hasMoreAssigneesToFetch,
  searchValue,
  isFetchingMoreAssignees,
  currentUserTimezone,
  setTaskIdOfFocusedTask,
  fetchMoreAssignees,
  handleSearchOnAssigneeList,
  editTaskContent,
  handleTaskUpdate,
  isTaskHistoryLoading,
  taskHistoryList,
  handleAssigneeChange,
  fetchMoreLogs,
  hasMoreLogsToFetch,
  isFetchingMoreLogs,
  handleUserClick,
  closeRightDrawer,
  archiveTask,
  sectionDropdownOptions,
  isTaskSectionDataLoading = false,
  isReadOnly,
  deleteTask,
}: LoadedNotebookTaskDetailsProps) => {
  const { pathname } = useLocation();

  const {
    note,
    createdAt,
    dueDate,
    type,
    isDeleted,
    description,
    createdBy,
    assignedTo,
    taskId,
    noteId,
    section,
    sectionTitle,
    stateEffectiveAt,
  } = task;

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [descriptionEditMode, setDescriptionEditMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const onDropdownOpen = (open: boolean) => {
    setIsOpen(open);
  };

  const sectionName = useMemo(() => {
    if (isReadOnly) {
      return sectionTitle;
    }

    const currentSection =
      sectionDropdownOptions?.filter((i) => i?.id === section) || [];
    if (currentSection?.length > 0) {
      return currentSection[0].value;
    }
    return '';
  }, [section, sectionDropdownOptions, isReadOnly, sectionTitle]);

  const sectionDropdownOptionsData = useMemo(() => {
    return [
      {
        id: 'section-right-drawer-menu',
        items: (sectionDropdownOptions || []).filter((i) => i?.id !== section),
      },
    ];
  }, [section, sectionDropdownOptions]);

  const serializedTitle = useMemo(
    () => convertTokenizedObjectToString(note),
    [note],
  );

  const serializedDescription = useMemo(
    () => convertTokenizedObjectToString(description),
    [description],
  );

  const createdByName = createdBy
    ? createdBy.memberID === currentUserId
      ? YOU.toLocaleLowerCase()
      : getFullName(createdBy)
    : YOU.toLocaleLowerCase();

  const dueDateLabel = useMemo(() => {
    if (task.type === TaskCategories.ARCHIVED) return ARCHIVED_DATE;
    if (task.type === TaskCategories.COMPLETED) return COMPLETED_DATE;
    return DUE_DATE;
  }, [task]);

  const onTitleChange = useCallback(
    (newTitleValue: string) => {
      setTitleEditMode(false);
      if (newTitleValue && newTitleValue !== serializedTitle) {
        editTaskContent({
          ...task,
          note: convertStringToTokenizedObject(newTitleValue),
        });
      }
    },
    [editTaskContent, serializedTitle, task],
  );

  const handleAssigneeSelection = useCallback(
    (assignee: Assignee) => {
      const updatedTask = { ...task, assignedTo: assignee };
      handleAssigneeChange(updatedTask, assignedTo, assignee);
    },
    [assignedTo, handleAssigneeChange, task],
  );

  const onDescriptionChange = useCallback(
    (newDescriptionValue: string) => {
      setDescriptionEditMode(false);
      if (newDescriptionValue !== serializedDescription) {
        editTaskContent({
          ...task,
          description: isEmpty(newDescriptionValue.trimEnd())
            ? []
            : convertStringToTokenizedObject(newDescriptionValue.trimEnd()),
        });
      }
    },
    [editTaskContent, serializedDescription, task],
  );

  const setDueDate = useCallback(
    (updatedDueDate: string | undefined) => {
      const previousCategory = task.type;
      const updatedCategory = getTaskCategoryBasedOnDueDate(
        updatedDueDate,
        currentUserTimezone,
      );
      const updatedTask: NotebookTask = {
        ...task,
        dueDate: updatedDueDate,
        type: updatedCategory,
      };
      handleTaskUpdate(updatedTask, previousCategory);
    },
    [task, currentUserTimezone, handleTaskUpdate],
  );

  const markTaskAsCompleted = useCallback(() => {
    const previousCategory = task.type;
    const updatedTask: NotebookTask = {
      ...task,
      type: TaskCategories.COMPLETED,
      stateEffectiveAt: getTaskItemDueDate(
        TaskCategories.TODAY,
        currentUserTimezone,
      ),
      // TO DO - Sumedha : Fix the ID
      // status: DefinedNotebookSections.Completed,
    };
    handleTaskUpdate(updatedTask, previousCategory);
  }, [task, currentUserTimezone, handleTaskUpdate]);

  const handleSectionChange = useCallback(
    (updatedSection: string | number) => {
      const updatedTask: NotebookTask = {
        ...task,
        section: `${updatedSection}`,
      };
      handleTaskUpdate(updatedTask, task?.type);
    },
    [task, handleTaskUpdate],
  );

  const { isEmbeddedInMainApp } = useLayoutStore();

  const renderTitleEditor = () => {
    return (
      <TextAreaWrapper
        onClick={() => {
          setTitleEditMode(true);
        }}
        isDisabled={isDeleted || isReadOnly}
      >
        {titleEditMode ? (
          <TaskItemTextArea
            autofocus
            serializedTitle={serializedTitle}
            onBlur={onTitleChange}
            handleEnterClick={onTitleChange}
            disabled={isDeleted}
            stopPropagation={false}
            hasHoverEffect
            textVariant="subHead2Medium"
            variant="custom"
          />
        ) : (
          <TaskContent
            strikeThroughOverride={false}
            content={note}
            disabled={false}
            stopPropagation={false}
            hasHoverEffect
            variant="subHead2Medium"
          />
        )}
      </TextAreaWrapper>
    );
  };

  const renderDescriptionEditor = () => {
    return (
      <TextAreaWrapper
        onClick={() => {
          setDescriptionEditMode(true);
        }}
        isDisabled={isDeleted || isReadOnly}
        data-testid={`description-wrapper-${task.noteId}`}
      >
        {descriptionEditMode ? (
          <TaskItemTextArea
            autofocus
            serializedTitle={serializedDescription}
            onBlur={onDescriptionChange}
            handleEnterClick={onDescriptionChange}
            disabled={isDeleted}
            stopPropagation={false}
            variant="custom"
            enableShiftNewLine
            hasHoverEffect
            textVariant="body1"
          />
        ) : (
          <Description>
            {isEmpty(serializedDescription) && (
              <DescriptionPlaceholderText
                variant="body1"
                color={isReadOnly ? 'gray7' : 'gray6'}
              >
                {isReadOnly ? 'No description' : DESCRIPTION_PLACEHOLDER}
              </DescriptionPlaceholderText>
            )}
            <TaskContent
              strikeThroughOverride={false}
              content={description}
              disabled={false}
              stopPropagation={false}
              noPadding={false}
              hasHoverEffect
              data-testid={`description-${task.noteId}`}
              variant="body1"
            />
          </Description>
        )}
      </TextAreaWrapper>
    );
  };
  const handleMenuItemClick = useCallback(
    (value: string | number) => {
      const operation = value as RightDrawerTaskDropdownOperations;
      switch (operation) {
        case RightDrawerTaskDropdownOperations.COPY_LINK:
          showSuccessMessage(LINK_COPY_SUCCESS_TOAST);
          copyToClipboard(
            `${window.location.origin}${
              isEmbeddedInMainApp ? `/a${pathname}` : pathname
            }?taskId=${task.noteId}`,
          );
          break;

        case RightDrawerTaskDropdownOperations.ARCHIVE:
          archiveTask({
            ...task,
            type: TaskCategories.ARCHIVED,
            section,
          });
          break;

        case RightDrawerTaskDropdownOperations.DELETE:
          deleteTask(task?.taskId);
          break;

        default:
          break;
      }
    },
    [isEmbeddedInMainApp, pathname, task, archiveTask, section, deleteTask],
  );

  return (
    <>
      {isMobileView && (
        <>
          <RightDrawerHeader
            title={NOTEBOOK_TASK_DETAILS_HEADER}
            icon="task-checklist"
            onCloseClick={closeRightDrawer}
          >
            {!isDeleted && (
              <DropdownIcon
                menuItems={dropdownOptions || []}
                icon="more"
                title="more"
                onItemClick={handleMenuItemClick}
                iconColor={ThemeV2.palette.gray8}
                rounded
                stopEventPropagation
                size={ButtonSize.Medium}
              />
            )}
          </RightDrawerHeader>
        </>
      )}
      <Wrapper>
        <Header justifyContent="space-between">
          {!isMobileView && (
            <>
              <Flex>
                <SVGIcon icon="task-checklist" size="36px" />
                <Heading variant="body1">
                  {NOTEBOOK_TASK_DETAILS_HEADER}
                </Heading>
              </Flex>
              <Flex>
                {!isDeleted && (
                  <DropdownIcon
                    menuItems={dropdownOptions || []}
                    icon="more"
                    title="more"
                    onItemClick={handleMenuItemClick}
                    iconColor={ThemeV2.palette.gray8}
                    rounded
                    stopEventPropagation
                  />
                )}
                <IconButtonWrapper role="button" onClick={closeRightDrawer}>
                  <SVGIcon icon="close-rounded" size="24px" />
                </IconButtonWrapper>
              </Flex>
            </>
          )}
        </Header>
        <NotebookSunsetBanner />
        {renderTitleEditor()}
        {(!isReadOnly || type === TaskCategories.COMPLETED) && (
          <Block>
            {type === TaskCategories.COMPLETED ? (
              <CompleteButton role="button" justifyContent="center">
                <Icon
                  icon="checkbox-circle-checked"
                  size="16px"
                  color={ThemeV2.palette.polarGreen7}
                />
                {capitalizeFirstLetter(COMPLETED)}
              </CompleteButton>
            ) : (
              <BlockContent disabled>
                <MarkAsCompleteButton
                  onClick={markTaskAsCompleted}
                  role="button"
                  justifyContent="center"
                  disabled={isDeleted}
                >
                  <Icon icon="checkbox-circle-unchecked" size="16px" />
                  {MARK_AS_COMPLETED}
                </MarkAsCompleteButton>{' '}
              </BlockContent>
            )}
          </Block>
        )}
        {isDeleted && (
          <Block>
            <InfoAlert
              id={+new Date()}
              text={DELETED_TASK_BANNER}
              icon="error-warning"
              alertType="custom"
              customVariant={customBannerVariant}
            />
          </Block>
        )}
        <Block>
          <BlockTitle variant="body2Medium" color="gray8">
            {capitalizeFirstLetter(DESCRIPTION)}
          </BlockTitle>
          {renderDescriptionEditor()}
        </Block>
        <Block>
          <BlockTitle variant="body2Medium" color="gray8">
            {dueDateLabel}
          </BlockTitle>
          <BlockContent>
            <TaskItemDatePicker
              dueDate={
                type === TaskCategories.COMPLETED ? stateEffectiveAt : dueDate
              }
              setDueDate={setDueDate}
              taskType={type}
              isDeleted={isDeleted}
              currentUserTimezone={currentUserTimezone}
              isReadOnly={isReadOnly}
            />
          </BlockContent>
        </Block>
        {!isDeleted &&
          type !== TaskCategories.ARCHIVED &&
          isAssignedToCurrentMember(task, currentUserId) && (
            <Block>
              <BlockTitle variant="body2Medium" color="gray8">
                {capitalizeFirstLetter(SECTION)}
              </BlockTitle>
              {isTaskSectionDataLoading ? (
                <BlockContent>
                  <NotebookRightDrawerDateLoader />
                </BlockContent>
              ) : (
                <BlockContent>
                  <DropdownWithChildren
                    menuItems={sectionDropdownOptionsData || []}
                    dropdownPlacement={DropdownPlacement.BottomStart}
                    onDropdownOpen={onDropdownOpen}
                    onItemClick={handleSectionChange}
                  >
                    <StatusWrapper>
                      {sectionName}
                      <SVGIcon
                        icon="caret-rounded"
                        color={ThemeV2.palette.gray8}
                        flip={isOpen}
                      />
                    </StatusWrapper>
                  </DropdownWithChildren>
                </BlockContent>
              )}
            </Block>
          )}
        <Block>
          <BlockTitle variant="body2Medium" color="gray8">
            {ASSIGNEE}
          </BlockTitle>
          <BlockContent>
            <AssigneeWrapper
              onClick={() => {
                setTaskIdOfFocusedTask(noteId);
              }}
              isDisabled={isDeleted || isReadOnly}
            >
              <TaskItemAssigneeSelector
                assigneeList={assigneesList || []}
                isLoading={isFetchingMoreAssignees}
                onMemberClick={handleAssigneeSelection}
                hasMoreUsersToFetch={hasMoreAssigneesToFetch}
                canPopoverTriggerByClick
                position
                fetchMoreMembers={fetchMoreAssignees}
                id={`${taskId}`}
                assignedTo={assignedTo}
                searchValue={searchValue}
                handleSearchOnAssigneeList={handleSearchOnAssigneeList}
                setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
                noPadding
                currentUserId={currentUserId}
              />
            </AssigneeWrapper>
          </BlockContent>
        </Block>
        <Divider
          color={ThemeV2.palette.gray4}
          isFullWidth
          marginBottom="16px"
          marginTop="16px"
        />
        <Block>
          <BlockTitle variant="body2Medium" color="gray8">
            <Flex justifyContent="space-between">
              {TASK_HISTORY}
              <IconButton
                icon={historyLogSort === 'desc' ? 'arrow-down' : 'arrow-up'}
                size={ButtonSize.Small}
                iconColor="gray8"
                onClick={toggleHistoryLogSort}
              />
            </Flex>
          </BlockTitle>
          <BlockContent>
            {currentUserId && (
              <TaskHistory
                taskHistory={taskHistoryList}
                isLoading={isTaskHistoryLoading}
                handleUserClick={handleUserClick}
              />
            )}
            {hasMoreLogsToFetch && (
              <ShowMoreButton
                onClick={fetchMoreLogs}
                color="primary"
                variant="text"
                icon="arrow-down"
                isFullWidth
                isLoading={isFetchingMoreLogs}
              >
                {SEE_MORE}
              </ShowMoreButton>
            )}
          </BlockContent>
        </Block>
        <Block>
          <BlockContent>
            <CreatedByText inline variant="body3" color="gray7">
              {CREATED_BY}
              {` ${createdByName} ${ON} ${format(
                new Date(createdAt),
                FORMAT_DATE,
              )}`}
            </CreatedByText>
          </BlockContent>
        </Block>
      </Wrapper>
    </>
  );
};

const LoadingNotebookTaskDetails = () => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Block>
        <Button
          icon="Link"
          color="secondary"
          onClick={() => {}}
          disabled
          isFullWidth
        >
          {NOTEBOOK_TASK_DETAILS_URL_DETAILS}
        </Button>
      </Block>
      <Block>
        <BlockTitle variant="body2Medium" color="gray8">
          {capitalizeFirstLetter(TASK)}
        </BlockTitle>
        <NotebookRightDrawerTaskLoader />
      </Block>
      <Block>
        <BlockTitle variant="body2Medium" color="gray8">
          {DUE_DATE}
        </BlockTitle>
        <NotebookRightDrawerDateLoader />
      </Block>
      <Block>
        <BlockTitle variant="body2Medium" color="gray8">
          {capitalizeFirstLetter(SECTION)}
        </BlockTitle>
        <NotebookRightDrawerDateLoader />
      </Block>
      <Block>
        <BlockTitle variant="body2Medium" color="gray8">
          {ASSIGNEE}
        </BlockTitle>
        <NotebookRightDrawerAssigneeLoader />
      </Block>
      <Block>
        <BlockTitle variant="body2Medium" color="gray8">
          {TASK_HISTORY}
        </BlockTitle>
        <NotebookRightDrawerTaskHistoryLoader />
      </Block>
    </Wrapper>
  );
};

const ErrorNotebookTaskDetails = ({
  isAccessError,
}: {
  isAccessError: boolean;
}) => {
  return (
    <Wrapper data-testid="right-drawer-notebook-error">
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Flex
        justifyContent="center"
        flexDirection="column"
        data-testid={isAccessError ? 'access-error-image' : 'error-image'}
      >
        <Image
          alt="error"
          src={isAccessError ? RightDrawerAccessError : RightDrawerErrorImage}
        />
        <ErrorTitle variant="body1Bold">
          {isAccessError
            ? NOTEBOOK_TASK_DETAILS_ACCESS_ERROR.TITLE
            : NOTEBOOK_TASK_DETAILS_ERROR.TITLE}
        </ErrorTitle>

        {!isAccessError && (
          <ErrorSubtitle variant="body2">
            {NOTEBOOK_TASK_DETAILS_ERROR.SUB_TITLE}
          </ErrorSubtitle>
        )}
      </Flex>
    </Wrapper>
  );
};

const ErrorNotebookTaskDeletedDetails = () => {
  return (
    <Wrapper data-testid="right-drawer-notebook-error">
      <Header>
        <SVGIcon icon="task-checklist" size="36px" />
        <Heading variant="body1">{NOTEBOOK_TASK_DETAILS_HEADER}</Heading>
      </Header>
      <Flex
        justifyContent="center"
        flexDirection="column"
        data-testid="error-image"
      >
        <Image alt="error" src={RightDrawerErrorImage} />
        <ErrorTitle variant="body1Bold">
          {NOTEBOOK_TASK_DETAILS_DELETED_ERROR.TITLE}
        </ErrorTitle>

        <ErrorSubtitle variant="body2">
          {NOTEBOOK_TASK_DETAILS_DELETED_ERROR.SUB_TITLE}
        </ErrorSubtitle>
      </Flex>
    </Wrapper>
  );
};

const NotebookTaskDetailsInfo = () => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="info" size="36px" />
        <Heading variant="body1">{ABOUT_NOTEBOOK_TITLE}</Heading>
      </Header>
      <Flex justifyContent="center" flexDirection="column">
        <Body variant="body1" color="gray9">
          {ABOUT_NOTEBOOK_DESCRIPTION_1}
        </Body>
        <Body variant="body1" color="gray9" margin="16px 0">
          {ABOUT_NOTEBOOK_DESCRIPTION_2}
        </Body>
      </Flex>
    </Wrapper>
  );
};

const NotebookTaskDetails = (
  props:
    | LoadingNotebookTaskDetailsProps
    | LoadedNotebookTaskDetailsProps
    | ErrorNotebookTaskDetailsProps
    | ReadOnlyNotebookTaskDetailsProps,
) => {
  switch (props.status) {
    case ComponentStatus.LOADING: {
      return <LoadingNotebookTaskDetails />;
    }

    case ComponentStatus.LOADED: {
      return <LoadedNotebookTaskDetails {...props} />;
    }

    case ComponentStatus.ERROR: {
      return props.isDeletedError ? (
        <ErrorNotebookTaskDeletedDetails />
      ) : (
        <ErrorNotebookTaskDetails isAccessError={props.isAccessError} />
      );
    }

    case ReadOnlyComponentStatus.READ_ONLY: {
      return <NotebookTaskDetailsInfo />;
    }
  }
};

export default memo(NotebookTaskDetails);
