import { useContext } from 'react';

import BasicModalTemplate from '../../atomic/templates/BasicModalTemplate';
import Body from '../../atomic/atoms/Body';
import EmoticonWithText from '../../atomic/atoms/EmoticonWithText';
import { ArchiveFlowModalContext } from './archiveFlowModalContext';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../Utils/analytics/constants';
import { FLOWS_ARCHIVE_MODAL } from '../../languages/en/flows';
import { trackLeftDrawerActionEvent } from '../../Utils/analytics/leftDrawer';
import { ARCHIVE, WHEN, YOU } from '../../languages/en/singleWords';

const ArchiveFlowModal = () => {
  const {
    flowDetails,
    handleOnArchiveClick,
    isArchiveFlowLoading,
    isArchiveModalOpen,
    setArchiveModalClose,
  } = useContext(ArchiveFlowModalContext);

  return (
    <BasicModalTemplate
      isOpen={isArchiveModalOpen}
      heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
      primaryButton={{
        text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
        onClick: () => {
          handleOnArchiveClick();
          trackLeftDrawerActionEvent({
            action:
              LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_ARCHIVED,
            ...flowDetails,
          });
        },
        status: 'warning',
        isLoading: isArchiveFlowLoading,
      }}
      secondaryButton={{
        text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
        onClick: setArchiveModalClose,
      }}
      onModalClose={setArchiveModalClose}
    >
      <Body variant="body2" inline>
        {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
      </Body>
      <Body variant="body2Medium" inline>
        <EmoticonWithText
          emoticon={flowDetails?.emoticon || ''}
          text={flowDetails?.label}
          hasBoldText
        />
      </Body>
      <Body variant="body2" inline>
        {', '}
        {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
      </Body>
    </BasicModalTemplate>
  );
};

export default ArchiveFlowModal;
