import React from 'react';
import { makeStyles } from '@mui/styles';
import ProgressBar from '../../atoms/ProgressBar';
import { ColorTypes } from '../../atoms/ProgressBar/styles';

enum ProgressValidatorStrength {
  StrengthNone = 'STRENGTH_NONE',
  StrengthNormal = 'STRENGTH_NORMAL',
  StrengthStrong = 'STRENGTH_STRONG',
  StrengthWeak = 'STRENGTH_WEAK',
}

interface ProgressValidatorProps {
  strength: ProgressValidatorStrength;
  className?: string;
}

const useStyles = makeStyles({
  root: {
    padding: 4,
    display: 'flex',
    flexDirection: 'row',
    marginRight: '-4px',
    justifyContent: 'space-between',
  },
});

const ProgressValidator = (props: ProgressValidatorProps) => {
  const { strength, className: parentClassName } = props;
  const classes = useStyles();
  let validation;
  if (strength === ProgressValidatorStrength.StrengthWeak) {
    validation = [
      {
        color: 'danger',
        value: 100,
      },
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  } else if (strength === ProgressValidatorStrength.StrengthNormal) {
    validation = [
      {
        color: 'warning',
        value: 100,
      },
      {
        color: 'warning',
        value: 100,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  } else if (strength === ProgressValidatorStrength.StrengthStrong) {
    validation = [
      {
        color: 'success',
        value: 100,
      },
      {
        color: 'success',
        value: 100,
      },
      {
        color: 'success',
        value: 100,
      },
    ];
  } else if (strength === ProgressValidatorStrength.StrengthNone) {
    validation = [
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
      {
        color: 'none',
        value: 0,
      },
    ];
  }
  const className = `${classes.root} ${parentClassName}`;
  return (
    <div className={className} data-testid="progressValidator">
      {validation?.map((strengthItem, index) => (
        <ProgressBar
          color={strengthItem.color as ColorTypes}
          value={strengthItem.value}
          // FIXME: Come up with better approach for passing key
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        />
      ))}
    </div>
  );
};

export default ProgressValidator;
