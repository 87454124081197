import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { FlowState } from '../../../Utils/analytics/interfaces';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import { StyledPrefixIcon } from '../../../atomic/organism/LeftNav/styles';
import FlowsMenuAccordion from '../../../atomic/organism/MenuAccordion/FlowsMenuAccordion';
import { ComponentStatus } from '../../../interfaces/component';
import { ANSWER_NOW_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE } from '../../../languages/en/flows';
import { LeftNavHorizontalRule } from '../../layout/PrimaryNavController/styles';
import useAnswerNowFlowsController from './useAnswerNowFlowsController';

const AnswerNowFlowsController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    models: { isOpen, menuItems, isSuccess },
    operations: { handleAccordionClick, handleMenuItemClick },
  } = useAnswerNowFlowsController();

  const getFlowItemOnMenuClick = (id: string) => {
    const individualFlowItem = menuItems.find((flow) => flow.id === id);
    return individualFlowItem;
  };

  if (isSuccess && menuItems.length) {
    return (
      <>
        <FlowsMenuAccordion
          status={ComponentStatus.LOADED}
          isAccordionOpen={isOpen}
          accordionTitle={ANSWER_NOW_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
          onAccordionClick={handleAccordionClick}
          navItems={menuItems}
          onMenuItemClick={(id) => {
            handleMenuItemClick(id as string);
            const flowDetails = getFlowItemOnMenuClick(id as string);
            trackLeftDrawerActionEvent({
              action:
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_CLICKED,
              ...flowDetails,
              flowState: FlowState.ResponsePrompted,
              folder: flowDetails?.folderInfo?.folderId
                ? 'customFolder'
                : 'flows',
            });
          }}
          isAddNewFlowOn={false}
          isHover={isLeftNavHovered || isLeftAsideOpen}
          canAddFlows={false}
          headerPrefixComponent={
            <StyledPrefixIcon icon="answer-now" size="24px" />
          }
          headerSuffixComponent={null}
        />
        <LeftNavHorizontalRule />
      </>
    );
  }

  return null;
};

export default AnswerNowFlowsController;
