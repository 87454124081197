import React from 'react';
import { Grow } from '@mui/material';
import { DropdownCardBackground, StyledPopper } from './styles';
import { DropdownCardProps } from './interface';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';
import useOnClickOutside from '../../../hooks/useOnOutsideClick';

const DropdownCard = (props: DropdownCardProps) => {
  const {
    id,
    isOpen,
    anchorEl,
    dropdownPlacement = DropdownPlacement.Bottom,
    children,
    isFullWidth,
    isDropdownPositionFixed = false,
    className,
    preventOverflowEnabled = false,
    disablePortal = true,
    onOutsideClick,
  } = props;
  const popperRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(popperRef, () => onOutsideClick?.());
  const popperWidth =
    anchorEl && isFullWidth ? `${anchorEl.offsetWidth}px` : 'auto';
  return (
    <StyledPopper
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      placement={dropdownPlacement}
      width={popperWidth}
      disablePortal={disablePortal}
      modifiers={[
        {
          name: 'hide',
          enabled: false,
        },
        {
          name: 'preventOverflowEnabled',
          enabled: preventOverflowEnabled || false,
        },
      ]}
      popperOptions={
        isDropdownPositionFixed
          ? {
              strategy: 'fixed',
            }
          : {}
      }
      transition
      className={className}
      ref={popperRef}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <DropdownCardBackground isFullWidth={isFullWidth}>
            {children}
          </DropdownCardBackground>
        </Grow>
      )}
    </StyledPopper>
  );
};

export default DropdownCard;
