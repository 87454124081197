import { makeStyles } from '@mui/styles';
import styled from 'styled-components';

import IconButton from '../../../atoms/IconButton_V2';
import SVGIcon from '../../../atoms/SVGIcon';

export const CheckMarkIcon = styled(SVGIcon)`
  margin-right: 10px;
  padding-top: 13px;
  position: sticky;
  top: 0;
`;

export const ClearIcon = styled(SVGIcon)`
  cursor: pointer;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubmitButton = styled(IconButton)`
  margin-left: 8px;
  position: sticky;
  top: 0;
`;

export const useStyles = makeStyles({
  root: (props: { isDesktopView: boolean }) => {
    return {
      flexDirection: 'row',

      '& .MuiInputAdornment-root': {
        height: 'initial',
        position: 'sticky',
        right: 0,
        top: 0,
      },

      '& .MuiInputBase-root': {
        alignItems: 'flex-start',
        width: '100%',
      },

      '& .MuiOutlinedInput-inputAdornedEnd': {
        paddingRight: '8px',
      },

      '& .MuiOutlinedInput-multiline': {
        padding: '5px 8px',
      },
      ...(props.isDesktopView ? { width: '280px !important' } : {}),
    };
  },
});
