import React from 'react';

import Button from '../../atoms/Button';
import SVGIcon from '../../atoms/SVGIcon';
import LoadingPulse from '../../atoms/LoadingPulse';
import TabComponent from '../../molecules/TabComponent';
import { HorizontalRule } from '../../molecules/FlowsPostHeader/styles';
import { ShareSheetModalScreen } from '../../../controllers/flows/FlowsShareSheetController/types';

import { SharingOptionsModalProps, SharingOptionsSlotType } from './types';

import {
  Container,
  HeaderTitle,
  CloseButton,
  PrimaryButton,
  FooterContainer,
  HeaderContainer,
  InviteAndShareContainer,
  RightPanelButtonsContainer,
  SharingOptionsModalContainer,
} from './styles';

import ThemeV2 from '../../../theme';

const InviteAndShareTab = (props: any) => {
  return <section>{props.children}</section>;
};

const ConnectionsTab = (props: any) => {
  return <section>{props.children}</section>;
};

const SharingOptionsModal = (props: SharingOptionsModalProps) => {
  const {
    screen,
    heading,
    children,
    onModalClose,
    headerButton,
    primaryButton,
    isOpen = true,
    tertiaryButton,
    secondaryButton,
    isLoading = false,
    showHeading = true,
    selectedTabIndex,
    handleSelectedTabIndexChange,
  } = props;

  const slots: SharingOptionsSlotType = {
    connectionsTab: null,
    inviteAndShareTab: null,
  };

  React.Children.forEach(children, (child) => {
    switch (child?.type) {
      case SharingOptionsModal.InviteAndShareTab: {
        slots.inviteAndShareTab = child;
        break;
      }
      case SharingOptionsModal.ConnectionsTab: {
        slots.connectionsTab = child;
        break;
      }
    }
  });

  const tabComponentProps = {
    tabItems: [
      {
        tabHeader: {
          label: `Invite & Share`,
        },
        tabContent:
          screen === ShareSheetModalScreen.PARTICIPANTS ||
          screen === ShareSheetModalScreen.VIEWERS ? (
            <section>{slots.inviteAndShareTab}</section>
          ) : (
            <InviteAndShareContainer
              $noPadding={
                screen === ShareSheetModalScreen.PREVIEW_ACCESS_SETTINGS
              }
            >
              {slots.inviteAndShareTab}
            </InviteAndShareContainer>
          ),
      },
      {
        tabHeader: {
          label: `Connections`,
        },
        tabContent: slots.connectionsTab,
      },
    ],
    splitEqually: false,
    handleSelectTab: handleSelectedTabIndexChange,
  };

  return (
    <SharingOptionsModalContainer
      isOpen={isOpen}
      showMobileFullScreen
      handleClose={onModalClose}
    >
      {isLoading && <LoadingPulse />}
      {!isLoading && (
        <Container>
          {screen === ShareSheetModalScreen.HOME && (
            <CloseButton onClick={onModalClose}>
              <SVGIcon
                icon="round-close"
                color={ThemeV2.palette.gray6}
                size="16px"
              />
            </CloseButton>
          )}
          {showHeading && (
            <>
              <HeaderContainer>
                {headerButton && (
                  <Button
                    size={headerButton?.size}
                    icon={headerButton?.icon}
                    color={headerButton?.color}
                    variant={headerButton?.variant}
                    onClick={headerButton?.onClick}
                  >
                    {headerButton?.text}
                  </Button>
                )}
                <HeaderTitle variant="h5">{heading}</HeaderTitle>
                <div></div>
              </HeaderContainer>
              {(screen === ShareSheetModalScreen.ANONYMOUS_SETTINGS ||
                screen === ShareSheetModalScreen.LINK_ACCESS_SETTINGS) && (
                <HorizontalRule />
              )}
            </>
          )}
          <TabComponent
            {...tabComponentProps}
            hideTabs={showHeading}
            selectedTabIndex={selectedTabIndex}
          />
          {(primaryButton || secondaryButton) && (
            <FooterContainer>
              {tertiaryButton && (
                <Button
                  isFullWidth={false}
                  icon={tertiaryButton.icon}
                  size={tertiaryButton.size}
                  status={tertiaryButton.status}
                  onClick={tertiaryButton.onClick}
                  variant={tertiaryButton.variant}
                  disabled={tertiaryButton.disabled}
                  isLoading={tertiaryButton.isLoading}
                  color={
                    tertiaryButton.color ? tertiaryButton.color : 'secondary'
                  }
                >
                  {tertiaryButton.text}
                </Button>
              )}

              <RightPanelButtonsContainer>
                {secondaryButton && (
                  <Button
                    color={
                      secondaryButton.color
                        ? secondaryButton.color
                        : 'secondary'
                    }
                    icon={secondaryButton.icon}
                    size={secondaryButton.size}
                    status={secondaryButton.status}
                    onClick={secondaryButton.onClick}
                    variant={secondaryButton.variant}
                    disabled={secondaryButton.disabled}
                    isLoading={secondaryButton.isLoading}
                  >
                    {secondaryButton.text}
                  </Button>
                )}
                {primaryButton && (
                  <PrimaryButton
                    color={
                      primaryButton.color ? primaryButton.color : 'primary'
                    }
                    icon={primaryButton.icon}
                    size={primaryButton.size}
                    status={primaryButton.status}
                    onClick={primaryButton.onClick}
                    variant={primaryButton.variant}
                    disabled={primaryButton.disabled}
                    isLoading={primaryButton.isLoading}
                  >
                    {primaryButton.text}
                  </PrimaryButton>
                )}
              </RightPanelButtonsContainer>
            </FooterContainer>
          )}
        </Container>
      )}
    </SharingOptionsModalContainer>
  );
};

SharingOptionsModal.ConnectionsTab = ConnectionsTab;
SharingOptionsModal.InviteAndShareTab = InviteAndShareTab;

export default SharingOptionsModal;
