import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

type ColorPickerProps = {
  selectedColor?: string;
  onColorChange: (color: string) => void;
};

export const folderColorTextMap = {
  // gray-5
  '#D9D9D9': '#262626',

  // gray-6
  '#BFBFBF': '#262626',

  // brand-3
  '#FFBB96': '#871400',

  // warning-2
  '#FFE7BA': '#873800',

  // upgrade-2
  '#FFF1B8': '#874D00',

  // success-3
  '#B7EB8F': '#135200',

  // success-2
  '#D9F7BE': '#237804',

  // cyan-3
  '#87E8DE': '#00474F',

  // blue-2
  '#BAE7FF': '#0050B3',

  // primary-2
  '#D6E4FF': '#1D39C4',

  // purple-2
  '#EFDBFF': '#391085',

  // error-2
  '#FFCCC7': '#820014',

  // error-3
  '#FFA39E': '#A8071A',
} as const;

export type FolderColor = keyof typeof folderColorTextMap;

const useStyles = makeStyles({
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: (props: ColoredRadioStyle) =>
      `inset 0 0 0 0 ${props.folderColor}, inset 0 -1px 0 ${props.folderColor}`,

    backgroundColor: (props: ColoredRadioStyle) => props.folderColor,

    '$root.Mui-focusVisible &': {
      outline: (props: ColoredRadioStyle) =>
        `2px auto ${folderColorTextMap[props.folderColor as FolderColor]}`,
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      boxShadow: (props: ColoredRadioStyle) =>
        `inset 0 0 0 1px ${
          folderColorTextMap[props.folderColor as FolderColor]
        }, inset 0 -1px 0 ${
          folderColorTextMap[props.folderColor as FolderColor]
        }`,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: (props: ColoredRadioStyle) => props.folderColor,

    boxShadow: (props: ColoredRadioStyle) =>
      `inset 0 0 0 1px ${
        folderColorTextMap[props.folderColor as FolderColor]
      }, inset 0 -1px 0 ${
        folderColorTextMap[props.folderColor as FolderColor]
      }`,

    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: (props: ColoredRadioStyle) =>
        `radial-gradient(${
          folderColorTextMap[props.folderColor as FolderColor]
        },${
          folderColorTextMap[props.folderColor as FolderColor]
        } 28%,transparent 32%)`,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: (props: ColoredRadioStyle) => props.folderColor,
    },
  },
});

type ColoredRadioStyle = { folderColor: string };

const ColoredRadio = ({
  folderColor,
  ...radioProps
}: ColoredRadioStyle & RadioProps) => {
  const classes = useStyles({ folderColor });

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={
        <span className={classNames(classes.icon, classes.checkedIcon)} />
      }
      icon={<span className={classes.icon} />}
      value={folderColor}
      {...radioProps}
    />
  );
};

export function FolderColorPicker(props: ColorPickerProps) {
  return (
    <RadioGroup
      name="colorPicker"
      value={props.selectedColor}
      onChange={(e) => props.onColorChange(e.currentTarget.value)}
      row
    >
      {Object.keys(folderColorTextMap).map((folderColor) => (
        <ColoredRadio folderColor={folderColor} key={folderColor} />
      ))}
    </RadioGroup>
  );
}
