import { useQuery } from '@tanstack/react-query';

import { makeAPICallWithDataReturn } from '../../utils';
import { GET_ALL_DEPARTMENTS } from '../../../constants/endpoints';

export type AllDepartmentsResponse = {
  data: string[];
};

export const useGetAllDepartmentsQuery = () => {
  return useQuery<AllDepartmentsResponse>(
    [GET_ALL_DEPARTMENTS],
    () => {
      return makeAPICallWithDataReturn(GET_ALL_DEPARTMENTS, {});
    },
    { enabled: true, staleTime: 60 * 5 * 1000, refetchOnWindowFocus: false },
  );
};
