/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
import { EmojiData } from 'emoji-mart';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState } from 'draft-js';
import produce from 'immer';

import { Reaction } from '../../../interfaces/Feed';

import FlowFeedPost from '../FlowFeedPost';
import { DummyFlowFeedPostProps } from './types';
import { toggleReactionInReactions, updateReactionsWithEmoji } from './utils';
import styled from 'styled-components';
import FlowsPostHeaderOption from '../FlowsPostHeaderOption';
import { menuItemsWithCopyLink } from '../FlowFeedPost/data';
import { PostTypes } from '../../../interfaces/Home';
import { ComponentStatus } from '../../../interfaces/component';

const DropdownContainer = styled.div`
  margin-left: auto;
`;

const DummyFlowFeedPost = (props: DummyFlowFeedPostProps) => {
  const { reactionBarProps, commentsProps, currentUser, assembly, ...rest } =
    props;
  const [reactions, setReactions] = useState(props.reactionBarProps.reactions);
  const editorRef = useRef<Editor>(null);

  const [editorState] = useState(EditorState.createEmpty());
  const [comments, setComments] = useState(commentsProps.feedCommentProps);
  const commentsRef = useRef(comments);
  commentsRef.current = comments;
  const [, setHasText] = useState(false);
  const [isEditorFocused] = useState(false);

  const dummyFunction = () => {};
  const onMemberClick = dummyFunction;
  const onDeletePostSelectedOption = dummyFunction;
  const onDeleteModalSubmitClick = dummyFunction;
  const onCopyLinkClick = dummyFunction;
  const onFlowClick = dummyFunction;

  const dropdownComponent = (
    <DropdownContainer>
      <FlowsPostHeaderOption
        dropdownOptions={menuItemsWithCopyLink}
        icon="more"
        title="flows post options"
        onCopyLink={onCopyLinkClick}
        onDeletePostSelectedOption={onDeletePostSelectedOption}
        onDeleteModalSubmitClick={onDeleteModalSubmitClick}
      />
    </DropdownContainer>
  );

  const handleCommentEmoticonSelect = (emoji: EmojiData, contentId: string) => {
    const currentComments = commentsRef.current;

    const commentIndex = currentComments.findIndex(
      (comment) => comment.contentId === contentId,
    );

    if (commentIndex > -1) {
      const commentReactionsToUpdate = currentComments[commentIndex].reactions;
      const updatedCommentReactions = updateReactionsWithEmoji(
        commentReactionsToUpdate,
        emoji,
        currentUser,
      );

      const updatedComments = produce(currentComments, (draft) => {
        draft[commentIndex].reactions = updatedCommentReactions;
      });
      setComments(updatedComments);
      commentsRef.current = updatedComments;
    }
  };

  const handleCommentReactionToggleClick = (
    reaction: Reaction,
    contentId: string,
  ) => {
    const currentComments = commentsRef.current;

    const commentIndex = currentComments.findIndex(
      (comment) => comment.contentId === contentId,
    );

    if (commentIndex > -1) {
      const reactionName = reaction.reaction.name;

      const commentReactionsToUpdate = currentComments[commentIndex].reactions;
      const updatedCommentReactions = toggleReactionInReactions(
        reactionName,
        commentReactionsToUpdate,
        currentUser,
      );

      const updatedComments = produce(currentComments, (draft) => {
        draft[commentIndex].reactions = updatedCommentReactions;
      });
      setComments(updatedComments);
      commentsRef.current = updatedComments;
    }
  };

  const handleReactionSet = (emoji: EmojiData) => {
    const updatedReactions = updateReactionsWithEmoji(
      reactions,
      emoji,
      currentUser,
    );
    setReactions(updatedReactions);
  };

  const handleOnReactionUnSet = useCallback(
    (reaction: Reaction) => {
      const reactionName = reaction.reaction.name;
      const updatedReactions = toggleReactionInReactions(
        reactionName,
        reactions,
        currentUser,
      );
      setReactions(updatedReactions);
    },
    [currentUser, reactions],
  );

  const completeReactionBarProps = {
    ...props.reactionBarProps,
    reactions,
  };

  useEffect(() => {
    setHasText(editorState.getCurrentContent().hasText());
  }, [editorState]);

  useEffect(() => {
    if (isEditorFocused) {
      editorRef.current?.focus();
    }
  }, [isEditorFocused]);

  useEffect(() => {
    const commentsWithProps = comments.map((comment) => {
      return produce(comment, (draft) => {
        draft.onEmoticonSelect = handleCommentEmoticonSelect;
        draft.onReactionToggleClick = handleCommentReactionToggleClick;
      });
    });
    setHasText(editorState.getCurrentContent().hasText());
    setComments(commentsWithProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlowFeedPost
      profileData={{ member: currentUser, assembly: assembly }}
      feedItemMetaData={{
        postId: '',
        flowId: undefined,
        responseId: undefined,
        type: PostTypes.CELEBRATED,
      }}
      {...rest}
      index={0}
      reactionBarProps={completeReactionBarProps}
      onReactionSet={handleReactionSet}
      onReactionUnSet={handleOnReactionUnSet}
      onMemberClick={onMemberClick}
      onFlowClick={onFlowClick}
      dropdownComponent={dropdownComponent}
      repliesBarProps={{
        count: 0,
        lastRepliedAt: new Date(),
        status: ComponentStatus.LOADED,
        initialRespondents: [],
        respondentsCount: 0,
      }}
    />
  );
};

export default DummyFlowFeedPost;
