import { OnboardingHeader } from '../../controllers/UserOnBoardingController/FlowsOnboardingWithQuickSetup/styles';
import { Flex } from '../../Utils/styles/display';
import { CustomHeader } from '../organism/PrimaryHeader/styles';
import { LogoContainer, StyledLogo } from './PageHeader/styles';
import placeholderLogo from '../../img/assembly-placeholder-logo.svg';

export const Navbar = () => {
  return (
    <OnboardingHeader>
      <Flex margin="0 0 0 12px">
        <LogoContainer>
          <StyledLogo src={placeholderLogo} alt="assembly logo" />
        </LogoContainer>
        <CustomHeader>Assembly</CustomHeader>
      </Flex>
    </OnboardingHeader>
  );
};
