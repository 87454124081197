import styled from 'styled-components';

import Button from '../../atoms/Button';

export const StyledButton = styled(Button)`
  margin-top: 16px;
`;

export const StyledWrapper = styled.div`
  margin-top: 16px;
`;
