import React, { SyntheticEvent, useEffect, useState } from 'react';
import uuid from 'uuid';
import {
  MultiSelectAutoCompleteWrapper,
  OptionItemWrapper,
  StyledButton,
  StyledFormControlLabel,
  StyledRadioGroup,
  Wrapper,
} from '../FlowParticipationBuilderOptionsContent/styles';
import {
  FlowsManagerBuilderOptionProps,
  RadioOptionManagerValueTypes,
} from './types';
import Radio from '../../atoms/Radio';
import orderBy from 'lodash/orderBy';
import { ADD } from '../../../languages/en/singleWords';
import { MultiSelectAutoComplete } from '../Autocomplete';
import { AutocompleteDropdownItem } from '../Autocomplete/interfaces';

const FlowsManagerBuilderOptionsContent = (
  props: FlowsManagerBuilderOptionProps<string>,
) => {
  const {
    className,
    radioOptions,
    selectedOptionId,
    autocompleteItems,
    onInputChange,
    onAddButtonClick,
    isLoading,
    onOptionsScroll,
  } = props;
  const [optionValue, setOptionValue] = useState<string>(
    RadioOptionManagerValueTypes.DirectReport,
  );

  const [criteriaItems, setCriteriaItems] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  useEffect(() => {
    setCriteriaItems([]);
  }, [selectedOptionId]);

  const handleOnAddClick = () => {
    if (
      optionValue === 'memberIsManager' ||
      optionValue === 'memberIsNotManager'
    ) {
      const criteriaData = {
        field: 'manager',
        operator: 'is',
        value: [{ id: uuid.v4(), title: optionValue }],
      };
      onAddButtonClick(criteriaData);
    } else {
      const criteriaData = {
        field: optionValue,
        operator: 'of',
        value: criteriaItems,
      };
      onAddButtonClick(criteriaData);
    }
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionValue(e.target.value);
    setCriteriaItems([]);
  };

  const handleCriteriaChange = (value: AutocompleteDropdownItem<string>[]) => {
    setCriteriaItems(value);
    onInputChange?.('');
  };

  const handleOnCriteriaItemsScroll = (event: SyntheticEvent) => {
    const listBoxNode = event.currentTarget;
    if (
      listBoxNode.scrollHeight * 0.7 <=
      listBoxNode.scrollTop + listBoxNode.clientHeight
    ) {
      onOptionsScroll?.();
    }
  };

  const renderMultiSelectAutoComplete = (currentOptionValue: string) => {
    if (
      optionValue === currentOptionValue &&
      selectedOptionId === 'manager' &&
      optionValue === RadioOptionManagerValueTypes.DirectReport
    ) {
      return (
        <MultiSelectAutoCompleteWrapper>
          <MultiSelectAutoComplete
            className="input-new-line"
            multiline
            options={orderBy(autocompleteItems, 'title', 'asc')}
            value={criteriaItems}
            onChange={handleCriteriaChange}
            onTextboxValueChange={onInputChange}
            removeElevation
            loading={isLoading}
            ListboxProps={{
              onScroll: handleOnCriteriaItemsScroll,
            }}
          />
        </MultiSelectAutoCompleteWrapper>
      );
    }

    return null;
  };

  return (
    <Wrapper className={className}>
      <StyledRadioGroup onChange={handleOptionChange} value={optionValue}>
        {radioOptions.map((option) => (
          <OptionItemWrapper key={option.value}>
            <StyledFormControlLabel
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
            {optionValue === RadioOptionManagerValueTypes.DirectReport &&
              renderMultiSelectAutoComplete(option.value)}
          </OptionItemWrapper>
        ))}
      </StyledRadioGroup>
      <StyledButton onClick={handleOnAddClick}>{ADD}</StyledButton>
    </Wrapper>
  );
};

export default FlowsManagerBuilderOptionsContent;
