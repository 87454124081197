import { useCallback, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import add from 'date-fns/add';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';
import { SecondaryPanel } from '../../../interfaces/Layout';
import useLayoutStore from '../../../stores/layoutStore';
import {
  useProfileInfoFetchQuery,
  useProfileInfoQuickSetupMutate,
} from '../../../queries/Profile';
import {
  getCurrentUserFirstLogin,
  setCurrentUserFirstLogin,
} from '../../../Utils/user';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { changeTimezone } from '../../../Utils/date';
import { MemberInteractionType } from '../../../interfaces/Feed';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import { useMembersFetchInfiniteScrollQuery } from '../../../queries/Members';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

const usePrimaryNavLogic = (
  isQuickSetupDataLoading: boolean,
  isCurrentUserAdmin: boolean,
  showQuickSetupOption: boolean,
) => {
  const {
    activeSecondaryPanel,
    setActiveNavItem,
    setActiveSecondaryPanel,
    isLeftAsideOpen,
    isRightAsideOpen,
    toggleLeftAsideOpen,
    toggleRightAsideOpen,
    setShowChats,
    setMemberAndToggleRightAsideOpen,
  } = useLayoutStore(
    (state) => ({
      activeSecondaryPanel: state.activeSecondaryPanel,
      setActiveNavItem: state.setActiveNavItem,
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
      toggleLeftAsideOpen: state.toggleLeftAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      setMemberAndToggleRightAsideOpen: state.setMemberAndToggleRightAsideOpen,
      isLeftNavHovered: state.isLeftNavHovered,
      setActiveSecondaryPanel: state.setActiveSecondaryPanel,
      setShowChats: state.setShowChats,
    }),
    shallow,
  );

  const { hasNextPage, fetchNextPage, isFetchingNextPage } =
    useMembersFetchInfiniteScrollQuery();

  if (getCurrentUserFirstLogin()) {
    setCurrentUserFirstLogin(false);
  }

  const [showQuickSetupPanel, setShowQuickSetupPanel] =
    useState(showQuickSetupOption);
  const onProfileInfoQuickSetupUpdateSuccess = useCallback(() => {
    setShowQuickSetupPanel(false);
    setActiveNavItem(SecondaryPanel.ASSEMBLY);
    setActiveSecondaryPanel(SecondaryPanel.ASSEMBLY);
  }, [setActiveNavItem, setActiveSecondaryPanel]);
  const { mutate: updateQuickSetup, isLoading: isUpdateProfileInfoLoading } =
    useProfileInfoQuickSetupMutate(onProfileInfoQuickSetupUpdateSuccess);

  const { data: profileData } = useProfileInfoFetchQuery();

  const isTabletOrDesktopView = useMediaQuery({
    query: device.tabletOrDesktop,
  });

  const toggleNavBar = useCallback(
    ({ currentTarget: { dataset } }: React.SyntheticEvent<HTMLElement>) => {
      if (!isLeftAsideOpen) {
        toggleLeftAsideOpen();
      }
      if (isTabletOrDesktopView && isRightAsideOpen) {
        toggleRightAsideOpen();
      }
      const id = dataset.id as SecondaryPanel;
      setActiveNavItem(id || SecondaryPanel.ASSEMBLY);
      setActiveSecondaryPanel(id || SecondaryPanel.ASSEMBLY);
    },
    [
      isLeftAsideOpen,
      isTabletOrDesktopView,
      isRightAsideOpen,
      setActiveNavItem,
      setActiveSecondaryPanel,
      toggleLeftAsideOpen,
      toggleRightAsideOpen,
    ],
  );
  const closeQuickSetupPanel = useCallback(() => {
    updateQuickSetup({ showQuickSetupPanel: false });
  }, [updateQuickSetup]);

  const onProfilePopoverClick = useCallback(
    (person: MemberInteractionType) => {
      setShowChats(true);
      setMemberAndToggleRightAsideOpen({
        memberId: person.memberID,
        firstName: person.firstName,
      });
    },
    [setMemberAndToggleRightAsideOpen, setShowChats],
  );

  const [isProfileToggled, setIsProfileToggled] = useState(false);
  const onProfileClick = useCallback(() => {
    if (!isProfileToggled) {
      setIsProfileToggled(true);
      setActiveNavItem(SecondaryPanel.PROFILE);
    } else {
      setIsProfileToggled(false);
      setActiveNavItem(activeSecondaryPanel);
    }
  }, [activeSecondaryPanel, isProfileToggled, setActiveNavItem]);
  const onProfileOutsideClick = useCallback(
    (event: MouseEvent | TouchEvent) => {
      setIsProfileToggled(false);
      const navElement = event.composedPath().find((el: any) => {
        const id = el?.dataset?.id;
        return Boolean(id && id !== SecondaryPanel.PROFILE);
      });
      if (!navElement) {
        setActiveNavItem(activeSecondaryPanel);
      }
    },
    [activeSecondaryPanel, setActiveNavItem],
  );
  const onProfileAnywhereElseClick = useCallback(() => {
    setIsProfileToggled(false);
    setActiveNavItem(activeSecondaryPanel);
  }, [activeSecondaryPanel, setActiveNavItem]);

  const trackLeftDrawerCollapsed = () => {
    trackLeftDrawerActionEvent({
      action:
        LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_LEFT_DRAWER_COLLAPSED,
    });
  };

  const trackLeftDrawerExpanded = () => {
    trackLeftDrawerActionEvent({
      action:
        LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_LEFT_DRAWER_EXPANDED,
    });
  };

  const trackLeftDrawerRedeemClick = () => {
    trackLeftDrawerActionEvent({
      action: LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_REDEEM_CLICKED,
    });
  };

  const trackLeftDrawerMainFeedClick = () => {
    trackLeftDrawerActionEvent({
      action: LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_MAIN_FEED_CLICKED,
    });
  };

  const trackLeftDrawerRewardsClick = () => {
    trackLeftDrawerActionEvent({
      action: LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_REWARDS_CLICKED,
    });
  };

  const trackLeftDrawerPeopleDirectoryClick = () => {
    trackLeftDrawerActionEvent({
      action:
        LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_PEOPLE_DIRECTORY_CLICKED,
    });
  };

  const refreshDate = useMemo(() => {
    const timezoneDate = changeTimezone(
      new Date(),
      profileData?.assembly.timeZone,
    );

    return format(startOfMonth(add(timezoneDate, { months: 1 })), 'MM/dd/yyyy');
  }, [profileData]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 200;
    if (bottom && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  return {
    operations: {
      toggleNavBar,
      onProfilePopoverClick,
      onProfileClick,
      onProfileOutsideClick,
      onProfileAnywhereElseClick,
      closeQuickSetupPanel,
      trackLeftDrawerCollapsed,
      trackLeftDrawerExpanded,
      trackLeftDrawerRedeemClick,
      trackLeftDrawerMainFeedClick,
      trackLeftDrawerRewardsClick,
      trackLeftDrawerPeopleDirectoryClick,
      handleScroll,
    },
    models: {
      showQuickSetupPanel,
      isUpdateProfileInfoLoading,
      refreshDate,
    },
  };
};

export default usePrimaryNavLogic;
