import React, { ReactNode, useMemo } from 'react';
import * as PopperJS from '@popperjs/core';

import IconButton from '../../atoms/IconButton_V2';
import {
  IconButtonProps,
  ButtonSize,
} from '../../atoms/IconButton_V2/interface';
import ThemeV2 from '../../../theme';
import ToolTip from '../ToolTipV2';

export interface IconButtonWithToolTipProps {
  toolTipContent: ReactNode;
  position?: PopperJS.Placement;
  IconButtonContent?: React.ReactNode;
}

const IconButtonWithToolTip = (
  props: IconButtonWithToolTipProps & IconButtonProps,
) => {
  const {
    disabled,
    isSelected = false,
    size = ButtonSize.Small,
    icon,
    rounded,
    IconButtonContent,
    toolTipContent,
    position = 'top',
    className,
    title,
    onClick,
    iconBgColor = '',
    buttonRef,
  } = props;

  const TooltipComponent = useMemo(
    () => (
      <IconButton
        className={className}
        icon={icon}
        iconColor={ThemeV2.palette.gray8}
        disabled={disabled}
        size={size === ButtonSize.Small ? ButtonSize.Small : ButtonSize.Normal}
        rounded={rounded}
        onClick={onClick}
        title={title}
        isSelected={isSelected}
        iconBgColor={iconBgColor}
        buttonRef={buttonRef}
      >
        {IconButtonContent}
      </IconButton>
    ),
    [
      IconButtonContent,
      className,
      disabled,
      icon,
      isSelected,
      onClick,
      rounded,
      size,
      title,
      iconBgColor,
      buttonRef,
    ],
  );

  return (
    <ToolTip toolTipComponent={TooltipComponent} position={position}>
      {toolTipContent}
    </ToolTip>
  );
};

export default IconButtonWithToolTip;
