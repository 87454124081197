import { useQuery } from '@tanstack/react-query';

import { makeAPICallWithDataReturn } from '../../utils';
import {
  GET_FLOW_PERMISSIONS,
  GET_FLOW_REPLIES_SUMMARY,
} from '../../../constants/endpoints';

import {
  ReplySummaryResponse,
  GetReplySummaryRequest,
  GetFlowPermissionsRequest,
  GetFlowPermissionsResponse,
} from '../../../interfaces/Feed/Replies';
import axios from 'axios';

export const useGetReplySummary = (
  request: GetReplySummaryRequest,
  enabled: boolean,
) => {
  return useQuery<ReplySummaryResponse>(
    [GET_FLOW_REPLIES_SUMMARY, request.postId],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      signal?.addEventListener('abort', () => {
        source.cancel('Query was cancelled by React Query');
      });
      return makeAPICallWithDataReturn(
        GET_FLOW_REPLIES_SUMMARY,
        undefined,
        { type: request.type },
        { ...request },
        false,
        source,
      );
    },
    {
      enabled,
    },
  );
};

export const useGetFlowPermissions = (request: GetFlowPermissionsRequest) => {
  return useQuery<GetFlowPermissionsResponse>(
    [GET_FLOW_PERMISSIONS, request.flowId, request.memberId],
    () =>
      makeAPICallWithDataReturn(
        GET_FLOW_PERMISSIONS,
        undefined,
        {
          memberId: request.memberId,
        },
        { flowId: request.flowId },
      ),
  );
};
