import { useEffect } from 'react';
import { useLocation } from 'react-router';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import Heading from '../../atoms/Heading';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import { authStore } from '../../../stores/authStore';
import { SHARE_SHEET_EVENTS } from '../../../Utils/analytics/constants';
import { trackShareSheetActionEvent } from '../../../Utils/analytics/shareSheet';

const ConnectionRedirectPath = () => {
  const { search } = useLocation();
  const { setSlackCode } = authStore();

  const params = new URLSearchParams(search).entries();
  const queryParameters = Object.fromEntries(params);

  useEffect(() => {
    const delay = 2000;
    trackShareSheetActionEvent({
      app: 'slack',
      tab: 'connections',
      action: SHARE_SHEET_EVENTS.SUCCESSFUL_CONNECTION,
    });
    setSlackCode(queryParameters.code);
    const timer = setTimeout(() => {
      window.close();
    }, delay);
    return () => clearTimeout(timer);
  }, [queryParameters.code, setSlackCode]);

  return queryParameters.code ? (
    <>
      <Flex
        height="350px"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <SVGIcon
          size="54px"
          icon="checkmark-circle"
          color={ThemeV2.palette.polarGreen7}
        />
        <Flex
          margin="20px 0"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Heading variant="h5">You have been successfully authorized!</Heading>
          <Heading variant="h5">Please close this window</Heading>
          <Body variant="body2">This will auto-close in few seconds...</Body>
        </Flex>
      </Flex>
    </>
  ) : (
    <h1>Something went wrong, please try again</h1>
  );
};

export default ConnectionRedirectPath;
