import { AxiosError } from 'axios';
import { REMIND_PARTICIPANTS_RESPONSE_MESSAGE } from '../../languages/en/flows/feed';
import { useSendFlowReminderQuery } from '../../queries/Flows/FlowFeedOptions/RemindParticipants';
import { showErrorMessage, showSuccessMessage } from '../../Utils/toast';

import { getErrorMessage } from '../../Utils/message';
import useToggle from '../useToggle';

const useRemindParticipantsOption = () => {
  const {
    models: { toggleValue: isRemindParticipantsModalOpen },
    operations: {
      setToggleToFalse: setRemindParticipantsModalClose,
      setToggleToTrue: setRemindParticipantsModalOpen,
    },
  } = useToggle();

  const { mutate: mutateRemindParticipants, isLoading } =
    useSendFlowReminderQuery();

  const handleRemindParticipantsButtonClick = (
    flowId: string,
    occurrenceId?: string,
  ) => {
    mutateRemindParticipants(
      {
        flowId,
        occurrenceId,
      },
      {
        onError: (error) => {
          const errorMessage = getErrorMessage(
            error as AxiosError<{ message: string; body: string }>,
            REMIND_PARTICIPANTS_RESPONSE_MESSAGE.ERROR,
          );

          showErrorMessage(
            errorMessage || REMIND_PARTICIPANTS_RESPONSE_MESSAGE.ERROR,
          );
        },
        onSuccess: () => {
          setRemindParticipantsModalClose();
          showSuccessMessage(REMIND_PARTICIPANTS_RESPONSE_MESSAGE.SUCCESS);
        },
      },
    );
  };

  return {
    models: {
      isRemindParticipantsModalOpen,
      isRemindParticipantsLoading: isLoading,
    },
    operations: {
      setRemindParticipantsModalClose,
      setRemindParticipantsModalOpen,
      handleRemindParticipantsButtonClick,
    },
  };
};

export default useRemindParticipantsOption;
