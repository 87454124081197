import { useEffect } from 'react';
import {
  useGiftCardFetchQuery,
  useExchangeRatesFetchQuery,
  useIPDataFetchQuery,
} from '../../../queries/Rewards';
import { getCurrencyCodes } from '../../../Utils/rewards';
import { RewardsFetchQueryParamsType } from '../../../queries/Rewards/interfaces';
import useRewardDenominationSocket from '../../../hooks/useRewardDenominationsSocket';

const useGiftCardControllerModel = (params: RewardsFetchQueryParamsType) => {
  const {
    isInitialLoading: isRewardsLoading,
    isError,
    refetch,
    data: rewardsData,
  } = useGiftCardFetchQuery(params);
  useRewardDenominationSocket();

  const {
    isLoading: isExchangeRatesLoading,
    data: exchangeRatesData,
    mutate: exchangeRateMutate,
  } = useExchangeRatesFetchQuery();
  const { isLoading: isIpDataLoading, data: ipData } = useIPDataFetchQuery();

  useEffect(() => {
    if (rewardsData && rewardsData.rewards.length > 0 && !exchangeRatesData) {
      const currencyCodes = getCurrencyCodes(rewardsData.rewards);
      exchangeRateMutate(currencyCodes);
    }
  }, [exchangeRateMutate, exchangeRatesData, rewardsData]);

  const isLoading =
    isRewardsLoading || isExchangeRatesLoading || isIpDataLoading;

  return {
    isLoading,
    isError,
    refetch,
    rewardsData,
    exchangeRates: exchangeRatesData?.data.data.exchangeRates,
    ipData,
    denominationRangeSelectedByEmployer:
      rewardsData?.denominationRangeSelectedByEmployer,
  };
};

export default useGiftCardControllerModel;
