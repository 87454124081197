import React, { useCallback, useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import ThemeV2 from '../../../theme';
import { ProfilePopOverProps } from './interfaces';
import ProfileNavHeader from '../ProfileNavHeader';
import Divider from '../../atoms/Divider';
import PrimaryNavLink, {
  StyledExternalPrimaryNavLink,
} from '../PrimaryNavLink';
import routesList from '../../../routesList';
import { SecondaryPanel } from '../../../interfaces/Layout';
import useLayoutStore from '../../../stores/layoutStore';
import {
  generateV2UserProfileRoute,
  V2_GENERAL_SETTINGS,
  V2_MY_REWARDS,
  HELP_CENTER,
  PRODUCT_FEEDBACK,
} from '../../../constants/routes';
import {
  GET_LIVE_SUPPORT,
  GIVE_US_FEEDBACK,
  GO_TO_ADMIN,
  GO_TO_HELP_CENTER,
  VIEW_PROFILE,
  SWITCH_ACCOUNTS,
} from '../../../languages/en/home/profileDrawer';
import { DividerWrapper, ProfilePopoverWrapper } from './styles';
import RewardsPopover from '../RewardsPopover';
import { MY_REWARDS } from '../../../languages/en/rewards';
import { MY_SETTINGS } from '../../../languages/en/settings';
import { ADMIN_APP_URL } from '../../../config';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';
import isEmpty from 'lodash/isEmpty';
import SVGIcon from '../../atoms/SVGIcon';
import Body from '../../atoms/Body';
import AssemblyQRCodeModal from '../../organism/AssemblyQRCodeModal';
import { useIntercom } from 'react-use-intercom';
import { trackDiscoverActionEvent } from '../../../Utils/analytics/discover';
import { DISCOVER_EVENTS } from '../../../Utils/analytics/constants';

const ProfilePopover = (props: ProfilePopOverProps) => {
  const {
    img,
    onProfilePopoverClick,
    avatarSize,
    userName,
    onlineStatus,
    userId,
    isCurrentUserAdmin,
    hideRewardsLink,
    widthCheck,
    closePopover,
    rewardsProps,
    showQRMenu,
  } = props;

  const { setActiveNavItem, setActiveSecondaryPanel } = useLayoutStore(
    (state) => ({
      setActiveNavItem: state.setActiveNavItem,
      setActiveSecondaryPanel: state.setActiveSecondaryPanel,
    }),
    shallow,
  );

  const { show } = useIntercom();

  const setMySettingsPanel = () => {
    setActiveNavItem(SecondaryPanel.PROFILE);
    setActiveSecondaryPanel(SecondaryPanel.PROFILE);
  };

  const setAssemblyPanel = () => {
    setActiveNavItem(SecondaryPanel.ASSEMBLY);
    setActiveSecondaryPanel(SecondaryPanel.ASSEMBLY);
  };

  const profileRoute = useMemo(
    () => generateV2UserProfileRoute(userId),
    [userId],
  );

  const handleNavClick = useCallback(() => {
    trackDiscoverActionEvent({
      action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
      profileMenuItem: 'viewProfile',
      v3enabled: false,
    });
    onProfilePopoverClick({ memberID: userId, firstName: userName });
    closePopover();
  }, [closePopover, onProfilePopoverClick, userId, userName]);

  const handleModalClose = () => {
    if (showQRMenu) {
      showQRMenu.toggleQRModalOpen(false);
    }
  };

  return (
    <ProfilePopoverWrapper>
      <ProfileNavHeader
        img={img}
        avatarSize={avatarSize}
        userName={userName}
        onlineStatus={onlineStatus}
        userId={userId}
      />
      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>
      {!widthCheck && (
        <>
          <RewardsPopover
            assemblyCurrency={rewardsProps.assemblyCurrency}
            totMonthlyAllowance={rewardsProps.totMonthlyAllowance}
            remainingMonthlyAllowance={rewardsProps.remainingMonthlyAllowance}
            refreshInfoDate={rewardsProps.refreshInfoDate}
            trophyBalance={rewardsProps.trophyBalance}
            hideRedeemButton={rewardsProps.hideRedeemButton}
            hideAllowanceDetails={rewardsProps.hideAllowanceDetails}
            hideRewardsLink={hideRewardsLink}
            hideRewardsInfo={rewardsProps.hideRewardsInfo}
            redeemButtonClicked={rewardsProps.redeemButtonClicked}
            widthCheck={widthCheck}
            closePopover={closePopover}
            rewardType={rewardsProps.rewardType}
          />
          <DividerWrapper>
            <Divider color={ThemeV2.palette.gray4} isFullWidth />
          </DividerWrapper>
        </>
      )}
      {showQRMenu && (
        <>
          <PrimaryNavLink
            isActive={false}
            icon="mobile-app-icon"
            onClick={() => {
              showQRMenu.toggleQRModalOpen(true);
              trackDiscoverActionEvent({
                action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
                profileMenuItem: 'getMobileApp',
                v3enabled: false,
              });
            }}
          >
            Get our mobile app!
          </PrimaryNavLink>
          <DividerWrapper>
            <Divider color={ThemeV2.palette.gray4} isFullWidth />
          </DividerWrapper>
        </>
      )}
      <PrimaryNavLink
        icon="person-filled"
        isActive={false}
        onClick={handleNavClick}
        to={profileRoute}
      >
        {VIEW_PROFILE}
      </PrimaryNavLink>
      {isCurrentUserAdmin &&
        !microsoftTeamsStore.getState().isLoggedInThroughMobileTeamsApp &&
        (isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) ? (
          <PrimaryNavLink
            icon="admin-with-gear"
            isActive={false}
            data-testid="adminNavigation"
            onClick={() => {
              trackDiscoverActionEvent({
                action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
                profileMenuItem: 'goToAdmin',
                v3enabled: false,
              });
              closePopover();
              window.location.href = `${ADMIN_APP_URL}/settings`;
            }}
          >
            {GO_TO_ADMIN}
          </PrimaryNavLink>
        ) : (
          <StyledExternalPrimaryNavLink
            href={`${ADMIN_APP_URL}/settings`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SVGIcon icon="admin-with-gear" size="18px" />
            <Body margin="0 4px" variant="body2" color="darkGray9">
              {GO_TO_ADMIN}
            </Body>
          </StyledExternalPrimaryNavLink>
        ))}
      {!hideRewardsLink && (
        <PrimaryNavLink
          to={V2_MY_REWARDS}
          icon="gift"
          isActive={false}
          onClick={() => {
            trackDiscoverActionEvent({
              action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
              profileMenuItem: 'myRewards',
              v3enabled: false,
            });
            setAssemblyPanel();
            closePopover();
          }}
        >
          {MY_REWARDS}
        </PrimaryNavLink>
      )}
      <PrimaryNavLink
        icon="settings-2"
        isActive={false}
        onClick={() => {
          trackDiscoverActionEvent({
            action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
            profileMenuItem: 'mySettings',
            v3enabled: false,
          });
          setMySettingsPanel();
          closePopover();
        }}
        to={V2_GENERAL_SETTINGS}
        data-testid="mySettings"
        data-qa-id="mySettings"
      >
        {MY_SETTINGS}
      </PrimaryNavLink>

      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>

      <PrimaryNavLink
        icon="question-mark"
        isActive={false}
        to={HELP_CENTER}
        onClick={() => {
          trackDiscoverActionEvent({
            action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
            profileMenuItem: 'goToHelp',
            v3enabled: false,
          });
          closePopover();
        }}
      >
        {GO_TO_HELP_CENTER}
      </PrimaryNavLink>

      {isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) && (
        <PrimaryNavLink
          icon="answer"
          isActive={false}
          onClick={() => {
            trackDiscoverActionEvent({
              action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
              profileMenuItem: 'getLiveSupport',
              v3enabled: false,
            });
            show();
            closePopover();
          }}
        >
          {GET_LIVE_SUPPORT}
        </PrimaryNavLink>
      )}

      {isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) && (
        <PrimaryNavLink
          icon="survey"
          isActive={false}
          to={PRODUCT_FEEDBACK}
          onClick={() => {
            trackDiscoverActionEvent({
              action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
              profileMenuItem: 'giveUsFeedback',
              v3enabled: false,
            });
            closePopover();
          }}
        >
          {GIVE_US_FEEDBACK}
        </PrimaryNavLink>
      )}

      <DividerWrapper>
        <Divider color={ThemeV2.palette.gray4} isFullWidth />
      </DividerWrapper>

      <PrimaryNavLink
        icon="exit"
        to={routesList.SIGN_OUT}
        isActive={false}
        onClick={() => {
          trackDiscoverActionEvent({
            action: DISCOVER_EVENTS.PROFILE_MENU_ITEM_CLICKED,
            profileMenuItem: 'switchAccounts',
            v3enabled: false,
          });
          closePopover();
        }}
      >
        {SWITCH_ACCOUNTS}
      </PrimaryNavLink>
      {showQRMenu && (
        <AssemblyQRCodeModal
          isOpen={showQRMenu.isQRModalOpen}
          onModalClose={handleModalClose}
        />
      )}
    </ProfilePopoverWrapper>
  );
};

export default ProfilePopover;
