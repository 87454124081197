import { IdentityManagerResponse } from '../../interfaces/identityManager/common';

export const getCurrentHRISConnectionName = (
  response: IdentityManagerResponse,
) => {
  let hrisConnectionData = {
    isConnected: false,
    connectionName: '',
  };
  const currentHRISConnection = response?.identityManagers?.filter(
    (connection) => connection?.status === 'CONNECTED',
  );
  if (currentHRISConnection?.length > 0) {
    hrisConnectionData = {
      isConnected: true,
      connectionName:
        currentHRISConnection[0]?.id === 'MERGE' && response?.metaData
          ? response?.metaData?.integrationData?.name
          : currentHRISConnection[0]?.id,
    };
  }
  return hrisConnectionData;
};
