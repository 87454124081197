import { SocialProfiles } from '../../interfaces/Settings';

export const getSocialProfileName = (socialProfile: string) => {
  const profilePlaceholder = {
    LINKEDIN: 'LinkedIn',
    TWITTER: 'Twitter',
    INSTAGRAM: 'Instagram',
    FACEBOOK: 'Facebook',
    MEDIUM: 'Medium',
  };

  return profilePlaceholder[socialProfile] || 'LinkedIn';
};

export const getSocialProfilesPlaceholder = (
  socialProfiles: SocialProfiles,
) => {
  const selectedProfiles = socialProfiles
    .filter((profile) => profile.handle)
    .map((item) => getSocialProfileName(item.name));

  return selectedProfiles.length > 1
    ? selectedProfiles.join(',')
    : selectedProfiles.join('');
};

export const getSocialProfileIcon = (socialProfile: string) => {
  const profileIcons = {
    LINKEDIN: 'linkedin',
    TWITTER: 'twitter',
    INSTAGRAM: 'instagram',
    FACEBOOK: 'facebook',
    MEDIUM: 'medium',
  };

  return profileIcons[socialProfile] || 'linkedin';
};
