import { useCallback, useEffect, useMemo, useState } from 'react';

import ThemeV2 from '../../../theme';
import { getColor } from '../../../Utils/profilePic';

import SVGIcon from '../SVGIcon';
import {
  AvatarBackground,
  AvatarContainer,
  Img,
  StyledAvatarText,
  StyledOnlineStatus,
} from './styles';

import ToolTip from '../../molecules/ToolTipV2';
import { AvatarProps } from './interfaces';
import anonymousReplyIcon from '../../../img/svgs/anonymous-reply-avatar.svg';

export default function Avatar(props: AvatarProps) {
  const {
    img,
    className: parentClassName,
    size = '24px',
    verticalAlign,
    name = '',
    onHoverClick,
    onlineStatus,
    onlineStatusBorderColor = ThemeV2.palette.white,
    isDeleted = false,
    isAnonymous = false,
    userId = '',
    fontSize = '14px',
    icon,
    iconSize,
    toolTip,
    iconColor = ThemeV2.palette.gray3,
    iconBgColor = ThemeV2.palette.geekBlue6,
    backgroundColor = getColor({ _id: userId }).bg || '',
    imageBackgroundColor,
    fillAllIconPaths = false,
  } = props;

  const [loading, setLoading] = useState(true);
  const onLoadingComplete = useCallback(() => setLoading(false), []);

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    // THIS USE-EFFECT SHOULD ONLY BE CALLED WHEN IMAGE PROP IS CHANGED.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img]);

  const canShowAvatarText = userId && name;

  const renderImageFallback = useMemo(() => {
    // If there is no image url or image not loaded
    if (!icon && loading) {
      if (canShowAvatarText) {
        let avatarText = '';
        let color = '';
        const user = { _id: userId };
        const colors = getColor(user);
        avatarText = name.slice(0, 1).toUpperCase();
        color = colors.color;
        return (
          <StyledAvatarText
            size={size}
            backgroundColor={backgroundColor}
            color={color}
            fontSize={fontSize}
            justifyContent="center"
            alignItems="center"
          >
            {avatarText}
          </StyledAvatarText>
        );
      }

      if (name === 'Anonymous' && !userId) {
        return (
          <Img
            src={anonymousReplyIcon}
            alt={'anonymous-reply-avatar'}
            hide={false}
            data-testid={'anonymous-reply-avatar-test-id'}
            loading="eager"
            style={{ width: size, height: size }}
          />
        );
      }

      return (
        <SVGIcon icon="user" color={ThemeV2.palette.geekBlue1} size={size} />
      );
    }
    return null;
  }, [
    icon,
    loading,
    canShowAvatarText,
    name,
    size,
    userId,
    backgroundColor,
    fontSize,
  ]);

  const renderAvatarContainer = () => {
    return (
      <AvatarContainer
        size={size}
        className={parentClassName}
        iconBgColor={iconBgColor}
        data-testid="avatar"
        restrictHeight
      >
        {icon ? (
          <SVGIcon
            icon={icon}
            color={iconColor}
            fillAllPaths={fillAllIconPaths}
          />
        ) : (
          <Img
            src={img}
            alt={name ? `${name} avatar` : 'Avatar'}
            onLoad={onLoadingComplete}
            hide={loading}
            data-testid={img}
            loading="eager"
            verticalAlign={verticalAlign}
            backgroundColor={imageBackgroundColor || ThemeV2.palette.gray1}
          />
        )}
        {renderImageFallback}
        {onHoverClick && (
          <AvatarBackground
            data-testid="avatar-background"
            onClick={onHoverClick}
          >
            <SVGIcon icon="bin" color={ThemeV2.palette.gray1} />
          </AvatarBackground>
        )}
        {onlineStatus && (
          <StyledOnlineStatus
            onlineStatus={onlineStatus}
            onlineStatusBorderColor={onlineStatusBorderColor}
            data-testid="online-status"
          />
        )}
      </AvatarContainer>
    );
  };

  if (isDeleted || isAnonymous) {
    return (
      <AvatarContainer
        size={size}
        isDeleted={isDeleted}
        restrictHeight={!isAnonymous}
        className={parentClassName}
        data-testid={`avatar-with-is-${isDeleted ? 'deleted' : 'anonymous'}`}
      >
        <SVGIcon
          {...(iconSize && { size: iconSize })}
          icon={isAnonymous ? 'anonymous-avatar' : 'person-filled'}
          color={ThemeV2.palette.gray6}
        />
      </AvatarContainer>
    );
  }

  if (toolTip) {
    return (
      <ToolTip position="top" toolTipComponent={renderAvatarContainer()}>
        {toolTip}
      </ToolTip>
    );
  }
  return renderAvatarContainer();
}
