import React from 'react';

import useSettingsMultiSelectDropdownLogic from './useSettingsMultiSelectDropdownLogic';

import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { SAVE_CHANGES } from '../../../languages/en/settings';

import { SettingsMultiSelectDropdownProps } from './interface';
import {
  StyledButton,
  StyledSVGIcon,
  StyledHelperTextWrapper,
  HideBirthdayDetailsWrapper,
} from './styles';
import MultiSelectDropdown from '../../organism/MultiSelectDropdown';
import ThemeV2 from '../../../theme';
import Checkbox from '../../atoms/Checkbox';

const SettingsMultiSelectDropdown = (
  props: SettingsMultiSelectDropdownProps,
) => {
  const { isDisabled, checkBoxProps, canShowSaveChangesButton = true } = props;
  const { models, operations } = useSettingsMultiSelectDropdownLogic(props);
  const { multiSelectProps, helperTextMessage, isButtonDisabled } = models;
  const { onSaveChangesClick } = operations;
  return (
    <>
      <Flex>
        <MultiSelectDropdown {...multiSelectProps} />
        {isDisabled && (
          <StyledSVGIcon
            icon="lock"
            color={ThemeV2.palette.lightGray6}
            size="16px"
          />
        )}
      </Flex>
      {!checkBoxProps && (
        <StyledHelperTextWrapper>
          <Body variant="body4">{helperTextMessage}</Body>
        </StyledHelperTextWrapper>
      )}
      {!!checkBoxProps && (
        <>
          <Body variant="body4">{checkBoxProps.subTitle}</Body>
          <Flex alignItems="flex-start" width="453px" margin="16px auto">
            <Checkbox
              size="20px"
              name="hideBirthdayCheckbox"
              value={checkBoxProps.value}
              onChange={checkBoxProps.onChange}
              disabled={
                multiSelectProps.fieldOne.value === '' ||
                multiSelectProps.fieldTwo.value === ''
              }
            />
            <HideBirthdayDetailsWrapper>
              <Body variant="body3">{checkBoxProps.title}</Body>
            </HideBirthdayDetailsWrapper>
          </Flex>
        </>
      )}
      {canShowSaveChangesButton && (
        <StyledButton
          color="primary"
          variant="contained"
          status="default"
          disabled={
            checkBoxProps
              ? isButtonDisabled && checkBoxProps?.canDisableSaveButton
              : isButtonDisabled
          }
          onClick={onSaveChangesClick}
          size="small"
        >
          {SAVE_CHANGES}
        </StyledButton>
      )}
    </>
  );
};

export default SettingsMultiSelectDropdown;
