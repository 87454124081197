import React from 'react';
import ContentLoader from 'react-content-loader';

import Divider from '../../atoms/Divider';
import ProfileHeaderSectionLoader from '../../molecules/ProfileHeaderSection/Loader';
import ProfileDetailsSectionLoader from '../../molecules/ProfileDetailsSection/Loader';
import ThemeV2 from '../../../theme';

const ProfileCelebrationStatisticsSectionLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={293}
      height={138}
      viewBox="0 0 293 138"
      backgroundColor={ThemeV2.palette.gray4}
      foregroundColor={ThemeV2.palette.gray3}
      uniqueKey="profile-celebration-statistics-section-loader"
    >
      <rect x="23" y="16" rx="4" width="148" height="20" />

      <circle cx="40" cy="62" r="16" />
      <rect x="65" y="54" rx="4" width="110" height="16" />

      <circle cx="40" cy="102" r="16" />
      <rect x="65" y="94" rx="4" width="110" height="16" />
    </ContentLoader>
  );
};

const ProfileViewLoader = () => {
  return (
    <>
      <ProfileHeaderSectionLoader />
      <Divider color={ThemeV2.palette.gray5} isFullWidth />
      <ProfileCelebrationStatisticsSectionLoader />
      <Divider color={ThemeV2.palette.gray5} isFullWidth />
      <ProfileDetailsSectionLoader />
    </>
  );
};

export default ProfileViewLoader;
