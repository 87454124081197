import React, { memo, useEffect } from 'react';
import assemblyLogo from '../../../img/svgs/assembly-logo-mono.svg';
import { AssemblyLanderBg, InnerWrapper, StyledImage } from './styles';
import LoadingPulse from '../../atoms/LoadingPulse';
import { AppAction } from '../../../interfaces/PostMessage';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';

const AssemblyLanderLoadingScreen = () => {
  useEffect(() => {
    postMessageToMobileApp({
      action: AppAction.ApplicationBootstrapped,
      payload: { type: 'assemblyLanderLoadingScreen' },
    });
  }, []);

  return (
    <AssemblyLanderBg>
      <InnerWrapper>
        <StyledImage
          src={assemblyLogo}
          alt="assembly logo"
          data-test-d="img-assembly-logo"
        />
        <LoadingPulse />
      </InnerWrapper>
    </AssemblyLanderBg>
  );
};

const MemoizedAssemblyLanderLoadingScreen = memo(AssemblyLanderLoadingScreen);
MemoizedAssemblyLanderLoadingScreen.displayName = 'AssemblyLanderLoadingScreen';
export default MemoizedAssemblyLanderLoadingScreen;
