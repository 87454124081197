import { useMutation, useQuery } from '@tanstack/react-query';

import { makeAPICall, makeAPICallWithDataReturn } from '../utils';
import { AssemblySlugResponse, OnboardingRequest } from './interfaces';
import {
  ACCOUNT_SETUP_JOB,
  GET_ASSEMBLY_SLUG_NAME,
  GET_ONBOARDING_REDIRECT_URL,
  ONBOARD_INVITE_USER,
  VERIFY_EMAIL_ONBOARDING,
} from '../../constants/endpoints';

export const useValidateEmailRequestForOnboarding = () => {
  return useMutation((payload: OnboardingRequest) =>
    makeAPICall(VERIFY_EMAIL_ONBOARDING, payload),
  );
};

export const useRunAccountSetupJob = () => {
  return useMutation(
    (payload: { onboardingPath: string; shopForTemplate?: boolean }) =>
      makeAPICall(ACCOUNT_SETUP_JOB, payload),
  );
};

export const useGetAssemblySlug = (assemblyId: string) => {
  return useQuery<AssemblySlugResponse>(
    [GET_ASSEMBLY_SLUG_NAME, assemblyId],
    () =>
      makeAPICallWithDataReturn(GET_ASSEMBLY_SLUG_NAME, undefined, undefined, {
        assemblyId,
      }),
  );
};

export const useOnBoardInviteUser = (inviteToken: string) => {
  return useQuery([ONBOARD_INVITE_USER, inviteToken], () =>
    makeAPICall(ONBOARD_INVITE_USER, {
      inviteToken,
    }),
  );
};

export const useGetOnboardingRedirectUrl = (makeRequest = false) => {
  return useQuery<{ redirectTo: string }>(
    [GET_ONBOARDING_REDIRECT_URL],
    () =>
      makeAPICallWithDataReturn(
        GET_ONBOARDING_REDIRECT_URL,
        undefined,
        undefined,
      ),
    { enabled: makeRequest },
  );
};
