import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  GET_SHARE_REQUESTS,
  UPDATE_SHARE_REQUESTS,
} from '../../../constants/endpoints';
import { makeAPICall, makeAPICallWithDataReturn } from '../../utils';

export type ShareRequestsUsers = {
  requestId: string;
  permission: 'VIEWER' | 'PARTICIPANT' | 'PARTICIPANT_AND_VIEWER';
  requestedFor: {
    name: string;
    email: string;
    memberID: string;
    image: string;
    state: 'ACTIVE' | 'PENDING';
  };
};

export type ShareSheetsRequestsResponse = {
  data: ShareRequestsUsers[];
  total: number;
  metadata: {
    pagination: {
      cursor: {
        previous: string | null;
        next: string | null;
      };
    };
  };
};

export type ShareRequestsProps = {
  shareRequestId: string;
  action: 'APPROVE' | 'DENY';
  requestPermission: 'VIEWER' | 'PARTICIPANT' | 'PARTICIPANT_AND_VIEWER';
  source: 'SHARE_SHEET';
  message?: string;
};

type UpdateShareSheetRequestsPayload = {
  shareRequests: ShareRequestsProps[];
};

export const useGetShareRequestsQuery = (
  flowId: string,
  limit: number,
  enabled: boolean,
) => {
  return useInfiniteQuery<ShareSheetsRequestsResponse>(
    [GET_SHARE_REQUESTS, flowId],
    ({ pageParam = '' }) => {
      return makeAPICallWithDataReturn(
        GET_SHARE_REQUESTS,
        undefined,
        {
          limit: limit,
          cursor: pageParam,
        },
        {
          flowId,
        },
      );
    },
    {
      enabled: false || enabled,
      staleTime: 60 * 5 * 1000,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};

export const useUpdateShareSheetRequests = (flowId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: UpdateShareSheetRequestsPayload) =>
      makeAPICall(UPDATE_SHARE_REQUESTS, payload, undefined, { flowId }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([GET_SHARE_REQUESTS, flowId]);
      },
    },
  );
};
