import styled from 'styled-components';
import ThemeV2 from '../../../theme';

import Body from '../../../atomic/atoms/Body';
import Button from '../../../atomic/atoms/Button';
import { Flex } from '../../../Utils/styles/display';
import { StyledClickable } from '../../../atomic/atoms/Clickable_V2';
import { FlowAccordionItemState } from '../../../atomic/molecules/FlowAccordionItem/interface';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import InfoAlert from '../../../atomic/molecules/InfoAlert';
import IconContainer from '../../../atomic/atoms/IconContainer';

export const TabItemContainer = styled.section`
  grid-column-start: 2;
  grid-column-end: 12;

  @media (min-width: 320px) and (max-width: 1214px) {
    grid-column-start: 2;
    grid-column-end: 12;
  }

  @media (min-width: 1215px) {
    grid-column-start: 3;
    grid-column-end: 11;
  }
`;

export const FlowAccordionItemContainer = styled.section`
  margin: 0 48px 20px 48px;
`;

export const FlowAccordionItemTriggerContainer = styled.section`
  padding: 0px 36px 12px;
`;

export const StyledNextButton = styled(Button)`
  ::before {
    border: none;
  }

  width: 100%;
`;

export const Rectangle = styled.div`
  width: 4px;
  height: 24px;
  background: ${ThemeV2.palette.gray4};
`;

export const Border = styled.div`
  border-top: 1px solid ${ThemeV2.palette.gray4};
  margin: auto 12px;
  padding: 16px;
`;

export const FlowBuilderBlockContainer = styled.section<{
  blockStatus: FlowAccordionItemState;
}>`
  box-sizing: border-box;
  border: ${({ blockStatus }) =>
    (blockStatus === FlowAccordionItemState.Blur ||
      blockStatus === FlowAccordionItemState.Disabled) &&
    `1px solid ${ThemeV2.palette.gray4}`};
  border-radius: 4px;
  background: ${ThemeV2.palette.white};

  transition: 0.2s ease;
  transition-property: box-shadow;

  box-shadow: ${({ blockStatus }) =>
    blockStatus === FlowAccordionItemState.Focus &&
    `0px 4px 5px 0px rgba(0, 0, 0, 0.12),
    0px 3px 14px 0px rgba(0, 0, 0, 0.02), 0px 8px 10px 0px rgba(0, 0, 0, 0.04)`};

  &:hover {
    box-shadow: ${({ blockStatus }) =>
      blockStatus !== FlowAccordionItemState.Disabled &&
      `0 8px 10px rgba(0, 0, 0, 0.04), 0 3px 14px rgba(0, 0, 0, 0.02),
      0 4px 5px rgba(0, 0, 0, 0.12)`};
  }

  opacity: ${({ blockStatus }) =>
    blockStatus === FlowAccordionItemState.Disabled && '80%'};
`;

export const WarningMessage = styled(Flex)`
  padding: 8px;
  margin-bottom: 12px;
  border-radius: 4px;
  background: ${ThemeV2.palette.gold2};
`;

export const Message = styled(Body)`
  padding-left: 8px;
`;

export const Clickable = styled(StyledClickable)`
  font-size: 12px;
  padding-left: 4px;
  text-decoration: underline;
`;

export const FlowNavigationModal = styled(BasicModalTemplate)`
  width: 100%;
  max-width: 500px;
`;

export const StyledInfoAlert = styled(InfoAlert)`
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const StyledBody = styled(Body)`
  margin-bottom: 16px;
`;

export const StyledFlex = styled(Flex)`
  margin-bottom: 18px;
`;

export const StyledIconContainer = styled(IconContainer)`
  margin-left: 4px;
`;
