import { useEffect } from 'react';

import { ShareSheetRightDrawerControllerWrapper } from './styles';

import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { flowIdSelector } from '../../../stores/flowBuilderStore/selectors';

import FlowsShareSheetController from '../../flows/FlowsShareSheetController';
import { usePopulateFlowDetailsToStore } from '../../../hooks/usePopulateFlowDetailsToStore';

const ShareSheetRightDrawerController = () => {
  const flowId = useFlowBuilderStore(flowIdSelector) || '';

  const { populateFlowDetailsFromAPI } = usePopulateFlowDetailsToStore(flowId);

  useEffect(() => {
    populateFlowDetailsFromAPI();
  }, [populateFlowDetailsFromAPI]);

  return (
    <ShareSheetRightDrawerControllerWrapper>
      <FlowsShareSheetController />
    </ShareSheetRightDrawerControllerWrapper>
  );
};

export default ShareSheetRightDrawerController;
