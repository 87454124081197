import { useEffect, useState } from 'react';

import { APP_URL, SLACK } from '../../../../../config';
import useSlackUserStore from '../../../../../stores/userStore';
import { AutocompleteDropdownItem } from '../../../../../atomic/organism/Autocomplete/interfaces';

import {
  useGetConnectionsList,
  useCreateConnectionPool,
} from '../../../../../queries/Connections';
import { useGetChannelsJob } from '../useGetChannelsJob';
import { authStore } from '../../../../../stores/authStore';

const useSlackCriteriaOptionsContent = () => {
  const { slackCode, setSlackCode } = authStore();
  const { setIsCreatingConnectionPool } = useSlackUserStore();

  const [selectedConnection, setSelectedConnection] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  const [shouldCreateConnectionPool, setShouldCreateConnectionPool] =
    useState(false);

  const {
    data: connections,
    refetch: refetchConnectionsList,
    isInitialLoading: isLoadingConnections,
  } = useGetConnectionsList('slack');

  useEffect(() => {
    if (!isLoadingConnections && connections && connections.data.length === 0) {
      setShouldCreateConnectionPool(true);
    } else {
      setShouldCreateConnectionPool(false);
    }
  }, [connections, isLoadingConnections]);

  const {
    data: connectionPoolData,
    isInitialLoading: isCreatingConnectionPool,
  } = useCreateConnectionPool(
    {
      code: slackCode,
      integrationName: 'slack',
      redirectUri: `${APP_URL}${SLACK.REDIRECT_PATH}`,
    },
    shouldCreateConnectionPool && !!slackCode,
  );

  useEffect(() => {
    setIsCreatingConnectionPool(isCreatingConnectionPool);
  }, [isCreatingConnectionPool, setIsCreatingConnectionPool]);

  useEffect(() => {
    if (connectionPoolData) {
      refetchConnectionsList();
      setSlackCode('');
    }
  }, [connectionPoolData, refetchConnectionsList, setSlackCode]);

  const {
    refetchChannels,
    data: channelsListResponse,
    isLoading: isLoadingChannels,
    setIsLoadingChannels,
    isJobQueued: isGetChannelsListJobQueued,
  } = useGetChannelsJob(selectedConnection[0]?.id || '');

  useEffect(() => {
    const onStorageUpdate = (e: StorageEvent) => {
      const { key, newValue } = e;
      if (key === 'auth-store') {
        const data = JSON.parse(newValue || '{}');
        if (data?.state.slackCode) {
          setSlackCode(data.state.slackCode);
          setShouldCreateConnectionPool(true);
        }
      }
    };

    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, [setSlackCode]);

  return {
    slackCode,
    connections,
    refetchChannels,
    isLoadingChannels,
    selectedConnection,
    isLoadingConnections,
    setIsLoadingChannels,
    channelsListResponse,
    setSelectedConnection,
    isGetChannelsListJobQueued,
  };
};

export default useSlackCriteriaOptionsContent;
