import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FLOWS_FEEDS } from '../../constants/routes';

import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';

import { serializeBuilderBlockData } from '../../Utils/flows/builder/utils';
import { getErrorMessage } from '../../Utils/message';

import {
  FLOW_BUILDER_CREATION_ERROR,
  FLOW_BUILDER_EDIT_ERROR,
} from '../../languages/en/flows/builder';

import {
  useCreateFlowMutation,
  useEditFlowMutation,
} from '../../queries/Flows/Builder';

import { useQueryClient } from '@tanstack/react-query';
import {
  FLOW_BUILDER_EVENTS,
  INVITE_MEMBER_EVENTS,
} from '../../Utils/analytics/constants';
import {
  trackFlowBuilderActionEvent,
  trackFlowBuilderErrorEvent,
} from '../../Utils/analytics/flowsBuilder';
import {
  trackInviteMemberActionEvent,
  trackInviteMemberErrorEvent,
} from '../../Utils/analytics/inviteMembers';
import { GET_MEMBERS_FROM_CRITERIA } from '../../constants/endpoints';
import {
  INVITE_ERROR,
  INVITE_PENDING,
  INVITE_SUCCESS,
} from '../../languages/en/flows';
import { useInviteUserPostQuery } from '../../queries/Admin/invites';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useFlowBuilderStore from '../../stores/flowBuilderStore';
import {
  flowNameSelector,
  newContentMembersToInviteSelector,
  newParticipantsToInviteSelector,
  newVisibilityMembersToInviteSelector,
  setDisableNavigationModalSelector,
  templateIdSelector,
  templateNameSelector,
} from '../../stores/flowBuilderStore/selectors';
import useHistoryWrapper from '../useHistoryWrapper';
import { authStore } from '../../stores/authStore';

type UseSaveFlowParams = {
  openInviteNewTeammatesModal: () => void;
  closeInviteNewTeammatesModal: () => void;
  folderId: string | null;
};

const useSaveFlow = ({
  openInviteNewTeammatesModal,
  closeInviteNewTeammatesModal,
  folderId,
}: UseSaveFlowParams) => {
  const { isCreatingFlowForTheFirstTime } = authStore();

  const templateId = useFlowBuilderStore(templateIdSelector);
  const templateName = useFlowBuilderStore(templateNameSelector);
  const flowName = useFlowBuilderStore(flowNameSelector);

  const history = useHistoryWrapper();
  const storeData = useFlowBuilderStore();
  const { flowId } = useParams<{ flowId: string }>();
  const { mutate: uploadInviteUserAction } = useInviteUserPostQuery();
  const { data: profileInfo } = useProfileInfoFetchQuery();

  const newParticipantsToInvite = useFlowBuilderStore(
    newParticipantsToInviteSelector,
  );

  const newVisibilityMembersToInvite = useFlowBuilderStore(
    newVisibilityMembersToInviteSelector,
  );

  const newContentMembersToInvite = useFlowBuilderStore(
    newContentMembersToInviteSelector,
  );

  const setDisableNavigationModal = useFlowBuilderStore(
    setDisableNavigationModalSelector,
  );

  const canShowInviteNewTeammatesModal = (
    participantsToInvite: string[] = [],
    visibilityMembersToInvite: string[] = [],
    contentMembersToInvite: string[] = [],
  ) => {
    return (
      participantsToInvite.length > 0 ||
      visibilityMembersToInvite.length > 0 ||
      contentMembersToInvite.length
    );
  };

  const queryClient = useQueryClient();

  const { mutate: createFlowMutate, isLoading: isFlowBeingCreated } =
    useCreateFlowMutation();

  const handleOnEditFlowMutateSuccess = useCallback(() => {
    storeData.revertBlockDataToDefaultState();
    setDisableNavigationModal(true);
    history.push(FLOWS_FEEDS.replace(':flowId', flowId));
  }, [flowId, history, setDisableNavigationModal, storeData]);

  const { mutate: editFlowMutate, isLoading: isFlowBeingUpdated } =
    useEditFlowMutation(flowId, handleOnEditFlowMutateSuccess);

  const { search, state } = useLocation<{ flowIdToDuplicate: string }>();
  const isInSchedulerMode = search.includes('setSchedule');
  const isFlowBeingDuplicated = Boolean(state?.flowIdToDuplicate);

  const saveFlow = useCallback(() => {
    const storeDataParams = isFlowBeingDuplicated
      ? { ...storeData, currentSchedule: undefined }
      : storeData;
    const serializeBuilderBlockDataParams = {
      ...storeDataParams,
      canIncludeNewInviteMembers: false,
      isInSchedulerMode,
    };

    const payload = serializeBuilderBlockData(serializeBuilderBlockDataParams);

    if (
      canShowInviteNewTeammatesModal(
        newParticipantsToInvite,
        newVisibilityMembersToInvite,
        newContentMembersToInvite,
      )
    ) {
      openInviteNewTeammatesModal();
    } else {
      if (flowId) {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.FLOW_EDIT_SAVED,
          flowTitle: flowName,
          templateId,
          templateName,
          numCollaborators: payload.collaborators?.length || 0,
        });

        editFlowMutate(payload, {
          onSettled: (_data, error) => {
            if (!error) {
              handleOnEditFlowMutateSuccess();
            }
          },
          onError: (error) => {
            dismissAllToasts();

            const errorMessage = getErrorMessage(
              error as AxiosError<{ message: string; body: string }>,
              FLOW_BUILDER_EDIT_ERROR,
            );
            showErrorMessage(errorMessage || FLOW_BUILDER_EDIT_ERROR);
            trackFlowBuilderErrorEvent({
              error: FLOW_BUILDER_EVENTS.EDIT_SAVE_CHANGES_ERROR,
            });
          },
        });
      } else {
        trackFlowBuilderActionEvent({
          action: FLOW_BUILDER_EVENTS.FLOW_CREATED,
          flowTitle: flowName,
          templateId,
          templateName,
          numCollaborators: payload.collaborators?.length || 0,
        });

        if (folderId) {
          payload.folderId = folderId;
        }

        createFlowMutate(payload, {
          onSuccess: (res) => {
            storeData.revertBlockDataToDefaultState();
            setDisableNavigationModal(true);
            history.push(
              FLOWS_FEEDS.replace(
                ':flowId',
                isCreatingFlowForTheFirstTime
                  ? `${res.data.flowId}`
                  : `${res.data.flowId}?show-share-sheet=true`,
              ),
            );
          },
          onError: (error) => {
            const errorMessage = getErrorMessage(
              error as AxiosError<{ message: string; body: string }>,
              FLOW_BUILDER_CREATION_ERROR,
            );
            dismissAllToasts();
            showErrorMessage(errorMessage || FLOW_BUILDER_CREATION_ERROR);
            trackFlowBuilderErrorEvent({
              action: FLOW_BUILDER_EVENTS.CREATE_FLOW_ERROR,
            });
          },
        });
      }
    }
  }, [
    isFlowBeingDuplicated,
    storeData,
    isInSchedulerMode,
    newParticipantsToInvite,
    newVisibilityMembersToInvite,
    newContentMembersToInvite,
    openInviteNewTeammatesModal,
    flowId,
    flowName,
    templateId,
    templateName,
    editFlowMutate,
    handleOnEditFlowMutateSuccess,
    folderId,
    createFlowMutate,
    setDisableNavigationModal,
    history,
    isCreatingFlowForTheFirstTime,
  ]);

  const inviteAndSaveFlow = useCallback(() => {
    const serializeBuilderBlockDataParams = {
      ...storeData,
      canIncludeNewInviteMembers: false,
      isInSchedulerMode,
    };
    const payload = serializeBuilderBlockData(serializeBuilderBlockDataParams);

    // Show modal
    if (
      canShowInviteNewTeammatesModal(
        newParticipantsToInvite,
        newVisibilityMembersToInvite,
        newContentMembersToInvite,
      )
    ) {
      const allNewMembers = [
        ...newParticipantsToInvite,
        ...newVisibilityMembersToInvite,
        ...newContentMembersToInvite,
      ];
      const inviteUsers = allNewMembers?.map((emailId) => {
        return { email: emailId };
      });

      const inviteUsersPayload = {
        data: inviteUsers,
      };

      showInfoMessage(INVITE_PENDING);

      /* Mix Panel */
      const source = 'builder';
      let subSource: string[] = [];
      let inviteesEmail: string[] = [];
      if (newParticipantsToInvite.length) {
        subSource = ['participantCriteria'];
        inviteesEmail = [...newParticipantsToInvite];
      }
      if (newVisibilityMembersToInvite.length) {
        subSource = [...subSource, 'viewerCriteria'];
        inviteesEmail = [...inviteesEmail, ...newVisibilityMembersToInvite];
      }
      if (newContentMembersToInvite.length) {
        subSource = [...subSource, 'personSelection'];
        inviteesEmail = [...inviteesEmail, ...newContentMembersToInvite];
      }
      /* Mix Panel */

      uploadInviteUserAction(inviteUsersPayload, {
        onSuccess: (inviteSuccessData: any) => {
          queryClient.invalidateQueries([GET_MEMBERS_FROM_CRITERIA]);
          dismissAllToasts();
          if (inviteSuccessData.data.addedMembers.length > 0) {
            /* Mix Panel */
            trackInviteMemberActionEvent({
              action: INVITE_MEMBER_EVENTS.INVITE_COMPLETE,
              inviterId: profileInfo?.member.memberId,
              inviterRole: profileInfo?.member.role,
              inviteesEmails: inviteesEmail,
              source: source,
              subSource: subSource,
            });
            /* Mix Panel */
            showSuccessMessage(INVITE_SUCCESS);
          }
          closeInviteNewTeammatesModal();

          inviteSuccessData.data.failedMembers.forEach((element: any) => {
            showInfoMessage(`${element.email} ${element.message}`);
          });

          setDisableNavigationModal(true);
          if (flowId) {
            trackFlowBuilderActionEvent({
              action: FLOW_BUILDER_EVENTS.FLOW_EDIT_SAVED,
              flowTitle: flowName,
              templateId,
              templateName,
            });
            editFlowMutate(payload, {
              onSettled: (_data, error) => {
                if (!error) {
                  handleOnEditFlowMutateSuccess();
                }
              },
              onError: (error) => {
                dismissAllToasts();
                const errorMessage = getErrorMessage(
                  error as AxiosError<{ message: string; body: string }>,
                  FLOW_BUILDER_EDIT_ERROR,
                );
                showErrorMessage(errorMessage || FLOW_BUILDER_EDIT_ERROR);
                trackFlowBuilderErrorEvent({
                  error: FLOW_BUILDER_EVENTS.EDIT_SAVE_CHANGES_ERROR,
                });
              },
            });
          } else {
            trackFlowBuilderActionEvent({
              action: FLOW_BUILDER_EVENTS.FLOW_CREATED,
              flowTitle: flowName,
              templateId,
              templateName,
            });

            createFlowMutate(payload, {
              onSuccess: (res) => {
                storeData.revertBlockDataToDefaultState();
                setDisableNavigationModal(true);
                history.push(
                  FLOWS_FEEDS.replace(
                    ':flowId',
                    isCreatingFlowForTheFirstTime
                      ? `${res.data.flowId}`
                      : `${res.data.flowId}?show-share-sheet=true`,
                  ),
                );
              },
              onError: (error) => {
                dismissAllToasts();
                const errorMessage = getErrorMessage(
                  error as AxiosError<{ message: string; body: string }>,
                  FLOW_BUILDER_CREATION_ERROR,
                );
                showErrorMessage(errorMessage || FLOW_BUILDER_CREATION_ERROR);
                trackFlowBuilderErrorEvent({
                  error: FLOW_BUILDER_EVENTS.CREATE_FLOW_ERROR,
                });
              },
            });
          }
        },
        onError: () => {
          /* Mix Panel */
          trackInviteMemberErrorEvent({
            action: INVITE_MEMBER_EVENTS.INVITE_COMPLETE,
            inviterId: profileInfo?.member.memberId,
            inviterRole: profileInfo?.member.role,
            inviteesEmails: inviteesEmail,
            source: source,
            subSource: subSource,
          });
          /* Mix Panel */
          showErrorMessage(INVITE_ERROR);
        },
      });
    }
  }, [
    storeData,
    isInSchedulerMode,
    newParticipantsToInvite,
    newVisibilityMembersToInvite,
    newContentMembersToInvite,
    uploadInviteUserAction,
    queryClient,
    closeInviteNewTeammatesModal,
    setDisableNavigationModal,
    flowId,
    profileInfo?.member.memberId,
    profileInfo?.member.role,
    flowName,
    templateId,
    templateName,
    editFlowMutate,
    handleOnEditFlowMutateSuccess,
    createFlowMutate,
    history,
    isCreatingFlowForTheFirstTime,
  ]);

  return {
    saveFlow,
    inviteAndSaveFlow,
    isSavingFlow: isFlowBeingCreated || isFlowBeingUpdated,
  };
};

export default useSaveFlow;
