import Body from '../../atoms/Body';
import Image from '../../atoms/Image';
import SVGIcon from '../../atoms/SVGIcon';
import { Flex } from '../../../Utils/styles/display';
import {
  ContentWrapper,
  ImageHolder,
  LeftSideWrapper,
  RightSideWrapper,
  Title,
  Wrapper,
} from './styles';

export type ClickableSelectionItemProps = {
  className?: string;
  image: {
    src: string;
    width: string;
    height: string;
    backgroundColor?: string;
  };
  id: string;
  title: string;
  helperText?: string;
  margin?: string;
  children?: React.ReactNode;
  onItemClick?: (id: string) => void;
};

const ClickableSelectionItem = (props: ClickableSelectionItemProps) => {
  const {
    id,
    className,
    image,
    title,
    helperText,
    children,
    margin,
    onItemClick,
  } = props;
  const handleOptionItemClick = () => {
    if (onItemClick) {
      onItemClick(id);
    }
  };
  return (
    <Wrapper
      className={className}
      onClick={handleOptionItemClick}
      data-testid="selection-item"
      data-qa-id="selection-item"
      $margin={margin}
    >
      <>
        <LeftSideWrapper>
          <ImageHolder
            alignItems="center"
            justifyContent="center"
            backgroundColor={image.backgroundColor}
          >
            <Image
              src={image.src}
              alt="selection"
              height={image.height}
              width={image.width}
            />
          </ImageHolder>
        </LeftSideWrapper>
        <RightSideWrapper>
          <ContentWrapper>
            <Title variant="body1Medium">{title}</Title>
            {helperText && (
              <Body variant="body2" color="gray8">
                {helperText}
              </Body>
            )}
            {children}
          </ContentWrapper>
          <Flex width="16px" height="16px">
            <SVGIcon icon="carat-right" size="16px" />
          </Flex>
        </RightSideWrapper>
      </>
    </Wrapper>
  );
};

export default ClickableSelectionItem;
