import { Helmet } from 'react-helmet-async';
import PageHeader from '../../../atomic/molecules/PageHeader';
import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import { ERROR_FINDING_USER, PROFILE_FEED } from '../../../languages/en/header';
import Error from '../Error';
import Loader from '../Loader';

import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useProfileHeaderController from './useProfileHeaderController';
import useLayoutStore from '../../../stores/layoutStore';

const ProfileHeaderController = () => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const { userId, query, trackHomeClicked } = useProfileHeaderController();
  const { data: profileData } = useProfileInfoFetchQuery();

  if (query.isLoading || query.isFetching) {
    return <Loader isEmbeddedInMainApp={isEmbeddedInMainApp} />;
  }

  if (query.isError) {
    return <Error header={ERROR_FINDING_USER} subheader={PROFILE_FEED} />;
  }

  const { profile, email } = query.data.membersDetails[0];
  const {
    department,
    firstName,
    image,
    lastName,
    username,
    location,
    jobTitle,
  } = profile;

  const fullName = `${firstName} ${lastName}`;

  const workspaceSlugName = profileData?.assembly.workspaceSlug.name;

  const openGraphTitle = `${fullName} | Profile | ${
    !workspaceSlugName && workspaceSlugName !== 'Assembly'
      ? `${workspaceSlugName} Assembly`
      : `Assembly`
  }`;

  const openGraphDescription = [jobTitle, department, email, location]
    .filter(Boolean)
    .join(' | ');

  return (
    <>
      <Helmet>
        <title>{fullName}</title>
        <meta property="og:title" content={openGraphTitle} />
        <meta property="og:description" content={openGraphDescription} />
        {image && <meta property="assembly:favicon" content={image} />}
      </Helmet>
      <PageHeader
        headerType={HeaderType.PROFILE}
        profile={{
          firstName,
          departments: department,
          imageUrl: image,
          lastName,
          username,
        }}
        userId={userId}
        onClick={trackHomeClicked}
      />
    </>
  );
};

export default ProfileHeaderController;
