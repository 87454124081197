import React from 'react';
import styled from 'styled-components';
import Body from '../../atoms/Body';
import { REWARDS_SWAGS } from '../../../languages/en/admin';
import UpgradeButton from '../../atoms/Button';
import {
  REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS,
  UPGRADE_BUTTON_TEXT,
  REWARD_EMPTY_SWAG_TITLE,
  REWARD_EMPTY_SWAG_DESCRIPTION,
  REWARD_UPGRAGE_SWAG_TITLE,
  REWARD_UPGRADE_SWAG_DESCRIPTION,
  RE_CONNECT_AXOMO,
} from '../../../languages/en/rewards';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import Button from '../../atoms/Button';
import InfoAlert from '../InfoAlert';
import { microsoftTeamsStore } from '../../../stores/microsoftStore';
import { APP_URL } from '../../../config';
import routesList from '../../../routesList';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';
import Image from '../../atoms/Image';
import assemblyAxomoImage from '../../atoms/SVGIcon/icons/admin-assembly-axomo-logos.svg';

interface EmptySwagCardProps {
  upgradeRequired: boolean;
  onGotoAdminSwagSettingsClick: () => void;
  hasError?: boolean;
}

const StyledEmptySwagCard = styled(Flex)`
  max-width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
`;

const StyledEmptySwagNote = styled(Flex)`
  max-width: 310px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const StyledDescriptionWrapper = styled.div`
  width: calc(100% - 50px);
`;

const StyledInfoAlert = styled(InfoAlert)`
  margin: 48px auto;
  max-width: 550px;
  width: 100%;
`;

const HeaderCard = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 6px;
  justify-content: center;
  background-color: ${ThemeV2.palette.white};
  border: 1px solid ${ThemeV2.palette.white};
  color: ${ThemeV2.palette.gray8};
  border-width: 1px;
  border-style: solid;
  padding: 15px 16px;
  max-width: 350px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s ease-in;
  box-sizing: border-box;
`;

const EmptyAndErrorSwagsDisplayCard = (props: EmptySwagCardProps) => {
  const { upgradeRequired, onGotoAdminSwagSettingsClick, hasError } = props;

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  return (
    <>
      {hasError && (
        <StyledInfoAlert
          id="swagsErrorAlert"
          text={RE_CONNECT_AXOMO}
          alertType="custom"
          icon="warning-hollow"
          customVariant={{
            color: ThemeV2.palette.gray9,
            iconColor: ThemeV2.palette.dustRed6,
            background: ThemeV2.palette.dustRed2,
          }}
        />
      )}
      <StyledEmptySwagCard>
        <HeaderCard>
          <Image src={assemblyAxomoImage} alt="assembly-axomo" />
          <Body
            variant="body2"
            color={ThemeV2.palette.gray8}
            textAlign="center"
          >
            {REWARDS_SWAGS.HEADER_CARD_TEXT}
          </Body>
        </HeaderCard>

        <StyledEmptySwagNote>
          {upgradeRequired ? (
            <>
              <Body variant="body1Bold">{REWARD_UPGRAGE_SWAG_TITLE}</Body>
              <StyledDescriptionWrapper>
                <Body variant="body2" margin="0 0 17px 0;" textAlign="center">
                  {REWARD_UPGRADE_SWAG_DESCRIPTION}
                </Body>
                {!isMobileView &&
                  (!isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) ? (
                    <a
                      href={`${APP_URL}${routesList.ADMIN_BILLING_ACCOUNT_LEGACY}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Body variant="body2" color="gold6" inline>
                        {UPGRADE_BUTTON_TEXT}
                      </Body>
                    </a>
                  ) : (
                    <UpgradeButton
                      status="info"
                      icon="crown-solid"
                      isFullWidth={true}
                    >
                      {UPGRADE_BUTTON_TEXT}
                    </UpgradeButton>
                  ))}
              </StyledDescriptionWrapper>
            </>
          ) : (
            <>
              <Body variant="body1Bold">{REWARD_EMPTY_SWAG_TITLE}</Body>
              <StyledDescriptionWrapper>
                <Body variant="body2" margin="0 0 17px 0;" textAlign="center">
                  {REWARD_EMPTY_SWAG_DESCRIPTION}
                </Body>
                {!isMobileView &&
                  (!isEmpty(microsoftTeamsStore.getState().msTeamsLoginHint) ? (
                    <a
                      href={`${APP_URL}${routesList.ADMIN_BILLING_ACCOUNT_LEGACY}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Body variant="body2" color="geekBlue6" inline>
                        {REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS}
                      </Body>
                    </a>
                  ) : (
                    <Button
                      color="primary"
                      isFullWidth={true}
                      onClick={onGotoAdminSwagSettingsClick}
                      dataQaId={`${REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS.replaceAll(
                        ' ',
                        '',
                      )}Btn`}
                    >
                      {REWARD_NAVIGATE_TO_ADMIN_SWAG_SETTINGS}
                    </Button>
                  ))}
              </StyledDescriptionWrapper>
            </>
          )}
        </StyledEmptySwagNote>
      </StyledEmptySwagCard>
    </>
  );
};

export default EmptyAndErrorSwagsDisplayCard;
