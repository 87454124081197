import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import {
  PRESS_ENTER,
  TO_PROCEED,
  CLICK_POST_TO_SUBMIT,
  PRESS_SHIFT,
  FOR_LINE_BREAK,
} from '../../../languages/en/flows';
import { ENTER, OR } from '../../../languages/en/singleWords';

const StyledBody = styled(Body)`
  display: flex;
  align-items: center;
`;

const StyledIconWrapper = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background-color: ${ThemeV2.palette.gray3};
`;

type FlowsInputBlockNavigationInstructionsProps = {
  type: 'enter' | 'shift+enter' | 'last+enter' | 'last+shift+enter' | null;
  goToNextStep: () => void;
  customEnterCheck?: () => boolean;
  isPreviewFlow?: boolean;
};

const FlowsInputBlockNavigationInstructions = ({
  type,
  goToNextStep,
  customEnterCheck,
  isPreviewFlow,
}: FlowsInputBlockNavigationInstructionsProps) => {
  const handleEnterKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const customCheckResult = customEnterCheck ? customEnterCheck() : true;
      if (event.key === 'Enter' && customCheckResult) {
        goToNextStep();
      }
    },
    [goToNextStep, customEnterCheck],
  );

  useEffect(() => {
    window.addEventListener('keypress', handleEnterKeyPress);

    return () => window.removeEventListener('keypress', handleEnterKeyPress);
  }, [handleEnterKeyPress]);

  switch (type) {
    case 'enter': {
      return (
        <StyledBody variant="body3" color="gray8">
          {PRESS_ENTER}&nbsp;{' '}
          <StyledIconWrapper>
            <SVGIcon icon="return-arrow" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; {TO_PROCEED}
        </StyledBody>
      );
    }

    case 'last+enter': {
      if (isPreviewFlow) {
        return null;
      }
      return (
        <StyledBody variant="body3" color="gray8">
          {CLICK_POST_TO_SUBMIT}
        </StyledBody>
      );
    }

    case 'shift+enter': {
      return (
        <StyledBody variant="body3" color="gray8">
          {PRESS_SHIFT}&nbsp;{' '}
          <StyledIconWrapper>
            <SVGIcon icon="arrow-thick-up" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; + {ENTER} &nbsp;
          <StyledIconWrapper>
            <SVGIcon icon="return-arrow" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; {FOR_LINE_BREAK} {OR.toLowerCase()} {ENTER} &nbsp;
          <StyledIconWrapper>
            <SVGIcon icon="return-arrow" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; {TO_PROCEED.toLowerCase()}
        </StyledBody>
      );
    }

    case 'last+shift+enter': {
      return (
        <StyledBody variant="body3" color="gray8">
          {PRESS_SHIFT}&nbsp;{' '}
          <StyledIconWrapper>
            <SVGIcon icon="arrow-thick-up" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; + {ENTER} &nbsp;
          <StyledIconWrapper>
            <SVGIcon icon="return-arrow" size="12px" />
          </StyledIconWrapper>{' '}
          &nbsp; {FOR_LINE_BREAK}{' '}
          {!isPreviewFlow && `${OR.toLowerCase()} ${CLICK_POST_TO_SUBMIT}`}
        </StyledBody>
      );
    }
    default:
      return null;
  }
};

export default FlowsInputBlockNavigationInstructions;
