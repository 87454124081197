import React from 'react';
import { EmoticonHolderProps, EmoticonHolderSizes } from './interface';
import { IconHolder, StyledEmoticon } from './styles';

const getEmoticonHolderSizes = {
  large: '60px',
  medium: '56px',
  medium2: '48px',
  medium3: '40px',
  small: '32px',
  extraSmall: '24px',
};

const getEmoticonSizes = {
  large: '38px',
  medium: '30px',
  medium2: '24px',
  medium3: '20px',
  small: '16px',
  extraSmall: '12px',
};

const EmoticonHolder = (props: EmoticonHolderProps) => {
  const {
    className,
    color,
    emoticon,
    size = EmoticonHolderSizes.Medium,
    isRounded = false,
    borderColor,
  } = props;

  return (
    <IconHolder
      color={color}
      size={getEmoticonHolderSizes[size]}
      className={className}
      isRounded={isRounded}
      borderColor={borderColor}
    >
      <StyledEmoticon data-testid="emoticon" size={getEmoticonSizes[size]}>
        {emoticon}
      </StyledEmoticon>
    </IconHolder>
  );
};

export default EmoticonHolder;
