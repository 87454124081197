import styled from 'styled-components';
import IconButton from '../../atoms/IconButton_V2';
import Heading from '../../atoms/Heading';
import ThemeV2 from '../../../theme';
import { Flex } from '../../../Utils/styles/display';
import { StyledComponentProps } from './interface';
import { device } from '../../../constants/layout';

export const MobileModalHeader = styled(Flex)`
  height: 72px;
  border-bottom: 1px solid #e8e8e8;
`;

export const ModalBackground = styled.div<StyledComponentProps>`
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${ThemeV2.palette.gray1};
  border-radius: 2px;
  overflow: hidden;
  @media ${device.mobile} {
    height: calc(100vh - 72px);
    width: 100%;
    flex-wrap: wrap;
    overflow: scroll;
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 0;
`;

export const StyledHeading = styled(Heading)`
  margin: auto;
  text-align: center;
  width: calc(100vw - 74px);
`;

export const ColumnLeft = styled.div`
  width: 375px;
  background: ${ThemeV2.palette.gray1};
  z-index: 1;
  border-right: ${`1px solid ${ThemeV2.palette.gray5}`};
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const ColumnRight = styled.div`
  width: 375px;
  padding: 16px 0px;
  background: ${ThemeV2.palette.gray3};
  @media ${device.mobile} {
    width: 100%;
    padding: 0px;
    margin: 0px 32px 24px 32px;
  }
`;

export const ColumnRightContent = styled.div<{ height?: string }>`
  padding: 0 24px 64px 24px;
  height: ${({ height }) => (height ? height : '500px')};
  overflow-y: auto;
  @media ${device.mobile} {
    height: auto;
    padding: 24px 32px;
  }
`;

export const ModalNavigation = styled.div`
  display: flex;
  width: 100%;
  padding: 0px 12px;
  box-sizing: border-box;
`;

export const ModalNavigationButton = styled(IconButton)`
  margin-right: 8px;
  &:disabled {
    & svg {
      opacity: 0.4;
    }
  }
`;

export const ModalCloseButton = styled(ModalNavigationButton)`
  margin-left: auto;
  margin-right: 0;
  padding: 0;
  width: 32px;
  height: 32px;

  &:hover {
    svg > rect {
      fill: ${ThemeV2.palette.geekBlue2};
    }
  }
`;
