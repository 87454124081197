import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { create } from 'zustand';
import { MenuItemIndividualItem } from '../../atomic/molecules/Dropdown_V2/interfaces';
import { TaskCreationPosition } from '../../controllers/notebook/NotebookBoardTasksController/types';
import {
  getTaskItemDueDate,
  getModalPropsBasedOnCategoryAndTab,
  getModalPropsBasedOnCategory,
  hasTaskChanged,
} from '../../controllers/notebook/NotebookViewController/utils';
import {
  BoardNotesData,
  DeadlineNotesData,
  MiscTaskCategories,
  NotebookCategoryData,
  NotebookOperations,
  NotebookTask,
  NotebookTransaction,
  NotebookViews,
  TaskCategories,
  TransactionData,
} from '../../interfaces/notebook';
import {
  addTransactionToQueue,
  getTransactionFromTask,
  convertAPITaskToTaskAndCategory,
  isAssignedToCurrentMember,
  isTaskInMemberNotebook,
} from '../../Utils/notebook';
import {
  BoardSection,
  BoardSectionData,
  CurrentTaskDataProps,
  ModalData,
  ModalProps,
  NotebookStore,
} from './types';
import {
  NotebookSectionDeleteSocketPayload,
  NotebookSectionUpdateSocketPayload,
} from '../../controllers/notebook/NotebookViewController/useNotebookPusher/types';
import {
  filterDropdownDataByCurrentSection,
  getUpdatedNotebookStoreStateAfterSectionDeletion,
} from './helpers';

export const defaultModalProps: ModalProps = {
  heading: '',
  bodyText: '',
  primaryButtonText: '',
  secondaryButtonText: '',
  primaryButtonType: 'warning',
};

export const setupNotebookData = <T>(fn: () => T) => ({
  ARCHIVED: fn(),
  COMPLETED: fn(),
  OVERDUE: fn(),
  TODAY: fn(),
  UNSCHEDULED: fn(),
  UPCOMING: fn(),
});

const defaultNotes = setupNotebookData<NotebookCategoryData>(() => ({
  tasks: [],
  total: 0,
  initialRequest: true,
  totalUnreadCount: 0,
}));

const createDefaultNotebookModalStore = (): ModalData => ({
  modalProps: defaultModalProps,
  isOpen: false,
  currentTaskData: null,
  callback: () => {},
  hasCheckbox: false,
});

const createDefaultNotebookStore = (
  totalTodayAndOverdueTasks?: number | undefined,
): {
  deadlineNotes: DeadlineNotesData;
  transactions: TransactionData;
  erroredTaskIDs: string[];
  allNotes: Record<number, NotebookTask>;
  idMap: Record<string, string>;
  currentView: NotebookViews;
  autofocusTask: TaskCategories | undefined;
  totalTodayAndOverdueTasks: number | undefined;
  boardSectionData: BoardSectionData;
  sectionOrder: string[];
  boardNotes: BoardNotesData;
  completedSectionId: string | null;
  sectionalDropdownData: MenuItemIndividualItem[];
} => ({
  deadlineNotes: defaultNotes,
  transactions: { transactionQueue: [] },
  erroredTaskIDs: [],
  allNotes: {},
  idMap: {},
  currentView: NotebookViews.Board,
  autofocusTask: undefined,
  totalTodayAndOverdueTasks: totalTodayAndOverdueTasks || undefined,
  boardSectionData: {},
  sectionOrder: [],
  boardNotes: {},
  completedSectionId: '',
  sectionalDropdownData: [],
});

const useNewNotebookStore = create<NotebookStore>()((set) => ({
  ...createDefaultNotebookStore(),
  ...createDefaultNotebookModalStore(),
  setAutofocusTask: (category?: TaskCategories) =>
    set(() => ({ autofocusTask: category })),
  toggleCurrentView: (newView: NotebookViews) =>
    set(() => ({
      currentView: newView,
    })),
  resetNotebookStoreData: () =>
    set((currentData) =>
      createDefaultNotebookStore(currentData?.totalTodayAndOverdueTasks),
    ),
  resetNotes: () => set(() => ({ deadlineNotes: defaultNotes })),
  updateCount: (count: number) =>
    set(() => ({
      totalTodayAndOverdueTasks: count,
    })),
  addSingleNote: (task) =>
    set(({ allNotes, idMap }) => {
      const { taskId, noteId } = task;
      if (allNotes[taskId]) {
        return { allNotes, idMap };
      }
      return {
        allNotes: {
          ...allNotes,
          [taskId]: task,
        },
        idMap: {
          ...idMap,
          [noteId]: taskId,
        },
      };
    }),
  editSingleNote: (task) =>
    set(({ allNotes }) => {
      const { taskId } = task;
      return {
        allNotes: {
          ...allNotes,
          [taskId]: task,
        },
      };
    }),
  updateNoteIds: (transactions: TransactionData, noteIds: string[]) =>
    set((currentData) => {
      const { allNotes, idMap } = currentData;
      const { transactionQueue } = transactions;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedIdMap = {
        ...idMap,
      };
      transactionQueue.forEach((transaction, index) => {
        // Assumption: Transactions and noteIds are serialized in the same order
        const noteId = noteIds[index];
        const { taskId } = transaction;
        allUpdatedNotes[taskId] = {
          ...allUpdatedNotes[taskId],
          noteId,
        };
        updatedIdMap[noteId] = taskId;
      });
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
      };
    }),
  updateTransactions: (transactions: TransactionData) =>
    set(() => ({
      transactions,
    })),
  removeTransaction: (transaction: NotebookTransaction) =>
    set((currentData) => {
      const {
        erroredTaskIDs,
        transactions: { transactionQueue },
      } = currentData;

      const updatedTransactionQueue = transactionQueue.filter(
        (item) => item.taskId !== transaction.taskId,
      );
      const updatedErroredTaskIds = erroredTaskIDs.filter(
        (erroredTaskId) => erroredTaskId !== transaction.taskId,
      );

      return {
        erroredTaskIDs: updatedErroredTaskIds,
        transactions: {
          transactionQueue: updatedTransactionQueue,
        },
      };
    }),
  updateErrorIDs: (errorIDs: string[]) =>
    set(() => ({ erroredTaskIDs: errorIDs })),
  uploadSectionData: (taskCategory, tasks, total, memberId) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, idMap, erroredTaskIDs } = currentData;
      const currentNotesArr = deadlineNotes[taskCategory].tasks;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedIdMap = {
        ...idMap,
      };
      const updatedSectionalTasks: string[] = [];
      erroredTaskIDs.forEach((errorId: string) => {
        if (currentNotesArr.includes(errorId)) {
          updatedSectionalTasks.push(errorId);
        }
      });
      let totalUnreadCount = 0;
      tasks.forEach((task) => {
        const shouldShowUnreadTreatment = isAssignedToCurrentMember(
          task,
          memberId,
        );
        const { taskId, noteId } = task;
        // Assumption: since react-query caches the API call, when we change tabs,
        // the component gets mounted again and this function is called. Hence, we are assuming
        // that unless we have pusher based updates or updateTask function being called,
        // the data present under allNotes[taskId] to be the latest one.
        if (!allNotes[taskId])
          allUpdatedNotes[taskId] = {
            ...task,
            isUnread: task?.isUnread && shouldShowUnreadTreatment,
          };
        updatedIdMap[noteId] = taskId;
        updatedSectionalTasks.push(taskId);
        if (task?.isUnread && shouldShowUnreadTreatment) {
          totalUnreadCount += 1;
        }
      });

      const updatedNotes: DeadlineNotesData = {
        ...deadlineNotes,
        [taskCategory]: {
          tasks: updatedSectionalTasks,
          total,
          totalUnreadCount,
        },
      };

      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
        deadlineNotes: updatedNotes,
      };
    }),
  createTask: (task) => {
    let newTransaction: NotebookTransaction | null = null;

    set((currentData) => {
      const { deadlineNotes, allNotes, transactions } = currentData;
      const { taskId, type } = task;
      const updatedNotes = produce(deadlineNotes, (draft) => {
        draft[type].tasks.unshift(taskId);
        draft[type].total += 1;
      });
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: task,
      };
      let taskIdOfFirstItemInSection: string | undefined;
      if (deadlineNotes[type].tasks.length > 0) {
        taskIdOfFirstItemInSection = deadlineNotes[type].tasks[0];
      }

      newTransaction = getTransactionFromTask(
        task,
        type,
        undefined,
        taskIdOfFirstItemInSection
          ? allUpdatedNotes[taskIdOfFirstItemInSection].taskId
          : undefined,
        undefined,
        true,
        NotebookOperations.CREATE,
      );

      const updatedTransactions = addTransactionToQueue(
        newTransaction,
        transactions,
      );
      return {
        deadlineNotes: updatedNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
      };
    });

    return newTransaction;
  },
  editTaskContent: (updatedTask) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions } = currentData;
      const { taskId, type } = updatedTask;
      const shouldTaskBeDeleted = !updatedTask.note;
      const tasksArr = deadlineNotes[type].tasks;
      const noteIndex = tasksArr.indexOf(taskId);
      const updatedNotes = produce(deadlineNotes, (draft) => {
        const { tasks } = draft[type];
        if (shouldTaskBeDeleted) {
          tasks.splice(noteIndex, 1);
        }
      });
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          updatedTask,
          type,
          undefined,
          undefined,
          undefined,
          false,
          NotebookOperations.UPDATE,
        ),
        transactions,
      );
      const allUpdatedNotes = {
        ...allNotes,
      };
      if (shouldTaskBeDeleted) {
        delete allUpdatedNotes[taskId];
      } else {
        allUpdatedNotes[taskId] = updatedTask;
      }
      return {
        allNotes: allUpdatedNotes,
        deadlineNotes: updatedNotes,
        transactions: updatedTransactions,
      };
    }),
  updateTask: (updatedTask, previousCategory, memberId, isAssignment = false) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions, currentView, boardNotes } =
        currentData;
      const { taskId, type, section, assignedTo } = updatedTask;
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: updatedTask,
      };
      const previousSectionId = allNotes[taskId]?.section;
      let updatedBoardNotes = { ...boardNotes };
      const updatedNotes = produce(deadlineNotes, (draft) => {
        const taskToBeMovedIndex =
          draft[previousCategory].tasks.indexOf(taskId);
        if (previousCategory !== type) {
          if (taskToBeMovedIndex >= 0) {
            draft[previousCategory].tasks.splice(taskToBeMovedIndex, 1);
            draft[previousCategory].total -= 1;
            draft[type].tasks.unshift(taskId);
            draft[type].total += 1;
          }
        }
      });
      let taskIdOfFirstItemInSection: string | undefined;
      if (deadlineNotes[type].tasks.length > 1 && previousCategory !== type) {
        taskIdOfFirstItemInSection = deadlineNotes[type].tasks[0];
      }

      // Changing assignee should delete task from board view
      if (
        currentView === NotebookViews.Board &&
        isAssignment &&
        assignedTo?.memberID !== memberId
      ) {
        updatedBoardNotes = produce(boardNotes, (draft) => {
          if (section) {
            draft[section].tasks = draft[section]?.tasks.filter(
              (id) => id !== taskId,
            );
          }
        });
      }
      // Moving tasks through board view dropdown menu
      if (
        currentView === NotebookViews.Board &&
        allNotes[taskId].type !== type &&
        type === TaskCategories.COMPLETED &&
        previousSectionId
      ) {
        updatedBoardNotes = produce(boardNotes, (draft) => {
          draft[previousSectionId].tasks = draft[
            previousSectionId
          ]?.tasks.filter((id) => id !== taskId);
          if (section) draft[section]?.tasks.unshift(taskId);
        });
      }
      const sendPreviousSectionId = section !== previousSectionId;
      // Moving tasks through board view through right drawer dropdown menu
      if (section !== allNotes[taskId]?.section && previousSectionId) {
        updatedBoardNotes = produce(boardNotes, (draft) => {
          draft[previousSectionId].tasks = draft[
            previousSectionId
          ]?.tasks.filter((id) => id !== taskId);
          if (section) draft[section]?.tasks.unshift(taskId);
        });
      }

      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          updatedTask,
          type,
          undefined,
          taskIdOfFirstItemInSection
            ? allUpdatedNotes[taskIdOfFirstItemInSection].taskId
            : undefined,
          previousCategory,
          false,
          isAssignment ? NotebookOperations.ASSIGN : NotebookOperations.UPDATE,
          sendPreviousSectionId ? previousSectionId : null,
        ),
        transactions,
      );
      return {
        deadlineNotes: updatedNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
        boardNotes: updatedBoardNotes,
      };
    }),
  archiveTask: (updatedTask) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions, boardNotes } = currentData;
      const { taskId, type } = updatedTask;
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: updatedTask,
      };
      const previousCategory = allNotes[taskId]?.type;
      const previousSection = allNotes[taskId]?.section;
      const updatedNotes = produce(deadlineNotes, (draft) => {
        draft[previousCategory].tasks = draft[previousCategory]?.tasks?.filter(
          (task) => task !== taskId,
        );
        draft[previousCategory].total -= 1;
        draft[type].tasks.unshift(taskId);
        draft[type].total += 1;
      });

      const updatedBoardNotes = produce(boardNotes, (draft) => {
        if (
          previousSection &&
          draft[previousSection] &&
          draft[previousSection]?.tasks
        ) {
          draft[previousSection].tasks = draft[previousSection]?.tasks.filter(
            (id) => id !== taskId,
          );
        }
      });
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          updatedTask,
          type,
          undefined,
          deadlineNotes[type]?.tasks[0],
          previousCategory,
          false,
          NotebookOperations.UPDATE,
        ),
        transactions,
      );

      return {
        deadlineNotes: updatedNotes,
        boardNotes: updatedBoardNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
      };
    }),
  deleteTask: (taskId) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions, idMap } = currentData;
      const deletedTaskIndex = deadlineNotes.ARCHIVED.tasks.indexOf(taskId);
      const deletedTask = allNotes[taskId];
      const updatedNotes = produce(deadlineNotes, (draft) => {
        draft.ARCHIVED.tasks.splice(deletedTaskIndex, 1);
        draft.ARCHIVED.total -= 1;
      });
      const updatedIdMap = {
        ...idMap,
      };

      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: {
          ...allNotes[taskId],
          isDeleted: true,
        },
      };
      delete updatedIdMap[taskId];
      const deletedAPITask = getTransactionFromTask(
        deletedTask,
        TaskCategories.ARCHIVED,
        undefined,
        undefined,
        TaskCategories.ARCHIVED,
        false,
        NotebookOperations.DELETE,
      );
      deletedAPITask.taskData.state = 'DELETED';
      const updatedTransactions = addTransactionToQueue(
        deletedAPITask,
        transactions,
      );
      return {
        deadlineNotes: updatedNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
      };
    }),
  dragAndDropTask: (taskIdInString, source, destination, timezone) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions } = currentData;
      const taskId = taskIdInString;
      const hasCategoryChanged = source.category !== destination.category;
      const allUpdatedNotes = hasCategoryChanged
        ? {
            ...allNotes,
            [taskId]: {
              ...allNotes[taskId],
              dueDate: getTaskItemDueDate(destination.category, timezone),
              type: destination.category,
            },
          }
        : allNotes;
      const updatedNotes = produce(deadlineNotes, (draft) => {
        draft[source.category].tasks.splice(source.index, 1);
        draft[destination.category].tasks.splice(destination.index, 0, taskId);
        draft[source.category].total -= 1;
        draft[destination.category].total += 1;
      });
      const beforeTaskId =
        destination.index === 0
          ? undefined
          : updatedNotes[destination.category].tasks[destination.index - 1];
      const afterTaskId =
        destination.index + 1 >= updatedNotes[destination.category].tasks.length
          ? undefined
          : updatedNotes[destination.category].tasks[destination.index + 1];
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          allUpdatedNotes[taskId],
          destination.category,
          beforeTaskId || undefined,
          afterTaskId || undefined,
          undefined,
          false,
          NotebookOperations.UPDATE,
        ),
        transactions,
      );
      return {
        deadlineNotes: updatedNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
      };
    }),
  setModalData: (
    toggle: boolean,
    category?: TaskCategories | MiscTaskCategories,
    currentTaskData?: CurrentTaskDataProps,
    callback?: () => void,
    currentUserId?: string,
  ) =>
    set(() => {
      return {
        isOpen: toggle,
        currentTaskData: currentTaskData || null,
        modalProps: currentTaskData?.updatedTask
          ? getModalPropsBasedOnCategoryAndTab(
              currentTaskData?.updatedTask,
              currentUserId || '',
              currentTaskData?.updatedCategory,
            )
          : getModalPropsBasedOnCategory(category),
        callback: callback || (() => {}),
        hasCheckbox: false,
      };
    }),
  setCompletedSectionId: (sectionId: string) =>
    set(() => ({
      completedSectionId: sectionId,
    })),
  updateStoreForUnreadTasks: (taskId, taskCategory) =>
    set((currentData) => {
      const { allNotes, deadlineNotes } = currentData;

      const allUpdatedNotes = {
        ...allNotes,
      };

      const updatedNotes = produce(deadlineNotes, (draft) => {
        if (draft[taskCategory].totalUnreadCount > 0)
          draft[taskCategory].totalUnreadCount -= 1;
      });
      allUpdatedNotes[taskId] = {
        ...allUpdatedNotes[taskId],
        isUnread: false,
      };
      return {
        allNotes: allUpdatedNotes,
        deadlineNotes: updatedNotes,
      };
    }),
  updateBoardSectionOrder: (data) =>
    set((currentData) => {
      const { sectionId, source, destination } = data;
      const { sectionOrder } = currentData;
      const updateSectionOrder = [...sectionOrder];
      updateSectionOrder.splice(source.index, 1);
      updateSectionOrder.splice(destination.index, 0, sectionId);
      return {
        sectionOrder: updateSectionOrder,
      };
    }),
  dragAndDropBoardViewTask: (data, timezone) =>
    set((currentData) => {
      const {
        boardNotes,
        allNotes,
        deadlineNotes,
        transactions,
        boardSectionData: boardStatusData,
      } = currentData;
      const { taskId, source, destination } = data;
      const updatedBoardNotes = produce(boardNotes, (draft) => {
        draft[source.droppableId].tasks.splice(source.index, 1);
        draft[destination.droppableId].tasks.splice(
          destination.index,
          0,
          taskId,
        );
        draft[source.droppableId].total -= 1;
        draft[destination.droppableId].total += 1;
      });
      const sourceCategory = allNotes[taskId].type;
      const sourceSection = allNotes[taskId]?.section;
      const completedStatusData = filter(boardStatusData, {
        isCompleted: true,
      });
      const completedSectionId =
        completedStatusData?.length > 0
          ? completedStatusData[0]?.sectionId
          : undefined;
      let destinationCategory: TaskCategories;
      if (destination.droppableId !== completedSectionId) {
        destinationCategory =
          sourceSection === completedSectionId
            ? TaskCategories.UNSCHEDULED
            : sourceCategory;
      } else {
        destinationCategory = TaskCategories.COMPLETED;
      }
      const hasCategoryChanged =
        source?.droppableId !== destination?.droppableId;
      const allUpdatedNotes = hasCategoryChanged
        ? {
            ...allNotes,
            [taskId]: {
              ...allNotes[taskId],
              dueDate: getTaskItemDueDate(destinationCategory, timezone),
              type: destinationCategory,
              section: destination.droppableId,
              stateEffectiveAt:
                destinationCategory === TaskCategories.COMPLETED
                  ? getTaskItemDueDate(TaskCategories.TODAY, timezone)
                  : allNotes[taskId].stateEffectiveAt,
            },
          }
        : allNotes;
      const updatedDeadlineNotes = produce(deadlineNotes, (draft) => {
        draft[sourceCategory].tasks.splice(source.index, 1);
        draft[destinationCategory].tasks.splice(destination.index, 0, taskId);
        draft[sourceCategory].total -= 1;
        draft[destinationCategory].total += 1;
      });
      const beforeTaskId =
        destination.index === 0
          ? undefined
          : updatedBoardNotes[destination.droppableId].tasks[
              destination.index - 1
            ];
      const afterTaskId =
        destination.index + 1 >=
        updatedBoardNotes[destination.droppableId].tasks.length
          ? undefined
          : updatedBoardNotes[destination.droppableId].tasks[
              destination.index + 1
            ];
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          allUpdatedNotes[taskId],
          destinationCategory,
          beforeTaskId || undefined,
          afterTaskId || undefined,
          undefined,
          false,
          NotebookOperations.UPDATE,
          destination.droppableId === sourceSection ? undefined : sourceSection,
        ),
        transactions,
      );
      return {
        allNotes: allUpdatedNotes,
        boardNotes: updatedBoardNotes,
        deadlineNotes: updatedDeadlineNotes,
        transactions: updatedTransactions,
      };
    }),
  uploadBoardViewSectionalData: (section, tasks, total) =>
    set((currentData) => {
      const { boardNotes, allNotes, idMap, erroredTaskIDs } = currentData;
      const currentNotesArr = boardNotes[section?.sectionId]?.tasks || [];
      const allUpdatedNotes = {
        ...allNotes,
      };
      const updatedIdMap = {
        ...idMap,
      };
      const updatedSectionalTasks: string[] = [];
      erroredTaskIDs.forEach((errorId: string) => {
        if (currentNotesArr.includes(errorId)) {
          updatedSectionalTasks.push(errorId);
        }
      });
      tasks.forEach((task) => {
        const { taskId, noteId } = task;
        allUpdatedNotes[taskId] = {
          ...task,
          section: section?.sectionId,
        };
        updatedSectionalTasks.push(taskId);
        updatedIdMap[noteId] = taskId;
      });

      const updatedNotes: BoardNotesData = {
        ...boardNotes,
        [section?.sectionId]: {
          tasks: updatedSectionalTasks,
          total,
          totalUnreadCount: 0,
          initialRequest: false,
        },
      };
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
        boardNotes: updatedNotes,
      };
    }),
  updateBoardSections: (sections: BoardSection[]) =>
    set((currentData) => {
      const { boardSectionData: boardStatusData, boardNotes } = currentData;
      const updatedSectionDropdownData: MenuItemIndividualItem[] = [];
      const updatedBoardStatusData = {
        ...boardStatusData,
      };
      let updatedBoardNotes = {
        ...boardNotes,
      };
      const sectionOrder: string[] = [];
      sections.forEach((s) => {
        updatedBoardStatusData[s.sectionId] = { ...s } as BoardSection;
        if (!boardNotes[s.sectionId]) {
          updatedBoardNotes = {
            ...updatedBoardNotes,
            [s?.sectionId]: {
              tasks: [],
              total: 0,
              totalUnreadCount: 0,
              initialRequest: false,
            },
          };
        }
        updatedSectionDropdownData.push({
          id: s.sectionId,
          value: s.name,
        });
        sectionOrder.push(s?.sectionId);
      });
      return {
        boardSectionData: updatedBoardStatusData,
        sectionOrder: sectionOrder,
        sectionalDropdownData: updatedSectionDropdownData,
        boardNotes: updatedBoardNotes,
      };
    }),
  createTaskForBoardView: (task, section, position) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, transactions, boardNotes } = currentData;
      const { taskId, type } = task;
      const taskIdOfFirstItemInSection: string = boardNotes[section]?.tasks[0];

      const updatedBoardViewNotes = produce(boardNotes, (draft) => {
        if (!draft[section]) {
          draft[section] = {
            tasks: [taskId],
            total: draft[section]?.total + 1,
            initialRequest: false,
            totalUnreadCount: 0,
          };
        } else {
          if (position === TaskCreationPosition.top) {
            draft[section]?.tasks.unshift(taskId);
          }
          if (position === TaskCreationPosition.bottom) {
            draft[section]?.tasks.push(taskId);
          }
          draft[section].total = draft[section]?.total + 1;
        }
      });
      const updatedDeadlineViewNotes = produce(deadlineNotes, (draft) => {
        draft[type].tasks.unshift(taskId);
        draft[type].total += 1;
      });
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: { ...task, section },
      };
      const beforeTaskId =
        position === TaskCreationPosition.bottom
          ? boardNotes[section]?.tasks[boardNotes[section]?.tasks?.length - 1]
          : undefined;
      const afterTaskId =
        position === TaskCreationPosition.top && taskIdOfFirstItemInSection
          ? allUpdatedNotes[taskIdOfFirstItemInSection].taskId
          : undefined;
      const updatedTransactions = addTransactionToQueue(
        getTransactionFromTask(
          task,
          type,
          beforeTaskId,
          afterTaskId,
          undefined,
          true,
          NotebookOperations.CREATE,
        ),
        transactions,
      );
      return {
        deadlineNotes: updatedDeadlineViewNotes,
        transactions: updatedTransactions,
        allNotes: allUpdatedNotes,
        boardNotes: updatedBoardViewNotes,
      };
    }),
  createBoardViewSection: (section, positionData) =>
    set((currentData) => {
      const {
        boardNotes,
        boardSectionData,
        sectionOrder,
        sectionalDropdownData,
      } = currentData;
      let updatedBoardSectionData = { ...boardSectionData };
      const updatedSectionOrder = [...sectionOrder];
      const updatedSectionDropdownData = [...sectionalDropdownData];
      const updatedBoardViewNotes = produce(boardNotes, (draft) => {
        if (!draft[section?.sectionId]) {
          draft[section?.sectionId] = {
            tasks: [],
            total: draft[section?.sectionId]?.total + 1,
            initialRequest: false,
            totalUnreadCount: 0,
          };
          if (!isEmpty(positionData?.currentSectionId)) {
            const index = updatedSectionOrder.findIndex(
              (i) => i === positionData?.currentSectionId,
            );
            if (index > 0 && positionData?.position === 'left') {
              updatedSectionOrder?.splice(index, 0, section?.sectionId);
            }
            if (index > 0 && positionData?.position === 'right') {
              updatedSectionOrder?.splice(index + 1, 0, section?.sectionId);
            }
          } else {
            updatedSectionOrder?.push(section?.sectionId);
          }
          updatedSectionDropdownData.push({
            id: section.sectionId,
            value: section.name,
          });
        }
      });
      updatedBoardSectionData = {
        ...updatedBoardSectionData,
        [section?.sectionId]: section,
      };

      return {
        boardNotes: updatedBoardViewNotes,
        boardSectionData: updatedBoardSectionData,
        sectionOrder: updatedSectionOrder,
        sectionalDropdownData: updatedSectionDropdownData,
      };
    }),
  editBoardViewSectionTitle: ({ sectionId, sectionTitle }) =>
    set((currentData) => {
      const { boardSectionData, sectionalDropdownData, completedSectionId } =
        currentData;
      let updatedBoardSectionData = { ...boardSectionData };
      updatedBoardSectionData = {
        ...updatedBoardSectionData,
        [sectionId]: {
          ...updatedBoardSectionData[sectionId],
          name: sectionTitle,
          isCompleted: sectionId === completedSectionId,
        },
      };
      const updatedSectionDropdownData = filterDropdownDataByCurrentSection({
        sectionalDropdownData,
        currentSection: {
          sectionId,
          sectionTitle,
        },
      });
      return {
        boardSectionData: updatedBoardSectionData,
        sectionalDropdownData: updatedSectionDropdownData,
      };
    }),
  deleteBoardViewSection: (sectionId: string) =>
    set((currentData) => {
      const {
        boardSectionData,
        sectionOrder,
        sectionalDropdownData,
        boardNotes,
      } = getUpdatedNotebookStoreStateAfterSectionDeletion({
        currentNotebookStoreState: currentData,
        sectionIdToBeDeleted: sectionId,
      });
      return {
        boardSectionData,
        sectionOrder,
        sectionalDropdownData,
        boardNotes,
      };
    }),
  onPusherTaskCreate: (newTask, memberId, timezone) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, idMap } = currentData;
      const [serializedTask, taskCategory] = convertAPITaskToTaskAndCategory(
        newTask,
        timezone,
      );
      const { taskId, noteId } = serializedTask;
      const taskCategoryIds = deadlineNotes[taskCategory].tasks;
      const taskAlreadyExists = taskCategoryIds.includes(taskId);
      if (taskAlreadyExists) {
        return {
          allNotes,
          idMap,
          deadlineNotes,
        };
      }
      const shouldShowUnreadTreatment = isAssignedToCurrentMember(
        newTask,
        memberId,
      );
      const isTaskUnread =
        serializedTask?.isUnread && shouldShowUnreadTreatment;
      const updatedNotes = produce(deadlineNotes, (draft) => {
        draft[taskCategory].tasks.unshift(taskId);
        draft[taskCategory].total += 1;
        if (isTaskUnread && shouldShowUnreadTreatment)
          draft[taskCategory].totalUnreadCount += 1;
      });
      const allUpdatedNotes = {
        ...allNotes,
        [taskId]: {
          ...serializedTask,
          isUnread: isTaskUnread,
        },
      };
      const updatedIdMap = {
        ...idMap,
        [noteId]: taskId,
      };
      return {
        allNotes: allUpdatedNotes,
        idMap: updatedIdMap,
        deadlineNotes: updatedNotes,
      };
    }),
  onPusherTaskDelete: (deletedTask, timezone, sectionData) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, idMap, boardNotes } = currentData;
      const [serializedTask] = convertAPITaskToTaskAndCategory(
        deletedTask,
        timezone,
      );
      const { taskId, noteId } = serializedTask;
      const deletedNoteInStore = allNotes[taskId];
      if (deletedNoteInStore) {
        const allUpdatedNotes = {
          ...allNotes,
        };
        const updatedIdMap = {
          ...idMap,
        };
        delete allUpdatedNotes[taskId];
        delete updatedIdMap[noteId];
        const updatedNotes = produce(deadlineNotes, (draft) => {
          const categoryTasks = draft[deletedNoteInStore.type];
          const index = categoryTasks?.tasks?.indexOf(taskId);
          categoryTasks?.tasks.splice(index, 1);
          categoryTasks.total -= 1;
          if (
            categoryTasks?.totalUnreadCount > 0 &&
            allNotes[taskId].isUnread
          ) {
            categoryTasks.totalUnreadCount -= 1;
          }
        });
        const updatedBoardNotes = produce(boardNotes, (draft) => {
          const indexFromPreviousSection = sectionData
            ? draft[sectionData?.from]?.tasks?.findIndex(
                (id) => id === serializedTask?.taskId,
              )
            : -1;
          if (indexFromPreviousSection > -1 && sectionData?.from)
            draft[sectionData?.from].tasks.splice(indexFromPreviousSection, 1);
        });
        return {
          allNotes: allUpdatedNotes,
          idMap: updatedIdMap,
          deadlineNotes: updatedNotes,
          boardNotes: updatedBoardNotes,
        };
      }
      return {
        allNotes,
        idMap,
        deadlineNotes,
        boardNotes,
      };
    }),
  onPusherTaskUpdate: (updatedTask, memberId, timezone) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, idMap } = currentData;
      const [serializedTask] = convertAPITaskToTaskAndCategory(
        updatedTask,
        timezone,
      );
      const { noteId, taskId } = serializedTask;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const previousCategory = allUpdatedNotes[taskId]?.type;
      const shouldShowUnreadTreatment = isAssignedToCurrentMember(
        updatedTask,
        memberId,
      );
      const shouldUpdate = hasTaskChanged(
        allNotes[taskId] ? [allNotes[taskId]] : [],
        serializedTask,
      );
      const updatedNotes = produce(deadlineNotes, (draft) => {
        if (shouldUpdate) {
          const categoryTasks = draft[previousCategory];
          const index = categoryTasks?.tasks.indexOf(taskId);
          if (categoryTasks && index > -1) {
            categoryTasks?.tasks.splice(index, 1);
            if (categoryTasks.total && categoryTasks.total > 0)
              categoryTasks.total -= 1;
            if (categoryTasks?.totalUnreadCount)
              categoryTasks.totalUnreadCount -= 1;
          }

          // Do not update notes in Deadline View if the Task is not in the member's Notebook.
          // A socket event can be received for a Task that is neither assigned to or created by the member.
          if (isTaskInMemberNotebook(serializedTask, memberId)) {
            const updatedCategoryTasks = draft[serializedTask.type];
            updatedCategoryTasks.tasks.unshift(taskId);
            updatedCategoryTasks.total += 1;
            if (updatedCategoryTasks?.totalUnreadCount)
              updatedCategoryTasks.totalUnreadCount -= 1;

            if (serializedTask?.isUnread && shouldShowUnreadTreatment)
              draft[serializedTask.type].totalUnreadCount += 1;
          }

          allUpdatedNotes[taskId] = {
            ...serializedTask,
            section: allNotes[serializedTask?.taskId]?.section,
            isUnread: serializedTask?.isUnread && shouldShowUnreadTreatment,
          };
        }
      });
      return {
        deadlineNotes: updatedNotes,
        allNotes: allUpdatedNotes,
        idMap: {
          ...idMap,
          [noteId]: taskId,
        },
      };
    }),
  onPusherSectionDelete: (data: NotebookSectionDeleteSocketPayload) =>
    set((currentData) => {
      const {
        boardSectionData,
        sectionOrder,
        sectionalDropdownData,
        boardNotes,
      } = currentData;
      const sectionId = data.deletedSectionId;
      const updatedBoardViewNotes = { ...boardNotes };
      const updatedBoardSectionData = { ...boardSectionData };
      if (updatedBoardSectionData[sectionId]) {
        delete updatedBoardSectionData[sectionId];
      }
      if (updatedBoardViewNotes[sectionId]) {
        delete updatedBoardViewNotes[sectionId];
      }

      const updatedSectionOrder = sectionOrder.filter(
        (section) => section !== sectionId,
      );
      const updatedSectionDropdownData = sectionalDropdownData.filter(
        (section) => section?.id !== sectionId,
      );
      return {
        boardSectionData: updatedBoardSectionData,
        sectionOrder: updatedSectionOrder,
        sectionalDropdownData: updatedSectionDropdownData,
        boardNotes: updatedBoardViewNotes,
      };
    }),
  onPusherSectionUpdate: (data: NotebookSectionUpdateSocketPayload) =>
    set((currentData) => {
      const sectionId = data.sectionId;
      const {
        boardNotes,
        boardSectionData,
        sectionOrder,
        sectionalDropdownData,
      } = currentData;
      let updatedBoardSectionData = { ...boardSectionData };
      const updatedSectionOrder = [...sectionOrder];
      const updatedSectionDropdownData = [...sectionalDropdownData];
      const updatedBoardViewNotes = produce(boardNotes, (draft) => {
        if (!draft[sectionId]) {
          draft[sectionId] = {
            tasks: [],
            total: 0,
            initialRequest: false,
            totalUnreadCount: 0,
          };
          if (data?.before) {
            const sectionToBeMovedIndex = updatedSectionOrder.indexOf(
              data?.before,
            );
            if (sectionToBeMovedIndex > -1)
              updatedSectionOrder?.splice(
                sectionToBeMovedIndex + 1,
                0,
                sectionId,
              );
          } else {
            updatedSectionOrder?.push(sectionId);
          }
          updatedSectionDropdownData.push({
            id: sectionId,
            value: data.title || '',
          });
        }
      });
      updatedBoardSectionData = {
        ...updatedBoardSectionData,
        [sectionId]: {
          sectionId,
          type: 'CUSTOM',
          name: data.title || '',
          isCompleted: false,
          isUnassigned: false,
          count: 0,
        },
      };
      return {
        boardNotes: updatedBoardViewNotes,
        boardSectionData: updatedBoardSectionData,
        sectionOrder: updatedSectionOrder,
        sectionalDropdownData: updatedSectionDropdownData,
      };
    }),
  onPusherSectionalTaskUpdate: (socketData, memberId, timezone) =>
    set((currentData) => {
      const { deadlineNotes, allNotes, idMap, boardNotes } = currentData;
      const [serializedTask] = convertAPITaskToTaskAndCategory(
        socketData?.data.task,
        timezone,
      );
      const section =
        socketData && socketData.data ? socketData.data.section : undefined;

      const { noteId, taskId } = serializedTask;
      const allUpdatedNotes = {
        ...allNotes,
      };
      const previousCategory = allUpdatedNotes[taskId]?.type;
      const shouldShowUnreadTreatment = isAssignedToCurrentMember(
        socketData?.data.task,
        memberId,
      );
      const shouldUpdate = hasTaskChanged(
        allNotes[taskId] ? [allNotes[taskId]] : [],
        serializedTask,
      );
      const updatedNotes = produce(deadlineNotes, (draft) => {
        if (shouldUpdate) {
          const categoryTasks = draft[previousCategory];

          if (categoryTasks) {
            const index = categoryTasks?.tasks.indexOf(taskId);
            categoryTasks?.tasks.splice(index, 1);
            categoryTasks.total -= 1;
            if (categoryTasks?.totalUnreadCount)
              categoryTasks.totalUnreadCount -= 1;
          }

          // Do not update notes in Deadline View if the Task is not in the member's Notebook.
          // A socket event can be received for a Task that is neither assigned to or created by the member.
          if (isTaskInMemberNotebook(serializedTask, memberId)) {
            const updatedCategoryTasks = draft[serializedTask.type];
            updatedCategoryTasks.tasks.unshift(taskId);
            updatedCategoryTasks.total += 1;
            if (updatedCategoryTasks?.totalUnreadCount)
              updatedCategoryTasks.totalUnreadCount -= 1;

            if (serializedTask?.isUnread && shouldShowUnreadTreatment)
              draft[serializedTask.type].totalUnreadCount += 1;
          }

          allUpdatedNotes[taskId] = {
            ...serializedTask,
            section: allNotes[serializedTask?.taskId]?.section,
            isUnread: serializedTask?.isUnread && shouldShowUnreadTreatment,
          };
        }
      });
      const updatedBoardNotes = produce(boardNotes, (draft) => {
        const indexFromPreviousSection =
          section &&
          draft[section.from]?.tasks?.findIndex(
            (id) => id === serializedTask?.taskId,
          );

        if (
          section &&
          indexFromPreviousSection !== undefined &&
          indexFromPreviousSection > -1
        )
          draft[section.from]?.tasks.splice(indexFromPreviousSection, 1);
      });
      return {
        deadlineNotes: updatedNotes,
        allNotes: allUpdatedNotes,
        idMap: {
          ...idMap,
          [noteId]: taskId,
        },
        boardNotes: updatedBoardNotes,
      };
    }),
}));

export default useNewNotebookStore;
