import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import ButtonBase from '../../atoms/ButtonBase';

export const ContentWrapper = styled.div`
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
`;

export const LeftSideWrapper = styled.div`
  max-width: 96px;
  max-height: 96px;
`;
export const RightSideWrapper = styled(Flex)`
  width: 100%;
  max-height: 96px;
  padding: 12px 16px;
  justify-content: space-between;
`;

export const TitleWrapper = styled.span<{ hasChildren: boolean }>`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ hasChildren }) => hasChildren && '4px'};
`;

export const Title = styled(Body)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  width: 100%;
`;

export const Wrapper = styled(ButtonBase)<{
  $margin?: string;
}>`
  display: flex;
  width: 100%;
  max-height: 98px;
  max-width: 424px;
  height: 100%;
  text-align: left;
  align-items: center;
  border-radius: 4px;
  border: 1px solid ${ThemeV2.palette.gray4};
  width: 100%;
  background: ${ThemeV2.palette.gray1};
  transition: 0.2s ease;
  transition-property: border color background;
  cursor: pointer;
  &:hover,
  &:focus-visible,
  &.is-checked {
    border-color: ${ThemeV2.palette.geekBlue6};
    background: ${ThemeV2.palette.geekBlue1};
    & * {
      color: ${ThemeV2.palette.geekBlue6};
    }
    ${Title} {
      color: ${ThemeV2.palette.geekBlue6};
    }
    svg path {
      fill: ${ThemeV2.palette.geekBlue6};
    }
  }
  margin: ${({ $margin }) => $margin};
`;

export const ImageHolder = styled(Flex)<{
  backgroundColor?: string;
}>`
  background: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : `${ThemeV2.palette.geekBlue2}`};
  border-radius: 4px 0px 0px 4px;
  width: 96px;
  height: 96px;
`;
