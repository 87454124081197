import mixpanel from 'mixpanel-browser';
import { isSourceMSTeams } from '../msteams';
import {
  AnalyticsEvents,
  ANALYTICS_EVENTS,
  FEED_EVENTS,
  RIGHT_DRAWER_EVENT_NAME,
} from './constants';
import { GET_PLATFORM, MIXPANEL_KEY } from '../../config';

import {
  checkIfProduction,
  getUserAnniversary,
  getUserBirthday,
} from '../admin';

import {
  EventPropsType,
  FeedAnalyticsEventProps,
  FeedEventNameType,
  PostAnalyticsEventProps,
  PostEventNameType,
  RightDrawerEventNameType,
  RightDrawerEventProps,
  SuperProps,
  UserProps,
} from './interfaces';

import { CurrentUser } from '../../interfaces/currentUser';
import { isUserAdmin, isUserManager } from '../user';
import { getFullName } from '../text';

const getUserID = () => {
  const user = localStorage.getItem('user');
  if (user) {
    const me = JSON.parse(user);
    return me._id;
  }
  return null;
};

const initAnalytics = () => {
  if (!checkIfProduction()) {
    return;
  }

  mixpanel.init(MIXPANEL_KEY);
};

const signoutEvent = () => {
  if (!checkIfProduction()) {
    return;
  }

  mixpanel.reset();
};

const trackPageView = (pageName: string) => {
  if (!checkIfProduction()) {
    return;
  }

  let pageURL = '';
  let referrer = '';
  if (window) {
    pageURL = window.location.href;
    referrer = document.referrer;
  }
  const eventProps = {
    'Page Name': pageName,
    Platform: GET_PLATFORM(isSourceMSTeams()),
    'Page URL': pageURL,
    'Referrer URL': referrer,
    referrerSource: sessionStorage.getItem('referrer'),
  };
  mixpanel.track(ANALYTICS_EVENTS.PAGE_VIEW, eventProps);
};

const registerSuperProps = (superProps: Partial<SuperProps>) => {
  if (!checkIfProduction()) {
    return;
  }

  mixpanel.register(superProps);
};

const registerUserProps = (userProps: Partial<UserProps>) => {
  if (!checkIfProduction()) {
    return;
  }

  const userID = getUserID() || userProps?.$distinct_id || userProps?.memberId;
  if (userID) {
    mixpanel.identify(userID);
    mixpanel.people.set(userProps);
  }
};

const registerPropsFromUser = (user: CurrentUser) => {
  const isAdmin = isUserAdmin(user);
  const isManager = isUserManager(user);
  const Department = user.profile.department || '';
  const allowanceType = user.allowance.type;
  const allowanceValue = user.allowance.points;
  const userStatus = user.status;
  const totalPointsGiven = user.totalPointsGiven;
  const birthday = getUserBirthday(user);
  const hiredDay = getUserAnniversary(user);
  const userProps: Partial<UserProps> = {
    Platform: GET_PLATFORM(isSourceMSTeams()),
    isAdmin,
    isManager,
    Department,
    'Allowance Type': allowanceType,
    'Total Allowance': allowanceValue,
    'User Status': userStatus,
    'Total Points Given': totalPointsGiven,
    Birthday: birthday,
    managesPeople: isManager,
    Anniversary: hiredDay,
    role: user.role,
    memberId: user.memberId,
  };
  const superProps: Partial<SuperProps> = {
    Platform: GET_PLATFORM(isSourceMSTeams()),
    isAdmin,
    isManager,
    Department,
    'Allowance Type': allowanceType,
    'Total Allowance': allowanceValue,
    'User Status': userStatus,
    'Total Points Given': totalPointsGiven,
    Email: user.email,
    Name: getFullName(user.profile),
  };

  registerUserProps(userProps);
  registerSuperProps(superProps);
};

const trackEvent = (
  eventName: string,
  userProps?: Partial<UserProps>,
  eventProps?: EventPropsType,
  superProps?: Partial<SuperProps>,
) => {
  if (!checkIfProduction()) {
    return;
  }

  if (superProps) {
    registerSuperProps(superProps);
  }
  if (userProps) {
    registerUserProps(userProps);
  }
  mixpanel.track(eventName, { ...eventProps });
};

const trackFeedEvent = (
  action: FeedEventNameType,
  eventProps: FeedAnalyticsEventProps,
) => {
  trackEvent(FEED_EVENTS, undefined, {
    ...eventProps,
    action: action,
    event: AnalyticsEvents.ACTION,
  });
};

const trackPostEvent = (
  eventName: PostEventNameType,
  eventProps: PostAnalyticsEventProps,
) => {
  trackEvent(eventName, undefined, eventProps);
};

const trackRightDrawerEvent = (
  action: RightDrawerEventNameType,
  eventProps: RightDrawerEventProps,
) => {
  trackEvent(RIGHT_DRAWER_EVENT_NAME, undefined, {
    ...eventProps,
    action: action,
    event: AnalyticsEvents.ACTION,
  });
};

export {
  initAnalytics,
  trackPageView,
  trackEvent,
  trackFeedEvent,
  trackPostEvent,
  trackRightDrawerEvent,
  signoutEvent,
  registerPropsFromUser,
  registerSuperProps,
  registerUserProps,
};
