import styled from 'styled-components';
import ThemeV2 from '../../../theme';
import ClickableSelectionItem from '../../../atomic/molecules/ClickableSelectionItem';
import { Flex } from '../../../Utils/styles/display';
import { zIndexPopup } from '../../../Utils/styles/z-index';

export const Wrapper = styled(Flex)`
  position: fixed;
  top: 0;
  height: 100%;
  background-color: ${ThemeV2.palette.gray1};
  overflow-y: auto;
`;

export const OnboardingHeader = styled.div`
  top: 0;
  left: 0;
  height: 72px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background-color: ${ThemeV2.palette.gray1};
  padding: 16px;
  border-bottom: 1px solid ${ThemeV2.palette.gray4};
  z-index: ${zIndexPopup};
`;

export const MainContainer = styled(Flex)`
  margin-top: 32px;
  padding-bottom: 16px;
`;

export const SelectionWrapper = styled.div`
  max-width: 100%;
  margin: auto;
  width: 646px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 16px;
  }
`;

export const StyledClickableSelectionItem = styled(ClickableSelectionItem)`
  max-width: 100%;
`;
