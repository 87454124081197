import styled, { css } from 'styled-components';

import ThemeV2 from '../../../theme';

const borderStyle = css`
  border: 1px solid ${ThemeV2.palette.geekBlue6};
`;

const multipleChoiceStyle = css`
  box-sizing: border-box;
  margin-left: 8px;
  border-radius: 4px;
  padding: 0 12px;
  width: 100%;
  height: 32px;
  font-size: 16px;
  font-family: ${ThemeV2.typography.adminFontFamily};

  &:focus,
  &:focus-visible {
    outline: none;
  }

  ::placeholder {
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    color: ${ThemeV2.palette.gray6};
  }
`;

export const FlowsOtherMultipleChoiceInput = styled.input<{
  isBorderVisible: boolean;
}>`
  ${multipleChoiceStyle};
  ${({ isBorderVisible }) => (isBorderVisible ? borderStyle : 'border: none')};
`;

export const FlowsMultiChoiceOthersChoiceInput = styled.input<{
  isBorderVisible: boolean;
}>`
  margin-bottom: 4px;
  ${multipleChoiceStyle};
  ${({ isBorderVisible }) => (isBorderVisible ? borderStyle : 'border: none')};
`;
