import { getFlowNameWithEmoticon } from '../../../Utils/flows';
import { getFlowFrequencyText } from '../../../Utils/flows/builder/utils';
import { getColorById } from '../../../Utils/flows/colors';
import { mapHexCodeToEmoticon } from '../../../Utils/mappers';
import { isMobilePlatform } from '../../../Utils/window';
import Body from '../../../atomic/atoms/Body';
import EmoticonWithText from '../../../atomic/atoms/EmoticonWithText';
import FlowEndOccurrenceModal from '../../../atomic/molecules/FlowEndOccurrenceModal';
import FlowsFeedHeader from '../../../atomic/molecules/FlowsFeedHeader';
import Error from '../../../atomic/molecules/PageHeader/Error';
import Loader from '../../../atomic/molecules/PageHeader/Loader';
import FlowRunNowModal from '../../../atomic/organism/FlowRunNowModal';
import BasicModalTemplate from '../../../atomic/templates/BasicModalTemplate';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../../hooks/useSplitSdkConfig/constants';
import {
  FLOWS_ARCHIVE_MODAL,
  FLOWS_END_OCCURRENCE_MODAL,
  FLOW_NOT_FOUND,
  FLOW_NOT_FOUND_DESCRIPTION,
  FLOW_REMINDER_PARTICIPANT_MODAL,
  NO_TRIGGER,
  ON_DEMAND,
} from '../../../languages/en/flows';
import { YOU } from '../../../languages/en/home';
import { ARCHIVE, RUNS, WHEN } from '../../../languages/en/singleWords';
import { useMainNavQuery } from '../../../queries/Flows/Dashboard';
import {
  FlowAccessRoles,
  FlowItemResponse,
} from '../../../queries/Flows/interfaces';
import { useCurrentPlanFetchQuery } from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import RequestAccessModalController from '../RequestAccessModalController';
import useFlowFeedHeaderControllerLogic from './useFlowFeedHeaderControllerLogic';
import { getFlowHeaderOptionItems } from './utils';

const getFlowTypeFromFlowDetails = (flowDetails: FlowItemResponse) => {
  if (flowDetails.kind === 'SCHEDULED') {
    const scheduleRule = flowDetails.schedule?.rule;
    const flowFrequencyText = getFlowFrequencyText(scheduleRule);
    return `${RUNS} ${
      flowFrequencyText.charAt(0).toLowerCase() + flowFrequencyText.slice(1)
    }`;
  } else if (flowDetails.kind === 'NO_TRIGGER') {
    return NO_TRIGGER;
  }
  return ON_DEMAND;
};

const FlowFeedHeaderController = () => {
  const { models, operations } = useFlowFeedHeaderControllerLogic();

  const {
    isLoading,
    isError,
    data,
    feedData,
    participantsCount,
    isTriggerFlowModalOpen,
    dueDateValue,
    occurrenceValue,
    participants,
    flowVisibilityCriteria,
    isTriggerFlowLoading,
    isEndOccurrenceModalOpen,
    isArchiveModalOpen,
    isArchiveFlowLoading,
    isEndOccurrenceModalLoading,
    hasMoreMembers,
    isUsersError,
    isUsersLoading,
    hasNextPage,
    memberSearchValue,
    users,
    isRemindParticipantsModalOpen,
    isRemindParticipantsLoading,
    hasActiveOccurrence,
    pendingMembersParticipantCount,
    isMobileView,
    isRequestAccessModalOpen,
    requestedUsersCount,
    isShareRequestsLoading,
    hasOnlyGifBlocks,
    canUserCreateFlow,
  } = models;

  const {
    handleMenuOptionOnClick,
    setTriggerFlowModalClose,
    handleDueDateValueChange,
    setOccurrenceValue,
    onRunNowButtonClick,
    setEndOccurrenceModalClose,
    onEndOccurrenceClick,
    handleOnArchiveClick,
    setArchiveModalClose,
    fetchMoreMembers,
    fetchMoreUsers,
    handleMemberSearchChange,
    setRemindParticipantsModalClose,
    onRemindParticipantsClick,
    onMemberClick,
    openRunNowModal,
    openRemindParticipantsModal,
    trackFlowFeedMenuOpened,
    trackViewersHovered,
    handleShareSheetModalOpen,
    handleCopyFlowFeedLink,
    handleRequestAccessModalOpen,
    handleFlowSummariesActionClick,
  } = operations;

  const { data: mainNavData } = useMainNavQuery();
  const { isEmbeddedInMainApp } = useLayoutStore();

  const { treatment: flowSummariesTreatment } = useFeatureSplit(
    SplitNames.FlowSummaries,
  );

  const { treatment: multiPopoutDrawerTreatment } = useFeatureSplit(
    SplitNames.MultiPopoutDrawer,
  );

  const isFlowSummariesFeatureOn =
    flowSummariesTreatment === TreatmentTypes.ON &&
    multiPopoutDrawerTreatment === TreatmentTypes.ON;

  const { data: planFeatureDetails } = useCurrentPlanFetchQuery(
    isFlowSummariesFeatureOn && isEmbeddedInMainApp,
  );

  const isUpgradedWorkspace = !!planFeatureDetails?.features.some(
    (feature) => feature.id === 'ASSEMBLY_AI' && feature.status === 'ENABLED',
  );

  if (isLoading) {
    return (
      <Loader
        isEmbeddedInMainApp={isEmbeddedInMainApp}
        hasBottomBorder={!(isEmbeddedInMainApp && data?.isFlowOwner)}
      />
    );
  }

  if (isError) {
    return (
      <Error title={FLOW_NOT_FOUND} subTitle={FLOW_NOT_FOUND_DESCRIPTION} />
    );
  }

  if (data) {
    const {
      icon,
      name,
      description,
      flowId,
      isFlowOwner,
      isFlowParticipant,
      isFlowViewer,
      visibility,
      kind,
      schedule,
      isShortcut,
      hasAnyOccurrences,
    } = data;
    const isFlowCollaborator = Boolean(
      data?.flowAccessRoles?.includes(FlowAccessRoles.Collaborator),
    );
    const hasPosts = Boolean(feedData?.pages?.[0]?.data?.length);
    const hasMultiplePosts = Boolean(
      feedData?.pages?.[0]?.data?.length ?? 0 > 1,
    );

    const isFlowSummariesEnabled =
      isFlowSummariesFeatureOn &&
      isUpgradedWorkspace &&
      hasPosts &&
      isEmbeddedInMainApp;

    const headerOptionItems = getFlowHeaderOptionItems({
      isFlowOwner,
      isFlowParticipant,
      isFlowViewer,
      hasActiveOccurrence,
      hasMultiplePosts,
      isMobilePlatform,
      isMobileView,
      currentFolder: mainNavData?.flattenedData.find(
        (f) => f.folderInfo?.folderId === flowId,
      )?.folderInfo,
      folders: mainNavData?.folders || [],
      isFlowCollaborator,
      isFlowSummariesEnabled,
      canUserCreateFlow,
    });

    const flowFeedHeaderOptions = [
      {
        id: 'flow-feed-header-options',
        items: headerOptionItems,
      },
    ];

    const userDetails = {
      visibility,
      users,
      hasMoreUsersToFetch: !!hasNextPage,
      memberSearchValue,
      onMemberSearchValueChange: handleMemberSearchChange,
      isUsersError,
      isUsersLoading,
      fetchMoreMembers: fetchMoreUsers,
    };

    const emoticon = mapHexCodeToEmoticon(icon.value);
    const emoticonBg = getColorById(flowId);

    const ArchiveModal = (
      <BasicModalTemplate
        isOpen={isArchiveModalOpen}
        heading={FLOWS_ARCHIVE_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.ARCHIVE_LABEL}`,
          onClick: handleOnArchiveClick,
          status: 'warning',
          isLoading: isArchiveFlowLoading,
        }}
        secondaryButton={{
          text: `${FLOWS_ARCHIVE_MODAL.CANCEL_LABEL}`,
          onClick: setArchiveModalClose,
        }}
        onModalClose={setArchiveModalClose}
      >
        <Body variant="body2" inline>
          {`${WHEN} ${YOU.toLocaleLowerCase()} ${ARCHIVE.toLocaleLowerCase()} `}
        </Body>
        <Body variant="body2Medium" inline>
          <EmoticonWithText emoticon={emoticon} text={name} hasBoldText />
        </Body>
        <Body variant="body2" inline>
          {', '}
          {FLOWS_ARCHIVE_MODAL.MODAL_CONTENT_TEXT}
        </Body>
      </BasicModalTemplate>
    );

    const RemindParticipantsModal = (
      <BasicModalTemplate
        isOpen={isRemindParticipantsModalOpen}
        heading={FLOW_REMINDER_PARTICIPANT_MODAL.MODAL_HEADING}
        primaryButton={{
          text: `${FLOW_REMINDER_PARTICIPANT_MODAL.SEND_REMINDER_LABEL}`,
          onClick: onRemindParticipantsClick,
          isLoading: isRemindParticipantsLoading,
        }}
        secondaryButton={{
          text: `${FLOW_REMINDER_PARTICIPANT_MODAL.CANCEL_LABEL}`,
          onClick: setRemindParticipantsModalClose,
        }}
        onModalClose={setRemindParticipantsModalClose}
      >
        <Body variant="body2" inline>
          {FLOW_REMINDER_PARTICIPANT_MODAL.SEND_A_REMINDER}
        </Body>
        {hasAnyOccurrences && (
          <Body variant="body2Medium" inline>
            {' '}
            {FLOW_REMINDER_PARTICIPANT_MODAL.get_not_responded_participants_count(
              data.participantsNotYetResCount ?? 0,
            )}{' '}
          </Body>
        )}
        <Body variant="body2" inline>
          {FLOW_REMINDER_PARTICIPANT_MODAL.THAT_HAVEN_NOT_ANSWERED}
        </Body>
        <Body variant="body2Medium" inline>
          {' '}
          <EmoticonWithText emoticon={emoticon} text={name} hasBoldText />
        </Body>
      </BasicModalTemplate>
    );

    return (
      <>
        <FlowsFeedHeader
          title={name}
          subTitle={getFlowTypeFromFlowDetails(data)}
          description={description}
          icon={mapHexCodeToEmoticon(icon.value)}
          backgroundColor={emoticonBg}
          membersCount={participantsCount}
          menuOptions={flowFeedHeaderOptions}
          onMenuOptionClick={handleMenuOptionOnClick}
          userDetails={userDetails}
          onMemberClick={onMemberClick}
          isFlowViewer={isFlowViewer}
          isFlowEditor={isFlowOwner || isFlowCollaborator}
          isFlowParticipant={isFlowParticipant}
          openRunNowModel={openRunNowModal}
          openRemindParticipantsModal={openRemindParticipantsModal}
          hasActiveOccurrence={hasActiveOccurrence}
          onClick={trackFlowFeedMenuOpened}
          onPopOverOpen={trackViewersHovered}
          schedule={schedule}
          kind={kind}
          isShortcut={isShortcut}
          openShareSheetModal={handleShareSheetModalOpen}
          handleCopyFlowFeedLink={handleCopyFlowFeedLink}
          openRequestAccessModal={handleRequestAccessModalOpen}
          requestedUsersCount={requestedUsersCount}
          isShareRequestsLoading={isShareRequestsLoading}
          hasMultiplePosts={hasMultiplePosts}
          hasPosts={hasPosts}
          hasOnlyGifBlocks={hasOnlyGifBlocks}
          isFlowSummariesFeatureOn={isFlowSummariesFeatureOn}
          isUpgradedWorkspace={isUpgradedWorkspace}
          onFlowSummariesActionClick={handleFlowSummariesActionClick}
        />
        <FlowRunNowModal
          isOpen={isTriggerFlowModalOpen}
          modalHeading={getFlowNameWithEmoticon(data)}
          onModalClose={setTriggerFlowModalClose}
          value={dueDateValue}
          onChange={handleDueDateValueChange}
          participants={participants}
          participantsCount={participantsCount}
          occurrenceValue={occurrenceValue}
          onOccurrenceChange={setOccurrenceValue}
          onRunNowButtonClick={onRunNowButtonClick}
          visibilityCriteria={flowVisibilityCriteria}
          isLoading={isTriggerFlowLoading}
          hasMoreMembers={hasMoreMembers}
          fetchNextPage={fetchMoreMembers}
          pendingMembersParticipantCount={pendingMembersParticipantCount}
        />
        <FlowEndOccurrenceModal
          heading={FLOWS_END_OCCURRENCE_MODAL.modalHeading}
          isOpen={isEndOccurrenceModalOpen}
          onCancelClick={setEndOccurrenceModalClose}
          onEndOccurrenceClick={onEndOccurrenceClick}
          onModalClose={setEndOccurrenceModalClose}
          isLoading={isEndOccurrenceModalLoading}
        />

        {ArchiveModal}
        {RemindParticipantsModal}
        <RequestAccessModalController
          flowName={name}
          handleModalClose={() => {
            handleRequestAccessModalOpen(false);
          }}
          isRequestAccessModalOpen={isRequestAccessModalOpen}
        />
      </>
    );
  }

  return null;
};

export default FlowFeedHeaderController;
