import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { Nullable } from '../../Utils/common';
import { makeAPICallWithDataReturn } from '../utils';

import {
  GET_MEMBERS_PREVIEW,
  GET_MEMBERS_CRITERIA_PREVIEW,
} from '../../constants/endpoints';

import {
  GetMembersForPreviewRequest,
  GetMembersForPreviewResponse,
  GetMembersFromCriteriaForPreviewResponse,
} from '../../interfaces/Sharesheet';

export const useGetMembersFromCriteriaForPreview = (
  payload: GetMembersForPreviewRequest,
  enabled = true,
) => {
  return useQuery<GetMembersFromCriteriaForPreviewResponse>(
    [GET_MEMBERS_CRITERIA_PREVIEW, payload],
    () => makeAPICallWithDataReturn(GET_MEMBERS_CRITERIA_PREVIEW, payload),
    {
      cacheTime: 0,
      enabled: !!payload && enabled,
    },
  );
};

export const useGetMembersPreview = (
  previewId: Nullable<string>,
  limit: number,
  enabled: boolean,
) => {
  return useInfiniteQuery<GetMembersForPreviewResponse>(
    [GET_MEMBERS_PREVIEW, previewId],
    ({ pageParam = '' }) =>
      makeAPICallWithDataReturn(
        GET_MEMBERS_PREVIEW,
        {},
        {
          limit: limit,
          cursor: pageParam,
        },
        { previewId: previewId || '' },
      ),
    {
      enabled,
      cacheTime: 0,
      getNextPageParam: (lastPage) =>
        lastPage.metadata.pagination?.cursor?.next || undefined,
      getPreviousPageParam: (lastPage) => {
        return lastPage.metadata.pagination.cursor.previous;
      },
    },
  );
};
