import React from 'react';
import {
  REWARDS_HEADER_SUBTITLE,
  REWARDS_HEADING,
} from '../../../languages/en/header';

import { HeaderType } from '../../../atomic/molecules/PageHeader/interfaces';
import PageHeader from '../../../atomic/molecules/PageHeader';
import useRewardsHeaderController from './useRewardsHeaderController';
import { V2_REWARDS } from '../../../constants/routes';
import useMultiAssemblySlug from '../../../hooks/useMultiAssemblySlug';

const RewardsHeaderController = () => {
  const { slugUrl } = useMultiAssemblySlug();
  const { filteredRewardsTabButtons } = useRewardsHeaderController();
  return (
    <PageHeader
      headerType={HeaderType.OTHER}
      icon="rewards"
      title={REWARDS_HEADING}
      subTitle={REWARDS_HEADER_SUBTITLE}
      hasBottomBorder={false}
      pageTabs={filteredRewardsTabButtons}
      tabRouteBase={`/${slugUrl}${V2_REWARDS}`}
    />
  );
};

export default RewardsHeaderController;
