import React from 'react';
import { oneOf, number, string } from 'prop-types';
import {
  StyledProgressBarWrapper,
  StyledProgressBar,
  colorMap,
} from './styles';

export type ProgressStatuses =
  | 'none'
  | 'success'
  | 'warning'
  | 'danger'
  | 'flowProgress';

export interface ProgressBarProps {
  color: ProgressStatuses;
  value: number;
  background: string;
  height: string;
  className: string;
  dataTestId: string;
  dataQaId?: string;
  transition?: string;
}

const ProgressBar = (props: ProgressBarProps) => {
  const {
    color,
    value = 0,
    background,
    height,
    className,
    dataTestId,
    dataQaId,
    transition,
  } = props;

  return (
    <StyledProgressBarWrapper
      background={background}
      height={height}
      className={className}
      data-testid={dataTestId}
      data-qa-id={dataQaId}
    >
      <StyledProgressBar color={color} value={value} transition={transition} />
    </StyledProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  color: oneOf(Object.keys(colorMap)),
  value: number,
  background: string,
  height: string,
  className: string,
  dataTestId: string,
  dataQaId: string,
};

ProgressBar.defaultProps = {
  color: 'none',
  value: 0,
  background: '',
  height: '',
  className: '',
  dataTestId: 'progress-bar',
  dataQaId: 'progress-bar',
};

export default ProgressBar;
