import styled from 'styled-components';

import Body from '../../../atoms/Body';
import ThemeV2 from '../../../../theme';

export const AvatarContainer = styled.div`
  margin-left: 8px;
`;

export const CreateTaskButton = styled.button`
  background-color: inherit;
  border: 0;
  color: ${ThemeV2.palette.geekBlue6};
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-decoration: underline;
`;

export const IconButtonWrapper = styled.div`
  margin-left: 4px;
`;

export const NoResultsContainer = styled.div`
  margin: 4px 12px 12px 16px;
`;

export const SearchBoxContainer = styled.div`
  background-color: ${ThemeV2.palette.white};
  display: flex;
  flex-direction: row;
  padding: 8px;
  position: sticky;
  top: 0px;
  z-index: 1000;
`;

export const SecondaryInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

export const TaskContentContainer = styled.div`
  display: block;
  margin-left: 5px;
  overflow: hidden;
  width: 100%;
`;

export const TryAgainButton = styled.button`
  background-color: inherit;
  border: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-decoration: underline;
`;

export const TaskOptionContainer = styled.li`
  color: ${ThemeV2.palette.gray8};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 4px;
  padding: 4px 8px;

  &:hover,
  &:active,
  &:focus {
    background: ${ThemeV2.palette.geekBlue1};
    border-radius: 4px;
    outline: none;
  }
`;

export const TaskSecondaryInfo = styled(Body)<{
  $margin: number;
  truncate?: boolean;
}>`
  margin-left: ${({ $margin }) => `${$margin}px`};
  ${({ truncate }) => truncate && 'overflow: hidden;'}
  ${({ truncate }) => truncate && 'text-overflow: ellipsis;'}
  ${({ truncate }) => truncate && 'white-space: nowrap;'}
`;

export const TaskTitle = styled(Body)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
