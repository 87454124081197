import React, { useState, useEffect } from 'react';

import FilterController from './FilterController';
import { Wrapper } from '../../../atomic/organism/RightDrawerFilter/styles';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';

const MainFeedRightDrawerController = () => {
  const [, setIsFiltered] = useState(false);
  const { isMainFeedFilterApplied } = useGetParsedFilterOptions();

  useEffect(() => {
    if (isMainFeedFilterApplied) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Wrapper>
        <FilterController />
      </Wrapper>
    </>
  );
};

export default MainFeedRightDrawerController;
