import { CurrentUser } from '../../interfaces/currentUser';
import { AssemblySettings } from '../../interfaces/assembly';
import { isUserAdmin, isUserManager } from '../user';
import { GetProfileInfoResponse } from '../../queries/Profile';

export const canInviteMembers = (
  member: CurrentUser | undefined,
  settings: AssemblySettings | undefined,
  isUserManagementChangesOn: boolean | undefined,
) => {
  if (isUserManagementChangesOn) {
    return true;
  }
  if (member) {
    if (isUserAdmin(member)) {
      return true;
    } else if (
      isUserManager(member) &&
      settings &&
      settings.userManagement &&
      settings.userManagement.inviteAccess.value.includes('MANAGER')
    ) {
      return true;
    } else if (
      settings &&
      settings.userManagement &&
      settings.userManagement.inviteAccess.value.includes('EVERYONE')
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const getCanShowParticipationCriteriaEveryone = (
  profileData: GetProfileInfoResponse | undefined,
) => {
  if (profileData && profileData.assembly.flow) {
    const participationCriteriaAccessibility =
      profileData.assembly.flow.participationCriteriaAccessibility;

    if (isUserAdmin(profileData.member)) {
      return true;
    }

    if (participationCriteriaAccessibility.everyone.includes('EVERYONE')) {
      return true;
    }

    if (
      participationCriteriaAccessibility.everyone.includes('MANAGER') &&
      isUserManager(profileData.member)
    ) {
      return true;
    }
  }

  return false;
};

export const getCanShowGivePointsStack = (
  profileData: GetProfileInfoResponse | undefined,
) => {
  if (profileData && profileData.assembly.flow) {
    const contentStackAccessibility =
      profileData.assembly.flow.contentStackAccessibility;

    if (isUserAdmin(profileData.member)) {
      return true;
    }

    if (contentStackAccessibility.GIVE_POINTS_STACK.includes('EVERYONE')) {
      return true;
    }

    if (
      contentStackAccessibility.GIVE_POINTS_STACK.includes('MANAGER') &&
      isUserManager(profileData.member)
    ) {
      return true;
    }
  }

  return false;
};

export const getIsInviteRequiresApproval = (
  profileData: GetProfileInfoResponse | undefined,
  isUserManagementChangesOn?: boolean,
) => {
  if (isUserManagementChangesOn && profileData) {
    const {
      member,
      assembly: { settings },
    } = profileData;
    if (member) {
      if (isUserAdmin(member)) {
        return false;
      } else if (
        isUserManager(member) &&
        settings &&
        settings.userManagement &&
        settings.userManagement.inviteAccess.value.includes('MANAGER')
      ) {
        return false;
      } else if (
        settings &&
        settings.userManagement &&
        settings.userManagement.inviteAccess.value.includes('EVERYONE')
      ) {
        return false;
      }
      return true;
    }
  }
  if (profileData && profileData.assembly.settings) {
    const approvalAccess =
      profileData.assembly.settings.userManagement.approvalAccess;
    if (approvalAccess.enabled && !isUserAdmin(profileData.member)) {
      return true;
    }
  }
  return false;
};
