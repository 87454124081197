import { useRef } from 'react';

type Stack<T> = {
  push: (item: T) => void;
  pop: () => T | undefined;
  peek: () => T | undefined;
};

const useStack = <T>(): Stack<T> => {
  const itemsRef = useRef<T[]>([]);

  const push = (item: T) => {
    itemsRef.current.push(item);
  };

  const pop = () => {
    return itemsRef.current.pop();
  };

  const peek = () => {
    return itemsRef.current[itemsRef.current.length - 1];
  };

  return { push, pop, peek };
};

export default useStack;
