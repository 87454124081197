import hoursToMinutes from 'date-fns/hoursToMinutes';
import { SelectedCustomRecurrenceTypes } from '../../../../atomic/organism/CustomRecurrence/types';
import { TimeUnitTypes } from '../../../../atomic/organism/TriggerConfigureSettings/types';
// eslint-disable-next-line max-len
import { FlowFrequencyOptions } from '../../../../controllers/flowsBuilder/FlowsBuilderTriggerController/ScheduleDateTimeController/types';
import { FLOW_TRIGGER_MODAL_VALIDATION_ERROR } from '../../../../languages/en/flows/feed';

const MINUTES_MIN_LIMIT = 5;
const DAY_MINUTES_LIMIT = 1440;
const DAY_HOURS_LIMIT = 24;
const WEEK_MINUTES_LIMIT = 10080;
const WEEK_HOURS_LIMIT = 168;
const WEEK_DAYS_LIMIT = 7;
const BIWEEKLY_MINUTES_LIMIT = 20160;
const BIWEEKLY_HOURS_LIMIT = 336;
const BIWEEKLY_DAYS_LIMIT = 14;
const MINUTES_MAX_LIMIT = 44640;
const HOURS_MAX_LIMIT = 744;
const DAYS_MAX_LIMIT = 28;
const WEEK_MAX_LIMIT = 4;

// to check the flow reminder extends error
export const checkFlowDeadlineSelection = (
  deadlineConfiguration: number,
  deadlineConfigurationTIme: TimeUnitTypes,
  flowFrequencyValue?: string,
) => {
  switch (flowFrequencyValue) {
    case FlowFrequencyOptions.DAILY:
    case FlowFrequencyOptions.EVERY_WEEKDAY: {
      if (deadlineConfigurationTIme === 'minutes') {
        return deadlineConfiguration > DAY_MINUTES_LIMIT;
      }
      if (deadlineConfigurationTIme === 'hours') {
        return deadlineConfiguration > DAY_HOURS_LIMIT;
      }
      if (deadlineConfigurationTIme === 'weeks') {
        return true;
      }
      return deadlineConfiguration > 1;
    }
    case FlowFrequencyOptions.WEEKLY: {
      if (deadlineConfigurationTIme === 'minutes') {
        return deadlineConfiguration > WEEK_MINUTES_LIMIT;
      }
      if (deadlineConfigurationTIme === 'hours') {
        return deadlineConfiguration > WEEK_HOURS_LIMIT;
      }
      if (deadlineConfigurationTIme === 'days') {
        return deadlineConfiguration > WEEK_DAYS_LIMIT;
      }
      return deadlineConfiguration > 1;
    }
    case FlowFrequencyOptions.BIWEEKLY: {
      if (deadlineConfigurationTIme === 'minutes') {
        return deadlineConfiguration > BIWEEKLY_MINUTES_LIMIT;
      }
      if (deadlineConfigurationTIme === 'hours') {
        return deadlineConfiguration > BIWEEKLY_HOURS_LIMIT;
      }
      if (deadlineConfigurationTIme === 'days') {
        return deadlineConfiguration > BIWEEKLY_DAYS_LIMIT;
      }
      return deadlineConfiguration > 2;
    }
    case FlowFrequencyOptions.ONCE:
    default: {
      if (deadlineConfigurationTIme === 'minutes') {
        return deadlineConfiguration > MINUTES_MAX_LIMIT;
      }
      if (deadlineConfigurationTIme === 'hours') {
        return deadlineConfiguration > HOURS_MAX_LIMIT;
      }
      if (deadlineConfigurationTIme === 'days') {
        return deadlineConfiguration > DAYS_MAX_LIMIT;
      }
      return deadlineConfiguration > WEEK_MAX_LIMIT;
    }
  }
};

// to check the recurrence error
export const checkRecurrenceError = (
  deadlineConfiguration: number,
  deadlineConfigurationTIme: TimeUnitTypes,
) => {
  if (deadlineConfiguration <= 0) {
    return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.MINUTES.LOWER;
  }
  switch (deadlineConfigurationTIme) {
    case 'minutes': {
      if (deadlineConfiguration < MINUTES_MIN_LIMIT) {
        return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.MINUTES.LOWER;
      }
      if (deadlineConfiguration > MINUTES_MAX_LIMIT) {
        return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.MINUTES.HIGHER;
      }
      return null;
    }
    case 'hours': {
      if (deadlineConfiguration > HOURS_MAX_LIMIT) {
        return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.HOURS;
      }
      return null;
    }
    case 'days': {
      if (deadlineConfiguration > DAYS_MAX_LIMIT) {
        return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.TWENTY_EIGHT_DAYS;
      }
      return null;
    }
    default:
      if (deadlineConfiguration > WEEK_MAX_LIMIT) {
        return FLOW_TRIGGER_MODAL_VALIDATION_ERROR.WEEKS;
      }
      return null;
  }
};

const getMinutesLimit = (
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined,
) => {
  if (selectedCustomRecurrenceTypes) {
    switch (selectedCustomRecurrenceTypes.repeatEvery) {
      case 'days': {
        if (selectedCustomRecurrenceTypes.repeatEveryCount > 31) {
          return MINUTES_MAX_LIMIT;
        }
        const hours = selectedCustomRecurrenceTypes.repeatEveryCount * 24;
        return hoursToMinutes(hours);
      }
      case 'weeks': {
        if (selectedCustomRecurrenceTypes.repeatEveryCount > 4) {
          return MINUTES_MAX_LIMIT;
        }
        if (selectedCustomRecurrenceTypes.selectedDayIds.length > 1) {
          return DAY_MINUTES_LIMIT;
        }
        const hours = selectedCustomRecurrenceTypes.repeatEveryCount * 168;
        return hoursToMinutes(hours);
      }
      case 'months':
      default: {
        return hoursToMinutes(HOURS_MAX_LIMIT);
      }
    }
  }
  return DAY_MINUTES_LIMIT;
};

/* custom occurrence */
export const customOccurrenceErrorCheck = (
  endTimeInMinutes: number,
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined,
) => {
  const limit = getMinutesLimit(selectedCustomRecurrenceTypes);
  if (endTimeInMinutes > limit) {
    return true;
  }
  return false;
};

/* custom occurrence - limits */
export const customOccurrenceEndTimeLimit = (
  endTimeInMinutes: number,
  selectedCustomRecurrenceTypes: SelectedCustomRecurrenceTypes | undefined,
) => {
  const limit = getMinutesLimit(selectedCustomRecurrenceTypes);
  if (limit === endTimeInMinutes) {
    return endTimeInMinutes - 5;
  }
  return null;
};
