import ContentLoader from 'react-content-loader';
import React, { useCallback, useMemo } from 'react';

import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import SVGIcon from '../../atoms/SVGIcon';
import IconButton from '../../atoms/IconButton_V2';

import ThemeV2 from '../../../theme';
import DropdownIconButton from '../DropdownIconButton';

import { ProfileChatHeaderProps, ProfileDropDownMenuItem } from './type';

import {
  ViewProfileLink,
  ProfileInfoContainer,
  ProfileChatHeaderContainer,
  StyledBackButton,
} from './styles';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { MemberState } from '../../../interfaces/user';
import useLayoutStore from '../../../stores/layoutStore';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { useFeatureSplit } from '../../../hooks/useFeatureSplit';
import { SplitNames } from '../../../hooks/useSplitSdkConfig/constants';
import mobileStore from '../../../stores/mobileAppStore';

const showChatsSelector = (state: LayoutStoreState) => state.showChats;

const ProfileChatHeader = (props: ProfileChatHeaderProps) => {
  const {
    profile,
    isLoading,
    onCloseClick,
    onViewProfileClick,
    onCopyProfileLinkClick,
    isMobileView,
    hideCloseButton = false,
  } = props;

  const { treatment: hideV2DirectMessaging } = useFeatureSplit(
    SplitNames.HideV2DirectMessaging,
  );

  const showChats = useLayoutStore(showChatsSelector);

  const chatDropdownOptions = useMemo(() => {
    return [
      {
        id: 'profile-header-options',
        items: [
          ...(hideV2DirectMessaging !== 'on'
            ? [
                {
                  isSelected: false,
                  prefixIcon: 'person-filled',
                  value: 'View profile',
                  id: ProfileDropDownMenuItem.ViewProfile,
                },
              ]
            : []),
          {
            isSelected: false,
            prefixIcon: 'Link',
            value: 'Copy profile link',
            id: ProfileDropDownMenuItem.CopyProfileLink,
          },
        ],
      },
    ];
  }, [hideV2DirectMessaging]);

  const directMessageText = `Direct Message ${profile?.firstName}`;

  const profileDropdownOptions = useMemo(() => {
    return [
      {
        id: 'profile-header-options',
        items: [
          ...(hideV2DirectMessaging !== 'on' &&
          !mobileStore.getState().isMobileAppV3
            ? [
                {
                  isSelected: false,
                  prefixIcon: 'person-filled',
                  value: directMessageText,
                  id: ProfileDropDownMenuItem.ViewProfile,
                },
              ]
            : []),
          {
            isSelected: false,
            prefixIcon: 'Link',
            value: 'Copy profile link',
            id: ProfileDropDownMenuItem.CopyProfileLink,
          },
        ],
      },
    ];
  }, [directMessageText, hideV2DirectMessaging]);

  const handleOnOptionsSelect = useCallback(
    (value: string | number) => {
      switch (value) {
        case ProfileDropDownMenuItem.ViewProfile:
          onViewProfileClick();
          break;
        case ProfileDropDownMenuItem.CopyProfileLink:
          onCopyProfileLinkClick();
          break;
        default:
          break;
      }
    },
    [onCopyProfileLinkClick, onViewProfileClick],
  );

  if (isLoading) {
    return (
      <ProfileChatHeaderContainer>
        <ContentLoader
          speed={2}
          width="100%"
          height={79}
          data-testid="flow-feed-post-loader"
          backgroundColor={ThemeV2.palette.gray4}
          foregroundColor={ThemeV2.palette.gray3}
        >
          <circle cx="40" cy="36" r="21" />
          <rect x="72" y="18" rx="4" ry="4" width="125" height="12" />
          <rect x="72" y="40" rx="4" ry="4" width="95" height="12" />
        </ContentLoader>
      </ProfileChatHeaderContainer>
    );
  }

  if (profile) {
    return (
      <ProfileChatHeaderContainer
        alignItems="center"
        padding="16px 16px 16px 16px"
      >
        {isMobileView && (
          <StyledBackButton onClick={onCloseClick}>
            <SVGIcon
              icon="carat-left"
              size="24px"
              color={ThemeV2.palette.gray8}
            />
          </StyledBackButton>
        )}
        <Avatar
          size="40px"
          fontSize="16px"
          img={profile.image}
          userId={profile.id}
          name={`${profile?.firstName} ${profile?.lastName}`}
          icon={profile.state === MemberState.PENDING ? 'pending-person' : ''}
        />

        <ProfileInfoContainer flexDirection="column">
          <Body
            color="gray9"
            variant={isMobileView ? 'body3Medium' : 'body1Medium'}
          >
            {`${profile?.firstName} ${profile?.lastName}`}
          </Body>
          {showChats && hideV2DirectMessaging !== 'on' ? (
            <ViewProfileLink onClickCallback={onViewProfileClick}>
              View profile
            </ViewProfileLink>
          ) : (
            hideV2DirectMessaging !== 'on' &&
            !mobileStore.getState().isMobileAppV3 && (
              <ViewProfileLink onClickCallback={onViewProfileClick}>
                {directMessageText}
              </ViewProfileLink>
            )
          )}
        </ProfileInfoContainer>

        <DropdownIconButton
          icon="more"
          title="profile options"
          menuItems={showChats ? chatDropdownOptions : profileDropdownOptions}
          onItemClick={handleOnOptionsSelect}
          dataTestId="profile-options-button"
          dataQaId="btn-profile-options"
          size={isMobileView ? ButtonSize.Medium : ButtonSize.Normal}
        />

        {!hideCloseButton && !isMobileView && (
          <IconButton onClick={onCloseClick}>
            <SVGIcon
              icon="round-close"
              size="16px"
              color={ThemeV2.palette.gray8}
            />
          </IconButton>
        )}
      </ProfileChatHeaderContainer>
    );
  }

  return null;
};

export default ProfileChatHeader;
