import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  KnowledgeCenterListItemWrapper,
  KnowledgeCenterListItemBody,
  KnowledgeCenterListItemImage,
  SVGKnowledgeCenterListItemIcon,
  KnowledgeCenterListItemAvatarWrapper,
  KnowledgeCenterListItemNameWrapper,
  KnowledgeCenterListItemUserWrapper,
  StyledSVGLinkIcon,
} from './styles';
import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import {
  menuItemsWithoutDeletePost,
  menuItemsWithoutDownloadFileItem,
} from './data';
import {
  AttachmentType,
  FeedAttachmentItemFromAPI,
  FeedItemKind,
} from '../../../interfaces/Feed';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import ShortcutActionListHolder from '../ShortcutActionList';
import useDropDown from '../../../hooks/useDropDown';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import ThemeV2 from '../../../theme';
import { assignFileTypeInfo } from '../../../Utils/file';
import { ANONYMOUS } from '../../../languages/en/singleWords';
import { openExternalUrl } from '../../../Utils/routing';

const KnowledgeCenterListItem = (props: {
  listItem: FeedAttachmentItemFromAPI;
  index: number;
  style: React.CSSProperties;
  goToPost: (flowId: string, responseId: string) => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI) => void;
  openPreviewer: Dispatch<SetStateAction<boolean>>;
  onClickDownload: (listItemFile: FeedAttachmentItemFromAPI) => Promise<void>;
  copyPostLink: (flowId: string, responseId: string) => void;
}) => {
  const {
    listItem,
    index,
    style,
    goToPost,
    handleSetSelectedFile,
    openPreviewer,
    onClickDownload,
    copyPostLink,
  } = props;
  const [listItemIcon, setListItemIcon] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link' && copyPostLink) {
      copyPostLink(listItem.response?.flowId, listItem.response?.responseId);
    }
    if (value === 'go-to-post') {
      goToPost(listItem.response.flowId, listItem.response.responseId);
    }
    if (value === 'download-file' && onClickDownload) {
      onClickDownload(listItem);
    }
    if (value === 'delete') {
      //  TODO: Not implemented on the BE yet.
    }
  };

  const isLink = Boolean(listItem?.type === AttachmentType.LinkAttachment);

  const { anchorProps } = useDropDown({
    menuItems: isLink
      ? menuItemsWithoutDownloadFileItem
      : menuItemsWithoutDeletePost,
    onItemClick: onOptionsSelect,
    isFullWidth: false,
  });

  useEffect(() => {
    if (listItem.fileInfo) {
      assignFileTypeInfo(listItem.fileInfo.type, undefined, setListItemIcon);
    } else {
      assignFileTypeInfo('url', undefined, setListItemIcon);
    }
  }, [listItem.fileInfo]);

  const { referenceElement, openDropdown, closeDropdown, anchorEl } =
    anchorProps;

  const listItemDate = format(
    parseISO(listItem.fileInfo?.createdAt || listItem.urlInfo?.createdAt || ''),
    'MMMM do, yyyy',
  );

  return (
    <KnowledgeCenterListItemWrapper
      isLink={isLink}
      onMouseEnter={() => {
        setIsHovered(true);
        openDropdown();
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        closeDropdown();
      }}
      onClick={() => {
        if (isLink && listItem.urlInfo) {
          openExternalUrl(listItem.urlInfo.name);
        } else {
          openPreviewer(true);
          handleSetSelectedFile(listItem);
        }
      }}
      data-testid={`knowledge-center-listItem-${index}`}
      data-qa-id={`knowledge-center-listItem-${index}`}
      ref={referenceElement}
      style={style}
    >
      <KnowledgeCenterListItemNameWrapper>
        {listItemIcon &&
          (listItemIcon !== 'imageFile' || isValidLink === false) && (
            <SVGKnowledgeCenterListItemIcon icon={listItemIcon} size="32px" />
          )}
        {listItem.fileInfo?.thumbnails &&
          isValidLink === true &&
          listItemIcon === 'imageFile' && (
            <KnowledgeCenterListItemImage
              alt={listItem.fileInfo?.originalName ?? listItem.fileInfo?.name}
              src={listItem.fileInfo?.thumbnails['360']}
              onError={() => setIsValidLink(false)}
            />
          )}
        <KnowledgeCenterListItemBody
          variant="body1Medium"
          color={isHovered ? 'geekBlue6' : 'darkGray9'}
        >
          {listItem.fileInfo?.originalName
            ? listItem.fileInfo.originalName.split('.')[0]
            : listItem.fileInfo?.name.split('.')[0] || listItem.urlInfo?.name}
        </KnowledgeCenterListItemBody>
        {listItem.fileInfo ? (
          <Body
            color={isHovered ? 'geekBlue6' : 'darkGray9'}
            variant="body1Medium"
          >
            .{listItem.fileInfo?.type}
          </Body>
        ) : (
          <StyledSVGLinkIcon icon="external-link" size="16px" />
        )}
      </KnowledgeCenterListItemNameWrapper>
      <KnowledgeCenterListItemUserWrapper>
        <KnowledgeCenterListItemAvatarWrapper>
          <Avatar
            img={listItem.respondent?.image}
            name={listItem.respondent?.name}
            userId={listItem.respondent?.memberID}
            size="16px"
            iconSize="16px"
            verticalAlign="baseline"
            isAnonymous={
              listItem.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
              listItem.kind === FeedItemKind.EXTERNAL_ANONYMOUS
            }
          />
        </KnowledgeCenterListItemAvatarWrapper>
        <KnowledgeCenterListItemBody
          variant="body2"
          color={isHovered ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray9}
        >
          {listItem?.respondent ? listItem.respondent.name : ANONYMOUS}
        </KnowledgeCenterListItemBody>
      </KnowledgeCenterListItemUserWrapper>
      <KnowledgeCenterListItemBody
        style={{ marginTop: '6px', width: '25%' }}
        variant="body2"
        color={isHovered ? ThemeV2.palette.geekBlue6 : ThemeV2.palette.gray9}
      >
        {listItemDate}
      </KnowledgeCenterListItemBody>
      <div ref={anchorEl}>
        <ShortcutActionListHolder
          {...anchorProps}
          dropdownPlacement={DropdownPlacement.Left}
          isDropdownPositionFixed={false}
        />
      </div>
    </KnowledgeCenterListItemWrapper>
  );
};

export default KnowledgeCenterListItem;
