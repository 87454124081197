import styled from 'styled-components';
import Body from '../../../atomic/atoms/Body';
import Link from '../../../atomic/atoms/Link_V2';
import SVGIcon from '../../../atomic/atoms/SVGIcon';
import {
  Wrapper,
  Header,
  Heading,
} from '../../../atomic/organism/Notebook/NotebookTaskDetails/styles';
import { V2_SETTINGS } from '../../../constants/routes';
import { Flex } from '../../../Utils/styles/display';

const StyledLink = styled(Link)`
  font-size: 16px;
`;

const PeopleDirectoryRightDrawerController = () => {
  return (
    <Wrapper>
      <Header>
        <SVGIcon icon="info" size="36px" />
        <Heading variant="body1">People directory</Heading>
      </Header>
      <Flex justifyContent="center" flexDirection="column">
        <Body variant="body1" color="gray9">
          Search for any member in your organization, view their
          profile,activity in Assembly, or send them a direct message
        </Body>
        <Body variant="body1" color="gray9" margin="16px 0">
          You can update your profile information at any time in My Settings or
          click{' '}
          <StyledLink to={V2_SETTINGS} underline>
            here
          </StyledLink>
        </Body>
      </Flex>
    </Wrapper>
  );
};

export default PeopleDirectoryRightDrawerController;
