import React, { useState } from 'react';

import Collapse from '@mui/material/Collapse';
import Body from '../../atoms/Body';
import SVGIcon from '../../atoms/SVGIcon';
import ThemeV2 from '../../../theme';
import ToggleSwitch from '../../atoms/ToggleSwitch';
import ToggleSwitchLoader from '../../atoms/ToggleSwitch/Loader';
import {
  EmailActivityUpdateToggleItemProps,
  EmailNotificationAccordionWithToggleSubItemProps,
  EmailNotificationSettingFlowItemProps,
  EmailNotificationSettingItemProps,
  EmailNotificationSettingsRowProps,
} from './types';
import Button from '../../atoms/Button';
import EmoticonWithText from '../../atoms/EmoticonWithText';
import Divider from '../../atoms/Divider';
import {
  StyledLabel,
  StyledClickable,
  ItemTitle,
  ItemCaretSVGIcon,
  Wrapper,
  SubItemRoot,
  SubAccordionInnerContent,
  SettingsOption,
  StyledBodyErrorMessage,
  FlowItemCollapseWrapper,
} from './styles';
import { CLOSE, MANAGE } from '../../../languages/en/singleWords';
import { EMAIL_NOTIFICATION_SETTINGS_EMPTY_FLOWS } from '../../../languages/en/flows';

const EmailNotificationSettingItem = (
  props: EmailNotificationSettingItemProps,
) => {
  const { id, notificationType, description, isLoading, isError, children } =
    props;
  return (
    <Wrapper>
      <StyledLabel htmlFor={id}>
        <Body color="gray9" variant="body2">
          {notificationType}
        </Body>
        <Body color="gray8" variant="body3">
          {description}
        </Body>
      </StyledLabel>

      {isLoading && <ToggleSwitchLoader />}
      {isError && (
        <SVGIcon
          data-testid="error-warning-icon"
          color={ThemeV2.palette.gray6}
          icon="error-warning"
        />
      )}
      {!isLoading && !isError && children}
    </Wrapper>
  );
};

const EmailActivityUpdateToggleItem = (
  props: EmailActivityUpdateToggleItemProps,
) => {
  const { id, title, onToggle, isOn } = props;
  return (
    <SubItemRoot data-testid="accordionActivityUpdateSubItem">
      <div>
        <SettingsOption>
          <Body variant="body3">{title}</Body>
          <ToggleSwitch id={id} isOn={isOn || false} onChange={onToggle} />
        </SettingsOption>
      </div>
    </SubItemRoot>
  );
};

const EmailNotificationSettingsRow = (
  props: EmailNotificationSettingsRowProps,
) => {
  const [isAccordionOpen, setAccordionOpen] = useState(false);
  const [isAccordionSubItemOpenValue, setAccordionSubItemOpenValue] =
    useState('');

  const handleAccordionOpen = () => {
    setAccordionOpen(!isAccordionOpen);
    setAccordionSubItemOpenValue('');
  };

  const handleAccordionSubItemClick = (event: {
    currentTarget: { id: React.SetStateAction<string> };
  }) => {
    if (isAccordionSubItemOpenValue === event.currentTarget.id) {
      setAccordionSubItemOpenValue('');
    } else {
      setAccordionSubItemOpenValue(event.currentTarget.id);
    }
  };
  switch (props.type) {
    case 'accordion':
      return (
        <>
          <EmailNotificationSettingItem
            id={props.id}
            notificationType={props.notificationType}
            description={props.description}
            isLoading={props.isLoading}
            isError={props.isError}
          >
            <Button
              variant="text"
              size="small"
              onClick={handleAccordionOpen}
              dataTestId={isAccordionOpen ? CLOSE : MANAGE}
            >
              {isAccordionOpen ? CLOSE : MANAGE}
            </Button>
          </EmailNotificationSettingItem>
          <FlowItemCollapseWrapper in={isAccordionOpen}>
            {props.accordionSubItems && props.accordionSubItems.length > 0 ? (
              props.accordionSubItems.map(
                (subItem: EmailNotificationSettingFlowItemProps, index) => {
                  return (
                    <SubItemRoot
                      key={subItem.id}
                      data-testid="accordionSubItem"
                    >
                      <StyledClickable
                        onClick={handleAccordionSubItemClick}
                        id={subItem.id}
                      >
                        <ItemTitle variant="body2">
                          <EmoticonWithText
                            text={subItem.title}
                            emoticon={subItem.emoticon}
                          />
                          <ItemCaretSVGIcon
                            size="20px"
                            icon="caret-rounded"
                            color={ThemeV2.palette.gray8}
                            rotate={
                              isAccordionSubItemOpenValue === subItem.id
                                ? 180
                                : 0
                            }
                          />
                        </ItemTitle>
                      </StyledClickable>
                      <Collapse in={isAccordionSubItemOpenValue === subItem.id}>
                        <SubAccordionInnerContent>
                          <>
                            {subItem.flowGroup.map((group, groupIndex) => {
                              return (
                                <div key={group.groupName}>
                                  <Body variant="body2">{group.groupName}</Body>
                                  {group.options.map((option, optionIndex) => {
                                    return (
                                      <div key={option.label}>
                                        <SettingsOption>
                                          <Body variant="body3">
                                            {option.label}
                                          </Body>
                                          <ToggleSwitch
                                            id={
                                              props.suffixId
                                                ? `${subItem.id}_${groupIndex}_${optionIndex}_${props.suffixId}`
                                                : `${subItem.id}_${groupIndex}_${optionIndex}`
                                            }
                                            isOn={option.isOn}
                                            onChange={props.onToggle}
                                            dataTestId={`${subItem.title}-${group.groupName}-${option.label}`}
                                          />
                                        </SettingsOption>
                                        {group.options.length > 1 &&
                                          group.options.length - 1 ===
                                            optionIndex && (
                                            <Divider
                                              color={ThemeV2.palette.gray4}
                                              isFullWidth
                                            />
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </>
                        </SubAccordionInnerContent>
                      </Collapse>
                      {props.accordionSubItems &&
                        props.accordionSubItems.length - 1 !== index && (
                          <Divider color={ThemeV2.palette.gray4} isFullWidth />
                        )}
                    </SubItemRoot>
                  );
                },
              )
            ) : (
              <StyledBodyErrorMessage variant="body2" color="gray7">
                <span role="img" aria-label="emoticon">
                  🍃
                </span>{' '}
                {EMAIL_NOTIFICATION_SETTINGS_EMPTY_FLOWS}
              </StyledBodyErrorMessage>
            )}
          </FlowItemCollapseWrapper>
        </>
      );
    case 'toggleSwitch':
      return (
        <EmailNotificationSettingItem
          id={props.id}
          notificationType={props.notificationType}
          description={props.description}
          isLoading={props.isLoading}
          isError={props.isError}
        >
          <ToggleSwitch
            id={props.id}
            isOn={props.isOn || false}
            onChange={props.onToggle}
          />
        </EmailNotificationSettingItem>
      );
    case 'accordionWithToggleSwitch':
      return (
        <>
          <EmailNotificationSettingItem
            id={props.id}
            notificationType={props.notificationType}
            description={props.description}
            isLoading={props.isLoading}
            isError={props.isError}
          >
            <Button variant="text" size="small" onClick={handleAccordionOpen}>
              {isAccordionOpen ? CLOSE : MANAGE}
            </Button>
          </EmailNotificationSettingItem>
          <FlowItemCollapseWrapper in={isAccordionOpen}>
            {props.accordionSubItems?.map(
              (
                subItem: EmailNotificationAccordionWithToggleSubItemProps,
                index,
              ) => (
                <>
                  <EmailActivityUpdateToggleItem
                    key={subItem.id}
                    id={subItem.id}
                    title={subItem.title}
                    onToggle={subItem.onToggle}
                    isOn={subItem.isOn}
                  />
                  {props.accordionSubItems &&
                    props.accordionSubItems.length - 1 !== index && (
                      <Divider color={ThemeV2.palette.gray4} isFullWidth />
                    )}
                </>
              ),
            )}
          </FlowItemCollapseWrapper>
        </>
      );
    default:
      return null;
  }
};

export default EmailNotificationSettingsRow;
