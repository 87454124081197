import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useMediaQuery } from 'react-responsive';
import { convertTokenizedObjectToString } from '../../../Utils/notebook';
import { getWorkspaceTitlePrefix } from '../../../Utils/richMetaData';
import NotebookTaskDetails, {
  ReadOnlyComponentStatus,
} from '../../../atomic/organism/Notebook/NotebookTaskDetails';
import { device } from '../../../constants/layout';
import { ComponentStatus } from '../../../interfaces/component';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import useLayoutStore from '../../../stores/layoutStore';
import useNotebookRightDrawerController from './useNotebookRightDrawerController';

const NotebookRightDrawerController = () => {
  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const { removeSelectedMember, selectedMember } = useLayoutStore();

  const {
    currentUserId,
    onUpdateTask,
    editTaskContent,
    fetchMoreAssignees,
    handleAssigneeChange,
    setTaskIdOfFocusedTask,
    setSearchValueForAssignees,
    note,
    selectedTaskId,
    isLoading,
    assigneesList,
    hasMoreAssigneesToFetch,
    isFetchingMoreAssignees,
    searchValueForAssignees,
    currentUserTimezone,
    isTaskHistoryLoading,
    taskHistoryList,
    fetchMoreLogs,
    hasMoreLogsToFetch,
    isFetchingMoreLogs,
    historyLogSort,
    handleUserClick,
    toggleHistoryLogSort,
    isError,
    isAccessError,
    closeRightDrawer,
    dropdownOptions,
    archiveTask,
    rightDrawerSectionMenu,
    isTaskSectionDataLoading,
    isReadOnly,
    deleteTask,
  } = useNotebookRightDrawerController();

  const { data: profileData } = useProfileInfoFetchQuery();

  if (!selectedTaskId) {
    if (isMobileView && selectedMember?.memberId) {
      removeSelectedMember();
      return null;
    }

    return <NotebookTaskDetails status={ReadOnlyComponentStatus.READ_ONLY} />;
  }

  if (!note && isLoading) {
    return <NotebookTaskDetails status={ComponentStatus.LOADING} />;
  }

  if (
    (isError && selectedTaskId && !note && !isLoading) ||
    (!note && !isLoading) ||
    note?.isDeleted
  ) {
    return (
      <NotebookTaskDetails
        isAccessError={isAccessError}
        isDeletedError={!!note?.isDeleted === true}
        status={ComponentStatus.ERROR}
      />
    );
  }

  const taskTitle = convertTokenizedObjectToString(note.note);

  const openGraphDescription = convertTokenizedObjectToString(note.description);
  const openGraphTitle = `Task: ${taskTitle} | ${getWorkspaceTitlePrefix(
    profileData?.assembly.name,
  )}`;

  return (
    <>
      <Helmet>
        <title>{`Task: ${taskTitle}`}</title>
        <meta property="og:title" content={openGraphTitle} />
        {openGraphDescription && (
          <meta property="og:description" content={openGraphDescription} />
        )}
      </Helmet>
      <NotebookTaskDetails
        historyLogSort={historyLogSort}
        toggleHistoryLogSort={toggleHistoryLogSort}
        currentUserId={currentUserId}
        task={note}
        editTaskContent={editTaskContent}
        handleTaskUpdate={onUpdateTask}
        status={ComponentStatus.LOADED}
        setTaskIdOfFocusedTask={setTaskIdOfFocusedTask}
        fetchMoreAssignees={fetchMoreAssignees}
        assigneesList={assigneesList}
        hasMoreAssigneesToFetch={hasMoreAssigneesToFetch || false}
        isFetchingMoreAssignees={isFetchingMoreAssignees || false}
        handleSearchOnAssigneeList={setSearchValueForAssignees}
        searchValue={searchValueForAssignees}
        handleAssigneeChange={handleAssigneeChange}
        isTaskHistoryLoading={isTaskHistoryLoading}
        taskHistoryList={taskHistoryList}
        fetchMoreLogs={fetchMoreLogs}
        hasMoreLogsToFetch={hasMoreLogsToFetch}
        isFetchingMoreLogs={isFetchingMoreLogs}
        currentUserTimezone={currentUserTimezone}
        handleUserClick={handleUserClick}
        closeRightDrawer={closeRightDrawer}
        dropdownOptions={dropdownOptions}
        archiveTask={archiveTask}
        sectionDropdownOptions={rightDrawerSectionMenu}
        isTaskSectionDataLoading={isTaskSectionDataLoading}
        isReadOnly={isReadOnly}
        deleteTask={deleteTask}
      />
    </>
  );
};

export default memo(NotebookRightDrawerController);
