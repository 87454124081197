import React, { ReactNode, useCallback, useState } from 'react';
import { shallow } from 'zustand/shallow';

import {
  AsideInner,
  StyledAside,
  StyledIconButton,
  StyledResizable,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import useLayoutStore from '../../../stores/layoutStore';
import useKioskStore from '../../../stores/kioskStore';
import { NumberSize } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';
import {
  MaxAsideWidth,
  AsideWidthWhenOpen,
  MinimumAsideWidthWhenOpen,
  device,
} from '../../../constants/layout';
import useMobileApp from '../../../hooks/useMobileApp';
import { LayoutStoreState } from '../../../interfaces/Layout';
import { useMediaQuery } from 'react-responsive';

interface SecondaryDrawerProps {
  children: ReactNode;
  onSecondaryDrawerToggle?: (isRightAsideOpen: boolean) => void;
}

const SecondaryDrawer = ({
  children,
  onSecondaryDrawerToggle,
}: SecondaryDrawerProps) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const [width, setWidth] = useState(AsideWidthWhenOpen);
  const { isTurnedOn: isKioskTurnedOn } = useKioskStore();

  const { isMobileApp } = useMobileApp();

  const {
    isRightAsideOpen,
    toggleRightAsideOpen,
    showRightAside,
    setRightAsideWidth,
  } = useLayoutStore(
    (state) => ({
      isRightAsideOpen: state.isRightAsideOpen,
      toggleRightAsideOpen: state.toggleRightAsideOpen,
      showRightAside: state.showRightAside,
      showReplies: state.showReplies,
      setRightAsideWidth: state.setRightAsideWidth,
    }),
    shallow,
  );

  const handleOnResize = (
    _event: MouseEvent | TouchEvent,
    _direction: Direction,
    _elementRef: HTMLElement,
    delta: NumberSize,
  ) => {
    setWidth(width + delta.width);
    setRightAsideWidth(width + delta.width);
  };

  const handleOnResizing = (
    event: MouseEvent | TouchEvent,
    direction: Direction,
    elementRef: HTMLElement,
    delta: NumberSize,
  ) => {
    setRightAsideWidth(width + delta.width);
  };

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });
  const showRightAsideToFalseSelector = (state: LayoutStoreState) =>
    state.setRightAsideOpenToFalse;
  const showRightAsideToFalse = useLayoutStore(showRightAsideToFalseSelector);

  const toggleSecondaryDrawer = useCallback(() => {
    if (onSecondaryDrawerToggle) {
      onSecondaryDrawerToggle(isRightAsideOpen);
    }
    if (isMobileView && isRightAsideOpen) {
      showRightAsideToFalse();
    }

    toggleRightAsideOpen();
  }, [
    isMobileView,
    isRightAsideOpen,
    onSecondaryDrawerToggle,
    showRightAsideToFalse,
    toggleRightAsideOpen,
  ]);

  return (
    <StyledResizable
      size={{
        width: isRightAsideOpen ? width : '0px',
        height: 'unset',
      }}
      enable={{
        left: true,
      }}
      handleClasses={{
        left: 'resizable-handle-left',
      }}
      style={{
        zIndex: 3,
      }}
      maxWidth={isRightAsideOpen ? MaxAsideWidth : 0}
      onResizeStop={handleOnResize}
      minWidth={isRightAsideOpen ? MinimumAsideWidthWhenOpen : 0}
      onResize={handleOnResizing}
    >
      <StyledAside
        isResizable
        isKioskTurnedOn={isKioskTurnedOn}
        isRightAsideOpen={isRightAsideOpen}
        aria-hidden={isRightAsideOpen ? 'false' : 'true'}
        showRightAside={showRightAside}
        isMobileApp={isMobileApp}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
      >
        <AsideInner
          id="side-drawer"
          data-testid="side-drawer"
          data-qa-id="side-drawer"
          showRightAside={showRightAside}
        >
          {children}
        </AsideInner>
      </StyledAside>
      <StyledIconButton
        type="button"
        isActive
        onClick={toggleSecondaryDrawer}
        isRightAsideOpen={isRightAsideOpen}
        showRightAside={showRightAside}
        isKioskTurnedOn={isKioskTurnedOn}
        isEmbeddedInMainApp={isEmbeddedInMainApp}
        data-testid="side-drawer-toggle-button"
        data-qa-id="btn-side-draw-toggle"
      >
        {isRightAsideOpen ? (
          <SVGIcon icon="carat-right" size="20px" />
        ) : (
          <SVGIcon icon="carat-left" size="20px" />
        )}
      </StyledIconButton>
    </StyledResizable>
  );
};

export default SecondaryDrawer;
