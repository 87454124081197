import React from 'react';
import styled from 'styled-components';

import Chip from '../Chip';
import Body from '../../atoms/Body';
import { Flex } from '../../../Utils/styles/display';
import { FlowBlockPersonSelectionProps } from './type';

const StyledPersonChip = styled(Chip)`
  margin: 8px 8px 0 0;
`;

const FlowBlockPersonSelection = (props: FlowBlockPersonSelectionProps) => {
  const { title, persons, onMemberClick } = props;

  return (
    <section>
      <Body variant="body3" color="gray8">
        {title}
      </Body>
      <Flex flexWrap="wrap">
        {persons.map((person) => (
          <StyledPersonChip
            key={person.id}
            label={person.label}
            avatar={person.avatar}
            clickable={person.clickable}
            onClick={
              person.clickable
                ? () => {
                    if (onMemberClick) {
                      onMemberClick({
                        memberID: person?.id?.toString() || '',
                        firstName: person?.avatar?.name?.toString() || '',
                      });
                    }
                  }
                : undefined
            }
          />
        ))}
      </Flex>
    </section>
  );
};

export default FlowBlockPersonSelection;
