import format from 'date-fns/format';
import Slider from '@mui/material/Slider';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useFetchFlowFileQuery,
  useFetchPreviewFileQuery,
} from '../../queries/Flows/Feed';

import {
  SliderBox,
  HeaderText,
  VerticalBar,
  SideWrapper,
  StyledAvatar,
  PageSelector,
  ModalHeading,
  SliderWrapper,
  ButtonsWrapper,
  FileDescription,
  StyledIconButton,
  StyledSubheading,
  StyledBodyHeaderTitle,
  StickyFooter,
  FooterContainer,
  DownloadButton,
} from '../../atomic/molecules/FilePreviewModal/styles';

import { ANONYMOUS, UPLOADED, YOU } from '../../languages/en/singleWords';

import {
  SHARED_IN,
  VISIBILITY,
  DATE_NOT_FOUND,
  FILE_NOT_FOUND,
} from '../../languages/en/feed';

import ThemeV2 from '../../theme';
import { Root, PreviewContent } from './styles';
import ToolTip from '../../atomic/molecules/ToolTipV2';
import FileViewer from '../../atomic/organism/FileViewer';
import { mapHexCodeToEmoticon } from '../../Utils/mappers';
import {
  FileViewerEvents,
  KnowledgeCenterEvents,
} from '../../Utils/analytics/constants';
import { ButtonSize } from '../../atomic/atoms/IconButton_V2/interface';
import { trackFileViewerActionEvent } from '../../Utils/analytics/fileViewer';
import { useGetMainFeedAttachmentsQuery } from '../../queries/KnowledgeCenter';
import { FeedAttachmentItemFromAPI, FeedItemKind } from '../../interfaces/Feed';

import {
  PageBreakComponent,
  renderVisibilityText,
} from '../../atomic/molecules/FilePreviewModal';

import {
  imagePreviewFileTypes,
  supportedPdfPreviewFileTypes,
  supportedPreviewFileTypes,
} from '../../constants/files';
import { trackKnowledgeCenterActionEvent } from '../../Utils/analytics/knowledgeCenter';
import { downloadFeedFile } from '../../Utils/flows';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import PreviewLoader from './PreviewLoader';
import Body from '../../atomic/atoms/Body';
import { DOWNLOAD_FILE } from '../../languages/en/home';
import { PREVIEW_PAGE_LIMIT } from '../../atomic/organism/FileViewer/languages/en';
import { useFeatureSplit } from '../../hooks/useFeatureSplit';
import {
  SplitNames,
  TreatmentTypes,
} from '../../hooks/useSplitSdkConfig/constants';
import mobileStore from '../../stores/mobileAppStore';

const FilePreviewController = () => {
  const [selectedFile, setSelectedFile] =
    useState<FeedAttachmentItemFromAPI | null>(null);

  const { blockId, fileName, flowId, responseId } = useParams<{
    flowId: string;
    blockId: string;
    fileName: string;
    responseId: string;
  }>();

  const { data: files, isInitialLoading: isLoading } =
    useGetMainFeedAttachmentsQuery({
      filter: {
        flowIds: [flowId],
        blockIds: [blockId],
        keywords: [fileName],
      },
      sortBy: '',
    });

  const shouldFetchDownloadFileInfo = Boolean(
    flowId && blockId && fileName && responseId && selectedFile,
  );

  const {
    data: fileToDownload,
    error: downloadError,
    refetch: refetchDownloadLink,
  } = useFetchFlowFileQuery(
    {
      flowId,
      blockId,
      fileName,
      responseId,
    },
    shouldFetchDownloadFileInfo,
  );

  const { treatment: convertedPdfPreviewsTreatment } = useFeatureSplit(
    SplitNames.CONVERTED_PDF_PREVIEWS,
  );

  const isConvertedPdfPreviewsEnabled =
    convertedPdfPreviewsTreatment === TreatmentTypes.ON;

  const {
    data: fileToPreview,
    error: previewError,
    isLoading: isPreviewLoading,
    refetch: refetchPreviewLink,
  } = useFetchPreviewFileQuery(
    {
      flowId,
      blockId,
      fileName,
      responseId,
    },
    shouldFetchDownloadFileInfo &&
      isConvertedPdfPreviewsEnabled &&
      !!selectedFile?.fileInfo?.type &&
      supportedPdfPreviewFileTypes.includes(selectedFile.fileInfo.type),
  );

  const isPreviewTooLargeError = previewError?.response?.status === 413;
  const isPreviewUnsupportedError = previewError?.response?.status === 415;

  useEffect(() => {
    setSelectedFile(files?.pages[0].data[0] || null);
  }, [files]);

  const idPrefix = 'pdf-viewer-page-';

  const [defaultZoom, setDefaultZoom] = useState(100);
  const [zoomPercent, setZoomPercent] = useState(0);

  const bodyElement = useRef(null);

  useEffect(() => {
    setZoomPercent(defaultZoom);
  }, [defaultZoom]);

  const handleOnCloseButtonClick = () => {
    window.parent.postMessage(
      { type: 'FILE_PREVIEW_CLOSE', payload: location.pathname },
      '*',
    );
  };

  const handleOnGoToPostClick = () => {
    window.parent.postMessage({ type: 'GO_TO_POST' }, '*');
    handleOnCloseButtonClick();
  };

  const handleOnDownloadFileClick = useCallback(async (fileDownload) => {
    trackKnowledgeCenterActionEvent({
      action: KnowledgeCenterEvents.FileDownloaded,
    });

    showInfoMessage('Download in progress');

    try {
      await downloadFeedFile({
        blockId: fileDownload?.response.blockId,
        responseId: fileDownload?.response.responseId,
        fileName: fileDownload?.fileInfo.name,
        flowId: fileDownload?.response.flowId,
      });
      dismissAllToasts();
      showSuccessMessage('File downloaded successfully');
    } catch (e) {
      dismissAllToasts();
      showErrorMessage('Error while downloading the file');
    }
  }, []);

  const scrollToPage = (index: number) => {
    const myElement = document.getElementById(idPrefix + index);
    const topPos = myElement && myElement.offsetTop;
    if (bodyElement && bodyElement.current) {
      //@ts-ignore
      bodyElement.current.scrollTop = topPos;
    }
  };

  const PdfFooter = () => {
    return (
      <StickyFooter>
        <FooterContainer>
          <Body color="gray8" variant="body3">
            {PREVIEW_PAGE_LIMIT}
          </Body>
          <DownloadButton
            color="secondary"
            icon="download"
            onClick={() => handleOnDownloadFileClick(selectedFile)}
            size="small"
            variant="text"
          >
            <Body color="gray8" variant="body2">
              {DOWNLOAD_FILE}
            </Body>
          </DownloadButton>
        </FooterContainer>
      </StickyFooter>
    );
  };

  const PdfSideWrapper = (wrapperProps: {
    index: number;
    children: React.ReactNode;
  }) => {
    const { index, children } = wrapperProps;
    return (
      <SideWrapper onClick={() => scrollToPage(index)}>
        <div style={{ height: '16px' }} />
        {children}
      </SideWrapper>
    );
  };

  const onSliderMove = (event: Event, value: number) => {
    setZoomPercent(value);
  };

  const sliderText = (value: number) => {
    return '' + value;
  };

  const zoomPercentOnClickChangeValue = 15;

  const maxZoom = 200;

  if (isLoading) {
    return <PreviewLoader />;
  }

  if (!isLoading && !selectedFile) {
    return <div>File not found</div>;
  }

  if (selectedFile) {
    const {
      respondent: person,
      fileInfo: fileInformation,
      flowInfo: flow,
    } = selectedFile;

    const visibility = selectedFile.flowInfo.visibility;
    const isAnonymous =
      selectedFile?.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
      selectedFile?.kind === FeedItemKind.EXTERNAL_ANONYMOUS;

    const fileType = fileInformation
      ? fileInformation.name.substring(
          fileInformation.name.lastIndexOf('.') + 1,
          fileInformation.name.length,
        )
      : '';

    const isImage = imagePreviewFileTypes.includes(fileType);

    const shouldUseConvertedFileFormat =
      isConvertedPdfPreviewsEnabled &&
      supportedPdfPreviewFileTypes.includes(fileType);

    const isSupported =
      supportedPreviewFileTypes.includes(fileType) ||
      shouldUseConvertedFileFormat;

    const previewFileType =
      shouldUseConvertedFileFormat && !isPreviewUnsupportedError
        ? 'pdf'
        : fileType;

    const filePath = shouldUseConvertedFileFormat //@ts-ignore
      ? fileToPreview?.data.url //@ts-ignore
      : fileToDownload?.data.url;

    const hasPreviewError = shouldUseConvertedFileFormat
      ? !!previewError
      : !!downloadError;

    const hasProblemLoadingPreview = shouldUseConvertedFileFormat
      ? !isPreviewTooLargeError && !isPreviewUnsupportedError && !!previewError
      : !!downloadError;

    const reloadPreview = shouldUseConvertedFileFormat
      ? refetchPreviewLink
      : refetchDownloadLink;

    return (
      <Root data-testid="filePreviewModal" data-qa-id="filePreviewModal">
        <ModalHeading>
          <StyledAvatar
            img={person?.image}
            name={person?.firstName}
            userId={person?.memberID}
            size="40px"
            isAnonymous={isAnonymous}
            {...(isAnonymous && { iconSize: '40px' })}
          />
          <HeaderText>
            <StyledBodyHeaderTitle color="gray9" variant="body2">
              <span style={{ fontWeight: '500' }}>
                {person
                  ? person.memberID === '0'
                    ? `${YOU} `
                    : person?.firstName
                  : ANONYMOUS}
              </span>
              {` ${UPLOADED} `}
              <span style={{ fontWeight: '500' }}>
                {fileInformation
                  ? fileInformation.originalName ?? fileInformation.name
                  : `${FILE_NOT_FOUND}`}
              </span>
            </StyledBodyHeaderTitle>
            <StyledSubheading color="gray7" variant="body3">
              {fileInformation?.createdAt
                ? format(new Date(fileInformation.createdAt), 'LLL do, yyyy')
                : `${DATE_NOT_FOUND}`}
              {flow && visibility && (
                <>
                  {' • '}
                  {SHARED_IN}{' '}
                  {flow ? mapHexCodeToEmoticon(flow.icon.value) : ''}{' '}
                  {flow?.name}
                  {' • '}
                  {`${VISIBILITY}: ` + renderVisibilityText(visibility)}
                </>
              )}
            </StyledSubheading>
          </HeaderText>
          <ButtonsWrapper>
            {flowId && responseId && !mobileStore.getState().isMobileAppV3 && (
              <ToolTip
                toolTipComponent={
                  <StyledIconButton
                    height={32}
                    icon="go-to-post"
                    size={ButtonSize.Normal}
                    iconColor={ThemeV2.palette.gray6}
                    dataTestId="goToPostModal"
                    dataQaId="goToPostModal"
                    margin="0 16px 0"
                    onClick={handleOnGoToPostClick}
                  />
                }
                position="top"
                positionStrategy="fixed"
              >
                {'Go to post'}
              </ToolTip>
            )}
            {fileInformation && (
              <ToolTip
                toolTipComponent={
                  <StyledIconButton
                    height={32}
                    icon="download"
                    size={ButtonSize.Normal}
                    iconColor={ThemeV2.palette.gray6}
                    onClick={() => {
                      trackFileViewerActionEvent({
                        action: FileViewerEvents.DownloadFile,
                      });

                      handleOnDownloadFileClick(selectedFile);
                    }}
                  />
                }
                position="top"
                positionStrategy="fixed"
              >
                {'Download file'}
              </ToolTip>
            )}
            {((flowId && responseId) || fileInformation) && (
              <VerticalBar height={32} sideMargin={16} />
            )}
            <StyledIconButton
              height={32}
              icon="close-rounded"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
              dataTestId="closeFilePreviewModal"
              dataQaId="closeFilePreviewModal"
              onClick={handleOnCloseButtonClick}
            />
          </ButtonsWrapper>
        </ModalHeading>
        {isSupported && <FileDescription hasMessage={false} />}
        <PreviewContent fullHeight={!isSupported}>
          {previewFileType === 'pdf' && !hasPreviewError && (
            <PageSelector>
              <FileViewer
                fileType={previewFileType}
                filePath={filePath}
                zoomPercent={zoomPercent}
                setDefaultZoom={setDefaultZoom}
                SubComponent={PageBreakComponent}
                WrapperComponent={PdfSideWrapper}
                hideLoader={true}
                FooterComponent={PdfFooter}
                flowId={flowId}
                responseId={responseId}
                blockId={blockId}
              />
            </PageSelector>
          )}
          <FileViewer
            fileType={previewFileType}
            filePath={filePath}
            zoomPercent={zoomPercent}
            setDefaultZoom={setDefaultZoom}
            viewerRef={bodyElement}
            idPrefix={idPrefix}
            hasNoPreview={isPreviewTooLargeError}
            onDownloadClick={() => handleOnDownloadFileClick(selectedFile)}
            isLoading={isPreviewLoading}
            hasError={hasProblemLoadingPreview}
            onRetry={reloadPreview}
            isUnsupportedFileType={isPreviewUnsupportedError}
            flowId={flowId}
            responseId={responseId}
            blockId={blockId}
          />
        </PreviewContent>

        {isImage && (
          <SliderBox>
            <StyledIconButton
              height={24}
              icon="zoom-out"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent - zoomPercentOnClickChangeValue > defaultZoom) {
                  setZoomPercent(zoomPercent - zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(defaultZoom);
                }
              }}
            />
            <SliderWrapper>
              <Slider
                getAriaLabel={() => 'Range'}
                value={zoomPercent}
                //@ts-ignore
                onChange={onSliderMove}
                valueLabelDisplay="auto"
                getAriaValueText={sliderText}
                track={false}
                min={defaultZoom}
                max={maxZoom}
              />
            </SliderWrapper>
            <StyledIconButton
              height={24}
              icon="zoom-in"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray8}
              onClick={() => {
                if (zoomPercent + zoomPercentOnClickChangeValue < maxZoom) {
                  setZoomPercent(zoomPercent + zoomPercentOnClickChangeValue);
                } else {
                  setZoomPercent(maxZoom);
                }
              }}
            />
            <VerticalBar height={24} sideMargin={8} />
            <StyledIconButton
              height={24}
              icon="reset-zoom"
              size={ButtonSize.Normal}
              iconColor={ThemeV2.palette.gray6}
              onClick={() => setZoomPercent(defaultZoom)}
            />
          </SliderBox>
        )}
      </Root>
    );
  }

  return null;
};

export default FilePreviewController;
