import styled from 'styled-components';
import { Flex } from '../../../Utils/styles/display';
import Body from '../../atoms/Body';
import Dropdown from '../Dropdown_V2';
import {
  DropdownVariants,
  DropdownColor,
  MenuItemProps,
  DropdownPlacement,
  MenuItemIndividualItem,
} from '../Dropdown_V2/interfaces';
import ThemeV2 from '../../../theme';
import { useMemo, useState } from 'react';
import { MultiSelectAutoComplete } from '../../organism/Autocomplete';
import IconButton from '../../atoms/IconButton_V2';
import { CustomButtonColorConfig } from '../../atoms/IconButton_V2/interface';
import SVGIcon from '../../atoms/SVGIcon';
import { AutocompleteDropdownItem } from '../../organism/Autocomplete/interfaces';
import { useMediaQuery } from 'react-responsive';
import { device } from '../../../constants/layout';

export const defaultColorConfig: CustomButtonColorConfig = {
  background: ThemeV2.palette.geekBlue6,
  textColor: ThemeV2.palette.gray3,
  borderColor: ThemeV2.palette.gray8,
  hover: {
    background: ThemeV2.palette.geekBlue4,
    textColor: ThemeV2.palette.gray8,
    borderColor: ThemeV2.palette.gray3,
  },
  active: {
    background: ThemeV2.palette.geekBlue6,
    textColor: ThemeV2.palette.gray8,
    borderColor: ThemeV2.palette.gray3,
  },
};

const DepartmentIndicator = styled(Flex)`
  padding: 9px 12px;
  gap: 8px;
  background: ${ThemeV2.palette.gray3};
  border-radius: 4px;
  width: fit-content;
  cursor: default;
`;

export const GroupCriteriaConditionDropdown = styled(Dropdown)`
  button {
    margin: 0 8px;
    border-radius: 4px;
    &:focus-within {
      background: ${ThemeV2.palette.gray3};
    }
  }
`;

const AutoCompleteWrapper = styled.div`
  flex-grow: 1;
  max-width: 320px;

  @media ${device.mobile} {
    max-width: 160px;
  }

  .input-new-line {
    .MuiInputBase-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      .MuiInputBase-input {
        width: 80%;
      }

      .autocomplete-loader {
        position: absolute;
        right: 12px;
        bottom: 12px;
        margin: 0;
      }

      .MuiAutocomplete-endAdornment {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`;

const SelectButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  margin: 0 8px;
`;

const DeleteButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  margin-right: 8px;
`;

export const SelectIcon = styled(SVGIcon)`
  border-radius: 4px;
`;

export const DeleteIcon = styled(SVGIcon)`
  border-radius: 4px;
  padding: 0 4px 0 2px;
`;

const isConditionMenuOption = {
  value: `is`,
  id: `is`,
};

const isNotConditionMenuOption = {
  value: `is not`,
  id: `isNot`,
};

export const departmentCriteriaConditionItems: MenuItemProps[] = [
  {
    id: 'department-criteria-options',
    items: [isConditionMenuOption, isNotConditionMenuOption],
  },
];

export const BulkRuleTitleMap = {
  department: 'Department',
  workLocation: 'Work',
  jobTitle: 'Job title',
  homeLocation: 'Home',
};

export const BulkRulePlaceholderMap = {
  department: 'department',
  workLocation: 'location',
  jobTitle: 'job title',
  homeLocation: 'location',
};

export const BulkRuleSelector = ({
  nonMemberItemSelectionType,
  isBulkRuleSelectorDataLoading,
  bulkRuleOptions,
  onBulkRuleOptionSelect: onDepartmentSelect,
  onBulkRuleOptionDelete: onDepartmentDelete,
}: {
  nonMemberItemSelectionType:
    | 'department'
    | 'workLocation'
    | 'jobTitle'
    | 'homeLocation';
  isBulkRuleSelectorDataLoading: boolean;
  bulkRuleOptions: AutocompleteDropdownItem<string>[];
  onBulkRuleOptionSelect: (data: {
    bulkRule: AutocompleteDropdownItem<string, undefined>[];
    condition: MenuItemIndividualItem;
    type: string;
  }) => void;
  onBulkRuleOptionDelete: () => void;
}) => {
  const [condition, setConditionValue] = useState<MenuItemIndividualItem>(
    isConditionMenuOption,
  );
  const [selectedDepartments, setSelectedDepartments] = useState<
    AutocompleteDropdownItem<string>[]
  >([]);

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  return (
    <Flex
      padding={isMobileView ? '' : '8px'}
      width="100%"
      alignItems="flex-start"
    >
      {!isMobileView && (
        <DepartmentIndicator>
          <Body color="gray8" variant="body2Medium">
            {BulkRuleTitleMap[nonMemberItemSelectionType]}
          </Body>
        </DepartmentIndicator>
      )}
      <GroupCriteriaConditionDropdown
        onItemClick={(itemKey) => {
          setConditionValue(
            itemKey === 'is' ? isConditionMenuOption : isNotConditionMenuOption,
          );
        }}
        menuItems={useMemo(
          () =>
            departmentCriteriaConditionItems.map((group) => ({
              ...group,
              items: group.items.map((item) => ({
                ...item,
                isSelected: item.id === condition.id,
              })),
            })),
          [condition.id],
        )}
        variant={DropdownVariants.Text}
        color={DropdownColor.BlueTertiary}
        value={condition.value}
        dropdownPlacement={DropdownPlacement.BottomStart}
      />

      <AutoCompleteWrapper>
        <MultiSelectAutoComplete
          loading={isBulkRuleSelectorDataLoading}
          value={selectedDepartments}
          options={bulkRuleOptions}
          onChange={(selectedOptions) => {
            if (selectedOptions) setSelectedDepartments(selectedOptions);
          }}
          label={
            isMobileView
              ? `Choose a ${BulkRulePlaceholderMap[nonMemberItemSelectionType]}`
              : undefined
          }
          placeholder={
            isMobileView
              ? undefined
              : `Choose a ${BulkRulePlaceholderMap[nonMemberItemSelectionType]}`
          }
          disableCloseOnSelect
          autoFocus
          className="input-new-line"
          thresholdToShowTooltipForChips={isMobileView ? undefined : 20}
        />
      </AutoCompleteWrapper>

      <SelectButton
        onClick={() =>
          onDepartmentSelect({
            bulkRule: selectedDepartments,
            condition,
            type: nonMemberItemSelectionType,
          })
        }
        color="primary"
        variant="contained"
        disabled={selectedDepartments.length === 0}
      >
        <SelectIcon
          icon="check-rounded"
          size="28px"
          data-testid="SVGIcon"
          data-qa-id="icon-svg"
        />
      </SelectButton>
      <DeleteButton onClick={onDepartmentDelete} color="text">
        <DeleteIcon
          icon="delete"
          size="24px"
          data-testid="SVGIcon"
          data-qa-id="icon-svg"
        />
      </DeleteButton>
    </Flex>
  );
};
