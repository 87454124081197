import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_FLOWS_TODO_NOTIFICATION_TEXT } from '../../languages/en/flows';
import { useGetFlowsTodoCountQuery } from '../../queries/ActionBar';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import useModalsStore from '../../stores/modalsStore';
import { addCelebrateTeammate, mapFlowsToActionBarItem } from './utils';

import { trackActionBarActionEvent } from '../../Utils/analytics/actionBar';
import {
  MAIN_FEED_ACTION_BAR_EVENTS,
  TEMPLATE_GALLERY_EVENTS,
} from '../../Utils/analytics/constants';
import { trackTemplateGalleryShowEvent } from '../../Utils/analytics/templateGallery';
import { HALF_SECOND } from '../../Utils/constants/times';
import { getAnswerFlowRoute } from '../../Utils/flows';
import { FLOWS_FEEDS, RECOGNITION_PARTICIPATE } from '../../constants/routes';
import useGetActionBarData from '../../hooks/useGetActionBarData';
import useHistoryWrapper from '../../hooks/useHistoryWrapper';
import useToggle from '../../hooks/useToggle';

const useActionBarControllerLogic = () => {
  const history = useHistoryWrapper();

  const [searchText, setSearchText] = useState<string>('');
  const [apiSearchText, setApiSearchText] = useState<string>('');
  const { push } = useHistory();
  const {
    isLoading: isActionBarDataLoading,
    isError,
    todo,
    shortcut,
    flowsList,
    isSuccess,
  } = useGetActionBarData(apiSearchText);

  const { data: todoCountData } = useGetFlowsTodoCountQuery();

  const {
    data: profileData,
    refetch: refetchProfileInfoQuery,
    isInitialLoading: isProfileDataLoading,
  } = useProfileInfoFetchQuery();
  const { openCreateFlowModal } = useModalsStore();

  const isLoading = isActionBarDataLoading || isProfileDataLoading;

  const previousUrl = window.history.state?.prevUrl;

  useEffect(() => {
    if (previousUrl) {
      refetchProfileInfoQuery();
    }
  }, [previousUrl, refetchProfileInfoQuery]);

  const todoNotification = useMemo(() => {
    if (todoCountData && todoCountData.count) {
      return GET_FLOWS_TODO_NOTIFICATION_TEXT(todoCountData.count);
    }
    return '';
  }, [todoCountData]);

  const todos = useMemo(() => {
    if (todo && todo.data) {
      return mapFlowsToActionBarItem(todo.data);
    }
    return [];
  }, [todo]);

  const shortcuts = useMemo(() => {
    if (shortcut && shortcut.data) {
      const allShortcuts = addCelebrateTeammate(shortcut.data, apiSearchText);

      return mapFlowsToActionBarItem(allShortcuts);
    }
    return [];
  }, [shortcut, apiSearchText]);

  const feeds = useMemo(() => {
    if (flowsList && flowsList?.data) {
      const allViewFeeds = addCelebrateTeammate(flowsList.data, apiSearchText);
      return mapFlowsToActionBarItem(allViewFeeds);
    }
    return [];
  }, [flowsList, apiSearchText]);

  const debounceSearchText = useRef(
    debounce((newValue: string) => {
      setApiSearchText(newValue);
      trackActionBarActionEvent({
        searchText: newValue,
        numToDoFlows: todos.length,
        numShortcutFlows: shortcuts.length,
        action: MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_SEARCHED,
      });
    }, HALF_SECOND),
  );

  const handleSearchTextChange = useCallback((newValue: string) => {
    setSearchText(newValue);
    debounceSearchText.current(newValue);
  }, []);

  const clearSearchText = () => {
    setSearchText('');
    setApiSearchText('');
  };

  const {
    models: { toggleValue: isRecognitionFlowModalOpen },
    operations: { setToggleValue: toggleIsRecognitionFlowModalOpen },
  } = useToggle();

  const handleSuffixAdornmentClickCb = () => {
    trackActionBarActionEvent({
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action:
        MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_SEARCH_CLEAR_CLICKED,
    });
  };

  const handleCelebrateTeammateClick = () =>
    history.push(`${RECOGNITION_PARTICIPATE}?redirectUrl=${location.pathname}`);

  const handleFlowClick = (flowId: string, occurrenceId?: string) => {
    history.push(getAnswerFlowRoute(flowId, occurrenceId, location.pathname));

    trackActionBarActionEvent({
      flowId: flowId,
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action: MAIN_FEED_ACTION_BAR_EVENTS.PARTICIPATION_STARTED,
    });
  };

  const handleFlowFeedClick = useCallback(
    (flowId: string) => {
      const getLinkURL = FLOWS_FEEDS.replace(':flowId', flowId);
      push(getLinkURL);

      trackActionBarActionEvent({
        flowId: flowId,
        numToDoFlows: todos.length,
        numShortcutFlows: shortcuts.length,
        action: MAIN_FEED_ACTION_BAR_EVENTS.VIEW_FEED_CLICKED,
      });
    },
    [push],
  );

  const handleAddNewFlowClick = useCallback(() => {
    openCreateFlowModal(undefined);
    trackActionBarActionEvent({
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action: MAIN_FEED_ACTION_BAR_EVENTS.ADD_FLOW_CLICKED,
    });

    trackTemplateGalleryShowEvent({
      show: TEMPLATE_GALLERY_EVENTS.TEMPLATE_GALLERY_SHOW,
      source: 'actionBar',
    });
  }, [openCreateFlowModal, shortcuts.length, todos.length]);

  const onClickActionBarButtonCb = () => {
    trackActionBarActionEvent({
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action: MAIN_FEED_ACTION_BAR_EVENTS.DROPDOWN_ACTION_BAR_OPENED,
    });
  };

  const closeModalCb = () => {
    trackActionBarActionEvent({
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action: MAIN_FEED_ACTION_BAR_EVENTS.ACTION_BAR_DROPDOWN_CLOSED,
    });
  };

  const onNeedHelpClick = () => {
    trackActionBarActionEvent({
      numToDoFlows: todos.length,
      numShortcutFlows: shortcuts.length,
      action: MAIN_FEED_ACTION_BAR_EVENTS.NEED_HELP_CLICKED,
    });
  };

  return {
    models: {
      isLoading,
      isSuccess,
      isError,
      todos,
      shortcuts,
      feeds,
      todoNotification,
      profileData,
      searchText,
      isRecognitionFlowModalOpen,
      hasNoFlows: apiSearchText.length === 0 && flowsList?.data.length === 0,
    },
    operations: {
      closeModalCb,
      clearSearchText,
      handleFlowClick,
      onNeedHelpClick,
      handleCelebrateTeammateClick,
      handleFlowFeedClick,
      handleAddNewFlowClick,
      handleSearchTextChange,
      onClickActionBarButtonCb,
      handleSuffixAdornmentClickCb,
      toggleIsRecognitionFlowModalOpen,
    },
  };
};

export default useActionBarControllerLogic;
