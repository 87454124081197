import React from 'react';

import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import { CONFIGURE_SETTINGS_FLOW_END_BLOCK } from '../../../languages/en/flows/builder';

import { FlowEndProps } from './types';
import { FlowEndRadioGroup, StyledBody, StyledIconInfo } from './styles';

const FlowEnd = (props: FlowEndProps) => {
  const {
    scheduleEndValue,
    onScheduleEndValueChange,
    scheduleEndValueOptions,
    disableScheduleEnd,
  } = props;

  return (
    <>
      <StyledBody variant="body1Medium">
        {CONFIGURE_SETTINGS_FLOW_END_BLOCK.TITLE_TEXT}
      </StyledBody>
      {disableScheduleEnd && (
        <StyledIconInfo
          backgroundColor={ThemeV2.palette.geekBlue2}
          icon="info-icon"
          iconColor={ThemeV2.palette.geekBlue6}
          iconSize="16px"
          paddingTopBottom="6px"
          paddingLeftRight="6px"
        >
          <Body variant="body3">
            {CONFIGURE_SETTINGS_FLOW_END_BLOCK.DISABLED_INFO_TEXT}
          </Body>
        </StyledIconInfo>
      )}
      <FlowEndRadioGroup
        radioOptions={scheduleEndValueOptions}
        value={scheduleEndValue}
        name="schedule-end"
        onChange={onScheduleEndValueChange}
      />
    </>
  );
};

export default FlowEnd;
