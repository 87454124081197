import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@mui/material';
import Radio from '../../atoms/Radio';
import ThemeV2 from '../../../theme';
import Clickable from '../../atoms/Clickable';
import Body from '../../atoms/Body';
import Button from '../../atoms/Button';
import TextField from '../NewTextfield';

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 16px;
  padding-left: 12px;
  .MuiFormControlLabel-label {
    margin-left: 20px;
    font-size: 14px;
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    line-height: 22px;
  }
  .MuiButtonBase-root {
    padding: 0;
  }
`;

export const StyledClickableClose = styled(Clickable)`
  display: flex;
  margin-left: auto;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export const StyledRadio = styled(Radio)`
  padding: 0 !important;
  .MuiSvgIcon-root {
    height: 20px;
    width: 20px;
  }
`;

export const StyledRadioOptionItem = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  ${StyledClickableClose} {
    align-self: flex-start;
    margin-top: 2px;
  }
`;

export const StyledNumberedOptionItem = styled(Body)`
  color: ${ThemeV2.palette.gray9};
`;

export const OptionLabel = styled(Body)`
  padding: 5px 4px 5px 12px;
`;

export const LabelHelperText = styled.span`
  margin-left: 20px;
  color: ${ThemeV2.palette.gray6};
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  font-size: 14px;
  line-height: 22px;
`;

export const OtherLabelText = styled(Body)`
  margin-left: 20px;
  padding-left: 4px;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiButtonBase-root {
    color: ${ThemeV2.palette.gray6};
    padding: 0;
    &.Mui-checked {
      color: ${ThemeV2.palette.geekBlue6};
      &:hover {
        background: transparent;
      }
    }
    &:hover {
      background: transparent;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
`;

export const StyledClickableOption = styled(Clickable)<{
  $isInEditingMode: boolean;
}>`
  width: 100%;
  text-align: left;
  cursor: ${({ $isInEditingMode }) =>
    $isInEditingMode ? 'default !important' : 'pointer !important'};
`;

export const LabelWrapper = styled.div<{ $isInEditingMode: boolean }>`
  display: flex;
  align-items: ${({ $isInEditingMode }) =>
    $isInEditingMode ? 'flex-start' : 'center'};
  border-radius: 4px;
  padding: 0;
  margin-right: 12px;
  margin-left: 12px;
  width: 100%;
  text-align: left;
  cursor: ${({ $isInEditingMode }) =>
    $isInEditingMode ? 'default !important' : 'pointer !important'};
  min-height: 32px;
  box-sizing: border-box;
  &:hover {
    background: ${({ $isInEditingMode }) =>
      !$isInEditingMode && ThemeV2.palette.gray4};
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiInputBase-root textarea {
    height: 32px;
    font-family: ${ThemeV2.typography.adminFontFamily};
    font-weight: ${ThemeV2.typography.fontWeightRegular};
    font-size: 14px;
    line-height: 22px;
    padding: 8px 12px;
  }
  .MuiOutlinedInput-multiline {
    padding: 0px;
  }
`;

export const OrTextSeparator = styled.span`
  font-family: ${ThemeV2.typography.adminFontFamily};
  font-weight: ${ThemeV2.typography.fontWeightRegular};
  color: ${ThemeV2.palette.gray8};
  font-size: 14px;
  line-height: 22px;
  margin-top: 5px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const StyledAddOptionButton = styled(Button)`
  color: ${ThemeV2.palette.gray6};
  padding-left: 14px;
`;
