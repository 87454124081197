import React from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

import ThemeV2 from '../../../../../theme';

const LoaderWrapper = styled.div`
  padding: 16px 18px;
`;

export const PostLoader = () => {
  return (
    <LoaderWrapper>
      <ContentLoader
        speed={2}
        width="100%"
        height={320}
        data-testid="flow-feed-post-loader"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
      >
        <circle cx="40" cy="36" r="26" />
        <rect x="72" y="8" rx="4" ry="4" width="189" height="24" />
        <rect x="72" y="40" rx="4" ry="4" width="257" height="24" />
        <rect x="12" y="90" rx="4" ry="4" width="426" height="16" />
        <rect x="12" y="116" rx="4" ry="4" width="313" height="16" />
      </ContentLoader>
    </LoaderWrapper>
  );
};

export const CommentsLoader = () => {
  return (
    <div>
      <ContentLoader
        speed={2}
        width="100%"
        height={120}
        data-testid="flow-feed-post-loader"
        backgroundColor={ThemeV2.palette.gray4}
        foregroundColor={ThemeV2.palette.gray3}
      >
        <circle cx="40" cy="36" r="21" />
        <rect x="72" y="18" rx="4" ry="4" width="189" height="12" />
        <rect x="72" y="40" rx="4" ry="4" width="257" height="12" />
      </ContentLoader>
    </div>
  );
};
