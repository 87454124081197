import React, { memo, useContext, useMemo } from 'react';

import Button from '../../atoms/Button';
import Body from '../../atoms/Body';
import GifSelector from '../GifSelector';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';

import { Flex } from '../../../Utils/styles/display';
import {
  IconButtonWithToolTipData,
  PostButtonText,
  PostPrivateButtonText,
  REMOVE_GIF_TO_SWITCH,
} from '../../../languages/en/giveRecognitionForm/index';
import { TrophiesInputTooltip } from '../../../languages/en/commentsForm/index';
import { Icon } from '../../../constants/giveRecognitionForm';
import { AddTrophiesInputProps } from '../AddTrophiesInput';
import {
  GiveRecognitionFormBottomButtonsProps,
  IconButtonWrapperInterface,
  PostType,
  ToolTipComponentProps,
} from './interface';

import {
  Wrapper,
  StyledToolTip,
  TooltipContent,
  IconButtonWrapper,
  StyledTrophiesInput,
  ButtonContentFlexWrapper,
  StyledEmoticonPickerPopover,
} from './styles';

import { AssemblyCurrency } from '../../../interfaces/assembly';
import { GifRatingsProps } from '../../atoms/Giphy/interface';
import DropdownIconButton from '../DropdownIconButton';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';
import { CREATE_NEW_TASK } from '../../../languages/en/notebook';
import { TaskOptionsContext } from '../../../hooks/useParticipationTaskOptions/taskOptionsContext';

const ToolTipComponent = ({ text }: ToolTipComponentProps) => (
  <Body variant="body3" color="gray1">
    {text}
  </Body>
);

const GiveRecognitionFormBottomButtons = (
  props: GiveRecognitionFormBottomButtonsProps,
) => {
  const {
    postType = PostType.Comment,
    isGifSelected = false,
    isAttachmentSelected = false,
    isPrivateMessageSelected = false,
    onAtMentionClick,
    onEmoticonClick,
    onGifSelectorClick,
    onAttachmentClick,
    onPostClick,
    isCreatingPostOrComment = false,
    disabled = false,
    className,
    showAddTrophy = false,
    onPrivateMessageClick,
    isAttachmentButtonHidden = false,
    isPrivatePostButtonHidden = false,
    gifRatings = GifRatingsProps.PG_13,
    isEmoticonsButtonHidden,
    isGifButtonHidden,
    isAtMentionButtonHidden,
    isTasksButtonHidden,
    isDisabled = false,
    iconSize = ButtonSize.Large,
    isEndIcon = false,
    isFocused = true,
  }: GiveRecognitionFormBottomButtonsProps = props;
  const {
    buttonText,
    value,
    assemblyCurrency,
    maxValue,
    splitBy,
    menuItems,
    canAllowCustomAmount,
    onChange,
    onBlur,
    onItemClick,
    buttonColor,
  }: AddTrophiesInputProps = props;

  const {
    anchorElForTasksDropdown,
    handleCreateTaskClick,
    handleReferenceTaskClick,
  } = useContext(TaskOptionsContext);

  const { name: currencyName }: AssemblyCurrency = assemblyCurrency;

  const updatedTrophiesInputTooltip = useMemo(() => {
    const replacedString = TrophiesInputTooltip.replace(
      ':currency',
      currencyName,
    );
    return replacedString;
  }, [currencyName]);

  const iconButtonWrapperContent = useMemo(() => {
    let result: IconButtonWrapperInterface[] = [
      {
        icon: Icon.AT_MENTION,
        onClick: onAtMentionClick,
        toolTipText: IconButtonWithToolTipData.AT_MENTION,
        isHidden: isAtMentionButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.EMOTICON,
        toolTipText: IconButtonWithToolTipData.EMOTICONS,
        isHidden: isEmoticonsButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.GIF_SELECTOR,
        toolTipText: IconButtonWithToolTipData.GIF_SELECTOR,
        isSelected: isGifSelected,
        isHidden: gifRatings === undefined || isGifButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.ATTACHMENT,
        onClick: onAttachmentClick,
        toolTipText: IconButtonWithToolTipData.ATTACHMENT,
        isSelected: isAttachmentSelected,
        isHidden: isAttachmentButtonHidden,
        isDisabled,
      },
      {
        icon: Icon.TASKS,
        toolTipText: IconButtonWithToolTipData.TASKS,
        isHidden: isTasksButtonHidden,
        isDisabled,
      },
      {
        icon: isPrivateMessageSelected ? Icon.LOCK : Icon.UN_LOCK,
        onClick: onPrivateMessageClick,
        toolTipText: IconButtonWithToolTipData.PRIVATE_MESSAGE,
        isSelected: isPrivateMessageSelected,
        isHidden: isPrivatePostButtonHidden,
      },
    ];

    result = result.filter((x) => x.icon !== Icon.TASKS);

    return result;
  }, [
    onAtMentionClick,
    isAtMentionButtonHidden,
    isDisabled,
    isEmoticonsButtonHidden,
    isGifSelected,
    gifRatings,
    isGifButtonHidden,
    onAttachmentClick,
    isAttachmentSelected,
    isAttachmentButtonHidden,
    isTasksButtonHidden,
    isPrivateMessageSelected,
    onPrivateMessageClick,
    isPrivatePostButtonHidden,
  ]);

  const ButtonContent = useMemo(() => {
    return iconButtonWrapperContent
      .filter((iconButton) => !iconButton.isHidden)
      .map((iconButton) => {
        if (iconButton.icon === Icon.GIF_SELECTOR) {
          return (
            <GifSelector
              onGifSelect={onGifSelectorClick}
              xAxisOffset={-180}
              yAxisOffset={185}
              key={iconButton.toolTipText}
              gifRatings={gifRatings}
            >
              {({ togglePopover, isPopoverOpen }) => {
                return (
                  <IconButtonWrapper
                    isFocused={isFocused}
                    disabled={isGifSelected || iconButton.isDisabled}
                    size={iconSize}
                    icon={iconButton.icon}
                    toolTipContent={
                      <ToolTipComponent
                        text={
                          isGifSelected
                            ? REMOVE_GIF_TO_SWITCH
                            : iconButton.toolTipText
                        }
                      />
                    }
                    iconBgColor={iconButton.isDisabled ? 'white' : ''}
                    title={iconButton.toolTipText}
                    onClick={togglePopover}
                    isSelected={!!(isPopoverOpen || iconButton.isSelected)}
                  />
                );
              }}
            </GifSelector>
          );
        }
        if (iconButton.icon === Icon.EMOTICON) {
          return (
            <StyledEmoticonPickerPopover
              onEmoticonSelect={onEmoticonClick}
              xAxisOffset={-180}
              yAxisOffset={185}
              key={iconButton.toolTipText}
            >
              {({ togglePopover, isPopoverOpen }) => (
                <IconButtonWrapper
                  isFocused={isFocused}
                  disabled={iconButton.isDisabled}
                  size={iconSize}
                  icon={iconButton.icon}
                  toolTipContent={
                    <ToolTipComponent text={iconButton.toolTipText} />
                  }
                  title={iconButton.toolTipText}
                  onClick={togglePopover}
                  isSelected={!!(isPopoverOpen || iconButton.isSelected)}
                  iconBgColor={iconButton.isDisabled ? 'white' : ''}
                />
              )}
            </StyledEmoticonPickerPopover>
          );
        }
        const commonProps = {
          disabled: iconButton?.isDisabled || false,
          icon: iconButton.icon,
          iconBgColor: iconButton.isDisabled ? 'white' : '',
          isSelected: iconButton.isSelected,
          rounded: false,
          size: ButtonSize.Small,
          title: iconButton.toolTipText,
          toolTipContent: <ToolTipComponent text={iconButton.toolTipText} />,
        };

        if (iconButton.icon === Icon.TASKS) {
          const handleTaskMenuOptionClick = (id: string | number) => {
            if (id === 'create-new-task') {
              handleCreateTaskClick();
            } else if (id === 'reference-existing-task') {
              handleReferenceTaskClick();
            }
          };
          return (
            <DropdownIconButton
              {...commonProps}
              size={ButtonSize.Large}
              anchorEl={anchorElForTasksDropdown}
              dropdownPlacement={DropdownPlacement.Top}
              key={iconButton.toolTipText}
              menuItems={[
                {
                  id: 'create-new-task',
                  items: [
                    {
                      id: 'create-new-task',
                      prefixIcon: 'union',
                      value: CREATE_NEW_TASK,
                    },
                  ],
                },
                {
                  id: 'reference-existing-task',
                  items: [
                    {
                      id: 'reference-existing-task',
                      prefixIcon: 'note',
                      value: 'Reference existing task',
                    },
                  ],
                },
              ]}
              onItemClick={handleTaskMenuOptionClick}
            />
          );
        }
        return (
          <IconButtonWrapper
            {...commonProps}
            isFocused={isFocused}
            key={iconButton.toolTipText}
            onClick={iconButton.onClick}
          />
        );
      });
  }, [
    iconButtonWrapperContent,
    isFocused,
    onGifSelectorClick,
    gifRatings,
    isGifSelected,
    iconSize,
    onEmoticonClick,
    anchorElForTasksDropdown,
    handleCreateTaskClick,
    handleReferenceTaskClick,
  ]);

  const renderTrophiesInput = useMemo(() => {
    if (postType === PostType.Comment && showAddTrophy) {
      return (
        <Flex>
          <StyledTrophiesInput
            isEndIcon={isEndIcon}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            maxValue={maxValue}
            buttonText={buttonText}
            assemblyCurrency={assemblyCurrency}
            menuItems={menuItems}
            splitBy={splitBy}
            canAllowCustomAmount={canAllowCustomAmount}
            onItemClick={onItemClick}
            buttonColor={buttonColor}
          />
          <StyledToolTip>
            <TooltipContent>{updatedTrophiesInputTooltip}</TooltipContent>
          </StyledToolTip>
        </Flex>
      );
    }
    return null;
  }, [
    postType,
    showAddTrophy,
    isEndIcon,
    value,
    onChange,
    onBlur,
    maxValue,
    buttonText,
    assemblyCurrency,
    menuItems,
    splitBy,
    canAllowCustomAmount,
    onItemClick,
    buttonColor,
    updatedTrophiesInputTooltip,
  ]);

  return (
    <Wrapper
      postType={postType}
      showAddTrophy={showAddTrophy}
      className={className}
    >
      {renderTrophiesInput}

      <ButtonContentFlexWrapper>{ButtonContent}</ButtonContentFlexWrapper>
      {postType === PostType.Recognition && (
        <Button
          size="small"
          onClick={onPostClick}
          disabled={disabled}
          isLoading={isCreatingPostOrComment}
        >
          {isPrivateMessageSelected ? PostPrivateButtonText : PostButtonText}
        </Button>
      )}
    </Wrapper>
  );
};

const MemoizedGiveRecognitionFormBottomButtons = memo(
  GiveRecognitionFormBottomButtons,
);
MemoizedGiveRecognitionFormBottomButtons.displayName =
  'GiveRecognitionFormBottomButtons';

export default MemoizedGiveRecognitionFormBottomButtons;
