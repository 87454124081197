import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import ThemeV2 from '../../../theme';

export const ImageWrapper = styled.img<{
  placeholderImage?: boolean;
  imgLoaded?: boolean;
}>`
  display: ${({ placeholderImage, imgLoaded }) =>
    placeholderImage && !imgLoaded ? 'none' : 'inherit'};
  max-width: 100%;
`;
export const ImagePlaceholderAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;
export const ImagePlaceholder = styled.div<{
  imgLoaded?: boolean;
  height?: string;
  width?: string;
}>`
  display: ${({ imgLoaded }) => (imgLoaded ? 'none' : 'block')};
  position: relative;
  z-index: 0;
  background: ${ThemeV2.palette.gray4};
  height: ${({ height }) => height || '100%'};
  width: ${({ width }) => width || '100%'};
  &:after {
    content: '';
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.4) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    animation: ${ImagePlaceholderAnimation} 1s infinite;
  }
`;

export interface ImageProps {
  className?: string;
  src: string;
  srcset?: string;
  alt: string;
  width?: string;
  height?: string;
  isFullWidth?: boolean;
  placeholder?: boolean;
  onError?: () => void;
  classes?: {
    root: string;
    placeholder: string;
  };
}

const Image = (props: ImageProps) => {
  const [imgLoaded, setLoading] = useState(false);
  const {
    className,
    src,
    srcset,
    alt,
    height,
    width,
    isFullWidth,
    placeholder: placeholderImage = false,
    onError,
  } = props;

  // Requires Height and width for the Placeholder if we don't use any wrapper
  // check GifContainer to know how to use it with wrapper
  const handleImageLoad = () => {
    setLoading(true);
  };
  return (
    <>
      <ImageWrapper
        className={className}
        srcSet={srcset}
        src={src}
        height={height}
        width={(isFullWidth && '100%') || width}
        alt={alt}
        placeholderImage={placeholderImage}
        imgLoaded={imgLoaded}
        onLoad={handleImageLoad}
        onError={onError}
        data-testid={src}
        data-qa-id={src}
      />
      {placeholderImage && (
        <ImagePlaceholder
          data-testid="image placeholder"
          data-qa-id="img-placeholder"
          imgLoaded={imgLoaded}
          height={height}
          width={width}
        />
      )}
    </>
  );
};

export default Image;
