import {
  CriteriaRule,
  CriteriaField,
  CriteriaOperator,
} from '../../../interfaces/Flow/Builder';

export const getCriteriaFieldIcon = (criteriaField: CriteriaField) => {
  switch (criteriaField) {
    case 'department':
      return 'department';
    case 'email':
      return 'mail';
    case 'member':
      return 'person-filled';
    case 'manager':
      return 'person-filled';
    case 'directReport':
      return 'person-filled';
    case 'slack':
      return 'slack-dark';
    case 'workLocation':
      return 'building';
    case 'homeLocation':
      return 'home';
    case 'jobTitle':
      return 'job-title';
    case 'everyone':
    default:
      return 'assembly';
  }
};

export const getCriteriaCondition = (criteriaOperator: CriteriaOperator) => {
  if (criteriaOperator === 'isNot') {
    return 'is not';
  }

  if (criteriaOperator === 'of') {
    return 'to';
  }

  return 'is';
};

export const getCriteriaValue = (
  criteriaRule: CriteriaRule,
  defaultCriteriaValue: { id: string; value: string },
) => {
  if (criteriaRule.field === 'everyone') {
    return '';
  }

  return defaultCriteriaValue.value;
};
