import { useCallback } from 'react';
import Button from '../../atoms/Button';
import { SlackWorkspaceAndChannelsSelectorProps } from './types';
import { ConnectionState } from '../../../controllers/flows/FlowsShareSheetController/ConnectionsController/types';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';

const SlackWorkspaceStateButton = (
  props: SlackWorkspaceAndChannelsSelectorProps,
) => {
  const {
    state,
    onStateChange,
    connectionsList,
    selectedConnection,
    isDeletingConnection,
    onSelectedConnectionChange,
  } = props;

  const { setIsConnectionsTabTouched } = useFlowBuilderStore();

  const handleConnectClick = useCallback(() => {
    if (connectionsList.length > 0) {
      setIsConnectionsTabTouched(true);
      onStateChange(ConnectionState.ConnectionEditState);
      onSelectedConnectionChange([connectionsList[0]]);
    } else {
      onStateChange(ConnectionState.ConnectToSlackState);
    }
  }, [
    onStateChange,
    connectionsList,
    onSelectedConnectionChange,
    setIsConnectionsTabTouched,
  ]);

  const handleEditClick = useCallback(() => {
    onStateChange(ConnectionState.ConnectionEditState);
    setIsConnectionsTabTouched(true);
  }, [onStateChange, setIsConnectionsTabTouched]);

  if (isDeletingConnection) {
    return (
      <Button
        size="small"
        variant="text"
        color="primary"
        onClick={handleConnectClick}
      >
        Connect
      </Button>
    );
  }

  switch (state) {
    case ConnectionState.ConnectState:
      return (
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={handleConnectClick}
        >
          Connect
        </Button>
      );

    case ConnectionState.ConnectionDisplayState:
      return (
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={handleEditClick}
          disabled={
            connectionsList.length <= 0 || selectedConnection.length === 0
          }
        >
          Edit
        </Button>
      );

    default:
      return null;
  }
};

export default SlackWorkspaceStateButton;
