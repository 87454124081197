import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { FeedsContainer } from '../../../Utils/styles/display';
import { StyledActionBarWrapper } from '../MainFeedController/styles';

import { useProfileFeedController } from './useProfileFeedController';
import RecognitionFlowController from '../RecognitionFlowController';

import FlowsFeedPostLoader from '../../flows/FlowsFeedController/Loader';
import FlowPostController from '../../flows/FlowPostController';
import searching from '../../../img/searching.svg';
import useGetParsedFilterOptions from '../../../hooks/useGetParsedFilterOptions';
import { FLOW_FEED_STATES } from '../../../languages/en/flows/feed';
import { PostTypes } from '../../../interfaces/Home';
import { FeedContext } from '../../../Utils/flows/feeds';

import ActionBarContainer from '../../../atomic/molecules/ActionBarContainer';
import BottomOfFeedMessage from '../../../atomic/molecules/BottomOfFeedMessage';
import { FeedTypes } from '../../../atomic/molecules/ActionBar/types';
import EmptyErrorStateTemplate from '../../../atomic/molecules/EmptyErrorStateTemplate';
import { HeadingType } from '../../../atomic/molecules/EmptyErrorStateTemplate/interfaces';
import useNotebookPusher from '../../notebook/NotebookViewController/useNotebookPusher';
import useMobileApp from '../../../hooks/useMobileApp';
import useLayoutStore from '../../../stores/layoutStore';
import { useParams } from 'react-router-dom';

export const ProfileFeedController = () => {
  const { isMobileApp } = useMobileApp();
  const { setSelectedMember, setRightAsideOpenToTrue } = useLayoutStore();
  const { userId } = useParams<{ userId: string }>();
  const { isProfileFeedFilterApplied } = useGetParsedFilterOptions();

  const { models, operations } = useProfileFeedController();

  useEffect(() => {
    if (userId) {
      setSelectedMember({ memberId: userId });
      setRightAsideOpenToTrue();
    }
  }, [setSelectedMember, setRightAsideOpenToTrue, userId]);

  const {
    isLoading,
    hasNextPage,
    profileData,
    selectedProfileUser,
    isOwnProfileFeed,
    data,
    isRecognitionFlowModalOpen,
    profileDataForRecognitionModalDropdown,
    isEmbeddedInMainApp,
  } = models;

  const {
    refetch,
    handleFetchNextPage,
    toggleIsRecognitionFlowModalOpen,
    feedContextProps,
    handleEditPostClick,
    handleCelebrateTeammateClick,
    handlePostInteractionSettingsClick,
  } = operations;

  useNotebookPusher();

  const renderEmptyState = () => {
    if (isProfileFeedFilterApplied) {
      return (
        <EmptyErrorStateTemplate
          image={searching}
          variant={HeadingType.Bold}
          heading={FLOW_FEED_STATES.NO_MATCHING_POSTS.HEADING}
          subHeading={FLOW_FEED_STATES.NO_MATCHING_POSTS.SUB_HEADING}
        />
      );
    }
    return (
      <EmptyErrorStateTemplate
        image={searching}
        variant={HeadingType.Bold}
        heading={FLOW_FEED_STATES.NO_POSTS.HEADING}
        subHeading={FLOW_FEED_STATES.NO_POSTS.SUB_HEADING}
      />
    );
  };

  return (
    <FeedsContainer>
      {!isOwnProfileFeed &&
        selectedProfileUser &&
        profileData &&
        !isMobileApp && (
          <StyledActionBarWrapper>
            <ActionBarContainer
              feedType={FeedTypes.Flow}
              userId={profileData.member.memberId}
              onClick={handleCelebrateTeammateClick}
              avatar={profileData.member.profile?.image}
              userName={profileData.member.profile?.username}
              text={`🎉 Celebrate ${selectedProfileUser.profile.firstName} ${selectedProfileUser.profile.lastName}`}
            />
          </StyledActionBarWrapper>
        )}
      {isRecognitionFlowModalOpen && profileData && (
        <RecognitionFlowController
          profileInfo={profileData}
          onPostSuccess={refetch}
          selectedTeammateDropdownItems={profileDataForRecognitionModalDropdown}
          toggleIsRecognitionFlowModalOpen={toggleIsRecognitionFlowModalOpen}
        />
      )}
      {isLoading && <FlowsFeedPostLoader />}
      {data.length > 0 && (
        <InfiniteScroll
          next={handleFetchNextPage}
          dataLength={data.length}
          loader={<FlowsFeedPostLoader />}
          hasMore={Boolean(hasNextPage)}
          style={{ overflow: 'inherit' }}
          endMessage={<BottomOfFeedMessage />}
          scrollableTarget="scrollableFeedContent"
        >
          {
            <FeedContext.Provider value={feedContextProps}>
              {data.map((post, index) => (
                <FlowPostController
                  index={index}
                  canEndAnnouncement={Boolean(
                    post.flowResponse?.canEndAnnouncement,
                  )}
                  canEditAnnouncement={Boolean(
                    post.flowResponse?.canEditAnnouncement,
                  )}
                  canShareAsAnnouncement={Boolean(
                    post.flowResponse?.canShareAsAnnouncement,
                  )}
                  canViewAnnouncementInsights={Boolean(
                    post.flowResponse?.activeAnnouncement?.showInsights,
                  )}
                  key={post.postID}
                  post={post}
                  profileData={profileData}
                  showEditOption={
                    (post.type === PostTypes.RECOGNITION &&
                      post.from?.memberID === profileData?.member.memberId) ||
                    post.flowResponse?.canEdit
                  }
                  handleEditPostClick={handleEditPostClick}
                  hideReactions={post.flowResponse?.hideReactions}
                  hideRepliesBar={post.flowResponse?.hideReplies}
                  showPostInteractionSettings={
                    (isEmbeddedInMainApp &&
                      post.flowResponse?.showTurnOffSettings) ||
                    false
                  }
                  handlePostInteractionSettingsClick={() => {
                    return handlePostInteractionSettingsClick(
                      post.flowResponse,
                    );
                  }}
                />
              ))}
            </FeedContext.Provider>
          }
        </InfiniteScroll>
      )}
      {!isLoading && data.length === 0 && renderEmptyState()}
    </FeedsContainer>
  );
};
