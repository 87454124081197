import React, { useCallback } from 'react';
import { BaseEmoji, EmojiData } from 'emoji-mart';
import {
  BUILDER_RIGHT_DRAWER_SETTINGS,
  FLOW_NAME_MAX_LENGTH_ERROR,
} from '../../../languages/en/flows/builder';
import EmoticonHolder from '../../atoms/EmoticonHolder';
import Input from '../Input';
import {
  FlowEmoticonAndNameWrapper,
  StyledEmoticonPickerPopover,
  StyledFlowNameInput,
  StyledHeader,
  StyledToggleButtonStyle,
  TextAreaWrapper,
  ErrorText,
} from './styles';
import { FlowsNameAndEmoticonFormProps } from './type';
import ThemeV2 from '../../../theme';
import { PopoverChildrenProps } from '../../../hooks/usePopover/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { EmoticonHolderSizes } from '../../atoms/EmoticonHolder/interface';
import { DESCRIPTION, REQUIRED } from '../../../languages/en/singleWords';
import { BuilderFlowNameCharacterLimit } from '../../../constants/flowsBuilder';
import { getColorById } from '../../../Utils/flows/colors';

export const textFieldLabel = `${
  BUILDER_RIGHT_DRAWER_SETTINGS.NAME_YOUR_FLOW
} (${REQUIRED.toLowerCase()})`;

const FlowsNameAndEmoticonForm = (props: FlowsNameAndEmoticonFormProps) => {
  const {
    errorText,
    hasEmojiError,
    hasFlowNameError,
    className,
    flowId,
    flowName,
    onNameChange,
    onTitleBlur,
    description,
    onDescriptionChange,
    onDescriptionBlur,
    onEmoticonSelect,
    emoticon,
    disabled,
  } = props;

  const handleEmoticonSelect = useCallback(
    (selectedEmojiData: EmojiData) => {
      const currentEmoji = selectedEmojiData as BaseEmoji;
      if (currentEmoji.native) {
        onEmoticonSelect(currentEmoji);
      }
    },
    [onEmoticonSelect],
  );

  const maxCharExceedsError = flowName.length > BuilderFlowNameCharacterLimit;

  const emojiColorScheme = hasEmojiError
    ? ThemeV2.palette.dustRed6
    : ThemeV2.palette.gray6;

  const emoticonBg = getColorById(flowId);

  return (
    <>
      <FlowEmoticonAndNameWrapper className={className}>
        <StyledEmoticonPickerPopover onEmoticonSelect={handleEmoticonSelect}>
          {({ togglePopover }: PopoverChildrenProps) => (
            <StyledToggleButtonStyle
              onClick={togglePopover}
              size={ButtonSize.Large}
              isRounded={false}
              disabled={disabled}
            >
              {emoticon ? (
                <EmoticonHolder
                  emoticon={emoticon.native}
                  color={emoticonBg}
                  size={EmoticonHolderSizes.Medium2}
                  borderColor={ThemeV2.palette.gray6}
                />
              ) : (
                <StyledHeader
                  variant="h5"
                  weight="regular"
                  color={emojiColorScheme}
                  borderColor={emojiColorScheme}
                >
                  +
                </StyledHeader>
              )}
            </StyledToggleButtonStyle>
          )}
        </StyledEmoticonPickerPopover>
        <StyledFlowNameInput
          name="flow-name"
          type="textV3_text"
          placeholder={textFieldLabel}
          onChange={onNameChange}
          size="large"
          removeElevation
          value={flowName}
          label={textFieldLabel}
          error={maxCharExceedsError || hasFlowNameError}
          helperText={maxCharExceedsError ? FLOW_NAME_MAX_LENGTH_ERROR : ''}
          autoComplete="off"
          disabled={disabled}
          onBlur={onTitleBlur}
        />
      </FlowEmoticonAndNameWrapper>
      {/* maxCharExceedsError takes priority over all other errors */}
      {errorText && !maxCharExceedsError && (
        <ErrorText variant="body3">{errorText}</ErrorText>
      )}

      {
        <TextAreaWrapper>
          <Input
            value={description}
            name="flows-description"
            type="textV3_text"
            placeholder={BUILDER_RIGHT_DRAWER_SETTINGS.DESCRIPTION}
            onChange={onDescriptionChange}
            multiline
            removeElevation
            label={DESCRIPTION}
            disabled={disabled}
            onBlur={onDescriptionBlur}
          />
        </TextAreaWrapper>
      }
    </>
  );
};

export default FlowsNameAndEmoticonForm;
