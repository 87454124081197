import { parse } from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import { trackRightDrawerEvent } from '../../../Utils/analytics';
import { RIGHT_DRAWER_EVENTS } from '../../../Utils/analytics/constants';
import { DrawerTabType } from '../../../Utils/analytics/interfaces';
import { TourStatusEnum } from '../../../Utils/featureIntros/types';
import { isMobilePlatform } from '../../../Utils/window';
import {
  FILE_PREVIEW,
  NEW_FLOWS_BUILDER,
  NOTEBOOK,
  V2_REWARDS,
  V2_SETTINGS,
} from '../../../constants/routes';
import useArchiveSocket from '../../../hooks/useArchiveSocket';
import useChats from '../../../hooks/useChats';
import useGetPageName from '../../../hooks/useGetPageName';
import useRepliesSidebar from '../../../hooks/useRepliesSidebar';
import useToggleInviteModal from '../../../hooks/useToggleInviteModal';
import { PageType } from '../../../interfaces/Feed';
import { LayoutStoreState } from '../../../interfaces/Layout';
import {
  MiscTaskCategories,
  TaskCategories,
} from '../../../interfaces/notebook';
import { useFeatureIntroQuery } from '../../../queries/FeatureIntros';
import { useFetchFlowDetailsQuery } from '../../../queries/Flows/Feed';
import { useProfileInfoFetchQuery } from '../../../queries/Profile';
import { useFeatureIntrosStore } from '../../../stores/featureIntrosStore';
import { setFeatureIntrosSelector } from '../../../stores/featureIntrosStore/selectors';
import useFlowBuilderStore from '../../../stores/flowBuilderStore';
import { flowNameSelector } from '../../../stores/flowBuilderStore/selectors';
import useLayoutStore from '../../../stores/layoutStore';
import useModalsStore from '../../../stores/modalsStore';
import {
  modalsStoreSelector,
  setParticipationFlowSelector,
} from '../../../stores/modalsStore/selectors';
import useNotebookStore from '../../../stores/notebookStore';
import { NotebookStore } from '../../../stores/notebookStore/types';
import { useAddNewFlowCallback } from '../../flows/FlowsActiveListController/useAddNewFlowCallback';
import useChatSocket from '../../rightDrawer/ChatController/useChatSocket';
import { authStore } from '../../../stores/authStore';
import useShareSheetSocket from '../../../hooks/useShareSheetSocket';

const layoutStoreSelector = (state: LayoutStoreState) => ({
  isRightAsideOpen: state.isRightAsideOpen,
  setTaskIdForNotebook: state.setTaskIdForNotebook,
  isEmbeddedInMainApp: state.isEmbeddedInMainApp,
});

const notebookPageSelector = (store: NotebookStore) => ({
  isOpen: store.isOpen,
  setModalData: store.setModalData,
  modalProps: store.modalProps,
  hasCheckbox: store.hasCheckbox,
  currentTaskData: store.currentTaskData,
  callback: store.callback,
});

const usePrimaryLayoutController = () => {
  useChats();
  useChatSocket();
  useRepliesSidebar();
  const { page } = useGetPageName();
  const { setCreatingFlowForTheFirstTime } = authStore();

  const flowName = useFlowBuilderStore(flowNameSelector);
  const setFeatureIntros = useFeatureIntrosStore(setFeatureIntrosSelector);
  const featureIntroData = useFeatureIntroQuery(
    // eslint-disable-next-line max-len
    'ACCESS_POLICY_PARTICIPANT_VIEWER,ACCESS_POLICY_SHARE_BUTTON,FLOWS_FEED',
  );
  const featureIntroDataData = featureIntroData?.data?.data;
  if (featureIntroDataData) {
    featureIntroDataData.data.forEach((data) => {
      const featureStatus: Record<string, TourStatusEnum> = {};
      featureStatus[data.name] = data.status;
      setFeatureIntros(featureStatus);
    });
  }

  const location = useLocation();
  const history = useHistory();
  const parsedFlowId = useMemo(
    () =>
      (location.pathname.includes('/flows/') &&
        location.pathname.split('/')[3]) ||
      '',
    [location.pathname],
  );

  const parsedParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const participationFlowId =
    (parsedParams && (parsedParams.participationFlowId as string)) || undefined;
  const occurrenceId =
    (parsedParams && (parsedParams.occurrenceId as string)) || undefined;
  const setParticipationFlow = useModalsStore(setParticipationFlowSelector);
  const { participationFlow, isCreateFlowModalOpen } = useModalsStore(
    modalsStoreSelector,
    shallow,
  );
  const { search } = useLocation();
  const selectedTaskId = useMemo(() => {
    const query = new URLSearchParams(search);
    return query.get('taskId') || '';
  }, [search]);

  const { isRightAsideOpen, setTaskIdForNotebook, isEmbeddedInMainApp } =
    useLayoutStore(layoutStoreSelector, shallow);

  const {
    isOpen,
    modalProps,
    setModalData,
    hasCheckbox,
    currentTaskData,
    callback,
  } = useNotebookStore(notebookPageSelector, shallow);

  const hasDropdown = useMemo(
    () =>
      currentTaskData?.updatedCategory === MiscTaskCategories.UNARCHIVE ||
      currentTaskData?.updatedCategory === MiscTaskCategories.INCOMPLETE,
    [currentTaskData?.updatedCategory],
  );

  const onPrimaryButtonClick = useCallback(
    (currentSelection?: string) => {
      callback(currentSelection as TaskCategories);
      setModalData(false, undefined, undefined, () => {});
    },
    [callback, setModalData],
  );

  const onSecondaryButtonClick = useCallback(() => {
    if (currentTaskData?.callback) {
      currentTaskData.callback(true);
    }
  }, [currentTaskData]);

  const toggleNotebookRightDrawer = useCallback(() => {
    setTaskIdForNotebook(isRightAsideOpen ? '' : selectedTaskId);
  }, [isRightAsideOpen, selectedTaskId, setTaskIdForNotebook]);

  const secondaryDrawerToggleHandler = (isDrawerOpen: boolean) => {
    let drawerTabViewed: DrawerTabType = 'people';
    if (page === 'flow' || page === 'profile') {
      drawerTabViewed = 'about';
    }
    if (page === 'single post recognition') {
      drawerTabViewed = 'leaderboards';
    } else if (page === 'single post flows') {
      drawerTabViewed = 'people';
    } else if (page === 'recognition') {
      drawerTabViewed = 'leaderboards';
    }

    let feedViewed: PageType = page;
    if (page === 'single post flows') {
      feedViewed = 'single post';
    } else if (page === 'single post recognition') {
      feedViewed = 'single post';
    } else if (page === 'recognition') {
      feedViewed = 'flow';
    } else {
      feedViewed = page;
    }

    if (page === 'notebook') {
      toggleNotebookRightDrawer();
    }
    if (isDrawerOpen) {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.RIGHT_DRAWER_COLLAPSED, {
        feedViewed,
        drawerTabViewed,
        flowName,
      });
    } else {
      trackRightDrawerEvent(RIGHT_DRAWER_EVENTS.RIGHT_DRAWER_EXPANDED, {
        feedViewed,
        drawerTabViewed,
        flowName,
      });
    }
  };

  useEffect(() => {
    if (location.search.includes('flowsOnboardingFirstTime=true')) {
      setCreatingFlowForTheFirstTime(true);
    }
  }, [location.search]);

  useEffect(() => {
    if (participationFlowId) {
      window.scrollTo(0, 0);
      setParticipationFlow({
        participationFlowId,
        occurrenceId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participationFlowId]);

  const isNotebookView = useMemo(() => page === 'notebook', [page]);

  const { data: profileData } = useProfileInfoFetchQuery();

  useArchiveSocket();
  useShareSheetSocket();

  const flowID = useMemo(() => {
    if (participationFlow) {
      return participationFlow.participationFlowId;
    }
    if (parsedFlowId) {
      if (parsedFlowId !== 'recognition') {
        return parsedFlowId;
      }
    }
    return '';
  }, [parsedFlowId, participationFlow]);

  // Note - Surender: Handling responded shortcut flow scenario
  // this code can be improved in future
  const { data } = useFetchFlowDetailsQuery(flowID, 'builder');

  const flowDetailsData = data?.data;

  const isFlowCollaborator = flowDetailsData?.collaborators?.some(
    (collaborator) => collaborator.memberID === profileData?.member.memberId,
  );

  const isFlowOwner =
    (flowDetailsData?.owner.memberID === profileData?.member.memberId ||
      isFlowCollaborator ||
      (flowDetailsData?.owner.memberID === undefined &&
        location.pathname.includes(NEW_FLOWS_BUILDER))) &&
    !isMobilePlatform;

  const hasTabComponent =
    isFlowOwner ||
    location.pathname.includes(NOTEBOOK) ||
    location.pathname.includes(NEW_FLOWS_BUILDER) ||
    location.pathname.includes(V2_REWARDS) ||
    location.pathname.includes(V2_SETTINGS) ||
    location.pathname.includes(FILE_PREVIEW);

  //Template Modal from URL Param
  const canOpenTemplateModal = search.includes('showTemplate=true');

  const openTemplatesModal = useAddNewFlowCallback();

  useEffect(() => {
    if (canOpenTemplateModal) {
      openTemplatesModal();
    }
  }, [canOpenTemplateModal, openTemplatesModal]);

  //Invite Modal from URL Param
  const params = new URLSearchParams(search);
  const canOpenInviteModal = search.includes('showInvite=true');
  const { isModalOpen: isInviteModalOpen, toggleInviteModal } =
    useToggleInviteModal();
  const handleInviteModalCloseClick = () => {
    if (isInviteModalOpen) {
      params.delete('showInvite');
      history.replace({
        search: params.toString(),
      });
    }
    toggleInviteModal();
  };
  useEffect(() => {
    if (canOpenInviteModal) {
      toggleInviteModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canOpenInviteModal]);

  //Feedback Survey Modal
  const showFeedbackSurveyModal = search.includes(
    'showFeedbackSurveyModal=true',
  );
  const [isFeedbackSurveyModalOpen, setFeedbackSurveyModalOpen] = useState(
    showFeedbackSurveyModal,
  );
  const handleFeedbackSurveyModalCloseClick = () => {
    params.delete('showFeedbackSurveyModal');
    history.replace({
      search: params.toString(),
    });
    setFeedbackSurveyModalOpen(false);
  };

  return {
    models: {
      isOpen,
      hasCheckbox,
      hasDropdown,
      isFlowOwner,
      modalProps,
      isNotebookView,
      hasTabComponent,
      participationFlow,
      isInviteModalOpen,
      isCreateFlowModalOpen,
      onSecondaryDrawerToggle: secondaryDrawerToggleHandler,
      isEmbeddedInMainApp,
      isFeedbackSurveyModalOpen,
    },
    operations: {
      setModalData,
      onPrimaryButtonClick,
      onSecondaryButtonClick,
      handleInviteModalCloseClick,
      handleFeedbackSurveyModalCloseClick,
    },
  };
};

export default usePrimaryLayoutController;
