import { useCallback, useEffect, useMemo, useState } from 'react';

import { AxiosError } from 'axios';
import {
  useDeactivateMember,
  useUserProfilePictureMutation,
  useUserSettingsMutation,
} from '../../queries/Settings';

import { UserInfo } from '../../interfaces/Settings/index';
import {
  GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
  SETTINGS_UPDATED,
  UPDATING_SETTINGS,
} from '../../languages/en/settings';
import { useProfileInfoFetchQuery } from '../../queries/Profile';
import { getMonthByNumber } from '../../Utils/date';
import { getErrorMessage } from '../../Utils/message';
import {
  dismissAllToasts,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from '../../Utils/toast';
import isEmpty from 'lodash/isEmpty';
import {
  useDeactivateZapierApiKey,
  useFetchExternalAuthKey,
  useGenerateExternalAuthKey,
} from '../../queries/Integrations';

const useSettingsControllerModel = () => {
  const {
    isError,
    data: profileData,
    isInitialLoading: isCurrentUserInfoLoading,
  } = useProfileInfoFetchQuery();

  const [authKey, setAuthKey] = useState<string>('');

  const onGenerateAPIKeySuccess = useCallback((generatedAuthKey: string) => {
    setAuthKey(generatedAuthKey);
  }, []);

  const onDeactivateAPISuccess = useCallback(() => {
    setAuthKey('');
  }, []);

  const {
    data: externalAuthKeyData,
    isLoading: isFetchExternalAuthKeyLoading,
  } = useFetchExternalAuthKey();

  const {
    mutate: generateExternalAuthKey,
    isLoading: isGenerateExternalAuthKeyLoading,
  } = useGenerateExternalAuthKey(onGenerateAPIKeySuccess);

  const {
    mutate: deactivateZapierApiKey,
    isLoading: isDeactivatingZapierApiKey,
  } = useDeactivateZapierApiKey(onDeactivateAPISuccess);

  const onGenerateAPIKeyClick = useCallback(() => {
    generateExternalAuthKey();
  }, [generateExternalAuthKey]);

  const onDeactivateAPIClick = useCallback(() => {
    deactivateZapierApiKey();
  }, [deactivateZapierApiKey]);

  useEffect(() => {
    if (externalAuthKeyData?.authKey) {
      setAuthKey(externalAuthKeyData.authKey);
    }
  }, [externalAuthKeyData]);

  const {
    mutate: updateUserSettings,
    error: updateUserSettingsError,
    isError: isUpdateUserSettingsError,
    isSuccess: isUpdateUserSettingsSuccess,
    isLoading: isUpdateUserSettingsLoading,
  } = useUserSettingsMutation();

  const {
    isError: isProfilePictureUploadError,
    mutate: updateProfilePictureSettings,
    isLoading: isProfilePictureUploading,
    error: updateProfilePictureUploadError,
    isSuccess: isProfilePictureUploadSuccess,
  } = useUserProfilePictureMutation();

  const {
    mutate: deactivateMember,
    data: deactivatedMemberData,
    isLoading: isDeactivatingMember,
    error: deactivatedMemberErrorData,
    isError: isDeactivateMemberFailed,
    isSuccess: isDeactivateMemberSuccess,
  } = useDeactivateMember();

  const deactivatedMemberProps = {
    isDeactivateMemberSuccess,
    isDeactivateMemberFailed,
    deactivatedMemberData,
    deactivatedMemberErrorData,
    isDeactivatingMember,
  };

  const currentMember = profileData?.member;
  const workSpaceName = profileData?.assembly.name || '';

  const currentUserInfo: UserInfo = useMemo(() => {
    return {
      _id: currentMember?.memberId,
      email: currentMember?.email,
      handle: currentMember?.profile.username,
      fullName: `${currentMember?.profile.firstName} ${currentMember?.profile.lastName}`,
      firstName: currentMember?.profile.firstName,
      lastName: currentMember?.profile.lastName,
      jobTitle: currentMember?.profile.jobTitle,
      location: currentMember?.profile.location,
      workLocation: currentMember?.profile.workLocation,
      department: currentMember?.profile.department,
      managers: currentMember?.managerIds,
      reports: currentMember?.reportIds,
      image: currentMember?.profile.image,
      birthday: {
        day: currentMember?.profile.birthday.day,
        month: currentMember?.profile.birthday.month,
        isVisibleAndCelebrated:
          currentMember?.profile.birthday.isVisibleAndCelebrated,
      },
      startDate: {
        joiningDay: currentMember?.profile.hiredday.day,
        joiningMonth: getMonthByNumber(
          currentMember?.profile.hiredday.month || 0,
        ),
        joiningYear: currentMember?.profile.hiredday.year,
      },
      timeZone: currentMember?.timeZone,
      pronouns: currentMember?.profile.pronouns,
      aboutMe: currentMember?.profile.aboutMe,
      socialProfiles: currentMember?.profile.socialProfiles,
    };
  }, [currentMember]);

  useEffect(() => {
    if (isUpdateUserSettingsLoading || isProfilePictureUploading) {
      showInfoMessage(UPDATING_SETTINGS);
    }
    if (isUpdateUserSettingsSuccess || isProfilePictureUploadSuccess) {
      dismissAllToasts();
      showSuccessMessage(SETTINGS_UPDATED);
    }
    if (isUpdateUserSettingsError) {
      dismissAllToasts();
      const errorMessage = getErrorMessage(
        updateUserSettingsError as AxiosError<{
          message: string;
          body: string;
        }>,
        GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
      );
      showErrorMessage(errorMessage || GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE);
    }
    if (isProfilePictureUploadError) {
      dismissAllToasts();
      const errorMessage = getErrorMessage(
        updateProfilePictureUploadError as AxiosError<{
          message: string;
          body: string;
        }>,
        GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE,
      );
      showErrorMessage(errorMessage || GENERAL_SETTINGS_UPDATE_ERROR_MESSAGE);
    }
  }, [
    isProfilePictureUploadError,
    isUpdateUserSettingsError,
    isUpdateUserSettingsLoading,
    isUpdateUserSettingsSuccess,
    updateUserSettingsError,
    isProfilePictureUploadSuccess,
    isProfilePictureUploading,
    updateProfilePictureUploadError,
  ]);

  const getCanShowSaveChangesButton = () => {
    if (profileData) {
      const { hiredday: startDate } = profileData.member.profile;
      return isEmpty(startDate);
    }
    return false;
  };

  return {
    models: {
      workSpaceName,
      currentUserInfo,
      deactivatedMemberProps,
      isDeactivatingZapierApiKey,
      canShowSaveChangesButton: getCanShowSaveChangesButton(),
      authKey,
      isExternalAuthKeyLoading:
        isFetchExternalAuthKeyLoading || isGenerateExternalAuthKeyLoading,
    },
    operations: {
      deactivateMember,
      updateUserSettings,
      updateProfilePictureSettings,
      onGenerateAPIKeyClick,
      onDeactivateAPIClick,
    },
    metadata: {
      isUpdateUserSettingsLoading,
      isUpdateUserSettingsError,
      isUpdateUserSettingsSuccess,
      isError,
      isCurrentUserInfoLoading: isCurrentUserInfoLoading,
    },
  };
};

export default useSettingsControllerModel;
