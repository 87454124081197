import sortBy from 'lodash/sortBy';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { FlowState } from '../../../Utils/analytics/interfaces';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import { StyledPrefixIcon } from '../../../atomic/organism/LeftNav/styles';
import FlowsMenuAccordion from '../../../atomic/organism/MenuAccordion/FlowsMenuAccordion';
import { ComponentStatus } from '../../../interfaces/component';
import { UNREAD_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE } from '../../../languages/en/flows';
import { LeftNavHorizontalRule } from '../../layout/PrimaryNavController/styles';
import useUnreadFlowsController from './useUnreadFlowsController';

const UnreadFlowsController = ({
  isLeftAsideOpen,
  isLeftNavHovered,
}: {
  isLeftAsideOpen: boolean;
  isLeftNavHovered: boolean;
}) => {
  const {
    models: {
      isOpen,
      unReadChats,
      dropdownMenuItems,
      menuItems,
      totalNotificationsCount,
      isSuccess,
    },
    operations: {
      handleAccordionClick,
      handleMenuItemClick,
      handleOnUserMenuItemClick,
      handleMenuItemOptionsSelect,
      onDropdownMenuItemRightClick,
    },
  } = useUnreadFlowsController();

  const getFlowItemOnMenuClick = (id: string) =>
    menuItems.find((flow) => flow.id === id);

  if (isSuccess && (menuItems.length || unReadChats?.length)) {
    return (
      <>
        <FlowsMenuAccordion
          status={ComponentStatus.LOADED}
          isAccordionOpen={isOpen}
          onUserMenuItemClick={handleOnUserMenuItemClick}
          accordionTitle={UNREAD_FLOWS_LEFT_DRAWER_NAVIGATION_TITLE}
          onAccordionClick={handleAccordionClick}
          navItems={menuItems}
          userMenuItems={sortBy(unReadChats, 'memberId')}
          onMenuItemClick={(id) => {
            handleMenuItemClick(id as string);
            const flowItem = getFlowItemOnMenuClick(id as string);
            trackLeftDrawerActionEvent({
              action:
                LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_FLOW_CLICKED,
              ...flowItem,
              flowState: FlowState.UnreadResponses,
              folder: flowItem?.folderInfo?.folderId ? 'customFolder' : 'flows',
            });
          }}
          dropdownMenuItems={dropdownMenuItems}
          onDropdownMenuItemClick={handleMenuItemOptionsSelect}
          onMenuItemRightClick={onDropdownMenuItemRightClick}
          isAddNewFlowOn={false}
          totalNotificationsCount={totalNotificationsCount}
          isHover={isLeftNavHovered || isLeftAsideOpen}
          canAddFlows={false}
          headerPrefixComponent={
            <StyledPrefixIcon icon="unreads" size="24px" />
          }
          headerSuffixComponent={null}
        />
        <LeftNavHorizontalRule />
      </>
    );
  }

  return null;
};

export default UnreadFlowsController;
