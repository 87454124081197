import { FlowReactionsProps } from '../../../../interfaces/Feed';
import { ComponentStatus } from '../../../../interfaces/component';
import Theme from '../../../../theme';
import { AvatarProps } from '../../../atoms/Avatar/interfaces';
import Divider from '../../../atoms/Divider';
import FeedPostReactions from '../../FeedPostReactions';
import FlowRepliesBar from '../../FlowRepliesBar';
import { FlowRepliesBarProps } from '../../FlowRepliesBar/types';
import { FlowRepliesBarWrapper } from '../styles';
import { NewFlowFeedPostProps } from '../types';
import FlowPostFooterHiddenState from './FlowPostFooterHiddenState';

export type FlowPostFooterProps = {
  hideReplies: boolean;
  currentUserId: string;
  hideReactions: boolean;
  avatarProps: AvatarProps;
  handleOnRepliesClick: () => void;
  reactionBarProps: FlowReactionsProps;
  repliesBarProps: FlowRepliesBarProps;
  variant: NewFlowFeedPostProps['variant'];
  hideBorder: NewFlowFeedPostProps['hideBorder'];
  onReactionSet: NewFlowFeedPostProps['onReactionSet'];
  onReactionUnSet: NewFlowFeedPostProps['onReactionUnSet'];
  feedItemMetaData: NewFlowFeedPostProps['feedItemMetaData'];
  hideInteractions: NewFlowFeedPostProps['hideInteractions'];
};

const FlowPostFooter = (props: FlowPostFooterProps) => {
  const {
    variant,
    hideBorder,
    hideReplies,
    avatarProps,
    onReactionSet,
    currentUserId,
    hideReactions,
    repliesBarProps,
    onReactionUnSet,
    hideInteractions,
    reactionBarProps,
    feedItemMetaData,
    handleOnRepliesClick,
  } = props;

  if (hideReplies && hideReactions && variant !== 'sidebar')
    return (
      <div>
        <Divider isFullWidth color={Theme.palette.gray4} marginBottom="8px" />
        <FlowPostFooterHiddenState
          avatarProps={avatarProps}
          text="Replies and reactions have been turned off"
        />
      </div>
    );

  return (
    <div>
      {!hideReactions && (
        <FeedPostReactions
          {...reactionBarProps}
          variant={variant}
          hideBorder={hideBorder}
          currentUserId={currentUserId}
          onEmoticonSelect={onReactionSet}
          feedItemMetaData={feedItemMetaData}
          hideInteractions={hideInteractions}
          onReactionToggleClick={onReactionUnSet}
        />
      )}
      {!hideReplies
        ? (feedItemMetaData.flowId ||
            feedItemMetaData.postId ||
            feedItemMetaData.responseId) &&
          repliesBarProps.status === ComponentStatus.LOADED && (
            <FlowRepliesBarWrapper
              data-test-id={`replies-bar`}
              data-qa-id={`btn-post-reply`}
              onClick={handleOnRepliesClick}
            >
              <FlowRepliesBar {...repliesBarProps} />
            </FlowRepliesBarWrapper>
          )
        : variant !== 'sidebar' && (
            <FlowPostFooterHiddenState
              avatarProps={avatarProps}
              text="Replies have been turned off"
            />
          )}
    </div>
  );
};

export default FlowPostFooter;
