import React, { useCallback, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UNASSIGNED_MEMBER_ID } from '../../../../constants/notebook';
import useOnClickOutside from '../../../../hooks/useOnOutsideClick';
import { Assignee } from '../../../../interfaces/notebook';
import { ASSIGNEE, ERROR } from '../../../../languages/en/singleWords';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import {
  Card,
  StyledPopover,
  PopoverContentWrapper,
  InfiniteScrollWrapper,
  UserAvatar,
  UnassignedIcon,
  AssigneeName,
  SelectionIndicator,
  AssigneeLabel,
  TaskItemAssigneeSelectorWrapper,
  Search,
  NoAssigneesFoundLabel,
  Placeholder,
  DropdownSearch,
} from './styles';
import ThemeV2 from '../../../../theme';
import { Flex } from '../../../../Utils/styles/display';
import {
  ASSIGNEE_ME,
  NO_ASSIGNEES_FOUND,
} from '../../../../languages/en/notebook';
import debounce from 'lodash/debounce';
import SVGIcon from '../../../atoms/SVGIcon';
import { MemberState } from '../../../../interfaces/user';
import { useProfileInfoFetchQuery } from '../../../../queries/Profile';
import DropdownFooter from '../../../molecules/DropdownFooter';
import { NotebookAssigneeLoader } from '../../../molecules/Notebook/Loader';
import TaskItemAssignee from '../../../molecules/Notebook/TaskItem/TaskItemAssignee';
import { canInviteMembers } from '../../../../Utils/permissions';

type TaskItemAssigneeSelectorProps = {
  id: string;
  isLoading: boolean;
  assigneeList: Assignee[];
  isError?: boolean;
  hasMoreUsersToFetch: boolean;
  fetchMoreMembers: () => void;
  canPopoverTriggerByClick: boolean;
  onMemberClick: (user: Assignee) => void;
  onPopOverOpen?: () => void;
  onPopOverClose?: () => void;
  position?: boolean;
  assignedTo: Assignee;
  searchValue: string;
  handleSearchOnAssigneeList: (searchValue: string) => void;
  setTaskIdOfFocusedTask: (taskId: string, isDelete?: boolean) => void;
  noPadding?: boolean;
  currentUserId: string;
  showSearchInDropdown?: boolean;
  hideName?: boolean;
};

const TaskItemAssigneeSelector = (props: TaskItemAssigneeSelectorProps) => {
  const {
    isError,
    id,
    isLoading,
    hasMoreUsersToFetch,
    canPopoverTriggerByClick,
    assigneeList,
    assignedTo,
    currentUserId,
    onMemberClick,
    fetchMoreMembers,
    setTaskIdOfFocusedTask,
    handleSearchOnAssigneeList,
    noPadding = false,
    hideName = false,
    showSearchInDropdown = true,
  } = props;

  const popoverAnchor = useRef(null);
  const popoverComponent = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);
  const [isFocused, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const handlePopoverClick = useCallback(() => {
    if (canPopoverTriggerByClick && !openedPopover) {
      setOpenedPopover(true);
    }
    if (!isFocused) setFocus(true);
  }, [canPopoverTriggerByClick, isFocused, openedPopover]);

  const handleKeypress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setOpenedPopover(true);
    }
  };

  function handleDebounceFn(inputValue: string) {
    handleSearchOnAssigneeList(inputValue);
  }

  const debounceFunction = useMemo(() => debounce(handleDebounceFn, 1000), []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    debounceFunction(event.target.value);
  }

  const handlePopoverOutsideClick = () => {
    setFocus(false);
    setTaskIdOfFocusedTask('', true);
    handleSearchOnAssigneeList('');
    setOpenedPopover(false);
    setValue('');
  };

  const handleAssigneeSelection = (user: Assignee) => {
    onMemberClick(user);
    setOpenedPopover(false);
    setFocus(false);
    setTaskIdOfFocusedTask('', true);
    handleSearchOnAssigneeList('');
  };

  useOnClickOutside(popoverComponent, handlePopoverOutsideClick);

  const { data: profileInfo } = useProfileInfoFetchQuery();

  const canInviteUsersFromTaskAssignment = canInviteMembers(
    profileInfo?.member,
    profileInfo?.assembly.settings,
    true,
  );

  return (
    <TaskItemAssigneeSelectorWrapper
      noPadding={noPadding}
      onClick={handlePopoverClick}
      noBackground={hideName}
    >
      {!hideName && (
        <AssigneeLabel variant="body2">{`${capitalizeFirstLetter(
          ASSIGNEE,
        )}:`}</AssigneeLabel>
      )}
      <div
        role="link"
        ref={popoverAnchor}
        aria-haspopup="true"
        onKeyPress={handleKeypress}
        tabIndex={0}
      >
        <Flex padding="0 8px 0 0">
          <TaskItemAssignee
            currentUserId={currentUserId}
            assignedTo={assignedTo}
          />
          {!showSearchInDropdown &&
            (isFocused ? (
              <Search
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                autoFocus
                size="small"
                removeElevation
                padding="0px"
              />
            ) : (
              <Placeholder />
            ))}
        </Flex>
      </div>
      <StyledPopover
        ref={popoverComponent}
        id={`assignee-popover-${id}`}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        data-testid="user-dropdown-popover"
        placement="bottom-start"
      >
        <PopoverContentWrapper>
          <InfiniteScrollWrapper
            id={id}
            data-testid="assignee-div"
            $hasPadding={!!canInviteUsersFromTaskAssignment}
          >
            {showSearchInDropdown && (
              <DropdownSearch
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                }}
                autoFocus
                size="small"
                removeElevation
                prefix={
                  <SVGIcon
                    icon="person-filled"
                    color={ThemeV2.palette.geekBlue1}
                    size="16px"
                  />
                }
              />
            )}
            {assigneeList && (
              <InfiniteScroll
                dataLength={assigneeList?.length}
                hasMore={hasMoreUsersToFetch}
                loader={<></>}
                next={fetchMoreMembers}
                scrollableTarget={id}
              >
                {assigneeList?.length > 0 &&
                  assigneeList.map((user) => {
                    return user?.emailToInvite ? (
                      <Card
                        onClick={() => handleAssigneeSelection(user)}
                        key={user?.memberID}
                      >
                        <UnassignedIcon icon="envelope" size="24px" />
                        <AssigneeName
                          variant="body2"
                          color={`${
                            assignedTo?.memberID === user?.memberID
                              ? 'geekBlue6'
                              : 'gray8'
                          }`}
                          className="assignee-name"
                        >
                          {user.name}
                        </AssigneeName>
                      </Card>
                    ) : (
                      <Card
                        onClick={() => handleAssigneeSelection(user)}
                        key={user?.memberID}
                      >
                        {user?.memberID !== UNASSIGNED_MEMBER_ID ? (
                          <UserAvatar
                            img={user?.image}
                            name={user?.name}
                            userId={`${user?.memberID}`}
                            size="24px"
                            icon={
                              user?.memberState === MemberState.PENDING
                                ? 'pending-person'
                                : ''
                            }
                          />
                        ) : (
                          <UnassignedIcon icon="unassigned" size="24px" />
                        )}
                        <AssigneeName
                          variant="body2"
                          color={`${
                            assignedTo?.memberID === user?.memberID
                              ? 'geekBlue6'
                              : 'gray8'
                          }`}
                          className="assignee-name"
                        >{`${capitalizeFirstLetter(user?.name)} ${
                          currentUserId === user?.memberID ? ASSIGNEE_ME : ''
                        }
                      `}</AssigneeName>
                        {assignedTo?.memberID === user?.memberID && (
                          <SelectionIndicator
                            icon="check-rounded"
                            size="16px"
                            data-testid="selectedIcon"
                            color={ThemeV2.palette.geekBlue6}
                          />
                        )}
                      </Card>
                    );
                  })}
              </InfiniteScroll>
            )}
            {!isLoading && assigneeList?.length === 0 && (
              <NoAssigneesFoundLabel variant="body2">
                {NO_ASSIGNEES_FOUND}
              </NoAssigneesFoundLabel>
            )}
            {isLoading && <NotebookAssigneeLoader />}
            {isError && <div>{ERROR}</div>}
            {canInviteUsersFromTaskAssignment && (
              <DropdownFooter message="Type in an email to invite a new teammate" />
            )}
          </InfiniteScrollWrapper>
        </PopoverContentWrapper>
      </StyledPopover>
    </TaskItemAssigneeSelectorWrapper>
  );
};

export default TaskItemAssigneeSelector;
