import React, { ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import ThemeV2 from '../../../theme';

const useStyles = makeStyles({
  root: () => ({
    fontSize: '14px',
    lineHeight: '22px',
    color: ThemeV2.palette.gray9,
    marginBottom: '4px',
    boxSizing: 'border-box',
  }),
});

type LabelProps = {
  id: string;
  className?: string;
  children: ReactNode;
};

const Label = (props: LabelProps) => {
  const { id, className: parentClassName, children } = props;
  const classes = useStyles({});
  const className = `${classes.root} ${parentClassName}`;
  return (
    <InputLabel htmlFor={id} className={className}>
      {children}
    </InputLabel>
  );
};

export default Label;
