import { trackEvent } from '../index';
import { FileViewerEventProps } from '../interfaces';
import { AnalyticsEvents, FILE_PREVIEWER_EVENT } from '../constants';

export const trackFileViewerShowEvent = (eventProps: FileViewerEventProps) => {
  trackEvent(FILE_PREVIEWER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.SHOW,
  });
};

export const trackFileViewerActionEvent = (
  eventProps: FileViewerEventProps,
) => {
  trackEvent(FILE_PREVIEWER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ACTION,
  });
};

export const trackFileViewerErrorEvent = (eventProps: FileViewerEventProps) => {
  trackEvent(FILE_PREVIEWER_EVENT, undefined, {
    ...eventProps,
    event: AnalyticsEvents.ERROR,
  });
};
