import { RRule } from 'rrule';

import { TriggerBuilderBlockData } from '../../../../interfaces/Flow/Builder';

// to get the default scheduleEndValue
export const getDefaultScheduleEndValue = (
  triggerBlockData: TriggerBuilderBlockData,
) => {
  if (
    triggerBlockData.triggerType === 'SCHEDULED' &&
    triggerBlockData.schedule
  ) {
    const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
    if (parsedString.until) {
      return 'ON';
    }
  }
  return 'MANUAL';
};

// to get the default value for disableScheduleEndDatePicker
export const getDefaultDisableScheduleEndDatePicker = (
  triggerBlockData: TriggerBuilderBlockData,
) => {
  if (
    triggerBlockData.triggerType === 'SCHEDULED' &&
    triggerBlockData.schedule
  ) {
    const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
    if (parsedString.until) {
      return false;
    }
  }
  return true;
};
