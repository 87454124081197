import styled from 'styled-components';

import ThemeV2 from '../../../theme';
import ReactionsBar from '../ReactionsBar';
import FeedPostHeaderOption from '../FeedPostHeaderOption';
import { ReactionBarProps } from '../ReactionsBar/interface';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import { ToggleButtonVariant } from '../../atoms/ToggleButton/interface';
import { FeedPostHeaderOptionProps } from '../FeedPostHeaderOption/interface';
import { FeedTypes } from '../../../interfaces/Home';
import { DropdownPlacement } from '../../molecules/Dropdown_V2/interfaces';

export type CommentActionsProps = ReactionBarProps &
  FeedPostHeaderOptionProps & {
    canShowReactions: boolean;
    dropdownPlacement?: DropdownPlacement;
  };

const StyledReactionsBar = styled(ReactionsBar)`
  button {
    padding-left: 4px;
    padding-right: 4px;
  }

  &:hover {
    svg {
      border-radius: 4px;
      background: ${ThemeV2.palette.geekBlue1};
    }
  }
`;

const StyledFeedPostHeaderOption = styled(FeedPostHeaderOption)`
  svg {
    height: 18px;
  }

  button {
    background: transparent;
    padding: 4px 0 0 0;
  }

  &:hover {
    button {
      background: transparent !important;

      svg {
        border-radius: 4px;
        background: ${ThemeV2.palette.geekBlue1};
      }
    }
  }
`;

const CommentActions = (props: CommentActionsProps) => {
  const {
    icon,
    title,
    variant,
    contentId,
    reactions,
    onCopyLink,
    hasTrophies,
    showChatView,
    showReactions,
    currentUserId,
    dropdownOptions,
    onEmoticonSelect,
    dropdownPlacement,
    onReactionToggleClick,
    onEditReplyOptionClick,
    canShowReactions = true,
    onDeleteModalSubmitClick,
    onDeletePostSelectedOption,
    currency,
  } = props;
  return (
    <>
      {canShowReactions && (
        <StyledReactionsBar
          variant={variant}
          reactions={reactions}
          contentId={contentId}
          showReactions={showReactions}
          currentUserId={currentUserId}
          onEmoticonSelect={onEmoticonSelect}
          reactionButtonSize={ButtonSize.Small}
          onReactionToggleClick={onReactionToggleClick}
          toggleButtonVariant={ToggleButtonVariant.Text}
        />
      )}

      <StyledFeedPostHeaderOption
        icon={icon}
        title={title}
        hasTrophies={hasTrophies}
        showChatView={showChatView}
        feedType={FeedTypes.COMMENT}
        onCopyLink={onCopyLink}
        dropdownOptions={dropdownOptions}
        dropdownPlacement={dropdownPlacement}
        onEditReplyOptionClick={onEditReplyOptionClick}
        dropdownToggleButtonSize={ButtonSize.Small}
        onDeleteModalSubmitClick={onDeleteModalSubmitClick}
        onDeletePostSelectedOption={onDeletePostSelectedOption}
        currency={currency}
      />
    </>
  );
};

export default CommentActions;
