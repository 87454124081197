import styled from 'styled-components';

import ToolTip from '../ToolTipV2';
import Body from '../../atoms/Body';
import ThemeV2 from '../../../theme';
import Button from '../../atoms/Button';
import { Flex } from '../../../Utils/styles/display';

export const Value = styled(Body)`
  margin-left: 6px;
`;

export const DropdownWrapper = styled.section`
  padding: 16px 0;
  margin-bottom: 12px;
`;

export const SlackWorkspaceWrapper = styled.section`
  padding: 16px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid ${ThemeV2.palette.gray5};
`;

export const DropdownOptionsContainer = styled.section`
  margin-bottom: 12px;
`;

export const DisconnectSlackWrapper = styled(Flex)`
  margin-top: -12px;
`;

export const DisconnectSlackButton = styled(Button)`
  margin-left: 8px;
  padding-left: 8px;
`;

export const ConnectToWorkspaceHelperWrapper = styled(Body)`
  margin: 4px 0 4px 4px;
  position: absolute;
  bottom: 4px;
  width: 98%;
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 36px;
  padding: 8px 14px;
  color: ${ThemeV2.palette.geekBlue6};
  background-color: ${ThemeV2.palette.white};
  &:hover {
    cursor: pointer;
    background: ${ThemeV2.palette.geekBlue1};
  }
`;

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  padding: 3px 3px 0 3px;
  border-radius: 4px;
  margin-right: 8px;
  background: ${ThemeV2.palette.geekBlue6};
`;

export const ChannelContainer = styled.span`
  span {
    display: inline-block;
    margin-top: 3px;
  }
`;

export const MessageBanner = styled(Flex)`
  position: absolute;
  bottom: 4px;
  background: ${ThemeV2.palette.white};
  box-sizing: border-box;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: calc(100% - 8px);
  height: 40px;
  border-top: 1px solid ${ThemeV2.palette.gray4};
  padding: 8px;
`;

export const StyledToolTip = styled(ToolTip)`
  width: 100%;
`;

export const ToolTipMessageWrapper = styled.section`
  cursor: pointer;
`;

export const ErrorMessage = styled(Body)`
  color: ${ThemeV2.palette.red6};
  font-size: 12px;
`;

export const Link = styled.u`
  cursor: pointer;
`;

export const ToolTipContainer = styled.section`
  width: 328px;
  border-radius: 8px;
  background-color: ${ThemeV2.palette.gray9};
`;

export const ChannelsListContainer = styled.section`
  display: grid;
  grid-template-columns: auto 1fr;
`;
