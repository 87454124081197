import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CaretIconSpan,
  LimitBody,
  StyledAssemblyCurrencyIcon,
  StyledInput,
  Wrapper,
} from './styles';
import SVGIcon from '../../atoms/SVGIcon';
import DropdownListHolder, {
  DropdownWrapper,
  DropdownProps,
} from '../DropdownListHolder';
import useDropDown from '../../../hooks/useDropDown';
import Button from '../../atoms/Button';
import {
  DropdownVariants,
  DropdownColor,
  DropdownPlacement,
  DropdownSizes,
} from '../Dropdown_V2/interfaces';
import { EACH, NO } from '../../../languages/en/singleWords';
import { AssemblyCurrency } from '../../../interfaces/assembly';

export interface AddTrophiesInputProps extends DropdownProps {
  buttonColor?: DropdownColor;
  buttonText: string;
  buttonSize?: DropdownSizes;
  buttonVariant?: DropdownVariants;
  value?: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
  maxValue: number;
  splitBy?: number;
  assemblyCurrency: AssemblyCurrency;
  className?: string;
  canAllowCustomAmount?: boolean;
  onUnmountReset?: boolean;
  isEndIcon?: boolean;
}

export enum AddTrophiesValue {
  ButtonMode = 0,
  Input = -1,
  NoTrophies = -2,
}

const AddTrophiesInput = (props: AddTrophiesInputProps) => {
  const {
    isEndIcon = false,
    buttonText,
    buttonColor = DropdownColor.Secondary,
    buttonSize = DropdownSizes.Small,
    buttonVariant = DropdownVariants.Text,
    value,
    onChange,
    onBlur,
    maxValue,
    splitBy = 1,
    assemblyCurrency,
    className,
    canAllowCustomAmount,
    onUnmountReset = true,
  } = props;
  const { anchorProps } = useDropDown(props);
  const {
    referenceElement,
    isOpen,
    toggleDropdown,
    closeDropdown,
    disabled,
    isFullWidth,
    menuItems,
    anchorEl,
  } = anchorProps;
  const [inputValue, setInputValue] = useState<number | undefined>(value);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      // To reset the value on un mount
      if (onUnmountReset) {
        onChange(AddTrophiesValue.ButtonMode);
      }
    };
  }, [onChange, onUnmountReset]);

  useEffect(() => {
    if (value === AddTrophiesValue.Input) {
      // We are trying to focus on the input when it mounts.
      if (anchorEl.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore Even if i add checks its still complaining for null check */
        anchorEl.current.focus();
      }
    }
  }, [value, anchorEl]);

  const splitMaxValue = useMemo(
    () => Math.floor(maxValue / splitBy),
    [maxValue, splitBy],
  );

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
    closeDropdown();
    if (inputValue === 0) {
      onChange(AddTrophiesValue.NoTrophies);
    }
    if (!isError) {
      onChange(inputValue || AddTrophiesValue.ButtonMode);
      setIsError(false);
    }
    if (onBlur) {
      onBlur();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = e.currentTarget;
    if (newValue !== '') {
      const numValue = parseInt(newValue, 10);
      if (
        maxValue !== undefined &&
        (numValue > splitMaxValue || numValue < 1)
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setInputValue(numValue);
    } else {
      setInputValue(undefined);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleInputKeyDown = (event: any) => {
    const ENTER_KEY_CODE = 13;
    if (event.keyCode === ENTER_KEY_CODE) {
      handleInputBlur();
    }
  };

  const handleItemClick = useCallback(
    (newValue: string | number) => {
      const numValue = newValue as number;
      onChange(numValue);
      if (numValue !== AddTrophiesValue.Input) {
        setInputValue(numValue);
      } else {
        setInputValue(undefined);
      }
      closeDropdown();
    },
    [onChange, closeDropdown],
  );

  const currencyIcon = useMemo(
    () => (
      <StyledAssemblyCurrencyIcon
        assemblyCurrency={assemblyCurrency}
        id="id"
        currencyName="currencyName"
        size="14px"
        dataTestId="currency"
        dataQaId="currency"
      />
    ),
    [assemblyCurrency],
  );

  const caretIcon = useMemo(
    () => (
      <CaretIconSpan onClick={toggleDropdown}>
        <SVGIcon icon="caret-rounded" size="20px" flip={isOpen} />
      </CaretIconSpan>
    ),
    [isOpen, toggleDropdown],
  );

  const modifiedMenuItems = useMemo(() => {
    return [
      {
        ...menuItems[0],
        items: menuItems[0].items
          .filter((item) =>
            canAllowCustomAmount
              ? true
              : (item.id as number) !== AddTrophiesValue.Input,
          )
          .map((item) => ({
            ...item,
            value:
              item.id !== AddTrophiesValue.ButtonMode && splitBy > 1
                ? `${item.value} ${
                    item.id === AddTrophiesValue.NoTrophies && splitBy > 1
                      ? ''
                      : EACH
                  }`
                : item.value,
            disabled:
              (item.id as number) > splitMaxValue &&
              (item.id as number) !== AddTrophiesValue.Input,
          })),
      },
    ];
  }, [menuItems, splitBy, splitMaxValue, canAllowCustomAmount]);

  const inputValueLength = inputValue ? `${inputValue}`.length : 0;
  const placeholderText = isFullWidth
    ? `of ${splitMaxValue}`
    : `/${splitMaxValue}`;

  const buttonProps = {
    variant: buttonVariant,
    disabled,
    isFullWidth,
    color: buttonColor,
    onClick: toggleDropdown,
    enableCaret: true,
    caretOpen: !disabled && isOpen,
    assemblyCurrency,
    ref: anchorEl,
    size: buttonSize,
  };

  const renderButtonText = useMemo(() => {
    let buttonTextContent;
    if (
      value !== AddTrophiesValue.NoTrophies &&
      value !== AddTrophiesValue.Input &&
      value !== AddTrophiesValue.ButtonMode
    ) {
      buttonTextContent = `
        ${value}
        ${' '}
        ${
          (isFullWidth &&
            (value === 1
              ? assemblyCurrency.name
              : assemblyCurrency.pluralName)) ||
          ''
        }
        ${' '}
        ${splitBy !== 1 ? EACH : ''}
        `;

      return buttonTextContent;
    }
    if (
      value === AddTrophiesValue.ButtonMode ||
      value === AddTrophiesValue.NoTrophies
    ) {
      buttonTextContent =
        value === AddTrophiesValue.ButtonMode
          ? buttonText
          : `${NO} ${assemblyCurrency.pluralName}`;

      return buttonTextContent;
    }

    return null;
  }, [assemblyCurrency, buttonText, isFullWidth, splitBy, value]);

  if (value === AddTrophiesValue.Input) {
    return (
      <Wrapper buttonSize={buttonSize} isFocused={isFocused} isError={isError}>
        {currencyIcon}
        <StyledInput
          buttonSize={buttonSize}
          ref={anchorEl}
          type="number"
          value={inputValue}
          valueLength={inputValueLength}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          onKeyDown={handleInputKeyDown}
          isFullWidth={isFullWidth || false}
          data-testid="inputTrophies"
          data-qa-id="inp-trophy"
        />
        <LimitBody variant="body2" color="gray7">
          {maxValue !== undefined && placeholderText}
          {splitBy !== 1 && ` ${EACH}`}
        </LimitBody>
        {caretIcon}
      </Wrapper>
    );
  }

  return (
    <DropdownWrapper
      ref={referenceElement}
      className={className}
      isFullWidth={isFullWidth}
    >
      <Button
        {...buttonProps}
        isEndIcon={isEndIcon}
        assemblyCurrency={assemblyCurrency}
      >
        {renderButtonText}
      </Button>
      <DropdownListHolder
        {...anchorProps}
        onItemClick={handleItemClick}
        menuItems={modifiedMenuItems}
        dropdownPlacement={DropdownPlacement.BottomStart}
        isDropdownPositionFixed
      />
    </DropdownWrapper>
  );
};

export default AddTrophiesInput;
