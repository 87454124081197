import _ from 'lodash';
import { RRule, Frequency, Options, rrulestr } from 'rrule';
import { hasAllWeekDays, hasWeekDaysInParsedRRule } from '..';
import { SelectedCustomRecurrenceTypes } from '../../../../atomic/organism/CustomRecurrence/types';
import { TriggerBuilderBlockData } from '../../../../interfaces/Flow/Builder';

export const getSelectedCustomRecurrenceTypes = (
  rruleString: Partial<Options>,
): SelectedCustomRecurrenceTypes | undefined => {
  const { freq, interval, byweekday, bysetpos } = rruleString;

  const rule = new RRule(rruleString);
  const RRuleStr = rrulestr(rule.toString());

  // REPEAT DAYS - EVERY 3 DAYS
  if (freq === Frequency.DAILY && interval !== undefined && interval > 0) {
    return {
      repeatEvery: 'days',
      repeatEveryCount: interval,
      selectedDayIds: [],
      repeatOnMonth: '',
    };
  }

  // REPEAT WEEK DAYS - WEEKLY ON ALL DAYS
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    (interval === 1 || interval > 1) &&
    hasAllWeekDays(RRuleStr.options.byweekday.sort())
  ) {
    return {
      repeatEvery: 'weeks',
      repeatEveryCount: interval,
      selectedDayIds: [0, 1, 2, 3, 4, 5, 6],
      repeatOnMonth: '',
    };
  }

  // REPEAT WEEK DAYS - WEEKLY ON WEEK DAYS
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    (interval === 1 || interval > 1) &&
    hasWeekDaysInParsedRRule(RRuleStr.options.byweekday.sort())
  ) {
    return {
      repeatEvery: 'weeks',
      repeatEveryCount: interval,
      selectedDayIds: [0, 1, 2, 3, 4],
      repeatOnMonth: '',
    };
  }

  // REPEAT WEEKS - Every (1/3) WEEKS ON (Mon/Tue)
  if (
    freq === Frequency.WEEKLY &&
    interval !== undefined &&
    interval > 0 &&
    _.isArray(byweekday) &&
    byweekday.length > 0
  ) {
    return {
      repeatEvery: 'weeks',
      repeatEveryCount: interval,
      selectedDayIds: RRuleStr.options.byweekday,
      repeatOnMonth: '',
    };
  }

  // REPEAT YEARS - ANNUALLY & EVERY (2/3) YEARS
  if (freq === Frequency.YEARLY && interval !== undefined && interval > 0) {
    return {
      repeatEvery: 'years',
      repeatEveryCount: interval,
      selectedDayIds: [],
      repeatOnMonth: '',
    };
  }

  // REPEAT MONTHLY - MONTHLY/EVERY MONTH ON DAY (1/16/31)
  if (
    freq === Frequency.MONTHLY &&
    interval !== undefined &&
    interval > 0 &&
    bysetpos === undefined
  ) {
    return {
      repeatEvery: 'months',
      repeatEveryCount: interval,
      selectedDayIds: [],
      repeatOnMonth: 'repeatMonthly',
    };
  }

  // REPEAT MONTHLY ON - MONTHLY/EVERY MONTH ON (1st,2nd,3rd,4th & last) (Mon/Tue)
  if (
    freq === Frequency.MONTHLY &&
    interval !== undefined &&
    interval > 0 &&
    bysetpos !== undefined &&
    _.isArray(byweekday) &&
    byweekday.length > 0
  ) {
    return {
      repeatEvery: 'months',
      repeatEveryCount: interval,
      selectedDayIds: [],
      repeatOnMonth: 'repeatMonthlyOn',
    };
  }

  return undefined;
};

// to get the default selectedCustomRecurrenceTypes
export const getDefaultSelectedCustomRecurrenceTypes = (
  triggerBlockData: TriggerBuilderBlockData,
) => {
  if (
    triggerBlockData.triggerType === 'SCHEDULED' &&
    triggerBlockData.schedule
  ) {
    const parsedString = RRule.parseString(triggerBlockData.schedule.rule);
    if (parsedString.dtstart) {
      const receivedSelectedCustomRecurrenceType =
        getSelectedCustomRecurrenceTypes(parsedString);
      return receivedSelectedCustomRecurrenceType;
    }
    return undefined;
  }
  return undefined;
};
