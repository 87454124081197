import { createContext } from 'react';

import { useTaskOptions } from '.';
import { CreateTaskController } from './CreateTaskController';
import { TasksAutocompleteController } from './TasksAutocompleteController';
import {
  TaskOptionsContextState,
  TaskOptionsProviderProps,
} from './interfaces';

export const TaskOptionsContext = createContext<TaskOptionsContextState>({
  anchorElForTasksDropdown: { current: null },
  handleCloseCreateTaskInput: () => {},
  handleCloseTasksDropdown: () => {},
  handleCreateTaskClick: () => {},
  handleCreateTaskLinkClick: () => {},
  handleNewTaskSubmit: () => {},
  handleNewTaskValueChange: () => {},
  handleNewTaskValueClear: () => {},
  handleReferenceTaskClick: () => {},
  handleTaskOptionSelect: () => {},
  handleTaskTokenInsertion: () => {},
  isCreateTaskInputOpen: false,
  isTasksDropdownOpen: false,
  newTaskValue: '',
});

export const TaskOptionsProvider = (props: TaskOptionsProviderProps) => {
  const { children, ...taskOptionsProps } = props;
  const value = useTaskOptions(taskOptionsProps);

  return (
    <TaskOptionsContext.Provider value={value}>
      <CreateTaskController />
      <TasksAutocompleteController />
      {children}
    </TaskOptionsContext.Provider>
  );
};
