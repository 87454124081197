import React, { useCallback } from 'react';
import { EmojiData, Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { EmoticonPickerProps } from './interface';
import { EMOTICON_PICKER_TEXT } from '../../../languages/en/home';
import { isWindowsOS } from '../../../Utils/window';
import styled from 'styled-components';
import { device } from '../../../constants/layout';

const StyledPicker = styled(Picker)`
  @media ${device.mobile} {
    height: auto;
    overflow-y: auto;
  }
`;

const EmoticonPicker = (props: EmoticonPickerProps) => {
  const { onEmoticonSelect } = props;

  const handleOnClick = useCallback(
    (emoji: EmojiData) => {
      onEmoticonSelect(emoji);
    },
    [onEmoticonSelect],
  );
  return (
    <StyledPicker
      onClick={handleOnClick}
      set={isWindowsOS() ? 'google' : 'apple'}
      sheetSize={16}
      native={!isWindowsOS()}
      title={EMOTICON_PICKER_TEXT}
      emoji="point_up"
    />
  );
};

const MemoizedEmoticonPicker = React.memo(EmoticonPicker);
MemoizedEmoticonPicker.displayName = 'EmoticonPicker';
export default MemoizedEmoticonPicker;
