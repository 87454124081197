import React, { useEffect } from 'react';
import { DropdownWithChildrenProps } from './interface';
import useDropDown from '../../../hooks/useDropDown';
import DropdownListHolder, { DropdownWrapper } from '../DropdownListHolder';
import { DropdownPlacement } from '../Dropdown_V2/interfaces';

const DropdownWithChildren = (props: DropdownWithChildrenProps) => {
  const {
    className,
    getItemLabel,
    dropdownPlacement = DropdownPlacement.Bottom,
    isDropdownPositionFixed,
    onFocus,
    onItemClick,
    children,
    onDropdownOpen,
  } = props;

  const { anchorProps } = useDropDown(props);
  const {
    id,
    referenceElement,
    toggleDropdown,
    isOpen,
    isFullWidth,
    anchorEl,
  } = anchorProps;

  useEffect(() => {
    if (onDropdownOpen) {
      onDropdownOpen(isOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <DropdownWrapper
      ref={referenceElement}
      isFullWidth={isFullWidth}
      className={className}
      onFocus={onFocus}
    >
      <div
        onClick={toggleDropdown}
        aria-describedby={id}
        ref={anchorEl}
        data-testid="dropdown"
        data-qa-id="ddown"
      >
        {children}
      </div>
      <DropdownListHolder
        {...anchorProps}
        dropdownPlacement={dropdownPlacement}
        onItemClick={onItemClick}
        getItemLabel={getItemLabel}
        isDropdownPositionFixed={isDropdownPositionFixed}
      />
    </DropdownWrapper>
  );
};

export default DropdownWithChildren;
