import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import Collapse from '@mui/material/Collapse';

import Body from '../../atoms/Body';
import FlowPostBody from '../FlowPostBody';
import { NewFlowFeedPostProps } from './types';
import { device } from '../../../constants/layout';
import FlowsPostHeader from '../../molecules/FlowsPostHeader';
import { trackPostActionEvent } from '../../../Utils/analytics/post';
import { POST_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { FlowAccordionItemState } from '../../molecules/FlowAccordionItem/interface';

import {
  StyledArrowIcon,
  AccordionItemBody,
} from '../../molecules/FlowAccordionItem/styles';

import {
  StyledFlex,
  SamplePostWrapper,
  FlowFeedPostWrapper,
  StyledAccordionItemHeader,
} from './styles';
import useLayoutStore from '../../../stores/layoutStore';
import FlowPostFooter from './Components/FlowPostFooter';
import { AnnouncementBanner } from './Components/AnnouncementBanner';
import mobileStore from '../../../stores/mobileAppStore';
import postMessageToMobileApp from '../../../hooks/useMobileApp/postMessageToMobileApp';
import { AppAction, AppTargetEvent } from '../../../interfaces/PostMessage';

const PostContent = (props: NewFlowFeedPostProps) => {
  const {
    variant,
    bodyProps,
    hideBorder,
    isAnonymous,
    headerProps,
    profileData,
    onMemberClick,
    onReactionSet,
    hideReactions,
    hideRepliesBar,
    repliesBarProps,
    onReactionUnSet,
    feedItemMetaData,
    reactionBarProps,
    hideInteractions,
  } = props;

  const handleOnRepliesClick = useCallback(() => {
    window.parent.postMessage(
      {
        type: 'OPEN_POST_DRAWER',
        payload: {
          flowId: props.feedItemMetaData.flowId,
          responseId: props.feedItemMetaData.responseId,
          postId: props.feedItemMetaData.postId,
        },
      },
      '*',
    );

    trackPostActionEvent({
      ...feedItemMetaData,
      messageType: 'replies',
      flowName: headerProps.flow.name,
      action: POST_ANALYTICS_EVENTS.VIEW_REPLIES_CLICKED,
    });
  }, [
    feedItemMetaData,
    headerProps.flow.name,
    props.feedItemMetaData.flowId,
    props.feedItemMetaData.responseId,
    props.feedItemMetaData.postId,
  ]);

  return (
    <>
      <FlowPostBody
        {...bodyProps}
        {...feedItemMetaData}
        variant={variant}
        flow={headerProps.flow}
        isAnonymous={isAnonymous}
        person={headerProps.person}
        onMemberClick={onMemberClick}
        createdAt={headerProps.createdAt}
        visibility={headerProps.visibility}
      />
      <FlowPostFooter
        variant={variant}
        hideBorder={hideBorder}
        hideReplies={Boolean(hideRepliesBar)}
        hideReactions={Boolean(hideReactions)}
        onReactionSet={onReactionSet}
        onReactionUnSet={onReactionUnSet}
        repliesBarProps={repliesBarProps}
        hideInteractions={hideInteractions}
        feedItemMetaData={feedItemMetaData}
        reactionBarProps={reactionBarProps}
        handleOnRepliesClick={handleOnRepliesClick}
        currentUserId={profileData.member.memberId}
        avatarProps={{
          name: profileData.member.profile.firstName,
          img: profileData.member.profile.image,
          userId: profileData.member.memberId,
        }}
      />
    </>
  );
};

const FlowFeedPost = (props: NewFlowFeedPostProps) => {
  const isEmbeddedInMainApp = useLayoutStore.getState().isEmbeddedInMainApp;
  const { isMobileApp } = mobileStore.getState();

  const {
    variant,
    className,
    hideBorder,
    headerProps,
    profileData,
    onFlowClick,
    isSamplePost,
    onMemberClick,
    hideInteractions,
    dropdownComponent,
    activeAnnouncement,
    onViewAnnouncementInsightsClick,
  } = props;

  const { search } = useLocation();

  const isMobileView = useMediaQuery({
    query: device.mobile,
  });

  const params = new URLSearchParams(search).entries();
  const queryParameters = Object.fromEntries(params);

  const [isExpanded, setIsExpanded] = React.useState(
    isMobileView || queryParameters.showOriginalPost === 'true',
  );

  const canShowAnnouncementInsights =
    activeAnnouncement?.showInsights && isEmbeddedInMainApp;

  return (
    <FlowFeedPostWrapper
      className={className}
      hideBorder={hideBorder}
      data-testid="flow-feed-post"
      data-qa-id="flow-feed-post"
      data-js-id="flow-feed-post" // required for post width calculation
    >
      {activeAnnouncement && (isEmbeddedInMainApp || isMobileApp) && (
        <AnnouncementBanner
          disableToolTip={isMobileApp}
          title={activeAnnouncement.title}
          selectedColor={activeAnnouncement.color}
          description={activeAnnouncement.description}
          onClick={(args) => {
            if (isMobileApp) {
              postMessageToMobileApp({
                action: AppAction.AnnouncementClicked,
                targetEvent: AppTargetEvent.Mobile,
                payload: {
                  description: args.description,
                },
              });
            }
          }}
        />
      )}
      {isSamplePost && (
        <SamplePostWrapper>
          <Body variant="body2" color="gray9">
            This is a sample post - it is only visible to you
          </Body>
        </SamplePostWrapper>
      )}
      {variant !== 'sidebar' && (
        <FlowsPostHeader
          {...headerProps}
          variant={variant}
          onFlowClick={onFlowClick}
          onClickUserName={onMemberClick}
          currentUserId={profileData.member.memberId}
          dropdownComponent={hideInteractions ? undefined : dropdownComponent}
          isSamplePost={isSamplePost}
          {...(canShowAnnouncementInsights && {
            announcementInsights: {
              onViewAnnouncementInsightsClick,
            },
          })}
        />
      )}
      {variant === 'sidebar' ? (
        <>
          <StyledAccordionItemHeader
            type="button"
            hideBorder={true}
            isOpen={isExpanded}
            state={FlowAccordionItemState.Focus}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <StyledFlex>
              <Body variant="body2" color="gray8">
                {isExpanded ? 'Hide original post' : 'Show original post'}
              </Body>
              <StyledArrowIcon
                size="18px"
                icon="caret-rounded"
                rotate={isExpanded ? 0 : 180}
                state={FlowAccordionItemState.Focus}
                data-testid="accordionItemCaratIcon"
              />
            </StyledFlex>
          </StyledAccordionItemHeader>
          <AccordionItemBody>
            <Collapse in={isExpanded}>
              <PostContent {...props} />
            </Collapse>
          </AccordionItemBody>
        </>
      ) : (
        <PostContent {...props} />
      )}
    </FlowFeedPostWrapper>
  );
};

export default FlowFeedPost;
