import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import NewTextfield from '../../../molecules/NewTextfield';
import { ButtonSize } from '../../../atoms/IconButton_V2/interface';
import { CreateTaskInputProps } from './interfaces';
import { device } from '../../../../constants/layout';
import { CREATE_TASK_INPUT_LABEL } from '../../../../languages/en/notebook';
import {
  CheckMarkIcon,
  ClearIcon,
  InputContainer,
  SubmitButton,
  useStyles,
} from './styles';
import ThemeV2 from '../../../../theme';

export const CreateTaskInput = ({
  onChange,
  onClear,
  onSubmit,
  value,
}: CreateTaskInputProps) => {
  const isDesktopView = useMediaQuery({
    query: device.desktop,
  });

  const classes = useStyles({ isDesktopView });

  const handleKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case 'Enter':
          onSubmit();
          e.preventDefault();
          break;
        default:
          break;
      }
    },
    [onSubmit],
  );

  return (
    <InputContainer>
      <CheckMarkIcon
        color={ThemeV2.palette.gray3}
        icon="checkbox-circle-unchecked"
        size="20px"
        stroke={ThemeV2.palette.gray5}
      />
      <NewTextfield
        autoComplete="off"
        className={classes.root}
        autoFocus
        maxRows={isDesktopView ? 20 : 10}
        multiline
        name={CREATE_TASK_INPUT_LABEL}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        onSuffixAdornmentClick={onClear}
        size="small"
        spellCheck={false}
        suffix={value && <ClearIcon icon="close-rounded" size="20px" />}
        type="text"
        value={value}
      />
      <SubmitButton
        color="primary"
        disabled={value.trim().length === 0}
        size={ButtonSize.Normal}
        icon="check-rounded"
        onClick={onSubmit}
      />
    </InputContainer>
  );
};
