import React, { useState } from 'react';
import { shallow } from 'zustand/shallow';
import moment from 'moment-timezone';

import Body from '../../atoms/Body';
import PaginationComponent, {
  defaultItemsPerPageValues,
} from '../../molecules/PaginationComponent';

import { MyRewardsProps } from './interface';
import {
  StyledRedemptionCardWrapper,
  StyledQuickStatsWrapper,
  StyledEmptyDiv,
  StyledFlex,
} from './styles';

import {
  MY_REWARDS_TEXT,
  REDEMPTIONS_PER_PAGE,
} from '../../../languages/en/rewards';

import { RewardsFooter } from '../RouteTabs/styles';
import RedemptionCard from '../../molecules/RedemptionCard';
import { ComponentStatus } from '../../../interfaces/component';
import QuickStats from '../../molecules/QuickStats';
import useLayoutStore from '../../../stores/layoutStore';
import { OrderStatus } from '../../../queries/Rewards/interfaces';

const MyRewards = (props: MyRewardsProps) => {
  const { isEmbeddedInMainApp } = useLayoutStore();
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);

  const { isLeftAsideOpen, isRightAsideOpen } = useLayoutStore(
    (state) => ({
      isLeftAsideOpen: state.isLeftAsideOpen,
      isRightAsideOpen: state.isRightAsideOpen,
    }),
    shallow,
  );

  switch (props.status) {
    case ComponentStatus.LOADING:
      return (
        <div data-testid="MyRewardsLoading">
          <Body variant="body1Bold">{MY_REWARDS_TEXT.QUICK_STATS}</Body>
          <StyledFlex margin="12px 0 24px" justifyContent="space-between">
            <StyledQuickStatsWrapper>
              <QuickStats status={ComponentStatus.LOADING} />
            </StyledQuickStatsWrapper>
            <StyledEmptyDiv />
            <StyledQuickStatsWrapper>
              <QuickStats status={ComponentStatus.LOADING} />
            </StyledQuickStatsWrapper>
          </StyledFlex>
          <Body variant="body1Bold" margin="0 0 12px">
            {MY_REWARDS_TEXT.REDEMPTION_HISTORY}
          </Body>
          <RedemptionCard status={ComponentStatus.LOADING} />
        </div>
      );
    case ComponentStatus.EMPTY: {
      const { assemblyCurrency } = props;
      return (
        <div data-testid="MyRewardsLoading">
          <Body variant="body1Bold">{MY_REWARDS_TEXT.QUICK_STATS}</Body>
          <StyledFlex margin="12px 0 24px" justifyContent="space-between">
            <StyledQuickStatsWrapper>
              <QuickStats
                status={ComponentStatus.EMPTY}
                assemblyCurrency={assemblyCurrency}
                title={`${assemblyCurrency.pluralName} ${MY_REWARDS_TEXT.REDEEMED}`}
              />
            </StyledQuickStatsWrapper>
            <StyledEmptyDiv />
            <StyledQuickStatsWrapper>
              <QuickStats
                status={ComponentStatus.EMPTY}
                title={MY_REWARDS_TEXT.DOLLARS_REDEEMED}
              />
            </StyledQuickStatsWrapper>
          </StyledFlex>
          <RedemptionCard status={ComponentStatus.EMPTY} />
        </div>
      );
    }
    default: {
      const {
        rewardsHistory,
        rewards,
        assemblyCurrency,
        onButtonClick: onButtonClick,
        isRedemptionDetailsLoading,
        trackingLink,
        shippingStatus,
      } = props;

      return (
        <div data-testid="MyRewards" data-qa-id="myRewards">
          <Body variant="body1Bold">{MY_REWARDS_TEXT.QUICK_STATS}</Body>
          <StyledFlex margin="12px 0 24px" justifyContent="space-between">
            <StyledQuickStatsWrapper>
              <QuickStats
                status={ComponentStatus.LOADED}
                assemblyCurrency={assemblyCurrency}
                title={`${assemblyCurrency.pluralName} ${MY_REWARDS_TEXT.REDEEMED}`}
                value={rewardsHistory.totalPointsEarned}
              />
            </StyledQuickStatsWrapper>
            <StyledEmptyDiv />
            <StyledQuickStatsWrapper>
              <QuickStats
                status={ComponentStatus.LOADED}
                title={MY_REWARDS_TEXT.DOLLARS_REDEEMED}
                value={`$${Math.floor(rewardsHistory.totalAmount)}`}
              />
            </StyledQuickStatsWrapper>
          </StyledFlex>
          <Body variant="body1Bold" margin="0 0 12px">
            {MY_REWARDS_TEXT.REDEMPTION_HISTORY}
          </Body>
          {rewards !== null &&
            rewards
              .slice(
                (pageNumber - 1) * itemsPerPage,
                (pageNumber - 1) * itemsPerPage + itemsPerPage,
              )
              .map((reward) => {
                const {
                  currencyAmount,
                  rewardType,
                  image,
                  title,
                  description,
                  redeemedPoints,
                  redeemedOn,
                  resendEmailCount,
                  orderId,
                  orderInfo,
                  swagDetails,
                } = reward;
                const isButtonDisabled = () => {
                  if (resendEmailCount) {
                    return true;
                  } else if (swagDetails?.orderStatus) {
                    if (
                      swagDetails.orderStatus === OrderStatus.CANCELED ||
                      swagDetails.orderStatus === OrderStatus.DELIVERED
                    ) {
                      return true;
                    }
                  }
                  return false;
                };
                if (rewardType && title && description && assemblyCurrency) {
                  return (
                    <StyledRedemptionCardWrapper key={orderId}>
                      <RedemptionCard
                        currencyAmount={currencyAmount}
                        status={ComponentStatus.LOADED}
                        rewardType={rewardType}
                        orderId={orderId}
                        image={image}
                        title={title}
                        assemblyCurrency={assemblyCurrency}
                        redeemedPoints={redeemedPoints}
                        description={description}
                        redeemedOn={moment(redeemedOn).format('MM/DD/YYYY')}
                        onButtonClick={onButtonClick}
                        disabled={isButtonDisabled()}
                        data-testid="redemption-card"
                        orderInfo={orderInfo}
                        swagDetails={swagDetails}
                        isRedemptionDetailsLoading={isRedemptionDetailsLoading}
                        trackingLink={trackingLink}
                        shippingStatus={shippingStatus}
                      />
                    </StyledRedemptionCardWrapper>
                  );
                }
                return null;
              })}
          {rewards && rewards?.length > 0 && (
            <RewardsFooter
              isLeftAsideOpen={isLeftAsideOpen}
              isRightAsideOpen={isRightAsideOpen}
              isEmbeddedInMainApp={isEmbeddedInMainApp}
            >
              <PaginationComponent
                dropdownLabelText={REDEMPTIONS_PER_PAGE}
                itemCount={rewards.length}
                itemsPerPage={itemsPerPage}
                itemsPerPageValues={defaultItemsPerPageValues}
                onItemsPerPageValueChange={setItemsPerPage}
                onHandlePaginationChange={setPageNumber}
                pageNumber={pageNumber}
                hasRightSpacing
              />
            </RewardsFooter>
          )}
        </div>
      );
    }
  }
};

export default MyRewards;
