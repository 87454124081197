import introJs from 'intro.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PARTICIPATION_ANALYTICS_EVENTS } from '../../../../../Utils/analytics/constants';
import {
  trackFlowParticipationActionEvent,
  trackFlowParticipationShowEvent,
} from '../../../../../Utils/analytics/flowsParticipation';
import useParticipationFlow from '../../../../../hooks/useParticipationFlow';
import { isBlockEmpty } from '../../../../../hooks/useParticipationFlow/utils';
import {
  FlowSubmissionDetails,
  StaticBlockState,
} from '../../../../../interfaces/Flow';
import { useFetchFlowDetailsQuery } from '../../../../../queries/Flows/Feed';
import { FlowFeedResponse } from '../../../../../queries/Flows/Feed/interfaces';
import {
  AnonymityStates,
  FlowInstanceResponse,
} from '../../../../../queries/Flows/interfaces';
import { GetProfileInfoResponse } from '../../../../../queries/Profile';
import { useDraftStore } from '../../../../../stores/draftStore';
import { ExternalFlowDetails } from '../../../ParticipationFlowController/useLoadedParticipationFlowController';
import { processFlowBlocks } from '../../../ParticipationFlowController/utils';
import useLayoutStore from '../../../../../stores/layoutStore';
import { useParticipationFlowMessageEvent } from '../../../../../hooks/useParticipationFlowMessageEvent';

const useLoadedFlowsParticipationController = (
  instanceDetails: FlowInstanceResponse,
  handleSaveAndCloseParticipationModal: (values?: Record<string, any>) => void,
  profileInfo: GetProfileInfoResponse,
  onFlowSubmit: (submissionDetails: FlowSubmissionDetails) => void,
  externalFlowDetails?: ExternalFlowDetails,
  postData?: FlowFeedResponse,
) => {
  const [isFetchingUploadUrl, setIsFetchingUploadUrl] = useState(false);
  const { flowId } = useParams<{ flowId: string }>();
  const history = useHistory();
  const { drafts, errorDrafts, deleteDraft } = useDraftStore();
  const containerRef = useRef<HTMLDivElement>(null);
  const { responseId } = useParams<{ responseId: string }>();
  const isEditMode = !!responseId;
  const isDraftMode = !!drafts[flowId] && !isEditMode;
  const { isEmbeddedInMainApp } = useLayoutStore();

  const { data: flowDetails } = useFetchFlowDetailsQuery(
    instanceDetails.flowId,
  );

  useParticipationFlowMessageEvent();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const fromErrorDraft = Boolean(params.get('fromErrorDraft')) || false;

  const currentFlow = fromErrorDraft ? errorDrafts[flowId] : drafts[flowId];

  const [schema, initialValues, stepData, draftValues] = useMemo(
    () =>
      processFlowBlocks(
        instanceDetails.blocks,
        profileInfo,
        undefined,
        externalFlowDetails,
        postData,
        currentFlow,
      ),
    [
      currentFlow,
      externalFlowDetails,
      instanceDetails.blocks,
      postData,
      profileInfo,
    ],
  );

  const flowData = flowDetails?.data || {
    ...externalFlowDetails,
    flowId: flowId,
  };

  useEffect(() => {
    introJs().showHints();
    return () => {
      introJs().hideHints();
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      trackFlowParticipationActionEvent({
        ...flowData,
        flowId: flowDetails?.data.flowId || flowId,
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_FLOW_POST_STARTED,
      });
    } else {
      trackFlowParticipationShowEvent({
        ...flowData,
        action: PARTICIPATION_ANALYTICS_EVENTS.START,
      });
    }
  }, []);

  const postStepChange = (
    blockData: StaticBlockState,
    values: Record<string, any>,
  ) => {
    const isSkipped = isBlockEmpty(blockData, values);
    trackFlowParticipationActionEvent({
      ...flowData,
      action: isSkipped
        ? PARTICIPATION_ANALYTICS_EVENTS.BLOCK_SKIPPED
        : PARTICIPATION_ANALYTICS_EVENTS.BLOCK_ANSWERED,
    });
  };

  const { models, operations } = useParticipationFlow({
    staticBlockData: stepData,
    schema,
    initialValues: isDraftMode ? draftValues : initialValues,
    containerRef,
    postStepChange,
    onFlowSubmit,
    flowInstanceDetails: instanceDetails,
    isPrivatePost: Boolean(flowDetails?.data.allowPrivateResponse),
    isAnonymousPost: Boolean(
      flowDetails?.data.responseSettings.anonymity.state ===
        AnonymityStates.ENABLED,
    ),
    postData,
  });

  const handleModalClose = (draftSaveValues?: {
    saveRequested: boolean;
    isNewDraft?: boolean;
    onDraftSave?: () => void;
  }) => {
    if (isEditMode) {
      trackFlowParticipationActionEvent({
        ...flowData,
        action: PARTICIPATION_ANALYTICS_EVENTS.EDIT_FLOW_POST_EXIT,
      });
    } else {
      trackFlowParticipationActionEvent({
        ...flowData,
        action: PARTICIPATION_ANALYTICS_EVENTS.EXIT_LEAVE_CLICKED,
      });
    }
    if (!isEditMode && draftSaveValues?.saveRequested) {
      handleSaveAndCloseParticipationModal(models.values);
      trackFlowParticipationActionEvent({
        action: draftSaveValues.isNewDraft
          ? PARTICIPATION_ANALYTICS_EVENTS.DRAFT_SAVED
          : PARTICIPATION_ANALYTICS_EVENTS.DRAFT_UPDATED,
        flowId,
        name: flowData.name,
        templateId: flowDetails?.data.templateId,
      });
    } else {
      handleSaveAndCloseParticipationModal();
      if (!isEditMode && draftSaveValues) {
        trackFlowParticipationActionEvent({
          action: draftSaveValues.isNewDraft
            ? PARTICIPATION_ANALYTICS_EVENTS.DRAFT_DISCARDED
            : PARTICIPATION_ANALYTICS_EVENTS.DRAFT_UPDATES_DISCARDED,
          flowId,
          name: flowData.name,
          templateId: flowDetails?.data.templateId,
        });
      }
    }
    operations.resetForm();
    draftSaveValues?.onDraftSave?.();
  };

  const handleDeleteDraft = () => {
    if (isDraftMode) deleteDraft(flowId);
    operations.resetForm({ values: initialValues });
    trackFlowParticipationActionEvent({
      action: PARTICIPATION_ANALYTICS_EVENTS.DRAFT_CLEARED,
      flowId,
      name: flowData.name,
      templateId: flowDetails?.data.templateId,
    });
    if (isEmbeddedInMainApp) {
      return null;
    } else {
      history.push('/home');
      history.goBack();
    }
  };

  return {
    models: {
      ...models,
      stepData,
      flowData,
      containerRef,
      isFetchingUploadUrl,
      allowPrivateResponse: Boolean(flowDetails?.data.allowPrivateResponse),
      allowAnonymousResponse: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.OPTIONAL,
      ),
      isAnonymityEnabled: Boolean(
        flowDetails?.data.responseSettings.anonymity.state ===
          AnonymityStates.ENABLED,
      ),
      isDraftMode,
    },
    operations: {
      ...operations,
      handleModalClose,
      handleDeleteDraft,
      setIsFetchingUploadUrl,
    },
  };
};

export default useLoadedFlowsParticipationController;
