import styled, { css, keyframes } from 'styled-components';

import Body from '../../../atoms/Body';
import Heading from '../../../atoms/Heading';
import SVGIcon from '../../../atoms/SVGIcon';
import ThemeV2 from '../../../../theme';

const shakeKeyframes = keyframes`
  0% { -webkit-transform: translate(15px); }
  20% { -webkit-transform: translate(-15px); }
  40% { -webkit-transform: translate(10px); }
  60% { -webkit-transform: translate(-10px); }
  80% { -webkit-transform: translate(6px); }
  100% { -webkit-transform: translate(0px); }
`;

export const StyledWrapper = styled.div<{ hasError: boolean }>`
  width: 80%;
  height: 100%;
  max-width: 1000px;
  margin: 0 10%;
  display: flex;
  flex-direction: column;

  animation: ${({ hasError }) =>
    hasError &&
    css`
      ${shakeKeyframes} 0.5s normal linear
    `};

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    margin: 0 auto;
    width: 70%;
  }

  @media (max-width: ${ThemeV2.breakPoints.md}) {
    width: 65%;
  }
`;

export const ChildrenWrapper = styled.div<{
  childMargin: string | undefined;
}>`
  margin-top: ${({ childMargin }) => childMargin || '32px'};
`;

export const StyledHeading = styled(Heading)`
  margin: 32px 0 0 0;
`;

export const StyledDescriptionBody = styled(Body)`
  margin-top: 8px;
`;

export const StyledSubDescriptionBody = styled(Body)`
  margin-top: 16px;
`;

export const StyledRequiredSpan = styled.span`
  margin-left: 4px;
  color: ${ThemeV2.palette.red6};
`;

export const StyledFieldErrorBody = styled(Body)`
  margin-left: 12px;
`;

export const StyledSVGIcon = styled(SVGIcon)`
  margin: 2px 4px 0 0;
  align-self: flex-start;
`;

const BlockAlertContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;
  border-radius: 4px;
  padding: 6px;
`;

export const StyledErrorContainer = styled(BlockAlertContainer)`
  background-color: ${ThemeV2.palette.dustRed2};
`;

export const StyledWarningContainer = styled(BlockAlertContainer)`
  background-color: ${ThemeV2.palette.gold2};
`;

export const StyledInfoContainer = styled(BlockAlertContainer)`
  background-color: ${ThemeV2.palette.geekBlue2};
`;

export const AutoMarginDiv = styled.div`
  margin: auto 0;
`;

export const StyledBlockFooter = styled.div`
  display: flex;
  margin-bottom: 32px;
  min-height: 32px;
`;

export const StyledNavigationInstructionsWrapper = styled.div`
  display: none;
  margin-top: 12px;

  @media (min-width: ${ThemeV2.breakPoints.md}) {
    display: block;
    margin-right: auto;
  }
`;

export const StyledInputActionWrapper = styled.div`
  margin-left: auto;
`;
