import { TaskCategories } from '../../../../interfaces/notebook';
import { capitalizeFirstLetter } from '../../../../Utils/text';
import { MenuItemIndividualItem } from '../../Dropdown_V2/interfaces';

export const dropdownItems: MenuItemIndividualItem[] = [
  {
    id: TaskCategories.COMPLETED,
    value: capitalizeFirstLetter(TaskCategories.COMPLETED),
  },
  {
    id: TaskCategories.TODAY,
    value: capitalizeFirstLetter(TaskCategories.TODAY),
  },
  {
    id: TaskCategories.UPCOMING,
    value: capitalizeFirstLetter(TaskCategories.UPCOMING),
  },
  {
    id: TaskCategories.UNSCHEDULED,
    value: capitalizeFirstLetter(TaskCategories.UNSCHEDULED),
  },
  {
    id: TaskCategories.OVERDUE,
    value: capitalizeFirstLetter(TaskCategories.OVERDUE),
  },
];
