import { Collapse } from '@mui/material';
import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import { Draggable } from 'react-beautiful-dnd';
import { LEFT_DRAWER_ANALYTICS_EVENTS } from '../../../Utils/analytics/constants';
import { trackLeftDrawerActionEvent } from '../../../Utils/analytics/leftDrawer';
import Body from '../../../atomic/atoms/Body';
import { LeftDrawerCollapsibleNavItemProps } from '../../../atomic/molecules/FlowLeftDrawerCollapsibleNavigation/types';
import MenuList from '../../../atomic/molecules/MenuItem';
import { ARCHIVED_FLOWS } from '../../../languages/en/flows';
import ThemeV2 from '../../../theme';
import { UnarchiveFlowsModal } from './UnarchiveFlowsModal';
import useFlowsArchivedListController from './useFlowsArchivedListController';

const StyledMenuListItem = styled(MenuList.Item)`
  margin-bottom: 4px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const StyledMenuHeader = styled(MenuList.Item)<{ $isCollapsed: boolean }>`
  ${({ $isCollapsed }) =>
    !$isCollapsed &&
    css`
      background-color: ${ThemeV2.palette.gray3};
    `}
`;

const FlowsArchivedListController = ({
  isHover,
  droppablePlaceholder,
  disableDragAndDrop,
}: {
  isHover: boolean;
  droppablePlaceholder?: ReactNode;
  disableDragAndDrop: boolean;
}) => {
  const [flowItem, setFlowItem] = useState<
    LeftDrawerCollapsibleNavItemProps | undefined
  >(undefined);
  const {
    models: {
      isOpen,
      archiveFlowsNavItems,
      isArchiveModalOpen,
      isUnArchiveModalLoading,
      unArchivingFlowDetails,
    },
    operations: {
      onExpandClick,
      onMenuItemClick,
      onUnArchiveClick,
      onModalClose,
    },
  } = useFlowsArchivedListController();

  const getFlowItemOnMenuClick = (id: string) => {
    const individualFlowItem = archiveFlowsNavItems.find(
      (flow) => flow.id === id,
    );
    return individualFlowItem;
  };

  const AccordionHeader = () => {
    return (
      <MenuList>
        <StyledMenuHeader
          $isCollapsed={!isOpen}
          isExpanded={isHover}
          disableHoverState
          onClick={() => {
            onExpandClick();
            if (isOpen) {
              trackLeftDrawerActionEvent({
                action:
                  LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_COLLAPSED,
              });
            } else {
              trackLeftDrawerActionEvent({
                action:
                  LEFT_DRAWER_ANALYTICS_EVENTS.LEFT_DRAWER_EVENTS_ARCHIVED_LIST_EXPANDED,
              });
            }
          }}
        >
          <MenuList.Icon icon={isOpen ? 'carat-up' : 'carat-down'} />
          <MenuList.Text variant="body1">
            {ARCHIVED_FLOWS.MAIN_TEXT_LABEL}
          </MenuList.Text>
        </StyledMenuHeader>
      </MenuList>
    );
  };

  if (archiveFlowsNavItems.length) {
    return (
      <>
        <AccordionHeader />
        <Collapse
          in={isOpen}
          aria-expanded={isOpen}
          data-testid="accordionBody"
        >
          <MenuList>
            {disableDragAndDrop
              ? archiveFlowsNavItems.map((navItem) => (
                  <StyledMenuListItem
                    key={navItem.id}
                    id={navItem.id}
                    onClick={() => {
                      onMenuItemClick(navItem.id);
                      setFlowItem(getFlowItemOnMenuClick(navItem.id));
                    }}
                    isExpanded={isHover}
                    isSelected={navItem.isActive}
                    isMuted={navItem.isArchived}
                  >
                    <MenuList.Emoticon emoticon={navItem.emoticon} />
                    <MenuList.Text
                      variant="body1"
                      color={ThemeV2.palette.gray9}
                    >
                      {navItem.label}
                    </MenuList.Text>
                    {navItem.isUnread ? (
                      <MenuList.SuffixElement>
                        <Body
                          variant="body3"
                          color={
                            navItem.isMuted
                              ? ThemeV2.palette.gray6
                              : ThemeV2.palette.gray9
                          }
                        >
                          {navItem.badgeText}
                        </Body>
                      </MenuList.SuffixElement>
                    ) : (
                      <></>
                    )}
                  </StyledMenuListItem>
                ))
              : archiveFlowsNavItems.map((navItem, index) => (
                  <Draggable
                    draggableId={navItem.id || Math.random.toString()}
                    index={index || 0}
                    disableInteractiveElementBlocking
                    key={navItem.id}
                  >
                    {(dragContext) => (
                      <div
                        {...dragContext.draggableProps}
                        {...dragContext.dragHandleProps}
                        ref={dragContext.innerRef}
                      >
                        <StyledMenuListItem
                          id={navItem.id}
                          onClick={() => {
                            onMenuItemClick(navItem.id);
                            setFlowItem(getFlowItemOnMenuClick(navItem.id));
                          }}
                          isExpanded={isHover}
                          isSelected={navItem.isActive}
                          isMuted={navItem.isArchived}
                        >
                          <MenuList.Emoticon emoticon={navItem.emoticon} />
                          <MenuList.Text
                            variant="body1"
                            color={ThemeV2.palette.gray9}
                          >
                            {navItem.label}
                          </MenuList.Text>
                          {navItem.isUnread ? (
                            <MenuList.SuffixElement>
                              <Body
                                variant="body3"
                                color={
                                  navItem.isMuted
                                    ? ThemeV2.palette.gray6
                                    : ThemeV2.palette.gray9
                                }
                              >
                                {navItem.badgeText}
                              </Body>
                            </MenuList.SuffixElement>
                          ) : (
                            <></>
                          )}
                        </StyledMenuListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
          </MenuList>
          {droppablePlaceholder ? droppablePlaceholder : null}
        </Collapse>
        <UnarchiveFlowsModal
          isArchiveModalOpen={isArchiveModalOpen}
          isUnArchiveModalLoading={isUnArchiveModalLoading}
          onModalClose={onModalClose}
          onUnArchiveClick={onUnArchiveClick}
          unArchivingFlowDetails={unArchivingFlowDetails}
          flowItem={flowItem}
        />
      </>
    );
  }

  return null;
};

export default FlowsArchivedListController;
