import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ThemeV2 from '../../../theme';
import {
  AvatarWrapper,
  StyledCard,
  StyledBody,
  StyledImage,
  StyledSVGCardIcon,
  StyledSVGTextIcon,
  StyledSVGLinkIcon,
  CardTop,
  CardBottom,
  NameWrapper,
  StyledDropdownIconButton,
} from './styles';
import Body from '../../atoms/Body';
import Avatar from '../../atoms/Avatar';
import { Flex } from '../../../Utils/styles/display';
import { ButtonSize } from '../../atoms/IconButton_V2/interface';
import {
  menuItemsWithoutDeletePost,
  menuItemsWithoutDownloadFileItem,
} from './data';
import {
  AttachmentType,
  FeedAttachmentItemFromAPI,
  FeedItemKind,
} from '../../../interfaces/Feed';
import { assignFileTypeInfo } from '../../../Utils/file';
import { trackKnowledgeCenterActionEvent } from '../../../Utils/analytics/knowledgeCenter';
import { KnowledgeCenterEvents } from '../../../Utils/analytics/constants';
import { ANONYMOUS } from '../../../languages/en/singleWords';
import { openExternalUrl } from '../../../Utils/routing';

const KnowledgeCenterCard = (props: {
  card: FeedAttachmentItemFromAPI;
  pageIndex: number;
  cardIndex: number;
  goToPost: (flowId: string, responseId: string) => void;
  handleSetSelectedFile: (value: FeedAttachmentItemFromAPI) => void;
  openPreviewer: Dispatch<SetStateAction<boolean>>;
  onClickDownload: (cardFile: FeedAttachmentItemFromAPI) => Promise<void>;
  copyPostLink: (flowId: string, responseId: string) => void;
}) => {
  const {
    card,
    pageIndex,
    cardIndex,
    goToPost,
    handleSetSelectedFile,
    openPreviewer,
    onClickDownload,
    copyPostLink,
  } = props;
  const [cardColor, setCardColor] = useState('');
  const [cardIcon, setCardIcon] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);

  useEffect(() => {
    if (card.fileInfo) {
      assignFileTypeInfo(card.fileInfo.type, setCardColor, setCardIcon);
    } else {
      assignFileTypeInfo('url', setCardColor, setCardIcon);
    }
  }, [card.fileInfo]);

  const isLink = Boolean(card?.type === AttachmentType.LinkAttachment);

  const onOptionsSelect = (value: string | number) => {
    if (value === 'copy-link' && copyPostLink) {
      copyPostLink(card.response?.flowId, card.response?.responseId);
    }
    if (value === 'go-to-post') {
      trackKnowledgeCenterActionEvent({
        action: KnowledgeCenterEvents.PostViewed,
      });
      goToPost(card.response.flowId, card.response.responseId);
    }
    if (value === 'download-file' && onClickDownload) {
      onClickDownload(card);
    }
    if (value === 'delete') {
      console.log('Not implemented on the BE yet.');
    }
  };

  return (
    <StyledCard
      isLink={isLink}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (isLink && card.urlInfo) {
          openExternalUrl(card.urlInfo.name);
        } else {
          openPreviewer(true);
          handleSetSelectedFile(card);
        }
      }}
      data-testid={`knowledge-center-card-${pageIndex}-${cardIndex}`}
      data-qa-id={`knowledge-center-card-${pageIndex}-${cardIndex}`}
    >
      <CardTop backgroundColor={cardColor}>
        {cardIcon && (cardIcon !== 'imageFile' || isValidLink === false) && (
          <StyledSVGCardIcon icon={cardIcon} size="48px" />
        )}
        {isHovered && (
          <StyledDropdownIconButton
            color="tertiary"
            iconColor={ThemeV2.palette.gray8}
            menuItems={
              isLink
                ? menuItemsWithoutDownloadFileItem
                : menuItemsWithoutDeletePost
            }
            icon={'more'}
            title={'file card options'}
            onItemClick={onOptionsSelect}
            size={ButtonSize.Normal}
            stopEventPropagation={true}
            dataTestId={`knowledge-center-card-${pageIndex}-${cardIndex}-dropdown`}
          />
        )}
        {card.fileInfo?.thumbnails &&
          isValidLink === true &&
          cardIcon === 'imageFile' && (
            <StyledImage
              alt={card.fileInfo?.originalName ?? card.fileInfo?.name}
              src={card.fileInfo?.thumbnails['360']}
              onError={() => setIsValidLink(false)}
            />
          )}
      </CardTop>
      <CardBottom>
        <NameWrapper>
          {cardIcon && <StyledSVGTextIcon icon={cardIcon} size="16px" />}
          <StyledBody variant="body2Medium">
            {card.fileInfo?.originalName
              ? card.fileInfo.originalName.split('.')[0]
              : card.fileInfo?.name.split('.')[0] || card.urlInfo?.name}
          </StyledBody>
          {card.fileInfo ? (
            <Body variant="body2Medium">.{card.fileInfo.type}</Body>
          ) : (
            <StyledSVGLinkIcon icon="external-link" size="16px" />
          )}
        </NameWrapper>
        <StyledBody
          color={ThemeV2.palette.gray8}
          variant="body3"
          style={{ marginTop: '4px' }}
        >
          <Flex>
            <AvatarWrapper>
              <Avatar
                img={card.respondent?.image}
                name={card.respondent?.name}
                userId={card.respondent?.memberID}
                size="16px"
                iconSize="16px"
                verticalAlign="baseline"
                isAnonymous={
                  card.kind === FeedItemKind.INTERNAL_ANONYMOUS ||
                  card.kind === FeedItemKind.EXTERNAL_ANONYMOUS
                }
              />
            </AvatarWrapper>
            {card?.respondent ? card.respondent.name : ANONYMOUS}
          </Flex>
        </StyledBody>
      </CardBottom>
    </StyledCard>
  );
};

export default KnowledgeCenterCard;
